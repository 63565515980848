import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Grid, Row, Col } from 'react-bootstrap';

import { userDataForCheckout } from 'helpers/clients';
import { submitLoginWithoutRedirect } from 'actions/LoginActions';

import CmsElementLoginForm from 'components/cms/elements/CmsElementLoginForm';

// login / continue without login
class StepA extends React.Component {
  componentDidMount = () => {
    const { appIntl, history, user } = this.props;

    if (user.logged) {
      history.replace(appIntl.urlPrefix + 'checkout/overview');
    }
  };

  componentDidUpdate = prevProps => {
    const { appIntl, history, user, setCheckoutState } = this.props;

    if (!prevProps.user.logged && user.logged) {
      setCheckoutState({ userData: userDataForCheckout(user) });
      history.replace(appIntl.urlPrefix + 'checkout/overview');
    }
  };

  getSocialMediaState = () => {
    const { appIntl, checkout, projectConfig } = this.props;
    const res = {
      whiteLabelProjectId: projectConfig.data.id,
      route: appIntl.urlPrefix + 'checkout/init',
      items: checkout.items.map(item => {
        return {
          id: item.id,
          quantity: item.quantity,
        };
      }),
    };

    if (checkout.isItemCheckout) {
      // simple item checkout
      res.type = 'item_sales';
    } else if (checkout.isCarCheckout) {
      // car checkout
      res.type = 'car_sales';
    } else {
      // car rent checkout
      res.type = 'car_rent';
      res.locationId = checkout.locationData.id;
      res.dateFrom = checkout.dateFrom;
      res.dateTo = checkout.dateTo;
      res.userTimezone = checkout.userTimezone;
    }

    return res;
  };

  submitLogin = loginData => {
    const { appIntl, submitLogin } = this.props;

    return submitLogin(loginData, appIntl.urlPrefix);
  };

  render() {
    const { intl, appIntl } = this.props;
    const { messages } = intl;

    return (
      <Grid>
        <Row class="p-t-65 p-b-100 sm-p-b-30 sm-m-b-30">
          <Col md={6}>
            <h2>{messages.continue_with_login}</h2>
            <div class="p-r-40 sm-p-r-0">
              <p>{messages.already_have_an_account_enter_username_and_password}</p>

              <CmsElementLoginForm
                hideTitle
                socialMediaLoginState={window.btoa(JSON.stringify(this.getSocialMediaState()))}
                intl={intl}
                appIntl={appIntl}
                onSubmit={this.submitLogin}
              />
            </div>
          </Col>
          <Col md={6}>
            <div class="visible-xs visible-sm b-b b-grey-light m-t-35 m-b-30" /> {/* spacer for small devices */}
            <h3>{messages.dont_have_an_account}</h3>
            <p>{messages.continue_without_login_and_register}</p>
            <div class="text-right m-t-15">
              <Link class="btn btn-lg btn-primary" to={appIntl.urlPrefix + 'checkout/overview'}>
                {messages.signup}
              </Link>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    projectConfig: state.data.projectConfig,
    user: state.login.user,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    submitLogin: (args, urlPrefix) => dispatch(submitLoginWithoutRedirect(args, urlPrefix)),
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(StepA)
  )
);
