import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import AbstractCmsElement from './AbstractCmsElement';

export default class CmsElementCol extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  getResponsiveDef = () => {
    const xs = parseInt(this.getParamValue('xs', 12), 10);
    const xsOffset = parseInt(this.getParamValue('xsOffset', 0), 10);
    const sm = parseInt(this.getParamValue('sm', xs), 10);
    const smOffset = parseInt(this.getParamValue('smOffset', 0), 10);
    const md = parseInt(this.getParamValue('md', sm), 10);
    const mdOffset = parseInt(this.getParamValue('mdOffset', 0), 10);
    const lg = parseInt(this.getParamValue('lg', md), 10);
    const lgOffset = parseInt(this.getParamValue('lgOffset', 0), 10);

    return (
      ' col-xs-' +
      xs +
      ' col-sm-' +
      sm +
      ' col-md-' +
      md +
      ' col-lg-' +
      lg +
      ' col-xs-offset-' +
      xsOffset +
      ' col-sm-offset-' +
      smOffset +
      ' col-md-offset-' +
      mdOffset +
      ' col-lg-offset-' +
      lgOffset
    );
  };

  render() {
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={
          'ms-col col ' + shownClass + this.getResponsiveDef() + this.getResponsiveClasses() + this.getCssClasses()
        }
        style={{
          ...this.getBackgroundStyle(),
        }}
        key="c"
      >
        {this.getElementStyle()}

        {this.props.children}

        {this.getAppendHtml()}
      </div>
    );

    return (
      <>
        <ScrollTrigger onEnter={this.shown} />
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </>
    );
  }
}
CmsElementCol.defaultProps = {
  index: '0',
};
