import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ScrollTrigger from 'react-scroll-trigger';
import Slider from 'react-slick';

import { isPluginActive } from 'helpers/whiteLabel';
import { getCarRentalPath, getCarRentalSearchPath } from 'helpers/intl';

import { AUTOMOTIVE_SALES_PLUGIN, AUTOMOTIVE_RENT_PLUGIN } from 'js/constants';

class PluginBlurbs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    this.setState({ shown: true });
  };

  showNewPriceNegotiation = e => {
    e.preventDefault();

    const { doShowNewPriceNegotiation } = this.props;
    doShowNewPriceNegotiation();
  };

  render() {
    const {
      messages,
      layout,
      projectConfig: { data: projectConfigData },
      locationData,
    } = this.props;

    const chedriSalesActive = isPluginActive(AUTOMOTIVE_SALES_PLUGIN, projectConfigData);
    const chedriRentActive = isPluginActive(AUTOMOTIVE_RENT_PLUGIN, projectConfigData);

    let rentHref = getCarRentalPath();
    // check the cars location
    // if it supports car rental, get the default dates and build the url
    if (!locationData.pending && locationData.data.is_rental_point && locationData.data.white_label_location_id) {
      rentHref = getCarRentalSearchPath(locationData.data.white_label_location_id, locationData.data.name);
    }

    const settings = {
      dots: layout.breakpointIndex > 1, // display the dots only on larger devices
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const slides = [];
    if (chedriSalesActive) {
      slides.push(
        <Col xs={12} sm={6} md={4} className="plugin-blurb" key="price-proposal">
          <a href="#sendPriceNegotiation" className="block text-black" onClick={this.showNewPriceNegotiation}>
            <i className="chedri-deal chedri-4x m-b-10" />
            <h3>{messages.send_price_proposal}</h3>
            <p>{messages.you_want_this_vehicle_but_the_price_isnt_right_submit_price_proposal}</p>
          </a>

          <a
            href="#sendPriceNegotiation"
            className="block btn btn-primary btn-trp m-t-40"
            onClick={this.showNewPriceNegotiation}
          >
            {messages.send_price_proposal}
          </a>
        </Col>
      );
    }

    if (chedriRentActive) {
      slides.push(
        <Col xs={12} sm={6} md={4} className="plugin-blurb" key="car-rental">
          <Link to={rentHref} className="block text-black">
            <i className="chedri-rent-a-car chedri-4x m-b-10" />
            <h3>{messages.rent_a_car}</h3>
            <p>
              <FormattedMessage
                id="you_want_to_rent_this_vehicle_at_project_name_you_can"
                values={{ projectName: projectConfigData.name }}
              />
            </p>
          </Link>

          <Link to={rentHref} className="block btn btn-primary btn-trp m-t-40">
            {messages.rent_a_car}
          </Link>
        </Col>
      );
    }

    const showSlider =
      (layout.breakpointIndex === 0 && slides.length > 1) ||
      (layout.breakpointIndex === 1 && slides.length > 2) ||
      slides.length > 3;

    return slides.length ? (
      <ScrollTrigger onEnter={this.shown}>
        <Grid>
          <Row className="p-t-70 p-b-50 item-details-plugin-blurbs">
            {showSlider ? <Slider {...settings}>{slides}</Slider> : slides}
          </Row>
        </Grid>
      </ScrollTrigger>
    ) : null;
  }
}

export default PluginBlurbs;
