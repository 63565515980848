'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _cropperjs = require('cropperjs');

var _cropperjs2 = _interopRequireDefault(_cropperjs);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var optionProps = ['dragMode', 'aspectRatio', 'data', 'crop',
// unchangeable props start from here
'viewMode', 'preview', 'responsive', 'restore', 'checkCrossOrigin', 'checkOrientation', 'modal', 'guides', 'center', 'highlight', 'background', 'autoCrop', 'autoCropArea', 'movable', 'rotatable', 'scalable', 'zoomable', 'zoomOnTouch', 'zoomOnWheel', 'wheelZoomRation', 'cropBoxMovable', 'cropBoxResizable', 'toggleDragModeOnDblclick', 'minContainerWidth', 'minContainerHeight', 'minCanvasWidth', 'minCanvasHeight', 'minCropBoxWidth', 'minCropBoxHeight', 'ready', 'cropstart', 'cropmove', 'cropend', 'zoom', 'responseType'];

var unchangeableProps = optionProps.slice(4);

var ImageEditorRc = function (_Component) {
  _inherits(ImageEditorRc, _Component);

  function ImageEditorRc(props) {
    _classCallCheck(this, ImageEditorRc);

    var _this = _possibleConstructorReturn(this, (ImageEditorRc.__proto__ || Object.getPrototypeOf(ImageEditorRc)).call(this, props));

    _this.state = {
      rotateAngle: 0,
      image: ''
    };
    _this.rotateToLeft = _this.rotateToLeft.bind(_this);
    _this.rotateToRight = _this.rotateToRight.bind(_this);
    _this.crop = _this.crop.bind(_this);
    return _this;
  }

  _createClass(ImageEditorRc, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      var options = Object.keys(this.props).filter(function (propKey) {
        return optionProps.indexOf(propKey) !== -1;
      }).reduce(function (prevOptions, propKey) {
        return _extends({}, prevOptions, _defineProperty({}, propKey, _this2.props[propKey]));
      }, {});
      this.cropper = new _cropperjs2.default(this.img, options);
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      var _this3 = this;

      if (nextProps.src !== this.props.src) {
        this.cropper.reset().clear().replace(nextProps.src);
      }
      if (nextProps.aspectRatio !== this.props.aspectRatio) {
        this.setAspectRatio(nextProps.aspectRatio);
      }
      if (nextProps.data !== this.props.data) {
        this.setData(nextProps.data);
      }
      if (nextProps.dragMode !== this.props.dragMode) {
        this.setDragMode(nextProps.dragMode);
      }
      if (nextProps.cropBoxData !== this.props.cropBoxData) {
        this.setCropBoxData(nextProps.cropBoxData);
      }
      if (nextProps.canvasData !== this.props.canvasData) {
        this.setCanvasData(nextProps.canvasData);
      }
      if (nextProps.moveTo !== this.props.moveTo) {
        if (nextProps.moveTo.length > 1) {
          this.moveTo(nextProps.moveTo[0], nextProps.moveTo[1]);
        } else {
          this.moveTo(nextProps.moveTo[0]);
        }
      }
      if (nextProps.zoomTo !== this.props.zoomTo) {
        this.zoomTo(nextProps.zoomTo);
      }
      if (nextProps.scaleX !== this.props.scaleX) {
        this.scaleX(nextProps.scaleX);
      }
      if (nextProps.scaleY !== this.props.scaleY) {
        this.scaleY(nextProps.scaleY);
      }
      if (nextProps.enable !== this.props.enable) {
        if (nextProps.enable) {
          this.enable();
        } else {
          this.disable();
        }
      }

      Object.keys(nextProps).forEach(function (propKey) {
        var isDifferentVal = nextProps[propKey] !== _this3.props[propKey];
        var isUnchangeableProps = unchangeableProps.indexOf(propKey) !== -1;

        if (typeof nextProps[propKey] === 'function' && typeof _this3.props[propKey] === 'function') {
          isDifferentVal = nextProps[propKey].toString() !== _this3.props[propKey].toString();
        }

        if (isDifferentVal && isUnchangeableProps) {
          throw new Error('prop: ' + propKey + ' can\'t be change after componentDidMount');
        }
      });
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      if (this.img) {
        // Destroy the cropper, this makes sure events such as resize are cleaned up and do not leak
        this.cropper.destroy();
        delete this.img;
        delete this.cropper;
      }
    }
  }, {
    key: 'setDragMode',
    value: function setDragMode(mode) {
      return this.cropper.setDragMode(mode);
    }
  }, {
    key: 'setAspectRatio',
    value: function setAspectRatio(aspectRatio) {
      return this.cropper.setAspectRatio(aspectRatio);
    }
  }, {
    key: 'getCroppedCanvas',
    value: function getCroppedCanvas(options) {
      return this.cropper.getCroppedCanvas(options);
    }
  }, {
    key: 'setCropBoxData',
    value: function setCropBoxData(data) {
      return this.cropper.setCropBoxData(data);
    }
  }, {
    key: 'getCropBoxData',
    value: function getCropBoxData() {
      return this.cropper.getCropBoxData();
    }
  }, {
    key: 'setCanvasData',
    value: function setCanvasData(data) {
      return this.cropper.setCanvasData(data);
    }
  }, {
    key: 'getCanvasData',
    value: function getCanvasData() {
      return this.cropper.getCanvasData();
    }
  }, {
    key: 'getImageData',
    value: function getImageData() {
      return this.cropper.getImageData();
    }
  }, {
    key: 'getContainerData',
    value: function getContainerData() {
      return this.cropper.getContainerData();
    }
  }, {
    key: 'setData',
    value: function setData(data) {
      return this.cropper.setData(data);
    }
  }, {
    key: 'getData',
    value: function getData(rounded) {
      return this.cropper.getData(rounded);
    }
  }, {
    key: 'crop',
    value: function crop(options, cb) {
      this.setState({
        image: this.cropper.getCroppedCanvas(options)
      }, cb);
    }
  }, {
    key: 'move',
    value: function move(offsetX, offsetY) {
      return this.cropper.move(offsetX, offsetY);
    }
  }, {
    key: 'moveTo',
    value: function moveTo(x, y) {
      return this.cropper.moveTo(x, y);
    }
  }, {
    key: 'zoom',
    value: function zoom(ratio) {
      return this.cropper.zoom(ratio);
    }
  }, {
    key: 'zoomTo',
    value: function zoomTo(ratio) {
      return this.cropper.zoomTo(ratio);
    }
  }, {
    key: 'rotate',
    value: function rotate(degree) {
      return this.cropper.rotate(degree);
    }
  }, {
    key: 'rotateToLeft',
    value: function rotateToLeft() {
      this.setState({ rotateAngle: this.state.rotateAngle - 90 });
      return this.cropper.rotateTo(this.state.rotateAngle - 90);
    }
  }, {
    key: 'rotateToRight',
    value: function rotateToRight() {
      this.setState({ rotateAngle: this.state.rotateAngle + 90 });
      return this.cropper.rotateTo(this.state.rotateAngle + 90);
    }
  }, {
    key: 'enable',
    value: function enable() {
      return this.cropper.enable();
    }
  }, {
    key: 'disable',
    value: function disable() {
      return this.cropper.disable();
    }
  }, {
    key: 'reset',
    value: function reset() {
      return this.cropper.reset();
    }
  }, {
    key: 'clear',
    value: function clear() {
      return this.cropper.clear();
    }
  }, {
    key: 'replace',
    value: function replace(url, onlyColorChanged) {
      return this.cropper.replace(url, onlyColorChanged);
    }
  }, {
    key: 'scale',
    value: function scale(scaleX, scaleY) {
      return this.cropper.scale(scaleX, scaleY);
    }
  }, {
    key: 'scaleX',
    value: function scaleX(_scaleX) {
      return this.cropper.scaleX(_scaleX);
    }
  }, {
    key: 'scaleY',
    value: function scaleY(_scaleY) {
      return this.cropper.scaleY(_scaleY);
    }
  }, {
    key: 'saveImage',
    value: function saveImage() {
      var _this4 = this;

      switch (this.props.responseType) {
        case 'blob':
          this.state.image.toBlob(function (blob) {
            _this4.props.saveImage(blob);
          });
          break;
        case 'base64':
          this.props.saveImage(this.state.image.toDataURL());
          break;
        default:
          this.props.saveImage(this.state.image.toDataURL());
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this5 = this;

      var _props = this.props,
          src = _props.src,
          alt = _props.alt,
          crossOrigin = _props.crossOrigin,
          scaleX = 1,
          scaleY = 1;

      return _react2.default.createElement(
        'div',
        {
          src: null,
          alt: null,
          style: this.props.style,
          className: this.props.className
        },
        _react2.default.createElement('img', {
          crossOrigin: crossOrigin,
          ref: function ref(img) {
            _this5.img = img;
          },
          src: src,
          alt: alt === undefined ? 'picture' : alt,
          style: { opacity: 0 }
        }),
        _react2.default.createElement('br', null),
        _react2.default.createElement(
          'ul',
          {className: "btn-group btn-group-lg"},
          _react2.default.createElement(
            'li',
            {className: "btn btn-default", 'data-tip':"90° nach rechts drehen", 'data-for':"bottom", onClick: function onClick() {
                return _this5.rotateToRight();
              } },
            _react2.default.createElement(
              'i',
              { className: "fad fa-redo-alt"},
              ''
            )
          ),
          _react2.default.createElement(
            'li',
            {className: "btn btn-default", 'data-tip':"90° nach links drehen", 'data-for':"bottom", onClick: function onClick() {
                return _this5.rotateToLeft();
              }},
            _react2.default.createElement(
              'i',
              { className: "fad fa-undo-alt" },
              ''
            )
          ),
          _react2.default.createElement(
            'li',
            {className: "btn btn-default", 'data-tip':"Horizontal spiegeln", 'data-for':"bottom", onClick: function onClick() {
                scaleX *= -1;
                return _this5.scaleX(scaleX);
              }},
            _react2.default.createElement(
              'i',
              { className: "fad fa-exchange-alt" },
              ''
            )
          ),
          _react2.default.createElement(
            'li',
            {className: "btn btn-default", 'data-tip':"Vertikal spiegeln", 'data-for':"bottom", onClick: function onClick() {
                scaleY *= -1;
                return _this5.scaleY(scaleY);
              }},
            _react2.default.createElement(
              'i',
              { className: "fad fa-sort-alt" },
              ''
            )
          ),
          _react2.default.createElement(
            'li',
            {className: "btn btn-success", 'data-tip':"Speichern und übernehmen", 'data-for':"bottom", onClick: function onClick() {
                return _this5.crop(_this5.props, _this5.saveImage);
              }},
            _react2.default.createElement(
              'i',
              { className: "fad fa-save" },
              ''
            ),
            _react2.default.createElement(
              'span',
              { className: "image-button-text"},
              ' Speichern'
            ),
          )
        )
      );
    }
  }]);

  return ImageEditorRc;
}(_react.Component);

ImageEditorRc.propTypes = {
  style: _propTypes2.default.object, // eslint-disable-line react/forbid-prop-types
  className: _propTypes2.default.string,

  // react cropper options
  crossOrigin: _propTypes2.default.string,
  imageName: _propTypes2.default.string,
  src: _propTypes2.default.string,
  alt: _propTypes2.default.string,

  // props of option can be changed after componentDidmount
  aspectRatio: _propTypes2.default.number,
  dragMode: _propTypes2.default.oneOf(['crop', 'move', 'none']),
  data: _propTypes2.default.shape({
    x: _propTypes2.default.number,
    y: _propTypes2.default.number,
    width: _propTypes2.default.number,
    height: _propTypes2.default.number,
    rotate: _propTypes2.default.number,
    scaleX: _propTypes2.default.number,
    scaleY: _propTypes2.default.number
  }),
  scaleX: _propTypes2.default.number,
  scaleY: _propTypes2.default.number,
  enable: _propTypes2.default.bool,
  cropBoxData: _propTypes2.default.shape({
    left: _propTypes2.default.number,
    top: _propTypes2.default.number,
    width: _propTypes2.default.number,
    height: _propTypes2.default.number
  }),
  canvasData: _propTypes2.default.shape({
    left: _propTypes2.default.number,
    top: _propTypes2.default.number,
    width: _propTypes2.default.number,
    height: _propTypes2.default.number
  }),
  zoomTo: _propTypes2.default.number,
  moveTo: _propTypes2.default.arrayOf(_propTypes2.default.number),
  rotateTo: _propTypes2.default.number,

  // cropperjs options
  // https://github.com/fengyuanchen/cropperjs#options
  // aspectRatio, dragMode, data
  viewMode: _propTypes2.default.oneOf([0, 1, 2, 3]),
  preview: _propTypes2.default.string,
  responsive: _propTypes2.default.bool,
  restore: _propTypes2.default.bool,
  checkCrossOrigin: _propTypes2.default.bool,
  checkOrientation: _propTypes2.default.bool,
  modal: _propTypes2.default.bool,
  guides: _propTypes2.default.bool,
  center: _propTypes2.default.bool,
  highlight: _propTypes2.default.bool,
  background: _propTypes2.default.bool,
  autoCrop: _propTypes2.default.bool,
  autoCropArea: _propTypes2.default.number,
  movable: _propTypes2.default.bool,
  rotatable: _propTypes2.default.bool,
  scalable: _propTypes2.default.bool,
  zoomable: _propTypes2.default.bool,
  zoomOnTouch: _propTypes2.default.bool,
  zoomOnWheel: _propTypes2.default.bool,
  wheelZoomRation: _propTypes2.default.number,
  cropBoxMovable: _propTypes2.default.bool,
  cropBoxResizable: _propTypes2.default.bool,
  toggleDragModeOnDblclick: _propTypes2.default.bool,
  minContainerWidth: _propTypes2.default.number,
  minContainerHeight: _propTypes2.default.number,
  minCanvasWidth: _propTypes2.default.number,
  minCanvasHeight: _propTypes2.default.number,
  minCropBoxWidth: _propTypes2.default.number,
  minCropBoxHeight: _propTypes2.default.number,
  ready: _propTypes2.default.func,
  cropstart: _propTypes2.default.func,
  cropmove: _propTypes2.default.func,
  cropend: _propTypes2.default.func,
  crop: _propTypes2.default.func,
  zoom: _propTypes2.default.func,
  saveImage: _propTypes2.default.func,
  responseType: _propTypes2.default.string
};

ImageEditorRc.defaultProps = {
  src: null,
  dragMode: 'crop',
  data: null,
  scaleX: 1,
  scaleY: 1,
  enable: true,
  zoomTo: 1,
  rotateTo: 0,
  imageName: 'New'
};

exports.default = ImageEditorRc;
