import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';

class TeamInfos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    this.setState({ shown: true });
  };

  showBuyInquiry = () => {
    const { doShowBuyInquiry } = this.props;
    doShowBuyInquiry();
  };

  render() {
    const { messages, data, locationData, locationType } = this.props;

    let phone = null;
    if (!locationData.pending) {
      switch (locationType) {
        case 'rental': {
          phone = locationData.data.rental_point_phone || locationData.data.main_phone;
          break;
        }

        default: {
          phone = locationData.data.sales_point_phone || locationData.data.main_phone;
        }
      }
    }

    const imageComponent = <img src="/img/default_car_details_team.jpg" alt="" className="img-responsive" />;

    return (
      <ScrollTrigger onEnter={this.shown}>
        <Grid>
          <Row className="p-t-50 p-b-50 item-details-team-infos">
            <Col xs={12} md={6} className="hidden-xs hidden-sm">
              {imageComponent}
            </Col>
            <Col xs={12} md={6} className="p-r-60 sm-p-r-0 sm-m-b-20">
              <h2>
                <FormattedMessage
                  id="any_question_the_company_team_is_at_your_disposal"
                  values={{ company: data.owner.company }}
                />
              </h2>
              {!!phone && (
                <h2>
                  <a href={'tel:' + phone} className="text-primary bold">
                    <i className="fad fa-phone-alt fa-fw m-r-10" />
                    {phone}
                  </a>
                </h2>
              )}

              <Button
                bsStyle="primary"
                className="btn-trp m-t-40 p-l-60 p-r-60 sm-p-l-30 sm-p-r-30"
                onClick={this.showBuyInquiry}
              >
                {messages.contact_us}
              </Button>
            </Col>
            <Col xs={12} className="hidden-md hidden-lg">
              {imageComponent}
            </Col>
          </Row>
        </Grid>
      </ScrollTrigger>
    );
  }
}
TeamInfos.defaultProps = {
  locationType: 'sales',
};

export default TeamInfos;
