import React from 'react';
import { Clearfix } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { forEach as _forEach } from 'lodash';

class CmsNewRow extends React.Component {
  addRow = rowDefinition => {
    const rowData = {
      type: 'row',
      params: [{ name: 'paddingTop', value: 50 }, { name: 'paddingBottom', value: 50 }],
      children: [],
    };

    rowDefinition.map(col => {
      const colParams = [];

      _forEach(col, (value, name) => {
        colParams.push({ name, value });
      });

      rowData.children.push({
        type: 'col',
        params: colParams,
      });
    });

    this.props.addRow(rowData);
    document.body.click();
  };

  render() {
    return (
      <div class="ms-cols-selection">
        <ul>
          <li
            class="ms-cols-option-12"
            onClick={() => {
              this.addRow([{ xs: 12 }]);
            }}
          >
            <span class="ms-cols-option-wrap" style={{ width: '100%' }}>
              <span class="ms-cols-option-inner" />
            </span>
          </li>
          <li
            class="ms-cols-option-6-6"
            onClick={() => {
              this.addRow([{ md: 6, xs: 12 }, { md: 6, xs: 12 }]);
            }}
          >
            <span class="ms-cols-option-wrap" style={{ width: '50%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '50%' }}>
              <span class="ms-cols-option-inner" />
            </span>
          </li>
          <li
            class="ms-cols-option-4-4-4"
            onClick={() => {
              this.addRow([{ md: 4, xs: 12 }, { md: 4, xs: 12 }, { md: 4, xs: 12 }]);
            }}
          >
            <span class="ms-cols-option-wrap" style={{ width: '33.333%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '33.333%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '33.333%' }}>
              <span class="ms-cols-option-inner" />
            </span>
          </li>
          <li
            class="ms-cols-option-3-3-3-3"
            onClick={() => {
              this.addRow([{ xs: 6, md: 3 }, { xs: 6, md: 3 }, { xs: 6, md: 3 }, { xs: 6, md: 3 }]);
            }}
          >
            <span class="ms-cols-option-wrap" style={{ width: '25%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '25%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '25%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '25%' }}>
              <span class="ms-cols-option-inner" />
            </span>
          </li>
          <li
            class="ms-cols-option-8-4"
            onClick={() => {
              this.addRow([{ xs: 12, md: 8 }, { xs: 12, md: 4 }]);
            }}
          >
            <span class="ms-cols-option-wrap" style={{ width: '66.666%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '33.333%' }}>
              <span class="ms-cols-option-inner" />
            </span>
          </li>
          <li
            class="ms-cols-option-4-8"
            onClick={() => {
              this.addRow([{ xs: 12, md: 4 }, { xs: 12, md: 8 }]);
            }}
          >
            <span class="ms-cols-option-wrap" style={{ width: '33.333%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '66.666%' }}>
              <span class="ms-cols-option-inner" />
            </span>
          </li>
          <li
            class="ms-cols-option-9-3"
            onClick={() => {
              this.addRow([{ xs: 12, md: 9 }, { xs: 12, md: 3 }]);
            }}
          >
            <span class="ms-cols-option-wrap" style={{ width: '75%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '25%' }}>
              <span class="ms-cols-option-inner" />
            </span>
          </li>
          <li
            class="ms-cols-option-3-9"
            onClick={() => {
              this.addRow([{ xs: 12, md: 3 }, { xs: 12, md: 9 }]);
            }}
          >
            <span class="ms-cols-option-wrap" style={{ width: '25%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '75%' }}>
              <span class="ms-cols-option-inner" />
            </span>
          </li>
          <li
            class="ms-cols-option-6-3-3"
            onClick={() => {
              this.addRow([{ xs: 12, md: 6 }, { xs: 6, md: 3 }, { xs: 6, md: 3 }]);
            }}
          >
            <span class="ms-cols-option-wrap" style={{ width: '50%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '25%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '25%' }}>
              <span class="ms-cols-option-inner" />
            </span>
          </li>
          <li
            class="ms-cols-option-3-3-6"
            onClick={() => {
              this.addRow([{ xs: 6, md: 3 }, { xs: 6, md: 3 }, { xs: 12, md: 6 }]);
            }}
          >
            <span class="ms-cols-option-wrap" style={{ width: '25%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '25%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '50%' }}>
              <span class="ms-cols-option-inner" />
            </span>
          </li>
          <li
            class="ms-cols-option-3-6-3"
            onClick={() => {
              this.addRow([{ xs: 12, md: 3 }, { xs: 12, md: 6 }, { xs: 12, md: 3 }]);
            }}
          >
            <span class="ms-cols-option-wrap" style={{ width: '25%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '50%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '25%' }}>
              <span class="ms-cols-option-inner" />
            </span>
          </li>
          <li
            class="ms-cols-option-24-24-24-24-24"
            onClick={() => {
              this.addRow([
                { xs: 6, md: 2, mdOffset: 1, lgOffset: 1 },
                { xs: 6, md: 2 },
                { xs: 6, md: 2 },
                { xs: 6, md: 2 },
                { xs: 6, xsOffset: 3, smOffset: 3, md: 2 },
              ]);
            }}
          >
            <span class="ms-cols-option-wrap" style={{ width: '20%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '20%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '20%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '20%' }}>
              <span class="ms-cols-option-inner" />
            </span>
            <span class="ms-cols-option-wrap" style={{ width: '20%' }}>
              <span class="ms-cols-option-inner" />
            </span>
          </li>
        </ul>
        <Clearfix />
      </div>
    );
  }
}

export default injectIntl(CmsNewRow);
