import React from 'react';
import { withRouter } from 'react-router-dom';
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.cancelCheckoutContainerRef = React.createRef();
  }

  render() {
    const {
      forwardedRef,
      intl: { messages },
      appIntl: { urlPrefix },
      history,
      menuVersion,
      menuBackground,
      logoUrl,
    } = this.props;

    return (
      <nav className="horizontal-menu mv-checkout relative" role="navigation" ref={forwardedRef}>
        <div
          ref={this.cancelCheckoutContainerRef}
          className={'header no-border header-' + menuVersion + ' header-' + menuBackground}
          id="horizontal-menu"
        >
          <div className="container-fluid relative">
            <div className="pull-center">
              <div className="header-inner">
                <div className="text-center">
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    rootClose
                    container={this.cancelCheckoutContainerRef.current}
                    overlay={
                      <Popover id="break-checkout" title={messages.cancel_checkout_question} style={{ zIndex: 1200 }}>
                        <Button block onClick={() => history.push(urlPrefix)} className="m-t-10">
                          Home
                        </Button>
                      </Popover>
                    }
                  >
                    <a href="#logo" onClick={e => e.preventDefault()}>
                      <img src={logoUrl} alt="logo" className="nav-logo" />
                    </a>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

const WithRouterCheckout = withRouter(Checkout);

const CheckoutForExport = React.forwardRef((props, ref) => <WithRouterCheckout forwardedRef={ref} {...props} />);
CheckoutForExport.displayName = 'MenuDefault';

export default CheckoutForExport;
