import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { appModule, intlModule, plugin } from '@chedri/base';

import Panel from 'components/panel/Panel';
import IconLink from 'components/IconLink';
import CommunicationContacts from './CommunicationContacts';

function ChatContactsPanel({ onClose, onContactSelected }) {
  const urlPrefix = useSelector(intlModule.selectors.getUrlPrefix);
  const isPluginActive = useSelector(appModule.selectors.isPluginActive(plugin.CHEDRI_COMMS_CENTER));

  return (
    <Panel className="employee-chat box-shadow-small" style={{ height: '450px', overflow: 'hidden' }}>
      <Panel.Header
        rightAction={
          <>
            {isPluginActive ? (
              <IconLink
                name="fa-expand-alt"
                light
                href={`${urlPrefix}account/communication`}
                linkClassName="link m-r-10"
                iconClassName="fa-flip-horizontal fs-18 v-align-middle"
              />
            ) : null}
            <IconLink
              name="fa-times-circle"
              solid
              onClick={onClose}
              linkClassName="link"
              iconClassName="fa-flip-horizontal fs-18 v-align-middle"
            />
          </>
        }
      >
        Live Chat
      </Panel.Header>
      <Panel.Body className="chatter-users list-view boreded relative">
        <CommunicationContacts onItemSelected={onContactSelected} />
      </Panel.Body>
    </Panel>
  );
}
ChatContactsPanel.defaultProps = {
  onClose: () => undefined,
  onContactSelected: () => undefined,
};
ChatContactsPanel.propTypes = {
  onClose: PropTypes.func,
  onContactSelected: PropTypes.func,
};

export default ChatContactsPanel;
