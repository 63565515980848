import React from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import { isExternal } from 'helpers/http';

import RichEditorContent from 'components/RichEditorContent';

import AbstractCmsElement from './AbstractCmsElement';

class CmsElementCallToAction extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  onClick = () => {
    const buttonUrl = this.getParamValue('link', '');
    if (isExternal(buttonUrl)) {
      window.location.href = buttonUrl;
    } else {
      this.props.history.push(buttonUrl);
    }
  };

  render() {
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const buttonClass = shown ? 'button-shown' : 'button-not-shown-yet';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={
          'ms-call-to-action-content p-b-40 p-t-40 p-l-60 p-r-60 text-' +
          this.getParamValue('mainColor', 'master') +
          ' ' +
          this.getResponsiveClasses() +
          this.getCssClasses() +
          shownClass
        }
        style={{
          ...this.getBackgroundStyle(),
        }}
      >
        {this.getElementStyle()}

        <div class={'call-to-action-container text-' + this.getParamValue('textAlign', 'center')}>
          <h2 class="no-margin p-b-25">{this.getParamValue('title', '')}</h2>
          <RichEditorContent content={'<p>' + this.getParamValue('content', '') + '</p>'} />
          <Button
            class={
              'call-to-action btn-animated from-left fa ' +
              this.getParamValue('icon', 'fa-arrow-right') +
              ' ' +
              buttonClass
            }
            bsStyle="primary"
            onClick={this.onClick}
          >
            <span>{this.getParamValue('buttonTitle', '')}</span>
          </Button>
        </div>

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementCallToAction.defaultProps = {
  index: '0',
};

export default withRouter(CmsElementCallToAction);
