import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import 'moment-timezone';
import { FormattedRelativeTime } from 'react-intl';

const ONE_DAY = 60 * 60 * 24 * 1000;
const ONE_HOUR = 60 * 60 * 1000;
const ONE_MINUTE = 60 * 1000;
const ONE_SECOND = 1000;

const modusFallbacks = [
  { unit: 'day', divider: ONE_DAY, min: 1 },
  { unit: 'hour', divider: ONE_HOUR, min: 1 },
  { unit: 'minute', divider: ONE_MINUTE, min: 1 },
  { unit: 'second', divider: ONE_SECOND, min: 1 },
];

function callculateRelativeTime(timestamp, timezone) {
  let relativeTime = 0;
  let modusIndex = 0;
  let modus;
  do {
    modus = modusFallbacks[modusIndex++];
    relativeTime = parseInt(
      moment(timestamp).diff(
        moment()
          .tz(timezone)
          .toDate()
          .getTime()
      ) / modus.divider,
      10
    );
  } while (Math.abs(relativeTime) < modus.min && modusIndex < modusFallbacks.length);
  return [relativeTime, modus.unit];
}

function Time(/* props */ { currentMessage, timezone, locale }) {
  if (currentMessage && currentMessage.cdate) {
    const createdAt = moment(currentMessage.cdate);
    const [relativeTime, unit] = callculateRelativeTime(createdAt, timezone);
    return createdAt.isSame(moment(), 'day') ? (
      <FormattedRelativeTime
        value={relativeTime}
        unit={unit}
        numeric="auto"
        updateIntervalInSeconds={unit !== 'day' ? 60 : undefined}
      />
    ) : (
      createdAt.locale(locale).format('LT')
    );
  }
  return null;
}
Time.defaultProps = {
  currentMessage: {
    cdate: null,
  },
};
Time.propTypes = {
  currentMessage: PropTypes.object,
};

export default Time;
