import React from 'react';
// import PropTypes from 'prop-types';

// CartWizardStepFooter.propTypes = {};

function CartWizardStepFooter({ children }) {
  return <div className="wizard-step-footer">{children}</div>;
}

export default CartWizardStepFooter;
