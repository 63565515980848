import React from 'react';
import { injectIntl } from 'react-intl';
import { OverlayTrigger, Tooltip, Overlay, Popover, Label } from 'react-bootstrap';
import AnimateHeight from 'react-animate-height';
import passwordStrength from 'check-password-strength';

class FormInputPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value ? props.value : '',
      focused: false,
      showPlain: false,
    };

    this.inputRef = React.createRef();
  }

  showPassword = e => {
    e.preventDefault();
    this.setState({ showPlain: true });
  };

  closePassword = e => {
    e.preventDefault();
    this.setState({ showPlain: false });
  };

  checkFocused = () => {
    return this.state.focused ? 'focused ' : '';
  };

  checkRequired = () => {
    return this.props.meta.validate.rules.indexOf('required') !== -1 ? 'required ' : '';
  };

  checkError = () => {
    return this.props.validation.valid ? '' : 'has-error ';
  };

  // check if user may read this field
  checkAccess = () => {
    return this.props.meta.acl.read;
  };

  // check if user may write to this field
  checkReadOnly = () => {
    return !this.props.meta.acl.write;
  };

  catchFocus = () => {
    this.inputRef.current.focus();
  };

  onChange = value => {
    this.setState({ value });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.onChange(this.props.name, value);
    }, 150);
  };

  onBlur = () => {
    this.setState({ focused: false });
    this.props.onBlur();
  };

  checkDisabled = () => {
    const { disabled, meta } = this.props;

    if (disabled) {
      return true;
    }
    if (!meta.acl.write) {
      return true;
    }
    return false;
  };

  setValue = value => {
    this.setState({ value });
    this.onChange(value);
  };

  render() {
    const {
      intl: { messages },
      label,
      name,
      type,
      step,
      validation,
      infoText,
      prefixAddon,
      suffixAddon,
      autocomplete,
      min,
      max,
    } = this.props;
    const { value, focused, showPlain } = this.state;

    const input = (
      <input
        ref={this.inputRef}
        value={value || ''}
        type={showPlain ? 'text' : type}
        step={type === 'number' ? step : undefined}
        min={type === 'number' && min !== undefined ? min : undefined}
        max={type === 'number' && max !== undefined ? max : undefined}
        className="form-control"
        name={name}
        onChange={e => this.onChange(e.target.value)}
        onFocus={() => this.setState({ focused: true })}
        onBlur={this.onBlur}
        disabled={this.checkDisabled()}
        autoComplete={autocomplete}
      />
    );

    const strength = value && value.length ? passwordStrength(value) : {};

    return (
      <div
        className={
          'form-group form-group-default ' +
          this.checkFocused() +
          this.checkRequired() +
          this.checkError() +
          (this.checkDisabled() ? 'disabled ' : '')
        }
        onClick={this.catchFocus}
      >
        <label>
          {label}
          {!!infoText && (
            <span className="m-l-5">
              <OverlayTrigger placement="top" overlay={<Tooltip>{infoText}</Tooltip>}>
                <i className="far fa-question-circle cursor-help" />
              </OverlayTrigger>
            </span>
          )}
        </label>

        <span className="input-group">
          {!!prefixAddon && <span className="input-group-addon">{prefixAddon}</span>}
          {input}
          {!!suffixAddon && <span className="input-group-addon">{suffixAddon}</span>}
          <span className="input-group-addon bg-transparent">
            {showPlain ? (
              <a href="#hidePassword" className="text-primary" onClick={this.closePassword}>
                <i className="fas fa-eye-slash" />
              </a>
            ) : (
              <a href="showPassword" className="text-primary" onClick={this.showPassword}>
                <i className="fas fa-eye" />
              </a>
            )}
          </span>
        </span>

        {!validation.valid && (
          <Overlay show={this.state.focused} target={this.inputRef.current} placement="top">
            <Popover className="popover-modal" id={`error_${name}_${label}`}>
              {validation.helpText}
            </Popover>
          </Overlay>
        )}

        <AnimateHeight duration={500} height={value && focused ? 'auto' : 0}>
          <div className="password-strength-indicator">
            {strength.id === 0 && <Label bsStyle="danger">{messages.password_weak}</Label>}
            {strength.id === 1 && <Label bsStyle="warning">{messages.password_medium}</Label>}
            {strength.id === 2 && <Label bsStyle="success">{messages.password_strong}</Label>}
          </div>
        </AnimateHeight>
      </div>
    );
  }
}
FormInputPassword.defaultProps = {
  label: '',
  meta: { validate: { rules: '' }, acl: { read: true, write: true } },
  validation: { valid: true },
  value: '',
  onChange: () => {},
  disabled: false,
  type: 'password',
  step: 1,
  prefixAddon: '',
  suffixAddon: '',
  infoText: '',
  onBlur: () => {},
  autocomplete: null,
};

export default injectIntl(FormInputPassword);
