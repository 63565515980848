import axios, { CancelToken } from 'axios';

export const UPDATE_FORM_CONTEXT = 'UPDATE_FORM_CONTEXT';
export const RESET_FORM_CONTEXT = 'RESET_FORM_CONTEXT';
export const FORM_QUEUE = 'FORM_QUEUE';
export const FORM_FINISHED = 'FORM_FINISHED';
export const FORM_SUBMIT = 'FORM_SUBMIT';
export const FORM_ERROR = 'FORM_ERROR';
export const FORM_RESET = 'FORM_RESET';
export const FORM_RETRY = 'FORM_RETRY';

export const updateFormContext = (type, id) => {
  return {
    type: UPDATE_FORM_CONTEXT,
    payload: { type, id },
  };
};

export const resetFormContext = () => {
  return {
    type: RESET_FORM_CONTEXT,
    payload: null,
  };
};

export const formSend = (formName, formData) => {
  return {
    type: FORM_QUEUE,
    payload: formName,
  };
};

export const formSubmit = (url, data, finishedCallback, errorCallback, cancelToken, method = 'post') => {
  let fullUrl = url;
  if (fullUrl.indexOf('?') === -1) {
    fullUrl += '?';
  } else {
    fullUrl += '&';
  }

  fullUrl += '_locale=' + globalLocale;

  return {
    type: FORM_SUBMIT,
    payload: axios({
      method,
      url: fullUrl,
      data,
      cancelToken,
    })
      .then(finishedCallback)
      .catch(errorCallback),
  };
};

export const formFinished = (formName, response) => {
  return {
    type: FORM_FINISHED,
    payload: {
      formName,
      response,
    },
  };
};

export const formError = (formName, error) => {
  return {
    type: FORM_ERROR,
    payload: {
      formName,
      error,
    },
  };
};

export const formReset = formName => {
  return {
    type: FORM_RESET,
    payload: formName,
  };
};

export const formRetryCount = (formName, retryCount) => {
  return {
    type: FORM_RETRY,
    payload: {
      formName,
      retryCount,
    },
  };
};

export const formStatusDispatchHelper = (formName, url, data, method, dispatch, retryAttemptsMax) => {
  let retryAttempts = 0;
  retryAttemptsMax = retryAttemptsMax || 10;

  const source = new CancelToken.source();

  // add the request to queue
  dispatch(formSend(formName));

  // finished callback will trigger an event
  const finishedCallback = response => {
    return dispatch(formFinished(formName, response));
  };
  // error callback will trigger an event
  const errorCallback = error => {
    if ((!error.response || !error.response.status) && retryAttempts < retryAttemptsMax) {
      retryAttempts++;

      dispatch(formRetryCount(formName, retryAttempts));
      setTimeout(() => {
        dispatch(formSubmit(url, data, finishedCallback, errorCallback, source.token, method));
      }, retryAttempts * retryAttempts * 1000); // timeout exponential (max. 10*10 seconds)
    } else {
      return dispatch(formError(formName, error));
    }
  };
  // dispatch the request
  dispatch(formSubmit(url, data, finishedCallback, errorCallback, source.token, method));

  return source;
};
