export const SET_SUMMARY_FILTERS = 'SET_SUMMARY_FILTERS';
export const SET_SUMMARY_FILTER = 'SET_SUMMARY_FILTER';
export const REMOVE_SUMMARY_FILTER = 'REMOVE_SUMMARY_FILTER';
export const RESET_SUMMARY_FILTER = 'RESET_SUMMARY_FILTER';

export function setFilters(filters) {
  return {
    type: SET_SUMMARY_FILTERS,
    payload: { filters },
  };
}

export function setFilter(name, values) {
  return {
    type: SET_SUMMARY_FILTER,
    payload: { name, values },
  };
}

export function removeFilter(name, value) {
  return {
    type: REMOVE_SUMMARY_FILTER,
    payload: { name, value },
  };
}

export function resetFilter() {
  return {
    type: RESET_SUMMARY_FILTER,
    payload: null,
  };
}
