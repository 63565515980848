import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Table, Grid, Row, Col, Label, Alert } from 'react-bootstrap';
import { isArray as _isArray, forEach as _forEach, filter as _filter } from 'lodash';

import DocMeta from 'components/DocMeta';
import List from 'components/List';

import Breadcrumb from 'components/layout/Breadcrumb';
import Loader from 'components/layout/Loader';

import DocumentationEntityInfo from 'components/modals/DocumentationEntityInfo';

class ApiDocDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groupId: '',
      showInfo: false,
      infoEntityMetaIdentifier: null,
    };
  }

  componentDidUpdate = prevProps => {
    const { match, list } = this.props;

    if (prevProps.list.pending && !list.pending && !list.hasError) {
      if (list.data.count > 0) {
        this.setState({ groupId: match.params.groupId });
      } else {
        // the group has no detail entries - it must be an error
      }
    }
  };

  getTitle = () => {
    const { groupId } = this.state;
    return groupId.charAt(0).toUpperCase() + groupId.slice(1);
  };

  getBsStyle = apiCall => {
    let bsStyle = 'info';
    switch (apiCall.method) {
      case 'post':
        bsStyle = 'success';
        break;

      case 'put':
        bsStyle = 'warning';
        break;

      case 'delete':
        bsStyle = 'danger';
        break;
    }

    return bsStyle;
  };

  getMethodLabel = apiCall => {
    return (
      <Label bsStyle={this.getBsStyle(apiCall)} className="m-r-10">
        {apiCall.method.toUpperCase()}
      </Label>
    );
  };

  renderInput = apiCall => {
    if (apiCall.parameters && _isArray(apiCall.parameters)) {
      const parameters = _filter(apiCall.parameters, ['in', 'body']);

      if (parameters && parameters.length) {
        const fields = parameters.map(parameter => {
          return (
            <tr key={parameter.name}>
              <td className="v-align-middle bold">{parameter.name}</td>
              <td className="v-align-middle">{parameter.schema.type}</td>
              <td className="v-align-middle" />
            </tr>
          );
        });

        return (
          <div>
            <h5 className="m-b-0 p-l-15">Input</h5>
            <Table striped condensed responsive className="">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Validation</th>
                </tr>
              </thead>
              <tbody>{fields}</tbody>
            </Table>
          </div>
        );
      }
    }
  };

  showInfo = (e, infoEntityMetaIdentifier) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      showInfo: true,
      infoEntityMetaIdentifier,
    });
  };

  closeInfo = () => {
    this.setState({ showInfo: false });
  };

  render() {
    const { appIntl, match, list } = this.props;
    const { groupId, showInfo, infoEntityMetaIdentifier } = this.state;

    const tags = [
      //      {name: 'title', content: messages.meta.api_doc.title},
      //      {name: 'description', content: messages.meta.api_doc.description},
      //      {itemProp: 'name', content: messages.meta.api_doc.name},
      //      {itemProp: 'description', content: messages.meta.api_doc.description},
      //      {itemProp: 'image', content: "http://www.example.com/image.jpg"}
    ];

    return (
      <DocMeta tags={tags}>
        <div className="content">
          <List
            listName="apiDocGroupDetails"
            url={'/api/documentation/groups/' + match.params.groupId + '.json'}
            list={list}
            noRender
          />

          {!list.pending ? (
            <div className="padding-25 sm-padding-10">
              <Breadcrumb
                crumbs={[
                  { link: appIntl.urlPrefix, name: 'Creacheck' },
                  { link: appIntl.urlPrefix + 'docs', name: 'Documentation' },
                  { link: null, name: groupId },
                ]}
              />

              <Grid fluid>
                <Row>
                  <Col md={3} className="bg-master-lighter p-b-25">
                    <h1>Methods</h1>

                    {list.data.list.map((apiCall, i) => {
                      const methodUrl = apiCall.path + '.json';

                      return (
                        <div key={apiCall.method + apiCall.path} className="m-b-5">
                          <a href={'#call' + i} title={methodUrl} class="block overflow-ellipsis">
                            {this.getMethodLabel(apiCall)} {methodUrl}
                          </a>
                        </div>
                      );
                    })}
                  </Col>
                  <Col md={9}>
                    <h1>{this.getTitle()}</h1>

                    {list.data.list.map((apiCall, i) => {
                      const bsStyle = this.getBsStyle(apiCall);
                      const filters = [];
                      _forEach(
                        _filter(apiCall.parameters, parameterData => ['query', 'path'].indexOf(parameterData.in) > -1),
                        parameterData => {
                          filters.push(
                            <tr key={parameterData.name}>
                              <td className="v-align-middle bold">{parameterData.name}</td>
                              <td className="v-align-middle">{parameterData.description}</td>
                              {/* <td class="v-align-middle">{parameterData.requirement}</td> */}
                              <td className="v-align-middle">{parameterData.default ? parameterData.default : '-'}</td>
                            </tr>
                          );
                        }
                      );

                      const responseCodes = [];
                      _forEach(apiCall.responses, (responseData, responseCode) => {
                        responseCodes.push(
                          <tr key={responseCode}>
                            <td className="v-align-middle bold">{responseCode}</td>
                            <td className="v-align-middle">{responseData.description}</td>
                          </tr>
                        );
                      });

                      return (
                        <div id={'call' + i} key={'call' + apiCall.method + apiCall.path} className="p-b-30">
                          <Alert bsStyle={bsStyle} className="m-b-5">
                            {this.getMethodLabel(apiCall)}
                            {apiCall.path}.json
                          </Alert>
                          <p className="p-l-15 p-r-15">{apiCall.description}</p>

                          {!!filters.length && (
                            <div>
                              <h5 className="m-b-0 p-l-15">Filters</h5>
                              <Table striped condensed responsive className="">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Description</th>
                                    {/* <th>Requirement</th> */}
                                    <th>Default</th>
                                  </tr>
                                </thead>
                                <tbody>{filters}</tbody>
                              </Table>
                            </div>
                          )}

                          {!!responseCodes.length && (
                            <React.Fragment>
                              <h5 className="m-b-0 p-l-15">Status codes</h5>
                              <Table striped condensed responsive className="">
                                <thead>
                                  <tr>
                                    <th>Code</th>
                                    <th>Description</th>
                                  </tr>
                                </thead>
                                <tbody>{responseCodes}</tbody>
                              </Table>
                            </React.Fragment>
                          )}

                          {this.renderInput(apiCall)}
                        </div>
                      );
                    })}
                  </Col>
                </Row>
              </Grid>
            </div>
          ) : (
            <Loader />
          )}

          <DocumentationEntityInfo
            show={showInfo}
            close={this.closeInfo}
            entityMetaIdentifier={infoEntityMetaIdentifier}
          />
        </div>
      </DocMeta>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    list: state.list.apiDocGroupDetails,
  };
};

export default injectIntl(connect(mapStateToProps)(ApiDocDetails));
