import React from 'react';
import { Grid } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { Flipped } from 'react-flip-toolkit';

import AbstractCmsElement from './AbstractCmsElement';

export default class CmsElementGrid extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      ...this.state,
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  render() {
    const { useFlipped, index } = this.props;
    const { shown } = this.state;

    const isFluid = this.getParamValue('isFluid', 'yes') === 'yes';
    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-grid ' + shownClass + this.getResponsiveClasses()}
        style={{
          ...this.getBackgroundStyle(),
        }}
      >
        {this.getElementStyle()}

        <Grid class={this.getPaddingClasses() + this.getCssClasses()} fluid={isFluid}>
          {useFlipped ? (
            <Flipped flipId={index}>
              <div>{this.props.children}</div>
            </Flipped>
          ) : (
            this.props.children
          )}
        </Grid>

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementGrid.defaultProps = {
  index: '0',
};
