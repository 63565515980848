import React from 'react';
import { components } from 'react-select';

class DocTemplateOption extends React.Component {
  render() {
    const { data } = this.props;
    return <components.Option {...this.props}>{data.name}</components.Option>;
  }
}

class DocTemplateValue extends React.Component {
  render() {
    const { data } = this.props;

    return <div className="Select-value">{data.name}</div>;
  }
}

const docTemplateOptionFormatter = options => {
  return options.list.map(option => {
    return docTemplateSingleOptionFormatter(option);
  });
};

const docTemplateSingleOptionFormatter = (option = null) => {
  if (option.id) {
    return { ...option, value: option.id, label: option.name };
  }

  return null;
};

const docTemplateOptionFromEntity = docTemplate => {
  return !docTemplate
    ? null
    : docTemplateSingleOptionFormatter({
        id: docTemplate.getId(),
        name: docTemplate.getName(),
      });
};

export {
  DocTemplateValue,
  DocTemplateOption,
  docTemplateOptionFormatter,
  docTemplateSingleOptionFormatter,
  docTemplateOptionFromEntity,
};
