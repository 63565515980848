import React from 'react';
import { components } from 'react-select';

class CompanyLocationOption extends React.Component {
  render() {
    const { data } = this.props;
    return <components.Option {...this.props}>{data.label}</components.Option>;
  }
}

class CompanyLocationValue extends React.Component {
  render() {
    const { data } = this.props;

    return <div className="Select-value">{data.label}</div>;
  }
}

const companyLocationOptionFormatter = options => {
  return options.list.map(option => {
    return companyLocationSingleOptionFormatter(option);
  });
};

const companyLocationSingleOptionFormatter = option => {
  return {
    ...option,
    value: option.id,
    label: option.name,
  };
};

const companyLocationOptionFromEntity = companyLocation => {
  return !companyLocation
    ? null
    : companyLocationSingleOptionFormatter({
        id: companyLocation.getId(),
        name: companyLocation.getName(),
      });
};

export {
  CompanyLocationOption,
  CompanyLocationValue,
  companyLocationOptionFormatter,
  companyLocationSingleOptionFormatter,
  companyLocationOptionFromEntity,
};
