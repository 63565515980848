import React from 'react';
import { connect } from 'react-redux';
import { Form, Button, Alert } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';
import { Element, scroller } from 'react-scroll';
import Plx from 'react-plx';
import { isPlainObject as _isPlainObject, clone as _clone } from 'lodash';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import { formStatusDispatchHelper, formReset } from 'actions/FormStatusActions';

import FormErrors from 'components/forms/FormErrors';
import FormInput from 'components/forms/FormInput';
import FormTextarea from 'components/forms/FormTextarea';

import RichEditorContent from 'components/RichEditorContent';
import Data from 'components/Data';
import Entity from 'components/Entity';

import LegalTextHelper from 'components/layout/LegalTextHelper';

import ModalPrivacyPolicy from 'components/modals/ModalPrivacyPolicy';

import { entityMetaMapping } from 'js/constants';

import AbstractCmsElement from './AbstractCmsElement';

// make sure each instance gets a new index
let cmsElementContactIndex = 1;
class CmsElementContact extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      ...this.state,
      id: cmsElementContactIndex++,
      entityLoaded: false,
      entityChanged: false,
      shown: false,
      showPrivacyPolicy: false,
      submitted: false,
      showSuccess: false,
    };

    this.entity = new Entity();
    this.entity.setData({ email: props.email, name: props.name });

    if (
      props.meta[entityMetaMapping.cmsContactForm] !== undefined &&
      !props.meta[entityMetaMapping.cmsContactForm].pending
    ) {
      this.entity.setMeta(props.meta, entityMetaMapping.cmsContactForm);

      this.state = {
        ...this.state,
        entityLoaded: true,
        entity: this.entity,
      };
    }
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { meta, formStatus } = this.props;
    const { id, submitted } = this.state;

    if (
      meta[entityMetaMapping.cmsContactForm] !== undefined &&
      !meta[entityMetaMapping.cmsContactForm].pending &&
      !this.entity.getMetaLoaded()
    ) {
      this.entity.setMeta(meta, entityMetaMapping.cmsContactForm);
      this.setState({ entityLoaded: true, entity: this.entity });
    }

    if (prevProps.formStatus.pending && !formStatus.pending && !formStatus.hasError && submitted) {
      this.setState({ showSuccess: true }, () => {
        scroller.scrollTo('contact-container-' + id, {
          offset: -100,
        });
      });

      // send the information to Google
      ReactGA.event({
        category: window.location.href,
        action: 'Form_Button_Click',
        label: 'contact',
        value: 1,
      });

      // send the information to Facebook
      ReactPixel.trackCustom('Form_Button_Click', {
        sourceURL: window.location.href,
        formName: 'contact',
      });
    }

    this.__componentDidUpdate(prevProps, prevState);
  };

  componentWillUnmount = () => {
    this.props.resetForm();
    this.__componentWillUnmount();
  };

  showPrivacyPolicy = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showPrivacyPolicy: true });
  };

  closePrivacyPolicy = () => {
    this.setState({ showPrivacyPolicy: false });
  };

  getPrivacyPolicyText = () => {
    const {
      intl: { messages },
    } = this.props;

    const text = messages.contact_agreement_text;
    const substitutes = {
      '{data_use}': (
        <a href="#dataUse" onClick={this.showPrivacyPolicy}>
          {messages.signup_agreement_text_data_use}
        </a>
      ),
    };

    return <LegalTextHelper text={text} substitutes={substitutes} />;
  };

  changeEntity = (entity, callback = null) => {
    this.setState(
      prevState => ({
        [this.getEntityName()]: entity,
        [this.getEntityName() + 'Changed']: true,
        key: prevState.key + 1,
      }),
      callback
    );
  };

  updateEntity = (propertyName, value, fieldTree = [], callback = null) => {
    const entity = this.state[this.getEntityName()];

    if (_isPlainObject(value)) {
      value = entity.createValue(propertyName, _clone(fieldTree), value);
    }

    const newEntity = { ...entity.updateValue(propertyName, value, fieldTree) };

    this.changeEntity(newEntity, callback);
  };

  getEntityName = () => {
    return this.entityName !== undefined ? this.entityName : 'entity';
  };

  onSubmit = e => {
    e.preventDefault();
    e.stopPropagation(); // if we are editing a page, we have to make sure the parent form will not be submitted

    this.setState(
      prevState => ({ entity: prevState.entity.validate() }),
      () => {
        if (this.state.entity.isValid) {
          const data = { ...this.state.entity.processedData };
          if (this.props.pipeline) {
            data.pipeline = { id: this.props.pipeline };
          }
          if (this.props.item) {
            data.item = { id: this.props.item };
          }
          data.contexts = this.props.formContext ? [this.props.formContext] : [];

          this.setState({ submitted: true }, () => this.props.onSubmit(data));
        }
      }
    );
  };

  render() {
    const {
      intl: { messages },
      formStatus,
    } = this.props;
    const { id, entityLoaded, entity, shown, showPrivacyPolicy, showSuccess } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const textClass = 'text-' + this.getParamValue('mainColor', 'master');
    const disabled = formStatus.pending;

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        className={'ms-contact ' + shownClass + this.getResponsiveClasses() + this.getCssClasses()}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <Data dataName="cmsContactForm" />

        <Element name={'contact-container-' + id}>
          {entityLoaded && showSuccess && <Alert bsStyle="success">{messages.check_your_email_to_confirm}</Alert>}

          {entityLoaded && !showSuccess && (
            <React.Fragment>
              <FormErrors formStatus={formStatus} />

              <div
                className={'ms-contact-container text-' + this.getParamValue('textAlign', 'center') + ' ' + textClass}
              >
                <h2 className="no-margin p-b-25">{this.getParamValue('title')}</h2>
                <RichEditorContent content={'<p>' + this.getParamValue('content', '') + '</p>'} />
              </div>

              <Form className="m-t-15" onSubmit={this.onSubmit}>
                <div className="form-group-attached m-b-15">
                  <FormInput
                    label={messages.email}
                    name="email"
                    type="email"
                    value={entity.getEmail()}
                    meta={entity.getEmailMetaInformation()}
                    validation={entity.getEmailValidationResult()}
                    onChange={this.updateEntity}
                    disabled={disabled}
                  />
                  <FormInput
                    label={messages.client_name}
                    name="name"
                    value={entity.getName()}
                    meta={entity.getNameMetaInformation()}
                    validation={entity.getNameValidationResult()}
                    onChange={this.updateEntity}
                    disabled={disabled}
                  />
                  <FormTextarea
                    label={messages.message}
                    name="content"
                    value={entity.getContent()}
                    meta={entity.getContentMetaInformation()}
                    validation={entity.getContentValidationResult()}
                    onChange={this.updateEntity}
                    disabled={disabled}
                  />
                </div>

                <p className={textClass + ' small'}>{this.getPrivacyPolicyText()}</p>
                <div className="text-right">
                  <Button
                    type="submit"
                    className="call-to-action btn-animated from-left fa fa-arrow-right"
                    bsStyle="primary"
                    disabled={disabled}
                  >
                    <span>{messages.send}</span>
                  </Button>
                </div>
              </Form>
            </React.Fragment>
          )}

          {this.getAppendHtml()}
        </Element>

        <ModalPrivacyPolicy show={showPrivacyPolicy} close={this.closePrivacyPolicy} />
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementContact.defaultProps = {
  index: '0',
};

const mapStateToProps = state => {
  return {
    formStatus: state.formStatus.contactNew,
    meta: state.entityMeta,
    formContext: state.formStatus.context,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSubmit: args => {
      formStatusDispatchHelper('contactNew', '/api/contact.json', args, 'post', dispatch);
    },
    resetForm: () => {
      formReset('contactNew');
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CmsElementContact);
