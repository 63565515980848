import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import { intlModule } from '@chedri/base';
import Modal from 'components/Modal';

export default function ModalPaypalButtons({ show, onHide, children }) {
  const messages = useSelector(intlModule.selectors.getMessages);
  const cart = useSelector(({ cart }) => cart);
  const { sum_gross: sumGross, currency } = cart;

  const wrapperStyle = {
    maxWidth: '500px',
    margin: '30px auto 30px',
  };

  return (
    <Modal show={show} onHide={onHide} bsSize="lg" className="full-width">
      <Modal.Header closeButton>
        <Modal.Title>{messages.meta.paypal.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {messages.salesDoc.total}:
          <FormattedNumber value={sumGross} style="currency" currency={currency} />
        </p>
        <div style={wrapperStyle}>{children}</div>
      </Modal.Body>
    </Modal>
  );
}
