import React from 'react';
import { injectIntl } from 'react-intl';
import { Clearfix, Col } from 'react-bootstrap';

class StepFCarEscrowPayment extends React.Component {
  render() {
    const { intl } = this.props;
    const messages = intl.messages;

    return (
      <Clearfix>
        <Col xs={12} md={7} class="no-padding">
          <p>
            After the sales contract is done, you will receive information how to transfer the money to an escrow
            account.
            <br />
            Then we will initialize other processes, like car insurance, registration and transport.
            <br />
            After you receive the car, and you confirm that everything is alright, the payment will be released to the
            car salesman.
          </p>
        </Col>
        <Col xs={12} md={5} class="p-l-20 p-r-0 sm-p-r-0 sm-m-t-15">
          <p>
            Treuhandkonto
            <br />
            Rechtsanwalt Dr. Johannes Bardens
            <br />
            Kanalstraße 5<br />
            67655 Kaiserslautern
            <br />
            <br />
            Vertragsart: Finanzierungsvertrag
            <br />
          </p>
        </Col>
      </Clearfix>
    );
  }
}

export default injectIntl(StepFCarEscrowPayment);
