import React from 'react';
import langs from 'langs';
import { get as _get } from 'lodash';
import { getNameInCurrentLanguage } from 'helpers/intl';
import { isPluginActive } from 'helpers/whiteLabel';
import { ROLE_ROOT_EMPLOYEE, CHEDRI_COMMS_CENTER_PLUGIN, ROLE_CMS_PAGES, ROLE_ERP_ITEM } from 'js/constants';
import Sidebar from 'components/layout/Sidebar';
import may from 'js/security';

export const getNavInfos = (appIntl, layout, projectConfig, user, tasksData, leadsData) => {
  const topNavLinks = [];
  const secondaryTopNavLinks = [];
  const languages = getLanguages(projectConfig);

  const menuElements = _get(projectConfig, 'data.menu.elements');
  const secondaryMenuElements = _get(projectConfig, 'data.menu.secondary_elements');

  if (menuElements && menuElements.length) {
    for (let i = 0; i < menuElements.length; i++) {
      const processedMenuElement = processMenuElement(menuElements[i], appIntl, user);
      if (processedMenuElement) {
        topNavLinks.push(processedMenuElement);
      }
    }
  }

  if (window.isImpersonating) {
    const {
      messages: { messages },
    } = appIntl;

    topNavLinks.push({
      id: '_exit_impersonating',
      link: '/de/account?_switch_user=_exit',
      icon: <i className="fas fa-theater-masks" />,
      text: messages.cancel_impersonation,
      hasDropdown: false,
      dropdownWidth: 100,
      dropdownMenuElements: [],
      listClassName: '',
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        window.location.href = '/de/account?_switch_user=_exit';
      },
      className: '',
    });
  }

  if (secondaryMenuElements && secondaryMenuElements.length) {
    for (let i = 0; i < secondaryMenuElements.length; i++) {
      const processedMenuElement = processMenuElement(secondaryMenuElements[i], appIntl, user);
      if (processedMenuElement) {
        secondaryTopNavLinks.push(processedMenuElement);
      }
    }
  }

  return {
    appIntl,
    projectConfig,
    user,
    topNavLinks,
    secondaryTopNavLinks,
    languages,
    tasksData,
    leadsData,
  };
};

const processMenuElement = (element, appIntl, user) => {
  const {
    id,
    only_when_logged_in: showOnlyWhenUserIsLoggedIn,
    hidden_desktop: hiddenDesktop,
    hidden_tablet: hiddenTablet,
    hidden_phone: hiddenPhone,
    link,
    name,
    icon_class: iconClass,
    has_content: hasContent,
    content,
    list_css_classes: defaultListCssClasses,
    link_css_classes: className,
    dropdown_width: dropdownWidth,
  } = element;

  if ((user.logged && showOnlyWhenUserIsLoggedIn) || !showOnlyWhenUserIsLoggedIn) {
    let listClassName = (defaultListCssClasses || '') + ' ';

    if (hiddenDesktop) {
      listClassName += 'hidden-lg hidden-md';
    }
    if (hiddenTablet) {
      listClassName += ' hidden-sm ';
    }
    if (hiddenPhone) {
      listClassName += ' hidden-xs ';
    }

    return {
      id,
      link: getNameInCurrentLanguage(link, appIntl),
      icon: (!!iconClass && <i className={iconClass} />) || '',
      text: getNameInCurrentLanguage(name, appIntl),
      hasDropdown: !!hasContent,
      dropdownWidth: dropdownWidth || 100,
      dropdownMenuElements: content || [],
      listClassName,
      className,
    };
  }

  return null;
};

const getLanguages = projectConfig => {
  const languages = _get(projectConfig, 'data.languages');
  if (languages && languages.length) {
    const res = [];

    for (let i = 0; i < languages.length; i++) {
      res.push({
        label: langs.where('1', languages[i]).local,
        value: languages[i],
      });
    }

    return res;
  }

  return null;
};

export const getSidebar = (layout, navInfos, sidebarVisible, onShowSidebar, onHideSidebar, data = {}) => {
  const { appIntl, user, projectConfig, topNavLinks, languages, tasksData, leadsData } = navInfos;
  const {
    messages: { messages },
    urlPrefix,
  } = appIntl;
  const isEmployee = may(ROLE_ROOT_EMPLOYEE, user.roles);

  let sidebar = null;
  const sidebarLinks = [];

  if (user.logged && isEmployee) {
    sidebarLinks.push({
      link: urlPrefix + 'account',
      icon: <i className="fa fa-tachometer-alt" />,
      text: 'Dashboard',
    });

    sidebarLinks.push({
      link: urlPrefix + 'account/clients',
      icon: <i className="fa fa-male" />,
      text: messages.clients,
    });

    if (isPluginActive(CHEDRI_COMMS_CENTER_PLUGIN, projectConfig.data)) {
      sidebarLinks.push({
        link: urlPrefix + 'account/communication',
        icon: <i className={'far fa-user-headset ' + (data.isChatterAgent ? 'text-success' : '')} />,
        text: 'Comms center',
      });
    }

    sidebarLinks.push({
      link: urlPrefix + 'account/leads',
      icon:
        leadsData?.count > 0 ? <span className="sidebarLeads">{leadsData.count}</span> : <i className="fa fa-gem" />,
      text: messages.leads,
    });
    sidebarLinks.push({
      link: urlPrefix + 'account/tasks',
      icon:
        tasksData?.count > 0 ? <span className="sidebarTasks">{tasksData.count}</span> : <i className="far fa-tasks" />,
      text: messages.tasks,
    });

    if (may(ROLE_ERP_ITEM, user.roles)) {
      sidebarLinks.push({
        link: urlPrefix + 'account/items',
        icon: <i className="fa fa-cube" />,
        text: messages.products,
      });
    }

    sidebarLinks.push({
      link: urlPrefix + 'account/calendar',
      icon: <i className="fa fa-calendar" />,
      text: messages.calendar,
    });

    if (may(ROLE_CMS_PAGES, user.roles)) {
      sidebarLinks.push({
        link: urlPrefix + 'account/cms',
        icon: <i className="fa fa-desktop" />,
        text: messages.content,
      });
    }

    sidebarLinks.push({
      link: urlPrefix + 'account/orders',
      icon: <i className="fa fa-shopping-bag" />,
      text: messages.orders,
    });

    sidebarLinks.push({
      link: urlPrefix + 'account/contact-management',
      icon: <i className="fa fa-address-book" />,
      text: messages.addresses,
    });

    sidebar = (
      <Sidebar
        layout={layout}
        elements={sidebarLinks}
        topNavLinks={layout.breakpointIndex <= 1 ? topNavLinks : []}
        languageNav={layout.breakpointIndex <= 1 ? languages : []}
        handleMouseEnter={onShowSidebar}
        handleMouseLeave={onHideSidebar}
        visible={sidebarVisible}
      />
    );
  } else if (layout.breakpointIndex <= 1) {
    sidebar = (
      <Sidebar
        layout={layout}
        elements={[]}
        topNavLinks={topNavLinks}
        languageNav={languages}
        handleMouseEnter={onShowSidebar}
        handleMouseLeave={onHideSidebar}
        visible={sidebarVisible}
      />
    );
  }

  return sidebar;
};
