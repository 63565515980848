import React from 'react';
import { components } from 'react-select';
import _, { forEach as _forEach } from 'lodash';
import { getNameInCurrentLanguage } from 'helpers/intl';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    categoriesSettings: state.templateSettings.categoriesSettings,
  };
};

class ItemOptionWithProps extends React.Component {
  render() {
    const { data, appIntl, categoriesSettings } = this.props;

    let categoryName = '';
    if (typeof data.category_path !== undefined && typeof data.category_path === 'string') {
      const path = data.category_path.split(',');
      _forEach(path, (singlePath, i) => {
        if (singlePath !== '' && singlePath !== '0') {
          const index = _.findIndex(categoriesSettings?.list, category => category.id === singlePath);
          if (index !== -1) {
            categoryName =
              categoryName + getNameInCurrentLanguage(categoriesSettings?.list[index].name, appIntl) + ' > ';
          }
        }
      });
    }

    const attributes = [];
    _forEach(data.attributes, function(attribute, index) {
      attributes.push(index + 1 + '. ' + attribute?.name[0]?.name + ': ' + attribute.values[0]?.value[0]?.name + ', ');
    });
    let attributesAsString = '';
    _forEach(attributes, function(attribute) {
      attributesAsString = attributesAsString.concat(attribute + ' ');
    });
    let itemName = '';
    typeof data?.name === 'object'
      ? (itemName = getNameInCurrentLanguage(data?.name, appIntl))
      : (itemName = data?.name);
    return (
      <components.Option {...this.props}>
        <p className="categoryPathName">{categoryName}</p>
        <span className="productHeader">{itemName}</span>
        <p>{attributesAsString}</p>
      </components.Option>
    );
  }
}

const ItemOption = connect(mapStateToProps)(ItemOptionWithProps);

class ItemValueWithProps extends React.Component {
  render() {
    const { data, appIntl } = this.props;
    const attributes = [];
    _forEach(data.attributes, function(attribute, index) {
      attributes.push(index + 1 + '. ' + attribute?.name[0]?.name + ': ' + attribute?.values[0]?.value[0]?.name + ', ');
    });
    let attributesAsString = '';
    _forEach(attributes, function(attribute) {
      attributesAsString = attributesAsString.concat(attribute + ' ');
    });
    let itemName = '';
    typeof data?.name === 'object'
      ? (itemName = getNameInCurrentLanguage(data?.name, appIntl))
      : (itemName = data?.name);
    return (
      <div className="Select-value">
        <span className="productHeader">{itemName}</span>
        <p>{attributesAsString}</p>
      </div>
    );
  }
}
const ItemValue = connect(mapStateToProps)(ItemValueWithProps);

const itemOptionFormatter = options => {
  return options.list.map(option => {
    return itemSingleOptionFormatter(option);
  });
};

const itemSingleOptionFormatter = (option = null) => {
  let label = '';
  _forEach(option.name, langName => {
    if (langName.name !== undefined) {
      label += langName.name + ' ';
    }
  });
  return { ...option, value: option.id, label };
};

const itemOptionFromEntity = item => {
  return !item
    ? null
    : itemSingleOptionFormatter({
        id: item.getId(),
        name: item.getName().map(langName => {
          return { lang: langName.getLang(), name: langName.getName() };
        }),
      });
};

export { ItemValue, ItemOption, itemOptionFormatter, itemSingleOptionFormatter, itemOptionFromEntity };
