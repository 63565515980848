import React from 'react';
import { connect } from 'react-redux';
import Modal from 'components/Modal';

import { injectIntl } from 'react-intl';

import Loader from 'components/layout/Loader';

class ModalOrderInProgress extends React.Component {
  render() {
    const {
      intl: { messages },
      show,
      layout,
      type,
    } = this.props;

    let text = messages.we_are_processing_your_order_please_wait;
    switch (type) {
      case 'booking': {
        text = messages.we_are_processing_your_booking_please_wait;
        break;
      }
    }

    return (
      <Modal show={show} bsSize="lg" class="full-width">
        <Modal.Body class="no-padding">
          <div style={{ height: layout.height - 50 + 'px' }}>
            <div class="themed relative" style={{ top: '50%', transform: 'translateY(-50%)' }}>
              <Loader />
              <div class="text-center hint-text small m-t-20">{text}</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout,
  };
};

export default injectIntl(connect(mapStateToProps)(ModalOrderInProgress));
