import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedNumber } from 'react-intl';
import { Row, Col } from 'react-bootstrap';

class CheckoutTotal extends React.Component {
  // calculate how long the spacer is
  getSpacer = () => {
    const { checkout } = this.props;
    let spacer = 8;

    if (checkout.discount > 0) {
      spacer -= 3;
    }

    if (this.isServicesActive()) {
      spacer -= 3;
    }

    return spacer;
  };

  // check if any of the services we offer is active
  isServicesActive = () => {
    const { checkout } = this.props;

    if (checkout.shipping !== null && checkout.shipping.cost > 0) {
      return true;
    }

    for (let i = 0; i < checkout.services.length; i++) {
      if (checkout.services[i].isActive && checkout.services[i].cost > 0) {
        return true;
      }
    }

    return false;
  };

  // get the total of all services the user activated
  getServicesTotal = () => {
    const { checkout } = this.props;
    let total = 0;

    if (checkout.shipping !== null && checkout.shipping.cost > 0) {
      total += checkout.shipping.cost;
    }

    for (let i = 0; i < checkout.services.length; i++) {
      if (checkout.services[i].isActive && checkout.services[i].cost > 0) {
        total += checkout.services[i].cost;
      }
    }

    return total;
  };

  // get a list of services the user activated
  getServicesList = () => {
    const {
      intl: { messages },
      checkout,
      isCarCheckout,
    } = this.props;
    const res = [];

    if (checkout.shipping !== null && checkout.shipping.cost > 0) {
      res.push(isCarCheckout ? messages.car_transport : messages.delivery);
    }

    for (let i = 0; i < checkout.services.length; i++) {
      if (checkout.services[i].isActive && checkout.services[i].cost > 0) {
        switch (checkout.services[i].name) {
          case 'carInsurance':
            res.push(messages.car_insurance);
            break;

          case 'carRegistration':
            res.push(messages.car_registration);
            break;
        }
      }
    }

    return res;
  };

  render() {
    const {
      intl: { messages },
      checkout,
      projectConfig,
    } = this.props;

    return (
      <Row class="b-a b-grey no-margin bg-white">
        {checkout.discount > 0 && (
          <Col md={3} class="p-l-10 padding-15">
            <h5 class="font-secondary all-caps small no-margin hint-text bold">{messages.online_discount}</h5>
            <p class="no-margin">
              <FormattedNumber value={-1 * checkout.discount} style="currency" currency={projectConfig.data.currency} />
            </p>
          </Col>
        )}

        {this.isServicesActive() && (
          <Col md={3} class="p-l-10 padding-15">
            <h5 class="font-secondary all-caps small no-margin hint-text bold">{messages.services}</h5>
            <p class="no-margin">
              <FormattedNumber
                value={this.getServicesTotal()}
                style="currency"
                currency={projectConfig.data.currency}
              />
            </p>
          </Col>
        )}

        <Col md={this.getSpacer()} />

        <Col md={4} class="text-right bg-primary padding-10">
          <h5 class="font-secondary all-caps small no-margin hint-text text-white bold">{messages.salesDoc.total}</h5>
          <div className="hint-text text-white small">(alle Preise inkl. USt.)</div>
          <h4 class="no-margin text-white">
            <FormattedNumber value={checkout.total} style="currency" currency={projectConfig.data.currency} />
          </h4>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    projectConfig: state.data.projectConfig,
  };
};

export default injectIntl(connect(mapStateToProps)(CheckoutTotal));
