import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import generateId from 'helpers/id';

import Icon from './Icon';

function IconButton({
  type,
  name,
  disabled,
  duotone,
  solid,
  light,
  iconStyles,
  iconClassName,
  buttonStyles,
  buttonClassName,
  tooltip,
  tooltipPlacement,
  label,
  layers,
  onClick,
}) {
  const [tooltipId] = useState(generateId('icon-button-'));
  const button = (
    // eslint-disable-next-line react/button-has-type
    <button type={type} onClick={onClick} className={`${buttonClassName}`} style={buttonStyles} disabled={disabled}>
      <Icon
        name={name}
        duotone={duotone}
        solid={solid}
        light={light}
        iconStyles={iconStyles}
        iconClassName={iconClassName}
        label={label}
        layers={layers}
      />
    </button>
  );
  if (tooltip) {
    return (
      <OverlayTrigger placement={tooltipPlacement} overlay={<Tooltip id={tooltipId}>{tooltip}</Tooltip>}>
        {button}
      </OverlayTrigger>
    );
  }
  return button;
}
IconButton.defaultProps = {
  type: 'button',
  disabled: false,
  duotone: false,
  solid: false,
  light: false,
  iconStyles: {},
  iconClassName: '',
  buttonStyles: {},
  buttonClassName: '',
  tooltip: null,
  tooltipPlacement: undefined,
  label: null,
  layers: undefined,
  onClick: () => undefined,
};
IconButton.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  duotone: PropTypes.bool,
  solid: PropTypes.bool,
  light: PropTypes.bool,
  iconStyles: PropTypes.object,
  iconClassName: PropTypes.string,
  buttonStyles: PropTypes.object,
  buttonClassName: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  label: PropTypes.string,
  layers: PropTypes.arrayOf(PropTypes.element),
  onClick: PropTypes.func,
};

export default IconButton;
