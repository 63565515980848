import React from 'react';
import { findDOMNode } from 'react-dom';
import { Grid, Clearfix, Button, Popover } from 'react-bootstrap';
import { DragSource, DropTarget } from 'react-dnd';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';
import CmsNewRow from 'components/cms/CmsNewRow';
import SimplePrompt from 'components/layout/SimplePrompt';

import OverlayTrigger from 'components/OverlayTrigger';

const gridSource = {
  beginDrag(props) {
    return {
      index: props.index,
    };
  },
};

const gridTarget = {
  hover(props, monitor, component) {
    if (monitor.getItemType() === 'row' && props.children === null) {
      // accept rows when the grid is empty
      const dragIndex = monitor.getItem().index.toString();
      const hoverIndex = props.index.toString();

      props.reorderElements(dragIndex, hoverIndex + '.0', 'before', false);
      monitor.getItem().index = hoverIndex + '.0';
    } else if (monitor.getItemType() === 'grid') {
      const dragIndex = monitor.getItem().index.toString();
      const hoverIndex = props.index.toString();

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // compare if it is the same container
      let dragContainer = dragIndex.substring(0, dragIndex.lastIndexOf('.'));
      dragContainer = dragContainer !== '' ? dragContainer + '.' : '';
      let hoverContainer = hoverIndex.substring(0, hoverIndex.lastIndexOf('.'));
      hoverContainer = hoverContainer !== '' ? hoverContainer + '.' : '';

      let direction,
        newIndex,
        sameContainer = dragContainer === hoverContainer;
      const dragContainerIndex = dragIndex.substring(dragIndex.lastIndexOf('.')).replace('.', '');
      const hoverContainerIndex = hoverIndex.substring(hoverIndex.lastIndexOf('.')).replace('.', '');

      // drop after or before?
      if (hoverClientY < hoverMiddleY) {
        direction = 'before';
      } else {
        direction = 'after';
      }

      // calculate new index
      if ((sameContainer && dragContainerIndex > hoverContainerIndex) || !sameContainer) {
        if (direction === 'after') {
          newIndex = hoverContainer + (parseInt(hoverContainerIndex) + 1);
        } else {
          newIndex = hoverContainer + hoverContainerIndex;
        }
      } else {
        if (direction === 'after') {
          newIndex = hoverContainer + hoverContainerIndex;
        } else {
          newIndex = hoverContainer + (parseInt(hoverContainerIndex) - 1 < 0 ? 0 : parseInt(hoverContainerIndex) - 1);
        }
      }

      if (newIndex !== dragIndex) {
        props.reorderElements(dragIndex, hoverIndex, direction, sameContainer);
        monitor.getItem().index = newIndex;
      }
    }
  },
};

@DropTarget(['grid', 'row'], gridTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('grid', gridSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
// editable
export default class CmsElementGrid extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      mouseOver: false,
      showNew: false,
      shown: false,
    };

    this.mouseMoveBlock = false;
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  componentDidMount = () => {
    const isFluid = this.getParamValue('isFluid', 'yes') === 'yes' ? true : false;

    const img = new Image();
    img.onload = () => this.props.connectDragPreview(img);
    if (isFluid) {
      img.src =
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAAAUCAYAAADIpHLKAAAAoUlEQVRoge3boQ0CQRRF0buTDX63ECoCiSM4cNsAtICjCppaJBYxQRGenRH3VPDMTb75w3H73AA3YAfMSHoBD+A8Alfg1HaP1JWJ2sS7APvGY6ReHQqeVdI/c2m9QOqZgUiBgUiBgUiBgUiBgUiBgUiBgUiBgUiBgUiBgUiBgUiBgUhBAdbWI6ROrYX6OSXp130ELsBAfZya2u6RuvB9uV0+QTYN6OLgTpcAAAAASUVORK5CYII=';
    } else {
      img.src =
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAAAUCAYAAADIpHLKAAAAoUlEQVRoge3boQ0CQRRF0buTDX63DVoCicWBQ28CLeDoiiIWiUVMUIRnZ8Q9FTxzk2/+sF2eG+AG7IAZSS/gAZxG4Aoc2+6RujJRm3gXYN94jNSrQ8GzSvpnLq0XSD0zECkwECkwECkwECkwECkwECkwECkwECkwECkwECkwECkwECkowNp6hNSptVA/pyT9uo/AGRioj1NT2z1SF74vt5cPMCgOIZcI0fkAAAAASUVORK5CYII=';
    }

    this.__componentDidMount();
  };

  mouseEnter = () => {
    this.setState({ mouseOver: true });
  };

  mouseLeave = () => {
    this.setState({ mouseOver: false });
  };

  mouseMove = () => {
    if (!this.state.mouseOver && !this.mouseMoveBlock) {
      this.setState({ mouseOver: true }, () => (this.mouseMoveBlock = false));
    }
  };

  showNew = () => {
    this.setState({ showNew: true });
  };

  closeNew = () => {
    this.setState({ showNew: false });
  };

  addRow = rowData => {
    const { index, addElement } = this.props;
    addElement(index, rowData);
  };

  showSettings = e => {
    const { index, showElementSettings } = this.props;
    showElementSettings(e, index);
  };

  removeElement = () => {
    const { index, removeElement } = this.props;
    removeElement(index);
  };

  doNothing = e => {
    e.preventDefault();
  };

  render() {
    const {
      intl: { messages },
      editMode,
      canvasContainerRef,
      isDragging,
      connectDragSource,
      connectDropTarget,
      scale,
    } = this.props;
    const { mouseOver, showNew, shown } = this.state;

    const mainId = this.getMainId();
    const isFluid = this.getParamValue('isFluid', 'yes') === 'yes' ? true : false;
    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={mainId}
        className={
          'ms-grid ' +
          shownClass +
          (isFluid ? 'ms-grid-fluid ' : '') +
          (isDragging ? 'ms-grid-dragging ' : '') +
          this.getCssClasses() +
          this.getResponsiveClasses()
        }
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        {/* show outline of the section */}
        {(mouseOver || showNew) && editMode && (
          <>
            <span className="ms-grid-outline ms-grid-outline-top" />
            <span className="ms-grid-outline ms-grid-outline-right" />
            <span className="ms-grid-outline ms-grid-outline-bottom" />
            <span className="ms-grid-outline ms-grid-outline-left" />
            <div className="ms-grid-menu" style={{ transform: `scale(${1 / scale})`, transformOrigin: 'top left' }}>
              {connectDragSource(
                <a className="btn btn-default cursor-move" href="#moveToReorder" onClick={this.doNothing}>
                  <i className="fal fa-arrows" />
                </a>
              )}
              <Button onClick={this.showSettings}>
                <i className="fal fa-cog" />
              </Button>
              <SimplePrompt
                onSuccess={this.removeElement}
                container={canvasContainerRef.current}
                placement="bottom"
                bsStyle="default"
                showLabel={false}
                className=" "
              />
            </div>
          </>
        )}

        <Grid className={this.getPaddingClasses()} fluid={isFluid}>
          <div style={{ position: 'relative' }}>
            {this.props.children}
            <Clearfix />
          </div>
        </Grid>

        {editMode && (
          <div className="text-center ms-add-new ms-add-new-row">
            <OverlayTrigger
              container={() => canvasContainerRef.current}
              delayHide={150}
              placement="bottom"
              trigger="click"
              rootClose
              wrappingComponent={Popover}
              id={`add_row_${mainId}`}
              overlay={<CmsNewRow addRow={this.addRow} />}
              onEnter={this.showNew}
              onExit={this.closeNew}
            >
              <Button
                bsStyle="primary"
                className={'ms-cms-button btn-rounded ' + (mouseOver || showNew ? ' btn-show' : ' btn-hide')}
                style={{
                  transform: `scale(${1 / scale}) translateY(${15 * scale}px)`,
                }}
              >
                {messages.cms_add_row}
              </Button>
            </OverlayTrigger>
          </div>
        )}

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      <div
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
        onDragLeave={this.mouseLeave}
        onMouseMove={this.mouseMove}
      >
        <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
          {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
        </ScrollTrigger>
      </div>
    );
  }
}
