import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col, Alert } from 'react-bootstrap';
import GoogleMap from 'google-map-react';
import scriptLoader from 'react-async-script-loader';
import { findIndex as _findIndex } from 'lodash';

import Loader from 'components/layout/Loader';
import MapMarker from 'components/layout/MapMarker';
import WorkingHours from 'components/layout/client/WorkingHours';
import IntlDateTime from 'components/layout/IntlDateTime';

import List from 'components/List';

import { GOOGLE_MAPS_KEY, GOOGLE_MAPS_URL, mapOptions } from 'js/constants';

class CmsElementCommerceCarRentLocations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLocation: null,
      locationsList: null,
    };
  }

  componentDidUpdate = prevProps => {
    const { locations } = this.props;
    let { selectedLocation, locationsList } = this.state;

    if (!locations.pending && !locations.hasError) {
      if (!prevProps.locations.pending) {
        // check if a location is selected and if not, select the first one
        if (
          locations.data.list.length > 0 &&
          (!selectedLocation || _findIndex(locations.data.list, ['id', selectedLocation.id]) === -1)
        ) {
          this.setState({ selectedLocation: locations.data.list[0] });
        }
      }

      if (prevProps.locations.pending) {
        // Get the current previous location search list from local storage.
        const prevLocationSearches = JSON.parse(localStorage.getItem('prevLocationSearches'));
        locationsList = locations;

        // Do we have a previous search list?
        if (prevLocationSearches) {
          // Go through the list of previous search list.
          for (let i = prevLocationSearches.length - 1; i >= 0; i--) {
            // Get the index of the item in the previous search list at index i.
            const recentIndex = _findIndex(locationsList.data.list, ['id', prevLocationSearches[i].id]);

            if (recentIndex > -1) {
              // If the location at index i is in the current search result, we remove it in the current list.
              locationsList.data.list.splice(recentIndex, 1);
            }
            // Add the entry at index i of the previous search list at the top of the current search list.
            locationsList.data.list.splice(0, 0, prevLocationSearches[i]);
          }
        }
        // Write the edited location list in the state.
        this.setState({ locationsList });
      }
    }
  };

  mouseEnter = selectedLocation => {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({ selectedLocation });
    }, 350);
  };

  mouseLeave = () => {
    clearTimeout(this.timeout);
  };

  selectLocation = (e, selectedLocation) => {
    e.stopPropagation();
    e.preventDefault();

    const { onSelect } = this.props;

    this.setState({ selectedLocation }, () => {
      onSelect(selectedLocation);
    });
  };

  getTimezone = () => {
    const { defaultTimezone, timezone } = this.props;
    return timezone || defaultTimezone;
  };

  getLocationList = (locationsList, selectedLocation) => {
    if (locationsList && locationsList.data && locationsList.data.list.length > 0) {
      if (locationsList) {
        return locationsList.data.list.map(location => {
          const isActive = selectedLocation && selectedLocation.id === location.id;

          return (
            <li
              key={location.id}
              class={'ws-normal ' + (isActive ? 'active ' : '')}
              onMouseEnter={() => this.mouseEnter(location)}
              onMouseLeave={this.mouseLeave}
            >
              <a class="p-t-10 p-b-10" href={'#' + location.id} onClick={e => this.selectLocation(e, location)}>
                {location.isRecent ? (
                  <div>
                    <i class="fal fa-clock" /> {location.name}
                    <br />
                    <IntlDateTime date={location.dateFrom} /> - <IntlDateTime date={location.dateTo} />
                  </div>
                ) : (
                  location.name
                )}

                <span class="hint-text small-text m-l-5">({Math.round(location.distance / 100) / 10}km)</span>
              </a>
            </li>
          );
        });
      }
    } else {
      return null;
    }
  };

  render() {
    const {
      intl: { messages },
      appIntl,
      positionTop,
      positionLeft,
      pending,
      locations,
      isScriptLoaded,
    } = this.props;
    const { selectedLocation, locationsList } = this.state;

    const showPending = pending || locations.pending;

    const urlData = { distance: 150, filters: { isRentalPoint: true } };
    if (this.props.location) {
      const location = this.props.location.split(',');
      if (location[0] && location[1]) {
        [urlData.lat, urlData.lng] = location;
      }
    }

    return (
      <div class="overlayer fullwidth" style={{ top: positionTop + 'px', left: positionLeft + 'px' }}>
        <div
          class="popover relative block m-l-100 m-r-100 sm-m-l-30 sm-m-r-30 padding-30 sm-padding-10"
          style={{ maxWidth: '3000px' }}
        >
          <List
            noRender
            listName="carRentLocations"
            url="/api/whitelabellocations.json"
            urlData={urlData}
            list={locations}
          />

          {(showPending || !isScriptLoaded) && <Loader />}

          {!showPending && isScriptLoaded && (
            <Grid fluid class="no-padding">
              {locations.data.list.length ? (
                <Row class="no-margin row-eq-height">
                  <Col xs={12} md={5} class="no-padding">
                    <div class="list-view clearfix">
                      <ul>{this.getLocationList(locationsList, selectedLocation)}</ul>
                    </div>
                  </Col>
                  <Col xs={6} md={7} class="p-r-0 hidden-xs">
                    {selectedLocation ? (
                      <div class="text-black">
                        <h3>{selectedLocation.name}</h3>
                        <p>
                          {selectedLocation.street} {selectedLocation.house_number}{' '}
                          {selectedLocation.apartment_number ? selectedLocation.apartment_number : null}
                          <br />
                          {selectedLocation.zip_code} {selectedLocation.city}
                        </p>
                        <WorkingHours
                          data={selectedLocation.working_week_rental_point}
                          timezone={selectedLocation.timezone}
                        />

                        <div style={{ height: '200px' }}>
                          <GoogleMap
                            bootstrapURLKeys={{
                              key: GOOGLE_MAPS_KEY,
                              language: appIntl.locale,
                            }}
                            defaultCenter={{
                              lat: selectedLocation.coordinates.lat,
                              lng: selectedLocation.coordinates.lng,
                            }}
                            center={{ lat: selectedLocation.coordinates.lat, lng: selectedLocation.coordinates.lng }}
                            defaultZoom={15}
                            options={mapOptions}
                          >
                            <MapMarker lat={selectedLocation.coordinates.lat} lng={selectedLocation.coordinates.lng} />
                          </GoogleMap>
                        </div>
                      </div>
                    ) : (
                      <Alert>{messages.select_location}</Alert>
                    )}
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={12}>
                    {/* no locations found */}
                    <Alert>{messages.could_not_find_location_please_enter_more_details}</Alert>
                  </Col>
                </Row>
              )}
            </Grid>
          )}
        </div>
      </div>
    );
  }
}
CmsElementCommerceCarRentLocations.defaultProps = {
  index: '0',
};

const mapStateToProps = state => {
  return {
    locations: state.list.carRentLocations,
    locale: state.intl.locale,
  };
};

export default connect(mapStateToProps)(scriptLoader(GOOGLE_MAPS_URL)(CmsElementCommerceCarRentLocations));
