import React from 'react';
import { useDrag } from 'react-dnd';
import { findIndex as _findIndex } from 'lodash';
import SimplePrompt from '../SimplePrompt';

const DragFiles = ({
  name,
  type,
  index,
  thumb,
  id,
  fileKey,
  title,
  creator,
  owner,
  filesDrag,
  copiedFiles,
  s3urlOriginal,
  s3urlThumb,
  choice,
  copyFile,
  markFile,
  userData,
  del,
}) => {
  const ownFileType = 0; // 1 = folder, 0 = file
  const [{ opacity }, drag] = useDrag({
    item: { id, type, ownFileType, owner, creator },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  const indexColor = _findIndex(filesDrag, ['id', id]);
  let backgroundColor;
  let border;
  let mark;
  let copy;
  let buttonFontColor;

  const foundIndex = _findIndex(copiedFiles, ['id', id]);
  if (foundIndex > -1) {
    border = '3px solid #CF1719';
    copy = '#CF1719';
    buttonFontColor = '#FFFFFF';
  }

  if (indexColor > -1) {
    border = '3px solid #bde565';
    mark = '#bde565';
  }

  const isPdf = thumb === 'application/pdf';

  const allowed = userData.role === 'admin' || [owner, creator].includes(userData.username);

  const isVideo = thumb === 'video/mp4';
  const mediaClass = isVideo ? 'cc-media-video' : 'cc-media-image';

  return (
    <div md={2} className={mediaClass} key={index} ref={drag} style={{ opacity }} draggable="true">
      <div className="card" style={{ backgroundColor, border }}>
        {isVideo ? (
          <div className="card-img-top rounded cc-card-img">
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video controls style={{ width: '100%', height: '100%' }}>
              <source src={s3urlOriginal + fileKey} type="video/mp4" />
            </video>
          </div>
        ) : (
          <>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <img
              className="card-img-top rounded cc-card-img"
              src={isPdf ? '/img/pdf.svg' : s3urlThumb + thumb}
              onClick={() => choice(id)}
              width="100%"
              alt=""
            />
          </>
        )}
        <div className="card-body">
          <div className="cc-card-title" style={{ cursor: 'pointer' }} onClick={() => choice(id)}>
            <h5 className="">{title || name}</h5>
          </div>
          <div class="cc-card-footer">
            {allowed && (
              <div className="inlineSet">
                {!isPdf && (
                  <button
                    type="button"
                    style={{ marginRight: '2px', backgroundColor: copy, color: buttonFontColor }}
                    onClick={() => copyFile(id)}
                    className="btn btn-default card-button"
                  >
                    <i className="far fa-cut" />
                  </button>
                )}
                <button
                  type="button"
                  style={{ marginRight: '-2px', backgroundColor: mark }}
                  onClick={() => markFile(id)}
                  className="btn btn-default card-button"
                >
                  <i className="far fa-arrows-alt" />
                </button>
              </div>
            )}
            <div className="inlineSeta">
              <a
                href={s3urlOriginal + fileKey}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-default card-button"
              >
                <i className="far fa-search-plus" />
              </a>
            </div>
            {allowed && (
              <div className="inlineSet">
                {!isPdf && (
                  <button type="button" onClick={() => choice(id)} className="btn btn-default card-button">
                    <i className="fas fa-edit" />
                  </button>
                )}
                <SimplePrompt class="card-button" onSuccess={() => del(id)} showLabel={false} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DragFiles;
