import React from 'react';
import { withRouter } from 'react-router-dom';

import CarCheckboxFilter from 'components/layout/search/CarCheckboxFilter';

class CheckboxFilters extends React.Component {
  constructor(props) {
    super(props);

    const dummiesCount = props.dummiesCount,
      minLength = 35,
      maxLength = 75;

    let dummies = [];
    for (let i = 0; i < dummiesCount; i++) {
      dummies.push(Math.random() * (maxLength - minLength) + minLength);
    }

    this.state = { dummies };
  }

  doNothing = () => {};

  render() {
    const { location, history, size } = this.props;

    return (
      <div>
        {this.state.dummies.map((dummyWidth, i) => {
          const obj = {
            name: (
              <span class="ms-wireframe" style={{ width: dummyWidth + '%' }}>
                <span class="block animated-background" style={{ width: '100%' }}>
                  {'\u00A0'}
                </span>
              </span>
            ),
            value: '',
          };

          return (
            <CarCheckboxFilter
              obj={obj}
              name={'dummy'}
              location={location}
              history={history}
              size={size}
              key={i}
              onChange={this.doNothing}
            />
          );
        })}
      </div>
    );
  }
}
CheckboxFilters.defaultProps = {
  dummiesCount: 5,
  size: '',
};

export default withRouter(CheckboxFilters);
