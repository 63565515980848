import React from 'react';
import { injectIntl } from 'react-intl';
import { Clearfix, Col } from 'react-bootstrap';
import Slider from 'rc-slider';

import FormInput from 'components/forms/FormInput';

class StepFCarCreditPayment extends React.Component {
  render() {
    const { intl } = this.props;
    const messages = intl.messages;

    return (
      <Clearfix>
        <Col xs={12} md={7} class="no-padding">
          <div class="form-group-attached">
            <FormInput prefixAddon={'€'} label={'Anzahlung'} />

            <div class="form-group form-group-default p-b-25">
              <label>Laufzeit (Monate)</label>
              <Slider
                min={18}
                max={66}
                marks={{ 24: '24', 36: '36', 48: '48', 60: '60' }}
                step={null}
                onChange={() => {}}
                defaultValue={36}
              />
            </div>
          </div>

          <div class="text-right">
            <h4>199,00 € / Monat</h4>
          </div>
        </Col>
        <Col xs={12} md={5} class="p-l-20 p-r-0 sm-p-r-0 sm-m-t-15">
          Finanzierungsinsitut
          <br />
          <img src="/img/santander_logo.png" class="img-responsive m-t-10 m-b-10" />
          Santander Consumer Bank AG
          <br />
          Santander-Platz 1<br />
          41061 Mönchengladbach
          <br />
          <br />
          Vertragsart: Finanzierungsvertrag
          <br />
        </Col>
      </Clearfix>
    );
  }
}

export default injectIntl(StepFCarCreditPayment);
