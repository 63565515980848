import React from 'react';
import SimplePrompt from '../SimplePrompt';

const FileInTrash = ({
  name,
  index,
  thumb,
  id,
  fileKey,
  title,
  isAllowed,
  s3urlOriginal,
  s3urlThumb,
  undelete,
  erase,
  messages,
}) => {
  const isPdf = thumb === 'application/pdf';
  const isVideo = thumb === 'video/mp4';
  const mediaClass = isVideo ? 'cc-media-video' : 'cc-media-image';

  return (
    isAllowed && (
      <div md={2} className={mediaClass} key={index}>
        <div className="card" style={{ backgroundColor: '#CCC' }}>
          {isVideo ? (
            <div className="card-img-top rounded cc-card-img">
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video controls style={{ width: '100%', height: '100%' }}>
                <source src={s3urlOriginal + fileKey} type="video/mp4" />
              </video>
            </div>
          ) : (
            <>
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <img
                className="card-img-top rounded cc-card-img"
                style={{ opacity: 0.5, filter: 'saturate(0)' }}
                src={isPdf ? '/img/pdf.svg' : s3urlThumb + thumb}
                width="100%"
                alt=""
              />
            </>
          )}
          <div className="card-body">
            <div className="cc-card-title" style={{ cursor: 'pointer' }}>
              <h5 className="">{title || name}</h5>
            </div>
            <div class="cc-card-footer">
              <div className="inlineSet">
                <button type="button" onClick={() => undelete(id)} className="btn btn-default card-button">
                  <i className="fas fa-undo" />
                </button>
                <SimplePrompt
                  iconClass="fas fa-times"
                  class="card-button"
                  onSuccess={() => erase(id)}
                  showLabel={false}
                  showMessage={messages.medialibrary.are_you_sure_erase_file}
                  buttonLabel={messages.medialibrary.erase}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default FileInTrash;
