import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Grid, Row, Col } from 'react-bootstrap';
import { StickyContainer, Sticky } from 'react-sticky';

import { updateFormContext, resetFormContext } from 'actions/FormStatusActions';
import { modalOpen, modalClose } from 'actions/ModalsActions';
import { parseQs, stringifyQs } from 'helpers/http';

import NavHeader from 'components/layout/itemDetails/carDetailsRender/NavHeader';
import StickyNavigation from 'components/layout/itemDetails/carDetailsRender/StickyNavigation';
import AssetsHeader from 'components/layout/itemDetails/carDetailsRender/AssetsHeader';
import DetailsDescription from 'components/layout/itemDetails/carDetailsRender/DetailsDescription';
import PriceBox from 'components/layout/itemDetails/carDetailsRender/PriceBox';
import PhotosSlider from 'components/layout/itemDetails/carDetailsRender/PhotosSlider';
import PluginBlurbs from 'components/layout/itemDetails/carDetailsRender/PluginBlurbs';
import LocationInfos from 'components/layout/itemDetails/carDetailsRender/LocationInfos';
import TeamInfos from 'components/layout/itemDetails/carDetailsRender/TeamInfos';
import MoreItems from 'components/layout/itemDetails/carDetailsRender/MoreItems';

import ModalNewPriceNegotiation from 'components/modals/ModalNewPriceNegotiation';
import ModalCarBuyInquiry from 'components/modals/ModalCarBuyInquiry';
import ModalCarTestDriveInquiry from 'components/modals/ModalCarTestDriveInquiry';

import DataId from 'components/DataId';

class CarDetailsRender extends React.Component {
  constructor(props) {
    super(props);

    const query = parseQs(props.location.search);

    this.state = {
      showBuyInquiry: query.showBuyInquiry && query.showBuyInquiry === 'true',
      showTestDriveInquiry: query.showTestDriveInquiry && query.showTestDriveInquiry === 'true',
      showNewPriceNegotiation: query.showNewPriceNegotiation && query.showNewPriceNegotiation === 'true',
      mainConversionButtonShown: false,
    };
  }

  componentWillMount = () => {
    const {
      item: { data },
      updateFormContext,
    } = this.props;
    updateFormContext(data.id);
  };

  componentWillUnmount = () => {
    const { doResetFormContext } = this.props;
    doResetFormContext();
  };

  showBuyInquiry = () => {
    const { location, history, modalOpen } = this.props;
    const query = parseQs(location.search);
    query.showBuyInquiry = true;

    history.replace({ pathname: location.pathname, search: stringifyQs(query) });
    modalOpen();
    this.setState({ showBuyInquiry: true });
  };

  closeBuyInquiry = () => {
    const { location, history, modalClose } = this.props;
    const query = parseQs(location.search);
    delete query.showBuyInquiry;

    history.replace({ pathname: location.pathname, search: stringifyQs(query) });
    modalClose();
    this.setState({ showBuyInquiry: false });
  };

  showTestDriveInquiry = () => {
    const { location, history, modalOpen } = this.props;
    const query = parseQs(location.search);
    query.showTestDriveInquiry = true;

    history.replace({ pathname: location.pathname, search: stringifyQs(query) });
    modalOpen();
    this.setState({ showTestDriveInquiry: true });
  };

  closeTestDriveInquiry = () => {
    const { location, history, modalClose } = this.props;
    const query = parseQs(location.search);
    delete query.showTestDriveInquiry;

    history.replace({ pathname: location.pathname, search: stringifyQs(query) });
    modalClose();
    this.setState({ showTestDriveInquiry: false });
  };

  showNewPriceNegotiation = () => {
    const { location, history, modalOpen } = this.props;
    const query = parseQs(location.search);
    query.showNewPriceNegotiation = true;

    history.replace({ pathname: location.pathname, search: stringifyQs(query) });
    modalOpen();
    this.setState({ showNewPriceNegotiation: true });
  };

  closeNewPriceNegotiation = () => {
    const { location, history, modalClose } = this.props;
    const query = parseQs(location.search);
    delete query.showNewPriceNegotiation;

    history.replace({ pathname: location.pathname, search: stringifyQs(query) });
    modalClose();
    this.setState({ showNewPriceNegotiation: false });
  };

  mainConversionButtonShown = () => {
    this.setState({ mainConversionButtonShown: true });
  };

  mainConversionButtonHidden = () => {
    this.setState({ mainConversionButtonShown: false });
  };

  render() {
    const {
      intl: { messages },
      intl,
      appIntl,
      layout,
      projectConfig,
      user,
      item: { data },
      locationData,
    } = this.props;
    const { showBuyInquiry, showTestDriveInquiry, showNewPriceNegotiation, mainConversionButtonShown } = this.state;

    const sharedProps = {
      intl,
      appIntl,
      messages,
      user,
      layout,
      data,
      projectConfig,
      locationData,
      showBuyInquiry,
      doShowBuyInquiry: this.showBuyInquiry,
      showTestDriveInquiry,
      doShowTestDriveInquiry: this.showTestDriveInquiry,
      showNewPriceNegotiation,
      doShowNewPriceNegotiation: this.showNewPriceNegotiation,
      onMainConversionButtonShown: this.mainConversionButtonShown,
      onMainConversionButtonHidden: this.mainConversionButtonHidden,
    };

    return (
      <div className="item-details">
        {!!data.default_location && (
          <DataId
            dataIdName="location"
            dataIdId={data.default_location.id}
            url={'/api/items/' + data.id + '/defaultLocationDetails.json'}
          />
        )}

        <NavHeader {...sharedProps} />
        <AssetsHeader {...sharedProps} />

        {layout.breakpointIndex < 3 ? (
          <Grid className="m-t-40 m-b-40">
            <Row>
              <Col xs={12}>
                <PriceBox {...sharedProps} isSticky={false} />
              </Col>
              <Col xs={12}>
                <DetailsDescription {...sharedProps} />
              </Col>
            </Row>
          </Grid>
        ) : (
          <StickyContainer>
            <Grid className="m-t-40 m-b-40">
              <Row>
                <Col xs={12} sm={5}>
                  <DetailsDescription {...sharedProps} />
                </Col>

                <Sticky>
                  {({ style, isSticky }) => {
                    const stickyContent = (
                      <Col
                        sm={isSticky ? 12 : 7}
                        className={isSticky ? 'p-t-65' : ''}
                        style={{ width: style.width * 0.5833 }}
                      >
                        <PriceBox {...sharedProps} isSticky={isSticky} />
                      </Col>
                    );

                    return (
                      <div style={{ ...style, left: style.left + style.width * (1 - 0.5833), width: 'auto' }}>
                        {stickyContent}
                      </div>
                    );
                  }}
                </Sticky>
              </Row>
            </Grid>
          </StickyContainer>
        )}

        <PhotosSlider {...sharedProps} />
        <PluginBlurbs {...sharedProps} />
        <LocationInfos {...sharedProps} />
        <TeamInfos {...sharedProps} />
        <MoreItems {...sharedProps} />

        <StickyNavigation {...sharedProps} visible={!mainConversionButtonShown} />

        <div id="buy-inquiry">
          <ModalCarBuyInquiry show={showBuyInquiry} close={this.closeBuyInquiry} item={data} />
        </div>
        <div id="test-drive-inquiry">
          <ModalCarTestDriveInquiry show={showTestDriveInquiry} close={this.closeTestDriveInquiry} item={data} />
        </div>
        <div id="new-price-negotiation">
          <ModalNewPriceNegotiation show={showNewPriceNegotiation} close={this.closeNewPriceNegotiation} item={data} />
        </div>
      </div>
    );
  }
}
CarDetailsRender.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
  const res = {
    appIntl: state.intl,
    layout: state.layout,
    projectConfig: state.data.projectConfig,
    user: state.login.user,
    locationData: { pending: true },
  };

  const locationId = !!ownProps.item.data.default_location && ownProps.item.data.default_location.id;
  if (locationId && state.dataId.location[locationId] !== undefined) {
    res.locationData = state.dataId.location[locationId];
  }

  return res;
};
const mapDispatchToProps = dispatch => {
  return {
    updateFormContext: id => {
      dispatch(updateFormContext('item', id));
    },
    doResetFormContext: () => {
      dispatch(resetFormContext());
    },
    modalOpen: () => dispatch(modalOpen()),
    modalClose: () => dispatch(modalClose()),
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CarDetailsRender)
  )
);
