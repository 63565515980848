import React from 'react';
import Masonry from 'react-masonry-component';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';

import { elementSource, elementTarget } from './elementDragUtils';
import { dispatchResizeEvent } from 'helpers/window';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
export default class CmsElementGallery extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { children } = this.props;

    if (children === null || prevProps.children === null || children.length !== prevProps.children.length) {
      dispatchResizeEvent();
    }

    if (this.getPrevParamValue(prevProps, 'isCondensed') !== this.getParamValue('isCondensed')) {
      dispatchResizeEvent();
    }

    this.__componentDidUpdate(prevProps, prevState);
  };

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  render() {
    const { connectDropTarget, connectDragSource, connectDragPreview } = this.props;
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={
          'ms-gallery-container ' +
          shownClass +
          (this.getParamValue('isCondensed', 'no') === 'yes' ? ' condensed' : '') +
          this.getCssClasses()
        }
        onClick={this.showSettings}
        style={{
          ...this.getBackgroundStyle(),
        }}
      >
        {this.getElementStyle()}

        {this.props.children ? (
          <Masonry
            className={'ms-gallery'}
            elementType={'div'}
            options={{ transitionDuration: 0 }}
            disableImagesLoaded={false}
            updateOnEachImageLoad
          >
            {this.props.children}
          </Masonry>
        ) : (
          <div class="p-t-15 p-b-15 text-center">
            <i class="fa fa-images fa-4x" />
          </div>
        )}

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}
