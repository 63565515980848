import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Clearfix } from 'react-bootstrap';
import AnimateHeight from 'react-animate-height';

import { carIcons } from 'helpers/items';
import { getMainDomain } from 'helpers/whiteLabel';

import CarSelectableAttribute from 'components/layout/search/CarSelectableAttribute';

class SummaryFilterCarBrand extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mouseOver: false,
      show: false,
    };
  }

  handleClick = () => {
    this.setState(prevProps => ({ show: !prevProps.show }));
  };

  getName = () => {
    return 'BRAND';
  };

  hasFilters = () => {
    const { filters } = this.props;
    return filters[this.getName()] !== undefined;
  };

  render() {
    const { facet, projectConfig } = this.props;
    const { mouseOver, show } = this.state;

    const projectDomain = getMainDomain(projectConfig.data, true, false);

    return (
      <div
        className={'summary-filter animate-all ' + (mouseOver ? 'hover ' : '') + (show ? 'active ' : '')}
        onMouseEnter={() => this.setState({ mouseOver: true })}
        onMouseLeave={() => this.setState({ mouseOver: false })}
      >
        <div className="summary-filter-title" onClick={this.handleClick}>
          <Clearfix>
            <div className="pull-left">{facet.name}</div>{' '}
            {/* filter name comes already with the name in proper language */}
            <div className="pull-right">
              {this.hasFilters() && !show ? (
                <i className="fal fa-check text-success" />
              ) : (
                <i className={'fal fa-chevron-' + (show ? 'up' : 'right')} />
              )}
            </div>
          </Clearfix>
        </div>

        <AnimateHeight duration={200} height={show ? 'auto' : 0}>
          <div className="car-search summary-filter-body">
            {facet.values.map(brand => {
              const obj = {
                name: brand.name,
                src: carIcons[brand.name]
                  ? projectDomain + '/img/brands/' + carIcons[brand.name] + '_grey_small.png'
                  : null,
                src_selected: carIcons[brand.name]
                  ? projectDomain + '/img/brands/' + carIcons[brand.name] + '_small.png'
                  : null,
              };

              return (
                <CarSelectableAttribute
                  obj={obj}
                  name="BRAND"
                  location={this.props.location}
                  history={this.props.history}
                  size="xxl"
                  key={obj.name}
                />
              );
            })}
          </div>
        </AnimateHeight>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    projectConfig: state.data.projectConfig,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(SummaryFilterCarBrand)));
