import axios, { CancelToken } from 'axios';
import { toQueryString } from 'helpers/http';

export const DATA_PENDING = 'DATA_PENDING';
export const DATA_SUBMIT = 'DATA_SUBMIT';
export const DATA_FULFILLED = 'DATA_FULFILLED';
export const DATA_RESET = 'DATA_RESET';
export const DATA_REJECTED = 'DATA_REJECTED';
export const ADD_LEAD_TIMELINE = 'ADD_LEAD_TIMELINE';
export const LEAD_MASTER_COPY_UPLOADED = 'LEAD_MASTER_COPY_UPLOADED';
export const ADD_ORDER_TIMELINE = 'ADD_ORDER_TIMELINE';

export const REMOVE_ITEM_IMAGE = 'REMOVE_ITEM_IMAGE';
export const REMOVE_PLUGIN_IMAGE = 'REMOVE_PLUGIN_IMAGE';
export const REMOVE_CATEGORY_IMAGE = 'REMOVE_CATEGORY_IMAGE';
export const MARK_EDITION = 'MARK_EDITION';
export const SET_VARIABLES_RDATA = 'SET_VARIABLES_RDATA';
export const ADD_SHARED_EDITION_USER = 'ADD_SHARED_EDITION_USER';
export const DELETE_SHARED_EDITION_USER = 'DELETE_SHARED_EDITION_USER';

export const dataPending = (dataName, data) => {
  return {
    type: DATA_PENDING,
    payload: { dataName, ...data },
  };
};

export const dataSend = (url, data, finishedCallback, errorCallback, method, cancelToken) => {
  let fullUrl = url;
  if (fullUrl.indexOf('?') === -1) {
    fullUrl += '?';
  }

  if (method === 'get' && data !== undefined && data) {
    fullUrl += toQueryString(data) + '&';
  }
  fullUrl += '_locale=' + globalLocale;

  return {
    type: DATA_SUBMIT,
    payload: axios({
      method,
      url: fullUrl,
      data: { ...data },
      cancelToken,
    })
      .then(finishedCallback)
      .catch(errorCallback),
  };
};

export const dataFulfilled = (dataName, response) => {
  return {
    type: DATA_FULFILLED,
    payload: { dataName, response },
  };
};

export const dataReset = dataName => {
  return {
    type: DATA_RESET,
    payload: { dataName },
  };
};

export const dataRejected = (dataName, response) => {
  return {
    type: DATA_REJECTED,
    payload: { dataName, response: response.response },
  };
};

export const addLeadTimeline = (type, text, publicCreator, params) => {
  return {
    type: ADD_LEAD_TIMELINE,
    payload: { type, text, publicCreator, params },
  };
};

export const addOrderTimeline = (type, text, publicCreator, params) => {
  return {
    type: ADD_ORDER_TIMELINE,
    payload: { type, text, publicCreator, params },
  };
};

export const removeItemImage = (itemId, variantId, imageIndex) => {
  return {
    type: REMOVE_ITEM_IMAGE,
    payload: {
      itemId,
      variantId,
      imageIndex,
    },
  };
};

export const removePluginImage = (pluginId, imageIndex) => {
  return {
    type: REMOVE_PLUGIN_IMAGE,
    payload: {
      pluginId,
      imageIndex,
    },
  };
};

export const removeCategoryImage = (categoryId, imageIndex) => {
  return {
    type: REMOVE_CATEGORY_IMAGE,
    payload: {
      categoryId,
      imageIndex,
    },
  };
};

export const dataDispatchHelper = (dataName, url, data, method, dispatch) => {
  const source = CancelToken.source();

  // add the request to queue
  dispatch(dataPending(dataName));

  // finished callback will trigger an event
  const finishedCallback = response => {
    return dispatch(dataFulfilled(dataName, response));
  };
  // error callback will trigger an event
  const errorCallback = error => {
    if (!axios.isCancel(error)) {
      return dispatch(dataRejected(dataName, error));
    }
  };
  // dispatch the request
  dispatch(dataSend(url, data, finishedCallback, errorCallback, method, source.token));

  return source;
};

// --------- data.campaign.edition --------------------------------
export function markEditionR(editionId, mark) {
  return {
    type: MARK_EDITION,
    payload: axios
      .put(`/api/editions/${editionId}.json`, { json: JSON.stringify({ marked: mark }) })
      .then(({ data }) => data),
  };
}

// --------- edition --------------------------------
export function setVariablesAboutListRData(key) {
  return {
    type: SET_VARIABLES_RDATA,
    payload: key,
  };
}

export function addSharedEditionUser(editionId, userData) {
  return {
    type: ADD_SHARED_EDITION_USER,
    payload: { editionId, userData },
  };
}

export function deleteSharedEditionUser(editionId, userId) {
  return {
    type: DELETE_SHARED_EDITION_USER,
    payload: { editionId, userId },
  };
}

export const leadMasterCopyUploaded = uploadedS3Url => ({
  type: LEAD_MASTER_COPY_UPLOADED,
  payload: uploadedS3Url,
});
