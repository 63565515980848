import slugify from 'react-slugify';
import { forEach as _forEach } from 'lodash';

import { stringifyQs } from 'helpers/http';

export const getNameInCurrentLanguage = (names, appIntl) => {
  let name;
  _forEach(names, nameValue => {
    if (nameValue.lang === appIntl.displayIntlForm || nameValue.lang === null || nameValue.lang === undefined) {
      ({ name } = nameValue);
    }
  });
  return name;
};

export const getDescriptionInCurrentLanguage = (descriptions, appIntl) => {
  let description;
  _forEach(descriptions, descriptionValue => {
    if (
      descriptionValue.lang === appIntl.displayIntlForm ||
      descriptionValue.lang === null ||
      descriptionValue.lang === undefined
    ) {
      ({ description } = descriptionValue);
    }
  });
  return description;
};

export const getPassengerCarSearchPath = () => {
  switch (globalLocale) {
    case 'en':
      return '/en/cardetailsearch';

    case 'pl':
      return '/pl/cardetailsearch';

    default:
      return '/de/gebrauchtwagensuche';
  }
};

export const getPassengerCarSearchResultPath = () => {
  switch (globalLocale) {
    case 'en':
      return '/en/cars';

    case 'pl':
      return '/pl/cars';

    default:
      return '/de/gebrauchtwagen';
  }
};

export const getItemDetailsPath = (type, slug) => {
  switch (type) {
    case 'cars':
      return getPassengerCarSearchResultPath() + '/' + slug;

    case 'items':
      return '/' + globalLocale + '/shop/' + slug;

    default:
      return '/' + globalLocale + '/' + type + '/' + slug;
  }
};

export const getCarSearchBrandsPath = () => {
  switch (globalLocale) {
    case 'en':
      return '/en/select-car-brand';

    case 'pl':
      return '/pl/select-car-brand';

    default:
      return '/de/fahrzeugmarke-waehlen';
  }
};

export const getCarSearchModelsPath = brandName => {
  switch (globalLocale) {
    case 'en':
      return '/en/' + brandName + '-models';

    case 'pl':
      return '/pl/' + brandName + '-models';

    default:
      return '/de/' + brandName + '-modelle';
  }
};

export const getCarSearchModelDrivePath = (brandName, modelName) => {
  switch (globalLocale) {
    case 'en':
      return '/en/buy-' + brandName + '/' + modelName;

    case 'pl':
      return '/pl/buy-' + brandName + '/' + modelName;

    default:
      return '/de/' + brandName + '-kaufen/' + modelName;
  }
};

export const getCarSearchModelEquipmentPath = (brandName, modelName, gearOptions, fuelOptions) => {
  switch (globalLocale) {
    case 'en':
      return '/en/buy-' + brandName + '/' + modelName + '/' + gearOptions + '/' + fuelOptions + '/equipment';

    case 'pl':
      return '/pl/buy-' + brandName + '/' + modelName + '/' + gearOptions + '/' + fuelOptions + '/equipment';

    default:
      return '/de/' + brandName + '-kaufen/' + modelName + '/' + gearOptions + '/' + fuelOptions + '/austattung';
  }
};

export const getCartPath = () => {
  switch (globalLocale) {
    case 'en':
      return '/en/cart';

    case 'es':
      return '/es/cart';

    case 'fr':
      return '/fr/cart';

    case 'it':
      return '/it/cart';

    case 'nl':
      return '/nl/cart';

    case 'pl':
      return '/pl/cart';

    case 'tr':
      return '/tr/cart';

    default:
      return '/de/warenkorb';
  }
};

export const getFavoritesPath = () => {
  switch (globalLocale) {
    case 'en':
      return '/en/favorites';

    case 'pl':
      return '/pl/favorites';

    default:
      return '/de/favoriten';
  }
};

export const getLocationsPath = () => {
  switch (globalLocale) {
    case 'en':
      return '/en/locations';

    case 'pl':
      return '/pl/locations';

    default:
      return '/de/standorte';
  }
};

export const getCarRentalPath = () => {
  switch (globalLocale) {
    case 'en':
      return '/en/car-rental';

    case 'pl':
      return '/pl/car-rental';

    default:
      return '/de/fahrzeugvermietung';
  }
};

export const getCarRentalSearchPath = (locationId, locationName, query = {}, dateFrom = null, dateTo = null) => {
  const search = { ...query };

  if (dateFrom) {
    search.dateFrom = dateFrom;
  }
  if (dateTo) {
    search.dateTo = dateTo;
  }

  return getCarRentalPath() + '/' + locationId + '-' + locationName + '?' + stringifyQs(search);
};

export const getCarRentalMapSearchPath = (coordinates, query = {}, dateFrom = null, dateTo = null) => {
  const search = { ...query };

  if (dateFrom) {
    search.dateFrom = dateFrom;
  }
  if (dateTo) {
    search.dateTo = dateTo;
  }

  return getCarRentalPath() + '/' + coordinates + '?' + stringifyQs(search);
};

export const getCarRentalDetailsPathFromMap = (coordinates, itemSlug, query = {}) => {
  return getCarRentalPath() + '/' + coordinates + '/' + itemSlug + '?' + stringifyQs(query);
};

export const getOnlineAppointmentBookingPath = () => {
  switch (globalLocale) {
    case 'en':
      return '/en/car-service';

    case 'pl':
      return '/pl/car-service';

    default:
      return '/de/onlineterminbuchung';
  }
};

export const getOnlineAppointmentBookingLocationsPath = coordinates => {
  const base = getOnlineAppointmentBookingPath();
  return base + '/' + coordinates;
};

export const getOnlineAppointmentBookingLocationServices = (coordinates, locationId, locationName) => {
  const base = getOnlineAppointmentBookingLocationsPath(coordinates);
  return base + '/' + locationId + '-' + slugify(locationName);
};

export const getOnlineAppointmentBookingLocationResources = (
  coordinates,
  locationId,
  locationName,
  services = null
) => {
  let url = getOnlineAppointmentBookingLocationServices(coordinates, locationId, locationName);

  switch (globalLocale) {
    case 'de':
      url += '/ressourcen';
      break;

    default:
      url += '/resources';
      break;
  }

  return url + (services && services.length ? '?' + stringifyQs({ services }) : '');
};

export const getOnlineAppointmentBookingLocationFreeSlots = (
  coordinates,
  locationId,
  locationName,
  services = [],
  resources = []
) => {
  let url = getOnlineAppointmentBookingLocationResources(coordinates, locationId, locationName);

  switch (globalLocale) {
    case 'de':
      url += '/freie-termine';
      break;

    default:
      url += '/free-slots';
      break;
  }

  return (
    url +
    ((services && services.length) || (resources && resources.length) ? '?' + stringifyQs({ services, resources }) : '')
  );
};

export const getOnlineAppointmentBookingClientData = (
  coordinates,
  locationId,
  locationName,
  services = [],
  resources = [],
  resourceId = null,
  selectedSlot
) => {
  let url = getOnlineAppointmentBookingLocationFreeSlots(coordinates, locationId, locationName);
  url += '/' + selectedSlot;
  return (
    url +
    ((services && services.length) || (resources && resources.length) || resourceId
      ? '?' + stringifyQs({ services, resources, resourceId })
      : '')
  );
};

export const getOnlineAppointmentBookingCarData = (
  coordinates,
  locationId,
  locationName,
  services = [],
  resources = [],
  resourceId = null,
  selectedSlot
) => {
  let url = getOnlineAppointmentBookingClientData(coordinates, locationId, locationName, [], [], null, selectedSlot);
  switch (globalLocale) {
    case 'de':
      url += '/dein-fahrzeug';
      break;

    default:
      url += '/your-car';
      break;
  }
  return (
    url +
    ((services && services.length) || (resources && resources.length) || resourceId
      ? '?' + stringifyQs({ services, resources, resourceId })
      : '')
  );
};

export const getOnlineAppointmentBookingSummary = (
  coordinates,
  locationId,
  locationName,
  services = [],
  resources = [],
  resourceId = null,
  selectedSlot,
  clientCarId
) => {
  const url = getOnlineAppointmentBookingCarData(coordinates, locationId, locationName, [], [], null, selectedSlot);
  return (
    url +
    '/' +
    clientCarId +
    ((services && services.length) || (resources && resources.length) || resourceId
      ? '?' + stringifyQs({ services, resources, resourceId })
      : '')
  );
};

export const getTimezoneFromState = state => {
  const {
    data: { projectConfig },
    login: { user },
  } = state;

  if (user && user.profile && user.profile.timezone) {
    return user.profile.timezone;
  }
  if (projectConfig && projectConfig.data && projectConfig.data.default_timezone) {
    return state.data.projectConfig.data.default_timezone;
  }

  return 'UTC';
};
