import axios from 'axios';

export const F_UPLOAD = 'F_UPLOAD';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const GET_MEDIA_LIBRARY = 'GET_MEDIA_LIBRARY';
export const GET_MEDIA_LIBRARY_FOLDER = 'GET_MEDIA_LIBRARY_FOLDER';
export const GET_MEDIA_LIBRARY_FOLDER_SORTED = 'GET_MEDIA_LIBRARY_FOLDER_SORTED';
export const EDIT_FILE = 'EDIT_FILE';
export const DELETE_FILE = 'DELETE_FILE';
export const UNDELETE_FILE = 'UNDELETE_FILE';
export const ERASE_FILE = 'ERASE_FILE';
export const DELETE_FOLDER = 'DELETE_FOLDER';
export const UNDELETE_FOLDER = 'UNDELETE_FOLDER';
export const ERASE_FOLDER = 'ERASE_FOLDER';
export const EDIT_FOLDER = 'EDIT_FOLDER';
export const CONNECT_TO_PIXABAY = 'CONNECT_TO_PIXABAY';
export const SET_VARIABLES_MEDIA = 'SET_VARIABLES_MEDIA';
export const UPDATE_STORE = 'UPDATE_STORE';

const filtersOwner = 'filters[owner][]';

export function uploadFile(formData, uploadProgress, filesToUpload) {
  return {
    type: F_UPLOAD,
    payload: axios
      .post('/api/medialibraries/files.json', formData, uploadProgress)
      .then(({ data }) => ({ ...data, files: filesToUpload })),
  };
}

export function createFolder(data) {
  return {
    type: CREATE_FOLDER,
    payload: axios.post('/api/medialibraries/folders.json', data).then(({ data }) => data),
  };
}

/**
 * Helper function to adapt requested arguments to handle sort direction
 * @param {string} sort
 * @param {string} dir
 * @returns string[]
 */
function getSortDir(sort, dir = 'asc') {
  if (sort === 'cdateDESC') {
    return ['cdate', 'desc'];
  }
  if (sort === 'cdateASC') {
    return ['cdate', 'asc'];
  }

  return [sort, dir];
}

/**
 * Append owner filters to the URLSearchParams object
 * @param {URLSearchParams} params object to append to
 * @param {string} username
 * @param {string} client
 * @param {string[]} access
 */
function appendOwnerFilters(params, username, client, access) {
  if (username) params.append(filtersOwner, username);
  if (client) params.append(filtersOwner, client);
  if (Array.isArray(access)) {
    access.forEach(ancestor => {
      if (ancestor) {
        params.append(filtersOwner, ancestor);
      }
    });
  }
}

export function getMediaLibrary(clientArg, sortArg = '', access = [], username = '', includePdfFiles = false) {
  const client = clientArg ?? '';

  const [sort, dir] = getSortDir(sortArg);
  const params = new URLSearchParams({ sort, dir, includePdfFiles });
  appendOwnerFilters(params, username, client, access);

  const url = `/api/medialibraries/main.json?${params.toString()}`;

  return {
    type: GET_MEDIA_LIBRARY,
    payload: axios.get(url).then(({ data }) => data),
  };
}

export function getMediaLibraryFolder(clientArg, folderId, sortArg, access, username, includePdfFiles = false) {
  const client = clientArg ?? '';

  const [sort, dir] = getSortDir(sortArg);
  const params = new URLSearchParams({ sort, dir, includePdfFiles });
  appendOwnerFilters(params, username, client, access);

  if (folderId === 'main') {
    return {
      type: GET_MEDIA_LIBRARY_FOLDER,
      payload: axios.get(`/api/medialibraries/${folderId}.json?${params.toString()}`).then(({ data }) => data),
    };
  }

  params.delete(filtersOwner);

  return {
    type: GET_MEDIA_LIBRARY_FOLDER,
    payload: axios.get(`/api/medialibraries/${folderId}.json?${params.toString()}`).then(({ data }) => data),
  };
}

export function getMediaLibraryFolderSorted(clientArg, folderId, sortArg, access, username, includePdfFiles = false) {
  const client = clientArg ?? '';

  const [sort, dir] = getSortDir(sortArg);
  const params = new URLSearchParams({ sort, dir, includePdfFiles });
  appendOwnerFilters(params, username, client, access);

  if (folderId === 'main') {
    return {
      type: GET_MEDIA_LIBRARY_FOLDER_SORTED,
      payload: axios.get(`/api/medialibraries/${folderId}.json?${params.toString()}`).then(({ data }) => data),
    };
  }

  params.delete(filtersOwner);

  return {
    type: GET_MEDIA_LIBRARY_FOLDER_SORTED,
    payload: axios.get(`/api/medialibraries/${folderId}.json?${params.toString()}`).then(({ data }) => data),
  };
}

export function editFile({ id, data }) {
  return {
    type: EDIT_FILE,
    payload: axios.put(`/api/medialibraries/${id}/file.json`, data).then(({ data }) => data),
  };
}

export function deleteFile(id) {
  return {
    type: DELETE_FILE,
    payload: axios.delete(`/api/medialibraries/${id}/files/toTrash.json`).then(({ data }) => data),
  };
}

export function undeleteFile(id) {
  return {
    type: UNDELETE_FILE,
    payload: axios.put(`/api/medialibraries/${id}/file/undelete.json`).then(({ data }) => data),
  };
}

export function eraseFile(id) {
  return {
    type: ERASE_FILE,
    payload: axios.delete(`/api/medialibraries/${id}/files/delete.json`).then(({ data }) => data),
  };
}

export function deleteFolder(id) {
  return {
    type: DELETE_FOLDER,
    payload: axios.delete(`/api/medialibraries/${id}/folders/toTrash.json`).then(({ data }) => data),
  };
}

export function undeleteFolder(id) {
  return {
    type: UNDELETE_FOLDER,
    payload: axios.put(`/api/medialibraries/${id}/folder/undelete.json`).then(({ data }) => data),
  };
}

export function eraseFolder(id) {
  return {
    type: ERASE_FOLDER,
    payload: axios.delete(`/api/medialibraries/${id}/folders/delete.json`).then(({ data }) => data),
  };
}

export function editFolder({ id, data }) {
  return {
    type: EDIT_FOLDER,
    payload: axios.put(`/api/medialibraries/${id}/folder.json`, data).then(({ data }) => data),
  };
}

export function setVariables(key) {
  return {
    type: SET_VARIABLES_MEDIA,
    payload: key,
  };
}

export function updateStoreFR(data) {
  return {
    type: UPDATE_STORE,
    payload: data,
  };
}

/*
 * NEEDS TO IMPLEMENT IN THE FUTURE
 */

/**
 * Search PixaBay for images
 * @param {string} key
 * @param {string} search
 * @param {string} minHeight
 * @param {string} minWidth
 * @returns array
 */
export function connectToPixabay(key, search, minHeight, minWidth) {
  const params = new URLSearchParams({ key, q: search, min_height: minHeight, min_width: minWidth });

  /** @see https://pixabay.com/api/docs/#api_javascript_example for available settings */
  params.append('per_page', 30); // results per Page, default: 20
  params.append('page', 1); // search results are paginated, default: 1

  return {
    type: CONNECT_TO_PIXABAY,
    payload: axios.get(`https://pixabay.com/api/?key=${params.toString()}`).then(({ data }) => {
      return [search, data];
    }),
  };
}
