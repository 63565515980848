import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { modalOpen, modalClose } from 'actions/ModalsActions';

import SummaryTags from 'components/layout/summary/SummaryTags';
import SummaryFilterList from 'components/layout/summary/SummaryFilterList';

import Loader from 'components/layout/Loader';

import Modal from 'components/Modal';

class ModalSummaryFilters extends React.Component {
  componentDidUpdate = prevProps => {
    const { show, modalOpen, modalClose } = this.props;

    if (!prevProps.show && show) {
      modalOpen();
    } else if (prevProps.show && !show) {
      modalClose();
    }
  };

  render() {
    const {
      intl: { messages },
      intl,
      appIntl,
      webApp,
      type,
      list,
      show,
      close,
      facets,
      filters,
      projectConfig,
      location,
      setFilters,
      setFilter,
      resetFilter,
      removeFilter,
    } = this.props;

    const filterActions = { setFilters, setFilter, resetFilter, removeFilter };

    const res = [
      <Modal show={show} onHide={close} bsSize="lg" class="full-width slide-right" key="modal">
        <Modal.Header closeButton>
          <Modal.Title>{messages.do_filter}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="full-width p-b-70">
            {facets.pending ? (
              <div class="text-center">
                <Loader />
              </div>
            ) : (
              <div>
                <div class="p-b-15">
                  <SummaryTags />
                </div>

                <div class="summary-filter-list-container">
                  <SummaryFilterList
                    intl={intl}
                    appIntl={appIntl}
                    webApp={webApp}
                    type={type}
                    filters={filters}
                    facets={facets}
                    location={location}
                    projectConfig={projectConfig}
                    {...filterActions}
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>,
    ];

    if (show) {
      res.push(
        <div
          class="summary-filter-modal-submit-button bg-white"
          style={{ display: show ? 'block' : 'none' }}
          key="button"
        >
          <Button block bsSize="lg" bsStyle="primary" class="b-rad-null" onClick={close}>
            <FormattedMessage
              id={type === 'cars' ? 'count_cars' : 'count_products'}
              values={{ count: list.data.count }}
            />
          </Button>
        </div>
      );
    }

    return res;
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    webApp: state.webApp,
    list: state.list.summary,
    projectConfig: state.data.projectConfig,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    modalOpen: () => {
      dispatch(modalOpen());
    },
    modalClose: () => {
      dispatch(modalClose());
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ModalSummaryFilters)
  )
);
