import axios, { CancelToken } from 'axios';
import { forEach as _forEach } from 'lodash';

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_START = 'UPLOAD_FILE_START';
export const UPLOAD_FILE_QUEUE = 'UPLOAD_FILE_QUEUE';
export const UPLOAD_FILE_PROGRESS = 'UPLOAD_FILE_PROGRESS';
export const UPLOAD_FILE_FINISHED = 'UPLOAD_FILE_FINISHED';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const UPLOAD_FINISHED = 'UPLOAD_FINISHED';
export const UPLOAD_RETRY = 'UPLOAD_RETRY';

export const CHANGE_UPLOAD_CONTEXT = 'CHANGE_UPLOAD_CONTEXT';
export const RESET_UPLOAD_CONTEXT = 'RESET_UPLOAD_CONTEXT';

export const uploadFileQueue = (uploadLink, file, args) => {
  return {
    type: UPLOAD_FILE_QUEUE,
    payload: { uploadLink, file, args },
  };
};

export const uploadFile = (uploadLink, file, args, progressCallback, finishedCallback, errorCallback, cancelToken) => {
  const data = new FormData();
  data.append('file', file);

  if (args) {
    data.append('json', JSON.stringify(args));
  }

  return {
    type: UPLOAD_FILE,
    payload: axios
      .post(uploadLink + '?_locale=' + globalLocale, data, { onUploadProgress: progressCallback, cancelToken })
      .then(finishedCallback)
      .catch(errorCallback),
  };
};

export const uploadFileStart = (file, retryAttempts) => {
  return {
    type: UPLOAD_FILE_START,
    payload: {
      file,
      retryAttempts,
    },
  };
};

export const uploadProgress = (progressEvent, file) => {
  return {
    type: UPLOAD_FILE_PROGRESS,
    payload: {
      file,
      currentBytes: progressEvent.loaded,
      totalBytes: progressEvent.total,
    },
  };
};

export const uploadFinished = (file, response) => {
  return {
    type: UPLOAD_FILE_FINISHED,
    payload: {
      file,
      response,
    },
  };
};

export const uploadError = (file, error) => {
  return {
    type: UPLOAD_FILE_ERROR,
    payload: {
      file,
      error,
    },
  };
};

export const wholeUploadFinished = () => {
  return {
    type: UPLOAD_FINISHED,
    payload: {},
  };
};

export const changeUploadContext = newContext => {
  return {
    type: CHANGE_UPLOAD_CONTEXT,
    payload: newContext,
  };
};

export const resetUploadContext = () => {
  return {
    type: RESET_UPLOAD_CONTEXT,
    payload: {},
  };
};

export const uploadRetryCount = (file, retryCount) => {
  return {
    type: UPLOAD_RETRY,
    payload: {
      file,
      retryCount,
    },
  };
};

export const filesUploadHelper = dispatch => {
  return (files, uploadLink, args = {}) => {
    _forEach(files, file => {
      fileUploadHelper(dispatch)(file, uploadLink, args);
    });
  };
};

export const fileUploadHelper = dispatch => {
  return (file, uploadLink, args) => {
    let mimeTypeTest = /^(image|video)\//;
    if (args?.contextId === 'medialibrary') {
      mimeTypeTest = /^(image\/(jpeg|jpg|png|webp)|video|application\/pdf)/;
    }

    let type = 'img';
    if (/^video\//.test(file.type)) {
      type = 'vid';
    }

    if (!mimeTypeTest.test(file.type)) {
      /* all other files */
      // add file to queue
      if (args?.contextId !== 'medialibrary') {
        dispatch(uploadFileQueue(uploadLink, file, args));
      } else {
        // dispatch upload error - the file is no image, video or pdf
        dispatch(uploadError(file, {}));
      }
    } else {
      // add file to queue
      dispatch(uploadFileQueue(uploadLink, file, { ...args, type }));
    }
  };
};

export const imageUploadHelper = dispatch => {
  return (file, uploadLink, args) => {
    const mimeTypeTest = /^image\//;

    if (!mimeTypeTest.test(file.type)) {
      // add file to queue
      dispatch(uploadFileQueue(uploadLink, file, args));
      // dispatch upload error - the file is no image
      dispatch(uploadError(file, {}));
    } else {
      // add file to queue
      fileUploadHelper(dispatch)(file, uploadLink, args);
    }
  };
};

export const videoUploadHelper = dispatch => {
  return (file, uploadLink, args) => {
    const mimeTypeTest = /^video\//;

    if (!mimeTypeTest.test(file.type)) {
      // add file to queue
      dispatch(uploadFileQueue(uploadLink, file, args));
      // dispatch upload error - the file is no image
      dispatch(uploadError(file, {}));
    } else {
      // add file to queue
      fileUploadHelper(dispatch)(file, uploadLink, args);
    }
  };
};
