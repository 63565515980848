import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as uuidLib from 'uuid';

import { mayUseAudioCall, mayUseVideoCall } from 'helpers/webrtccall';

import { intlModule, communicationVideoModule as videoComms, authModule } from '@chedri/base';

import Icon from 'components/Icon';
import IconLink from 'components/IconLink';
import Panel from 'components/panel/Panel';

import Chat from './Chat';

function ChatMessagePanel({ me, contact, customerChatId, onBack, onClose, isDisabled }) {
  const {
    first_name: firstName = 'N.',
    last_name: lastName = 'N.',
    is_online: isOnline,
    is_audio_call_available: isAudioAvailable,
    is_video_call_available: isVideoAvailable,
  } = contact || {};
  const dispatch = useDispatch();

  const messages = useSelector(intlModule.selectors.getMessages);
  const isEmployee = useSelector(authModule.selectors.isEmployee);

  const [canDoAudioCall, setCanDoAudioCall] = useState(false);
  const [canDoVideoCall, setCanDoVideoCall] = useState(false);

  const startCall = useCallback(
    type => {
      if (!contact || isDisabled) {
        return;
      }
      const uuid = uuidLib.v4();
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then(stream => {
          stream.getTracks().forEach(track => {
            track.stop();
          });

          if (isEmployee) {
            dispatch(
              videoComms.actions.startCall({
                type,
                from: { id: me.username, name: me.name },
                to: { id: contact.id, name: `${contact.first_name} ${contact.last_name}` },
                uuid,
              })
            );
            dispatch(videoComms.actions.updateCallId(uuid, contact.id));
          } else {
            dispatch(
              videoComms.actions.startCustomerCall({
                type,
                from: { id: me.username, name: me.name },
                to: { id: contact.id, name: `${firstName} ${lastName}` },
                uuid,
              })
            );
          }

          if (typeof onClose === 'function') {
            onClose();
          }
        })
        .catch(err => console.error('Error initiating call', err));
    },
    [contact, customerChatId, isDisabled]
  );

  const checkAudioAvailability = useCallback(async () => {
    try {
      const audioAvailable = await mayUseAudioCall();
      setCanDoAudioCall(audioAvailable && isAudioAvailable);
    } catch (err) {
      console.error('Error calculating audio call usage', err);
      setCanDoAudioCall(false);
    }
  }, [isAudioAvailable, setCanDoAudioCall]);

  const checkVideoAvailability = useCallback(async () => {
    try {
      const videoAvailable = await mayUseVideoCall();
      setCanDoVideoCall(videoAvailable && isVideoAvailable);
    } catch (err) {
      console.error('Error calculating video call usage', err);
      setCanDoVideoCall(false);
    }
  }, [isVideoAvailable, setCanDoVideoCall]);

  useEffect(() => {
    checkAudioAvailability();
    checkVideoAvailability();
  }, [checkAudioAvailability, checkVideoAvailability]);

  return (
    <Panel className="employee-chat box-shadow-small" style={{ height: '450px', overflow: 'hidden' }}>
      <Panel.Header
        leftAction={
          contact &&
          onBack && (
            <IconLink
              name="fa-angle-left"
              solid
              onClick={onBack}
              linkClassName="link"
              iconClassName="fs-18 v-align-middle"
            />
          )
        }
        rightAction={
          <>
            {!isDisabled && canDoAudioCall && (
              <IconLink
                name="fa-phone"
                solid
                onClick={() => startCall('audio')}
                linkClassName="link p-r-5"
                iconClassName="fa-flip-horizontal fs-18 v-align-middle"
              />
            )}
            {!isDisabled && canDoVideoCall && (
              <IconLink
                name="fa-video"
                solid
                onClick={() => startCall('video')}
                linkClassName="link p-r-5"
                iconClassName="fa-flip-horizontal fs-18 v-align-middle"
              />
            )}
            <IconLink
              name="fa-times-circle"
              solid
              onClick={onClose}
              linkClassName="link"
              iconClassName="fa-flip-horizontal fs-18 v-align-middle"
            />
          </>
        }
      >
        {contact && (
          <>
            <Icon
              name="fa-circle"
              solid={isOnline}
              iconClassName={`${isOnline ? 'text-success ' : 'text-danger '}fa-fw fs-10 m-l-15`}
              tooltip={isOnline ? messages.user_is_online : messages.user_is_offline}
              tooltipPlacement="top"
            />
            {` ${firstName} ${lastName}`}
          </>
        )}
      </Panel.Header>
      <Panel.Body className="chat-view">{contact && <Chat me={me} selectedContact={contact} />}</Panel.Body>
    </Panel>
  );
}
ChatMessagePanel.defaultProps = {};
ChatMessagePanel.propTypes = {};

export default ChatMessagePanel;
