import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import AbstractCmsElement from './AbstractCmsElement';

export default class CmsElementBarCounter extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  render() {
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const now = this.getParamValue('progress', 50);

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-bar-counter ' + shownClass + this.getResponsiveClasses() + this.getCssClasses()}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <span class="progress-title">{this.getParamValue('title', '')}</span>
        <div class="progress" style={{ backgroundColor: this.getParamValue('progressBackgroundColor', '#dddddd') }}>
          <div
            class="progress-bar"
            style={{
              width: (shown ? now : 0) + '%',
              backgroundColor: this.getParamValue('barBackgroundColor', '#48b0f7'),
              color: this.getParamValue('color', '#fff'),
            }}
          >
            {now + '%'}
          </div>
        </div>

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementBarCounter.defaultProps = {
  index: '0',
};
