export const MODAL_OPENED = 'MODAL_OPENED';
export const MODAL_CLOSED = 'MODAL_CLOSED';

export function modalOpen(modal = null) {
  return {
    type: MODAL_OPENED,
    payload: modal,
  };
}

export function modalClose(modal = null) {
  return {
    type: MODAL_CLOSED,
    payload: null,
  };
}
