import React from 'react';
import { components } from 'react-select';

export class QuestionnaireOption extends React.Component {
  render() {
    const { data } = this.props;
    return <components.Option {...this.props}>{data.name}</components.Option>;
  }
}

export class QuestionnaireValue extends React.Component {
  render() {
    const { data } = this.props;

    return <div className="Select-value">{data.name}</div>;
  }
}

export function questionnaireOptionFormatter(options) {
  return options.list.map(option => {
    return { ...option, value: option.id, label: option.name };
  });
}
