import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import IconButton from 'components/IconButton';
import { useIntl } from 'react-intl';
import { addNotification } from 'actions/NotificationsActions';
import Collapsible from '../Collapsible';
import CartWizardContactForm from './CartWizardContactForm';
import Spinner from '../../Spinner';
import { cartUpdate } from '../../../actions/CartActions';
import AddressListModal from 'components/modals/AddressListModal';

function CartWizardDelivery({ billingContactFormNeedsAttention, shippingContactFormNeedsAttention }) {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const user = useSelector(state => state.login.user);

  const [isInvoiceAddressModalVisible, setIsInvoiceAddressModalVisible] = useState(false);
  const [isShippingAddressModalVisible, setIsShippingAddressModalVisible] = useState(false);
  const [addContact, setAddContact] = useState(false);
  const [isInvoiceAddContactModalVisible, setIsInvoiceAddContactModalVisible] = useState(false);
  const [isShippingAddContactModalVisible, setIsShippingAddContactModalVisible] = useState(false);
  const [useBillingAsShippingAddress, setUseBillingAsShippingAddress] = useState(null);
  const isBillingAddressUsed = () => {
    return useBillingAsShippingAddress !== null ? useBillingAsShippingAddress : cart.use_invoice_address_for_shipping;
  };

  const validateBillingContact = useCallback(data => {
    if (!(data.addresses || []).find(addr => (addr.types || []).includes('invoice'))) {
      dispatch(addNotification(400, messages.no_invoice_address));
      return false;
    }
    return true;
  });
  const validateShippingContact = useCallback(data => {
    if (!(data.addresses || []).find(addr => (addr.types || []).includes('delivery'))) {
      dispatch(addNotification(400, messages.no_delivery_address));
      return false;
    }
    return true;
  });

  const setBillingAddress = useCallback((contact, addr) => {
    const hasShippingAddress =
      !!(contact.addresses || []).find(addr => (addr.types || []).includes('delivery')) || !!addr;
    const isShippingSameAsBilling = isBillingAddressUsed();

    dispatch(
      cartUpdate({
        invoice_address: {
          contact,
          address: addr || contact.addresses.find(addr => addr.types.includes('invoice')),
        },
        use_invoice_address_for_shipping: hasShippingAddress && isShippingSameAsBilling,
        shipping_address:
          hasShippingAddress && isShippingSameAsBilling
            ? {
              contact,
              address: addr || contact.addresses.find(addr => addr.types.includes('invoice')),
            }
            : cart.shipping_address,
      }),
    );
    setUseBillingAsShippingAddress(hasShippingAddress && isShippingSameAsBilling);
    setIsInvoiceAddContactModalVisible(false);
    setIsShippingAddContactModalVisible(false);
  });

  const setShippingAddress = useCallback((contact, addr) => {
    const isShippingSameAsBilling = isBillingAddressUsed();

    dispatch(
      cartUpdate({
        use_invoice_address_for_shipping: isShippingSameAsBilling,
        shipping_address: {
          contact,
          address: addr || contact.addresses.find(addr => addr.types.includes('delivery')),
        },
      }),
    );
    setIsShippingAddContactModalVisible(false);
    setIsInvoiceAddContactModalVisible(false);
  });

  const updateBillingAsShippingAddress = useCallback(() => {
    const isBillingUsed = !isBillingAddressUsed();

    setUseBillingAsShippingAddress(isBillingUsed);

    if (
      isBillingUsed &&
      (cart.invoice_address.contact.id !== cart.shipping_address?.contact?.id ||
        cart.invoice_address.address.id !== cart.shipping_address?.address?.id)
    ) {
      dispatch(
        cartUpdate({
          use_invoice_address_for_shipping: true,
          shipping_address: cart.invoice_address,
        }),
      );
    }
  }, [cart, useBillingAsShippingAddress]);

  const isCartPending = useSelector(state => state.cart.pending);
  const isBillingAddressEditable = user?.financial?.is_debtor === true;
  const isShippingAddressEditable = (user?.financial?.is_debtor === true ||
    (typeof cart.shipping_address?.contact?.target != 'undefined' && cart.shipping_address.contact.target === user.username)) &&
    cart.shipping_address?.contact?.id !== cart.invoice_address?.contact?.id;
  const filterPartyShippableItems = () => {
    if (isCartPending) {
      return [];
    } else {
      return (
        cart.items
          .filter(
            item =>
              item.print_type === 'print_production'
              && (item.item.type === 'item'
                || item.item.type === 'itemPrint'
              ),
          )
      );
    }
  };

  const partlyShippableItems = filterPartyShippableItems();
  return (!(cart.id)) ? (<Spinner />) : (
    <>
      <Collapsible icon="fa-file-invoice" variant="primary" label={messages.invoice_address}
                   description={messages.please_select} open>
        <div className="wizard-contact-form">
          <Row className="m-b-30">
            <Col xs={2} sm={8} />
            <Col xs={10} sm={12}>
              <IconButton
                name="fa-address-book"
                buttonClassName="primary wizard-icon-btn"
                label={messages.choose_contact}
                onClick={() => setIsInvoiceAddressModalVisible(true)}
              />
              {/*{may('ROLE_DIRECT_CUSTOMER', user.roles) && <IconButton*/}
              {/*  name="fa-plus-square"*/}
              {/*  buttonClassName="primary wizard-icon-btn m-t-5"*/}
              {/*  label={messages.add_contact}*/}
              {/*  onClick={() => setIsInvoiceAddContactModalVisible(true)}*/}
              {/*/>}*/}
            </Col>
          </Row>
          <CartWizardContactForm
            name="billing"
            contact={(cart.invoice_address && cart.invoice_address.contact) || {}}
            address={(cart.invoice_address && cart.invoice_address.address) || {}}
            target={user}
            disabled={!isBillingAddressEditable}
            contactIsUpdated={setBillingAddress}
            type="invoice"
            contactFormNeedsAttention={billingContactFormNeedsAttention}
          />
          <AddressListModal
            filterByTypes={['invoice']}
            filter={'clientProfile'}
            show={isInvoiceAddressModalVisible}
            showAddContact={false}
            onSelected={setBillingAddress}
            onClose={() => setIsInvoiceAddressModalVisible(false)}
          />
          {/*<ContactModal*/}
          {/*  show={isInvoiceAddContactModalVisible}*/}
          {/*  validate={validateBillingContact}*/}
          {/*  onSuccess={setBillingAddress}*/}
          {/*  onClose={() => setIsInvoiceAddContactModalVisible(false)}*/}
          {/*/>*/}
        </div>
      </Collapsible>
      {(partlyShippableItems.length === 0) ? (<></>) : (
        <>
          <Collapsible icon="fa-truck" variant="primary" label={messages.deliver_adress}
                       description={messages.please_select} open>
            <div className="wizard-contact-form">
              <Row className="m-b-30">
                <Col xs={12} sm={8}>
                  <label for="useBillingAsShippingAddress">
                    <input
                      id="useBillingAsShippingAddress"
                      type="checkbox"
                      name="useBillingAsShippingAddress"
                      value
                      checked={isBillingAddressUsed()}
                      onChange={updateBillingAsShippingAddress}
                    />
                    <span className="m-l-5">{messages.use_invoice_contact}</span>
                  </label>
                </Col>
              </Row>
              <Row>
                <Col class="m-b-30" md={12} xsOffset={2} xs={10} smOffset={0} sm={4}>
                  {isBillingAddressUsed() ? null : (
                    <>
                      <IconButton
                        name="fa-address-book"
                        buttonClassName="primary wizard-icon-btn"
                        label={messages.choose_contact}
                        onClick={() => setIsShippingAddressModalVisible(true)}
                      />
                      {/*<IconButton*/}
                      {/*  name="fa-plus-square"*/}
                      {/*  buttonClassName="primary wizard-icon-btn m-t-5"*/}
                      {/*  label={messages.add_contact}*/}
                      {/*  onClick={() => setIsShippingAddContactModalVisible(true)}*/}
                      {/*/>*/}
                    </>
                  )}
                </Col>
              </Row>
              {isBillingAddressUsed() ? null : (
                <>
                  <CartWizardContactForm
                    name="shipping"
                    contact={(cart.shipping_address && cart.shipping_address.contact) || {}}
                    address={(cart.shipping_address && cart.shipping_address.address) || {}}
                    target={user}
                    type="delivery"
                    disabled={!isShippingAddressEditable}
                    contactIsUpdated={setShippingAddress}
                    contactFormNeedsAttention={shippingContactFormNeedsAttention}
                  />
                  <AddressListModal
                    filterByTypes={['delivery']}
                    show={isShippingAddressModalVisible}
                    showAddContact={false}
                    onSelected={setShippingAddress}
                    onClose={() => setIsShippingAddressModalVisible(false)}
                  />
                  {/*<ContactModal*/}
                  {/*  show={isShippingAddContactModalVisible}*/}
                  {/*  validate={validateShippingContact}*/}
                  {/*  onSuccess={setShippingAddress}*/}
                  {/*  onClose={() => setIsShippingAddContactModalVisible(false)}*/}
                  {/*/>*/}
                </>
              )}
            </div>
          </Collapsible>
        </>
      )}
    </>
  );
}

export default CartWizardDelivery;
