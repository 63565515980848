import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Grid, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import DocMeta from 'components/DocMeta';

import { formStatusDispatchHelper } from 'actions/FormStatusActions';
import { addNotification } from 'actions/NotificationsActions';

import Data from 'components/Data';
import Entity from 'components/Entity';

import FormErrors from 'components/forms/FormErrors';
import FormInputPassword from 'components/forms/FormInputPassword';

import EntityStateUpdater from 'component/EntityStateUpdater';

import { entityMetaMapping } from 'js/constants';

class NewPasswordAfterReset extends EntityStateUpdater {
  constructor(props) {
    super(props);

    this.state = {
      token: props.match.params.token,
      entityLoaded: false,
      entityChanged: false,
    };

    this.entity = new Entity();
    // if we get service data from the outside, we can pass it on to our data entity
    this.entity.setData({});

    if (props.meta[entityMetaMapping.password] !== undefined && !props.meta[entityMetaMapping.password].pending) {
      this.entity.setMeta(props.meta, entityMetaMapping.password);

      this.state = {
        ...this.state,
        entityLoaded: true,
        entity: this.entity,
      };
    }
  }

  componentDidUpdate = prevProps => {
    const {
      intl: { messages },
      appIntl,
      history,
      formStatus,
      user,
      doNotify,
      meta,
    } = this.props;

    if (prevProps.formStatus.pending && !formStatus.pending && !formStatus.hasError && user.logged) {
      // forward to account
      history.push(appIntl.urlPrefix + 'account');

      // notify
      doNotify(1, messages.password_changed_after_reset);
    }

    if (
      meta[entityMetaMapping.password] !== undefined &&
      !meta[entityMetaMapping.password].pending &&
      !this.entity.getMetaLoaded()
    ) {
      this.entity.setMeta(meta, entityMetaMapping.password);
      if (this.entity.getDataLoaded()) {
        this.setState({ entityLoaded: true, entity: this.entity });
      }
    }
  };

  onSubmit = e => {
    e.preventDefault();

    const { appIntl, onSubmit } = this.props;
    const { token } = this.state;

    this.setState(
      prevState => ({ entity: prevState.entity.validate() }),
      () => {
        if (this.state.entity.isValid) {
          onSubmit({ ...this.state.entity.processedData, token }, appIntl.urlPrefix);
        }
      }
    );
  };

  render() {
    const {
      intl: { messages },
      formStatus,
    } = this.props;
    const { entityLoaded, entity } = this.state;

    const tags = [
      { name: 'title', content: messages.meta.new_password_after_reset.title },
      { name: 'description', content: messages.meta.new_password_after_reset.description },
      { itemProp: 'name', content: messages.meta.new_password_after_reset.name },
      { itemProp: 'description', content: messages.meta.new_password_after_reset.description },
      // {itemProp: 'image', content: "http://www.example.com/image.jpg"}
    ];

    return (
      <DocMeta tags={tags}>
        <div class="content">
          <Data dataName="password" /> {/* fetch meta data */}
          {entityLoaded && (
            <>
              <Grid fluid>
                <Row class="p-t-30 p-b-40">
                  <Col xs={12} md={6} lg={4} mdOffset={3} lgOffset={4}>
                    <h1>{messages.password_assistance}</h1>

                    <Row>
                      <Col xs={12}>
                        <section class="widgit">
                          <header>
                            <h4>
                              <i class="fa fa-lock" /> {messages.set_new_password}
                            </h4>
                          </header>
                          <Row>
                            <Col xs={12}>
                              <p>{messages.enter_new_password}</p>
                            </Col>
                          </Row>
                        </section>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <section class="widgit">
                          <form onSubmit={this.onSubmit}>
                            <FormErrors formStatus={formStatus} />

                            <div class="panel panel-transparent no-margin">
                              <div class="panel-body p-l-0 p-r-0">
                                <div class="form-group-attached">
                                  <FormInputPassword
                                    type="password"
                                    label={messages.password}
                                    name="password"
                                    value={entity.getPassword()}
                                    meta={entity.getPasswordMetaInformation()}
                                    validation={entity.getPasswordValidationResult()}
                                    onChange={this.updateEntity}
                                    disabled={formStatus.pending}
                                  />
                                  <FormInputPassword
                                    type="password"
                                    label={messages.repeat_password}
                                    name="repeatPassword"
                                    value={entity.getRepeatPassword()}
                                    meta={entity.getRepeatPasswordMetaInformation()}
                                    validation={entity.getRepeatPasswordValidationResult()}
                                    onChange={this.updateEntity}
                                    disabled={formStatus.pending}
                                  />
                                </div>

                                <div class="text-right m-t-15">
                                  <Button
                                    bsSize="lg"
                                    block
                                    disabled={formStatus.pending}
                                    type="submit"
                                    bsStyle="primary"
                                  >
                                    <i class={'fa-fw ' + (formStatus.pending ? ' fa fa-circle-notch fa-spin' : '')} />{' '}
                                    {messages.save}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </section>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Grid>
            </>
          )}
        </div>
      </DocMeta>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    formStatus: state.formStatus.forgotPasswordReset,
    user: state.login.user,
    meta: state.entityMeta,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (args, localePrefix) => {
      formStatusDispatchHelper(
        'forgotPasswordReset',
        localePrefix + 'api/requestpasswordreset/' + args.token + '.json',
        args,
        'put',
        dispatch
      );
    },
    doNotify: (code, text) => {
      dispatch(addNotification(code, text));
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(NewPasswordAfterReset)
  )
);
