import {
  UPLOAD_FILE_QUEUE,
  UPLOAD_FILE_START,
  UPLOAD_FILE_PROGRESS,
  UPLOAD_FILE_FINISHED,
  UPLOAD_FILE_ERROR,
  UPLOAD_RETRY,
  CHANGE_UPLOAD_CONTEXT,
  RESET_UPLOAD_CONTEXT,
} from 'actions/UploadActions';

import { createObjectURL } from 'helpers/upload';

const initialState = {
  isUploading: false,
  uploadingFile: null,
  overallProgress: 0,
  uploads: 0,
  errors: 0,
  networkError: false,
  retryCount: 0,
  newNotifications: 0,
  notifications: [],
  files: [],
  context: { type: 'global', uploadLink: '/api/images/latest.json' },
};

const getFileContext = context => {
  switch (context.type) {
    case 'gallery':
      return context.type + ':' + context.galleryId;

    case 'category':
      return context.type + ':' + context.categoryId;

    case 'item':
      return context.type + ':' + context.itemId;

    case 'itemVariant':
      return context.type + ':' + context.variantId;

    case 'companyLocation':
      return context.type + ':' + context.locationId;

    case 'lead':
      return context.type + ':' + context.leadId;

    case 'leadHandover':
      return context.type + ':' + context.leadId;

    case 'plugin_img':
      return context.type + ':' + context.whiteLabelPluginId;

    case 'mediaFile':
      return context.type;

  }

  return context.type;
};

export default function upload(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FILE_QUEUE: {
      const { file, uploadLink, args } = action.payload;

      let found = false;
      let files = state.files.slice();
      for (let i = 0; i < files.length; i++) {
        if (files[i].file === file && files[i].uploadLink === uploadLink) {
          files[i].isUploading = false;

          found = true;
          break;
        }
      }

      if (!found) {
        files.push({
          file,
          uploadLink,
          args,
          thumbnail: createObjectURL(file),
          isUploading: false,
          isUploaded: false,
          progress: 0,
          error: 0,
          context: getFileContext(state.context),
        });
      }

      return {
        ...state,
        files,
      };
    }

    case UPLOAD_FILE_START: {
      const { file, retryAttempts } = action.payload;

      let files = state.files.slice();
      for (let i = 0; i < files.length; i++) {
        if (files[i].file === file) {
          files[i].isUploading = true;
          files[i].networkError = retryAttempts ? true : false;
          files[i].retryCount = retryAttempts;
        }
      }

      return {
        ...state,
        isUploading: true,
        files: files,
        uploadingFile: file,
      };
    }

    case UPLOAD_FILE_PROGRESS: {
      let files = state.files.slice();
      let totalBytes = 0;
      let currentBytes = 0;
      for (let i = 0; i < files.length; i++) {
        if (files[i].file === action.payload.file) {
          files[i].isUploading = true;
          files[i].progress = (action.payload.currentBytes / action.payload.totalBytes) * 100;
        }
        if (files[i].isUploading) {
          totalBytes += action.payload.totalBytes;
          currentBytes += action.payload.currentBytes;
        }
      }

      return {
        ...state,
        files,
        overallProgress: (currentBytes / totalBytes) * 100,
      };
    }

    case UPLOAD_FILE_FINISHED: {
      const { file, response } = action.payload;
      let isUploading = false; // global upload
      let files = state.files.slice();
      let newNotifications = state.newNotifications;
      for (let i = 0; i < files.length; i++) {
        if (files[i].file === file) {
          files[i] = {
            ...files[i],
            isUploading: false,
            isUploaded: true,
            progress: 100,
            response: response.data,
          };
        } else if (files[i].isUploading) {
          isUploading = true;
        }
      }
      if (!isUploading) {
        newNotifications++;
      }

      return {
        ...state,
        isUploading,
        files,
        newNotifications,
        uploads: state.uploads + 1,
        uploadingFile: null,
      };
    }

    case UPLOAD_FILE_ERROR: {
      const { file, status } = action.payload;

      let isUploading = false; // global upload
      let files = state.files.slice();
      let newNotifications = state.newNotifications;
      for (let i = 0; i < files.length; i++) {
        if (files[i].file === file) {
          files[i].isUploading = false;
          files[i].isUploaded = false;
          files[i].progress = 0;
          files[i].error = status;
        } else if (files[i].isUploading) {
          isUploading = true;
        }
      }
      if (!isUploading) {
        newNotifications++;
      }

      return {
        ...state,
        isUploading,
        files,
        newNotifications,
        errors: state.errors + 1,
      };
    }

    case UPLOAD_RETRY: {
      const { file, retryCount } = action.payload;

      let files = state.files.slice();
      let fileIndex = null;

      for (let i = 0; i < files.length; i++) {
        if (files[i].file === file) {
          files[i].networkError = true;
          files[i].retryCount = retryCount;
          fileIndex = i;

          break;
        }
      }

      if (fileIndex) {
        const fileData = files.splice(fileIndex, 1)[0];
        files.push(fileData);
      }

      return {
        ...state,
        files,
      };
    }

    case 'NOTIFICATION_CENTER_OPENED': {
      return {
        ...state,
        newNotifications: 0,
      };
    }

    case CHANGE_UPLOAD_CONTEXT: {
      return {
        ...state,
        context: action.payload,
      };
    }

    case RESET_UPLOAD_CONTEXT: {
      return {
        ...state,
        context: { type: 'global', uploadLink: '/api/images/latest.json' },
      };
    }
  }

  return state;
}
