import React from 'react';
import { FormattedDate } from 'react-intl';
import { Clearfix } from 'react-bootstrap';

import { getDescriptionInCurrentLanguage } from 'helpers/intl';
import {
  getPower,
  getHorsePower,
  getGearbox,
  getMileage,
  getFirstRegistration,
  getPaintColor,
  getPaintColorHex,
  getPaintColorManufacturer,
  getInteriorMaterial,
  getEnvironmentBadge,
  getFuelType,
  getCo2Emissions,
  getFuelConsumptionCombined,
  getFuelConsumptionUrban,
  getFuelConsumptionExtraUrban,
  getEnergyEfficiencyClass,
} from 'helpers/items';

import RichEditorContent from 'components/RichEditorContent';

import EnergyLabel from 'components/layout/itemDetails/carDetailsRender/EnergyLabel';

class DetailsDescription extends React.Component {
  getHighlights = () => {
    const { messages, appIntl, data } = this.props;

    const powerKw = getPower(data, appIntl);
    const manufacturerColorName = getPaintColorManufacturer(data, appIntl);
    const firstRegistration = getFirstRegistration(data, appIntl);

    const gfxStyle = {
      style: {
        width: '17px',
        marginRight: '3px',
      },
    };

    return [
      {
        label: messages.car_fueltype,
        value: getFuelType(data, appIntl) ? getFuelType(data, appIntl) : '-',
        gfx: <i className="chedri-fuel" {...gfxStyle} />,
      },
      {
        label: messages.car_power,
        value:
          powerKw !== undefined
            ? powerKw + ' kW (' + getHorsePower(powerKw) + ' ' + messages.car_power_unit + ')'
            : '-',
        gfx: <i className="chedri-speedometer" {...gfxStyle} />,
      },
      {
        label: messages.car_gear,
        value: getGearbox(data, appIntl) ? getGearbox(data, appIntl) : '-',
        gfx: <i className="chedri-gearshift" {...gfxStyle} />,
      },
      {
        label: messages.combined,
        title: messages.car_fuel_consumption + ' (' + messages.combined + ')',
        value: getFuelConsumptionCombined(data, appIntl) + ' ' + messages.car_fuel_consumption_unit,
        gfx: <i className="fas fa-tint" {...gfxStyle} />,
      },
      {
        label: messages.color,
        title: manufacturerColorName || messages.color,
        value: getPaintColor(data, appIntl) ? getPaintColor(data, appIntl) : '-',
        gfx: (
          <span
            className="inline"
            style={{
              margin: '0 auto',
              width: '16px',
              height: '15px',
              background: getPaintColorHex(data) + ' url(/img/paintjob_effect.png)',
              backgroundSize: 'cover',
            }}
          />
        ),
      },
      {
        label: messages.car_interior_material,
        value: getInteriorMaterial(data, appIntl) ? getInteriorMaterial(data, appIntl) : '-',
        gfx: <i className="chedri-safety-seat" {...gfxStyle} />,
      },
      {
        label: messages.first_registration,
        value: firstRegistration ? (
          <FormattedDate value={new Date(firstRegistration.substring(0, 10))} year="numeric" month="2-digit" />
        ) : (
          '-'
        ),
        gfx: <i className="far fa-calendar-alt" {...gfxStyle} />,
      },
      {
        label: messages.mileage,
        value: getMileage(data, appIntl) ? getMileage(data, appIntl) + ' km' : '-',
        gfx: <i className="chedri-tire" {...gfxStyle} />,
      },
    ];
  };

  render() {
    const { messages, appIntl, data } = this.props;

    let textDescription = getDescriptionInCurrentLanguage(data.description, appIntl);

    if (textDescription === undefined) {
      textDescription = '';
    }

    return (
      <div className="item-details-description">
        <Clearfix className="fs-14">
          <div className="lh-16 v-align-middle no-overflow b-rad-lg">
            {this.getHighlights().map((highlight, i) => {
              const res = [
                <div
                  className="inline text-nowrap col-xs-6 no-padding"
                  title={highlight.title ? highlight.title : highlight.label}
                  key={highlight.label}
                >
                  <div className="bg-white padding-10" style={{ marginRight: '1px', marginBottom: '1px' }}>
                    <div className="relative p-l-25">
                      <div className="top-left">{highlight.gfx}</div> {highlight.value}
                    </div>
                  </div>
                </div>,
              ];

              if ((i + 1) % 2 === 0) {
                res.push(<Clearfix key={'c' + highlight.label} />);
              }

              return res;
            })}
          </div>
        </Clearfix>

        <hr />

        <RichEditorContent content={'<div class="m-t-15 m-b-15">' + textDescription + '</div>'} />

        <hr />

        <div className="m-t-15">
          <p className="heading-paragraph">{messages.environment}</p>
          <dl className="dl-horizontal">
            <dt>{messages.fuel_type}:</dt>
            <dd>{getFuelType(data, appIntl) ? getFuelType(data, appIntl) : '-'}</dd>

            <dt>{messages.car_fuel_consumption}:</dt>
            <dd>
              {getFuelConsumptionCombined(data, appIntl) ? getFuelConsumptionCombined(data, appIntl) : '-'}{' '}
              {messages.car_fuel_consumption_unit} ({messages.combined_short})
              <br />
              {getFuelConsumptionUrban(data, appIntl) ? getFuelConsumptionUrban(data, appIntl) : '-'}{' '}
              {messages.car_fuel_consumption_unit} ({messages.urban})
              <br />
              {getFuelConsumptionExtraUrban(data, appIntl) ? getFuelConsumptionExtraUrban(data, appIntl) : '-'}{' '}
              {messages.car_fuel_consumption_unit} ({messages.extra_urban})
            </dd>

            <dt>{messages.car_co2_emissions}:</dt>
            <dd>
              {getCo2Emissions(data, appIntl) ? getCo2Emissions(data, appIntl) : '-'} {messages.car_co2_emissions_unit}{' '}
              ({messages.combined_short})
            </dd>

            <dt>{messages.car_environment_badge}:</dt>
            <dd>{getEnvironmentBadge(data, appIntl) ? getEnvironmentBadge(data, appIntl) : '-'}</dd>
          </dl>

          <div className="p-l-20 p-r-40">
            <EnergyLabel label={getEnergyEfficiencyClass(data, appIntl)} />
          </div>
        </div>
      </div>
    );
  }
}

export default DetailsDescription;
