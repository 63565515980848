export const BLOCK_FORM = 'BLOCK_FORM';
export const FORM_BLOCKED = 'FORM_BLOCKED';
export const FORM_VIEWED = 'FORM_VIEWED';
export const RELEASE_FORM = 'RELEASE_FORM';
export const REQUEST_FORM_RELEASE = 'REQUEST_FORM_RELEASE';
export const REQUEST_TAB_SWITCH = 'REQUEST_TAB_SWITCH';
export const ACCEPT_RELEASE_REQUEST = 'ACCEPT_RELEASE_REQUEST';
export const REJECT_RELEASE_REQUEST = 'REJECT_RELEASE_REQUEST';

export const blockForm = (formType, formId) => {
  return {
    type: BLOCK_FORM,
    payload: { formType, formId },
  };
};

export const formBlocked = (blockedBy, viewers) => {
  return {
    type: FORM_BLOCKED,
    payload: { blockedBy, viewers },
  };
};

export const formViewed = (blockedBy, viewers) => {
  return {
    type: FORM_VIEWED,
    payload: { blockedBy, viewers },
  };
};

export const releaseForm = (formType, formId) => {
  return {
    type: RELEASE_FORM,
    payload: { formType, formId },
  };
};

export const requestFormRelease = (formType, formId) => {
  return {
    type: REQUEST_FORM_RELEASE,
    payload: { formType, formId },
  };
};

export const requestTabSwitch = (formType, formId) => {
  return {
    type: REQUEST_TAB_SWITCH,
    payload: { formType, formId },
  };
};

export const acceptReleaseRequest = (formType, formId) => {
  return {
    type: ACCEPT_RELEASE_REQUEST,
    payload: { formType, formId },
  };
};

export const rejectReleaseRequest = (formType, formId) => {
  return {
    type: REJECT_RELEASE_REQUEST,
    payload: { formType, formId },
  };
};
