import React from 'react';
import { connect } from 'react-redux';
import { Badge } from 'react-bootstrap';
import { components } from 'react-select';
import { forEach as _forEach } from 'lodash';

import { getNameInCurrentLanguage } from 'helpers/intl';

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

class ModelGroupOptionWithProps extends React.Component {
  render() {
    const { data, appIntl } = this.props;
    return (
      <components.Option {...this.props}>
        {!!data.id && <Badge className="m-r-10 text-uppercase p-l-10 p-r-10">{appIntl.displayIntlForm}</Badge>}
        {getNameInCurrentLanguage(data.name, appIntl)}
      </components.Option>
    );
  }
}
const ModelGroupOption = connect(mapStateToProps)(ModelGroupOptionWithProps);

class ModelGroupValueWithProps extends React.Component {
  render() {
    const { data, appIntl } = this.props;

    return (
      <div class="Select-value">
        {!!data.id && <Badge className="m-r-10 text-uppercase p-l-10 p-r-10">{appIntl.displayIntlForm}</Badge>}
        {getNameInCurrentLanguage(data.name, appIntl)}
      </div>
    );
  }
}
const ModelGroupValue = connect(mapStateToProps)(ModelGroupValueWithProps);

const modelGroupOptionFormatter = options => {
  return options.list.map(option => {
    return modelGroupSingleOptionFormatter(option);
  });
};

const modelGroupSingleOptionFormatter = (option = null) => {
  let label = '';
  _forEach(option.name, langName => {
    if (langName.name !== undefined) {
      label += langName.name + ' ';
    }
  });
  return { ...option, value: option.id, label };
};

const modelGroupOptionFromEntity = modelGroup => {
  return !modelGroup
    ? null
    : modelGroupSingleOptionFormatter({
        id: modelGroup.getId(),
        name: modelGroup.getName().map(langName => {
          return { lang: langName.getLang(), name: langName.getName() };
        }),
      });
};

export {
  ModelGroupValue,
  ModelGroupOption,
  modelGroupOptionFormatter,
  modelGroupSingleOptionFormatter,
  modelGroupOptionFromEntity,
};
