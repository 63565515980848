import React from 'react';
import { injectIntl } from 'react-intl';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { find as _find } from 'lodash';

import { getChatterUserAvatarInfos } from 'helpers/clients';
import { getThumbFile } from 'helpers/items';
import { getColorForString } from 'helpers/charts';
import { getTextColor } from 'components/forms/autocomplete/Color';

import RoundImage from 'components/layout/RoundImage';

const avatarSize = 39;

class ViewersList extends React.Component {
  getAvatar = username => {
    const { user, activeForms, chatUsers } = this.props;

    let className = 'inline b-a b-rad-xl b-thick ';
    if (username === activeForms.blockedBy) {
      className += 'b-primary ';
    } else {
      className += 'b-white ';
    }

    if (user.username === username) {
      let avatarThumbLink = null;
      if (user.profile.avatar) {
        const imageThumb = getThumbFile(user.profile.avatar);
        avatarThumbLink = imageThumb ? imageThumb.web_link : null;
      }

      const avatarColor = '#' + getColorForString(user.profile.first_name + user.profile.last_name);
      const avatarTextColor = getTextColor(avatarColor);
      const tooltip = (
        <Tooltip>
          {user.profile.first_name} {user.profile.last_name}
        </Tooltip>
      );

      if (avatarThumbLink) {
        return (
          <OverlayTrigger placement="top" overlay={tooltip}>
            <span className="inline">
              <RoundImage size={avatarSize} usePixels url={avatarThumbLink} className={className} />
            </span>
          </OverlayTrigger>
        );
      }

      return (
        <OverlayTrigger placement="top" overlay={tooltip}>
          <span style={{ opacity: '.6' }} className="inline">
            <RoundImage size={avatarSize} usePixels hexColor={avatarColor} className={className}>
              <h6 style={{ color: avatarTextColor }} class="bold">
                {user.profile.first_name.substring(0, 1)}&nbsp;
                {user.profile.last_name.substring(0, 1)}
              </h6>
            </RoundImage>
          </span>
        </OverlayTrigger>
      );
    }

    const chatUser = _find(chatUsers, ['id', username]);
    if (chatUser) {
      const avatarInfos = getChatterUserAvatarInfos(chatUser);
      const tooltip = <Tooltip>{chatUser.name}</Tooltip>;

      if (avatarInfos.avatarThumbLink) {
        return (
          <OverlayTrigger placement="top" overlay={tooltip}>
            <span className="inline">
              <RoundImage size={avatarSize} usePixels url={avatarInfos.avatarThumbLink} className={className} />
            </span>
          </OverlayTrigger>
        );
      }

      return (
        <OverlayTrigger placement="top" overlay={tooltip}>
          <span style={{ opacity: '.6' }} className="inline">
            <RoundImage size={avatarSize} usePixels hexColor={avatarInfos.avatarColor} className={className}>
              <h6 style={{ color: avatarInfos.avatarTextColor }} className="bold">
                {chatUser.first_name.substring(0, 1)}&nbsp;
                {chatUser.last_name.substring(0, 1)}
              </h6>
            </RoundImage>
          </span>
        </OverlayTrigger>
      );
    }

    const avatarColor = '#' + getColorForString(username);
    const avatarTextColor = getTextColor(avatarColor);

    return (
      <span style={{ opacity: '.6' }} className="inline">
        <RoundImage size={avatarSize} usePixels hexColor={avatarColor} className={className}>
          <h6 style={{ color: avatarTextColor }} class="bold">
            {username.substring(0, 1)}
          </h6>
        </RoundImage>
      </span>
    );
  };

  render() {
    const {
      intl: { messages },
      activeForms,
    } = this.props;

    const counter = activeForms.viewers.length;

    return (
      <span className="inline m-t-5">
        {/* start with the person that is currently editing */}
        <span className="inline relative" style={{ zIndex: counter + 1 }}>
          {this.getAvatar(activeForms.blockedBy)}
        </span>

        {/* now display all other users */}
        {activeForms.viewers
          .filter(viewer => viewer !== activeForms.blockedBy)
          .map((viewer, i) => {
            return (
              <span
                className="inline relative"
                style={{ marginLeft: avatarSize * -0.2 + 'px', zIndex: counter - i }}
                key={viewer}
              >
                {this.getAvatar(viewer)}
              </span>
            );
          })}
      </span>
    );
  }
}

export default injectIntl(ViewersList);
