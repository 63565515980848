import React from 'react';
import { connect } from 'react-redux';

import _, { find as _find } from 'lodash';
import ReactDOM from "react-dom";
import {Button, Col, Row} from "react-bootstrap";
import FormAutocomplete from "../../../components/forms/FormAutocomplete";
import FormInput from "../../../components/forms/FormInput";
import FormFile from "../../../components/forms/FormFile";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";
import FormSwitchery from "components/forms/FormSwitchery";
const ContextModal = ({children}) => {
  return ReactDOM.createPortal(
    children,
    document.getElementById('context-modal')
  );
};

class VideoElement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      video: [],
      videoEdit: [],
      showVideoEdit: false,
      videoFile: undefined,
    }

  }

  updateAutoplay = (name, value) => {
    const { videoEdit} = this.state;
    videoEdit[3] = value;
    this.setState({videoEdit: videoEdit})
  }

  updateMuted = (name, value) => {
    const { videoEdit} = this.state;
    videoEdit[4] = value;
    this.setState({videoEdit: videoEdit})
  }

  setVideo = () => {
    const { videoEdit, videoFile} = this.state;
    const { id, attributes, updateParam, imageUpload } = this.props;

    let param = videoEdit[0] + '#aaa#' + videoEdit[1] + '#aaa#' + videoEdit[2] + '#aaa#' + videoEdit[3] + '#aaa#' + videoEdit[4]
    const overflow = false;
    const default_image = false;
    const errors = [{overflow, default_image}];
    updateParam(id, attributes['data-name'], param, errors);
    if(videoEdit[0]==='own' && videoFile){
      imageUpload(id, videoFile, 'video', attributes['data-name'], null, 0, 0);
    }
    this.setState({video: videoEdit, showVideoEdit: false, videoFile: undefined}, () => {});
  }

  getVideo = () => {
    const { name: Tag, attributes, params } = this.props;
    let video = []
    const param = _find(params, ['name', attributes['data-name']]);
    if(param) {
        video = param.value.split('#aaa#');
    }

    this.setState(
      {video: video, videoEdit: video}, () => {  });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.params[0] && prevProps.params[0]?.value !== this.props.params[0]?.value) {
      this.getVideo();
    }
  }

  componentDidMount = () => {
    this.getVideo();
  }

  showVideoEdit = () => {
    this.setState({showVideoEdit: true});
  }

  closeVideoEdit = () => {
    this.setState({showVideoEdit: false});
  }

  getVideoContainer = (video) => {

    let videoId = [];
    let cleanedVideoId = [];

    if(video[0] === "youtube"){
      videoId = video[1].split("v=");
    }
    else if(video[0] === "vimeo")
    {
      if(video[1].search("video")>0){
        videoId = video[1].split("video/");
      }
      else{
        videoId = video[1].split("vimeo.com/")
      }

    }
    else if(video[0] === "twitch")
    {
      videoId = video[1].split("videos/");
    }

    if(videoId[1] !== undefined){
      cleanedVideoId = videoId[1].split("&");
    }

    if(cleanedVideoId[0] !== undefined){
      video[1] = cleanedVideoId[0];
      this.setState({video: video}, () => {  });
    }


    return (
      <div className="video-container">
        {video[0] === 'youtube' &&
        <iframe src={"https://www.youtube-nocookie.com/embed/" + video[1] + "?" + video[2]}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
                allowFullScreen></iframe>
        }
        {video[0] === 'vimeo' &&
        <>
          <iframe src={"https://player.vimeo.com/video/" + video[1] + "?" + video[2]}
                  frameBorder="0"
                  allow="autoplay; fullscreen; accelerometer; clipboard-write; encrypted-media; gyroscope;"
                  allowFullScreen></iframe>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </>
        }
        {video[0] === 'twitch' &&
        //<iframe src={"https://player.twitch.tv/?channel=" + video[1] + "&parent=" + window.location.hostname} frameBorder="0"
        <iframe src={"https://player.twitch.tv/?video=" + video[1] + "&parent=" + window.location.hostname} frameBorder="0"
                allowFullScreen="true" scrolling="no"></iframe>

        }
        {(video[0] === 'link' || video[0] === 'own') &&
          <video src={video[1]} crossorigin="anonymous" controls="controls" controlslist="nodownload" />
        }
      </div>
    )
  }

  setFile = (name, value) => {
    this.setState({videoFile:value[0]});
    const reader = new FileReader();
    reader.onloadend = (e) => {
      const {videoEdit} = this.state;
      let buffer = e.target.result;
      let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'video/mp4' });
      let url = window.URL.createObjectURL(videoBlob);
      videoEdit[1] = url ;
      this.setState({videoEdit:videoEdit});
    };
    reader.readAsArrayBuffer(value[0]);

  }

  changeProvider = (name, value) => {
    const {videoEdit} = this.state;
    videoEdit[0] = value;
    videoEdit[1] = '';
    this.setState({videoEdit: videoEdit});
  }


  setUrl = (name, value) => {
    const {videoEdit} = this.state;
    videoEdit[1] = value;
    this.setState({videoEdit: videoEdit});
  }

  render() {
    const { name: Tag, attributes, params, appIntl, defaultTimezone } = this.props;
    const { video, videoEdit, showVideoEdit } = this.state;
    const {intl: { messages }} = this.props;

    return (
      <>
        <Tag {...attributes} onClick={this.showVideoEdit} data-tip={messages.editor.video_settings} data-for="top">
          <div className="wrap">
            {video.length>0 ?
              this.getVideoContainer(video)
              :
              <p>{messages.editor.add_video}</p>
            }
          </div>
        </Tag>
      {showVideoEdit && (
        <ContextModal>
          <div id="contextModalWrap">
            <div id="contextModalStage" className="row">
              <Row className="h-45">
                <Col md={4}>
                  <FormAutocomplete
                    name="provider"
                    label={messages.editor.choice_video_provider}
                    options={[
                      { label: '-', value: '' },
                      { label: 'Youtube', value: 'youtube' },
                      { label: 'Vimeo', value: 'vimeo' },
                      { label: 'Twitch', value: 'twitch' },
                      { label: 'Video Link', value: 'link' },
                      { label: messages.editor.upload_video, value: 'own' },
                    ]}
                    value={videoEdit[0] || ''}
                    onChange={this.changeProvider}



                  />
                  {videoEdit[0] && videoEdit[0] !== 'own' &&
                    <FormInput
                      name="externId"
                      label={videoEdit[0] === 'link' ? 'URL zur Video-Datei' : "URL von " + videoEdit[0].charAt(0).toUpperCase() + videoEdit[0].slice(1)}
                      value={videoEdit[1] || ''}
                      onChange={this.setUrl}

                    />
                  }
                  {videoEdit[0] === 'own' &&
                      <FormFile
                        label={messages.editor.choice_mp4_file}
                        name={'upload'}
                        onChange={this.setFile}
                        allowTypes="video/mp4"
                      />
                  }
                  {(videoEdit[0] === 'own' || videoEdit[0] === 'link') &&
                    <>
                      <p>{messages.editor.autoplay_info}</p>
                      <FormSwitchery
                        name="autoplay"
                        checked={this.state.videoEdit.length > 2 ? videoEdit[3] : false}
                        infoText={messages.editor.autoplay_info}
                        value
                        label={messages.editor.set_autoplay}
                        onChange={(name, value)=>this.updateAutoplay(name, value)}
                      />
                        <FormSwitchery
                        name="muted"
                        checked={this.state.videoEdit.length > 3 ? videoEdit[4] : false}
                        infoText=""
                        value
                        label={messages.editor.set_muted}
                        onChange={(name, value)=>this.updateMuted(name, value)}
                        />
                    </>
                  }
                </Col>
                <Col md={8} className="h-100">
                  <div style={{minHeight: '50%', border: '2px dashed #ccc', padding: '10px'}}>
                    <p>{messages.editor.edition_preview}</p>
                    {videoEdit[0] && videoEdit[1] && this.getVideoContainer(videoEdit)}
                  </div>
                </Col>
              </Row>

              <Row className="text-right">
                <Button bsStyle='success' bsSize='large' onClick={this.setVideo}>{messages.editor.take_on}</Button>{' '}
                <Button bsStyle='danger' bsSize='large' onClick={this.closeVideoEdit}>{messages.editor.abort}</Button>
              </Row>
            </div>
          </div>
        </ContextModal>
      )}
    </>
    )
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};


export default withRouter(
  injectIntl(
    connect(
      mapStateToProps
    )(VideoElement)
  )
);
