import React from 'react';
import { FormattedNumber } from 'react-intl';
import DoubleEuroIcon from '../../DoubleEuroIcon';

function CartWizardStepBudgetHint({
  title,
  availableBudgetLabel,
  availableBudget,
  leftBudgetLabel,
  leftBudget,
  currency,
  className,
}) {
  if (!availableBudget) {
    return null;
  }

  return (
    <div className={`cart-budget-hint ${className || ''}`}>
      <DoubleEuroIcon className="m-r-10" />
      <span className="cart-budget-hint__title">{title}</span>
      <div className="cart-budget-hint__details">
        <div className="cart-budget-hint__details-row">
          <span>{availableBudgetLabel}:</span>
          <span>
            <FormattedNumber value={availableBudget} style="currency" currency={currency} />
          </span>
        </div>
        <div className="cart-budget-hint__details-row">
          <span>{leftBudgetLabel}:</span>
          <span>
            <FormattedNumber value={leftBudget} style="currency" currency={currency} />
          </span>
        </div>
      </div>
    </div>
  );
}

CartWizardStepBudgetHint.defaultProps = {};

export default CartWizardStepBudgetHint;
