import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedDate } from 'react-intl';
import { Row, Col } from 'react-bootstrap';

import {
  getPaintColorManufacturer,
  getPower,
  getHorsePower,
  getGearbox,
  getMileage,
  getFirstRegistration,
  getFuelType,
} from 'helpers/items';

class SummaryListCarInfos extends React.Component {
  render() {
    const {
      intl: { messages },
      appIntl,
      item,
    } = this.props;

    const powerKw = getPower(item, appIntl);
    const firstRegistration = getFirstRegistration(item, appIntl);

    const firstRegistrationDate = firstRegistration ? (
      <FormattedDate value={new Date(firstRegistration.substring(0, 10))} year="numeric" month="2-digit" />
    ) : (
      '-'
    );

    return (
      <Row class="no-margin">
        <Col xs={6} class="no-padding" title={messages.car_color_manufacturer}>
          <div class="overflow-ellipsis">
            <i class="fal fa-palette" />
            {getPaintColorManufacturer(item, appIntl)}
          </div>
        </Col>
        <Col xs={6} class="no-padding" title={messages.car_power}>
          <i class="chedri-engine" />
          {powerKw} kW ({getHorsePower(powerKw)} {messages.car_power_unit})
        </Col>
        <Col xs={6} class="no-padding" title={messages.mileage}>
          <i class="chedri-tire" />
          {getMileage(item, appIntl) ? getMileage(item, appIntl) : '-'} km
        </Col>
        <Col xs={6} class="no-padding" title={messages.car_fueltype}>
          <i class="chedri-fuel" />
          {getFuelType(item, appIntl)}
        </Col>
        <Col xs={6} class="no-padding" title={messages.car_gear}>
          <i class="chedri-gearshift" />
          {getGearbox(item, appIntl) ? getGearbox(item, appIntl) : '-'}
        </Col>
        <Col xs={6} class="no-padding" title={messages.first_registration}>
          <i class="fal fa-calendar-alt" />
          {firstRegistrationDate}
        </Col>

        {/* <h5 class="text-info">{item.owner_company_name}</h5> */}
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default injectIntl(connect(mapStateToProps)(SummaryListCarInfos));
