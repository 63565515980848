import axios from 'axios';

export const TRANSLATE_PAGE = 'TRANSLATE_PAGE';
export const TRANSLATE_PAGE_PENDING = 'TRANSLATE_PAGE_PENDING';
export const TRANSLATE_PAGE_FULFILLED = 'TRANSLATE_PAGE_FULFILLED';

export const TRANSLATE_CMS_TEMPLATE = 'TRANSLATE_CMS_TEMPLATE';
export const TRANSLATE_CMS_TEMPLATE_PENDING = 'TRANSLATE_CMS_TEMPLATE_PENDING';
export const TRANSLATE_CMS_TEMPLATE_FULFILLED = 'TRANSLATE_CMS_TEMPLATE_FULFILLED';

export const UPDATE_SETTINGS_POSITION = 'UPDATE_SETTINGS_POSITION';

export const translatePage = (pageId, language) => {
  return {
    type: TRANSLATE_PAGE,
    payload: axios.get('/api/pages/' + pageId + '/translate.json?_locale=' + globalLocale + '&language=' + language),
  };
};

export const translateCmsTemplate = (cmsTemplateId, language) => {
  return {
    type: TRANSLATE_CMS_TEMPLATE,
    payload: axios.get(
      '/api/pagetemplates/' + cmsTemplateId + '/translate.json?_locale=' + globalLocale + '&language=' + language
    ),
  };
};

export const updateSettingsPosition = (x, y) => {
  return {
    type: UPDATE_SETTINGS_POSITION,
    payload: { x, y },
  };
};
