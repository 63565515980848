import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';

import RichEditorContent from 'components/RichEditorContent';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

export default class CmsElementTimelineBlock extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    this.setState({ shown: true });
  };

  render() {
    return (
      <ScrollTrigger onEnter={this.shown} className="timeline-block">
        <div class="timeline-point complete text-center bounce-in">
          <i class={this.getParamValue('icon', 'far fa-circle')} />
        </div>

        <div class={'timeline-content ' + (this.state.shown ? 'bounce-in ' : 'is-hidden ')}>
          <div class="card share full-width">
            <div class="card-block">
              <RichEditorContent content={'<p class="no-margin">' + this.getParamValue('content', '') + '</p>'} />
            </div>
            <div class="event-date">
              <small class="fs-12 hint-text">
                <span>{this.getParamValue('title', '')}</span>
              </small>
            </div>
          </div>
        </div>
      </ScrollTrigger>
    );
  }
}
CmsElementTimelineBlock.defaultProps = {
  index: '0',
};
