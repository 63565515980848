import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Grid, Row, Col, Clearfix } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { communicationVideoModule } from '@chedri/base';

import { isPluginActive } from 'helpers/whiteLabel';
import { submitLogin, submitLogout } from 'actions/LoginActions';
import { ROLE_EMPLOYEE, ROLE_CUSTOMER, ROLE_COMPANY, ROLE_DIRECT_CUSTOMER } from 'js/constants';

import LoginForm from 'components/forms/auth/LoginForm';
import LoginSocialMediaButtons from 'components/layout/LoginSocialMediaButtons';

import MainMenuLink from 'components/layout/mainMenus/common/MainMenuLink';

import may from 'js/security';

class DropdownProfile extends React.Component {
  constructor(props) {
    super(props);

    this.index = 1000;
  }

  onClick = e => {
    e.preventDefault();
  };

  submitLogin = loginData => {
    const { appIntl, submitLogin } = this.props;

    return submitLogin(loginData, appIntl.urlPrefix).then(this.close);
  };

  submitLogout = e => {
    e.preventDefault();

    const { submitLogout, isInCall } = this.props;
    if (isInCall) {
      return;
    }

    submitLogout();
  };

  getText = () => {
    const {
      intl: { messages },
      loginPending,
      user,
      label,
    } = this.props;

    return (
      (!!label && label) || (
        <>
          {loginPending ? (
            <i className="fa fa-circle-notch fa-spin m-r-10" style={{ transform: 'scale(1.2)' }} />
          ) : (
            <i className="fad fa-user-circle" />
          )}
          {user.logged ? user.profile.first_name + " " + user.profile.last_name : messages.accountAndLogin}
          <span className="caret m-l-5" />
        </>
      )
    );
  };

  renderProfileLinks = () => {
    const {
      intl: { messages },
      appIntl: { urlPrefix },
      user,
      projectConfig,
    } = this.props;

    return (
      <>
        {may(ROLE_CUSTOMER, user.roles) || may(ROLE_DIRECT_CUSTOMER, user.roles) && (
          <li>
            <Link to={urlPrefix + 'account'}>
              <i className="fa fa-fw fa-tachometer-alt" /> Dashboard
            </Link>
          </li>
        )}

        {may(ROLE_CUSTOMER, user.roles) || may(ROLE_DIRECT_CUSTOMER, user.roles) && (
          <li>
            <Link to={urlPrefix + 'account/orders'}>
              <i className="fas fa-fw fa-shopping-bag" /> {messages.orders}
            </Link>
          </li>
        )}

        {(may(ROLE_EMPLOYEE, user.roles) || may(ROLE_COMPANY, user.roles)) && (
          <li>
            <Link to={urlPrefix + 'account/settings'}>
              <i className="fa fa-fw fa-cogs" /> {messages.account}
            </Link>
          </li>
        )}

        {may(ROLE_EMPLOYEE, user.roles) || may(ROLE_COMPANY, user.roles) ? (
          <li>
            <Link to={urlPrefix + 'account/settings/profile'}>
              <i className="far fa-fw fa-user" /> {messages.profile}
            </Link>
          </li>
        ) : (
          <li>
            <Link to={urlPrefix + 'account/profile'}>
              <i className="far fa-fw fa-user" /> {messages.profile}
            </Link>
          </li>
        )}

        {/*may(ROLE_EMPLOYEE, user.roles) && isPluginActive('SALES_ACTIVITY_DASHBOARD', projectConfig.data) && (
          <li>
            <Link to={urlPrefix + 'account/salesActivityDashboard'}>
              <i className="fa fa-fw fa-plug" /> Sales Activity Dashboard
            </Link>
          </li>
        )*/}

        {/*may(ROLE_EMPLOYEE, user.roles) && isPluginActive('RENT_ACTIVITY_DASHBOARD', projectConfig.data) && (
          <li>
            <Link to={urlPrefix + 'account/rentActivityDashboard'}>
              <i className="fa fa-fw fa-plug" /> Rent Activity Dashboard
            </Link>
          </li>
        )*/}
      </>
    );
  };

  getContent = () => {
    const {
      intl: { messages },
      user,
      projectConfig,
    } = this.props;

    const showSocialMediaLogin =
      projectConfig.data.facebook_login_client_id ||
      projectConfig.data.google_login_client_id ||
      projectConfig.data.microsoft_login_client_id;

    return (
      <Clearfix className="menu-dropdown">
        {user.logged ? (
          <ul
            className="dropdown-menu bg-transparent no-border no-padding no-shadow"
            style={{ position: 'static', display: 'block', float: 'none' }}
          >
            {this.renderProfileLinks()}

            <li className="bg-master-lighter">
              <a href="#logout" onClick={this.submitLogout} className="clearfix">
                <i className="fa fa-power-off" />
                {' '+messages.logout}
              </a>
            </li>
          </ul>
        ) : (
          <ul className="list-unstyled no-margin no-padding">
            <li className="p-t-20">
              <LoginForm onSubmit={this.submitLogin} linkClicked={this.close} buttonTitle={messages.button_signin} />
            </li>

            {showSocialMediaLogin && (
              <li className="bg-master-lighter">
                <Grid fluid className="padding-5 p-b-10 p-t-10">
                  <Row className="show-grid">
                    <Col xs={4} className="text-right p-l-10">
                      {messages.alternative_login}
                    </Col>
                    <Col xs={8}>
                      <LoginSocialMediaButtons showLabels={false} />
                    </Col>
                  </Row>
                </Grid>
              </li>
            )}
          </ul>
        )}
      </Clearfix>
    );
  };

  render() {
    const { appIntl, layout, user, onMouseEnter, onMouseLeave, currentIndex, animatingOut } = this.props;

    return (
      <MainMenuLink
        flipId=""
        index={this.index}
        currentIndex={currentIndex}
        layout={layout}
        onMouseEnter={onMouseEnter}
        onDropdownClose={onMouseLeave}
        onClick={this.onClick}
        hasDropdown
        animatingOut={animatingOut}
        id="nav-user"
        className=""
        link={`/${appIntl.locale}/account/profile`}
        text={this.getText()}
        dropdownWidth={20}
        listClassName={user.logged ? 'nav-user-profile ' : 'nav-user-auth '}
        dropdownMenuElements={[
          {
            content: [{ type: 'raw', params: [{ name: 'content', value: this.getContent() }] }],
            language: appIntl.locale,
          },
        ]}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    user: state.login.user,
    loginPending: state.login.pending,
    projectConfig: state.data.projectConfig,
    isInCall: communicationVideoModule.selectors.isInCall(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    submitLogout: () => dispatch(submitLogout()),
    submitLogin: (args, urlPrefix) => dispatch(submitLogin(args, urlPrefix)),
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(DropdownProfile)
  )
);
