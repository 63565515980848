import React from 'react';
import { connect } from 'react-redux';
import { find as _find, forEach as _forEach } from 'lodash';
import ReactDOM from 'react-dom';
import { Button, Col, Row } from 'react-bootstrap';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import SimplePrompt from '../../../components/layout/SimplePrompt';
import DateTimePicker from '../../../components/forms/DateTimePicker';
import { getTimezoneFromState } from '../../../helpers/intl';
import FormTextarea from '../../../components/forms/FormTextarea';
import FormInput from '../../../components/forms/FormInput';

const ContextModal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('context-modal'));
};

class CalendarElementOptimized extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      schedule: [],
      schedulesEdit: [],
      showSchedulesEdit2: false,
      appointments: [],
      appointmentsEdit: [],
      newDateTime: moment(Date.now()),
      newDateTimeUntil: moment(Date.now()),
      newTermDesc: '',
      newTermTitle: '',
      newTermPlace: '',
    };
  }

  componentDidMount = () => {
    this.getSchedules();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.params[0] && prevProps.params[0]?.value !== this.props.params[0]?.value) {
      this.getSchedules();
    }
  }

  setSchedule = () => {
    const { schedulesEdit } = this.state;
    const { id, attributes, updateParam } = this.props;
    let collapsible = 'false';
    if (typeof attributes.collapsible !== 'undefined' && attributes.collapsible === 'true') {
      collapsible = 'true';
    }

    let param = '';
    _forEach(schedulesEdit, (schedule, i) => {
      param +=
        schedule[0] +
        '#aaa#' +
        schedule[1] +
        '#aaa#' +
        schedule[2] +
        '#aaa#' +
        schedule[3] +
        '#aaa#' +
        schedule[4] +
        '#aaa#' +
        schedule[5] +
        '#aaa#' +
        schedule[6] +
        '#aaa#' +
        schedule[7] +
        '#aaa#' +
        schedule[8] +
        '#aaa#' +
        schedule[9] +
        '#aaa#' +
        schedule[10] +
        '#aaa#' +
        collapsible +
        '#aaa#' +
        schedule?.[12];
      if (i < schedulesEdit.length - 1) {
        param += '#cc#';
      }
    });
    const overflow = false;
    const default_image = false;
    const errors = [{ overflow, default_image }];
    updateParam(id, attributes['data-name'], param, errors);
    this.setState({ schedules: schedulesEdit, showSchedulesEdit2: false }, () => {
      this.getAppointments();
    });
  };

  getSchedules = () => {
    const { name: Tag, attributes, params } = this.props;
    const param = _find(params, ['name', attributes['data-name']]);
    const terms = [];
    if (param) {
      if (param.value !== '') {
        const output = param.value.split('#cc#');
        _forEach(output, entry => {
          const schedule = entry.split('#aaa#');
          // if no id exists for elements created before the update
          if (!schedule?.[12]) {
            schedule[12] = Math.floor(Math.random() * 10000000 + 1000000); // unique id for render key
          }
          terms.push(schedule);
        });
      }
    }
    this.setState({ schedules: terms, schedulesEdit: terms }, () => {
      this.getAppointments();
    });
  };

  getAppointments = () => {
    const { schedules, schedulesEdit } = this.state;
    const appointments = [];
    if (schedules.length > 0) {
      _forEach(schedules, (schedule, i) => {
        if (!appointments[schedule[0]]) {
          appointments[schedule[0]] = { name: schedule[0], months: [] };
        }
        if (!appointments[schedule[0]].months[parseInt(schedule[1], 10)]) {
          appointments[schedule[0]].months[parseInt(schedule[1], 10)] = { name: schedule[1], days: [] };
        }
        if (!appointments[schedule[0]].months[parseInt(schedule[1], 10)].days[parseInt(schedule[2], 10)]) {
          appointments[schedule[0]].months[parseInt(schedule[1], 10)].days[parseInt(schedule[2], 10)] = {
            name: schedule[2],
            terms: [],
          };
        }

        appointments[schedule[0]].months[parseInt(schedule[1], 10)].days[parseInt(schedule[2], 10)].terms[
          parseInt(schedule[3].replace(':', '') + '0' + i, 10)
        ] = {
          time: schedule[3].toString(),
          content: schedule[4],
          contentTitle: schedule[5],
          contentPlace: schedule[6],
          timeUntil: schedule[10].toString(),
          year: schedule[7],
          month: schedule[8],
          day: schedule[9],
          id: schedule?.[12],
        };
      });
    }

    const appointmentsEdit = [];
    if (schedulesEdit.length > 0) {
      _forEach(schedulesEdit, (schedule, i) => {
        if (!appointmentsEdit[schedule[0]]) {
          appointmentsEdit[schedule[0]] = { name: schedule[0], months: [] };
        }
        if (!appointmentsEdit[schedule[0]].months[parseInt(schedule[1], 10)]) {
          appointmentsEdit[schedule[0]].months[parseInt(schedule[1], 10)] = { name: schedule[1], days: [] };
        }
        if (!appointmentsEdit[schedule[0]].months[parseInt(schedule[1], 10)].days[parseInt(schedule[2], 10)]) {
          appointmentsEdit[schedule[0]].months[parseInt(schedule[1], 10)].days[parseInt(schedule[2], 10)] = {
            name: schedule[2],
            terms: [],
          };
        }

        appointmentsEdit[schedule[0]].months[parseInt(schedule[1], 10)].days[parseInt(schedule[2], 10)].terms[
          parseInt(schedule[3].replace(':', '') + '0' + i, 10)
        ] = {
          time: schedule[3].toString(),
          content: schedule[4],
          contentTitle: schedule[5],
          contentPlace: schedule[6],
          index: i,
          timeUntil: schedule[10].toString(),
          year: schedule[7],
          month: schedule[8],
          day: schedule[9],
          id: schedule?.[12],
        };
      });
    }

    this.setState({ appointments, appointmentsEdit });
  };

  showScheduleEdit = () => {
    this.setState({ showSchedulesEdit2: true });
  };

  closeScheduleEdit = () => {
    this.setState({ showSchedulesEdit2: false });
  };

  removeSchedule = () => {
    this.setState({ schedulesEdit: null });
  };

  addSchedule = () => {
    const { schedulesEdit, newDateTime, newTermDesc, newTermTitle, newTermPlace, newDateTimeUntil } = this.state;
    const hourAdd = newDateTime.hour() < 10 ? '0' : '';
    const minuteAdd = newDateTime.minute() < 10 ? '0' : '';
    const month = parseInt(newDateTime.month(), 10) + 1;
    const monthsAdd = month < 10 ? '0' : '';
    const dayAdd = newDateTime.date() < 10 ? '0' : '';

    const hourAddUntil = newDateTimeUntil.hour() < 10 ? '0' : '';
    const minuteAddUntil = newDateTimeUntil.minute() < 10 ? '0' : '';
    const monthUntil = parseInt(newDateTimeUntil.month(), 10) + 1;
    const monthsAddUntil = monthUntil < 10 ? '0' : '';
    const dayAddUntil = newDateTimeUntil.date() < 10 ? '0' : '';

    schedulesEdit.push({
      0: newDateTime.year(),
      1: monthsAdd + month,
      2: dayAdd + newDateTime.date(),
      3: hourAdd + newDateTime.hour() + ':' + minuteAdd + newDateTime.minute(),
      4: newTermDesc,
      5: newTermTitle,
      6: newTermPlace,
      7: newDateTimeUntil.year(),
      8: monthsAddUntil + monthUntil,
      9: dayAddUntil + newDateTimeUntil.date(),
      10: hourAddUntil + newDateTimeUntil.hour() + ':' + minuteAddUntil + newDateTimeUntil.minute(),
      11: false, // default value of collapsible
      12: Math.floor(Math.random() * 10000000 + 1000000), // unique id for render key
    });
    this.setState({ schedulesEdit }, () => {
      this.getAppointments();
    });
  };

  getMonthName = month => {
    const {
      intl: { messages },
    } = this.props;
    const monthNames = [
      messages.editor.january,
      messages.editor.february,
      messages.editor.march,
      messages.editor.april,
      messages.editor.may,
      messages.editor.june,
      messages.editor.july,
      messages.editor.august,
      messages.editor.september,
      messages.editor.october,
      messages.editor.november,
      messages.editor.december,
    ];
    return monthNames[month - 1];
  };

  getWeekday = (year, month, day) => {
    const {
      intl: { messages },
    } = this.props;
    const date = new Date(year, month - 1, day);
    const dayNames = [
      messages.editor.sunday,
      messages.editor.monday,
      messages.editor.tuesday,
      messages.editor.wednesday,
      messages.editor.thursday,
      messages.editor.friday,
      messages.editor.saturday,
    ];
    return dayNames[date.getDay()];
  };

  setNewDateTime = (name, value) => {
    this.setState({
      newDateTime: value,
    });
  };

  setNewDateTimeUntil = (name, value) => {
    this.setState({
      newDateTimeUntil: value,
    });
  };

  setNewTermDesc = (name, value) => {
    this.setState({
      newTermDesc: value,
    });
  };

  setNewTermTitle = (name, value) => {
    this.setState({
      newTermTitle: value,
    });
  };

  setNewTermPlace = (name, value) => {
    this.setState({
      newTermPlace: value,
    });
  };

  removeTerm = index => {
    const { schedulesEdit } = this.state;
    const part1 = schedulesEdit.slice(0, index);
    const part2 = schedulesEdit.slice(index + 1);
    this.setState({ schedulesEdit: part1.concat(part2) }, () => {
      this.getAppointments();
    });
  };

  updateTerm(index, value, type) {
    const { schedulesEdit } = this.state;
    if (type === 'desc') {
      schedulesEdit[index][4] = value;
    }
    if (type === 'title') {
      schedulesEdit[index][5] = value;
    }
    if (type === 'place') {
      schedulesEdit[index][6] = value;
    }
    if (type === 'dateUntil') {
      const hourAdd = value.hour() < 10 ? '0' : '';
      const minuteAdd = value.minute() < 10 ? '0' : '';
      const month = parseInt(value.month(), 10) + 1;
      const monthsAdd = month < 10 ? '0' : '';
      const dayAdd = value.date() < 10 ? '0' : '';
      schedulesEdit[index][7] = value.year();
      schedulesEdit[index][8] = monthsAdd + month;
      schedulesEdit[index][9] = dayAdd + value.date();
      schedulesEdit[index][10] = hourAdd + value.hour() + ':' + minuteAdd + value.minute();
    } else if (type === 'date') {
      const hourAdd = value.hour() < 10 ? '0' : '';
      const minuteAdd = value.minute() < 10 ? '0' : '';
      const month = parseInt(value.month(), 10) + 1;
      const monthsAdd = month < 10 ? '0' : '';
      const dayAdd = value.date() < 10 ? '0' : '';
      schedulesEdit[index][0] = value.year();
      schedulesEdit[index][1] = monthsAdd + month;
      schedulesEdit[index][2] = dayAdd + value.date();
      schedulesEdit[index][3] = hourAdd + value.hour() + ':' + minuteAdd + value.minute();
    }
    this.setState({ schedulesEdit }, () => {
      this.getAppointments();
    });
  }

  render() {
    const { name: Tag, attributes, appIntl, defaultTimezone } = this.props;
    const {
      appointments,
      appointmentsEdit,
      showSchedulesEdit2,
      newDateTime,
      newTermDesc,
      newTermTitle,
      newTermPlace,
      newDateTimeUntil,
    } = this.state;
    const {
      intl: { messages },
    } = this.props;

    return (
      <>
        <Tag {...attributes} onClick={this.showScheduleEdit} data-tip={messages.editor.edit_date} data-for="top">
          <div className="wrap">
            {appointments.length > 0 ? (
              appointments.map(year => {
                return year.months.map(month => {
                  return (
                    <div className="kmonat" data-month={month.name} data-year={year.name}>
                      <div className="mline" />
                      <p className="dateHeadline">
                        <span className="monthOfHeadline">{this.getMonthName(month.name)} </span>
                        <span className="yearOfHeadline">{year.name}</span>
                      </p>

                      {month.days.map(day => {
                        return day.terms.map(time => {
                          return (
                            <div className="daten">
                              <div className="dateFrom">
                                <p id="dateFromMsg">{messages.editor.date_from}</p>
                                <p className="dateExtra">
                                  <span class="day">{day.name}.</span>
                                  <span className="month">{month.name}.</span>
                                  <span className="year">{year.name}</span>
                                </p>
                                <p id="datum">{day.name + '.' + month.name + '.' + year.name}</p>
                                <p id="uhr">
                                  {this.getWeekday(year.name, month.name, day.name)}
                                  <br />
                                  {time.time + ' ' + messages.editor.oclock}
                                </p>
                              </div>
                              <div className="dateTo">
                                <p id="dateToMsg">{messages.editor.date_to}</p>
                                <p className="dateExtra">
                                  <span className="dayTo">{time.day}.</span>
                                  <span className="monthTo">{time.month}.</span>
                                  <span className="yearTo">{time.year}</span>
                                </p>
                                <p id="datumTo">{time.day + '.' + time.month + '.' + time.year}</p>
                                <p id="uhrTo">
                                  {this.getWeekday(time.year, time.month, time.day)}
                                  <br />
                                  {time.timeUntil + ' ' + messages.editor.oclock}
                                </p>
                              </div>

                              <div>
                                <p className="timeExtra">
                                  {this.getWeekday(year.name, month.name, day.name)}
                                  <br />
                                  {time.time + ' ' + messages.editor.oclock}
                                </p>
                                <p className="timeExtraTo">
                                  {this.getWeekday(time.year, time.month, time.day)}
                                  <br />
                                  {time.timeUntil + ' ' + messages.editor.oclock}
                                </p>
                                <p id="termTitle">{time.contentTitle}</p>
                                <p id="termPlace">{time.contentPlace}</p>
                                <p id="veranst">{time.content}</p>
                              </div>
                            </div>
                          );
                        });
                      })}
                      <div className="clear" />
                    </div>
                  );
                });
              })
            ) : (
              <p>{messages.editor.no_dates_available}</p>
            )}
          </div>
        </Tag>
        {showSchedulesEdit2 && (
          <ContextModal>
            <div id="contextModalWrap">
              <div id="contextModalStage" className="row">
                <Row>
                  <Col md={4} className="new-term">
                    <Row>
                      <Col md={12}>
                        <h5>{messages.editor.new_date}</h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={8} className="edit-term">
                    <Row>
                      <Col md={12}>
                        <h5>{messages.editor.edit_dates}</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="terms-view">
                  <Col md={4} className="new-term">
                    <Row>
                      <Col>
                        <FormGroup controlId="formNewTerm">
                          <DateTimePicker
                            value={newDateTime}
                            label="Datum - am/ab"
                            locale={appIntl.locale}
                            displayTz={defaultTimezone}
                            onChange={this.setNewDateTime}
                          />
                          <DateTimePicker
                            value={newDateTimeUntil}
                            label="Datum - bis"
                            locale={appIntl.locale}
                            displayTz={defaultTimezone}
                            onChange={this.setNewDateTimeUntil}
                          />
                          <FormInput
                            type="text"
                            label={messages.editor.date_title}
                            name=""
                            onChange={this.setNewTermTitle}
                            value={newTermTitle}
                          />
                          <FormInput
                            type="text"
                            label={messages.editor.date_place}
                            name=""
                            onChange={this.setNewTermPlace}
                            value={newTermPlace}
                          />
                          <FormTextarea
                            label={messages.editor.date_description}
                            value={newTermDesc}
                            required
                            onChange={this.setNewTermDesc}
                          />
                          <Button onClick={this.addSchedule}>
                            <i className="fas fa-plus" /> {messages.editor.add}
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={8} className="edit-term">
                    {appointmentsEdit.length > 0 ? (
                      appointmentsEdit.map(year => {
                        return (
                          <>
                            <Row>
                              <Col md={12} style={{ backgroundColor: '#ccc' }}>
                                <p>{year.name}</p>
                              </Col>
                            </Row>
                            {year.months.map(month => {
                              return (
                                <>
                                  <Row>
                                    <Col md={12} style={{ backgroundColor: '#ddd' }}>
                                      <p>{this.getMonthName(month.name)}</p>
                                    </Col>
                                  </Row>
                                  {month.days.map(day => {
                                    return (
                                      <>
                                        {day.terms.map(term => {
                                          return (
                                            <Row key={term.id} className="term-entry">
                                              <Col md={3}>
                                                <DateTimePicker
                                                  name="date"
                                                  value={moment(
                                                    year.name +
                                                      '-' +
                                                      month.name +
                                                      '-' +
                                                      day.name +
                                                      'T' +
                                                      term.time +
                                                      ':00'
                                                  )}
                                                  locale={appIntl.locale}
                                                  displayTz={defaultTimezone}
                                                  onChange={(name, value) => this.updateTerm(term.index, value, name)}
                                                />
                                                <DateTimePicker
                                                  name="dateUntil"
                                                  value={moment(
                                                    term.year +
                                                      '-' +
                                                      term.month +
                                                      '-' +
                                                      term.day +
                                                      'T' +
                                                      term.timeUntil +
                                                      ':00'
                                                  )}
                                                  locale={appIntl.locale}
                                                  displayTz={defaultTimezone}
                                                  onChange={(name, value) => this.updateTerm(term.index, value, name)}
                                                />
                                              </Col>
                                              <Col md={8}>
                                                <FormInput
                                                  name="title"
                                                  value={term.contentTitle}
                                                  onChange={(name, value) => this.updateTerm(term.index, value, name)}
                                                />
                                                <FormInput
                                                  name="place"
                                                  value={term.contentPlace}
                                                  onChange={(name, value) => this.updateTerm(term.index, value, name)}
                                                />
                                                <FormTextarea
                                                  name="desc"
                                                  value={term.content}
                                                  onChange={(name, value) => this.updateTerm(term.index, value, name)}
                                                />
                                              </Col>
                                              <Col md={1}>
                                                <SimplePrompt
                                                  showLabel={false}
                                                  className=""
                                                  bsSize=""
                                                  onSuccess={() => {
                                                    this.removeTerm(term.index);
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                          );
                                        })}
                                      </>
                                    );
                                  })}
                                </>
                              );
                            })}
                          </>
                        );
                      })
                    ) : (
                      <p style={{ backgroundColor: '#fff' }}>{messages.editor.no_dates_availableTwo}</p>
                    )}
                  </Col>
                </Row>
                <Row className="text-right">
                  <Button bsStyle="success" bsSize="large" onClick={this.setSchedule}>
                    {messages.editor.take_on}
                  </Button>{' '}
                  <Button bsStyle="danger" bsSize="large" onClick={this.closeScheduleEdit}>
                    {messages.editor.abort}
                  </Button>
                </Row>
              </div>
            </div>
          </ContextModal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    defaultTimezone: getTimezoneFromState(state),
    appIntl: state.intl,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(CalendarElementOptimized)));
