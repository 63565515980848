import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { concat as _concat } from 'lodash';

import { getNameInCurrentLanguage } from 'helpers/intl';

import Breadcrumb from 'components/layout/Breadcrumb';
import BreadcrumbWireframe from 'components/layout/wireframes/BreadcrumbCategoryNav';

import List from 'components/List';

class SummaryCategoryBreadcrumb extends React.Component {
  render() {
    const {
      intl: { messages },
      appIntl,
      category,
      breadcrumbs,
    } = this.props;

    // initial crumbs based on type
    let initialCrumbs = [{ link: appIntl.urlPrefix, name: messages.home }];

    if (initialCrumbs === 'cars') {
      initialCrumbs = [{ link: appIntl.urlPrefix, name: messages.home }];
    }

    // crumbs based on current category
    const crumbs = breadcrumbs.data.list.map(category => {
      return {
        link: appIntl.urlPrefix,
        name: getNameInCurrentLanguage(category.name, appIntl),
      };
    });

    return (
      <Row class="condensed">
        <List listName="breadcrumbs" url={'/api/breadcrumbs/' + category + '.json'} list={breadcrumbs} noRender />

        {!breadcrumbs.pending ? (
          <div class="hidden-xs">
            <Col xs={12}>
              <Breadcrumb crumbs={_concat(initialCrumbs, crumbs)} />
            </Col>
          </div>
        ) : null}

        {!breadcrumbs.pending ? (
          <div class="hidden-sm hidden-md hidden-lg">
            <Col xs={12}>
              <Breadcrumb crumbs={_concat(initialCrumbs, crumbs)} />
            </Col>
          </div>
        ) : null}

        {breadcrumbs.pending ? <BreadcrumbWireframe /> : null}
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    breadcrumbs: state.list.breadcrumbs,
  };
};

export default injectIntl(connect(mapStateToProps)(SummaryCategoryBreadcrumb));
