import React from 'react';

export default class RoundImage extends React.Component {
  render() {
    const { size, url, variation, color, hexColor, usePixels, style: propsStyle, className } = this.props;

    let style = {
      ...propsStyle,
      position: 'relative',
      overflow: 'hidden',
      width: size + (usePixels ? 'px' : '%'),
      margin: '0 auto',
    };
    if (usePixels) {
      style.height = size + 'px';
    } else {
      style.paddingBottom = size + '%';
    }
    if (!color && url) {
      style = { ...style, backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: 'url(' + url + ')' };
    }

    if (hexColor) {
      style.background = hexColor;
    }

    let colorClass = '';
    if (color) {
      colorClass = color;
    }

    return (
      <div style={style} className={'img-' + variation + ' ' + colorClass + ' ' + className}>
        <div
          style={{
            position: 'absolute',
            maxWidth: '100%',
            maxHeight: '100%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
RoundImage.defaultProps = {
  size: 100,
  variation: 'circle',
  usePixels: false,
  style: {},
  className: '',
};
