import _, {forEach as _forEach} from 'lodash';

const initialState = {
  serviceTypesR:[],
  pending: false,
  pendingC: false,
  pendingI: false,
  saving:false,
  serviceTypesClientR: [],
  serviceTypesItemR: [],
};


export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_SERVICE_TYPES_PENDING': {
      return { ...state, pending: true };
    }
    case 'GET_SERVICE_TYPES_FULFILLED': {
      return { ...state, serviceTypesR: payload.list, pending: false };
    }
    case 'ADD_SERVICE_TYPE_PENDING': {
      return { ...state, pending: true };
    }
    case 'ADD_SERVICE_TYPE_FULFILLED': {
      const { serviceTypesR } = state;
      let serviceTypes = serviceTypesR;
      serviceTypes = serviceTypes.concat(payload);
      return { ...state, serviceTypesR: serviceTypes, pending: false };

    }
    case 'EDIT_SERVICE_TYPE_PENDING': {
      return { ...state, pending: true };
    }
    case 'EDIT_SERVICE_TYPE_FULFILLED': {
      const { serviceTypesR } = state;
      const index = _.findIndex(serviceTypesR, serviceTypeR => serviceTypeR.id === payload.id);
      serviceTypesR[index] = payload;
      return { ...state, serviceTypesR: serviceTypesR, pending: false };

    }
    case 'DELETE_SERVICE_TYPE_PENDING': {
      return { ...state, pending: true };
    }
    case 'DELETE_SERVICE_TYPE_FULFILLED': {
      const { serviceTypesR } = state;
      let deletedServiceTypesId = payload.id // or payload
      let deletedServiceType = _.filter(serviceTypesR, x => x.id !== deletedServiceTypesId);
      return { ...state, serviceTypesR: deletedServiceType, pending: false};
    }
    case 'GET_SERVICE_TYPES_CLIENT_PENDING': {
      return { ...state, pendingC: true };
    }
    case 'GET_SERVICE_TYPES_CLIENT_FULFILLED': {
      return { ...state, serviceTypesClientR: payload.list, pendingC: false };
    }
    case 'ADD_SERVICE_TYPE_CLIENT_PENDING': {
      return { ...state, pendingC: true };
    }
    case 'ADD_SERVICE_TYPE_CLIENT_FULFILLED': {
      const { serviceTypesClientR } = state;
      let serviceTypesClient = serviceTypesClientR;
      serviceTypesClient = serviceTypesClient.concat(payload);
      return { ...state, serviceTypesClientR: serviceTypesClient, pendingC: false };

    }
    case 'EDIT_SERVICE_TYPE_CLIENT_PENDING': {
      return { ...state, pendingC: true };
    }
    case 'EDIT_SERVICE_TYPE_CLIENT_FULFILLED': {
      const { serviceTypesClientR } = state;
      const index = _.findIndex(serviceTypesClientR, serviceTypeClientR => serviceTypeClientR.id === payload.id);
      serviceTypesClientR[index] = payload;
      return { ...state, serviceTypesClientR: serviceTypesClientR,pendingC: false };

    }
    case 'DELETE_SERVICE_TYPE_CLIENT_PENDING': {
      return { ...state, pendingC: true };
    }
    case 'DELETE_SERVICE_TYPE_CLIENT_FULFILLED': {
      const { serviceTypesClientR } = state;
      let deletedServiceTypesClientId = payload.id // or payload
      let deletedServiceTypeClient = _.filter(serviceTypesClientR, x => x.id !== deletedServiceTypesClientId);
      return { ...state, serviceTypesClientR: deletedServiceTypeClient, pendingC: false};
    }
    case 'GET_SERVICE_TYPES_ITEM_PENDING': {
      return { ...state, pendingI: true };
    }
    case 'GET_SERVICE_TYPES_ITEM_FULFILLED': {
      return { ...state, serviceTypesItemR: payload.list, pendingI: false };
    }
    case 'ADD_SERVICE_TYPE_ITEM_PENDING': {
      return { ...state, pendingI: true };
    }
    case 'ADD_SERVICE_TYPE_ITEM_FULFILLED': {
      const { serviceTypesItemR } = state;
      let serviceTypesItem = serviceTypesItemR;
      serviceTypesItem = serviceTypesItem.concat(payload);
      return { ...state, serviceTypesItemR: serviceTypesItem, pendingI: false };

    }
    case 'EDIT_SERVICE_TYPE_ITEM_PENDING': {
      return { ...state, pendingI: true };
    }
    case 'EDIT_SERVICE_TYPE_ITEM_FULFILLED': {
      const { serviceTypesItemR } = state;
      const index = _.findIndex(serviceTypesItemR, serviceTypeItemR => serviceTypeItemR.id === payload.id);
      serviceTypesItemR[index] = payload;
      return { ...state, serviceTypesItemR: serviceTypesItemR,pendingI: false };

    }
    case 'DELETE_SERVICE_TYPE_ITEM_PENDING': {
      return { ...state, pendingI: true };
    }
    case 'DELETE_SERVICE_TYPE_ITEM_FULFILLED': {
      const { serviceTypesItemR } = state;
      let deletedServiceTypesItemId = payload.id // or payload
      let deletedServiceTypeItem = _.filter(serviceTypesItemR, x => x.id !== deletedServiceTypesItemId);
      return { ...state, serviceTypesItemR: deletedServiceTypeItem, pendingI: false};
    }
  }
  return state;
};
