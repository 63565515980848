let locale = globalLocale !== undefined ? globalLocale : (navigator.language || navigator.userLanguage).substr(0, 2);
const { whiteLabelSettings } = global;

if (typeof whiteLabelSettings !== 'undefined') {
  if (whiteLabelSettings.languages && whiteLabelSettings.languages.length) {
    if (whiteLabelSettings.languages.indexOf(locale) === -1) {
      locale = whiteLabelSettings.languages[0];
    }
  }
}
global.globalLocale = locale;

export default {
  // eslint-disable-next-line no-undef
  debug: DEBUG,

  locale,
  whiteLabelSettings,
};
