import { ACCOUNT_SEARCH_SHOW, ACCOUNT_SEARCH_HIDE, ACCOUNT_SEARCH_VALUE } from 'actions/AccountSearchActions';

const initialState = {
  show: false,
  value: '',
};

export default function accountSearch(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_SEARCH_SHOW: {
      return { ...state, show: true };
    }

    case ACCOUNT_SEARCH_HIDE: {
      return { ...state, show: false };
    }

    case ACCOUNT_SEARCH_VALUE: {
      return { ...state, value: action.payload };
    }
  }

  return state;
}
