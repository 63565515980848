import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';

import { elementSource, elementTarget } from './elementDragUtils';

import CmsElementQuestionnaireForm from 'components/cms/elements/CmsElementQuestionnaireForm';
import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
export default class CmsElementQuestionnaire extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  render() {
    const {
      intl: { messages },
      connectDropTarget,
      connectDragSource,
      connectDragPreview,
    } = this.props;
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const mainColorClass = 'text-' + this.getParamValue('mainColor', 'master') + ' ';
    const questionnaireId = this.getParamValue('questionnaireId');
    const pipelineId = this.getParamValue('pipelineId');
    const buttonTitle = this.getParamValue('buttonTitle');

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-questionnaire-content ' + shownClass + mainColorClass + this.getCssClasses()}
        onClick={this.showSettings}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        {questionnaireId ? (
          <React.Fragment>
            <h4 class={'no-margin p-b-5 ' + mainColorClass}>{this.getParamValue('title', messages.questionnaire)}</h4>

            <CmsElementQuestionnaireForm
              questionnaireId={questionnaireId}
              pipelineId={pipelineId}
              mainColorClass={mainColorClass}
              buttonTitle={buttonTitle}
            />
          </React.Fragment>
        ) : (
          <div class="p-t-15 p-b-15 text-center">
            <i class="fal fa-envelope-open-text fa-4x" />
          </div>
        )}

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}
