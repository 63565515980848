import { get as _get } from 'lodash';

import {
  CHECKOUT_RESET,
  CHECKOUT_INIT,
  CHECKOUT_UPDATE,
  PAYMENT_UPDATE,
  CHECKOUT_STRIPE_CARD_SETUP_FULFILLED,
} from 'actions/CheckoutActions';

const initialState = {
  init: false,
  items: [],
  availablePlugins: [], // ePayment plugins that are available in the checkout
  activePaymentPlugin: '', // which payment plugin is currently active?
  payment: 'creditCard', // what is the active payment method
  paymentData: {}, // additional data the payment plugins may store
  paymentPending: false,
  remarks: '', // order comments or special wishes
  isItemCheckout: false, // plain, simple item checkout
  carPayment: 'cash', // what is the active car payment method
  carCashPayment: {}, // additional information for car payment - when paying cash
  // stripeType: 'token', // token or source as stripe type; token: a new credit card will be added; source: known credit card will be used
  // stripeToken: null, // token returned from stripe when user validated his credit card
  // stripeSource: null, // stripe source id
  // paypalData: null, // when paypal payment is executed, we receive payment id and payer id
  orderingAs: 'company', // ordering as private person (value: person) or as a company (value: company)
  userRoles: typeof userData !== 'undefined' && userData.roles ? userData.roles : ['ROLE_CUSTOMER'],
  navToCheckout: false, // should we navigate the user to checkout after the checkout init?

  currency: 'EUR',
  vat_sum_rates: [],
  invoice_address: [],
  shipping_address: [],
  discount_vat_rates: [],
  total_vat_rates: [],
  available_payment_options: [],
  sum_net: 0,
  sum_gross: 0,
  use_invoice_address_for_shipping: false,
  discount_net: 0,
  discount_gross: 0,
  total_shipping_price: 0,
  total_net: 0,
  total_gross: 0,
};

export default function checkout(state = initialState, action) {
  switch (action.type) {
    case CHECKOUT_RESET: {
      return initialState;
    }

    case CHECKOUT_INIT: {
      let newState = {
        ...state,
        init: true,
        remarks: '',
        items: action.payload.items,
        userTimezone: action.payload.userTimezone || 'UTC',
        currency: 'EUR',
        vat_sum_rates: [],
        invoice_address: [],
        shipping_address: [],
        discount_vat_rates: [],
        total_vat_rates: [],
        available_payment_options: [],
        sum_net: 0,
        sum_gross: 0,
        use_invoice_address_for_shipping: false,
        discount_net: 0,
        discount_gross: 0,
        total_shipping_price: 0,
        total_net: 0,
        total_gross: 0,
        ...(action.payload.initData || {}),
      };

      return newState;
    }

    case CHECKOUT_UPDATE: {
      return { ...state, ...action.payload };
    }

    case PAYMENT_UPDATE: {
      const { plugin, type, data } = action.payload;
      return { ...state, activePaymentPlugin: plugin, payment: type, paymentData: data };
    }

    case CHECKOUT_STRIPE_CARD_SETUP_FULFILLED: {
      const paymentMethodId = _get(action, 'payload.setupIntent.payment_method');
      if (!paymentMethodId) {
        return state;
      }

      return {
        ...state,
        activePaymentPlugin: '',
        payment: 'creditCard',
        newPaymentMethod: false,
        paymentData: {
          ...state.paymentData,
          paymentMethodId,
        },
      };
    }

    case 'HANDLE_LOGIN_FULFILLED':
    case 'FETCH_CREDENTIALS_FULFILLED': {
      const newState = { ...state, userRoles: action.payload.data.roles.slice() };
      return { ...newState };
    }
  }

  return state;
}

export const checkoutMiddleware = store => {
  const { dispatch } = store;

  return next => action => {
    if (action.type === CHECKOUT_RESET) {
      const res = next(action);

      if (action.payload) {
        setTimeout(() => dispatch(action.payload));
      }

      return res;
    }

    return next(action);
  };
};
