import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { find as _find } from 'lodash';
// import { Flipped } from 'react-flip-toolkit';

import CmsElement from 'components/cms/CmsElement';

class DropdownMenu extends React.Component {
  getMenuContent = content => {
    const { intl, appIntl, layout } = this.props;

    const langContent = _find(content, ['language', appIntl.locale]);
    if (langContent) {
      return langContent.content.map((element, i) => {
        return (
          <CmsElement
            index={i.toString()}
            {...element}
            intl={intl}
            appIntl={appIntl}
            layout={layout}
            useFlipped={false}
            key={i}
          />
        );
      });
    }

    return null;
  };

  render() {
    const {
      element: { dropdownWidth, dropdownMenuElements },
      visible,
      marginLeft,
      animatingOut,
    } = this.props;

    return (
      <div className="ms-mm-container" style={{ width: `${dropdownWidth}vw`, marginLeft: `${marginLeft}px` }}>
        <div className={'ms-mm-overlay ' + (visible && !animatingOut ? 'ms-mm-visible ' : 'ms-mm-hidden ')}>
          <div className="ms-mm-content">{this.getMenuContent(dropdownMenuElements)}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    layout: state.layout,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(DropdownMenu)));
