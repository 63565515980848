import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Data from 'components/Data';
import CmsPage from 'components/CmsPage';

import Loader from 'components/layout/Loader';

class Pages extends React.Component {
  componentDidUpdate = prevProps => {
    const { appIntl, history, cmsPage } = this.props;

    if (!cmsPage.pending && prevProps.cmsPage.pending && !cmsPage.hasError) {
      if (!cmsPage.data) {
        history.push(appIntl.urlPrefix);
      } else if (!cmsPage.data.is_published) {
        history.push(cmsPage.forward_url ? cmsPage.forward_url : appIntl.urlPrefix);
      }
    }
  };

  render() {
    const { cmsPage, match, height } = this.props;

    return (
      <React.Fragment>
        <Data dataName="cmsPage" url={`/api/slugs/${match.params.slug}.json`} data={cmsPage} />

        {cmsPage.pending ? (
          <div className="content relative" style={{ height: `${height}px` }}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%)' }}>
              <Loader />
            </div>
          </div>
        ) : (
          <CmsPage data={cmsPage.data} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    height: state.layout.height,
    appIntl: state.intl,
    cmsPage: state.data.cmsPage,
  };
};

export default withRouter(connect(mapStateToProps)(Pages));
