import React from 'react';
import { connect } from 'react-redux';

import { find as _find } from 'lodash';
import ReactDOM from 'react-dom';
import {Button, ButtonGroup, Col, Row} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { getTimezoneFromState } from '../../../helpers/intl';
import FormAutocomplete from '../../../components/forms/FormAutocomplete';
import FormInput from '../../../components/forms/FormInput';
import FormTextarea from '../../../components/forms/FormTextarea';
import Tab from "react-bootstrap/lib/Tab";

const ContextModal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('context-modal'));
};

class SocialMediaElement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      social: [],
      socialEdit: [],
      showSocialEdit: false,
      buttonColor:"#000000",
    };
  }

  setCalendly = () => {
    const { socialEdit } = this.state;
    const { id, attributes, updateParam, imageUpload } = this.props;

    const param = socialEdit[0] + '#aaa#' + socialEdit[1] + '#aaa#' + socialEdit[2];
    const overflow = false;
    const default_image = false;
    const errors = [{ overflow, default_image }];
    updateParam(id, attributes['data-name'], param, errors);
    this.setState({ social: socialEdit, showSocialEdit: false }, () => {});
  };

  getCalendly = () => {
    const { name: Tag, attributes, params } = this.props;
    let social = [];
    const param = _find(params, ['name', attributes['data-name']]);
    if (param) {
      social = param.value.split('#aaa#');
    }
    this.setState({ social, socialEdit: social, buttonColor: social[2] }, () => {});
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.params[0] && prevProps.params[0].value !== this.props.params[0].value) {
      this.getCalendly();
    }
  }

  componentDidMount = () => {
    this.getCalendly();
  };

  showSocialEdit = () => {
    this.setState({ showSocialEdit: true });
  };

  closeCalendlyEdit = () => {
    this.setState({ showSocialEdit: false });
  };

  getCalendlyContainer = social => {
    const {
      intl: { messages },
    } = this.props;
    return (
      <div className="calendly_button" style={{backgroundColor:this.state.buttonColor}}>
        <span>{this.state.socialEdit && this.state.socialEdit[1] || ""}</span>
      </div> );
  };



  updateButtonColor = color => {
    const { socialEdit } = this.state;
    socialEdit[2] = color;
    this.setState({ buttonColor: color, socialEdit });
  };

  render() {
    const { name: Tag, attributes, params, appIntl, defaultTimezone } = this.props;
    const { social, socialEdit, showSocialEdit } = this.state;
    const {
      intl: { messages },
    } = this.props;

    return (
      <>
        <Tag
          {...attributes}
          onClick={this.showSocialEdit}
          data-tip={messages.editor.calendly_settings}
          data-for="top"
        >
          <div className="wrap">
            {social.length > 0 ? this.getCalendlyContainer(social) : <p>{messages.editor.edit_calendly}</p>}
          </div>
        </Tag>
        {showSocialEdit && (
          <ContextModal>
            <div id="contextModalWrap">
              <div id="contextModalStage" className="row">
                <Row className="h-45">
                  <Col md={4}>
                      <FormInput
                        name="calendlyLink"
                        label={messages.editor.link_to_your_calendly_page}
                        value={socialEdit[0] || ''}
                        onChange={(name, value) => {
                          const { socialEdit } = this.state;
                          socialEdit[0] = value;
                          this.setState({ socialEdit });
                        }}
                      />
                    <FormInput
                      name="calendlyButtonName"
                      label={messages.editor.choose_button_label}
                      value={socialEdit[1] || ''}
                      onChange={(name, value) => {
                        const { socialEdit } = this.state;
                        socialEdit[1] = value;
                        this.setState({ socialEdit });
                      }}
                    />
                    <div className="changeColorOptions">
                      <h3>{messages.editor.button_color}</h3>
                      <ButtonGroup className="edit-color QRBackground" bsSize="small" vertical>
                        {this.props.settings.back_colors &&
                        this.props.settings.back_colors.map(bgColor => {
                          return (
                            <Button
                              data-tip={messages.editor.change_backgroundcolor}
                              onClick={() => this.updateButtonColor(bgColor.color)}
                              style={{ padding: 4 }}
                            >
                              <p
                                style={{ width: 30, height: 30, padding: 0, margin: 0, background: bgColor.color }}
                              />
                            </Button>
                          );
                        })}
                      </ButtonGroup>
                    </div>
                  </Col>
                  <Col md={8} className="h-100">
                    <div style={{ minHeight: '50%', border: '2px dashed #ccc', padding: '10px' }}>
                      <p>{messages.editor.no_preview_of_calendly}</p>
                      <p>{messages.editor.preview_button}</p>
                      {socialEdit[1] && this.getCalendlyContainer(socialEdit)}
                    </div>
                  </Col>
                </Row>

                <Row className="text-right">
                  <Button bsStyle="success" bsSize="large" onClick={this.setCalendly}>
                    {messages.editor.take_on}
                  </Button>{' '}
                  <Button bsStyle="danger" bsSize="large" onClick={this.closeCalendlyEdit}>
                    {messages.editor.abort}
                  </Button>
                </Row>
              </div>
            </div>
          </ContextModal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    defaultTimezone: getTimezoneFromState(state),
    appIntl: state.intl,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(SocialMediaElement)));
