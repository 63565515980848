import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ButtonGroup, Button, Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';

import {
  requestFormRelease,
  requestTabSwitch,
  acceptReleaseRequest,
  rejectReleaseRequest,
} from 'actions/ActiveFormsActions';

class BlockerInfos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      requestFormReleasePending: false,
      requestEmergency: false,
    };

    this.buttonRef = React.createRef();
  }

  componentDidUpdate = prevProps => {
    const { activeForms } = this.props;
    const { requestEmergency } = this.state;
    const { requestFormReleasePending } = activeForms;

    if (requestFormReleasePending && !prevProps.activeForms.requestFormReleasePending) {
      this.setState({ requestFormReleasePending: true });
      if (requestEmergency === true) {
        this.setState({ requestEmergency: false }, () => {
          const { formType, formId, doAcceptReleaseRequest } = this.props;
          doAcceptReleaseRequest(formType, formId);
        });
      }
    }

    if (!requestFormReleasePending && prevProps.activeForms.requestFormReleasePending) {
      this.setState({ requestFormReleasePending: false });
    }
  };

  requestFormRelease = () => {
    const { formType, formId, doRequestFormRelease } = this.props;
    doRequestFormRelease(formType, formId);
  };

  requestTabSwitch = () => {
    const { formType, formId, doRequestTabSwitch } = this.props;
    doRequestTabSwitch(formType, formId);
  };

  acceptReleaseRequest = () => {
    const { formType, formId, doAcceptReleaseRequest } = this.props;
    doAcceptReleaseRequest(formType, formId);
  };

  rejectReleaseRequest = () => {
    const { formType, formId, doRejectReleaseRequest } = this.props;
    doRejectReleaseRequest(formType, formId);
  };

  emergencyRelease = () => {
    const { formType, formId, doRequestFormRelease } = this.props;
    this.setState({ requestEmergency: true });
    doRequestFormRelease(formType, formId);
  };

  render() {
    const {
      intl: { messages },
      user,
      activeForms,
      emergency,
    } = this.props;

    const { allowEdit, blockedBy, requestFormReleasePending, userRequestingFormRelease } = activeForms;
    const imBlocking = user.username === blockedBy;
    const iRequestedFormRelease = user.username === userRequestingFormRelease;

    return (
      <React.Fragment>
        <span className="lh-47" />

        {imBlocking && requestFormReleasePending && (
          <React.Fragment>
            <ButtonGroup ref={this.buttonRef}>
              <Button bsStyle="success" onClick={this.acceptReleaseRequest}>
                <i className="fas fa-fw fa-handshake-alt" />
              </Button>
              <Button bsStyle="danger" onClick={this.rejectReleaseRequest}>
                <i className="fas fa-do-not-enter" />
              </Button>
            </ButtonGroup>

            <Overlay show={!!this.buttonRef.current} target={this.buttonRef.current} placement="top">
              <Tooltip>
                {userRequestingFormRelease} {messages.user_requested_that_you_give_up_the_editing}
              </Tooltip>
            </Overlay>
          </React.Fragment>
        )}

        {!allowEdit && imBlocking && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                {messages.editor.already_edited_in_another_tab}
                <br />
                {messages.click_to_enable_editing}
              </Tooltip>
            }
          >
            <Button onClick={this.requestTabSwitch}>
              <i className="fas fa-fw fa-user-edit" />
            </Button>
          </OverlayTrigger>
        )}

        {!allowEdit && !imBlocking && !requestFormReleasePending && (
          <OverlayTrigger placement="top" overlay={<Tooltip>{messages.click_to_send_a_request_to_enable_editing}</Tooltip>}>
            <Button className="m-l-10" onClick={this.requestFormRelease}>
              <i className="fas fa-fw fa-user-lock" />
            </Button>
          </OverlayTrigger>
        )}

        {!allowEdit && !imBlocking && requestFormReleasePending && iRequestedFormRelease && (
          <React.Fragment>
            <Button className="m-l-10" disabled ref={this.buttonRef}>
              <i className="fa fa-circle-notch fa-spin fa-fw" />
            </Button>

            <Overlay show={!!this.buttonRef.current} target={this.buttonRef.current} placement="top">
              <Tooltip>{messages.waiting_for_form_release}</Tooltip>
            </Overlay>
          </React.Fragment>
        )}

        {!allowEdit && !imBlocking && requestFormReleasePending && !iRequestedFormRelease && (
          <React.Fragment>
            <Button className="m-l-10" disabled ref={this.buttonRef}>
              <i className="fa fa-circle-notch fa-spin fa-fw" />
            </Button>

            <Overlay show={!!this.buttonRef.current} target={this.buttonRef.current} placement="top">
              <Tooltip>{messages.please_wait} {userRequestingFormRelease} {messages.user_already_requested_release}</Tooltip>
            </Overlay>
          </React.Fragment>
        )}

        {!allowEdit && !imBlocking && emergency && (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {messages.emergency_release}
                  <br />
                  {messages.click_to_enable_editing}
                </Tooltip>
              }
            >
              <Button onClick={this.emergencyRelease}>
                <i className="fas fa-exclamation-triangle text-danger" />
              </Button>
            </OverlayTrigger>
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {
    doRequestFormRelease: (formType, formId) => {
      dispatch(requestFormRelease(formType, formId));
    },
    doRequestTabSwitch: (formType, formId) => {
      dispatch(requestTabSwitch(formType, formId));
    },
    doAcceptReleaseRequest: (formType, formId) => {
      dispatch(acceptReleaseRequest(formType, formId));
    },
    doRejectReleaseRequest: (formType, formId) => {
      dispatch(rejectReleaseRequest(formType, formId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(BlockerInfos));
