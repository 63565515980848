import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Row, Col, Button, Clearfix } from 'react-bootstrap';
import Slider from 'react-slick';

import { getXlFile } from 'helpers/items';

export default class AssetsHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mouseOver: false,
      showGallery: false,
    };
  }

  showGallery = () => {
    this.setState({ mouseOver: false, showGallery: true });
  };

  closeGallery = () => {
    this.setState({ showGallery: false });
  };

  mouseLeave = () => {
    this.setState({ mouseOver: false });
  };

  onMouseEnter = () => {
    this.setState({ mouseOver: true });
  };

  getImageLink = index => {
    const {
      data: {
        assets: { imgs },
      },
    } = this.props;

    const img = imgs[index];
    const image = getXlFile(img);

    if (image) {
      return image.web_link;
    }

    return null;
  };

  getImageComponent = (index, itemName, flat = false) => {
    const { mouseOver } = this.state;

    return (
      <div style={{ paddingTop: flat ? '28%' : '56.25%' }} className="relative">
        <div className="top-left bottom-right no-overflow">
          <img
            src={this.getImageLink(index)}
            className="img-responsive relative animate-all"
            style={{
              top: '50%',
              transform: 'translateY(-50%) ' + (mouseOver ? 'scale(1.1) ' : ''),
              minWidth: '100%',
            }}
            alt={itemName + ' photo ' + (index + 1)}
          />
        </div>
      </div>
    );
  };

  render() {
    const { data } = this.props;
    const { showGallery } = this.state;

    const itemName = data.name;
    const imageCount = data.assets && data.assets.imgs ? data.assets.imgs.length : 0;

    if (imageCount) {
      return (
        <Grid fluid={showGallery} className="item-details-header-assets">
          <Row>
            <Col xs={12}>
              <div className="relative">
                {showGallery ? (
                  <Slider arrows dots={false} infinite adaptiveHeight accessibility>
                    {data.assets.imgs.map((img, i) => {
                      return (
                        <div className="relative" key={img.id}>
                          {this.getImageComponent(i, itemName)}
                        </div>
                      );
                    })}
                  </Slider>
                ) : (
                  <Clearfix>
                    <div
                      className="col-xs-12 no-padding cursor no-overflow b-rad-lg item-details-header-flat-img"
                      onClick={this.showGallery}
                      onMouseEnter={this.onMouseEnter}
                      onMouseLeave={this.mouseLeave}
                    >
                      {this.getImageComponent(0, itemName, true)}

                      <div className="overlayer bottom-right p-r-15 p-b-15">
                        <Button onClick={this.showGallery} className="btn-rounded">
                          <i className="fad fa-images" />{' '}
                          <FormattedMessage id="count_images" values={{ count: imageCount }} />
                        </Button>
                      </div>
                    </div>
                  </Clearfix>
                )}
              </div>
            </Col>
          </Row>
        </Grid>
      );
    }

    return null;
  }
}
