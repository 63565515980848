import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { updateActiveCms } from 'actions/LayoutActions';
import { getWebFile } from 'helpers/items';

import DocMeta from 'components/DocMeta';

import CmsElement from 'components/cms/CmsElement';

class CmsPage extends React.Component {
  componentDidMount = () => {
    if (this.props.doUpdateActiveCms) {
      this.props.updateActiveCms('cmsPage');
    }
  };

  componentWillUnmount = () => {
    if (this.props.doUpdateActiveCms) {
      this.props.updateActiveCms(null);
    }
  };

  render() {
    const { intl, appIntl, doUpdateDocMeta, layout, data, textSubstitute } = this.props;

    const tags =
      !data || !data.seo
        ? []
        : [
            { name: 'title', content: data.seo.title },
            { name: 'description', content: data.seo.description },
            { itemProp: 'name', content: data.seo.title },
            { itemProp: 'description', content: data.seo.description },
          ];

    if (data && data.seo_image) {
      const seoImageThumb = getWebFile(data.seo_image);

      if (seoImageThumb) {
        tags.push({ itemProp: 'image', content: seoImageThumb.web_link });
      }
    }

    const content = (
      <div className="content">
        {data.content !== undefined
          ? data.content.map((element, i) => {
              return (
                <CmsElement
                  index={i.toString()}
                  {...element}
                  intl={intl}
                  appIntl={appIntl}
                  layout={layout}
                  textSubstitute={textSubstitute}
                  key={i}
                />
              );
            })
          : null}
      </div>
    );

    return doUpdateDocMeta ? <DocMeta tags={tags}>{content}</DocMeta> : <div>{content}</div>;
  }
}
CmsPage.defaultProps = {
  doUpdateActiveCms: true,
  doUpdateDocMeta: true,
  textSubstitute: null,
};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    layout: state.layout,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateActiveCms: activeCms => {
      dispatch(updateActiveCms(activeCms));
    },
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CmsPage)
);
