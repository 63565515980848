import React from 'react';
import _, { findIndex as _findIndex, forEach as _forEach, cloneDeep as _cloneDeep, find as _find } from 'lodash';
import {
  closeGaps,
  findSpace,
  getBlockTopFromRaster,
  moveAncestorsDown,
  pasteRasterBlock,
  removeRasterBlock,
  reserveSpace,
  resizeRasterBlock,
  switchRasterPosition,
} from './Raster';
import { getPageNameIndex } from './TemplateSettings';
import { copyMediasUsed, makeMediasUnused } from './UsedMedia';

/**
 * Extracts attribute value
 * from a HTML code string like
 * <tagName attributeName=" 123 " />
 * @param {string} str HTML code to parse
 * @param {string} attributeName to look for
 * @returns number
 */
const getNumericValue = (str, attributeName) => {
  const re = new RegExp(`${attributeName}="\\s*([^"\\s]*)`);
  const match = re.exec(str);
  return match ? Number(match[1]) : 0;
};

export function getBlockResizers(block, templateBlocks) {
  let bigger = 100000;
  let smaller = 0;
  let smallerId = null;
  let biggerId = null;
  _forEach(templateBlocks, (category, j) => {
    _forEach(category.variants, (variant, k) => {
      _forEach(variant.blocks, (templateBlock, l) => {
        if(templateBlock.size_group !== '' && templateBlock.size_group === block.size_group && templateBlock.id.localeCompare(block.template_id) !== 0){
          if(templateBlock.group_index > 0){
            if(templateBlock.group_index > block.group_index){
              if(templateBlock.group_index < bigger){
                biggerId = templateBlock.id;
                bigger = 0;
              }
            } else if(templateBlock.group_index < block.group_index){
              if(templateBlock.group_index > smaller){
                smallerId = templateBlock.id;
                smaller = 0;
              }
            }
          }else{
            if(templateBlock.page_space_needed > block.page_space_needed){
              if(templateBlock.page_space_needed < bigger){
                biggerId = templateBlock.id;
                bigger = templateBlock.page_space_needed;
              }
            } else if(templateBlock.page_space_needed < block.page_space_needed){
              if(templateBlock.page_space_needed > smaller){
                smallerId = templateBlock.id;
                smaller = templateBlock.page_space_needed;
              }
            }
          }
        }

      });
    });
    if (j === templateBlocks.length - 1) {
      block.bigger = biggerId;
      block.bigger_needed = bigger;
      block.smaller = smallerId;
    }
  });
  return block;
}

export function copyBlock(target, currentContentPage, raster, type) {
  // todo: kopieren ganze seite mit raster
  let targetBlock = [];
  const targetId = target.getAttribute('data-block');
  const contentBlocks = currentContentPage.blocks;
  if (type === 'inner') {
    const targetParent = target.getAttribute('data-parent');
    const parent = _find(contentBlocks, { id: targetParent });

    targetBlock.push(_cloneDeep(_find(parent.blocks, { id: targetId })));
  } else {
    const targetIndex = targetId === 'all' ? 'all' : _findIndex(contentBlocks, { id: targetId });
    if (targetIndex === 'all') {
      targetBlock = _cloneDeep(contentBlocks);
      targetBlock = _.filter(targetBlock, x => x.no_copies !== true);
    } else {
      targetBlock.push(_cloneDeep(contentBlocks[targetIndex]));
    }
  }
  return targetBlock;
}

export function getFixedBlocks(contentPageBlocks) {
  const fixedBlocks = [];
  let fromIndex = _findIndex(contentPageBlocks, ['fixed', true], 0);
  while (fromIndex !== -1) {
    fixedBlocks.push(_find(contentPageBlocks, ['fixed', true], fromIndex).id);
    fromIndex = _findIndex(contentPageBlocks, ['fixed', true], fromIndex + 1);
  }

  return fixedBlocks;
}
export function getToppedBlocks(contentPageBlocks) {
  const toppedBlocks = [];
  let fromIndex2 = _.findIndex(contentPageBlocks, block => (block.top > -1 && block.fixed === false), 0);
  while (fromIndex2 !== -1) {
    toppedBlocks.push(_find(contentPageBlocks, block => (block.top > -1 && block.fixed === false), fromIndex2).id);
    fromIndex2 = _.findIndex(contentPageBlocks, block =>(block.top > -1 && block.fixed === false), fromIndex2 + 1);
  }
  return toppedBlocks;
}

export function pasteBlock(
  totalBlockCount,
  targetIndex,
  currentPage,
  contentPage,
  raster,
  copiedBlock,
  usedMedia,
  type,
  innerTargetIndex
) {
  let newTotalBlockCount = totalBlockCount;
  let spaceConsumed;
  let start;
  let changedRaster = raster;
  let startRasterId = 0;
  const changedNavEntries = [];
  let fixedBlocks = [];
  let newId = '';
  if (type !== 'inner') {
    spaceConsumed = contentPage.space_consumed;
    startRasterId = targetIndex !== -1 ? contentPage.blocks[targetIndex].id : 0;
    start =
      targetIndex === -1
        ? 0
        : getBlockTopFromRaster(raster, currentPage, startRasterId) + contentPage.blocks[targetIndex].page_space_needed;
    fixedBlocks = getFixedBlocks(contentPage.blocks);
    changedRaster = moveAncestorsDown(raster, currentPage, startRasterId, fixedBlocks);
  }
  _forEach(copiedBlock, (block, i) => {
    newTotalBlockCount++;
    newId = block.template_id + '_' + newTotalBlockCount;
    usedMedia = copyMediasUsed(usedMedia, block, newId);
    block.id = newId;
    if (type !== 'inner') {
      if (block.nav_name && block.nav_link) {
        changedNavEntries.push({
          0: block.nav_link + newId,
          1: block.nav_name,
          2: (block.nav_options || '') + ' ' + newId,
        });
        changedNavEntries.push('in');
      }
      spaceConsumed += block.page_space_needed;
      changedRaster = pasteRasterBlock(
        changedRaster,
        currentPage,
        start,
        startRasterId,
        block.id,
        block.page_space_needed
      );
      start += block.page_space_needed;
    }
  });
  if (type !== 'inner') {
    changedRaster = closeGaps(changedRaster, currentPage, fixedBlocks);
    contentPage.space_consumed = spaceConsumed;
    if (targetIndex < 0) {
      contentPage.blocks = [...copiedBlock, ...contentPage.blocks];
    } else {
      contentPage.blocks = [
        ...contentPage.blocks.slice(0, targetIndex + 1),
        ...copiedBlock,
        ...contentPage.blocks.slice(targetIndex + 1),
      ];
    }
  } else if (innerTargetIndex < 0) {
    contentPage.blocks[targetIndex].blocks = [...copiedBlock, contentPage.blocks[targetIndex].blocks];
  } else {
    contentPage.blocks[targetIndex].blocks = [
      ...contentPage.blocks[targetIndex].blocks.slice(0, innerTargetIndex + 1),
      ...copiedBlock,
      ...contentPage.blocks[targetIndex].blocks.slice(innerTargetIndex + 1),
    ];
  }
  return {
    contentPage,
    newTotalBlockCount,
    changedRaster,
    usedMedia,
    navEntry: changedNavEntries,
    newId,
  };
}

export function changeBlock(sourceId, targetId, contentPages, raster, currentPage, exportType) {
  const contentPage = contentPages[currentPage];
  const sourceBlock = _find(contentPage.blocks, { id: sourceId });
  const targetBlock = _find(contentPage.blocks, { id: targetId });

  const rasterNew = switchRasterPosition(
    raster,
    currentPage,
    getBlockTopFromRaster(raster, currentPage, sourceId),
    getBlockTopFromRaster(raster, currentPage, targetId),
    sourceBlock.page_space_needed,
    targetBlock.page_space_needed,
    sourceId,
    targetId
  );

  const sourceBlockIndex = _findIndex(contentPage.blocks, { id: sourceId });
  const targetBlockIndex = _findIndex(contentPage.blocks, { id: targetId });
  contentPages[currentPage].blocks[sourceBlockIndex] = targetBlock;
  contentPages[currentPage].blocks[targetBlockIndex] = sourceBlock;

  return {
    raster: rasterNew,
    contentPages,
  };
}

export function createTimelineBlockEntry(changes, rasterId, currentPage, text, action) {
  const newChanges = {
    text,
    action,
  };
  const pIndex = (currentPage + 1).toString();
  if (typeof changes === 'undefined' || typeof changes[pIndex] === 'undefined') {
    changes[pIndex] = [];
  }
  changes[pIndex][rasterId] = newChanges;
  return changes;
}

export function resetBlock(rasterId, blockId, contentPages, currentPage, templateBlocks, usedMedia) {
  const sourceIndex = _findIndex(contentPages[currentPage].blocks, { id: rasterId });
  const sourceBlock = contentPages[currentPage].blocks[sourceIndex];
  const unusedMedia = makeMediasUnused(usedMedia, sourceBlock);
  let targetBlock = '';
  _forEach(templateBlocks, (category, i) => {
    _forEach(category.variants, (variant, j) => {
      _forEach(variant.blocks, (block, k) => {
        if (block.id.localeCompare(blockId) === 0) {
          targetBlock = _cloneDeep(block);
          targetBlock.template_id = block.id;
        }
      });
      if (targetBlock !== '') {
      }
    });
    if (targetBlock !== '') {
    }
  });
  targetBlock.params = [];
  targetBlock.id = rasterId;

  if (sourceBlock.oversize > 0) {
    targetBlock.oversize_position = sourceBlock.oversize_position;
    targetBlock.links_to = sourceBlock.links_to;
    const linkedIndex = _findIndex(contentPages[sourceBlock.links_to].blocks, { id: rasterId });
    contentPages[sourceBlock.links_to].blocks[linkedIndex].params = targetBlock.params;
  }
  contentPages[currentPage].blocks[sourceIndex] = getBlockResizers(targetBlock, templateBlocks);
  return {
    contentPages,
    usedMedia: unusedMedia,
    unsaved: true,
  };
}

export function resizeBlock(templateBlocks, contentPage, raster, currentPage, blockId, changeId) {
  const sourceIndex = _findIndex(contentPage.blocks, { id: blockId });
  const spaceConsumed = contentPage.space_consumed - contentPage.blocks[sourceIndex].page_space_needed;
  const params = _cloneDeep(contentPage.blocks[sourceIndex].params);
  let changedRaster = raster;
  let targetBlock = '';
  _forEach(templateBlocks, (category, i) => {
    _forEach(category.variants, (variant, j) => {
      _forEach(variant.blocks, (block, k) => {
        if (block.id.localeCompare(changeId) === 0) {
          targetBlock = getBlockResizers(_cloneDeep(block), templateBlocks);
          targetBlock.params = params;
          targetBlock.id = blockId;
          targetBlock.template_id = block.id;
        }
      });
      if (targetBlock !== '') {
      }
    });
    if (targetBlock !== '') {
    }
  });
  if (targetBlock !== '') {
    const fixedBlocks = getFixedBlocks(contentPage.blocks);
    changedRaster = resizeRasterBlock(
      raster,
      currentPage,
      blockId,
      contentPage.blocks[sourceIndex].page_space_needed,
      targetBlock.page_space_needed,
      fixedBlocks
    );
    contentPage.blocks[sourceIndex] = targetBlock;
    contentPage.space_consumed = spaceConsumed + targetBlock.page_space_needed;
  }
  return {
    contentPage,
    changedRaster,
    unsaved: true,
  };
}

export function removeBlock(currentPage, contentPages, raster, sourceRasterId, sourceSpace, magneticBlocks, usedMedia) {
  const fixedBlocks = getFixedBlocks(contentPages[currentPage].blocks);
  const toppedBlocks =  getToppedBlocks(contentPages[currentPage].blocks);
  let mergedBlocksOfFixedAndTopped = fixedBlocks.concat(toppedBlocks);
  let changedRaster = null;
  const changedNavEntries = [];
  if (raster) {
    changedRaster = removeRasterBlock(raster, currentPage, sourceRasterId, magneticBlocks, mergedBlocksOfFixedAndTopped);
  }
  const newSpace = contentPages[currentPage].space_consumed - sourceSpace;
  const targetIndex = _findIndex(contentPages[currentPage].blocks, { id: sourceRasterId });
  if (
    contentPages[currentPage].blocks[targetIndex].nav_name &&
    contentPages[currentPage].blocks[targetIndex].nav_link
  ) {
    changedNavEntries.push({
      0: '',
      1: '',
      2: sourceRasterId,
    });
    changedNavEntries.push('out');
  }
  const unusedMedia = makeMediasUnused(usedMedia, contentPages[currentPage].blocks[targetIndex]);
  if (contentPages[currentPage].blocks[targetIndex].oversize !== 0) {
    const linksTo = contentPages[currentPage].blocks[targetIndex].links_to;
    const linkedIndex = _findIndex(contentPages[linksTo].blocks, { id: sourceRasterId });
    contentPages[linksTo].blocks = [
      ...contentPages[linksTo].blocks.slice(0, linkedIndex),
      ...contentPages[linksTo].blocks.slice(linkedIndex + 1),
    ];
    // contentPages[currentPage].blocks[linkedIndex].isDeleted = true;
    contentPages[linksTo].space_consumed = newSpace;
    const fixedBlocks = getFixedBlocks(contentPages[linksTo].blocks);
    const toppedBlocks =  getToppedBlocks(contentPages[currentPage].blocks);
    let mergedBlocksOfFixedAndTopped = fixedBlocks.concat(toppedBlocks);
    if (raster) {
      changedRaster = removeRasterBlock(changedRaster, linksTo, sourceRasterId, magneticBlocks, mergedBlocksOfFixedAndTopped);
    }
  }
  contentPages[currentPage].blocks = [
    ...contentPages[currentPage].blocks.slice(0, targetIndex),
    ...contentPages[currentPage].blocks.slice(targetIndex + 1),
  ];
  // contentPages[currentPage].blocks[targetIndex].isDeleted = true;
  contentPages[currentPage].space_consumed = newSpace;
  return {
    contentPages,
    raster: changedRaster,
    unsaved: true,
    usedMedia: unusedMedia,
    navEntry: changedNavEntries,
  };
}

export function getSortedBlocks(cRow, cCol, blocks){

      let col = 1;
      let cRowInit = cRow;
      let sortedBlock = [];
      for(let i = 0; i < blocks.length; i++){
        if(blocks[i].page_space_needed <= cRow){
          for(let a = 0; a < sortedBlock.length; a++){
            if(sortedBlock.length > 0 && sortedBlock[a].col === col){
              sortedBlock[a].R = 0;

            }
          }
          cRow = cRow - blocks[i].page_space_needed;
        }
        else{
          col = col + 1;
          cRow = cRowInit;
          cRow = cRow - blocks[i].page_space_needed;
        }
        sortedBlock.push({id:blocks[i].id, col: col, R:cRow});
      }
  return sortedBlock;
}




export function insertBlock(
  pagesOperator,
  changedRaster,
  newTotalBlockCount,
  treeIndex,
  templateBlocks,
  currentPage,
  templateSettings,
  pageAmount,
  blockContainer,
  page,
  treeIndexTwo
) {
 /* console.log("alles", "pagesoperator", pagesOperator, "changedRaster", changedRaster, "newTotalblockcount", newTotalBlockCount,
    "treeindex", treeIndex, "templateblocks", templateBlocks, "currentpage", currentPage, "templatesettings", templateSettings,
    "pageamount", pageAmount, "blockcontainer", blockContainer, "page", page, "treeindextwo", treeIndexTwo); */
  let counter;
  let currentPageOwn;
  const treeIndexClone = _cloneDeep(treeIndex);
  const treeIndexTwoClone = _cloneDeep(treeIndexTwo);
  if (page === 'samplePage' || page === 'samplePageTwo') {
    counter = pageAmount;
    currentPageOwn = 0;
  } else if (page === 'addPage') {
    counter = currentPage[0] + currentPage.length - 1;
    currentPageOwn = currentPage[0] - 1;
  } else {
    counter = currentPage + 1;
    currentPageOwn = currentPage;
  }

  let block;
  let nextFreeSpace;
  let newBlockContainer = blockContainer;
  const changedNavEntries = [];
  let lastPageNumber;

  for (currentPageOwn; currentPageOwn < counter; currentPageOwn++) {
    let even; // even set even || odd, and even && odd
    if (templateSettings.sample_page_number_two > 0 && page === 'samplePage') {
      if (templateSettings.sample_page_number % 2 === 0) {
        if ((currentPageOwn + 1) % 2 === 0) {
          even = true;
        } else {
          even = false;
        }
      }
      if (templateSettings.sample_page_number % 2 !== 0) {
        if ((currentPageOwn + 1) % 2 !== 0) {
          even = true;
        } else {
          even = false;
        }
      }
    }

    if (templateSettings.sample_page_number_two > 0 && page === 'samplePageTwo') {
      if (templateSettings.sample_page_number_two % 2 === 0) {
        if ((currentPageOwn + 1) % 2 === 0) {
          even = true;
        } else {
          even = false;
        }
      }
      if (templateSettings.sample_page_number_two % 2 !== 0) {
        if ((currentPageOwn + 1) % 2 !== 0) {
          even = true;
        } else {
          even = false;
        }
      }
    }

    // single Page and add Page
    if (
      (typeof templateSettings.sample_page_number_two === 'undefined' ||
        templateSettings.sample_page_number_two === 0) &&
      (page === 'single' || page === 'addPage')
    ) {
      even = true;
    }

    if (templateSettings.sample_page_number_two > 0 && page === 'addPage') {
      if (templateSettings.sample_page_number_two % 2 === 0) {
        if ((currentPageOwn + 1) % 2 === 0) {
          even = false; // even ist true, but it is to reverse
        } else {
          even = true;
        }
      } else if ((currentPageOwn + 1) % 2 === 0) {
        even = true;
      } else {
        even = false;
      }
      // false druckt samplepagetwo
      // true druckt samplepage
    }

    // even === false nur bei addpage ansonsten bleiben die seiten leer
    if (even === false) {
      if (templateSettings.sample_page_number_two > 0 && page === 'addPage') {
        /** * startindextwo ** */
        let treeIndexLengthTwo;
        treeIndexLengthTwo = treeIndexTwoClone.length;

        for (let loopRepeatTwo = 0; loopRepeatTwo < treeIndexLengthTwo; loopRepeatTwo++) {
          treeIndexTwo = treeIndexTwoClone[loopRepeatTwo].toString().split('.');
          block = _cloneDeep(templateBlocks[treeIndexTwo[0]].variants[treeIndexTwo[1]].blocks[treeIndexTwo[2]]);
          nextFreeSpace = block.top;
          if (templateSettings.last_page_number === 'last') {
            lastPageNumber = pageAmount;
          } else {
            lastPageNumber = templateSettings.last_page_number;
          }
          if (
            page === 'single' || 1 === 1
            //todo(currentPageOwn !== templateSettings.first_page_number - 1 && currentPageOwn !== lastPageNumber - 1) (we need a new setting to select or deselect first or last page for adding samplepagecontent)
          ) {
            block.template_id = block.id;
            block.id = block.template_id + '_' + newTotalBlockCount;
            if(block.template && (block.template.includes('draggable') || block.template.includes('allowdraganddrop'))){
              let blockTemplate = _cloneDeep(block.template);
              blockTemplate = blockTemplate.toLowerCase();
              block.position_x = getNumericValue(blockTemplate, "defaultpositionx") || 0;
              block.position_y = getNumericValue(blockTemplate, "defaultpositiony") || 0;
            }
            block.params = [];
            block.blocks = [];
            block.space_consumed = 0;
            const endless = !!templateSettings.pages[getPageNameIndex(currentPageOwn, templateSettings)].endless;
            if (!blockContainer) {
              if (block.nav_name && block.nav_link) {
                changedNavEntries.push({
                  0: block.nav_link + block.id,
                  1: block.nav_name,
                  2: (block.nav_options || '') + ' ' + block.id,
                });
                changedNavEntries.push('in');
              }
              if (block.page_space_needed > 0) {
                if (nextFreeSpace < 0) {
                  let offset = block.top < 0 ? null : block.top;
                  if (typeof block.bottom !== 'undefined' && block.bottom !== null) {
                    offset =
                      templateSettings.pages[getPageNameIndex(currentPageOwn, templateSettings)].page_space -
                      block.page_space_needed;
                  }
                  nextFreeSpace = findSpace(
                    changedRaster,
                    currentPageOwn,
                    block.page_space_needed,
                    offset,
                    templateSettings.pages[getPageNameIndex(currentPageOwn, templateSettings)].page_space,
                    endless
                  );
                  if (nextFreeSpace < 0) {
                    // break if not enough space on page, the app shouldn't get to this point!!!
                    alert("kein Platz");
                    return;
                  }
                }
              }
              if (block.oversize !== 0) {
                let blockDirection = 'Right';
                let pageDirection = currentPageOwn - 1;
                const copyBlockDirection = block.oversize_direction;
                if (copyBlockDirection === 'Right') {
                  blockDirection = 'Left';
                  pageDirection = currentPageOwn + 1;
                }
                if (templateSettings.format.order_pages === 'down-up' && currentPageOwn < pageAmount / 2) {
                  // reverse insert page
                  pageDirection = currentPageOwn + 1;
                  if (block.oversize_direction === 'Right') {
                    pageDirection = currentPageOwn - 1;
                  }
                }

                if (block.page_space_needed > 0) {
                  const nextFreeSpaceCopy = findSpace(
                    changedRaster,
                    pageDirection,
                    block.page_space_needed,
                    block.top,
                    templateSettings.pages[getPageNameIndex(pageDirection, templateSettings)].page_space
                  );
                  if (nextFreeSpaceCopy > nextFreeSpace) {
                    nextFreeSpace = nextFreeSpaceCopy;
                  }
                  changedRaster = reserveSpace(
                    changedRaster,
                    pageDirection,
                    block.page_space_needed,
                    nextFreeSpace,
                    block.id
                  );
                }
                const blockCopy = _cloneDeep(block);
                block.oversize_position = ' block' + blockDirection;
                blockCopy.oversize_position = ' block' + block.oversize_direction;
                blockCopy.links_to = currentPageOwn;
                blockCopy.fixed = true;
                blockCopy.top = nextFreeSpace;
                block.links_to = pageDirection;
                block.fixed = true;
                block.top = nextFreeSpace;
                pagesOperator[pageDirection].blocks.push(getBlockResizers(blockCopy, templateBlocks));
                pagesOperator[pageDirection].space_consumed += blockCopy.page_space_needed;
              }
              if (block.page_space_needed > 0) {
                changedRaster = reserveSpace(
                  changedRaster,
                  currentPageOwn,
                  block.page_space_needed,
                  nextFreeSpace,
                  block.id,
                  endless
                );
              }
              if (block.container === true) {
                newBlockContainer = block.id;
              }
              pagesOperator[currentPageOwn].blocks.push(getBlockResizers(block, templateBlocks));
              pagesOperator[currentPageOwn].space_consumed += block.page_space_needed;
            } else {
              const innerBlock = _find(pagesOperator[currentPageOwn].blocks, { id: blockContainer });
              innerBlock.blocks.push(block);
              innerBlock.space_consumed += block.page_space_needed;
            }
            newTotalBlockCount++;
          }
        }
        /** EndIndexTwo * */
      }
    } else {
      /** * start ** */
      let treeIndexLength;

      if (page === 'addPage') {
        treeIndexLength = treeIndexClone.length;
      } else {
        treeIndexLength = 1;
      }
      for (let loopRepeat = 0; loopRepeat < treeIndexLength; loopRepeat++) {
        if (page === 'addPage') {
          treeIndex = treeIndexClone[loopRepeat].toString().split('.');
        }
        block = _cloneDeep(templateBlocks[treeIndex[0]].variants[treeIndex[1]].blocks[treeIndex[2]]);
        nextFreeSpace = block.top;
        if (templateSettings.last_page_number === 'last') {
          lastPageNumber = pageAmount;
        } else {
          lastPageNumber = templateSettings.last_page_number;
        }
        if (
          page === 'single' || 1 === 1
          // todo (currentPageOwn !== templateSettings.first_page_number - 1 && currentPageOwn !== lastPageNumber - 1) (we need a new setting to select or deselect first or last page for adding samplepagecontent)
        ) {
          block.template_id = block.id;
          block.id = block.template_id + '_' + newTotalBlockCount;
          if(block.template && (block.template.includes('draggable') || block.template.includes('allowdraganddrop'))){
            let blockTemplate = _cloneDeep(block.template);
            blockTemplate = blockTemplate.toLowerCase();
            block.position_x = getNumericValue(blockTemplate, "defaultpositionx") || 0;
            block.position_y = getNumericValue(blockTemplate, "defaultpositiony") || 0;
          }

          block.params = [];
          block.blocks = [];
          block.space_consumed = 0;
          const endless = !!templateSettings.pages[getPageNameIndex(currentPageOwn, templateSettings)].endless;
          if (!blockContainer) {
            if (block.nav_name && block.nav_link) {
              changedNavEntries.push({
                0: block.nav_link + block.id,
                1: block.nav_name,
                2: (block.nav_options || '') + ' ' + block.id,
              });
              changedNavEntries.push('in');
            }
            if (block.page_space_needed > 0) {
              if (nextFreeSpace < 0) {
                let offset = block.top < 0 ? null : block.top;
                if (typeof block.bottom !== 'undefined' && block.bottom !== null) {
                  offset =
                    templateSettings.pages[getPageNameIndex(currentPageOwn, templateSettings)].page_space -
                    block.page_space_needed;
                }
                nextFreeSpace = findSpace(
                  changedRaster,
                  currentPageOwn,
                  block.page_space_needed,
                  offset,
                  templateSettings.pages[getPageNameIndex(currentPageOwn, templateSettings)].page_space,
                  endless
                );
                if (nextFreeSpace < 0) {
                  // break if not enough space on page, the app shouldn't get to this point!!!
                  alert("kein Platz");
                  return;
                }
              }
            }
            if (block.oversize !== 0) {
              let blockDirection = 'Right';
              let pageDirection = currentPageOwn - 1;
              const copyBlockDirection = block.oversize_direction;
              if (copyBlockDirection === 'Right') {
                blockDirection = 'Left';
                pageDirection = currentPageOwn + 1;
              }
              if (templateSettings.format.order_pages === 'down-up' && currentPageOwn < pageAmount / 2) {
                // reverse insert page
                pageDirection = currentPageOwn + 1;
                if (block.oversize_direction === 'Right') {
                  pageDirection = currentPageOwn - 1;
                }
              }

              if (block.page_space_needed > 0) {
                const nextFreeSpaceCopy = findSpace(
                  changedRaster,
                  pageDirection,
                  block.page_space_needed,
                  block.top,
                  templateSettings.pages[getPageNameIndex(pageDirection, templateSettings)].page_space
                );
                if (nextFreeSpaceCopy > nextFreeSpace) {
                  nextFreeSpace = nextFreeSpaceCopy;
                }
                changedRaster = reserveSpace(
                  changedRaster,
                  pageDirection,
                  block.page_space_needed,
                  nextFreeSpace,
                  block.id
                );
              }
              const blockCopy = _cloneDeep(block);
              block.oversize_position = ' block' + blockDirection;
              blockCopy.oversize_position = ' block' + block.oversize_direction;
              blockCopy.links_to = currentPageOwn;
              blockCopy.fixed = true;
              blockCopy.top = nextFreeSpace;
              block.links_to = pageDirection;
              block.fixed = true;
              block.top = nextFreeSpace;
              pagesOperator[pageDirection].blocks.push(getBlockResizers(blockCopy, templateBlocks));
              pagesOperator[pageDirection].space_consumed += blockCopy.page_space_needed;
            }
            if (block.page_space_needed > 0) {
              changedRaster = reserveSpace(
                changedRaster,
                currentPageOwn,
                block.page_space_needed,
                nextFreeSpace,
                block.id,
                endless
              );
            }
            if (block.container === true) {
              newBlockContainer = block.id;
            }
            pagesOperator[currentPageOwn].blocks.push(getBlockResizers(block, templateBlocks));
            pagesOperator[currentPageOwn].space_consumed += block.page_space_needed;
          } else {
            const innerBlock = _find(pagesOperator[currentPageOwn].blocks, {id: blockContainer});
            //innerBlock.blocks.push(block);
            /*
            let _innerBlock = _cloneDeep(innerBlock);
            _innerBlock.blocks.push(block);
             */
            let _sortedBlocks = getSortedBlocks(
              innerBlock.container_space,
              innerBlock.container_cols,
              innerBlock.blocks
            );

            if (_sortedBlocks.length > 0) {
              let startSort = false;
              _forEach(_sortedBlocks, (sBlock, i) => {
                if(sBlock.col === innerBlock.container_cols){
                  startSort = true
                }
              });
              if(startSort) {
                if (_sortedBlocks[_sortedBlocks.length - 1].R >= block.page_space_needed) {
                  innerBlock.blocks.push(block);
                } else {
                  for (let x = 0; x < _sortedBlocks.length; x++) {
                    if (_sortedBlocks[x].R >= block.page_space_needed) {
                      innerBlock.blocks.splice(x + 1, 0, block);
                      x = _sortedBlocks.length;
                    }
                  }
                }
              }
              else{
                innerBlock.blocks.push(block);
              }
            }
            else{
              innerBlock.blocks.push(block);
            }

            innerBlock.space_consumed += block.page_space_needed;
          }
          newTotalBlockCount++;
        }
      }
      /** End * */
    }
  }
  return {
    contentPages: pagesOperator,
    raster: changedRaster,
    totalBlockCount: newTotalBlockCount,
    unsaved: true,
    blockContainer: newBlockContainer,
    navEntry: changedNavEntries,
    newId: block.id,
  };
}
