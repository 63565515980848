import { put, select, takeEvery } from 'redux-saga/effects';
import { Howl } from 'howler';
import { push } from 'connected-react-router';

import { getLogger, intlModule } from '@chedri/base';

const messageSound = new Howl({
  src: ['/sounds/chatterMessage.mp3'],
  loop: false,
});

const logger = getLogger('chatter');

function newMessageSaga() {
  try {
    logger.debug('Play message sound');
    messageSound.play();
  } catch (err) {
    logger.error('Error playing message sound', err);
  }
}

function* navigateToCustomerChat({ payload: { username } }) {
  const locale = yield select(intlModule.selectors.getLocale);
  yield put(push(`/${locale}/account/communication/${username}`));
  window.focus();
}

export default function* chatterSaga() {
  logger.debug('Watch chatter actions');

  yield takeEvery(action => action.type === 'SOCKET_EVENT' && action.payload.eventName === 'message', newMessageSaga);
  yield takeEvery('VIEW_CUSTOMER_CHAT', navigateToCustomerChat);
}
