import React from 'react';
import { find as _find, forEach as _forEach } from 'lodash';
import ReactDOM from 'react-dom';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import FormInput from '../../../components/forms/FormInput';

const ContextModal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('context-modal'));
};

class GoogleMapsElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showGoogleMapsOptions: false,
      markerFromCFE: 'mark',
      selectedPlaceFromCFE: 'select',
      adresse: '',
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.params[0] && prevProps.params[0]?.value !== this.props.params[0]?.value) {
      this.getMapData();
    }
  };


  componentDidMount = () => {
    this.getMapData();
  };

  GoogleMapsFormOptions = () => {
    this.setState({ showGoogleMapsOptions: true });
  };

  handleIEclose = () => {
    this.setState({ showGoogleMapsOptions: false });
  };

  changeMarkerOfGoogleMaps = (name, value) => {
    this.setState({ adresse: value });
  };

  getMapData = () => {
    const { name: Tag, attributes, params } = this.props;
    const { options } = this.state;
    const param = _find(params, ['name', attributes['data-name']]);
    const elements = [];
    if (param) {
      const output = param.value.split('#cc#');
      _forEach(output, (entry, i) => {
        const mapData = entry.split('#aaa#');
        elements.push(mapData);
      });
    }
    if (elements[1] !== undefined) {
      this.setState({ adresse: elements[1][0] });
    }else{
      this.setState({ adresse: undefined });
    }
  };

  setGoogleMaps = () => {
    const { id, attributes, updateParam } = this.props;

    let param = '';
    param += '#cc#' + this.state.adresse;

    const overflow = false;
    const default_image = false;
    const errors = [{ overflow, default_image }];
    updateParam(id, attributes['data-name'], param, errors);

    this.handleIEclose();
  };

  render() {
    const { name: Tag, attributes, disabled } = this.props;
    const { showGoogleMapsOptions, adresse, options } = this.state;
    const output = [];
    const {
      intl: { messages },
    } = this.props;

    return (
      <>
        <Tag {...attributes} onClick={this.GoogleMapsFormOptions}>
          <>
            <div className="GoogleMapsCSS">
              {adresse ? (
                <div className="GoogleMapsCSS2">
                  <div
                    className="mapouter"
                    style={{ position: 'relative', textAlign: 'right', height: '500px', width: '100%' }}
                  >
                    <div className="gmap_canvas" style={{ pointerEvents: 'none', overflow: 'hidden', background: 'none!important' }}>
                      <iframe
                        title="Map"
                        width="100%"
                        height="500px"
                        id="gmap_canvas"
                        src={
                          'https://maps.google.com/maps?q=' +
                          encodeURIComponent(this.state.adresse) +
                          '&t=&z=13&ie=UTF8&iwloc=&output=embed'
                        }
                        frameBorder="0"
                        scrolling="no"
                        marginHeight="0"
                        marginWidth="0"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="wrap">
                  <p>{messages.editor.no_location}</p>
                </div>
              )}
            </div>
          </>
        </Tag>

        {showGoogleMapsOptions && !disabled && (
          <ContextModal>
            <div id="contextModalWrap">
              <div id="contextModalStage" className="row">
                <h3>{messages.editor.google_maps_element_settings}</h3>
                <FormInput
                  type="text"
                  label={messages.editor.address}
                  name="address"
                  onChange={this.changeMarkerOfGoogleMaps}
                  value={this.state.adresse}
                />
                <div
                  className="mapouter"
                  style={{ position: 'relative', textAlign: 'right', height: '500px', width: '100%' }}
                >
                  <div className="gmap_canvas" style={{ overflow: 'hidden', background: 'none!important' }}>
                    <iframe
                      width="100%"
                      height="500px"
                      id="gmap_canvas"
                      src={
                        'https://maps.google.com/maps?q=' +
                        encodeURIComponent(this.state.adresse) +
                        '&t=&z=13&ie=UTF8&iwloc=&output=embed'
                      }
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginWidth="0"
                    />
                  </div>
                </div>
                <div className="cancelIE">
                  <Button bsStyle="danger" bsSize="large" onClick={this.setGoogleMaps}>
                    {messages.editor.take_on}
                  </Button>
                  <Button bsStyle="warning" bsSize="large" onClick={this.handleIEclose}>
                    {messages.editor.abort}
                  </Button>
                </div>
              </div>
            </div>
          </ContextModal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(GoogleMapsElement)));
