import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

export default class CmsElementTimeline extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  render() {
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={
          'ms-timeline-container timeline-container ' + shownClass + this.getResponsiveClasses() + this.getCssClasses()
        }
        style={{
          ...this.getBackgroundStyle(),
        }}
      >
        {this.getElementStyle()}

        <section class="timeline">
          {this.props.children
            ? this.props.children.map((child, i) => {
                return React.cloneElement(child, { timelineIndex: i });
              })
            : null}
        </section>

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementTimeline.defaultProps = {
  index: '0',
};
