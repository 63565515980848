import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import Modal from 'components/Modal';
import { forEach as _forEach } from 'lodash';

import EntityMeta from 'components/EntityMeta';

import Loader from 'components/layout/Loader';

class DocumentationEntityInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      entityMetaIdentifier: props.entityMetaIdentifier ? props.entityMetaIdentifier : null,
    };

    if (props.meta[props.entityMetaIdentifier] !== undefined && !props.meta[props.entityMetaIdentifier].pending) {
      this.state = {
        ...this.state,
        metaInformation: props.meta[props.entityMetaIdentifier],
      };
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { show, meta, entityMetaIdentifier } = this.props;
    const { entityMetaIdentifier: stateEntityMetaIdentifier } = this.state;

    if (meta[stateEntityMetaIdentifier]) {
      if (
        (!prevProps.meta[stateEntityMetaIdentifier] || prevProps.meta[stateEntityMetaIdentifier].pending) &&
        !meta[stateEntityMetaIdentifier].pending
      ) {
        this.setState({
          metaInformation: meta[stateEntityMetaIdentifier],
        });
      }
    }

    //
    if (stateEntityMetaIdentifier !== entityMetaIdentifier && !prevProps.show && show) {
      this.setState({ entityMetaIdentifier });
    }

    if (stateEntityMetaIdentifier !== prevState.entityMetaIdentifier) {
      if (meta[stateEntityMetaIdentifier] && !meta[stateEntityMetaIdentifier].pending) {
        this.setState({
          metaInformation: meta[stateEntityMetaIdentifier],
        });
      }
    }
  };

  entityMetaInfoTable = () => {
    const entityMeta = this.state.metaInformation.data;
    const fields = [];

    _forEach(entityMeta, (data, name) => {
      const validationRules = data.validate.rules.split(',');

      fields.push(
        <tr key={name}>
          <td class="v-align-middle bold">{name}</td>
          <td class="v-align-middle">
            {data.complexType ? (
              <div>
                <a href="#moreInfo" onClick={e => this.showInfo(e, data.type)}>
                  {data.type}
                </a>
                {data.ref ? (
                  <div class="m-t-10">
                    This field is a reference. Just pass an id object to reference the data.
                    <br />
                    For example: {'{id: "valid id"}'}. Or in case of a Client object, {'{username: "valid username"}'}.
                  </div>
                ) : null}
              </div>
            ) : (
              <span>{data.type}</span>
            )}
          </td>
          <td class="v-align-middle">{data.array ? 'true' : 'false'}</td>
          <td class="v-align-middle">
            {validationRules.map((rule, i) => {
              return <span key={i}>{rule}</span>;
            })}
          </td>
        </tr>
      );
    });

    return (
      <Table striped condensed responsive class="">
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Array</th>
            <th>Validation</th>
          </tr>
        </thead>
        <tbody>{fields}</tbody>
      </Table>
    );
  };

  showInfo = (e, entityMetaIdentifier) => {
    e.preventDefault();
    this.setState({ entityMetaIdentifier });
  };

  render() {
    const { show, close } = this.props;
    const { entityMetaIdentifier } = this.state;

    return (
      <Modal show={show} onHide={close} bsSize="lg">
        <Modal.Header closeButton>
          <Modal.Title>{entityMetaIdentifier}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {entityMetaIdentifier ? (
            <EntityMeta entityMetaIdentifier={entityMetaIdentifier} metaData={this.props.meta[entityMetaIdentifier]} />
          ) : null}

          {this.state.metaInformation ? <div>{this.entityMetaInfoTable()}</div> : <Loader />}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    meta: state.entityMeta,
  };
};

export default connect(mapStateToProps)(DocumentationEntityInfo);
