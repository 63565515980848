import React from 'react';
import TwentyTwenty from 'react-twentytwenty';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

export default class CmsElementImageCompare extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  render() {
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const imageUrl = this.getParamValue('imageUrl', '');
    const compareImageFile = this.getParamValue('compareImageUrl', '');

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-image-compare relative ' + shownClass + this.getCssClasses()}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <TwentyTwenty>
          <img src={imageUrl} class="ms-img img-responsive" alt="" />
          <img src={compareImageFile} class="ms-img img-responsive" alt="" />
          <div class="ms-image-compare-slider" />
        </TwentyTwenty>

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementImageCompare.defaultProps = {
  index: '0',
};
