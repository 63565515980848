import React from 'react';
import { SingleDatePicker } from 'react-dates';
import SingleDatePickerInBody from 'components/forms/SingleDatePickerInBody';
import moment from 'moment';
import 'moment-timezone';

export default class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    moment.locale(globalLocale);

    this.state = {
      value: moment.tz(props.value, props.returnTz).toISOString(),
      focused: false,
    };
  }

  checkFocused = () => {
    return this.state.focused ? 'focused ' : '';
  };

  checkRequired = () => {
    return this.props.meta.validate.rules.indexOf('required') !== -1 ? 'required ' : '';
  };

  checkError = () => {
    return this.props.validation.valid ? '' : 'has-error ';
  };

  // check if user may read this field
  checkAccess = () => {
    return this.props.meta.acl.read;
  };

  // check if user may write to this field
  checkReadOnly = () => {
    return !this.props.meta.acl.write;
  };

  handleDateSelect = date => {
    const { name, value, locale, showClearDate, displayTz, returnTz, onChange } = this.props;

    if (date) {
      const oldDate = moment.tz(value, returnTz);
      const newDate = moment.tz(date, displayTz).tz(returnTz);
      newDate.hours(oldDate.hours());
      newDate.minutes(oldDate.minutes());
      onChange(name, newDate);

      date.locale(locale);
      this.setState({
        value: moment
          .tz(date, displayTz)
          .tz(returnTz)
          .toISOString(),
      });
    } else if (showClearDate) {
      onChange(name, null);
      this.setState({
        value: null,
      });
    }
  };

  checkDisabled = () => {
    const { disabled, meta } = this.props;
    if (disabled) {
      return true;
    }
    if (!meta.acl.write) {
      return true;
    }
    return false;
  };

  isOutsideRange = date => {
    if (this.props.minDate) {
      let minDate = moment.tz(this.props.minDate, 'UTC');
      if (date.isBefore(minDate)) {
        return true;
      }
    }
    if (this.props.maxDate) {
      let maxDate = moment.tz(this.props.maxDate, 'UTC');
      if (date.isAfter(maxDate)) {
        return true;
      }
    }

    return false;
  };

  renderSingleDatePicker = () => {
    const { label, name, isDayBlocked, returnTz, displayTz, value } = this.props;
    const { focused } = this.state;
    const displayTzValue = moment.tz(value, returnTz).tz(displayTz);

    return (
      <SingleDatePicker
        date={value && displayTzValue}
        onDateChange={this.handleDateSelect}
        focused={focused}
        onFocusChange={({ focused }) => this.setState({ focused })}
        id={label + name}
        numberOfMonths={1}
        noBorder
        block
        small
        hideKeyboardShortcutsPanel
        verticalSpacing={0}
        isOutsideRange={this.isOutsideRange}
        isDayBlocked={isDayBlocked}
      />
    );
  };

  render() {
    const {
      label,
      name,
      className,
      style,
      isDayBlocked,
      appendToBody,
      value,
      showClearDate,
      placeholder,
      returnTz,
      displayTz,
    } = this.props;
    const { focused } = this.state;
    const displayTzValue = moment.tz(value, returnTz).tz(displayTz);

    return (
      <div
        class={
          'cursor form-group form-group-default ' +
          this.checkFocused() +
          this.checkRequired() +
          this.checkError() +
          (this.checkDisabled() ? 'disabled ' : '') +
          ' ' +
          className
        }
        style={{ overflow: 'visible', paddingBottom: appendToBody ? '4px' : '3px', ...style }}
      >
        <label>{label}</label>
        <div class="lh-25">
          {appendToBody ? (
            <SingleDatePickerInBody
              date={value && displayTzValue}
              onDateChange={this.handleDateSelect}
              focused={focused}
              onFocusChange={({ focused }) => this.setState({ focused })}
              id={label + name}
              numberOfMonths={1}
              noBorder
              block
              small
              hideKeyboardShortcutsPanel
              verticalSpacing={0}
              isOutsideRange={this.isOutsideRange}
              isDayBlocked={isDayBlocked}
              showClearDate={showClearDate}
              placeholder={placeholder}
            />
          ) : (
            <SingleDatePicker
              date={value && displayTzValue}
              onDateChange={this.handleDateSelect}
              focused={focused}
              onFocusChange={({ focused }) => this.setState({ focused })}
              id={label + name}
              numberOfMonths={1}
              noBorder
              block
              small
              hideKeyboardShortcutsPanel
              verticalSpacing={0}
              isOutsideRange={this.isOutsideRange}
              isDayBlocked={isDayBlocked}
              showClearDate={showClearDate}
              placeholder={placeholder}
            />
          )}
        </div>
      </div>
    );
  }
}
DatePicker.defaultProps = {
  label: '',
  name: '',
  meta: { validate: { rules: '' }, acl: { read: true, write: true } },
  validation: { valid: true },
  value: null,
  onChange: () => {},
  disabled: false,
  displayTz: 'UTC',
  returnTz: 'UTC',
  className: '',
  style: {},
  locale: 'en',
  showClearDate: false,
};
