import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Grid, Row } from 'react-bootstrap';

import StepBCarCheckoutInfo from 'components/layout/checkout/infoArea/StepBCarCheckoutInfo';
import StepBCarRentCheckoutInfo from 'components/layout/checkout/infoArea/StepBCarRentCheckoutInfo';
import StepBItemCheckoutInfo from 'components/layout/checkout/infoArea/StepBItemCheckoutInfo';
import CheckoutItemList from 'components/layout/checkout/CheckoutItemList';
import CheckoutTotal from 'components/layout/checkout/CheckoutTotal';

// just the item list for the user
class StepB extends React.Component {
  mayProceed = () => {
    const { checkoutInfos } = this.props;

    return !checkoutInfos.pending;
  };

  clickNext = () => {
    if (this.mayProceed()) {
      const { appIntl, history } = this.props;
      history.push(appIntl.urlPrefix + 'checkout/personal');
    }
  };

  clickPrevious = () => {
    const { appIntl, history } = this.props;
    history.push(appIntl.urlPrefix + 'checkout');
  };

  componentDidUpdate = prevProps => {
    const { appIntl, history, checkoutInfos } = this.props;

    if (prevProps.checkoutInfos.pending && !checkoutInfos.pending) {
      if (!checkoutInfos.data.usable_plugins || !checkoutInfos.data.usable_plugins.length) {
        history.replace(appIntl.urlPrefix + 'checkout/error?checkout_error=no_payment_module_found');
      }
    }
  };

  render() {
    const {
      intl: { messages },
      checkout,
      projectConfig,
      user,
      checkoutInfos,
    } = this.props;

    return (
      <Grid fluid>
        {!checkoutInfos.pending && (
          <React.Fragment>
            <div class="panel panel-transparent">
              <Row class="row-same-height">
                <div class="col-md-5 b-r b-dashed b-grey sm-b-b">
                  <div class="padding-30 sm-padding-5 sm-m-t-15 m-t-50">
                    {checkout.isItemCheckout && <StepBItemCheckoutInfo checkout={checkout} />}
                    {checkout.isCarCheckout && (
                      <StepBCarCheckoutInfo checkout={checkout} projectConfig={projectConfig} />
                    )}
                    {checkout.isCarRentCheckout && <StepBCarRentCheckoutInfo checkout={checkout} />}
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="padding-30 sm-padding-5">
                    <CheckoutItemList {...this.props} />

                    <br />

                    <CheckoutTotal isCarCheckout={checkout.isCarCheckout} checkout={checkout} />
                  </div>
                </div>
              </Row>
            </div>

            <div class="padding-20 sm-padding-5 sm-m-b-20 sm-m-t-20 bg-white clearfix">
              <ul class="pager wizard no-style">
                <li class={'next ' + (this.mayProceed() ? '' : 'disabled ')} style={{ display: 'list-item' }}>
                  <button
                    class="btn btn-primary btn-cons pull-right btn-animated from-left fa fa-user"
                    type="button"
                    onClick={this.clickNext}
                  >
                    <span>{messages.checkout_next}</span>
                  </button>
                </li>

                {!user.logged ? (
                  <li class="previous">
                    <button class="btn btn-default btn-cons pull-right" type="button" onClick={this.clickPrevious}>
                      <span>{messages.checkout_previous}</span>
                    </button>
                  </li>
                ) : (
                  <li class="previous disabled">
                    <button class="btn btn-default btn-cons pull-right" type="button">
                      <span>{messages.checkout_previous}</span>
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </React.Fragment>
        )}
      </Grid>
    );
  }
}
StepB.defaultProps = {};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    projectConfig: state.data.projectConfig,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(StepB)));
