import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col } from 'react-bootstrap';

import { getLogger, intlModule, communicationVideoModule as videoComms } from '@chedri/base';

import Icon from 'components/Icon';
import IconButton from 'components/IconButton';

const logger = getLogger('communication');

function CallController() {
  const dispatch = useDispatch();

  const messages = useSelector(intlModule.selectors.getMessages);
  const localCall = useSelector(videoComms.selectors.getLocalCall);
  const remoteCall = useSelector(videoComms.selectors.getFirstRemoteCall);
  const status = useSelector(videoComms.selectors.getStatus);
  const isMicMuted = useSelector(videoComms.selectors.isMicMuted);

  const incomingAudioRef = useRef();
  const [isActive, setIsActive] = useState(false);
  const [uuid, setUuid] = useState();

  const closeCall = useCallback(() => {
    dispatch(videoComms.actions.endCall(uuid));
  }, [uuid]);

  const toggleMute = useCallback(() => {
    dispatch(videoComms.actions.toggleMic(isMicMuted));
  }, [isMicMuted]);

  useEffect(() => {
    if (
      incomingAudioRef.current &&
      remoteCall &&
      incomingAudioRef.current.srcObject !== remoteCall.stream &&
      isActive
    ) {
      logger.debug('Establishing remote audio connection', incomingAudioRef, remoteCall);
      incomingAudioRef.current.setAttribute('playsinline', true);
      incomingAudioRef.current.srcObject = remoteCall.stream;
      logger.debug('Remote audio connection established', incomingAudioRef, remoteCall.stream);
    }
  }, [isActive, incomingAudioRef, remoteCall]);

  useEffect(() => {
    setIsActive(
      localCall &&
        localCall.stream &&
        (localCall.isAccepted || (remoteCall && remoteCall.isAccepted)) &&
        !localCall.isEnded &&
        !localCall.isRejected
    );
    if (remoteCall && uuid !== remoteCall.uuid) {
      // We are presenter, we have no UUID, so use the one from remoteCall.
      setUuid(remoteCall.uuid);
    }
  }, [localCall, remoteCall, uuid, setUuid]);

  return (
    <div>
      <Col xs={6} className="overflow-ellipsis lh-23 no-padding">
        <div className="small">
          {remoteCall && remoteCall.isMicrophoneMuted ? (
            <span>{messages.muted}</span>
          ) : status.info === 'calling' ? (
            <span>{status.source === 'presenter' ? 'Anrufen' : 'Anruf'} von </span>
          ) : status.info === 'checking' ? (
            <span>{messages.checking_connection} </span>
          ) : status.info === 'connected' ? (
            <span>{messages.connected_with} </span>
          ) : status.info !== 'connected' ? (
            <span>{messages.connecting_with} </span>
          ) : (
            ''
          )}
        </div>
        <div>{remoteCall && remoteCall.user.name}</div>
      </Col>
      <Col xs={6} className="lh-47 p-r-0 text-right controls">
        {isActive && (
          <IconButton
            name="fa-microphone"
            duotone
            iconClassName="fa-fw"
            buttonClassName="btn btn-sm btn-rounded"
            layers={isMicMuted ? [<Icon key="slash" name="fa-slash" iconStyles={{ marginLeft: '-16px' }} />] : null}
            onClick={toggleMute}
            tooltip={isMicMuted ? messages.unmute_microphone : messages.mute_microphone}
            tooltipPlacement="bottom"
          />
        )}
        <IconButton
          name="fa-phone-slash"
          buttonClassName="btn btn-sm btn-rounded btn-danger"
          onClick={closeCall}
          tooltip={messages.end_call}
          tooltipPlacement="bottom"
        />
      </Col>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={incomingAudioRef} autoPlay />
    </div>
  );
}
CallController.defaultProps = {};
CallController.propTypes = {};

export default CallController;
