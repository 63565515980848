import passwordRepeat from './passwordRepeat';
import emailPattern from './emailPattern';
import entityPasswordRepeat from './entityPasswordRepeat';
import itemAttributes from './itemAttributes';
import docRequiredNumber from './docRequiredNumber';
import docRequiredIssuingCity from './docRequiredIssuingCity';
import docRequiredIssuingAuthority from './docRequiredIssuingAuthority';
import docRequiredIssueDate from './docRequiredIssueDate';
import docRequiredExpirationDate from './docRequiredExpirationDate';

const injectValidator = (validatorFunction, intl) => {
  return entity => {
    const validationResult = validatorFunction(entity);
    return {
      valid: validationResult.valid,
      rule: validationResult.rule,
      helpText: !validationResult.valid ? intl.messages[validationResult.helpTextTranslationIndex] : '',
      fields: validationResult.fields,
    };
  };
};

export {
  passwordRepeat,
  emailPattern,
  entityPasswordRepeat,
  itemAttributes,
  docRequiredNumber,
  docRequiredIssuingCity,
  docRequiredIssuingAuthority,
  docRequiredIssueDate,
  docRequiredExpirationDate,
  injectValidator,
};
