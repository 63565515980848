import React from 'react';
import { Row, Col } from 'react-bootstrap';

export default class SummaryItemGridView extends React.Component {
  render() {
    const { hideBrand, hidePerks, hideEquipment, hideFooter } = this.props;

    return (
      <div class="summary-item summary-item-grid">
        <div class="summary-item-grid-header bg-master-light">
          <div class="relative bg-master-light" style={{ paddingTop: '70%' }} />
        </div>

        <div class="summary-item-grid-body">
          {/* title */}
          <h3 class="m-b-5">
            <span class="ms-wireframe" style={{ width: '100%' }}>
              <span class="block bg-master-light" style={{ width: '60%' }}>
                {'\u00A0'}
              </span>
            </span>
          </h3>
          {!hideBrand && (
            <h6 class="m-b-10">
              <span class="ms-wireframe" style={{ width: '100%' }}>
                <span class="block bg-master-light" style={{ width: '25%' }}>
                  {'\u00A0'}
                </span>
              </span>
            </h6>
          )}

          {/* details */}
          {!hideEquipment && (
            <Row class="no-margin">
              <Col xs={6} class="no-padding">
                <i class="fas fa-circle text-master-lighter m-r-5" />
                <span class="ms-wireframe" style={{ width: '50%' }}>
                  <span class="block bg-master-light lh-12" style={{ width: '100%' }}>
                    {'\u00A0'}
                  </span>
                </span>
              </Col>
              <Col xs={6} class="no-padding">
                <i class="fas fa-circle text-master-lighter m-r-5" />
                <span class="ms-wireframe" style={{ width: '50%' }}>
                  <span class="block bg-master-light lh-12" style={{ width: '100%' }}>
                    {'\u00A0'}
                  </span>
                </span>
              </Col>
              <Col xs={6} class="no-padding">
                <i class="fas fa-circle text-master-lighter m-r-5" />
                <span class="ms-wireframe" style={{ width: '50%' }}>
                  <span class="block bg-master-light lh-12" style={{ width: '100%' }}>
                    {'\u00A0'}
                  </span>
                </span>
              </Col>
              <Col xs={6} class="no-padding">
                <i class="fas fa-circle text-master-lighter m-r-5" />
                <span class="ms-wireframe" style={{ width: '50%' }}>
                  <span class="block bg-master-light lh-12" style={{ width: '100%' }}>
                    {'\u00A0'}
                  </span>
                </span>
              </Col>
              <Col xs={6} class="no-padding">
                <i class="fas fa-circle text-master-lighter m-r-5" />
                <span class="ms-wireframe" style={{ width: '50%' }}>
                  <span class="block bg-master-light lh-12" style={{ width: '100%' }}>
                    {'\u00A0'}
                  </span>
                </span>
              </Col>
              <Col xs={6} class="no-padding">
                <i class="fas fa-circle text-master-lighter m-r-5" />
                <span class="ms-wireframe" style={{ width: '50%' }}>
                  <span class="block bg-master-light lh-12" style={{ width: '100%' }}>
                    {'\u00A0'}
                  </span>
                </span>
              </Col>
            </Row>
          )}

          <div class="m-t-15">
            <h3 class="no-margin text-center">
              {/* price */}
              <span class="ms-wireframe" style={{ width: '50%' }}>
                <span class="block bg-master-light" style={{ width: '100%' }}>
                  {'\u00A0'}
                </span>
              </span>
            </h3>
          </div>
        </div>
      </div>
    );
  }
}
SummaryItemGridView.defaultProps = {
  hideBrand: false,
  hidePerks: false,
  hideEquipment: false,
  hideFooter: true,
};
