import React from 'react';
import { components } from 'react-select';

class MainMenuVersionOption extends React.Component {
  render() {
    const { data } = this.props;
    return <components.Option {...this.props}>{data.label}</components.Option>;
  }
}

class MainMenuVersionValue extends React.Component {
  render() {
    const { data } = this.props;

    return <div className="Select-value">{data.label}</div>;
  }
}

const mainMenuVersionOptionFormatter = options => {
  return options.list.map(option => {
    return mainMenuVersionSingleOptionFormatter(option);
  });
};

const mainMenuVersionSingleOptionFormatter = (option = null) => {
  if (option && option.id) {
    return { ...option, value: option.id, label: option.name };
  }

  return null;
};

const mainMenuVersionOptionFromEntity = team => {
  return !team
    ? null
    : mainMenuVersionSingleOptionFormatter({
        id: team.getId(),
        name: team.getName(),
      });
};

export {
  MainMenuVersionValue,
  MainMenuVersionOption,
  mainMenuVersionOptionFormatter,
  mainMenuVersionSingleOptionFormatter,
  mainMenuVersionOptionFromEntity,
};
