import React from 'react';
import { withRouter } from 'react-router-dom';

import { parseQs, stringifyQs } from 'helpers/http';

class SummaryTag extends React.Component {
  // remove a value from array
  removeArrayFilter = (name, value) => {
    let query = parseQs(this.props.location.search);
    let values = query[name].slice();
    values.splice(values.indexOf(value), 1);

    if (!values.length) {
      delete query[name];
    } else {
      query[name] = values;
    }

    this.props.history.replace({ pathname: this.props.location.pathname, search: stringifyQs(query) });
  };

  onClick = () => {
    const { name, value } = this.props;

    if (this.props.onClick) {
      this.props.onClick(name, value);
    } else {
      this.removeArrayFilter(name, value);
    }
  };

  render() {
    const { title, value, className } = this.props;

    return (
      <li class={className}>
        <a href="javascript:;" onClick={this.onClick}>
          {title ? title : value}{' '}
          <span class="m-l-10">
            <i class="fal fa-times" />
          </span>
        </a>
      </li>
    );
  }
}
SummaryTag.defaultProps = {
  className: '',
};

export default withRouter(SummaryTag);
