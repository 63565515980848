import React from 'react';
import { Clearfix } from 'react-bootstrap';
import { Range } from 'rc-slider';

class SliderFilter extends React.Component {
  render() {
    return (
      <div>
        <div class="p-l-15 p-r-15 p-b-5">
          <Range disabled />
        </div>

        <Clearfix class="p-l-15 p-r-15 p-t-5">
          <div class="pull-left" style={{ width: '8%' }}>
            <span class="ms-wireframe fs-10" style={{ width: '100%' }}>
              <span class="block animated-background" style={{ width: '100%' }}>
                {'\u00A0'}
              </span>
            </span>
          </div>
          <div class="pull-right" style={{ width: '14%' }}>
            <span class="ms-wireframe fs-10" style={{ width: '100%' }}>
              <span class="block animated-background" style={{ width: '100%' }}>
                {'\u00A0'}
              </span>
            </span>
          </div>
        </Clearfix>
      </div>
    );
  }
}

export default SliderFilter;
