import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export const gMapDdistance = (itemId, location, finishedCallback, errorCallback) => {
  const fullUrl = '/api/items/' + itemId + '/distance.json?location=' + location;

  axios({
    method: 'get',
    url: fullUrl,
  })
    .then(response => {
      const distance = response.data || 0;
      const status = distance > 0 ? 'OK' : 'NOT_FOUND';

      finishedCallback(status, distance);
    })
    .catch(errorCallback);
};
