/* eslint-disable import/prefer-default-export */
import { forEach } from 'lodash';

export function checkEditionErrors(edition, messages) {
  const errors = [];
  let countEmptyPages = 0;
  forEach(edition.pages, page => {
    if (page.blocks.length === 0) {
      countEmptyPages++;
    }
  });
  if (countEmptyPages > 0) {
    errors.push({
      info: messages.campaign.edition_error_empty.replace('%s', countEmptyPages),
      type: 'emptyPages',
    });
  }
  return errors;
}
