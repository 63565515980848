import React from 'react';
import { connect } from 'react-redux';
import { find as _find, forEach as _forEach, cloneDeep as _cloneDeep } from 'lodash';
import ReactDOM from 'react-dom';
import { Button } from 'react-bootstrap';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Axios from 'axios';
import HTML from 'html-parse-stringify';
import FormAutocomplete from '../../../components/forms/FormAutocomplete';
import FormInput from '../../../components/forms/FormInput';
import {getTimezoneFromState} from "helpers/intl";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";

const ContextModal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('context-modal'));
};

class RSSFeedsElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRSSOptions: false,
      RSSContent: [],
      RSSTitleContent: [],
      RSSLink: '',
      titleIt: '',
      linkIt: '',
      descriptionIt: '',
      pubDateIt: '',
      categoryIt: '',
      imgUrlIt: '',
      RSSSelection: '',
      RSSInfo: 'what',
    };
  }

  /*
   RSSLink: "https://www.spiegel.de/schlagzeilen/index.rss",
      titleIt: "title",
      linkIt: "link",
      descriptionIt: "description",
      pubDateIt: "pubDate",
      categoryIt: "category",
      imgUrlIt: '<enclosure type="image/jpeg" url="',
   */

  componentDidUpdate = prevState => {
    // if (prevState.RSSContent !== this.state.RSSContent) { this.setRSSContent()}
  };

  componentDidMount = () => {
    this.getRSSData(true);
    const { id, attributes, updateParam } = this.props;
  };

  RSSOptions = () => {
    this.setState({ showRSSOptions: true });
    this.getRSSData(false);
  };

  handleIEclose = () => {
    this.setState({ showRSSOptions: false });
  };

  addData = () => {
    const { RSSLink, titleIt, linkIt, descriptionIt, pubDateIt, categoryIt, imgUrlIt, RSSInfo } = this.state;
    const { id, attributes, updateParam } = this.props;
    let param = '';
    const RSSData = [];
    RSSData.push(RSSLink, titleIt, linkIt, descriptionIt, pubDateIt, categoryIt, imgUrlIt, RSSInfo);

    _forEach(RSSData, (link, i) => {
      param += link;
      if (i < RSSData.length - 1) {
        param += '#cc#';
      }
    });

    const overflow = false;
    const default_image = false;
    const errors = [{ overflow, default_image }];

    updateParam(id, attributes['data-name'], param, errors);
    this.setState({ showRSSOptions: false }, () => {
      this.getRSSData(false);
    });
  };

  /*
  editRSSLink = (name, value) => {
      this.setState({ RSSLink: value });
  }
*/
  getRSSData = setting => {
    const { name: Tag, attributes, params } = this.props;
    const param = _find(params, ['name', attributes['data-name']]);
    const data = [];

    if (param) {
      const output = param.value.split('#cc#');
      _forEach(output, (entry, i) => {
        const RSSData = entry.split('#aaa#');
        data.push(RSSData);
      });
    }

    if (data !== undefined && data.length > 0) {
      this.setState(
        {
          RSSLink: data[0][0],
          titleIt: data[1][0],
          linkIt: data[2][0],
          descriptionIt: data[3][0],
          pubDateIt: data[4][0],
          categoryIt: data[5][0],
          imgUrlIt: data[6][0],
        },
        () => {
          if (setting) {
            this.getRSS();
          }
        }
      );
    }
  };

  setRSSDefault = provider => {
    if (provider === 'spiegel.de') {
      this.setState(
        {
          RSSInfo: '',
          RSSSelection: provider,
          RSSLink: 'https://www.spiegel.de/schlagzeilen/index.rss',
          titleIt: 'title',
          linkIt: 'link',
          descriptionIt: 'description',
          pubDateIt: 'pubDate',
          categoryIt: 'category',
          imgUrlIt: 'enclosure',
        },
        () => {}
      );
    }
    if (provider === 'faz.net') {
      this.setState(
        {
          RSSInfo: 'faz',
          RSSSelection: provider,
          RSSLink: 'https://www.faz.net/rss/aktuell/',
          titleIt: 'title',
          linkIt: 'link',
          descriptionIt: 'description',
          pubDateIt: 'pubDate',
          categoryIt: '',
          imgUrlIt: 'img',
        },
        () => {}
      );
    }
    if (provider === 'cdu') {
      this.setState(
        {
          RSSInfo: 'cdu',
          RSSSelection: provider,
          RSSLink: 'https://www.cducsu.de/rss_cdu_artikel.xml',
          titleIt: 'title',
          linkIt: 'link',
          descriptionIt: 'description',
          pubDateIt: 'pubDate',
          categoryIt: '',
          imgUrlIt: 'img',
        },
        () => {}
      );
    }
  };

  getRSS = () => {
    Axios.get(this.state.RSSLink, {}).then(response => {
      const RSSTitleContentDum = [];
      const { RSSContent, RSSInfo, titleIt, linkIt, descriptionIt, pubDateIt, categoryIt, imgUrlIt } = this.state;
      const parser = new DOMParser();
      //const wrapped = `<rss xmlns:content="http://purl.org/rss/1.0/modules/content/">${response.data}</rss>`;
      const doc = parser.parseFromString(response.data, 'application/xml');
      let RSSTitle = [doc.querySelector("title")?.textContent];
      let RSSLink =  [doc.querySelector("link")?.textContent];
      let RSSDescription =  [doc.querySelector("description")?.textContent];
      let RSSLastBuildDate =  [doc.querySelector("lastBuildDate")?.textContent];

      if (RSSTitle) {
        RSSTitle = RSSTitle[0];
      }
      if (RSSLink) {
        RSSLink = RSSLink[0];
      }
      if (RSSDescription && this.state.RSSInfo !== 'cdu' && this.state.RSSInfo !== 'cdu_html') {
        RSSDescription = RSSDescription[0];
      } else {
        RSSDescription = '';
      }
      if (RSSLastBuildDate) {
        RSSLastBuildDate = RSSLastBuildDate[0];
      }

      RSSTitleContentDum.push(RSSTitle, RSSLink, RSSDescription, RSSLastBuildDate);

      this.setState({ RSSTitleContent: RSSTitleContentDum });

      const regexp = /<item>.*?<\/item>[^<>]+/gs;
      const RSSItems = [...response.data.matchAll(regexp)];

      this.setState({ RSSContent: RSSItems }, () => {
        this.setRSSContent();
      });
    });
  };

  /**
   * Parses an array of XML strings
   * from inside each of the <item> elements of RSS feed
   * to an array of text elements:
   * [title, link, description, pubDate, category, imgUrl]
   */
  setRSSContent = () => {
    const { RSSContent, RSSInfo, titleIt, linkIt, descriptionIt, pubDateIt, categoryIt, imgUrlIt } = this.state;
    const renderOutput = [];
    const parser = new DOMParser();

    _forEach(RSSContent, itemContent => {
      let wrappedOriginal = `<rss xmlns:content="http://purl.org/rss/1.0/modules/content/">${itemContent}</rss>`;
      let wrapped = wrappedOriginal.replace("media:thumbnail", "img");
      let doc = parser.parseFromString(wrapped, 'application/xml');
      const title = doc.querySelector(titleIt)?.textContent;
      const link = doc.querySelector(linkIt)?.textContent;
      const description = descriptionIt !== "" ? doc.querySelector(descriptionIt)?.textContent : "";
      const pubDate = doc.querySelector(pubDateIt)?.textContent;
      const category = categoryIt !== "" ? doc.querySelector(categoryIt)?.textContent : "";
      let imgUrl = "";
        imgUrl = doc.querySelector(imgUrlIt)?.getAttribute(RSSInfo === 'cdu' ? 'src' : 'url');

      renderOutput.push([title, link, description, pubDate, category, imgUrl]);
    });
    this.setState({ RSSContent: renderOutput });
  };

  htmlDecode(input) {
    const e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  }

  renderSlides() {
    const { IMAGE_URL, textNumber } = this.state;
    const { filesUploading } = this.props;
    const renderRSSSlidesOutput = [];
    const exampleImage = 'https://cdn.pixabay.com/photo/2021/02/07/09/11/sunset-5990540_960_720.jpg';
    const exampleArray = [
      exampleImage,
      'Sonnenuntergänge',
      'Link',
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
      '23.02.2021 16:48',
    ];
    const content = [exampleArray, exampleArray, exampleArray, exampleArray];
    const IMGHeight = '100%';
    const IMGWidth = '100%';
    {
      this.state.RSSContent ? (
        renderRSSSlidesOutput.push(
          this.state.RSSContent.map((slide, index) => (
            <SplideSlide key={index}>
              <div style={{ width: '100%', height: 'maxContent' }}>
                <div class="RSSTitle">
                  <h3 class="rssTitleAndUrl">
                    <a target="_blank" href={slide[1]}>
                      {slide[0]}
                    </a>
                  </h3>
                </div>
                {slide[5] && this.state.RSSInfo !== 'cdu' ? (
                  <div className="imgDiv">
                    <img
                      style={{
                        border: '1px solid #FFFFFF',
                        height: IMGHeight,
                        width: IMGWidth,
                        borderRadius: '10px',
                        objectFit: 'cover',
                        position: 'absolute',
                      }}
                      src={slide[5]}
                      alt={slide[5]}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {this.state.RSSInfo === 'cdu' ? (
                  <>
                    <div dangerouslySetInnerHTML={{ __html: this.htmlDecode(slide[2]) }} />
                  </>
                ) : (
                  <p className="rssDescription">{slide[2]}</p>
                )}
                <p className="rssPubDate">{slide[3]}</p>
                <h5 className="category">{slide[4]}</h5>
              </div>
              {/*
            <img src={slide[0]} style={{objectFit:"cover", width:"100%", height:"100%"}}/>
            <h1>{slide[1]}</h1>
            <p>{slide[3]}</p>
            <p style={{textAlign:"right"}}>{slide[4]}</p>
            <p>{slide[5]}</p>
          */}
            </SplideSlide>
          ))
        )
      ) : (
        <></>
      );
    }
    return renderRSSSlidesOutput;
  }

  setReferences = () => {
    this.primaryRef.current.sync(this.secondaryRef.current.splide);
  };

  render() {
    const { name: Tag, attributes, disabled } = this.props;
    const { showRSSOptions, RSSLink, RSSSelection, RSSInfo } = this.state;
    const {intl: { messages },} = this.props;

    let show_spiegel = false;
    let show_faz = false;
    let show_cdu = false;

    if (attributes['data-name'].includes('spiegel')) {
      show_spiegel = true;
    }
    if (attributes['data-name'].includes('faz')) {
      show_faz = true;
    }
    if (attributes['data-name'].includes('cdu')) {
      show_cdu = true;
    }

    const option = [];
    option[0] = new Array('CDU RSS Feed - (Im Editor keine Vorschau verfügbar)', 'https://www.cdu.de/rss.xml');
    option[1] = new Array('Alle Artikel der CDU/CSU-Fraktion', 'https://www.cducsu.de/rss_cdu_artikel.xml');
    option[2] = new Array(
      'Alle Artikel des Themas Außen, Europa und Verteidigung',
      'https://www.cducsu.de/hptcdu-aussen-europa-und-verteidigung.xml'
    );
    option[3] = new Array(
      'Alle Pressestatements der CDU/CSU-Fraktion',
      'https://www.cducsu.de/pressestatements-rss-feed.xml'
    );

    return (
      <>
        <Tag {...attributes} onClick={this.RSSOptions} data-tip="" data-for="top">
          <div className="RSSCSS">
            <div className="RSSTitleElement">
              <h1>RSS Feeds</h1>

              <h1>
                {' '}
                <a target="_blank" href={this.state.RSSTitleContent[1]}>
                  {this.state.RSSTitleContent[0]}
                </a>
              </h1>
              <p> {this.state.RSSTitleContent[2]}</p>
              <p> {this.state.RSSTitleContent[3]}</p>
            </div>

            {RSSLink ? (
              <div className="RSSCSS2">
                <div className="RSSContentCSS">
                  <div className="formElementsCSS">
                    <div className="sliderRSSCSS">
                      <Splide
                        options={{
                          type: 'loop',
                          fixedWidth: 570,
                          rewind: true,
                          perPage: 1,
                          gap: 0,
                          pagination: false,
                          breakpoints: {
                            '1160': {
                              fixedWidth: 570,
                              height: 600,
                            },
                          },
                        }}
                      >
                        {this.renderSlides()}
                      </Splide>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="wrap">
                <p>{messages.editor.edit_rss_elements}</p>
              </div>
            )}
          </div>
        </Tag>

        {showRSSOptions && !disabled && (
          <ContextModal>
            <div id="contextModalWrap">
              <div id="contextModalStage" className="row">
                <h3>{messages.editor.rss_settings}</h3>
                {/* <p><i className="far fa-info-circle"></i> Je nach RSS Anbieter müssen die "Tags" angepasst werden.</p> */}
                <p>{messages.editor.select_rss}</p>
                <div class="RSSFeedButtons">
                  <h3>{messages.editor.available_rss}</h3>
                  {show_spiegel ? (
                    <Button
                      bsStyle="success"
                      bsSize="large"
                      onClick={() => {
                        this.setRSSDefault('spiegel.de');
                      }}
                    >
                      Spiegel.de
                    </Button>
                  ) : (
                    <></>
                  )}
                  {show_faz ? (
                    <Button
                      bsStyle="success"
                      bsSize="large"
                      onClick={() => {
                        this.setRSSDefault('faz.net');
                      }}
                    >
                      faz.net
                    </Button>
                  ) : (
                    <></>
                  )}
                  {show_cdu ? (
                    <Button
                      bsStyle="success"
                      bsSize="large"
                      onClick={() => {
                        this.setRSSDefault('cdu');
                      }}
                    >
                      CDU/CSU
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
                {this.state.RSSInfo === 'cdu' ? (
                  <FormAutocomplete
                    onChange={(name, value) => {
                      this.setState({ RSSLink: value });
                    }}
                    value="Bitte wählen Sie ein RSS Feed"
                    label="Untergeordnete RSS Auswahl CDU/CSU"
                    name="RSSCDU"
                    options={option.map(option => {
                      return { label: option[0], value: option[1] };
                    })}
                  />
                ) : (
                  <></>
                )}
                <div className="RSSCSS">
                  {RSSSelection ? (
                    <p>
                      {messages.editor.load_rss_part_1 + " "} {RSSSelection} { + " " + messages.editor.load_rss_part_2}
                    </p>
                  ) : (
                    <></>
                  )}

                  <div className="RSSCSSOptions">
                    <div className="sliderOptionsRSSCSS" />

                    <Button
                      bsStyle="danger"
                      bsSize="large"
                      onClick={() => {
                        this.getRSS();
                      }}
                    >
                      {messages.editor.load_rss_feeds}
                    </Button>
                    <div className="sliderOptionsRSSCSS">
                      <h3>RSS Feeds</h3>
                      <Splide
                        options={{
                          type: 'loop',
                          fixedWidth: 490,
                          rewind: true,
                          perPage: 1,
                          gap: 0,
                          pagination: false,
                          breakpoints: {
                            '1000': {
                              fixedWidth: 490,
                              height: 500,
                            },
                          },
                        }}
                      >
                        {this.renderSlides()}
                      </Splide>
                    </div>
                  </div>
                </div>
                {/* <div contentEditable='true' dangerouslySetInnerHTML={{__html: RSSContent[0]}}></div> */}
                <div className="cancelIE">
                  <Button
                    bsStyle="success"
                    bsSize="large"
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                      this.addData();
                      this.handleIEclose;
                    }}
                  >
                    {messages.editor.take_on}
                  </Button>
                  <Button bsStyle="danger" bsSize="large" onClick={this.handleIEclose}>
                    {messages.editor.abort}
                  </Button>
                </div>
              </div>
            </div>
          </ContextModal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(RSSFeedsElement)));
