import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withWizard } from 'react-albus';
import _findIndex from 'lodash/findIndex';

import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import Loader from 'components/layout/Loader';
import Slider from 'react-slick';

import { listDispatchHelper, changeListSort, changeActivePage } from 'actions/ListActions';
import { getWebFile } from 'helpers/items';
import { getDescriptionInCurrentLanguage } from 'helpers/intl';

class CompanySignupWizardSubscriptionPlan extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // clearSearchVisible: !!props.list.searchTerm,
      searchType: localStorage.getItem(props.listName + 'ListSearchType') || 'basic',
      conditions: null,
    };

    this.currentRequestSource = null;
  }

  componentDidMount() {
    // fetch initial list
    const { listName, url, list, resPerPage, fetch, urlData } = this.props;

    if (!list || !list.complete || !list.data.list.length) {
      fetch(
        this.currentRequestSource,
        this.setCurrentRequestSource,
        listName,
        url,
        urlData,
        list.activePage,
        resPerPage || list.resPerPage
      );
    }
  }

  setCurrentRequestSource = currentRequestSource => {
    this.currentRequestSource = currentRequestSource;
  };

  handleSelectionClick = id => {
    const { onChange, wizard, changeStep } = this.props;
    const { steps, step } = wizard;
    const currentStepIdx = _findIndex(steps, item => item.id === step.id);

    let pluginIds = [];
    pluginIds.push(id);

    this.setState({ pluginIds });
    onChange(this.props.name, pluginIds);

    changeStep(wizard, steps[currentStepIdx + 1]);
  };

  render() {
    const {
      intl: { messages },
      appIntl,
      list,
      selection,
    } = this.props;

    const data = [];
    let subscriptionPlanCount = 0;
    let focusItem = 0;

    if (!list.pending) {
      subscriptionPlanCount = list.data.list.length;

      for (let i = 0; i < subscriptionPlanCount; i++) {
        const whiteLabel = list.data.list[i];
        const { id, name, description, main_logo, price_short_info, is_featured } = whiteLabel;

        const desc = getDescriptionInCurrentLanguage(description, appIntl);
        let shortDescription = desc;

        if (shortDescription.length > 100) {
          shortDescription = shortDescription.substring(0, 200) + ' ...';
        }

        const priceInfo = (
          <div>
            {messages.company_signup_wizard_free_trial_plugin_hint}
            <br />
            {getDescriptionInCurrentLanguage(price_short_info, appIntl)}
          </div>
        );

        let mainLogo = '';
        if (main_logo !== undefined) {
          mainLogo = getWebFile(main_logo);
        }

        if (i === 1) {
          mainLogo = '';
        }

        let borderColor = 'b-grey';
        let backgroundColor = 'bg-white';
        if (selection && selection.includes(id)) {
          focusItem = i;
        }

        if (is_featured) {
          borderColor = 'b-primary';
        }

        const colors = borderColor + ' ' + backgroundColor;

        data.push(
          <div class="d-flex plugin-selector-slider-item relative">
            <div
              class={'flex-column p-r-10 p-l-10 m-r-5 b-thick b-a b-rad-lg ' + colors}
              style={{ height: '100% !important' }}
            >
              <div class="m-t-15 m-b-15 plugin-selector-slider-item-header relative">
                {is_featured && (
                  <div class="plugin-selector-slider-item-selected" style={{ textAlign: 'center' }}>
                    {messages.mostly_asked}
                  </div>
                )}
                {mainLogo ? (
                  <img class="plugin-selector-slide-item-header-image" src={mainLogo.web_link} />
                ) : (
                  <div class="plugin-selector-slide-item-header-no-image fa-stack fa-4x">
                    <i class="fas fa-camera fa-stack-1x" />
                    <i class="fas fa-ban fa-stack-2x" />
                  </div>
                )}
              </div>
              <div class="flex-shrink-1 text-center">
                <h1>{name}</h1>
              </div>
              <div class="flex-grow-1 plugin-selector-slider-item-body">
                <div dangerouslySetInnerHTML={{ __html: shortDescription }} />
                <div class="pull-right">
                  <OverlayTrigger
                    placement="top"
                    trigger="click"
                    rootClose
                    wrappingComponent={Popover}
                    overlay={
                      <Popover style={{ widht: '100%' }}>
                        <div class="plugin-selector-slider">
                          <div class="plugin-selector-slider-item">
                            <div class=" text-center">
                              <h1>{name}</h1>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: desc }} />
                            <div class="plugin-selector-slide-item-price-info">{priceInfo}</div>
                          </div>
                        </div>
                      </Popover>
                    }
                    style={{ widht: '100%' }}
                  >
                    <i className="far fa-question-circle cursor-help" />
                  </OverlayTrigger>
                </div>
                <div class="plugin-selector-slide-item-price-info">{priceInfo}</div>
              </div>
              <Button
                className="btn-rounded m-b-30 plugin-selector-slider-item-select_button"
                onClick={() => this.handleSelectionClick(id)}
              >
                {messages.test_package}
              </Button>
            </div>
          </div>
        );
      }
    }

    const settings = {
      centerMode: true,
      dots: true,
      infinite: true,
      initialSlide: focusItem,
      slidesToShow: subscriptionPlanCount > 3 ? 3 : subscriptionPlanCount,
      className: 'plugin-selector-slider',
      adaptiveHeight: true,
      focusOnSelect: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: subscriptionPlanCount > 3 ? 3 : subscriptionPlanCount,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: subscriptionPlanCount > 2 ? 2 : subscriptionPlanCount,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return list.pending ? <Loader /> : <Slider {...settings}>{data}</Slider>;
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    meta: state.entityMeta,
    user: state.login.user,
    formStatus: state.formStatus.companySignup,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetch: (
      currentRequestSource,
      setCurrentRequestSource,
      listName,
      url,
      data,
      page = 1,
      resPerPage = 20,
      search = '',
      sort = '',
      dir = ''
    ) => {
      if (currentRequestSource) {
        currentRequestSource.cancel();
      }

      setCurrentRequestSource(
        listDispatchHelper(
          listName,
          url,
          { offset: (page - 1) * resPerPage, limit: resPerPage, search, sort, dir, page, ...data },
          dispatch
        )
      );
    },
    changeSort: (listName, sort, dir) => {
      dispatch(changeListSort(listName, sort, dir));
    },
    changeActivePage: (listName, activePage, resPerPage) => {
      dispatch(changeActivePage(listName, activePage, resPerPage));
    },
  };
};

export default injectIntl(
  withWizard(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CompanySignupWizardSubscriptionPlan)
  )
);
