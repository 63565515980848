import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import { getPassengerCarSearchResultPath } from 'helpers/intl';

import CarDetailSearchForm from 'components/layout/search/CarDetailSearchForm';
import CarNarrowSearchForm from 'components/layout/search/CarNarrowSearchForm';

import AbstractCmsElement from './AbstractCmsElement';

class CmsElementCommerceCarSearch extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  onSubmit = e => {
    e.preventDefault();
    e.stopPropagation(); // if we are editing a page, we have to make sure the parent form will not be submitted

    this.props.history.push({ pathname: getPassengerCarSearchResultPath(), search: this.props.location.search });
  };

  render() {
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const textClass = 'text-' + this.getParamValue('mainColor', 'master') + ' ';
    const layoutVersion = this.getParamValue('layoutVersion', 'detailed');

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={
          'ms-ecommerce-car-search-content relative ' +
          textClass +
          shownClass +
          this.getResponsiveClasses() +
          this.getCssClasses()
        }
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <Form onSubmit={this.onSubmit}>
          {layoutVersion === 'narrow' ? <CarNarrowSearchForm /> : null}

          {layoutVersion === 'detailed' ? <CarDetailSearchForm /> : null}
        </Form>

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementCommerceCarSearch.defaultProps = {
  index: '0',
};

export default withRouter(CmsElementCommerceCarSearch);
