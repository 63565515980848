import { includes as _includes, forEach as _forEach } from 'lodash';

import {
  ROLE_SUPERADMIN,
  ROLE_ADMIN,
  ROLE_WL_ADMIN,
  ROLE_PROVISION_VALID,
  ROLE_USER,
  ROLE_EMPLOYEE,
  ROLE_ROOT_EMPLOYEE,
  ROLE_CUSTOMER,
  ROLE_COMPANY,
  ROLE_CRM_MASTER,
  ROLE_CRM_SUPPORT,
  ROLE_CRM_FINANCIAL,
  ROLE_CRM_LOCATION,
  ROLE_CRM_PROFILE,
  ROLE_CRM_SUBSCRIPTION,
  ROLE_CRM_PASSWORD,
  ROLE_CRM_ROLES,
  ROLE_CMS_PAGES,
  ROLE_ERP_MASTER,
  ROLE_ERP_ITEM,
  ROLE_AUTHORIZE,
} from 'js/constants';

const rolesHierarchy = {
  [ROLE_SUPERADMIN]: [ROLE_ADMIN, 'ROLE_ROOT_ADMIN'],
  ROLE_ROOT_ADMIN: [
    ROLE_ADMIN,
    'ROLE_ROOT_ACC_MASTER',
    'ROLE_ROOT_CMS_MASTER',
    'ROLE_ROOT_CRM_MASTER',
    'ROLE_ROOT_CRM_WHITE_LABEL',
    'ROLE_ROOT_ERP_MASTER',
    'ROLE_ROOT_HR_MASTER',
    ROLE_ROOT_EMPLOYEE,
    ROLE_AUTHORIZE,
  ],
  [ROLE_ADMIN]: [
    'ROLE_ACC_MASTER',
    'ROLE_CMS_MASTER',
    ROLE_CRM_MASTER,
    'ROLE_CRM_WHITE_LABEL',
    ROLE_ERP_MASTER,
    'ROLE_HR_MASTER',
    ROLE_EMPLOYEE,
  ],
  [ROLE_WL_ADMIN]: [ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_AUTHORIZE],
  ROLE_MMS_MASTER: [ROLE_AUTHORIZE, 'ROLE_CRM_MASTER'],
  ROLE_ROOT_CRM_MASTER: [ROLE_CRM_MASTER],
  [ROLE_CRM_MASTER]: [
    ROLE_CRM_SUPPORT,
    ROLE_CRM_FINANCIAL,
    ROLE_CRM_LOCATION,
    ROLE_CRM_PROFILE,
    ROLE_CRM_SUBSCRIPTION,
    ROLE_CRM_PASSWORD,
    ROLE_CRM_ROLES,
    'ROLE_CRM_DOCUMENTS',
    'ROLE_CRM_QUESTIONNAIRE',
  ],
  ROLE_ROOT_HR_MASTER: ['ROLE_HR_MASTER'],
  ROLE_HR_MASTER: ['ROLE_HR_FINANCIAL', 'ROLE_HR_PROFILE', 'ROLE_HR_PASSWORD', 'ROLE_HR_ROLES', 'ROLE_HR_TEAMS'],
  ROLE_ROOT_ERP_MASTER: [ROLE_ERP_MASTER],
  [ROLE_ERP_MASTER]: [ROLE_ERP_ITEM, 'ROLE_ERP_ATTRIBUTE', 'ROLE_ERP_BRAND', 'ROLE_ERP_CATEGORY'],
  ROLE_ROOT_SCM_MASTER: ['ROLE_SCM_MASTER'],
  ROLE_SCM_MASTER: ['ROLE_SCM_FULFILLMENT', 'ROLE_SCM_PLANNING', 'ROLE_SCM_INVOICING'],
  ROLE_ROOT_CMS_MASTER: ['ROLE_CMS_MASTER'],
  ROLE_CMS_MASTER: [ROLE_CMS_PAGES, 'ROLE_CMS_HOME', 'ROLE_CMS_FAQ'],
  ROLE_ROOT_ACC_MASTER: ['ROLE_ACC_MASTER'],
  ROLE_ACC_MASTER: ['ROLE_ACC_INVOICE'],
  [ROLE_ROOT_EMPLOYEE]: [ROLE_EMPLOYEE],
  [ROLE_COMPANY]: [ROLE_EMPLOYEE],
  [ROLE_EMPLOYEE]: [ROLE_USER],
  [ROLE_PROVISION_VALID]: [ROLE_USER],
  [ROLE_CUSTOMER]: [ROLE_USER],
};

// check if user has the role
const may = (role, userRoles) => {
  if (_includes(userRoles, role)) {
    return true;
  }

  let result = false;
  _forEach(userRoles, userRole => {
    if (rolesHierarchy[userRole] !== undefined) {
      if (!result) {
        result = checkRecursive(role, rolesHierarchy[userRole]);
      }
    }
  });

  return result;
};

// user has all those rights; check if he owns a role we are looking for
const checkRecursive = (role, rolesList) => {
  if (_includes(rolesList, role)) {
    return true;
  }

  let result = false;
  _forEach(rolesList, userRole => {
    if (rolesHierarchy[userRole] !== undefined) {
      if (!result) {
        result = checkRecursive(role, rolesHierarchy[userRole]);
      }
    }
  });

  return result;
};

const mayOneOf = (roles, userRoles) => {
  if (roles && roles.length) {
    const rolesCount = roles.length;
    for (let i = 0; i < rolesCount; i++) {
      if (may(roles[i], userRoles)) {
        return true;
      }
    }
  }

  return false;
};

export default may;
export { mayOneOf };
