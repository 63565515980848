import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Grid, Row, Col, Clearfix } from 'react-bootstrap';
import Masonry from 'react-masonry-component';
import ImageGallery from 'react-image-gallery';
import GoogleMap from 'google-map-react';
import scriptLoader from 'react-async-script-loader';

import { generateMetaTags } from 'helpers/metaTags';
import { getLocationsPath } from 'helpers/intl';
import { getWebFile, getThumbFile } from 'helpers/items';

import Loader from 'components/layout/Loader';
import MapMarker from 'components/layout/MapMarker';
import Breadcrumb from 'components/layout/Breadcrumb';
import WorkingHours from 'components/layout/client/WorkingHours';

import DocMeta from 'components/DocMeta';
import Data from 'components/Data';

import * as constants from 'js/constants';

class LocationDetails extends React.Component {
  render() {
    const { intl, appIntl, location, match, isScriptLoaded } = this.props;
    const { messages } = intl;

    if (!isScriptLoaded) {
      return <Loader />;
    }

    return (
      <DocMeta tags={generateMetaTags(messages.meta.company_locations)}>
        <div class={typeof widget !== 'undefined' && widget ? '' : 'content'}>
          <Data
            dataName="location"
            url={'/api/whitelabellocations/' + match.params.locationId + '.json'}
            data={location}
          />

          <Grid fluid>
            <Row class="condensed">
              <Col xs={12}>
                <Breadcrumb
                  crumbs={[
                    { name: messages.home, link: appIntl.urlPrefix },
                    { name: messages.locations, link: getLocationsPath() },
                    { name: location.pending ? null : location.data.name, link: null },
                  ]}
                />
              </Col>
            </Row>
          </Grid>

          {location.pending ? (
            <Loader />
          ) : (
            <div>
              <Grid fluid>
                <Row>
                  <Col xs={12} md={8}>
                    <div>
                      <h1>{location.data.name}</h1>
                      <h4>
                        {location.data.street} {location.data.house_number}
                        <br />
                        {location.data.zip_code} {location.data.city}
                      </h4>
                      <h4>
                        {messages.phone}: {location.data.main_phone}
                        <br />
                        {messages.email}: {location.data.main_email}
                      </h4>
                    </div>

                    <h2 class="m-t-30 m-b-5">
                      <FormattedMessage id="services_we_offer_in_city" values={{ city: location.data.city }} />
                    </h2>
                    <Masonry elementType="div" options={{ transitionDuration: 0 }}>
                      {location.data.is_sales_point ? (
                        <Col xs={12} md={6} class="padding-10 sm-padding-0">
                          <div class="bg-white padding-15">
                            <h3>
                              <span class="fa-stack">
                                <i class="fal fa-user-tie fa-stack-1x" />
                              </span>{' '}
                              {messages.sales_point}
                            </h3>

                            <div>
                              {location.data.sales_point_email ? (
                                <div>
                                  {messages.email}: {location.data.sales_point_email}
                                </div>
                              ) : null}
                              {!location.data.sales_point_email && location.data.main_email ? (
                                <div>
                                  {messages.email}: {location.data.main_email}
                                </div>
                              ) : null}

                              {location.data.sales_point_phone ? (
                                <div>
                                  {messages.phone}:{' '}
                                  <a href={'tel:' + location.data.sales_point_phone} class="text-master">
                                    {location.data.sales_point_phone}
                                  </a>
                                </div>
                              ) : null}
                              {!location.data.sales_point_phone && location.data.main_phone ? (
                                <div>
                                  {messages.phone}:{' '}
                                  <a href={'tel:' + location.data.main_phone} class="text-master">
                                    {location.data.main_phone}
                                  </a>
                                </div>
                              ) : null}
                            </div>

                            {location.data.working_week ? (
                              <div class="m-t-20">
                                <h5 class="m-b-5">{messages.formGroup.opening_hours}</h5>
                                <WorkingHours data={location.data.working_week} />
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      ) : null}

                      {location.data.is_service_point ? (
                        <Col xs={12} md={6} class="padding-10 sm-padding-0">
                          <div class="bg-white padding-15">
                            <h3>
                              <span class="fa-stack">
                                <i class="fal fa-wrench fa-stack-1x" />
                                <i class="fal fa-screwdriver fa-stack-1x fa-flip-horizontal" />
                              </span>{' '}
                              {messages.service_point}
                            </h3>

                            <div>
                              {location.data.service_point_email ? (
                                <div>
                                  {messages.email}: {location.data.service_point_email}
                                </div>
                              ) : null}
                              {!location.data.service_point_email && location.data.main_email ? (
                                <div>
                                  {messages.email}: {location.data.main_email}
                                </div>
                              ) : null}

                              {location.data.service_point_phone ? (
                                <div>
                                  {messages.phone}:{' '}
                                  <a href={'tel:' + location.data.service_point_phone} class="text-master">
                                    {location.data.service_point_phone}
                                  </a>
                                </div>
                              ) : null}
                              {!location.data.service_point_phone && location.data.main_phone ? (
                                <div>
                                  {messages.phone}:{' '}
                                  <a href={'tel:' + location.data.main_phone} class="text-master">
                                    {location.data.main_phone}
                                  </a>
                                </div>
                              ) : null}
                            </div>

                            {location.data.working_week_service_point ? (
                              <div class="m-t-20">
                                <h5 class="m-b-5">{messages.formGroup.opening_hours}</h5>
                                <WorkingHours data={location.data.working_week_service_point} />
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      ) : null}

                      {location.data.is_rental_point ? (
                        <Col xs={12} md={6} class="padding-10 sm-padding-0">
                          <div class="bg-white padding-15">
                            <h3>
                              <span class="fa-stack">
                                <i class="fal fa-car-side fa-stack-1x" />
                                <i class="fas fa-key text-master top-right" style={{ fontSize: '.6em' }} />
                              </span>{' '}
                              {messages.rental_point}
                            </h3>

                            <div>
                              {location.data.rental_point_email ? (
                                <div>
                                  {messages.email}: {location.data.rental_point_email}
                                </div>
                              ) : null}
                              {!location.data.rental_point_email && location.data.main_email ? (
                                <div>
                                  {messages.email}: {location.data.main_email}
                                </div>
                              ) : null}

                              {location.data.rental_point_phone ? (
                                <div>
                                  {messages.phone}:{' '}
                                  <a href={'tel:' + location.data.rental_point_phone} class="text-master">
                                    {location.data.rental_point_phone}
                                  </a>
                                </div>
                              ) : null}
                              {!location.data.rental_point_phone && location.data.main_phone ? (
                                <div>
                                  {messages.phone}:{' '}
                                  <a href={'tel:' + location.data.main_phone} class="text-master">
                                    {location.data.main_phone}
                                  </a>
                                </div>
                              ) : null}
                            </div>

                            {location.data.working_week_rental_point ? (
                              <div class="m-t-20">
                                <h5 class="m-b-5">{messages.formGroup.opening_hours}</h5>
                                <WorkingHours data={location.data.working_week_rental_point} />
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      ) : null}

                      {location.data.is_warehouse ? (
                        <Col xs={12} md={6} class="padding-10 sm-padding-0">
                          <div class="bg-white padding-15">
                            <h3>
                              <span class="fa-stack">
                                <i class="fal fa-warehouse-alt fa-stack-1x" />
                              </span>{' '}
                              {messages.warehouse}
                            </h3>

                            <div>
                              {location.data.warehouse_email ? (
                                <div>
                                  {messages.email}: {location.data.warehouse_email}
                                </div>
                              ) : null}
                              {!location.data.warehouse_email && location.data.main_email ? (
                                <div>
                                  {messages.email}: {location.data.main_email}
                                </div>
                              ) : null}

                              {location.data.warehouse_phone ? (
                                <div>
                                  {messages.phone}:{' '}
                                  <a href={'tel:' + location.data.warehouse_phone} class="text-master">
                                    {location.data.warehouse_phone}
                                  </a>
                                </div>
                              ) : null}
                              {!location.data.warehouse_phone && location.data.main_phone ? (
                                <div>
                                  {messages.phone}:{' '}
                                  <a href={'tel:' + location.data.main_phone} class="text-master">
                                    {location.data.main_phone}
                                  </a>
                                </div>
                              ) : null}
                            </div>

                            {location.data.working_week_warehouse ? (
                              <div class="m-t-20">
                                <h5 class="m-b-5">{messages.formGroup.opening_hours}</h5>
                                <WorkingHours data={location.data.working_week_warehouse} />
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      ) : null}

                      <Clearfix />
                    </Masonry>
                  </Col>
                  <Col xs={12} md={4}>
                    {location.data && location.data.imgs && location.data.imgs.length > 0 ? (
                      <ImageGallery
                        showThumbnails
                        showPlayButton={false}
                        onScreenChange={fullscreenElement =>
                          this.setState({ isFullScreen: fullscreenElement !== null })
                        }
                        renderItem={item => {
                          return (
                            <div
                              className="image-gallery-image"
                              style={{
                                height: this.state.isFullScreen ? '100%' : '400px',
                                width: '100%',
                                backgroundImage: 'url(' + item.original + ')',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center center',
                                backgroundRepeat: 'no-repeat',
                              }}
                            >
                              <img src={item.original} style={{ visibility: 'hidden' }} alt="" />
                            </div>
                          );
                        }}
                        items={location.data.imgs.map((img, i) => {
                          const imageOriginal = getWebFile(img);
                          const imageThumb = getThumbFile(img);

                          //  if (imageThumb) {
                          return { original: imageOriginal.web_link, thumbnail: imageThumb.web_link };
                          //  }
                        })}
                      />
                    ) : null}
                  </Col>
                </Row>
              </Grid>

              {location.data.coordinates ? (
                <div style={{ height: '400px' }} class="gmap m-t-10 p-b-30 sm-p-b-10">
                  <GoogleMap
                    bootstrapURLKeys={{
                      key: constants.GOOGLE_MAPS_KEY,
                      language: appIntl.locale,
                    }}
                    defaultCenter={{ lat: location.data.coordinates.lat, lng: location.data.coordinates.lng }}
                    defaultZoom={14}
                    options={constants.mapOptions}
                    resetBoundsOnResize
                    key="map"
                  >
                    <MapMarker
                      lat={location.data.coordinates.lat}
                      lng={location.data.coordinates.lng}
                      title={<div class="lh-16">{location.data.name}</div>}
                      tooltip={
                        <div class="small hint-text">
                          {location.data.street} {location.data.house_number} {location.data.apartment_number}
                          <br />
                          {location.data.zip_code} {location.data.city}
                        </div>
                      }
                    />
                  </GoogleMap>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </DocMeta>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    location: state.data.location,
  };
};

export default injectIntl(connect(mapStateToProps)(scriptLoader(constants.GOOGLE_MAPS_URL)(LocationDetails)));
