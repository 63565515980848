import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import TableResponsive from 'components/layout/TableResponsive';

class PerformanceCookiesList extends React.Component {
  render() {
    const {
      intl: { messages },
    } = this.props;

    const cookieListTableHeaders = {
      name: messages.name,
      description: messages.description,
      domain: messages.domain,
      ttl: 'TTL',
    };

    return (
      <TableResponsive
        headers={cookieListTableHeaders}
        rows={[
          {
            name: '_ga',
            description: messages.cookie_description.ga,
            domain: '.gstatic.com',
            ttl: messages.cookie_ttl['2_years'],
          },
          {
            name: '_gid',
            description: messages.cookie_description.gid,
            domain: '.gstatic.com',
            ttl: messages.cookie_ttl['1_day'],
          },
        ]}
        hover
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    projectConfig: state.data.projectConfig,
  };
};

export default injectIntl(connect(mapStateToProps)(PerformanceCookiesList));
