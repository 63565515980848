import React from 'react';
import { injectIntl } from 'react-intl';
import Modal from 'components/Modal';

// start page for checkout - information about online order
class MoreInfoStepB extends React.Component {
  render() {
    const { intl, isCarCheckout, show, onHide } = this.props;
    const messages = intl.messages;

    return (
      <Modal show={show} onHide={onHide} bsSize="lg" class="fill-in">
        <Modal.Header closeButton>Title</Modal.Header>
        <Modal.Body>{isCarCheckout ? 'Car checkout infos' : 'Item checkout infos'}</Modal.Body>
      </Modal>
    );
  }
}
MoreInfoStepB.defaultProps = {
  isCarCheckout: false,
};

export default injectIntl(MoreInfoStepB);
