import axios from 'axios';

export const TASKS_C = 'TASKS_C';
export const LEADS_C = 'LEADS_C'




export function getTasksData() {

  let viewType = "self";
  let important = 3;
  let high = 2;
  return {
    type: TASKS_C,
    payload: axios.get(`/api/leads.json?offset=0&limit=100&search=&sort=cdate&dir=desc&page=1&pipelineType=task&priority=${high}&priority=${important}&view=${viewType}`).then(({ data }) => data),
  };
}

export function getLeadsData() {

  let viewType = "self";
  let important = 3;
  let high = 2;
  return {
    type: LEADS_C,
    payload: axios.get(`/api/leads.json?offset=0&limit=100&search=&sort=cdate&dir=desc&page=1&pipelineType=lead&priority=${high}&priority=${important}&view=${viewType}`).then(({ data }) => data),
  };
}
