import React from 'react';
import { Clearfix } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { Flipped } from 'react-flip-toolkit';
import { find as _find } from 'lodash';

import AbstractCmsElement from './AbstractCmsElement';

export default class CmsElementRow extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      ...this.state,
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  getColParam = (col, name) => {
    return _find(col.props.params, ['name', name]);
  };

  getColParamValue = (col, name, defaultValue = null) => {
    const param = this.getColParam(col, name);
    if (param && param.value) {
      return param.value;
    }

    return defaultValue;
  };

  getColDef = col => {
    const { editMode } = this.props;

    const xs = parseInt(this.getColParamValue(col, 'xs', 12), 10);
    const sm = parseInt(this.getColParamValue(col, 'sm', xs), 10);
    const md = parseInt(this.getColParamValue(col, 'md', sm), 10);
    const lg = parseInt(this.getColParamValue(col, 'lg', md), 10);

    const hiddenPhone = !editMode && this.getColParamValue(col, 'hiddenPhone', 'no') === 'yes';
    const hiddenTablet = !editMode && this.getColParamValue(col, 'hiddenTablet', 'no') === 'yes';
    const hiddenDesktop = !editMode && this.getColParamValue(col, 'hiddenDesktop', 'no') === 'yes';

    return {
      xs: hiddenPhone ? 0 : xs,
      sm: hiddenTablet ? 0 : sm,
      md: hiddenTablet ? 0 : md,
      lg: hiddenDesktop ? 0 : lg,
    };
  };

  render() {
    const { useFlipped, index, layout } = this.props;
    const { shown } = this.state;

    let equalHeightColClass = ' ';
    if (layout.breakpointIndex === 0 && this.getParamValue('colSameHeightPhone', 'no') === 'yes') {
      equalHeightColClass = 'row-eq-height ';
    } else if (layout.breakpointIndex <= 2 && this.getParamValue('colSameHeightTablet', 'no') === 'yes') {
      equalHeightColClass = 'row-eq-height ';
    } else if (layout.breakpointIndex === 3 && this.getParamValue('colSameHeightDesktop', 'no') === 'yes') {
      equalHeightColClass = 'row-eq-height ';
    }

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';

    const parallaxData = this.getParallaxData();

    let colCounter = 0;
    let innerContent = (
      <div
        id={this.getMainId()}
        class={
          'ms-row row ' +
          shownClass +
          equalHeightColClass +
          this.getResponsiveClasses(15) +
          this.getPaddingClasses() +
          (this.getParamValue('isCondensed', 'no') === 'yes' ? ' condensed' : '') +
          this.getCssClasses()
        }
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        {this.props.children
          ? this.props.children.map((col, i) => {
              const colDef = this.getColDef(col);

              const res = [];
              res.push(React.cloneElement(col, { key: i }));

              colCounter += colDef[layout.breakpoint];
              if (colCounter % 12 === 0) {
                res.push(<Clearfix key={'c' + i} />);
              }

              return res;
            })
          : null}

        {this.getAppendHtml()}
      </div>
    );

    if (useFlipped) {
      innerContent = <Flipped flipId={index}>{innerContent}</Flipped>;
    }

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementRow.defaultProps = {
  index: '0',
};
