import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux';
import { intlModule } from '@chedri/base';
import { getNameInCurrentLanguage } from 'helpers/intl';

const cartShape = PropTypes.shape({
  label: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      lang: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  quantity: PropTypes.number,
  unit: PropTypes.string,
  net: PropTypes.number,
  discount: PropTypes.number,
  info: PropTypes.string,
  domain_hosting: PropTypes.string,
});

InvoiceTable.propTypes = {
  positions: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(cartShape)]),
  currency: PropTypes.string,
  transportationCost: PropTypes.number,
  totalGross: PropTypes.number,
  totalNet: PropTypes.number,
  sumNet: PropTypes.number,
  discountNet: PropTypes.number,
  vatRates: PropTypes.arrayOf(
    PropTypes.shape({
      rate: PropTypes.number,
      sum: PropTypes.number,
    })
  ),
};

InvoiceTable.defaultProps = {
  positions: [],
  currency: 'EUR',
  transportationCost: undefined,
  totalGross: 0,
  totalNet: 0,
  sumNet: 0,
  discountNet: undefined,
  vatRates: [],
};

function InvoiceTable({
  positions,
  totalGross,
  totalNet,
  sumNet,
  discountNet,
  vatRates,
  transportationCost,
  currency,
}) {
  const messages = useSelector(intlModule.selectors.getMessages);
  const appIntl = useSelector(intlModule.selectors.getIntl);

  return (
    <table className="invoice-table">
      <thead>
        <tr>
          <th />
          <th>{messages.net}</th>
        </tr>
      </thead>
      <tbody>
        {positions.map((position, idx) => (
          <tr key={`position_${idx}`}>
            <td>
              {typeof position.label === 'object' ? (
                <div>{getNameInCurrentLanguage(position.label, appIntl)}</div>
              ) : (
                <div>{position.label}</div>
              )}
              {position.quantity ? (
                <div>
                  {position.quantity}
                  {position.unit ? ' ' + position.unit : null}
                </div>
              ) : null}
              {position.info ? <div>{position.info}</div> : null}
              {!!position.discount && (
                <div>
                  {messages.discount}{' '}
                  <FormattedNumber value={-position.discount} style="currency" currency={currency} />
                </div>
              )}
            </td>
            <td>
              <FormattedNumber value={position.net} style="currency" currency={currency} />
            </td>
          </tr>
        ))}
        <tr className="subtotal">
          <td>{messages.salesDoc.subtotal}</td>
          <td>
            <FormattedNumber value={sumNet} style="currency" currency={currency} />
          </td>
        </tr>
        {discountNet ? (
          <tr className="discount">
            <td>{messages.discount}</td>
            <td>
              <FormattedNumber value={-discountNet} style="currency" currency={currency} />
            </td>
          </tr>
        ) : null}
        {transportationCost ? (
          <tr className="transportation-cost">
            <td>{messages.transportation_cost}</td>
            <td>
              <FormattedNumber value={transportationCost} style="currency" currency={currency} />
            </td>
          </tr>
        ) : null}
        <tr className="total">
          <td>{messages.total}</td>
          <td>
            <FormattedNumber value={totalNet} style="currency" currency={currency} />
          </td>
        </tr>
        {(vatRates || []).map(({ rate, sum }) => (
          <tr className="var-rates" key={rate + sum + ''}>
            <td>
              {messages.vat_short}&nbsp;({rate}%)
            </td>
            <td>
              <FormattedNumber value={sum} style="currency" currency={currency} />
            </td>
          </tr>
        ))}
        <tr className="total-gross">
          <td>{messages.total_gross}</td>
          <td>
            <FormattedNumber value={totalGross} style="currency" currency={currency} />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default InvoiceTable;
