import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import generateId from 'helpers/id';

function Icon({ name, duotone, solid, light, iconStyles, iconClassName, tooltip, tooltipPlacement, label, layers }) {
  let iconClassTag = 'fa';
  if (duotone) {
    iconClassTag += 'd';
  } else if (solid) {
    iconClassTag += 's';
  } else if (light) {
    iconClassTag += 'l';
  } else {
    iconClassTag += 'r';
  }
  const [tooltipId] = useState(generateId('icon-'));
  const rawIcon = <i className={`${iconClassName} ${name} ${iconClassTag}`} style={iconStyles} />;
  const icon = label ? (
    <span>
      {rawIcon} {label}
    </span>
  ) : Array.isArray(layers) ? (
    <span className="fa-layers">
      {rawIcon}
      {layers.map(layer => layer)}
    </span>
  ) : (
    rawIcon
  );
  if (tooltip) {
    return (
      <OverlayTrigger placement={tooltipPlacement} overlay={<Tooltip id={tooltipId}>{tooltip}</Tooltip>}>
        {icon}
      </OverlayTrigger>
    );
  }
  return icon;
}
Icon.defaultProps = {
  duotone: false,
  solid: false,
  light: false,
  iconStyles: {},
  iconClassName: '',
  tooltip: null,
  tooltipPlacement: undefined,
  label: null,
  layersd: undefined,
  onClick: () => undefined,
};
Icon.propTypes = {
  name: PropTypes.string.isRequired,
  duotone: PropTypes.bool,
  solid: PropTypes.bool,
  light: PropTypes.bool,
  iconStyles: PropTypes.object,
  iconClassName: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  label: PropTypes.string,
  layers: PropTypes.arrayOf(PropTypes.element),
};

export default Icon;
