import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';

import { elementSource, elementTarget } from './elementDragUtils';

import SignupForm from 'components/forms/auth/SignupForm';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';
import RichEditorContent from 'components/RichEditorContent';

import * as constants from 'js/constants';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
export default class CmsElementSignupForm extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      pending: false,
      shown: false,
    };
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeout);
    this.__componentWillUnmount();
  };

  // dummy onSubmit
  onClick = () => {
    clearTimeout(this.timeout);
    this.setState({ pending: !this.state.pending }, () => {
      if (this.state.pending) {
        this.timeout = setTimeout(() => this.setState({ pending: false }), 1000);
      }
    });
  };

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  render() {
    const {
      intl: { messages },
      connectDropTarget,
      connectDragSource,
      connectDragPreview,
    } = this.props;
    const { pending, shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const textClass = 'text-' + this.getParamValue('mainColor', 'master');

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-signup-form-content ' + shownClass + this.getCssClasses()}
        style={{ ...this.getBackgroundStyle() }}
        onClick={this.showSettings}
      >
        {this.getElementStyle()}

        <div class="ms-signup-form-container">
          <h4 class={'no-margin p-b-5 ' + textClass}>
            <i class="fa fa-pencil-alt m-r-5" />
            {this.getParamValue('title', '')}
          </h4>

          <RichEditorContent content={'<p class="' + textClass + '">' + this.getParamValue('content', '') + '</p>'} />

          <SignupForm
            {...this.props}
            onSubmit={this.onClick}
            buttonTitle={this.getParamValue('buttonTitle', messages.signup)}
          />
        </div>

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}
