import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Button } from 'react-bootstrap';
import { isEmpty as _isEmpty, isEqual as _isEqual, pick as _pick, find as _find } from 'lodash';

import { setFilters } from 'actions/SummaryActions';
import { parseQs, stringifyQs } from 'helpers/http';
import { mapping } from 'helpers/items';
import { getPassengerCarSearchPath } from 'helpers/intl';

import List from 'components/List';
import Count from 'components/Count';

import FormAutocomplete from 'components/forms/FormAutocomplete';

class CarNarrowSearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      category: ',0,0000000006,',
      initialised: true,
    };

    const query = parseQs(props.location.search);

    if (!_isEmpty(query) && !_isEqual(query, props.filters)) {
      props.setFilters(query);
      this.state.initialised = false; // we need to wait until the filters state get updated
    } else if (props.filters && !_isEmpty(props.filters)) {
      props.history.push({
        pathname: props.location.pathname,
        search: stringifyQs(_pick(props.filters, ['BRAND', props.intl.messages.car_type])),
      });
    }
  }

  componentDidUpdate = prevProps => {
    const { location, filters, setFilters } = this.props;
    const { initialised } = this.state;

    if (!_isEqual(prevProps.location.search, location.search)) {
      setFilters(parseQs(location.search));
    }

    if (!initialised && !_isEqual(prevProps.filters, filters)) {
      this.setState({ initialised: true });
    }
  };

  onChange = (name, value) => {
    const { location, history } = this.props;

    const query = parseQs(location.search);
    query[name] = [value];
    history.replace({ pathname: location.pathname, search: stringifyQs(query) });
  };

  render() {
    const {
      intl: { messages },
      location,
      count,
      facets,
      filters,
    } = this.props;
    const { category, initialised } = this.state;

    const query = parseQs(location.search);

    const listUrlData = { category, filter: { ...filters } };
    const facetUrlData = { category };

    const typesFacet = _find(facets.data.list, ['id', mapping.carType]);
    const brandFacet = _find(facets.data.list, ['id', mapping.brand]);

    const selectedType = query[messages.car_type] && query[messages.car_type][0] ? query[messages.car_type][0] : null;
    const selectedBrand = query.BRAND && query.BRAND[0] ? query.BRAND[0] : null;

    return initialised ? (
      <div>
        <Count countName="summary" url="/api/summary/count.json" urlData={listUrlData} count={count} noRender />
        <List listName="summaryFacets" url="/api/summary/facets.json" urlData={facetUrlData} list={facets} noRender />

        <Row>
          <Col xs={12}>
            <FormAutocomplete
              name={messages.car_type}
              label={messages.car_type}
              value={selectedType ? { label: selectedType, value: selectedType } : null}
              options={
                typesFacet && typesFacet.values
                  ? typesFacet.values.map(typeValue => {
                      return { value: typeValue.name, label: typeValue.name };
                    })
                  : []
              }
              onChange={this.onChange}
            />
          </Col>
          <Col xs={12}>
            <FormAutocomplete
              name="BRAND"
              label={messages.brand}
              value={selectedBrand ? { label: selectedBrand, value: selectedBrand } : null}
              options={
                brandFacet && brandFacet.values
                  ? brandFacet.values.map(brandValue => {
                      return { value: brandValue.name, label: brandValue.name };
                    })
                  : []
              }
              onChange={this.onChange}
            />
          </Col>
          <Col xs={12} class="text-right p-t-15">
            <Button bsStyle="primary" bsSize="lg" type="submit" disabled={facets.pending}>
              {facets.pending ? (
                <i class="fa fa-circle-notch fa-spin" />
              ) : (
                <FormattedMessage id="count_cars" values={{ count: count.data }} />
              )}
            </Button>
          </Col>
          <Col xs={12} class="text-center p-t-25">
            <FormattedMessage
              id="not_sure_go_to_advisory_mode"
              values={{ link: <Link to={getPassengerCarSearchPath()}>{messages.link_to_advisory_mode}</Link> }}
            />
          </Col>
        </Row>
      </div>
    ) : null;
  }
}
CarNarrowSearchForm.defaultProps = {};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    count: state.count.summary,
    facets: state.list.summaryFacets,
    attributes: state.list.summaryAttributes,
    filters: state.summary,
    projectConfig: state.data.projectConfig,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setFilters: filters => {
      dispatch(setFilters(filters));
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CarNarrowSearchForm)
  )
);
