import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getLogger } from '@chedri/base';

const logger = getLogger('components :: Panel');

function Panel({ className, style, children }) {
  useEffect(() => {
    let headerCount = 0;
    let bodyCount = 0;
    React.Children.forEach(children, child => {
      if (child.type.displayName === 'Panel.Header') {
        headerCount++;
      }
      if (child.type.displayName === 'Panel.Body') {
        bodyCount++;
      }
    });
    if (headerCount > 1) {
      logger.warn('Has more than one header.');
    }
    if (bodyCount > 1) {
      logger.warn('Has more than one body.');
    }
  }, [children]);
  return (
    <div className={`view-port ${className}`} style={style}>
      <div class="view bg-white b-rad-sm">{children}</div>
    </div>
  );
}

Panel.Header = function PanelHeader({ children, className, style, leftAction, rightAction }) {
  return (
    <div className={`navbar navbar-default ${className}`} style={style}>
      <div className="navbar-inner">
        {leftAction && <span className="inline action pull-left p-l-10">{leftAction}</span>}
        <div className="view-heading">{children}</div>
        {rightAction && <span className="inline action pull-right p-r-10">{rightAction}</span>}
      </div>
    </div>
  );
};
Panel.Header.defaultProps = {
  leftAction: undefined,
  rightAction: undefined,
};
Panel.Header.propTypes = {
  leftAction: PropTypes.element,
  rightAction: PropTypes.element,
};

Panel.Body = function PanelBody({ children, className, style }) {
  return (
    <div className={`panel-body relative ${className || ''}`} style={{ ...style, height: 'calc(100% - 51px)' }}>
      {children}
    </div>
  );
};

export default Panel;
