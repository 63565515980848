import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip, Clearfix } from 'react-bootstrap';

import { favoriteItemsAdd, favoriteItemsRemove } from 'actions/FavoriteItemsActions';
import { isCarItem, getCo2Emissions, getFuelConsumptionCombined } from 'helpers/items';
import { getMainDomain } from 'helpers/whiteLabel';

import SummaryListCarInfos from 'components/layout/summary/SummaryListCarInfos';
import SummaryListItemInfos from 'components/layout/summary/SummaryListItemInfos';
import SummaryListCarPerks from 'components/layout/summary/SummaryListCarPerks';

class ItemListView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showShare: false,
      isWidget: !!(typeof widget !== 'undefined' && widget),
    };
  }

  goToDetails = () => {
    const { detailsUrl, history, projectConfig } = this.props;
    const { isWidget } = this.state;

    if (isWidget) {
      window.location.href = getMainDomain(projectConfig.data) + detailsUrl;
    } else {
      history.push(detailsUrl);
    }
  };

  saveFavorite = e => {
    e.preventDefault();
    e.stopPropagation();

    const { item, saveFavorite } = this.props;
    saveFavorite(item);

    return false;
  };

  removeFavorite = e => {
    e.preventDefault();
    e.stopPropagation();

    const { item, removeFavorite } = this.props;
    removeFavorite(item);

    return false;
  };

  shareItem = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showShare: true });
    return false;
  };

  shareOnFacebook = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showShare: false });
    window.open(e.target.parentNode.getAttribute('href'), '_blank');
  };

  shareOnTwitter = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showShare: false });
    window.open(e.target.parentNode.getAttribute('href'), '_blank');
  };

  hideShare = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showShare: false });
    return false;
  };

  render() {
    const {
      intl: { messages },
      appIntl,
      projectConfig,
      type,
      item,
      imageUrl,
      brandName,
      detailsUrl,
      displayGrossPrice,
      displayNetPrice,
      isFavorite,
    } = this.props;
    const { isWidget, showShare } = this.state;

    const isCar = isCarItem(item);
    const co2Emissions = getCo2Emissions(item, appIntl);
    const fuelConsumptionCombined = getFuelConsumptionCombined(item, appIntl);

    const projectDomain = getMainDomain(projectConfig.data);

    return (
      <div class="summary-item summary-item-list">
        <Clearfix>
          {/* image: */}
          <div class="col-xs-4">
            {/* image and hover element for favorite, share and : */}
            <div class="summary-item-list-header">
              <div
                class="relative cursor"
                style={{
                  paddingTop: '70%',
                  backgroundImage: 'url(' + imageUrl + ')',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                }}
                onClick={this.goToDetails}
                title={brandName + ' ' + item.name}
              >
                <div class="summary-item-list-hover">
                  {showShare ? (
                    <React.Fragment>
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="top"
                        overlay={<Tooltip>Abbrechen</Tooltip>}
                      >
                        <a href="#closeShareOptions" onClick={this.hideShare}>
                          <i class="fal fa-times fa-3x" />
                        </a>
                      </OverlayTrigger>
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="top"
                        overlay={<Tooltip>Facebook</Tooltip>}
                      >
                        <a
                          href={
                            'https://www.facebook.com/sharer/sharer.php?u=' +
                            window.location.protocol +
                            '//' +
                            window.location.hostname +
                            detailsUrl
                          }
                          onClick={this.shareOnFacebook}
                          class="m-l-30"
                          target="_blank"
                        >
                          <i class="fab fa-facebook-f fa-3x" />
                        </a>
                      </OverlayTrigger>
                      <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip>Twitter</Tooltip>}>
                        <a
                          href={
                            'https://twitter.com/intent/tweet?text=&url=' +
                            window.location.protocol +
                            '//' +
                            window.location.hostname +
                            detailsUrl
                          }
                          onClick={this.shareOnTwitter}
                          class="m-l-15"
                          target="_blank"
                        >
                          <i class="fab fa-twitter fa-3x" />
                        </a>
                      </OverlayTrigger>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {isFavorite ? (
                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          placement="top"
                          overlay={<Tooltip>Aus Favoritenliste entfernen</Tooltip>}
                        >
                          <a href="#removeFromFavorites" onClick={this.removeFavorite}>
                            <i class="fas fa-heart fa-3x" />
                          </a>
                        </OverlayTrigger>
                      ) : (
                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          placement="top"
                          overlay={<Tooltip>Auf Favoritenliste speichern</Tooltip>}
                        >
                          <a href="#addToFavorites" onClick={this.saveFavorite}>
                            <i class="far fa-heart fa-3x" />
                          </a>
                        </OverlayTrigger>
                      )}
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="top"
                        overlay={<Tooltip>Auf Soziale Medien teilen</Tooltip>}
                      >
                        <a href="#showShareOptions" onClick={this.shareItem} class="m-l-15">
                          <i class="far fa-share-alt fa-3x" />
                        </a>
                      </OverlayTrigger>
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="top"
                        overlay={<Tooltip>Detailinfos anzeigen</Tooltip>}
                      >
                        <Link to={detailsUrl} class="m-l-15">
                          <i class="far fa-info-circle fa-3x" />
                        </Link>
                      </OverlayTrigger>
                    </React.Fragment>
                  )}
                </div>

                {!imageUrl ? (
                  <span class="summary-no-image fa-stack fa-4x">
                    <i class="fas fa-camera fa-stack-1x" />
                    <i class="fas fa-ban fa-stack-2x" />
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          <div class="col-xs-8 no-padding">
            <div class="col-xs-12">
              {type === 'cars' ? <SummaryListCarPerks projectConfig={projectConfig} /> : null}

              {isWidget ? (
                <a href={projectDomain + detailsUrl}>
                  <h3 class="overflow-ellipsis" title={brandName + ' ' + item.name}>
                    {brandName} {item.name}
                  </h3>
                </a>
              ) : (
                <Link to={detailsUrl}>
                  <h3 class="overflow-ellipsis" title={brandName + ' ' + item.name}>
                    {brandName} {item.name}
                  </h3>
                </Link>
              )}
            </div>

            <div class="col-xs-6 summary-item-list-body">
              {isCar ? <SummaryListCarInfos item={item} /> : <SummaryListItemInfos item={item} />}
            </div>

            <div class="col-xs-6">
              <h3 class="text-center m-b-5">{displayGrossPrice}</h3>
              {displayNetPrice && <h6 className="text-center no-margin">{displayNetPrice}</h6>}
            </div>

            <div class="col-xs-12">
              {/* if it's a car item, display the fuel economy information */}
              {isCar ? (
                <div class="text-center fs-10 m-t-10 overflow-ellipsis">
                  {messages.car_fuel_consumption} ({'komb.'}) {fuelConsumptionCombined || ' - '}
                  {messages.car_fuel_consumption_unit}
                  {', '}
                  {messages.car_co2_emissions} ({'komb.'}) {co2Emissions || ' - '}
                  {messages.car_co2_emissions_unit}
                </div>
              ) : null}
            </div>
          </div>
        </Clearfix>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveFavorite: item => {
      dispatch(favoriteItemsAdd(item));
    },
    removeFavorite: item => {
      dispatch(favoriteItemsRemove(item));
    },
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ItemListView);
