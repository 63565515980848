import React from 'react';
import { connect } from 'react-redux';
import _, { find as _find } from 'lodash';
import ReactDOM from "react-dom";
import {Button, Col, Row} from "react-bootstrap";
import FormAutocomplete from "../../../components/forms/FormAutocomplete";
import FormInput from "../../../components/forms/FormInput";
import FormFile from "../../../components/forms/FormFile";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";

const ContextModal = ({children}) => {
  return ReactDOM.createPortal(
    children,
    document.getElementById('context-modal')
  );
};

class Creatomate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      video: [],
      videoEdit: [],
      showVideoEdit: false,
      videoFile: undefined,
    }

  }

  setVideo = () => {
    const { videoEdit, videoFile} = this.state;
    const { id, attributes, updateParam, imageUpload } = this.props;

    let param = videoEdit[0] + '#aaa#' + videoEdit[1];
    const overflow = false;
    const default_image = false;
    const errors = [{overflow, default_image}];
    updateParam(id, attributes['data-name'], param, errors);
    if(videoEdit[0]==='own' && videoFile){
      imageUpload(id, videoFile, 'video', attributes['data-name'], null, 0, 0);
    }
    this.setState({video: videoEdit, showVideoEdit: false, videoFile: undefined}, () => {});
  }

  getVideo = () => {
    const { name: Tag, attributes, params } = this.props;
    let video = []
    const param = _find(params, ['name', attributes['data-name']]);
    if (param) {
      video = param.value.split('#aaa#');
    }

    this.setState(
      {video: video, videoEdit: video}, () => {  });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { params } = this.props;
    _.forEach(params, (url, i) => {
      if (prevProps.params[i]?.value !== params[i]?.value) {
        this.getVideo();
      }
    });
  }

  componentDidMount = () => {
    this.getVideo();
    const { attributes, params, id, updateParam } = this.props;
    const param = _find(params, ['name', attributes['data-name']]);
  }

  showVideoEdit = () => {
    this.setState({showVideoEdit: true});
  }

  closeVideoEdit = () => {
    this.setState({showVideoEdit: false});
  }

  getVideoContainer = (video, show) => {
    const { attributes } = this.props;
    return (
      <>
        { attributes && attributes['data-img'] && show === false ? <img src={attributes['data-img']} alt="img-placeholder" /> :
          <div className="video-container">
            {(video[0] === 'link' || video[0] === 'own') &&
              <video src={video[1]} crossorigin="anonymous" controls="controls" controlslist="nodownload" />
            }
          </div>
        }
      </>
    )
  }

  setFile = (name, value) => {
    this.setState({videoFile:value[0]});
    const reader = new FileReader();
    reader.onloadend = (e) => {
      const {videoEdit} = this.state;
      let buffer = e.target.result;
      let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'video/mp4' });
      let url = window.URL.createObjectURL(videoBlob);
      videoEdit[1] = url ;
      this.setState({videoEdit:videoEdit});
    };
    reader.readAsArrayBuffer(value[0]);

  }

  changeProvider = (name, value) => {
    const {videoEdit} = this.state;
    videoEdit[0] = value;
    videoEdit[1] = '';
    this.setState({videoEdit: videoEdit});
  }


  setUrl = (name, value) => {
    const {videoEdit} = this.state;
    videoEdit[1] = value;
    this.setState({videoEdit: videoEdit});
  }

  checkVideoSrc = video => {
    // check source of video for blob
    if (video && (video[0] === 'own' || video[0] === 'link')) {
      return video[1].includes('blob')
    }
    return false;
  }


  render() {
    const { name: Tag, attributes} = this.props;
    const { video, videoEdit, showVideoEdit } = this.state;
    const {intl: { messages }} = this.props;

    let invalidSource = this.checkVideoSrc(video);

    return (
      <>
        <Tag {...attributes} onClick={this.showVideoEdit} data-tip={messages.editor.video_settings} data-for="top">
          <div className="wrap">

            {Array.isArray(video) && video.length > 0 && invalidSource ? (
              <p className="uploadError">
                <i className="far fa-exclamation-triangle" />
              </p>
            ) : Array.isArray(video) && video.length > 0 && !invalidSource ? (
              <p className="uploadSuccess">
                <i className="fas fa-check"></i>
              </p>
            ) : <></>}


            {
              video.length > 0 ? (
                this.getVideoContainer(video, false)
              ) : (
               attributes['data-text'] ? (
                  <>
                    <i className="fad fa-film" />
                    <p className="added_video_text">{attributes['data-text']}</p>
                  </>
                ) : (
                  <>
                    <i className="fad fa-film" />
                    <p className="default_video_text">{messages.editor.add_video}</p>
                  </>
                )
              )
            }
          </div>
        </Tag>
      {showVideoEdit && (
        <ContextModal>
          <div id="contextModalWrap">
            <div id="contextModalStage" className="row">
              <Row className="h-45">
                <Col md={4}>
                  <FormAutocomplete
                    name="provider"
                    label={messages.editor.choice_video_provider}
                    options={[
                      { label: '-', value: '' },
                      { label: 'Video Link', value: 'link' },
                      { label: messages.editor.upload_video, value: 'own' },
                    ]}
                    value={videoEdit[0] || ''}
                    onChange={this.changeProvider}



                  />
                  {videoEdit[0] !== 'own' &&
                    <FormInput
                      name="externId"
                      label="URL zur Video-Datei"
                      value={videoEdit[1] || ''}
                      onChange={this.setUrl}

                    />
                  }
                  {videoEdit[0] === 'own' &&
                      <FormFile
                        label={messages.editor.choice_mp4_file}
                        name='upload'
                        onChange={this.setFile}
                        allowTypes="video/mp4"
                      />
                  }
                </Col>
                <Col md={8} className="h-100">
                  <div style={{minHeight: '50%', border: '2px dashed #ccc', padding: '10px'}}>
                    <p>{messages.editor.edition_preview}</p>
                    {videoEdit[0] && videoEdit[1] && this.getVideoContainer(videoEdit, true)}
                  </div>
                </Col>
              </Row>

              <Row className="text-right">
                <Button bsStyle='success' bsSize='large' onClick={this.setVideo}>{messages.editor.take_on}</Button>{' '}
                <Button bsStyle='danger' bsSize='large' onClick={this.closeVideoEdit}>{messages.editor.abort}</Button>
              </Row>
            </div>
          </div>
        </ContextModal>
      )}
    </>
    )
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};


export default withRouter(
  injectIntl(
    connect(
      mapStateToProps
    )(Creatomate)
  )
);
