import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Row, Alert } from 'react-bootstrap';

import { isCarRegistrationServiceActive, isCarRegistrationCustomLicensePlateNumberActive } from 'helpers/checkout';

import FormSwitchery from 'components/forms/FormSwitchery';

import CheckoutTotal from 'components/layout/checkout/CheckoutTotal';
import LegalTextHelper from 'components/layout/LegalTextHelper';
import MoreInfoStepDCarRegistration from 'components/layout/checkout/moreInfo/MoreInfoStepDCarRegistration';

import LegalChedriCarServicesTerms from 'components/layout/checkout/legal/LegalChedriCarServicesTerms';
import LegalChedriPrivacyPolicy from 'components/layout/checkout/legal/LegalChedriPrivacyPolicy';

const serviceName = 'carRegistration';

// a service by Kroschke to register the car in name of the client
export default class StepDCarRegistration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMoreInfo: false,
      showTerms: false,
      showPrivacyPolicy: false,
    };
  }

  showMoreInfo = e => {
    e.preventDefault();

    this.setState({ showMoreInfo: true });
  };

  closeMoreInfo = () => {
    this.setState({ showMoreInfo: false });
  };

  showTerms = e => {
    e.preventDefault();

    this.setState({ showTerms: true });
  };

  closeTerms = () => {
    this.setState({ showTerms: false });
  };

  showPrivacyPolicy = e => {
    e.preventDefault();

    this.setState({ showPrivacyPolicy: true });
  };

  closePrivacyPolicy = () => {
    this.setState({ showPrivacyPolicy: false });
  };

  getCost = () => {
    return 219.9;
  };

  getCustomLicensePlateNumberCost = () => {
    return 19.9;
  };

  validateData = () => {
    return true;
  };

  updateIsActive = value => {
    const {
      intl: { messages },
      checkout,
      setCheckoutState,
    } = this.props;

    const newServices = checkout.services.slice(); // copy the array

    let foundIndex = -1;
    for (let i = 0; i < newServices.length; i++) {
      if (newServices[i].name === serviceName) {
        foundIndex = i;
        newServices[i].isActive = value;
        newServices[i].legalAccepted = false;
      }
    }

    if (value && foundIndex === -1) {
      // we need to add this service
      newServices.push({
        name: serviceName,
        title: messages.car_registration,
        isActive: true,
        cost: this.getCost(),
        dataValid: this.validateData(),
        legalAccepted: false,
        requestedCustomLicensePlateNumber: false,
      });
    }

    // if user wishes no registration, we cannnot allow to have the transport selected
    // in this case, we must disable transport and change the shipping method to pickup
    let shipping = { ...checkout.shipping };
    if (!value && shipping.method === 'transport') {
      shipping = {
        method: 'pickup',
        cost: 0,
      };
    }

    setCheckoutState({ services: newServices, shipping });
  };

  updateRequestedCustomLicensePlateNumber = value => {
    const { checkout, setCheckoutState } = this.props;

    const newServices = checkout.services.slice(); // copy the array

    for (let i = 0; i < newServices.length; i++) {
      if (newServices[i].name === serviceName) {
        newServices[i].requestedCustomLicensePlateNumber = value;

        // update the service price
        newServices[i].cost = this.getCost();
        if (value) {
          newServices[i].cost += this.getCustomLicensePlateNumberCost();
        }
      }
    }

    setCheckoutState({ services: newServices });
  };

  render() {
    const {
      intl: { messages },
      checkout,
      includeTotal,
      projectConfig,
    } = this.props;
    const { showMoreInfo, showTerms, showPrivacyPolicy } = this.state;

    const isChecked = isCarRegistrationServiceActive(checkout);
    const requestedCustomLicensePlateNumber = isCarRegistrationCustomLicensePlateNumberActive(checkout);

    return (
      <Row class="row-same-height">
        <div class="col-md-5 b-r b-dashed b-grey sm-b-b">
          <div class="padding-30 sm-padding-5 sm-m-t-15 m-t-50">
            <i class="fa fa-gavel fa-2x hint-text" />
            <h2>{messages.car_registration}</h2>
            <p>{messages.registration_is_stressfull_get_your_car_registered}</p>
            <p class="small hint-text">
              <LegalTextHelper
                text={messages.car_registration_legal_text}
                substitutes={{
                  '{company}': 'HC Digital Services GmbH',
                  '{terms_and_conditions}': (
                    <a href="#showTerms" className="text-master b-b" onClick={this.showTerms}>
                      {messages.terms_and_conditions}
                    </a>
                  ),
                  '{privacy_policy}': (
                    <a href="#showPrivacyPolicy" className="text-master b-b" onClick={this.showPrivacyPolicy}>
                      {messages.signup_agreement_text_data_use}
                    </a>
                  ),
                }}
              />
            </p>
          </div>
        </div>

        <div class="col-md-7">
          <div class="padding-30 sm-padding-5">
            {checkout.userData.country !== 'DE' && (
              <Alert bsStyle="info">{messages.car_registration_service_only_available_in_germany}</Alert>
            )}

            <FormSwitchery
              name={serviceName}
              checked={isChecked}
              value
              disabled={checkout.userData.country !== 'DE'}
              label={
                <span>
                  {messages.i_wish_to_have_car_registered}
                  {' + '}
                  <FormattedNumber value={this.getCost()} style="currency" currency={projectConfig.data.currency} />
                </span>
              }
              onChange={(name, value) => this.updateIsActive(value)}
            />

            {isChecked && (
              <div class="p-t-15 p-l-15">
                {messages.about_car_registration}
                <br />
                <br />
                <i class="fa fa-check-circle" /> {messages.we_gather_necessary_documents}
                <br />
                <i class="fa fa-check-circle" /> {messages.we_produce_license_plates}
                <div class="m-t-15">
                  <FormSwitchery
                    name="requestedCustomLicensePlateNumber"
                    checked={requestedCustomLicensePlateNumber}
                    value
                    label={
                      <span>
                        {messages.i_would_like_individual_license_plate_number}
                        {' + '}
                        <FormattedNumber
                          value={this.getCustomLicensePlateNumberCost()}
                          style="currency"
                          currency={projectConfig.data.currency}
                        />
                      </span>
                    }
                    onChange={(name, value) => this.updateRequestedCustomLicensePlateNumber(value)}
                  />
                </div>
                {requestedCustomLicensePlateNumber && (
                  <div class="relative m-t-15">
                    {/* display information */}
                    <i class="far fa-info-circle top-left m-t-5 m-l-5" />

                    <p class="m-l-25">
                      {messages.after_the_order_reserve_desired_license_plate_at_local_registration_office}
                      <br />
                      {messages.inform_us_about_your_license_plate_reservation_so_we_can_start_the_process}
                    </p>
                  </div>
                )}
              </div>
            )}

            {includeTotal && (
              <div>
                <br />
                <CheckoutTotal isCarCheckout checkout={checkout} />
              </div>
            )}
          </div>
        </div>

        <MoreInfoStepDCarRegistration show={showMoreInfo} onHide={this.closeMoreInfo} />
        <LegalChedriCarServicesTerms show={showTerms} close={this.closeTerms} />
        <LegalChedriPrivacyPolicy show={showPrivacyPolicy} close={this.closePrivacyPolicy} />
      </Row>
    );
  }
}
StepDCarRegistration.defaultProps = {};
