import React, {createElement,PureComponent} from 'react';
import { transform } from 'js/css-transform';
import HTML from 'html-parse-stringify';
import _, { find as _find, includes as _includes } from 'lodash';
import he from 'he';

class PreviewEditable extends PureComponent {
  constructor(props) {
    super(props);
  }


  componentDidMount = () => {
    const { attributes, id, params, setFontSizeSettings } = this.props;
    if (typeof attributes['data-fontsize-section'] && attributes['data-fontsize-section']) {
      let sectionFontSize = attributes['data-fontsize-section'];
      let source = '';
      if (typeof params && params.length > 0) {
        _.forEach(params, (param, key) => {
          if (attributes['data-name'] === param.name) {
            source = param.value;
            sectionFontSize = this.extractClassFromHTML(source) ?? sectionFontSize;
          }
        });
      }

      setFontSizeSettings(id, sectionFontSize);
    }
  };

  extractClassFromHTML = (htmlString) => {
    // Create a temporary HTML element and set its HTML content
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;

    // Find all span elements
    const spanElements = tempElement.querySelectorAll('span');

    if (spanElements.length > 0) {
      // Get the last span element
      const lastSpan = spanElements[spanElements.length - 1];

      // Get the class list of the last span element
      const classList = lastSpan.className;

      // Split the class list into individual classes
      const classes = classList.split(' ');

      // Filter the classes that start with "text-"
      const textClasses = classes.filter(className => className.startsWith('text-'));

      // Return the found "text-" classes (if any)
      return textClasses?.[0];
    }
  };

  loop = elements => {
    let result = [];

    _.forEach(elements, (value, key) => {
      if (value.type === 'text') {
        result.push(he.decode(value.content));
      } else if (value.type === 'tag' && !_.includes(this.forbiddenTags, value.name)) {
        let { name } = value;
        let attributes = { key };

        _.forEach(value.attrs, (attr, attrKey) => {
          switch (attrKey) {
            case 'class': {
              attributes.className = attr;
              break;
            }

            case 'style': {
              if (attr) {
                try {
                  attributes[attrKey] = transform(attr);
                } catch (err) {}
              }
              break;
            }

            default: {
              attributes[attrKey] = attr;
              break;
            }
          }
        });

        /*if (name === 'a') {
          ({ name, attributes } = this.getLinkData({ name, attributes }));
        } else*/
        if (name === 'html' || name === 'head' || name === 'body') {
          name = 'div';
        }

        if (name === 'meta') {
          result.push(createElement(name, { ...attributes }));
        } else if (name === 'img' || name === 'br' || name === 'hr') {
          result.push(createElement(name, { ...attributes }));
        } else if (attributes.isRoot) {
          result = this.loop(value.children);
        } else {
          result.push(createElement(name, { ...attributes }, this.loop(value.children)));
        }
      }
    });

    return result;
  };

  getContent = () => {
    const { attributes, children, params } = this.props;

    const param = _find(params, ['name', attributes['data-name']]);
    if (param) {
      return this.loop(HTML.parse('<div isRoot="true">' + param.value + '</div>'));
    } else {
      // parse the ast tree and return the default html value for the template (children)
      // children
      return this.loop(children);
    }
  };

  // return the HTML content as string for the CK editor
  getEditorContent = () => {
    const { attributes, children, params } = this.props;
    const param = _find(params, ['name', attributes['data-name']]);
    if (param) {
      return param.value;
    } else {
      return HTML.stringify(children);
    }
  };

  render() {
    const { name: Tag, attributes } = this.props;

    return (
      <>
        <Tag {...attributes} contentEditable={false}>
          <div>{this.getContent()}</div>
        </Tag>
      </>
    );
  }
}

export default PreviewEditable;
