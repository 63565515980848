import React from 'react';
import { injectIntl } from 'react-intl';
import Modal from 'components/Modal';

// shipping information - car checkout
class MoreInfoStepECarCheckout extends React.Component {
  render() {
    const { intl, isCarCheckout, show, onHide } = this.props;
    const messages = intl.messages;

    return (
      <Modal show={show} onHide={onHide} bsSize="lg" class="fill-in">
        <Modal.Header closeButton>Title</Modal.Header>
        <Modal.Body>Car checkout infos</Modal.Body>
      </Modal>
    );
  }
}

export default injectIntl(MoreInfoStepECarCheckout);
