import { FAVORITE_ITEMS_ADD, FAVORITE_ITEMS_REMOVE } from 'actions/FavoriteItemsActions';

const localData = localStorage.getItem('favoriteItems') || '[]';
const itemList = JSON.parse(localData);

const initialState = {
  list: itemList,
  count: itemList.length,
};

export default function favoriteItems(state = initialState, action) {
  switch (action.type) {
    case FAVORITE_ITEMS_ADD: {
      let newList = state.list.slice();
      newList.push({ ...action.payload });

      localStorage.setItem('favoriteItems', JSON.stringify(newList));

      return { ...state, list: newList, count: newList.length };
    }

    case FAVORITE_ITEMS_REMOVE: {
      let newList = state.list.slice();
      newList = newList.filter(elem => {
        if (action.payload.variant && elem.variant) {
          return elem.item.id !== action.payload.item.id || elem.variant.id !== action.payload.variant.id;
        }

        return elem.item.id !== action.payload.item.id;
      });

      localStorage.setItem('favoriteItems', JSON.stringify(newList));

      return { ...state, list: newList, count: newList.length };
    }
  }

  return state;
}
