import React from 'react';

export default class FabActionElement extends React.Component {
  action = e => {
    e.preventDefault();

    const { action } = this.props;
    action();
  };

  render() {
    const { size, icon, caption } = this.props;

    return (
      <a href={'#' + caption} class={'ms-fab-item ms-fab-size-' + size} onClick={this.action} title={caption}>
        <i class={icon} />
        <div class="ms-fab-title overflow-ellipsis">{caption}</div>
      </a>
    );
  }
}
