import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { intlModule } from '@chedri/base';
import { FormattedNumber } from 'react-intl';
import { INVOICE_PAYMENT_OPTION_ENABLED } from 'helpers/clients';
import { netValueFromGross, grossValueFromNet, vatValueFromNet } from '../../helpers/vat';

ValueAddedTax.propTypes = {
  net: PropTypes.number,
  gross: PropTypes.number,
  vat: PropTypes.number,
  currency: PropTypes.string,
  isItemAllowsReducedVatRate: PropTypes.bool,
};

ValueAddedTax.defaultProps = {
  net: undefined,
  gross: undefined,
  /*
    TODO: VAT should be dynamic for other countries.
    Clients from Germany will always have 19% rate, optional reduced rate is applied later.
  */
  vat: 19,
  currency: 'EUR',
  isItemAllowsReducedVatRate: false,
};

function ValueAddedTax({
  net,
  gross,
  vat,
  currency,
  webPdfPrice,
  manualDataCheckPrice,
  appliedProductGroupDiscount,
  isItemAllowsReducedVatRate,
  ...props
}) {
  const messages = useSelector(intlModule.selectors.getMessages);
  const financialVatId = useSelector(state => state.login?.user?.financial?.vat_id);
  const isClientVatIdDefined = !!financialVatId;
  const effectiveInvoicePaymentOpion = useSelector(
    state => state.login?.user?.effective_invoice_payment_option?.setting
  );
  const isInvoicePaymentAvailable = INVOICE_PAYMENT_OPTION_ENABLED === effectiveInvoicePaymentOpion;
  const isReducedVatRatePossible = isItemAllowsReducedVatRate && isClientVatIdDefined && isInvoicePaymentAvailable;

  const [netValue, setNetValue] = useState(0);
  const [vatValue, setVatValue] = useState(0);
  const [grossValue, setGrossValue] = useState(0);

  useEffect(() => {
    if (typeof net !== 'undefined' && typeof gross !== 'undefined') {
      setGrossValue(gross);
      setNetValue(net);
      setVatValue(gross - net);
    } else {
      setGrossValue(netValue + vatValue);
      setNetValue(netValue);
      setVatValue(vatValue);
    }
  }, [net, vat, setNetValue, setVatValue, setGrossValue]);

  useEffect(() => {
    if (typeof net !== 'undefined' && typeof gross !== 'undefined') {
      const webPdfGrossPrice = grossValueFromNet(webPdfPrice, vat ?? 0);
      const webPdfVatValue = vatValueFromNet(webPdfPrice, vat ?? 0);
      const manualDataCheckGrossPrice = grossValueFromNet(manualDataCheckPrice, vat ?? 0);
      const manualDataCheckVatValue = vatValueFromNet(manualDataCheckPrice, vat ?? 0);
      setGrossValue(gross + webPdfGrossPrice + manualDataCheckGrossPrice);
      setNetValue(net + webPdfPrice + manualDataCheckPrice);
      setVatValue(gross - net + webPdfVatValue + manualDataCheckVatValue);
    } else {
      const grossPrice = gross ?? 0;
      const netPrice = netValueFromGross(grossPrice, vat ?? 0);
      setGrossValue(grossPrice);
      setNetValue(netPrice);
      setVatValue(grossPrice - netPrice);
    }
  }, [gross, vat, setNetValue, setVatValue, setGrossValue, webPdfPrice, manualDataCheckPrice]);
  return (
    <div className="value-added-tax" style={{ minWidth: 23 + '%' }} {...props}>
      {appliedProductGroupDiscount ? (
        <Row className="discount">
          <Col xs={6} title={messages.discount}>
            {messages.discount}
          </Col>
          <Col xs={6} className="text-right">
            <FormattedNumber value={-appliedProductGroupDiscount} currency={currency} style="currency" />
          </Col>
        </Row>
      ) : null}
      <Row className="net">
        <Col xs={6} title={messages.net}>
          {messages.net}
        </Col>
        <Col xs={6} className="text-right">
          <FormattedNumber value={netValue} currency={currency} style="currency" />
        </Col>
      </Row>
      <Row className="vat">
        <Col xs={6} title={`${messages.vat_short} (${vat}%)`}>
          {messages.vat_short}&nbsp;({vat}%) {isReducedVatRatePossible && <sup>*</sup>}
        </Col>
        <Col xs={6} className="text-right">
          <FormattedNumber value={vatValue} currency={currency} style="currency" />
        </Col>
      </Row>
      <Row className="gross">
        <Col xs={6} title={messages.gross}>
          {messages.gross}
        </Col>
        <Col xs={6} className="text-right">
          <FormattedNumber value={grossValue} currency={currency} style="currency" />
        </Col>
      </Row>
      {isReducedVatRatePossible && (
        <div>
          <sup>*</sup> {messages.reduced_vat_rate.cart_item_hint}
        </div>
      )}
    </div>
  );
}

export default ValueAddedTax;
