import {
  formBlocked,
  formViewed,
  BLOCK_FORM,
  FORM_BLOCKED,
  FORM_VIEWED,
  RELEASE_FORM,
  REQUEST_FORM_RELEASE,
  REQUEST_TAB_SWITCH,
  ACCEPT_RELEASE_REQUEST,
  REJECT_RELEASE_REQUEST,
} from 'actions/ActiveFormsActions';
import { socketEmit, SOCKET_EVENT } from 'actions/SocketActions';

const initialState = {
  formType: null,
  formId: null,
  allowEdit: true,
  blockedBy: null,
  viewers: [],
  pending: false,
  requestFormReleasePending: false,
  userRequestingFormRelease: null,
};

export default function activeForms(state = initialState, action) {
  switch (action.type) {
    case BLOCK_FORM: {
      const { formType, formId } = action.payload;

      return {
        ...state,
        formType,
        formId,
        pending: true,
      };
    }

    case FORM_BLOCKED: {
      const { viewers, blockedBy } = action.payload;

      return {
        ...state,
        allowEdit: true,
        pending: false,
        viewers,
        blockedBy,
      };
    }

    case FORM_VIEWED: {
      const { viewers, blockedBy } = action.payload;

      return {
        ...state,
        allowEdit: false,
        pending: false,
        viewers,
        blockedBy,
      };
    }

    case RELEASE_FORM: {
      return {
        ...initialState,
      };
    }

    case REQUEST_FORM_RELEASE: {
      const { userRequestingFormRelease } = action.payload;

      return {
        ...state,
        userRequestingFormRelease,
        requestFormReleasePending: true,
      };
    }

    case SOCKET_EVENT: {
      const { eventName, args } = action.payload;

      switch (eventName) {
        // who is currently viewing the form?
        case 'form_viewed': {
          return {
            ...state,
            viewers: args,
          };
        }

        // the form can not be edited anymore
        case 'block_form': {
          return {
            ...state,
            allowEdit: false,
            viewers: args,
          };
        }

        case 'form_released': {
          const { successfullyBlocked, leaveEditFlag, blockedBy, viewers } = args;

          return {
            ...state,
            allowEdit: leaveEditFlag === true || !!successfullyBlocked,
            viewers,
            blockedBy,
          };
        }

        case 'requested_form_release': {
          const { userRequestingFormRelease, blockedBy, viewers } = args;

          return {
            ...state,
            viewers,
            blockedBy,
            requestFormReleasePending: true,
            userRequestingFormRelease,
          };
        }

        case 'accepted_form_release': {
          const { successfullyAcceptedRelease, blockedBy, viewers } = args;

          return {
            ...state,
            viewers,
            allowEdit: !!successfullyAcceptedRelease,
            blockedBy,
            requestFormReleasePending: false,
            userRequestingFormRelease: null,
          };
        }

        case 'rejected_form_release': {
          const { successfullyRejectedRelease, blockedBy, viewers } = args;

          return {
            ...state,
            viewers,
            blockedBy,
            requestFormReleasePending: false,
            userRequestingFormRelease: null,
          };
        }
      }
    }
  }

  return state;
}

export const activeFormsMiddleware = store => {
  const { dispatch } = store;

  return next => action => {
    switch (action.type) {
      case BLOCK_FORM: {
        const { formType, formId } = action.payload;

        dispatch(
          socketEmit(
            'block_form',
            {
              formType,
              formId,
            },
            ({ successfullyBlocked, blockedBy, viewers }) => {
              if (successfullyBlocked) {
                dispatch(formBlocked(blockedBy, viewers));
              } else {
                dispatch(formViewed(blockedBy, viewers));
              }
            }
          )
        );
        break;
      }

      case RELEASE_FORM: {
        const { formType, formId } = action.payload;

        dispatch(
          socketEmit('release_form', {
            formType,
            formId,
          })
        );
        break;
      }

      case REQUEST_FORM_RELEASE: {
        const { formType, formId } = action.payload;

        dispatch(
          socketEmit('request_form_release', {
            formType,
            formId,
          })
        );

        return next({
          ...action,
          payload: { ...action.payload, userRequestingFormRelease: store.getState().login.user.username },
        });
      }

      case REQUEST_TAB_SWITCH: {
        const { formType, formId } = action.payload;

        dispatch(
          socketEmit(
            'request_tab_switch',
            {
              formType,
              formId,
            },
            ({ successfullyBlocked, blockedBy, viewers }) => {
              if (successfullyBlocked) {
                dispatch(formBlocked(blockedBy, viewers));
              }
            }
          )
        );
        break;
      }

      case ACCEPT_RELEASE_REQUEST: {
        const { formType, formId } = action.payload;

        dispatch(
          socketEmit('accept_form_release', {
            formType,
            formId,
          })
        );

        break;
      }

      case REJECT_RELEASE_REQUEST: {
        const { formType, formId } = action.payload;

        dispatch(
          socketEmit('reject_form_release', {
            formType,
            formId,
          })
        );
      }
    }

    return next(action);
  };
};
