import DetectRTC from 'detectrtc';
import { getLogger } from '@chedri/base';

const logger = getLogger('webapp-init');

global.isWebApplication = true;
logger.debug('Initializing DetectRTC...');
const detectRtcLoadedResolve = [];
// Create a Promise to watch for loading state of DetectRTC
DetectRTC.isLoaded = () =>
  new Promise((resolve, reject) => {
    // Keep the resolver to call it if DetectRTC is loaded.
    detectRtcLoadedResolve.push({ resolve, reject });
  });
DetectRTC.load(() => {
  logger.debug(
    'DetectRTC is initialized',
    '- Is web sockets supported',
    DetectRTC.isWebSocketsSupported,
    '- Is web sockets blocked',
    DetectRTC.isWebSocketsBlocked,
    '- Is WebRTC supported',
    DetectRTC.isWebRTCSupported,
    '- Has microphone',
    DetectRTC.hasMicrophone,
    '- Has speakers',
    DetectRTC.hasSpeakers,
    '- Has webcam',
    DetectRTC.hasWebcam,
    '- Has microphone permission',
    DetectRTC.isWebsiteHasMicrophonePermissions,
    '- Has webcam permission',
    DetectRTC.isWebsiteHasWebcamPermissions
  );
  let tries = 0;
  const checkForRegistration = () => {
    if (detectRtcLoadedResolve.length > 0) {
      // Replace the resolver with a just true resolver
      // - No more handling needed
      DetectRTC.isLoaded = () => Promise.resolve(true);
      // Resolve that DetectRTC is loaded
      detectRtcLoadedResolve.forEach(({ resolve, reject }) => {
        try {
          resolve(true);
        } catch (err) {
          logger.error('Failed to resolve DetectRTC.isLoaded', err);
          reject(err);
        }
      });
    } else if (tries < 10) {
      tries++;
      // Recheck loop for late registrations
      setTimeout(checkForRegistration, 50);
    } else {
      // Gracefully leave loop
      logger.info('No registration available for DetectRTC.isLoaded');
    }
  };
  checkForRegistration();
});
