import React from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { forEach as _forEach } from 'lodash';

class FormFile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValue: props.value ? props.value : '',
      value: props.value ? props.value : '',
      focused: false,
    };

    this.inputRef = React.createRef();
  }

  checkFocused = () => {
    return this.state.focused ? 'focused ' : '';
  };

  checkRequired = () => {
    return this.props.meta.validate.rules.indexOf('required') !== -1 ? 'required ' : '';
  };

  checkError = () => {
    return this.props.validation.valid ? '' : 'has-error ';
  };

  // check if user may read this field
  checkAccess = () => {
    return this.props.meta.acl.read;
  };

  // check if user may write to this field
  checkReadOnly = () => {
    return !this.props.meta.acl.write;
  };

  catchFocus = () => {
    // this.setState({ focused: true })
  };

  onChange = files => {
    this.setState({ value: files });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.onChange(this.props.name, files);
    }, 150);
  };

  checkDisabled = () => {
    const { disabled, meta } = this.props;
    if (disabled) {
      return true;
    }
    if (!meta.acl.write) {
      return true;
    }
    return false;
  };

  setValue = value => {};

  render() {
    const { label, name, validation, allowTypes } = this.props;
    const { value } = this.state;

    const filesList = [];
    _forEach(value, (val, key) => {
      if (key !== 'length') {
        filesList.push(val.name);
      }
    });

    return (
      <div
        class={
          'form-group form-group-default ' +
          this.checkFocused() +
          this.checkRequired() +
          this.checkError() +
          (this.checkDisabled() ? 'disabled ' : '')
        }
        onClick={this.catchFocus}
      >
        <label>{label}</label>
        <input
          ref={this.inputRef}
          type="file"
          class="form-control"
          name={name}
          onChange={e => this.onChange(e.target.files)}
          onFocus={() => this.setState({ focused: true })}
          onBlur={() => this.setState({ focused: false })}
          disabled={this.checkDisabled()}
          accept={allowTypes}
        />
        <input
          type="text"
          class="form-control"
          onClick={() => this.inputRef.current.click()}
          readOnly
          value={filesList.join(', ')}
          style={{ display: 'none' }}
        />

        <Overlay show={this.state.focused && !validation.valid} target={this.inputRef.current} placement="top">
          <Popover class="popover-modal">{validation.helpText}</Popover>
        </Overlay>
      </div>
    );
  }
}
FormFile.defaultProps = {
  label: '',
  meta: { validate: { rules: '' }, acl: { read: true, write: true } },
  validation: { valid: true },
  value: '',
  onChange: () => {},
  disabled: false,
  type: 'file',
  allowTypes: '',
};

export default FormFile;
