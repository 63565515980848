export default function docRequiredNumber(entity) {
  let isValid = true,
    helpTextTranslationIndex = 'doc_number_required',
    fields = [];

  if (!entity.getNumber()) {
    isValid = false;
    fields = [{ name: 'number', fieldTree: [] }];
  }

  return {
    valid: isValid,
    rule: 'docRequiredNumber',
    helpTextTranslationIndex,
    fields,
  };
}
