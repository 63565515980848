import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Clearfix, Button } from 'react-bootstrap';
import { animateScroll as scroll } from 'react-scroll';

import { resetCheckout, initCheckout } from 'actions/CheckoutActions';
import { isPluginActive } from 'helpers/whiteLabel';
import { userDataForCheckout } from 'helpers/clients';
import { getCarRentalPath, getCarRentalSearchPath } from 'helpers/intl';

import { AUTOMOTIVE_SALES_PLUGIN, AUTOMOTIVE_RENT_PLUGIN } from 'js/constants';

class StickyNavigation extends React.Component {
  scrollToTop = e => {
    e.preventDefault();
    scroll.scrollToTop();
  };

  proceedToCheckout = () => {
    const { data, doInitCheckout, user } = this.props;
    doInitCheckout([{ ...data, price: data.pricing.price, quantity: 1 }], userDataForCheckout(user));
  };

  showBuyInquiry = () => {
    const { doShowBuyInquiry } = this.props;
    doShowBuyInquiry();
  };

  showTestDriveInquiry = e => {
    e.preventDefault();

    const { doShowTestDriveInquiry } = this.props;
    doShowTestDriveInquiry();
  };

  showNewPriceNegotiation = e => {
    e.preventDefault();

    const { doShowNewPriceNegotiation } = this.props;
    doShowNewPriceNegotiation();
  };

  render() {
    const {
      messages,
      layout,
      projectConfig: { data: projectConfigData },
      locationData,
      visible,
    } = this.props;

    const chedriSalesActive = isPluginActive(AUTOMOTIVE_SALES_PLUGIN, projectConfigData);
    const chedriRentActive = isPluginActive(AUTOMOTIVE_RENT_PLUGIN, projectConfigData);

    let rentHref = getCarRentalPath();
    // check the cars location
    // if it supports car rental, get the default dates and build the url
    if (!locationData.pending && locationData.data.is_rental_point && locationData.data.white_label_location_id) {
      rentHref = getCarRentalSearchPath(locationData.data.white_label_location_id, locationData.data.name);
    }

    const transformIconStyle = { style: { transform: 'scale(2)' } };

    const visibleClass = visible ? 'visible ' : '';

    return (
      <div className={'item-details-sticky-nav-container ' + visibleClass}>
        <div className="item-details-sticky-nav">
          <Clearfix>
            <div className={'m-l-20 sm-m-l-0 ' + (layout.breakpointIndex < 3 ? 'inline ' : 'pull-left ')}>
              <a
                href="#scrollToTop"
                className="b-a b-b padding-5 inline b-rad-xl scroll-to-top text-black"
                onClick={this.scrollToTop}
              >
                <i className="far fa-angle-double-up fa-fw" />
              </a>
            </div>

            {chedriSalesActive && (
              <div className="pull-left hidden-xs hidden-sm hidden-md m-l-35 m-t-5">
                <a
                  href="#sendPriceNegotiation"
                  className="text-primary padding-5 inline"
                  onClick={this.showNewPriceNegotiation}
                >
                  <i className="chedri-deal m-r-20 inline" {...transformIconStyle} /> {messages.send_price_proposal}
                </a>
              </div>
            )}

            {chedriRentActive && (
              <div className="pull-left hidden-xs hidden-sm hidden-md m-l-35 m-t-5">
                <Link to={rentHref} className="text-primary padding-5 inline">
                  <i className="chedri-rent-a-car m-r-20 inline" {...transformIconStyle} /> {messages.rent_a_car}
                </Link>
              </div>
            )}

            {/* <div className="pull-left hidden-xs hidden-sm hidden-md m-l-35 m-t-5">
              <a href="#carCreditCalculator" className="text-primary padding-5 inline">
                <i className="fal fa-file-invoice-dollar m-r-20 inline" {...transformIconStyle} /> Zum Finanzierungsrechner
              </a>
            </div> */}

            <div className={'m-r-20 sm-m-r-0 ' + (layout.breakpointIndex < 3 ? 'inline m-l-25 ' : 'pull-right ')}>
              {chedriSalesActive ? (
                <Button bsStyle="primary" className="btn-priority btn-rounded" onClick={this.proceedToCheckout}>
                  {messages.buy_now}
                </Button>
              ) : (
                <Button bsStyle="primary" className="btn-priority btn-rounded" onClick={this.showBuyInquiry}>
                  {messages.inquire}
                </Button>
              )}
            </div>
          </Clearfix>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    doInitCheckout: (items, userData) => {
      dispatch(resetCheckout(initCheckout(items, userData)));
    },
  };
};

export default connect(
  null,
  mapDispatchToProps
)(StickyNavigation);
