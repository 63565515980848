import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Clearfix, Row, Col, Form, Button, Overlay, Alert } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';

import { elementSource, elementTarget } from './elementDragUtils';
import { equipmentLevels } from 'helpers/items';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

import CarNarrowSearchForm from 'components/layout/search/CarNarrowSearchForm';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
export default class CmsElementCommerceCarSearch extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  onSubmit = e => {
    e.preventDefault();
    e.stopPropagation(); // if we are editing a page, we have to make sure the parent form will not be submitted
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  printAttribute = obj => {
    return (
      <div class={'filter ' + (obj.hovered ? 'hovered ' : '') + (obj.selected ? 'selected ' : '')} key={obj.name}>
        <label title={obj.name} for={obj.name}>
          <div class="body">
            <div class="icon">
              {obj.src ? <img src={obj.src} /> : null}
              {obj.icon ? obj.icon : null}
            </div>

            <div class="text overflow-ellipsis">{obj.name}</div>

            <input
              id={obj.name}
              name={obj.name + '[]'}
              type="checkbox"
              checked={obj.selected}
              value={true}
              style={{ visibility: 'hidden', position: 'absolute' }}
            />
          </div>
        </label>
      </div>
    );
  };

  printCheckbox = obj => {
    return (
      <div
        class={
          'filter ' +
          (obj.hovered ? 'hovered ' : '') +
          (obj.selected ? 'selected ' : '') +
          ' ' +
          (obj.className ? obj.className : '')
        }
        key={obj.name}
      >
        <label for={obj.checkboxId}>
          <div class="body">
            <div class="text overflow-ellipsis">{obj.name}</div>

            <div class="p-b-15 relative">
              <div
                class="checkbox check-complete m-t-0"
                key={'checkbox' + obj.checkboxId}
                style={{
                  marginLeft: '50%',
                  transform: 'translateX(-45%)',
                }}
              >
                <input id={obj.checkboxId} name={obj.name + '[]'} type="checkbox" value={true} checked={obj.selected} />
                <label for={obj.checkboxId}>{'\u00A0'}</label> {/* need this label in order to render the checkbox */}
              </div>
            </div>

            {obj.description ? obj.description : null}
          </div>
        </label>
      </div>
    );
  };

  render() {
    const {
      intl: { messages },
      connectDropTarget,
      connectDragSource,
      connectDragPreview,
    } = this.props;
    const { shown } = this.state;

    const carTypes = [
      {
        name: 'Convertible',
        icon: <i class="chedri-cabrio-car chedri-3x" />,
        checkboxId: 'carType1',
        hovered: true,
        selected: true,
      },
      {
        name: 'Sedan',
        icon: <i class="chedri-sedan-car-model chedri-3x" />,
        checkboxId: 'carType2',
        hovered: false,
        selected: true,
      },
      {
        name: 'Saloon',
        icon: <i class="chedri-car-suv chedri-3x" />,
        checkboxId: 'carType3',
        hovered: false,
        selected: false,
      },
      {
        name: 'SUV/Pickup',
        icon: <i class="chedri-jeep chedri-3x" />,
        checkboxId: 'carType4',
        hovered: false,
        selected: false,
      },
      {
        name: 'Small',
        icon: <i class="chedri-car-city-model chedri-3x" />,
        checkboxId: 'carType5',
        hovered: false,
        selected: false,
      },
      {
        name: 'Sports',
        icon: <i class="chedri-sportive-car chedri-3x" />,
        checkboxId: 'carType6',
        hovered: false,
        selected: false,
      },
      {
        name: 'Minivan',
        icon: <i class="chedri-minivan-car chedri-3x" />,
        checkboxId: 'carType7',
        hovered: false,
        selected: false,
      },
    ];
    const brands = [
      {
        name: 'Mercedes-Benz',
        src: '/img/brands/mercedes_grey_small.png',
        checkboxId: 'brand1',
        hovered: true,
        selected: true,
      },
      { name: 'Smart', src: '/img/brands/smart_grey_small.png', checkboxId: 'brand2', hovered: false, selected: true },
      { name: 'BMW', src: '/img/brands/bmw_grey_small.png', checkboxId: 'brand3', hovered: false, selected: false },
    ];
    const fuelTypes = [
      { name: 'Petrol', checkboxId: 'fuelType1', hovered: true, selected: true },
      { name: 'Diesel', checkboxId: 'fuelType2', hovered: false, selected: false },
    ];
    const gearboxes = [
      { name: 'Automatic', checkboxId: 'gearbox1', hovered: true, selected: true },
      { name: 'Manual', checkboxId: 'gearbox2', hovered: false, selected: false },
    ];
    const performances = [
      { name: 'more than 100 PS', checkboxId: 'performance1', hovered: true, selected: true },
      { name: '100-150 PS', checkboxId: 'performance2', hovered: false, selected: true },
      { name: '150-200 PS', checkboxId: 'performance3', hovered: false, selected: false },
      { name: '200+ PS', checkboxId: 'performance4', hovered: false, selected: false },
    ];
    const equipments = equipmentLevels(messages);

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const textClass = 'text-' + this.getParamValue('mainColor', 'master') + ' ';
    const layoutVersion = this.getParamValue('layoutVersion', 'detailed');

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-ecommerce-car-search-content ' + shownClass + textClass + this.getCssClasses()}
        onClick={this.showSettings}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <Form onSubmit={this.onSubmit}>
          {layoutVersion === 'narrow' ? (
            <div>
              <CarNarrowSearchForm />
            </div>
          ) : null}

          {layoutVersion === 'detailed' ? (
            <div>
              <div class="p-b-70">
                <Row class="m-b-30">
                  <Col xs={12} class="text-center">
                    <h2>Finden Sie das perfekte Fahrzeug</h2>
                    <h4 class="m-b-0">Bauform auswählen</h4>
                    <div class="m-b-10 small hint-text">(Mehrfachauswahl möglich)</div>
                  </Col>
                </Row>

                <Row class="m-t-20 m-b-30 car-search m-l-100 m-r-100 sm-m-l-0 sm-m-r-0">
                  {carTypes.map((carType, i) => {
                    return this.printAttribute(carType);
                  })}
                </Row>

                <hr />

                <Row>
                  <Col xs={12} class="text-center">
                    <h4 class="m-b-0">Marke auswählen</h4>
                    <div class="m-b-10 small hint-text">(Mehrfachauswahl möglich)</div>
                  </Col>
                </Row>

                <Row class="m-t-20 m-b-30 car-search m-l-100 m-r-100 sm-m-l-0 sm-m-r-0">
                  {brands.map((brand, i) => {
                    return this.printAttribute(brand);
                  })}
                </Row>

                <hr />

                <Row class="m-b-30">
                  <Col xs={12} md={6} lg={6}>
                    <h4 class="m-b-0 text-center">Kraftstoff</h4>
                    <div class="m-b-10 small hint-text text-center">(Mehrfachauswahl möglich)</div>

                    <div class="car-search">
                      {fuelTypes.map((fuelType, i) => {
                        return this.printCheckbox(fuelType);
                      })}
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <h4 class="m-b-0 text-center">Motorisierung</h4>
                    <div class="m-b-10 small hint-text text-center">(Mehrfachauswahl möglich)</div>

                    <div class="car-search">
                      {performances.map((performance, i) => {
                        return this.printCheckbox(performance);
                      })}
                    </div>
                  </Col>
                </Row>

                <hr />

                <Row class="m-b-30">
                  <Col xs={12}>
                    <h4 class="m-b-0 text-center">{messages.car_gear}</h4>
                    <div class="m-b-10 small hint-text text-center">(Mehrfachauswahl möglich)</div>

                    <div class="car-search">
                      {gearboxes.map((gearbox, i) => {
                        return this.printCheckbox(gearbox);
                      })}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <h4 class="m-b-0 text-center">Ausstattungslevel wählen</h4>
                    <div class="m-b-10 small hint-text text-center">(Mehrfachauswahl möglich)</div>

                    <div class="car-search m-l-100 m-r-100 sm-m-l-0 sm-m-r-0">
                      {equipments.map((equipment, i) => {
                        return this.printCheckbox(equipment);
                      })}
                    </div>
                  </Col>
                </Row>
              </div>

              <div
                class="padding-20 bg-white b-t"
                style={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1010 }}
              >
                <Button block bsStyle="primary" bsSize="lg" style={{ borderRadius: 0 }} type="submit">
                  <FormattedMessage id="count_cars" values={{ count: 55 }} />
                </Button>
              </div>
            </div>
          ) : null}
        </Form>

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}
