import _, { forEach as _forEach, findIndex as _findIndex } from 'lodash';

const initialState = {
  archivedCampaigns: [],
  archivedEditions: [],
  pending: false,
  parentIsMain2: false,
  pendingRestoreC: false,
  restore: false,
  pendingDeleteC: false,
  errorMessage: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'CE_ARCHIVE_PENDING': {
      return { ...state, pending: true };
    }
    case 'CE_ARCHIVE_FULFILLED': {
      let { archivedCampaigns, archivedEditions } = state;

      archivedCampaigns = payload.archivedCampaigns;
      archivedEditions = payload.archivedEditions;
      return { ...state, archivedCampaigns, archivedEditions, parentIsMain2: true, pending: false };
    }
    case 'E_ARCHIVE_PENDING': {
      return { ...state, pending: true };
    }
    case 'E_ARCHIVE_FULFILLED': {
      const { archivedEditions } = state;
      let aEditionsConcat = archivedEditions;
      if (payload.archivedEditions && payload.archivedEditions.length !== 0) {
        aEditionsConcat = archivedEditions.concat(payload.archivedEditions);
      }

      return { ...state, archivedEditions: aEditionsConcat, pending: false, parentIsMain2: false };
    }
    case 'RESTORE_ARCHIVED_CAMPAIGN_PENDING': {
      return { ...state, pendingRestoreC: true };
    }
    case 'RESTORE_ARCHIVED_CAMPAIGN_FULFILLED': {
      let { archivedCampaigns, archivedEditions } = state;
      const campaignId = payload;
      archivedCampaigns = _.filter(archivedCampaigns, x => x.id !== campaignId);
      archivedEditions = _.filter(archivedEditions, x => x.campaign.id !== campaignId);
      return { ...state, archivedCampaigns, archivedEditions, restore: true, pendingRestoreC: false };
    }

    case 'DELETE_CAMPAIGN_TO_TRASH_PENDING': {
      return { ...state, pendingDeleteC: true };
    }
    case 'DELETE_CAMPAIGN_TO_TRASH_FULFILLED': {
      let { archivedCampaigns, archivedEditions } = state;
      const deletedId = payload;
      archivedCampaigns = _.filter(archivedCampaigns, x => x.id !== deletedId);
      archivedEditions = _.filter(archivedEditions, x => x.campaign.id !== deletedId);
      return { ...state, archivedCampaigns, archivedEditions, pendingDeleteC: false };
    }
  }
  return state;
};
