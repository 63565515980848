import React from 'react';
import { components } from 'react-select';

class DynamicDataMetaIdentifierOption extends React.Component {
  render() {
    const { data } = this.props;
    return <components.Option {...this.props}>{data.label}</components.Option>;
  }
}

class DynamicDataMetaIdentifierValue extends React.Component {
  render() {
    const { data } = this.props;

    return <div className="Select-value">{data.label}</div>;
  }
}

const dynamicDataMetaIdentifierOptionFormatter = options => {
  return options.list.map(option => {
    return dynamicDataMetaIdentifierSingleOptionFormatter(option);
  });
};

const dynamicDataMetaIdentifierSingleOptionFormatter = (option = null) => {
  if (option) {
    return { value: option, label: option };
  }

  return null;
};

export {
  DynamicDataMetaIdentifierValue,
  DynamicDataMetaIdentifierOption,
  dynamicDataMetaIdentifierOptionFormatter,
  dynamicDataMetaIdentifierSingleOptionFormatter,
};
