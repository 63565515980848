import React from 'react';
import _, { find as _find, forEach as _forEach, cloneDeep as _cloneDeep } from 'lodash';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import FormAutocomplete from '../../../components/forms/FormAutocomplete';
import FormInput from '../../../components/forms/FormInput';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import FormTextarea from '../../../components/forms/FormTextarea';


const ContextModal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('context-modal'));
};

class ContactFormElementNew extends React.Component {
  constructor(props) {
    super(props);
    const { intl: { messages }} = this.props;
    this.state = {
      showContactFormOptions: false,
      formElements: [],
      formElementsEdit: [],
      options: [
        ['Anrede',messages.editor.salutation],
        ['Titel',messages.editor.title],
        ['Vorname',messages.editor.first_name],
        ['Name', messages.editor.name],
        ['Email', messages.editor.email],
        ['Telefon', messages.editor.phone],
        ['Betreff', messages.editor.subject],
        ['Nachricht', messages.editor.message],
        ['benutzerdefiniertes Dropdown', messages.editor.custom_dropdown],
        ['benutzerdefiniertes Feld', messages.editor.custom_field],
        ['checkBox', messages.custom_checkbox],
        ],
      dropdownOptions: [],
      dropdownLabel: messages.editor.custom_label,
      customLabel: messages.editor.custom_label,
      showCustomLabelOptions: false,
      checkBoxLabel: messages.custom_checkbox,
      showCheckBoxLabelOptions: false,
      checkBoxLink: "",
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.params[0] && prevProps.params[0]?.value !== this.props.params[0]?.value) {
      this.getContactElements();
    }
  };

  componentDidMount = () => {
    this.getContactElements();
  };

  showImagesEdit = () => {
    this.setState({ showImagesEdit: true });
  };

  closeImagesEdit = () => {
    this.setState({ showImagesEdit: false });
  };

  ContactFormOptions = () => {
    this.setState({ showContactFormOptions: true });
  };

  handleIEclose = () => {
    this.setState({ showContactFormOptions: false });
  };

  addContactElement = value => {
    const { formElementsEdit, options } = this.state;
    const formElements_CLONE = _cloneDeep(formElementsEdit);

    //const _formElements = [value];
    formElements_CLONE.push(value);
    let optionsCut = [];

    _forEach(options, (option, i) => {
      if(JSON.stringify(option)!==JSON.stringify(value)){
        optionsCut.push(option);
      }
    });
    this.setState(
      {
        formElementsEdit: formElements_CLONE,
        options: optionsCut
      },
      () => {}
    );
  };

  setContactElements = () => {
    const { formElementsEdit } = this.state;
    const { id, attributes, updateParam } = this.props;
    let param = '';
    _forEach(formElementsEdit, (elements, i) => {
      param += elements[0] + '#aaa#' + elements[1];
      if (i < formElementsEdit.length - 1) {
        param += '#cc#';
      }
    });

    const { dropdownOptions, dropdownLabel, checkBoxLink } = this.state;

    let paramsCustomDropdown = "";
    if(dropdownOptions && dropdownOptions.length > 0){
      _forEach(dropdownOptions, (element, i) => {
        paramsCustomDropdown += element
        if (i < dropdownOptions.length - 1) {
          paramsCustomDropdown += '#cc#';
        }
      });
    }
    param = param + '#abc#' + paramsCustomDropdown;

    if (checkBoxLink) {
      param = param + '#cbl#' + checkBoxLink;
    }

    const overflow = false;
    const default_image = false;
    const errors = [{ overflow, default_image }];
    updateParam(id, attributes['data-name'], param, errors);
    this.setState({ formElements: formElementsEdit }, () => {
      this.handleIEclose();
    });
  };

  getContactElements = () => {
    const { name: Tag, attributes, params } = this.props;
    const { intl: { messages }} = this.props;
    let { options } = this.state;
    const param = _find(params, ['name', attributes['data-name']]);
    const elements = [];
    let outputDropdownFields = [];
    let outputDropdownLabel = [messages.editor.set_label_dropdown];
    let outputCustomLabel = [messages.editor.set_label_custom_field];
    let outputCheckBoxLabel = [messages.set_label_checkbox];
    let outputCheckBoxLink = "";
    if (param) {
      let data = param.value.split('#abc#');
      let output = data[0].split("#cc#");
      let divFieldOptions = data[1].split("#cbl#");


      _forEach(output, (entry, i) => {
        const formElement = entry.split('#aaa#');
        if (formElement?.[0]) {
          elements.push(formElement);
        }
      });

      _forEach(options, (option, i) => {
        _forEach(elements, (element, a) => {
          if (JSON.stringify(option) === JSON.stringify(element)) {
            options = options.filter(function(e) {
              return JSON.stringify(e) !== JSON.stringify(element)
            })
          }

        });
      });

        outputDropdownFields = divFieldOptions[0].split("#cc#");
        outputCheckBoxLink = divFieldOptions[1];
        const indexDL = _.findIndex(elements, element => element[0] === "benutzerdefiniertes Dropdown");
        const indexCL = _.findIndex(elements, element => element[0] === "benutzerdefiniertes Feld");
        const indexCBL = _.findIndex(elements, element => element[0] === "checkBox");
        outputDropdownLabel =  indexDL > 0 && elements[indexDL][1];
        outputCustomLabel = indexCL > 0 && elements[indexCL][1];
        outputCheckBoxLabel = indexCBL > 0 && elements[indexCBL][1];

    }

    this.setState({ formElements: elements, formElementsEdit: elements, options: options, dropdownOptions: outputDropdownFields,  dropdownLabel:outputDropdownLabel, customLabel:outputCustomLabel, checkBoxLabel: outputCheckBoxLabel, checkBoxLink: outputCheckBoxLink }, () => {});
  };

  handleDeleteInput = index => {
    const { formElementsEdit, options } = this.state;
    const INPUT_CLONE = _cloneDeep(formElementsEdit);
    const removedInput = INPUT_CLONE.splice(index, 1);

    options.push(removedInput[0]);
    this.setState({ formElementsEdit: INPUT_CLONE, options });
  };

  handleAddOption = () => {
    const { intl: { messages }} = this.props;
    let newOption = this.state.dropdownOptions;
    let fieldName = messages.editor.empty_field;
    newOption.push(fieldName)
    this.setState({dropdownOptions: newOption})
  }

  changeOptions = (name, value, i) => {
    const _dropdownOptions = this.state.dropdownOptions;
    _dropdownOptions[i] = value;
    this.setState({dropdownOptions: _dropdownOptions})

  }

  changeLabel = (name, value) =>{
    const index = _.findIndex(this.state.formElementsEdit, element => element[0] === "benutzerdefiniertes Dropdown");
    let _formElementsEdit = this.state.formElementsEdit;
    _formElementsEdit[index][1] = value;
    this.setState({dropdownLabel: value, formElementsEdit: _formElementsEdit})
  }

  changeLabelOfCustomField = (name, value) =>{
    const index = _.findIndex(this.state.formElementsEdit, element => element[0] === "benutzerdefiniertes Feld");
    let _formElementsEdit = this.state.formElementsEdit;
    _formElementsEdit[index][1] = value;
    this.setState({customLabel: value, formElementsEdit: _formElementsEdit})
  }

  changeLabelOfCheckBox = (name, value) =>{
    const index = _.findIndex(this.state.formElementsEdit, element => element[0] === "checkBox");
    let _formElementsEdit = this.state.formElementsEdit;
    _formElementsEdit[index][1] = value;
    this.setState({checkBoxLabel: value, formElementsEdit: _formElementsEdit})
  }

  changeLinkOfCheckBox = (name, value) => {
    this.setState({ checkBoxLink: value });
  };

  showLabelOptions = (name, value) =>{
    this.setState({showCustomLabelOptions: true})
  }

  showCheckBoxLabelOptions = (name, value) =>{
    this.setState({showCheckBoxLabelOptions: true})
  }


  handleDeleteOption = index => {
    const _dropdownOptions = this.state.dropdownOptions;
    _dropdownOptions.splice(index, 1);
    this.setState({ dropdownOptions: _dropdownOptions});
  };

  setRequiredField = (index) => {
    const { formElementsEdit, options } = this.state;
    let _formElementsEdit = _cloneDeep(formElementsEdit);
    _formElementsEdit[index][1] =  _formElementsEdit[index][1] + "*";

    this.setState({ formElementsEdit: _formElementsEdit});
  };

  resetRequiredField = (index) => {
    const { formElementsEdit, options } = this.state;
    let _formElementsEdit = _cloneDeep(formElementsEdit);
    let formElementReset = _formElementsEdit[index][1].split('*');
    _formElementsEdit[index][1] = formElementReset[0];

    this.setState({ formElementsEdit: _formElementsEdit});
  };

  formatText = (text, link) => {
    const match = text.match(/\$(.*?)\$/);
    if (match && match[1]) {
      const replacedText = text.replace(/\$(.*?)\$/, `<a href="${link}" target="_blank" class="custom-checkbox">${match[1]}</a>`);
      return <span dangerouslySetInnerHTML={{ __html: replacedText }} />;
    }
    return text;
  };

  render() {
    const { name: Tag, attributes, disabled } = this.props;
    const { showContactFormOptions, formElements, formElementsEdit, options } = this.state;
    const output = [];
    const {
      intl: { messages },
    } = this.props;

    const tooltip = (
        <Tooltip>
          {messages.mandatory_field}
        </Tooltip>
    )

    const mandatoryButtonStyle = {
      position: 'absolute',
      top: '0',
      right: '35px',
      padding: '0',
      height: '30px',
      margin: '3px',
      paddingRight: '10px',
      paddingLeft: '10px',
    }

    formElements.map((inputElement, index) => {
      if (inputElement[0] === 'Nachricht') {
        output.push(
          <div className={inputElement[0]} key={index}>
            <FormTextarea name={inputElement[0]} label={inputElement[1]} value="" onChange="" placeholder="" />
          </div>
        );
      }
      else if(inputElement[0] === 'benutzerdefiniertes Dropdown') {
        output.push(
          <div className={inputElement[0]} key={index}>
            <FormAutocomplete
              onChange={()=>{}}
              value=""
              label={this.state.dropdownLabel}
              name="Status"
              options={this.state.dropdownOptions.map( option => {
                return { label: option, value: option };
              })}
            />
          </div>
        );
      }
      else if(inputElement[0] === 'benutzerdefiniertes Feld') {
        output.push(
        <div className={inputElement[0]} key={index}>
          <FormInput
            type="text"
            label={this.state.customLabel}
            name="customField"
            onChange=""
            value="" />
        </div>
        );
      }
      else if(inputElement[0] === 'checkBox') {
        const formattedText = this.formatText(this.state.checkBoxLabel, this.state.checkBoxLink);
        output.push(
          <div className={inputElement[0]} key={index}>
            <p style={{ marginBottom: '10px' }}>
              <input type="checkbox" name={inputElement[0]} />
              {formattedText}
            </p>
          </div>
        );
      }

      else {
        output.push(
          <div className={inputElement[0]} key={index}>
            <FormInput type="text" label={inputElement[1]} name={inputElement[0]} onChange="" value="" />
          </div>
        );
      }
    });




    return (
      <>
        <Tag {...attributes} onClick={this.ContactFormOptions}>
          <>
            <div className="ContactCSS">
              {formElements.length > 0 ? (
                <div className="ContactCSS2">
                  <div className="ContactContentCSS">
                    <div className="formElementsCSS">
                      {!showContactFormOptions && output}
                      <div style={{ marginTop: '10px' }}>
                        <p style={{ marginBottom: '10px' }}>
                          <input type="checkbox" />
                          {messages.editor.read_privacy_policy}
                        </p>
                        <Button style={{ width: '100%' }} bsStyle="danger" bsSize="large">
                          <i style={{ fontSize: '40px' }} className="fal fa-envelope" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="wrap">
                  <p>{messages.editor.no_forms}</p>
                </div>
              )}
            </div>
          </>
        </Tag>

        {showContactFormOptions && !disabled && (
          <ContextModal>
            <div id="contextModalWrap">
              <div id="contextModalStage" className="row">
                <h3>{messages.editor.contact_form_settings}</h3>
                <div className="ContactCSS">
                  <div className="ContactCSSOptions">
                    <FormAutocomplete
                      onChange={(name, value) => {
                        this.addContactElement(value);
                      }}
                      value={messages.editor.pls_choose}
                      label={messages.editor.pls_chooseTwo}
                      name="ContactOptions"
                      options={options.map((option, i) => {
                        return { label: option[1] , value: option };
                      })}
                    />
                    <div className="ContactContentCSS">
                      <div className="formElementsCSS">
                        {formElementsEdit.map((inputElement, index) => {
                          if (inputElement[0] === 'Nachricht') {
                            return (
                              <div className={inputElement[0]} key={index}>
                                <FormTextarea
                                  name={inputElement[0]}
                                  label={inputElement[1]}
                                  value=""
                                  onChange=""
                                  placeholder=""
                                />
                                {inputElement[1]?.includes("*") ?
                                  <OverlayTrigger placement="top" overlay={tooltip}>
                                    <Button
                                      style={mandatoryButtonStyle}
                                      bsStyle="danger"
                                      bsSize="large"
                                      onClick={() => {
                                        this.resetRequiredField(index);
                                      }}
                                    >
                                      {messages.editor.mandatory_field} *
                                    </Button>
                                  </OverlayTrigger> :
                                  <OverlayTrigger placement="top" overlay={tooltip}>
                                    <Button
                                      style={mandatoryButtonStyle}
                                      bsStyle="success"
                                      bsSize="large"
                                      onClick={() => {
                                        this.setRequiredField(index);
                                      }}
                                    >
                                      {messages.editor.mandatory_field} *
                                    </Button>
                                  </OverlayTrigger>
                                }
                                <Button
                                  style={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '0',
                                    padding: '0',
                                    height: '30px',
                                    width: '30px',
                                    margin: '3px',
                                  }}
                                  bsStyle="danger"
                                  bsSize="large"
                                  onClick={() => {
                                    this.handleDeleteInput(index);
                                  }}
                                >
                                  X
                                </Button>
                              </div>
                            );
                          } else if (inputElement[0] === 'benutzerdefiniertes Dropdown') {
                            return (
                              <div className={inputElement[0]} key={index}>
                                <FormAutocomplete
                                  onChange={()=>{}}
                                  value=""
                                  label={inputElement[1]}
                                  name="dropdownLabel"
                                  options={this.state.dropdownOptions.map(option => {
                                    return { label: option, value: option };
                                  })}
                                />
                                <Button
                                  style={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '133px',
                                    padding: '0',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    height: '30px',
                                    width: 'maxContent',
                                    margin: '3px',
                                  }}
                                  bsStyle="success"
                                  bsSize="large"
                                  onClick={() => {
                                    this.handleAddOption();
                                  }}
                                >
                                  <i className="far fa-plus-circle"></i> {messages.editor.add_options}
                                </Button>
                                {inputElement[1]?.includes("*") ?
                                  <OverlayTrigger placement="top" overlay={tooltip}>
                                    <Button
                                      style={mandatoryButtonStyle}
                                      bsStyle="danger"
                                      bsSize="large"
                                      onClick={() => {
                                        this.resetRequiredField(index);
                                      }}
                                    >
                                      {messages.editor.mandatory_field} *
                                    </Button>
                                  </OverlayTrigger>:
                                  <OverlayTrigger placement="top" overlay={tooltip}>
                                    <Button
                                      style={mandatoryButtonStyle}
                                      bsStyle="success"
                                      bsSize="large"
                                      onClick={() => {
                                        this.setRequiredField(index);
                                      }}
                                    >
                                      {messages.editor.mandatory_field} *
                                    </Button>
                                  </OverlayTrigger>
                                }
                                <Button
                                  style={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '0',
                                    padding: '0',
                                    height: '30px',
                                    width: '30px',
                                    margin: '3px',
                                  }}
                                  bsStyle="danger"
                                  bsSize="large"
                                  onClick={() => {
                                    this.handleDeleteInput(index);
                                  }}
                                >
                                  X
                                </Button>
                              </div>
                            );
                          } else if (inputElement[0] === 'benutzerdefiniertes Feld') {
                            return (
                              <div className={inputElement[0]} key={index}>
                                <FormInput
                                  type="text"
                                  label={inputElement[1]}
                                  name={inputElement[0]}
                                  onChange=""
                                  value=""
                                />
                                <Button
                                  style={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '133px',
                                    padding: '0',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    height: '30px',
                                    width: 'maxContent',
                                    margin: '3px',
                                  }}
                                  bsStyle="success"
                                  bsSize="large"
                                  onClick={() => {
                                    this.showLabelOptions();
                                  }}
                                >
                                  <i className="far fa-edit"></i> {messages.editor.edit_label}
                                </Button>
                                {inputElement[1]?.includes("*") ?
                                  <OverlayTrigger placement="top" overlay={tooltip}>
                                    <Button
                                      style={mandatoryButtonStyle}
                                      bsStyle="danger"
                                      bsSize="large"
                                      onClick={() => {
                                        this.resetRequiredField(index);
                                      }}
                                    >
                                      {messages.editor.mandatory_field} *
                                    </Button>
                                  </OverlayTrigger>:
                                  <OverlayTrigger placement="top" overlay={tooltip}>
                                    <Button
                                      style={mandatoryButtonStyle}
                                      bsStyle="success"
                                      bsSize="large"
                                      onClick={() => {
                                        this.setRequiredField(index);
                                      }}
                                    >
                                      {messages.editor.mandatory_field} *
                                    </Button>
                                  </OverlayTrigger>
                                }
                                <Button
                                  style={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '0',
                                    padding: '0',
                                    height: '30px',
                                    width: '30px',
                                    margin: '3px',
                                  }}
                                  bsStyle="danger"
                                  bsSize="large"
                                  onClick={() => {
                                    this.handleDeleteInput(index);
                                  }}
                                >
                                  X
                                </Button>
                              </div>
                            );
                          } else if (inputElement[0] === 'checkBox') {
                            return (
                              <div className={inputElement[0]} key={index}>
                                  <p style={{ marginBottom: '10px' }}>
                                    <input type="checkbox" name={inputElement[0]} /> <span>{this.state.checkBoxLabel}</span>
                                  </p>
                                <Button
                                  style={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '133px',
                                    padding: '0',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    height: '30px',
                                    width: 'maxContent',
                                    margin: '3px',
                                  }}
                                  bsStyle="success"
                                  bsSize="large"
                                  onClick={() => {
                                    this.showCheckBoxLabelOptions();
                                  }}
                                >
                                  <i className="far fa-edit"></i> {messages.editor.edit_label}
                                </Button>
                                {inputElement[1]?.includes("*") ?
                                  <OverlayTrigger placement="top" overlay={tooltip}>
                                    <Button
                                      style={mandatoryButtonStyle}
                                      bsStyle="danger"
                                      bsSize="large"
                                      onClick={() => {
                                        this.resetRequiredField(index);
                                      }}
                                    >
                                      {messages.editor.mandatory_field} *
                                    </Button>
                                  </OverlayTrigger>:
                                  <OverlayTrigger placement="top" overlay={tooltip}>
                                    <Button
                                      style={mandatoryButtonStyle}
                                      bsStyle="success"
                                      bsSize="large"
                                      onClick={() => {
                                        this.setRequiredField(index);
                                      }}
                                    >
                                      {messages.editor.mandatory_field} *
                                    </Button>
                                  </OverlayTrigger>
                                }
                                <Button
                                  style={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '0',
                                    padding: '0',
                                    height: '30px',
                                    width: '30px',
                                    margin: '3px',
                                  }}
                                  bsStyle="danger"
                                  bsSize="large"
                                  onClick={() => {
                                    this.handleDeleteInput(index);
                                  }}
                                >
                                  X
                                </Button>
                              </div>
                            );
                          }else {
                          return (
                            <div className={inputElement[0]} key={index}>
                              <FormInput
                                type="text"
                                label={inputElement[1]}
                                name={inputElement[0]}
                                onChange=""
                                value=""
                              />
                              {inputElement[1]?.includes("*") ?
                                <OverlayTrigger placement="top" overlay={tooltip}>
                                <Button
                                  style={mandatoryButtonStyle}
                                  bsStyle="danger"
                                  bsSize="large"
                                  onClick={() => {
                                    this.resetRequiredField(index);
                                  }}
                                >
                                  {messages.editor.mandatory_field} *
                                </Button>
                                </OverlayTrigger>:
                                <OverlayTrigger placement="top" overlay={tooltip}>
                                <Button
                                  style={mandatoryButtonStyle}
                                  bsStyle="success"
                                  bsSize="large"
                                  onClick={() => {
                                    this.setRequiredField(index);
                                  }}
                                >
                                  {messages.editor.mandatory_field} *
                                </Button>
                                </OverlayTrigger>
                              }
                              <Button
                                style={{
                                  position: 'absolute',
                                  top: '0',
                                  right: '0',
                                  padding: '0',
                                  height: '30px',
                                  width: '30px',
                                  margin: '3px',
                                }}
                                bsStyle="danger"
                                bsSize="large"
                                onClick={() => {
                                  this.handleDeleteInput(index);
                                }}
                              >
                                X
                              </Button>
                            </div>
                          );}
                        })}
                        <div style={{ marginTop: '10px' }}>
                          <p style={{ marginBottom: '10px' }}>
                            <input type="checkbox" /> {messages.editor.read_privacy_policy}
                          </p>
                          <Button style={{ width: '100%' }} className="btn-send" bsStyle="danger" bsSize="large">
                            {messages.editor.send}
                          </Button>
                        </div>
                      </div>
                    </div>
                    {this.state.showCheckBoxLabelOptions ?
                      <div>
                        <h3>{messages.custom_checkbox}</h3>
                        <p>{messages.editor.change_label}</p>
                        <FormInput
                          type="text"
                          label={messages.set_label_checkbox}
                          name="checkBoxLabel"
                          onChange={(name, value)=>{this.changeLabelOfCheckBox(name, value)}}
                          value={this.state.checkBoxLabel}
                          infoText={messages.checkbox_infotext}
                        />

                        <p>{messages.change_checkbox_link}</p>
                        <FormInput
                          type="text"
                          label={messages.set_checkbox_link}
                          name="checkBoxLink"
                          onChange={(name, value)=>{this.changeLinkOfCheckBox(name, value)}}
                          value={this.state.checkBoxLink} />
                      </div>
                      : <></>}
                    {this.state.showCustomLabelOptions ?
                      <div>
                        <h3>{messages.editor.custom_field}</h3>
                        <p>{messages.editor.change_label}</p>
                        <FormInput
                          type="text"
                          label={messages.editor.set_label_custom_field}
                          name="customLabel"
                          onChange={(name, value)=>{this.changeLabelOfCustomField(name, value)}}
                          value={this.state.customLabel} />
                      </div>
                      : <></>}
                    {this.state.dropdownOptions && this.state.dropdownOptions.length > 0 &&
                      <>
                        <h3>{messages.editor.dropdown_settings}</h3>
                        <p>  {messages.editor.dropdown_label}</p>
                        <FormInput
                          type="text"
                          label="DropdownLabel"
                          name="dropdownLabel"
                          onChange={(name, value)=>{this.changeLabel(name, value)}}
                          value={this.state.dropdownLabel} />
                        <p>  {messages.editor.dropdown_option_fields}</p>
                        {this.state.dropdownOptions.map((option, index) => {
                          return(
                            <div style={{position:"relative"}}>
                              <FormInput
                                type="text"
                                label={"Feld " + (index + 1)}
                                name={option + "_" + index}
                                onChange={(name, value)=>this.changeOptions(name, value, index)}
                                value={option} />
                              <Button
                                style={{
                                  position: 'absolute',
                                  top:'0px',
                                  right: '0px',
                                  padding: '0',
                                  height: '30px',
                                  width: '30px',
                                  margin: '3px',
                                  zIndex:10,
                                }}
                                bsStyle="danger"
                                bsSize="large"
                                onClick={() => {
                                  this.handleDeleteOption(index);
                                }}
                              >
                                X
                              </Button>
                            </div>
                          )
                        })}
                      </>
                    }
                  </div>
                </div>
                <div className="cancelIE">
                  <Button style={{marginRight: "10px"}} bsStyle="danger" bsSize="large" onClick={this.setContactElements}>
                    {messages.editor.take_on}
                  </Button>
                  <Button bsStyle="warning" bsSize="large" onClick={this.handleIEclose}>
                    {messages.editor.abort}
                  </Button>
                </div>
              </div>
            </div>
          </ContextModal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(ContactFormElementNew)));


