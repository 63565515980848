import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { getThumbFile } from 'helpers/items';

import * as MenuVersions from '.';

class MainMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scrolled: false,
      activeIndices: [],
      duration: 300,
    };

    this.menuRef = React.createRef();
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
    document.addEventListener('click', this.outsideClick, false);
  };

  componentDidUpdate = prevProps => {
    const { location } = this.props;
    const { activeIndices } = this.state;

    const currentIndex = activeIndices[activeIndices.length - 1];

    if (
      currentIndex &&
      (prevProps.location.pathname !== location.pathname || prevProps.location.search !== location.search)
    ) {
      this.onMouseLeave(null, true);
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('click', this.outsideClick, false);
    clearTimeout(this.animatingOutTimeout);
  };

  handleScroll = () => {
    const { scrolled } = this.state;

    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    if (scrollTop > 15) {
      this.setState({ scrolled: true });
    } else if (scrolled) {
      this.setState({ scrolled: false });
    }
  };

  outsideClick = e => {
    const { activeIndices } = this.state;

    const currentIndex = activeIndices[activeIndices.length - 1];
    /* the definition ref={this.menuRef} in menu is incorrect,
    which means that the reference cannot be used. that's why we call the menu by id here.
     */
    let navUser = document.getElementById("nav-user");
    if (!currentIndex || this.menuRef.current?.contains(e.target) || navUser.contains(e.target)) {
      return;
    }

    this.onMouseLeave(null, true);
  };

  toggleSidebar = e => {
    e.preventDefault();

    const { sidebarVisible, showSidebar, hideSidebar } = this.props;

    if (sidebarVisible) {
      hideSidebar();
    } else {
      showSidebar();
    }
  };

  resetDropdownState = i => {
    this.setState({
      activeIndices: typeof i === 'number' ? [i] : [],
      animatingOut: false,
    });
    delete this.animatingOutTimeout;
  };

  onMouseEnter = i => {
    const { activeIndices } = this.state;

    if (this.animatingOutTimeout) {
      clearTimeout(this.animatingOutTimeout);
      this.resetDropdownState(i);
      return;
    }
    if (activeIndices[activeIndices.length - 1] === i) return;

    this.setState(prevState => ({
      activeIndices: prevState.activeIndices.concat(i),
      animatingOut: false,
    }));
  };

  onMouseLeave = (e = null, forceClose = false) => {
    const { user } = this.props;
    const { activeIndices, duration } = this.state;

    const currentIndex = activeIndices[activeIndices.length - 1];
    if (!forceClose && currentIndex === 1000 && !user.logged) {
      // do not close when it's the user profile popover
      return;
    }

    this.setState({
      animatingOut: true,
    });
    this.animatingOutTimeout = setTimeout(this.resetDropdownState, duration);
  };

  getMenuName = () => {
    const { menuVersion } = this.props;

    const componentName = ('Menu_' + menuVersion.toLowerCase()).replace(/_([a-z])/g, g => {
      return g[1].toUpperCase();
    });
    return componentName;
  };

  getMenu = () => {
    const {
      appIntl: { urlPrefix },
      location,
    } = this.props;

    const isCheckout = location.pathname.indexOf(urlPrefix + 'checkout') === 0;

    if (isCheckout) {
      return MenuVersions.MenuCheckout;
    }

    const name = this.getMenuName();
    // eslint-disable-next-line import/namespace
    if (MenuVersions[name] !== undefined) {
      // eslint-disable-next-line import/namespace
      return MenuVersions[name];
    }

    return MenuVersions.MenuDefault;
  };

  render() {
    const {
      layout,
      cmsPageData,
      projectConfig: {
        data: { main_logo: mainLogo, use_scroll_logo: useScrollLogo, scroll_logo: scrollLogo },
      },
      preview,
      previewScrolled,
    } = this.props;
    const { scrolled, activeIndices, animatingOut } = this.state;

    let menuBackground = '';
    let menuVersion = 'light';
    let logoVersion = '';
    if (!preview ? scrolled : previewScrolled) {
      menuVersion = 'dark';
      logoVersion = 'scroll';
      menuBackground = '';
    } else {
      if (cmsPageData && cmsPageData.data.menu_version) {
        menuVersion = cmsPageData.data.menu_version;
      }
      if (cmsPageData && cmsPageData.data.menu_background) {
        menuBackground = cmsPageData.data.menu_background;
      }
      if (cmsPageData && cmsPageData.data.logo_version) {
        switch (cmsPageData.data.logo_version) {
          case 'scroll':
            logoVersion = 'scroll';
            break;

          default:
            break;
        }
      }
    }

    let logoUrl = '/img/chedri_logo' + (logoVersion === 'scroll' ? '_white' : '') + '.png';
    if (logoVersion === 'scroll' && useScrollLogo) {
      const thumbnail = getThumbFile(scrollLogo);
      if (thumbnail) {
        logoUrl = thumbnail.web_link;
      }
    } else {
      const thumbnail = getThumbFile(mainLogo);
      if (thumbnail) {
        logoUrl = thumbnail.web_link;
      }
    }

    const currentIndex = activeIndices[activeIndices.length - 1];
    const prevIndex = activeIndices.length > 1 && activeIndices[activeIndices.length - 2];

    const Menu = this.getMenu();
    return (
      <Menu
        {...this.props}
        //ref={this.menuRef}
        layout={layout}
        toggleSidebar={this.toggleSidebar}
        scrolled={!preview ? scrolled : previewScrolled}
        menuBackground={menuBackground}
        menuVersion={menuVersion}
        logoVersion={logoVersion}
        logoUrl={logoUrl}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        currentIndex={currentIndex}
        prevIndex={prevIndex}
        animatingOut={animatingOut}
        preview={preview}
      />
    );
  }
}
MainMenu.defaultProps = {
  sidebarVisible: false,
  showSidebar: () => {},
  hideSidebar: () => {},
  preview: false,
  previewScrolled: false,
};

const mapStateToProps = (state, ownProps) => {
  return {
    appIntl: state.intl,
    layout: state.layout,
    menuVersion:
      ownProps.menuVersion ||
      (state.data.projectConfig.data.menu &&
        state.data.projectConfig.data.menu.version &&
        state.data.projectConfig.data.menu.version.id) ||
      'default',
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(MainMenu)));
