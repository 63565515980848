import React from 'react';
import { Table } from 'react-bootstrap';

import CarRentItem from 'components/layout/checkout/stepFItems/CarRentItem';
import CarItem from 'components/layout/checkout/stepFItems/CarItem';
import CarSalesServices from 'components/layout/checkout/stepFItems/CarSalesServices';
import Items from 'components/layout/checkout/stepFItems/Items';
import Transport from 'components/layout/checkout/stepFItems/Transport';

export default class StepFCheckoutItemList extends React.Component {
  render() {
    const { layout, checkout } = this.props;

    const inner = (
      <React.Fragment>
        {checkout.isCarRentCheckout && <CarRentItem {...this.props} />}
        {checkout.isCarCheckout && (
          <React.Fragment>
            <CarItem {...this.props} />
            <CarSalesServices {...this.props} />
          </React.Fragment>
        )}
        {checkout.isItemCheckout && <Items {...this.props} />}
        <Transport {...this.props} />
      </React.Fragment>
    );

    return layout.breakpointIndex > 1 ? (
      <Table width="100%">
        <tbody>{inner}</tbody>
      </Table>
    ) : (
      <div>{inner}</div>
    );
  }
}
