import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Grid, Row, Col } from 'react-bootstrap';

import { addNotification } from 'actions/NotificationsActions';
import { formStatusDispatchHelper } from 'actions/FormStatusActions';
import { getSalutationOptions, getGenderForSalutation } from 'helpers/clients';

import EntityStateUpdater from 'component/EntityStateUpdater';
import Data from 'components/Data';
import Entity from 'components/Entity';

import FormErrors from 'components/forms/FormErrors';
import FormInput from 'components/forms/FormInput';
import FormAutocomplete from 'components/forms/FormAutocomplete';

// import CheckoutTotal from 'components/layout/checkout/CheckoutTotal';
import MoreInfoStepC from 'components/layout/checkout/moreInfo/MoreInfoStepC';

import SignupFormLegal from 'components/cms/elements/CmsElementSignupFormLegal';

import { entityMetaMapping } from 'js/constants';

// personal information
class StepC extends EntityStateUpdater {
  constructor(props) {
    super(props);

    let entityMetaName = null;
    if (props.checkout.orderingAs === 'person') {
      entityMetaName = props.user.logged ? 'checkoutUserData' : 'checkoutUserDataWithRegistration';
    } else {
      entityMetaName = props.user.logged ? 'checkoutCompanyData' : 'checkoutCompanyDataWithRegistration';
    }

    this.state = {
      ...this.state,
      entityLoaded: false,
      entityChanged: false,
      showMoreInfo: false,
      entityMetaName,
    };

    this.entity = new Entity();
    this.entity.setData(props.checkout.userData);

    if (
      props.meta[entityMetaMapping[entityMetaName]] !== undefined &&
      !props.meta[entityMetaMapping[entityMetaName]].pending
    ) {
      this.entity.setMeta(props.meta, entityMetaMapping[entityMetaName]);

      this.state = {
        ...this.state,
        entityLoaded: true,
        entity: this.entity,
      };
    }
  }

  showMoreInfo = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showMoreInfo: true });
  };

  closeMoreInfo = () => {
    this.setState({ showMoreInfo: false });
  };

  mayProceed = () => {
    return true;
  };

  getNextUrl = () => {
    const { appIntl, checkout } = this.props;

    let nextUrl = appIntl.urlPrefix + 'checkout/';
    if (checkout.isCarCheckout) {
      nextUrl += 'services';
    } else if (checkout.isCarRentCheckout) {
      nextUrl += 'payment';
    } else {
      nextUrl += 'shipping';
    }

    return nextUrl;
  };

  clickNext = () => {
    const { appIntl, history, user, checkout, setCheckoutState } = this.props;

    if (this.mayProceed()) {
      this.setState(
        prevState => ({ entity: prevState.entity.validate() }),
        () => {
          if (this.state.entity.isValid) {
            // we have user data and we may now register the user server side
            if (user.logged) {
              history.push(this.getNextUrl());

              let newCheckoutState = { userDataValid: true };
              if (!checkout.creditCardHolder) {
                // update the credit card holder so we have a default value in the checkout
                newCheckoutState.creditCardHolder =
                  this.state.entity.getFirstName() + ' ' + this.state.entity.getLastName();
              }

              setCheckoutState(newCheckoutState);
            } else {
              // user is not logged in, he needs to register
              // we have the data and we may now try to create an account for the user
              // pass the withLogin parameter so the user will get logged in
              this.props.onSubmit({ ...this.state.entity.processedData, withLogin: true }, appIntl.urlPrefix);
            }
          } else {
            setCheckoutState({ userDataValid: false });
          }
        }
      );
    }
  };

  clickPrevious = () => {
    this.props.history.push(this.props.appIntl.urlPrefix + 'checkout/overview');
  };

  componentDidUpdate = prevProps => {
    const {
      intl: { messages },
      history,
      meta,
      checkout,
      formStatus,
      setCheckoutState,
      doNotify,
    } = this.props;
    const { entityMetaName } = this.state;

    if (
      meta[entityMetaMapping[entityMetaName]] !== undefined &&
      !meta[entityMetaMapping[entityMetaName]].pending &&
      !this.entity.getMetaLoaded()
    ) {
      this.entity.setMeta(meta, entityMetaMapping[entityMetaName]);
      if (this.entity.getDataLoaded()) {
        this.setState({ entityLoaded: true, entity: this.entity });
      }
    }

    if (prevProps.checkout.orderingAs !== checkout.orderingAs) {
      this.entity = new Entity();
      this.entity.setData({ ...this.state.entity.processedData });

      if (meta[entityMetaMapping[entityMetaName]] !== undefined && !meta[entityMetaMapping[entityMetaName]].pending) {
        this.entity.setMeta(meta, entityMetaMapping[entityMetaName]);

        this.setState({ entityLoaded: true, entity: this.entity });
      }
    }

    if (prevProps.formStatus.pending && !formStatus.pending && !formStatus.hasError) {
      // user successfully singed up
      // update user data in the checkout store
      setCheckoutState({ userData: formStatus.response });

      // we may forward him to the next step
      history.push(this.getNextUrl());

      // display information that registration was successfull
      doNotify(1, messages.thanks_for_signing_up);
    }
  };

  componentWillUnmount = () => {
    this.props.setCheckoutState({ userData: { ...this.state.entity.processedData } });
  };

  addressFieldChanged = (fieldName, fieldValue) => {
    // user changed his address information
    this.updateEntity(fieldName, fieldValue);

    const { checkout, setCheckoutState } = this.props;

    let doUpdateCheckoutState = false;
    let updateCheckoutState = {};

    // we need to invalidate the shipping distance and shipping method (if it's a car transport)
    if (checkout.shipping && checkout.shipping.distance > 0 && checkout.shipping.method === 'transport') {
      doUpdateCheckoutState = true;
      updateCheckoutState.shipping = { method: 'pickup', cost: 0, distance: null };
    }

    if (fieldName === 'country' && fieldValue !== 'DE') {
      // if the country is not Germany, we need to disable car registration and/or car transport
      if (checkout.services) {
        let newServices = checkout.services.slice();

        for (let i = 0; i < newServices.length; i++) {
          if (newServices[i].name === 'carRegistration' && newServices[i].isActive) {
            newServices[i].isActive = false;

            doUpdateCheckoutState = true;
            updateCheckoutState.services = newServices;
            break;
          }
        }
      }

      if (checkout.shipping && checkout.shipping.method === 'transport') {
        doUpdateCheckoutState = true;
        updateCheckoutState.shipping = { method: 'pickup', cost: 0 };
      }
    }

    if (doUpdateCheckoutState) {
      setCheckoutState(updateCheckoutState);
    }
  };

  orderingAsChange = e => {
    this.entity = new Entity();
    this.entity.setData({ ...this.state.entity.processedData });

    const orderingAs = e.target.value;

    let entityMetaName;
    if (orderingAs === 'person') {
      entityMetaName = this.props.user.logged ? 'checkoutUserData' : 'checkoutUserDataWithRegistration';
    } else {
      entityMetaName = this.props.user.logged ? 'checkoutCompanyData' : 'checkoutCompanyDataWithRegistration';
    }

    this.setState({ entityMetaName, entityLoaded: false }, () => this.props.setCheckoutState({ orderingAs }));
  };

  updateSalutation = (name, value) => {
    const gender = getGenderForSalutation(value);
    this.updateEntityMultiple(['salutation', 'gender'], [value, gender]);
  };

  render() {
    const {
      intl: { messages },
      intl,
      appIntl,
      checkout,
      user,
      formStatus,
    } = this.props;
    const { entity, entityLoaded, entityMetaName, showMoreInfo } = this.state;

    return (
      <Grid fluid>
        <Data dataName={entityMetaName} /> {/* fetch meta data */}
        {entityLoaded && (
          <React.Fragment>
            <div class="panel panel-transparent">
              <Row class="row-same-height">
                <div class="col-md-5 b-r b-dashed b-grey sm-b-b">
                  <div class="padding-30 sm-padding-5 sm-m-t-15 m-t-50">
                    <i class="fa fa-user fa-2x hint-text" />
                    <h2>{messages.your_personal_data}</h2>
                    <p />
                    <p class="small hint-text">
                      {messages.your_information_is_safe_with_us}{' '}
                      <a href="#moreInfo" onClick={this.showMoreInfo}>
                        {messages.find_out_more}
                      </a>
                    </p>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="padding-30 sm-padding-5">
                    <FormErrors formStatus={formStatus} />

                    <div class="m-b-30">
                      <div class="radio radio-success m-b-0">
                        <input
                          id="orderingAsPerson"
                          name="orderingAs"
                          type="radio"
                          value="person"
                          checked={checkout.orderingAs === 'person'}
                          onChange={this.orderingAsChange}
                        />
                        <label for="orderingAsPerson">{messages.ordering_as_person}</label>
                      </div>
                      <div class="radio radio-success m-b-0">
                        <input
                          id="orderingAsCompany"
                          name="orderingAs"
                          type="radio"
                          value="company"
                          checked={checkout.orderingAs === 'company'}
                          onChange={this.orderingAsChange}
                        />
                        <label for="orderingAsCompany">{messages.ordering_as_company}</label>
                      </div>
                    </div>

                    <p>{messages.client_name}</p>
                    <div class="form-group-attached">
                      <Row class="clearfix no-margin">
                        <Col sm={6}>
                          <FormAutocomplete
                            label={messages.salutation}
                            name="salutation"
                            value={entity.getSalutation()}
                            meta={entity.getSalutationMetaInformation()}
                            validation={entity.getSalutationValidationResult()}
                            onChange={this.updateSalutation}
                            options={getSalutationOptions(messages)}
                          />
                        </Col>
                      </Row>

                      <Row class="clearfix no-margin">
                        <Col sm={6}>
                          <FormInput
                            label={messages.first_name}
                            name="firstName"
                            value={entity.getFirstName()}
                            meta={entity.getFirstNameMetaInformation()}
                            validation={entity.getFirstNameValidationResult()}
                            onChange={this.updateEntity}
                          />
                        </Col>
                        <Col sm={6}>
                          <FormInput
                            label={messages.last_name}
                            name="lastName"
                            value={entity.getLastName()}
                            meta={entity.getLastNameMetaInformation()}
                            validation={entity.getLastNameValidationResult()}
                            onChange={this.updateEntity}
                          />
                        </Col>
                      </Row>
                    </div>

                    {checkout.orderingAs === 'company' && (
                      <div>
                        <p class="m-t-10">{messages.formGroup.company_information}</p>
                        <div class="form-group-attached">
                          <FormInput
                            label={messages.company}
                            name="company"
                            value={entity.getCompany()}
                            meta={entity.getCompanyMetaInformation()}
                            validation={entity.getCompanyValidationResult()}
                            onChange={this.addressFieldChanged}
                          />
                        </div>
                      </div>
                    )}

                    <p class="m-t-10">{messages.address}</p>
                    <div class="form-group-attached">
                      <FormInput
                        label={messages.street}
                        name="street"
                        value={entity.getStreet()}
                        meta={entity.getStreetMetaInformation()}
                        validation={entity.getStreetValidationResult()}
                        onChange={this.addressFieldChanged}
                      />

                      <Row class="clearfix no-margin">
                        <Col sm={6}>
                          <FormInput
                            label={messages.house_number}
                            name="houseNumber"
                            value={entity.getHouseNumber()}
                            meta={entity.getHouseNumberMetaInformation()}
                            validation={entity.getHouseNumberValidationResult()}
                            onChange={this.addressFieldChanged}
                          />
                        </Col>
                        <Col sm={6}>
                          <FormInput
                            label={messages.apartment_number}
                            name="apartmentNumber"
                            value={entity.getApartmentNumber()}
                            meta={entity.getApartmentNumberMetaInformation()}
                            validation={entity.getApartmentNumberValidationResult()}
                            onChange={this.addressFieldChanged}
                          />
                        </Col>
                      </Row>

                      <Row class="clearfix no-margin">
                        <Col sm={6}>
                          <FormInput
                            label={messages.zip_code}
                            name="zipCode"
                            value={entity.getZipCode()}
                            meta={entity.getZipCodeMetaInformation()}
                            validation={entity.getZipCodeValidationResult()}
                            onChange={this.addressFieldChanged}
                          />
                        </Col>
                        <Col sm={6}>
                          <FormInput
                            label={messages.city}
                            name="city"
                            value={entity.getCity()}
                            meta={entity.getCityMetaInformation()}
                            validation={entity.getCityValidationResult()}
                            onChange={this.addressFieldChanged}
                          />
                        </Col>
                      </Row>

                      <FormAutocomplete
                        label={messages.country}
                        name="country"
                        value={entity.getCountry()}
                        meta={entity.getCountryMetaInformation()}
                        validation={entity.getCountryValidationResult()}
                        onChange={this.addressFieldChanged}
                        autocompleteType="country"
                        complete
                      />
                    </div>

                    <p class="m-t-10">{messages.contact}</p>
                    <div class="form-group-attached">
                      {!user.logged && (
                        <FormInput
                          type="email"
                          label={messages.email}
                          name="email"
                          value={entity.getEmail()}
                          meta={entity.getEmailMetaInformation()}
                          validation={entity.getEmailValidationResult()}
                          onChange={this.updateEntity}
                        />
                      )}
                      <FormInput
                        type="tel"
                        label={messages.mobile_phone}
                        name="mobile"
                        value={entity.getMobile()}
                        meta={entity.getMobileMetaInformation()}
                        validation={entity.getMobileValidationResult()}
                        onChange={this.updateEntity}
                      />
                    </div>

                    {!user.logged && (
                      <div>
                        <p class="m-t-10">{messages.password}</p>
                        <div class="form-group-attached">
                          <FormInput
                            type="password"
                            label={messages.password}
                            name="password"
                            value={entity.getPassword()}
                            meta={entity.getPasswordMetaInformation()}
                            validation={entity.getPasswordValidationResult()}
                            onChange={this.updateEntity}
                          />
                          <FormInput
                            type="password"
                            label={messages.repeat_password}
                            name="repeatPassword"
                            value={entity.getRepeatPassword()}
                            meta={entity.getRepeatPasswordMetaInformation()}
                            validation={entity.getRepeatPasswordValidationResult()}
                            onChange={this.updateEntity}
                          />
                        </div>
                      </div>
                    )}

                    {!user.logged && (
                      <div class="p-t-20 p-b-15 fs-11">
                        <SignupFormLegal intl={intl} appIntl={appIntl} />
                      </div>
                    )}

                    {/* <br />

                  <CheckoutTotal isCarCheckout={checkout.isCarCheckout} checkout={checkout} /> */}
                  </div>
                </div>
              </Row>
            </div>

            <div class="padding-20 sm-padding-5 sm-m-b-20 sm-m-t-20 bg-white clearfix">
              <ul class="pager wizard no-style">
                {checkout.isCarCheckout && (
                  <li
                    class={'next ' + (this.mayProceed() ? '' : 'disabled ') + (formStatus.pending ? ' disabled' : '')}
                    style={{ display: 'list-item' }}
                  >
                    <button
                      class="btn btn-primary btn-cons pull-right btn-animated from-left fa fa-certificate"
                      type="button"
                      onClick={this.clickNext}
                    >
                      <span>
                        {formStatus.pending && <i class="fa-fw fa fa-circle-notch fa-spin m-r-5" />}{' '}
                        {user.logged ? messages.checkout_next : messages.signup}
                      </span>
                    </button>
                  </li>
                )}
                {checkout.isItemCheckout && (
                  <li
                    class={'next ' + (this.mayProceed() ? '' : 'disabled ') + (formStatus.pending ? ' disabled' : '')}
                    style={{ display: 'list-item' }}
                  >
                    <button
                      class="btn btn-primary btn-cons pull-right btn-animated from-left fa fa-truck"
                      type="button"
                      onClick={this.clickNext}
                    >
                      <span>
                        {formStatus.pending && <i class="fa-fw fa fa-circle-notch fa-spin m-r-5" />}{' '}
                        {user.logged ? messages.checkout_next : messages.signup}
                      </span>
                    </button>
                  </li>
                )}
                {checkout.isCarRentCheckout && (
                  <li
                    class={'next ' + (this.mayProceed() ? '' : 'disabled ') + (formStatus.pending ? ' disabled' : '')}
                    style={{ display: 'list-item' }}
                  >
                    <button
                      class="btn btn-primary btn-cons pull-right btn-animated from-left fa fa-credit-card"
                      type="button"
                      onClick={this.clickNext}
                    >
                      <span>
                        {formStatus.pending && <i class="fa-fw fa fa-circle-notch fa-spin m-r-5" />}{' '}
                        {user.logged ? messages.checkout_next : messages.signup}
                      </span>
                    </button>
                  </li>
                )}

                <li class="previous">
                  <button class="btn btn-default btn-cons pull-right" type="button" onClick={this.clickPrevious}>
                    <span>{messages.checkout_previous}</span>
                  </button>
                </li>
              </ul>
            </div>
          </React.Fragment>
        )}
        <MoreInfoStepC show={showMoreInfo} onHide={this.closeMoreInfo} isCarCheckout={checkout.isCarCheckout} />
      </Grid>
    );
  }
}
StepC.defaultProps = {};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    meta: state.entityMeta,
    formStatus: state.formStatus.checkoutSignupForm,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (args, urlPrefix) => {
      formStatusDispatchHelper('checkoutSignupForm', urlPrefix + 'login/register', args, 'post', dispatch);
    },
    doNotify: (code, text) => {
      dispatch(addNotification(code, text));
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(StepC)
  )
);
