import React from 'react';
import { connect } from 'react-redux';

import { find as _find } from 'lodash';
import ReactDOM from 'react-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { getTimezoneFromState } from '../../../helpers/intl';
import FormAutocomplete from '../../../components/forms/FormAutocomplete';
import FormInput from '../../../components/forms/FormInput';
import FormTextarea from '../../../components/forms/FormTextarea';

const ContextModal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('context-modal'));
};

class SocialMediaElement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      social: [],
      socialEdit: [],
      showSocialEdit: false,
    };
  }

  setSocial = () => {
    const { socialEdit } = this.state;
    const { id, attributes, updateParam, imageUpload } = this.props;

    const param = socialEdit[0] + '#aaa#' + socialEdit[1];
    const overflow = false;
    const default_image = false;
    const errors = [{ overflow, default_image }];
    updateParam(id, attributes['data-name'], param, errors);
    this.setState({ social: socialEdit, showSocialEdit: false }, () => {});
  };

  getSocial = () => {
    const { name: Tag, attributes, params } = this.props;
    let social = [];
    const param = _find(params, ['name', attributes['data-name']]);
    if (param) {
      social = param.value.split('#aaa#');
    }
    this.setState({ social, socialEdit: social }, () => {});
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.params[0] && prevProps.params[0]?.value !== this.props.params[0]?.value) {
      this.getSocial();
    }
  }

  componentDidMount = () => {
    this.getSocial();
  };

  showSocialEdit = () => {
    this.setState({ showSocialEdit: true });
  };

  closeSocialEdit = () => {
    this.setState({ showSocialEdit: false });
  };

  getSocialContainer = social => {
    const {
      intl: { messages },
    } = this.props;
    return (
      <div className="social-container">
        {social[0] === 'facebook' && (
          <iframe
            src={
              'https://www.facebook.com/plugins/page.php?href=' +
              encodeURI(social[1]) +
              '&width=1800&height=500&tabs=timeline&small_header=false&adapt_container_width=true&hide_cover=true&show_facepile=true&appId=105380969554568'
            }
            style={{ border: 'none', overflow: 'hidden' }}
            scrolling="no"
            frameBorder="0"
            width="1800"
            title="Facebook"
            height="500"
            allowFullScreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          />
        )}
        {social[0] === 'twitter' && (
          <>
            <p>{messages.editor.no_preview_of_twitter}</p>
            <p>
              {messages.editor.twitter_link} {social[1]}
            </p>
          </>
        )}
        {social[0] === 'embed' && <div dangerouslySetInnerHTML={{ __html: social[1] }} />}
      </div>
    );
  };

  render() {
    const { name: Tag, attributes, params, appIntl, defaultTimezone } = this.props;
    const { social, socialEdit, showSocialEdit } = this.state;
    const {
      intl: { messages },
    } = this.props;

    return (
      <>
        <Tag
          {...attributes}
          onClick={this.showSocialEdit}
          data-tip={messages.editor.social_media_settings}
          data-for="top"
        >
          <div className="wrap">
            {social.length > 0 ? this.getSocialContainer(social) : <p>{messages.editor.add_social_media_account}</p>}
          </div>
        </Tag>
        {showSocialEdit && (
          <ContextModal>
            <div id="contextModalWrap">
              <div id="contextModalStage" className="row">
                <Row className="h-45">
                  <Col md={4}>
                    <FormAutocomplete
                      name="provider"
                      label={messages.editor.choice_of_provider}
                      options={[
                        { label: '-', value: '' },
                        { label: 'Facebook', value: 'facebook' },
                        { label: 'Twitter', value: 'twitter' },
                        // { label: 'Embed Code', value: 'embed' },
                      ]}
                      value={socialEdit[0] || ''}
                      onChange={(name, value) => {
                        const { socialEdit } = this.state;
                        socialEdit[0] = value;
                        socialEdit[1] = '';
                        this.setState({ socialEdit });
                      }}
                    />
                    {socialEdit[0] && socialEdit[0] === 'embed' ? (
                      <FormTextarea
                        name="embedCode"
                        label={messages.editor.embed_code}
                        value={socialEdit[1] || ''}
                        onChange={(name, value) => {
                          const { socialEdit } = this.state;
                          socialEdit[1] = value;
                          this.setState({ socialEdit });
                        }}
                      />
                    ) : (
                      <FormInput
                        name="externId"
                        label={
                          socialEdit[0]
                            ? messages.editor.link_to_your_page +
                              socialEdit[0].charAt(0).toUpperCase() +
                              socialEdit[0].slice(1)
                            : messages.editor.link_to_your_page_two
                        }
                        value={socialEdit[1] || ''}
                        onChange={(name, value) => {
                          const { socialEdit } = this.state;
                          socialEdit[1] = value;
                          this.setState({ socialEdit });
                        }}
                      />
                    )}
                  </Col>
                  <Col md={8} className="h-100">
                    <div style={{ minHeight: '50%', border: '2px dashed #ccc', padding: '10px' }}>
                      <p>{messages.editor.edition_preview}</p>
                      {socialEdit[0] && socialEdit[1] && this.getSocialContainer(socialEdit)}
                    </div>
                  </Col>
                </Row>

                <Row className="text-right">
                  <Button bsStyle="success" bsSize="large" onClick={this.setSocial}>
                    {messages.editor.take_on}
                  </Button>{' '}
                  <Button bsStyle="danger" bsSize="large" onClick={this.closeSocialEdit}>
                    {messages.editor.abort}
                  </Button>
                </Row>
              </div>
            </div>
          </ContextModal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    defaultTimezone: getTimezoneFromState(state),
    appIntl: state.intl,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(SocialMediaElement)));
