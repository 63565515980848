import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col } from 'react-bootstrap';

import { getLogger, intlModule, communicationVideoModule as videoComms } from '@chedri/base';

import Icon from 'components/Icon';
import IconButton from 'components/IconButton';

const logger = getLogger('communication');

function isFullscreen() {
  return (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitIsFullScreen
  );
}

function CallVideoController() {
  const dispatch = useDispatch();
  const fullscreenModeActive = useRef(false);

  const messages = useSelector(intlModule.selectors.getMessages);
  const localCall = useSelector(videoComms.selectors.getLocalCall);
  const remoteCall = useSelector(videoComms.selectors.getFirstRemoteCall);
  const status = useSelector(videoComms.selectors.getStatus);
  const isMicMuted = useSelector(videoComms.selectors.isMicMuted);
  const isCameraEnabled = useSelector(videoComms.selectors.isCameraEnabled);
  const isScreenSharing = useSelector(videoComms.selectors.isScreanSharingEnabled);

  const videoRef = useRef();
  const incomingVideoRef = useRef();
  const [videoWrapperRef, setVideoWrapperRef] = useState();
  const [isMaximized, setIsMaximized] = useState(true);
  const [orientation, setOrientation] = useState('vertical');
  const [uuid, setUuid] = useState();

  // Close call
  const closeCall = useCallback(() => {
    dispatch(videoComms.actions.endCall(uuid));
  }, [uuid]);

  // Toggle microphone muted
  const toggleMute = useCallback(() => {
    dispatch(videoComms.actions.toggleMic(isMicMuted));
  }, [isMicMuted]);

  // Toggle camera
  const toggleCamera = useCallback(() => {
    dispatch(videoComms.actions.toggleCamera(!isCameraEnabled));
  }, [isCameraEnabled]);

  // Toggle screen sharing
  const toggleScreenSharing = useCallback(() => {
    dispatch(videoComms.actions.toggleScreenSharing(!fullscreenModeActive));
  }, []);

  // Toggle fullscreen mode
  const toggleFullscreen = useCallback(() => {
    if (!fullscreenModeActive.current) {
      if (videoWrapperRef.requestFullScreen) {
        videoWrapperRef.requestFullScreen();
      } else if (videoWrapperRef.mozRequestFullScreen) {
        videoWrapperRef.mozRequestFullScreen();
      } else if (videoWrapperRef.webkitRequestFullScreen) {
        videoWrapperRef.webkitRequestFullScreen();
      }
    }
    if (fullscreenModeActive.current) {
      if (document.exitFullscreen) {
        document
          .exitFullscreen()
          .then(() => {})
          .catch(err => console.error('Exiting fullscreen mode.', err));
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }, [fullscreenModeActive, videoWrapperRef]);

  // Toggle window size
  const toggleWindowSize = useCallback(() => {
    setIsMaximized(!isMaximized);
  }, [isMaximized, setIsMaximized]);

  useEffect(() => {
    const fullscreenChangeHandler = () => {
      if (isFullscreen()) {
        fullscreenModeActive.current = true;
      } else {
        fullscreenModeActive.current = false;
      }
    };
    document.addEventListener('fullscreenchange', fullscreenChangeHandler);
    document.addEventListener('mozfullscreenchange', fullscreenChangeHandler);
    document.addEventListener('webkitfullscreenchange', fullscreenChangeHandler);
    document.addEventListener('onwebkitfullscreenchange', fullscreenChangeHandler);
    return () => {
      document.removeEventListener('fullscreenchange', fullscreenChangeHandler);
      document.removeEventListener('mozfullscreenchange', fullscreenChangeHandler);
      document.removeEventListener('webkitfullscreenchange', fullscreenChangeHandler);
      document.removeEventListener('onwebkitfullscreenchange', fullscreenChangeHandler);
    };
  }, []);

  // Connect the video ports to the streams
  useEffect(() => {
    if (videoRef.current && localCall && !localCall.isEnded && videoRef.current.srcObject !== localCall.stream) {
      logger.debug('Establishing video connection', videoRef, localCall);
      videoRef.current.setAttribute('playsinline', true);
      videoRef.current.srcObject = localCall.stream;
      logger.debug('Video connection established', videoRef, localCall.stream);
    }
  }, [videoRef, localCall]);

  useEffect(() => {
    if (
      incomingVideoRef.current &&
      remoteCall &&
      !remoteCall.isEnded &&
      incomingVideoRef.current.srcObject !== remoteCall.stream
    ) {
      logger.debug('Establishing remote video connection', incomingVideoRef, remoteCall);
      incomingVideoRef.current.setAttribute('playsinline', true);
      incomingVideoRef.current.srcObject = remoteCall.stream;
      logger.debug('Remote video connection established', incomingVideoRef, remoteCall.stream);
    }
  }, [incomingVideoRef, remoteCall]);

  // Set the active UUID for the active call
  useEffect(() => {
    if (remoteCall && uuid !== remoteCall.uuid) {
      // We are presenter, we have no UUID, so set one.
      setUuid(remoteCall.uuid);
    }
  }, [localCall, remoteCall, uuid, setUuid]);

  // Calculate orientation
  useEffect(() => {
    if (remoteCall && remoteCall.stream) {
      const videoTrack = remoteCall.stream.getVideoTracks()[0];
      if (videoTrack) {
        const settings = videoTrack.getSettings();
        const { width } = settings;
        const { height } = settings;
        if (width > height) {
          setOrientation('horizontal');
        } else {
          setOrientation('vertical');
        }
      }
    }
  }, [remoteCall, setOrientation]);
  return (
    <div
      ref={ref => setVideoWrapperRef(ref)}
      className={`video-wrapper control-center ${
        isMaximized || fullscreenModeActive.current ? 'maximized' : 'minimized'
      }`}
    >
      <Row className={fullscreenModeActive.current ? 'fullscreen' : ''}>
        <Col xs={12} md={4} className="overflow-ellipsis lh-23 no-padding">
          <div className="pull-left" style={{ width: '48px', height: '48px', marginRight: '10px' }}>
            <Icon name="fa-video" iconClassName="round-icon" iconStyles={{ lineHeight: '48px' }} />
          </div>
          <div className="pull-left">
            <div className="small">
              {remoteCall && remoteCall.isMicrophoneMuted ? (
                <span>{messages.muted}</span>
              ) : status.info === 'calling' ? (
                <span>{status.source === 'presenter' ? 'Anrufen' : 'Anruf'} von </span>
              ) : status.info === 'checking' ? (
                <span>{messages.checking_connection} </span>
              ) : status.info === 'connected' ? (
                <span>{messages.connected_with} </span>
              ) : status.info !== 'connected' ? (
                <span>{messages.connecting_with} </span>
              ) : (
                ''
              )}
            </div>
            <div>{remoteCall && remoteCall.user.name}</div>
          </div>
        </Col>
        <Col xs={12} md={8} className="controls lh-47 p-r-0 text-right">
          {!fullscreenModeActive.current && (
            <IconButton
              name={isMaximized ? 'fa-window-minimize' : 'fa-window-maximize'}
              iconClassName="fa-fw"
              buttonClassName="btn btn-sm btn-rounded"
              onClick={toggleWindowSize}
              tooltip={isMaximized ? messages.minimize_video : messages.maximize_video}
              tooltipPlacement="bottom"
            />
          )}
          <IconButton
            name={fullscreenModeActive.current ? 'fa-compress' : 'fa-expand'}
            iconClassName="fa-fw"
            buttonClassName="btn btn-sm btn-rounded"
            onClick={toggleFullscreen}
            tooltip={fullscreenModeActive.current ? messages.disable_fullscreen : messages.enable_fullscreen}
            tooltipPlacement="bottom"
          />
          <IconButton
            name="fa-share-square"
            duotone
            iconClassName="fa-fw"
            buttonClassName="btn btn-sm btn-rounded"
            layers={
              !isScreenSharing ? [<Icon key="slash" name="fa-slash" iconStyles={{ marginLeft: '-50%' }} />] : null
            }
            onClick={toggleScreenSharing}
            tooltip={isScreenSharing ? messages.disable_screensharing : messages.enable_screensharing}
            tooltipPlacement="bottom"
          />
          <IconButton
            name="fa-microphone"
            duotone
            iconClassName="fa-fw"
            buttonClassName="btn btn-sm btn-rounded"
            layers={isMicMuted ? [<Icon key="slash" name="fa-slash" iconStyles={{ marginLeft: '-50%' }} />] : null}
            onClick={toggleMute}
            tooltip={isMicMuted ? messages.unmute_microphone : messages.mute_microphone}
            tooltipPlacement="bottom"
          />
          <IconButton
            name="fa-camera"
            duotone
            iconClassName="fa-fw"
            buttonClassName="btn btn-sm btn-rounded"
            layers={
              !isCameraEnabled ? [<Icon key="slash" name="fa-slash" iconStyles={{ marginLeft: '-50%' }} />] : null
            }
            onClick={toggleCamera}
            tooltip={isCameraEnabled ? messages.disable_camera : messages.enable_camera}
            tooltipPlacement="bottom"
          />
          <IconButton
            name="fa-phone-slash"
            buttonClassName="btn btn-sm btn-rounded btn-danger"
            onClick={closeCall}
            tooltip={messages.end_videocall}
            tooltipPlacement="bottom"
          />
        </Col>
      </Row>
      <div className="remote-port">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video ref={incomingVideoRef} className={orientation} autoPlay />
      </div>
      <div className="local-port">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video ref={videoRef} className={orientation} autoPlay muted />
      </div>
    </div>
  );
}
CallVideoController.defaultProps = {};
CallVideoController.propTypes = {};

export default CallVideoController;
