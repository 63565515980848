import React from 'react';
import SimplePrompt from '../SimplePrompt';

const FolderInTrash = ({ index, id, name, isAllowed, erase, undelete, messages }) => (
  isAllowed && (
    <div md={2} className="cc-media-folder" key={index}>
      <div className="card" style={{ backgroundColor: 'transparent' }}>
        <div className="card-body">
          <div class="selectZone">
            <div className="cc-card-title">
              <img
                src="https://creacheck-icons.s3.eu-central-1.amazonaws.com/folder-solid-cutted-svg-weiss.svg"
                style={{ filter: 'brightness(0.85)' }}
                alt=""
              />
              <h5 className="">{name}</h5>
            </div>
            <p className="card-text"> </p>
          </div>
          <div class="cc-card-footer">

              <div class="inlineSet">
                <button type="button" onClick={() => undelete(id)} className="btn btn-default card-button">
                  <i className="fas fa-undo" />
                </button>
                <SimplePrompt
                  iconClass="fas fa-times"
                  class="card-button"
                  onSuccess={() => erase(id)}
                  showLabel={false}
                  showMessage={messages.medialibrary.are_you_sure_erase_folder}
                  buttonLabel={messages.medialibrary.erase}
                />
              </div>

          </div>
        </div>
      </div>
    </div>
  )
);

export default FolderInTrash;
