import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'components/Modal';

import { injectIntl } from 'react-intl';

import Data from 'components/Data';
import CmsPage from 'components/CmsPage';

import Loader from 'components/layout/Loader';

// display terms and conditions of current platform
class ModalMasterTermsOfService extends React.Component {
  render() {
    const {
      intl: { messages },
      show,
      close,
      cmsPage,
    } = this.props;

    return (
      <Modal show={show} onHide={close} bsSize="lg" class="full-width">
        <Modal.Header closeButton>
          <Modal.Title>{messages.terms_of_service}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Data dataName="cmsSystemPageTermsOfService" url="/api/pages/termsofservice.json" data={cmsPage} />

          <div class="themed">
            {cmsPage.pending ? <Loader /> : <CmsPage data={cmsPage.data} doUpdateActiveCms={false} />}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    cmsPage: state.data.cmsSystemPageTermsOfService,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(ModalMasterTermsOfService)));
