import React from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';

import { Col } from 'react-bootstrap';

import IconButton from 'components/IconButton';

function CallRejected({ calleeName, onCallRetry, onClose }) {
  const { messages } = useIntl();

  return (
    <div>
      <Col xs={6} className="overflow-ellipsis lh-23 no-padding">
        <div className="small">{messages.callRejected}</div>
        <div>{calleeName}</div>
      </Col>
      <Col xs={6} className="lh-47 p-r-0 text-right controls">
        <IconButton
          name="fa-redo"
          buttonClassName="btn btn-sm btn-rounded btn-success"
          onClick={onCallRetry}
          tooltip={messages.call_again}
          tooltipPlacement="bottom"
        />
        <IconButton
          name="fa-times"
          buttonClassName="btn btn-sm btn-rounded"
          onClick={onClose}
          tooltip={messages.close}
          tooltipPlacement="bottom"
        />
      </Col>
    </div>
  );
}
CallRejected.defaultProps = {};
CallRejected.propTypes = {
  calleeName: PropTypes.string.isRequired,
  onCallRetry: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CallRejected;
