import { findDOMNode } from 'react-dom';

export const elementSource = {
  beginDrag(props) {
    return {
      index: props.index,
    };
  },
};

export const elementTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index.toString();
    const hoverIndex = props.index.toString();

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // compare if it is the same container
    let dragContainer = dragIndex.substring(0, dragIndex.lastIndexOf('.'));
    dragContainer = dragContainer !== '' ? dragContainer + '.' : '';
    let hoverContainer = hoverIndex.substring(0, hoverIndex.lastIndexOf('.'));
    hoverContainer = hoverContainer !== '' ? hoverContainer + '.' : '';

    let direction,
      newIndex,
      sameContainer = dragContainer === hoverContainer;
    const dragContainerIndex = dragIndex.substring(dragIndex.lastIndexOf('.')).replace('.', '');
    const hoverContainerIndex = hoverIndex.substring(hoverIndex.lastIndexOf('.')).replace('.', '');

    // drop after or before?
    if (hoverClientY < hoverMiddleY) {
      direction = 'before';
    } else {
      direction = 'after';
    }

    // calculate new index
    if ((sameContainer && dragContainerIndex > hoverContainerIndex) || !sameContainer) {
      if (direction === 'after') {
        newIndex = hoverContainer + (parseInt(hoverContainerIndex) + 1);
      } else {
        newIndex = hoverContainer + hoverContainerIndex;
      }
    } else {
      if (direction === 'after') {
        newIndex = hoverContainer + hoverContainerIndex;
      } else {
        newIndex = hoverContainer + (parseInt(hoverContainerIndex) - 1 < 0 ? 0 : parseInt(hoverContainerIndex) - 1);
      }
    }

    if (newIndex !== dragIndex) {
      props.reorderElements(dragIndex, hoverIndex, direction, sameContainer);
      monitor.getItem().index = newIndex;
    }
  },
};
