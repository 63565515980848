export const WEBRTCCALL_DISCONNECT = 'WEBRTCCALL_DISCONNECT';
export const WEBRTCCALL_LOCAL_STREAM = 'WEBRTCCALL_LOCAL_STREAM';
export const WEBRTCCALL_LOCAL_STREAM_ERROR = 'WEBRTCCALL_LOCAL_STREAM_ERROR';
export const WEBRTCCALL_REMOTE_STREAM = 'WEBRTCCALL_REMOTE_STREAM';
export const WEBRTCCALL_CHANGE_VIEW = 'WEBRTCCALL_CHANGE_VIEW';
export const WEBRTCCALL_SHOW_CHAT = 'WEBRTCCALL_SHOW_CHAT';
export const WEBRTCCALL_HIDE_CHAT = 'WEBRTCCALL_HIDE_CHAT';
export const WEBRTCCALL_ENDED = 'WEBRTCCALL_ENDED';

export const webRTCCallDisconnect = () => {
  return {
    type: WEBRTCCALL_DISCONNECT,
    payload: null,
  };
};

export const webRTCCallLocalStream = stream => {
  return {
    type: WEBRTCCALL_LOCAL_STREAM,
    payload: stream,
  };
};

export const webRTCCallLocalStreamError = error => {
  return {
    type: WEBRTCCALL_LOCAL_STREAM_ERROR,
    payload: error,
  };
};

export const webRTCCallRemoteStream = stream => {
  return {
    type: WEBRTCCALL_REMOTE_STREAM,
    payload: stream,
  };
};

export const webRTCCallChangeView = view => {
  return {
    type: WEBRTCCALL_CHANGE_VIEW,
    payload: view,
  };
};

export const webRTCCallShowChat = () => {
  return {
    type: WEBRTCCALL_SHOW_CHAT,
    payload: null,
  };
};

export const webRTCCallHideChat = () => {
  return {
    type: WEBRTCCALL_HIDE_CHAT,
    payload: null,
  };
};

export const webRTCCallEnded = args => {
  return {
    type: WEBRTCCALL_ENDED,
    payload: args,
  };
};
