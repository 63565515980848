export const ONLINE_STATUS_CHANGED = 'ONLINE_STATUS_CHANGED';
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY';

export const updateOnlineStatus = onlineStatus => {
  return {
    type: ONLINE_STATUS_CHANGED,
    payload: onlineStatus,
  };
};

export const changeCurrency = currency => {
  return {
    type: CHANGE_CURRENCY,
    payload: currency,
  };
};
