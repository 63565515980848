import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Loader from '../Loader';
import DragFiles from './DragFiles';
import DropFolder from './DropFolder';
import DropFolderBack from './DropFolderBack';
import FileInTrash from './FileInTrash';
import FolderInTrash from './FolderInTrash';

function FolderView({
  foldersDrag,
  userData,
  markFolder,
  choiceFolder,
  editFolder,
  deleteFolder,
  undeleteFolder,
  eraseFolder,
  activdir,
  s3urlOriginal,
  s3urlThumb,
  choice,
  markFile,
  del,
  undeleteFile,
  eraseFile,
  processDrop,
  addDirectory,
  getMedia,
  mediaFiles,
  mediaFolders,
  folders,
  pending,
  selectFile,
  files,
  filesDrag,
  saving,
  copyFolder,
  copyFile,
  pasteFile,
  pasteFolder,
  copiedFiles,
  copiedFolders,
  messages,
}) {
  const hasAccess = !!userData.access;
  const getIsAllowed = fileOrFolder => {
    const ownerName = fileOrFolder?.owner?.username;
    const creatorName = fileOrFolder?.creator?.username;
    const { role, username } = userData;

    return role === 'admin' || [ownerName, creatorName].includes(username);
  };

  const ItemTypes = { ALL: 'alles' };

  const hasFiles = files?.filter(({ is_in_trash: isInTrash }) => !isInTrash)?.length > 0;
  const hasFilesTrashed = files?.filter(({ is_in_trash: isInTrash }) => !!isInTrash)?.length > 0;
  const hasFolders = folders?.filter(({ is_in_trash: isInTrash }) => !isInTrash)?.length > 0;
  const hasFoldersTrashed = folders?.filter(({ is_in_trash: isInTrash }) => !!isInTrash)?.length > 0;

  const handleDrop = (item, folderName, folderId, mediaFiles, mediaFolders) => {
    processDrop(item.id, folderId, mediaFiles, mediaFolders, item.ownFileType, item.owner, item.creator);
  };

  return (
    <>
      <Row>
        <Col class="colSettings001">
          <div style={{ overflow: 'auto', clear: 'both' }}>
            {hasAccess && (
              <div style={{ display: 'flex' }}>
                <div
                  md={2}
                  className="add-cc-media-folder"
                  style={{ color: '#FFFFFF', cursor: 'pointer' }}
                  onClick={() => addDirectory()}
                >
                  <div className="addFolder">
                    <p>
                      <span style={{ fontSize: '20px' }}>+</span> {messages.medialibrary.new_folder}
                    </p>
                  </div>
                </div>
                {copiedFolders?.length > 0 && (
                  <div
                    md={2}
                    className="add-cc-media-folder"
                    style={{ color: '#FFFFFF', cursor: 'pointer' }}
                    onClick={() => pasteFolder()}
                  >
                    <div className="addFolder">
                      <p>
                        <span style={{ fontSize: '20px' }}>
                          {' '}
                          <i className="far fa-clone" />
                        </span>{' '}
                        {messages.medialibrary.paste}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div>
            {!!getMedia.back && (
              <DropFolderBack
                accept={[ItemTypes.ALL]}
                onDrop={item =>
                  handleDrop(item, activdir.parentName, activdir.folderInfo.parent, mediaFiles, mediaFolders)
                }
                id={activdir.folderInfo.parent}
                name={activdir.parentName}
                choiceFolder={choiceFolder}
              />
            )}
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          {/* Dragzone */}
          <div class="folderSettingsForSmallDevice" style={{ overflow: 'auto', clear: 'both' }}>
            {pending ? (
              <Loader />
            ) : hasFolders ? (
              folders
                .filter(({ is_in_trash: isInTrash }) => !isInTrash)
                .map((folder, index) => (
                  <DropFolder
                    accept={[ItemTypes.ALL]}
                    onDrop={item => handleDrop(item, folder.name, folder.id, mediaFiles, mediaFolders)}
                    key={folder.id}
                    index={index}
                    id={folder.id}
                    parent={folder.parent}
                    name={folder.name}
                    type={ItemTypes.ALL}
                    creator={folder?.creator?.username ?? null}
                    owner={folder?.owner?.username ?? null}
                    choiceFolder={choiceFolder}
                    copyFolder={copyFolder}
                    deleteFolder={deleteFolder}
                    editFolder={editFolder}
                    markFolder={markFolder}
                    foldersDrag={foldersDrag}
                    copiedFolders={copiedFolders}
                    userData={userData}
                  />
                ))
            ) : (
              <div style={{ margin: '20px 0' }}>
                <i className="fas fa-exclamation-triangle" style={{ color: '#CF1719', fontSize: '20px' }} />
                <span> {messages.medialibrary.no_folder_created}</span>
              </div>
            )}

            {hasAccess && (
              <div md={2} className="cc-media-folder">
                <div className="card add-cc-media-folder-circle">
                  <i
                    className="far fa-plus-circle"
                    style={{ color: '#FFFFFF', fontSize: '100px', cursor: 'pointer' }}
                    onClick={() => addDirectory()}
                  />
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="extraStyle" />
      </Row>
      <Row>
        <Col>
          <h3>
            <i className="far fa-images" /> {messages.medialibrary.media}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <div class="mediaSettingsForSmallDevice" style={{ overflow: 'auto', clear: 'both' }}>
            {hasAccess && (
              <div style={{ display: 'flex' }}>
                <div md={2} className="add-cc-media-folder" style={{ color: '#FFFFFF' }}>
                  <div className="addFolder">
                    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                    <label htmlFor="file-input" style={{ cursor: 'pointer' }}>
                      <p>
                        <span style={{ fontSize: '20px' }}>+</span> {messages.medialibrary.upload_media}
                      </p>
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept="image/jpeg, image/png, image/webp, video/mp4, application/pdf"
                      multiple
                      onChange={selectFile}
                    />
                  </div>
                </div>
                {copiedFiles?.length > 0 && (
                  <div
                    md={2}
                    className="add-cc-media-folder"
                    style={{ color: '#FFFFFF', cursor: 'pointer' }}
                    onClick={() => pasteFile()}
                  >
                    <div className="addFolder">
                      <p>
                        <span style={{ fontSize: '20px' }}>
                          {' '}
                          <i className="far fa-clone" />
                        </span>{' '}
                        {messages.medialibrary.paste}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}

            {saving || pending ? (
              <Loader />
            ) : hasFiles ? (
              files
                .filter(({ is_in_trash: isInTrash }) => !isInTrash)
                .map(file => (
                  <DragFiles
                    name={file.filename}
                    type={ItemTypes.ALL}
                    key={file.id}
                    index={file.index}
                    thumb={file.thumb}
                    id={file.id}
                    fileKey={file.key}
                    title={file.title}
                    creator={file.creator ? file.creator.username : null}
                    owner={file.owner ? file.owner.username : null}
                    filesDrag={filesDrag}
                    copiedFiles={copiedFiles}
                    s3urlOriginal={s3urlOriginal}
                    s3urlThumb={s3urlThumb}
                    choice={choice}
                    copyFile={copyFile}
                    markFile={markFile}
                    userData={userData}
                    del={del}
                  />
                ))
            ) : (
              <div style={{ margin: '20px 0' }}>{messages.medialibrary.no_media_found}</div>
            )}
            {hasAccess && (
              <div md={2} className="cc-media-image">
                <div className="card add-cc-media-image">
                  <div className="image-upload">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */}
                    <label htmlFor="file-input" style={{ cursor: 'pointer' }}>
                      <span style={{ color: '' }}>
                        <i
                          className="far fa-plus-circle"
                          style={{ color: '#FFFFFF', marginTop: '28px', fontSize: '100px', cursor: 'pointer' }}
                        />
                      </span>
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept="image/*, video/mp4, application/pdf"
                      multiple
                      onChange={selectFile}
                    />
                  </div>
                  <div className="card-body">
                    <p className="card-text" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>

      <Row>
        <Col className="extraStyle" />
      </Row>
      {(hasFilesTrashed || hasFoldersTrashed) && (
        <>
          <Row>
            <Col>
              <h3>
                <i className="far fa-images" /> {messages.medialibrary.wastebasket}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              {hasFoldersTrashed &&
                folders
                  .filter(({ is_in_trash: isInTrash }) => !!isInTrash)
                  .map((folder, index) => (
                    <FolderInTrash
                      key={folder.id}
                      index={index}
                      id={folder.id}
                      name={folder.name}
                      isAllowed={getIsAllowed(folder)}
                      erase={eraseFolder}
                      undelete={undeleteFolder}
                      messages={messages}
                    />
                  ))}
              {hasFilesTrashed &&
                files
                  .filter(({ is_in_trash: isInTrash }) => !!isInTrash)
                  .map(file => (
                    <FileInTrash
                      name={file.filename}
                      type={ItemTypes.ALL}
                      key={file.id}
                      index={file.index}
                      thumb={file.thumb}
                      id={file.id}
                      fileKey={file.key}
                      title={file.title}
                      isAllowed={getIsAllowed(file)}
                      s3urlOriginal={s3urlOriginal}
                      s3urlThumb={s3urlThumb}
                      undelete={undeleteFile}
                      erase={eraseFile}
                      messages={messages}
                    />
                  ))}
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default FolderView;
