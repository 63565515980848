import React from 'react';
import { connect } from 'react-redux';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import { formStatusDispatchHelper } from 'actions/FormStatusActions';

import SignupForm from 'components/forms/auth/SignupForm';

import RichEditorContent from 'components/RichEditorContent';

import AbstractCmsElement from './AbstractCmsElement';

class CmsElementSignupForm extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  onSubmit = data => {
    this.props.onSubmit(data, this.props.appIntl.urlPrefix);
  };

  render() {
    const {
      intl: { messages },
    } = this.props;
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const textClass = 'text-' + this.getParamValue('mainColor', 'master');

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-signup-form-content ' + shownClass + this.getCssClasses()}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <div class="ms-signup-form-container">
          <h4 class={'no-margin p-b-5 ' + textClass}>
            <i class="fa fa-pencil-alt m-r-5" />
            {this.getParamValue('title', '')}
          </h4>

          <RichEditorContent content={'<p class="' + textClass + '">' + this.getParamValue('content', '') + '</p>'} />

          <SignupForm
            {...this.props}
            onSubmit={this.onSubmit}
            buttonTitle={this.getParamValue('buttonTitle', messages.signup)}
          />
        </div>

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementSignupForm.defaultProps = {
  index: '0',
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (args, urlPrefix) => {
      formStatusDispatchHelper('cmsSignupForm', urlPrefix + 'login/register', args, 'post', dispatch);
    },
  };
};

export default connect(
  null,
  mapDispatchToProps
)(CmsElementSignupForm);
