import { find as _find } from 'lodash';

import { FORM_FINISHED } from 'actions/FormStatusActions';

const initialState = {
  isLoading: false,
  errors: 0,
  events: {
    events: [],
  },
};

export default function clientTimeline(state = initialState, action) {
  switch (action.type) {
    case 'RESET_CLIENT_TIMELINE': {
      return {
        ...state,
        isLoading: false,
        errors: 0,
        events: { events: [] },
      };
    }

    case 'FETCH_CLIENT_TIMELINE_PENDING': {
      return {
        ...state,
        isLoading: true,
        errors: 0,
      };
    }

    case 'FETCH_CLIENT_TIMELINE_FULFILLED': {
      const events = { ...state.events };
      events.events = state.events.events.slice();

      for (let i = 0; i < action.payload.data.events.length; i++) {
        if (_find(events.events, ['id', action.payload.data.events[i].id]) === undefined) {
          // load only when the id is not on the list
          events.events.push(action.payload.data.events[i]);
        }
      }
      events.count = action.payload.data.count;
      events.limit = action.payload.data.limit;
      events.offset = action.payload.data.offset;

      return {
        ...state,
        isLoading: false,
        errors: 0,
        events,
      };
    }

    case 'FETCH_CLIENT_TIMELINE_REJECTED': {
      return {
        ...state,
        isLoading: false,
        errors: action.payload.response ? action.payload.response.data : 400,
        events: { events: [] },
      };
    }

    case FORM_FINISHED: {
      if (action.payload.formName === 'noteNew') {
        const event = {
          id: Math.random()
            .toString(36)
            .substr(2, 9),
          type: 'NOTE_SAVED',
          cdate: action.payload.response.data.cdate,
          data: { ...action.payload.response.data },
        };

        const events = { ...state.events };
        events.events = state.events.events.slice();
        events.events.splice(0, 0, event);

        return { ...state, events };
      }

      if (action.payload.formName === 'messageNew') {
        const event = {
          id: Math.random()
            .toString(36)
            .substr(2, 9),
          type: 'MAIL_NEW_MESSAGE',
          cdate: action.payload.response.data.cdate,
          data: { ...action.payload.response.data },
        };

        const events = { ...state.events };
        events.events = state.events.events.slice();
        events.events.splice(0, 0, event);

        return { ...state, events };
      }

      if (action.payload.formName === 'textMessageNew') {
        const event = {
          id: Math.random()
            .toString(36)
            .substr(2, 9),
          type: 'TEXT_MESSAGE_SENT',
          cdate: action.payload.response.data.cdate,
          data: { ...action.payload.response.data },
        };

        const events = { ...state.events };
        events.events = state.events.events.slice();
        events.events.splice(0, 0, event);

        return { ...state, events };
      }

      break;
    }
  }

  return state;
}
