import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';
import { Row, Col, Button } from 'react-bootstrap';

import { getSalutationOptions } from 'helpers/clients';
import { elementSource, elementTarget } from './elementDragUtils';

import FormInput from 'components/forms/FormInput';
import FormAutocomplete from 'components/forms/FormInput';
import FormTextarea from 'components/forms/FormTextarea';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';
import RichEditorContent from 'components/RichEditorContent';

import ModalPrivacyPolicy from 'components/modals/ModalPrivacyPolicy';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
export default class CmsElementLeadForm extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
      showPrivacyPolicy: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  showPrivacyPolicy = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showPrivacyPolicy: true });
  };

  closePrivacyPolicy = () => {
    this.setState({ showPrivacyPolicy: false });
  };

  getPrivacyPolicyText = () => {
    const {
      intl: { messages },
    } = this.props;

    const text = messages.contact_agreement_text;

    const matches = [];
    text.replace(/({{1}[a-z_]+}{1}){1}/g, (match, g1, g2) => {
      matches.push({ match, pos: g2 });
    });

    const res = [];
    let currentIndex = 0;
    matches.map(match => {
      switch (match.match) {
        // inject {data_use}
        case '{data_use}':
          res.push(text.substring(currentIndex, match.pos));
          res.push(
            <a href="#dataUse" onClick={this.showPrivacyPolicy}>
              {messages.signup_agreement_text_data_use}
            </a>
          );

          currentIndex = match.pos + 10;
          break;
      }
    });

    res.push(text.substring(currentIndex));

    return res;
  };

  render() {
    const {
      intl: { messages },
      connectDropTarget,
      connectDragSource,
      connectDragPreview,
    } = this.props;
    const { shown, showPrivacyPolicy } = this.state;

    const withAddress = this.getParamValue('requireAddress', 'no') === 'yes';
    const withEmailContact = this.getParamValue('requireEmailContact') === 'yes';
    const withPhoneContact = this.getParamValue('requirePhoneContact') === 'yes';
    const withMessage = this.getParamValue('requireMessage') === 'yes';

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const textClass = 'text-' + this.getParamValue('mainColor', 'master');

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        className={'ms-lead-form-content ' + shownClass + this.getCssClasses()}
        style={{ ...this.getBackgroundStyle() }}
        onClick={this.showSettings}
      >
        {this.getElementStyle()}

        <div className="lead-form-container">
          <h4 className={'no-margin p-b-5 ' + textClass}>{this.getParamValue('title', '')}</h4>

          <RichEditorContent content={'<p class="' + textClass + '">' + this.getParamValue('infosTop', '') + '</p>'} />

          <div className="form-group-attached">
            <Row className="clearfix no-margin">
              <Col sm={6}>
                <FormAutocomplete
                  label={messages.salutation}
                  name="salutation"
                  value="mr"
                  options={getSalutationOptions(messages)}
                />
              </Col>
            </Row>
            <Row className="clearfix no-margin">
              <Col sm={6}>
                <FormInput label={messages.title} name="title" />
              </Col>
            </Row>

            <Row className="clearfix no-margin">
              <Col sm={6}>
                <FormInput label={messages.first_name} />
              </Col>
              <Col sm={6}>
                <FormInput label={messages.last_name} />
              </Col>
            </Row>

            {withAddress && (
              <React.Fragment>
                <FormInput label={messages.street} />

                <Row className="clearfix no-margin">
                  <Col sm={6}>
                    <FormInput label={messages.house_number} />
                  </Col>
                  <Col sm={6}>
                    <FormInput label={messages.apartment_number} />
                  </Col>
                </Row>

                <Row className="clearfix no-margin">
                  <Col sm={6}>
                    <FormInput label={messages.zip_code} />
                  </Col>
                  <Col sm={6}>
                    <FormInput label={messages.city} />
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </div>

          {(withEmailContact || withPhoneContact) && (
            <div className="m-t-15 form-group-attached">
              {withEmailContact && <FormInput label={messages.email} />}
              {withPhoneContact && <FormInput type="number" label={messages.phone} />}
            </div>
          )}

          {withMessage && (
            <div className="m-t-15 form-group-attached">
              <FormTextarea label={messages.message} name="message" />
            </div>
          )}

          <RichEditorContent
            content={'<p class="' + textClass + '">' + this.getParamValue('infosBottom', '') + '</p>'}
          />

          <p className={textClass}>{this.getPrivacyPolicyText()}</p>
          <div className="text-right">
            <Button
              className={'call-to-action btn-animated from-left fa fa-arrow-right '}
              bsStyle="primary"
              onClick={this.onClick}
            >
              <span>{this.getParamValue('buttonTitle', '')}</span>
            </Button>
          </div>
        </div>

        {this.getAppendHtml()}

        <ModalPrivacyPolicy show={showPrivacyPolicy} close={this.closePrivacyPolicy} />
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}
