import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage, FormattedNumber } from 'react-intl';
import { ButtonGroup, Button, Alert } from 'react-bootstrap';

class StepECarCheckoutSettings extends React.Component {
  // calculate shipping costs in euro
  calculateTransportCost = () => {
    const { checkout } = this.props;

    if (checkout.shipping !== null && checkout.shipping.distance > 0) {
      const initCosts = 140;
      // 1.8 is indicator that manually increase the price because of potential costs of returning car.
      // It's not possible to charge the customer for the returning costs when he will return the car, therefore the general transport price is higher.
      const returnRiskIndicator = 1.8;
      // The 50 means that every 100km costs 50EUR
      const priceFor100euro = 50;

      const level = parseInt(Math.ceil(checkout.shipping.distance / (100 * 1000)), 10);
      return returnRiskIndicator * (level * priceFor100euro + initCosts);
    }

    return 0;
  };

  changeShippingMethod = method => {
    const { checkout, setCheckoutState } = this.props;

    switch (method) {
      case 'pickup': {
        setCheckoutState({
          shipping: { ...checkout.shipping, method, cost: 0 },
        });
        break;
      }

      case 'transport': {
        // do not allow cash payment when the car will be transported to the client
        // reason is, kroschke does not cover cash payments
        let { carPayment } = checkout;
        if (carPayment === 'cash') {
          carPayment = 'wireTransfer';
        }

        setCheckoutState({
          shipping: {
            ...checkout.shipping,
            method,
            cost: this.calculateTransportCost(),
          },
          carPayment,
        });
        break;
      }
    }
  };

  getDistanceInKm = () => {
    const { checkout } = this.props;
    return parseInt(Math.ceil(checkout.shipping.distance / 1000), 10);
  };

  isRegistrationEnabled = () => {
    const { checkout } = this.props;

    for (let i = 0; i < checkout.services.length; i++) {
      if (checkout.services[i].name === 'carRegistration') {
        return checkout.services[i].isActive;
      }
    }
  };

  render() {
    const {
      intl: { messages },
      checkout,
      detectingDistance,
      projectConfig,
    } = this.props;

    const transportNotPossibleError =
      !detectingDistance && ((checkout.shipping !== null && !checkout.shipping.distance) || checkout.shipping === null);
    let transportNotPossibleDueToTooShortDrivingDistance = false;

    const isPickupActive =
      checkout.shipping === null || !checkout.shipping.method || checkout.shipping.method === 'pickup';
    let isTransportDisabled =
      detectingDistance ||
      transportNotPossibleError ||
      !this.isRegistrationEnabled() ||
      checkout.userData.country !== 'DE';

    // if the transport is possbile, but the driving distance is less than 100km, disable the car transport
    if (!transportNotPossibleError && this.getDistanceInKm() < 100) {
      isTransportDisabled = true;
      transportNotPossibleDueToTooShortDrivingDistance = true;
    }

    return (
      <div>
        {transportNotPossibleError && checkout.userData.country === 'DE' && (
          <Alert bsStyle="warning">{messages.we_could_not_calculate_route_to_address}</Alert>
        )}

        {checkout.userData.country !== 'DE' && (
          <Alert bsStyle="info">{messages.car_transport_only_available_in_germany}</Alert>
        )}

        {transportNotPossibleDueToTooShortDrivingDistance && (
          <Alert bsStyle="info">{messages.car_transport_distance_too_short}</Alert>
        )}

        <ButtonGroup vertical block>
          <Button type="button" bsSize="lg" active={isPickupActive} onClick={() => this.changeShippingMethod('pickup')}>
            <i class="fa fa-user m-r-10" /> {messages.i_will_pick_up_my_car}
          </Button>
          <Button
            type="button"
            bsSize="lg"
            active={checkout.shipping && checkout.shipping.method === 'transport'}
            disabled={isTransportDisabled}
            onClick={() => this.changeShippingMethod('transport')}
          >
            <i class="fa fa-truck m-r-10" /> {messages.i_want_my_car_delivered}
            {detectingDistance && <i class="m-l-10 fa fa-circle-notch fa-spin" />}
            {!isTransportDisabled && !detectingDistance && (
              <span class="m-l-10">
                +{' '}
                <FormattedNumber
                  value={this.calculateTransportCost()}
                  style="currency"
                  currency={projectConfig.data.currency}
                />
              </span>
            )}
          </Button>
        </ButtonGroup>

        {checkout.shipping && checkout.shipping.method === 'transport' ? (
          <div class="p-t-30 p-l-15">
            <FormattedMessage id="we_calculated_route_from_car_to_you" values={{ distance: this.getDistanceInKm() }} />{' '}
            <br />
            <br />
            {messages.service_includes}
            <br />
            <i class="fa fa-check-circle" /> {messages.driver_will_call_you_and_schedule_delivery}
            <br />
            <i class="fa fa-check-circle" /> {messages.your_car_will_be_washed}
            <br />
            <i class="fa fa-check-circle" /> {messages.your_car_will_be_fully_tanked}
            <br />
            <i class="fa fa-check-circle" /> {messages.you_will_get_quick_tour_around_car}
            <br />
          </div>
        ) : (
          <div class="p-t-30">{messages.you_can_pick_up_car_yourself}</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    projectConfig: state.data.projectConfig,
  };
};

export default injectIntl(connect(mapStateToProps)(StepECarCheckoutSettings));
