import axios from 'axios';

export const RESET_CLIENT_TIMELINE = 'RESET_CLIENT_TIMELINE';
export const FETCH_CLIENT_TIMELINE = 'FETCH_CLIENT_TIMELINE';

export const FETCH_COMPANY_DATA_TIMELINE = 'FETCH_COMPANY_DATA_TIMELINE';
export const RESET_COMPANY_DATA_TIMELINE = 'RESET_COMPANY_DATA_TIMELINE';

export const FETCH_CLIENT_ANCESTORS = 'FETCH_CLIENT_ANCESTORS';
export const FETCH_CLIENT_ANCESTORS_PENDING = 'FETCH_CLIENT_ANCESTORS_PENDING';
export const FETCH_CLIENT_ANCESTORS_FULFILLED = 'FETCH_CLIENT_ANCESTORS_FULFILLED';

export function resetClientTimeline() {
  return {
    type: RESET_CLIENT_TIMELINE,
    payload: {},
  };
}

export function fetchClientTimeline(clientId, offset, limit) {
  return {
    type: FETCH_CLIENT_TIMELINE,
    payload: axios.get(
      '/api/clients/' + clientId + '/timeline.json?offset=' + offset + '&limit=' + limit + '&_locale=' + globalLocale
    ),
  };
}

/**
 * Get array of ancestors to display in breadrumbs
 *
 * @param {string} clientId
 * @returns {array}
 */
export function fetchClientAncestors(clientId) {
  return {
    type: FETCH_CLIENT_ANCESTORS,
    payload: axios.get(`/api/clients/${clientId}/ancestors.json`).then(({ data }) => {
      return data;
    }),
  };
}

export function resetCompanyDataTimeline() {
  return {
    type: RESET_COMPANY_DATA_TIMELINE,
    payload: {},
  };
}

export function fetchCompanyDataTimeline(clientId, offset, limit) {
  return {
    type: FETCH_COMPANY_DATA_TIMELINE,
    payload: axios.get(
      '/api/clients/' + clientId + '/companydatatimeline.json?offset=' + offset + '&limit=' + limit + '&_locale=' + globalLocale
    ),
  };
}
