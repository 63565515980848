import React from 'react';
import { Overlay, Popover } from 'react-bootstrap';

class FormRadio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValue: props.value !== null && props.value !== undefined ? props.value : '',
      value: props.value !== null && props.value !== undefined ? props.value : '',
      focused: false,
    };

    this.inputRef = React.createRef();
  }

  checkFocused = () => {
    return this.state.focused ? 'focused ' : '';
  };

  checkRequired = () => {
    return this.props.meta.validate.rules.indexOf('required') !== -1 ? 'required ' : '';
  };

  checkError = () => {
    return this.props.validation.valid ? '' : 'has-error ';
  };

  // check if user may read this field
  checkAccess = () => {
    return this.props.meta.acl.read;
  };

  // check if user may write to this field
  checkReadOnly = () => {
    return !this.props.meta.acl.write;
  };

  catchFocus = () => {
    this.inputRef.current.focus();
  };

  onChange = value => {
    if (value === 'true') {
      value = true;
    } else if (value === 'false') {
      value = false;
    }

    this.setState({ value });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.onChange(this.props.name, value);
    }, 150);
  };

  checkDisabled = () => {
    const { disabled, meta } = this.props;
    if (disabled) {
      return true;
    }
    if (!meta.acl.write) {
      return true;
    }
    return false;
  };

  setValue = value => {
    this.setState({ value });
    this.onChange(value);
  };

  render() {
    const { label, name, validation, options } = this.props;
    const { focused, value } = this.state;

    return (
      <div
        className={
          'form-group form-group-default ' +
          this.checkFocused() +
          this.checkRequired() +
          this.checkError() +
          (this.checkDisabled() ? 'disabled ' : '')
        }
        onClick={this.catchFocus}
      >
        <label>{label}</label>
        {options.map((option, i) => {
          return (
            <div key={name + option.value} className="radio radio-success">
              <input
                id={name + option.value}
                ref={i === 0 ? this.inputRef : null}
                value={option.value}
                type="radio"
                name={name}
                onChange={e => this.onChange(e.target.value)}
                onFocus={() => this.setState({ focused: true })}
                onBlur={() => this.setState({ focused: false })}
                disabled={this.checkDisabled()}
                checked={option.value === value}
              />
              <label
                for={name + option.value}
                className="font-heading fs-13"
                style={{ textTransform: 'none', fontWeight: 'normal' }}
              >
                {option.label}
              </label>
            </div>
          );
        })}

        {!validation.valid && (
          <Overlay show={focused} target={this.inputRef.current} placement="top">
            <Popover className="popover-modal" id={`error_${name}_${label}`}>
              {validation.helpText}
            </Popover>
          </Overlay>
        )}
      </div>
    );
  }
}
FormRadio.defaultProps = {
  label: '',
  meta: { validate: { rules: '' }, acl: { read: true, write: true } },
  validation: { valid: true },
  value: '',
  onChange: () => {},
  disabled: false,
  options: [],
  type: 'text',
};

export default FormRadio;
