import { MODAL_OPENED, MODAL_CLOSED } from 'actions/ModalsActions';

const initialState = {
  isOpened: false,
};

export default function cart(state = initialState, action) {
  switch (action.type) {
    case MODAL_OPENED: {
      return { ...state, isOpened: true };
    }

    case MODAL_CLOSED: {
      return { ...state, isOpened: false };
    }
  }

  return state;
}
