import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import {  S3_LINKS_DIV } from 'js/constants';

class EditionPreviews extends React.Component {
  static defaultProps = {
    editionPreviews: [],
  };

  constructor(props) {
    super(props);
    let initialPreview = S3_LINKS_DIV.TEMPLATE_INITIAL_PREVIEW;
    if (typeof props.editionPreviews[0] !== 'undefined') {
      initialPreview = props.editionPreviews[0].key;
    }
    this.state = {
      showPreview: initialPreview,
    };
  }

  changePreview = key => {
    this.setState({
      showPreview: key,
    });
  };

  render() {
    const { editionPreviews } = this.props;

    const { showPreview } = this.state;

    const previews =
      editionPreviews.length > 0
        ? editionPreviews
        : [
            {
              key: S3_LINKS_DIV.TEMPLATE_INITIAL_PREVIEW,
            }
          ];

    return (
      <div className="preview row">
        <div className="preview-image">
          <div className="preview-wrap">
            <img src={ showPreview} />
          </div>
        </div>
        <div className="preview-thumbs">
          {previews.map((preview, i) => {
            if (i < 3) {
              if (preview.key !== showPreview) {
                return (
                  <div
                    key={preview.key}
                    className="preview-thumb"
                    onClick={() => {
                      this.changePreview(preview.key);
                    }}
                  >
                    <img
                      src={ preview.key}
                      alt={'Preview ' + i}
                    />
                  </div>
                );
              }
              return (
                <div key={preview.key} className="preview-thumb active">
                  <img
                    src={ preview.key}
                    alt={'Preview ' + i}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default withRouter(injectIntl(connect(mapStateToProps)(EditionPreviews)));
