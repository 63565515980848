import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { dispatchResizeEvent } from 'helpers/window';
import { CHEDRI_CMS } from 'js/constants';

import Data from 'components/Data';
import CmsPage from 'components/CmsPage';
import Modal from 'components/Modal';

import Loader from 'components/layout/Loader';

class LegalChedriPrivacyPolicy extends React.Component {
  componentDidUpdate = prevProps => {
    const { cmsPage } = this.props;

    if (!cmsPage.pending && prevProps.cmsPage.pending) {
      dispatchResizeEvent();
    }
  };

  render() {
    const {
      intl: { messages },
      intl,
      show,
      close,
      cmsPage,
    } = this.props;

    const cmsPageIds = CHEDRI_CMS.chedriCancellationPolicy;
    const cmsPageId = cmsPageIds[intl.locale] ? cmsPageIds[intl.locale] : cmsPageIds.de;

    return (
      <Modal show={show} onHide={close} bsSize="lg" class="full-width">
        <Modal.Header closeButton>
          <Modal.Title>{messages.cancellation_policy}</Modal.Title>
        </Modal.Header>
        {show && (
          <Modal.Body>
            <Data dataName="cmsPage" url={'/api/pages/' + cmsPageId + '.json'} data={cmsPage} />

            <div class="themed">
              {cmsPage.pending ? (
                <Loader />
              ) : (
                <CmsPage data={cmsPage.data} doUpdateActiveCms={false} doUpdateDocMeta={false} />
              )}
            </div>
          </Modal.Body>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    cmsPage: state.data.cmsPage,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(LegalChedriPrivacyPolicy)));
