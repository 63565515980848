import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { oauthError } from 'actions/LoginActions';
import { updateActiveCms } from 'actions/LayoutActions';
import { parseQs, stringifyQs } from 'helpers/http';

import Data from 'components/Data';
import CmsPage from 'components/CmsPage';

import Loader from 'components/layout/Loader';

class Login extends React.Component {
  componentWillUnmount = () => {
    this.props.updateActiveCms(null);
  };

  componentDidMount() {
    const query = parseQs(this.props.location.search);

    if (query.error) {
      let uriParts = this.props.location.pathname.split('/');
      this.props.onOauthError(uriParts[2], query.error);
    }

    this.props.updateActiveCms('cmsSystemPageLogin');
  }

  render() {
    const { cmsPage, height } = this.props;

    return (
      <React.Fragment>
        {!cmsPage.data.id && <Data dataName="cmsSystemPageLogin" url="/api/pages/login.json" data={cmsPage} />}

        {cmsPage.pending ? (
          <div className="content relative" style={{ height: `${height}px` }}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%)' }}>
              <Loader />
            </div>
          </div>
        ) : (
          <CmsPage data={cmsPage.data} doUpdateActiveCms={false} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    height: state.layout.height,
    user: state.login.user,
    forwardUrl: state.login.forwardUrl,
    appIntl: state.intl,
    cmsPage: state.data.cmsSystemPageLogin,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onOauthError: (service, error) => {
      dispatch(oauthError(service, error));
    },
    updateActiveCms: activeCms => {
      dispatch(updateActiveCms(activeCms));
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
);
