import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';
import { throttle as _throttle } from 'lodash';

import { dispatchResizeEvent } from 'helpers/window';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

import RichEditorContent from 'components/RichEditorContent';

import { elementSource, elementTarget } from './elementDragUtils';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
class CmsElementHero extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      translateY: 0,
      opacity: 1,
      shown: false,
    };
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.parallax);

    const backgroundUrl = this.getParamValue('backgroundUrl');
    if (backgroundUrl) {
      const img = new Image();
      img.onload = () => {
        dispatchResizeEvent();
      };
      img.src = this.getParamValue('backgroundUrl');
    }

    this.__componentDidMount();
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.parallax);
    this.__componentWillUnmount();
  };

  parallax = () => {
    _throttle(() => {
      const container = ReactDOM.findDOMNode(this.container);
      const containerRectangle = container.getBoundingClientRect();

      const containerHeight = containerRectangle.bottom - containerRectangle.top;

      const opacity =
        (containerHeight - (containerRectangle.top < 0 ? Math.abs(containerRectangle.top) : 0)) / containerHeight;

      this.setState({
        translateY: (1 - opacity) * containerHeight * 0.3,
        opacity: opacity * 1.5,
      });
    }, 50)();
  };

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  render() {
    const { connectDropTarget, connectDragSource, connectDragPreview } = this.props;
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const content = this.getParamValue('content', '');

    const parallaxData = this.getParallaxData();

    const headerStyle = {
      //      opacity,
      //      transform: 'translateY(' + translateY + 'px)',
      height: this.getParamValue('fullHeight', 'no') === 'yes' ? this.props.layout.height + 'px' : 'auto',
    };

    const innerContent = (
      <div class="full-height no-padding" ref={container => (this.container = container)} onClick={this.showSettings}>
        <div
          id={this.getMainId()}
          class={'ms-hero container-fluid container-fixed-lg sm-p-l-20 sm-p-r-20 ' + shownClass + this.getCssClasses()}
          style={{
            ...headerStyle,
            ...this.getBackgroundStyle(),
          }}
        >
          {this.getElementStyle()}

          <div class="content inner full-height">
            <div class="container-xs-height full-height">
              <div class={'col-xs-height ' + (this.getParamValue('vAlignMiddle', false) ? 'col-middle ' : '')}>
                {content !== '' ? (
                  <Grid>
                    <Row>
                      <Col xs={12} class={'text-' + this.getParamValue('mainColor', 'master')}>
                        <RichEditorContent content={'<div>' + content + '</div>'} />
                      </Col>
                    </Row>
                  </Grid>
                ) : (
                  <div class={'p-t-15 p-b-15 text-center text-' + this.getParamValue('mainColor', 'master')}>
                    <i class="fa fa-newspaper-o fa-4x" />
                  </div>
                )}
              </div>
            </div>
          </div>

          {this.getAppendHtml()}
        </div>
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout,
  };
};

export default connect(mapStateToProps)(CmsElementHero);
