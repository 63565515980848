import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Grid, Row, Col } from 'react-bootstrap';

import { generateMetaTags } from 'helpers/metaTags';

import DocMeta from 'components/DocMeta';

import { updateActiveCms } from 'actions/LayoutActions';

import Data from 'components/Data';
import CmsPage from 'components/CmsPage';

import Loader from 'components/layout/Loader';

class CompanySignupComplete extends React.Component {
  // check if the signup formStatus response is set
  // if it's not, redirect the user to the start page
  componentDidMount = () => {
    const { appIntl, history, formStatus } = this.props;

    if (!formStatus.response) {
      history.replace(appIntl.urlPrefix);
    }

    this.props.updateActiveCms('cmsSystemPageRegistrationSuccess');
  };

  componentWillUnmount = () => {
    this.props.updateActiveCms(null);
  };

  render() {
    const {
      intl: { messages },
      cmsPage,
      height,
    } = this.props;

    return (
      <React.Fragment>
        {cmsPage.data && !cmsPage.data.id && (
          <Data dataName="cmsSystemPageRegistrationSuccess" url="/api/pages/registrationsuccess.json" data={cmsPage} />
        )}

        {cmsPage.pending ? (
          <div className="content relative" style={{ height: `${height}px` }}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%)' }}>
              <Loader />
            </div>
          </div>
        ) : cmsPage.data ? (
          <CmsPage data={cmsPage.data} doUpdateActiveCms={false} />
        ) : (
          <DocMeta tags={generateMetaTags(messages.meta.order_complete)}>
            <div class={typeof widget !== 'undefined' && widget ? '' : 'content'}>
              <Grid>
                <Row>
                  <Col xs={12}>
                    <h1 class="bold text-center m-t-60">{messages.thank_you}.</h1>
                    <h2 class="text-center m-t-30">{messages.your_company_was_successfully_registered}</h2>
                  </Col>
                </Row>

                <div class="m-t-40 m-b-40">
                  <hr />
                </div>

                <Row class="">
                  <Col xs={2} sm={1}>
                    <div class="text-right">
                      <i class="fas fa-paper-plane fa-3x" />
                    </div>
                  </Col>
                  <Col xs={10} sm={11}>
                    <h4 class="m-t-0">{messages.check_your_email}</h4>
                    <p>{messages.check_email_for_more_instructions_about_your_company_registration}</p>
                  </Col>
                </Row>
              </Grid>
            </div>
          </DocMeta>
        )}
      </React.Fragment>
    );
  }
}
CompanySignupComplete.defaultProps = {};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    formStatus: state.formStatus.companySignup,
    height: state.layout.height,
    cmsPage: state.data.cmsSystemPageRegistrationSuccess,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateActiveCms: activeCms => {
      dispatch(updateActiveCms(activeCms));
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CompanySignupComplete)
  )
);
