import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import {
  getRangeInTimezone,
  isInHourRange,
  isTooLate,
  isTooSoon,
  getHour,
  getMinute,
  isAmPm,
  getAmPm,
  getMinDateTimeInRange,
  getMaxDateTimeInRange,
} from 'helpers/dateTime';

export default class TimePicker extends React.PureComponent {
  constructor(props) {
    super(props);

    moment.locale(globalLocale);
    const localeData = moment.localeData();

    this.state = {
      value: moment.tz(props.value, props.returnTz),
      format: localeData.longDateFormat('LT'),
    };
  }

  getAmPm = () => {
    const { returnTz, displayTz } = this.props;
    const { value } = this.state;

    return getAmPm(moment.tz(value, returnTz).tz(displayTz));
  };

  addHour = e => {
    e.preventDefault();
    this.change('hour', 'add');
  };

  addMinute = e => {
    e.preventDefault();
    this.change('minute', 'add');
  };

  subHour = e => {
    e.preventDefault();
    this.change('hour', 'sub');
  };

  subMinute = e => {
    e.preventDefault();
    this.change('minute', 'sub');
  };

  getHour = dateTime => {
    const { returnTz, displayTz } = this.props;

    return getHour(moment.tz(dateTime, returnTz).tz(displayTz));
  };

  getMinute = dateTime => {
    const { returnTz, displayTz } = this.props;

    return getMinute(moment.tz(dateTime, returnTz).tz(displayTz));
  };

  changeAmPm = e => {
    e.preventDefault();

    let newValue = moment.tz(this.state.value, this.props.displayTz);
    if (this.getAmPm() === 'AM') {
      // add 12 hours
      newValue = newValue.add(moment.duration(12, 'hours'));
      this.setState({ value: newValue.toISOString() });
    } else {
      // substract 12 hours
      newValue = newValue.subtract(moment.duration(12, 'hours'));
      this.setState({ value: newValue.toISOString() });
    }

    // call allowChange props function
    if (!this.props.allowChange || this.props.allowChange(this.state.value, newValue.toISOString())) {
      // if change is allowed, change the state and call the onChange props function
      this.setState({ value: newValue.tz(this.props.returnTz).toISOString() }, () => {
        if (this.props.onChange) {
          this.props.onChange(newValue.tz(this.props.returnTz).toISOString());
        }
      });
    }
  };

  change = (what, action, amount = 1) => {
    const { returnTz, displayTz, validRange: utcValidRange } = this.props;
    const { value } = this.state;
    const validRange = getRangeInTimezone(utcValidRange, displayTz);

    // calculate new value in current timezone
    let newValue = moment.tz(value, displayTz);
    if (what === 'hour') {
      newValue.set({ minute: 0 });
    }

    // check how we need to adjust the value
    // base it on steps (props) for minutes and hours
    let adjustment = this.props[what + 'Steps'];
    if (action === 'add') {
      if (newValue.get(what) % adjustment !== 0) {
        adjustment -= newValue.get(what) % adjustment;
      }
      newValue.add(moment.duration(amount * adjustment, what));
    } else {
      if (newValue.get(what) % adjustment !== 0) {
        adjustment = newValue.get(what) % adjustment;
      }
      newValue.subtract(moment.duration(amount * adjustment, what));
    }

    if (isTooSoon(newValue, validRange)) {
      newValue = getMinDateTimeInRange(newValue, validRange);
    } else if (isTooLate(newValue, validRange)) {
      newValue = getMaxDateTimeInRange(newValue, validRange);
    } else if (!isInHourRange(newValue, validRange)) {
      this.change(what, action, amount + 1);
      return;
    }

    const newValueString = newValue.tz(returnTz).toISOString();

    // call allowChange props function
    if (!this.props.allowChange || this.props.allowChange(value, newValueString)) {
      // if change is allowed, change the state and call the onChange props function
      this.setState({ value: newValueString }, () => {
        if (this.props.onChange) {
          this.props.onChange(newValueString);
        }
      });
    }
  };

  componentDidUpdate = () => {
    const { value: propsValue } = this.props;
    const { value: stateValue } = this.state;

    if (propsValue !== stateValue) {
      this.setState({ value: propsValue });
    }
  };

  render() {
    const { value } = this.state;
    const showAmPm = isAmPm();

    return (
      <div className="timepicker-picker" style={{ display: 'block' }}>
        <table className="table-condensed">
          <tbody>
            <tr>
              <td>
                <a href="#addHour" className="btn" onClick={this.addHour}>
                  <span className="fas fa-chevron-up" />
                </a>
              </td>
              <td className="separator" />
              <td>
                <a href="#addMinute" className="btn" onClick={this.addMinute}>
                  <span className="fas fa-chevron-up" />
                </a>
              </td>
              {showAmPm ? <td className="separator" /> : null}
            </tr>
            <tr>
              <td className="text-center">
                <span className="timepicker-hour"> {this.getHour(value)} </span>
              </td>
              <td className="separator">:</td>
              <td className="text-center">
                <span className="timepicker-minute"> {this.getMinute(value)} </span>
              </td>
              {showAmPm ? (
                <td>
                  <button type="button" className="btn btn-info" onClick={this.changeAmPm}>
                    {this.getAmPm()}
                  </button>
                </td>
              ) : null}
            </tr>
            <tr>
              <td>
                <a href="#subHour" className="btn" onClick={this.subHour}>
                  <span className="fas fa-chevron-down" />
                </a>
              </td>
              <td className="separator" />
              <td>
                <a href="#subMinute" className="btn" onClick={this.subMinute}>
                  <span className="fas fa-chevron-down" />
                </a>
              </td>
              {showAmPm ? <td className="separator" /> : null}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
TimePicker.propTypes = {};
TimePicker.defaultProps = {
  displayTz: 'UTC',
  returnTz: 'UTC',
  disabled: false,
  locale: 'en',
  minuteSteps: 5,
  hourSteps: 1,
  validRange: { start: 0, end: 24 }, // [{ start: 8, end: 12 }, { start: 14, end: 17 }]
};
