import PropTypes from 'prop-types';

export const LabelValuePairShape = {
  key: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.any,
};

export const CouponShape = PropTypes.shape({
  type: PropTypes.string,
  name: PropTypes.string,
  budget: PropTypes.number,
  currency: PropTypes.string,
  description: PropTypes.string,
  max_company_budget: PropTypes.number,
  max_order_discount: PropTypes.number,
  max_user_budget: PropTypes.number,
  min_order_value: PropTypes.number,
  percent_discount: PropTypes.number,
  value_discount: PropTypes.number,
  running_company_budget: PropTypes.number,
  running_user_budget: PropTypes.number,
});

export const ImageShape = PropTypes.shape({
  source: PropTypes.string,
  thumbnail: PropTypes.string,
  tooltip: PropTypes.string,
});

export const CartItem = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  campaign: PropTypes.any,
  lastModified: PropTypes.instanceOf(Date),
  lastStored: PropTypes.instanceOf(Date),
  coupons: PropTypes.arrayOf(CouponShape),
  details: PropTypes.arrayOf(PropTypes.shape(LabelValuePairShape)),
  images: PropTypes.arrayOf(ImageShape),
  confirmed: PropTypes.bool,
});

export const Cart = PropTypes.shape({
  items: PropTypes.arrayOf(CartItem),
});

export const ValidationResult = PropTypes.shape({
  rule: PropTypes.string,
  valid: PropTypes.bool,
  severity: PropTypes.string,
  messageKey: PropTypes.string,
  helpText: PropTypes.string,
});

export function equalTo(component) {
  return function validate(propValue, key, componentName, location, propFullName) {
    const prop = propValue[key];
    if (prop.type !== component) {
      return new Error(
        `Invalid prop '${propFullName}' supplied to '${componentName}' it needs to be a '${component.name}' component.`
      );
    }
  };
}
