export default function passwordRepeat(entity) {
  let isValid = true,
    helpTextTranslationIndex = 'second_password_incorrect',
    fields = [];

  if (entity.getPassword != entity.getRepeatPassword) {
    isValid = false;
    fields = [{ name: 'password', fieldTree: [] }, { name: 'repeatPassword', fieldTree: [] }];
  }

  return {
    valid: isValid,
    rule: 'passwordRepeat',
    helpTextTranslationIndex,
    fields,
  };
}
