import axios from 'axios';

import { toQueryString } from 'helpers/http';

export const ENTITY_META_PENDING = 'ENTITY_META_PENDING';
export const ENTITY_META_SUBMIT = 'ENTITY_META_SUBMIT';
export const ENTITY_META_FULFILLED = 'ENTITY_META_FULFILLED';
export const ENTITY_META_REJECTED = 'ENTITY_META_REJECTED';

export function entityMetaPending(entityMetaIdentifier, data) {
  return {
    type: ENTITY_META_PENDING,
    payload: { entityMetaIdentifier, ...data },
  };
}

export function entityMetaSend(url, data, finishedCallback, errorCallback, method = 'get') {
  let fullUrl = url;
  if (fullUrl.indexOf('?') === -1) {
    fullUrl += '?';
  }

  if (method === 'get' && data !== undefined && data) {
    fullUrl += toQueryString(data) + '&';
  }
  fullUrl += '_locale=' + globalLocale;

  return {
    type: ENTITY_META_SUBMIT,
    payload: axios({
      method: method,
      url: fullUrl,
      data: { ...data, _locale: globalLocale },
    })
      .then(finishedCallback)
      .catch(errorCallback),
  };
}

export function entityMetaFulfilled(entityMetaIdentifier, response) {
  return {
    type: ENTITY_META_FULFILLED,
    payload: {
      entityMetaIdentifier,
      response,
    },
  };
}

export function entityMetaRejected(entityMetaIdentifier, response) {
  return {
    type: ENTITY_META_REJECTED,
    payload: {
      entityMetaIdentifier,
      response: response.response,
    },
  };
}
