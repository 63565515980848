import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { isArray as _isArray } from 'lodash';
import { generateMetaTags } from 'helpers/metaTags';
import DocMeta from 'components/DocMeta';

class OrderComplete extends React.Component {
  // check if the order formStatus response is set
  // if it's not, redirect the user to the start page
  componentDidMount = () => {
    const { appIntl, history, formStatus } = this.props;

    if (formStatus.response === null || !_isArray(formStatus.response)) {
      history.replace(appIntl.urlPrefix);
    }
  };

  render() {
    const {
      intl: { messages },
      appIntl,
    } = this.props;

    return (
      <DocMeta tags={generateMetaTags(messages.meta.order_complete)}>
        <div
          class={typeof widget !== 'undefined' && widget ? '' : 'content'}
          style={{ height: '100%', width: '100%', position: 'absolute' }}
        >
          <div className="container_1">
            <div className="container_1_1">
              <div className="container_1_1_1">
                <h1>{messages.order_complete.good_luck}</h1>
              </div>

              <div className="container_1_1_2">
                <h3>{messages.order_complete.order_complete}</h3>
                <p>
                  <b>{messages.order_complete.pls_check_inbox}</b> {messages.order_complete.order_info}
                  <a href={appIntl.urlPrefix + 'account/orders'}> {messages.order_complete.orders}</a>{' '}
                  {messages.order_complete.view}
                </p>
              </div>

              <div className="container_1_1_3">
                <br />
                <a href={appIntl.urlPrefix + 'account/campaigns'}>
                  <button type="submit" className="btn btn-lg btn-danger uppercase">
                    {messages.order_complete.start_next_campaign}
                    <i className="fad fa-bullhorn" />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </DocMeta>
    );
  }
}
OrderComplete.defaultProps = {};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    user: state.login.user,
    formStatus: state.formStatus.orderNew,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(OrderComplete)));
