import React from 'react';
import { findIndex as _findIndex } from "lodash";

export function getPageNameIndex (pageIndex, templateSettings, pagesAmount) {
  pageIndex++;
  if(pageIndex === pagesAmount && templateSettings.last_page_number === 'last'){
    pageIndex='last';
  }

  let pageNameIndex = _findIndex(templateSettings.pages, ['page_number', pageIndex.toString()]);
  if( pageNameIndex === -1){
    pageNameIndex = _findIndex(templateSettings.pages, ['page_number', '0']); //use Default
  }
  return pageNameIndex;
}
