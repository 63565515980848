import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip, Overlay, Popover } from 'react-bootstrap';
import Icon from '../Icon';

IconCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  infoText:PropTypes.string,
};

IconCheckbox.defaultProps = {
  variant: undefined,
  description: undefined,
  value: 'on',
  checked: false,
  onChange: () => undefined,
  className: '',
  infoText:'',
};

let i = 0;

function IconCheckbox({ name, icon, variant, label, description, value, checked, onChange, className, infoText }) {
  i++;
  const id = `${name}${i}_${value}_checkbox`;

  const change = useCallback(
    ({ target }) => {
      const { checked, value } = target;
      onChange(checked ? value : undefined, name);
    },
    [onChange]
  );

  return (
    <div className={`icon-checkbox${variant ? ' ' + variant : ''}${className ? ' ' + className : ''}`}>
      <Icon name={icon} />
      <label className="icon-checkbox-label" for={id}>
        {infoText && (
          <span className="m-l-5">
                <OverlayTrigger placement="top" overlay={<Tooltip>{infoText}</Tooltip>}>
                  <i className="far fa-question-circle cursor-help" />
                </OverlayTrigger>
              </span>
        )}
        <div>
          <div className="label" title={label}>
            <span>{label}</span>
          </div>
          <div className="description" title={description}>
            {description}
          </div>
        </div>
        <div>
          <input id={id} type="checkbox" name={name + i} value={value} checked={checked} onChange={change} />
        </div>
      </label>
    </div>
  );
}

export default IconCheckbox;
