export const UPDATE_BREAKPOINT = 'UPDATE_BREAKPOINT';
export const UPDATE_RETINA = 'UPDATE_RETINA';
export const UPDATE_WIDTH_AND_HEIGHT = 'UPDATE_WIDTH_AND_HEIGHT';
export const UPDATE_HEADER_HEIGHT = 'UPDATE_HEADER_HEIGHT';
export const UPDATE_ACTIVE_CMS = 'UPDATE_ACTIVE_CMS';
export const UPDATE_FOCUS = 'UPDATE_FOCUS';
export const UPDATE_ZOOM ="UPDATE_ZOOM";
export const UPDATE_VAR = "UPDATE_VAR";

export const updateBreakpoint = breakpoint => {
  return {
    type: UPDATE_BREAKPOINT,
    payload: breakpoint,
  };
};

export const updateRetina = isRetina => {
  return {
    type: UPDATE_RETINA,
    payload: isRetina,
  };
};

export const updateWidthAndHeight = (width, height) => {
  return {
    type: UPDATE_WIDTH_AND_HEIGHT,
    payload: { width, height },
  };
};

export const updateHeaderHeight = headerHeight => {
  return {
    type: UPDATE_HEADER_HEIGHT,
    payload: headerHeight,
  };
};

export const updateActiveCms = activeCms => {
  return {
    type: UPDATE_ACTIVE_CMS,
    payload: activeCms,
  };
};

export const updateFocus = isFocused => {
  return {
    type: UPDATE_FOCUS,
    payload: isFocused,
  };
};

export const updateZoom = data => {
  return {
    type: UPDATE_ZOOM,
    payload: data,
  };
};

export const updateVar = data => {
  return {
    type: UPDATE_VAR,
    payload: data,
  };
};
