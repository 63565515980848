import React from 'react';
import { connect } from 'react-redux';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Row, Col, Clearfix, Button, Overlay, Popover } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';

import { favoriteItemsAdd, favoriteItemsRemove } from 'actions/FavoriteItemsActions';
import { resetCheckout, initCheckout } from 'actions/CheckoutActions';
import { getNetFromGross } from 'helpers/financial';
import { isInFavorites } from 'helpers/items';
import { userDataForCheckout } from 'helpers/clients';
import { isPluginActive } from 'helpers/whiteLabel';
import { getCarRentalPath, getCarRentalSearchPath } from 'helpers/intl';

import { AUTOMOTIVE_SALES_PLUGIN, AUTOMOTIVE_RENT_PLUGIN } from 'js/constants';

class PriceBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showShareOptions: false,
      shareOptionsPlacement: 'left',
    };

    this.shareOptionsRef = React.createRef();
  }

  addToFavorites = e => {
    e.preventDefault();

    const { data, saveFavorite } = this.props;
    saveFavorite(data);
  };

  removeFavorite = e => {
    e.preventDefault();

    const { data, removeFavorite } = this.props;
    removeFavorite(data);
  };

  showShareOptions = e => {
    e.preventDefault();

    const clientRect = !!e.target && !!e.target.getBoundingClientRect && e.target.getBoundingClientRect();
    if (clientRect) {
      this.setState({ showShareOptions: true, shareOptionsPlacement: clientRect.top < 120 ? 'bottom' : 'left' });
    }
  };

  hideShareOptions = () => {
    this.setState({ showShareOptions: false });
  };

  proceedToCheckout = () => {
    const { data, doInitCheckout, user } = this.props;
    doInitCheckout([{ ...data, price: data.pricing.price, quantity: 1 }], userDataForCheckout(user));
  };

  showBuyInquiry = () => {
    const { doShowBuyInquiry } = this.props;
    doShowBuyInquiry();
  };

  showTestDriveInquiry = e => {
    e.preventDefault();

    const { doShowTestDriveInquiry } = this.props;
    doShowTestDriveInquiry();
  };

  showNewPriceNegotiation = e => {
    e.preventDefault();

    const { doShowNewPriceNegotiation } = this.props;
    doShowNewPriceNegotiation();
  };

  conversionButtonShown = () => {
    const { onMainConversionButtonShown } = this.props;
    onMainConversionButtonShown();
  };

  conversionButtonHidden = () => {
    const { onMainConversionButtonHidden } = this.props;
    onMainConversionButtonHidden();
  };

  render() {
    const {
      messages,
      layout,
      data,
      favoriteItems,
      projectConfig: { data: projectConfigData },
      locationData,
    } = this.props;
    const { showShareOptions, shareOptionsPlacement } = this.state;

    const inFavorites = isInFavorites(favoriteItems.list, data);
    const chedriSalesActive = isPluginActive(AUTOMOTIVE_SALES_PLUGIN, projectConfigData);
    const chedriRentActive = isPluginActive(AUTOMOTIVE_RENT_PLUGIN, projectConfigData);

    let rentHref = getCarRentalPath();
    // check the cars location
    // if it supports car rental, get the default dates and build the url
    if (!locationData.pending && locationData.data.is_rental_point && locationData.data.white_label_location_id) {
      rentHref = getCarRentalSearchPath(locationData.data.white_label_location_id, locationData.data.name);
    }

    const phone = !locationData.pending && (locationData.data.sales_point_phone || locationData.data.main_phone);

    return (
      <div className={'sm-m-b-25 ' + (layout.breakpointIndex > 1 ? 'p-l-25 ' : '')}>
        <Clearfix className="m-b-25 item-details-social">
          <div className="pull-left">
            {inFavorites ? (
              <a href="#removeFromFavorites" onClick={this.removeFavorite} className="text-master fs-18">
                <i className="fas fa-heart m-r-5 text-primary" /> {messages.remove_from_favorites}
              </a>
            ) : (
              <a href="#addToFavorites" onClick={this.addToFavorites} className="text-master fs-18">
                <i className="fal fa-heart m-r-5" /> {messages.add_to_favorites}
              </a>
            )}
          </div>
          <div className="pull-right">
            <a
              ref={this.shareOptionsRef}
              href="#showShareOptions"
              onClick={this.showShareOptions}
              className="text-master fs-18"
            >
              <i className="fal fa-share-square" /> {messages.share_the_offer}
            </a>
          </div>

          <Overlay
            rootClose
            show={showShareOptions}
            target={() => this.shareOptionsRef.current}
            placement={shareOptionsPlacement}
            onHide={this.hideShareOptions}
          >
            <Popover id="select-share-option">
              <div className="list-view">
                <ul className="no-border">
                  <li>
                    <a
                      href={'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href}
                      className="p-l-20 p-r-20 p-t-10 p-b-10"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f fa-fw fa-2x" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={'https://twitter.com/intent/tweet?text=&url=' + window.location.href}
                      className="p-l-20 p-r-20 p-t-10 p-b-10"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter fa-fw fa-2x" />
                    </a>
                  </li>
                </ul>
              </div>
            </Popover>
          </Overlay>
        </Clearfix>

        <div className="no-overflow b-rad-lg shadow-1 item-details-price-box p-t-15 p-l-25 p-r-25 p-b-25">
          <Row>
            <Col xs={6}>
              <h2>
                {!!data.brand && data.brand.name} {!!data.model && data.model.name}
              </h2>
              <h5 className="fs-14 lh-14 overflow-ellipsis" title={data.name}>
                {data.name}
              </h5>
              {!!data.ident && !!data.ident.internal && (
                <h6 className="fs-12">
                  {messages.car_number} {data.ident.internal}
                </h6>
              )}

              <div className="m-t-25 m-b-25">
                {!!phone && (
                  <a className="block fs-18 text-primary" href={'tel:' + phone}>
                    <i className="fad fa-phone-alt fa-fw m-r-10" />
                    {phone}
                  </a>
                )}
                <a className="block fs-18 text-primary m-t-10" href="#testDrive" onClick={this.showTestDriveInquiry}>
                  <i className="fad fa-key fa-fw m-r-10" />
                  {messages.test_drive}
                </a>
              </div>

              <div className="overflow-ellipsis semi-bold">{data.owner.company}</div>
              {!locationData.pending && (
                <div className="overflow-ellipsis lh-16">
                  {locationData.data.street} {locationData.data.house_number}
                  <br />
                  {locationData.data.zip_code} {locationData.data.city}
                </div>
              )}
            </Col>
            <Col xs={6}>
              <h2 className="text-black bold">
                <span className="fs-12">{messages.purchase_price}</span>
                <br />
                <FormattedNumber value={data.pricing.price} style="currency" currency={data.pricing.currency} />
              </h2>

              {!!data.pricing.pseudo_price && data.pricing.pseudo_price > data.pricing.price && (
                <div className="hint-text m-b-15">
                  <del>
                    <h5>
                      <FormattedNumber
                        value={data.pricing.pseudo_price}
                        style="currency"
                        currency={data.pricing.currency}
                      />
                    </h5>
                  </del>
                </div>
              )}

              {!!data.pricing.vat_rate && (
                <React.Fragment>
                  <h6 className="fs-12">
                    {messages.incl_short} {data.pricing.vat_rate}% {messages.vat_short}
                    <br />
                    <FormattedMessage
                      id="price_net"
                      values={{
                        price: (
                          <FormattedNumber
                            value={getNetFromGross(data.pricing.price, data.pricing.vat_rate)}
                            style="currency"
                            currency={data.pricing.currency}
                          />
                        ),
                      }}
                    />
                  </h6>
                </React.Fragment>
              )}
            </Col>

            <Col xs={12} className="m-t-30">
              <ScrollTrigger onEnter={this.conversionButtonShown} onExit={this.conversionButtonHidden}>
                {chedriSalesActive ? (
                  <Button block bsStyle="primary" bsSize="lg" className="btn-priority" onClick={this.proceedToCheckout}>
                    {messages.buy_now}
                  </Button>
                ) : (
                  <Button block bsStyle="primary" bsSize="lg" className="btn-priority" onClick={this.showBuyInquiry}>
                    {messages.inquire}
                  </Button>
                )}
              </ScrollTrigger>
            </Col>

            <Col xs={12}>
              <hr className="m-t-40 m-b-30" />
            </Col>

            {chedriSalesActive && (
              <Col xs={4}>
                <a
                  href="#sendPriceNegotiation"
                  className="block text-center text-master"
                  onClick={this.showNewPriceNegotiation}
                >
                  <i className="chedri-deal chedri-4x m-b-10" />
                  <br />
                  {messages.send_price_proposal}
                </a>
              </Col>
            )}
            {/* <Col xs={4}>
              <a href="#carCreditCalculator" className="block text-center text-master">
                <i className="chedri-business-and-finance chedri-4x m-b-10" />
                <br />
                Zum Finanzierungsrechner
              </a>
            </Col> */}
            {chedriRentActive && (
              <Col xs={4}>
                <Link to={rentHref} className="block text-center text-master">
                  <i className="chedri-rent-a-car chedri-4x m-b-10" />
                  <br />
                  {messages.rent_a_car}
                </Link>
              </Col>
            )}
            <Clearfix />
          </Row>
        </div>
      </div>
    );
  }
}
PriceBox.defaultProps = {
  isSticky: false,
};

const mapStateToProps = state => {
  return {
    favoriteItems: state.favoriteItems,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    saveFavorite: item => {
      dispatch(favoriteItemsAdd(item));
    },
    removeFavorite: item => {
      dispatch(favoriteItemsRemove(item));
    },
    doInitCheckout: (items, userData) => {
      dispatch(resetCheckout(initCheckout(items, userData)));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceBox);
