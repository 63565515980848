import React from 'react';

export default class MapCountMarker extends React.Component {
  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.outsideClick, false);
  };

  componentDidUpdate = prevProps => {
    const { closeTooltip, active } = this.props;

    if (closeTooltip) {
      if (!prevProps.active && active) {
        document.addEventListener('click', this.outsideClick, false);
      } else if (prevProps.active && !active) {
        document.removeEventListener('click', this.outsideClick, false);
      }
    }
  };

  outsideClick = e => {
    if (this.containerRef.current.contains(e.target)) {
      return;
    }

    const { closeTooltip } = this.props;
    closeTooltip();
  };

  render() {
    const { active, title, tooltip, count } = this.props;

    return (
      <div
        ref={this.containerRef}
        className="overlayer"
        style={{ zIndex: active ? '21' : '20', transform: 'translate(-50%, -100%)' }}
      >
        {active && tooltip ? (
          <div className="popover top block relative m-b-15" style={{ width: '240px' }}>
            <div className="arrow" />
            <h3 className="popover-title">{title}</h3>
            <div className="popover-content">{tooltip}</div>
          </div>
        ) : null}

        <div className="text-center">
          <span className="inline lh-25 b-rad-xl map-count-pin" style={{ width: '28px', height: '28px' }}>
            {count}
          </span>
        </div>
      </div>
    );
  }
}
MapCountMarker.defaultProps = {
  active: true,
  title: '',
  tooltip: null,
};
