import React from 'react';
import { Overlay } from 'react-bootstrap';
import { connect, ReactReduxContext, Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';

class OverlayTrigger extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      mouseOver: false,
    };

    this.targetRef = React.createRef();
  }

  componentWillUnmount = () => {
    clearTimeout(this.hideTimeout);
  };

  show = () => {
    this.setState({ show: true });
  };

  hide = () => {
    const { delayHide } = this.props;
    this.hideTimeout = setTimeout(this.doHide, delayHide);
  };

  doHide = () => {
    this.setState({ show: false });
  };

  getChildWithEvents = child => {
    const { trigger } = this.props;

    const props = {
      ref: this.targetRef,
    };

    switch (trigger) {
      case 'click': {
        props.onClick = e => {
          e.preventDefault();
          e.stopPropagation();

          this.show();
        };
        break;
      }

      case 'hover': {
        props.onMouseEnter = () => {
          this.setState({ mouseOver: true });
        };

        props.onMouseLeave = () => {
          this.setState({ mouseOver: false });
        };
        break;
      }

      case '': {
        break;
      }
    }

    return React.cloneElement(child, props);
  };

  triggerVisible = () => {
    this.show();
  };

  render() {
    const {
      wrappingComponent: WrappingComponent,
      id,
      appIntl,
      container,
      placement,
      rootClose,
      overlay,
      children,
      onEnter,
      onExit,
    } = this.props;
    const { show, mouseOver } = this.state;

    return (
      <ReactReduxContext.Consumer>
        {({ store }) => {
          return (
            <>
              <Overlay
                show={show || mouseOver}
                container={container}
                onHide={this.hide}
                placement={placement}
                rootClose={rootClose}
                target={this.targetRef.current}
                onEnter={onEnter}
                onExit={onExit}
              >
                <WrappingComponent id={id}>
                  <Provider store={store}>
                    <IntlProvider locale={appIntl.locale} messages={appIntl.messages.messages}>
                      {overlay}
                    </IntlProvider>
                  </Provider>
                </WrappingComponent>
              </Overlay>

              {children && children.length
                ? children.map((element, i) => {
                    if (i === 0) {
                      return this.getChildWithEvents(element);
                    }

                    return element;
                  })
                : this.getChildWithEvents(children)}
            </>
          );
        }}
      </ReactReduxContext.Consumer>
    );
  }
}
OverlayTrigger.defaultProps = {
  trigger: 'hover',
};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(OverlayTrigger);
