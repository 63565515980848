import React from 'react';
import { forEach as _forEach } from "lodash";


export function findSpace (raster, pageIndex, blockSpace, blockOffsetTop, pageSpace, endless = false) {
  let start = 0;

  if(blockOffsetTop !== null){
    start = blockOffsetTop;
  }
  let spaceUsed = false;
  for (let i = start; i < pageSpace; i++) {
    spaceUsed = false;
    if(raster[pageIndex][i] === "") {
      for (let j = 0; j < blockSpace; j++) {
        if (raster[pageIndex][j + i] !== "") {
          spaceUsed = true;
        }
      }
      if (!spaceUsed) {
        return i;
      }
    }
  }
  if(endless){
    return raster[pageIndex].length;
  }
  return -1;
}

export function reserveSpace (raster, pageIndex, blockSpace, blockOffsetTop, blockId, endless = false) {
  let start = 0;

  if(blockOffsetTop !== null){
    start = blockOffsetTop;
  }
  if(endless){
    for (let i = start; i < blockSpace + start; i++) {
      raster[pageIndex].push( blockId );
    }
  } else {
    for (let i = start; i < blockSpace + start; i++) {
      raster[pageIndex][i] = blockId;
    }
  }
  return raster;
}

export function switchRasterPosition (raster, currentPage, sourceTopPosition, targetTopPosition, sourceBlockPageSpaceNeeded, targetBlockPageSpaceNeeded, sourceBlockId, targetBlockId) {
  let start = sourceTopPosition;
  let end = targetTopPosition+targetBlockPageSpaceNeeded;
  if(sourceTopPosition > targetTopPosition){
    start = targetTopPosition;
    end = sourceTopPosition+sourceBlockPageSpaceNeeded;
  }
  for(let i = start; i < end; i++){
    if((sourceTopPosition > targetTopPosition && i < targetTopPosition+sourceBlockPageSpaceNeeded) || (sourceTopPosition < targetTopPosition && i >= sourceTopPosition+targetBlockPageSpaceNeeded)) {
      raster[currentPage][i] = sourceBlockId;
    } else {
      raster[currentPage][i] = targetBlockId;
    }
  }
  return raster;
}

export function recreateRasterPage(raster, page, pageSpace, contentBlocks) {
  let nextFreeSpace;
  let changedRaster = raster;
  for(let i = 0; i < pageSpace; i++) {
    changedRaster[page][i] = "";
  }
  _forEach(contentBlocks, (block, i) => {
    let offsetTop = 0;
    if(block.fixed){
      offsetTop = block.top;
    }
    nextFreeSpace = findSpace(changedRaster, page, block.page_space_needed, offsetTop, pageSpace);
    changedRaster = reserveSpace(changedRaster, page, block.page_space_needed, nextFreeSpace, block.id);
  });
  return raster;
}

export function lookForTemplateBlock(raster, blockTemplateId){
  for(let i=0; i < raster.length; i++){
    for(let j=0; j < raster[i].length; j++){
      if(raster[i][j].includes(blockTemplateId)){
        return true;
      }
    }
  }
  return false;
}

export function getRasterSlots (raster, page, pageSpace, after, before, endless=false){

  let countFreeSpace = 0;
  const freeSlots = [];
  if(endless){
    for(let i = 1; i<=100; i++){
      freeSlots.push(i);
    }
  }
  else {
    for (let i = after; i < pageSpace - before; i++) {
      if (raster[page][i] !== "") {
        if (countFreeSpace > 0) {
          freeSlots.push(countFreeSpace);
        }
        countFreeSpace = 0;
        continue;
      }
      countFreeSpace++
    }
    if (countFreeSpace > 0) {
      freeSlots.push(countFreeSpace);
    }
  }
  return freeSlots;
}

export function getBlockTopFromRaster (raster, page, rasterId){
  const length = raster[page].length;
  for(let i=0; i < length; i++){
    if(raster[page][i] === rasterId) {
        return i;
    }
  }
  return -1;
}

export function getSiblings(raster, page, rasterId){
  let siblings = {
    up: "",
    down: "",
  };
  const length = raster[page].length;
  let direction = 'up';
  for(let i = 0; i < length; i++){
    if(raster[page][i]===rasterId){
      direction = 'down';
    } else {
      if(direction === 'up'){
        siblings['up'] = raster[page][i];
      } else {
        siblings['down'] = raster[page][i];
        break;
      }
    }
  }
  return siblings;
}

export function removeRasterBlock(raster, page, rasterId, magnetic, fixedBlocks){
  const length = raster[page].length;
  for(let i=0; i < length; i++){
    if(raster[page][i]===rasterId) {
      raster[page][i] = "";
    }
  }
  if(magnetic){ //magnetic blocks, so close all empty spaces above or between
    raster = closeGaps(raster, page, fixedBlocks);
  }
  return raster;
}

export function closeGaps(raster, page, fixedBlocks) {
  const length = raster[page].length;
  for (let i = 0; i < length; i++){
    if(raster[page][i] === "") {
      for(let j=i; j<length; j++){
        if(raster[page][j] !== "") {
          if(fixedBlocks.findIndex((element) => element === raster[page][j]) === -1)
          {
            raster[page][i] = raster[page][j];
            raster[page][j] = "";
          } else {
            i = j;
          }
          break;
        }
      }
    }
  }
  return raster;
}

export function moveAncestorsDown(raster, page, rasterId, fixedBlocks){
  const length = raster[page].length;
  for(let i = length-1; i >= 0; i--){
    if(raster[page][i]===rasterId){
      break;
    }
    else if(raster[page][i] === "") {
      for(let j=i; j>0; j--){
        if(fixedBlocks.findIndex((element) => element === raster[page][j]) !== -1){
          i=j;
          break;
        }
        if(raster[page][j] !== "" && raster[page][j] !== rasterId) {
          raster[page][i] = raster[page][j];
          raster[page][j] = "";
          break;
        } else if (raster[page][j]===rasterId){
          break;
        }
      }
    }
  }
  return raster;
}

export function resizeRasterBlock(raster, page, rasterId, from, to, fixedBlocks) {
  const length = raster[page].length;
  raster = moveAncestorsDown(raster, page, rasterId, fixedBlocks);

  let start = getBlockTopFromRaster(raster, page, rasterId);
  for(let i=start; i < length; i++){
    if(i<start+to){
      raster[page][i]=rasterId;

    }
    else if(from>to && i < start+from){
      raster[page][i]="";

    }
  }
  raster = closeGaps(raster, page, fixedBlocks);
  return raster;
}

export function pasteRasterBlock (raster, page, start, rasterIdBefore, rasterId, spaceNeeded){

  for(let i=start; i < start + spaceNeeded; i++){
    raster[page][i] = rasterId;
  }

  return raster;
}

export function isSpaceFreeAt(raster, page, offset, space) {
  for(let i = offset; i < offset+space; i++){
    if(raster[page][i]!==""){
      return false;
    }
  }
  return true;
}
