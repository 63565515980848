import React from 'react';
import { forEach as _forEach, findIndex as _findIndex, isArray as _isArray } from 'lodash';

class TableBodyRowResponsive extends React.Component {
  listActions = actions => {
    const res = [];

    _forEach(actions, action => {
      res.push(action);
      res.push(' ');
    });

    return res;
  };

  render() {
    const { elements, headers, visibleElements, breakpointIndex } = this.props;
    const onClick = elements.onClick || null;
    const className = elements.className || '';

    const res = [];
    _forEach(elements, (value, key) => {
      if (visibleElements === 'all' || _findIndex(visibleElements, key)) {
        if (key !== 'onClick' && key !== 'className') {
          if (breakpointIndex === 0) {
            res.push(
              <div className="m-b-10" key={key}>
                <h6 className="m-t-0 m-b-5 no-padding">{headers[key]}</h6>
                <div>{key === 'actions' ? this.listActions(value) : value}</div>
              </div>
            );
          } else {
            res.push(
              <td key={key} className={'v-align-middle ' + (key === 'actions' || key === 'totalDue' ? ' text-right' : '')}>
                {key === 'actions' ? this.listActions(value) : value}
              </td>
            );
          }
        }
      }
    });

    if (breakpointIndex === 0) {
      return (
        <tr onClick={onClick} className={(onClick ? 'clickable ' : '') + className}>
          <td>{res}</td>
        </tr>
      );
    }

    return (
      <tr onClick={onClick} className={(onClick ? 'clickable ' : '') + className}>
        {res}
      </tr>
    );
  }
}
TableBodyRowResponsive.defaultProps = {
  visibleElements: 'all',
};

export default TableBodyRowResponsive;
