import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import { getCarSearchBrandsPath, getPassengerCarSearchPath } from 'helpers/intl';

export default class Start extends React.Component {
  render() {
    const { intl } = this.props;
    const messages = intl.messages;

    return [
      <Row class="no-margin select-funnel" key="1">
        <Col xs={12}>
          <div class="funnel-head">
            <h2>Wunschmodell wählen oder Fahrzeugangebot durchsuchen</h2>
          </div>
        </Col>
      </Row>,
      <Row class="no-margin select-funnel" key="2">
        <Col xs={12} sm={6} md={5} lg={4} mdOffset={1} lgOffset={2}>
          <Link to={getCarSearchBrandsPath()} class="text-master">
            <button class="select-funnel-btn full-width" type="button">
              <div class="select-funnel-btn-body">
                <div class="relative single-icon" style={{ height: '200px' }}>
                  <span>
                    <i class="chedri-sedan-car-model chedri-3x" />
                  </span>
                </div>
                <p class="small">Ich weiß schon genau die Marke und das Modell</p>
              </div>
            </button>

            <span class="select-funnel-btn-legend">Mein Modell wählen</span>
          </Link>
        </Col>

        <Col xs={12} sm={6} md={5} lg={4} class="sm-m-t-30">
          <Link to={getPassengerCarSearchPath()} class="text-master">
            <button class="select-funnel-btn full-width" type="button">
              <div class="select-funnel-btn-body">
                <div class="relative five-icons" style={{ height: '200px' }}>
                  <span>
                    <i class="chedri-car-city-model chedri-3x" />
                  </span>
                  <span>
                    <i class="chedri-car-suv chedri-3x" />
                  </span>
                  <span>
                    <i class="chedri-jeep chedri-3x" />
                  </span>
                  <span>
                    <i class="chedri-sportive-car chedri-3x" />
                  </span>
                  <span>
                    <i class="chedri-cabrio-car chedri-3x" />
                  </span>
                </div>
                <p class="small">Helft mir bei der Suche nach dem besten Modell für mich</p>
              </div>
            </button>

            <span class="select-funnel-btn-legend">Passendes Modell finden</span>
          </Link>
        </Col>
      </Row>,
    ];
  }
}
