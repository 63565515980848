const initialState = {
  timelineR: null,
  pendingTimelineR: false,
  timelineStartR: 0,
  updateTimelineR: true,
  showLoadMoreEntriesR: false,
  reloadR: false,
  categoriesSettings: [],
  pendingCategoriesSettings: false,
  pendingSync: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_TEMPLATE_TIMELINE_PENDING': {
      return { ...state, pendingTimelineR: true };
    }
    case 'GET_TEMPLATE_TIMELINE_FULFILLED': {
      const timeline = payload;
      /*
      let timelineStart = 0;
      let limit = 10;
      let showLoadMoreEntries;

      if(timelineR !== null && timelineR.length >= limit && updateTimelineR === false){
        timelineRConcat = timelineR;
        if(payload.length < limit){
          showLoadMoreEntries = false;
        }
        if(payload && payload.length !== 0) {
          timelineRConcat = timelineR.concat(action.payload);
          timelineStart = timelineRConcat.length;
        }
      }else{
        timelineRConcat = payload;
        timelineStart = payload.length;
        if(timelineStart >= limit){
          showLoadMoreEntries = true;
        }
      }
       */

      return { ...state, timelineR: timeline, pendingTimelineR: false, updateTimelineR: false };
    }
    case 'RESET_TEMPLATE_TIMELINE_PENDING': {
      return { ...state, pendingTimelineR: true };
    }
    case 'RESET_TEMPLATE_TIMELINE_FULFILLED': {
      return { ...state, reloadR: true };
    }
    case 'SET_VARIABLE_IN_TEMPLATE_SETTINGS': {
      if (payload === 'setUpdateTimelineRTrue') {
        return { ...state, updateTimelineR: true };
      }
      if (payload === 'resetTimelineProps') {
        return { ...state, timelineR: null, updateTimelineR: true, reloadR: false, pendingTimelineR: false };
      }
      return { ...state };
    }

    case 'GET_CATEGORIES_SETTINGS_PENDING': {
      return { ...state, pendingCategoriesSettings: true };
    }
    case 'GET_CATEGORIES_SETTINGS_FULFILLED': {
      return { ...state, categoriesSettings: payload, pendingCategoriesSettings: false };
    }

    case 'SYNCHRONIZE_S3_FILES_PENDING': {
      return { ...state, pendingSync: true };
    }
    case 'SYNCHRONIZE_S3_FILES_FULFILLED': {
      return { ...state, pendingSync: false };
    }
  }
  return state;
};
