import React from 'react';
import AnimateHeight from 'react-animate-height';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

import RichEditorContent from 'components/RichEditorContent';

export default class CmsElementAccordionChild extends AbstractCmsElement {
  render() {
    const { accordionIndex, shownAccordionIndex, show } = this.props;

    const title = this.getParamValue('title', '');
    const content = this.getParamValue('content', '');

    const open = accordionIndex === shownAccordionIndex;

    return title && content ? (
      <div id={this.getMainId()} class={'ms-accordion-child m-b-5 ' + (open ? 'open ' : '')}>
        {this.getElementStyle()}

        <div
          class="ms-accordion-child-head p-t-20 p-b-20 p-l-20 p-r-20 cursor"
          onClick={() => show(open ? null : accordionIndex)}
        >
          <span class="fs-14 text-uppercase">{title}</span>

          <i class="pull-right fa fa-chevron-down m-t-5" />
        </div>

        <AnimateHeight duration={500} height={open ? 'auto' : 0}>
          <RichEditorContent
            content={
              '<div class="p-b-10 p-l-20 p-r-20 relative ms-accordion-child-content">' +
              this.getParamValue('content', '') +
              '</div>'
            }
          />
        </AnimateHeight>
      </div>
    ) : (
      <div class="padding-20 text-center">
        <i class="fa fa-font fa-4x" />
      </div>
    );
  }
}
