/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Form } from 'react-bootstrap';

import { intlModule } from '@chedri/base';

import { Elements } from 'react-stripe-elements';
import { getNameInCurrentLanguage } from '../../../helpers/intl';
import { cartUpdateItem } from '../../../actions/CartActions';
import Spinner from '../../Spinner';
import CartWizardFinish from './CartWizardFinish';
import CartWizardStep from './CartWizardStep';

function projectCart(cart, locale) {
  const { id, items } = cart;
  return {
    id,
    items: items.map(getCartItem(locale, cart)),
  };
}

function getCartItem(locale, cart) {
  const intl = { displayIntlForm: locale };
  const { shipping_address } = cart;
  return cartItem => {
    const {
      id,
      price,
      print_pdf_price,
      gross,
      print_type,
      edition,
      item,
      variant,
      is_configuration_finished,
      campaign_budget,
      campaign_running_budget,
      user_budget,
      user_running_budget,
      coupon,
      coupon_value,
      is_reduced_vat_rate_check_requested,
      is_reduced_vat_rate_check_available,
      is_manual_data_check_requested,
      manual_data_check_price,
      manual_data_check_request_comment,
      is_web_pdf_requested,
      web_pdf_price,
      is_wants_domain_hosting,
      domain_hosting_net_amount,
      desired_domain,
      url_forwarding,
      available_shipping_types,
      shipping_type,
      split_shipping,
      split_shipping_price,
      applied_product_group_discount,
      perforation_options_available,
      perforation_options_selected,
      perforation_options_preselected,
      textile_sizes_option,
      textile_sizes_selected,
    } = cartItem;
    let { name } = item;
    let itemName;
    typeof name === 'object' ? (itemName = getNameInCurrentLanguage(name, intl)) : (itemName = name);
    name = itemName;
    const { title: label, campaign, mdate, cdate } = edition;
    const { currency } = variant ? variant.pricing : item.pricing;
    const { vat_rate: vat } =
      cartItem.print_type === 'pdf_only'
        ? 19
        : !variant
        ? Math.round(item.pricing.gross_price / item.pricing.price)
        : Math.round(variant.pricing.gross_price / variant.pricing.price);
    const variantDetails = variant ? getItemDetails(variant, intl) : [];
    const details = [
      {
        key: 'format',
        label: 'Format',
        description: edition.template.size_text,
      },
      ...getItemDetails(item, intl),
      ...variantDetails,
    ];

    // TODO: use spread syntax
    // only props marked with "diff" comment differs from the input

    return {
      id,
      name, // diff
      label, // diff
      price,
      print_pdf_price,
      gross,
      currency, // diff
      vat, // diff
      lastModified: new Date(mdate), // diff
      lastStored: new Date(cdate), // diff
      details, // diff
      campaign, // diff
      print_type,
      edition,
      item,
      variant,
      is_configuration_finished,
      campaign_budget,
      campaign_running_budget,
      user_budget,
      user_running_budget,
      coupon,
      coupon_value,
      is_reduced_vat_rate_check_requested,
      is_reduced_vat_rate_check_available,
      is_manual_data_check_requested,
      manual_data_check_price,
      manual_data_check_request_comment,
      is_web_pdf_requested,
      web_pdf_price,
      is_wants_domain_hosting,
      domain_hosting_net_amount,
      desired_domain,
      url_forwarding,
      available_shipping_types,
      shipping_type,
      split_shipping,
      split_shipping_price,
      shipping_address, // diff
      applied_product_group_discount,
      perforation_options_available,
      perforation_options_selected,
      perforation_options_preselected,
      textile_sizes_option,
      textile_sizes_selected,
    };
  };
}

function CartWizard() {
  const dispatch = useDispatch();
  const locale = useSelector(intlModule.selectors.getLocale);
  const messages = useSelector(intlModule.selectors.getMessages);
  const originalCart = useSelector(state => state.cart);
  const isCartPending = useSelector(state => state.cart.pending);
  const isAuthPending = useSelector(state => state.auth.loginPending);

  const [cart, setCart] = useState();

  const confirmStep = useCallback(
    cartItem => {
      dispatch(
        cartUpdateItem(cartItem, {
          is_configuration_finished: true,
        })
      );
    },
    [cart, setCart]
  );

  useEffect(() => {
    if (originalCart) {
      setCart(projectCart(originalCart, locale));
    }
  }, [originalCart, locale]);

  if ((isCartPending && (!cart || !cart.id)) || isAuthPending) {
    return (
      <div class="full-width text-center top-gap">
        <Spinner />
      </div>
    );
  }

  if (cart && cart.items.length === 0) {
    return <h5 className="text-center top-gap">{messages.cart_is_empty}</h5>;
  }

  let countUnselectedDomainRegistration = 0;
  let countUnselectedDataCheck = 0;
  let countDataCheckSet = 0;
  return (
    <div className="wizard top-gap">
      <Form>
        {cart &&
          Array.isArray(cart.items) &&
          cart.items.map((cartItem, index) => {
            if (cartItem.item.type === 'itemWebsite') {
              if (!cartItem.is_wants_domain_hosting) {
                countUnselectedDomainRegistration++;
              }
            } else if (!cartItem.is_manual_data_check_requested) {
              countUnselectedDataCheck++;
            }

            if (
              cartItem?.edition?.item?.pricing?.dataproof_base_price > 0 ||
              cartItem?.edition?.item?.pricing?.new_dataproof_base_price > 0
            ) {
              countDataCheckSet++;
            }

            return (
              <CartWizardStep
                key={cartItem.id}
                number={index + 1}
                cartItem={cartItem}
                onConfirm={() => confirmStep(cartItem)}
              />
            );
          })}

        <Elements locale={locale} key={locale}>
          <CartWizardFinish
            countMissingDataChecks={countUnselectedDataCheck}
            countMissingDomainRegistrations={countUnselectedDomainRegistration}
            countDataCheckSet={countDataCheckSet}
          />
        </Elements>
      </Form>
    </div>
  );
}

export default CartWizard;

function getItemDetails(item, intl) {
  return item.attributes
    ? item.attributes.map(attribute => {
        const { id: key, name, values } = attribute;
        return {
          key,
          label: getNameInCurrentLanguage(name, intl) + ':',
          description:
            Array.isArray(values) && values.length > 0 ? getNameInCurrentLanguage(values[0].value, intl) : undefined,
        };
      })
    : [];
}
