import React from 'react';
import { Alert } from 'react-bootstrap';
import AnimateHeight from 'react-animate-height';
import { isObject as _isObject, forEach as _forEach, isArray as _isArray, isString as _isString } from 'lodash';

export default class FormErrors extends React.Component {
  render() {
    const { formStatus, additionalErrors } = this.props;

    const errorList = [];
    if (formStatus.hasError) {
      if (_isArray(formStatus.errors)) {
        _forEach(formStatus.errors, (value, key) => {
          if (value.message) {
            errorList.push(<p key={key}>{value.message}</p>);
          }
        });
      } else if (_isObject(formStatus.errors)) {
        _forEach(formStatus.errors, (value, key) => {
          if (key !== 'code' && _isString(value)) {
            errorList.push(<p key={key}>{value}</p>);
          }
        });
      } else if (formStatus.errors) {
        errorList.push(<p key={1}>{formStatus.errors}</p>);
      }
    }

    for (let i = 0; i < additionalErrors.length; i++) {
      errorList.push(<p key={'a' + i}>{additionalErrors[i]}</p>);
    }

    return (
      <AnimateHeight duration={500} height={formStatus.hasError || additionalErrors.length ? 'auto' : 0}>
        <div class="p-b-10">
          <Alert bsStyle="danger" class="no-margin">
            {errorList}
          </Alert>
        </div>
      </AnimateHeight>
    );
  }
}
FormErrors.defaultProps = {
  formStatus: { hasError: false, errors: '' },
  additionalErrors: [],
};
