import React from 'react';
import { Badge } from 'react-bootstrap';
import { connect } from 'react-redux';
import { components } from 'react-select';
import { forEach as _forEach } from 'lodash';

import { getNameInCurrentLanguage } from 'helpers/intl';

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

class AttributeOptionWithProps extends React.Component {
  render() {
    const { data, appIntl } = this.props;
    return (
      <components.Option {...this.props}>
        {!!data.id && <Badge className="m-r-10 text-uppercase p-l-10 p-r-10">{appIntl.displayIntlForm}</Badge>}
        {getNameInCurrentLanguage(data.langValue, appIntl)}
      </components.Option>
    );
  }
}
const AttributeOption = connect(mapStateToProps)(AttributeOptionWithProps);

class AttributeValueWithProps extends React.Component {
  render() {
    const { data, appIntl } = this.props;

    return (
      <div className="Select-value">
        {!!data.id && <Badge className="m-r-10 text-uppercase p-l-10 p-r-10">{appIntl.displayIntlForm}</Badge>}
        {getNameInCurrentLanguage(data.langValue, appIntl)}
      </div>
    );
  }
}
const AttributeValue = connect(mapStateToProps)(AttributeValueWithProps);

const attributeOptionFormatter = options => {
  return options.list.map(option => {
    return attributeSingleOptionFormatter(option);
  });
};

const attributeSingleOptionFormatter = (option = null) => {
  let label = '';
  _forEach(option.name, langName => {
    label += langName.name + ' ';
  });
  return { ...option, langValue: option.name, value: option.id, label };
};

export { AttributeValue, AttributeOption, attributeOptionFormatter, attributeSingleOptionFormatter };
