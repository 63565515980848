import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'components/Modal';

import { injectIntl } from 'react-intl';

import Data from 'components/Data';
import CmsPage from 'components/CmsPage';

import Loader from 'components/layout/Loader';

// display terms of the dealer for renting cars
class ModalDealerCarRentTermsOfService extends React.Component {
  render() {
    const {
      intl: { messages },
      show,
      close,
      cmsPage,
      owner,
    } = this.props;

    let dataUrl = '/api/pages/carrenttermsofservice.json?';
    if (owner) {
      dataUrl += 'owner=' + (owner.username || owner.id);
    }

    return (
      <Modal show={show} onHide={close} bsSize="lg" className="full-width">
        <Modal.Header closeButton>
          <Modal.Title>{messages.rental_terms_of_service}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Data dataName="cmsSystemPageCarRentTermsOfService" url={dataUrl} data={cmsPage} />

          <div className="themed">
            {cmsPage.pending ? <Loader /> : <CmsPage data={cmsPage.data} doUpdateActiveCms={false} />}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
ModalDealerCarRentTermsOfService.defaultProps = {
  owner: null,
};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    cmsPage: state.data.cmsSystemPageCarRentTermsOfService,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(ModalDealerCarRentTermsOfService)));
