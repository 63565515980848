import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import { intlModule, communicationModule } from '@chedri/base';

import IconLink from 'components/IconLink';
import Panel from 'components/panel/Panel';
import CmsElementLoginForm from 'components/cms/elements/CmsElementLoginForm';
import AnonymousUserInfoForm from './AnonymousUserInfoForm';
/*
 */

function ChatRequestCredentialsPanel({ onStartChat, onClose }) {
  const { pathname, search } = useLocation();

  const appIntl = useSelector(intlModule.selectors.getIntl);
  const messages = useSelector(intlModule.selectors.getMessages);
  const isConnecting = useSelector(communicationModule.selectors.socket.isConnecting);
  const [wantLogin, setWantLogin] = useState(false);

  const socialMediaState = {
    whiteLabelProjectId: global.whiteLabelSettings.id,
    route: appIntl.urlPrefix + pathname + (search ? '?' : '') + search,
  };

  // TODO Check if login is needed here, we have a main login.
  // eslint-disable-next-line no-unused-vars
  const showLogin = useCallback(
    e => {
      if (e) {
        e.preventDefault();
      }
      setWantLogin(true);
    },
    [setWantLogin]
  );

  const submitLogin = useCallback(args => {
    console.log(args);
  }, []);

  return (
    <Panel className="chatter-teaser">
      <Panel.Header
        rightAction={
          <IconLink
            name="fa-times-circle"
            solid
            onClick={onClose}
            linkClassName="link"
            iconClassName="fs-18 v-align-middle"
          />
        }
      >
        Ihre Daten
      </Panel.Header>
      <Panel.Body>
        {wantLogin && (
          <CmsElementLoginForm
            hideTitle
            socialMediaLoginState={window.btoa(JSON.stringify(socialMediaState))}
            intl={{ messages }}
            appIntl={appIntl}
            onSubmit={submitLogin}
          />
        )}
        {!wantLogin && <AnonymousUserInfoForm onSubmit={onStartChat} isConnecting={isConnecting} />}
      </Panel.Body>
    </Panel>
  );
}
ChatRequestCredentialsPanel.defaultProps = {
  onStartChat: () => undefined,
};
ChatRequestCredentialsPanel.propTypes = {
  onStartChat: PropTypes.func,
};

export default ChatRequestCredentialsPanel;
