import React from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import Scrollbar from "react-scrollbars-custom";

class EditorPageNavigator extends React.PureComponent {
  constructor(props) {
    super(props);
    this.pageRef = React.createRef();
    this.scrollRef = React.createRef();
    this.state = {
      previewOffset: 0,
    }
  }

  componentDidUpdate = () => {
    const activePage = ReactDOM.findDOMNode(this).getElementsByClassName('active');
    if(activePage.length > 0 && activePage[0].offsetTop !== this.state.previewOffset){
      this.setState({
        previewOffset: activePage[0].offsetTop,
      });
    }
  };

  componentDidMount = () => {
    const activePage = ReactDOM.findDOMNode(this).getElementsByClassName('active');
    if(activePage.length > 0 && activePage[0].offsetTop !== this.state.previewOffset){
      this.setState({
        previewOffset: activePage[0].offsetTop,
      });
    }
  };

  gotoPage = (key, e) => {
    const { updateCurrentPage } = this.props;
    updateCurrentPage( parseInt(key) - 1 );
  };

  render() {
    const { pages } = this.props;
    const { previewOffset } = this.state;
    return (
      <div key="pagePreviewDetail" id={"pagePreviewDetail"}>
        <Scrollbar id="pageScroller" ref={this.scrollRef} scrollTop={previewOffset} createContext={true} style={{width: "100%", height:"100%"}}>
          {pages}
        </Scrollbar>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
  };
};

export default connect(mapStateToProps)(EditorPageNavigator);
