import React from 'react';
import { FormattedNumber, FormattedDate } from 'react-intl';
import { Clearfix } from 'react-bootstrap';

import {
  getThumbFile,
  getPower,
  getHorsePower,
  getGearbox,
  getMileage,
  getFirstRegistration,
  getPaintColor,
  getInteriorMaterial,
  getEnvironmentBadge,
  getFuelType,
  getCo2Emissions,
  getFuelConsumptionCombined,
  getEnergyEfficiencyClass,
} from 'helpers/items';

import EnergyLabel from 'components/layout/itemDetails/carDetailsRender/EnergyLabel';

export default class CarItem extends React.Component {
  getImage = item => {
    if (item.variant && item.variant.assets && item.variant.assets.imgs && item.variant.assets.imgs.length) {
      // the item is variant, the variant has own image, we should use it
      const imageThumb = getThumbFile(item.variant.assets.imgs[0]);
      return imageThumb ? imageThumb.web_link : null;
    }

    if (item && item.assets && item.assets.imgs && item.assets.imgs.length) {
      const imageThumb = getThumbFile(item.assets.imgs[0]);
      return imageThumb ? imageThumb.web_link : null;
    }

    return null;
  };

  getDetails = car => {
    const {
      intl: { messages },
      appIntl,
    } = this.props;

    const powerKw = getPower(car, appIntl);
    const firstRegistration = getFirstRegistration(car, appIntl);
    const mileage = getMileage(car, appIntl);
    const fuelConsumption = getFuelConsumptionCombined(car, appIntl);
    const co2Emissions = getCo2Emissions(car, appIntl);

    const res = [
      { id: 'brand', label: messages.brand, value: !!car.brand && car.brand.name },
      { id: 'model', label: messages.model, value: !!car.model && car.model.name },
      {
        id: 'power',
        label: messages.car_power,
        value: powerKw ? powerKw + ' kW (' + getHorsePower(powerKw) + ' ' + messages.car_power_unit + ')' : '-',
      },
      {
        id: 'gearbox',
        label: messages.car_gear,
        value: getGearbox(car, appIntl) || '-',
      },
      {
        id: 'fuel_type',
        label: messages.fuel_type,
        value: getFuelType(car, appIntl) || '-',
      },
      {
        id: 'first_registration',
        label: messages.first_registration,
        value: firstRegistration ? (
          <FormattedDate value={new Date(firstRegistration.substring(0, 10))} year="numeric" month="2-digit" />
        ) : (
          '-'
        ),
      },
      {
        id: 'color',
        label: messages.color,
        value: getPaintColor(car, appIntl) || '-',
      },
      {
        id: 'mileage',
        label: messages.mileage,
        value: mileage ? mileage + ' km' : '-',
      },
      {
        id: 'consumption',
        label: messages.car_fuel_consumption + ' (' + messages.combined + ')',
        value: fuelConsumption ? fuelConsumption + ' ' + messages.car_fuel_consumption_unit : '-',
      },
      {
        id: 'co2',
        label: messages.car_co2_emissions_unit + ' (' + messages.combined_short + ')',
        value: co2Emissions ? co2Emissions + ' ' + messages.car_co2_emissions_unit : '-',
      },
      {
        id: 'environment_badge',
        label: messages.car_environment_badge,
        value: getEnvironmentBadge(car, appIntl) || '-',
      },
      {
        id: 'efficiency_class',
        label: messages.energy_efficiency_class,
        value: getEnergyEfficiencyClass(car, appIntl),
      },
      {
        id: 'interior_material',
        label: messages.car_interior_material,
        value: getInteriorMaterial(car, appIntl) || '-',
      },
    ];

    return res;
  };

  render() {
    const {
      intl: { messages },
      appIntl,
      layout,
      checkout,
      projectConfig,
      checkoutInfos,
    } = this.props;

    const [car] = checkout.items;
    const imageUrl = this.getImage(car);
    const details = this.getDetails(car);
    const detailsCount = details.length;

    const itemImage = (
      <div>
        {imageUrl ? (
          <img src={imageUrl} alt={car.name} className="img-responsive" />
        ) : (
          <div title={car.name}>
            <div class="p-t-20 p-b-20 bg-master-lightest text-center">
              <i class="fa fa-cube" />
            </div>
          </div>
        )}
      </div>
    );
    const itemDetails = (
      <div>
        <h5 className="bold m-t-0">
          {!!car.brand && car.brand.name} {car.name}
        </h5>

        <div className="small">
          {details.map((detail, i) => {
            return (
              <React.Fragment key={detail.id}>
                <span className="ws-nowrap">
                  {detail.label}: {detail.value}
                </span>
                {i + 1 < detailsCount && ', '}
              </React.Fragment>
            );
          })}
        </div>

        <div className="p-l-20 col-xs-12 col-md-6">
          <EnergyLabel label={getEnergyEfficiencyClass(car, appIntl)} />
        </div>
        <Clearfix />

        <h6>{messages.offered_by}</h6>
        <div className="small">
          <address>
            {checkoutInfos.data.owner_infos[0].owner.profile.company}
            <br />
            {checkoutInfos.data.owner_infos[0].owner.profile.street}{' '}
            {checkoutInfos.data.owner_infos[0].owner.profile.house_number}{' '}
            {checkoutInfos.data.owner_infos[0].owner.profile.apartment_number}
            <br />
            {checkoutInfos.data.owner_infos[0].owner.profile.zip_code},{' '}
            {checkoutInfos.data.owner_infos[0].owner.profile.city}
          </address>
        </div>
      </div>
    );
    const itemPrice = (
      <h5 className="bold">
        <FormattedNumber value={car.price} style="currency" currency={projectConfig.data.currency} />
      </h5>
    );

    return layout.breakpointIndex > 1 ? (
      <tr>
        <td className="col-xs-3 v-align-middle">{itemImage}</td>
        <td className="col-xs-6 v-align-middle">{itemDetails}</td>
        <td className="col-xs-3 v-align-middle text-right">{itemPrice}</td>
      </tr>
    ) : (
      <React.Fragment>
        {itemDetails} {itemPrice}
      </React.Fragment>
    );
  }
}
