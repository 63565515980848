import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import Spinner from 'components/Spinner';
import { intlModule } from '@chedri/base';

// const INTENT_CAPTURE = 'CAPTURE';
const INTENT_AUTHORIZE = 'AUTHORIZE';

const errorLog = (...args) => {
  // TODO: send error to the backend
  console.error('[PAYPAL]', ...args);
};

const MessageSpinner = ({ children }) => {
  return (
    <>
      <div style={{ width: '0', margin: '36px auto', position: 'relative', left: '-18px' }}>
        <Spinner size={36} />
      </div>
      <div style={{ textAlign: 'center' }}>{children}</div>
    </>
  );
};

// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({ showSpinner, disabled, onApprove, createOrder, paypalOrder, currency }) => {
  // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
  // This is the main reason to wrap the PayPalButtons in a new component
  const [{ options, isPending, isRejected }, dispatch] = usePayPalScriptReducer();
  const messages = useSelector(intlModule.selectors.getMessages);
  const style = { layout: 'vertical' };

  useEffect(() => {
    dispatch({
      type: 'resetOptions',
      value: { ...options, currency },
    });
  }, [currency, showSpinner]);

  return (
    <>
      {showSpinner && isPending && <MessageSpinner>{messages.paypal_buttons_loading}</MessageSpinner>}
      {isRejected && <div>{messages.error_loading_paypal_scripts}</div>}

      <PayPalButtons
        style={style}
        disabled={disabled}
        forceReRender={[currency, paypalOrder]}
        fundingSource={undefined}
        createOrder={createOrder}
        onApprove={onApprove}
      />
    </>
  );
};

function mapPropsToPaypalPurchaseUnit(props, languageCode = 'de') {
  const {
    positions,
    sumNet,
    // totalNet,
    vatRates,
    totalGross,
    discountNet,
    transportationCost,
    currency: currency_code = 'EUR',
    cartId,
    altDebtorNumber,
    debtorNumber,
  } = props;

  const findName = (label, languageCode) => {
    let name;
    if (Array.isArray(label)) {
      // multi-language item name
      const bestName = label.find(({ lang }) => lang === languageCode);
      if (bestName) {
        // found matching language name version
        ({ name } = bestName);
      } else {
        // take from the first array element
        [{ name }] = label;
      }
    } else if (typeof label === 'string') {
      name = label;
    } else {
      errorLog('name not found', label, languageCode);
      name = 'Untitled';
    }

    return name;
  };

  const result = {
    amount: {
      currency_code,
      value: totalGross.toFixed(2),
      breakdown: {
        // https://developer.paypal.com/api/orders/v2/#definition-amount_breakdown
        item_total: {
          value: sumNet.toFixed(2), // to be filled later
          currency_code,
        },
        tax_total: {
          value: vatRates ? vatRates.reduce((acc, vat) => acc + vat.sum, 0).toFixed(2) : 0,
          currency_code,
        },
        shipping: {
          value: transportationCost.toFixed(2),
          currency_code,
        },
        discount: {
          value: discountNet.toFixed(2),
          currency_code,
        },
      },
    },
    // payee: {
    //   // https://developer.paypal.com/api/orders/v2/#definition-payee
    //   email_address: '', // TODO: get from WhiteLabel settings
    // },
    description: altDebtorNumber ? `Order by ${altDebtorNumber}` : '',
    reference_id: cartId,
    custom_id: debtorNumber,
    invoice_id: `${cartId}_${Date.now()}`,
    items: positions.map(position => {
      const { label, net } = position;
      const name = findName(label, languageCode);

      return {
        name,
        unit_amount: {
          value: net.toFixed(2),
          currency_code,
        },
        quantity: 1,
        description: name,
      };
    }),
  };

  // add item_total = sum(items[] * quantities[])
  // result.amount.breakdown.item_total.value = result.items
  //   .reduce((acc, item) => acc + item.unit_amount.value * item.quantity, 0)
  //   .toFixed(2);

  return result;
}

export default function Paypal(props) {
  const [isShowSpinner, setIsShowSpinner] = useState(false);
  const messages = useSelector(intlModule.selectors.getMessages);
  const {
    paypalClientId,
    disabled,
    sendPaypalReply,
    buyerCountry = 'DE',
    currency = 'EUR',
    debug = false,
    components = 'buttons,marks,funding-eligibility',
  } = props;

  const languageCode = String(buyerCountry)?.toLowerCase() ?? 'de';

  const purchaseUnit = mapPropsToPaypalPurchaseUnit(props, languageCode);
  const paypalOrder = {
    intent: INTENT_AUTHORIZE,
    // payer: {},
    purchase_units: [purchaseUnit],
    // ref: https://developer.paypal.com/api/orders/v2/#definition-order_application_context
    // application_context: {
    //   shipping_preference: 'NO_SHIPPING',
    // },
  };

  // callback for Paypal buttons
  const createOrder = (data, actions) => {
    return actions.order.create(paypalOrder).catch(error => {
      errorLog('error creating order', error);
    });
  };

  // Handle authorization result
  const onApprove = ({ orderID: id }) => {
    sendPaypalReply({ id });
    setIsShowSpinner(true);
  };

  // reference: https://developer.paypal.com/docs/business/javascript-sdk/javascript-sdk-configuration/#query-parameters
  const paypalScriptOptions = {
    'client-id': paypalClientId,
    currency,
    debug,
    components,
    'data-csp-nonce': window.nonce_cc || '',
    intent: INTENT_AUTHORIZE.toLowerCase(),
  };

  // Dev testing only: add buyer country to test as-if other countries.
  // Parameter "buyer-country" only allowed in PayPal dev sandbox. Remove in production.
  // paypalScriptOptions['buyer-country'] = buyerCountry;
  return (
    <div style={{ maxWidth: '750px', minHeight: '200px' }}>
      <PayPalScriptProvider options={paypalScriptOptions}>
        {isShowSpinner ? (
          <MessageSpinner>{messages.please_wait_saving_order}</MessageSpinner>
        ) : (
          <ButtonWrapper
            showSpinner
            disabled={disabled}
            createOrder={createOrder}
            onApprove={onApprove}
            paypalOrder={paypalOrder}
            currency={currency}
          />
        )}
      </PayPalScriptProvider>
    </div>
  );
}
