import React from 'react';
import { Badge } from 'react-bootstrap';
import { connect } from 'react-redux';
import { components } from 'react-select';
import { forEach as _forEach } from 'lodash';

import { getNameInCurrentLanguage } from 'helpers/intl';

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

class AttributeGroupOptionWithProps extends React.Component {
  render() {
    const { data, appIntl } = this.props;
    return (
      <components.Option {...this.props}>
        {!!data.id && <Badge className="m-r-10 text-uppercase p-l-10 p-r-10">{appIntl.displayIntlForm}</Badge>}
        {getNameInCurrentLanguage(data.name, appIntl)}
      </components.Option>
    );
  }
}
const AttributeGroupOption = connect(mapStateToProps)(AttributeGroupOptionWithProps);

class AttributeGroupValueWithProps extends React.Component {
  render() {
    const { data, appIntl } = this.props;

    return (
      <div className="Select-value">
        {!!data.id && <Badge className="m-r-10 text-uppercase p-l-10 p-r-10">{appIntl.displayIntlForm}</Badge>}
        {getNameInCurrentLanguage(data.name, appIntl)}
      </div>
    );
  }
}
const AttributeGroupValue = connect(mapStateToProps)(AttributeGroupValueWithProps);

function attributeGroupOptionFormatter(options) {
  return options.list.map(option => {
    let label = '';
    _forEach(option.value, langName => {
      if (langName.name !== undefined) {
        label += langName.name + ' ';
      }
    });
    return { ...option, langValue: option.name, value: option.id, label };
  });
}

export { AttributeGroupValue, AttributeGroupOption, attributeGroupOptionFormatter };
