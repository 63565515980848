import React from 'react';
import PropTypes from 'prop-types';

import { LabelValuePairShape } from 'js/helpers/proptypes';

Properties.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(LabelValuePairShape)),
};

Properties.defaultProps = {
  data: undefined,
};

function Properties({ data, className, ...props }) {
  if (!Array.isArray(data)) {
    return null;
  }

  return (
    data.length > 0 && (
      <div className={`properties ${className || ''}`} {...props}>
        {data
          .filter(property => property !== null)
          .map(property => (
            <div key={`${property.label}-${property.description}`} className="property">
              <div className="property-name">{property.label}</div>
              <div className="property-value">{property.description}</div>
            </div>
          ))}
      </div>
    )
  );
}

export default Properties;
