export function vatValueFromNet(value, vat) {
  return (value * (vat / 100));
}

export function grossValueFromNet(value, vat) {
  const vatValue = vatValueFromNet(value, vat);
  return (value + vatValue);
}

export function netValueFromGross(value, vat) {
  return (value / (1 + (vat / 100)));
}

export function vatValueFromGross(value, vat) {
  const net = netValueFromGross(value, vat);
  return (value - net);
}
