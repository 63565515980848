import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Form, Alert } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';
import { Element, scroller } from 'react-scroll';
import Plx from 'react-plx';
import { find as _find, isPlainObject as _isPlainObject, clone as _clone, forEach as _forEach } from 'lodash';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import { formStatusDispatchHelper, formReset } from 'actions/FormStatusActions';
import { getSalutationOptions, getGenderForSalutation } from 'helpers/clients';
import { stringifyQs } from 'helpers/http';

import FormErrors from 'components/forms/FormErrors';
import FormInput from 'components/forms/FormInput';
import FormAutocomplete from 'components/forms/FormAutocomplete';
import FormTextarea from 'components/forms/FormTextarea';

import RichEditorContent from 'components/RichEditorContent';

import Data from 'components/Data';
import Entity from 'components/Entity';

// import FacebookMessengerOptin from 'components/layout/FacebookMessengerOptin';

import ModalPrivacyPolicy from 'components/modals/ModalPrivacyPolicy';

import { entityMetaMapping } from 'js/constants';

import AbstractCmsElement from './AbstractCmsElement';

// make sure each instance gets a new index
let cmsElementLeadFormIndex = 1;
class CmsElementLeadForm extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      ...this.state,
      id: cmsElementLeadFormIndex++,
      entityLoaded: false,
      entityChanged: false,
      shown: false,
      showPrivacyPolicy: false,
      showSuccess: false,
    };

    const { params } = props;
    const groupSequences = [];
    const withAddress = _find(params, ['name', 'requireAddress']);
    const withEmailContact = _find(params, ['name', 'requireEmailContact']);
    const withPhoneContact = _find(params, ['name', 'requirePhoneContact']);
    const withMessage = _find(params, ['name', 'requireMessage']);

    if (withAddress && withAddress.value !== null && withAddress.value === 'yes') {
      groupSequences.push('withAddress');
    }
    if (withEmailContact && withEmailContact.value !== null && withEmailContact.value === 'yes') {
      groupSequences.push('withEmailContact');
    }
    if (withPhoneContact && withPhoneContact.value !== null && withPhoneContact.value === 'yes') {
      groupSequences.push('withPhoneContact');
    }
    if (withMessage && withMessage.value !== null && withMessage.value === 'yes') {
      groupSequences.push('withMessage');
    }

    this.entity = new Entity();
    this.entity.setData({
      salutation: 'mr',
      gender: 'male',
      group_sequences: groupSequences,
    });

    if (props.meta[entityMetaMapping.cmsLeadForm] !== undefined && !props.meta[entityMetaMapping.cmsLeadForm].pending) {
      this.entity.setMeta(props.meta, entityMetaMapping.cmsLeadForm);

      this.state = {
        ...this.state,
        entityLoaded: true,
        entity: this.entity,
      };
    }
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { meta, formStatus } = this.props;
    const { id } = this.state;

    if (
      meta[entityMetaMapping.cmsLeadForm] !== undefined &&
      !meta[entityMetaMapping.cmsLeadForm].pending &&
      !this.entity.getMetaLoaded()
    ) {
      this.entity.setMeta(meta, entityMetaMapping.cmsLeadForm);
      this.setState({ entityLoaded: true, entity: this.entity });
    }

    if (prevProps.formStatus.pending && !formStatus.pending && !formStatus.hasError) {
      this.setState({ showSuccess: true }, () => {
        scroller.scrollTo('lead-form-container-' + id, {
          offset: -100,
        });
      });

      // send the information to Google
      ReactGA.event({
        category: window.location.href,
        action: 'Form_Button_Click',
        label: this.getParamValue('leadSource') || 'leadForm',
        value: 1,
      });

      // send the information to Facebook
      ReactPixel.trackCustom('Form_Button_Click', {
        sourceURL: window.location.href,
        formName: this.getParamValue('leadSource') || 'leadForm',
      });
    }

    this.__componentDidUpdate(prevProps, prevState);
  };

  componentWillUnmount = () => {
    this.props.resetForm();
    this.__componentWillUnmount();
  };

  showPrivacyPolicy = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showPrivacyPolicy: true });
  };

  closePrivacyPolicy = () => {
    this.setState({ showPrivacyPolicy: false });
  };

  getPrivacyPolicyText = () => {
    const {
      intl: { messages },
    } = this.props;

    const text = messages.contact_agreement_text;

    const matches = [];
    text.replace(/({{1}[a-z_]+}{1}){1}/g, (match, g1, g2) => {
      matches.push({ match, pos: g2 });
    });

    const res = [];
    let currentIndex = 0;
    _forEach(matches, match => {
      switch (match.match) {
        // inject {data_use}
        case '{data_use}':
          res.push(text.substring(currentIndex, match.pos));
          res.push(
            <a href="#dataUse" onClick={this.showPrivacyPolicy}>
              {messages.signup_agreement_text_data_use}
            </a>
          );

          currentIndex = match.pos + 10;
          break;
      }
    });

    res.push(text.substring(currentIndex));

    return res;
  };

  changeEntity = (entity, callback = null) => {
    this.setState(
      prevState => ({
        [this.getEntityName()]: entity,
        [this.getEntityName() + 'Changed']: true,
        key: prevState.key + 1,
      }),
      callback
    );
  };

  updateEntity = (propertyName, value, fieldTree = [], callback = null) => {
    const entity = this.state[this.getEntityName()];

    if (_isPlainObject(value)) {
      value = entity.createValue(propertyName, _clone(fieldTree), value);
    }

    const newEntity = { ...entity.updateValue(propertyName, value, fieldTree) };

    this.changeEntity(newEntity, callback);
  };

  updateSalutation = (name, value) => {
    const gender = getGenderForSalutation(value);

    const entity = this.state[this.getEntityName()];
    let newEntity = { ...entity.updateValue('salutation', value) };
    newEntity = { ...entity.updateValue('gender', gender) };
    this.changeEntity(newEntity);
  };

  getEntityName = () => {
    return this.entityName !== undefined ? this.entityName : 'entity';
  };

  onSubmit = e => {
    e.preventDefault();
    e.stopPropagation(); // if we are editing a page, we have to make sure the parent form will not be submitted

    this.setState(
      prevState => ({ entity: prevState.entity.validate() }),
      () => {
        const { onSubmit } = this.props;
        const { entity } = this.state;

        if (entity.isValid) {
          // construct data from form + definitions from the lead form configuration
          const data = {
            ...entity.processedData,
            pipelineId: this.getParamValue('pipelineId'),
            itemId: this.getParamValue('itemId'),
            source: this.getParamValue('leadSource'),
          };
          onSubmit(data);

          // dispatch the facebook event for messenger optin
          /* if (typeof FB !== 'undefined' && FB !== null && this.isFacebookMessengerActive()) {
            FB.AppEvents.logEvent('MessengerCheckboxUserConfirmation', null, {
              app_id: this.props.projectConfig.data.facebook_login_client_id,
              page_id: this.props.projectConfig.data.facebook_page_id,
              ref: this.props.facebookMessengerOptin.ref,
              user_ref: this.props.facebookMessengerOptin.userRef,
            });
          } */
        }
      }
    );
  };

  isFacebookMessengerActive = () => {
    const { projectConfig } = this.props;

    return projectConfig.data.facebook_page_id && projectConfig.data.facebook_login_client_id;
  };

  render() {
    const {
      intl: { messages },
      formStatus,
    } = this.props;
    const { id, entityLoaded, entity, shown, showPrivacyPolicy, showSuccess } = this.state;

    const disabled = formStatus.pending;

    const withAddress = this.getParamValue('requireAddress', 'no') === 'yes';
    const withEmailContact = this.getParamValue('requireEmailContact') === 'yes';
    const withPhoneContact = this.getParamValue('requirePhoneContact') === 'yes';
    const withMessage = this.getParamValue('requireMessage') === 'yes';

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const textClass = 'text-' + this.getParamValue('mainColor', 'master');
    const successMessage = this.getParamValue('thankYouText');

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        className={'ms-lead-form-content ' + shownClass + this.getResponsiveClasses() + this.getCssClasses()}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <Element name={'lead-form-container-' + id} className="lead-form-container">
          <Data dataName="cmsLeadForm" />

          {entityLoaded && showSuccess && (
            <React.Fragment>
              {successMessage ? (
                <RichEditorContent content={'<p class="' + textClass + '">' + successMessage + '</p>'} />
              ) : (
                <Alert bsStyle="success">{messages.weve_received_your_request_and_will_contact_you}</Alert>
              )}
            </React.Fragment>
          )}

          {entityLoaded && !showSuccess && (
            <Form onSubmit={this.onSubmit}>
              <FormErrors formStatus={formStatus} />

              <h4 className={'no-margin p-b-5 ' + textClass}>{this.getParamValue('title', '')}</h4>
              <RichEditorContent
                content={'<p class="' + textClass + '">' + this.getParamValue('infosTop', '') + '</p>'}
              />

              <div className="form-group-attached">
                <Row className="clearfix no-margin">
                  <Col sm={6}>
                    <FormAutocomplete
                      label={messages.salutation}
                      name="salutation"
                      value={entity.getSalutation()}
                      meta={entity.getSalutationMetaInformation()}
                      validation={entity.getSalutationValidationResult()}
                      onChange={this.updateSalutation}
                      options={getSalutationOptions(messages)}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                <Row className="clearfix no-margin">
                  <Col sm={6}>
                    <FormInput
                      label={messages.title}
                      name="title"
                      value={entity.getTitle()}
                      meta={entity.getTitleMetaInformation()}
                      validation={entity.getTitleValidationResult()}
                      onChange={this.updateEntity}
                      disabled={disabled}
                    />
                  </Col>
                </Row>

                <Row className="clearfix no-margin">
                  <Col sm={6}>
                    <FormInput
                      label={messages.first_name}
                      name="firstName"
                      value={entity.getFirstName()}
                      meta={entity.getFirstNameMetaInformation()}
                      validation={entity.getFirstNameValidationResult()}
                      onChange={this.updateEntity}
                      disabled={disabled}
                    />
                  </Col>
                  <Col sm={6}>
                    <FormInput
                      label={messages.last_name}
                      name="lastName"
                      value={entity.getLastName()}
                      meta={entity.getLastNameMetaInformation()}
                      validation={entity.getLastNameValidationResult()}
                      onChange={this.updateEntity}
                      disabled={disabled}
                    />
                  </Col>
                </Row>

                {withAddress && (
                  <>
                    <FormInput
                      label={messages.street}
                      name="street"
                      value={entity.getStreet()}
                      meta={entity.getStreetMetaInformation()}
                      validation={entity.getStreetValidationResult()}
                      onChange={this.updateEntity}
                      disabled={disabled}
                    />

                    <Row className="clearfix no-margin">
                      <Col sm={6}>
                        <FormInput
                          label={messages.house_number}
                          name="houseNumber"
                          value={entity.getHouseNumber()}
                          meta={entity.getHouseNumberMetaInformation()}
                          validation={entity.getHouseNumberValidationResult()}
                          onChange={this.updateEntity}
                          disabled={disabled}
                        />
                      </Col>
                      <Col sm={6}>
                        <FormInput
                          label={messages.apartment_number}
                          name="apartmentNumber"
                          value={entity.getApartmentNumber()}
                          meta={entity.getApartmentNumberMetaInformation()}
                          validation={entity.getApartmentNumberValidationResult()}
                          onChange={this.updateEntity}
                          disabled={disabled}
                        />
                      </Col>
                      <Col sm={12}>
                        <FormInput
                          label={messages.address_line2}
                          name="addressLine2"
                          value={entity.getAddressLine2()}
                          meta={entity.getAddressLine2MetaInformation()}
                          validation={entity.getAddressLine2ValidationResult()}
                          onChange={this.updateEntity}
                          disabled={disabled}
                        />
                      </Col>
                    </Row>

                    <Row className="clearfix no-margin">
                      <Col sm={6}>
                        <FormInput
                          label={messages.zip_code}
                          name="zipCode"
                          value={entity.getZipCode()}
                          meta={entity.getZipCodeMetaInformation()}
                          validation={entity.getZipCodeValidationResult()}
                          onChange={this.updateEntity}
                          disabled={disabled}
                        />
                      </Col>
                      <Col sm={6}>
                        <FormInput
                          label={messages.city}
                          name="city"
                          value={entity.getCity()}
                          meta={entity.getCityMetaInformation()}
                          validation={entity.getCityValidationResult()}
                          onChange={this.updateEntity}
                          disabled={disabled}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </div>

              {(withEmailContact || withPhoneContact) && (
                <div className="m-t-15 form-group-attached">
                  {withEmailContact && (
                    <FormInput
                      type="email"
                      label={messages.email}
                      name="email"
                      value={entity.getEmail()}
                      meta={entity.getEmailMetaInformation()}
                      validation={entity.getEmailValidationResult()}
                      onChange={this.updateEntity}
                      disabled={disabled}
                    />
                  )}
                  {withPhoneContact && (
                    <FormInput
                      type="number"
                      label={messages.phone}
                      name="phone"
                      value={entity.getPhone()}
                      meta={entity.getPhoneMetaInformation()}
                      validation={entity.getPhoneValidationResult()}
                      onChange={this.updateEntity}
                      disabled={disabled}
                    />
                  )}
                </div>
              )}

              {withMessage && (
                <div className="m-t-15 form-group-attached">
                  <FormTextarea
                    label={messages.message}
                    name="message"
                    value={entity.getMessage()}
                    meta={entity.getMessageMetaInformation()}
                    validation={entity.getMessageValidationResult()}
                    onChange={this.updateEntity}
                    disabled={disabled}
                  />
                </div>
              )}

              {/* this.isFacebookMessengerActive() && (
                <Row className="no-margin">
                  <Col xs={12}>
                    <h5 className={textClass}>{messages.receive_updates_in_messenger}</h5>
                    <FacebookMessengerOptin />
                  </Col>
                </Row>
              ) */}

              <RichEditorContent
                content={'<p class="' + textClass + '">' + this.getParamValue('infosBottom', '') + '</p>'}
              />

              <p className={textClass + ' small'}>{this.getPrivacyPolicyText()}</p>
              <div className="text-right">
                <Button
                  type="submit"
                  className="call-to-action btn-animated from-left fa fa-arrow-right"
                  bsStyle="primary"
                  onClick={this.onClick}
                >
                  <span>{this.getParamValue('buttonTitle', '')}</span>
                </Button>
              </div>
            </Form>
          )}
        </Element>

        {this.getAppendHtml()}

        <ModalPrivacyPolicy show={showPrivacyPolicy} close={this.closePrivacyPolicy} />
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementLeadForm.defaultProps = {
  index: '0',
};

const mapStateToProps = state => {
  return {
    formStatus: state.formStatus.cmsLeadForm,
    meta: state.entityMeta,
    projectConfig: state.data.projectConfig,
    facebookMessengerOptin: state.facebookMessengerOptin,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSubmit: args => {
      formStatusDispatchHelper(
        'cmsLeadForm',
        '/api/cms/leadForm.json?' + stringifyQs({ groupSequences: args.groupSequences }),
        args,
        'post',
        dispatch
      );
    },
    resetForm: () => {
      formReset('cmsLeadForm');
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CmsElementLeadForm);
