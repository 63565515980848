import axios from 'axios';

export const INVOICE_MARK_REFUNDED = 'INVOICE_MARK_REFUNDED';
export const INVOICE_MARK_REFUNDED_PENDING = 'INVOICE_MARK_REFUNDED_PENDING';
export const INVOICE_MARK_REFUNDED_FULFILLED = 'INVOICE_MARK_REFUNDED_FULFILLED';

export const INVOICE_MARK_DENIED_TO_PAY = 'INVOICE_MARK_DENIED_TO_PAY';
export const INVOICE_MARK_DENIED_TO_PAY_PENDING = 'INVOICE_MARK_DENIED_TO_PAY_PENDING';
export const INVOICE_MARK_DENIED_TO_PAY_FULFILLED = 'INVOICE_MARK_DENIED_TO_PAY_FULFILLED';

export const INVOICE_MARK_PAID_AMOUNT = 'INVOICE_MARK_PAID_AMOUNT';
export const INVOICE_MARK_PAID_AMOUNT_PENDING = 'INVOICE_MARK_PAID_AMOUNT_PENDING';
export const INVOICE_MARK_PAID_AMOUNT_FULFILLED = 'INVOICE_MARK_PAID_AMOUNT_FULFILLED';

export const INVOICE_SENT_REMINDERS = 'INVOICE_SENT_REMINDERS';
export const INVOICE_SENT_REMINDERS_PENDING = 'INVOICE_SENT_REMINDERS_PENDING';
export const INVOICE_SENT_REMINDERS_FULFILLED = 'INVOICE_SENT_REMINDERS_FULFILLED';

function createInvoiceUpdateAction(type, listName, url, data, updatedInvoice) {
  const payload = {
    promise: axios
      .post(url, data)
      .then(response => response.data)
      .then(invoice => ({ invoice, listName }))
      .catch(err => console.error(err)),
    data: { invoice: updatedInvoice, listName },
  };

  return {
    type,
    payload,
  };
}

export function markInvoiceRefunded(invoice, value, listName) {
  const updatedInvoice = { ...invoice };
  updatedInvoice.is_manually_refunded = value; // for immediate UI update

  const url = `/api/invoices/${invoice.id}/refunds.json`;
  const data = { isManuallyRefunded: value };

  return createInvoiceUpdateAction(INVOICE_MARK_REFUNDED, listName, url, data, updatedInvoice);
}

export function markInvoiceDeniedToPay(invoice, value, listName) {
  const updatedInvoice = { ...invoice };
  updatedInvoice.is_denied_to_pay = value; // for immediate UI update

  const url = `/api/invoices/${invoice.id}/deniedtopays.json`;
  const data = { isDeniedToPay: value };

  return createInvoiceUpdateAction(INVOICE_MARK_DENIED_TO_PAY, listName, url, data, updatedInvoice);
}

export function markInvoicePaidAmount(invoice, amount, listName) {
  const updatedInvoice = { ...invoice };
  updatedInvoice.payment_info.payed = amount; // for immediate UI update

  const url = `/api/invoices/${invoice.id}/paids.json`;
  const data = { amount };

  return createInvoiceUpdateAction(INVOICE_MARK_PAID_AMOUNT, listName, url, data, updatedInvoice);
}

export function setInvoiceSentReminders(invoice, sentReminders, listName) {
  const updatedInvoice = { ...invoice };
  updatedInvoice.sent_reminders = sentReminders; // for immediate UI update

  const url = `/api/invoices/${invoice.id}/sentreminders.json`;
  const data = { sentReminders };

  return createInvoiceUpdateAction(INVOICE_SENT_REMINDERS, listName, url, data, updatedInvoice);
}
