import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Clearfix } from 'react-bootstrap';
import { Range } from 'rc-slider';
import { find as _find, isNumber as _isNumber } from 'lodash';

import { setFilter } from 'actions/SummaryActions';
import { parseQs, stringifyQs } from 'helpers/http';

class CarSliderFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mouseOver: false,
      show: false,
      changed: false,
      min: props.filters[props.urlNameMin]
        ? parseFloat(props.filters[props.urlNameMin])
        : props.useNullMin
        ? 0
        : this.getMin(),
      max: props.filters[props.urlNameMax] ? parseFloat(props.filters[props.urlNameMax]) : this.getMax(),
    };
  }

  getName = () => {
    return this.props.name;
  };

  valueClick = values => {
    let changed = false;
    let state = {};
    if (values[0] !== this.state.min) {
      state.min = values[0];
      changed = true;
    }
    if (values[1] !== this.state.max) {
      state.max = values[1];
      changed = true;
    }

    if (changed) {
      this.setState({ ...state, changed });
    }
  };

  setFilter = () => {
    const { history, location, urlNameMin, urlNameMax } = this.props;

    let filters = {};
    if (this.state.min) {
      filters[urlNameMin] = this.state.min;
    }
    if (this.state.max) {
      filters[urlNameMax] = this.state.max;
    }

    history.replace({ pathname: location.pathname, search: stringifyQs({ ...parseQs(location.search), ...filters }) });
    this.setState({ changed: false });
  };

  getMin = () => {
    const { facets, facetNameMin } = this.props;

    const facet = _find(facets.data.list, ['id', facetNameMin]);
    return facet && facet.values[0] && _isNumber(facet.values[0].count) ? facet.values[0].count : 0;
  };

  getMax = () => {
    const { facets, facetNameMax } = this.props;

    const facet = _find(facets.data.list, ['id', facetNameMax]);
    return facet && facet.values[0] && _isNumber(facet.values[0].count) ? facet.values[0].count : 0;
  };

  hasFilters = () => {
    const { filters, urlNameMin, urlNameMax } = this.props;
    return filters[urlNameMin] !== undefined || filters[urlNameMax] !== undefined;
  };

  // make sure that when the values change outside of the range (for example user resets all or just one filter),
  //   the slider adjusts accordingly
  componentDidUpdate = prevProps => {
    const { urlNameMin, urlNameMax, filters } = this.props;

    const prevMin = parseFloat(prevProps.filters[urlNameMin] ? prevProps.filters[urlNameMin] : 0);
    const currentMin = parseFloat(filters[urlNameMin] ? filters[urlNameMin] : 0);

    const prevMax = parseFloat(prevProps.filters[urlNameMax] ? prevProps.filters[urlNameMax] : this.getMax());
    const currentMax = parseFloat(filters[urlNameMax] ? filters[urlNameMax] : this.getMax());

    const stateUpdate = {};
    let doUpdateState = false;

    if (currentMin !== prevMin) {
      stateUpdate.min = currentMin;
      doUpdateState = true;
    }

    if (currentMax !== prevMax) {
      stateUpdate.max = currentMax;
      doUpdateState = true;
    }

    if (doUpdateState) {
      this.setState(stateUpdate);
    }
  };

  render() {
    const { useNullMin, disabled } = this.props;

    const min = useNullMin ? 0 : this.getMin();
    const max = this.getMax();

    return (
      <div>
        <div class="p-l-15 p-r-15 p-b-5">
          <Range
            allowCross={false}
            defaultValue={[min, max]}
            value={[this.state.min, this.state.max]}
            min={min}
            max={this.state.max > max ? this.state.max : max}
            onChange={this.valueClick}
            onAfterChange={this.setFilter}
            disabled={disabled}
          />
        </div>

        <Clearfix class="p-l-15 p-r-15">
          <div class="pull-left">{this.props.formatter(this.state.min)}</div>
          <div class="pull-right">{this.props.formatter(this.state.max)}</div>
        </Clearfix>
      </div>
    );
  }
}
CarSliderFilter.defaultProps = {
  formatter: value => {
    return value;
  },
  name: 'PRICE',
  urlNameMin: 'MIN_PRICE',
  urlNameMax: 'MAX_PRICE',
  facetNameMin: 'minPrice',
  facetNameMax: 'maxPrice',
  disabled: false,
  useNullMin: true,
};

const mapDispatchToProps = dispatch => {
  return {
    setFilter: (name, values) => {
      dispatch(setFilter(name, values));
    },
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(CarSliderFilter)
);
