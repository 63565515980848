import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import ThemedContext from 'js/contexts/ThemedContext';

class Modal extends React.PureComponent {
  onEntering = (dialogEl, themeClass) => {
    if (!dialogEl || !dialogEl.parentElement || !themeClass) {
      return;
    }
    dialogEl.parentElement.className += ' ' + themeClass;
  };

  /**
   * The component is applying the class from the ThemedContext
   * in the root dialog element appended to the body. Since the
   * "onEntering" method is called after the dom is rendered
   * we need extra CSS for ".chedri_modal" to prevent window jumping
   * while opening and closing dialog.
   */
  render() {
    return (
      <ThemedContext.Consumer>
        {themeClass => (
          <React.Fragment>
            <style
              dangerouslySetInnerHTML={{
                __html: `
              .creacheck_modal { position: fixed; }
            `,
              }}
            />
            <BootstrapModal
              ref={ref => (this.modalRef = ref)}
              {...this.props}
              className={'creacheck_modal ' + this.props.className}
              onEntering={dialogEl => this.onEntering(dialogEl, themeClass)}
            ></BootstrapModal>
          </React.Fragment>
        )}
      </ThemedContext.Consumer>
    );
  }
}

Modal.propTypes = BootstrapModal.propTypes;
Modal.defaultProps = BootstrapModal.defaultProps;
// Modal.childContextTypes = BootstrapModal.childContextTypes;

Modal.Body = BootstrapModal.Body;
Modal.Header = BootstrapModal.Header;
Modal.Title = BootstrapModal.Title;
Modal.Footer = BootstrapModal.Footer;

Modal.Dialog = BootstrapModal.ModalDialog;

Modal.TRANSITION_DURATION = BootstrapModal.TRANSITION_DURATION;
Modal.BACKDROP_TRANSITION_DURATION = BootstrapModal.BACKDROP_TRANSITION_DURATION;

export default Modal;
