import React from 'react';
import { injectIntl } from 'react-intl';

import MoreInfoStepB from 'components/layout/checkout/moreInfo/MoreInfoStepB';

class StepBItemCheckoutInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMoreInfo: false,
    };
  }

  closeMoreInfo = () => {
    this.setState({ showMoreInfo: false });
  };

  render() {
    const { intl } = this.props;
    const messages = intl.messages;

    return (
      <div>
        <i class="fa fa-shopping-cart fa-2x hint-text" />
        <h2>{messages.checkout}</h2>
        <p>{messages.please_provide_address_delivery_method_payment}</p>

        <MoreInfoStepB show={this.state.showMoreInfo} onHide={this.closeMoreInfo} />
      </div>
    );
  }
}

export default injectIntl(StepBItemCheckoutInfo);
