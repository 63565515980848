import React from 'react';
import { components } from 'react-select';

class TeamOption extends React.Component {
  render() {
    const { data } = this.props;
    return <components.Option {...this.props}>{data.label}</components.Option>;
  }
}

class TeamValue extends React.Component {
  render() {
    const { data } = this.props;

    return <div className="Select-value">{data.label}</div>;
  }
}

const teamOptionFormatter = options => {
  return options.list.map(option => {
    return teamSingleOptionFormatter(option);
  });
};

const teamSingleOptionFormatter = (option = null) => {
  if (option && option.id) {
    return { ...option, value: option.id, label: option.name };
  }

  return null;
};

const teamOptionFromEntity = team => {
  return !team
    ? null
    : teamSingleOptionFormatter({
        id: team.getId(),
        name: team.getName(),
      });
};

export { TeamValue, TeamOption, teamOptionFormatter, teamSingleOptionFormatter, teamOptionFromEntity };
