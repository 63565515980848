import React from 'react';
import Modal from 'components/Modal';

import { injectIntl } from 'react-intl';

import ChatterReviewNewForm from 'components/forms/chatter/ChatterReviewNewForm';

class CustomerChatReview extends React.Component {
  closeAfterSave = () => {
    const { close } = this.props;
    close();
  };

  render() {
    const {
      intl: { messages },
      show,
      close,
      reviewData,
    } = this.props;

    return (
      <Modal show={show} onHide={close} bsSize="lg">
        <Modal.Header>
          <Modal.Title>{messages.please_rate_us}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChatterReviewNewForm shown={show} onSubmitParent={this.closeAfterSave} reviewData={reviewData} />
        </Modal.Body>
      </Modal>
    );
  }
}

export default injectIntl(CustomerChatReview);
