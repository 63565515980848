import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { throttle as _throttle } from 'lodash';

import { dispatchResizeEvent } from 'helpers/window';

import RichEditorContent from 'components/RichEditorContent';

import AbstractCmsElement from './AbstractCmsElement';

class CmsElementHero extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      translateY: 0,
      opacity: 1,
      shown: false,
    };

    this.containerRef = React.createRef();
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  componentDidMount = () => {
    window.addEventListener('scroll', this.parallax);

    const backgroundUrl = this.getParamValue('backgroundUrl');
    if (backgroundUrl) {
      const img = new Image();
      img.onload = () => {
        dispatchResizeEvent();
      };
      img.src = this.getParamValue('backgroundUrl');
    }

    this.__componentDidMount();
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.parallax);
    this.__componentWillUnmount();
  };

  parallax = () => {
    _throttle(() => {
      const container = this.containerRef.current;
      const containerRectangle = container.getBoundingClientRect();

      const containerHeight = containerRectangle.bottom - containerRectangle.top;

      const opacity =
        (containerHeight - (containerRectangle.top < 0 ? Math.abs(containerRectangle.top) : 0)) / containerHeight;

      this.setState({
        translateY: (1 - opacity) * containerHeight * 0.3,
        opacity: opacity * 1.5,
      });
    }, 50)();
  };

  render() {
    const { translateY, opacity, shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const content = this.getParamValue('content', '');

    const headerStyle = {
      opacity,
      transform: 'translateY(' + translateY + 'px)',
      height: this.getParamValue('fullHeight', 'no') === 'yes' ? this.props.layout.height + 'px' : 'auto',
    };

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={
          'ms-hero jumbotron bg-transparent full-height no-padding ' +
          shownClass +
          this.getResponsiveClasses() +
          this.getCssClasses()
        }
        ref={this.containerRef}
      >
        {this.getElementStyle()}

        <div
          class="container-fluid container-fixed-lg sm-p-l-20 sm-p-r-20"
          style={{
            ...headerStyle,
            ...this.getBackgroundStyle(),
          }}
        >
          <div class="content inner full-height">
            <div class="container-xs-height full-height">
              <div class={'col-xs-height ' + (this.getParamValue('vAlignMiddle', false) ? 'col-middle ' : '')}>
                <Grid fluid={this.getParamValue('isFluid', 'no') === 'yes'}>
                  <Row>
                    <Col xs={12} class={'text-' + this.getParamValue('mainColor', 'master')}>
                      <RichEditorContent content={'<div>' + content + '</div>'} />
                    </Col>
                  </Row>
                </Grid>
              </div>
            </div>
          </div>
        </div>

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementHero.defaultProps = {
  index: '0',
};

const mapStateToProps = state => {
  return {
    layout: state.layout,
  };
};

export default connect(mapStateToProps)(CmsElementHero);
