import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';

import LazyLoadingGoogleMap from 'components/LazyLoadingGoogleMap';

import MapAreaMarker from 'components/layout/MapAreaMarker';
import WorkingHours from 'components/layout/client/WorkingHours';

class LocationInfos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    this.setState({ shown: true });
  };

  showTestDriveInquiry = e => {
    e.preventDefault();

    const { doShowTestDriveInquiry } = this.props;
    doShowTestDriveInquiry();
  };

  render() {
    const { messages, appIntl, data, locationData, locationType } = this.props;
    const { shown } = this.state;

    let workingHoursData = null;
    if (!locationData.pending) {
      switch (locationType) {
        case 'rental': {
          workingHoursData = locationData.data.working_week_rental_point;
          break;
        }

        default: {
          workingHoursData = locationData.data.working_week;
        }
      }
    }

    return (
      <ScrollTrigger onEnter={this.shown}>
        <Grid>
          <Row className="p-t-50 p-b-50 item-details-location-infos">
            <Col xs={12} md={6} className="p-r-60 sm-p-r-0 sm-m-b-20">
              <h2>
                <FormattedMessage
                  id="you_can_find_this_and_other_vehicles_at_company"
                  values={{ company: data.owner.company }}
                />
              </h2>

              {!locationData.pending && (
                <React.Fragment>
                  <p className="fs-18">
                    {locationData.data.street} {locationData.data.house_number}
                    <br />
                    {locationData.data.zip_code} {locationData.data.city}
                  </p>
                  <div className="fs-18">{!!workingHoursData && <WorkingHours data={workingHoursData} />}</div>
                </React.Fragment>
              )}

              <Button
                bsStyle="primary"
                className="btn-trp m-t-40 p-l-60 p-r-60 sm-p-l-30 sm-p-r-30"
                onClick={this.showTestDriveInquiry}
              >
                {messages.test_drive}
              </Button>
            </Col>
            <Col xs={12} md={6}>
              {shown && (
                <LazyLoadingGoogleMap
                  language={appIntl.locale}
                  center={{
                    lat: data.default_location.coordinates.lat,
                    lng: data.default_location.coordinates.lng,
                  }}
                >
                  <MapAreaMarker
                    lat={data.default_location.coordinates.lat}
                    lng={data.default_location.coordinates.lng}
                    title={data.owner.company}
                    tooltip={
                      <div>
                        {data.default_location.street} {data.default_location.house_number}
                        <br />
                        {data.default_location.zip_code} {data.default_location.city}
                      </div>
                    }
                  />
                </LazyLoadingGoogleMap>
              )}
            </Col>
          </Row>
        </Grid>
      </ScrollTrigger>
    );
  }
}
LocationInfos.defaultProps = {
  locationType: 'sales',
};

export default LocationInfos;
