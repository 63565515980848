import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { forEach as _forEach } from 'lodash';

import { entityMetaStatic } from 'js/constants';
import { entityMetaPending, entityMetaSend, entityMetaFulfilled, entityMetaRejected } from 'actions/EntityMetaActions';

class EntityMeta extends React.Component {
  checkMetaData = () => {
    const {
      intl: { messages },
      metaData,
      entityMetaIdentifier,
      fetch,
      loadFromStatic,
    } = this.props;

    // check if we already have the meta data in store. If not, fetch meta data
    if (!metaData) {
      if (entityMetaStatic[entityMetaIdentifier]) {
        const data = entityMetaStatic[entityMetaIdentifier];

        // load messages from keys
        _forEach(data.data, (property, propertyName) => {
          _forEach(property.validate.messages, (validationMessage, validationRule) => {
            if (messages[validationMessage]) {
              data.data[propertyName].validate.messages[validationRule] = messages[validationMessage];
            }
          });
        });

        loadFromStatic(entityMetaIdentifier, data);
      } else {
        fetch(entityMetaIdentifier, `/api/entitymetadatas/${entityMetaIdentifier}.json`, {}, 'get');
      }
    }
  };

  componentDidMount = () => {
    this.checkMetaData();
  };

  componentDidUpdate = prevProps => {
    const { entityMetaIdentifier } = this.props;

    if (entityMetaIdentifier !== prevProps.entityMetaIdentifier) {
      this.checkMetaData();
    }
  };

  render() {
    return null;
  }
}
EntityMeta.defaultProps = {
  entityMetaIdentifier: '',
  metaData: false,
};

const mapDispatchToProps = dispatch => {
  return {
    fetch: (entityMetaIdentifier, url, data) => {
      // add meta to queue
      dispatch(entityMetaPending(entityMetaIdentifier));

      // finished callback will trigger an event
      const finishedCallback = response => {
        return dispatch(entityMetaFulfilled(entityMetaIdentifier, response));
      };
      // error callback will trigger an event
      const errorCallback = error => {
        return dispatch(entityMetaRejected(entityMetaIdentifier, error));
      };
      // dispatch fetching the meta
      dispatch(entityMetaSend(url, data, finishedCallback, errorCallback));
    },
    loadFromStatic: (entityMetaIdentifier, data) => {
      dispatch(entityMetaPending(entityMetaIdentifier));
      setTimeout(() => {
        dispatch(entityMetaFulfilled(entityMetaIdentifier, { data }));
      }, 150);
    },
  };
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(EntityMeta)
);
