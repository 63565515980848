import React from 'react';
import { components } from 'react-select';

class WhiteLabelLocationOption extends React.Component {
  render() {
    const { data } = this.props;
    return <components.Option {...this.props}>{data.label}</components.Option>;
  }
}

class WhiteLabelLocationValue extends React.Component {
  render() {
    const { data } = this.props;

    return <div className="Select-value">{data.label}</div>;
  }
}

const whiteLabelLocationOptionFormatter = options => {
  return options.list.map(option => {
    return whiteLabelLocationSingleOptionFormatter(option);
  });
};

const whiteLabelLocationSingleOptionFormatter = option => {
  return {
    ...option,
    value: option.id,
    label: option.name,
  };
};

const whiteLabelLocationOptionFromEntity = whiteLabelLocation => {
  return !whiteLabelLocation
    ? null
    : whiteLabelLocationSingleOptionFormatter({
        id: whiteLabelLocation.getId(),
        name: whiteLabelLocation.getName(),
      });
};

export {
  WhiteLabelLocationOption,
  WhiteLabelLocationValue,
  whiteLabelLocationOptionFormatter,
  whiteLabelLocationSingleOptionFormatter,
  whiteLabelLocationOptionFromEntity,
};
