import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import GoogleMap from 'google-map-react';
import scriptLoader from 'react-async-script-loader';
import { DragSource, DropTarget } from 'react-dnd';

import Loader from 'components/layout/Loader';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

import { GOOGLE_MAPS_KEY, GOOGLE_MAPS_URL, mapOptions } from 'js/constants';
import { elementSource, elementTarget } from './elementDragUtils';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
class CmsElementMap extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  render() {
    const { appIntl, connectDropTarget, connectDragSource, connectDragPreview, isScriptLoaded } = this.props;
    const { shown } = this.state;

    if (!isScriptLoaded) {
      return <Loader />;
    }

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';

    const position = {
      lat: parseFloat(this.getParamValue('mapLat', 49.4456943)),
      lng: parseFloat(this.getParamValue('mapLng', 7.7704389)),
    };

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-map ' + shownClass + this.getCssClasses()}
        onClick={this.showSettings}
        style={{ ...this.getBackgroundStyle(), height: this.getParamValue('mapHeight', 300) + 'px' }}
      >
        {this.getElementStyle()}

        <GoogleMap
          bootstrapURLKeys={{
            key: GOOGLE_MAPS_KEY,
            language: appIntl.locale,
          }}
          defaultCenter={position}
          center={position}
          defaultZoom={this.getParamValue('mapZoom', 11)}
          options={mapOptions}
        />

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}

export default scriptLoader(GOOGLE_MAPS_URL)(CmsElementMap);
