export const getFieldParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const matchingFields = {
    names: [],
    values: []
  };

  urlParams.forEach((value, key) => {
    const inputFields = document.querySelectorAll(`input[name="${key}"]`);
    inputFields.forEach(inputField => {
      if (inputField && inputField.name === key && inputField.value !== value) {
        inputField.value = value;
        matchingFields.names.push(key);
        matchingFields.values.push(value);
      }
    });
  });

  if (matchingFields.names.length > 0) {
    return matchingFields;
  }
};

export const observeBodyForFormGroups = (context) => {
  const observer = new MutationObserver(() => {
    const { names, values } = getFieldParams() || {};
    if (names && values) {
      context.updateEntityMultiple(names, values);
    }
  });
  observer.observe(document.body, { childList: true, subtree: true });
}
