import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage, FormattedNumber } from 'react-intl';

//import MoreInfoStepEItemCheckout from 'components/layout/checkout/moreInfo/MoreInfoStepEItemCheckout'

import { SHIPPING_PARCEL_COST, PICKUP_AVAILABLE } from 'js/constants';

// information about shipping methods for item orders
class StepEItemCheckoutInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMoreInfo: false,
    };
  }

  closeMoreInfo = () => {
    this.setState({ showMoreInfo: false });
  };

  render() {
    const { intl, projectConfig } = this.props;
    const messages = intl.messages;

    return (
      <div>
        <i class="fa fa-truck fa-2x hint-text" />
        <h2>{messages.shipping_information}</h2>
        <p>
          {SHIPPING_PARCEL_COST > 0 ? (
            <span>
              <FormattedMessage
                id="we_offer_parcel_shipment_for_just_amount"
                values={{
                  amount: (
                    <FormattedNumber
                      value={SHIPPING_PARCEL_COST}
                      style="currency"
                      currency={projectConfig.data.currency}
                    />
                  ),
                }}
              />
            </span>
          ) : (
            messages.free_delivery_on_all_our_orders
          )}
        </p>

        {PICKUP_AVAILABLE ? <p>{messages.we_can_complete_your_order_in_shop_for_pickup}</p> : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    projectConfig: state.data.projectConfig,
  };
};

export default injectIntl(connect(mapStateToProps)(StepEItemCheckoutInfo));
