const initialState = {
  lead: {
    pending: false,
    hasError: false,
    last: '',
  },
};

export default function watching(state = initialState, action) {
  switch (action.type) {
    case 'WATCH_LEAD_PENDING':
    case 'UNWATCH_LEAD_PENDING': {
      return { ...state, lead: { ...state.lead, pending: true } };
    }

    case 'WATCH_LEAD_REJECTED':
    case 'UNWATCH_LEAD_REJECTED': {
      return { ...state, lead: { ...state.lead, pending: false, hasError: true } };
    }

    case 'WATCH_LEAD_FULFILLED': {
      return { ...state, lead: { ...state.lead, pending: false, last: 'watch' } };
    }

    case 'UNWATCH_LEAD_FULFILLED': {
      return { ...state, lead: { ...state.lead, pending: false, last: 'unwatch' } };
    }
  }

  return state;
}
