export default function docRequiredIssueDate(entity) {
  let isValid = true,
    helpTextTranslationIndex = 'doc_issue_date_required',
    fields = [];

  if (!entity.getIssueDate()) {
    isValid = false;
    fields = [{ name: 'issueDate', fieldTree: [] }];
  }

  return {
    valid: isValid,
    rule: 'docRequiredIssueDate',
    helpTextTranslationIndex,
    fields,
  };
}
