import React from 'react';
import { components } from 'react-select';

class VendorOption extends React.Component {
  render() {
    const { data } = this.props;
    console.log("vendoroptions", data)
    return (
      <components.Option {...this.props}>
        {data.isHighlighted && (
          <>
            <span className="small text-primary p-b-5">{data.highlightTitle}</span>
            <br />
          </>
        )}
        {!!data.profile.company && (
          <>
            <strong>{data.profile.company}</strong>
            <br />
          </>
        )}
        {data.profile.first_name} {data.profile.last_name} {data.username ? '(' + data.username + ')' : null}
      </components.Option>
    );
  }
}

class VendorValue extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <div className="Select-value">
        <strong>{data.profile.company}</strong> {data.profile.first_name} {data.profile.last_name}{' '}
        {!!data.username && '(' + data.username + ')'}
      </div>
    );
  }
}

const vendorOptionFormatter = options => {
  return options.list.map(option => {
    return vendorSingleOptionFormatter(option);
  });
};

const vendorSingleOptionFormatter = (option = null) => {
  if (option && option.username) {
    let label = '';
    if (option.profile && option.profile.company) {
      label += option.profile.company + ' ';
    }
    if (option.profile && option.profile.first_name) {
      label += option.profile.first_name + ' ';
    }
    if (option.profile && option.profile.last_name) {
      label += option.profile.last_name + ' ';
    }
    if (option.profile && option.profile.street) {
      label += option.profile.street + ' ';
    }
    if (option.profile && option.profile.city) {
      label += option.profile.city + ' ';
    }

    return { ...option, value: option.username, label };
  }

  return null;
};

const vendorOptionFromEntity = client => {
  return !client
    ? null
    : vendorSingleOptionFormatter({
        username: client.getUsername(),
        profile: {
          company: client.getProfile().getCompany(),
          first_name: client.getProfile().getFirstName(),
          last_name: client.getProfile().getLastName(),
          street: client.getProfile().getStreet(),
          city: client.getProfile().getCity(),
        },
      });
};

const vendorOptionFromEntityV2 = client => {
  return !client
    ? null
    : vendorSingleOptionFormatter({
        username: client.getUsername,
        profile: {
          company: client.getProfile.getCompany,
          first_name: client.getProfile.getFirstName,
          last_name: client.getProfile.getLastName,
          street: client.getProfile.getStreet,
          city: client.getProfile.getCity,
        },
      });
};

export {
  VendorValue,
  VendorOption,
  vendorOptionFormatter,
  vendorSingleOptionFormatter,
  vendorOptionFromEntity,
  vendorOptionFromEntityV2,
};
