import React from 'react';
import { connect } from 'react-redux';
import { isEqual as _isEqual } from 'lodash';

import { entityMetaMapping } from 'js/constants';

import EntityMeta from 'components/EntityMeta';

import { dataIdPending, dataIdSend, dataIdFulfilled, dataIdRejected } from 'actions/DataIdActions';

class DataId extends React.Component {
  componentDidMount() {
    const { dataIdName, url, dataIdId, requestData, method, fetch } = this.props;

    // fetch initial data
    if (dataIdName && dataIdId && url) {
      fetch(dataIdName, dataIdId, url, requestData, method);
    }
  }

  componentDidUpdate = prevProps => {
    const { appIntl, dataIdName, url, dataIdId, requestData, method, fetch } = this.props;

    if (
      (dataIdName !== prevProps.dataIdName && url) ||
      (url !== prevProps.url && dataIdName) ||
      appIntl.locale !== prevProps.appIntl.locale ||
      !_isEqual(requestData, prevProps.requestData)
    ) {
      fetch(dataIdName, dataIdId, url, requestData, method);
    }
  };

  render() {
    const { dataIdName, meta } = this.props;

    return entityMetaMapping[dataIdName] !== undefined ? (
      <EntityMeta entityMetaIdentifier={entityMetaMapping[dataIdName]} metaData={meta[entityMetaMapping[dataIdName]]} />
    ) : null;
  }
}
DataId.defaultProps = {
  dataIdName: '',
  dataIdId: '',
  url: '',
  requestData: {},
  method: 'get',
};

const mapStateToProps = state => {
  return {
    meta: state.entityMeta,
    appIntl: state.intl,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetch: (dataIdName, dataIdId, url, data, method) => {
      // add data to queue
      dispatch(dataIdPending(dataIdName, dataIdId));

      // finished callback will trigger an event
      const finishedCallback = response => {
        return dispatch(dataIdFulfilled(dataIdName, dataIdId, response));
      };
      // error callback will trigger an event
      const errorCallback = error => {
        return dispatch(dataIdRejected(dataIdName, dataIdId, error));
      };
      // dispatch fetching the data
      dispatch(dataIdSend(url, data, finishedCallback, errorCallback, method));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataId);
