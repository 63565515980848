import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Clearfix, Col, Form, Button, Overlay, Alert } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';
import moment from 'moment';

import { elementSource, elementTarget } from './elementDragUtils';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

import FormTimePicker from 'components/forms/FormTimePicker';
import DatePicker from 'components/forms/DatePicker';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
export default class CmsElementCommerceCarRent extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      dateFrom: moment()
        .add(2, 'days')
        .toISOString(),
      dateTo: moment()
        .add(3, 'days')
        .toISOString(),

      coordinates: '',
      address: localStorage.getItem('locationAddress') || '',
      addressInputFocused: false,
      addressInputValue: localStorage.getItem('locationAddress') || '',

      shown: false,
    };

    moment.locale(props.intl.locale);
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  render() {
    const { intl, appIntl, user, connectDropTarget, connectDragSource, connectDragPreview } = this.props;
    const { addressInputFocused, shown } = this.state;
    const messages = intl.messages;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const timezone = user && user.profile ? user.profile.timezone : 'UTC';
    const textClass = 'text-' + this.getParamValue('mainColor', 'master') + ' ';
    const layoutVersion = this.getParamValue('layoutVersion', 'wide');

    const parallaxData = this.getParallaxData();

    const locationInput = (
      <div class={'form-group form-group-default ' + (addressInputFocused ? 'focused ' : '')}>
        <input
          value={''}
          class="form-control"
          type="text"
          onFocus={() => {
            this.setState({ addressInputFocused: true });
          }}
          onBlur={() => this.setState({ addressInputFocused: false })}
        />
      </div>
    );

    const dateFromInput = (
      <Clearfix>
        <div class="pull-left text-master" style={{ width: '50%' }}>
          <DatePicker
            value={this.state.dateFrom}
            minDate={moment()}
            maxDate={moment()}
            locale={appIntl.locale}
            displayTz={timezone}
            style={{ borderRight: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          />
        </div>
        <div class="pull-left text-master" style={{ width: '50%' }}>
          <FormTimePicker
            value={this.state.dateFrom}
            locale={appIntl.locale}
            displayTz={timezone}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          />
        </div>
      </Clearfix>
    );

    const dateToInput = (
      <Clearfix>
        <div class="pull-left text-master" style={{ width: '50%' }}>
          <DatePicker
            value={this.state.dateTo}
            minDate={moment()}
            locale={appIntl.locale}
            displayTz={timezone}
            style={{ borderRight: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          />
        </div>
        <div class="pull-left text-master" style={{ width: '50%' }}>
          <FormTimePicker
            value={this.state.dateTo}
            locale={appIntl.locale}
            displayTz={timezone}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          />
        </div>
      </Clearfix>
    );

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-ecommerce-car-rent-content ' + shownClass + this.getCssClasses()}
        onClick={this.showSettings}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        {layoutVersion === 'narrow' && (
          <Clearfix>
            <Col xs={6}>
              <h4 class={'no-margin font-secondary fs-13 text-uppercase bold lh-16 ' + textClass}>
                {messages.pick_up_time}
              </h4>
              {dateFromInput}
            </Col>
            <Col xs={6}>
              <h4 class={'no-margin font-secondary fs-13 text-uppercase bold lh-16 ' + textClass}>
                {messages.drop_off_time}
              </h4>
              {dateToInput}
            </Col>
            <Col xs={10} xsOffset={2}>
              <h4 class={'no-margin font-secondary fs-13 text-uppercase lh-16 bold ' + textClass}>
                {messages.desired_pickup_location}
              </h4>
              {locationInput}
            </Col>
            <Col xs={12} class="text-right">
              <Button bsStyle="primary" bsSize="lg">
                <FormattedMessage id="we_found_count_cars" values={{ count: 23 }} />
              </Button>
            </Col>
          </Clearfix>
        )}

        {layoutVersion === 'wide' && (
          <Clearfix>
            <Col xs={12} lg={5}>
              <h4 class={'no-margin font-secondary fs-13 text-uppercase lh-16 ' + textClass}>
                {messages.desired_pickup_location}
              </h4>
              {locationInput}
            </Col>
            <Col xs={6} lg={3}>
              <h4 class={'no-margin font-secondary fs-13 text-uppercase lh-16 ' + textClass}>
                {messages.pick_up_time}
              </h4>
              {dateFromInput}
            </Col>
            <Col xs={6} lg={3}>
              <h4 class={'no-margin font-secondary fs-13 text-uppercase lh-16 ' + textClass}>
                {messages.drop_off_time}
              </h4>
              {dateToInput}
            </Col>
            <Col xs={12} mdOffset={6} md={6} lgOffset={0} lg={1}>
              <h4 class="no-margin font-secondary fs-13 lh-16 hidden-xs hidden-sm hidden-md">{'\u00A0'}</h4>{' '}
              {/* dummy element so the button aligns with the input elements */}
              <Button bsStyle="primary" class="hidden-xs hidden-sm hidden-md" block>
                <i class="fa fa-chevron-right" />
              </Button>
              <Button bsStyle="primary" bsSize="lg" class="hidden-lg text-uppercase" block>
                {messages.get_a_quote}
              </Button>
            </Col>
          </Clearfix>
        )}

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}
