/**
 * When WhiteLabel Settings define an email validation pattern,
 * ensure that it matches provided email address.
 *
 * @param {*} entity
 * @returns Object
 */

const { whiteLabelSettings } = global;

export default function emailPattern(entity) {
  const result = {
    valid: true,
    rule: 'emailPattern',
    helpTextTranslationIndex: 'email_invalid',
    fields: [],
  };

  if (!whiteLabelSettings) {
    return result;
  }

  const faultyResult = {
    valid: false,
    fields: [{ name: 'email', fieldTree: [] }],
  };

  const {
    email_validation_pattern: emailValidationPattern,
    is_email_validation_enabled: isEmailValidationEnabled,
  } = whiteLabelSettings;

  if (isEmailValidationEnabled && emailValidationPattern) {
    let patternBody = emailValidationPattern;

    // wrapping in slashes is optional
    if (patternBody.startsWith('/') && patternBody.endsWith('/')) {
      patternBody = patternBody.substring(1, patternBody.length - 1);
    }

    try {
      const re = new RegExp(patternBody, 'i');
      if (!re.test(entity.getEmail)) {
        return { ...result, ...faultyResult };
      }
    } catch (error) {
      // TODO: notify WhiteLabel owners about bad regular expression in the settings
      console.error(error);
    }
  }

  return result;
}
