import React, { Fragment, createRef, Component } from 'react';
import { connect } from 'react-redux';

import Slider from 'rc-slider/lib/Slider';
import _, {forEach as _forEach} from 'lodash';
import { Panel } from 'react-bootstrap';
import Scrollbar from 'react-scrollbars-custom';
import ProgressBar from 'react-bootstrap/lib/ProgressBar';
import ReactTooltip from 'react-tooltip';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { getPageNameIndex } from './actions/TemplateSettings';
import TemplateBlockCategory from './TemplateBlockCategory';
import EditorPageNavigator from './EditorPageNavigator';
import EditorPage from './EditorPage';
import { updateVar } from '../../actions/LayoutActions';
import { getDeviceType } from './actions/deviceType';
import {cloneDeep as _cloneDeep} from 'lodash';
import EditorBlock from "cc-editor/components/EditorBlock";

class EditorCanvas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageZoom: 1,
      pageOffset: 0,
      stageOffset: 0,
      pageWidth: null,
      pageHeight: null,
      stageWidth: null,
      stageHeight: null,
      printPages: false,
      printPages3D: false,
      gridActive: false,
      trimBorderActive: ' active',
      hideBars: {stageWrapHeight: "", stageWrapTop: "", stageHeight: ""},
      showKeyboard: true,
      allUsedBlocks: [],
      allUsedBlocksContent: [],
      contentPagesPrev: undefined,


    };
    this.zoomerRef = createRef();
    this.stageRef = createRef();
  }

  updatePageSize = (pageWidth, pageHeight) => {
    this.setState({ pageWidth, pageHeight });
  };

  updateStageSize = () => {
    if (
      this.stageRef.current &&
      this.stageRef.current.offsetWidth !== null &&
      this.stageRef.current.offsetHeight !== null
    ) {
      this.setState({
        stageWidth: this.stageRef.current.offsetWidth,
        stageHeight: this.stageRef.current.offsetHeight,
      });
    }
  };

  fitWidth = () => {
    const { pageWidth, stageWidth } = this.state;
    const neededZoom = (stageWidth - 250) / (pageWidth + 40);

    this.setState({ pageZoom: parseFloat(neededZoom.toFixed(2)) });
  };

  fitHeight = () => {
    const { pageHeight, stageHeight } = this.state;
    const neededZoom = (stageHeight - 80) / pageHeight;
    this.setState({ pageZoom: parseFloat(neededZoom.toFixed(2)) });
  };

  fitAuto = () => {
    const { pageWidth, stageWidth, pageHeight, stageHeight, hideBars } = this.state;
    let neededZoomWidth;
    if (getDeviceType() === 'mobile') {
      neededZoomWidth = (stageWidth - 40) / pageWidth;
      if (this.props.viewActive) {
        this.props.updateVar('viewActiveFalse');
      }
    } else {
      neededZoomWidth = (stageWidth - 250) / (pageWidth + 40);
    }
    const neededZoomHeight = (stageHeight - 80) / pageHeight;
    const neededZoom = neededZoomHeight < neededZoomWidth ? neededZoomHeight : neededZoomWidth;
    let _hideBars = _cloneDeep(hideBars);
    if(this.props.updateNotification !== undefined){
      _hideBars.stageWrapHeight = 'calc(100% - 200px)';
    }else{
      _hideBars.stageWrapHeight = 'calc(100% - 150px)';
    }

    _hideBars.stageWrapTop = '';
    _hideBars.stageHeight = '';
    this.props.updateVar("ccToolbarBlock");
    this.setState({ pageZoom: parseFloat(neededZoom.toFixed(2)), hideBars: _hideBars });
  };

  fitByMobileZoom = (zElementHeight, zElementWidth) => {
    const { hideBars } = this.state;
    const neededZoom = this.props.zoomFactor * 1;
    let _hideBars = _cloneDeep(hideBars);

    _hideBars.stageWrapHeight = '100%';
    _hideBars.stageWrapTop = '0px';
    _hideBars.stageHeight = 'calc(100% - 0px)';
    this.props.updateVar("ccToolbarNone");
    this.setState({ pageZoom: parseFloat(neededZoom.toFixed(2)), hideBars: _hideBars }, () => {
      this.props.scrollView.scrollIntoView({ block: 'start' });
    });
  };

  toggleTrimBorder = () => {
    if (this.state.trimBorderActive === '') {
      this.setState({ trimBorderActive: ' active' });
    } else {
      this.setState({ trimBorderActive: '' });
    }
  };

  showPages = what => {
    this.setState({ printPages: !what });
  };

  showPages3D = what => {
    this.setState({ printPages3D: !what });
  };

  showGrid = what => {
    this.setState({ gridActive: !what });
  };

  setCurrentPage = e => {
    const { updateCurrentPage } = this.props;
    updateCurrentPage(parseInt(e.target.innerText) - 1);
  };

  componentDidMount = () => {
    const stageWidth = this.stageRef.current.offsetWidth;
    const stageHeight = this.stageRef.current.offsetHeight;
    this.setAllUsedBlocks(this.props.contentPages)
    this.setState(
      {
        stageWidth,
        stageHeight,
      },
      this.fitAuto
    );

    window.addEventListener('resize', _.throttle(this.updateStageSize, 200));
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const { setActiveHeight, contentPages } = this.props;
    const { pageHeight, contentPagesPrev } = this.state;
    if(prevProps.updateNotification !== this.props.updateNotification){
        this.fitAuto();
    }

    /* INFO - the comparison with the prevProps provided by react
    doesn't work in this case for some reason. even the manually
    created state "contentPagesPrev" always has a relation to contentPages.
    therefore the use of _cloneDeep is absolutely necessary in this case
    */

    if(JSON.stringify(contentPages) !== JSON.stringify(contentPagesPrev)){
      this.setState({contentPagesPrev: _cloneDeep(contentPages)});
      this.setAllUsedBlocks(contentPages)

    }

    if (prevState.pageHeight !== pageHeight) {
      setActiveHeight(pageHeight);
    }

    if (this.props.activateZoom === true) {
      this.fitByMobileZoom(this.props.zHeight, this.props.zWidth);
      this.props.updateVar('activateZoomFalse');
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateStageSize);
  };

  toggleKeyboard = () => {
    const { showKeyboard } = this.state;
    this.setState({ showKeyboard: !showKeyboard });
  };

  setAllUsedBlocks = (contentPages) => {
    const allUsedBlocks = [];
    const allUsedBlocksContent = [];
    _.forEach(contentPages, (pages, i) => {
      _.forEach(pages?.blocks, (block, i) => {
        let usedBlock = {id: block?.template_id}
        let usedBlockContent = block;
        allUsedBlocks.push(usedBlock)
        allUsedBlocksContent.push(usedBlockContent);
        if(block?.blocks?.length > 0){
          _.forEach(block?.blocks, (containerBlock, i) => {
            let usedBlockInsideContainer = {id: containerBlock?.template_id}
            let usedBlockContentInsideContainer = containerBlock;
            allUsedBlocks.push(usedBlockInsideContainer);
            allUsedBlocksContent.push(usedBlockContentInsideContainer);
          });
        }
      });
    });
    this.setState({allUsedBlocks: allUsedBlocks, allUsedBlocksContent: allUsedBlocksContent});
  }



  render() {
    const {
      imageUpload,
      usedMedia,
      filesUploading,
      raster,
      currentPage,
      errorsCount,
      errorsText,
      contentPages,
      changeBlock,
      removeContentBlock,
      resetBlock,
      copyBlock,
      copiedBlock,
      pasteBlock,
      pasteData,
      resizeBlock,
      templateSettings,
      templateBlocks,
      updateParam,
      pagePreview,
      pageFinder,
      updateCurrentPage,
      insertContentBlock,
      appIntl,
      updateInnerBlock,
      blockContainer,
      setBlockContainer,
      unsetBlockContainer,
      website,
      showTour,
      setActiveHeight,
      navEntry,
      resetNavEntry,
      init,
      leftPaddings,
      rightPaddings,
      topPaddings,
      bottomPaddings,
      pageHeights,
      pageWidths,
      publisherInfo,
      rootUser,
      wlAdminUser,
      isSubmission,
      onlyWhitelabelAdminCanModifyBlocks,
      savingR,
      goToLastPageState,
      goToLastPage,
      resetPage,
      resetPageState,
      intl: { messages },
      saveDescription,
      updateBlocksState,
      uploadError,
      fontSizeSettings,
      colorSettings
    } = this.props;
    const { pageZoom, stageWidth, printPages, printPages3D, gridActive, trimBorderActive, hideBars, showKeyboard } = this.state;
    const spaceConsumed = contentPages[parseInt(currentPage, 10)]?.space_consumed;
    const formatPages = templateSettings.format;
    let systemProgress = <ProgressBar bsStyle="success" now={0} />;
    let bs = 'danger';
    if (savingR) {
      systemProgress = (
        <ProgressBar
          id="progSAVE"
          active
          striped
          bsStyle="primary"
          now={100}
          label={messages.editor.content_is_saved}
        />
      );
    } else if (filesUploading.length > 0) {
      const totalFiles = filesUploading.length;
      const maxProgress = 100 / totalFiles;
      const progressInstance = [];
      for (let i = 0; i < totalFiles; i++) {
        const now = maxProgress; // filesUploading[i]['completed'] * (maxProgress / 100);
        const currentProgress = (
          <ProgressBar
            key={"totalFiles_" + i}
            id={`prog${i}`}
            active
            striped
            bsStyle={bs}
            now={now}
            label={`Upload ${i + 1} von ${totalFiles}`}
          />
        );
        progressInstance.push(currentProgress);
        if (bs === 'danger') {
          bs = 'warning';
        } else if (bs === 'warning') {
          bs = 'info';
        } else if (bs === 'info') {
          bs = 'success';
        } else {
          bs = 'danger';
        }
      }
      systemProgress = (
        <ProgressBar>
          {progressInstance.map((pgi, i) => {
            return pgi;
          })}
        </ProgressBar>
      );
    }

    const pageNameIndex = getPageNameIndex(currentPage, templateSettings, contentPages.length);
    const contentBlocks = contentPages[parseInt(currentPage)].blocks;

    const previousPages = [];
    const nextPages = [];
    let printPagesOption = false;
    let printPageActive = '';
    let printPage3DActive = '';
    let grid = '';
    if (printPages) {
      printPageActive = 'active';
    }
    if (printPages3D) {
      printPage3DActive = 'active';
    }
    if (gridActive) {
      grid = ' grid';
    }
    if (formatPages.divide_pages === 'double') {
      printPagesOption = true;
      if (printPages) {
        if (currentPage === 0) {
          if (
            formatPages.order_pages !== 'first-last-single' &&
            formatPages.order_pages !== 'down-up' &&
            formatPages.order_pages !== 'firstDown-up'
          ) {
            nextPages.push(currentPage + 1);
          } else if (formatPages.order_pages === 'firstDown-up') {
            previousPages.push(currentPage + 1);
          }
        } else if (currentPage === contentPages.length - 1) {
          if (formatPages.order_pages !== 'first-last-single' && formatPages.order_pages !== 'up-down') {
            previousPages.push(currentPage - 1);
          }
        } else if (currentPage % 2 === 0) {
          if (formatPages.order_pages === 'first-last-single') {
            previousPages.push(currentPage - 1);
          } else {
            nextPages.push(currentPage + 1);
          }
        } else if (formatPages.order_pages === 'first-last-single' && formatPages.order_pages !== 'firstDown-up') {
          nextPages.push(currentPage + 1);
        } else if (currentPage === 1 && formatPages.order_pages === 'firstDown-up') {
          nextPages.push(currentPage - 1);
        } else {
          previousPages.push(currentPage - 1);
        }
      }
    } else if (formatPages.divide_pages === 'half') {
      printPagesOption = true;
      if (printPages) {
        const halfPages = contentPages.length / 2;
        const endHalf = halfPages > currentPage ? halfPages : contentPages.length;
        for (let i = halfPages <= currentPage ? halfPages : 0; i < endHalf; i++) {
          if (i < currentPage) {
            if (formatPages.order_pages === 'down-up') {
              if (i < halfPages) {
                nextPages.unshift(i);
              } else {
                previousPages.push(i);
              }
            } else {
              previousPages.push(i);
            }
          } else if (i > currentPage) {
            if (formatPages.order_pages === 'down-up') {
              if (i < halfPages) {
                previousPages.unshift(i);
              } else {
                nextPages.push(i);
              }
            } else {
              nextPages.push(i);
            }
          }
        }
      }
    } else if (formatPages.divide_pages === 'double-single') {
      printPagesOption = true;
      if (printPages) {
        if (currentPage === 0) {
          previousPages.push(currentPage + 1);
        } else if (currentPage === 1) {
          nextPages.push(currentPage - 1);
        } else if (currentPage === 2) {
          nextPages.push(currentPage + 1);
        } else if (currentPage === 3) {
          previousPages.push(currentPage - 1);
        }
      }
    }

    let previousWidth = 0;
    let previousHeight = 0;
    let nextWidth = 0;
    let nextHeight = 0;
    const widthFix =
      (previousPages.length + nextPages.length + 1) *
        ((pageWidths[currentPage] ? pageWidths[currentPage] : pageWidths[0]) * pageZoom) +
      200 +
      'px';

    const pageIndex = currentPage.toString();
    let newPageZoom;
    let wtErrors = '';

    if (errorsCount === 0) {
      wtErrors = (
        <a href="#" data-tip="Weiter so!" data-for="top" className="no-problems">
          {messages.editor.no_error}
        </a>
      );
    } else {
      wtErrors = (
        <a href="#" data-tip={errorsText} data-for="top" data-html="true" data-type="error" className="problems">
          {errorsCount} {messages.editor.error}
        </a>
      );
    }
    const currentEvenOdd = (parseInt(currentPage) + 1) % 2 === 0 ? ' even' : ' odd';

    let showIt = 'none';
    if (this.props.viewActive) {
      showIt = 'block';
    }

    let stageColorClasses = '';
    let filteredStageColorSettings = [];
    filteredStageColorSettings = _.filter(colorSettings, setting => setting.id === 'stage');
    _forEach(filteredStageColorSettings, (filteredStageColorSetting, i) => {
      stageColorClasses = stageColorClasses + ' ' + filteredStageColorSetting.color;
    });


    return (
      <div key="scarlet_editor" id="scarlet_editor">
        <div key="stage-wrap" id="stage-wrap" style={{ height: hideBars.stageWrapHeight, top: hideBars.stageWrapTop }}>
          <ReactTooltip id="errorinfo" place="top" effect="solid" />
          <div
            key="stage"
            ref={this.stageRef}
            id="stage"
            className={'anzahl_' + contentPages.length + stageColorClasses}
            data-page={currentPage}
            data-zoom={pageZoom}
            style={{height: hideBars.stageHeight}}
          >
            <Scrollbar style={{ width: '100%', height: '100%' }}>
              <div key="scrollbar" className="widthFix" style={{ width: widthFix }}>
                {(printPages || printPages3D) && previousPages.length !== 0
                  ? previousPages.map((pPage, i) => {
                      const evenOdd = (parseInt(pPage) + 1) % 2 === 0 ? ' even' : ' odd';

                      previousWidth += parseFloat(
                        pageWidths[parseInt(pPage)] ? pageWidths[parseInt(pPage)] : pageWidths[0]
                      );
                      previousHeight += parseFloat(
                        pageHeights[parseInt(pPage)] ? pageHeights[parseInt(pPage)] : pageHeights[0]
                      );
                      const pageNameIndexP = getPageNameIndex(parseInt(pPage), templateSettings, contentPages.length);
                      return (
                        <EditorPage
                          type="previous"
                          raster={raster}
                          blocks={contentPages[parseInt(pPage)].blocks}
                          spaceConsumed={contentPages[parseInt(pPage)].space_consumed}
                          trimBorderActive={trimBorderActive}
                          grid={grid}
                          view3d={printPages3D}
                          currentPage={pPage}
                          previous={i}
                          evenOdd={evenOdd}
                          pageWidth={pageWidths[parseInt(pPage)] ? pageWidths[parseInt(pPage)] : pageWidths[0]}
                          pageHeight={pageHeights[parseInt(pPage)] ? pageHeights[parseInt(pPage)] : pageHeights[0]}
                          marginWidth={previousWidth}
                          marginHeight={previousHeight}
                          paddingTop={
                            templateSettings.pages[pageNameIndexP].padding_top !== null
                              ? [templateSettings.pages[pageNameIndexP].padding_top]
                              : topPaddings
                              ? parseFloat(topPaddings[parseInt(pPage)] ? topPaddings[parseInt(pPage)] : topPaddings[0])
                              : null
                          }
                          paddingRight={
                            templateSettings.pages[pageNameIndexP].padding_right !== null
                              ? [templateSettings.pages[pageNameIndexP].padding_right]
                              : rightPaddings
                              ? parseFloat(
                                  rightPaddings[parseInt(pPage)] ? rightPaddings[parseInt(pPage)] : rightPaddings[0]
                                )
                              : null
                          }
                          paddingBottom={
                            templateSettings.pages[pageNameIndexP].padding_bottom !== null
                              ? [templateSettings.pages[pageNameIndexP].padding_bottom]
                              : bottomPaddings
                              ? parseFloat(
                                  bottomPaddings[parseInt(pPage)] ? bottomPaddings[parseInt(pPage)] : bottomPaddings[0]
                                )
                              : null
                          }
                          paddingLeft={
                            templateSettings.pages[pageNameIndexP].padding_left !== null
                              ? [templateSettings.pages[pageNameIndexP].padding_left]
                              : leftPaddings
                              ? parseFloat(
                                  leftPaddings[parseInt(pPage)] ? leftPaddings[parseInt(pPage)] : leftPaddings[0]
                                )
                              : null
                          }
                          settings={templateSettings}
                          updateParam={updateParam}
                          updateSpecialBlockAttributes={this.props.updateSpecialBlockAttributes}
                          stageWidth={stageWidth}
                          index={pageIndex}
                          pageZoom={pageZoom}
                          blockContainer={blockContainer}
                          updatePageSize={this.updatePageSize}
                          updateCurrentPage={updateCurrentPage}
                          website={website}
                          setActiveHeight={setActiveHeight}
                          navEntry={navEntry}
                          resetNavEntry={resetNavEntry}
                          pagesCount={contentPages.length}
                          filesUploading={filesUploading}
                          rootUser={rootUser}
                          wlAdminUser={wlAdminUser}
                          isSubmission={isSubmission}
                          onlyWhitelabelAdminCanModifyBlocks={onlyWhitelabelAdminCanModifyBlocks}
                          goToLastPageState={goToLastPageState}
                          goToLastPage={goToLastPage}
                          resetBlock={resetBlock}
                          resetPageState={resetPageState}
                          resetPage={resetPage}
                          updateBlocksState={updateBlocksState}
                          fitAuto={this.fitAuto}
                          getDeviceType={getDeviceType}
                          updateBlock={this.props.updateBlock}
                          resizeBlock={resizeBlock}
                          allUsedBlocks={this.state.allUsedBlocks}
                          initDefaultPosition={this.props.initDefaultPosition}
                          allUsedBlocksContent={this.state.allUsedBlocksContent}
                          setSelectedColor={this.props.setSelectedColor}
                          colorSettings={this.props.colorSettings}
                          deleteSelectedColor={this.props.deleteSelectedColor}
                          fontSizeSettings={fontSizeSettings}
                          setFontSizeSettings={this.props.setFontSizeSettings}
                        />
                      );
                    })
                  : null}
                <EditorPage
                  type="active"
                  raster={raster}
                  blocks={contentBlocks}
                  spaceConsumed={spaceConsumed}
                  trimBorderActive={trimBorderActive}
                  grid={grid}
                  view3d={printPages3D}
                  currentPage={currentPage}
                  previous={previousPages.length}
                  settings={templateSettings}
                  updateParam={updateParam}
                  updateSpecialBlockAttributes={this.props.updateSpecialBlockAttributes}
                  stageWidth={stageWidth}
                  evenOdd={currentEvenOdd}
                  pageWidth={pageWidths[parseInt(currentPage)] ? pageWidths[parseInt(currentPage)] : pageWidths[0]}
                  pageHeight={pageHeights[parseInt(currentPage)] ? pageHeights[parseInt(currentPage)] : pageHeights[0]}
                  marginWidth={
                    previousWidth +
                    parseFloat(pageWidths[parseInt(currentPage)] ? pageWidths[parseInt(currentPage)] : pageWidths[0])
                  }
                  marginHeight={
                    previousHeight +
                    parseFloat(pageHeights[parseInt(currentPage)] ? pageHeights[parseInt(currentPage)] : pageHeights[0])
                  }
                  paddingTop={
                    templateSettings.pages[pageNameIndex].padding_top !== null
                      ? [templateSettings.pages[pageNameIndex].padding_top]
                      : topPaddings
                      ? parseFloat(
                          topPaddings[parseInt(currentPage)] ? topPaddings[parseInt(currentPage)] : topPaddings[0]
                        )
                      : null
                  }
                  paddingRight={
                    templateSettings.pages[pageNameIndex].padding_right !== null
                      ? [templateSettings.pages[pageNameIndex].padding_right]
                      : rightPaddings
                      ? parseFloat(
                          rightPaddings[parseInt(currentPage)] ? rightPaddings[parseInt(currentPage)] : rightPaddings[0]
                        )
                      : null
                  }
                  paddingBottom={
                    templateSettings.pages[pageNameIndex].padding_bottom !== null
                      ? [templateSettings.pages[pageNameIndex].padding_bottom]
                      : bottomPaddings
                      ? parseFloat(
                          bottomPaddings[parseInt(currentPage)]
                            ? bottomPaddings[parseInt(currentPage)]
                            : bottomPaddings[0]
                        )
                      : null
                  }
                  paddingLeft={
                    templateSettings.pages[pageNameIndex].padding_left !== null
                      ? [templateSettings.pages[pageNameIndex].padding_left]
                      : leftPaddings
                      ? parseFloat(
                          leftPaddings[parseInt(currentPage)] ? leftPaddings[parseInt(currentPage)] : leftPaddings[0]
                        )
                      : null
                  }
                  index={pageIndex}
                  pageZoom={pageZoom}
                  updatePageSize={this.updatePageSize}
                  changeBlock={changeBlock}
                  removeContentBlock={removeContentBlock}
                  resetBlock={resetBlock}
                  pasteBlock={pasteBlock}
                  pasteData={pasteData}
                  copyBlock={copyBlock}
                  copiedBlock={copiedBlock}
                  resizeBlock={resizeBlock}
                  updateCurrentPage={updateCurrentPage}
                  imageUpload={imageUpload}
                  usedMedia={usedMedia}
                  updateInnerBlock={updateInnerBlock}
                  updateBlock={this.props.updateBlock}
                  templateBlocks={templateBlocks}
                  blockContainer={blockContainer}
                  setBlockContainer={setBlockContainer}
                  unsetBlockContainer={unsetBlockContainer}
                  website={website}
                  setActiveHeight={setActiveHeight}
                  navEntry={navEntry}
                  resetNavEntry={resetNavEntry}
                  pagesCount={contentPages.length}
                  filesUploading={filesUploading}
                  publisherInfo={publisherInfo}
                  rootUser={rootUser}
                  wlAdminUser={wlAdminUser}
                  isSubmission={isSubmission}
                  onlyWhitelabelAdminCanModifyBlocks={onlyWhitelabelAdminCanModifyBlocks}
                  saveDescription={saveDescription}
                  goToLastPageState={goToLastPageState}
                  goToLastPage={goToLastPage}
                  resetPageState={resetPageState}
                  resetPage={resetPage}
                  updateBlocksState={updateBlocksState}
                  uploadError={uploadError}
                  fitAuto={this.fitAuto}
                  getDeviceType={getDeviceType}
                  showKeyboard={showKeyboard}
                  allUsedBlocks={this.state.allUsedBlocks}
                  initDefaultPosition={this.props.initDefaultPosition}
                  allUsedBlocksContent={this.state.allUsedBlocksContent}
                  setSelectedColor={this.props.setSelectedColor}
                  colorSettings={this.props.colorSettings}
                  getColorSettings={this.props.getColorSettings}
                  deleteSelectedColor={this.props.deleteSelectedColor}
                  fontSizeSettings={fontSizeSettings}
                  setFontSizeSettings={this.props.setFontSizeSettings}
                />
                {(printPages || printPages3D) && nextPages.length !== 0
                  ? nextPages.map((nPage, i) => {
                      nextWidth += parseFloat(
                        pageWidths[parseInt(nPage)] ? pageWidths[parseInt(nPage)] : pageWidths[0]
                      );
                      nextHeight += parseFloat(
                        pageHeights[parseInt(nPage)] ? pageHeights[parseInt(nPage)] : pageHeights[0]
                      );
                      const pageNameIndexN = getPageNameIndex(parseInt(nPage), templateSettings, contentPages.length);
                      const evenOdd = (parseInt(nPage) + 1) % 2 === 0 ? ' even' : ' odd';
                      return (
                        <EditorPage
                          type="next"
                          raster={raster}
                          blocks={contentPages[parseInt(nPage)].blocks}
                          spaceConsumed={contentPages[parseInt(nPage)].space_consumed}
                          trimBorderActive={trimBorderActive}
                          grid={grid}
                          view3d={printPages3D}
                          currentPage={nPage}
                          previous={previousPages.length + 1 + i}
                          evenOdd={evenOdd}
                          pageWidth={pageWidths[parseInt(nPage)] ? pageWidths[parseInt(nPage)] : pageWidths[0]}
                          pageHeight={pageHeights[parseInt(nPage)] ? pageHeights[parseInt(nPage)] : pageHeights[0]}
                          marginWidth={
                            previousWidth +
                            nextWidth +
                            parseFloat(
                              pageWidths[parseInt(currentPage)] ? pageWidths[parseInt(currentPage)] : pageWidths[0]
                            )
                          }
                          marginHeight={
                            previousHeight +
                            nextHeight +
                            parseFloat(
                              pageHeights[parseInt(currentPage)] ? pageHeights[parseInt(currentPage)] : pageHeights[0]
                            )
                          }
                          paddingTop={
                            templateSettings.pages[pageNameIndexN].padding_top !== null
                              ? [templateSettings.pages[pageNameIndexN].padding_top]
                              : topPaddings
                              ? parseFloat(topPaddings[parseInt(nPage)] ? topPaddings[parseInt(nPage)] : topPaddings[0])
                              : null
                          }
                          paddingRight={
                            templateSettings.pages[pageNameIndexN].padding_right !== null
                              ? [templateSettings.pages[pageNameIndexN].padding_right]
                              : rightPaddings
                              ? parseFloat(
                                  rightPaddings[parseInt(nPage)] ? rightPaddings[parseInt(nPage)] : rightPaddings[0]
                                )
                              : null
                          }
                          paddingBottom={
                            templateSettings.pages[pageNameIndexN].padding_bottom !== null
                              ? [templateSettings.pages[pageNameIndexN].padding_bottom]
                              : bottomPaddings
                              ? parseFloat(
                                  bottomPaddings[parseInt(nPage)] ? bottomPaddings[parseInt(nPage)] : bottomPaddings[0]
                                )
                              : null
                          }
                          paddingLeft={
                            templateSettings.pages[pageNameIndexN].padding_left !== null
                              ? [templateSettings.pages[pageNameIndexN].padding_left]
                              : leftPaddings
                              ? parseFloat(
                                  leftPaddings[parseInt(nPage)] ? leftPaddings[parseInt(nPage)] : leftPaddings[0]
                                )
                              : null
                          }
                          settings={templateSettings}
                          updateParam={updateParam}
                          updateSpecialBlockAttributes={this.props.updateSpecialBlockAttributes}
                          stageWidth={stageWidth}
                          index={pageIndex}
                          pageZoom={pageZoom}
                          blockContainer={blockContainer}
                          updatePageSize={this.updatePageSize}
                          updateCurrentPage={updateCurrentPage}
                          website={website}
                          setActiveHeight={setActiveHeight}
                          navEntry={navEntry}
                          resetNavEntry={resetNavEntry}
                          pagesCount={contentPages.length}
                          filesUploading={filesUploading}
                          rootUser={rootUser}
                          wlAdminUser={wlAdminUser}
                          isSubmission={isSubmission}
                          onlyWhitelabelAdminCanModifyBlocks={onlyWhitelabelAdminCanModifyBlocks}
                          goToLastPageState={goToLastPageState}
                          goToLastPage={goToLastPage}
                          resetBlock={resetBlock}
                          resetPageState={resetPageState}
                          resetPage={resetPage}
                          updateBlocksState={updateBlocksState}
                          updateBlock={this.props.updateBlock}
                          resizeBlock={resizeBlock}
                          allUsedBlocks={this.state.allUsedBlocks}
                          initDefaultPosition={this.props.initDefaultPosition}
                          allUsedBlocksContent={this.state.allUsedBlocksContent}
                          setSelectedColor={this.props.setSelectedColor}
                          colorSettings={this.props.colorSettings}
                          deleteSelectedColor={this.props.deleteSelectedColor}
                          fontSizeSettings={fontSizeSettings}
                          setFontSizeSettings={this.props.setFontSizeSettings}
                        />
                      );
                    })
                  : null}
              </div>
            </Scrollbar>
          </div>
          <TemplateBlockCategory
            appIntl={appIntl}
            templateBlocks={templateBlocks}
            templateSettings={templateSettings}
            pageAmount={contentPages.length}
            usedBlocks={contentBlocks}
            spaceConsumed={spaceConsumed}
            pageSpace={templateSettings.pages[pageNameIndex].page_space}
            endless={!!templateSettings.pages[pageNameIndex].endless}
            insertContentBlock={insertContentBlock}
            updatePageSize={this.updatePageSize}
            currentPage={currentPage}
            raster={raster}
            blockContainer={blockContainer}
            init={init}
            allUsedBlocks={this.state.allUsedBlocks}
            allUsedBlocksContent={this.state.allUsedBlocksContent}
          />
        </div>
        <div id="pagesCanvas" className={'stagePreview' + stageColorClasses}>
          <Panel id="pagesPreview" defaultExpanded>
            <Panel.Heading>
              <Panel.Title toggle>{messages.editor.page_arrangement}</Panel.Title>
            </Panel.Heading>
            <Panel.Collapse>
              <Panel.Body>{pageFinder}</Panel.Body>
            </Panel.Collapse>
          </Panel>
          <EditorPageNavigator pages={pagePreview} updateCurrentPage={updateCurrentPage} />
        </div>
        <div id="context-modal">&nbsp;</div>

        {getDeviceType() === 'mobile' && (
          <>
            <div className="disableZoom" style={{ display: showIt }} onClick={() => this.fitAuto()}>
              <i className="fas fa-search-minus" />
            </div>
            <div className="disableKeyboard" style={{ display: showIt }} onClick={() => this.toggleKeyboard()}>
              <i className="fas fa-keyboard" />
            </div>
          </>
        )}
        <div id="toolbarBottom">
          <div id="errorBox">{wtErrors}</div>
          <div id="system-progress">{systemProgress}</div>
          <div id="leftSide">
            {printPagesOption ? (
              <Fragment>
                <a
                  href="#"
                  onClick={() => {
                    this.showPages(printPages);
                  }}
                  className={printPageActive}
                  data-tip={messages.editor.print_sheet_view}
                  data-for="top"
                >
                  <i className="far fa-columns" />
                </a>
              </Fragment>
            ) : null}
            <a
              href="#"
              onClick={() => {
                this.toggleTrimBorder();
              }}
              className={trimBorderActive}
              data-tip={messages.editor.view_safety_distance}
              data-for="top"
            >
              <i className="fad fa-border-outer" />
            </a>
            <a
              href="#"
              onClick={() => {
                this.showGrid(gridActive);
              }}
              className={grid}
              data-tip={messages.editor.view_structure}
              data-for="top"
            >
              <i className="fad fa-paragraph" />
            </a>
            <a
              href="#"
              onClick={() => {
                showTour();
              }}
              data-tip={messages.editor.start_tutorial}
              data-for="top"
            >
              <i className="fad fa-question" />
            </a>
          </div>
          <div id="zoom-tools">
            <a href="#" onClick={this.fitAuto} data-tip={messages.editor.adjust_by_window} data-for="top">
              <i className="fad fa-arrows-alt" />
            </a>
            <a href="#" onClick={this.fitWidth} data-tip={messages.editor.adjust_by_width} data-for="top">
              <i className="fad fa-arrows-alt-h" />
            </a>
            <a href="#" onClick={this.fitHeight} data-tip={messages.editor.adjust_by_height} data-for="top">
              <i className="fad fa-arrows-alt-v" />
            </a>
          </div>
          <div id="zoomer">
            <div
              className="intention"
              onClick={() => {
                if (pageZoom > 0.1) {
                  newPageZoom = parseFloat((pageZoom - 0.1).toFixed(2));

                  this.setState({ pageZoom: newPageZoom });
                }
              }}
            >
              -
            </div>
            <Slider
              ref={this.zoomerRef}
              step={0.01}
              min={0.01}
              max={2}
              marks={{
                0.01: '1%',
                0.5: '50%',
                1: '100%',
                1.5: '150%',
                2: '200%',
              }}
              defaultValue={pageZoom}
              value={pageZoom}
              onChange={zoom => {
                this.setState({ pageZoom: parseFloat(zoom.toFixed(2)) });
              }}
            />
            <div
              className="intention"
              onClick={() => {
                if (pageZoom < 2) {
                  newPageZoom = parseFloat((pageZoom + 0.1).toFixed(2));

                  this.setState({ pageZoom: newPageZoom });
                }
              }}
            >
              +
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateVar: data => {
      dispatch(updateVar(data));
    },
  };
};

const mapStateToProps = state => ({
  appIntl: state.intl,
  zHeight: state.layout.zHeight,
  zWidth: state.layout.zWidth,
  scrollView: state.layout.scrollView,
  viewActive: state.layout.viewActive,
  activateZoom: state.layout.activateZoom,
  zoomFactor: state.layout.zoomFactor,
  updateNotification: state.list.updates.updateNotification,
});

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(EditorCanvas)
  )
);
