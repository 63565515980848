import React from 'react';
import { injectIntl } from 'react-intl';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { Element, scroller } from 'react-scroll';
import { forEach as _forEach, isEmpty as _isEmpty, isInteger as _isInteger } from 'lodash';

import { mayQuestionHaveOptions, mayQuestionHaveMultipleAnswers, constructMeta } from 'helpers/questionnaire';
import { getSalutationOptions, getGenderForSalutation } from 'helpers/clients';

import FormErrors from 'components/forms/FormErrors';
import FormInput from 'components/forms/FormInput';
import FormTextarea from 'components/forms/FormTextarea';
import FormAutocomplete from 'components/forms/FormAutocomplete';
import FormTags from 'components/forms/FormTags';
import FormCheckbox from 'components/forms/FormCheckbox';
import FormRadio from 'components/forms/FormRadio';
import DatePicker from 'components/forms/DatePicker';

import ModalPrivacyPolicy from 'components/modals/ModalPrivacyPolicy';

import Entity from 'components/Entity';
import RichEditorContent from 'components/RichEditorContent';

import EntityStateUpdater from 'component/EntityStateUpdater';

let filledQuestionnaireIndex = 1;
class FilledQuestionnaireForm extends EntityStateUpdater {
  constructor(props) {
    super(props);

    const {
      intl: { messages },
      questionnaireData,
    } = props;

    this.entity = new Entity();

    this.entity.setData({
      salutation: 'mr',
      gender: 'male',
      country: 'DE',
    });
    this.entity.setMeta(constructMeta(questionnaireData, messages), 'Questionnaire');

    this.state = {
      ...this.state,
      id: filledQuestionnaireIndex++,
      entity: this.entity,
      entityLoaded: true,
      entityChanged: false,
      showPrivacyPolicy: false,
      showSuccess: false,
    };
  }

  componentDidUpdate = prevProps => {
    const { formStatus } = this.props;
    const { id } = this.state;

    if (prevProps.formStatus.pending && !formStatus.pending && !formStatus.hasError) {
      this.setState({ showSuccess: true }, () => {
        scroller.scrollTo('filled-questionnaire-container-' + id, {
          offset: -100,
        });
      });
    }
  };

  showPrivacyPolicy = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showPrivacyPolicy: true });
  };

  closePrivacyPolicy = () => {
    this.setState({ showPrivacyPolicy: false });
  };

  getPrivacyPolicyText = () => {
    const {
      intl: { messages },
    } = this.props;

    const text = messages.contact_agreement_text;

    const matches = [];
    text.replace(/({{1}[a-z_]+}{1}){1}/g, (match, g1, g2) => {
      matches.push({ match, pos: g2 });
    });

    const res = [];
    let currentIndex = 0;
    _forEach(matches, (match, i) => {
      switch (match.match) {
        // inject {data_use}
        case '{data_use}':
          res.push(text.substring(currentIndex, match.pos));
          res.push(
            <a href="#dataUse" onClick={this.showPrivacyPolicy} key={`data_use_${i}`}>
              {messages.signup_agreement_text_data_use}
            </a>
          );

          currentIndex = match.pos + 10;
          break;
      }
    });

    res.push(text.substring(currentIndex));

    return res;
  };

  getEntityProps = (question, i) => {
    const { formStatus } = this.props;
    const { entity } = this.state;

    return {
      name: i.toString(),
      label: question.label,
      value: this.extractAnswerValue(question, entity['get' + i]()),
      meta: entity['get' + i + 'MetaInformation'](),
      validation: entity['get' + i + 'ValidationResult'](),
      onChange: mayQuestionHaveMultipleAnswers(question.type) ? this.updateMultipleValues : this.updateSingleValue,
      disabled: formStatus.pending,
    };
  };

  extractAnswerValue = (question, entityValue) => {
    const isMultiple = mayQuestionHaveMultipleAnswers(question.type);

    if (isMultiple) {
      return entityValue.map(value => ({ value: value.getIndex(), label: value.getAnswer() }));
    }

    if (_isInteger(entityValue.getIndex())) {
      return entityValue.getIndex();
    }

    return entityValue.getAnswer();
  };

  getForm = () => {
    const {
      intl: { messages },
      mainColorClass,
      questionnaireData,
      formStatus,
    } = this.props;
    const { entity } = this.state;

    const res = [];

    let formGroup = [];
    _forEach(questionnaireData.questions, (question, i) => {
      if (question.type === 'paragraph') {
        if (!_isEmpty(formGroup)) {
          res.push(
            <div className="questionnaire-form-group form-group-attached" key={'group' + i}>
              {formGroup}
            </div>
          );
          formGroup = [];
        }

        res.push(
          <div className={'questionnaire-paragraph ' + mainColorClass} key={'paragraph' + i}>
            <RichEditorContent content={question.content} />
          </div>
        );
      } else {
        switch (question.type) {
          case 'client_data': {
            formGroup.push(
              <React.Fragment key={'field' + i}>
                <Row className="clearfix no-margin">
                  <Col sm={6}>
                    <FormAutocomplete
                      label={messages.salutation}
                      name="salutation"
                      value={entity.getSalutation()}
                      meta={entity.getSalutationMetaInformation()}
                      validation={entity.getSalutationValidationResult()}
                      onChange={this.updateSalutation}
                      options={getSalutationOptions(messages)}
                      disabled={formStatus.pending}
                    />
                  </Col>
                </Row>
                {/* <Row className="clearfix no-margin">
                  <Col sm={6}>
                    <FormInput
                      label={messages.title}
                      name="title"
                      value={entity.getTitle()}
                      meta={entity.getTitleMetaInformation()}
                      validation={entity.getTitleValidationResult()}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                  </Col>
                </Row> */}
                <Row className="clearfix no-margin">
                  <Col sm={6}>
                    <FormInput
                      label={messages.first_name}
                      name="firstName"
                      value={entity.getFirstName()}
                      meta={entity.getFirstNameMetaInformation()}
                      validation={entity.getFirstNameValidationResult()}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                  </Col>
                  <Col sm={6}>
                    <FormInput
                      label={messages.last_name}
                      name="lastName"
                      value={entity.getLastName()}
                      meta={entity.getLastNameMetaInformation()}
                      validation={entity.getLastNameValidationResult()}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                  </Col>
                </Row>
                <FormInput
                  label={messages.street}
                  name="street"
                  value={entity.getStreet()}
                  meta={entity.getStreetMetaInformation()}
                  validation={entity.getStreetValidationResult()}
                  onChange={this.updateEntity}
                  disabled={formStatus.pending}
                />
                <Row className="clearfix no-margin">
                  <Col sm={6}>
                    <FormInput
                      label={messages.house_number}
                      name="houseNumber"
                      value={entity.getHouseNumber()}
                      meta={entity.getHouseNumberMetaInformation()}
                      validation={entity.getHouseNumberValidationResult()}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                  </Col>
                  <Col sm={6}>
                    <FormInput
                      label={messages.apartment_number}
                      name="apartmentNumber"
                      value={entity.getApartmentNumber()}
                      meta={entity.getApartmentNumberMetaInformation()}
                      validation={entity.getApartmentNumberValidationResult()}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                  </Col>
                  <Col sm={12}>
                    <FormInput
                      label={messages.address_line2}
                      name="addressLine2"
                      value={entity.getAddressLine2()}
                      meta={entity.getAddressLine2MetaInformation()}
                      validation={entity.getAddressLine2ValidationResult()}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                  </Col>
                </Row>
                <Row className="clearfix no-margin">
                  <Col sm={6}>
                    <FormInput
                      label={messages.zip_code}
                      name="zipCode"
                      value={entity.getZipCode()}
                      meta={entity.getZipCodeMetaInformation()}
                      validation={entity.getZipCodeValidationResult()}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                  </Col>
                  <Col sm={6}>
                    <FormInput
                      label={messages.city}
                      name="city"
                      value={entity.getCity()}
                      meta={entity.getCityMetaInformation()}
                      validation={entity.getCityValidationResult()}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                  </Col>
                </Row>
                <FormAutocomplete
                  label={messages.country}
                  name="country"
                  value={entity.getCountry()}
                  meta={entity.getCountryMetaInformation()}
                  validation={entity.getCountryValidationResult()}
                  onChange={this.updateEntity}
                  autocompleteType="country"
                  complete
                  disabled={formStatus.pending}
                />
                <FormInput
                  type="email"
                  label={messages.email}
                  name="email"
                  value={entity.getEmail()}
                  meta={entity.getEmailMetaInformation()}
                  validation={entity.getEmailValidationResult()}
                  onChange={this.updateEntity}
                  disabled={formStatus.pending}
                />
                <FormInput
                  type="number"
                  label={messages.mobile_phone}
                  name="mobile"
                  value={entity.getMobile()}
                  meta={entity.getMobileMetaInformation()}
                  validation={entity.getMobileValidationResult()}
                  onChange={this.updateEntity}
                  disabled={formStatus.pending}
                />
              </React.Fragment>
            );
            break;
          }

          case 'location': {
            formGroup.push(
              <FormAutocomplete
                key={'field' + i}
                label={question.label}
                name="location"
                meta={entity.getLocationMetaInformation()}
                validation={entity.getLocationValidationResult()}
                onChange={this.updateEntity}
                autocompleteType="whiteLabelLocation"
                complete
                disabled={formStatus.pending}
              />
            );
            break;
          }

          case 'dropdown': {
            formGroup.push(
              <FormAutocomplete
                key={'field' + i}
                options={question.options.map((option, value) => {
                  return { value, label: option.label };
                })}
                {...this.getEntityProps(question, i)}
              />
            );
            break;
          }

          case 'tags': {
            formGroup.push(
              <FormTags
                key={'field' + i}
                options={question.options.map((option, value) => {
                  return { value, label: option.label };
                })}
                messages={messages}
                creatable={false}
                {...this.getEntityProps(question, i)}
              />
            );
            break;
          }

          case 'checkboxes': {
            formGroup.push(
              <FormCheckbox
                key={'field' + i}
                options={question.options.map((option, value) => {
                  return { value, label: option.label };
                })}
                {...this.getEntityProps(question, i)}
              />
            );
            break;
          }

          case 'radio': {
            formGroup.push(
              <FormRadio
                key={'field' + i}
                options={question.options.map((option, value) => {
                  return { value: value.toString(), label: option.label };
                })}
                {...this.getEntityProps(question, i)}
              />
            );
            break;
          }

          case 'text_input': {
            formGroup.push(<FormInput key={'field' + i} {...this.getEntityProps(question, i)} />);
            break;
          }

          case 'number_input': {
            formGroup.push(<FormInput type="number" key={'field' + i} {...this.getEntityProps(question, i)} />);
            break;
          }

          case 'multiline_input': {
            formGroup.push(<FormTextarea key={'field' + i} {...this.getEntityProps(question, i)} />);
            break;
          }

          case 'date': {
            formGroup.push(<DatePicker key={'field' + i} {...this.getEntityProps(question, i)} />);
            break;
          }
        }
      }
    });

    if (!_isEmpty(formGroup)) {
      res.push(
        <div className="questionnaire-form-group form-group-attached" key="group-end">
          {formGroup}
        </div>
      );
    }

    return res;
  };

  updateSingleValue = (questionIndex, value) => {
    const { questionnaireData } = this.props;

    // We have to check if the value is a number and if the question can have options.
    // Then we want to get the label (which is the value we want) and he values index in the option list.
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(parseInt(value, 10)) && mayQuestionHaveOptions(questionnaireData.questions[questionIndex].type)) {
      this.updateEntity(questionIndex.toString(), {
        question: questionIndex,
        index: value,
        answer: questionnaireData.questions[questionIndex].options[value].label,
      });
    } else {
      this.updateEntity(questionIndex.toString(), {
        question: questionIndex,
        index: null,
        answer: value,
      });
    }
  };

  updateMultipleValues = (questionIndex, values) => {
    const { questionnaireData } = this.props;

    const value = values.map(valueIndex => {
      return {
        question: questionIndex,
        index: valueIndex,
        answer: questionnaireData.questions[questionIndex].options[valueIndex].label,
      };
    });

    this.updateEntity(questionIndex.toString(), value);
  };

  updateSalutation = (name, value) => {
    const gender = getGenderForSalutation(value);

    const entity = this.state[this.getEntityName()];
    let newEntity = { ...entity.updateValue('salutation', value) };
    newEntity = { ...entity.updateValue('gender', gender) };
    this.changeEntity(newEntity);
  };

  onSubmit = e => {
    e.preventDefault();
    e.stopPropagation(); // if we are editing a page, we have to make sure the parent form will not be submitted

    this.setState(
      prevState => ({ entity: prevState.entity.validate() }),
      () => {
        if (this.state.entity.isValid) {
          this.props.onSubmit(this.state.entity.processedData);
        }
      }
    );
  };

  render() {
    const {
      intl: { messages },
      formStatus,
      mainColorClass,
      successMessage,
      buttonTitle,
    } = this.props;
    const { id, showSuccess, showPrivacyPolicy } = this.state;

    return (
      <Element name={'filled-questionnaire-container-' + id}>
        {showSuccess ? (
          <>
            {successMessage ? (
              <RichEditorContent content={successMessage} />
            ) : (
              <Alert bsStyle="success">{messages.weve_received_your_request_and_will_contact_you}</Alert>
            )}
          </>
        ) : (
          <>
            <FormErrors formStatus={formStatus} />

            <Form className="m-t-15" onSubmit={this.onSubmit}>
              {this.getForm()}

              <p className={mainColorClass + ' small'}>{this.getPrivacyPolicyText()}</p>
              <div className="text-right">
                <Button
                  type="submit"
                  className="call-to-action btn-animated from-left fa fa-arrow-right"
                  bsStyle="primary"
                  disabled={formStatus.pending}
                >
                  <span>{buttonTitle || messages.send}</span>
                </Button>
              </div>
            </Form>
            <ModalPrivacyPolicy show={showPrivacyPolicy} close={this.closePrivacyPolicy} />
          </>
        )}
      </Element>
    );
  }
}
FilledQuestionnaireForm.defaultProps = {
  onSubmit: () => {},
  mainColorClass: '',
  successMessage: null,
  buttonTitle: null,
};

export default injectIntl(FilledQuestionnaireForm);
