import React from 'react';
import scriptLoader from 'react-async-script-loader';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import moment from 'moment';
import 'moment-timezone';

import Loader from 'components/layout/Loader';

import { GOOGLE_MAPS_URL } from 'js/constants';

import AbstractCmsElement from './AbstractCmsElement';
import SearchVersionLocation from './commerceCarRent/SearchVersionLocation';
import SearchVersionMap from './commerceCarRent/SearchVersionMap';

class CmsElementCommerceCarRent extends AbstractCmsElement {
  static SEARCH_LOCATION_ID_KEY = 'CAR_RENT_LOCATION_ID';

  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };

    moment.locale(props.intl.locale);
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  render() {
    const { isScriptLoaded } = this.props;

    if (!isScriptLoaded) {
      return <Loader />;
    }

    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const searchVersion = this.getParamValue('searchVersion', 'location');

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        className={
          'ms-ecommerce-car-rent-content relative ' + shownClass + this.getResponsiveClasses() + this.getCssClasses()
        }
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        {searchVersion === 'location' && <SearchVersionLocation {...this.props} />}
        {searchVersion === 'map' && <SearchVersionMap {...this.props} />}

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementCommerceCarRent.defaultProps = {
  index: '0',
  lockLocation: false,
};

export default scriptLoader(GOOGLE_MAPS_URL)(CmsElementCommerceCarRent);
