import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { injectIntl } from 'react-intl';

import FormInput from 'components/forms/FormInput';
import FormAutocomplete from 'components/forms/FormAutocomplete';

import EntityPropsUpdater from 'component/EntityPropsUpdater';

class CompanySignupStepCompanyData extends EntityPropsUpdater {
  render() {
    const {
      intl: { messages },
      formStatus,
      entity,
      disabled,
      doValidate,
    } = this.props;

    return (
      <div style={{ overflow: 'visible' }}>
        <h3>{messages.company_singup_wizard_step_company_data}</h3>
        <div class="form-group-attached">
          <FormInput
            label={messages.company}
            name="company"
            value={entity.getCompany()}
            meta={entity.getCompanyMetaInformation()}
            validation={doValidate ? entity.getCompanyValidationResult() : undefined}
            onChange={this.updateEntity}
            disabled={disabled}
          />
          <FormInput
            type="tel"
            label={messages.phone}
            name="phone"
            value={entity.getPhone()}
            meta={entity.getPhoneMetaInformation()}
            validation={doValidate ? entity.getPhoneValidationResult() : undefined}
            onChange={this.updateEntity}
            disabled={disabled}
          />
          <FormInput
            type="email"
            label={messages.email}
            name="companyEmail"
            value={entity.getCompanyEmail()}
            meta={entity.getCompanyEmailMetaInformation()}
            validation={doValidate ? entity.getCompanyEmailValidationResult() : undefined}
            onChange={this.updateEntity}
            disabled={disabled}
          />
          <FormInput
            label={messages.street}
            name="street"
            value={entity.getStreet()}
            meta={entity.getStreetMetaInformation()}
            validation={doValidate ? entity.getStreetValidationResult() : undefined}
            onChange={this.updateEntity}
            disabled={disabled}
          />
          <Row class="clearfix no-margin">
            <Col sm={6}>
              <FormInput
                label={messages.house_number}
                name="houseNumber"
                value={entity.getHouseNumber()}
                meta={entity.getHouseNumberMetaInformation()}
                validation={doValidate ? entity.getHouseNumberValidationResult() : undefined}
                onChange={this.updateEntity}
                disabled={disabled}
              />
            </Col>
            <Col sm={6}>
              <FormInput
                label={messages.apartment_number}
                name="apartmentNumber"
                value={entity.getApartmentNumber()}
                meta={entity.getApartmentNumberMetaInformation()}
                validation={doValidate ? entity.getApartmentNumberValidationResult() : undefined}
                onChange={this.updateEntity}
                disabled={disabled}
              />
            </Col>
            <Col sm={12}>
              <FormInput
                label={messages.address_line2}
                name="addressLine2"
                value={entity.getAddressLine2()}
                meta={entity.getAddressLine2MetaInformation()}
                validation={doValidate ? entity.getAddressLine2ValidationResult() : undefined}
                onChange={this.updateEntity}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row class="clearfix no-margin">
            <Col sm={6}>
              <FormInput
                label={messages.zip_code}
                name="zipCode"
                value={entity.getZipCode()}
                meta={entity.getZipCodeMetaInformation()}
                validation={doValidate ? entity.getZipCodeValidationResult() : undefined}
                onChange={this.updateEntity}
                disabled={disabled}
              />
            </Col>
            <Col sm={6}>
              <FormInput
                label={messages.city}
                name="city"
                value={entity.getCity}
                meta={entity.getCityMetaInformation()}
                validation={doValidate ? entity.getCityValidationResult() : undefined}
                onChange={this.updateEntity}
                disabled={disabled}
              />
            </Col>
          </Row>
          <FormAutocomplete
            label={messages.country}
            name="country"
            value={entity.getCountry()}
            meta={entity.getCountryMetaInformation()}
            validation={doValidate ? entity.getCountryValidationResult() : undefined}
            onChange={this.updateEntity}
            autocompleteType="country"
            complete
            disabled={formStatus.pending}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    meta: state.entityMeta,
    user: state.login.user,
    formStatus: state.formStatus.companySignup,
  };
};

export default injectIntl(connect(mapStateToProps)(CompanySignupStepCompanyData));
