import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Row, Col, Button, ButtonGroup, DropdownButton, MenuItem, Clearfix } from 'react-bootstrap';
import { isEqual as _isEqual } from 'lodash';

import Pagination from 'components/layout/Pagination';
import ListFilterSearchTerm from 'components/layout/list/ListFilterSearchTerm';
import ListFilterSearchTermNumberAndSpecific from 'components/layout/list/ListFilterSearchTermNumberAndSpecific';

import { listDispatchHelper, changeListSort, changeActivePage } from 'actions/ListActions';

class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchType: localStorage.getItem(props.listName + 'ListSearchType') || 'basic',
      conditions: null,
    };

    this.currentRequestSource = null;
  }

  componentDidMount() {
    // fetch initial list
    const { listName, url, list, resPerPage, fetch } = this.props;

    if (!list.complete || !list.data.list.length) {
      fetch(
        this.currentRequestSource,
        this.setCurrentRequestSource,
        listName,
        url,
        this.getUrlData(),
        list.activePage,
        resPerPage || list.resPerPage,
        this.getSearchTerm(),
        list.sort,
        list.dir
      );
    }
  }

  newAction = e => {
    e.preventDefault();

    const { newAction } = this.props;
    newAction();
  };

  getUrlData = () => {
    const { urlData, metaIdentifier } = this.props;
    const { searchType, conditions } = this.state;

    const res = { ...urlData };
    if (searchType === 'advanced' && metaIdentifier) {
      res.conditions = conditions;
    }

    return res;
  };

  getSearchTerm = () => {
    const { metaIdentifier, list } = this.props;
    const { searchType } = this.state;

    let searchTerm = '';
    if (searchType === 'basic' || !metaIdentifier) {
      ({ searchTerm } = list);
    }

    return searchTerm;
  };

  handlePagination = page => {
    // when the page changes, we need to set the current state, then dispatch new event to fetch list
    const { listName, list, changeActivePage } = this.props;
    changeActivePage(listName, page, list.resPerPage);
  };

  handleSearch = (searchTerm, field) => {
    const { listName, url, list, resPerPage, fetch } = this.props;
    fetch(
      this.currentRequestSource,
      this.setCurrentRequestSource,
      listName,
      url,
      this.getUrlData(),
      1,
      resPerPage || list.resPerPage,
      field !== undefined ? field + searchTerm : searchTerm,
      list.sort,
      list.dir
    );
  };

  doSort = eventKey => {
    const { listName, changeSort } = this.props;

    const sort = eventKey.split(',');
    changeSort(listName, sort[0], sort[1]);
  };

  getSortOptions = () => {
    const {
      intl: { messages },
      sortOptions,
    } = this.props;

    return sortOptions.map(option => {
      const sort = option.split(',');

      return (
        <MenuItem eventKey={option} key={option}>
          {messages.sort[sort[0]]}
        </MenuItem>
      );
    });
  };

  componentDidUpdate = prevProps => {
    const { listName, url, urlData, list, resPerPage, fetch } = this.props;

    if (
      url !== prevProps.url ||
      !_isEqual(urlData, prevProps.urlData) ||
      list.sort !== prevProps.list.sort ||
      list.dir !== prevProps.list.dir ||
      resPerPage !== prevProps.resPerPage
    ) {
      fetch(
        this.currentRequestSource,
        this.setCurrentRequestSource,
        listName,
        url,
        this.getUrlData(),
        1,
        resPerPage || list.resPerPage,
        this.getSearchTerm(),
        list.sort,
        list.dir
      );
    }

    if (list.activePage !== prevProps.list.activePage || list.resPerPage !== prevProps.list.resPerPage) {
      fetch(
        this.currentRequestSource,
        this.setCurrentRequestSource,
        listName,
        url,
        this.getUrlData(),
        list.activePage,
        resPerPage || list.resPerPage,
        this.getSearchTerm(),
        list.sort,
        list.dir
      );
    }
  };

  catchAdvancedConditions = conditions => {
    this.setState({ conditions });
  };

  setCurrentRequestSource = currentRequestSource => {
    this.currentRequestSource = currentRequestSource;
  };

  render() {
    const {
      noRender,
      intl: { messages },
      list,
      breadcrumb,
      iconClass,
      title,
      sortOptions,
      showSearch,
      showPagination,
      showNew,
      newText,
      newAction,
      customActions,
      displayTypes,
      activeDisplayType,
      changeDisplayType,
      children,
      noResults,
      filters,
      showSearchNumber,
      showSearchLabel
    } = this.props;

    const headerVisible =
      breadcrumb || iconClass || title || showSearch || showSearchNumber || showNew || (customActions && customActions.length);

    return noRender ? null : (
      <>
        <div className={'padding-25 sm-padding-10 bg-white ' + (!headerVisible ? 'hidden ' : '')} key="head">
          {breadcrumb}

          <Clearfix>
            {(iconClass || title) && (
              <h4 className="pull-left bold">
                {!!iconClass && <i className={iconClass} />} {title}
              </h4>
            )}

            {(showNew || !!customActions) && (
              <div className="pull-right sm-p-t-10">
                {showNew && (
                  <Button onClick={newAction} bsStyle="primary" className="btn-rounded" title={newText}>
                    <i className="fal fa-plus m-r-10 sm-m-r-0" /> <span className="hidden-xs">{newText}</span>
                  </Button>
                )}
                {customActions}
              </div>
            )}
          </Clearfix>

          {(!!filters || showSearch) && (
            <Clearfix>
              <div className={'list-filters ' + (list.pending ? 'disabled ' : '')}>
                {showSearchNumber && (
                  <ListFilterSearchTermNumberAndSpecific onSearch={this.handleSearch} messages={messages} />
                )}
                {showSearch && (
                  <>
                    <p>
                      <span>{showSearchLabel}</span>
                    </p>
                    <ListFilterSearchTerm onSearch={this.handleSearch} />
                  </>
                )}
                {filters}
              </div>
            </Clearfix>
          )}
        </div>
        <div className="padding-25 sm-padding-10" key="body">
          <Row className="no-margin no-padding">
            <Col xs={12} className="text-right p-b-10">
              {displayTypes.length > 1 && (
                <div className="inline">
                  <ButtonGroup>
                    {displayTypes.map(displayType => {
                      let iconClass = 'fa fa-' + displayType;
                      if (displayType === 'tree') {
                        iconClass = 'fal fa-folder-tree';
                      }

                      return (
                        <Button
                          key={displayType}
                          onClick={e => changeDisplayType(e, displayType)}
                          active={displayType === activeDisplayType}
                        >
                          <i className={iconClass} />
                        </Button>
                      );
                    })}
                  </ButtonGroup>
                </div>
              )}

              {!!sortOptions.length && (
                <div className="inline m-l-10">
                  <DropdownButton
                    noCaret
                    title={
                      <span>
                        {messages.do_sort}: {messages.sort[list.sort]} <i className={'m-l-10 fa fa-sort-' + list.dir} />
                      </span>
                    }
                    onSelect={this.doSort}
                    id="list-sort-dropdown"
                  >
                    {this.getSortOptions()}
                  </DropdownButton>
                </div>
              )}

              {showPagination && activeDisplayType === 'list' && (
                <div className="inline m-l-10">
                  <Pagination
                    count={list.data.count}
                    resPerPage={list.resPerPage}
                    activePage={list.activePage}
                    handleSelect={this.handlePagination}
                  />
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={12} className="no-padding">
              {children} {/* render the result table */}
              {!list.pending &&
                list.data.count === 0 &&
                (noResults || (
                  <>
                    <div className="p-t-60 p-b-60 text-center bg-master-light b-rad-lg">
                      <h3>
                        <i className="fal fa-times-circle text-warning" /> {messages.no_results_found}
                      </h3>
                      <div className="m-t-15">
                        {!list.searchTerm && showNew && (
                          <>
                            {messages.create_new_entry_now}{' '}
                            <a href="#new" onClick={this.newAction}>
                              {newText} <i className="fal fa-angle-right" />
                            </a>
                          </>
                        )}
                        {!!list.searchTerm && messages.no_results_for_your_search}
                      </div>
                    </div>
                  </>
                ))}
              {showPagination && activeDisplayType === 'list' && !list.pending && !!list.data.count && (
                <div className="text-right p-t-15 p-b-15">
                  <Pagination
                    count={list.data.count}
                    resPerPage={list.resPerPage}
                    activePage={list.activePage}
                    handleSelect={this.handlePagination}
                  />
                </div>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
List.defaultProps = {
  listName: '',
  url: '',
  urlData: {},
  noRender: false,
  breadcrumb: null,
  sortOptions: [],
  showSearch: true,
  showSearchLabel: '',
  showPagination: true,
  metaIdentifier: '',
  displayTypes: ['list'],
  activeDisplayType: 'list',
  changeDisplayType: () => {},
  filters: null,
  showNew: false,
  newText: '',
  newAction: () => {},
  customActions: [],
  resPerPage: null,
  showSearchNumber: false,
};

const mapStateToProps = (state, ownProps) => {
  return ownProps.list
    ? {}
    : {
        list: state.list[ownProps.listName],
      };
};
const mapDispatchToProps = dispatch => {
  return {
    fetch: (
      currentRequestSource,
      setCurrentRequestSource,
      listName,
      url,
      data,
      page = 1,
      resPerPage = 20,
      search = '',
      sort = '',
      dir = '',
    ) => {
      if (currentRequestSource) {
        currentRequestSource.cancel();
      }

      setCurrentRequestSource(
        listDispatchHelper(
          listName,
          url,
          { offset: (page - 1) * resPerPage, limit: resPerPage, search, sort, dir, page, ...data },
          dispatch
        )
      );
    },
    changeSort: (listName, sort, dir) => {
      dispatch(changeListSort(listName, sort, dir));
    },
    changeActivePage: (listName, activePage, resPerPage) => {
      dispatch(changeActivePage(listName, activePage, resPerPage));
    },
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(List)
);
