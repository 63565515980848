// eslint-disable-next-line import/no-extraneous-dependencies
import '@babel/polyfill';
import 'window-location-origin';
// eslint-disable-next-line import/order
import configureStore from './configureStore';
// eslint-disable-next-line import/order
import './initDetectRTC';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import { createBrowserHistory } from 'js/history';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { StripeProvider } from 'react-stripe-elements';

import { IntlProvider } from 'react-intl';

import 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/pl';
import 'moment/locale/de';
import 'moment/locale/tr';

import { getStripeConfig } from 'helpers/payment';

import Loader from 'components/layout/Loader';
import OrderProcessingContract from 'pages/OrderProcessingContract';
import MarketplaceTerms from 'pages/MarketplaceTerms';
import Layout from './pages/Layout';
import TermsOfService from './pages/TermsOfService';
import Home from './pages/Home';
import RentTermsOfService from './pages/RentTermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookieUse from './pages/CookieUse';
import CancellationPolicy from './pages/CancellationPolicy';
import Imprint from './pages/Imprint';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Contact from './pages/Contact';
import Signedup from './pages/Signedup';
import ForgotPassword from './pages/ForgotPassword';
import NewPasswordAfterReset from './pages/NewPasswordAfterReset';
import RenewPassword from './pages/RenewPassword';
import Pages from './pages/Pages';
import CompanySignupComplete from './pages/CompanySignupComplete';
import Locations from './pages/Locations';
import LocationDetails from './pages/LocationDetails';
import EditorCC from './pages/EditorCC';

import GoPro from './pages/GoPro';
import WidgetFilledQuestionnaire from './pages/Widget/FilledQuestionnaire';
import WidgetCheckoutInit from './pages/Widget/CheckoutInit';
import WidgetCheckout from './pages/Widget/Checkout';
import WidgetCheckoutError from './pages/Widget/CheckoutError';
import WidgetOrderComplete from './pages/Widget/OrderComplete';
import WidgetShopSummary from './pages/Widget/ShopSummary';
import WidgetItemDetails from './pages/Widget/ItemDetails';
import WidgetCart from './components/layout/cart/CartWizard';
import MediaLibrary from 'pages/Account/MediaLibrary';

import WidgetFavorites from './pages/Widget/Favorites';

import ApiDoc from './pages/ApiDoc';
import ApiDocDetails from './pages/ApiDocDetails';

import './clientStyles';
import globals from './globals';
import { provideStoreToNotification } from './helpers/notifications';
import BeforeUnload from './components/BeforeUnload';

const { debug, whiteLabelSettings } = globals;

const ClientAccountRoutes = React.lazy(() => import('./ClientAccountRoutes'));

const history = createBrowserHistory(debug);

const store = configureStore(history);
const routeLangPrefix = '/:lang(de|en|pl|tr|nl|it|fr|es)?';

provideStoreToNotification(store);

function ClientRaw(props) {
  const { locale, messages } = props;
  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <DndProvider backend={HTML5Backend}>
        <Switch>
          <Route path={routeLangPrefix + '/termsMobile'} component={TermsOfService} />
          <Route path={routeLangPrefix + '/privacyMobile'} component={PrivacyPolicy} />
          <Route path="/">
            <Layout>
              <Switch>
                <Route exact path={routeLangPrefix} component={Home} />
                <Route path={routeLangPrefix + '/account'}>
                  <Suspense
                    fallback={
                      <div class="content">
                        <Loader />
                      </div>
                    }
                  >
                    <ClientAccountRoutes />
                  </Suspense>
                </Route>
                <Route path={routeLangPrefix + '/editor/:editionId'} component={EditorCC} />
                <Route path={routeLangPrefix + '/terms'} component={TermsOfService} />
                <Route path={routeLangPrefix + '/rentTerms'} component={RentTermsOfService} />
                <Route path={routeLangPrefix + '/privacy'} component={PrivacyPolicy} />
                <Route path={routeLangPrefix + '/cookie'} component={CookieUse} />
                <Route path={routeLangPrefix + '/cancellationPolicy'} component={CancellationPolicy} />
                <Route path={routeLangPrefix + '/imprint'} component={Imprint} />
                <Route exact path={routeLangPrefix + '/login'} component={Login} />
                <Route path={routeLangPrefix + '/login/google'} component={Login} />
                <Route path={routeLangPrefix + '/login/facebook'} component={Login} />
                <Route path={routeLangPrefix + '/signup'} component={Signup} />
                <Route path={routeLangPrefix + '/companySignupComplete'} component={CompanySignupComplete} />
                <Route path={routeLangPrefix + '/contact'} component={Contact} />
                <Route path={routeLangPrefix + '/(locations|standorte)'} component={Locations} />
                <Route path={routeLangPrefix + '/termsofuse'} component={MarketplaceTerms} />
                <Route path={routeLangPrefix + '/orderprocessingcontract'} component={OrderProcessingContract} />
                <Route
                  path={routeLangPrefix + '/(locations|standorte)/:locationId-:locationName'}
                  component={LocationDetails}
                />
                <Route path={routeLangPrefix + '/signedup'} component={Signedup} />
                <Route exact path={routeLangPrefix + '/forgotpassword'} component={ForgotPassword} />
                <Route path={routeLangPrefix + '/forgotpassword/:token'} component={NewPasswordAfterReset} />
                <Route exact path={routeLangPrefix + '/renewpassword'} component={RenewPassword} />
                <Route path={routeLangPrefix + '/renewpassword/:token'} component={RenewPassword} />
                <Route exact path={routeLangPrefix + '/docs'} component={ApiDoc} /> {/* api docs */}
                <Route path={routeLangPrefix + '/docs/:groupId'} component={ApiDocDetails} />
                <Route path={routeLangPrefix + '/pages/:slug'} component={Pages} />
                <Route path={routeLangPrefix + '/gopro'} component={GoPro} />
                {/* widget routes */}
                <Route
                  path={routeLangPrefix + '/questionnaires/:filledQuestionnaireToken'}
                  component={WidgetFilledQuestionnaire}
                />
                <Route exact path={routeLangPrefix + '/checkout/init'} component={WidgetCheckoutInit} />
                <Route exact path={routeLangPrefix + '/checkout/error'} component={WidgetCheckoutError} />
                <Route exact path={routeLangPrefix + '/checkout'} component={WidgetCheckout} />
                <Route path={routeLangPrefix + '/checkout/overview'} component={WidgetCheckout} />
                <Route path={routeLangPrefix + '/checkout/personal'} component={WidgetCheckout} />
                <Route path={routeLangPrefix + '/checkout/services'} component={WidgetCheckout} />
                <Route path={routeLangPrefix + '/checkout/shipping'} component={WidgetCheckout} />
                <Route path={routeLangPrefix + '/checkout/payment'} component={WidgetCheckout} />
                <Route path={routeLangPrefix + '/orderComplete'} component={WidgetOrderComplete} />
                <Route exact path={routeLangPrefix + '/shop'} component={WidgetShopSummary} />
                <Route
                  exact
                  path={routeLangPrefix + '/shop/category-:categoryName-:category'}
                  component={WidgetShopSummary}
                />
                <Route
                  exact
                  path={routeLangPrefix + '/shop/kategorie-:categoryName-:category'}
                  component={WidgetShopSummary}
                />
                <Route path={routeLangPrefix + '/shop/:slug'} component={WidgetItemDetails} />
                <Route path={routeLangPrefix + '/(favorites|favoriten)'} component={WidgetFavorites} />
                <Route path={routeLangPrefix + '/(cart|warenkorb)'} component={WidgetCart} />
                <Route path={routeLangPrefix + '/account/media'} component={MediaLibrary} />
              </Switch>
            </Layout>
          </Route>
        </Switch>
      </DndProvider>
    </IntlProvider>
  );
}

const mapStateToProps = ({
  intl: {
    locale,
    messages: { messages },
  },
}) => ({ locale, messages });

const Client = connect(mapStateToProps)(ClientRaw);

const { isActive: isStripeActive, key: stripeKey } = getStripeConfig(
  typeof whiteLabelSettings !== 'undefined' && whiteLabelSettings
);

ReactDOM.render(
  <Provider store={store}>
    <BeforeUnload>
      {isStripeActive ? (
        <StripeProvider apiKey={stripeKey}>
          <ConnectedRouter history={history}>
            <Client />
          </ConnectedRouter>
        </StripeProvider>
      ) : (
        <ConnectedRouter history={history}>
          <Client />
        </ConnectedRouter>
      )}
    </BeforeUnload>
  </Provider>,
  document.getElementById('app')
);
