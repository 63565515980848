import React from 'react';
import Slider from 'react-slick';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';
import { Col, Clearfix } from 'react-bootstrap';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

import ItemGridViewWireframe from 'components/layout/wireframes/SummaryItemGridView';

import { elementSource, elementTarget } from './elementDragUtils';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
class CmsElementCommerceProducts extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  render() {
    const { connectDropTarget, connectDragSource, connectDragPreview, layout } = this.props;
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const mainColorClass = 'text-' + this.getParamValue('mainColor', 'master') + ' ';
    const layoutVersion = this.getParamValue('layoutVersion', 'slider');

    const settings = {
      dots: layout.breakpointIndex > 1, // display the dots only on larger devices
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const mapping = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={
          'ms-ecommerce-products-content ' +
          mainColorClass +
          shownClass +
          this.getResponsiveClasses() +
          this.getCssClasses()
        }
        style={{ ...this.getBackgroundStyle() }}
        onClick={this.showSettings}
      >
        {this.getElementStyle()}

        {layoutVersion === 'slider' && (
          <Slider {...settings}>
            {mapping.map(i => {
              return (
                <div class="padding-15" key={i}>
                  <ItemGridViewWireframe />
                </div>
              );
            })}
          </Slider>
        )}

        {layoutVersion === 'list' && (
          <Clearfix>
            {mapping.map(i => {
              return (
                <Col lg={4} md={6} xs={12} key={i}>
                  <ItemGridViewWireframe />
                </Col>
              );
            })}
          </Clearfix>
        )}

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}

export default CmsElementCommerceProducts;
