import { COUNT_PENDING, COUNT_FULFILLED, COUNT_REJECTED } from 'actions/CountActions';

const standardState = {
  pending: true,
  hasError: false,
  errors: [],
  data: null,
};

const initialState = {
  summary: { ...standardState },
  items: { ...standardState },
  users: { ...standardState },
  clientInvoices: { ...standardState },
  clientQuotes: { ...standardState },
  clientOrders: { ...standardState },
  clientUsers: { ...standardState },
  clientTemplates: { ...standardState },
  leads: { ...standardState },
};

export default function count(state = initialState, action) {
  switch (action.type) {
    case COUNT_PENDING: {
      const newState = { ...state };
      const oldCountState = newState[action.payload.countName];

      newState[action.payload.countName] = {
        ...oldCountState,
        pending: true,
        hasError: false,
        errors: [],
        data: null,
      };
      return newState;
    }

    case COUNT_FULFILLED: {
      const newState = { ...state };
      const oldCountState = newState[action.payload.countName];

      newState[action.payload.countName] = {
        ...oldCountState,
        pending: false,
        hasError: false,
        errors: [],
        data: action.payload.response.data,
      };
      return newState;
    }

    case COUNT_REJECTED: {
      const newState = { ...state };
      const oldCountState = newState[action.payload.countName];

      newState[action.payload.countName] = {
        ...oldCountState,
        pending: false,
        hasError: true,
        errors: action.payload.response.data,
        data: null,
      };
      return newState;
    }
  }

  return state;
}
