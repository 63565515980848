import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { transform } from 'js/css-transform';
import { Alert } from 'react-bootstrap';
import { keys as _keys, forEach as _forEach, includes as _includes } from 'lodash';
import { parseDOM } from 'htmlparser2';
import he from 'he';
import { animateScroll as scroll, Link as LinkScroll } from 'react-scroll';

import { isExternal } from 'helpers/http';

function safeLink(href) {
  const matcher = /^\s*javascript:.*$/gi.exec(href);
  if (matcher) {
    return undefined;
  }
  return href;
}

class RichEditorContent extends React.Component {
  constructor(props) {
    super(props);

    this.allowedTags = [
      'br',
      'img',
      'hr',
      'p',
      'a',
      'div',
      'span',
      'em',
      'strong',
      'b',
      'u',
      'i',
      'font',
      'table',
      'thead',
      'tbody',
      'tr',
      'td',
      'th',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'ol',
      'ul',
      'li',
      'blockquote',
      'button',
      'meta',
      'footer',
      'sub',
      'sup',
      'del',
      'ins',
      'dl',
      'dt',
      'dd',
    ];

    this.allowedAttributes = ['class', 'style', 'colspan', 'href', 'target', 'src', 'alt', 'id'];
  }

  getText = text => {
    const { textSubstitute } = this.props;

    if (textSubstitute) {
      const regex = _keys(textSubstitute).join('|');

      return he.decode(text).replace(new RegExp(regex, 'g'), match => textSubstitute[match]);
    }

    return he.decode(text);
  };

  getLinkData = ({ name, attributes }) => {
    const { history, googleAnalyticsTrackingId } = this.props;

    const isLinkExternal = isExternal(attributes.href || '');
    // Prevents double escaping - the urls are HTML escaped on save in Wysiwyg edior and while rendering by React.
    // This generates double escaping and broken links in the end.
    const decodedHref = he.decode(attributes.href || '');
    const isHashLink = attributes.href && attributes.href[0] === '#';

    let onClick = null;
    if (isHashLink) {
      switch (attributes.href) {
        case '#scrollToTop':
          onClick = e => {
            e.preventDefault();
            scroll.scrollToTop();
          };
          break;

        case '#scrollToBottom':
          onClick = e => {
            e.preventDefault();
            scroll.scrollToBottom();
          };
          break;

        case '#goBack':
          onClick = e => {
            e.preventDefault();
            history.goBack();
          };
          break;

        case '#goForward':
          onClick = e => {
            e.preventDefault();
            history.goForward();
          };
          break;

        case '#gaOptOut':
          onClick = e => {
            e.preventDefault();
            window['ga-disable-' + googleAnalyticsTrackingId] = true;
          };
          break;

        default:
          return {
            name: LinkScroll,
            attributes: {
              ...attributes,
              to: decodedHref.substr(1),
              smooth: true,
              duration: 500,
              href: '',
            },
          };
      }
    }

    return {
      name: isLinkExternal || isHashLink ? name : Link,
      attributes: {
        ...attributes,
        ...(isLinkExternal || isHashLink
          ? {
              href: decodedHref,
              onClick,
            }
          : {
              to: decodedHref,
            }),
      },
    };
  };

  loop = elements => {
    const result = [];

    _forEach(elements, (value, index) => {
      if (value.type === 'text') {
        result.push(this.getText(value.data));
      } else if (value.type === 'tag') {
        let { name } = value;
        let attributes = {};
        _forEach(value.attribs, (attr, attrKey) => {
          if (_includes(this.allowedAttributes, attrKey)) {
            switch (attrKey) {
              case 'class': {
                attributes.className = attr;
                break;
              }

              case 'style': {
                if (attr) {
                  try {
                    attributes[attrKey] = transform(attr);
                  } catch (err) {}
                }
                break;
              }

              case 'href': {
                try {
                  attributes[attrKey] = safeLink(attr);
                } catch (err) {}
                break;
              }

              default: {
                attributes[attrKey] = attr;
                break;
              }
            }
          }
        });

        if (name === 'a') {
          ({ name, attributes } = this.getLinkData({ name, attributes }));
        } else if (!_includes(this.allowedTags, name)) {
          attributes['data-original-tag'] = name;
          name = 'div';
        }

        if (name === 'meta') {
          // do nothing
        } else if (name === 'img' || name === 'br' || name === 'hr') {
          result.push(React.createElement(name, { key: `elem_${index}`, ...attributes }));
        } else {
          result.push(React.createElement(name, { key: `elem_${index}`, ...attributes }, this.loop(value.children)));
        }
      }
    });

    return result;
  };

  repair = content => {
    const selfClosingTags = [
      'area',
      'base',
      'br',
      'col',
      'embed',
      'hr',
      'img',
      'input',
      'link',
      'meta',
      'param',
      'source',
      'track',
      'wbr',
    ];

    return selfClosingTags.reduce((acc, tagName) => {
      const brokenTagsRegExp = new RegExp(`<\\s*/?\\s*${tagName}\\s*>`, 'gi');

      if (!acc || !acc.replaceAll) {
        return acc;
      }

      return acc.replaceAll(brokenTagsRegExp, `<${tagName} />`);
    }, content);
  };

  render() {
    const { content: rawContent } = this.props;

    const content = this.repair(rawContent);
    const parsed = parseDOM(content, { decodeEntities: true });

    try {
      return this.loop(parsed);
    } catch (e) {
      // if we cannot parse the HTML, we can show an error
      return (
        <div className="rich-editor-content">
          <Alert bsStyle="danger">Incorrect HTML detected. Please check your code in the editor.</Alert>
        </div>
      );
    }
  }
}
RichEditorContent.defaultProps = {
  textSubstitute: null,
  content: null,
};

const mapStateToProps = state => {
  return {
    googleAnalyticsTrackingId: state.data.projectConfig.data.google_analytics_tracking_id,
  };
};
export default withRouter(connect(mapStateToProps)(RichEditorContent));
