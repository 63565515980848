import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Col, Form, Row} from 'react-bootstrap';
import {injectIntl} from 'react-intl';
import {findIndex as _findIndex} from 'lodash';
import {getGenderForSalutation, getSalutationOptions} from 'helpers/clients';

import CmsElementSignupFormLegal from 'components/cms/elements/CmsElementSignupFormLegal';

import Loader from 'components/layout/Loader';
import SimpleSubmitButton from 'components/layout/SimpleSubmitButton';
import Data from 'components/Data';

import FormInput from 'components/forms/FormInput';
import FormInputPassword from 'components/forms/FormInputPassword';
import FormAutocomplete from 'components/forms/FormAutocomplete';
import FormErrors from 'components/forms/FormErrors';

import EntityStateUpdater from 'component/EntityStateUpdaterV2';

import {injectValidator, passwordRepeat} from 'formValidation/entityValidators';
import Axios from "axios";
import {formStatusDispatchHelper} from "../../../actions/FormStatusActions";
import {addNotification} from "../../../actions/NotificationsActions";
import FriendlyCaptcha from "./FriendlyCaptcha";

class SignupFormIHK extends EntityStateUpdater {
  constructor(props) {
    super(props, {
      metaIdentifier: 'cmsSignupForm',
      dataProps: 'empty',
      defaultDataProps: { country: 'DE', gender: 'male', salutation: 'mr' },
    }); // pass the configuration to entity state updater
    this.state = {
      pending: true,
      wlClients: [{
        label: 'Wird geladen...',
        value: null,
      }],
      wlClientsSub: [{
        label: 'Wird geladen...',
        value: null,
      }]
    };
    this.entity.__addValidator(injectValidator(passwordRepeat, props.intl));
  }

  updateSalutation = (name, value) => {
    const gender = getGenderForSalutation(value);
    this.updateEntityMultiple(['salutation', 'gender'], [value, gender]);
  };

  getClients = () => {
    Axios.get('/api/whitelabels/6036474f08d24f063255f4a3/clients.json')
      .then(response => {
        this.setState({
          wlClients: response.data,
          pending: false
        })
      })
      .catch(error => {
        console.log(error);
      })
      .then( () => {
        // always executed
      });
  }
  componentDidMount = () => {
    this.getClients();
  }

  formSubmit = e => {
    const {appIntl} = this.props;
    e.preventDefault();
    e.stopPropagation(); // if we are editing a page, we have to make sure the parent form will not be submitted

    this.validateEntity(() => {
      this.props.onSubmit(appIntl.urlPrefix, this.state.entity.__getStoredData());
    });
  };

  updateLevelOne = (name, value) => {
    const { entity, entityLoaded, wlClients, wlClientsSub, pending } = this.state;
    const chosenLevelOne = _findIndex(wlClients, { 'value': value });
    if(chosenLevelOne >= 0) {
      this.setState({
        wlClientsSub: wlClients[chosenLevelOne].children.length > 0 ? wlClients[chosenLevelOne].children : [{
          label: 'Keine Kreisverbände gefunden',
          value: null,
        }]
      });
    }
  }

  updateLevelTwo = (name, value) => {
    this.updateEntity('parent', value);
  }

  render() {
    const {
      intl: { messages },
      formStatus,
      buttonTitle,
      isInCall,
    } = this.props;
    const { entity, entityLoaded, wlClients, wlClientsSub, pending } = this.state;
    return (
      <Row>
        <Data dataName="cmsSignupForm" /> {/* fetch meta data */}
        <Col xs={12}>
          {entityLoaded ? (
            <Form onSubmit={this.formSubmit}>
              <FormErrors formStatus={formStatus} />
              <div className="form-group-attached">

                <FormAutocomplete
                  label={messages.salutation}
                  name="salutation"
                  value={entity.getSalutation}
                  meta={entity.getSalutationMetaInformation}
                  validation={entity.getSalutationValidationResult}
                  onChange={this.updateSalutation}
                  options={getSalutationOptions(messages)}
                  disabled={formStatus.pending}
                />
                <FormInput
                  label={messages.first_name}
                  name="firstName"
                  value={entity.getFirstName}
                  meta={entity.getFirstNameMetaInformation}
                  validation={entity.getFirstNameValidationResult}
                  onChange={this.updateEntity}
                  disabled={formStatus.pending}
                />
                <FormInput
                  label={messages.last_name}
                  name="lastName"
                  value={entity.getLastName}
                  meta={entity.getLastNameMetaInformation}
                  validation={entity.getLastNameValidationResult}
                  onChange={this.updateEntity}
                  disabled={formStatus.pending}
                />
                <FormInput
                  label="Rechnungsempfänger"
                  infoText="Falls abweichend zur Person"
                  name="company"
                  value={entity.getCompany}
                  meta={entity.getCompanyMetaInformation}
                  validation={entity.getCompanyValidationResult}
                  onChange={this.updateEntity}
                  disabled={formStatus.pending}
                />

                <FormInput
                  type="tel"
                  label={messages.phone}
                  infoText="Bei Zustellproblemen oder Rückfragen zu Bestellungen"
                  name="phone"
                  value={entity.getPhone}
                  meta={entity.getPhoneMetaInformation}
                  validation={entity.getPhoneValidationResult}
                  onChange={this.updateEntity}
                  disabled={formStatus.pending}
                />
                <FormInput
                  type="email"
                  label={messages.email}
                  name="email"
                  value={entity.getEmail}
                  meta={entity.getEmailMetaInformation}
                  validation={entity.getEmailValidationResult}
                  onChange={this.updateEntity}
                  disabled={formStatus.pending}
                  autoComplete="username"
                />
              </div>

              <div className="form-group-attached m-t-10">
                <FormInput
                  label={messages.street}
                  name="street"
                  value={entity.getStreet}
                  meta={entity.getStreetMetaInformation}
                  validation={entity.getStreetValidationResult}
                  onChange={this.updateEntity}
                  disabled={formStatus.pending}
                />

                <Row class="clearfix no-margin">
                  <Col sm={6}>
                    <FormInput
                      label={messages.house_number}
                      name="houseNumber"
                      value={entity.getHouseNumber}
                      meta={entity.getHouseNumberMetaInformation}
                      validation={entity.getHouseNumberValidationResult}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                  </Col>
                  <Col sm={6}>
                    <FormInput
                      label={messages.apartment_number}
                      name="apartmentNumber"
                      value={entity.getApartmentNumber}
                      meta={entity.getApartmentNumberMetaInformation}
                      validation={entity.getApartmentNumberValidationResult}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                  </Col>
                  <Col sm={12}>
                    <FormInput
                      label={messages.address_line2}
                      name="addressLine2"
                      value={entity.getAddressLine2}
                      meta={entity.getAddressLine2MetaInformation}
                      validation={entity.getAddressLine2ValidationResult}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                  </Col>
                </Row>

                <Row class="clearfix no-margin">
                  <Col sm={6}>
                    <FormInput
                      label={messages.zip_code}
                      name="zipCode"
                      value={entity.getZipCode}
                      meta={entity.getZipCodeMetaInformation}
                      validation={entity.getZipCodeValidationResult}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                  </Col>
                  <Col sm={6}>
                    <FormInput
                      label={messages.city}
                      name="city"
                      value={entity.getCity}
                      meta={entity.getCityMetaInformation}
                      validation={entity.getCityValidationResult}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                  </Col>
                </Row>

                <FormAutocomplete
                  label={messages.country}
                  name="country"
                  value={entity.getCountry}
                  meta={entity.getCountryMetaInformation}
                  validation={entity.getCountryValidationResult}
                  onChange={this.updateEntity}
                  autocompleteType="country"
                  complete
                  disabled={formStatus.pending}
                />
              </div>

              <div className="form-group-attached m-t-10">
                <FormInputPassword
                  name="password"
                  label={messages.password}
                  value={entity.getPassword}
                  meta={entity.getPasswordMetaInformation}
                  validation={entity.getPasswordValidationResult}
                  onChange={this.updateEntity}
                  disabled={formStatus.pending}
                  autoComplete="new-password"
                />
                <FormInputPassword
                  name="repeatPassword"
                  label={messages.repeat_password}
                  value={entity.getRepeatPassword}
                  meta={entity.getRepeatPasswordMetaInformation}
                  validation={entity.getRepeatPasswordValidationResult}
                  onChange={this.updateEntity}
                  disabled={formStatus.pending}
                  autoComplete="new-password"
                />
              </div>
              <FriendlyCaptcha/>
              <div className="p-t-20 p-b-15 fs-11">
                <CmsElementSignupFormLegal {...this.props} />
              </div>

              <SimpleSubmitButton
                label={buttonTitle}
                iconClass="fad fa-chevron-double-right"
                entityChanged={this.getEntityChanged()}
                errorButton={this.hasValidationErrorButton()}
                pending={formStatus.pending}
                block
                defaultStyle="primary"
              />
            </Form>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    meta: state.entityMeta,
    formStatus: state.formStatus.cmsSignupForm,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (urlPrefix, args) => {
      args['frc-captcha-solution'] = document.getElementsByName('frc-captcha-solution')[0].value;
      formStatusDispatchHelper('cmsSignupForm', urlPrefix + 'login/ihkRegister', args, 'post', dispatch);
    },
    doNotify: (code, text) => {
      dispatch(addNotification(code, text));
    },
  };
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(SignupFormIHK)));
