import { find as _find } from 'lodash';

import { SHIPPING_PICKUP } from 'js/constants';

export const isCarCheckout = checkout => {
  if (checkout.items.length === 1 && checkout.items[0].type === 'car' && !checkout.dateFrom && !checkout.dateTo) {
    return true;
  }

  return false;
};

export const isCarRentCheckout = checkout => {
  if (checkout.items.length === 1 && checkout.items[0].type === 'car' && checkout.dateFrom && checkout.dateTo) {
    return true;
  }

  return false;
};

// helper function that checks if user may navigate to step B (start of the checkout process)
export const mayNavigateToB = (/* checkout */) => {
  return true;
};

// helper function that checks if user may navigate to step C (personal data)
export const mayNavigateToC = checkout => {
  if (!mayNavigateToB(checkout)) {
    return false;
  }

  return true;
};

// helper function that checks if user may navigate to step D (car services)
export const mayNavigateToD = checkout => {
  if (!mayNavigateToC(checkout)) {
    return false;
  }

  if (!checkout.userDataValid) {
    return false;
  }

  return true;
};

// helper function that checks if user may navigate to step E (shipping information)
export const mayNavigateToE = checkout => {
  if (!mayNavigateToD(checkout)) {
    return false;
  }

  if (checkout.isCarCheckout) {
    // if it's a car checkout, we need to check the services and if the needed data was validated
    for (let i = 0; i < checkout.services.length; i++) {
      const service = checkout.services[i];

      if (service.isActive) {
        if (!service.dataValid) {
          // check if validation was ok
          return false;
        }
      }
    }
  }

  return true;
};

// helper function that checks if user may navigate to step F (payment information)
export const mayNavigateToF = checkout => {
  if (!mayNavigateToE(checkout)) {
    return false;
  }

  // check the delivery information
  // if user wants to pick the order himself, he needs to select a location
  if (checkout.isItemCheckout && checkout.shipping && checkout.shipping.method === 'pickup') {
    return !!(checkout.locationData && checkout.locationData.id);
  }

  // if user wants a different delivery address, we need to be sure the address is valid

  return true;
};

// helper function that checks if user may navigate to step G
export const mayNavigateToG = checkout => {
  if (!mayNavigateToF(checkout)) {
    return false;
  }

  // check the payment method
  switch (checkout.payment) {
    case 'payPal':
      return !!checkout.paypalActions;

    default:
      return !!checkout.stripePaymentMethodId;
  }
};

export const getShippingType = checkout => {
  return (!!checkout.shipping && checkout.shipping.method) || SHIPPING_PICKUP;
};

export const getCarRegistrationServiceData = checkout => {
  return _find(checkout.services, ['name', 'carRegistration']);
};

export const isCarRegistrationServiceActive = checkout => {
  const carRegistrationService = getCarRegistrationServiceData(checkout);
  return carRegistrationService ? carRegistrationService.isActive : false;
};

export const isCarRegistrationCustomLicensePlateNumberActive = checkout => {
  const carRegistrationService = getCarRegistrationServiceData(checkout);

  if (
    carRegistrationService &&
    carRegistrationService.isActive &&
    carRegistrationService.requestedCustomLicensePlateNumber
  ) {
    return true;
  }

  return false;
};

export const getSubmitData = (data, checkout) => {
  return {
    ...checkout,
    ...data,
    payment: {
      ...checkout.paymentData,
      plugin: checkout.activePaymentPlugin,
      type: checkout.payment,
    },
  };
};
