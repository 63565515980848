import React from 'react';
import { Button, Clearfix } from 'react-bootstrap';
import { injectIntl } from 'react-intl';

class CmsNewElement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      elementsGroup: 'standard',
    };
  }

  onChange = (e, elementsGroup) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ elementsGroup });
  };

  addElement = params => {
    const { addElement } = this.props;
    addElement(params);
    document.body.click();
  };

  render() {
    const {
      intl: { messages },
    } = this.props;
    const { elementsGroup } = this.state;

    return (
      <div className="ms-element-selection">
        <Clearfix className="m-b-10 bg-master-lightest font-secondary all-caps fs-11">
          <a href="#standard" onClick={e => this.onChange(e, 'standard')} className="block pull-left padding-10">
            {messages.cms_standard}
          </a>
          <a href="#ecommerce" onClick={e => this.onChange(e, 'ecommerce')} className="block pull-left padding-10">
            E-commerce
          </a>
          <a href="#marketing" onClick={e => this.onChange(e, 'marketing')} className="block pull-left padding-10">
            Marketing
          </a>
        </Clearfix>

        <Clearfix>
          {elementsGroup === 'standard' ? (
            <ul>
              <li className="ms-element-text">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'text' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title="HTML"
                >
                  <span className="p-t-5 p-b-5">
                    <i className="far fa-fw fa-file-alt" />
                  </span>
                  <br /> HTML
                </Button>
              </li>
              <li className="ms-element-image">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'image' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.image}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fa fa-fw fa-image" />
                  </span>
                  <br /> {messages.image}
                </Button>
              </li>
              <li className="ms-element-blurb">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'blurb' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title="Blurb"
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fa fa-fw fa-exclamation" />
                  </span>
                  <br /> Blurb
                </Button>
              </li>
              <li className="ms-element-newsletter">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'newsletter' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title="Newsletter"
                >
                  <span className="p-t-5 p-b-5">
                    <i className="far fa-fw fa-envelope" />
                  </span>
                  <br /> Newsletter
                </Button>
              </li>
              <li className="ms-element-divider">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'divider' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.divider}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fa fa-fw fa-minus" />
                  </span>
                  <br /> {messages.divider}
                </Button>
              </li>
              <li className="ms-element-testimonial">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'testimonial' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.testimonial}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fa fa-fw fa-quote-right" />
                  </span>
                  <br /> {messages.testimonial}
                </Button>
              </li>
              <li className="ms-element-hero-unit">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'hero' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.hero_unit}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="far fa-fw fa-newspaper" />
                  </span>
                  <br /> {messages.hero_unit}
                </Button>
              </li>
              <li className="ms-element-carousel">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'carousel' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.image_carousel}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fa fa-fw fa-columns" />
                  </span>
                  <br /> {messages.image_carousel}
                </Button>
              </li>
              <li className="ms-element-call-to-action">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'call_to_action' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.call_to_action}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fa fa-fw fa-mouse-pointer" />
                  </span>
                  <br /> {messages.call_to_action}
                </Button>
              </li>
              <li className="ms-element-bar-counter">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'bar_counter' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.bar_counter}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fa fa-fw fa-sliders-h" />
                  </span>
                  <br /> {messages.bar_counter}
                </Button>
              </li>
              <li className="ms-element-image-gallery">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'gallery' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.image_gallery}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fa fa-fw fa-images" />
                  </span>
                  <br /> {messages.image_gallery}
                </Button>
              </li>
              <li className="ms-element-accordion">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'accordion' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.accordion}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fa fa-fw fa-sort" />
                  </span>
                  <br /> {messages.accordion}
                </Button>
              </li>
              <li className="ms-element-map">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'map' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.map}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="far fa-fw fa-map" />
                  </span>
                  <br /> {messages.map}
                </Button>
              </li>

              <li className="ms-element-company-locations">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'locations' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.locations}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fal fa-globe-europe" />
                  </span>
                  <br /> {messages.locations}
                </Button>
              </li>

              <li className="ms-element-video">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'video' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.video}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fa fa-fw fa-video" />
                  </span>
                  <br /> {messages.video}
                </Button>
              </li>

              <li className="ms-element-uploaded-video">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'uploaded_video' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.uploaded_video}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fas fa-fw fa-play" />
                  </span>
                  <br /> {messages.uploaded_video}
                </Button>
              </li>

              <li className="ms-element-image-compare">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'image_compare' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.image_compare}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fa fa-fw fa-arrows-alt-h" />
                  </span>
                  <br /> {messages.image_compare}
                </Button>
              </li>
              <li className="ms-element-login-form">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'login_form' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.login_form}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fa fa-fw fa-sign-in-alt" />
                  </span>
                  <br /> {messages.login_form}
                </Button>
              </li>
              <li className="ms-element-signup-form">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'signup_form' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.signup_form}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fa fa-fw fa-pencil-alt" />
                  </span>
                  <br /> {messages.signup_form}
                </Button>
              </li>
              <li className="ms-element-contact-form">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'contact' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.contact}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="far fa-fw fa-paper-plane" />
                  </span>
                  <br /> {messages.contact}
                </Button>
              </li>
              <li className="ms-element-timeline">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'timeline' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.timeline}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="far fa-fw fa-clock" />
                  </span>
                  <br /> {messages.timeline}
                </Button>
              </li>
              <li className="ms-element-animated-header">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'animated_header' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.animated_header}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fad fa-fw fa-h1" />
                  </span>
                  <br /> {messages.animated_header}
                </Button>
              </li>
            </ul>
          ) : null}

          {elementsGroup === 'ecommerce' ? (
            <ul>
              <li className="ms-element-commerce-products">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'commerce_products' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.products}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fa fa-fw fa-cubes" />
                  </span>
                  <br /> {messages.products}
                </Button>
              </li>
              <li className="ms-element-commerce-product">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'commerce_product' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.single_product}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fa fa-fw fa-cube" />
                  </span>
                  <br /> {messages.single_product}
                </Button>
              </li>
              <li className="ms-element-commerce-car-rental">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'commerce_car_rent' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.car_rental_form}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fa fa-fw fa-retweet" />
                  </span>
                  <br /> {messages.car_rental_form}
                </Button>
              </li>
              <li className="ms-element-commerce-car-search">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'commerce_car_search' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.car_search_form}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fas fa-fw fa-car" />
                  </span>
                  <br /> {messages.car_search_form}
                </Button>
              </li>
              <li className="ms-element-commerce-car-search-funnel">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'commerce_car_search_funnel' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.car_search_funnel}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="far fa-fw fa-filter" />
                  </span>
                  <br /> {messages.car_search_funnel}
                </Button>
              </li>
              <li className="ms-element-commerce-car-search-funnel">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'commerce_online_appointment_booking' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.online_appointment_booking}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fal fa-fw fa-calendar-check" />
                  </span>
                  <br /> {messages.online_appointment_booking}
                </Button>
              </li>
            </ul>
          ) : null}

          {elementsGroup === 'marketing' ? (
            <ul>
              <li className="ms-element-lead-form">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'lead_form' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.lead_form}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fa fa-fw fa-list-ul" />
                  </span>
                  <br /> {messages.lead_form}
                </Button>
              </li>

              <li className="ms-element-questionnaire-form">
                <Button
                  block
                  onClick={() => {
                    this.addElement({ type: 'questionnaire' });
                  }}
                  className="w-100 p-l-5 p-r-5 overflow-ellipsis"
                  title={messages.questionnaire}
                >
                  <span className="p-t-5 p-b-5">
                    <i className="fa fa-fw fal fa-envelope-open-text" />
                  </span>
                  <br /> {messages.questionnaire}
                </Button>
              </li>
            </ul>
          ) : null}
        </Clearfix>
      </div>
    );
  }
}

export default injectIntl(CmsNewElement);
