import React from 'react';
import PropTypes from 'prop-types';

Headline.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Headline.defaultProps = {
  description: undefined,
};

function Headline({ children, description }) {
  return (
    <div className="headline">
      <div className="main">{children}</div>
      <div className="description">{description}</div>
    </div>
  );
}

export default Headline;
