import { cloneDeep } from 'lodash';

/**
 * Deep clones supplied object of metadata for Cms:SignupForm
 * and overrides its "required" validation settings
 * with those from the White Label data in global scope.
 *
 * That data is configured via WhiteLabel admin UI / "Client registration" tab
 * and stored within "WhiteLabelSettings" collection in MongoDB,
 * "registrationRequiredFields" property.
 *
 * @param Object CmsSignupFormMetaDataSource
 * @return Object
 */
export default CmsSignupFormMetaDataSource => {
  const CmsSignupFormMetaData = cloneDeep(CmsSignupFormMetaDataSource);
  const { whiteLabelSettings } = global;
  const REQUIRED = 'required';

  if (whiteLabelSettings) {
    // override the "required" validation with the WhiteLabel Settings
    const { registration_required_fields: requiredFields } = whiteLabelSettings;
    if (requiredFields) {
      const metaFields = Object.values(CmsSignupFormMetaData);
      for (let i = 0; i < metaFields.length; i++) {
        const metaField = metaFields[i];
        const { name, validate } = metaField;
        if (validate) {
          const { rules, messages } = validate;
          const rulesArray = rules.length > 0 ? rules.split(',') : [];
          if (requiredFields.includes(name)) {
            // this field is required
            if (!rulesArray.includes(REQUIRED)) {
              rulesArray.push(REQUIRED);
              validate.rules = rulesArray.join(',');
              if (!messages[REQUIRED]) {
                messages[REQUIRED] = 'message_required';
              }
            }
          } else if (rulesArray.includes(REQUIRED)) {
            // field is not required
            const updatedRules = rulesArray.filter(rule => rule !== REQUIRED);
            validate.rules = updatedRules.join(',');
            if (messages[REQUIRED]) {
              delete messages[REQUIRED];
            }
          }
        }
      }
    }
  }

  return CmsSignupFormMetaData;
};
