import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getLogger, intlModule } from '@chedri/base';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ValidationContext from './ValidationContext';

const logger = getLogger('validation');

Validation.propTypes = {
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      verify: PropTypes.func,
    })
  ).isRequired,
};

export function reduceValidationResult(validationResult, property) {
  if (validationResult.severity != null) {
    return validationResult;
  }
  if (property && validationResult[property]) {
    return validationResult[property];
  }
  if (!Object.values(validationResult).filter(entry => !entry.valid).length) {
    return;
  }
  return Object.values(validationResult)
    .filter(entry => !entry.valid)
    .reduce((previous, entry) => (previous ? (previous.severity < entry.severity ? entry : previous) : undefined));
}

function Validation({ rules, children, validateOnValueChange }) {
  const messages = useSelector(intlModule.selectors.getMessages);

  const [isValidating, setIsValidating] = useState(false);
  const [validationResult, setValidationResult] = useState();

  const validate = useCallback(
    async data => {
      setIsValidating(true);
      try {
        if (Array.isArray(rules)) {
          let hasIssues = false;
          const newValidationResult = {};
          await Promise.all(
            rules.map(async rule => {
              try {
                const ruleResult = await rule.verify(data);
                Object.keys(ruleResult).forEach(key => {
                  if (!hasIssues && !ruleResult[key].valid) {
                    hasIssues = true;
                  }
                  newValidationResult[key] = ruleResult[key];
                  newValidationResult[key].rule = rule.name;
                  if (newValidationResult[key] && newValidationResult[key].messageKey) {
                    newValidationResult[key].helpText = (
                      <FormattedMessage
                        id={newValidationResult[key].messageKey}
                        defaultMessage={`Failed validating the rule ${rule.name}.`}
                        values={{ rule: rule.name, ...ruleResult[key] }}
                      />
                    );
                  }
                });
              } catch (err) {
                hasIssues = true;
                logger.error(`Failed executing rule ${rule.name}`, err);
                if (!newValidationResult.errors) {
                  newValidationResult.errors = {};
                }
                newValidationResult.errors[rule.name] = err;
              }
            })
          );
          setValidationResult(hasIssues ? newValidationResult : undefined);
          return !hasIssues;
        }
        logger.warn('Validation is requested, but no rules are defined.');
      } finally {
        setIsValidating(false);
      }
    },
    [messages, rules, setIsValidating, setValidationResult]
  );

  useEffect(() => {
    if (!validateOnValueChange) {
      return;
    }
    validate(validateOnValueChange);
  }, [validateOnValueChange]);

  return (
    <ValidationContext.Provider value={{ isValidating, validationResult, validate }}>
      {children}
    </ValidationContext.Provider>
  );
}

export default Validation;
