import { takeEvery, take, delay, select, call } from 'redux-saga/effects';
import { Howl } from 'howler';

import { getLogger, communicationVideoModule } from '@chedri/base';

const logger = getLogger('communicationVideo :: calling');

const callBusySound = new Howl({
  src: ['/sounds/callBusy.mp3'],
  loop: true,
});
const callCancelSound = new Howl({
  src: ['/sounds/callCancel.mp3'],
  loop: false,
});
const ringBackSound = new Howl({
  src: ['/sounds/callRingback.mp3'],
  loop: true,
});
const ringtoneSound = new Howl({
  src: ['/sounds/chatterRingtone.mp3'],
  loop: true,
});

function* rejectCallSaga({ payload: { reason } }) {
  try {
    logger.debug('rejectCallSaga', reason);
    if (reason !== 'user_busy') {
      return;
    }
    yield call(playBusySoundSaga);
  } catch (e) {
    logger.error(e);
  }
}

function* playBusySoundSaga() {
  logger.debug('Play busy sound');
  callBusySound.play();

  const {
    startCall,
    startCallByRemote,
    startCustomerCall,
    resetCall,
    resetRemoteCall,
    newCustomerCall,
  } = communicationVideoModule.actions;

  yield take([startCall, startCallByRemote, startCustomerCall, resetCall, resetRemoteCall, newCustomerCall]);

  logger.debug('Stop playing busy sound');
  callBusySound.stop();
}

function* outgoingCallSoundSaga() {
  try {
    if (ringBackSound.playing()) {
      return;
    }
    // wait to verify if the call will not be auto rejected
    yield delay(1000);
    const isCallActive = yield select(communicationVideoModule.selectors.hasPendingCalls);
    if (!isCallActive) {
      return;
    }
    logger.debug('Play outgoing call sound');
    ringBackSound.play();

    const { type } = yield take([
      ...communicationVideoModule.actions.callFinishActions,
      ...communicationVideoModule.actions.callFinishByRemoteActions,
      communicationVideoModule.actions.acceptCallByRemote,
    ]);
    logger.debug('Stop playing outgoing call sound');
    ringBackSound.stop();

    switch (type) {
      case communicationVideoModule.actions.endCallByRemote.toString():
      case communicationVideoModule.actions.cancelCallByRemote.toString():
        yield delay(500);
        logger.debug('Play cancel tone sound');
        callCancelSound.play();
        break;
      case communicationVideoModule.actions.rejectCallByRemote.toString():
        yield call(playBusySoundSaga);
    }
  } catch (e) {
    logger.error(e);
  }
}

function* incomingCallSoundSaga() {
  try {
    if (ringtoneSound.playing()) {
      return;
    }

    logger.debug('Play incoming call sound');
    ringtoneSound.play();

    yield take([
      ...communicationVideoModule.actions.callFinishActions,
      ...communicationVideoModule.actions.callFinishByRemoteActions,
      communicationVideoModule.actions.acceptCall,
    ]);
    logger.debug('Stop playing incoming call sound');
    ringtoneSound.stop();
  } catch (e) {
    logger.error(e);
  }
}

export default function* chatterSaga() {
  logger.debug('Watch calling actions');

  yield takeEvery(communicationVideoModule.actions.rejectCallByRemote, rejectCallSaga);
  yield takeEvery([communicationVideoModule.actions.startCall], outgoingCallSoundSaga);
  yield takeEvery(
    [communicationVideoModule.actions.startCallByRemote, communicationVideoModule.actions.newCustomerCall],
    incomingCallSoundSaga
  );
}
