import { find as _find } from 'lodash';

export default function itemAttributes(attributes) {
  const func = entity => {
    let isValid = true;
    const fields = [];

    for (let i = 0; i < attributes.length; i++) {
      if (attributes[i].is_required) {
        // the attribute is required, we need to check the entity
        const attribute = _find(entity.getAttributes(), ['id', attributes[i].id]);

        if (!attribute) {
          // attribute is not set at all, the item is not valid!
          isValid = false;
          fields.push('attribute_' + attributes[i].id);
        } else {
          // check if there are any values in the attribute
          // and that the any value name is set

          let valuePresent = false;
          for (let j = 0; j < attribute.getValues().length; j++) {
            for (let k = 0; k < attribute.getValues()[j].getValue().length; k++) {
              if (
                attribute
                  .getValues()
                  [j].getValue()
                  [k].getName()
              ) {
                valuePresent = true;
                break;
              }
            }

            if (valuePresent) {
              break;
            }
          }

          if (!valuePresent) {
            isValid = false;
            fields.push('attribute_' + attributes[i].id);
          }
        }
      }
    }

    return {
      valid: isValid,
      rule: 'itemAttributes',
      helpTextTranslationIndex: 'This value should not be blank',
      fields: fields.map(name => {
        return {
          name,
          fieldTree: [],
        };
      }),
    };
  };

  return func;
}
