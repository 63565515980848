import axios from 'axios';

export const GET_EDITION = 'GET_EDITION';
// export const E_F_UPLOAD = "E_F_UPLOAD";
export const SAVE_TO = 'SAVE_TO';
export const INIT_TEMPLATE = 'INIT_TEMPLATE';
export const SAVE_CONTENT = 'SAVE_CONTENT';
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const GET_PDF_EDITOR = 'GET_PDF_EDITOR';
// export const GET_RSS = "GET_RSS";
export const SET_VARIABLES_IN_EDITOR = 'SET_VARIABLES_IN_EDITOR';
export const SET_CHANGESR = 'SET_CHANGESR';
export const SET_DATABASE = 'SET_DATABASE';
export const GET_TIMELINE = 'GET_TIMELINE';
export const SET_NAV_ENTRIES = 'SET_NAV_ENTRIES';
export const GET_SUBMISSIONS = 'GET_SUBMISSIONS';

// ------------------- EditorCC.js --------------------------
export function getSubmissionsR(filters) {
  return {
    type: GET_SUBMISSIONS,
    payload: axios.get('/api/editions.json?getSubmissions=true&limit=100&' + filters).then(({ data }) => data),
  };
}
export function getEditionR(editionId) {
  return {
    type: GET_EDITION,
    payload: axios.get('/api/editions/' + editionId + '.json').then(({ data }) => data),
  };
}

// ------------------- Editor.js --------------------------

export function saveToR(currentEdition) {
  return {
    type: SAVE_TO,
    payload: axios
      .post('/api/editions.json', { json: JSON.stringify(currentEdition) })
      .then(({ data }) => data)
      .catch(console.error),
  };
}

export function initTemplateR(templateSettings, contentToSave) {
  return {
    type: INIT_TEMPLATE,
    payload: axios
      .put('/api/producttemplates/' + templateSettings.id + '/initialize.json', { json: JSON.stringify(contentToSave) })
      .then(({ data }) => data),
  };
}

export function save_ContentR(editionId, contentToSave) {
  return {
    type: SAVE_CONTENT,
    payload: axios.put('/api/editions/' + editionId + '.json', contentToSave).then(({ data }) => data),
  };
}

export function getCampaignsR(filters) {
  return {
    type: GET_CAMPAIGNS,
    payload: axios.get('/api/campaigns.json' + filters, {}).then(({ data }) => data),
  };
}

// ------------------- EditorToolbar.js --------------------------

export function getPDFR(api, editionId, messages) {
  const payloadData = [];
  payloadData.messages = messages;
  return {
    type: GET_PDF_EDITOR,
    payload: axios.post('/api/printjob/' + api + '/' + editionId + '.json', {}).then(({ data }) => {
      payloadData.data = data;
      return payloadData;
    }),
  };
}

export function setVariables(key) {
  return {
    type: SET_VARIABLES_IN_EDITOR,
    payload: key,
  };
}

export function setChangesR(key) {
  return {
    type: SET_CHANGESR,
    payload: key,
  };
}

export function uploadExcelFileR(data, editionId) {
  return {
    type: SET_DATABASE,
    payload: axios.post('/api/editions/' + editionId + '/placeholders.json', data).then(({ data }) => data),
  };
}

export function getTimelineR(editionId, start, limit) {
  return {
    type: GET_TIMELINE,
    payload: axios
      .get(`/api/timelines/` + editionId + `.json?start=${start}&limit=${limit}`, {})
      .then(({ data }) => data),
  };
}

export function setNavEntriesR(entries) {
  return {
    type: SET_NAV_ENTRIES,
    payload: entries,
  };
}

// -------- FileUpload --------------------

/*

export function uploadImageR(imageData, editionId) {
  console.log("editoractionsdata", data);
  return {
    type: E_F_UPLOAD,
    payload: axios.post('/api/editions/'+editionId+'/images.json', imageData,)  .then(({ data }) => {
      //data.files = filesToUpload;

      return data;

    }),
  };
}

*/

// ------------------- RSSFeedsElement.js --------------------------

/*
export function getRSSR(editionId) {
  return {
    type: GET_RSS,
    payload: Axios.get('https://www.spiegel.de/schlagzeilen/tops/index.rss', {}).then(({ data }) => data),
  };
}

 */
