import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import RichEditorContent from 'components/RichEditorContent';

import AbstractCmsElement from './AbstractCmsElement';

export default class CmsElementTestimonial extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  render() {
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const imageClass = shown ? 'image-shown' : 'image-not-shown-yet';
    const companyName = this.getParamValue('companyName', '-');

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={
          'ms-testimonial-content ' +
          shownClass +
          (this.getParamValue('imagePosition', 'top') === 'left' ? 'image-left' : '') +
          ' text-' +
          this.getParamValue('mainColor', 'master') +
          ' ' +
          this.getResponsiveClasses() +
          this.getCssClasses()
        }
        style={{
          ...this.getBackgroundStyle(),
        }}
      >
        {this.getElementStyle()}

        <div class="ms-testimonial-image m-b-30">
          {this.getParamValue('imageUrl', false) ? (
            <img src={this.getParamValue('imageUrl')} class={'img-circle img-responsive ' + imageClass} alt="" />
          ) : (
            <i
              class={imageClass + ' fa fa-fw ' + this.getParamValue('icon', 'fa-quote-right')}
              style={{ color: this.getParamValue('iconColor', 'inherit') }}
            />
          )}
        </div>
        <div class="ms-testimonial-description">
          <RichEditorContent content={'<p>' + this.getParamValue('content', '') + '</p>'} />
          <strong class="m-t-15 ms-testimonial-author">{this.getParamValue('author', '')}</strong>
          {this.getParamValue('authorPosition') ? ( // display meta only if author position is filled
            <p class="ms-testimonial-meta">
              {this.getParamValue('authorPosition') + ', '}
              <a href={this.getParamValue('companyLink', '#' + companyName)}>{companyName}</a>
            </p>
          ) : null}
        </div>

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementTestimonial.defaultProps = {
  index: '0',
};
