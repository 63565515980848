import moment from 'moment';
import 'moment-timezone';

moment.locale(globalLocale);

/**
 * Change the range hours from UTC to the hours in the given timezone
 * @param {Object<{start: number, end: number}>|Array[<{start: number, end: number}>]} validRange Range in UTC hours
 * @param {String} timezone
 * @returns {Object<{start: number, end: number}>|Array[<{start: number, end: number}>]}
 */
export function getRangeInTimezone(validRange, timezone) {
  const hourUtcOffset =
    moment()
      .tz(timezone)
      .utcOffset() / 60;

  if (Array.isArray(validRange)) {
    return validRange.map(range => ({ start: range.start + hourUtcOffset, end: range.end + hourUtcOffset }));
  }

  if (validRange.start && validRange.end) {
    return { start: validRange.start + hourUtcOffset, end: validRange.end + hourUtcOffset };
  }

  return validRange;
}

export function findFirstDateTimeInWeekRange(dateTime, validWeekRange) {
  const isoWeekDay = dateTime.isoWeekday();
  const validDayRange = validWeekRange['iso_day' + isoWeekDay];
  const validRanges = Array.isArray(validDayRange) ? validDayRange : [validDayRange];

  if (!hasWorkingDays(validWeekRange)) {
    return dateTime;
  }

  if (!validRanges.length) {
    return findFirstDateTimeInWeekRange(
      moment(dateTime)
        .add(1, 'days')
        .startOf('day'),
      validWeekRange
    );
  }
  if (isInHourRange(dateTime, validDayRange)) {
    return dateTime;
  }
  if (isTooSoon(dateTime, validDayRange)) {
    return setTimeByHourNr(dateTime, validRanges[0].start);
  }
  if (isTooLate(dateTime, validDayRange)) {
    return findFirstDateTimeInWeekRange(
      moment(dateTime)
        .add(1, 'days')
        .startOf('day'),
      validWeekRange
    );
  }
}

export function hasWorkingDays(validWeekRange) {
  return (
    validWeekRange &&
    validWeekRange.length &&
    validWeekRange.filter(dayRanges => (Array.isArray(dayRanges) ? dayRanges.length : dayRanges)).length
  );
}

/**
 * Finds the minimum dateTime in the given range
 * @param {Moment} dateTime
 * @param {Object<{start: number, end: number}>|Array[<{start: number, end: number}>]} validRange
 * @returns {Momemnt}
 */
export function getMinDateTimeInRange(dateTime, validRange) {
  const validRanges = Array.isArray(validRange) ? validRange : [validRange];

  return setTimeByHourNr(dateTime, validRanges[0].start);
}

/**
 * Finds the maximum dateTime in the given range
 * @param {Moment} dateTime
 * @param {Object<{start: number, end: number}>|Array[<{start: number, end: number}>]} validRange
 * @returns {Momemnt}
 */
export function getMaxDateTimeInRange(dateTime, validRange) {
  const validRanges = Array.isArray(validRange) ? validRange : [validRange];

  return setTimeByHourNr(dateTime, validRanges[validRanges.length - 1].end - 0.25);
}

/**
 * Checks if given date fits in given hour range
 * @param {Moment} dateTime
 * @param {Object<{start: number, end: number}>|Array[<{start: number, end: number}>]} validRange
 * @returns {Boolean}
 */
export function isInHourRange(dateTime, validRange) {
  const hour = getHour(dateTime);
  const minute = getMinute(dateTime);
  const validRanges = Array.isArray(validRange) ? validRange : [validRange];

  return validRanges.some(({ start, end }) => hour * 60 + minute >= start * 60 && hour * 60 + minute < end * 60);
}

/**
 * Checks if an hour of the given date is after last valid range
 * @param {Moment} dateTime
 * @param {Object<{start: number, end: number}>|Array[<{start: number, end: number}>]} validRange
 * @returns {Boolean}
 */
export function isTooLate(dateTime, validRange) {
  const hour = getHour(dateTime);
  const minute = getMinute(dateTime);
  const validRanges = Array.isArray(validRange) ? validRange : [validRange];
  const lastValidDayMinutes = validRanges[validRanges.length - 1].end * 60 - 15;

  return hour * 60 + minute > lastValidDayMinutes;
}

/**
 * Checks if an hour of the given date is before last valid range
 * @param {Moment} dateTime
 * @param {Object<{start: number, end: number}>|Array[<{start: number, end: number}>]} validRange
 * @returns {Boolean}
 */
export function isTooSoon(dateTime, validRange) {
  const hour = getHour(dateTime);
  const minute = getMinute(dateTime);
  const validRanges = Array.isArray(validRange) ? validRange : [validRange];
  const firstValidDayMinutes = validRanges[0].start * 60;

  return hour * 60 + minute < firstValidDayMinutes;
}

/**
 * Get hour from given date
 * @param {Moment} dataTime
 * @returns {Number}
 */
export function getHour(dataTime) {
  const hours = moment(dataTime).hours();

  if (!isAmPm()) {
    return hours;
  }

  const amPm = hours >= 12 ? 'PM' : 'AM';

  if (amPm === 'AM') {
    return hours;
  }
  return hours > 12 ? hours - 12 : hours;
}

/**
 * Get AM or PM depends on the given dateTime
 * @param {Moment} dataTime
 * @returns {Number}
 */
export function getAmPm(dataTime) {
  const hours = moment(dataTime).hours();

  return hours >= 12 ? 'PM' : 'AM';
}

/**
 * Get minute from given date
 * @param {Moment} dataTime
 * @returns {Number}
 */
export function getMinute(dateTime) {
  return moment(dateTime).minutes();
}

/**
 * Checks if the current user local data as AM/PM time format
 * @returns {Boolean}
 */
export function isAmPm() {
  const format = moment.localeData().longDateFormat('LT');

  return format.indexOf(' A') !== -1;
}

/**
 * Sets given hourNr in given date. It calculates minutes if hourNr is not integer
 * @param {Moment} dateTime
 * @param {Number} hour e.g. 8,5
 * @returns {Moment}
 */
function setTimeByHourNr(dateTime, hourNr) {
  const hour = Math.floor(hourNr);
  const minute = (hourNr - hour) * 60;

  return moment(dateTime)
    .clone()
    .set({ hour, minute });
}
