import React from 'react';
import { connect } from 'react-redux';
import { find as _find } from 'lodash';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import FormAutocomplete from '../../../components/forms/FormAutocomplete';

class DropdownElement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDropdown: false,
      text: 'Please choose a text',
    };
  }

  componentDidMount = () => {
    const {
      intl: { messages },
    } = this.props;
    this.setState({ text: messages.pls_choose_text });
    this.getText();
  };

  setText = (name, value) => {
    const { id, attributes, updateParam } = this.props;
    const param = value + '#aaa#';
    const overflow = false;
    const default_image = false;
    const errors = [{ overflow, default_image }];
    this.setState({ text: value, showDropdown: false });
    if (updateParam !== undefined) {
      // updatePAram in PreviewBlock not available
      updateParam(id, attributes['data-name'], param, errors);
    }
  };

  getText = () => {
    const { attributes, params } = this.props;
    let _text = [];
    const param = _find(params, ['name', attributes['data-name']]);
    if (param) {
      _text = param.value.split('#aaa#');
      this.setState({ text: _text?.[0] }, () => {});
    } else {
      const dropdownFields = attributes ? attributes['data-dropdown-options'] : undefined;
      if (dropdownFields !== undefined) {
        let options = [];
        options = dropdownFields?.split(',');
        this.setState({ text: options?.[0] }, () => {
          this.setText('setInitialCustomText', options?.[0]); // set initial text
        });
      } else {
        const { text } = this.state;
        this.setText('setInitialDefaultText', text); // set initial text
      }
    }
  };

  getDropdown = () => {
    this.setState({ showDropdown: true });
  };

  hideDropdown = () => {
    this.setState({ showDropdown: false });
  };

  render() {
    const { name: Tag, attributes } = this.props;
    const { showDropdown, text } = this.state;

    const dropdownFields = attributes ? attributes['data-dropdown-options'] : undefined;
    let options = [];
    if (dropdownFields !== undefined) {
      options = dropdownFields?.split(',');
    }

    return (
      <>
        <Tag {...attributes} data-tip="" data-for="top">
          <p className="text-default" onClick={this.getDropdown}>
            {text}
          </p>
          {showDropdown && (
            <FormAutocomplete
              onChange={(name, value) => this.setText(name, value)}
              value={text}
              label=""
              name="editorDD"
              options={options.map(option => {
                return { label: option, value: option };
              })}
              onBlur={() => this.hideDropdown()}
            />
          )}
        </Tag>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(DropdownElement)));
