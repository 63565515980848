import React from 'react';
import { components } from 'react-select';

export class CurrencyOption extends React.Component {
  render() {
    const { data } = this.props;
    return <components.Option {...this.props}>{data.label}</components.Option>;
  }
}

export class CurrencyValue extends React.Component {
  render() {
    const { data } = this.props;

    return <div className="Select-value">{data.label}</div>;
  }
}

export const currencyOptionFormatter = options => {
  const result = [];
  Object.keys(options.list || options).forEach(key => {
    result.push({ value: key, label: key + ' - ' + options[key] });
  });

  return result;
};
