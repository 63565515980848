import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Alert, Table } from 'react-bootstrap';
import { size as _size } from 'lodash';

import TableHeaderResponsive from './TableHeaderResponsive';
import TableBodyRowResponsive from './TableBodyRowResponsive';

class TableResponsive extends React.Component {
  render() {
    const {
      intl: { messages },
      hover,
      rows,
      headers,
      breakpointIndex,
      visibleElements,
    } = this.props;

    return (
      <Table class="invoiceDetails" hover={!!rows.length && hover} responsive={breakpointIndex !== 0}>
        <TableHeaderResponsive headers={headers} breakpointIndex={breakpointIndex} visibleElements={visibleElements} />
        <tbody>
          {rows.map((elements, i) => {
            return (
              <TableBodyRowResponsive
                elements={elements}
                headers={headers}
                breakpointIndex={breakpointIndex}
                visibleElements={visibleElements}
                key={i}
              />
            );
          })}

          {!!rows && !rows.length && (
            <tr>
              <td colSpan={_size(headers)}>
                <Alert bsStyle="warning">
                  <i className="fal fa-times-circle text-warning" /> {messages.no_results_found}
                </Alert>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}
TableResponsive.defaultProps = {
  hover: false,
  visibleElements: 'all',
  type: 'default',
};

const mapStateToProps = state => {
  return {
    breakpointIndex: state.layout.breakpointIndex,
  };
};

export default injectIntl(connect(mapStateToProps)(TableResponsive));
