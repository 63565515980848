import axios from 'axios';
import { push as historyPush } from 'js/history';
import { fetchCart } from './CartActions';

export const HANDLE_LOGIN = 'HANDLE_LOGIN';
export const HANDLE_LOGOUT = 'HANDLE_LOGOUT';
export const OAUTH_ERROR = 'OAUTH_ERROR';
export const LOGIN_REDIRECT = 'LOGIN_REDIRECT';

export const SET_LOGIN_FORWARD_URL = 'SET_LOGIN_FORWARD_URL';
export const SET_ANONYMOUS_USER_DATA = 'SET_ANONYMOUS_USER_DATA';

export function submitLogin({ username, password }, urlPrefix = '/') {
  return dispatch => {
    return dispatch({
      type: HANDLE_LOGIN,
      payload: axios
        .post(urlPrefix + 'login/check', {
          _username: username,
          _password: password,
        })
        .then(data => {
          dispatch(loginRedirect());

          return data;
        }),
    });
  };
}

export function loginRedirect() {
  return (dispatch, getState) => {
    const {
      intl,
      login: { forwardUrl },
    } = getState();
    let url = forwardUrl || intl.urlPrefix + 'account';
    if(window.location.hostname.includes('creacheck-wahlkampf')){
      url = forwardUrl || intl.urlPrefix + 'pages/cdu_btw_startseite';
    }
    setTimeout(() => historyPush(url), 0);

    return dispatch({
      type: LOGIN_REDIRECT,
      payload: { url },
    });
  };
}

export function submitLoginWithoutRedirect({ username, password }, urlPrefix = '/') {
  return {
    type: HANDLE_LOGIN,
    payload: axios.post(urlPrefix + 'login/check', {
      _username: username,
      _password: password,
    }),
  };
}

export function submitLogout(urlPrefix = '/') {
  return {
    type: HANDLE_LOGOUT,
    payload: axios.get(urlPrefix + 'logout?_locale=' + globalLocale),
  };
}

export function oauthError(service, error) {
  return {
    type: OAUTH_ERROR,
    payload: { service, error },
  };
}

export function setLoginForwardUrl(url) {
  return {
    type: SET_LOGIN_FORWARD_URL,
    payload: url,
  };
}

export function setAnonymousUserData(data) {
  return {
    type: SET_ANONYMOUS_USER_DATA,
    payload: data,
  };
}
