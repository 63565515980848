import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import axios from 'axios';
import Select from 'react-select';
import AsyncPaginate from 'react-select-async-paginate';
import {
  find as _find,
  isString as _isString,
  isNumber as _isNumber,
  isArray as _isArray,
  isEqual as _isEqual,
  uniqBy as _uniqBy,
} from 'lodash';

import { stringifyQs } from 'helpers/http';
import {
  autocompleteQueue,
  autocompleteFinished,
  autocompleteError,
  autocompleteSubmit,
} from 'actions/AutocompleteActions';

class Autocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.fetcherTimeout = null;

    this.state = {
      key: 1,
      inputValue: props.inputValue,
      defaultValue: props.value || null,
      value: !_isString(props.value) && !_isNumber(props.value) ? props.value : null,
    };
  }

  componentDidMount = () => {
    const { complete, options } = this.props;

    if (complete && !options) {
      // if there are no options, fetch the list from server
      this.props.fetchAutocompleteData(this.getUrl(), this.props.type, '');
    } else if (options) {
      // we have options, we can adjust the value
      const { defaultValue } = this.state;
      if (defaultValue) {
        const value = _find(this.props.options, {
          value: _isString(defaultValue) || _isNumber(defaultValue) ? defaultValue : defaultValue.value,
        });

        if (value) {
          this.setState({ value });
        } else if (!_isString(defaultValue) && !_isNumber(defaultValue) && defaultValue.value) {
          this.setState({ value: defaultValue });
        } else if (_isArray(defaultValue)) {
          this.setState({
            value: defaultValue.map(dvOption => {
              return _isString(dvOption) || _isNumber(dvOption) ? { value: dvOption, label: dvOption } : dvOption;
            }),
          });
        }
      }
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.state.inputValue !== nextState.inputValue) {
      return true;
    }

    if (
      !_isEqual(this.getOptionsToken(this.props.highlightedOptions), this.getOptionsToken(nextProps.highlightedOptions))
    ) {
      return true;
    }

    if (!_isEqual(this.props.value, nextProps.value)) {
      return true;
    }

    if (!_isEqual(this.state.value, nextState.value)) {
      return true;
    }

    if (this.props.disabled !== nextProps.disabled) {
      return true;
    }

    if (this.props.autocompleteData && this.props.autocompleteData.pending !== nextProps.autocompleteData.pending) {
      return true;
    }

    if (!_isEqual(this.props.urlFilters, nextProps.urlFilters)) {
      return true;
    }

    if (this.props.options && this.props.options.length !== nextProps.options.length) {
      return true;
    }

    return false;
  };

  componentDidUpdate = prevProps => {
    const { type, urlFilters, options, autocompleteData, fetchAutocompleteData } = this.props;
    const { defaultValue, inputValue, value: stateValue } = this.state;

    if (!_isEqual(urlFilters, prevProps.urlFilters)) {
      this.setState(prevState => ({ key: prevState.key + 1 }));
      fetchAutocompleteData(this.getUrl(), type, inputValue);
    }

    if (
      autocompleteData &&
      defaultValue &&
      !stateValue &&
      prevProps.autocompleteData.pending &&
      !autocompleteData.pending
    ) {
      // we have options, we can adjust the value
      const value = _find(autocompleteData.response, {
        value: _isString(defaultValue) || _isNumber(defaultValue) ? defaultValue : defaultValue.value,
      });

      if (value) {
        this.setState({ value });
      } else if (!_isString(defaultValue) && !_isNumber(defaultValue) && defaultValue.value) {
        this.setState({ value: defaultValue });
      } else if (_isArray(defaultValue)) {
        this.setState({
          value: defaultValue.map(dvOption => {
            return _isString(dvOption) || _isNumber(dvOption) ? { value: dvOption, label: dvOption } : dvOption;
          }),
        });
      }
    }

    if (options && defaultValue && !stateValue && options.length !== prevProps.options.length) {
      //
      const value = _find(options, {
        value: _isString(defaultValue) || _isNumber(defaultValue) ? defaultValue : defaultValue.value,
      });
      if (value) {
        this.setState({ value });
      } else if (!_isString(defaultValue) && !_isNumber(defaultValue) && defaultValue.value) {
        this.setState({ value: defaultValue });
      } else if (_isArray(defaultValue)) {
        this.setState({
          value: defaultValue.map(dvOption => {
            return _isString(dvOption) || _isNumber(dvOption) ? { value: dvOption, label: dvOption } : dvOption;
          }),
        });
      }
    }
  };

  getUrl = () => {
    const {
      options,
      autocompleteData: { url: autocompleteUrl },
      urlFilters,
    } = this.props;

    let url = null;
    if (!options) {
      url = autocompleteUrl;

      if (urlFilters) {
        if (url.indexOf('?') === -1) {
          url += '?';
        }
        url += stringifyQs(urlFilters) + '&';
      }
    }

    return url;
  };

  getOptionComponent = () => {
    const { options, autocompleteData } = this.props;

    if ((!options && autocompleteData) || autocompleteData !== undefined) {
      return autocompleteData.optionComponent;
    }
  };

  getValueComponent = () => {
    const { options, autocompleteData } = this.props;

    if ((!options && autocompleteData) || autocompleteData !== undefined) {
      return autocompleteData.valueComponent;
    }
  };

  getOptions = () => {
    const { complete, options, autocompleteData, responseFilter } = this.props;

    if (options) {
      return this.prependHighlights(options);
    }
    if (complete && autocompleteData.response) {
      const options = responseFilter ? autocompleteData.response.filter(responseFilter) : autocompleteData.response;

      return this.prependHighlights(options);
    }
    if (!complete) {
      return null;
    }

    return [];
  };

  formatOptionList = (data = { list: [] }) => {
    const { autocompleteData } = this.props;

    return autocompleteData && autocompleteData.formatter ? autocompleteData.formatter(data) : data.list;
  };

  normalizeOptions = options => {
    const highlightedArray = Array.isArray(options) ? options : options ? [options] : [];

    return this.formatOptionList({ list: highlightedArray });
  };

  getOptionsToken = options => {
    return this.normalizeOptions(options)
      .map(opt => opt.value)
      .join('|');
  };

  prependHighlights = options => {
    const { highlightedOptions } = this.props;

    return _uniqBy([...this.normalizeOptions(highlightedOptions), ...options], option => option.value);
  };

  getLoadOptions = async (autocomplete, loadedOptions, { page }) => {
    const { responseFilter } = this.props;

    const query = {
      autocomplete,
      offset: (page - 1) * 20,
      limit: 20,
    };
    const response = await axios.get(this.getUrl() + '&' + stringifyQs(query));

    let options = this.formatOptionList(response.data);

    options = responseFilter ? options.filter(responseFilter) : options;

    return {
      options: page === 1 ? this.prependHighlights(options) : options,
      hasMore: response.data.count > page * 20,
      additional: {
        page: page + 1,
      },
    };
  };

  inputChange = inputValue => {
    const { complete, options, type, fetchAutocompleteData } = this.props;
    const { inputValue: stateInputValue } = this.state;

    if (inputValue !== stateInputValue) {
      this.setState({ inputValue });

      if (!complete && !options) {
        clearTimeout(this.fetcherTimeout);
        this.fetcherTimeout = setTimeout(() => {
          fetchAutocompleteData(this.getUrl(), type, inputValue);
        }, 500);
      }
    }
  };

  change = data => {
    const { onChange } = this.props;
    this.setState({ value: data });
    onChange(data);
  };

  focus = () => {
    this.inputRef.current.focus();
  };

  render() {
    const {
      intl: { messages },
      autocompleteData,
      name,
      disabled,
      complete,
      isMulti,
      stayOpen,
      options,
      placeholder,
      inputRef,
      onFocus,
      onBlur,
      clearable,
      highlightedOptions,
    } = this.props;
    const { key, inputValue, defaultValue: stateDefaultValue, value: stateValue } = this.state;

    const SelectComponent = complete || options ? Select : AsyncPaginate;
    const refProp = complete || options ? { ref: inputRef } : { selectRef: inputRef };

    const components = {};
    const optionComponent = this.getOptionComponent();
    const valueComponent = this.getValueComponent();
    if (optionComponent) {
      components.Option = optionComponent;
    }
    if (valueComponent) {
      components.SingleValue = valueComponent;
    }

    const defaultValue =
      _isString(stateDefaultValue) || _isNumber(stateDefaultValue)
        ? { value: stateDefaultValue, label: stateDefaultValue }
        : stateDefaultValue;
    const value =
      _isString(stateValue) || _isNumber(stateValue) ? { value: stateValue, label: stateValue } : stateValue;

    return (
      <div class={isMulti ? 'Select--multi' : ''}>
        <SelectComponent
          {...refProp}
          key={key}
          name={name}
          isLoading={options ? false : autocompleteData?.pending}
          options={this.getOptions()}
          loadOptions={complete || options ? null : this.getLoadOptions}
          onInputChange={this.inputChange}
          onChange={this.change}
          defaultValue={defaultValue}
          value={value}
          inputValue={inputValue}
          placeholder={placeholder || messages.autocomplete.placeholder}
          noResultsText={messages.autocomplete.noResultsText}
          loadingPlaceholder={messages.autocomplete.loadingPlaceholder}
          components={components}
          isDisabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          clearable={false}
          isMulti={isMulti}
          closeOnSelect={!stayOpen}
          classNamePrefix="ms-select"
          isClearable={clearable}
          additional={complete || options ? null : { page: 1 }}
          cacheUniq={this.getOptionsToken(highlightedOptions)}
        />
      </div>
    );
  }
}
Autocomplete.defaultProps = {
  inputValue: '',
  pending: false,
  type: '',
  disabled: false,
  inputRef: 'input',
  onFocus: () => {},
  onBlur: () => {},
  clearable: false,
  highlightedOptions: [],
};

const mapStateToProps = (state, ownProps) => {
  return {
    autocompleteData: state.autocomplete[ownProps.type],
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchAutocompleteData: (url, type, input) => {
      // add meta to queue
      dispatch(autocompleteQueue(type));

      // finished callback will trigger an event
      const finishedCallback = response => {
        return dispatch(autocompleteFinished(type, response));
      };
      // error callback will trigger an event
      const errorCallback = error => {
        return dispatch(autocompleteError(type, error));
      };
      // dispatch fetching the meta
      dispatch(autocompleteSubmit(url, { autocomplete: input }, finishedCallback, errorCallback));
    },
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Autocomplete),
  { forwardRef: true }
);
