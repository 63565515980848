import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Grid, Row, Col } from 'react-bootstrap';

import { parseQs, stringifyQs } from 'helpers/http';
import { getBrand, getModel, getSearchTypeFromCategoryPath } from 'helpers/items';
import { getItemDetailsPath } from 'helpers/intl';

import Data from 'components/Data';

class NavHeader extends React.Component {
  goBack = e => {
    e.preventDefault();

    const { history } = this.props;
    history.goBack();
  };

  render() {
    const {
      messages,
      appIntl,
      data,
      location: { search: locationSearch },
      nextItem,
    } = this.props;

    let nextItemName = null;
    let nextItemUrl = '';
    if (!nextItem.pending && nextItem.data && nextItem.data.id) {
      const brandName = getBrand(nextItem.data, appIntl);
      const modelName = getModel(nextItem.data, appIntl);
      if (brandName) {
        nextItemName = brandName;
      }
      if (modelName) {
        nextItemName += ' ' + modelName;
      }

      if (!brandName && !modelName) {
        nextItemName = nextItem.data.name;
      }

      nextItemUrl =
        getItemDetailsPath(getSearchTypeFromCategoryPath(nextItem.data.category_path), nextItem.data.slug) +
        locationSearch;
    }

    const query = parseQs(locationSearch);
    if (data.category_path) {
      query.category = data.category_path;
    }

    return (
      <Grid>
        <Data dataName="nextItem" url={`/api/summary/items/${data.id}/next.json?${stringifyQs(query)}`} />

        <Row className="p-t-10 p-b-20 item-details-header-nav">
          <Col xs={12}>
            <div className="pull-left">
              <a href="#goBack" onClick={this.goBack} className="text-master fs-18">
                <i className="fas fa-long-arrow-left m-r-5" /> {messages.back}
              </a>
            </div>
            {!nextItem.pending && !!nextItem.data && !!nextItem.data.id && (
              <div className="pull-right">
                <Link to={nextItemUrl} className="text-master fs-18" title={nextItem.data.name}>
                  <FormattedMessage id="continue_to_product_name" values={{ productName: nextItemName }} />
                  <i className="fas fa-long-arrow-right m-l-5" />
                </Link>
              </div>
            )}
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    nextItem: state.data.nextItem,
  };
};

export default withRouter(connect(mapStateToProps)(NavHeader));
