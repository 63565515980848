import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Grid, Row, Col, Button, Clearfix } from 'react-bootstrap';
import Modal from 'components/Modal';

import { saveCookie } from 'actions/CookiesActions';
import { arePerformanceCookiesUsed, areTargetingCookiesUsed } from 'helpers/whiteLabel';

import FormSwitchery from 'components/forms/FormSwitchery';

import NecessaryCookiesList from 'components/layout/cookie/NecessaryCookiesList';
import PerformanceCookiesList from 'components/layout/cookie/PerformanceCookiesList';
import TargetingCookiesList from 'components/layout/cookie/TargetingCookiesList';

class CookieConsentInfos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMenu: 'yourPrivacy',
    };
  }

  updatePreferences = (cookieName, isChecked) => {
    this.props.saveCookie({ [cookieName]: isChecked ? '1' : '0' });
  };

  clickAll = () => {
    this.props.saveCookie({
      chedriCookiesBoxClosed: '1',
      chedriCookiesPerformance: '1',
      chedriCookiesTargeting: '1',
    });
    this.props.close();
  };

  closeAndMarkBoxClosed = () => {
    this.props.saveCookie({
      chedriCookiesBoxClosed: '1',
    });
    this.props.close();
  };

  render() {
    const {
      intl: { messages },
      appIntl,
      show,
      close,
      cookies,
      projectConfig,
    } = this.props;
    const { selectedMenu } = this.state;

    const isPerformanceVisible = arePerformanceCookiesUsed(projectConfig.data);
    const isTargetingVisible = areTargetingCookiesUsed(projectConfig.data);

    const cookieSettingsSaveText =
      cookies.performance || cookies.target ? messages.save_and_continue : messages.accept_only_necessary_cookies;

    return (
      <Modal show={show} onHide={close} bsSize="lg">
        <Modal.Header closeButton>
          <Modal.Title>{messages.privacy_preference_center}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid fluid className="no-padding">
            <Row>
              <Col xs={12} md={4}>
                <div className="bg-master-lighter p-t-25 p-b-25 b-rad-sm p-l-10 p-r-10 sm-m-b-20">
                  <div className="list-view clearfix">
                    <ul className="no-border">
                      <li className={selectedMenu === 'yourPrivacy' ? 'active' : ''}>
                        <a
                          className="p-t-5 p-b-5"
                          href="javascript:;"
                          onClick={() => this.setState({ selectedMenu: 'yourPrivacy' })}
                        >
                          {messages.your_privacy}
                        </a>
                      </li>

                      <li className={selectedMenu === 'necessary' ? 'active' : ''}>
                        <a
                          className="p-t-5 p-b-5"
                          href="javascript:;"
                          onClick={() => this.setState({ selectedMenu: 'necessary' })}
                        >
                          {messages.necessary_cookies}
                        </a>
                      </li>

                      {isPerformanceVisible && (
                        <li className={selectedMenu === 'performance' ? 'active' : ''}>
                          <a
                            className="p-t-5 p-b-5"
                            href="javascript:;"
                            onClick={() => this.setState({ selectedMenu: 'performance' })}
                          >
                            {messages.performance_cookies}
                          </a>
                        </li>
                      )}

                      {isTargetingVisible && (
                        <li className={selectedMenu === 'targeting' ? 'active' : ''}>
                          <a
                            className="p-t-5 p-b-5"
                            href="javascript:;"
                            onClick={() => this.setState({ selectedMenu: 'targeting' })}
                          >
                            {messages.targeting_cookies}
                          </a>
                        </li>
                      )}

                      <li>
                        <Link className="p-t-5 p-b-5" to={appIntl.urlPrefix + 'privacy'} onClick={this.props.close}>
                          {messages.learn_more}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={8}>
                {selectedMenu === 'yourPrivacy' && (
                  <div>
                    <h2>{messages.your_privacy}</h2>
                    <p>{messages.basic_information_on_cookies}</p>
                  </div>
                )}

                {selectedMenu === 'necessary' && (
                  <div>
                    <h2>{messages.necessary_cookies}</h2>
                    <div className="text-right text-success bold">{messages.always_active}</div>
                    <p>{messages.necessary_cookies_information}</p>
                    <div>
                      <b>{messages.cookies_used}:</b>
                    </div>

                    <NecessaryCookiesList />
                  </div>
                )}

                {selectedMenu === 'performance' && (
                  <div>
                    <h2>{messages.performance_cookies}</h2>
                    <Clearfix>
                      <div className="pull-right">
                        {cookies.pending ? (
                          <i className="fa fa-circle-notch fa-spin" />
                        ) : (
                          <FormSwitchery
                            name="chedriCookiesPerformance"
                            checked={cookies.performance}
                            value={true}
                            onChange={this.updatePreferences}
                          />
                        )}
                      </div>
                    </Clearfix>

                    <PerformanceCookiesList />
                  </div>
                )}

                {selectedMenu === 'targeting' && (
                  <div>
                    <h2>{messages.targeting_cookies}</h2>
                    <Clearfix>
                      <div className="pull-right">
                        {cookies.pending ? (
                          <i className="fa fa-circle-notch fa-spin" />
                        ) : (
                          <FormSwitchery
                            name="chedriCookiesTargeting"
                            checked={cookies.targeting}
                            value={true}
                            onChange={this.updatePreferences}
                          />
                        )}
                      </div>
                    </Clearfix>

                    <TargetingCookiesList />
                  </div>
                )}
              </Col>
            </Row>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.closeAndMarkBoxClosed} className="m-t-5" data-cy="acceptCookies">
            {cookieSettingsSaveText}
          </Button>
          <Button bsStyle="primary" onClick={this.clickAll} className="m-t-5">
            {messages.accept_all_cookies}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    cookies: state.cookies,
    projectConfig: state.data.projectConfig,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    saveCookie: cookies => {
      dispatch(saveCookie(cookies));
    },
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CookieConsentInfos)
);
