import React from 'react';

import { getSubmitData } from 'helpers/checkout';

class PaymentButton extends React.Component {
  onClick = e => {
    if (e) {
      e.stopPropagation();
    }

    const { checkout, validate, onSubmit, formStatus } = this.props;

    if (!formStatus.pending) {
      validate().then(data => {
        const submitData = getSubmitData(data, checkout);

        onSubmit(submitData);
      });
    }
  };

  render() {
    const { label, disabled, buttonClassName, icon, type } = this.props;

    return (
      // eslint-disable-next-line react/button-has-type
      <button className={buttonClassName} disabled={disabled} onClick={this.onClick} type={type}>
        <span>
          {disabled ? <i className="fa-fw fa fa-circle-notch fa-spin" /> : icon} {label}
        </span>
      </button>
    );
  }
}
PaymentButton.defaultProps = {
  className: '',
};

export default PaymentButton;
