import React, { useState, useEffect } from 'react';
import { Col, Button, Row } from 'react-bootstrap';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import EditorMediathek from './EditorMediathek';
import Tabs from '../../components/layout/Tabs';
import MediaLibraryCreacheck from '../../components/layout/mediaLibrary/MediaLibraryCreacheck';
import PixabayApi from "../../components/layout/mediaLibrary/PixabayApi"
import FormInput from '../../components/forms/FormInput';

const MediaTabs = props => {
  const [descr, setDescr] = useState('');

  function changeDescr(name, value) {
    setDescr(value);
  }

  const saveDescr = () => {
    const index = _.findIndex(props.usedMedia, media => media.thumb === props.attributess);
    props.saveDescription(descr, index, props.attributess);
    props.handleIEclose();
  };

  const getTabContentList = () => {
    const result = [];
    const index = _.findIndex(props.usedMedia, media => media.thumb === props.attributess);
    const {
      intl: { messages },
    } = props;
    result.push(
      <div id="contextModalNewMedia" className="col-md-2">
        {props.originalSrc !== '' && (
          <div id="contextModalReuse" class="modalReuse">
            <h5>{messages.editor.currently_used_image}</h5>
            <div id="preview_used_image">
              <img src={props.originalSrc} alt="Vorschau" />
            </div>
            <Button bsStyle="info" bsSize="large" onClick={e => props.loadOriginal(e, false)} className="crop">
              {messages.editor.new_cut}
            </Button>
            <div className="editDescription">
              <FormInput
                type="text"
                label={messages.editor.image_description}
                name="description"
                onChange={changeDescr}
                value={props.usedMedia[index].imgDescription || props.usedMedia[index].img_description}
              />
              <Button bsStyle="info" bsSize="large" onClick={saveDescr} className="upload">
                {messages.editor.take_on}
              </Button>
            </div>
          </div>
        )}
        <div id="contextModalNewImage" class="modalNewImage">
          <h5>{messages.editor.upload_new_image}</h5>
          <div className="new_upload">
            <i className="fad fa-cloud-upload-alt" style={{ fontSize: '15vw' }} />
          </div>
          <Button bsStyle="info" bsSize="large" onClick={e => props.handleFileUpload(e, true)} className="upload">
            {messages.editor.choose_file}
          </Button>
        </div>
      </div>
    );
    if (!props.rootUser) {
      result.push(
        <MediaLibraryCreacheck
          handleMedia={props.handleMedia}
          neededHeight={props.neededHeight}
          neededWidth={props.neededWidth}
        />
      );
    } else {
      result.push(
        <MediaLibraryCreacheck
          handleMedia={props.handleMedia}
          neededHeight={props.neededHeight}
          neededWidth={props.neededWidth}
        />
      );
      // result.push(<p>Mediathek deaktiviert für Creacheck im Moment</p>);
    }
    // result.push(<PixabayApi handleMedia={props.handleMedia} neededHeight={props.neededHeight} neededWidth={props.neededWidth}/>);
    result.push(
      <EditorMediathek
        usedMedia={props.usedMedia}
        loadMedia={props.loadMedia}
        neededWidth={props.neededWidth}
        neededHeight={props.neededHeight}
        rootUser={props.rootUser}
      />
    );

    return result;
  };

  const getTabHeaderList = () => {
    const {
      intl: { messages },
    } = props;
    const result = [];
    result.push(messages.editor.image_upload);
    result.push(messages.editor.mediathek);
    //result.push("Pixabay");
    result.push(messages.editor.used_images);

    return result;
  };

  const getTabKeepMounted = () => {
    const keepMounted = [0, 1];
    return keepMounted;
  };
  return (
    <>
      <Row>
        <Col class="tabsMediaLibrary">
          <Tabs tabs={getTabHeaderList()} tabContent={getTabContentList()} keepMounted={getTabKeepMounted()} />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(MediaTabs)));
