import React from 'react';
import { connect } from 'react-redux';
import { isEqual as _isEqual } from 'lodash';

import { countDispatchHelper } from 'actions/CountActions';

class Count extends React.Component {
  constructor(props) {
    super(props);

    this.currentRequestSource = null;
  }

  componentDidMount = () => {
    const { countName, url, urlData, method, fetch } = this.props;

    // fetch initial count
    if (countName && url) {
      fetch(this.currentRequestSource, this.setCurrentRequestSource, countName, url, urlData, method);
    }
  };

  componentDidUpdate = prevProps => {
    const { countName, url, urlData, method, fetch } = this.props;

    if (
      url !== prevProps.url ||
      !_isEqual(urlData, prevProps.urlData) ||
      (countName !== prevProps.countName && url) ||
      (url !== prevProps.url && countName)
    ) {
      fetch(this.currentRequestSource, this.setCurrentRequestSource, countName, url, urlData, method);
    }
  };

  setCurrentRequestSource = currentRequestSource => {
    this.currentRequestSource = currentRequestSource;
  };

  render() {
    return null;
  }
}
Count.defaultProps = {
  countName: '',
  url: '',
  data: {},
  urlData: {},
  method: 'get',
};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetch: (currentRequestSource, setCurrentRequestSource, countName, url, data, method = 'get') => {
      if (currentRequestSource) {
        currentRequestSource.cancel();
      }

      setCurrentRequestSource(countDispatchHelper(countName, url, data, method, dispatch));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Count);
