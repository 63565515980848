import React from 'react';
import { connect } from 'react-redux';
import { getFacebookOAuthUrl, getGoogleOAuthUrl, getMicrosoftOAuthUrl } from 'helpers/socialMedia';

class LoginSocialMediaButtons extends React.Component {
  renderButton(btnClass, iconClass, label, url, idx) {
    const { showLabels } = this.props;

    return (
      <a className={'btn btn-social m-t-10 ' + btnClass + (idx === 0 ? '' : ' m-l-10')} href={url} key={idx}>
        <i className={'fab ' + iconClass + (showLabels ? ' m-r-5' : '')} /> {showLabels ? label : ''}
      </a>
    );
  }

  render() {
    const { appIntl, projectConfig, socialMediaLoginState } = this.props;

    const res = [];
    let i = 0;

    const state = socialMediaLoginState || projectConfig.data.id;

    if (projectConfig.data.facebook_login_client_id) {
      res.push(
        this.renderButton(
          'btn-facebook',
          'fa-facebook-f',
          'Facebook',
          getFacebookOAuthUrl(projectConfig.data.facebook_login_client_id, appIntl.urlPrefix, state),
          i++
        )
      );
    }

    if (projectConfig.data.google_login_client_id) {
      res.push(
        this.renderButton(
          'btn-google',
          'fa-google',
          'Google',
          getGoogleOAuthUrl(projectConfig.data.google_login_client_id, appIntl.urlPrefix, state),
          i++
        )
      );
    }

    const clientId = projectConfig.data.microsoft_login_client_id;
    const directoryId = projectConfig.data.microsoft_login_directory_id;
    console.log(this.constructor.name, { urlPrefix: appIntl.urlPrefix, state });
    if (clientId && directoryId) {
      res.push(
        this.renderButton(
          'btn-microsoft',
          'fa-microsoft',
          'Microsoft',
          getMicrosoftOAuthUrl(clientId, directoryId, appIntl.urlPrefix, state),
          i++
        )
      );
    }

    return <React.Fragment>{res}</React.Fragment>;
  }
}

LoginSocialMediaButtons.defaultProps = {
  socialMediaLoginState: null,
  showLabels: true,
};
const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    projectConfig: state.data.projectConfig,
  };
};

export default connect(mapStateToProps)(LoginSocialMediaButtons);
