import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import GoogleMap from 'google-map-react';
import scriptLoader from 'react-async-script-loader';
import Loader from 'components/layout/Loader';
import supercluster from 'points-cluster';

import ClusteredLocationsMarker from 'components/layout/maps/ClusteredLocationMarker';
import SingleLocationMarker from 'components/layout/maps/SingleLocationMarker';

import * as constants from 'js/constants';

class LocationsMap extends React.Component {
  constructor(props) {
    super(props);

    const { zoom, center, activeIndex } = props;

    this.state = {
      boundsInit: false,
      zoom: zoom || 11,
      center: center || {
        lat: 49.2401572,
        lng: 6.9969327,
      },
      activeIndex: activeIndex || null,
    };
  }

  onMapChange = ({ center, zoom, bounds }) => {
    const { onMapChange } = this.props;
    onMapChange({ center, zoom, bounds });

    this.setState({ center, zoom, bounds, boundsInit: true });
  };

  onChildClick = (key, childProps) => {
    const { onChildClick } = this.props;
    onChildClick(key, childProps);

    this.setState({ activeIndex: parseInt(key, 10) });
  };

  closeTooltip = () => {
    this.setState({ activeIndex: null });
  };

  render() {
    const { appIntl, locations, isScriptLoaded, getLink, onMapDrag, onMapDragEnd } = this.props;
    const { boundsInit, center, zoom, activeIndex } = this.state;

    if (!isScriptLoaded) {
      return <Loader />;
    }
    const points = [];
    if (!locations.pending && boundsInit) {
      const cluster = supercluster(
        locations.data.list.map(location => {
          return { ...location, ...location.coordinates };
        })
      );
      const clusterRes = cluster(this.state);

      for (let i = 0; i < clusterRes.length; i++) {
        const markerProps = {
          isActive: i === activeIndex,
          closeTooltip: this.closeTooltip,
          getLink,
          lat: clusterRes[i].wy,
          lng: clusterRes[i].wx,
        };

        if (clusterRes[i].numPoints > 1) {
          points.push(<ClusteredLocationsMarker key={i} cluster={clusterRes[i]} {...markerProps} />);
        } else {
          points.push(<SingleLocationMarker key={i} location={clusterRes[i].points[0]} {...markerProps} />);
        }
      }
    }

    return (
      <GoogleMap
        bootstrapURLKeys={{
          key: constants.GOOGLE_MAPS_KEY,
          language: appIntl.locale,
        }}
        center={center}
        zoom={zoom}
        options={constants.mapOptions}
        resetBoundsOnResize
        onChildClick={this.onChildClick}
        onChange={this.onMapChange}
        onDrag={onMapDrag}
        onDragEnd={onMapDragEnd}
      >
        {points}
      </GoogleMap>
    );
  }
}
LocationsMap.defaultProps = {
  onChildClick: () => {},
  onMapChange: () => {},
  getLink: () => {
    return '/';
  },
};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default injectIntl(connect(mapStateToProps)(scriptLoader(constants.GOOGLE_MAPS_URL)(LocationsMap)));
