import React from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import moment from 'moment';
import 'moment-timezone';

import TimePicker from 'components/forms/TimePicker';
import IntlTime from 'components/layout/IntlTime';

export default class FormTimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value ? props.value : '',
      showPrompt: false,
    };

    this.timeRef = React.createRef();
  }

  checkFocused = () => {
    return this.state.showPrompt ? 'focused ' : '';
  };

  checkRequired = () => {
    return this.props.meta.validate.rules.indexOf('required') !== -1 ? 'required ' : '';
  };

  checkError = () => {
    return this.props.validation.valid ? '' : 'has-error ';
  };

  // check if user may read this field
  checkAccess = () => {
    return this.props.meta.acl.read;
  };

  // check if user may write to this field
  checkReadOnly = () => {
    return !this.props.meta.acl.write;
  };

  checkDisabled = () => {
    const { disabled, meta } = this.props;
    if (disabled) {
      return true;
    }
    if (!meta.acl.write) {
      return true;
    }
    return false;
  };

  onChange = value => {
    this.setState({ value }, () => {
      // format the date from display to return timezone
      const returnValue = moment.tz(value, this.props.displayTz).tz(this.props.returnTz);
      this.props.onChange(this.props.name, returnValue.toISOString());
    });
  };

  render() {
    const { name, label, displayTz, locale, className, style, onChange, ...rest } = this.props;
    const { value } = this.props;

    return (
      <div
        class={
          'form-group cursor form-group-default ' +
          this.checkFocused() +
          this.checkRequired() +
          (this.checkDisabled() ? 'disabled ' : '') +
          ' ' +
          className
        }
        style={{ ...style }}
        onClick={() => this.setState({ showPrompt: true })}
      >
        <label>{label}</label>
        <div class="lh-25">
          <input
            name={name}
            style={{ width: 0, overflow: 'hidden', position: 'absolute', left: '-5000px' }}
            onFocus={() => this.setState({ showPrompt: true })}
            onBlur={() => this.setState({ showPrompt: false })}
          />
          <IntlTime ref={this.timeRef} date={moment.tz(value, 'UTC').toISOString()} />
        </div>

        <Overlay
          show={this.state.showPrompt}
          target={this.timeRef.current}
          placement="bottom"
          rootClose
          onHide={() => {
            this.setState({ showPrompt: false });
          }}
        >
          <Popover id={`time-picker-${name}`} style={{ maxWidth: 'none', zIndex: 1200 }}>
            <TimePicker
              {...rest}
              displayTz={displayTz}
              locale={locale}
              value={moment.tz(value, 'UTC').toISOString()}
              onChange={this.onChange}
            />
          </Popover>
        </Overlay>
      </div>
    );
  }
}
FormTimePicker.defaultProps = {
  name: '',
  label: '',
  meta: { validate: { rules: '' }, acl: { read: true, write: true } },
  validation: { valid: true },
  onChange: () => {},
  displayTz: 'UTC',
  returnTz: 'UTC',
  className: '',
  style: {},
  locale: 'en',
};
