import { authModule, getLogger, MessageType, notify } from '@chedri/base';
import { call, select, takeEvery } from 'redux-saga/effects';

import { Howl } from 'howler';

import { requestPermission as notificationRequestPermission } from '../helpers/notifications';

const logger = getLogger('notification');

const sounds = [
  new Howl({
    src: '/sounds/success.mp3',
    loop: false,
    preload: true,
  }),
  new Howl({
    src: '/sounds/information.mp3',
    loop: false,
    preload: true,
  }),
  new Howl({
    src: '/sounds/warning.mp3',
    loop: false,
    preload: true,
  }),
  new Howl({
    src: '/sounds/danger.wav',
    loop: false,
    preload: true,
  }),
];

function playSound({ payload }) {
  try {
    const { type, data } = payload || {};
    const { silent, sound } = data || {};
    if (silent) {
      logger.trace('There is a reqeust to be silent');
      return;
    }
    if (sound != null) {
      logger.debug('Playing custom sound', sound);
      const howl = new Howl(sound);
      howl.play();
      return;
    }
    logger.trace('Playing default sound', MessageType.toString(type));
    const soundIndex = MessageType.parse(type) - 1;
    const selectedSound = sounds[soundIndex];
    selectedSound.play();
  } catch (err) {
    logger.error('Error playing notification sound.', err);
  }
}

function* handleUserLoggedIn() {
  try {
    const isEmployee = yield select(authModule.selectors.isEmployee);
    if (isEmployee) {
      const permission = yield call(notificationRequestPermission);
      logger.debug('Request notification permission result:', permission);
    }
  } catch (err) {
    logger.error('Error requesting notification permissions');
  }
}

export default function* authSaga() {
  logger.debug('Watch notification actions');

  yield takeEvery('FETCH_CREDENTIALS_FULFILLED', handleUserLoggedIn);
  yield takeEvery('HANDLE_LOGIN_FULFILLED', handleUserLoggedIn);

  yield takeEvery(notify, playSound);
}
