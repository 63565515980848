import React from 'react';
import { findIndex as _findIndex, forEach as _forEach } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { S3_LINKS } from 'js/constants';

class EditorMediathek extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleMedia = (original, path, filename = '', thumb = '', copyright = '') => {
    const { loadMedia } = this.props;
    loadMedia(original, path, filename, thumb, copyright);
  };

  render() {
    const { usedMedia, neededHeight, neededWidth } = this.props;
    const {
      intl: { messages },
    } = this.props;
    const unusedMedias = [];
    const usedMedias = [];
    const usedImages = [];
    const unusedImages = [];

    const thumbSrc = S3_LINKS.MEDIA_IMGS_THUMB;
    _forEach(usedMedia, media => {
      let mediaUnavailable = '';
      const mediaThumb = {
        backgroundImage: 'url(' + thumbSrc + media.thumb + ')',
      };
      if (neededWidth > media.width || neededHeight > media.height) {
        mediaUnavailable = 'disabled';
      }
      const titleAdd =
        '<b>Bild:</b> ' +
        media.originalname +
        '<br><b>Dimensionen:</b> ' +
        media.width +
        ' x ' +
        media.height +
        ' Pixel';
      const mediaEntry = (
        <div
          data-tip={titleAdd}
          data-html
          data-for="mediatop"
          className={'medias used_medias ' + mediaUnavailable}
          onClick={
            !mediaUnavailable
              ? () => {
                  this.handleMedia(
                    media.key,
                    S3_LINKS.MEDIA_IMGS_ORIGINAL,
                    media.originalname,
                    media.thumb,
                    media.copyright
                  );
                }
              : null
          }
        >
          <div className="thumb" style={mediaThumb} />
          <p>{media.originalname}</p>
          <p>{media.imgDescription || media.img_description}</p>
        </div>
      );

      if (media.blockid === 'unused' && typeof unusedImages.find(element => element === media.key) === 'undefined') {
        const usedMediaEntry = _findIndex(usedMedia, function(o) {
          return o.key === media.key && o.blockid !== 'unused';
        });
        if (usedMediaEntry < 0) {
          unusedMedias.push(mediaEntry);
        }
        unusedImages.push(media.key);
      } else if (
        media.blockid !== 'unused' &&
        typeof usedImages.find(element => element === media.key) === 'undefined'
      ) {
        usedImages.push(media.key);
        unusedImages.push(media.key);
        usedMedias.push(mediaEntry);
      }
    });

    return (
      <div id="mediathekStage" className="row col-md-12">
        <div id="contextModalMediathek" style={{ paddingLeft: '0px' }} className="col-md-8 text-left">
          <div id="mediathekMedias" className="text-center" />
        </div>
        <div id="contextModalUsedMedia" className="col-md-12 text-left">
          <h4 style={{ margin: '0 auto', marginBottom: '20px' }}>{messages.editor.document_pictures}</h4>
          <div id="usedMedias" className="text-center">
            <h5>{messages.editor.used_images}</h5>
            {usedMedias}
          </div>
          <div id="unusedMedias" className="text-center">
            <h5>{messages.editor.unused_images}</h5>
            {unusedMedias}
          </div>
        </div>
        <ReactTooltip id="mediatop" place="top" effect="solid" />
      </div>
    );
  }
}
EditorMediathek.defaultProps = {};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(EditorMediathek)));
