import React from 'react';
import Modal from 'components/Modal';

export default function CartWarning({ isShow, onHide, children }) {
  return (
    <Modal show={isShow} className="creacheck-modal" onHide={onHide} bsSize="lg">
      <Modal.Header closeButton />
      <Modal.Body>
        <h1 className="text-center" style={{ fontSize: '100px', color: '#cd1718' }}>
          <i className="fas fa-exclamation-triangle" />
        </h1>
        {children}
      </Modal.Body>
    </Modal>
  );
}
