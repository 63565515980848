import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';

import { getTimezoneFromState } from 'helpers/intl';

class IntlDateRaw extends React.Component {
  getTimezone = () => {
    const { defaultTimezone, timezone } = this.props;
    return timezone || defaultTimezone;
  };

  render() {
    const { date, forwardedRef } = this.props;

    let tzDate = moment.tz(date, 'UTC');
    tzDate = tzDate.tz(this.getTimezone());

    return <span ref={forwardedRef}>{`${tzDate.format('l')} ${moment(date).format('HH:mm')} Uhr`}</span>;
  }
}

const mapStateToProps = state => {
  return {
    defaultTimezone: getTimezoneFromState(state),
  };
};

const ConnectedIntlDate = connect(mapStateToProps)(IntlDateRaw);

// eslint-disable-next-line react/display-name
const IntlDate = React.forwardRef((props, ref) => <ConnectedIntlDate forwardedRef={ref} {...props} />);
export default IntlDate;
