import React from 'react';
import {
  forEach as _forEach,
  clone as _clone,
  concat as _concat,
  isPlainObject as _isPlainObject,
  isArray as _isArray,
} from 'lodash';

export default class EntityPropsUpdater extends React.Component {
  updateEntity = (propertyName, value, fieldTree = [], callback = null, isLoud = true) => {
    if (this.defaultFieldTree !== undefined) {
      fieldTree = _concat(this.defaultFieldTree, fieldTree);
    }
    const { entity, changeEntity } = this.props;

    if (_isPlainObject(value)) {
      value = entity.createValue(propertyName, _clone(fieldTree), value);
    } else if (_isArray(value)) {
      value = value.map(valueEntry => entity.createValue(propertyName, _clone(fieldTree), valueEntry));
    }
    const newEntity = { ...entity.updateValue(propertyName, value, fieldTree) };

    changeEntity(newEntity, callback, isLoud);
  };

  updateEntityMultiple = (propertyNames, values, fieldTrees = [], callback = null, isLoud = true) => {
    const { entity, changeEntity } = this.props;

    let newEntity;
    _forEach(propertyNames, (propertyName, i) => {
      let value = values[i];
      let fieldTree = fieldTrees[i] !== undefined && fieldTrees[i] !== null ? fieldTrees[i] : [];
      if (this.defaultFieldTree !== undefined) {
        fieldTree = _concat(this.defaultFieldTree, fieldTree);
      }

      if (_isPlainObject(value)) {
        value = entity.createValue(propertyName, _clone(fieldTree), value);
      } else if (_isArray(value)) {
        value = value.map(valueEntry => entity.createValue(propertyName, _clone(fieldTree), valueEntry));
      }

      newEntity = { ...entity.updateValue(propertyName, value, fieldTree) };
    });

    changeEntity(newEntity, callback, isLoud);
  };
}
