import { ONLINE_STATUS_CHANGED, CHANGE_CURRENCY } from 'actions/AppActions';

import { getCurrencySymbol } from 'helpers/financial';

const initialState = {
  isOnline: true,
  debug: DEBUG,
  currency: 'EUR',
  currencySymbol: getCurrencySymbol('EUR'),
};

if (
  typeof whiteLabelSettings !== 'undefined' &&
  whiteLabelSettings.currencies &&
  whiteLabelSettings.currencies.length
) {
  initialState.currency = whiteLabelSettings.currencies[0];
  initialState.currencySymbol = getCurrencySymbol(initialState.currency);
}

export default function webApp(state = initialState, action) {
  switch (action.type) {
    case ONLINE_STATUS_CHANGED: {
      return { ...state, isOnline: action.payload };
    }

    case CHANGE_CURRENCY: {
      return { ...state, currency: action.payload };
    }
  }

  return state;
}
