import {
  DATA_PENDING,
  DATA_FULFILLED,
  DATA_RESET,
  DATA_REJECTED,
  ADD_LEAD_TIMELINE,
  LEAD_MASTER_COPY_UPLOADED,
  ADD_ORDER_TIMELINE,
  REMOVE_ITEM_IMAGE,
  REMOVE_PLUGIN_IMAGE,
  REMOVE_CATEGORY_IMAGE,
} from 'actions/DataActions';
import { FORM_FINISHED } from 'actions/FormStatusActions';
import { DELETER_FULFILLED } from 'actions/DeleterActions';
import { UPLOAD_FILE_FINISHED } from 'actions/UploadActions';
import { SOCKET_EMIT, SOCKET_EVENT } from 'actions/SocketActions';
import _, { cloneDeep as _cloneDeep } from 'lodash';

const standardState = {
  pending: true,
  hasError: false,
  errors: [],
  data: {},
  exeThen: '',
};

// check if project config (white label configuration may be found in the js head)
const projectConfig = { ...standardState };
if (typeof whiteLabelSettings !== 'undefined') {
  // eslint-disable-next-line no-undef
  projectConfig.data = whiteLabelSettings;
}

const initialState = {
  projectConfig,
  chatterToken: { ...standardState },
  myParent: { ...standardState },
  client: { ...standardState },
  user: { ...standardState },
  clientParent: { ...standardState },
  dashboard: { ...standardState },
  bill: { ...standardState },
  invoice: { ...standardState },
  commissionInvoice: { ...standardState },
  quote: { ...standardState },
  paymentSchedule: { ...standardState },
  questionnaire: { ...standardState, data: { questions: [] } },
  filledQuestionnaire: { ...standardState },
  pipeline: { ...standardState },
  attribute: { ...standardState },
  item: { ...standardState },
  itemVariant: { ...standardState },
  lead: { ...standardState },
  leadPipelineCount: { ...standardState },
  page: { ...standardState },
  pageDraft: { ...standardState },
  cmsElement: { ...standardState },
  cmsTemplate: { ...standardState },
  cmsTemplateDraft: { ...standardState },
  category: { ...standardState },
  productTemplate: { ...standardState },
  order: { ...standardState },
  docType: { ...standardState },
  carMileage: { ...standardState },
  carTankVolume: { ...standardState },
  cmsPage: { ...standardState },
  cmsLoginForm: { ...standardState },
  cmsContactForm: { ...standardState },
  cmsSystemPageHome: { ...standardState },
  cmsSystemPageImprint: { ...standardState },
  cmsSystemPageTermsOfService: { ...standardState },
  cmsSystemPagePrivacyPolicy: { ...standardState },
  cmsSystemPageCarRentTermsOfService: { ...standardState },
  cmsSystemPageCarSalesTermsOfService: { ...standardState },
  cmsSystemPageItemSalesTermsOfService: { ...standardState },
  cmsSystemPageCookieUse: { ...standardState },
  cmsSystemPageLogin: { ...standardState },
  cmsSystemPageSignup: { ...standardState },
  cmsSystemPageReservation: { ...standardState },
  cmsSystemPageContact: { ...standardState },
  cmsSystemPageCancellationPolicy: { ...standardState },
  cmsSystemPageMarketplaceTerms: { ...standardState },
  cmsSystemPageOrderProcessingContract: { ...standardState },
  cmsSystemPageOnlineAppointmentBooking: { ...standardState },
  cmsSystemPageLocations: { ...standardState },
  cmsSystemPageRegistrationSuccess: { ...standardState },
  whiteLabel: { ...standardState },
  whiteLabelPlugin: { ...standardState },
  clientLocation: { ...standardState },
  cmsFile: { ...standardState },
  location: { ...standardState },
  dynamicDataDefinition: { ...standardState },
  myAppKey: { ...standardState },
  pluginActivationRequest: { ...standardState },
  modelGroup: { ...standardState },
  modelAsset: { ...standardState },
  leadPerMailRule: { ...standardState },
  stripe: { ...standardState },
  checkoutInfos: { ...standardState },
  leadStatsAssignee: { ...standardState },
  leadStatsSource: { ...standardState },
  leadStatsTime: { ...standardState },
  leadStatsMonthTime: { ...standardState },
  priceNegotiation: { ...standardState },
  community: { ...standardState },
  clientCar: { ...standardState },
  gudatSolutionDaConfigured: { ...standardState },
  carService: { ...standardState },
  carServiceStation: { ...standardState },
  docTemplate: { ...standardState },
  nextItem: { ...standardState },
  autoresponse: { ...standardState },
  kvsSettings: { ...standardState },
  chatterAgent: { ...standardState },
  mainMenuVersion: { ...standardState },
  leadCatcher: { ...standardState },
  shareableResource: { ...standardState },
  team: { ...standardState },
  leadTagger: { ...standardState },
  leadAssignmentStrategy: { ...standardState },
  leadEscalationRule: { ...standardState },
  holdingInfos: { ...standardState },
  campaign: { ...standardState },
  commissionReceiver: { ...standardState },
  clientProvision: { ...standardState },
  itemProvision: { ...standardState },
  paymentReceiver: { ...standardState },
  billingTarget: { ...standardState },
  userBudget: { ...standardState },
  coupon: { ...standardState },
  orderAuthorization: { ...standardState },
  customer: { ...standardState },
  allowedDomain: { ...standardState },
};

export default function data(state = initialState, action) {
  switch (action.type) {
    case 'SET_VARIABLES_RDATA': {
      if (action.payload === 'startForceUpdateReset' || action.payload === 'startRenewListAndCloseSettingsReset') {
        state.campaign.exeThen = '';
      } else if (action.payload === 'campaignsavingtrue') {
        state.campaign.campaignSavingR = true;
      } else if (action.payload === 'campaignsavingfalse') {
        state.campaign.campaignSavingR = false;
      } else if (action.payload === 'changedtrue') {
        state.campaign.changedR = true;
      } else if (action.payload === 'startHistoryReset') {
        state.campaign.exeThen = '';
      }
      return { ...state };
    }

    case 'ADD_SHARED_EDITION_USER': {
      const newState = { ...state };
      const campaignData = _cloneDeep(state.campaign.data);
      const index = _.findIndex(state.campaign.data.editions, list => list.id === action.payload.editionId);
      if (index !== -1) {
        const newUserData = {
          username: action.payload?.userData?.id,
          profile: {
            company: action.payload?.userData?.company,
            first_name: action.payload?.userData?.firstName,
            last_name: action.payload?.userData?.lastName,
          },
        };
        campaignData.editions[index].shared_with_users.push(newUserData);
      }
      newState.campaign = {
        ...state.campaign,
        data: { ...campaignData },
      };
      return newState;
    }

    case 'DELETE_SHARED_EDITION_USER': {
      const newState = { ...state };
      const index = _.findIndex(state.campaign.data.editions, list => list.id === action.payload.editionId);
      if (index !== -1) {
        const sharedUsers = _.filter(
          state.campaign.data.editions[index]?.shared_with_users,
          x => x.username !== action.payload?.userId
        );
        state.campaign.data.editions[index].shared_with_users = sharedUsers;
      }
      newState.campaign = {
        ...state.campaign,
        data: { ...state.campaign.data },
      };
      return newState;
    }

    case 'MARK_EDITION_FULFILLED': {
      const newState = { ...state };
      const index = _.findIndex(state.campaign.data.editions, list => list.id === action.payload.id);
      state.campaign.data.editions[index] = action.payload;
      state.campaign.exeThen = 'startForceUpdate';

      newState.campaign = {
        ...state.campaign,
        data: { ...state.campaign.data },
      };
      return newState;
    }
    case DATA_PENDING: {
      const newState = { ...state };
      const oldDataState = newState[action.payload.dataName];

      newState[action.payload.dataName] = {
        ...oldDataState,
        pending: true,
        hasError: false,
        errors: [],
        data: { ...initialState[action.payload.dataName].data },
      };
      return newState;
    }

    case DATA_FULFILLED: {
      if (!action.payload.response || action.payload.response.data === undefined) return state;

      const newState = { ...state };
      const oldDataState = newState[action.payload.dataName];

      newState[action.payload.dataName] = {
        ...oldDataState,
        pending: false,
        hasError: false,
        errors: [],
        data: action.payload.response.data,
      };
      return newState;
    }

    case DATA_RESET: {
      return {
        ...state,
        [action.payload.dataName]: initialState[action.payload.dataName],
      };
    }

    case DATA_REJECTED: {
      const newState = { ...state };
      const oldDataState = newState[action.payload.dataName];

      newState[action.payload.dataName] = {
        ...oldDataState,
        pending: false,
        hasError: true,
        errors: action.payload.response ? action.payload.response.data : {},
        data: { ...state[action.payload.dataName].data },
      };
      return newState;
    }

    case FORM_FINISHED: {
      const { formName } = action.payload;

      if (formName === 'productTemplateEdit') {
        const newState = { ...state };
        newState.productTemplate = {
          ...newState.productTemplate,
          data: action.payload.response.data,
        };
        return newState;
      }

      if (formName === 'clientEdit') {
        // after saving a client form, update the data
        const newState = { ...state };

        newState.client = {
          ...newState.client,
          data: action.payload.response.data,
        };

        return newState;
      }

      if (formName === 'campaignEdit' || formName === 'campaignNew') {
        // after saving a client form, update the data
        const newState = { ...state };

        newState.campaign = {
          ...newState.campaign,
          data: action.payload.response.data,
        };

        return newState;
      }

      if (formName === 'priceNegotiationNew' && state.item.data.id === action.payload.response.data.item_id) {
        // after saving the new price negotiation, update the item data
        const newState = { ...state };

        const priceNegotiations = state.item.data.price_negotiations ? state.item.data.price_negotiations.slice() : [];
        priceNegotiations.push(action.payload.response.data);

        newState.item = {
          ...newState.item,
          data: { ...newState.item.data, price_negotiations: priceNegotiations },
        };

        return newState;
      }

      if (formName === 'acceptQuote' || formName === 'rejectQuote') {
        const newState = { ...state };

        newState.quote = {
          ...newState.quote,
          data: action.payload.response.data,
        };

        return newState;
      }

      if (
        formName === 'leadWon' ||
        formName === 'leadLost' ||
        formName === 'leadChangeStage' ||
        formName === 'leadChangeTeam' ||
        formName === 'leadChangePipeline'
      ) {
        const newState = { ...state };

        newState.lead = {
          ...newState.lead,
          data: action.payload.response.data,
        };

        return newState;
      }

      if (formName === 'whiteLabelEdit') {
        if (state.projectConfig.data.id === action.payload.response.data.id) {
          const newState = { ...state };

          newState.projectConfig = {
            ...newState.projectConfig,
            data: action.payload.response.data,
          };

          return newState;
        }
      } else if (formName === 'pluginActivate' || formName === 'pluginTerminate') {
        if (state.projectConfig.data.id) {
          const newState = { ...state };

          newState.projectConfig = {
            ...newState.projectConfig,
            data: {
              ...newState.projectConfig.data,
              plugins:
                action.payload.response.data && action.payload.response.data.plugins
                  ? action.payload.response.data.plugins
                  : [],
            },
          };

          return newState;
        }
      }

      if (
        formName === 'orderActionSendPaymentInstructions' ||
        formName === 'orderActionCancelOrder' ||
        formName === 'orderActionPickupInvite' ||
        formName === 'orderActionPickedUp' ||
        formName === 'orderActionCancelBooking'
      ) {
        const newState = { ...state };
        newState.lead.data = { ...action.payload.response.data };
        for (let i = 0; i < action.payload.response.data.orders.length; i++) {
          if (newState.order.data && newState.order.data.id === action.payload.response.data.orders[i].id) {
            newState.order.data = { ...action.payload.response.data.orders[i] };
          }
        }

        return newState;
      }

      if (formName === 'leadEdit') {
        // after saving a lead form, update the data
        const newState = { ...state };

        newState.lead = {
          ...newState.lead,
          data: action.payload.response.data,
        };

        return newState;
      }

      if (formName === 'myParentEdit') {
        const newState = { ...state };

        newState.myParent = {
          ...newState.myParent,
          data: action.payload.response.data,
        };

        return newState;
      }

      if (formName === 'pageDraftNew' || formName === 'pageDraftEdit') {
        // after saving a page draft, update the data
        const newState = { ...state };

        newState.pageDraft = {
          ...newState.pageDraft,
          data: action.payload.response.data,
        };

        return newState;
      }

      if (formName === 'pageEdit') {
        // after editing a page
        const newState = { ...state };

        newState.pageDraft = {
          ...newState.pageDraft,
          data: false,
        };

        return newState;
      }

      if (formName === 'cmsTemplateDraftNew' || formName === 'cmsTemplateDraftEdit') {
        // after saving a cms template draft, update the data
        const newState = { ...state };

        newState.cmsTemplateDraft = {
          ...newState.cmsTemplateDraft,
          data: action.payload.response.data,
        };

        return newState;
      }

      if (formName === 'cmsTemplateEdit') {
        // after editing a cms template
        const newState = { ...state };

        newState.cmsTemplateDraft = {
          ...newState.cmsTemplateDraft,
          data: false,
        };

        return newState;
      }

      if (formName === 'priceNegotiationEdit') {
        const newState = { ...state };

        newState.priceNegotiation = {
          ...newState.priceNegotiation,
          data: { ...action.payload.response.data },
        };

        if (newState.lead.data.id === action.payload.response.data.lead_id) {
          // check if lead data should be updated
          newState.lead = {
            ...newState.lead,
            data: { ...newState.item.lead, price_negotiation: action.payload.response.data },
          };
        }

        return newState;
      }

      if (formName === 'share') {
        // after adding a resource to a share
        const newState = { ...state };

        const { shareWithType, data } = action.payload.response.data;
        const { teams = [], users = [], companies = [] } = newState.shareableResource.data;
        switch (shareWithType) {
          case 'team': {
            teams.push(data);
            break;
          }

          case 'user': {
            users.push(data);
            break;
          }

          case 'company': {
            companies.push(data);
            break;
          }
        }

        newState.shareableResource = {
          ...newState.shareableResource,
          data: {
            ...newState.shareableResource.data,
            teams: teams.slice(),
            users: users.slice(),
            companies: companies.slice(),
          },
        };

        return newState;
      }

      if (formName === 'holdingInfos') {
        // after modifying the client's holding infos, update the new holding status
        const newState = { ...state };

        newState.holdingInfos = {
          ...newState.holdingInfos,
          data: action.payload.response.data,
        };

        return newState;
      }

      if (formName === 'commissionReceiverEdit') {
        const newState = { ...state };

        newState.commissionReceiver = {
          ...newState.commissionReceiver,
          data: { ...action.payload.response.data },
        };

        return newState;
      }

      if (formName === 'clientProvisionEdit') {
        const newState = { ...state };

        newState.clientProvision = {
          ...newState.clientProvision,
          data: { ...action.payload.response.data },
        };

        return newState;
      }

      if (formName === 'itemProvisionEdit') {
        const newState = { ...state };

        newState.itemProvision = {
          ...newState.itemProvision,
          data: { ...action.payload.response.data },
        };

        return newState;
      }

      if (formName === 'paymentReceiverEdit') {
        const newState = { ...state };

        newState.paymentReceiver = {
          ...newState.paymentReceiver,
          data: { ...action.payload.response.data },
        };

        return newState;
      }

      if (formName === 'billingTargetEdit') {
        const newState = { ...state };

        newState.billingTarget = {
          ...newState.billingTarget,
          data: { ...action.payload.response.data },
        };

        return newState;
      }

      if (
        (formName === 'budgetNew' || formName === 'budgetEdit') &&
        action.payload.response.data.budget_definition.target
      ) {
        const newState = { ...state };

        newState.userBudget = {
          ...newState.userBudget,
          data: { ...action.payload.response.data },
        };

        return newState;
      }

      if (
        (formName === 'budgetNew' || formName === 'budgetEdit') &&
        action.payload.response.data.budget_definition.campaign
      ) {
        const newState = { ...state };

        newState.campaignBudget = {
          ...newState.campaignBudget,
          data: { ...action.payload.response.data },
        };

        return newState;
      }

      if (formName === 'orderAuthorization') {
        const newState = { ...state };

        newState.orderAuthorization = {
          ...newState.orderAuthorization,
          data: { ...action.payload.response.data },
        };

        return newState;
      }

      break;
    }

    case DELETER_FULFILLED: {
      const {
        payload: {
          deleterName,
          // response: { data: deletedId },
        },
      } = action;

      if (deleterName === 'share') {
        const newState = { ...state };

        const { shareWithType, shareWithId } = action.payload.response.data;
        const { teams = [], users = [], companies = [] } = newState.shareableResource.data;

        newState.shareableResource = {
          ...newState.shareableResource,
          data: {
            ...newState.data,
            teams: teams.slice().filter(elem => !(shareWithType === 'team' && elem.id === shareWithId)),
            users: users.slice().filter(elem => !(shareWithType === 'user' && elem.id === shareWithId)),
            companies: companies.slice().filter(elem => !(shareWithType === 'company' && elem.id === shareWithId)),
          },
        };
        return newState;
      }

      if (deleterName === 'edition') {
        // campaigns list should be altered
        const campaignsArray = state.campaign.data;
        const deletedId = action.payload.response.data;
        campaignsArray.editions = campaignsArray.editions.slice().filter(edition => {
          return edition.id !== deletedId;
        });
        const newState = { ...state };
        newState.campaign = {
          ...state.campaign,
          data: { ...state.campaign.data, data: campaignsArray },
        };
        return newState;
      }

      break;
    }

    case UPLOAD_FILE_FINISHED: {
      if (
        state.client.data.username !== undefined &&
        action.payload.response.config.url.indexOf('/api/avatars/' + state.client.data.username) !== -1
      ) {
        const newState = { ...state };
        newState.client = {
          ...newState.client,
          pending: false,
          hasError: false,
          errors: [],
          data: action.payload.response.data,
        };
        return newState;
      }

      if (
        state.user.data.username !== undefined &&
        action.payload.response.config.url.indexOf('/api/avatars/' + state.user.data.username) !== -1
      ) {
        const newState = { ...state };
        newState.user = {
          ...newState.user,
          pending: false,
          hasError: false,
          errors: [],
          data: action.payload.response.data,
        };
        return newState;
      }

      if (
        state.itemVariant.data.id !== undefined &&
        action.payload.response.config.url.indexOf(state.itemVariant.data.id) !== -1
      ) {
        // item variant file; catch variant first (before item) cause the url also contains item id
        const variantImgs =
          state.itemVariant.data.assets && state.itemVariant.data.assets.imgs
            ? state.itemVariant.data.assets.imgs.slice()
            : [];
        variantImgs.push(action.payload.response.data);

        const newState = { ...state };
        newState.itemVariant = {
          ...newState.itemVariant,
          data: { ...newState.itemVariant.data, assets: { ...newState.itemVariant.data.assets, imgs: variantImgs } },
        };
        return newState;
      }

      if (state.item.data.id !== undefined && action.payload.response.config.url.indexOf(state.item.data.id) !== -1) {
        // item files
        const itemImgs =
          state.item.data.assets && state.item.data.assets.imgs ? state.item.data.assets.imgs.slice() : [];
        itemImgs.push(action.payload.response.data);

        const newState = { ...state };
        newState.item = {
          ...newState.item,
          data: { ...newState.item.data, assets: { ...newState.item.data.assets, imgs: itemImgs } },
        };
        return newState;
      }

      if (
        state.whiteLabelPlugin.data.id !== undefined &&
        action.payload.response.config.url.indexOf(state.whiteLabelPlugin.data.id) !== -1
      ) {
        // whiteLabelPlugin files
        const whiteLabelPluginImgs =
          state.whiteLabelPlugin.data.assets && state.whiteLabelPlugin.data.assets.imgs
            ? state.whiteLabelPlugin.data.assets.imgs.slice()
            : [];
        whiteLabelPluginImgs.push(action.payload.response.data);

        const newState = { ...state };
        newState.whiteLabelPlugin = {
          ...newState.whiteLabelPlugin,
          data: {
            ...newState.whiteLabelPlugin.data,
            assets: { ...newState.whiteLabelPlugin.data.assets, imgs: whiteLabelPluginImgs },
          },
        };
        return newState;
      }

      if (
        state.category.data.id !== undefined &&
        action.payload.response.config.url.indexOf(state.category.data.id) !== -1
      ) {
        // item files
        const itemImgs =
          state.category.data.assets && state.category.data.assets.imgs ? state.category.data.assets.imgs.slice() : [];
        itemImgs.push(action.payload.response.data);

        const newState = { ...state };
        newState.category = {
          ...newState.category,
          data: { ...newState.category.data, assets: { ...newState.category.data.assets, imgs: itemImgs } },
        };
        return newState;
      }

      if (
        state.client.data.username !== undefined &&
        action.payload.response.config.url.indexOf(state.client.data.username + '/locations') !== -1
      ) {
        // check if a client location got updated
        if (state.client.data.locations && state.client.data.locations.length) {
          let found = false;
          const locations = state.client.data.locations.slice();
          for (let i = 0; i < locations.length; i++) {
            if (action.payload.response.config.url.indexOf(locations[i].id)) {
              found = true;
              locations[i].imgs.push(action.payload.response.data);
            }
          }

          if (found) {
            const newState = { ...state };
            newState.client = {
              ...newState.client,
              data: { ...newState.client.data, locations },
            };
            return newState;
          }
        }
      }

      if (
        state.clientCar.data.id !== undefined &&
        action.payload.response.config.url.indexOf('/api/clientcars/' + state.clientCar.data.id) !== -1
      ) {
        const newState = { ...state };
        newState.clientCar = {
          ...newState.clientCar,
          pending: false,
          hasError: false,
          errors: [],
          data: {
            ...newState.clientCar.data,
            item: {
              ...newState.clientCar.data.item,
              default_img: action.payload.response.data,
            },
          },
        };
        return newState;
      }
      break;
    }

    // questionnaire-specific actions
    case 'NEW_QUESTION_FOR_NEW_QUESTIONNAIRE': {
      const newState = { ...state };
      newState.questionnaire.data.questions.slice();
      newState.questionnaire.data.questions.push(action.payload);

      return newState;
    }

    case 'EDIT_QUESTION_FOR_NEW_QUESTIONNAIRE': {
      for (let i = 0; i < state.questionnaire.data.questions.length; i++) {
        if (action.payload.questionId === state.questionnaire.data.questions[i].id) {
          const newState = { ...state };
          newState.questionnaire.data.questions.splice(i, 1, action.payload.data);
          return newState;
        }
      }
      break;
    }

    case 'QUESTIONNAIRE_REORDER_QUESTIONS': {
      const { fromIndex, toIndex } = action.payload;

      const newState = {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          data: {
            ...state.questionnaire.data,
          },
        },
      };
      const element = { ...state.questionnaire.data.questions[fromIndex] };

      newState.questionnaire.data.questions.splice(fromIndex, 1);
      newState.questionnaire.data.questions.splice(toIndex, 0, element);

      return newState;
    }

    case 'QUESTIONNAIRE_REMOVE_QUESTION': {
      for (let i = 0; i < state.questionnaire.data.questions.length; i++) {
        if (action.payload === state.questionnaire.data.questions[i].id) {
          const newState = { ...state };
          newState.questionnaire.data.questions.splice(i, 1);

          return newState;
        }
      }
      break;
    }

    case REMOVE_ITEM_IMAGE: {
      if (action.payload) {
        const { itemId, variantId, imageIndex } = action.payload;

        if (variantId) {
          const newState = { ...state };
          newState.itemVariant = {
            ...newState.itemVariant,
            data: {
              ...newState.itemVariant.data,
              assets: { ...newState.itemVariant.data.assets, imgs: newState.itemVariant.data.assets.imgs.slice() },
            },
          };
          newState.itemVariant.data.assets.imgs = newState.itemVariant.data.assets.imgs
            .slice()
            .filter((img, i) => i !== imageIndex);
          return newState;
        }

        if (itemId) {
          const newState = { ...state };
          newState.item = {
            ...newState.item,
            data: {
              ...newState.item.data,
              assets: { ...newState.item.data.assets, imgs: newState.item.data.assets.imgs.slice() },
            },
          };
          newState.item.data.assets.imgs = newState.item.data.assets.imgs.filter((img, i) => i !== imageIndex);
          return newState;
        }
      }
      break;
    }

    case REMOVE_PLUGIN_IMAGE: {
      if (action.payload) {
        const { pluginId, imageIndex } = action.payload;

        if (pluginId) {
          const newState = { ...state };
          newState.whiteLabelPlugin = {
            ...newState.whiteLabelPlugin,
            data: {
              ...newState.whiteLabelPlugin.data,
              assets: {
                ...newState.whiteLabelPlugin.data.assets,
                imgs: newState.whiteLabelPlugin.data.assets.imgs.slice(),
              },
            },
          };
          newState.whiteLabelPlugin.data.assets.imgs = newState.whiteLabelPlugin.data.assets.imgs.filter(
            (img, i) => i !== imageIndex
          );
          return newState;
        }
      }
      break;
    }

    case REMOVE_CATEGORY_IMAGE: {
      if (action.payload) {
        const { categoryId, imageIndex } = action.payload;
        if (categoryId) {
          const newState = { ...state };
          newState.category = {
            ...newState.category,
            data: {
              ...newState.category.data,
              assets: { ...newState.category.data.assets, imgs: newState.category.data.assets.imgs.slice() },
            },
          };
          newState.category.data.assets.imgs = newState.category.data.assets.imgs.filter((img, i) => i !== imageIndex);
          return newState;
        }
      }
      break;
    }

    case ADD_LEAD_TIMELINE: {
      const lead = { ...state.lead };
      const timelineEntry = {
        id: Math.random()
          .toString(36)
          .substr(2, 9),
        cdate: new Date().toUTCString(),
        ...action.payload,
      };
      lead.data.timeline = lead.data.timeline.slice();
      lead.data.timeline.splice(0, 0, timelineEntry);

      return { ...state, lead };
    }

    case ADD_ORDER_TIMELINE: {
      const order = { ...state.order };
      const timelineEntry = {
        id: Math.random()
          .toString(36)
          .substr(2, 9),
        cdate: new Date().toUTCString(),
        ...action.payload,
      };
      order.data.timeline = order.data.timeline.slice();
      order.data.timeline.splice(0, 0, timelineEntry);

      return { ...state, order };
    }

    case SOCKET_EMIT: {
      const { eventName /* , args */ } = action.payload;

      switch (eventName) {
        case 'videocall_cancelled':
        case 'videocall_rejected':
        case 'videocall_ended':
        case 'call_cancelled':
        case 'call_rejected':
        case 'call_ended': {
          return {
            ...state,
            kvsSettings: { ...standardState },
          };
        }
      }

      break;
    }

    case SOCKET_EVENT: {
      const { eventName /* , args */ } = action.payload;

      switch (eventName) {
        case 'videocall_cancelled':
        case 'videocall_rejected':
        case 'videocall_ended':
        case 'call_cancelled':
        case 'call_rejected':
        case 'call_ended': {
          return {
            ...state,
            kvsSettings: { ...standardState },
          };
        }
      }

      break;
    }

    case LEAD_MASTER_COPY_UPLOADED: {
      /**
       * Update Lead with a new URL of an uploaded print data:
       * Target: data.orders[0].items[0].masterCopies[ payload ]
       */
      const newUrl = action.payload;

      try {
        const { lead } = state;
        const { data } = lead;
        const { orders } = data;
        const [firstOrder] = orders;
        const { items } = firstOrder;
        const [firstItem] = items;

        const updatedFirstItem = { ...firstItem, master_copies: [newUrl] };
        const updatedItems = [updatedFirstItem, ...items.slice(1)];
        const updatedFirstOrder = { ...firstOrder, items: updatedItems };
        const updatedOrders = [updatedFirstOrder, ...orders.slice(1)];
        const updatedData = { ...data, orders: updatedOrders };
        const updatedLead = { ...lead, data: updatedData };

        return { ...state, lead: updatedLead };
      } catch (err) {
        // ignore
      }
    }
  }

  return state;
}
