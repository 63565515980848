import React from 'react';

import ModalMasterTermsOfService from 'components/modals/ModalMasterTermsOfService';
import ModalPrivacyPolicy from 'components/modals/ModalPrivacyPolicy';

import LegalTextHelper from 'components/layout/LegalTextHelper';

import AbstractCmsElement from './AbstractCmsElement';

class CmsElementSignupFormLegal extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      showTerms: false,
      showPrivacyPolicy: false,
    };
  }

  closeTerms = () => {
    this.setState({ showTerms: false });
  };

  closePrivacyPolicy = () => {
    this.setState({ showPrivacyPolicy: false });
  };

  linkClick = (e, type) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ [type]: true });
  };

  getSignupAgreementText = () => {
    const {
      intl: { messages },
    } = this.props;

    const text = this.getParamValue('signupAgreementText', messages.signup_agreement_text);
    const substitutes = {
      '{terms}': (
        <a href="#signupTerms" onClick={e => this.linkClick(e, 'showTerms')} className="text-master b-b">
          {messages.signup_agreement_text_terms}
        </a>
      ),
      '{data_use}': (
        <a href="#dataUse" onClick={e => this.linkClick(e, 'showPrivacyPolicy')} className="text-master b-b">
          {messages.signup_agreement_text_data_use}
        </a>
      ),
    };

    return <LegalTextHelper text={text} substitutes={substitutes} />;
  };

  render() {
    return (
      <div>
        {this.getSignupAgreementText()}

        <ModalMasterTermsOfService show={this.state.showTerms} close={this.closeTerms} />
        <ModalPrivacyPolicy show={this.state.showPrivacyPolicy} close={this.closePrivacyPolicy} />
      </div>
    );
  }
}
CmsElementSignupFormLegal.defaultProps = {
  index: '0',
};

export default CmsElementSignupFormLegal;
