import React from 'react';
import { components } from 'react-select';

class PageOption extends React.Component {
  render() {
    const { data } = this.props;
    return <components.Option {...this.props}>{data.name}</components.Option>;
  }
}

class PageValue extends React.Component {
  render() {
    const { data } = this.props;

    return <div className="Select-value">{data.name}</div>;
  }
}

const pageOptionFormatter = options => {
  return options.list.map(option => {
    return pageSingleOptionFormatter(option);
  });
};

const pageSingleOptionFormatter = option => {
  return { ...option, value: option.id, label: option.name };
};

const pageOptionFromEntity = page => {
  return !page
    ? null
    : pageSingleOptionFormatter({
        id: page.getId(),
        name: page.getName(),
      });
};

export { PageValue, PageOption, pageOptionFormatter, pageSingleOptionFormatter, pageOptionFromEntity };
