import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/pl';
import 'intl/locale-data/jsonp/de';
import 'intl/locale-data/jsonp/tr';
import 'intl/locale-data/jsonp/it';
import 'intl/locale-data/jsonp/es';
import 'intl/locale-data/jsonp/nl';
import 'intl/locale-data/jsonp/fr';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en'; // locale-data for en
import '@formatjs/intl-pluralrules/dist/locale-data/de'; // locale-data for de
import '@formatjs/intl-pluralrules/dist/locale-data/pl'; // locale-data for pl
import '@formatjs/intl-pluralrules/dist/locale-data/tr'; // locale-data for tr
import '@formatjs/intl-pluralrules/dist/locale-data/it'; // locale-data for it
import '@formatjs/intl-pluralrules/dist/locale-data/es'; // locale-data for es
import '@formatjs/intl-pluralrules/dist/locale-data/nl'; // locale-data for nl
import '@formatjs/intl-pluralrules/dist/locale-data/fr'; // locale-data for fr

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/pl';
import '@formatjs/intl-relativetimeformat/dist/locale-data/de';
import '@formatjs/intl-relativetimeformat/dist/locale-data/tr';
import '@formatjs/intl-relativetimeformat/dist/locale-data/it';
import '@formatjs/intl-relativetimeformat/dist/locale-data/es';
import '@formatjs/intl-relativetimeformat/dist/locale-data/nl';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';

import '@formatjs/intl-listformat/polyfill';
import '@formatjs/intl-listformat/locale-data/en';
import '@formatjs/intl-listformat/locale-data/pl';
import '@formatjs/intl-listformat/locale-data/de';
import '@formatjs/intl-listformat/locale-data/tr';
import '@formatjs/intl-listformat/locale-data/it';
import '@formatjs/intl-listformat/locale-data/es';
import '@formatjs/intl-listformat/locale-data/nl';
import '@formatjs/intl-listformat/locale-data/fr';

import 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/pl';
import 'moment/locale/de';
import 'moment/locale/tr';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/nl';
import 'moment/locale/fr';

// eslint-disable-next-line import/order
import { crashReporterMiddleware } from './errorhandler';

import axios from 'axios';

import { connectRouter, routerMiddleware } from 'connected-react-router';
import promise from 'redux-promise-middleware';
import { createLogger } from 'redux-logger';

import { getLogger, setupRedux } from '@chedri/base';

import { activeFormsMiddleware } from './reducers/activeForms';
import { checkoutMiddleware } from './reducers/checkout';

import { mayUseVideoCall, mayUseAudioCall } from './helpers/webrtccall';

import { initWebApp } from './sagas/appInitializeSaga';

import { rootClientReducer } from './reducers';

import { locale as en } from './intl/messages.en';
import { locale as de } from './intl/messages.de';
import { locale as pl } from './intl/messages.pl';
import { locale as tr } from './intl/messages.tr';
import { locale as it } from './intl/messages.it';
import { locale as es } from './intl/messages.es';
import { locale as nl } from './intl/messages.nl';
import { locale as fr } from './intl/messages.fr';
import globals from './globals';

// console.log('Loading configure store');

const logger = getLogger('configure-store');

const { locale } = globals;

export default function configureStore(history) {
  const middlewares = [
    routerMiddleware(history),
    promise(),
    crashReporterMiddleware,
    activeFormsMiddleware,
    checkoutMiddleware,
  ];
  if (
    (logger.rootLogger && logger.rootLogger.config.useReduxLogger) ||
    (!logger.rootLogger && logger.config.useReduxLogger)
  ) {
    middlewares.push(createLogger({ collapsed: true }));
  }

  // const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const sagas = [initWebApp];
  const reduc = { router: connectRouter(history), ...rootClientReducer };

  return setupRedux(middlewares, reduc, sagas, undefined, {
    isMobile: false,
    locale,
    messages: { en, de, pl, tr, it, es, nl, fr },
    http: axios,
    canUseAudioCall: mayUseAudioCall,
    canUseVideoCall: mayUseVideoCall,
    RTCPeerConnection:
      window.RTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.webkitRTCPeerConnection ||
      window.msRTCPeerConnection,
    mediaDevices: navigator.mediaDevices,
  });
}
