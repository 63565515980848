import React from 'react';
import { connect } from 'react-redux';
import { Badge } from 'react-bootstrap';
import { components } from 'react-select';
import { forEach as _forEach } from 'lodash';

import { getNameInCurrentLanguage } from 'helpers/intl';

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

class PipelineOptionWithProps extends React.Component {
  render() {
    const { data, appIntl } = this.props;
    return (
      <components.Option {...this.props}>
        {!!data.id && <Badge className="m-r-10 text-uppercase p-l-10 p-r-10">{appIntl.displayIntlForm}</Badge>}
        {getNameInCurrentLanguage(data.name, appIntl)}
      </components.Option>
    );
  }
}
const PipelineOption = connect(mapStateToProps)(PipelineOptionWithProps);

class PipelineValueWithProps extends React.Component {
  render() {
    const { data, appIntl } = this.props;

    return (
      <div className="Select-value">
        {!!data.id && <Badge className="m-r-10 text-uppercase p-l-10 p-r-10">{appIntl.displayIntlForm}</Badge>}
        {getNameInCurrentLanguage(data.name, appIntl)}
      </div>
    );
  }
}
const PipelineValue = connect(mapStateToProps)(PipelineValueWithProps);

const pipelineOptionFormatter = options => {
  return options.list.map(option => {
    return pipelineSingleOptionFormatter(option);
  });
};

const pipelineSingleOptionFormatter = pipeline => {
  let label = '';
  _forEach(pipeline.name, langName => {
    if (langName.name !== undefined) {
      label += langName.name + ' ';
    }
  });
  return { ...pipeline, value: pipeline.id, label };
};

const pipelineOptionFromEntity = pipeline => {
  return !pipeline
    ? null
    : pipelineSingleOptionFormatter({
        id: pipeline.getId(),
        name: pipeline.getName(),
      });
};

export {
  PipelineValue,
  PipelineOption,
  pipelineOptionFormatter,
  pipelineSingleOptionFormatter,
  pipelineOptionFromEntity,
};
