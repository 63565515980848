import React from 'react';
import Carousel from 'nuka-carousel';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';

import { elementSource, elementTarget } from './elementDragUtils';
import { dispatchResizeEvent } from 'helpers/window';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
export default class CmsElementCarousel extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      ...this.state,
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { children } = this.props;

    if (children === null || prevProps.children === null || children.length !== prevProps.children.length) {
      dispatchResizeEvent();
    }

    if (this.getPrevParamValue(prevProps, 'fullHeight') !== this.getParamValue('fullHeight')) {
      dispatchResizeEvent();
    }

    this.__componentDidUpdate(prevProps, prevState);
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  afterSlide = () => {
    dispatchResizeEvent();
  };

  render() {
    const { connectDropTarget, connectDragSource, connectDragPreview } = this.props;
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div id={this.getMainId()} class={'ms-carousel ' + shownClass + this.getCssClasses()} onClick={this.showSettings}>
        {this.getElementStyle()}

        {this.props.children ? (
          <Carousel afterSlide={this.afterSlide}>
            {this.props.children.map((child, i) => {
              let params = child.props.params.slice();
              params.push({ name: 'fullHeight', value: this.getParamValue('fullHeight', 'no') });

              return React.cloneElement(child, { params, key: i });
            })}
          </Carousel>
        ) : (
          <div class="p-t-15 p-b-15 text-center">
            <i class="fa fa-columns fa-4x" />
          </div>
        )}

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}
