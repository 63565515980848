import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import { Button } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import IntlDateRelative from '../../../components/layout/IntlDateRelative';
import IntlDate from '../../../components/layout/IntlDate';
import Loader from '../../../components/layout/Loader';

const momentWeekAgo = moment()
  .subtract(7, 'days')
  .startOf('day');

const ContextModal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('context-modal'));
};

const TimelineOverlay = ({
  timeline,
  openImageOverlay,
  undo,
  closeTimelineOverlay,
  pending,
  pendingTimelineR,
  getTimelineR,
  editionId,
  timelineStartR,
  showLoadMoreEntriesR,
}) => {
  let sortedTimeline;
  if (!pendingTimelineR && timeline !== null) {
    sortedTimeline = timeline.sort((a, b) => new Date(b.cdate) - new Date(a.cdate));
  }

  return (
    <ContextModal>
      <div id="contextModalWrap">
        <div id="contextModalStage">
          <h2>Timeline</h2>
          {!pendingTimelineR && timeline !== null ? (
            <div class="timelineOverlayContainer timeline-container left no-margin">
              {sortedTimeline.length === 0 ? <p>Dieses Werbemittel wurde noch nicht bearbeitet.</p> : ''}
              <section class="editorTimeline line-grey no-margin p-b-0">
                {sortedTimeline.map((event, index) => {
                  const eventDate = moment(event.cdate).isAfter(momentWeekAgo) ? (
                    <IntlDateRelative date={event.cdate} />
                  ) : (
                    <IntlDate date={event.cdate} />
                  );

                  return (
                    <div
                      className="timelineEvent"
                      style={{
                        justifyContent: index % 2 ? 'flex-start' : 'flex-end',
                      }}
                      key={event.id}
                    >
                      <div className="timeline-block" style={{ width: '50%' }}>
                        <div
                          className="timeline-content"
                          style={{ margin: index % 2 ? '0px 50px 0px 0px' : '0px 0px 0px 50px' }}
                        >
                          <div className="card social-card share full-width">
                            <div class="timelineCardHeader card-header clearfix">
                              <div class="user-pic">
                                {event.creator.profile.avatar?.file_name ? (
                                  <img
                                    alt={`${event.creator.profile.first_name} ${event.creator.profile.last_name}`}
                                    width="33"
                                    height="33"
                                    src={event.creator.profile.avatar}
                                  />
                                ) : (
                                  <i
                                    class="fal fa-fw fa-user-circle text-master-light"
                                    style={{ fontSize: '33px' }}
                                    title={`${event.creator.profile.first_name} ${event.creator.profile.last_name}`}
                                  />
                                )}
                              </div>

                              <h5>
                                <span class="inline lh-23">
                                  <i class="fa-fw fs-10 far fa-circle text-danger" />{' '}
                                  <span class="text-master">
                                    {event.creator.profile.first_name || event.creator.profile.last_name
                                      ? `${event.creator.profile.first_name} ${event.creator.profile.last_name}`
                                      : 'Creacheck'}
                                  </span>
                                </span>
                              </h5>
                            </div>
                            <div className="timelineImageWrapper">
                              {event.previews.map(image => (
                                <img
                                  className="timelineImage"
                                  src={image.key}
                                  alt={image.id}
                                  onClick={() => openImageOverlay(image)}
                                  key={image.id}
                                />
                              ))}
                            </div>
                            <div className="timelineEventContent">
                              <p dangerouslySetInnerHTML={{ __html: event.changes }} />
                              <div>
                                <Button
                                  bsStyle="danger"
                                  bsSize="small"
                                  onClick={() => undo(event.id)}
                                  disabled={pending}
                                >
                                  Undo
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="event-date">
                            <small className="fs-12 hint-text">{eventDate}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </section>
            </div>
          ) : (
            <Loader />
          )}
          {pending ? (
            <Loader />
          ) : (
            <>
              {showLoadMoreEntriesR ? (
                <Button
                  style={{ margin: '20px' }}
                  bsStyle="success"
                  bsSize="large"
                  onClick={() => getTimelineR(editionId, timelineStartR, 10)}
                >
                  Lade weitere Timelineeinträge
                </Button>
              ) : (
                <></>
              )}
              <Button
                style={{ position: 'relative', margin: '20px' }}
                bsStyle="warning"
                bsSize="large"
                onClick={closeTimelineOverlay}
              >
                Abbrechen
              </Button>
            </>
          )}
        </div>
      </div>
    </ContextModal>
  );
};

export default TimelineOverlay;
