import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import { formStatusDispatchHelper, formReset } from 'actions/FormStatusActions';

import DataId from 'components/DataId';

import FilledQuestionnaireForm from 'components/layout/questionnaire/FilledQuestionnaireForm';

import EntityStateUpdater from 'component/EntityStateUpdater';

// make sure each instance gets a new index
let cmsElementQuestionnaireIndex = 1;
class CmsElementQuestionnaireForm extends EntityStateUpdater {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      id: null,
    };
  }

  componentDidMount() {
    this.setState({ id: cmsElementQuestionnaireIndex++ });
  }

  componentDidUpdate = prevProps => {
    const { formStatus, leadSource: source } = this.props;

    if (prevProps.formStatus.pending && !formStatus.pending && !formStatus.hasError) {
      // send the information to Google
      ReactGA.event({
        category: window.location.href,
        action: 'Form_Button_Click',
        label: source || 'questionnaireForm',
        value: 1,
      });

      // send the information to Facebook
      ReactPixel.trackCustom('Form_Button_Click', {
        sourceURL: window.location.href,
        formName: source || 'questionnaireForm',
      });
    }
  };

  isPending = (props = null) => {
    const { data } = props || this.props;
    const { id } = this.state;

    return data[id] !== undefined ? data[id].pending : true;
  };

  onSubmit = formData => {
    const { leadSource: source } = this.props;

    const data = { questionnaire: { id: this.props.questionnaireId }, source, ...formData };
    if (this.props.pipelineId) {
      data.pipeline = { id: this.props.pipelineId };
    }
    this.props.onSubmit(data);
  };

  render() {
    const {
      data,
      formStatus,
      questionnaireId,
      mainColorClass,
      successMessage: propsSuccessMessage,
      buttonTitle,
    } = this.props;
    const { id } = this.state;

    const successMessage = !!propsSuccessMessage && '<p class="' + mainColorClass + '">' + propsSuccessMessage + '</p>';

    return (
      <>
        {id && <DataId dataIdName="questionnaire" dataIdId={id} url={`/api/questionnaires/${questionnaireId}.json`} />}

        {!this.isPending() && (
          <FilledQuestionnaireForm
            questionnaireData={data[id].data}
            formStatus={formStatus}
            mainColorClass={mainColorClass}
            successMessage={successMessage}
            onSubmit={this.onSubmit}
            buttonTitle={buttonTitle}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    formStatus: state.formStatus.cmsQuestionnaireForm,
    data: state.dataId.questionnaire,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSubmit: args => {
      formStatusDispatchHelper('cmsQuestionnaireForm', '/api/cms/leadQuestionnaire.json', args, 'post', dispatch);
    },
    resetForm: () => {
      formReset('cmsQuestionnaireForm');
    },
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CmsElementQuestionnaireForm)
);
