import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Row, Col, Clearfix } from 'react-bootstrap';

class ItemDetails extends React.Component {
  doNothing = () => {};

  generateText = rows => {
    const res = [];
    let width = 7;
    for (let i = 0; i < rows; i++) {
      width = i + 1 === rows ? 30 : 85 + ((width * 3 + 8) % 10);

      res.push(
        <span class="ms-wireframe" style={{ width: '100%' }}>
          <span class="block bg-master-light lh-14" style={{ width: width + '%' }}>
            {'\u00A0'}
          </span>
        </span>
      );
    }

    return res;
  };

  getText = (width, fontSize = null) => {
    const fontSizeClass = fontSize ? `fs-${fontSize}` : '';

    return (
      <span class={`inline bg-master-light ${fontSizeClass}`} style={{ width: width + 'px' }}>
        {/* go back link */}
        {'\u00A0'}
      </span>
    );
  };

  render() {
    const { layout } = this.props;

    const highlights = [0, 1, 2, 3, 4, 5, 6, 7];

    const icon = <i class="fas fa-circle text-master-lighter" />;

    return (
      <div class="item-details">
        <Grid>
          <Row className="p-t-10 p-b-20 item-details-header-nav">
            <Col xs={12} className="fs-18">
              <div className="pull-left">
                {icon} {this.getText(40)}
              </div>
              <div className="pull-right">
                {this.getText(70)} {icon}
              </div>
            </Col>
          </Row>
        </Grid>

        <Grid>
          <Row>
            <Col xs={12}>
              <Clearfix>
                <div className="no-overflow b-rad-lg bg-master-lighter" style={{ paddingTop: '28%' }} />
              </Clearfix>
            </Col>
          </Row>
        </Grid>

        <Grid className="m-t-40 m-b-40">
          <Row>
            <Col xs={12} md={5}>
              <Clearfix>
                <div class="lh-16 v-align-middle m-t-20">
                  {highlights.map(highlight => {
                    const res = [
                      <div class="inline text-nowrap m-b-10 col-xs-6 no-padding fs-12 lh-12" key={highlight}>
                        {icon} {this.getText(75)}
                      </div>,
                    ];

                    if ((highlight + 1) % 2 === 0) {
                      res.push(<Clearfix key={'c' + highlight} />);
                    }

                    return res;
                  })}
                </div>
              </Clearfix>

              <hr />

              <div class="item-description-container">
                {/* description */}
                {this.generateText(35)}
              </div>

              <hr />
            </Col>
            <Col xs={12} md={7}>
              <div className={'sm-m-b-25 ' + (layout.breakpointIndex > 1 ? 'p-l-25 ' : '')}>
                <Clearfix className="m-b-25 item-details-social">
                  <div className="pull-left">
                    {icon} {this.getText(45)}
                  </div>
                  <div className="pull-right">
                    {icon} {this.getText(70)}
                  </div>
                </Clearfix>
              </div>

              <div className="no-overflow b-rad-lg shadow-1 item-details-price-box p-t-15 p-l-25 p-r-25 p-b-25">
                <Row>
                  <Col xs={6}>
                    <h2>
                      {this.getText(70)} {this.getText(60)}
                    </h2>
                    <h5 className="fs-14 lh-14">
                      {this.getText(210)} {this.getText(140)}
                    </h5>
                    <h6 className="fs-12">
                      {this.getText(70)} {this.getText(80)}
                    </h6>

                    <div className="m-t-25 m-b-25">
                      <span className="block fs-18">
                        {icon} {this.getText(30)} {this.getText(70)}
                      </span>

                      <span className="block fs-18 m-t-10">
                        {icon} {this.getText(100)}
                      </span>
                    </div>

                    <div className="overflow-ellipsis semi-bold">{this.getText(70)}</div>
                    <div className="overflow-ellipsis lh-16">
                      {this.getText(70)} {this.getText(20)}
                      <br />
                      {this.getText(40)} {this.getText(120)}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <h2 className="text-black bold">
                      <span className="fs-12">{this.getText(70)}</span>
                      <br />
                      {this.getText(200)}
                    </h2>

                    <div className="hint-text m-b-15">{this.getText(130)}</div>

                    <h6 className="fs-12">
                      {this.getText(40)} {this.getText(20)}% {this.getText(60)}
                      <br />
                      {this.getText(130)}
                    </h6>
                  </Col>

                  <Col xs={12} className="m-t-30">
                    <button class="p-t-10 p-b-10 btn-block no-border bg-master-light shadow-1" type="button">
                      &nbsp;
                    </button>
                  </Col>

                  <Col xs={12}>
                    <hr className="m-t-40 m-b-30" />
                  </Col>

                  <Col xs={4}>
                    <i class="fas fa-circle text-master-lighter fa-4x m-b-10" />
                    <br />
                    {this.getText(100)}
                  </Col>
                  <Col xs={4}>
                    <i class="fas fa-circle text-master-lighter fa-4x m-b-10" />
                    <br />
                    {this.getText(100)}
                  </Col>
                  <Col xs={4}>
                    <i class="fas fa-circle text-master-lighter fa-4x m-b-10" />
                    <br />
                    {this.getText(100)}
                  </Col>
                  <Clearfix />
                </Row>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
ItemDetails.defaultProps = {
  itemSlug: null,
};

const mapStateToProps = (state, ownProps) => {
  let map = {
    layout: state.layout,
    data: null,
  };

  if (ownProps.itemSlug) {
    // 1. check if we have some data in the summaryList
    // state.list.summary
    // 2. check if there is some data in the dataId
  }

  return map;
};

export default injectIntl(withRouter(connect(mapStateToProps)(ItemDetails)));
