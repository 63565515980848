import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { authModule, intlModule, communicationModule } from '@chedri/base';

import Avatar from 'components/Avatar';
import IconLink from 'components/IconLink';
import Panel from 'components/panel/Panel';
import { getChatterUserAvatarInfos } from 'helpers/clients';

import ChatRequestCredentialsPanel from './ChatRequestCredentialsPanel';
import Loader from '../layout/Loader';

function ChatWelcomePanel({ agent, onClose, onStartChat }) {
  const { first_name: firstName = 'N', last_name: lastName = 'N', avatarThumbLink: userThumbLink, avatar, name } =
    agent || {};
  const { avatarThumbLink, avatarTextColor, avatarColor } = getChatterUserAvatarInfos({
    name: name || firstName + lastName,
    avatar: typeof avatar === 'string' ? avatar : userThumbLink,
  });

  const messages = useSelector(intlModule.selectors.getMessages);
  const isAuthenticated = useSelector(authModule.selectors.isAuthenticated);
  const isConnected = useSelector(communicationModule.selectors.socket.isConnected);
  const isSocketConnecting = useSelector(communicationModule.selectors.socket.isConnecting);

  const [requestCredentials, setRequestCredentials] = useState(false);

  const initiateConversation = useCallback(
    e => {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      if (isAuthenticated) {
        onStartChat();
      } else {
        setRequestCredentials(true);
      }
    },
    [isAuthenticated, isConnected, setRequestCredentials]
  );

  return (
    <>
      {!requestCredentials && (
        <Panel className="chatter-teaser customer-chat">
          <Panel.Header
            rightAction={
              <IconLink
                name="fa-times-circle"
                solid
                onClick={onClose}
                linkClassName="link"
                iconClassName="fs-18 v-align-middle"
              />
            }
          >
            <div className="welcome">
              <h2>{messages.hello}</h2>
              <h3>{messages.we_are_here_for_you}</h3>
            </div>
          </Panel.Header>
          <Panel.Body>
            <div style={{ height: '47px', marginBottom: '16px' }}>
              <div className="pull-left p-r-10" style={{ width: '47px' }}>
                <Avatar
                  firstName={firstName}
                  lastName={lastName}
                  size={40}
                  textColor={avatarTextColor}
                  color={avatarColor}
                  thumbLink={avatarThumbLink}
                />
              </div>
              <div className="pull-right" style={{ width: 'calc(100% - 51px)' }}>
                <FormattedMessage id="welcome_customer" values={{ firstName, lastName }} />
              </div>
            </div>
            <button
              type="button"
              disabled={isSocketConnecting}
              className="btn btn-lg btn-primary btn-block"
              onClick={initiateConversation}
            >
              {isSocketConnecting ? <Loader small /> : messages.start_conversation}
            </button>
          </Panel.Body>
        </Panel>
      )}
      {requestCredentials && !isConnected && (
        <ChatRequestCredentialsPanel onStartChat={onStartChat} onClose={onClose} />
      )}
    </>
  );
}
ChatWelcomePanel.defaultProps = {
  agent: null,
  onClose: () => undefined,
  onStartChat: () => undefined,
};
ChatWelcomePanel.propTypes = {
  agent: PropTypes.object,
  onClose: PropTypes.func,
  onStartChat: PropTypes.func,
};

export default ChatWelcomePanel;
