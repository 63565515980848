export const createObjectURL = file => {
  if (window.webkitURL) {
    return window.webkitURL.createObjectURL(file);
  }

  if (window.URL && window.URL.createObjectURL) {
    return window.URL.createObjectURL(file);
  }

  return null;
};
