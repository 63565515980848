import axios, { CancelToken } from 'axios';

import { toQueryString } from 'helpers/http';

export const COUNT_PENDING = 'COUNT_PENDING';
export const COUNT_SUBMIT = 'COUNT_SUBMIT';
export const COUNT_FULFILLED = 'COUNT_FULFILLED';
export const COUNT_REJECTED = 'COUNT_REJECTED';
export const COUNT_RETRY = 'COUNT_RETRY';

export function countPending(countName, data) {
  return {
    type: COUNT_PENDING,
    payload: { countName, ...data },
  };
}

export function countSend(url, data, finishedCallback, errorCallback, cancelToken, method = 'get') {
  let fullUrl = url;
  if (fullUrl.indexOf('?') === -1) {
    fullUrl += '?';
  }

  if (method === 'get' && data !== undefined && data) {
    fullUrl += toQueryString(data) + '&';
  }
  fullUrl += '_locale=' + globalLocale;

  return {
    type: COUNT_SUBMIT,
    payload: axios({
      method,
      url: fullUrl,
      data: method !== 'get' ? { ...data } : null,
      cancelToken,
    })
      .then(finishedCallback)
      .catch(errorCallback),
  };
}

export function countFulfilled(countName, response) {
  return {
    type: COUNT_FULFILLED,
    payload: {
      countName,
      response,
    },
  };
}

export function countRejected(countName, response) {
  return {
    type: COUNT_REJECTED,
    payload: {
      countName,
      response: response.response,
    },
  };
}

export const countRetryCount = (countName, retryCount) => {
  return {
    type: COUNT_RETRY,
    payload: {
      countName,
      retryCount,
    },
  };
};

export const countDispatchHelper = (countName, url, data, method, dispatch) => {
  let retryAttempts = 0;
  const retryAttemptsMax = 10;

  const source = new CancelToken.source();

  // add the request to queue
  dispatch(countPending(countName, data));

  // finished callback will trigger an event
  const finishedCallback = response => {
    return dispatch(countFulfilled(countName, response));
  };
  // error callback will trigger an event
  const errorCallback = error => {
    if (!axios.isCancel(error)) {
      if ((!error.response || !error.response.status) && retryAttempts < retryAttemptsMax) {
        retryAttempts++;

        dispatch(countRetryCount(countName, retryAttempts));
        setTimeout(() => {
          dispatch(countSend(url, data, finishedCallback, errorCallback, source.token));
        }, retryAttempts * retryAttempts * 1000);
      } else {
        return dispatch(countRejected(countName, error));
      }
    }
  };
  // dispatch the request
  dispatch(countSend(url, data, finishedCallback, errorCallback, source.token));

  return source;
};
