import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Grid, Row, Col } from 'react-bootstrap';

import DocMeta from 'components/DocMeta';
import List from 'components/List';

import Breadcrumb from 'components/layout/Breadcrumb';
import Loader from 'components/layout/Loader';

class ApiDoc extends React.Component {
  render() {
    const { intl, appIntl, list } = this.props;
    const messages = intl.messages;

    const tags = [
      //      {name: 'title', content: messages.meta.api_doc.title},
      //      {name: 'description', content: messages.meta.api_doc.description},
      //      {itemProp: 'name', content: messages.meta.api_doc.name},
      //      {itemProp: 'description', content: messages.meta.api_doc.description},
      //      {itemProp: 'image', content: "http://www.example.com/image.jpg"}
    ];

    return (
      <DocMeta tags={tags}>
        <div class="content">
          <List listName="apiDocGroups" url="/api/documentation/groups.json" list={list} noRender />

          {!list.pending ? (
            <div class="padding-25 sm-padding-10">
              <Breadcrumb
                crumbs={[{ link: appIntl.urlPrefix, name: 'Creacheck' }, { link: null, name: 'Documentation' }]}
              />

              <Grid fluid>
                <Row>
                  <Col md={3} class="bg-master-lighter p-b-25">
                    <h1>Groups</h1>

                    {list.data.list.map((groupId, i) => {
                      return (
                        <div key={i} class="m-b-5">
                          <Link to={appIntl.urlPrefix + 'docs/' + groupId}>{groupId}</Link>
                        </div>
                      );
                    })}
                  </Col>
                  <Col md={9}>
                    <h1>API docs</h1>
                  </Col>
                </Row>
              </Grid>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </DocMeta>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    appIntl: state.intl,
    list: state.list.apiDocGroups,
  };
};

export default injectIntl(connect(mapStateToProps)(ApiDoc));
