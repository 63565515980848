import React from 'react';
import { injectIntl } from 'react-intl';

class SummaryListItemInfos extends React.Component {
  render() {
    const {
      intl: { messages },
      item,
    } = this.props;

    return (
      <div>
        {item.variants.length ? (
          <span>
            <i class="fa fa-info-circle" /> {messages.shopItem.variants_possible}
          </span>
        ) : (
          '\u00A0'
        )}
      </div>
    );
  }
}

export default injectIntl(SummaryListItemInfos);
