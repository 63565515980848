import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { injectIntl } from 'react-intl';

import EntityStateUpdater from 'component/EntityStateUpdater';

import Loader from 'components/layout/Loader';

import Data from 'components/Data';
import Entity from 'components/Entity';

import FormInput from 'components/forms/FormInput';
import FormTextarea from 'components/forms/FormTextarea';
import FormErrors from 'components/forms/FormErrors';

import { entityMetaMapping } from 'js/constants';

class PriceNegotiationForm extends EntityStateUpdater {
  constructor(props) {
    super(props);

    this.state = {
      entityLoaded: false,
      entityChanged: false,
      showAddMessage: false,
    };

    this.entity = new Entity();

    // if we get data from the outside, we can pass it on to our data entity
    const data = props.data || {};
    this.entity.setData(data);

    if (
      props.meta[entityMetaMapping.priceNegotiation] !== undefined &&
      !props.meta[entityMetaMapping.priceNegotiation].pending
    ) {
      this.entity.setMeta(props.meta, entityMetaMapping.priceNegotiation);

      this.state = {
        ...this.state,
        entityLoaded: true,
        entity: this.entity,
      };
    }
  }

  componentDidUpdate = () => {
    const { meta } = this.props;

    if (
      meta[entityMetaMapping.priceNegotiation] !== undefined &&
      !meta[entityMetaMapping.priceNegotiation].pending &&
      !this.entity.getMetaLoaded()
    ) {
      this.entity.setMeta(meta, entityMetaMapping.priceNegotiation);
      if (this.entity.getDataLoaded()) {
        this.setState({ entityLoaded: true, entity: this.entity });
      }
    }
  };

  showAddMessage = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showAddMessage: true });
  };

  formSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState(
      prevState => ({ entity: prevState.entity.validate() }),
      () => {
        if (this.state.entity.isValid) {
          this.props.onSubmit(this.state.entity.processedData);
        }
      }
    );
  };

  render() {
    const {
      webApp,
      intl: { messages },
      type,
      formStatus,
    } = this.props;
    const { entity, entityLoaded, showAddMessage } = this.state;

    const disabled = formStatus.pending;

    return (
      <div>
        <Data dataName="priceNegotiation" /> {/* fetch meta data */}
        <FormErrors formStatus={formStatus} />
        {entityLoaded ? (
          <form method="post" onSubmit={this.formSubmit}>
            <div class="panel panel-transparent">
              <div class="form-group-attached">
                <FormInput
                  prefixAddon={webApp.currencySymbol}
                  type="number"
                  label={type === 'new' ? messages.your_initial_offer : messages.your_new_offer}
                  name="currentOffer"
                  value={entity.getCurrentOffer()}
                  meta={entity.getCurrentOfferMetaInformation()}
                  validation={entity.getCurrentOfferValidationResult()}
                  onChange={this.updateEntity}
                  disabled={disabled}
                />

                {showAddMessage ? (
                  <FormTextarea
                    label={messages.message}
                    name="currentMessage"
                    meta={entity.getCurrentMessageMetaInformation()}
                    validation={entity.getCurrentMessageValidationResult()}
                    onChange={this.updateEntity}
                    disabled={disabled}
                  />
                ) : (
                  <div class="form-group form-group-default p-t-10 p-b-10">
                    <a href="#addMessage" class="text-master" onClick={this.showAddMessage}>
                      {messages.add_message} <i class="fal fa-chevron-right m-l-10" />
                    </a>
                  </div>
                )}
              </div>

              <div class="text-right m-t-15">
                <Button
                  bsSize="lg"
                  disabled={formStatus.pending}
                  type="submit"
                  bsStyle={this.state.entityChanged ? 'primary' : 'default'}
                >
                  <i class={'fa-fw ' + (formStatus.pending ? ' fa fa-circle-notch fa-spin' : 'fal fa-save')} />{' '}
                  {messages.send}
                </Button>
              </div>
            </div>
          </form>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    webApp: state.webApp,
    meta: state.entityMeta,
    user: state.login.user,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(PriceNegotiationForm)));
