import React from 'react';
import { useSelector } from 'react-redux';

import { notificationModule } from '@chedri/base';
import NotificationMessage from './NotificationMessage';

function Notifications() {
  const messages = useSelector(notificationModule.selectors.getMessages);

  return (
    <div class="pgn-wrapper" data-position="bottom-left">
      {messages.map(message => (
        <NotificationMessage key={message.key} message={message} />
      ))}
    </div>
  );
}

export default Notifications;
