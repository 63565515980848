import {
  TRANSLATE_PAGE_PENDING,
  TRANSLATE_PAGE_FULFILLED,
  TRANSLATE_CMS_TEMPLATE_PENDING,
  TRANSLATE_CMS_TEMPLATE_FULFILLED,
  UPDATE_SETTINGS_POSITION,
} from 'actions/CmsActions';

const initialState = {
  translatePage: {
    pending: false,
    data: {},
  },
  translateCmsTemplate: {
    pending: false,
    data: {},
  },
  settingsPosition: {
    x: 0,
    y: 0,
  },
};

export default function cms(state = initialState, action) {
  switch (action.type) {
    case TRANSLATE_PAGE_PENDING: {
      return { ...state, translatePage: { ...state.translatePage, pending: true, data: {} } };
    }

    case TRANSLATE_PAGE_FULFILLED: {
      return { ...state, translatePage: { ...state.translatePage, pending: false, data: action.payload.data } };
    }

    case TRANSLATE_CMS_TEMPLATE_PENDING: {
      return { ...state, translateCmsTemplate: { ...state.translateCmsTemplate, pending: true, data: {} } };
    }

    case TRANSLATE_CMS_TEMPLATE_FULFILLED: {
      return {
        ...state,
        translateCmsTemplate: { ...state.translateCmsTemplate, pending: false, data: action.payload.data },
      };
    }

    case UPDATE_SETTINGS_POSITION: {
      return { ...state, settingsPosition: { x: action.payload.x, y: action.payload.y } };
    }
  }

  return state;
}
