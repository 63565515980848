import { COOKIE_PENDING, COOKIE_FULFILLED, COOKIE_REJECTED } from 'actions/CookiesActions';

const initialState = {
  pending: false,
  boxClosed: typeof chedriCookiesBoxClosed !== 'undefined' && chedriCookiesBoxClosed ? true : false,
  performance: typeof chedriCookiesPerformance !== 'undefined' && chedriCookiesPerformance ? true : false,
  targeting: typeof chedriCookiesTargeting !== 'undefined' && chedriCookiesTargeting ? true : false,
};

export default function cookies(state = initialState, action) {
  switch (action.type) {
    case COOKIE_PENDING: {
      return { ...state, pending: true };
    }

    case COOKIE_FULFILLED: {
      let newState = { ...state, pending: false };

      if (action.payload.data.chedriCookiesBoxClosed !== undefined) {
        newState.boxClosed = action.payload.data.chedriCookiesBoxClosed === '1' ? true : false;
      }

      if (action.payload.data.chedriCookiesPerformance !== undefined) {
        newState.performance = action.payload.data.chedriCookiesPerformance === '1' ? true : false;
      }

      if (action.payload.data.chedriCookiesTargeting !== undefined) {
        newState.targeting = action.payload.data.chedriCookiesTargeting === '1' ? true : false;
      }

      return newState;
    }

    case COOKIE_REJECTED: {
      return { ...state, pending: false };
    }
  }

  return state;
}
