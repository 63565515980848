import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Clearfix, Col, Form, Button, Overlay, Alert } from 'react-bootstrap';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import moment from 'moment';
import 'moment-timezone';
import { isEmpty as _isEmpty, values as _values, findIndex as _findIndex } from 'lodash';

import { dataDispatchHelper } from 'actions/DataActions';
import { getCarRentalSearchPath, getTimezoneFromState } from 'helpers/intl';
import { parseQs } from 'helpers/http';
import { getCarRentalDaysCount, checkOpeningHours } from 'helpers/items';
import { getPluginSettingValue } from 'helpers/whiteLabel';
import { findFirstDateTimeInWeekRange } from 'helpers/dateTime';

import FormTimePicker from 'components/forms/FormTimePicker';
import DatePicker from 'components/forms/DatePicker';

import Count from 'components/Count';

import { AUTOMOTIVE_RENT_PLUGIN } from 'js/constants';

import AbstractCmsElement from '../AbstractCmsElement';
import CmsElementCommerceCarRentLocations from '../CmsElementCommerceCarRentLocations';

class SearchVersionLocation extends AbstractCmsElement {
  static SEARCH_LOCATION_ID_KEY = 'CAR_RENT_LOCATION_ID';

  constructor(props) {
    super(props);

    this.allowStateChange = true;

    const location = localStorage.getItem('location') || null;
    const locationAddress = localStorage.getItem('locationAddress') || '';
    const query = parseQs(props.location.search);
    const selectedLocation = !_isEmpty(props.locationData.data) && props.locationData.data;

    let queryDateFrom = null;
    if (query.dateFrom) {
      queryDateFrom = moment.tz(query.dateFrom, 'UTC').toISOString();
    }

    let queryDateTo = null;
    if (query.dateTo) {
      queryDateTo = moment.tz(query.dateTo, 'UTC').toISOString();
    }

    const dateFrom = queryDateFrom || this.getDefaultFromDateTime(selectedLocation).toISOString();
    const dateTo = queryDateTo || this.getDefaultToDateTime(selectedLocation, dateFrom).toISOString();

    this.state = {
      dateFrom,
      dateTo,

      coordinates: location,
      address: locationAddress,
      addressInputFocused: false,
      addressInputValue: locationAddress,
      locationOverlayShow: false,

      posDetectPending: false,
      posDetectError: false,
      selectedLocation,

      displayLocationSelectRequired: false,
    };

    moment.locale(props.intl.locale);
    this.inputRef = React.createRef();
  }

  componentDidMount = () => {
    const { selectedLocation } = this.state;
    const { locationData } = this.props;

    if (!selectedLocation || locationData.pending) {
      this.fetchInitLocation();
    }

    this.setCoordsFromBrowserGeolocation();

    this.__componentDidMount();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { locationData, location, isScriptLoadSucceed, onDateRangeChange } = this.props;
    const { addressInputValue, dateFrom: stateDateFrom, dateTo: stateDateTo } = this.state;
    const query = parseQs(location.search);

    this.__componentDidUpdate(prevProps, prevState);

    if (_isEmpty(prevProps.locationData.data) && !_isEmpty(locationData.data)) {
      const dateFrom = query.dateFrom || this.getDefaultFromDateTime(locationData.data).toISOString();
      const dateTo = query.dateTo || this.getDefaultToDateTime(locationData.data, dateFrom).toISOString();

      this.setState({
        selectedLocation: locationData.data,
        dateFrom,
        dateTo,
      });
    }

    if (isScriptLoadSucceed && !prevProps.isScriptLoaded && addressInputValue) {
      this.addressChange(addressInputValue);
    }

    if (onDateRangeChange && (stateDateFrom !== prevState.dateFrom || stateDateTo !== prevState.dateTo)) {
      onDateRangeChange(stateDateFrom, stateDateTo);
    }
  };

  getLocationRentalWorkingHours = (location, isoWeekday) => {
    if (!location) {
      return [{ start: 0, end: 24 }];
    }
    return location.working_week_rental_point['iso_day' + isoWeekday] || [];
  };

  getDefaultFromDateTime = location => {
    const { timezone } = this.props;
    const startDate = this.addWorkingDays(location, moment().tz('UTC'), 2);

    startDate
      .tz(timezone)
      .set({ hour: 12, minute: 0, second: 0 })
      .tz('UTC');

    if (!location) {
      return startDate;
    }

    return findFirstDateTimeInWeekRange(startDate, location.working_week_rental_point);
  };

  getDefaultToDateTime = (location, dateFrom) => {
    const { timezone } = this.props;
    const toDate = this.addWorkingDays(location, moment(dateFrom), 1);

    toDate
      .tz(timezone)
      .set({ hour: 9, minute: 0, second: 0 })
      .tz('UTC');

    if (!location) {
      return toDate;
    }

    return findFirstDateTimeInWeekRange(toDate, location.working_week_rental_point);
  };

  addWorkingDays = (location, datetime, days, iteration = 0) => {
    const newDateTime = datetime.clone().add(days, 'days');

    const workingHours = this.getLocationRentalWorkingHours(location, newDateTime.isoWeekday());
    if (workingHours.length || iteration > 7) {
      return newDateTime;
    }

    return this.addWorkingDays(location, newDateTime, 1, iteration + 1);
  };

  limitWorkingHoursToMaxRentDays = (date, range) => {
    const maxDate = this.getMaxDateToLimit();

    if (_isEmpty(range) || !moment(date).isSame(maxDate, 'day')) {
      return range;
    }

    const rangeCopy = range.slice();

    rangeCopy[range.length - 1] = {
      start: rangeCopy[range.length - 1].start,
      end: Math.min(
        rangeCopy[range.length - 1].end,
        maxDate.utc().hours() + Math.round((maxDate.utc().minutes() / 60) * 100) / 100 + 0.25
      ),
    };

    return rangeCopy;
  };

  setCoordsFromBrowserGeolocation = () => {
    const { address } = this.state;

    if (address || !navigator.geolocation) {
      return;
    }

    navigator.geolocation.getCurrentPosition(position => {
      this.setState({
        coordinates: position.coords.latitude + ',' + position.coords.longitude,
        browserCoords: position.coords.latitude + ',' + position.coords.longitude,
      });
    });
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return this.__shouldComponentUpdate(nextProps, nextState);
  };

  fetchInitLocation = () => {
    const { locationId, fetchSearchLocation } = this.props;
    const searchLocationId = locationId || localStorage.getItem(SearchVersionLocation.SEARCH_LOCATION_ID_KEY);

    if (searchLocationId) {
      fetchSearchLocation(searchLocationId);
    }
  };

  addressChange = addressInputValue => {
    clearTimeout(this.typeTimeout);
    this.setState({ addressInputValue });

    this.typeTimeout = setTimeout(() => {
      if (!addressInputValue) {
        this.setState({
          address: '',
          coordinates: this.state.browserCoords,
        });
        localStorage.setItem('locationAddress', '');
      } else {
        this.setState({ posDetectPending: true, posDetectError: false }, () => {
          geocodeByAddress(addressInputValue)
            .then(results => {
              getLatLng(results[0]).then(latLng => this.setPosDetectResult(results[0], latLng));
            })
            .catch(() => {
              this.setState({ posDetectPending: false, posDetectError: true });
            });
        });
      }
    }, 250);
  };

  onAddressInputChange = addressInputValue => {
    const { locationOverlayShow } = this.state;

    if (!locationOverlayShow) {
      this.setState({ locationOverlayShow: true });
    }

    this.addressChange(addressInputValue);
  };

  // get the google geocoding result and update the state
  setPosDetectResult = (result, latLng) => {
    this.setState({
      address: result.formatted_address,
      coordinates: latLng.lat + ',' + latLng.lng,
      posDetectPending: false,
      posDetectError: false,
    });
    localStorage.setItem('locationAddress', result.formatted_address);
  };

  setLocation = selectedLocation => {
    const { address } = this.state;

    this.setState({
      address: address || selectedLocation.city,
      selectedLocation,
      locationOverlayShow: false,
      missingLocationError: false,
    });

    if (selectedLocation.dateFrom) {
      this.setState({ dateFrom: selectedLocation.dateFrom });
    }
    if (selectedLocation.dateTo) {
      this.setState({ dateTo: selectedLocation.dateTo });
    }

    localStorage.setItem('locationAddress', address || selectedLocation.city);
    localStorage.setItem(SearchVersionLocation.SEARCH_LOCATION_ID_KEY, selectedLocation.id);
  };

  getMaxDateFromLimit = () => {
    const { projectConfig } = this.props;

    const maxBookingFuture = parseInt(
      getPluginSettingValue(AUTOMOTIVE_RENT_PLUGIN, projectConfig.data, 'maxBookingFuture', 90),
      10
    );

    return moment().add(maxBookingFuture - 1, 'days');
  };

  getMaxDateToLimit = dateFrom => {
    const { projectConfig } = this.props;
    dateFrom = dateFrom || this.state.dateFrom;

    const maxRentDays = parseInt(
      getPluginSettingValue(AUTOMOTIVE_RENT_PLUGIN, projectConfig.data, 'maxRentDays', 14),
      10
    );

    return moment(dateFrom).add(maxRentDays, 'days');
  };

  getMinDateToLimit = () => {
    const { dateFrom } = this.state;
    const momentDateFrom = moment(dateFrom);

    return momentDateFrom.add(1, 'days').startOf('day');
  };

  isDayBlocked = date => {
    const { selectedLocation } = this.state;
    const workingHours = this.getLocationRentalWorkingHours(selectedLocation, date.isoWeekday());

    return !workingHours.length;
  };

  updateDateFrom = (name, dateFrom) => {
    const momentDateFrom = moment.tz(dateFrom, 'UTC');
    const momentDateTo = moment.tz(this.state.dateTo, 'UTC');
    const daysCount = getCarRentalDaysCount(momentDateFrom, momentDateTo);
    const maxDateToLimit = this.getMaxDateToLimit(dateFrom);

    if (momentDateFrom.isAfter(momentDateTo) || daysCount < 1) {
      const { selectedLocation } = this.state;
      const dateTo = dateFrom.clone();

      dateTo.set({
        hour: momentDateTo.get('hour'),
        minute: momentDateTo.get('minute'),
        second: momentDateTo.get('second'),
      });

      this.setState({
        dateFrom: moment(dateFrom).toISOString(),
        dateTo: this.addWorkingDays(selectedLocation, dateTo, 1).toISOString(),
      });
    } else if (momentDateTo.isAfter(maxDateToLimit)) {
      this.setState({ dateFrom: moment(dateFrom).toISOString(), dateTo: maxDateToLimit.toISOString() });
    } else {
      this.setState({ dateFrom: moment(dateFrom).toISOString() });
    }
  };

  updateDateTo = (name, dateTo) => {
    const { dateFrom } = this.state;
    const maxDateToLimit = this.getMaxDateToLimit(dateFrom);

    if (moment(dateTo).isAfter(maxDateToLimit)) {
      this.setState({ dateTo: maxDateToLimit.toISOString() });
    } else if (moment(dateTo).isBefore(moment(dateFrom))) {
      this.setState({
        dateTo: moment
          .tz(dateFrom, 'UTC')
          .add(1, 'days')
          .toISOString(),
      });
    } else {
      this.setState({ dateTo: moment(dateTo).toISOString() });
    }
  };

  validate = () => {
    const { selectedLocation, dateFrom, dateTo } = this.state;
    const missingLocationError = !selectedLocation || !selectedLocation.id;

    const errors = {
      missingLocationError,
      pickupTimeError:
        !missingLocationError &&
        !checkOpeningHours(selectedLocation.working_week_rental_point, moment(dateFrom).tz('UTC')),
      dropoffTimeError:
        !missingLocationError &&
        !checkOpeningHours(selectedLocation.working_week_rental_point, moment(dateTo).tz('UTC')),
      daysCountError: !missingLocationError && moment(dateTo).isAfter(this.getMaxDateToLimit()),
    };

    this.setState(errors);

    return _values(errors).every(hasError => !hasError);
  };

  hasErrors = () => {
    const { pickupTimeError, dropoffTimeError, daysCountError, missingLocationError } = this.state;

    return _values({
      pickupTimeError,
      dropoffTimeError,
      daysCountError,
      missingLocationError,
    }).some(hasError => hasError);
  };

  onSubmit = e => {
    const { onSubmit, history, location } = this.props;
    const { selectedLocation, dateFrom, dateTo } = this.state;

    e.preventDefault();
    e.stopPropagation(); // if we are editing a page, we have to make sure the parent form will not be submitted

    clearTimeout(this.submitTimeout);

    this.setState({ locationOverlayShow: false });

    if (this.validate()) {
      // Get the current previous location search list from local storage.
      let prevLocationSearches = JSON.parse(localStorage.getItem('prevLocationSearches'));
      // Set values that is _resent and selected pickup and dropoff date of selected location.
      selectedLocation.isRecent = true;
      selectedLocation.dateFrom = dateFrom;
      selectedLocation.dateTo = dateTo;

      // Do we have a previous location search list?
      if (prevLocationSearches) {
        // Check if the current searched location is in the list of previous searches.
        const recentIndex = _findIndex(prevLocationSearches, ['id', selectedLocation.id]);

        if (recentIndex > 0) {
          // If the current searched location is in the previous searcht list
          // the current entry is removed and the current is added at the top of the list.
          prevLocationSearches.splice(recentIndex, 1).unshift(selectedLocation);
          prevLocationSearches.unshift(selectedLocation);
        } else if (recentIndex === -1) {
          // The current searche location is not in the list.
          if (prevLocationSearches.length === 3) {
            // When there are three entries in the list, the last one is deleted.
            prevLocationSearches.pop();
          }
          // Add the current search ad the top of the previous search list.
          prevLocationSearches.unshift(selectedLocation);
        }
      } else {
        // There is no list of pevious searches, so we add the current search.
        prevLocationSearches = [selectedLocation];
      }

      // Store the new search list in the local storage.
      localStorage.setItem('prevLocationSearches', JSON.stringify(prevLocationSearches));

      this.submitTimeout = setTimeout(() => {
        if (onSubmit) {
          onSubmit(
            selectedLocation,
            moment.tz(dateFrom, 'UTC').format('YYYY-MM-DD HH:mm:ss'),
            moment.tz(dateTo, 'UTC').format('YYYY-MM-DD HH:mm:ss')
          );
        } else {
          history.push(
            getCarRentalSearchPath(
              selectedLocation.id,
              selectedLocation.name,
              parseQs(location.search),
              moment.tz(dateFrom, 'UTC').format('YYYY-MM-DD HH:mm:ss'),
              moment.tz(dateTo, 'UTC').format('YYYY-MM-DD HH:mm:ss')
            )
          );
        }
      }, 150);
    }
  };

  render() {
    const {
      intl: { messages },
      intl,
      appIntl,
      lockLocation,
      countCars,
      timezone,
      projectConfig,
      itemId,
    } = this.props;

    const {
      dateFrom,
      dateTo,
      addressInputFocused,
      addressInputValue,
      selectedLocation,
      locationOverlayShow,
      pickupTimeError,
      dropoffTimeError,
      daysCountError,
      missingLocationError,
    } = this.state;
    const maxRentDays = parseInt(
      getPluginSettingValue(AUTOMOTIVE_RENT_PLUGIN, projectConfig.data, 'maxRentDays', 14),
      10
    );

    const textClass = 'text-' + this.getParamValue('mainColor', 'master') + ' ';
    const layoutVersion = this.getParamValue('layoutVersion', 'wide');
    const workingHoursFrom = dateFrom
      ? this.getLocationRentalWorkingHours(selectedLocation, moment(dateFrom).isoWeekday())
      : [];
    const workingHoursTo = dateTo
      ? this.limitWorkingHoursToMaxRentDays(
          dateTo,
          this.getLocationRentalWorkingHours(selectedLocation, moment(dateTo).isoWeekday())
        )
      : [];

    const countUrlData = {
      filters: { type: 'car', isResource: true },
      reservationDateFrom: moment.tz(dateFrom, 'UTC').format('YYYY-MM-DD HH:mm:ss'),
      reservationDateTo: moment.tz(dateTo, 'UTC').format('YYYY-MM-DD HH:mm:ss'),
    };
    if (selectedLocation && selectedLocation.location_id) {
      countUrlData.filters['defaultLocation.id'] = selectedLocation.location_id;
    }
    if (itemId) {
      countUrlData.filters._id = [itemId];
    }

    let buttonLabel = this.getParamValue('buttonLabel');
    if (!buttonLabel) {
      switch (layoutVersion) {
        case 'narrow':
          buttonLabel = <FormattedMessage id="we_found_count_cars" values={{ count: countCars.data }} />;
          break;

        case 'wide':
          buttonLabel = messages.get_a_quote;
          break;
      }
    }

    const locationInput = (
      <div>
        {!lockLocation && (!selectedLocation || locationOverlayShow) ? (
          <div
            ref={this.inputRef}
            className={
              'form-group form-group-default ' + (addressInputFocused || locationOverlayShow ? 'focused ' : '')
            }
          >
            <input
              value={
                addressInputValue || addressInputFocused ? addressInputValue : messages.type_address_to_start_search
              }
              className={'form-control ' + (!addressInputValue ? 'text-master-light ' : '')}
              type="text"
              onChange={e => this.onAddressInputChange(e.target.value)}
              onFocus={() =>
                this.setState({ locationOverlayShow: true }, () => {
                  this.setState({ addressInputFocused: true });
                })
              }
              onBlur={() => this.setState({ addressInputFocused: false })}
            />
          </div>
        ) : (
          <div
            className={
              'lh-25 text-nowrap no-overflow form-group form-group-default ' +
              (lockLocation ? 'b-hide bg-transparent p-l-0 ' : '')
            }
            onClick={lockLocation ? null : () => this.setState({ locationOverlayShow: true })}
          >
            <span>{selectedLocation.name}</span>
            <span className="hint-text m-l-10">
              {selectedLocation.street} {selectedLocation.house_number}{' '}
              {selectedLocation.apartment_number ? selectedLocation.apartment_number : null} {selectedLocation.city}
            </span>
          </div>
        )}

        <Overlay
          show={locationOverlayShow}
          rootClose
          onHide={() => {
            if (this.state.addressInputFocused) {
              return;
            }
            this.setState({ locationOverlayShow: false });
          }}
          placement="bottom"
          target={() => this.inputRef.current}
        >
          <CmsElementCommerceCarRentLocations
            intl={intl}
            appIntl={appIntl}
            pending={this.state.posDetectPending}
            location={this.state.coordinates}
            onSelect={this.setLocation}
          />
        </Overlay>
      </div>
    );

    const dateFromInput = (
      <Clearfix>
        <div className="pull-left text-master" style={{ width: '50%' }}>
          <DatePicker
            onChange={this.updateDateFrom}
            value={dateFrom}
            minDate={moment().subtract(1, 'days')}
            maxDate={this.getMaxDateFromLimit()}
            locale={appIntl.locale}
            displayTz={timezone}
            isDayBlocked={this.isDayBlocked}
            style={{ borderRight: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            appendToBody
          />
        </div>
        <div className="pull-left text-master" style={{ width: '50%' }}>
          <FormTimePicker
            value={dateFrom}
            onChange={this.updateDateFrom}
            locale={appIntl.locale}
            displayTz={timezone}
            validRange={workingHoursFrom}
            minuteSteps={15}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          />
        </div>
      </Clearfix>
    );

    const dateToInput = (
      <Clearfix>
        <div className="pull-left text-master" style={{ width: '50%' }}>
          <DatePicker
            onChange={this.updateDateTo}
            value={dateTo}
            minDate={this.getMinDateToLimit()}
            maxDate={this.getMaxDateToLimit().endOf('day')}
            locale={appIntl.locale}
            displayTz={timezone}
            isDayBlocked={this.isDayBlocked}
            style={{ borderRight: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            appendToBody
          />
        </div>
        <div className="pull-left text-master" style={{ width: '50%' }}>
          <FormTimePicker
            value={dateTo}
            onChange={this.updateDateTo}
            locale={appIntl.locale}
            displayTz={timezone}
            validRange={workingHoursTo}
            minuteSteps={15}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          />
        </div>
      </Clearfix>
    );

    return (
      <>
        <Count countName="items" url="/api/items/count.json" urlData={countUrlData} count={countCars} />

        <Form onSubmit={this.onSubmit}>
          {this.hasErrors() && (
            <Clearfix>
              <Col xs={12} className="p-t-15 p-b-10">
                <Alert bsStyle="warning" className="no-margin">
                  {pickupTimeError && <p>{messages.car_rental_pickup_time_invalid}</p>}

                  {dropoffTimeError && <p>{messages.car_rental_drop_off_time_invalid}</p>}

                  {daysCountError && (
                    <p>
                      <FormattedMessage id="car_rental_max_days_count_exceeded" values={{ count: maxRentDays }} />
                    </p>
                  )}

                  {missingLocationError && <p>{messages.select_a_pick_up_location}</p>}
                </Alert>
              </Col>
            </Clearfix>
          )}

          {layoutVersion === 'narrow' && (
            <Clearfix>
              <Col xs={6} className="pickup-time">
                <h4 className={'no-margin font-secondary fs-13 text-uppercase bold lh-16 ' + textClass}>
                  {messages.pick_up_time}
                </h4>
                {dateFromInput}
              </Col>
              <Col xs={6} className="dropoff-time">
                <h4 className={'no-margin font-secondary fs-13 text-uppercase bold lh-16 ' + textClass}>
                  {messages.drop_off_time}
                </h4>
                {dateToInput}
              </Col>
              <Col xs={10} xsOffset={2} className="pickup-location">
                <h4 className={'no-margin font-secondary fs-13 text-uppercase lh-16 bold ' + textClass}>
                  {messages.desired_pickup_location}
                </h4>
                {locationInput}
              </Col>
              <Col xs={12} className="text-right">
                <Button type="submit" bsStyle="primary" bsSize="lg" block>
                  {buttonLabel}
                </Button>
              </Col>
            </Clearfix>
          )}

          {layoutVersion === 'wide' && (
            <Clearfix>
              <Col xs={12} lg={5} className="pickup-location">
                <h4 className={'no-margin font-secondary fs-13 text-uppercase lh-16 bold ' + textClass}>
                  {messages.desired_pickup_location}
                </h4>
                {locationInput}
              </Col>
              <Col xs={6} lg={3} className="pickup-time">
                <h4 className={'no-margin font-secondary fs-13 text-uppercase bold lh-16 ' + textClass}>
                  {messages.pick_up_time}
                </h4>
                {dateFromInput}
              </Col>
              <Col xs={6} lg={3} className="dropoff-time">
                <h4 className={'no-margin font-secondary fs-13 text-uppercase bold lh-16 ' + textClass}>
                  {messages.drop_off_time}
                </h4>
                {dateToInput}
              </Col>
              <Col xs={12} mdOffset={6} md={6} lgOffset={0} lg={1}>
                <h4 className="no-margin font-secondary fs-13 lh-16 hidden-xs hidden-sm hidden-md">{'\u00A0'}</h4>{' '}
                {/* dummy element so the button aligns with the input elements */}
                <Button type="submit" bsStyle="primary" className="hidden-xs hidden-sm hidden-md" block>
                  <i className="fa fa-chevron-right" />
                </Button>
                <Button type="submit" bsStyle="primary" bsSize="lg" className="hidden-lg text-uppercase" block>
                  {buttonLabel}
                </Button>
              </Col>
            </Clearfix>
          )}
        </Form>
      </>
    );
  }
}
SearchVersionLocation.defaultProps = {
  index: '0',
  lockLocation: false,
};

const mapStateToProps = (state, ownProps) => {
  const res = {
    countCars: state.count.items,
    timezone: getTimezoneFromState(state),
    projectConfig: state.data.projectConfig,
  };

  if (!ownProps.locationData) {
    res.locationData = state.data.clientLocation;
  }

  return res;
};
const mapDispatchToProps = dispatch => {
  return {
    fetchSearchLocation: locationId => {
      dataDispatchHelper('clientLocation', '/api/whitelabellocations/' + locationId + '.json', null, 'get', dispatch);
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SearchVersionLocation)
);
