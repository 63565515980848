import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { findIndex as _findIndex } from 'lodash';
import SimplePrompt from '../SimplePrompt';

const DropFolder = ({
  accept,
  onDrop,
  index,
  id,
  parent,
  name,
  type,
  owner,
  creator,
  choiceFolder,
  copyFolder,
  deleteFolder,
  editFolder,
  markFolder,
  foldersDrag,
  copiedFolders,
  userData,
}) => {
  const [, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const ownFileType = 1; // 1 = folder, 0 = file
  const [{ opacity }, drag] = useDrag({
    item: { id, type, ownFileType, owner, creator },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  let border;
  const indexColor = _findIndex(foldersDrag, ['id', id]);

  let mark;
  let copy;
  let buttonFontColor;
  let noEvents;

  const foundIndex = _findIndex(copiedFolders, ['id', id]);

  if (foundIndex > -1) {
    border = '3px solid #CF1719';
    copy = '#CF1719';
    buttonFontColor = '#FFFFFF';
    noEvents = 'none';
  }

  if (indexColor > -1) {
    border = '3px solid #bde565';
    mark = '#bde565';
  }

  const allowed = userData.role === 'admin' || [owner, creator].includes(userData.username);

  return (
    <div ref={drop} md={2} className="cc-media-folder" key={index}>
      <div ref={drag} className="card" style={{ backgroundColor: 'transparent', opacity, border }}>
        <div className="card-body">
          <div
            class="selectZone"
            style={{ cursor: 'pointer', pointerEvents: noEvents }}
            onClick={() => choiceFolder(id, parent)}
          >
            <div className="cc-card-title">
              <img
                src="https://creacheck-icons.s3.eu-central-1.amazonaws.com/folder-solid-cutted-svg-weiss.svg"
                alt=""
              />
              <h5 className="">{name}</h5>
            </div>
            <p className="card-text"> </p>
          </div>
          <div class="cc-card-footer">
            {allowed ? (
              <div class="inlineSet">
                <button
                  type="button"
                  onClick={() => copyFolder(id)}
                  className="btn btn-default card-button"
                  style={{ backgroundColor: copy, color: buttonFontColor }}
                >
                  <i className="far fa-cut" />
                </button>
                <button
                  type="button"
                  onClick={() => markFolder(id)}
                  className="btn btn-default card-button"
                  style={{ marginRight: '2px', backgroundColor: mark }}
                >
                  <i className="far fa-arrows-alt" />
                </button>
                <button type="button" onClick={() => editFolder(id)} className="btn btn-default card-button">
                  <i className="fas fa-edit" />
                </button>

                <SimplePrompt class="card-button" onSuccess={() => deleteFolder(id)} showLabel={false} />
              </div>
            ) : (
              <div>
                <i style={{ color: '#CF1719' }} className="far fa-ban forbiddenIcon" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropFolder;
