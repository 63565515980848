import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import * as uuidLib from 'uuid';

import { communicationVideoModule as videoComms, communicationModule, authModule } from '@chedri/base';

import NotificationItem from 'components/NotificationItem';
import Icon from 'components/Icon';

import CallController from './CallController';
import CallVideoController from './CallVideoController';
import CallIncoming from './CallIncoming';
import CallRejected from './CallRejected';

function CallControlCenter() {
  const dispatch = useDispatch();
  const callType = useSelector(videoComms.selectors.getType);
  const localCall = useSelector(videoComms.selectors.getLocalCall);
  const remoteCalls = useSelector(videoComms.selectors.getRemoteCalls);
  const status = useSelector(videoComms.selectors.getStatus);
  const isAuth = useSelector(authModule.selectors.isAuthenticated);
  const authUsername = useSelector(authModule.selectors.getId);
  const authName = useSelector(authModule.selectors.getName);
  const anonymous = useSelector(communicationModule.selectors.getAnonymous) || {};
  const me = isAuth ? { username: authUsername, name: authName } : { username: anonymous.email, name: anonymous.name };

  const [showIncomingCall, setShowIncomingCall] = useState(false);
  const [showOutgoingCall, setShowOutgoingCall] = useState(false);

  const closeCall = useCallback(() => {
    dispatch(videoComms.actions.resetCall());
  });

  const retryTheCall = useCallback(() => {
    const uuid = uuidLib.v4();
    dispatch(
      videoComms.actions.startCall({
        type: callType,
        from: me,
        to: remoteCalls[0].user,
        uuid,
      })
    );
    dispatch(videoComms.actions.updateCallId(uuid, remoteCalls[0].user.id));
  }, [callType, localCall, remoteCalls]);

  useEffect(() => {
    if (localCall) {
      setShowOutgoingCall(
        (localCall.isCalling || (remoteCalls[0] && remoteCalls[0].isRejected && !remoteCalls[0].isCalling)) &&
          !localCall.isEnded &&
          !localCall.isCancelled
      );
    }
  }, [localCall, setShowOutgoingCall]);

  useEffect(() => {
    if (remoteCalls && remoteCalls.length > 0) {
      let shouldBeShown = false;
      remoteCalls.forEach(remoteCall => {
        shouldBeShown = shouldBeShown || (remoteCall.isCalling && !remoteCall.isEnded && !remoteCall.isRejected);
      });
      setShowIncomingCall(shouldBeShown);
    }
  }, [remoteCalls, setShowIncomingCall]);

  // If someone is calling us, it is more important than our outgoing call
  if (showIncomingCall && remoteCalls.length > 0) {
    return remoteCalls.map((remoteCall, idx) => {
      const isActive = !remoteCall.isEnded && !remoteCall.isRejected && !remoteCall.isCancelled;
      if (isActive) {
        // Handling of audio calls
        if (remoteCall.type === 'audio') {
          return (
            <NotificationItem
              key={remoteCall.user.id}
              className="control-center"
              thumbnail={
                <div
                  className={
                    status.info === 'connected' || status.info === 'checking'
                      ? 'icon-active-call'
                      : 'icon-inactive-call'
                  }
                >
                  <Icon name="fa-phone" />
                </div>
              }
              message={
                remoteCall.isCalling && !remoteCall.isAccepted ? (
                  <CallIncoming call={remoteCall} />
                ) : remoteCall.isAccepted ? (
                  <CallController call={remoteCall} />
                ) : null
              }
              style={{ top: (idx + 1) * 59 + 'px' }}
            />
          );
        }
        // Handling of video calls
        if (remoteCall.type === 'video') {
          if (status.info === 'connected') {
            return <CallVideoController call={remoteCall} key={remoteCall.user.id} />;
          }

          return (
            <NotificationItem
              key={remoteCall.user.id}
              className="control-center"
              thumbnail={
                <div className="icon-inactive-call">
                  <Icon name="fa-video" />
                </div>
              }
              message={
                remoteCall.isCalling && !remoteCall.isAccepted ? (
                  <CallIncoming call={remoteCall} />
                ) : remoteCall.isAccepted ? (
                  <CallController call={remoteCall} />
                ) : null
              }
              style={{ top: '59px' }}
            />
          );
        }
      }
      return null;
    });
  }

  // We are calling someone by audio call
  if (localCall && remoteCalls[0] && showOutgoingCall && callType === 'audio') {
    return (
      <NotificationItem
        key={me.id}
        type={localCall.isRejected ? 'danger' : 'default'}
        className="control-center"
        thumbnail={
          <div
            className={
              status.info === 'connected' || status.info === 'checking' ? 'icon-active-call' : 'icon-inactive-call'
            }
          >
            <Icon name="fa-phone" />
          </div>
        }
        message={
          remoteCalls[0].isRejected ? (
            <CallRejected
              calleeName={get(remoteCalls, '[0].user.name')}
              onCallRetry={retryTheCall}
              onClose={closeCall}
            />
          ) : (
            <CallController call={localCall} />
          )
        }
        style={{ top: '59px' }}
      />
    );
  }
  // We are calling someone by video call
  if (localCall && remoteCalls[0] && showOutgoingCall && callType === 'video') {
    if (status.info === 'connected') {
      return <CallVideoController key={me.id} call={localCall} />;
    }

    return (
      <NotificationItem
        key={me.id}
        type={localCall.isRejected ? 'danger' : 'default'}
        className="control-center"
        thumbnail={
          <div className="icon-inactive-call">
            <Icon name="fa-video" />
          </div>
        }
        message={
          remoteCalls[0].isRejected ? (
            <CallRejected
              calleeName={get(remoteCalls, '[0].user.name')}
              onCallRetry={retryTheCall}
              onClose={closeCall}
            />
          ) : (
            <CallController call={localCall} />
          )
        }
        style={{ top: '59px' }}
      />
    );
  }
  return null;
}

export default CallControlCenter;
