import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Button } from 'react-bootstrap';
import { getDeviceType } from '../cc-editor/components/actions/deviceType';

const initialState = {
  forwardingR: '',
  previewingR: false,
  exeThen: '',
  changedR: false,
  campaignSavingR: false,
  linkDataR: '',
  shareImageR: false,
  pendingR: false,
  pendingCopyR: false,
  statusCopyR: '',
  typeOfCopyR: '',
};

function handleButtonClick(downloadLink) {
  // create a hidden link element
  const link = document.createElement('a');
  link.href = downloadLink;
  link.target = '_blank';
  link.download = 'filename.pdf';
  link.click();
}

export default function campaigns(state = initialState, action) {
  switch (action.type) {
    case 'NEW_EDITION_FULFILLED': {
      return { ...state, forwardingR: action.payload.id };
    }
    case 'SET_VARIABLES': {
      if (action.payload === 'forwardingfalse') {
        return { ...state, forwardingR: '' };
      }
      if (action.payload === 'image') {
        return { ...state, shareImageR: true };
      }

      return { ...state, previewingR: action.payload };
    }
    case 'SET_PREVIEW_IMAGE_PENDING': {
      return { ...state, pendingR: true };
    }
    case 'SET_PREVIEW_IMAGE_FULFILLED': {
      return { ...state, pendingR: false };
    }
    case 'DUPLICATE_CAMPAIGNS_PENDING': {
      return { ...state, pendingCopyR: true, statusCopyR: '', typeOfCopyR: '' };
    }
    case 'DUPLICATE_CAMPAIGNS_FULFILLED': {
      return { ...state, pendingCopyR: false, statusCopyR: 'success', typeOfCopyR: action.payload?.params?.type };
    }
    case 'DUPLICATE_CAMPAIGNS_REJECTED': {
      return { ...state, pendingCopyR: false, statusCopyR: 'error', typeOfCopyR: action.payload?.params?.type };
    }
    case 'GET_PDF_CAMP_FULFILLED': {
      const shareFB = url => {
        if (navigator.share) {
          navigator
            .share({
              title: 'CreaCheck',
              url,
            })
            .then(() => {
              console.log('Thanks for sharing!');
            })
            .catch(console.error);
        } else {
          window.open(
            'https://www.facebook.com/sharer/sharer.php?u=' + url,
            'pop',
            'width=600, height=400, scrollbars=no'
          );
        }
      };
      const options = {
        customUI: ({ onClose }) => {
          const { shareImageR } = state;
          const shareImage = shareImageR;
          return (
            <div className="react-confirm-alert-body">
              <h1>{action.payload?.messages.your_export}</h1>
              <p>{action.payload?.messages.export_download_info}</p>
              <div className="react-confirm-alert-button-group">
                {typeof action.payload?.data === 'string' && (
                  <>
                    <Button
                      onClick={() => {
                        handleButtonClick(action.payload?.data);
                        onClose();
                      }}
                    >
                      {action.payload?.messages.export_download}
                    </Button>
                    {(getDeviceType() === 'mobile' || getDeviceType() === 'tablet') && shareImage === true && (
                      <Button onClick={() => shareFB(action.payload?.data)}>
                        {action.payload?.messages.export_share}
                      </Button>
                    )}
                  </>
                )}
                {typeof action.payload?.data === 'object' &&
                  action.payload.data.length > 0 &&
                  action.payload.data.map((file, i) => {
                    return (
                      <>
                        <p>
                          <span>
                            {action.payload?.messages.export_file} {i + 1}
                          </span>
                          <br />
                        </p>
                        <Button
                          onClick={() => {
                            handleButtonClick(file);
                            onClose();
                          }}
                        >
                          {action.payload?.messages.export_download}
                        </Button>
                        {(getDeviceType() === 'mobile' || getDeviceType() === 'tablet') && shareImage === true && (
                          <Button onClick={() => shareFB(file)}>{action.payload?.messages.export_share}</Button>
                        )}
                      </>
                    );
                  })}
              </div>
            </div>
          );
        },
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
      };

      confirmAlert(options);
      return { ...state, previewingR: false, shareImageR: false };
    }
    case 'GET_PRINT_DATA_CAMP': {
      const shareFB = url => {
        if (navigator.share) {
          navigator
            .share({
              title: 'CreaCheck',
              url,
            })
            .then(() => {
              console.log('Thanks for sharing!');
            })
            .catch(console.error);
        } else {
          window.open(
            'https://www.facebook.com/sharer/sharer.php?u=' + url,
            'pop',
            'width=600, height=400, scrollbars=no'
          );
        }
      };
      const options = {
        customUI: ({ onClose }) => {
          const { shareImageR } = state;
          const shareImage = shareImageR;
          return (
            <div className="react-confirm-alert-body">
              <h1>{action.payload?.messages.your_export}</h1>
              <p>{action.payload?.messages.export_download_info}</p>
              <div className="react-confirm-alert-button-group">
                {typeof action.payload?.data === 'string' && (
                  <>
                    <Button
                      onClick={() => {
                        handleButtonClick(action.payload?.data);
                        onClose();
                      }}
                    >
                      {action.payload?.messages.export_download}
                    </Button>
                    {(getDeviceType() === 'mobile' || getDeviceType() === 'tablet') && shareImage === true && (
                      <Button onClick={() => shareFB(action.payload?.data)}>
                        {action.payload?.messages.export_share}
                      </Button>
                    )}
                  </>
                )}
                {typeof action.payload?.data === 'object' &&
                  action.payload.data.length > 0 &&
                  action.payload.data.map((file, i) => {
                    return (
                      <>
                        <p>
                          <span>
                            {action.payload?.messages.export_file} {i + 1}
                          </span>
                          <br />
                        </p>
                        <Button
                          onClick={() => {
                            handleButtonClick(file);
                            onClose();
                          }}
                        >
                          {action.payload?.messages.export_download}
                        </Button>
                        {(getDeviceType() === 'mobile' || getDeviceType() === 'tablet') && shareImage === true && (
                          <Button onClick={() => shareFB(file)}>{action.payload?.messages.export_share}</Button>
                        )}
                      </>
                    );
                  })}
              </div>
            </div>
          );
        },
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
      };

      confirmAlert(options);
      return { ...state, previewingR: false, shareImageR: false };
    }
  }
  return state;
}
