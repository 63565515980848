import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { updateCheckout, updatePayment } from 'actions/CheckoutActions';
import { isPluginActive } from 'helpers/whiteLabel';
import { getPaymentType } from 'helpers/payment';
import { INVOICE_PAYMENT_OPTION_ENABLED } from 'helpers/clients';

import {
  ROLE_EMPLOYEE,
  PAYMENT_NONE,
  PAYMENT_CASH,
  PAYMENT_BILL,
  PAYMENT_PLUGIN_STRIPE_PLAIN,
  PAYMENT_PLUGIN_STRIPE_CONNECT,
  PAYMENT_PLUGIN_PAYPAL_PLUS,
} from 'js/constants';
import * as PaymentContents from 'components/ePayment/paymentContents';

import Data from 'components/Data';

import may from 'js/security';

class PaymentOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      forceEmployee: false,
    };
  }

  getIsChangeDisabled = () => {
    return false;
  };

  componentDidUpdate = prevProps => {
    const { ePaymentTotal, user, checkout, doUpdateCheckout } = this.props;

    if (prevProps.ePaymentTotal !== 0 && ePaymentTotal === 0 && checkout.activePaymentPlugin !== PAYMENT_NONE) {
      // the ePayment is null now, so we don't need a payment anymore
      doUpdateCheckout({ activePaymentPlugin: PAYMENT_NONE, payment: 'none' });
    }

    if (prevProps.ePaymentTotal === 0 && ePaymentTotal !== 0 && checkout.activePaymentPlugin === PAYMENT_NONE) {
      // the ePayment total was null, but now the total to pay is greater than 0,
      // so we need to change the payment plugin to a default one
      const allowCashPayment = false; // may(ROLE_EMPLOYEE, user.roles);

      if (allowCashPayment) {
        doUpdateCheckout({ activePaymentPlugin: PAYMENT_CASH, payment: 'cash' });
      } else {
        // get the first plugin from the checkout info
        const paymentPlugin = checkout.availablePlugins[0] || PAYMENT_CASH;
        doUpdateCheckout({ activePaymentPlugin: paymentPlugin, payment: getPaymentType(paymentPlugin) });
      }
    }
  };

  componentDidMount = () => {
    const { ePaymentTotal, checkout, doUpdateCheckout } = this.props;

    if (ePaymentTotal === 0 && checkout.activePaymentPlugin !== PAYMENT_NONE) {
      doUpdateCheckout({ activePaymentPlugin: PAYMENT_NONE, payment: 'none' });
    }
  };

  doChangePayment = (e, activePaymentPlugin, payment) => {
    e.preventDefault();

    const { disabled, doUpdatePayment } = this.props;

    if (!disabled) {
      doUpdatePayment(activePaymentPlugin, payment);
    }
  };

  getPaymentContent = () => {
    const {
      intl,
      employee,
      clientId,
      ePaymentTotal,
      currency,
      checkout,
      disabled,
      myParent,
      doUpdateCheckout,
      doUpdatePayment,
    } = this.props;
    const { forceEmployee } = this.state;

    if (checkout.activePaymentPlugin === PAYMENT_BILL) {
      return null;
    }

    const componentName = ('PaymentContent_' + checkout.activePaymentPlugin.toLowerCase()).replace(/_([a-z])/g, g => {
      return g[1].toUpperCase();
    });
    const Component = PaymentContents[componentName];

    if (!Component) {
      return null;
    }

    return (
      <Component
        intl={intl}
        employee={employee || forceEmployee}
        checkout={checkout}
        disabled={disabled}
        clientId={clientId}
        ePaymentTotal={ePaymentTotal}
        currency={currency}
        doUpdateCheckout={doUpdateCheckout}
        doUpdatePayment={doUpdatePayment}
        myParent={myParent}
      />
    );
  };

  render() {
    const {
      intl: { messages },
      webApp: { debug },
      ePaymentTotal,
      user,
      projectConfig,
      checkout,
      myParent,
      showPaymentSelector,
    } = this.props;
    const { forceEmployee } = this.state;

    const changeDisabled = this.getIsChangeDisabled();
    const allowCashPayment = may(ROLE_EMPLOYEE, user.roles);
    const { setting } = user.effective_invoice_payment_option; // own or inherited setting
    const allowBillPayment = allowCashPayment && setting === INVOICE_PAYMENT_OPTION_ENABLED;

    const isStripePlainActive = isPluginActive(PAYMENT_PLUGIN_STRIPE_PLAIN, projectConfig.data);
    const isStripeConnectActive = isPluginActive(PAYMENT_PLUGIN_STRIPE_CONNECT, projectConfig.data);

    const paymentContent = this.getPaymentContent();

    return ePaymentTotal > 0 ? (
      <>
        {allowBillPayment && (
          <>
            <Data dataName="financialBankData" />
            <Data dataName="myParent" url="/api/clients/parent.json" data={myParent} />
          </>
        )}
        {/*
        {debug && (
          <div className="padding-10 b-a">
            <div>DEBUG ONLY</div>
            <input
              type="checkbox"
              checked={forceEmployee}
              onChange={e => {
                this.setState({ forceEmployee: e.target.checked });
              }}
            />{' '}
            Force Preview
          </div>
        )} */}

        {/* show the titles of e-payment options */}
        {showPaymentSelector ? (
          <ul className="list-unstyled list-inline m-l-30">
            {allowCashPayment &&
              ((changeDisabled && checkout.activePaymentPlugin === PAYMENT_CASH) || !changeDisabled) && (
                <li className={checkout.activePaymentPlugin === PAYMENT_CASH ? 'active' : ''}>
                  <a
                    href="#paymentCash"
                    className={
                      'p-r-30 text-black ' + (checkout.activePaymentPlugin === PAYMENT_CASH ? '' : 'hint-text')
                    }
                    onClick={e => this.doChangePayment(e, PAYMENT_CASH, 'cash')}
                  >
                    <i className="far fa-money-bill-alt fa-fw" /> {messages.payment_cash}
                  </a>
                </li>
              )}

            {/* stripe plain */}
            {isStripePlainActive &&
              ((changeDisabled && checkout.activePaymentPlugin === PAYMENT_PLUGIN_STRIPE_PLAIN) || !changeDisabled) && (
                <li className={checkout.activePaymentPlugin === PAYMENT_PLUGIN_STRIPE_PLAIN ? 'active' : ''}>
                  <a
                    href="#paymentCreditCard"
                    className={
                      'p-r-30 text-black ' +
                      (checkout.activePaymentPlugin === PAYMENT_PLUGIN_STRIPE_PLAIN ? '' : 'hint-text')
                    }
                    onClick={e => this.doChangePayment(e, PAYMENT_PLUGIN_STRIPE_PLAIN, 'creditCard')}
                  >
                    <i className="fa fa-credit-card fa-fw" /> {messages.payment_creditCard}
                  </a>
                </li>
              )}

            {/* stripe connect */}
            {isStripeConnectActive &&
              ((changeDisabled && checkout.activePaymentPlugin === PAYMENT_PLUGIN_STRIPE_CONNECT) ||
                !changeDisabled) && (
                <li className={checkout.activePaymentPlugin === PAYMENT_PLUGIN_STRIPE_CONNECT ? 'active' : ''}>
                  <a
                    href="#paymentCreditCard"
                    className={
                      'p-r-30 text-black ' +
                      (checkout.activePaymentPlugin === PAYMENT_PLUGIN_STRIPE_CONNECT ? '' : 'hint-text')
                    }
                    onClick={e => this.doChangePayment(e, PAYMENT_PLUGIN_STRIPE_CONNECT, 'creditCard')}
                  >
                    <i className="fa fa-credit-card fa-fw" /> {messages.payment_creditCard}
                  </a>
                </li>
              )}

            {/* PayPal Plus */}
            {isPaypalPlusActive &&
              ((changeDisabled && checkout.activePaymentPlugin === PAYMENT_PLUGIN_PAYPAL_PLUS) ||
                !changeDisabled) && (
                <li className={checkout.activePaymentPlugin === PAYMENT_PLUGIN_PAYPAL_PLUS ? 'active' : ''}>
                  <a
                    href="#paymentPayPal"
                    className={
                      'p-r-30 text-black ' +
                      (checkout.activePaymentPlugin === PAYMENT_PLUGIN_PAYPAL_PLUS ? '' : 'hint-text')
                    }
                    onClick={e => this.doChangePayment(e, PAYMENT_PLUGIN_PAYPAL_PLUS, 'PayPal')}
                  >
                    <i className="fab fa-paypal fa-fw" /> {messages.payment_payPal}
                  </a>
                </li>
              )}

            {/* bill payment */}
            {allowBillPayment &&
              ((changeDisabled && checkout.activePaymentPlugin === PAYMENT_BILL) || !changeDisabled) && (
                <li className={checkout.activePaymentPlugin === PAYMENT_BILL ? 'active' : ''}>
                  <a
                    href="#paymentBill"
                    className={
                      'p-r-30 text-black ' + (checkout.activePaymentPlugin === PAYMENT_BILL ? '' : 'hint-text')
                    }
                    onClick={e => this.doChangePayment(e, PAYMENT_BILL, 'bill')}
                  >
                    <i className="fal fa-file-invoice-dollar fa-fw" /> {messages.payment_bill}
                  </a>
                </li>
              )}
          </ul>
        ) : null}

        {showPaymentSelector ? (
          <div className="bg-master-light padding-30 b-rad-lg">
            <h2 className="no-margin">{messages['payment_' + checkout.payment]}</h2>

            <div className="p-t-20">
              {/* if a payment method got selected, display a link to reset the payment */}
              {changeDisabled && (
                <div>
                  <a href="#resetPayment" onClick={this.resetPayment}>
                    <i className="fa fa-angle-left" /> {messages.choose_different_payment_method}
                  </a>
                </div>
              )}

              {paymentContent}
            </div>
          </div>
        ) : (
          paymentContent
        )}
      </>
    ) : null;
  }
}
PaymentOptions.defaultProps = {
  disabled: false,
  employee: false,
  showPaymentSelector: true,
};

const mapStateToProps = state => {
  return {
    webApp: state.webApp,
    user: state.login.user,
    checkout: state.checkout,
    projectConfig: state.data.projectConfig,
    myParent: state.data.myParent,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    doUpdateCheckout: params => {
      dispatch(updateCheckout(params));
    },
    doUpdatePayment: (plugin, type, data = {}) => {
      dispatch(updatePayment(plugin, type, data));
    },
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PaymentOptions)
);
