export const SET_SAD_FILTERS = 'SET_SAD_FILTERS';
export const SET_SAD_FILTER = 'SET_SAD_FILTER';
export const REMOVE_SAD_FILTER = 'REMOVE_SAD_FILTER';
export const RESET_SAD_FILTER = 'RESET_SAD_FILTER';

export function setFilters(filters) {
  return {
    type: SET_SAD_FILTERS,
    payload: { filters },
  };
}

export function setFilter(name, values) {
  return {
    type: SET_SAD_FILTER,
    payload: { name, values },
  };
}

export function removeFilter(name, value) {
  return {
    type: REMOVE_SAD_FILTER,
    payload: { name, value },
  };
}

export function resetFilter() {
  return {
    type: RESET_SAD_FILTER,
    payload: null,
  };
}
