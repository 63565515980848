import React from 'react';

export default class MapAreaMarker extends React.Component {
  constructor(props) {
    super(props);

    let active;
    let isControlled = false;
    if (props.active !== undefined) {
      active = props.active;
    } else if (props.defaultActive !== undefined) {
      active = props.defaultActive;
      isControlled = true;
    }

    this.state = {
      active: active !== undefined ? active : false,
      isControlled,
    };
  }

  click = () => {
    const { onChange } = this.props;
    const { active, isControlled } = this.state;

    if (isControlled) {
      onChange(!active);
    } else {
      this.setState({ active: !active });
    }
  };

  componentDidUpdate = prevProps => {
    const { active } = this.props;
    const { isControlled } = this.state;

    if (isControlled && prevProps.active !== active) {
      this.setState({ active });
    }
  };

  render() {
    const { title, tooltip } = this.props;
    const { active } = this.state;

    return (
      <div class="overlayer" style={{ transform: 'translate(-50%, -100%)' }}>
        {active && tooltip ? (
          <div class="popover top block relative m-b-15" style={{ width: '200px' }}>
            <div class="arrow" />
            <h3 class="popover-title">{title}</h3>
            <div class="popover-content">{tooltip}</div>
          </div>
        ) : null}

        <div class="text-center">
          <div class="map area-marker" onClick={this.click} />
        </div>
      </div>
    );
  }
}
MapAreaMarker.defaultProps = {
  title: '',
  tooltip: null,
  onChange: () => {},
};
