import { SET_SUMMARY_VIEW } from 'actions/SummarySettingsActions';

const initialState = {
  view: 'grid',
};

export default function summary(state = initialState, action) {
  switch (action.type) {
    case SET_SUMMARY_VIEW: {
      return { ...state, view: action.payload };
    }
  }

  return state;
}
