import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Clearfix, Col, Form, Button, Alert } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { values as _values } from 'lodash';

import { getOnlineAppointmentBookingLocationsPath } from 'helpers/intl';

import FormGooglePlacesAutocomplete from 'components/forms/FormGooglePlacesAutocomplete';

import AbstractCmsElement from './AbstractCmsElement';

class CmsElementCommerceOnlineAppointmentBooking extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
      coordinates: null,
      missingLocationError: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  hasErrors = () => {
    const { missingLocationError } = this.state;

    return _values({
      missingLocationError,
    }).some(hasError => hasError);
  };

  locationSelected = (address, coordinates /* , googlePlacesResult */) => {
    // console.log(address, coordinates, googlePlacesResult);
    this.setState({ coordinates });
  };

  validate = () => {
    const { coordinates } = this.state;
    const missingLocationError = !coordinates;

    const errors = {
      missingLocationError,
    };

    this.setState(errors);

    return _values(errors).every(hasError => !hasError);
  };

  onSubmit = e => {
    const { coordinates } = this.state;

    e.preventDefault();
    e.stopPropagation(); // if we are editing a page, we have to make sure the parent form will not be submitted

    clearTimeout(this.submitTimeout);

    this.setState({ locationOverlayShow: false });

    if (this.validate()) {
      this.submitTimeout = setTimeout(() => {
        this.props.history.push(getOnlineAppointmentBookingLocationsPath(coordinates));
      }, 150);
    }
  };

  render() {
    const {
      intl: { messages },
    } = this.props;
    const { shown, coordinates, missingLocationError } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const textClass = 'text-' + this.getParamValue('mainColor', 'master') + ' ';
    const btnStyle = coordinates ? 'primary' : 'default';

    const title = this.getParamValue('title', '');

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        className={
          'ms-ecommerce-online-appointment-booking relative ' +
          shownClass +
          this.getResponsiveClasses() +
          this.getCssClasses()
        }
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <Form onSubmit={this.onSubmit}>
          {this.hasErrors() && (
            <Clearfix>
              <Col xs={12} className="p-t-15 p-b-10">
                <Alert bsStyle="warning" className="no-margin">
                  {missingLocationError && <p>{messages.select_location}</p>}
                </Alert>
              </Col>
            </Clearfix>
          )}

          <Clearfix>
            <Col xs={12} lg={11}>
              <h4 className={'no-margin font-secondary fs-13 text-uppercase lh-16 bold ' + textClass}>{title}</h4>
              <FormGooglePlacesAutocomplete onSelect={this.locationSelected} />
            </Col>
            <Col xs={12} mdOffset={6} md={6} lgOffset={0} lg={1}>
              <h4 className="no-margin font-secondary fs-13 lh-16 hidden-xs hidden-sm hidden-md">{'\u00A0'}</h4>{' '}
              {/* dummy element so the button aligns with the input elements */}
              <Button type="submit" bsStyle={btnStyle} className="hidden-xs hidden-sm hidden-md" block>
                <i className="fa fa-chevron-right" />
              </Button>
              <Button type="submit" bsStyle={btnStyle} bsSize="lg" className="hidden-lg text-uppercase" block>
                {messages.search}
              </Button>
            </Col>
          </Clearfix>
        </Form>

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementCommerceOnlineAppointmentBooking.defaultProps = {
  index: '0',
};

const mapStateToProps = state => {
  return {
    projectConfig: state.data.projectConfig,
  };
};

export default withRouter(connect(mapStateToProps)(CmsElementCommerceOnlineAppointmentBooking));
