import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Clearfix, Col, Form, Button, Alert } from 'react-bootstrap';
import moment from 'moment';
import 'moment-timezone';
import { isEmpty as _isEmpty, values as _values, findIndex as _findIndex } from 'lodash';

import { getCarRentalMapSearchPath, getTimezoneFromState } from 'helpers/intl';
import { parseQs } from 'helpers/http';
import { getCarRentalDaysCount } from 'helpers/items';
import { getPluginSettingValue } from 'helpers/whiteLabel';

import FormTimePicker from 'components/forms/FormTimePicker';
import DatePicker from 'components/forms/DatePicker';

import Count from 'components/Count';
import FormGooglePlacesAutocomplete from 'components/forms/FormGooglePlacesAutocomplete';

import { AUTOMOTIVE_RENT_PLUGIN } from 'js/constants';

import AbstractCmsElement from '../AbstractCmsElement';

class SearchVersionMap extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    const location = localStorage.getItem('location') || null;
    const locationAddress = localStorage.getItem('locationAddress') || '';
    const query = parseQs(props.location.search);

    let queryDateFrom = null;
    if (query.dateFrom) {
      queryDateFrom = moment.tz(query.dateFrom, 'UTC').toISOString();
    }

    let queryDateTo = null;
    if (query.dateTo) {
      queryDateTo = moment.tz(query.dateTo, 'UTC').toISOString();
    }
    const dateFrom = queryDateFrom || this.getDefaultFromDateTime().toISOString();
    const dateTo = queryDateTo || this.getDefaultToDateTime().toISOString();

    this.state = {
      dateFrom,
      dateTo,

      coordinates: location,
      address: locationAddress,
      addressInputFocused: false,
      addressInputValue: locationAddress,
    };

    moment.locale(props.intl.locale);
  }

  getDefaultFromDateTime = () => {
    const { timezone } = this.props;

    const startDate = moment();

    startDate
      .tz(timezone)
      .set({ hour: 12, minute: 0, second: 0 })
      .tz('UTC');

    return startDate;
  };

  getDefaultToDateTime = dateFrom => {
    const { timezone } = this.props;
    const toDate = moment(dateFrom).add(1, 'days');

    toDate
      .tz(timezone)
      .set({ hour: 9, minute: 0, second: 0 })
      .tz('UTC');

    return toDate;
  };

  limitWorkingHoursToMaxRentDays = (date, range) => {
    const maxDate = this.getMaxDateToLimit();

    if (_isEmpty(range) || !moment(date).isSame(maxDate, 'day')) {
      return range;
    }

    const rangeCopy = range.slice();

    rangeCopy[range.length - 1] = {
      start: rangeCopy[range.length - 1].start,
      end: Math.min(
        rangeCopy[range.length - 1].end,
        maxDate.utc().hours() + Math.round((maxDate.utc().minutes() / 60) * 100) / 100 + 0.25
      ),
    };

    return rangeCopy;
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return this.__shouldComponentUpdate(nextProps, nextState);
  };

  getMaxDateFromLimit = () => {
    const { projectConfig } = this.props;

    const maxBookingFuture = parseInt(
      getPluginSettingValue(AUTOMOTIVE_RENT_PLUGIN, projectConfig.data, 'maxBookingFuture', 90),
      10
    );

    return moment().add(maxBookingFuture - 1, 'days');
  };

  getMaxDateToLimit = dateFrom => {
    const { projectConfig } = this.props;
    dateFrom = dateFrom || this.state.dateFrom;

    const maxRentDays = parseInt(
      getPluginSettingValue(AUTOMOTIVE_RENT_PLUGIN, projectConfig.data, 'maxRentDays', 14),
      10
    );

    return moment(dateFrom).add(maxRentDays, 'days');
  };

  getMinDateToLimit = () => {
    const { dateFrom } = this.state;
    const momentDateFrom = moment(dateFrom);

    return momentDateFrom.add(1, 'days').startOf('day');
  };

  updateDateFrom = (name, dateFrom) => {
    const momentDateFrom = moment.tz(dateFrom, 'UTC');
    const momentDateTo = moment.tz(this.state.dateTo, 'UTC');
    const daysCount = getCarRentalDaysCount(momentDateFrom, momentDateTo);
    const maxDateToLimit = this.getMaxDateToLimit(dateFrom);

    if (momentDateFrom.isAfter(momentDateTo) || daysCount < 1) {
      const dateTo = dateFrom.clone();

      dateTo.set({
        hour: momentDateTo.get('hour'),
        minute: momentDateTo.get('minute'),
        second: momentDateTo.get('second'),
      });

      this.setState({
        dateFrom: moment(dateFrom).toISOString(),
        dateTo: dateTo.add(1, 'days').toISOString(),
      });
    } else if (momentDateTo.isAfter(maxDateToLimit)) {
      this.setState({
        dateFrom: moment(dateFrom).toISOString(),
        dateTo: maxDateToLimit.toISOString(),
      });
    } else {
      this.setState({ dateFrom: moment(dateFrom).toISOString() });
    }
  };

  updateDateTo = (name, dateTo) => {
    const { dateFrom } = this.state;
    const maxDateToLimit = this.getMaxDateToLimit(dateFrom);

    if (moment(dateTo).isAfter(maxDateToLimit)) {
      this.setState({ dateTo: maxDateToLimit.toISOString() });
    } else if (moment(dateTo).isBefore(moment(dateFrom))) {
      this.setState({
        dateTo: moment
          .tz(dateFrom, 'UTC')
          .add(1, 'days')
          .toISOString(),
      });
    } else {
      this.setState({ dateTo: moment(dateTo).toISOString() });
    }
  };

  validate = () => {
    const { coordinates, dateTo } = this.state;
    const missingLocationError = !coordinates;

    const errors = {
      missingLocationError,
      daysCountError: !missingLocationError && moment(dateTo).isAfter(this.getMaxDateToLimit()),
    };

    this.setState(errors);

    return _values(errors).every(hasError => !hasError);
  };

  hasErrors = () => {
    const { daysCountError, missingLocationError } = this.state;

    return _values({
      daysCountError,
      missingLocationError,
    }).some(hasError => hasError);
  };

  locationSelected = (address, coordinates, googlePlacesResult) => {
    const { location } = this.props;
    const query = parseQs(location.search);

    if (query.dateFrom || query.dateTo) {
      return;
    }

    const prevMapSearches = JSON.parse(localStorage.getItem('prevMapSearches'));
    let recentIndex = -1;

    if (googlePlacesResult) {
      recentIndex = _findIndex(prevMapSearches, ['placeId', googlePlacesResult.place_id]);
    } else {
      recentIndex = _findIndex(prevMapSearches, ['coordinates', coordinates]);
    }

    if (recentIndex > -1) {
      const { dateFrom } = prevMapSearches[recentIndex];
      const { dateTo } = prevMapSearches[recentIndex];

      this.setState({ dateFrom, dateTo });
    }

    this.setState({ coordinates });

    if (googlePlacesResult) {
      this.setState({ googlePlacesResult });
    }
  };

  onSubmit = e => {
    e.preventDefault();
    e.stopPropagation(); // if we are editing a page, we have to make sure the parent form will not be submitted

    const { coordinates, dateFrom, dateTo, address, googlePlacesResult } = this.state;

    clearTimeout(this.submitTimeout);

    if (this.validate()) {
      // Is there a google search?
      if (googlePlacesResult) {
        // Get the current previous map search list from local storage.
        let prevMapSearches = JSON.parse(localStorage.getItem('prevMapSearches'));

        // Create an object for the current search, which would be accepted for google search.
        const currentSearch = {
          placeId: googlePlacesResult.place_id,
          description: address,
          isRecent: true,
          dateFrom,
          dateTo,
          coordinates,
        };

        // Do we have a previous map search list?
        if (prevMapSearches) {
          // Check if the current search is in the list of previous searches.
          const recentIndex = _findIndex(prevMapSearches, ['coordinates', coordinates]);

          if (recentIndex > 0) {
            // If the current search is in the previous searcht list
            // the current entry is removed and the current is added at the top of the list.
            prevMapSearches.splice(recentIndex, 1).unshift(currentSearch);
            prevMapSearches.unshift(currentSearch);
          } else if (recentIndex === -1) {
            // The current searche location is not in the list.
            if (prevMapSearches.length === 3) {
              // When there are three entries in the list, the last one is deleted.
              prevMapSearches.pop();
            }
            // Add the current search ad the top of the previous search list.
            prevMapSearches.unshift(currentSearch);
          }
        } else {
          // There is no list of pevious searches, so we add the current search.
          prevMapSearches = [currentSearch];
        }

        localStorage.setItem('prevMapSearches', JSON.stringify(prevMapSearches));
      }

      this.submitTimeout = setTimeout(() => {
        this.props.history.push(
          getCarRentalMapSearchPath(
            coordinates,
            parseQs(this.props.location.search),
            moment.tz(dateFrom, 'UTC').format('YYYY-MM-DD HH:mm:ss'),
            moment.tz(dateTo, 'UTC').format('YYYY-MM-DD HH:mm:ss')
          )
        );
      }, 150);
    }
  };

  render() {
    const {
      intl: { messages },
      appIntl,
      countCars,
      timezone,
      projectConfig,
    } = this.props;

    const { dateFrom, dateTo, pickupTimeError, dropoffTimeError, daysCountError, missingLocationError } = this.state;
    const maxRentDays = parseInt(
      getPluginSettingValue(AUTOMOTIVE_RENT_PLUGIN, projectConfig.data, 'maxRentDays', 14),
      10
    );

    const textClass = 'text-' + this.getParamValue('mainColor', 'master') + ' ';
    const layoutVersion = this.getParamValue('layoutVersion', 'wide');

    const countUrlData = {
      filters: { type: 'car', isResource: true },
      reservationDateFrom: moment.tz(dateFrom, 'UTC').format('YYYY-MM-DD HH:mm:ss'),
      reservationDateTo: moment.tz(dateTo, 'UTC').format('YYYY-MM-DD HH:mm:ss'),
    };

    const prevMapSearches = JSON.parse(localStorage.getItem('prevMapSearches'));

    const locationInput = (
      <div>
        <FormGooglePlacesAutocomplete
          onSelect={this.locationSelected}
          enableBrowserLocation
          autoBrowserLocation
          prevMapSearches={prevMapSearches}
        />
      </div>
    );

    const dateFromInput = (
      <Clearfix>
        <div className="pull-left text-master" style={{ width: '50%' }}>
          <DatePicker
            onChange={this.updateDateFrom}
            value={dateFrom}
            minDate={moment().subtract(1, 'days')}
            maxDate={this.getMaxDateFromLimit()}
            locale={appIntl.locale}
            displayTz={timezone}
            isDayBlocked={this.isDayBlocked}
            style={{
              borderRight: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            appendToBody
          />
        </div>
        <div className="pull-left text-master" style={{ width: '50%' }}>
          <FormTimePicker
            value={dateFrom}
            onChange={this.updateDateFrom}
            locale={appIntl.locale}
            displayTz={timezone}
            minuteSteps={15}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          />
        </div>
      </Clearfix>
    );

    const dateToInput = (
      <Clearfix>
        <div className="pull-left text-master" style={{ width: '50%' }}>
          <DatePicker
            onChange={this.updateDateTo}
            value={dateTo}
            minDate={this.getMinDateToLimit()}
            maxDate={this.getMaxDateToLimit().endOf('day')}
            locale={appIntl.locale}
            displayTz={timezone}
            isDayBlocked={this.isDayBlocked}
            style={{
              borderRight: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            appendToBody
          />
        </div>
        <div className="pull-left text-master" style={{ width: '50%' }}>
          <FormTimePicker
            value={dateTo}
            onChange={this.updateDateTo}
            locale={appIntl.locale}
            displayTz={timezone}
            minuteSteps={15}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          />
        </div>
      </Clearfix>
    );

    return (
      <React.Fragment>
        <Count countName="items" url="/api/items/count.json" urlData={countUrlData} count={countCars} />

        <Form onSubmit={this.onSubmit}>
          {this.hasErrors() && (
            <Clearfix>
              <Col xs={12} className="p-t-15 p-b-10">
                <Alert bsStyle="warning" className="no-margin">
                  {pickupTimeError && <p>{messages.car_rental_pickup_time_invalid}</p>}

                  {dropoffTimeError && <p>{messages.car_rental_drop_off_time_invalid}</p>}

                  {daysCountError && (
                    <p>
                      <FormattedMessage id="car_rental_max_days_count_exceeded" values={{ count: maxRentDays }} />
                    </p>
                  )}

                  {missingLocationError && <p>{messages.select_a_pick_up_location}</p>}
                </Alert>
              </Col>
            </Clearfix>
          )}

          {layoutVersion === 'narrow' ? (
            <Clearfix>
              <Col xs={6}>
                <h4 className={'no-margin font-secondary fs-13 text-uppercase bold lh-16 ' + textClass}>
                  {messages.pick_up_time}
                </h4>
                {dateFromInput}
              </Col>
              <Col xs={6}>
                <h4 className={'no-margin font-secondary fs-13 text-uppercase bold lh-16 ' + textClass}>
                  {messages.drop_off_time}
                </h4>
                {dateToInput}
              </Col>
              <Col xs={10} xsOffset={2}>
                <h4 className={'no-margin font-secondary fs-13 text-uppercase lh-16 bold ' + textClass}>
                  {messages.desired_pickup_location}
                </h4>
                {locationInput}
              </Col>
              <Col xs={12} className="text-right">
                <Button type="submit" bsStyle="primary" bsSize="lg">
                  <FormattedMessage id="we_found_count_cars" values={{ count: countCars.data }} />
                </Button>
              </Col>
            </Clearfix>
          ) : null}

          {layoutVersion === 'wide' ? (
            <Clearfix>
              <Col xs={12} lg={5}>
                <h4 className={'no-margin font-secondary fs-13 text-uppercase lh-16 bold ' + textClass}>
                  {messages.desired_pickup_location}
                </h4>
                {locationInput}
              </Col>
              <Col xs={6} lg={3}>
                <h4 className={'no-margin font-secondary fs-13 text-uppercase bold lh-16 ' + textClass}>
                  {messages.pick_up_time}
                </h4>
                {dateFromInput}
              </Col>
              <Col xs={6} lg={3}>
                <h4 className={'no-margin font-secondary fs-13 text-uppercase bold lh-16 ' + textClass}>
                  {messages.drop_off_time}
                </h4>
                {dateToInput}
              </Col>
              <Col xs={12} mdOffset={6} md={6} lgOffset={0} lg={1}>
                <h4 className="no-margin font-secondary fs-13 lh-16 hidden-xs hidden-sm hidden-md">{'\u00A0'}</h4>{' '}
                {/* dummy element so the button aligns with the input elements */}
                <Button type="submit" bsStyle="primary" className="hidden-xs hidden-sm hidden-md" block>
                  <i className="fa fa-chevron-right" />
                </Button>
                <Button type="submit" bsStyle="primary" bsSize="lg" className="hidden-lg text-uppercase" block>
                  {messages.get_a_quote}
                </Button>
              </Col>
            </Clearfix>
          ) : null}
        </Form>
      </React.Fragment>
    );
  }
}
SearchVersionMap.defaultProps = {
  index: '0',
  lockLocation: false,
};

const mapStateToProps = state => {
  return {
    countCars: state.count.items,
    timezone: getTimezoneFromState(state),
    projectConfig: state.data.projectConfig,
  };
};

export default withRouter(connect(mapStateToProps)(SearchVersionMap));
