import React, { useContext, useEffect, useState } from 'react';

import { getLogger } from '@chedri/base';

const logger = getLogger('before-unload');

const unloadListeners = [];

const unloadHandler = event => {
  logger.debug('Check for unload veto', event);
  const hasChangesMessages = unloadListeners.map(listener => listener()).filter(message => message != null);
  if (hasChangesMessages.length > 0) {
    // eslint-disable-next-line no-param-reassign
    event.returnValue = hasChangesMessages[hasChangesMessages.length - 1];
    logger.debug('Veto', event.returnValue, 'of', hasChangesMessages);
    event.preventDefault();
    return event.returnValue;
  }
  // eslint-disable-next-line no-param-reassign
  delete event.returnValue; // Make sure to leave the page.
  return undefined;
};

function subscribe(callback) {
  unloadListeners.push(callback);
  return () => {
    const index = unloadListeners.findIndex(listener => listener === callback);
    if (index > -1) {
      unloadListeners.splice(index, 1);
    }
  };
}

export const BeforeUnloadContext = React.createContext();
export const useBeforeUnload = () => useContext(BeforeUnloadContext);

/**
 * Sample code
 *
 * !!! Please read https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#Browser_compatibility
 *
 * Use the following to avoid page unload:
 *
 * const { subscribe } = useBeforeUnload();
 * useEffect(() => {
 *   hasChanges = false;
 *   const unsubscribe = subscribe(() => (hasChanges ? messages.has_changes_message || 'You have changes' : undefined));
 *   return () => {
 *     unsubscribe();
 *   };
 * }, []);
 *
 * To avoid rerouting of the React Router also use:
 * <Prompt when={hasChanges} message={messages.has_changes_message || 'You have changes'} />
 *
 */

function BeforeUnload({ children }) {
  const [contextValue] = useState({ subscribe });

  useEffect(() => {
    logger.debug('Add event listener on before unload.');
    window.addEventListener('beforeunload', unloadHandler);
    return () => {
      logger.debug('Remove event listener from before unload.');
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, []);

  return <BeforeUnloadContext.Provider value={contextValue}>{children}</BeforeUnloadContext.Provider>;
}

export default BeforeUnload;
