import React, { createElement, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { transform } from 'js/css-transform';
import HTML from 'html-parse-stringify';
import { Alert } from 'react-bootstrap';
import _, { find as _find, findIndex as _findIndex } from 'lodash';
import he from 'he';
import { isExternal } from 'helpers/http';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import DropdownElement from 'cc-editor/components/Block/DropdownElement';
import Editable from './Block/Editable';
import ImageElement from './Block/ImageElement';
import QRCodeElement from './Block/QRCode';
import BlockContainer from './Block/BlockContainer';
import NavigationElement from './Block/NavigationElement';
import CalendarElement from './Block/CalendarElement';
import CalendarElementOptimized from './Block/CalendarElementOptimized';
import VideoElement from './Block/VideoElement';
import SocialMediaElement from './Block/SocialMediaElement';
import ImageGalleryElement from './Block/ImageGalleryElement';
import ImageGalleryElementTwo from './Block/ImageGalleryElementTwo';
import ContactFormElement from './Block/ContactFormElement';
import RSSFeedsElement from './Block/RSSFeedsElement';
import BackgroundElement from './Block/BackgroundElement';
import GoogleMapsElement from './Block/GoogleMapsElement';
import Calendly from './Block/Calendly';
import ActionButton from './Block/ActionButton';
import DraggableContainer from './Block/DraggableContainer';
import TextSlider from './Block/TextSlider';
import TextSliderTwo from './Block/TextSliderTwo';
import ContactFormElementNew from './Block/ContactFormElementNew';
import QRCodeWithLogo from 'cc-editor/components/Block/QRCodeWithLogo';
import Creatomate from './Block/Creatomate';

class EditorBlock extends React.Component {
  constructor(props) {
    super(props);

    this.forbiddenTags = ['iframe', 'script'];

    this.allowedAttributes = ['class', 'style', 'colspan', 'href', 'target', 'src', 'alt'];
    this.state = { elementToChange: '', bgColorE: '', startUpdate: false, startChangeBackground: false, dataNameE: '' };
  }

  getElementToChange = elementToChange => this.setState({ elementToChange });

  /* BackgroundElement */
  changeBackground = () => {
    let paramToUpdate = '';
    let metaTag = '';
    let metaValue = '';
    const { data, bgColor, updateParam } = this.props;
    _.forEach(data.params, param => {
      // if (elementToChange === param.name) {
      paramToUpdate = param.value;
      metaValue = paramToUpdate.match(/<meta (.*)"\/>/g);
      if (metaValue) paramToUpdate = paramToUpdate.replace(metaValue[0], '');
      metaTag = `<meta content="${bgColor}" name="bgColor"/>`;
      const dataToStore = `${paramToUpdate}${metaTag}`;
      updateParam(data.id, param.name, dataToStore);
      // }
    });
  };

  getLinkData = ({ name, attributes }) => {
    const isLinkExternal = isExternal(attributes.href);
    // Prevents double escaping - the urls are HTML escaped on save in Wysiwyg edior and while rendering by React.
    // This generates double escaping and broken links in the end.
    const decodedHref = he.decode(attributes.href);

    return {
      name: isLinkExternal ? name : Link,
      attributes: {
        ...attributes,
        ...(isLinkExternal
          ? {
              href: decodedHref,
            }
          : {
              to: decodedHref,
            }),
      },
    };
  };

  loop = elements => {
    const {
      index,
      data,
      updateInnerBlock,
      updateParam,
      pageZoom,
      settings,
      imageUpload,
      usedMedia,
      zoomEqualizer,
      appIntl,
      type,
      copyBlock,
      pasteBlock,
      templateBlocks,
      containerSelect,
      blockContainer,
      rasterV,
      pasteData,
      copiedBlock,
      unsetBlockContainer,
      navEntry,
      resetNavEntry,
      publisherInfo,
      bgColor,
      rootUser,
      wlAdminUser,
      isSubmission,
      onlyWhitelabelAdminCanModifyBlocks,
      showKeyboard,
      editionRV,
    } = this.props;
    let result = [];
    let bgColorParam = '';
    let counter = 0;
    _.forEach(elements, (value, key) => {
      counter++; //= uuidLib.v4();
      if (value.type === 'text') {
        result.push(he.decode(value.content));
      } else if (value.type === 'tag' && !_.includes(this.forbiddenTags, value.name)) {
        let { name } = value;
        let attributes = { key };

        if (value.attrs.hasOwnProperty('data-background')) {
          _.forEach(data.params, param => {
            if (param.name === value.attrs['data-name']) {
              const parsetParam = HTML.parse(param.value);
              _.forEach(parsetParam, paramContent => {
                if (paramContent.name === 'meta') {
                  bgColorParam = paramContent.attrs.content;
                } else {
                  bgColorParam = bgColor;
                }
              });
            }
          });
        }

        _.forEach(value.attrs, (attr, attrKey) => {
          switch (attrKey) {
            case 'class': {
              attributes.className = attr;
              break;
            }

            case 'style': {
              if (attr) {
                try {
                  attributes[attrKey] = transform(attr);
                } catch (err) {
                  console.log('error', err);
                }
              }
              break;
            }

            case 'data-background': {
              attributes.className = [`${attributes.className} ${bgColorParam}`].join();
              attributes[attrKey] = attr;
              break;
            }

            default: {
              attributes[attrKey] = attr;
              break;
            }
          }
        });

        let blockDisabled =
          !isSubmission &&
          (data.only_whitelabel_admin_can_modify ?? false) &&
          !wlAdminUser &&
          onlyWhitelabelAdminCanModifyBlocks?.includes(data.id);

        if (name === 'a') {
          ({ name, attributes } = this.getLinkData({ name, attributes }));
        } else if (name === 'html' || name === 'head' || name === 'body') {
          name = 'div';
        }
        if (name === 'meta') {
          // do nothing
        } else if (name === 'img' && _.includes(attributes.className, 'editable')) {
          const param = _find(data.params, ['name', attributes['data-name']]);
          if (typeof param !== 'undefined') {
            const usedImage = _findIndex(usedMedia, function(o) {
              return o.blockid === data.id && o.param === param.name;
            });
            if (usedImage >= 0) {
              attributes['data-original'] = usedMedia[usedImage].key;
              attributes['data-thumb'] = usedMedia[usedImage].thumb;
            }
          }
          result.push(
            <ImageElement
              appIntl={appIntl}
              id={data.id}
              name="img"
              attributes={attributes}
              params={data.params}
              updateParam={updateParam}
              imageUpload={imageUpload}
              usedMedia={usedMedia}
              templateId={settings.id}
              rootUser={rootUser}
              key={data.id + '_' + counter}
              saveDescription={this.props.saveDescription}
              filesUploading={this.props.filesUploading}
              uploadError={this.props.uploadError}
              settings={settings}
              disabled={blockDisabled}
            />
          );
        } else if (name === 'img' || name === 'br' || name === 'hr') {
          if (attributes.src !== undefined) {
            attributes.src = attributes.src.replace(
              '{logo}',
              editionRV?.creator?.parent?.client_main_logo?.variations[0]?.file?.web_link
            );
          }
          result.push(createElement(name, { ...attributes }));
        } else if (_.includes(attributes.className, 'block-container')) {
          result.push(
            <BlockContainer
              appIntl={appIntl}
              id={data.id}
              name={name}
              index={index}
              settings={settings}
              attributes={attributes}
              children={value.children}
              blocks={data.blocks}
              spaceConsumed={data.space_consumed}
              spaceTotal={data.container_space}
              data={data}
              type={type}
              pageZoom={pageZoom}
              updateInnerBlock={updateInnerBlock}
              usedMedia={usedMedia}
              imageUpload={imageUpload}
              copyBlock={copyBlock}
              pasteBlock={pasteBlock}
              templateBlocks={templateBlocks}
              containerSelect={containerSelect}
              unsetBlockContainer={unsetBlockContainer}
              blockContainer={blockContainer}
              rasterV={rasterV}
              pasteData={pasteData}
              copiedBlock={copiedBlock}
              publisherInfo={publisherInfo}
              params={data.params}
              key={data.id + '_' + counter}
              showKeyboard={showKeyboard}
              resizeBlock={this.props.resizeBlock}
              blockBiggerDisable={this.props.blockBiggerDisable}
              getDeviceType={this.props.getDeviceType}
              blockPasteDisable={this.props.blockPasteDisableBlockContainer}
              currentPage={this.props.currentPage}
              setSelectedColor={this.props.setSelectedColor}
              getColorSettings={this.props.getColorSettings}
              deleteSelectedColor={this.props.deleteSelectedColor}
              colorSettings={this.props.colorSettings}
              setFontSizeSettings={this.props.setFontSizeSettings}
              fontSizeSettings={this.props.fontSizeSettings}
              wlAdminUser={wlAdminUser}
              isSubmission={isSubmission}
              onlyWhitelabelAdminCanModifyBlocks={onlyWhitelabelAdminCanModifyBlocks}
            />
          );
        } else if (_.includes(attributes.className, 'ContainerDraggable')) {
          result.push(
            <DraggableContainer
              appIntl={appIntl}
              id={data.id}
              name={name}
              index={index}
              settings={settings}
              attributes={attributes}
              children={value.children}
              blocks={data.blocks}
              spaceConsumed={data.space_consumed}
              spaceTotal={data.containerSpace}
              data={data}
              type={type}
              pageZoom={pageZoom}
              updateInnerBlock={updateInnerBlock}
              usedMedia={usedMedia}
              imageUpload={imageUpload}
              copyBlock={copyBlock}
              pasteBlock={pasteBlock}
              templateBlocks={templateBlocks}
              containerSelect={containerSelect}
              unsetBlockContainer={unsetBlockContainer}
              blockContainer={blockContainer}
              rasterV={rasterV}
              pasteData={pasteData}
              copiedBlock={copiedBlock}
              publisherInfo={publisherInfo}
              params={data.params}
              key={data.id + '_' + counter}
              showKeyboard={showKeyboard}
              resizeBlock={this.props.resizeBlock}
              blockBiggerDisable={this.props.blockBiggerDisable}
              getDeviceType={this.props.getDeviceType}
              blockPasteDisable={this.props.blockPasteDisableBlockContainer}
              setFontSizeSettings={this.props.setFontSizeSettings}
              fontSizeSettings={this.props.fontSizeSettings}
              currentPage={this.props.currentPage}
              setSelectedColor={this.props.setSelectedColor}
              getColorSettings={this.props.getColorSettings}
              deleteSelectedColor={this.props.deleteSelectedColor}
              colorSettings={this.props.colorSettings}
              wlAdminUser={wlAdminUser}
              isSubmission={isSubmission}
              onlyWhitelabelAdminCanModifyBlocks={onlyWhitelabelAdminCanModifyBlocks}
            />
          );
        } else if (_.includes(attributes.className, 'qr-code')) {
          result.push(
            <QRCodeElement
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              key={data.id + '_' + counter}
              settings={settings}
            />
          );
        } else if (_.includes(attributes.className, 'qr-logo-code')) {
          result.push(
            <QRCodeWithLogo
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              key={data.id + '_' + counter}
              settings={settings}
            />
          );
        } else if (_.includes(attributes.className, 'backgroundOptions')) {
          result.push(
            <BackgroundElement
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              key={data.id + '_' + counter}
              getElementToChange={this.getElementToChange}
              settings={settings}
              block={data}
              value={value}
              currentPage={this.props.currentPage}
              setSelectedColor={this.props.setSelectedColor}
              blockContainerId={this.props.blockContainerId ?? undefined}
              colorSettings={this.props.colorSettings}
            />
          );
        } else if (_.includes(attributes.className, 'nav')) {
          result.push(
            <NavigationElement
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              navEntry={navEntry}
              resetNavEntry={resetNavEntry}
              key={data.id + '_' + counter}
              disabled={blockDisabled}
            />
          );
        } else if (_.includes(attributes.className, 'calendar')) {
          result.push(
            <CalendarElement
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              key={data.id + '_' + counter}
            />
          );
        } else if (_.includes(attributes.className, 'cal-optimized')) {
          result.push(
            <CalendarElementOptimized
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              key={data.id + '_' + counter}
            />
          );
        } else if (_.includes(attributes.className, 'video')) {
          result.push(
            <VideoElement
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              imageUpload={imageUpload}
              key={data.id + '_' + counter}
            />
          );
        } else if (_.includes(attributes.className, 'socialmedia')) {
          result.push(
            <SocialMediaElement
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              key={data.id + '_' + counter}
            />
          );
        } else if (_.includes(attributes.className, 'calendly')) {
          result.push(
            <Calendly
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              key={data.id + '_' + counter}
              settings={settings}
            />
          );
        } else if (_.includes(attributes.className, 'actionButton')) {
          result.push(
            <ActionButton
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              key={data.id + '_' + counter}
              settings={settings}
              navEntry={navEntry}
              disabled={blockDisabled}
            />
          );
        } else if (_.includes(attributes.className, 'dropdownmenu')) {
          result.push(
            <DropdownElement
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              key={data.id + '_' + counter}
              settings={settings}
              navEntry={navEntry}
            />
          );
        } else if (_.includes(attributes.className, 'imagegallery')) {
          result.push(
            <ImageGalleryElement
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              updateSpecialBlockAttributes={this.props.updateSpecialBlockAttributes}
              imageUpload={imageUpload}
              usedMedia={usedMedia}
              templateId={settings.id}
              filesUploading={this.props.filesUploading}
              key={data.id + '_' + counter}
              rootUser={rootUser}
              saveDescription={this.props.saveDescription}
            />
          );
        } else if (_.includes(attributes.className, 'biggallery')) {
          result.push(
            <ImageGalleryElementTwo
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              imageUpload={imageUpload}
              usedMedia={usedMedia}
              templateId={settings.id}
              filesUploading={this.props.filesUploading}
              key={data.id + '_' + counter}
              rootUser={rootUser}
              saveDescription={this.props.saveDescription}
            />
          );
        } else if (_.includes(attributes.className, 'textslider')) {
          result.push(
            <TextSlider
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              imageUpload={imageUpload}
              usedMedia={usedMedia}
              templateId={settings.id}
              filesUploading={this.props.filesUploading}
              key={data.id + '_' + counter}
              rootUser={rootUser}
              saveDescription={this.props.saveDescription}
              disabled={blockDisabled}
            />
          );
        } else if (_.includes(attributes.className, 'slidertxtbig')) {
          result.push(
            <TextSliderTwo
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              imageUpload={imageUpload}
              usedMedia={usedMedia}
              templateId={settings.id}
              filesUploading={this.props.filesUploading}
              key={data.id + '_' + counter}
              rootUser={rootUser}
              saveDescription={this.props.saveDescription}
              disabled={blockDisabled}
            />
          );
        } else if (_.includes(attributes.className, 'contactform')) {
          result.push(
            <ContactFormElement
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              imageUpload={imageUpload}
              usedMedia={usedMedia}
              filesUploading={this.props.filesUploading}
              key={data.id + '_' + counter}
              disabled={blockDisabled}
            />
          );
        } else if (_.includes(attributes.className, 'newform')) {
          result.push(
            <ContactFormElementNew
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              imageUpload={imageUpload}
              usedMedia={usedMedia}
              filesUploading={this.props.filesUploading}
              key={data.id + '_' + counter}
              disabled={blockDisabled}
            />
          );
        } else if (_.includes(attributes.className, 'newmap')) {
          result.push(
            <GoogleMapsElement
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              imageUpload={imageUpload}
              usedMedia={usedMedia}
              filesUploading={this.props.filesUploading}
              key={data.id + '_' + counter}
              disabled={blockDisabled}
            />
          );
        } else if (_.includes(attributes.className, 'rssfeeds')) {
          result.push(
            <RSSFeedsElement
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              imageUpload={imageUpload}
              usedMedia={usedMedia}
              key={data.id + '_' + counter}
            />
          );
        } else if (_.includes(attributes.className, 'editable')) {
          // it's a tag that the user can edit
          result.push(
            <Fragment key={data.id + '_' + counter}>
              <Editable
                appIntl={appIntl}
                id={data.id}
                name={name}
                settings={settings}
                attributes={attributes}
                children={value.children}
                params={data.params}
                pageZoom={pageZoom}
                updateParam={updateParam}
                publisherInfo={publisherInfo}
                errors={data.errors}
                bgColor={bgColorParam}
                getElementToChange={this.getElementToChange}
                key={data.id + '_' + counter}
                getDeviceType={this.props.getDeviceType}
                showKeyboard={showKeyboard}
                bigger={data.bigger}
                resizeBlock={this.props.resizeBlock}
                blockBiggerDisable={this.props.blockBiggerDisable}
                autoResizeSupported={this.props.autoResizeSupported}
                setFontSizeSettings={this.props.setFontSizeSettings}
                disabled={blockDisabled}
              />
            </Fragment>
          );
        } else if (_.includes(attributes.className, 'creatomate')) {
          result.push(
            <Creatomate
              appIntl={appIntl}
              id={data.id}
              name={name}
              zoomEqualizer={zoomEqualizer}
              attributes={attributes}
              params={data.params}
              pageZoom={pageZoom}
              updateParam={updateParam}
              imageUpload={imageUpload}
              key={data.id + '_' + counter}
            />
          );
        } else if (attributes.isRoot) {
          result = this.loop(value.children);
        } else {
          result.push(createElement(name, { ...attributes }, this.loop(value.children)));
        }
      }
    });

    return result;
  };

  doNothing = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  render() {
    const { data } = this.props;
    if (data && data.template) {
      try {
        return this.loop(HTML.parse('<div isRoot="true">' + data.template + '</div>'));
      } catch (e) {
        console.log(e);
        // if we cannot parse the HTML, we can show an error
        return (
          <div className="rich-editor-content">
            <Alert bsStyle="danger">Incorrect HTML detected. Please check your code in the editor.</Alert>
          </div>
        );
      }
    }

    return null;
  }
}

EditorBlock.defaultProps = {
  textSubstitute: null,
  content: null,
};

// export default EditorBlock;

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    viewActive: state.layout.viewActive,
    editionRV: state.editor.editionRV,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(EditorBlock)));
