import React from 'react';
import { OverlayTrigger, Tooltip, Overlay, Popover } from 'react-bootstrap';

export default class FormTextarea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValue: props.value ? props.value : '',
      value: props.value ? props.value : '',
      focused: false,
    };

    this.inputRef = React.createRef();
  }

  checkFocused = () => {
    return this.state.focused ? 'focused ' : '';
  };

  checkRequired = () => {
    return this.props.meta.validate.rules.indexOf('required') !== -1 ? 'required ' : '';
  };

  checkError = () => {
    return this.props.validation.valid ? '' : 'has-error ';
  };

  // check if user may read this field
  checkAccess = () => {
    return this.props.meta.acl.read;
  };

  // check if user may write to this field
  checkReadOnly = () => {
    return !this.props.meta.acl.write;
  };

  catchFocus = () => {
    this.inputRef.current.focus();
  };

  onChange = value => {
    this.setState({ value });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.onChange(this.props.name, value);
    }, 150);
  };

  checkDisabled = () => {
    const { disabled, meta } = this.props;
    if (disabled) {
      return true;
    }
    if (!meta.acl.write) {
      return true;
    }
    return false;
  };

  setValue = value => {
    this.setState({ value });
    this.onChange(value);
  };

  render() {
    const { label, name, validation, infoText } = this.props;
    const { value } = this.state;

    return (
      <div
        className={
          'form-group form-group-default ' +
          this.checkFocused() +
          this.checkRequired() +
          this.checkError() +
          (this.checkDisabled() ? 'disabled ' : '')
        }
        onClick={this.catchFocus}
      >
        <label>
          {label}
          {!!infoText && (
            <span className="m-l-5">
              <OverlayTrigger placement="top" overlay={<Tooltip>{infoText}</Tooltip>}>
                <i className="far fa-question-circle cursor-help" />
              </OverlayTrigger>
            </span>
          )}
        </label>
        <textarea
          ref={this.inputRef}
          value={value || ''}
          type={this.props.type}
          className="form-control"
          name={name}
          onChange={e => this.onChange(e.target.value)}
          onFocus={() => this.setState({ focused: true })}
          onBlur={() => this.setState({ focused: false })}
          disabled={this.checkDisabled()}
          style={{ width: '100%', height: 'auto' }}
          rows={this.props.rows}
        />

        {!validation.valid && (
          <Overlay show={this.state.focused} target={this.inputRef.current} placement="top">
            <Popover className="popover-modal" id={`error_${name}_${label}`}>
              {validation.helpText}
            </Popover>
          </Overlay>
        )}
      </div>
    );
  }
}
FormTextarea.defaultProps = {
  label: '',
  meta: { validate: { rules: '' }, acl: { read: true, write: true } },
  validation: { valid: true },
  value: '',
  onChange: () => {},
  disabled: false,
  infoText: '',
  rows: 8,
};
