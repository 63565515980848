import React from 'react';
import Carousel from 'nuka-carousel';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import { dispatchResizeEvent } from 'helpers/window';

import AbstractCmsElement from './AbstractCmsElement';

export default class CmsElementCarousel extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  afterSlide = () => {
    dispatchResizeEvent();
  };

  render() {
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-carousel ' + shownClass + this.getResponsiveClasses() + this.getCssClasses()}
      >
        {this.getElementStyle()}

        {this.props.children ? (
          <Carousel afterSlide={this.afterSlide}>
            {this.props.children.map((child, i) => {
              const params = child.props.params.slice();
              params.push({ name: 'fullHeight', value: this.getParamValue('fullHeight', 'no') });

              return React.cloneElement(child, { params, key: i });
            })}
          </Carousel>
        ) : null}

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementCarousel.defaultProps = {
  index: '0',
};
