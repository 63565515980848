export default function docRequiredIssuingCity(entity) {
  let isValid = true,
    helpTextTranslationIndex = 'doc_issuing_city_required',
    fields = [];

  if (!entity.getIssuingCity()) {
    isValid = false;
    fields = [{ name: 'issuingCity', fieldTree: [] }];
  }

  return {
    valid: isValid,
    rule: 'docRequiredIssuingCity',
    helpTextTranslationIndex,
    fields,
  };
}
