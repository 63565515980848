export const SET_RESERVATION_FILTERS = 'SET_RESERVATION_FILTERS';
export const SET_RESERVATION_FILTER = 'SET_RESERVATION_FILTER';
export const REMOVE_RESERVATION_FILTER = 'REMOVE_RESERVATION_FILTER';
export const RESET_RESERVATION_FILTER = 'RESET_RESERVATION_FILTER';

export function setFilters(filters) {
  return {
    type: SET_RESERVATION_FILTERS,
    payload: { filters },
  };
}

export function setFilter(name, values) {
  return {
    type: SET_RESERVATION_FILTER,
    payload: { name, values },
  };
}

export function removeFilter(name, value) {
  return {
    type: REMOVE_RESERVATION_FILTER,
    payload: { name, value },
  };
}

export function resetFilter() {
  return {
    type: RESET_RESERVATION_FILTER,
    payload: null,
  };
}
