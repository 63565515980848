import {
  UPDATE_FORM_CONTEXT,
  RESET_FORM_CONTEXT,
  FORM_QUEUE,
  FORM_FINISHED,
  FORM_ERROR,
  FORM_RESET,
} from 'actions/FormStatusActions';
import { SOCKET_EMIT, SOCKET_EVENT } from 'actions/SocketActions';
import { CONVERSATION_ENDED } from 'actions/ChatterActions';
import { WEBRTCCALL_ENDED } from 'actions/WebRTCCallActions';

const defaultFormState = {
  pending: false,
  hasError: false,
  errors: [],
  response: null,
};

const formNames = [
  'clientNew',
  'userNew',
  'clientEdit',
  'userEdit',
  'passwordChange',
  'holdingInfos',
  'noteNew',
  'pipelineNew',
  'pipelineEdit',
  'templateNew',
  'templateEdit',
  'questionnaireNew',
  'questionnaireEdit',
  'profileEdit',
  'productNew',
  'productEdit',
  'invoiceNew',
  'invoiceEdit',
  'invoiceCancel',
  'quoteNew',
  'quoteEdit',
  'paymentScheduleNew',
  'paymentScheduleEdit',
  'paymentRecordPayment',
  'paymentMarkAsUnpaid',
  'contactNew',
  'forgotPassword',
  'forgotPasswordReset',
  'acceptQuote',
  'rejectQuote',
  'convertQuoteToInvoice',
  'convertQuoteFromTemplate',
  'messageNew',
  'textMessageNew',
  'documentNew',
  'documentEdit',
  'documentReview',
  'docTypeNew',
  'docTypeEdit',
  'teamNew',
  'teamEdit',
  'brandNew',
  'brandEdit',
  'categoryNew',
  'categoryEdit',
  'categoryTree',
  'attributeNew',
  'attributeEdit',
  'attributeGroupNew',
  'attributeGroupEdit',
  'itemNew',
  'itemEdit',
  'variantNew',
  'variantEdit',
  'companyLocations',
  'cmsNewsletterForm',
  'leadNew',
  'leadEdit',
  'leadCommentNew',
  'leadWon',
  'leadLost',
  'leadDelete',
  'leadReopen',
  'leadChangeStage',
  'leadChangePipeline',
  'leadChangeTeam',
  'pageNew',
  'pageEdit',
  'pageDraftNew',
  'pageDraftEdit',
  'pageDuplicate',
  'cmsElementNew',
  'cmsElementEdit',
  'cmsElementPreviewImage',
  'cmsElementDuplicate',
  'leadDocumentNew',
  'leadDocumentEdit',
  'orderDocumentNew',
  'orderDocumentEdit',
  'modelNew',
  'modelEdit',
  'modelGroupNew',
  'modelGroupEdit',
  'modelAssetNew',
  'modelAssetEdit',
  'modelAssetImage',
  'reservationNew',
  'orderNew',
  'orderEdit',
  'confirmRegistration',
  'mobiledeImport',
  'pageSeoImage',
  'dataPushNew',
  'dataPushEdit',
  'whiteLabelNew',
  'whiteLabelEdit',
  'whiteLabelPluginNew',
  'whiteLabelPluginEdit',
  'whiteLabelMainLogo',
  'whiteLabelMainLogoSvg',
  'whiteLabelWatermarkLogo',
  'whiteLabelWatermarkLogoSvg',
  'clientMainLogo',
  'whiteLabelScrollLogo',
  'whiteLabelScrollLogoSvg',
  'whiteLabelMailLogo',
  'whiteLabelPluginMainLogo',
  'cmsLeadForm',
  'cmsLoginForm',
  'cmsSignupForm',
  'leadCatcherNew',
  'leadCatcherEdit',
  'cmsFile',
  'checkoutSignupForm',
  'sepaAgreementNew',
  'pluginActivate',
  'pluginTerminate',
  'pluginPartialActivate',
  'pluginRequestActivate',
  'pluginActivationRequest',
  'dynamicDataDefinitionNew',
  'dynamicDataDefinitionEdit',
  'myParentEdit',
  'clientAcceptTerms',
  'clientAcceptTermsOfUse',
  'clientAcceptOrderProcessingContract',
  'orderActionApproveCarSale',
  'orderActionEnterVinNumber',
  'orderActionEnterEinNumber',
  'orderActionMarkCarAsPaid',
  'orderActionSendPaymentInstructions',
  'orderActionCancelOrder',
  'orderActionPickupInvite',
  'orderActionPickedUp',
  'orderActionCancelBooking',
  'orderActionMarkAssigneeKroschkeDocumentsSent',
  'orderActionMarkClientKroschkeDocumentsSent',
  'orderActionDefineCustomLicensePlateNumber',
  'salesActivityEdit',
  'salesActivityFiltersetAdd',
  'serviceRequestEdit',
  'socialMediaLoginSecretNew',
  'socialMediaLoginSecretEdit',
  'externalAccountNew',
  'externalAccountEdit',
  'whiteLabelFaviconsAll',
  'whiteLabelFavicon',
  'whiteLabelFaviconIphone',
  'whiteLabelFaviconIphoneRetina',
  'whiteLabelFaviconIpad',
  'whiteLabelFaviconIpadRetina',
  'whiteLabelFaviconAndroid',
  'whiteLabelFaviconMicrosoft',
  'leadPerMailRuleNew',
  'leadPerMailRuleEdit',
  'pluginSettingsEdit',
  'initSoleRepresentative',
  'companySignup',
  'priceNegotiationNew',
  'priceNegotiationEdit',
  'communityJoin',
  'communityLeave',
  'clientCarNew',
  'clientCarEdit',
  'carServiceOrder',
  'carServiceNew',
  'carServiceEdit',
  'carServiceStationNew',
  'carServiceStationEdit',
  'addUserToTeam',
  'textractDriversLicense',
  'cmsTemplateNew',
  'cmsTemplateEdit',
  'cmsTemplateDuplicate',
  'cmsTemplatePreviewImage',
  'cmsTemplateDraftNew',
  'cmsTemplateDraftEdit',
  'docTemplateNew',
  'docTemplateEdit',
  'cmsQuestionnaireForm',
  'filledQuestionnaireNew',
  'filledQuestionnaireEdit',
  'filledQuestionnaireOpened',
  'autoresponseNew',
  'autoresponseEdit',
  'customerChatNew',
  'customerChatEdit',
  'customerVideoCallNew',
  'customerVideoCallEdit',
  'carDataImport',
  'mainMenuVersionNew',
  'mainMenuVersionEdit',
  'leadTaggerNew',
  'leadTaggerEdit',
  'share',
  'shareSettings',
  'leadAssignmentStrategyNew',
  'leadAssignmentStrategyEdit',
  'leadEscalationRuleNew',
  'leadEscalationRuleEdit',
  'productTemplateNew',
  'productTemplateEdit',
  'campaignNew',
  'campaignEdit',
  'commissionReceiverEdit',
  'clientProvisionEdit',
  'itemProvisionEdit',
  'billingTargetEdit',
  'paymentReceiverEdit',
  'budgetNew',
  'budgetEdit',
  'couponNew',
  'couponEdit',
  'orderAuthorization',
  'addTarget',
  'addItem',
  'addCategory',
  'lpFavIcon',
  'allowedDomainNew',
  'allowedDomainEdit',
  'emailPreview',
];

const initialState = Object.fromEntries(formNames.map(name => [name, { ...defaultFormState }]));
initialState.context = null;

export default function formStatus(state = initialState, action) {
  switch (action.type) {
    case FORM_QUEUE: {
      const newState = { ...state };
      newState[action.payload] = {
        pending: true,
        hasError: false,
        errors: [],
        response: null,
      };
      return newState;
    }

    case FORM_FINISHED: {
      const newState = { ...state };
      newState[action.payload.formName] = {
        pending: false,
        hasError: false,
        errors: [],
        response: action.payload.response.data,
      };
      return newState;
    }

    case FORM_ERROR: {
      const newState = { ...state };
      newState[action.payload.formName] = {
        pending: false,
        hasError: true,
        errors: action.payload.error && action.payload.error.response ? action.payload.error.response.data : null,
        response: null,
      };
      return newState;
    }

    case FORM_RESET: {
      const newState = { ...state };
      newState[action.payload] = {
        pending: false,
        hasError: false,
        errors: [],
        response: null,
      };
      return newState;
    }

    case UPDATE_FORM_CONTEXT: {
      const newState = { ...state };

      newState.context = { ...action.payload };

      return newState;
    }

    case RESET_FORM_CONTEXT: {
      const newState = { ...state };

      newState.context = null;

      return newState;
    }

    case SOCKET_EMIT: {
      // const { eventName, args } = action.payload;
      break;
    }

    case SOCKET_EVENT: {
      // const { eventName, args } = action.payload;
      break;
    }

    case CONVERSATION_ENDED: {
      const { myRole } = action.payload;

      if (myRole === 'customer') {
        return { ...state, customerChatNew: { ...defaultFormState } };
      }

      break;
    }

    case WEBRTCCALL_ENDED: {
      const { myRole } = action.payload;

      if (myRole === 'customer') {
        return { ...state, customerVideoCallNew: { ...defaultFormState } };
      }

      break;
    }
  }

  return state;
}
