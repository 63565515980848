import React from 'react';

export default class EditorApplyStyles extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render () {
    const {styles} = this.props || {};
    return <style jsx="true">{`${styles}`}</style>
  }
}
