import { ENTITY_META_PENDING, ENTITY_META_FULFILLED, ENTITY_META_REJECTED } from 'actions/EntityMetaActions';

// it may happen that an entity consists of complex types
// in such case, look for those complex types and document them
const lookForComplexTypes = (prevState, complexMeta) => {
  const state = { ...prevState };

  const addType = ({ name, data }) => {
    if (state[name] && !state[name].pending) {
      return;
    }

    state[name] = {
      pending: false,
      hasError: false,
      errors: [],
      data: { ...data },
    };

    const values = Object.values(state[name].data);

    for (let i = 0; i < values.length; i++) {
      const value = values[i];
      const { complexType, complexMeta } = value;
      if (complexType && complexMeta) {
        if (!state[name]?.pending) {
          addType(complexMeta);
          delete value.complexMeta; // why
        }
      }
    }
  };

  addType(complexMeta);

  return state;
};

const publicStringDataEntry = {
  array: false,
  complexType: false,
  type: 'string',
  validate: { rules: '', messages: '' },
};
const defaultState = {
  ItemVariantsList: {
    data: {
      list: {
        array: true,
        complexType: true,
        name: 'list',
        type: 'Items:Variant',
        validate: { rules: '', messages: '' },
      },
    },
  },
  LocationList: {
    data: {
      locations: {
        array: true,
        complexType: true,
        name: 'locations',
        type: 'Client:EmbeddedClientLocation',
        validate: { rules: '', messages: '' },
      },
    },
  },
  DocsList: {
    data: {
      list: { array: true, complexType: true, name: 'list', type: 'Docs:Doc', validate: { rules: '', messages: '' } },
    },
  },
  'Main:PublicCreator': {
    data: {
      id: { ...publicStringDataEntry, name: 'id' },
      name: { ...publicStringDataEntry, name: 'name' },
      avatar: { ...publicStringDataEntry, name: 'avatar' },
    },
  },
  'Main:PublicOwner': {
    data: {
      id: { ...publicStringDataEntry, name: 'id' },
      company: { ...publicStringDataEntry, name: 'name' },
      firstName: { ...publicStringDataEntry, name: 'first_name' },
      lastName: { ...publicStringDataEntry, name: 'last_name' },
      street: { ...publicStringDataEntry, name: 'street' },
      houseNumber: { ...publicStringDataEntry, name: 'house_number' },
      apartmentNumber: { ...publicStringDataEntry, name: 'apartment_number' },
      addressLine2: { ...publicStringDataEntry, name: 'address_line2' },
      zipCode: { ...publicStringDataEntry, name: 'zip_code' },
      city: { ...publicStringDataEntry, name: 'city' },
      countryName: { ...publicStringDataEntry, name: 'country_name' },
      avatar: { ...publicStringDataEntry, name: 'avatar' },
      email: { ...publicStringDataEntry, name: 'email' },
      phone: { ...publicStringDataEntry, name: 'phone' },
      fax: { ...publicStringDataEntry, name: 'fax' },
    },
  },
};

export default function entityMeta(state = defaultState, action) {
  switch (action.type) {
    case ENTITY_META_PENDING: {
      const newState = { ...state };
      const oldMetaState = newState[action.payload.entityMetaIdentifier];

      newState[action.payload.entityMetaIdentifier] = {
        ...oldMetaState,
        pending: true,
        hasError: false,
        errors: [],
        data: {},
      };
      return newState;
    }

    case ENTITY_META_FULFILLED: {
      const newState = { ...state };

      console.log('entityMeta ENTITY_META_FULFILLED', action.payload.response.data.name);

      return lookForComplexTypes(newState, action.payload.response.data);
    }

    case ENTITY_META_REJECTED: {
      const newState = { ...state };
      const oldMetaState = newState[action.payload.entityMetaIdentifier];

      newState[action.payload.entityMetaIdentifier] = {
        ...oldMetaState,
        pending: false,
        hasError: true,
        errors: action.payload.response ? action.payload.response.data : {},
        data: {},
      };

      return newState;
    }

    case 'HANDLE_LOGIN_FULFILLED':
    case 'FETCH_CREDENTIALS_FULFILLED': {
      const newState = { ...state };
      newState['PriceNegotiation:PriceNegotiation'] = undefined;

      return newState;
    }
  }

  return state;
}
