import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { updateActiveCms } from 'actions/LayoutActions';

import Data from 'components/Data';
import CmsPage from 'components/CmsPage';

import Loader from 'components/layout/Loader';

import CompanySignup from 'components/forms/auth/CompanySignup';
import CompanySignupWizard from 'components/forms/auth/CompanySignupWizard';
import { Col, Row } from 'react-bootstrap';
import SignupFormCDU from '../components/forms/auth/SignupFormCDU';
import SignupFormRobethood from '../components/forms/auth/SignupFormRobethood';
import SignupForm from "../components/forms/auth/SignupForm";
import SignupFormIHK from "../components/forms/auth/SignupFormIHK";
import SignupFormJU from 'components/forms/auth/SignupFormJU';

class Signup extends React.Component {
  componentDidMount = () => {
    const { updateActiveCms } = this.props;
    updateActiveCms('cmsSystemPageSignup');
  };

  componentWillUnmount = () => {
    const { updateActiveCms } = this.props;
    updateActiveCms(null);
  };

  componentDidUpdate = prevProps => {
    const { appIntl, history, formStatus } = this.props;

    if (!formStatus.pending && prevProps.formStatus.pending && !formStatus.hasError) {
      history.push(appIntl.urlPrefix + 'signedup');
    }
  };

  render() {
    const { cmsPage, projectConfig, height } = this.props;

    return (
      <React.Fragment>
        {!cmsPage.data.id && <Data dataName="cmsSystemPageSignup" url="/api/pages/signup.json" data={cmsPage} />}

        {cmsPage.pending ? (
          <div className="content relative" style={{ height: `${height}px` }}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%)' }}>
              <Loader />
            </div>
          </div>
        ) : (
          <CmsPage data={cmsPage.data} doUpdateActiveCms={false} />
        )}

        {/* projectConfig.data.id === '5a62f5367c567e51ed92503a' && !cmsPage.pending && <CompanySignupWizard /> */}
        {projectConfig.data.id === '6036474f08d24f063255f4a3' && !cmsPage.pending && (
          <div className="p-b-30">
            <Row className="signupFormCDU">
              <Col xs={3} />
              <Col xs={6}>
                <SignupFormCDU />
              </Col>
              <Col xs={3} />
            </Row>
          </div>
        )}
        {projectConfig.data.id === '6234463689d0c7126367c2ce' && !cmsPage.pending && (
          <div className="p-b-30">
            <Row className="signupFormRobethood">
              <Col xs={3} />
              <Col xs={6}>
                <SignupFormRobethood />
              </Col>
              <Col xs={3} />
            </Row>
          </div>
        )}
        {projectConfig.data.id === '61ee81d09f72bc10fd4540ca' && !cmsPage.pending && (
          <div className="p-b-30">
            <Row className="signupFormIHK">
              <Col xs={3} />
              <Col xs={6}>
                <SignupFormIHK />
              </Col>
              <Col xs={3} />
            </Row>
          </div>
        )}
        {projectConfig.data.id === '6537645b9fb6114fec66430a' && !cmsPage.pending && (
          <div className="p-b-30">
            <Row className="signupFormJU">
              <Col xs={3} />
              <Col xs={6}>
                <SignupFormJU />
              </Col>
              <Col xs={3} />
            </Row>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    height: state.layout.height,
    appIntl: state.intl,
    cmsPage: state.data.cmsSystemPageSignup,
    formStatus: state.formStatus.cmsSignupForm,
    projectConfig: state.data.projectConfig,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateActiveCms: activeCms => {
      dispatch(updateActiveCms(activeCms));
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Signup)
);
