import React from 'react';
import { connect } from 'react-redux';
import { Col, Clearfix } from 'react-bootstrap';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { get as _get } from 'lodash';

import FormInput from 'components/forms/FormInput';

import Loader from 'components/layout/Loader';

import List from 'components/List';
import IconRadioButton from '../../forms/IconRadioButton';

//
class PaymentContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ccFocused: false, // is the stripe credit card element focused?
      newPaymentMethod: false, // do we display a new credit card form or a list of known credit cards (coming from stripePaymentMethods)
      paymentMethodId: null, // which card was selected by the user?
    };
  }

  componentDidUpdate = prevProps => {
    const { stripePaymentMethods } = this.props;

    if (prevProps.stripePaymentMethods.pending && !stripePaymentMethods.pending) {
      const firstPaymentMethodId = _get(stripePaymentMethods, 'data.list[0].id');

      // if the paymentMethods are loaded, take the first if exists
      if (firstPaymentMethodId) {
        // if yes, assign it as automatically to the checkout state
        this.changeType(null, 'existing');
      } else {
        this.changeType(null, 'new');
      }
    }
  };

  changeType = (e = null, value = null) => {
    const { checkout, stripePaymentMethods, doUpdatePayment } = this.props;

    switch (value || e.target.value) {
      case 'existing': {
        const paymentMethodId = _get(stripePaymentMethods, 'data.list[0].id');
        this.setState({ newPaymentMethod: false, paymentMethodId }, () => {
          // update the checkout redux store
          doUpdatePayment(checkout.activePaymentPlugin, checkout.payment, {
            paymentMethodId,
            paymentMethodType: 'card',
          });
        });
        break;
      }

      case 'new': {
        this.setState({ newPaymentMethod: true }, () => {
          // update the checkout redux store
          doUpdatePayment(checkout.activePaymentPlugin, checkout.payment);
        });
        break;
      }
    }
  };

  selectCardPaymentMethod = e => {
    const { checkout, doUpdatePayment } = this.props;
    const paymentMethodId = e.target.value;

    this.setState({ paymentMethodId }, () => {
      // update the checkout redux store
      doUpdatePayment(checkout.activePaymentPlugin, checkout.payment, { ...checkout.paymentData, paymentMethodId });
    });
  };

  updateCreditCardHolder = (formName, creditCardHolder) => {
    const { checkout, doUpdatePayment } = this.props;
    doUpdatePayment(checkout.activePaymentPlugin, checkout.payment, { ...checkout.paymentData, creditCardHolder });
  };

  ccFocus = () => {
    this.setState({ ccFocused: true });
  };

  ccBlur = () => {
    this.setState({ ccFocused: false });
  };

  getIcon = brand => {
    switch (brand) {
      case 'Visa':
        return <i className="fab fa-cc-visa" />;

      case 'American Express':
        return <i className="fab fa-cc-amex" />;

      case 'MasterCard':
        return <i className="fab fa-cc-mastercard" />;

      case 'Discover':
        return <i className="fab fa-cc-discover" />;

      case 'JCB':
        return <i className="fab fa-cc-jcb" />;
    }

    return <i className="fas fa-credit-card" />;
  };

  render() {
    const {
      intl: { messages },
      clientId,
      stripePaymentMethods,
      disabled,
      checkout,
      employee,
    } = this.props;
    const { ccFocused, newPaymentMethod, paymentMethodId } = this.state;

    const creditCardHolder =
      checkout.paymentData.creditCardHolder ||
      _get(checkout, 'userData.firstName', '') + ' ' + _get(checkout, 'userData.lastName', '');

    return (
      <>
        {!!clientId && (
          <List
            listName="stripePaymentMethods"
            url="/api/stripe/paymentmethods.json"
            urlData={{ clientId }}
            list={stripePaymentMethods}
            noRender
          />
        )}

        {!!clientId && stripePaymentMethods.pending ? (
          <Loader />
        ) : (
          <>
            {!!stripePaymentMethods.data.list.length && (
              <>
                {!employee && (
                  <div>
                    <IconRadioButton
                      key="stripeExisting"
                      id="stripeExisting"
                      name="stripeType"
                      value="existing"
                      checked={!newPaymentMethod}
                      label={messages.pay_with_saved_credit_card}
                      onChange={this.changeType}
                    />
                  </div>
                )}

                {!newPaymentMethod && (
                  <div className="p-l-20">
                    {stripePaymentMethods.data.list.map(paymentMethod => (
                      <div key={paymentMethod.id}>
                        <div>
                          <IconRadioButton
                            name="paymentMethod"
                            disabled={disabled || checkout.paymentPending}
                            value={paymentMethod.id}
                            checked={paymentMethodId === paymentMethod.id}
                            label={
                              <>
                                {this.getIcon(paymentMethod.credit_card_brand)}{' '}
                                {`**** **** **** ${paymentMethod.credit_card_last4}, ${paymentMethod.credit_card_holder}`}
                              </>
                            }
                            title={`**** **** **** ${paymentMethod.credit_card_last4}, ${paymentMethod.credit_card_holder}`}
                            onChange={this.selectCardPaymentMethod}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {!employee && (
                  <div>
                    <IconRadioButton
                      id="stripeNew"
                      name="stripeType"
                      disabled={disabled || checkout.paymentPending}
                      value="new"
                      checked={newPaymentMethod}
                      label={messages.pay_with_new_credit_card}
                      onChange={this.changeType}
                    />
                  </div>
                )}
              </>
            )}

            {!employee && (!stripePaymentMethods.data.list.length || newPaymentMethod) && (
              <Clearfix>
                <Col xs={12} className="no-padding">
                  <div>
                    <FormInput
                      useFormGroupDefault={false}
                      name="cardHolder"
                      value={creditCardHolder}
                      label={messages.credit_card_holder}
                      onChange={this.updateCreditCardHolder}
                      disabled={disabled || checkout.paymentPending}
                    />
                    <div className={'form-group p-b-10 ' + (ccFocused ? 'focused ' : '')}>
                      <label>{messages.payment_creditCard}</label>
                      <div className="m-t-10">
                        <CardElement
                          style={{ base: { fontSize: '14px' } }}
                          hidePostalCode
                          onFocus={this.ccFocus}
                          onBlur={this.ccBlur}
                          disabled={disabled || checkout.paymentPending}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Clearfix>
            )}

            {employee && !stripePaymentMethods.data.list.length && (
              <div>{messages.add_credit_card_data_in_next_step}</div>
            )}
          </>
        )}
      </>
    );
  }
}
PaymentContent.defaultProps = {
  preview: false,
};

const mapStateToProps = state => {
  return {
    stripePaymentMethods: state.list.stripePaymentMethods,
  };
};

export default injectStripe(connect(mapStateToProps)(PaymentContent));
