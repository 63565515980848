import { find as _find } from 'lodash';

import { PROJECT_NAME, BASENAME } from 'js/constants';

export const isPluginActive = (pluginId, whiteLabelSettings) => {
  const plugin = _find(
    whiteLabelSettings &&
      ((whiteLabelSettings.getPlugins && whiteLabelSettings.getPlugins()) || whiteLabelSettings.plugins),
    ['id', pluginId]
  );

  if (plugin) {
    return plugin.getIsActive ? plugin.getIsActive() : plugin.is_active;
  }

  return false;
};

export const getMainDomain = (whiteLabelSettings, withProtocol = true, withBasePath = true) => {
  const isSubdomain = whiteLabelSettings.domains[0].indexOf('.') === -1;

  const protocol = 'https://';

  const mainRootDomain =
    window.location.origin.indexOf('.local') !== -1 ? `${PROJECT_NAME}.local` : `${PROJECT_NAME}.com`;

  const domain = isSubdomain ? whiteLabelSettings.domains[0] + '.' + mainRootDomain : whiteLabelSettings.domains[0];

  return (withProtocol ? protocol : '') + domain + (withBasePath ? BASENAME : '');
};

export const getPluginSettings = (pluginId, whiteLabelSettings) => {
  const plugin = _find(whiteLabelSettings.plugins, ['id', pluginId]);
  return plugin && plugin.is_active && plugin.settings ? plugin.settings : null;
};

export const getPluginSettingValue = (pluginId, whiteLabelSettings, settingName, defaultValue = null) => {
  const settings = getPluginSettings(pluginId, whiteLabelSettings);
  const setting = _find(settings, ['name', settingName]);

  return setting && setting.value ? setting.value : defaultValue;
};

export const getPluginSettingValues = (pluginId, whiteLabelSettings, settingName, defaultValue = null) => {
  const settings = getPluginSettings(pluginId, whiteLabelSettings);
  const setting = _find(settings, ['name', settingName]);

  return setting && setting.values ? setting.values : defaultValue;
};

export const pluginTypes = messages => {
  return [
    {
      label: messages.subscription_plan,
      value: 'subscription_plan',
    },
    {
      label: messages.main_plugin,
      value: 'main_plugin',
    },
    {
      label: messages.plugin,
      value: 'plugin',
    },
    {
      label: messages.payment_method,
      value: 'payment',
    },
  ];
};

export const arePerformanceCookiesUsed = whiteLabelSettings => {
  return !!whiteLabelSettings && !!whiteLabelSettings.google_analytics_tracking_id;
};

export const areTargetingCookiesUsed = whiteLabelSettings => {
  return !!whiteLabelSettings && !!whiteLabelSettings.facebook_pixel_tracking_id;
};
