import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { intlModule } from '@chedri/base';
import Spinner from 'components/Spinner';
import { LabelValuePairShape } from '../../helpers/proptypes';
import Icon from '../Icon';
import useOuterClick from '../../hooks/useOuterClick';

TableDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.shape({ ...LabelValuePairShape, render: PropTypes.func })),
  rowKey: PropTypes.func,
  rowClassname: PropTypes.func,
  selectLabel: PropTypes.func,
  selectValue: PropTypes.func,
  onChange: PropTypes.func,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  pending: PropTypes.bool,
  ownClassName:PropTypes.string,
};

TableDropdown.defaultProps = {
  icon: 'fa-question-circle',
  label: '',
  data: [],
  columns: [],
  variant: undefined,
  value: undefined,
  name: undefined,
  rowKey: row => row.id,
  rowClassname: undefined,
  selectLabel: row => row.label,
  selectValue: row => row.value,
  onChange: () => undefined,
  onShow: () => undefined,
  onHide: () => undefined,
  pending: false,
  ownClassName:undefined,
};

function TableDropdown({
  name,
  icon,
  label,
  variant,
  data,
  value,
  columns,
  rowKey,
  rowClassname,
  selectLabel,
  selectValue,
  onChange,
  onShow,
  onHide,
  pending,
  ownClassName,
}) {
  const messages = useSelector(intlModule.selectors.getMessages);
  const dropdownRef = useOuterClick(() => {
    setShow(false);
  });

  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  const changeValue = useCallback(
    newValue => {
      return () => {
        const value = selectValue(newValue);
        setSelectedValue(value);
        onChange(newValue, name);
        setShow(false);
      };
    },
    [name, onChange]
  );

  const toggleShow = useCallback(
    event => {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      if (event && event.stopPropagation) {
        event.stopPropagation();
      }
      setShow(!show);
      if (!show) {
        onHide();
      } else {
        onShow();
      }
      return false;
    },
    [show, setShow]
  );

  useEffect(() => {
    setSelectedValue(data.find(entry => selectValue(entry) === value));
  }, [data, value, selectValue, setSelectedValue]);

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a onClick={toggleShow} ref={dropdownRef}>
      <div className={`table-dropdown${variant ? ' ' + variant : ''}`}>
        <Icon name={icon} iconClassName="main" />
        <div className="table-dropdown-label">
          <div className="label">{label}</div>
          <div className="selected-value">{selectedValue ? selectLabel(selectedValue) : messages.please_select}</div>
          {show && (
            <div className="table-dropdown-list">
              {Array.isArray(data) && data.length > 0 ? (
                <table className={ownClassName}>
                  <thead>
                    <tr>
                      {columns.map(column => (
                        <th key={column.key}>{column.label}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(row => {
                      const rowValue = selectValue(row);
                      return (
                        <tr
                          key={rowKey(row)}
                          onClick={changeValue(row)}
                          className={`${typeof rowClassname === 'function' ? rowClassname(row) : ''}${
                            value === rowValue ? ' selected' : ''
                          }`}
                        >
                          {columns.map(column => (
                            <td key={column.key}>
                              {typeof column.render === 'function' ? column.render(row, column.key) : row[column.key]}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                'No data available'
              )}
            </div>
          )}
        </div>
        <div className="opener">{pending ? <Spinner /> : <Icon name={'fa-chevron-' + (!show ? 'down' : 'up')} />}</div>
      </div>
    </a>
  );
}

export default TableDropdown;
