import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { Col, Clearfix } from 'react-bootstrap';

import { getItemDetailsPath } from 'helpers/intl';
import {
  carCategories,
  getWebFile,
  getBrand,
  displaySummaryItemGrossPrice,
  displaySummaryItemNetPrice,
  isInFavorites,
} from 'helpers/items';

import ItemGridView from 'components/layout/summary/ItemGridView';
import ItemGridViewWireframe from 'components/layout/wireframes/SummaryItemGridView';

import DataId from 'components/DataId';

import AbstractCmsElement from './AbstractCmsElement';

// make sure each instance gets a new index
let cmsElementCommerceProductsIndex = 1;
class CmsElementCommerceProducts extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      id: cmsElementCommerceProductsIndex++,
      shown: false,
    };
  }

  isPending = () => {
    return this.props.data[this.state.id] !== undefined ? this.props.data[this.state.id].pending : true;
  };

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  getList = () => {
    if (!this.isPending()) {
      return this.props.data[this.state.id].data.list;
    }

    return [];
  };

  getItemComponent = (type, item) => {
    const { intl, appIntl, history, projectConfig, favoriteItems } = this.props;

    const defaultImageThumb = getWebFile(item.img);
    const detailsUrl = getItemDetailsPath(type, item.slug);

    const brandName = getBrand(item, this.props.appIntl);

    return (
      <ItemGridView
        intl={intl}
        appIntl={appIntl}
        projectConfig={projectConfig}
        type={type}
        item={item}
        imageUrl={defaultImageThumb !== undefined ? defaultImageThumb.web_link : ''}
        brandName={brandName}
        detailsUrl={detailsUrl}
        displayGrossPrice={displaySummaryItemGrossPrice(intl, item.currency, item)}
        displayNetPrice={displaySummaryItemNetPrice(intl, item.currency, item)}
        history={history}
        isFavorite={isInFavorites(favoriteItems.list, item)}
      />
    );
  };

  render() {
    const { layout } = this.props;
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const mainColorClass = 'text-' + this.getParamValue('mainColor', 'master') + ' ';
    const layoutVersion = this.getParamValue('layoutVersion', 'slider');

    // check if we have any parameters from the cms editor
    let userUrlParams = {};
    try {
      userUrlParams = JSON.parse(this.getParamValue('urlParams', '{}'));
    } finally {
      /**/
    }

    const urlParams = {
      ...userUrlParams,
      category: this.getParamValue('categoryPath', ',0,0000000006,'),
      minImageCount: this.getParamValue('minImageCount', 0),
    };

    // set the filter
    if (!urlParams.filter) {
      urlParams.filter = {};
    }
    urlParams.filter.SORT = this.getParamValue('sort', 'cdate');
    if (layoutVersion === 'list') {
      urlParams.limit = 24;
    }

    // check the type
    const categoryId = this.getParamValue('categoryId', '0000000006');
    const type = carCategories.indexOf(categoryId) > -1 ? 'cars' : 'items';

    const settings = {
      dots: layout.breakpointIndex > 1, // display the dots only on larger devices
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const mapping = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={
          'ms-ecommerce-products-content ' +
          mainColorClass +
          shownClass +
          this.getResponsiveClasses() +
          this.getCssClasses()
        }
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <DataId
          dataIdName="commerceProducts"
          dataIdId={this.state.id}
          url="/api/summary/list.json"
          requestData={urlParams}
        />

        {layoutVersion === 'slider' && (
          <Slider {...settings}>
            {this.isPending()
              ? mapping.map(i => {
                  return (
                    <div class="padding-15" key={i}>
                      <ItemGridViewWireframe />
                    </div>
                  );
                })
              : this.getList().map(item => {
                  return (
                    <div class="padding-15" key={item.id}>
                      {this.getItemComponent(type, item)}
                    </div>
                  );
                })}
          </Slider>
        )}

        {layoutVersion === 'list' && (
          <Clearfix>
            {this.isPending()
              ? mapping.map(i => {
                  return (
                    <Col lg={4} md={6} xs={12} key={i}>
                      <ItemGridViewWireframe />
                    </Col>
                  );
                })
              : this.getList().map((item, i) => {
                  const res = [
                    <Col lg={4} md={6} xs={12} key={item.id}>
                      {this.getItemComponent(type, item)}
                    </Col>,
                  ];

                  if (i && (i + 1) % 3 === 0) {
                    res.push(<Clearfix visibleLgBlock key={'c3' + item.id} />);
                  }

                  if (i && (i + 1) % 2 === 0) {
                    res.push(<Clearfix visibleMdBlock key={'c2' + item.id} />);
                  }

                  return res;
                })}
          </Clearfix>
        )}

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementCommerceProducts.defaultProps = {
  index: '0',
};

const mapStateToProps = state => {
  return {
    data: state.dataId.commerceProducts,
    projectConfig: state.data.projectConfig,
    layout: state.layout,
    favoriteItems: state.favoriteItems,
  };
};

export default withRouter(connect(mapStateToProps)(CmsElementCommerceProducts));
