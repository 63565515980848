import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import IconRadioButton from './IconRadioButton';

export const IconRadioContext = React.createContext();

IconRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      icon: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      className: PropTypes.string,
    })
  ).isRequired,
  inline: PropTypes.bool,
  variant: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

IconRadioGroup.defaultProps = {
  variant: undefined,
  value: '',
  inline: false,
  onChange: () => undefined,
};

function IconRadioGroup({ name, variant, inline, data, value, onChange }) {
  const valueChanged = useCallback(
    ({ target }) => {
      if (target.checked) {
        onChange(target.value, name);
      }
    },
    [name, data, value]
  );
  return (
    <div className={`icon-radio-group${inline ? ' inline' : ''}`}>
      {data.map(entry => (
        <IconRadioButton
          key={entry.value}
          name={name}
          variant={variant}
          icon={entry.icon}
          value={entry.value}
          checked={value.includes(entry.value)}
          label={entry.label}
          onChange={valueChanged}
          className={entry.className}
        />
      ))}
      {data.length % 3 ? <span>&nbsp;</span> : null}
    </div>
  );
}

export default IconRadioGroup;
