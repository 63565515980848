import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';
import { isEmpty as _isEmpty, isEqual as _isEqual, pick as _pick, find as _find, forEach as _forEach } from 'lodash';

import { setFilters } from 'actions/SummaryActions';
import { parseQs, stringifyQs } from 'helpers/http';
import { mapping, carIcons, typeIcons, equipmentLevels, getHorsePower } from 'helpers/items';
import { getMainDomain } from 'helpers/whiteLabel';

import CarSelectableAttribute from 'components/layout/search/CarSelectableAttribute';
import CarCheckboxFilter from 'components/layout/search/CarCheckboxFilter';
import CarSliderFilter from 'components/layout/search/CarSliderFilter';
import CheckboxFiltersWireframe from 'components/layout/wireframes/CheckboxFilters';
import SelectableAttributesWireframe from 'components/layout/wireframes/SelectableAttributes';
import SliderFilterWireframe from 'components/layout/wireframes/SliderFilter';

import List from 'components/List';
import Count from 'components/Count';

class CarDetailSearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      category: ',0,0000000006,',
      initialised: true,
      showFloatingButton: true,
    };

    const query = parseQs(props.location.search);

    if (!_isEmpty(query) && !_isEqual(query, props.filters)) {
      props.setFilters(query);
      this.state.initialised = false; // we need to wait until the filters state get updated
    } else if (props.filters && !_isEmpty(props.filters)) {
      props.history.push({
        pathname: props.location.pathname,
        search: stringifyQs(
          _pick(props.filters, [
            'BRAND',
            props.intl.messages.car_type,
            props.intl.messages.car_gear,
            props.intl.messages.car_fueltype,
            'EQUIPMENT',
          ])
        ),
      });
    }
  }

  componentDidUpdate = prevProps => {
    const { location, filters, setFilters } = this.props;
    const { initialised } = this.state;

    if (!_isEqual(prevProps.location.search, location.search)) {
      setFilters(parseQs(location.search));
    }

    if (!initialised && !_isEqual(prevProps.filters, filters)) {
      this.setState({ initialised: true });
    }
  };

  showFloatingButton = () => {
    this.setState({ showFloatingButton: true });
  };

  hideFloatingButton = () => {
    this.setState({ showFloatingButton: false });
  };

  getBrands = () => {
    const { facets, projectConfig } = this.props;
    const brandsFacet = _find(facets.data.list, ['id', mapping.brand]);
    const projectDomain = getMainDomain(projectConfig.data);

    if (brandsFacet) {
      const res = [];
      _forEach(brandsFacet.values, brand => {
        res.push({
          name: brand.name,
          src: carIcons[brand.name] ? projectDomain + '/img/brands/' + carIcons[brand.name] + '_grey_small.png' : null,
          src_selected: carIcons[brand.name]
            ? projectDomain + '/img/brands/' + carIcons[brand.name] + '_small.png'
            : null,
        });
      });

      return res;
    }

    return [];
  };

  getTypes = () => {
    const { facets } = this.props;
    const typesFacet = _find(facets.data.list, ['id', mapping.carType]);

    if (typesFacet) {
      const res = [];
      _forEach(typesFacet.values, type => {
        res.push({
          name: type.name,
          icon: typeIcons[type.id] ? <i class={typeIcons[type.id]} /> : null,
        });
      });

      return res;
    }

    return [];
  };

  getGearboxes = () => {
    const { facets } = this.props;
    const gearboxesFacet = _find(facets.data.list, ['id', mapping.gearbox]);

    if (gearboxesFacet) {
      const res = [];
      _forEach(gearboxesFacet.values, gearbox => {
        res.push({
          name: gearbox.name,
        });
      });

      return res;
    }

    return [];
  };

  getFuelTypes = () => {
    const { facets } = this.props;
    const fuelTypesFacet = _find(facets.data.list, ['id', mapping.fuelType]);

    if (fuelTypesFacet) {
      const res = [];
      _forEach(fuelTypesFacet.values, fuelType => {
        res.push({
          name: fuelType.name,
        });
      });

      return res;
    }

    return [];
  };

  render() {
    const {
      intl: { messages },
      location,
      history,
      ownGrid,
      count,
      facets,
      filters,
    } = this.props;
    const { category, initialised } = this.state;

    const listUrlData = { category, filter: { ...filters } };
    const facetUrlData = { category };

    const typesFacet = _find(facets.data.list, ['id', mapping.carType]);
    const gearboxesFacet = _find(facets.data.list, ['id', mapping.gearbox]);
    const fuelTypesFacet = _find(facets.data.list, ['id', mapping.fuelType]);

    const carTypes = this.getTypes();
    const brands = this.getBrands();
    const fuelTypes = this.getFuelTypes();
    const gearboxes = this.getGearboxes();

    const equipments = equipmentLevels(messages);

    const carTypeRender = (
      <>
        <Row class="m-b-30">
          <Col xs={12} class="text-center">
            <h2>{messages.find_your_perfect_car}</h2>
            <h4 class="m-b-0">{messages.select_car_design}</h4>
            <div class="m-b-10 small hint-text">({messages.multiple_selection_possible})</div>
          </Col>
        </Row>
        <Row class="m-t-20 m-b-30 car-search m-l-100 m-r-100 sm-m-l-0 sm-m-r-0">
          {carTypes.map((carType, i) => {
            return (
              <CarSelectableAttribute
                obj={carType}
                name={typesFacet.name}
                location={location}
                history={history}
                key={i}
              />
            );
          })}
          {facets.pending && <SelectableAttributesWireframe dummiesCount={7} />}
        </Row>
      </>
    );

    const carBrandRender = (
      <>
        <Row>
          <Col xs={12} class="text-center">
            <h4 class="m-b-0">{messages.select_brand}</h4>
            <div class="m-b-10 small hint-text">({messages.multiple_selection_possible})</div>
          </Col>
        </Row>
        <Row class="m-t-20 m-b-30 car-search m-l-100 m-r-100 sm-m-l-0 sm-m-r-0">
          {brands.map((brand, i) => {
            return <CarSelectableAttribute obj={brand} name="BRAND" location={location} history={history} key={i} />;
          })}
          {facets.pending && <SelectableAttributesWireframe />}
        </Row>
      </>
    );

    const carDriveRender = (
      <Row class="m-b-30">
        <Col xs={12} md={6} lg={6}>
          <h4 class="m-b-0 text-center">{messages.car_gear}</h4>
          <div class="m-b-10 small hint-text text-center">({messages.multiple_selection_possible})</div>

          <div class="car-search">
            {gearboxes.map((gearbox, i) => {
              return (
                <CarCheckboxFilter
                  obj={gearbox}
                  name={gearboxesFacet.name}
                  location={location}
                  history={history}
                  prefix="gearbox"
                  size="xl"
                  key={i}
                />
              );
            })}
            {facets.pending ? <CheckboxFiltersWireframe size="xl" dummiesCount={2} /> : null}
          </div>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <h4 class="m-b-0 text-center">{messages.fuel_type}</h4>
          <div class="m-b-10 small hint-text text-center">({messages.multiple_selection_possible})</div>

          <div class="car-search">
            {fuelTypes.map((fuelType, i) => {
              return (
                <CarCheckboxFilter
                  obj={fuelType}
                  name={fuelTypesFacet.name}
                  location={location}
                  history={history}
                  prefix="fuelType"
                  size="xl"
                  key={i}
                />
              );
            })}
            {facets.pending ? <CheckboxFiltersWireframe size="xl" dummiesCount={2} /> : null}
          </div>
        </Col>
      </Row>
    );

    const equipmentLevelRender = (
      <Row>
        <Col xs={12}>
          <h4 class="m-b-0 text-center">{messages.select_equipment_level}</h4>
          <div class="m-b-10 small hint-text text-center">({messages.multiple_selection_possible})</div>

          <div class="car-search m-l-100 m-r-100 sm-m-l-0 sm-m-r-0">
            {equipments.map((equipment, i) => {
              return (
                <CarCheckboxFilter
                  obj={equipment}
                  name="EQUIPMENT"
                  location={location}
                  history={history}
                  prefix="equipment"
                  size="xl"
                  key={i}
                />
              );
            })}
          </div>
        </Col>
      </Row>
    );

    const carPowerRender = (
      <div>
        <h4 class="m-t-0 m-b-25 text-center">{messages.engine}</h4>

        {facets.pending ? (
          <SliderFilterWireframe />
        ) : (
          <CarSliderFilter
            facets={facets}
            filters={filters}
            location={location}
            name="POWER"
            urlNameMin="MIN_POWER"
            urlNameMax="MAX_POWER"
            facetNameMin="minPower"
            facetNameMax="maxPower"
            formatter={value => {
              return getHorsePower(value) + ' ' + messages.car_power_unit;
            }}
          />
        )}
      </div>
    );

    const carFirstRegistrationRender = (
      <div>
        <h4 class="m-t-0 m-b-25 text-center">{messages.first_registration}</h4>

        {facets.pending ? (
          <SliderFilterWireframe />
        ) : (
          <CarSliderFilter
            facets={facets}
            filters={filters}
            location={location}
            useNullMin={false}
            name="FIRST_REGISTRATION"
            urlNameMin="MIN_FIRST_REG"
            urlNameMax="MAX_FIRST_REG"
            facetNameMin="minFirstRegistration"
            facetNameMax="maxFirstRegistration"
          />
        )}
      </div>
    );

    const carMileageRender = (
      <div>
        <h4 class="m-t-0 m-b-25 text-center">{messages.mileage}</h4>

        {facets.pending ? (
          <SliderFilterWireframe />
        ) : (
          <CarSliderFilter
            facets={facets}
            filters={filters}
            location={location}
            name="MILEAGE"
            urlNameMin="MIN_MILEAGE"
            urlNameMax="MAX_MILEAGE"
            facetNameMin="minMileage"
            facetNameMax="maxMileage"
            formatter={value => {
              return value + ' km';
            }}
          />
        )}
      </div>
    );

    const ctaRender = (
      <Row>
        <Col xs={12} md={6} mdOffset={3} class="p-t-20">
          <ScrollTrigger onEnter={this.hideFloatingButton} onExit={this.showFloatingButton}>
            <div>
              <Button bsStyle="primary" bsSize="lg" block type="submit">
                <FormattedMessage id="count_cars" values={{ count: count.data }} />
              </Button>
            </div>
          </ScrollTrigger>
        </Col>
      </Row>
    );

    return initialised ? (
      <div>
        <Count countName="summary" url="/api/summary/count.json" urlData={listUrlData} count={count} noRender />
        <List listName="summaryFacets" url="/api/summary/facets.json" urlData={facetUrlData} list={facets} noRender />

        {ownGrid ? (
          <div>
            <Grid>{carTypeRender}</Grid>

            <div class="bg-white">
              <Grid class="p-t-30 p-b-30">{carBrandRender}</Grid>
            </div>

            <Grid class="p-t-50 p-b-30">{carDriveRender}</Grid>

            <div class="bg-white">
              <Grid class="p-t-50 p-b-50">
                <Row>
                  <Col xs={12} md={6} lg={4}>
                    {carPowerRender}
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    {carFirstRegistrationRender}
                  </Col>
                  <Col xs={12} lg={4}>
                    {carMileageRender}
                  </Col>
                </Row>
              </Grid>
            </div>

            <Grid class="p-t-30 p-b-70">
              {equipmentLevelRender}

              <hr />

              {ctaRender}
            </Grid>
          </div>
        ) : (
          <div class="p-b-70">
            {carTypeRender}
            {carBrandRender}
            <hr />
            {carDriveRender}

            <Row>
              <Col xs={12} md={6} lg={4}>
                {carPowerRender}
              </Col>
              <Col xs={12} md={6} lg={4}>
                {carFirstRegistrationRender}
              </Col>
              <Col xs={12} lg={4}>
                {carMileageRender}
              </Col>
            </Row>

            {equipmentLevelRender}
            {ctaRender}
          </div>
        )}

        {this.state.showFloatingButton ? (
          <div class="floating-submit-button" style={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1010 }}>
            <div class="floating-submit-button-inner">
              <Button block bsStyle="primary" bsSize="lg" disabled={count.count === 0} type="submit">
                <FormattedMessage id="count_cars" values={{ count: count.data }} />
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    ) : null;
  }
}
CarDetailSearchForm.defaultProps = {
  ownGrid: false,
};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    count: state.count.summary,
    facets: state.list.summaryFacets,
    attributes: state.list.summaryAttributes,
    filters: state.summary,
    projectConfig: state.data.projectConfig,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setFilters: filters => {
      dispatch(setFilters(filters));
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CarDetailSearchForm)
  )
);
