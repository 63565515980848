import React from "react";
import Functions from "../../components/layout/mediaLibrary/Functions";
import  {Col, ModalBody, ModalHeader, ModalTitle, Row} from "react-bootstrap";
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";
import Breadcrumb from "../../components/layout/Breadcrumb";
import FormAutocomplete from "../../components/forms/FormAutocomplete";


class MediaLibrary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     sorting: "",
      openedFolderML:"",
    }
  }

  sortMedia = (name, value) => {
    this.setState({sorting: value})
  }


  openedFolderSet = (folder) => {
   this.setState({openedFolderML: folder})
  }
  render() {
    const {
      user,
      appIntl,
      intl: { messages },
    } = this.props;
    let options = [[messages.sorting_name, "name"], [messages.sorting_date_DESC, "cdateDESC"],  [messages.sorting_date_ASC, "cdateASC"], [messages.sorting_owner, "owner"], [messages.sorting_creator, "creator"]]
    return (
      <Row>
        <Col md={12} className="m-b-20">
          <div className="row" className="bg-white" style={{height:"134px", marginLeft:"-15px", marginRight:"-15px"}}>
            <div className="m-b-20 m-t-20 m-l-15 col-sm-8">
              <Breadcrumb
                crumbs={[
                  { link: appIntl.urlPrefix + 'account', name: 'Dashboard' },
                  { link: appIntl.urlPrefix + 'account/media', name: messages.medialibrary.medialibrary },

                ]}
                className="p-t-0"
              />
              <h4 class="bold"><i className="fas fa-photo-video"></i> {messages.medialibrary.medialibrary}</h4>
            </div>
            <div className="m-b-20 m-t-20 m-l-15 col-sm-3">

            <FormAutocomplete
              onChange={(name, value) => {this.sortMedia(name, value)}}
              value={this.state.sorting}
              label={messages.medialibrary.sorting}
              name="sort"
              infoText={messages.medialibrary.sorting_info_text}
              options={options.map(option => {
                return { label: option[0], value: option[1] };
              })}
            />

              {this.state.sorting === "name" ? <p style={{color:"#CF1719"}}>{messages.medialibrary.sorting_description}</p> : <></>}
            </div>
          </div>
          <Row>
            <Col md={12} className="m-b-20">
              <Functions user={user} sorting={this.state.sorting} openedFolderSet={this.openedFolderSet} messages={messages}/>
            </Col>
          </Row>
        </Col>
      </Row>


    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.user,
    appIntl: state.intl,
  };
};
const mapDispatchToProps = dispatch => {
  return {

  };
};

export default injectIntl(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(MediaLibrary)
  )
);
