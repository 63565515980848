import React from 'react';
import { components } from 'react-select';

class RoleOption extends React.Component {
  render() {
    const { data } = this.props;
    return <components.Option {...this.props}>{data.label}</components.Option>;
  }
}

class RoleValue extends React.Component {
  render() {
    const { data } = this.props;

    return <div className="Select-value">{data.label}</div>;
  }
}

const roleOptionFormatter = options => {
  return options.list.map(option => {
    return roleSingleOptionFormatter(option);
  });
};

const roleSingleOptionFormatter = (option = null) => {
  if (option) {
    return { value: option, label: option };
  }

  return null;
};

export { RoleValue, RoleOption, roleOptionFormatter, roleSingleOptionFormatter };
