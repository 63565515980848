import React from 'react';
import { connect } from 'react-redux';

class IntlFormIndicator extends React.Component {
  render() {
    const { appIntl, show } = this.props;

    return show ? <span class="hint-text m-l-10">({appIntl.displayIntlForm})</span> : null;
  }
}
IntlFormIndicator.defaultProps = {
  show: true,
};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default connect(mapStateToProps)(IntlFormIndicator);
