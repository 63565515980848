import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Grid, Row, Col } from 'react-bootstrap';

import { getLogger } from '@chedri/base';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { error: null, errorInfo: null };
  }

  componentDidMount() {
    const { history } = this.props;
    history.listen(() => {
      this.setState({ error: null, errorInfo: null });
    });
  }

  componentDidCatch = (error, errorInfo) => {
    getLogger().fatal('Unexpected error', error, errorInfo);
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });

    // You can also log error messages to an error reporting service here
  };

  render() {
    const { children } = this.props;
    const { error, errorInfo } = this.state;

    if (errorInfo) {
      // Error path
      return (
        <div className="content">
          <Grid fluid>
            <Row>
              <Col xs={12}>
                <h2>Something went wrong.</h2>
                <details style={{ whiteSpace: 'pre-wrap' }}>
                  {error && error.toString()}
                  <br />
                  {errorInfo.componentStack}
                </details>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }

    // Normally, just render children
    return children;
  }
}

const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {};
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ErrorBoundary)
  )
);
