import { createBrowserHistory as _createBrowserHistory, createHashHistory as _createHashHistory } from 'history';

let history;

export function getHistory() {
  return history;
}

export function createBrowserHistory(debug) {
  // if we are developing locally, we need to add app_dev.php to every request
  const basename = '';

  history = _createBrowserHistory({ basename });

  return history;
}

export function createHashHistory() {
  history = _createHashHistory();

  return history;
}

export function push() {
  return history.push.apply(null, arguments);
}
