import React from 'react';
import { OverlayTrigger, Tooltip, Overlay, Popover } from 'react-bootstrap';

import Autocomplete from 'components/forms/Autocomplete';

export default class FormAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      currentValue: props.defaultValue || props.value || null,
    };
  }

  checkFocused = () => {
    const { focused } = this.state;
    return focused ? 'focused ' : '';
  };

  checkRequired = () => {
    const { meta } = this.props;
    return meta.validate.rules.indexOf('required') !== -1 ? 'required ' : '';
  };

  checkError = () => {
    const { validation } = this.props;
    return validation.valid ? '' : 'has-error ';
  };

  catchFocus = () => {
    this.input.focus();
  };

  checkDisabled = () => {
    const { disabled, meta } = this.props;
    if (disabled) {
      return true;
    }
    if (!meta.acl.write) {
      return true;
    }
    return false;
  };

  onChange = option => {
    const { name, onChange, returnValueObject, multi, onValueChange } = this.props;

    if (!multi && returnValueObject) {
      onChange(name, option);
      onValueChange(option);
    } else if (multi) {
      onChange(name, option.map(oneOption => oneOption.value));
      onValueChange(option.map(oneOption => oneOption.value));
    } else {
      onChange(name, option.value);
      onValueChange(option.value);
    }
    this.setState({
      currentValue: option.value,
    });
  };

  onBlur = () => {
    this.setState({ focused: false });
    const { onBlur } = this.props;
    onBlur();
  };

  setValue = value => {
    this.onChange(value);
  };

  render() {
    const {
      className,
      label,
      name,
      value,
      validation,
      autocompleteType,
      complete,
      options,
      placeholder,
      urlFilters,
      infoText,
      multi,
      stayOpen,
      clearable,
      responseFilter,
      highlightedOptions,
      style,
    } = this.props;
    const { focused, currentValue } = this.state;

    return (
      <div
        className={
          'form-group form-group-default form-autocomplete ' +
          this.checkFocused() +
          this.checkRequired() +
          this.checkError() +
          (this.checkDisabled() ? 'disabled ' : '') +
          className
        }
        onClick={this.catchFocus}
        style={{ ... (style ?? {}), overflow: 'visible' }}
      >
        {
          // eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for, prettier/prettier
        }<label>
          {label}
          {!!infoText && (
            <span className="m-l-5">
              <OverlayTrigger placement="top" overlay={<Tooltip id={name || autocompleteType}>{infoText}</Tooltip>}>
                <i className="far fa-question-circle cursor-help" />
              </OverlayTrigger>
            </span>
          )}
        </label>
        <Autocomplete
          inputRef={input => {
            this.input = input;
          }}
          name={name || autocompleteType}
          type={autocompleteType}
          urlFilters={urlFilters}
          value={value}
          onChange={this.onChange}
          disabled={this.checkDisabled()}
          complete={complete}
          options={options}
          placeholder={placeholder}
          onFocus={() => this.setState({ focused: true })}
          onBlur={this.onBlur}
          isMulti={multi}
          stayOpen={stayOpen}
          loadingMessage={e => "Loading options close to '" + e.inputValue + "'"}
          noOptionsMessage={() => 'No options available for choice'}
          formatCreateLabel={e => "Create a new tag with label '" + e + "'"}
          clearable={clearable}
          responseFilter={responseFilter}
          highlightedOptions={highlightedOptions}
        />

        <Overlay show={focused && !validation.valid} target={this.input} placement="top">
          <Popover id={name || autocompleteType} className="popover-modal">
            {validation.helpText}
          </Popover>
        </Overlay>
      </div>
    );
  }
}
FormAutocomplete.defaultProps = {
  className: '',
  value: '',
  complete: false,
  label: '',
  meta: { validate: { rules: '' }, acl: { read: true, write: true } },
  validation: { valid: true },
  onChange: () => {},
  onValueChange: () => {},
  returnValueObject: false,
  disabled: false,
  infoText: '',
  urlFilters: {},
  onBlur: () => {},
  multi: false,
  stayOpen: false,
  clearable: false,
  responseFilter: null,
  highlightedOptions: [],
};
