export const generateMetaTags = (item, customTitle) => {
  return item
    ? [
        { name: 'title', content: customTitle || item.title },
        { name: 'description', content: item.description },
        { itemProp: 'name', content: item.name },
        { itemProp: 'description', content: item.description },
        //{itemProp: 'image', content: "http://www.example.com/image.jpg"}
      ]
    : [];
};
