export default class EntityGlobal {
  static init() {
    EntityGlobal.didInit = true;
    EntityGlobal.entities = {};
  }

  static setEntity(entityName, entity) {
    if (!EntityGlobal.didInit) {
      EntityGlobal.init();
    }

    EntityGlobal[entityName] = entity;
  }
}
