import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getSalutationOptions, getGenderForSalutation } from 'helpers/clients';

import CmsElementSignupFormLegal from 'components/cms/elements/CmsElementSignupFormLegal';

import Data from 'components/Data';
import FormInput from 'components/forms/FormInput';
import FormAutocomplete from 'components/forms/FormAutocomplete';

import EntityPropsUpdater from 'component/EntityPropsUpdater';

class CompanySignupStepPersonalData extends EntityPropsUpdater {
  updateSalutation = (name, value) => {
    const gender = getGenderForSalutation(value);
    this.updateEntityMultiple(['salutation', 'gender'], [value, gender]);
  };

  render() {
    const {
      intl: { messages },
      formStatus,
      entity,
      projectConfig,
      doValidate,
    } = this.props;

    return (
      <div>
        <h3>{messages.company_singup_wizard_step_personal_data_headline}</h3>
        <p>
          {messages.company_singup_wizard_step_personal_data_hint.replace('%platformName%', projectConfig.data.name)}
        </p>
        <Data dataName="chedriCompanySignupForm" /> {/* fetch meta data */}
        <div class="form-group-attached">
          <FormAutocomplete
            label={messages.salutation}
            name="salutation"
            value={entity.getSalutation()}
            meta={entity.getSalutationMetaInformation()}
            validation={doValidate ? entity.getSalutationValidationResult() : undefined}
            onChange={this.updateSalutation}
            options={getSalutationOptions(messages)}
            disabled={formStatus.pending}
          />
          <FormInput
            label={messages.first_name}
            name="firstName"
            value={entity.getFirstName()}
            meta={entity.getFirstNameMetaInformation()}
            validation={doValidate ? entity.getFirstNameValidationResult() : undefined}
            onChange={this.updateEntity}
            disabled={formStatus.pending}
          />
          <FormInput
            label={messages.last_name}
            name="lastName"
            value={entity.getLastName()}
            meta={entity.getLastNameMetaInformation()}
            validation={doValidate ? entity.getLastNameValidationResult() : undefined}
            onChange={this.updateEntity}
            disabled={formStatus.pending}
          />
          <FormInput
            type="tel"
            label={messages.mobile_phone}
            name="mobile"
            value={entity.getMobile()}
            meta={entity.getMobileMetaInformation()}
            validation={doValidate ? entity.getMobileValidationResult() : undefined}
            onChange={this.updateEntity}
            disabled={formStatus.pending}
          />
          <FormInput
            type="email"
            label={messages.email}
            name="email"
            value={entity.getEmail()}
            meta={entity.getEmailMetaInformation()}
            validation={doValidate ? entity.getEmailValidationResult() : undefined}
            onChange={this.updateEntity}
            disabled={formStatus.pending}
          />
        </div>
        <div class="form-group-attached m-t-10">
          <FormInput
            type="password"
            name="password"
            label={messages.password}
            value={entity.getPassword()}
            meta={entity.getPasswordMetaInformation()}
            validation={doValidate ? entity.getPasswordValidationResult() : undefined}
            onChange={this.updateEntity}
            disabled={formStatus.pending}
          />
          <FormInput
            type="password"
            name="repeatPassword"
            label={messages.repeat_password}
            value={entity.getRepeatPassword()}
            meta={entity.getRepeatPasswordMetaInformation()}
            validation={doValidate ? entity.getRepeatPasswordValidationResult() : undefined}
            onChange={this.updateEntity}
            disabled={formStatus.pending}
          />
        </div>
        <h3 class="m-b-0 p-b-0 m-t-30">Ihr Testdomainname</h3>
        {messages.company_singup_wizard_step_personal_data_domain_hint}
        <div class="form-group-attached">
          <FormInput
            name="testDomain"
            label="Test Domain"
            value={entity.getTestDomain()}
            meta={entity.getTestDomainMetaInformation()}
            validation={doValidate ? entity.getTestDomainValidationResult() : undefined}
            onChange={this.updateEntity}
            disabled={formStatus.pending}
            suffixAddon=".chedri.com"
          />
        </div>
        <div class="p-t-20 p-b-15 fs-11">
          <CmsElementSignupFormLegal {...this.props} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    meta: state.entityMeta,
    user: state.login.user,
    formStatus: state.formStatus.companySignup,
    projectConfig: state.data.projectConfig,
  };
};

export default injectIntl(connect(mapStateToProps)(CompanySignupStepPersonalData));
