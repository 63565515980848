import axios from 'axios';

export const CONTACT_LIST = 'CONTACT_LIST';

export function getContactList(limit, sort, dir, page, offset, search) {
  if(search === null || search === undefined){
    search="";
  }
  return {
    type: CONTACT_LIST,
    payload: axios
      .get(
        `/api/contacts.json?limit=${limit}&sort=${sort}&dir=${dir}&page=${page}&offset=${offset}&search=${search}`//&filters%5Bsearch%5D=${search}`
      )
      .then(({ data }) => {
        data.page = page;
        return data;
      }),
  };
}
