import axios from 'axios';

import { toQueryString } from 'helpers/http';

export const AUTOCOMPLETE_QUEUE = 'AUTOCOMPLETE_QUEUE';
export const AUTOCOMPLETE_FINISHED = 'AUTOCOMPLETE_FINISHED';
export const AUTOCOMPLETE_SUBMIT = 'AUTOCOMPLETE_SUBMIT';
export const AUTOCOMPLETE_ERROR = 'AUTOCOMPLETE_ERROR';
export const AUTOCOMPLETE_RESET = 'AUTOCOMPLETE_RESET';

export function autocompleteQueue(autocomplete) {
  return {
    type: AUTOCOMPLETE_QUEUE,
    payload: autocomplete,
  };
}

export function autocompleteSubmit(url, data, finishedCallback, errorCallback, method = 'get') {
  let fullUrl = url;
  if (fullUrl.indexOf('?') === -1) {
    fullUrl += '?';
  }

  if (method == 'get' && data !== undefined && data) {
    fullUrl += toQueryString(data) + '&';
  }
  fullUrl += '_locale=' + globalLocale;

  return {
    type: AUTOCOMPLETE_SUBMIT,
    payload: axios({
      method: method,
      url: fullUrl,
      data: { ...data },
    })
      .then(finishedCallback)
      .catch(errorCallback),
  };
}

export function autocompleteFinished(autocomplete, response) {
  return {
    type: AUTOCOMPLETE_FINISHED,
    payload: {
      autocomplete,
      response,
    },
  };
}

export function autocompleteError(autocomplete, error) {
  return {
    type: AUTOCOMPLETE_ERROR,
    payload: {
      autocomplete,
      error,
    },
  };
}

export function autocompleteReset(autocomplete) {
  return {
    type: AUTOCOMPLETE_RESET,
    payload: autocomplete,
  };
}
