import React from 'react';
import scriptLoader from 'react-async-script-loader';
import GoogleMap from 'google-map-react';

import { GOOGLE_MAPS_KEY, GOOGLE_MAPS_URL, mapOptions } from 'js/constants';

import Loader from 'components/layout/Loader';

class LazyLoadingGoogleMaps extends React.Component {
  render() {
    const { language, isScriptLoaded, center, height, children } = this.props;

    return (
      <div style={{ height: `${height}px` }} className="gmap relative">
        {!isScriptLoaded ? (
          <div
            style={{
              height: `${height}px`,
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
            className="text-center"
          >
            <Loader />
          </div>
        ) : (
          <GoogleMap
            bootstrapURLKeys={{
              key: GOOGLE_MAPS_KEY,
              language: language || 'de',
            }}
            center={center}
            defaultZoom={14}
            options={mapOptions}
            resetBoundsOnResize
          >
            {children}
          </GoogleMap>
        )}
      </div>
    );
  }
}
LazyLoadingGoogleMaps.defaultProps = {
  height: 400,
  center: {
    lat: 0,
    lng: 0,
  },
};

export default scriptLoader(GOOGLE_MAPS_URL)(LazyLoadingGoogleMaps);
