import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedNumber, FormattedMessage } from 'react-intl';
import { Clearfix } from 'react-bootstrap';
import { pick as _pick, forEach as _forEach, isArray as _isArray } from 'lodash';

import { resetFilter, removeFilter } from 'actions/SummaryActions';
import { parseQs, stringifyQs } from 'helpers/http';

import SummaryTag from './SummaryTag';

class SummaryTags extends React.Component {
  getFilterValues = name => {
    const { filters } = this.props;

    if (filters[name] !== undefined) {
      return filters[name];
    }

    return null;
  };

  // remove whole array from query
  removeFilter = name => {
    const { location, history } = this.props;

    const query = parseQs(location.search);
    delete query[name];

    history.replace({ pathname: location.pathname, search: stringifyQs(query) });
  };

  resetFilters = () => {
    const { location, history } = this.props;

    const query = parseQs(location.search);

    history.replace({
      pathname: location.pathname,
      search: stringifyQs(_pick(query, ['SORT'])),
    });
  };

  removeLocationFilter = () => {
    const { location, history } = this.props;

    const query = parseQs(location.search);
    delete query.LOCATION;
    delete query.ADDRESS;
    delete query.DISTANCE;

    history.replace({ pathname: location.pathname, search: stringifyQs(query) });
  };

  listTags = () => {
    const {
      intl: { messages },
      webApp,
      filters,
    } = this.props;

    const result = [];
    _forEach(filters, (values, name) => {
      if (_isArray(values)) {
        switch (name) {
          case 'EQUIPMENT':
            _forEach(values, (value, j) => {
              result.push(
                <SummaryTag name={name} value={value} title={messages['equipment_level_' + value]} key={name + j} />
              );
            });
            break;

          default:
            _forEach(values, (value, j) => {
              result.push(
                value === 'true' ? (
                  <SummaryTag name={name} value={name} onClick={() => this.removeFilter(name)} key={name + j} />
                ) : (
                  <SummaryTag name={name} value={value} key={name + j} />
                )
              );
            });
            break;
        }
      } else {
        switch (name) {
          case 'MIN_PRICE':
            result.push(
              <SummaryTag
                name={name}
                title={
                  <React.Fragment>
                    {messages.price_from} <FormattedNumber value={values} style="currency" currency={webApp.currency} />
                  </React.Fragment>
                }
                key={name}
                onClick={() => this.removeFilter(name)}
              />
            );
            break;

          case 'MAX_PRICE':
            result.push(
              <SummaryTag
                name={name}
                title={
                  <React.Fragment>
                    {messages.price_to} <FormattedNumber value={values} style="currency" currency={webApp.currency} />
                  </React.Fragment>
                }
                key={name}
                onClick={() => this.removeFilter(name)}
              />
            );
            break;

          case 'ADDRESS': {
            const distance = this.getFilterValues('DISTANCE');
            result.push(
              <SummaryTag
                title={
                  <FormattedMessage id="within_count_km_of_place" values={{ count: distance || 20, place: values }} />
                }
                key={name}
                onClick={() => this.removeLocationFilter()}
              />
            );
            break;
          }

          case 'MIN_FIRST_REG':
            result.push(
              <SummaryTag
                name={name}
                title={[messages.first_registration_from + ' ' + values]}
                key={name}
                onClick={() => this.removeFilter(name)}
              />
            );
            break;

          case 'MAX_FIRST_REG':
            result.push(
              <SummaryTag
                name={name}
                title={[messages.first_registration_to + ' ' + values]}
                key={name}
                onClick={() => this.removeFilter(name)}
              />
            );
            break;

          case 'MIN_POWER':
            result.push(
              <SummaryTag
                name={name}
                title={[messages.power_from + ' ' + values + ' ' + messages.car_power_unit]}
                key={name}
                onClick={() => this.removeFilter(name)}
              />
            );
            break;

          case 'MAX_POWER':
            result.push(
              <SummaryTag
                name={name}
                title={[messages.power_to + ' ' + values + ' ' + messages.car_power_unit]}
                key={name}
                onClick={() => this.removeFilter(name)}
              />
            );
            break;

          case 'MIN_MILEAGE':
            result.push(
              <SummaryTag
                name={name}
                title={[messages.mileage_from + ' ' + values + ' ' + messages.mileage_unit]}
                key={name}
                onClick={() => this.removeFilter(name)}
              />
            );
            break;

          case 'MAX_MILEAGE':
            result.push(
              <SummaryTag
                name={name}
                title={[messages.mileage_to + ' ' + values + ' ' + messages.mileage_unit]}
                key={name}
                onClick={() => this.removeFilter(name)}
              />
            );
            break;
        }
      }
    });

    if (result.length) {
      if (result.length > 3) {
        // show the reset all button only if there are more than 3 filters set
        result.push(
          <SummaryTag
            title={messages.delete_all_filters}
            className="filter-tags-reset-all"
            key="reset"
            onClick={this.resetFilters}
          />
        );
      }

      return (
        <ul class="list-unstyled summary-filter-tags-list">
          <Clearfix>{result}</Clearfix>
        </ul>
      );
    }

    return null;
  };

  render() {
    return this.listTags();
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    webApp: state.webApp,
    filters: state.summary,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    resetFilters: () => {
      dispatch(resetFilter());
    },
    removeFilter: (name, value) => {
      dispatch(removeFilter(name, value));
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(SummaryTags)
  )
);
