/* eslint-disable import/prefer-default-export */
import webApp from './webApp';
import login from './login';
import layout from './layout';
import upload from './upload';
import cookies from './cookies';
import clientBreadcrumbs from './clientBreadcrumbs';
import clientTimeline from './clientTimeline';
import companyDataTimeline from './companyDataTimeline';
import calendar from './calendar';
import cart from './cart';
import checkout from './checkout';
import summary from './summary';
import summarySettings from './summarySettings';
import salesActivityDashboard from './salesActivityDashboard';
import reservation from './reservation';
import facebookMessengerOptin from './facebookMessengerOptin';
import favoriteItems from './favoriteItems';

import list from './list';
import data from './data';
import dataId from './dataId';
import entityMeta from './entityMeta';
import activeForms from './activeForms';
import formStatus from './formStatus';
import deleter from './deleter';
import count from './count';
import watching from './watching';
import modals from './modals';

import hints from './hints';
import autocomplete from './autocomplete';
import accountSearch from './accountSearch';
import cms from './cms';
import settings from './settings';
import mediaLibrary from './mediaLibrary';
import contacts from './contacts';
import editor from './editor';
import campaigns from './campaigns';
import company from './company';
import trash from './trash';
import archive from './archive';
import templateSettings from './templateSettings';
import provisionServiceTypes from './provisionServiceTypes';
import leadAndTaskCounter from './leadAndTaskCounter';

export const rootClientReducer = {
  webApp,
  login,
  layout,
  upload,
  cookies,
  clientBreadcrumbs,
  clientTimeline,
  companyDataTimeline,
  calendar,
  cart,
  checkout,
  summary,
  summarySettings,
  salesActivityDashboard,
  reservation,
  facebookMessengerOptin,
  favoriteItems,

  list,
  data,
  dataId,
  entityMeta,
  activeForms,
  formStatus,
  deleter,
  count,
  watching,
  modals,

  hints,
  autocomplete,
  accountSearch,
  cms,
  settings,
  mediaLibrary,
  contacts,
  editor,
  campaigns,
  selectedClient: company,
  trash,
  archive,
  templateSettings,
  provisionServiceTypes,
  leadAndTaskCounter,
};
