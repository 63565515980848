import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Button } from 'react-bootstrap';

import ModalSummaryFilters from 'components/modals/ModalSummaryFilters';

import SummaryFilterList from './SummaryFilterList';

class SummaryFilterBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMobileFilters: false,
    };
  }

  showMobileFilters = () => {
    this.setState({ showMobileFilters: true });
  };

  closeMobileFilters = () => {
    this.setState({ showMobileFilters: false });
  };

  render() {
    const {
      intl,
      intl: { messages },
      appIntl,
      webApp,
      type,
      filters,
      facets,
      location,
      setFilters,
      setFilter,
      resetFilter,
      removeFilter,
    } = this.props;
    const { showMobileFilters } = this.state;

    const filterActions = { setFilters, setFilter, resetFilter, removeFilter };

    return (
      <div class="summary-filter-list-container">
        <div class="hidden-xs hidden-sm">
          <SummaryFilterList
            intl={intl}
            appIntl={appIntl}
            type={type}
            filters={filters}
            facets={facets}
            location={location}
            webApp={webApp}
            {...filterActions}
          />
        </div>

        <div class="hidden-md hidden-lg">
          <Button bsSize="lg" bsStyle="primary" class="m-b-15" onClick={this.showMobileFilters}>
            <i class="fas fa-sliders-h" /> {messages.do_filter}
          </Button>
        </div>

        <ModalSummaryFilters
          show={showMobileFilters}
          close={this.closeMobileFilters}
          type={type}
          facets={facets}
          filters={filters}
          location={location}
          {...filterActions}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    webApp: state.webApp,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(SummaryFilterBar)));
