import React from 'react';
import { Link } from 'react-router-dom';

import { isExternal } from 'helpers/http';

import DropdownMenu from 'components/layout/mainMenus/common/DropdownMenu';

export default class MainMenuLink extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  onMouseEnter = () => {
    const { index, onMouseEnter } = this.props;
    onMouseEnter(index);
  };

  render() {
    const {
      flipId,
      index,
      currentIndex,
      layout,
      // prevIndex,
      onClick,
      hasDropdown,
      animatingOut,
      id,
      listClassName,
      className,
      link,
      text,
      dropdownWidth,
      icon,
    } = this.props;

    const isActive = index === currentIndex;
    // const wasActive = index === prevIndex;
    const isExternalLink = isExternal(link);
    const LinkComponent = isExternalLink ? 'a' : Link;
    const linkProps = {
      className: `ms-top-menu ${className || ''}`,
      to: (!isExternalLink && link) || undefined,
      href: (isExternalLink && link) || undefined,
      onMouseEnter: this.onMouseEnter,
      onClick: onClick || undefined,
    };

    let marginLeft = 0;
    if (hasDropdown && isActive && this.ref.current) {
      const liRect = this.ref.current.firstChild.getBoundingClientRect();
      const linkCenterLeft = liRect.left; /* + liRect.width / 2 */
      const dropdownWidthInPx = (dropdownWidth / 100) * layout.width;

      if (linkCenterLeft + dropdownWidthInPx / 2 > layout.width) {
        marginLeft = -1 * (linkCenterLeft + dropdownWidthInPx / 2 - layout.width);
      } else if (linkCenterLeft - dropdownWidthInPx / 2 < 0) {
        marginLeft = dropdownWidthInPx / 2 - linkCenterLeft;
      }

      // console.log(linkCenterLeft, dropdownWidthInPx, marginLeft);
    }
    // marginLeft = 0;

    return (
      <li
        key={"mainMenuLink" + id}
        className={`ms-top-menu ${listClassName || ''} ${isActive ? 'active' : ''}`}
        id={id || undefined}
        ref={this.ref}
      >
        <LinkComponent {...linkProps}>
          {icon}
          {text}
        </LinkComponent>
        {hasDropdown && isActive && (
          <DropdownMenu
            flipId={flipId}
            index={index}
            element={this.props}
            visible={isActive}
            animatingOut={animatingOut}
            marginLeft={marginLeft}
          />
        )}
      </li>
    );
  }
}
