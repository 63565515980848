import React from 'react';

import { parseQs, stringifyQs } from 'helpers/http';

class CarCheckboxFilter extends React.Component {
  constructor(props) {
    super(props);

    const query = parseQs(props.location.search);

    this.state = {
      hovered: false,
      selected: !!(
        query[props.name] && query[props.name].indexOf(props.obj.value ? props.obj.value : props.obj.name) > -1
      ),
    };
  }

  componentDidUpdate = prevProps => {
    const { location, name, obj } = this.props;
    const { selected } = this.state;

    if (prevProps.location.search !== location.search) {
      const query = parseQs(location.search);
      const querySelected = !!(query[name] && query[name].indexOf(obj.value ? obj.value : obj.name) > -1);

      if (selected !== querySelected) {
        this.setState({ selected: querySelected });
      }
    }
  };

  // remove a value from array
  removeFilter = (name, value) => {
    const { location, history } = this.props;

    const query = parseQs(location.search);
    const values = query[name].slice();
    values.splice(values.indexOf(value), 1);

    if (!values.length) {
      delete query[name];
    } else {
      query[name] = values;
    }

    history.replace({ pathname: location.pathname, search: stringifyQs(query) });
  };

  //
  addFilter = (name, value) => {
    const { location, history } = this.props;

    const query = parseQs(location.search);
    const values = query[name] ? query[name].slice() : [];
    values.push(value);

    history.replace({
      pathname: location.pathname,
      search: stringifyQs({ ...query, [name]: values }),
    });
  };

  change = e => {
    const { name, obj, onChange } = this.props;
    const selected = e.target.checked;

    if (onChange) {
      onChange(selected);
    } else {
      this.setState({ selected }, () => {
        if (selected) {
          this.addFilter(name, obj.value ? obj.value : obj.name);
        } else {
          this.removeFilter(name, obj.value ? obj.value : obj.name);
        }
      });
    }
  };

  getId = () => {
    const { prefix, obj } = this.props;
    return prefix + ' ' + obj.name;
  };

  render() {
    const { obj, name, size } = this.props;
    const { selected, hovered } = this.state;

    const checkboxId = this.getId();

    return (
      <div
        class={
          'filter ' +
          (hovered ? 'hovered ' : '') +
          (selected ? 'selected ' : '') +
          ' ' +
          this.props.className +
          ' ' +
          (size ? 'filter-checkbox-' : '') +
          size +
          ' '
        }
      >
        <label
          for={checkboxId}
          title={obj.name}
          onMouseEnter={() => this.setState({ hovered: true })}
          onMouseLeave={() => this.setState({ hovered: false })}
        >
          <div class="body">
            <div class="text overflow-ellipsis">{obj.name}</div>

            <input
              id={checkboxId}
              name={name + '[]'}
              type="checkbox"
              checked={selected}
              value={true}
              onChange={this.change}
              style={{ visibility: 'hidden', position: 'absolute' }}
            />
          </div>
        </label>

        {obj.description ? obj.description : null}
      </div>
    );
  }
}
CarCheckboxFilter.defaultProps = {
  className: '',
  size: '',
  onChange: null,
};

export default CarCheckboxFilter;
