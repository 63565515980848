import React from 'react';
import { components } from 'react-select';

export class UomOption extends React.Component {
  render() {
    const { data } = this.props;
    return <components.Option {...this.props}>{data.label}</components.Option>;
  }
}

export class UomValue extends React.Component {
  render() {
    const { data } = this.props;

    return <div className="Select-value">{data.label}</div>;
  }
}
