import React from 'react';
import { findDOMNode } from 'react-dom';
import { Clearfix, Button } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

const gridSource = {
  beginDrag(props) {
    return {
      index: props.index,
    };
  },
};

const gridTarget = {
  hover(props, monitor, component) {
    if (monitor.getItemType() === 'grid') {
      const dragIndex = monitor.getItem().index.toString();
      const hoverIndex = props.index.toString();

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // compare if it is the same container
      let dragContainer = dragIndex.substring(0, dragIndex.lastIndexOf('.'));
      dragContainer = dragContainer !== '' ? dragContainer + '.' : '';
      let hoverContainer = hoverIndex.substring(0, hoverIndex.lastIndexOf('.'));
      hoverContainer = hoverContainer !== '' ? hoverContainer + '.' : '';

      let direction,
        newIndex,
        sameContainer = dragContainer === hoverContainer;
      const dragContainerIndex = dragIndex.substring(dragIndex.lastIndexOf('.')).replace('.', '');
      const hoverContainerIndex = hoverIndex.substring(hoverIndex.lastIndexOf('.')).replace('.', '');

      // drop after or before?
      if (hoverClientY < hoverMiddleY) {
        direction = 'before';
      } else {
        direction = 'after';
      }

      // calculate new index
      if ((sameContainer && dragContainerIndex > hoverContainerIndex) || !sameContainer) {
        if (direction === 'after') {
          newIndex = hoverContainer + (parseInt(hoverContainerIndex) + 1);
        } else {
          newIndex = hoverContainer + hoverContainerIndex;
        }
      } else {
        if (direction === 'after') {
          newIndex = hoverContainer + hoverContainerIndex;
        } else {
          newIndex = hoverContainer + (parseInt(hoverContainerIndex) - 1 < 0 ? 0 : parseInt(hoverContainerIndex) - 1);
        }
      }

      if (newIndex !== dragIndex) {
        props.reorderElements(dragIndex, hoverIndex, direction, sameContainer);
        monitor.getItem().index = newIndex;
      }
    }
  },
};

@DropTarget(['grid'], gridTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('grid', gridSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
// editable
export default class CmsElementDynamicElement extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.state = {
      mouseOver: false,
      shown: false,
    };

    this.allowStateChange = true;
    this.mouseMoveBlock = false;
  }

  mouseEnter = () => {
    this.setState({ mouseOver: true });
  };

  mouseLeave = () => {
    this.setState({ mouseOver: false });
  };

  mouseMove = () => {
    if (!this.state.mouseOver && !this.mouseMoveBlock) {
      this.setState({ mouseOver: true }, () => (this.mouseMoveBlock = false));
    }
  };

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  componentDidMount = () => {
    this.__componentDidMount();

    const img = new Image();
    img.onload = () => this.props.connectDragPreview(img);
    img.src =
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAAAUCAYAAADIpHLKAAAAoUlEQVRoge3boQ0CQRRF0buTDX63ECoCiSM4cNsAtICjCppaJBYxQRGenRH3VPDMTb75w3H73AA3YAfMSHoBD+A8Alfg1HaP1JWJ2sS7APvGY6ReHQqeVdI/c2m9QOqZgUiBgUiBgUiBgUiBgUiBgUiBgUiBgUiBgUiBgUiBgUiBgUhBAdbWI6ROrYX6OSXp130ELsBAfZya2u6RuvB9uV0+QTYN6OLgTpcAAAAASUVORK5CYII=';
  };

  render() {
    const { editMode, isDragging, connectDragSource, connectDropTarget } = this.props;
    const { mouseOver, shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
        onDragLeave={this.mouseLeave}
        onMouseMove={this.mouseMove}
      >
        {this.getElementStyle()}

        <div
          id={'ms_' + this.props.index.toString().replace(/\./g, '_')}
          class={'ms-dyn-el ' + shownClass + (isDragging ? 'ms-dyn-el-dragging ' : '') + this.getCssClasses()}
        >
          {/* show outline of the section */}
          {mouseOver &&
            editMode && [
              <span class="ms-dyn-el-outline ms-dyn-el-outline-top" key="t" />,
              <span class="ms-dyn-el-outline ms-dyn-el-outline-right" key="r" />,
              <span class="ms-dyn-el-outline ms-dyn-el-outline-bottom" key="b" />,
              <span class="ms-dyn-el-outline ms-dyn-el-outline-left" key="l" />,
              <div class="ms-dyn-el-menu" key="m">
                {' '}
                {/* show section settings and drag handle */}
                {connectDragSource(
                  <span class="btn bg-transparent text-white b-hide">
                    <i class="fa fa-arrows-alt" />
                  </span>
                )}
                <Button onClick={this.showSettings}>
                  <i class="fa fa-cog" />
                </Button>
              </div>,
            ]}

          <div>
            {this.props.children}
            <Clearfix />
          </div>

          {this.getAppendHtml()}
        </div>
      </div>
    );

    return (
      <div>
        <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
          {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
        </ScrollTrigger>
      </div>
    );
  }
}
