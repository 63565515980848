import React from 'react';

import AccountSearchLink from './AccountSearchLink';

export default class AccountSearchMenu extends React.Component {
  render() {
    const {
      index,
      currentIndex,
      label,
      // prevIndex,
      id,
      listClassName,
    } = this.props;

    const isActive = index === currentIndex;

    return (
      <li className={`ms-top-menu ${listClassName || ''} ${isActive ? 'active' : ''}`} id={id || undefined}>
        <AccountSearchLink label={label} />
      </li>
    );
  }
}
