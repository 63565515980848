import { isMobile } from 'react-device-detect';
import DetectRTC from 'detectrtc';

export const mayUseChat = () => {
  return Promise.resolve(DetectRTC.isWebSocketsSupported && !DetectRTC.isWebSocketsBlocked);
};

export const mayUseAudioCall = () => {
  return mayUseChat()
    .then(canUseChat => canUseChat && DetectRTC.isWebRTCSupported && DetectRTC.hasMicrophone)
    .then(available => Promise.all([Promise.resolve(available), navigator.permissions.query({ name: 'microphone' })]))
    .then(([available, permissionResult]) => {
      if (available && permissionResult.state === 'prompt') {
        return navigator.mediaDevices.getUserMedia({ audio: true }).then(() => true, () => false);
      }
      return available && permissionResult.state === 'granted';
    });
};

export const mayUseVideoCall = () => {
  return mayUseAudioCall()
    .then(canUseAudioCall => canUseAudioCall && DetectRTC.hasWebcam)
    .then(available => Promise.all([Promise.resolve(available), navigator.permissions.query({ name: 'camera' })]))
    .then(([available, permissionResult]) => {
      if (available && permissionResult.state === 'prompt') {
        return navigator.mediaDevices.getUserMedia({ video: true }).then(() => true, () => false);
      }
      return available && permissionResult.state === 'granted';
    });
};

export const initAudioCall = (successCallback, errorCallback) => {
  if (DetectRTC.MediaDevices[0] && DetectRTC.MediaDevices[0].isCustomLabel) {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then(stream => {
        stream.getTracks().forEach(track => {
          track.stop();
        });

        successCallback();
      })
      .catch(errorCallback);
  } else {
    successCallback();
  }
};

export const initVideoCall = (successCallback, errorCallback) => {
  if (DetectRTC.MediaDevices[0] && DetectRTC.MediaDevices[0].isCustomLabel) {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then(stream => {
        stream.getTracks().forEach(track => {
          track.stop();
        });

        successCallback();
      })
      .catch(errorCallback);
  } else {
    successCallback();
  }
};

export const getUserMediaConstraintsArgumentsFromState = webrtccall => {
  const { type } = webrtccall;
  const options = {};

  if (isMobile) {
    options.facingMode = webrtccall.cameraFacingMode;
  } else {
  }

  return { type, options };
};

// available options:
//   - audioSource
//   - videoSource
//   - facingMode
export const getUserMediaConstraints = (type = 'video', options = {}) => {
  const audio = { deviceId: options.audioSource ? { exact: options.audioSource } : undefined };

  if (type === 'audio') {
    return { audio };
  }

  const video = { deviceId: options.videoSource ? { exact: options.videoSource } : undefined };

  if (isMobile) {
    video.facingMode = { exact: options.facingMode ? options.facingMode : 'user' };
    if (video.facingMode === 'user') {
      video.width = { ideal: 720 };
    } else {
      video.width = { ideal: 1280 };
    }

    return { audio, video };
  }

  video.width = { ideal: 1280 };
  video.height = { ideal: 720 };

  return { audio, video };
};
