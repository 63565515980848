import React from 'react';
import { Row, Col, Clearfix } from 'react-bootstrap';

export default class SummaryItemListView extends React.Component {
  render() {
    return (
      <div class="summary-item summary-item-list">
        <Clearfix>
          {/* image: */}
          <div class="col-xs-4">
            <div class="summary-item-list-header">
              <div class="relative bg-master-light" style={{ paddingTop: '70%' }} />
            </div>
          </div>

          <div class="col-xs-8 no-padding">
            <div class="col-xs-12">
              <h3>
                <span class="ms-wireframe" style={{ width: '100%' }}>
                  <span class="block bg-master-light" style={{ width: '60%' }}>
                    {'\u00A0'}
                  </span>
                </span>
              </h3>
            </div>

            {/* details */}
            <div class="col-xs-6 summary-item-list-body">
              <Row class="no-margin">
                <Col xs={6} class="no-padding">
                  <i class="fas fa-circle text-master-lighter m-r-5" />
                  <span class="ms-wireframe" style={{ width: '50%' }}>
                    <span class="block bg-master-light lh-12" style={{ width: '100%' }}>
                      {'\u00A0'}
                    </span>
                  </span>
                </Col>
                <Col xs={6} class="no-padding">
                  <i class="fas fa-circle text-master-lighter m-r-5" />
                  <span class="ms-wireframe" style={{ width: '50%' }}>
                    <span class="block bg-master-light lh-12" style={{ width: '100%' }}>
                      {'\u00A0'}
                    </span>
                  </span>
                </Col>
                <Col xs={6} class="no-padding">
                  <i class="fas fa-circle text-master-lighter m-r-5" />
                  <span class="ms-wireframe" style={{ width: '50%' }}>
                    <span class="block bg-master-light lh-12" style={{ width: '100%' }}>
                      {'\u00A0'}
                    </span>
                  </span>
                </Col>
                <Col xs={6} class="no-padding">
                  <i class="fas fa-circle text-master-lighter m-r-5" />
                  <span class="ms-wireframe" style={{ width: '50%' }}>
                    <span class="block bg-master-light lh-12" style={{ width: '100%' }}>
                      {'\u00A0'}
                    </span>
                  </span>
                </Col>
                <Col xs={6} class="no-padding">
                  <i class="fas fa-circle text-master-lighter m-r-5" />
                  <span class="ms-wireframe" style={{ width: '50%' }}>
                    <span class="block bg-master-light lh-12" style={{ width: '100%' }}>
                      {'\u00A0'}
                    </span>
                  </span>
                </Col>
                <Col xs={6} class="no-padding">
                  <i class="fas fa-circle text-master-lighter m-r-5" />
                  <span class="ms-wireframe" style={{ width: '50%' }}>
                    <span class="block bg-master-light lh-12" style={{ width: '100%' }}>
                      {'\u00A0'}
                    </span>
                  </span>
                </Col>
              </Row>
            </div>

            <div class="col-xs-6">
              <h3 class="text-center">
                {/* price */}
                <span class="ms-wireframe" style={{ width: '50%' }}>
                  <span class="block bg-master-light" style={{ width: '100%' }}>
                    {'\u00A0'}
                  </span>
                </span>
              </h3>
            </div>
          </div>
        </Clearfix>
      </div>
    );
  }
}
