import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';

import { getXlFile } from 'helpers/items';

class PhotosSlider extends React.Component {
  getImageLink = index => {
    const {
      data: {
        assets: { imgs },
      },
    } = this.props;

    const img = imgs[index];
    const image = getXlFile(img);

    if (image) {
      return image.web_link;
    }

    return null;
  };

  getImageComponent = (index, itemName, flat = false) => {
    return (
      <div style={{ paddingTop: flat ? '28%' : '56.25%' }} className="relative">
        <div className="top-left bottom-right no-overflow">
          <img
            src={this.getImageLink(index)}
            className="img-responsive relative animate-all"
            style={{
              top: '50%',
              transform: 'translateY(-50%)',
              minWidth: '100%',
            }}
            alt={itemName + ' photo ' + (index + 1)}
          />
        </div>
      </div>
    );
  };

  render() {
    const { data, layout } = this.props;

    const itemName = data.name;
    const imageCount = data.assets && data.assets.imgs ? data.assets.imgs.length : 0;

    const settings = {
      dots: layout.breakpointIndex > 1, // display the dots only on larger devices
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return imageCount ? (
      <Grid>
        <Row className="p-t-30 p-b-40 item-details-photo-slider">
          <Slider {...settings}>
            {data.assets.imgs.map((img, i) => {
              return (
                <Col xs={12} sm={6} className="relative" key={img.id}>
                  {this.getImageComponent(i, itemName)}
                </Col>
              );
            })}
          </Slider>
        </Row>
      </Grid>
    ) : null;
  }
}

export default PhotosSlider;
