import React from 'react';
import { OverlayTrigger, Tooltip, Overlay, Popover } from 'react-bootstrap';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-less';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';

export default class FormCodeEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValue: props.value ? props.value : '',
      value: props.value ? props.value : '',
      focused: false,
    };

    this.inputRef = React.createRef();
  }

  checkFocused = () => {
    return this.state.focused ? 'focused ' : '';
  };

  checkRequired = () => {
    return this.props.meta.validate.rules.indexOf('required') !== -1 ? 'required ' : '';
  };

  checkError = () => {
    return this.props.validation.valid ? '' : 'has-error ';
  };

  // check if user may read this field
  checkAccess = () => {
    return this.props.meta.acl.read;
  };

  // check if user may write to this field
  checkReadOnly = () => {
    return !this.props.meta.acl.write;
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.value !== prevProps.value){
      this.setState({value: this.props.value});
    }
  }

  catchFocus = () => {
    //
  };

  onChange = value => {
    const { name, disabled, onChange } = this.props;

    if (!disabled) {
      this.setState({ value });
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        onChange(name, value);
      }, 150);
    }
  };

  checkDisabled = () => {
    const { disabled, meta } = this.props;
    if (disabled) {
      return true;
    }
    if (!meta.acl.write) {
      return true;
    }
    return false;
  };

  setValue = value => {
    this.setState({ value });
    this.onChange(value);
  };

  render() {
    const { label, validation, infoText, language } = this.props;
    const { value } = this.state;

    return (
      <div
        class={
          'form-group form-group-default ' +
          this.checkFocused() +
          this.checkRequired() +
          this.checkError() +
          (this.checkDisabled() ? 'disabled ' : '')
        }
        onClick={this.catchFocus}
      >
        <label>
          {label}
          {infoText ? (
            <span class="m-l-5">
              <OverlayTrigger placement="top" overlay={<Tooltip>{infoText}</Tooltip>}>
                <i class="far fa-question-circle cursor-help" />
              </OverlayTrigger>
            </span>
          ) : null}
        </label>

        <div className="prism-editor">
          <Editor
            ref={this.inputRef}
            value={value || ''}
            onValueChange={this.onChange}
            highlight={code => highlight(code, languages[language])}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              background: '#2d2d2d',
            }}
          />
        </div>

        <Overlay show={this.state.focused && !validation.valid} target={this.inputRef.current} placement="top">
          <Popover class="popover-modal">{validation.helpText}</Popover>
        </Overlay>
      </div>
    );
  }
}
FormCodeEditor.defaultProps = {
  label: '',
  meta: { validate: { rules: '' }, acl: { read: true, write: true } },
  validation: { valid: true },
  value: '',
  onChange: () => {},
  disabled: false,
  infoText: '',
  language: 'less',
};
