import React from 'react';
import PropType from 'prop-types';

import ValidationAlertIcon from './ValidationAlertIcon';
import { reduceValidationResult } from './Validation';
import { ValidationResult } from '../../helpers/proptypes';
import Severity from './Severity';

ValidationAlert.propTypes = {
  property: PropType.string,
  validationResult: ValidationResult,
};

ValidationAlert.defaultProps = {
  property: undefined,
  validationResult: undefined,
};

function ValidationAlert({ property, validationResult }) {
  if (!validationResult) {
    return null;
  }
  const validationResultEntry = reduceValidationResult(validationResult, property);
  if (!validationResultEntry) {
    return null;
  }

  const { severity, helpText } = validationResultEntry || {};
  return (
    <div className={`validation-alert ${Severity.toString(severity)}`}>
      <ValidationAlertIcon property={property} validationResult={validationResultEntry} />
      <div className="alert-text">{helpText}</div>
    </div>
  );
}

export default ValidationAlert;
