export default function docRequiredExpirationDate(entity) {
  let isValid = true,
    helpTextTranslationIndex = 'doc_expiration_date_required',
    fields = [];

  if (!entity.getExpirationDate()) {
    isValid = false;
    fields = [{ name: 'expirationDate', fieldTree: [] }];
  }

  return {
    valid: isValid,
    rule: 'docRequiredExpirationDate',
    helpTextTranslationIndex,
    fields,
  };
}
