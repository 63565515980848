import { intlModule } from '@chedri/base'
import React, { useCallback } from 'react';
// import PropTypes from 'prop-types';
import { CartItem } from '../../../helpers/proptypes';
import { useValidation } from '../../validation/ValidationContext';
import { useWizardStep } from './CartWizardStepContext';
import IconButton from '../../IconButton';
import { useSelector } from 'react-redux';

CartWizardStepActions.propTypes = {
  // eslint-disable-next-line react/require-default-props
  cartItem: CartItem,
};

function CartWizardStepActions({ cartItem }) {
  const messages = useSelector(intlModule.selectors.getMessages);
  const { item, confirm, remove, configure, domainAvailability } = useWizardStep();
  const { validate } = useValidation();
  const isCartPending = useSelector(state => state.cart.pending);

  const save = useCallback(async () => {
    const valid = await validate(item);
    if (valid) {
      confirm(item);
    }
  }, [item, confirm]);

  return (
    <div className="wizard-step-actions">
      {!(cartItem.is_wants_domain_hosting) ? null : (
        <IconButton
          name="bg-blue fa-globe"
          buttonClassName="primary wizard-icon-btn"
          label={messages.check_availability}
          disabled={isCartPending}
          onClick={domainAvailability}
        />
      )}
      <IconButton
        name="fa-trash-alt"
        buttonClassName="primary wizard-icon-btn"
        label={messages.remove_item}
        disabled={isCartPending}
        onClick={remove}
      />
      {cartItem.is_configuration_finished ? (
        <IconButton
          name="fa-cogs"
          buttonClassName="success wizard-icon-btn"
          label={messages.config}
          disabled={isCartPending}
          onClick={configure}
        />
      ) : (
        <IconButton
          name="fa-check"
          buttonClassName="success wizard-icon-btn"
          solid
          label={messages.submit_config}
          disabled={isCartPending}
          onClick={save}
        />
      )}
    </div>
  );
}

export default CartWizardStepActions;
