import React from 'react';
import { FormattedNumber } from 'react-intl';

import { getShippingType } from 'helpers/checkout';
import { SHIPPING_PICKUP, SHIPPING_TRANSPORT } from 'js/constants';

export default class Transport extends React.Component {
  getName = () => {
    const {
      intl: { messages },
      checkout,
    } = this.props;

    switch (getShippingType(checkout)) {
      case 'parcel':
        return messages.shipping_parcel;

      case SHIPPING_PICKUP:
        return messages.shipping_pickup;

      case SHIPPING_TRANSPORT:
        return messages.shipping_transport;
    }
  };

  getCost = () => {
    const { checkout } = this.props;

    if (checkout.shipping) {
      return checkout.shipping.cost;
    }

    return 0;
  };

  render() {
    const { layout, checkout, projectConfig } = this.props;

    const type = getShippingType(checkout);
    const name = this.getName();
    const cost = this.getCost();

    if (type === SHIPPING_TRANSPORT && cost) {
      const itemImage = (
        <div title={name}>
          <div class="p-t-20 p-b-20 bg-master-lightest text-center">
            <i class="fa fa-cube" />
          </div>
        </div>
      );
      const itemDetails = (
        <div>
          <h5 className="bold m-t-0">{name}</h5>

          <h6>Ihr Vertragspartner</h6>
          <div className="small">
            <address>
              HC Digital Services GmbH
              <br />
              Trippstadterstr. 110
              <br />
              67663 Kaiserslautern
            </address>
          </div>
        </div>
      );
      const itemPrice = (
        <h5 className="bold">
          <FormattedNumber value={cost} style="currency" currency={projectConfig.data.currency} />
        </h5>
      );

      return layout.breakpointIndex > 1 ? (
        <tr>
          <td className="col-xs-3 v-align-middle">{itemImage}</td>
          <td className="col-xs-6 v-align-middle">{itemDetails}</td>
          <td className="col-xs-3 v-align-middle text-right">{itemPrice}</td>
        </tr>
      ) : (
        <React.Fragment>
          {itemDetails} {itemPrice}
        </React.Fragment>
      );
    }

    if (type === 'parcel' && cost) {
      const itemImage = (
        <div title={name}>
          <div class="p-t-20 p-b-20 bg-master-lightest text-center">
            <i class="fa fa-cube" />
          </div>
        </div>
      );
      const itemDetails = (
        <div>
          <h5 className="bold m-t-0">{name}</h5>
        </div>
      );
      const itemPrice = (
        <h5 className="bold">
          <FormattedNumber value={cost} style="currency" currency={projectConfig.data.currency} />
        </h5>
      );

      return layout.breakpointIndex > 1 ? (
        <tr>
          <td className="col-xs-3 v-align-middle">{itemImage}</td>
          <td className="col-xs-6 v-align-middle">{itemDetails}</td>
          <td className="col-xs-3 v-align-middle text-right">{itemPrice}</td>
        </tr>
      ) : (
        <React.Fragment>
          {itemDetails} {itemPrice}
        </React.Fragment>
      );
    }

    return null;
  }
}
