import React from 'react';
import { FormattedNumber } from 'react-intl';

import { SHIPPING_PICKUP, SHIPPING_TRANSPORT } from 'js/constants';

export default class CheckoutSummaryTransport extends React.Component {
  getName = () => {
    const {
      intl: { messages },
      checkout,
    } = this.props;

    if (checkout.shipping) {
      switch (checkout.shipping.method) {
        case 'parcel':
          return messages.shipping_parcel;

        case SHIPPING_PICKUP:
          return messages.shipping_pickup;

        case SHIPPING_TRANSPORT:
          return messages.shipping_transport;
      }
    }

    return messages.shipping_pickup;
  };

  getCost = () => {
    const { checkout } = this.props;

    if (checkout.shipping) {
      return checkout.shipping.cost;
    }

    return 0;
  };

  render() {
    const {
      intl: { messages },
      projectConfig,
      className,
    } = this.props;

    const name = this.getName();
    const cost = this.getCost();

    return (
      <div className={className}>
        <h4 class="m-t-0">{messages.shipping_information}</h4>

        <div>
          <h5 className="semi-bold m-t-0">{name}</h5>
          {cost > 0 && (
            <div>
              <FormattedNumber value={cost} style="currency" currency={projectConfig.data.currency} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
CheckoutSummaryTransport.defaultProps = {
  className: '',
};
