import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { authModule, communicationModule } from '@chedri/base';

import { isPluginActive } from 'helpers/whiteLabel';
import { CHEDRI_COMMS_CENTER_PLUGIN } from 'js/constants';

import { createSelector } from 'reselect';
import ChatEmployeeDetached from './ChatEmployeeDetached';
import ChatCustomerDetached from './ChatCustomerDetached';

const selectProjectConfigData = createSelector(
  state => state.data,
  state => state.projectConfig
);
function ChatControlCenter() {
  const { pathname } = useLocation();

  const projectConfig = useSelector(selectProjectConfigData);
  const isAuthenticated = useSelector(authModule.selectors.isAuthenticated);
  const isAnonymous = useSelector(communicationModule.selectors.isAnonymous);
  const isEmployee = useSelector(authModule.selectors.isEmployee);

  const [showEmployee, setShowEmployee] = useState(false);
  const isPathCommunicationCenter = pathname.indexOf('/communication') > -1;
  const showCustomer =
    !showEmployee && !isPathCommunicationCenter && isPluginActive(CHEDRI_COMMS_CENTER_PLUGIN, projectConfig.data);

  useEffect(() => {
    if (isAuthenticated && isEmployee) {
      setShowEmployee(!isPathCommunicationCenter);
    } else {
      setShowEmployee(false);
    }
  }, [isPathCommunicationCenter, isAuthenticated, isAnonymous, isEmployee, setShowEmployee]);

  return (
    <div className="hidden-print">
      {/*showEmployee && <ChatEmployeeDetached />}
      {//showCustomer && <ChatCustomerDetached />*/}
    </div>
  );
}

export default ChatControlCenter;
