/* eslint no-param-reassign: ["error", { "props": false }] */
import validator from 'formValidation/Validator';
import {
  clone as _clone,
  forEach as _forEach,
  isFunction as _isFunction,
  isInteger as _isInteger,
  isString as _isString,
  isObject as _isObject,
} from 'lodash';

const capitalize = name => name.charAt(0).toUpperCase() + name.substring(1);
const setterName = propertyName => 'set' + capitalize(propertyName);
const getterName = propertyName => 'get' + capitalize(propertyName);
const VALIDATIONRESULT = 'ValidationResult';
const METAINFORMATION = 'MetaInformation';

export default class EntityV2 {
  __key = 0;

  __dataLoaded = false;

  __metaLoaded = false;

  __data = null;

  __meta = null;

  __mainMetaIdentifier = null;

  __isValid = true;

  __errors = [];

  __extraValidators = [];

  __setData = data => {
    this.__dataLoaded = true;
    this.__data = data;

    if (this.__metaLoaded) {
      this.__processData();
    }

    return this;
  };

  __getDataLoaded = () => {
    return this.__dataLoaded;
  };

  // this is the whole meta information, not only for this entity
  // but we also need the main meta information to know where to start from
  __setMeta = (meta, mainMetaIdentifier) => {
    this.__metaLoaded = true;
    this.__meta = meta;
    this.__mainMetaIdentifier = mainMetaIdentifier;

    if (this.__dataLoaded) {
      this.__processData();
    }

    return this;
  };

  __getMetaLoaded = () => {
    return this.__metaLoaded;
  };

  // we have all the data and the meta information
  // so we can format the data structure to
  __processData = () => {
    this.__loopMeta(this, this.__data, this.__mainMetaIdentifier, []);
    this.__key++;
  };

  // get meta identifier for given field name
  __getMetaIdentifier = (fieldName, fieldTree = []) => {
    let toRead = this;
    if (fieldTree.length > 0) {
      for (let i = 0; i < fieldTree.length; i++) {
        toRead = toRead[fieldTree[i]];
      }
    }

    return toRead[getterName(fieldName) + METAINFORMATION].type;
  };

  __createValue = (fieldName, initialValue = {}, fieldTreeSource = []) => {
    const fieldTree = _clone(fieldTreeSource);
    const metaIdentifier = this.__getMetaIdentifier(fieldName, fieldTree);

    fieldTree.push(fieldName);

    return this.__loopMeta({}, initialValue, metaIdentifier, fieldTree);
  };

  __updateValidation = (fieldName, validationResult, fieldTree = []) => {
    if (!validationResult.valid) {
      this.__errors.push({ fieldName, validationResult, fieldTree });
      this.__isValid = false;
    }
  };

  __loopMeta = (obj, data, metaIdentifier, fieldTree = []) => {
    const meta = this.__meta[metaIdentifier].data;

    Object.entries(meta).forEach(([fieldName, fieldMeta]) => {
      if (
        fieldMeta.complexType !== undefined &&
        fieldMeta.complexType &&
        fieldMeta.array !== undefined &&
        fieldMeta.array
      ) {
        // this field is a complex type and is an array
        const values = [];
        if (data[fieldMeta.name] !== undefined) {
          _forEach(data[fieldMeta.name], (value, key) => {
            const newFieldTree = fieldTree.slice();
            newFieldTree.push(fieldName);
            values[key] = this.__loopMeta({}, value !== undefined ? value : {}, fieldMeta.type, newFieldTree);
          });
        }
        obj[fieldName] = values;
      } else if (fieldMeta.complexType !== undefined && fieldMeta.complexType) {
        // this field is complex type, but not an array
        const newFieldTree = fieldTree.slice();
        newFieldTree.push(fieldName);
        obj[fieldName] = this.__loopMeta(
          {},
          data[fieldMeta.name] !== undefined ? data[fieldMeta.name] : {},
          fieldMeta.type,
          newFieldTree
        );
      } else if (fieldMeta.array !== undefined && fieldMeta.array) {
        // this is an array of simple type data
        const values = [];
        if (data[fieldMeta.name] !== undefined) {
          _forEach(data[fieldMeta.name], (value, key) => {
            values[key] = value;
          });
        }
        obj[fieldName] = values;
      } else {
        // eslint-disable-next-line no-lonely-if
        if (data !== null && data[fieldMeta.name] !== undefined) {
          obj[fieldName] = data[fieldMeta.name];
        } else if (data !== null && data[fieldName] !== undefined) {
          obj[fieldName] = data[fieldName];
        } else {
          obj[fieldName] = null;
        }
      }

      obj[fieldName + METAINFORMATION] = fieldMeta;
      obj[fieldName + VALIDATIONRESULT] = { valid: true };

      const getterPrefix = getterName(fieldName);
      const setterPrefix = setterName(fieldName);

      Object.defineProperties(obj, {
        [getterPrefix]: {
          get() {
            return obj[fieldName];
          },
        },
        [setterPrefix]: {
          set(value) {
            obj[fieldName] = value;
          },
        },
        [getterPrefix + METAINFORMATION]: {
          get() {
            return obj[fieldName + METAINFORMATION];
          },
        },
        [getterPrefix + VALIDATIONRESULT]: {
          get() {
            return obj[fieldName + VALIDATIONRESULT];
          },
        },
        [setterPrefix + VALIDATIONRESULT]: {
          set(value) {
            obj[fieldName + VALIDATIONRESULT] = value;
          },
        },
      });
    });

    return obj;
  };

  __validate = () => {
    this.__isValid = true; // initial value; if we find some errors, the value will be false
    this.__errors = [];
    this.__loopValidate(this, this.__mainMetaIdentifier, []);
    this.__loopExtraValidators();

    this.__key++;

    return this;
  };

  __loopValidate = (data, metaIdentifier, fieldTree = []) => {
    const meta = this.__meta[metaIdentifier].data;
    Object.entries(meta).forEach(([fieldName, fieldMeta]) => {
      const setterPrefix = setterName(fieldMeta.name);

      if (
        fieldMeta.complexType !== undefined &&
        fieldMeta.complexType &&
        fieldMeta.array !== undefined &&
        fieldMeta.array
      ) {
        // this field is a complex type and is an array
        if (
          data[fieldMeta.name] !== undefined &&
          Object.prototype.toString.call(data[fieldMeta.name]) === '[object Array]'
        ) {
          if (!fieldMeta.ref) {
            // it's not a referenced object, we need to validate it
            Object.keys(data[fieldMeta.name]).forEach(key => {
              const newFieldTree = fieldTree.slice();
              newFieldTree.push(fieldName);
              newFieldTree.push(key);
              this.__loopValidate(data[fieldName][key], fieldMeta.type, newFieldTree);
            });
          } else if (fieldMeta.validate.rules && fieldMeta.validate.rules.indexOf('required') !== -1) {
            // it's an array of referenced objects, but it is required. check if user has selected at least one
            let validationResult;
            if (data[fieldMeta.name] === undefined || data[fieldMeta.name].length === 0) {
              validationResult = { valid: false, rule: 'required', helpText: fieldMeta.validate.messages.required };
            } else {
              validationResult = { valid: true };
            }

            data[setterPrefix + VALIDATIONRESULT] = validationResult;
            this.__updateValidation(fieldMeta.name, validationResult, fieldTree);
          }
        }
      } else if (fieldMeta.complexType !== undefined && fieldMeta.complexType) {
        // this field is complex type, but not an array
        if (!fieldMeta.ref) {
          // it's not a referenced object, we need to validate it
          if (
            (fieldMeta.validate.rules && fieldMeta.validate.rules.indexOf('required') !== -1) ||
            (data[fieldName] && data[fieldName].id)
          ) {
            const newFieldTree = fieldTree.slice();
            newFieldTree.push(fieldName);
            this.__loopValidate(data[fieldName] !== undefined ? data[fieldName] : {}, fieldMeta.type, newFieldTree);
          } else {
            this.__updateValidation(fieldMeta.name, { valid: true }, fieldTree);
          }
        } else if (fieldMeta.validate.rules && fieldMeta.validate.rules.indexOf('required') !== -1) {
          // it's a referenced object, but it is required. check if user has set an object
          const hasID =
            fieldMeta.type === 'Client:Client' ? Boolean(data[fieldName]?.username) : Boolean(data[fieldName]?.id);

          const validationResult = hasID
            ? { valid: true }
            : { valid: false, rule: 'required', helpText: fieldMeta.validate.messages.required };

          data[setterPrefix + VALIDATIONRESULT] = validationResult;
          this.__updateValidation(fieldMeta.name, validationResult, fieldTree);
        }
      } else if (fieldMeta.array) {
        // this is an array of simple type data
        if (data[fieldName] !== undefined && Object.prototype.toString.call(data[fieldName]) === '[object Array]') {
          if (fieldMeta.validate.rules?.includes('required')) {
            const fieldRules = this.__getValidationRules(fieldMeta.validate.rules);
            let arrayValid = true;
            Object.keys(data[fieldName]).forEach(key => {
              const validate = this.__validateOne(data[fieldName][key], fieldRules, fieldMeta.validate.messages);

              const newFieldTree = fieldTree.slice();
              newFieldTree.push(key);

              if (!validate.valid) {
                this.__updateValidation(fieldName, validate, newFieldTree);
                arrayValid = false;
              } else {
                this.__updateValidation(fieldName, { valid: true }, newFieldTree);
              }
            });

            this.__updateValidation(fieldName, { valid: arrayValid }, fieldTree);
          }
        }
      } else {
        // data[ fieldMeta.name ] !== undefined ? data[ fieldMeta.name ] : null;
        // validate only if the field is required or if a value is set
        // eslint-disable-next-line no-lonely-if
        if (fieldMeta.validate.rules && (fieldMeta.validate.rules.includes('required') || data[fieldName])) {
          const fieldRules = this.__getValidationRules(fieldMeta.validate.rules);
          const validate = this.__validateOne(data[fieldName], fieldRules, fieldMeta.validate.messages);

          const validationResult = validate.valid ? { valid: true } : validate;

          data[setterPrefix + VALIDATIONRESULT] = validationResult;
          this.__updateValidation(fieldName, validationResult, fieldTree);
        }
      }
    });
  };

  __getValidationRules = validateConfig => {
    return validateConfig.split(',').map(rule => {
      const params = rule.split(':');
      let name = params.shift();
      const inverse = name[0] === '!';

      if (inverse) {
        name = name.substring(1);
      }

      return { name, inverse, params };
    });
  };

  __validateOne = (value, rules, helpText) => {
    const result = { valid: true };
    rules.forEach(rule => {
      if (typeof validator[rule.name] !== 'function') {
        throw new Error('Invalid input validation rule "' + rule.name + '"');
      }

      let ruleResult = validator[rule.name](value, ...rule.params);

      if (rule.inverse) {
        ruleResult = !ruleResult;
      }

      if (result.valid && ruleResult !== true) {
        result.valid = false;
        result.rule = rule.name;
        result.helpText = helpText[rule.name];
      }
    });

    return result;
  };

  // there is a possibility to set own validators
  // for example when the validator uses not only one field
  __addValidator = validator => {
    this.__extraValidators.push(validator);
  };

  // check the extra validators (if there are any)
  __loopExtraValidators = () => {
    _forEach(this.__extraValidators, validateFunction => {
      const result = validateFunction(this);
      if (!result.valid) {
        this.__isValid = false;
        _forEach(result.fields, field => {
          const { name, fieldTree } = field;

          // update the field in-validation
          const previousResult = this[getterName(name) + VALIDATIONRESULT];
          if (previousResult) {
            const { valid: isPreviousValid } = previousResult;
            if (isPreviousValid) {
              this[setterName(name) + VALIDATIONRESULT] = result;
            }
          }

          this.__updateValidation(name, result, fieldTree);
        });
      }
    });
  };

  // return the data that was manipulated by the user
  // omit meta data, validation and protected functions (starting with __)
  __getStoredData = (obj = null) => {
    if (obj === null) {
      return this.__getStoredData(this);
    }

    const res = {};

    _forEach(obj, (value, key) => {
      if (
        !_isFunction(value) &&
        (_isInteger(key) ||
          (_isString(key) &&
            !key.startsWith('__') &&
            !key.endsWith(METAINFORMATION) &&
            !key.endsWith(VALIDATIONRESULT)))
      ) {
        if (_isObject(value)) {
          res[key] = this.__getStoredData(value);
        } else {
          res[key] = value;
        }
      }
    });

    return res;
  };

  // copy object and skip id's, so that there won't be any conflicts in the db
  __copyObject = obj => {
    const result = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const fieldName in obj) {
      if (
        Object.prototype.hasOwnProperty.call(obj, fieldName) &&
        fieldName !== 'id' &&
        fieldName !== 'username' &&
        Object.prototype.toString.call(obj[fieldName]) !== '[object Function]'
      ) {
        const fieldNameUnderscore = fieldName
          .replace(/\.?([A-Z]+)/g, (x, y) => {
            return '_' + y.toLowerCase();
          })
          .replace(/^_/, '');

        if (Object.prototype.toString.call(obj[fieldName]) === '[object Array]') {
          const array = [];
          Object.keys(obj[fieldName]).forEach(key => {
            array.push(this.__copyObject(obj[fieldName][key]));
          });
          result[fieldNameUnderscore] = array;
        } else if (obj[fieldName] !== null && typeof obj[fieldName] === 'object') {
          result[fieldNameUnderscore] = this.__copyObject(obj[fieldName]);
        } else {
          result[fieldNameUnderscore] = obj[fieldName];
        }
      }
    }

    return result;
  };
}
