import React from 'react';
import { Clearfix } from 'react-bootstrap';

import { energyLabels } from 'helpers/items';

class EnergyLabel extends React.Component {
  render() {
    const { label } = this.props;

    const fontSize = 10;
    const padding = 4;
    const height = fontSize + 2 * padding;

    return label ? (
      <div className="p-t-10 p-b-20 item-details-energy-label">
        <div className="relative">
          {energyLabels.map((energyLabel, i) => {
            const style = {
              width: 30 + (i + 1) * 5 + '%',
              background: energyLabel.color,
              padding: `${padding}px 0 ${padding}px 10px`,
              marginBottom: '10px',
              color: '#fff',
            };
            const arrowRightStyle = {
              height: `${height}px`,
              borderTop: `${height / 2}px solid transparent`,
              borderBottom: `${height / 2}px solid transparent`,
              borderLeft: `${height / 2}px solid ` + energyLabel.color,
            };
            const arrowLeftStyle = {
              height: `${height * 2}px`,
              borderTop: `${height}px solid transparent`,
              borderBottom: `${height}px solid transparent`,
              borderRight: `${height}px solid  #000`,
              marginTop: `-${fontSize}px`,
            };

            return (
              <Clearfix key={energyLabel.label}>
                <div className={`fs-${fontSize} lh-${fontSize} pull-left bold`} style={style}>
                  {energyLabel.label}
                </div>
                <div className="pull-left" style={arrowRightStyle} />

                {label === energyLabel.label && (
                  <React.Fragment>
                    <div
                      className={`fs-${fontSize * 2} lh-${fontSize * 2} pull-right bold text-center`}
                      style={{
                        ...style,
                        background: '#000',
                        width: '20%',
                        marginTop: -1 * fontSize + 'px',
                        marginBottom: 0,
                      }}
                    >
                      {label}
                    </div>
                    <div className="pull-right" style={arrowLeftStyle} />
                  </React.Fragment>
                )}
              </Clearfix>
            );
          })}
        </div>
      </div>
    ) : null;
  }
}

export default EnergyLabel;
