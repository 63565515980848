import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Clearfix } from 'react-bootstrap';
import AnimateHeight from 'react-animate-height';
import {
  concat as _concat,
  sortBy as _sortBy,
  pullAll as _pullAll,
  find as _find,
  forEach as _forEach,
  findIndex as _findIndex,
} from 'lodash';

import { parseQs, stringifyQs } from 'helpers/http';
import { mapping } from 'helpers/items';

import List from 'components/List';

import SummaryFilterValue from 'components/layout/summary/SummaryFilterValue';

class SummaryFilterCarModel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mouseOver: false,
      show: false,
    };
  }

  handleClick = () => {
    this.setState(prevProps => ({ show: !prevProps.show }));
  };

  valueClick = (filterName, value, checked) => {
    // new values for this filter:
    let values = this.props.filters[filterName] !== undefined ? this.props.filters[filterName].slice() : [];

    if (checked) {
      values = _concat(values, [value]);
    } else {
      _pullAll(values, [value]);
    }

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: stringifyQs({ ...parseQs(this.props.location.search), [filterName]: values }),
    });
  };

  isValueChecked = (filterName, value) => {
    const { filters } = this.props;
    const stateIndex = filters[filterName] !== undefined ? filters[filterName].indexOf(value) : -1;
    return stateIndex >= 0;
  };

  hasFilters = () => {
    const { filters } = this.props;
    return filters.MODEL !== undefined || filters.MODEL_GROUP !== undefined;
  };

  componentDidUpdate = prevProps => {
    const { location, history } = this.props;

    if (prevProps.location.search !== location.search) {
      const nextQs = parseQs(location.search);
      const prevQs = parseQs(prevProps.location.search);

      if (prevQs.BRAND && prevQs.BRAND.length && (!nextQs.BRAND || nextQs.BRAND.length === 0)) {
        history.replace({
          pathname: location.pathname,
          search: stringifyQs({ ...nextQs, MODEL: undefined, MODEL_GROUP: undefined }),
        });
      }
    }
  };

  getModels = () => {
    const { models } = this.props;
    const modelsFacet = _find(models.data.list, ['id', mapping.model]);

    if (modelsFacet) {
      let res = [];
      const groups = [];
      _forEach(modelsFacet.values, model => {
        if (model.group_id) {
          if (_findIndex(groups, ['name', model.group_name]) === -1) {
            groups.push({
              filterName: 'MODEL_GROUP',
              name: model.group_name,
              src: model.assets ? model.assets[0] : null,
              src_selected: null,
              icon: model.assets ? null : <i class="fal fa-car-side hint-text" />,
            });
          }
        } else {
          res.push({
            filterName: 'MODEL',
            name: model.name,
            src: model.assets ? model.assets[0] : null,
            icon: model.assets ? null : <i class="fal fa-car-side hint-text" />,
            src_selected: null,
          });
        }
      });

      res = _concat(res, groups);
      res = _sortBy(res, ['name']);

      return res;
    }

    return [];
  };

  render() {
    const {
      intl: { messages },
      location,
      models,
    } = this.props;
    const { mouseOver, show } = this.state;

    const qs = parseQs(location.search);

    if (qs.BRAND && qs.BRAND.length) {
      const facetUrlData = { category: ',0,0000000006,', filter: { BRAND: qs.BRAND } };

      return (
        <div
          class={'summary-filter animate-all ' + (mouseOver ? 'hover ' : '') + (show ? 'active ' : '')}
          onMouseEnter={() => this.setState({ mouseOver: true })}
          onMouseLeave={() => this.setState({ mouseOver: false })}
        >
          <List
            listName="summaryModels"
            url="/api/summary/carmodels.json"
            urlData={facetUrlData}
            list={models}
            noRender
          />

          <div class="summary-filter-title" onClick={this.handleClick}>
            <Clearfix>
              <div class="pull-left">{messages.model}</div>{' '}
              {/* filter name comes already with the name in proper language */}
              <div class="pull-right">
                {this.hasFilters() && !show ? (
                  <i class="fal fa-check text-success" />
                ) : (
                  <i class={'fal fa-chevron-' + (show ? 'up' : 'right')} />
                )}
              </div>
            </Clearfix>
          </div>

          <AnimateHeight duration={200} height={show ? 'auto' : 0}>
            <div class="summary-filter-body">
              {this.getModels().map(model => {
                return (
                  <SummaryFilterValue
                    key={model.name}
                    type="select"
                    value={model}
                    checked={this.isValueChecked(model.filterName, model.name)}
                    onClick={(value, checked) => this.valueClick(model.filterName, value, checked)}
                  />
                );
              })}
            </div>
          </AnimateHeight>
        </div>
      );
    }

    return (
      <div class="summary-filter animate-all summary-filter-disabled hint-text">
        <Clearfix class="summary-filter-title">
          <div class="pull-left">{messages.model}</div>
          <div class="pull-right">
            <i class="fal fa-chevron-right" />
          </div>
        </Clearfix>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    models: state.list.summaryModels,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(SummaryFilterCarModel)));
