import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Clearfix, Form, Button, Overlay, Tooltip, Popover, Alert } from 'react-bootstrap';
import AnimateHeight from 'react-animate-height';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import scriptLoader from 'react-async-script-loader';
import Loader from 'components/layout/Loader';
import { GOOGLE_MAPS_URL } from 'js/constants';

import { parseQs, stringifyQs } from 'helpers/http';

class SummaryFilterLocation extends React.Component {
  constructor(props) {
    super(props);

    const localStorageLocation = localStorage.getItem('location') || null;

    this.state = {
      mouseOver: false,
      show: false,
      changed: !!localStorageLocation,
      location: localStorageLocation,
      address: localStorage.getItem('locationAddress') || '',
      distance: localStorage.getItem('locationDistance') || 20,
      showDistanceOptions: false,
      showGeoLocationTooltip: false,
    };

    this.distanceTargetRef = React.createRef();
    this.geoLocationTootipTargetRef = React.createRef();
  }

  hide = () => {
    this.setState({ show: false, showDistanceOptions: false, showGeoLocationTooltip: false });
  };

  handleClick = () => {
    this.setState(prevProps => ({ show: !prevProps.show }));
  };

  getName = () => {
    return 'LOCATION';
  };

  // set filter, local storage
  setFilter = () => {
    const { location, history } = this.props;
    const { location: locationCoordinates, address, distance } = this.state;
    let filters = {};
    if (locationCoordinates) {
      filters = {
        ...filters,
        LOCATION: locationCoordinates,
        ADDRESS: address,
        DISTANCE: distance,
      };
    }

    localStorage.setItem('locationAddress', address);
    localStorage.setItem('locationDistance', distance);
    localStorage.setItem('location', locationCoordinates);

    history.replace({
      pathname: location.pathname,
      search: stringifyQs({ ...parseQs(location.search), ...filters }),
    });
    this.setState({ changed: false });
  };

  hasFilters = () => {
    return this.props.filters.LOCATION !== undefined;
  };

  // check if detection was pending and did complete
  // if so, set the filter and close the overlay
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.state.posDetectPending && prevState.posDetectPending && !this.state.posDetectError) {
      this.setFilter();
    }
  };

  // trigger browser (html5) position detection
  // after the detection is done, we will do a google call to get a name for the location
  getCurrentPosition = () => {
    if (navigator.geolocation) {
      this.setState({ posDetectPending: true });

      navigator.geolocation.getCurrentPosition(
        position => {
          this.getPosition(position.latitude + ',' + position.longitude);
        },
        () => {
          this.setState({ posDetectPending: false, posDetectError: true });
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    }
  };

  // use google geocoding to get the position
  getPosition = address => {
    this.setState({ posDetectPending: true, posDetectError: false }, () => {
      geocodeByAddress(address)
        .then(results => {
          getLatLng(results[0]).then(latLng => this.setPosDetectResult(results[0], latLng));
        })
        .catch(() => this.setState({ posDetectPending: false, posDetectError: true }));
    });
  };

  // get the google geocoding result and update the state
  setPosDetectResult = (result, latLng) => {
    this.setState({
      address: result.formatted_address,
      location: latLng.lat + ',' + latLng.lng,
      posDetectPending: false,
      posDetectError: false,
      changed: true,
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const { changed, address } = this.state;

    if (changed) {
      if (localStorage.getItem('locationAddress') === address) {
        this.setFilter();
      } else {
        this.getPosition(address);
      }
    }
  };

  render() {
    const {
      intl: { messages },
      isScriptLoaded,
    } = this.props;
    const { mouseOver, show, changed, posDetectError, posDetectPending, distance, address, location } = this.state;

    const distanceOptions = [20, 50, 100, 200];

    return !isScriptLoaded ? (
      <Loader />
    ) : (
      <div
        class={'summary-filter animate-all ' + (mouseOver ? 'hover ' : '') + (show ? 'active ' : '')}
        onMouseEnter={() => this.setState({ mouseOver: true })}
        onMouseLeave={() => this.setState({ mouseOver: false })}
      >
        <div class="summary-filter-title" onClick={this.handleClick}>
          <Clearfix>
            <div class="pull-left">{messages.location}</div>
            <div class="pull-right">
              {this.hasFilters() && !show ? (
                <i class="fal fa-check text-success" />
              ) : (
                <i class={'fal fa-chevron-' + (show ? 'up' : 'right')} />
              )}
            </div>
          </Clearfix>
        </div>

        <AnimateHeight duration={200} height={show ? 'auto' : 0}>
          <div class="summary-filter-body">
            {posDetectError ? (
              <div class="m-b-10">
                <Alert bsStyle="danger">{messages.geolocation_error}</Alert>
              </div>
            ) : null}

            <Form onSubmit={this.onSubmit}>
              <div class="input-group transparent">
                <span
                  class="input-group-addon cursor"
                  ref={this.distanceTargetRef}
                  onClick={() => this.setState({ showDistanceOptions: true })}
                >
                  {distance}
                  {messages.count_km_from} <i class="fa fa-angle-down" />
                </span>

                <input
                  disabled={posDetectPending}
                  value={address}
                  class="form-control"
                  onChange={e => this.setState({ address: e.target.value, changed: true })}
                  style={{ borderRight: 0 }}
                />

                {navigator.geolocation ? (
                  <span
                    ref={this.geoLocationTootipTargetRef}
                    class="input-group-addon cursor"
                    onMouseOver={() => this.setState({ showGeoLocationTooltip: true })}
                    onMouseOut={() => this.setState({ showGeoLocationTooltip: false })}
                  >
                    {posDetectPending ? (
                      <i class="fa fa-circle-notch fa-spin" />
                    ) : (
                      <i class="fa fa-fw fa-compass cursor" onClick={this.getCurrentPosition} />
                    )}
                  </span>
                ) : null}
              </div>

              <div class="m-t-10 padding-10">
                {changed || (address && !this.hasFilters()) ? (
                  <Button block bsSize="lg" class="text-uppercase bold" bsStyle="primary" type="submit">
                    {messages.save}
                  </Button>
                ) : (
                  <Button block bsSize="lg" class="text-uppercase bold text-black" onClick={this.hide}>
                    {messages.close}
                  </Button>
                )}
              </div>
            </Form>

            <Overlay
              rootClose
              show={this.state.showDistanceOptions && show}
              target={() => this.distanceTargetRef.current}
              placement="bottom"
              onHide={() => this.setState({ showDistanceOptions: false })}
            >
              <Popover id="select-distance">
                <div class="list-view">
                  <ul>
                    {distanceOptions.map(distance => {
                      return (
                        <li key={distance}>
                          <a
                            href="javascript:;"
                            onClick={() => this.setState({ distance, changed: true, showDistanceOptions: false })}
                          >
                            {distance}km
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Popover>
            </Overlay>

            <Overlay
              show={this.state.showGeoLocationTooltip}
              target={() => this.geoLocationTootipTargetRef.current}
              placement="top"
            >
              <Tooltip id="geo-location-tooltip">{messages.click_to_start_geolocation}</Tooltip>
            </Overlay>
          </div>
        </AnimateHeight>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(scriptLoader(GOOGLE_MAPS_URL)(SummaryFilterLocation))));
