import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Overlay, Popover } from 'react-bootstrap';

class Breadcrumb extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };

    this.responsiveRef = React.createRef();
  }

  show = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ show: true });
  };

  close = () => {
    this.setState({ show: false });
  };

  getCrumbName = crumb => {
    return crumb.name ? crumb.name : <i className="fa fa-circle-notch fa-spin fa-fw" style={{ fontWeight: 700 }} />;
  };

  doNothing = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const { layout, crumbs, className } = this.props;
    const { show } = this.state;

    return layout.breakpointIndex > 1 ? (
      <ul className={'breadcrumb ' + className}>
        {crumbs.map((crumb, i) => {
          // console.log((i+1) + '/' + crumbs.length + ': ' + crumb.name + ' - ' + (crumb.name ? true : false))
          const name = this.getCrumbName(crumb);

          return i + 1 === crumbs.length ? (
            <li key={i}>
              <a href="#current" className="active" onClick={this.doNothing}>
                {name}
              </a>
            </li>
          ) : (
            <li key={i}>
              <Link to={crumb.link}>{name}</Link>
            </li>
          );
        })}
      </ul>
    ) : (
      <>
        <ul className={'breadcrumb ' + className}>
          {crumbs.map((crumb, i) => {
            if (i === 0) {
              return (
                <li key="first">
                  <Link to={crumb.link}>{this.getCrumbName(crumb)}</Link>
                </li>
              );
            }

            if (i === 1 && crumbs.length > 2) {
              return (
                <li key="middle" ref={this.responsiveRef}>
                  <a href="#showCrumbs" className="p-l-15 p-r-15" onClick={this.show}>
                    <i class="fad fa-ellipsis-h" />
                  </a>
                </li>
              );
            }

            if (i + 1 === crumbs.length) {
              return (
                <li key="last">
                  <a href="#current" className="active" onClick={this.doNothing}>
                    {this.getCrumbName(crumb)}
                  </a>
                </li>
              );
            }

            return null;
          })}
        </ul>

        <Overlay show={show} target={this.responsiveRef.current} placement="bottom" rootClose onHide={this.close}>
          <Popover>
            <div className="list-view clearfix">
              <ul className="no-border">
                {crumbs.map((crumb, i) => {
                  const name = this.getCrumbName(crumb);

                  return i + 1 === crumbs.length ? (
                    <li key={i} className="active">
                      <a href="#current" onClick={this.doNothing}>
                        <i className="fal fa-angle-right m-r-10" /> {name}
                      </a>
                    </li>
                  ) : (
                    <li key={i}>
                      <Link to={crumb.link}>
                        <i className="fal fa-angle-right m-r-10" /> {name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Popover>
        </Overlay>
      </>
    );
  }
}
Breadcrumb.defaultProps = {
  crumbs: [],
  className: '',
};

const mapStateToProps = state => {
  return {
    layout: state.layout,
  };
};

export default connect(mapStateToProps)(Breadcrumb);
