import React from 'react';
import { connect } from 'react-redux';
import { Badge } from 'react-bootstrap';
import { concat as _concat, uniq as _uniq, compact as _compact } from 'lodash';

import { intlModule } from '@chedri/base';

class IntlFormSelector extends React.Component {
  changeLanguage = (e, language) => {
    e.stopPropagation();
    e.preventDefault();

    const { changeLanguage } = this.props;
    changeLanguage(language);
  };

  getLangs = () => {
    const { user, projectConfig, whiteLabel, myWhiteLabelProject } = this.props;

    let langsList = _concat(user.project_lang.project_languages, projectConfig.data.languages);

    if (whiteLabel.data) {
      langsList = _concat(langsList, whiteLabel.data.languages);
    }

    if (myWhiteLabelProject.data.count) {
      for (let i = 0; i < myWhiteLabelProject.data.list.length; i++) {
        langsList = _concat(langsList, myWhiteLabelProject.data.list[i].languages);
      }
    }

    return _compact(_uniq(langsList));
  };

  render() {
    const { appIntl } = this.props;

    const list = this.getLangs().map(language => {
      if (language === appIntl.displayIntlForm) {
        return (
          <Badge key={language} bsStyle="success" class="m-r-5 text-uppercase p-l-10 p-r-10 fs-10">
            {language}
          </Badge>
        );
      }

      return (
        <a
          key={language}
          href="changeLanguage"
          onClick={e => {
            this.changeLanguage(e, language);
          }}
          class="m-r-5"
        >
          <Badge class="text-uppercase p-l-10 p-r-10 fs-10">{language}</Badge>
        </a>
      );
    });

    return <span class="font-heading">{list}</span>;
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    user: state.login.user,
    projectConfig: state.data.projectConfig,
    whiteLabel: state.data.whiteLabel,
    myWhiteLabelProject: state.list.myWhiteLabelProject,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    changeLanguage: language => {
      dispatch(intlModule.actions.changeDisplayIntlForm(language));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntlFormSelector);
