import React from 'react';
import { connect } from 'react-redux';
// import { Grid, Row, Col, Alert } from 'react-bootstrap';
import { findIndex as _findIndex } from 'lodash';

import Loader from 'components/layout/Loader';
import IntlDateTime from 'components/layout/IntlDateTime';

class FormGooglePlacesAutocompleteList extends React.Component {
  getSuggestionList = () => {
    const { prevSuggestions, suggestions: propsSuggestions, prevMapSearches } = this.props;

    const suggestions = propsSuggestions || prevSuggestions || [];

    const suggestionsList = [...suggestions];

    if (prevMapSearches) {
      for (let i = prevMapSearches.length - 1; i >= 0; i--) {
        const suggestionAdd = {
          description: prevMapSearches[i].description,
          placeId: prevMapSearches[i].placeId,
          isRecent: true,
          dateFrom: prevMapSearches[i].dateFrom,
          dateTo: prevMapSearches[i].dateTo,
        };

        const recentIndex = _findIndex(suggestionsList, ['placeId', suggestionAdd.placeId]);

        if (recentIndex > -1) {
          suggestionsList.splice(recentIndex, 1);
        }
        suggestionsList.splice(0, 0, suggestionAdd);
      }

      for (let i = 0; i < suggestionsList.length; i++) {
        suggestionsList[i].index = i;
      }
    }

    return suggestionsList;
  };

  getLocationList = () => {
    const { getSuggestionItemProps } = this.props;

    const suggestionsList = this.getSuggestionList();

    return suggestionsList.map(suggestion => {
      const className = 'ws-normal';

      let description = '';
      if (suggestion.isRecent) {
        description = (
          <div>
            <i class="fal fa-clock" /> {suggestion.description}
            <br />
            <IntlDateTime date={suggestion.dateFrom} /> - <IntlDateTime date={suggestion.dateTo} />
          </div>
        );
      } else {
        description = suggestion.description;
      }

      return (
        <li key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { className })}>
          <a class="p-t-10 p-b-10" href="javascript:;">
            {description}
          </a>
        </li>
      );
    });
  };

  render() {
    const { positionTop, positionLeft, pending, width } = this.props;

    return (
      <div class="overlayer" style={{ top: positionTop + 'px', left: positionLeft + 'px', width: width + 'px' }}>
        <div class="popover relative block padding-30 sm-padding-10" style={{ maxWidth: '3000px' }}>
          {pending ? (
            <Loader />
          ) : (
            <div class="list-view clearfix">
              <ul class="no-border">{this.getLocationList()}</ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}
FormGooglePlacesAutocompleteList.defaultProps = {
  index: '0',
};

const mapStateToProps = state => {
  return {
    locale: state.intl.locale,
  };
};

export default connect(mapStateToProps)(FormGooglePlacesAutocompleteList);
