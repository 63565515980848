import React from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';

import { isExternal } from 'helpers/http';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

import RichEditorContent from 'components/RichEditorContent';

import { elementSource, elementTarget } from './elementDragUtils';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
class CmsElementCallToAction extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  onClick = () => {
    const buttonUrl = this.getParamValue('link', '');

    if (isExternal(buttonUrl)) {
      window.location.href = buttonUrl;
    } else {
      this.props.history.push(buttonUrl);
    }
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  render() {
    const { connectDropTarget, connectDragSource, connectDragPreview } = this.props;
    const { shown } = this.state;

    const buttonClass = shown ? 'button-shown' : 'button-not-shown-yet';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={
          'ms-call-to-action-content p-b-40 p-t-40 p-l-60 p-r-60 text-' +
          this.getParamValue('mainColor', 'master') +
          this.getCssClasses()
        }
        style={{
          ...this.getBackgroundStyle(),
        }}
        onClick={this.showSettings}
      >
        <div class={'call-to-action-container text-' + this.getParamValue('textAlign', 'center')}>
          <h2 class="no-margin p-b-25">{this.getParamValue('title', '')}</h2>
          <RichEditorContent content={'<p>' + this.getParamValue('content', '') + '</p>'} />
          <Button
            class={
              'call-to-action btn-animated from-left fa ' +
              this.getParamValue('icon', 'fa-arrow-right') +
              ' ' +
              buttonClass
            }
            bsStyle="primary"
            onClick={this.onClick}
          >
            <span>{this.getParamValue('buttonTitle', '')}</span>
          </Button>
        </div>

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}

export default withRouter(CmsElementCallToAction);
