import { UPDATE_MESSENGER_OPTIN_USER_REF } from 'actions/FacebookActions';

const initialState = {
  ref: '',
  userRef: '',
};

export default function facebookMessengerOptin(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MESSENGER_OPTIN_USER_REF: {
      return {
        ...state,
        ref: action.payload.ref,
        userRef: action.payload.userRef,
      };
    }
  }

  return state;
}
