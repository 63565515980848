import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from './Icon';

function IconLink({
  href,
  name,
  duotone,
  solid,
  light,
  iconStyles,
  iconClassName,
  linkStyles,
  linkClassName,
  tooltip,
  tooltipPlacement,
  label,
  layers,
  onClick,
  children,
}) {
  const icon = (
    <Icon
      name={name}
      duotone={duotone}
      solid={solid}
      light={light}
      iconStyles={iconStyles}
      iconClassName={iconClassName}
      tooltip={tooltip}
      tooltipPlacement={tooltipPlacement}
      label={label}
      layers={layers}
    />
  );
  if (onClick) {
    return (
      <a href={href} onClick={onClick} className={`${linkClassName}`} style={{ ...linkStyles, cursor: 'pointer' }}>
        {icon}
        {children}
      </a>
    );
  }
  return (
    <Link to={href} className={`${linkClassName}`} style={linkStyles}>
      {icon}
      {children}
    </Link>
  );
}
IconLink.defaultProps = {
  duotone: false,
  solid: false,
  light: false,
  iconStyles: {},
  iconClassName: '',
  linkStyles: {},
  linkClassName: '',
  tooltip: null,
  tooltipPlacement: undefined,
  href: undefined,
  label: null,
  layers: undefined,
  onClick: undefined,
};
IconLink.propTypes = {
  name: PropTypes.string.isRequired,
  duotone: PropTypes.bool,
  solid: PropTypes.bool,
  light: PropTypes.bool,
  iconStyles: PropTypes.object,
  iconClassName: PropTypes.string,
  linkStyles: PropTypes.object,
  linkClassName: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  href: PropTypes.string,
  label: PropTypes.string,
  layers: PropTypes.arrayOf(PropTypes.element),
  // onClick: PropTypes.func,
};

export default IconLink;
