import React from 'react';
import { injectIntl } from 'react-intl';
import PlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import scriptLoader from 'react-async-script-loader';
import { Overlay, Tooltip } from 'react-bootstrap';

import { GOOGLE_MAPS_URL } from 'js/constants';

import FormGooglePlacesAutocompleteList from './FormGooglePlacesAutocompleteList';

class FormGooglePlacesAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    const coordinates = localStorage.getItem('location') || '';
    const locationAddress = localStorage.getItem('locationAddress') || '';

    this.state = {
      coordinates,
      address: locationAddress,
      googlePlacesResult: null,
      addressInputValue: locationAddress,
      listOverlayShow: false,
      prevSuggestions: [],
      showBrowserGeolocationTooltip: false,
      browserGeolocationPending: false,
    };

    this.placesAutocompleteRef = React.createRef();
    this.inputWrapperRef = React.createRef();
    this.geoLocationTootipTargetRef = React.createRef();
  }

  componentDidMount = () => {
    const { onSelect, enableBrowserLocation, autoBrowserLocation } = this.props;
    const { address, coordinates, googlePlacesResult } = this.state;

    if (!coordinates && enableBrowserLocation && autoBrowserLocation) {
      this.setCoordsFromBrowserGeolocation();
    }

    if (coordinates) {
      onSelect(address, coordinates, googlePlacesResult);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { isScriptLoadSucceed, onSelect } = this.props;
    const { address, coordinates, googlePlacesResult, addressInputValue } = this.state;

    if (isScriptLoadSucceed && !prevProps.isScriptLoaded && addressInputValue) {
      // this.addressChange(addressInputValue);
    }

    if (address !== prevState.address || coordinates !== prevState.coordinates) {
      onSelect(address, coordinates, googlePlacesResult);
    }
  };

  onFocus = () => {
    this.placesAutocompleteRef.current.fetchPredictions();
    this.setState({ listOverlayShow: true }, () => {
      this.setState({ addressInputFocused: true });
    });
  };

  onBlur = suggestions => {
    const stateUpdate = { addressInputFocused: false };
    if (suggestions && suggestions.length) {
      stateUpdate.prevSuggestions = suggestions;
    }
    this.setState(stateUpdate);
  };

  setCoordsFromBrowserGeolocation = () => {
    const { address } = this.state;

    if (address || !navigator.geolocation) {
      return;
    }

    this.setState({ browserGeolocationPending: true }, () => {
      navigator.geolocation.getCurrentPosition(position => {
        this.setState({
          coordinates: position.coords.latitude + ',' + position.coords.longitude,
          browserGeolocationPending: false,
        });
      });
    });
  };

  onAddressInputChange = addressInputValue => {
    const { listOverlayShow } = this.state;

    const changeState = { addressInputValue };
    if (!listOverlayShow) {
      changeState.listOverlayShow = true;
    }

    this.setState(changeState);
  };

  onSelect = (locationAddress, placeId) => {
    this.setState({ listOverlayShow: false }, () => {
      geocodeByPlaceId(placeId)
        .then(results => {
          // console.log(results[0]);
          getLatLng(results[0]).then(latLng => this.setPosDetectResult(results[0], latLng));
        })
        .catch(() => {});
    });
  };

  setPosDetectResult = (googlePlacesResult, latLng) => {
    this.setState({
      address: googlePlacesResult.formatted_address,
      addressInputValue: googlePlacesResult.formatted_address,
      coordinates: latLng.lat + ',' + latLng.lng,
      googlePlacesResult,
    });
    localStorage.setItem('locationAddress', googlePlacesResult.formatted_address);
    localStorage.setItem('location', latLng.lat + ',' + latLng.lng);
  };

  getOverlayTarget = () => {
    return this.inputWrapperRef.current;
  };

  getOverlayWidth = () => {
    return this.inputWrapperRef.current ? this.inputWrapperRef.current.offsetWidth : 0;
  };

  render() {
    const {
      intl: { messages },
      isScriptLoaded,
      name,
      placeholder,
      enableBrowserLocation,
      autoBrowserLocation,
      prevMapSearches,
    } = this.props;
    const {
      addressInputValue,
      addressInputFocused,
      listOverlayShow,
      prevSuggestions,
      showBrowserGeolocationTooltip,
      browserGeolocationPending,
    } = this.state;

    const searchOptions = {
      // types: ['address'],
    };
    const showBrowserGeolocationOption = enableBrowserLocation && !autoBrowserLocation && navigator.geolocation;

    return !isScriptLoaded ? null : (
      <PlacesAutocomplete
        ref={this.placesAutocompleteRef}
        value={addressInputValue}
        onChange={this.onAddressInputChange}
        onSelect={this.onSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <React.Fragment>
              <div
                ref={this.inputWrapperRef}
                className={
                  (showBrowserGeolocationOption ? 'input-group ' : 'form-group form-group-default ') +
                  'transparent ' +
                  (addressInputFocused || listOverlayShow ? 'focused ' : '')
                }
              >
                <input
                  {...getInputProps({
                    className: 'form-control ' + (!addressInputValue ? 'text-master-light ' : ''),
                    onFocus: this.onFocus,
                    onBlur: () => this.onBlur(suggestions),
                    placeholder,
                    name,
                    style: showBrowserGeolocationOption ? { borderRight: 0 } : {},
                  })}
                />

                {showBrowserGeolocationOption ? (
                  <span
                    ref={this.geoLocationTootipTargetRef}
                    class="input-group-addon"
                    onMouseOver={() => this.setState({ showBrowserGeolocationTooltip: true })}
                    onMouseOut={() => this.setState({ showBrowserGeolocationTooltip: false })}
                  >
                    {browserGeolocationPending ? (
                      <i class="fa fa-circle-notch fa-spin" />
                    ) : (
                      <i class="fa fa-fw fa-compass cursor" onClick={this.setCoordsFromBrowserGeolocation} />
                    )}
                  </span>
                ) : null}
              </div>

              <Overlay
                show={listOverlayShow}
                rootClose
                onHide={() => {
                  if (addressInputFocused) {
                    return;
                  }
                  this.setState({ listOverlayShow: false });
                }}
                placement="bottom"
                target={this.getOverlayTarget}
              >
                <FormGooglePlacesAutocompleteList
                  width={this.getOverlayWidth()}
                  searchTerm={addressInputValue}
                  prevSuggestions={prevSuggestions}
                  suggestions={suggestions && suggestions.length ? suggestions : null}
                  pending={loading}
                  getSuggestionItemProps={getSuggestionItemProps}
                  prevMapSearches={prevMapSearches}
                />
              </Overlay>

              <Overlay
                show={showBrowserGeolocationTooltip}
                target={() => this.geoLocationTootipTargetRef.current}
                placement="top"
              >
                <Tooltip id={name + '-geo-location-tooltip'}>{messages.click_to_start_geolocation}</Tooltip>
              </Overlay>
            </React.Fragment>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}
FormGooglePlacesAutocomplete.defaultProps = {
  enableBrowserLocation: false,
  autoBrowserLocation: false,
  placeholder: 'Type to start search',
  name: 'placesAutocomplete',
  prevMapSearches: [],
  onSelect: () => {},
};

export default injectIntl(scriptLoader(GOOGLE_MAPS_URL)(FormGooglePlacesAutocomplete));
