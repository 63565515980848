import React from 'react';
import { DayPickerSingleDateController } from 'react-dates';
import { Overlay, Popover } from 'react-bootstrap';
import { omit as _omit } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import generateId from 'helpers/id';
import IntlDate from 'components/layout/IntlDate';

export default class SingleDatePickerInBody extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showPrompt: false,
      pickerId: generateId('date-picker-'),
    };
  }

  onDateChange = date => {
    const { onDateChange } = this.props;

    this.setState({ showPrompt: false });
    onDateChange(date);
  };

  onFocus = () => {
    const { onFocusChange } = this.props;

    this.setState({ showPrompt: true });
    onFocusChange({ focused: true });
  };

  onBlur = () => {
    const { onFocusChange } = this.props;

    this.setState({ showPrompt: false });
    onFocusChange({ focused: false });
  };

  render() {
    const { showPrompt, pickerId } = this.state;
    const { date } = this.props;
    const validInputProps = [
      'id',
      'placeholder',
      'ariaLabel',
      'screenReaderMessage',
      'focused',
      'isFocused',
      'disabled',
      'required',
      'showCaret',
      'onChange',
      'onKeyDownShiftTab',
      'onKeyDownTab',
      'onKeyDownArrowDown',
      'onKeyDownQuestionMark',
      'openDirection',
      'verticalSpacing',
      'small',
      'regular',
      'block',
      'showClearDate',
      'placeholder',
    ];

    const dayPickerProps = _omit(this.props, [...validInputProps, 'autoFocus', 'initialDate', 'showInput']);

    return (
      <div onClick={this.onFocus}>
        <IntlDate
          ref={c => {
            this.inputRef = c;
          }}
          date={moment.tz(date, 'UTC').toISOString()}
        />

        <Overlay show={showPrompt} target={this.inputRef} placement="bottom" rootClose onHide={this.onBlur}>
          <Popover id={pickerId} style={{ maxWidth: 'none', zIndex: 1200 }}>
            <DayPickerSingleDateController {...dayPickerProps} onDateChange={this.onDateChange} focused date={date} />
          </Popover>
        </Overlay>
      </div>
    );
  }
}
