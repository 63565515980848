import React from 'react';
import Modal from '../../../components/Modal';

const TimelineDialogModal = ({ closeImageOverlay, openedImage, openImageOverlay }) => (
  <Modal show={openImageOverlay} onHide={closeImageOverlay} bsSize="sm" className="timelineDialogModal">
    <Modal.Header closeButton></Modal.Header>
    <Modal.Body>
      {openedImage && <img src={openedImage.key} alt={openedImage.id} width={'100%'} height={'100%'} />}
    </Modal.Body>
  </Modal>
);

export default TimelineDialogModal;
