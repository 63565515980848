import React from 'react';
import CSSParser from 'css-js';
import { find as _find } from 'lodash';

import RichEditorContent from 'components/RichEditorContent';

let counter = 0;

export default class AbstractCmsElement extends React.Component {
  constructor(props) {
    super(props);

    this.allowStateChange = true;
    this.id = 'ms' + ++counter;

    this.state = {
      shown: false,
    };
  }

  getMainId = () => {
    const { index } = this.props;
    return this.id + '_' + index.replace(/\./g, '_');
  };

  getCustomId = () => {
    const id = this.getParamValue('elementId');
    return id ? { id } : null;
  };

  getParam = name => {
    const { params } = this.props;
    return _find(params, ['name', name]);
  };

  getParamValue = (name, defaultValue = null) => {
    const param = this.getParam(name);
    if (param && param.value !== null && param.value !== '' && param.value !== undefined) {
      return param.value;
    }

    return defaultValue;
  };

  getPrevParam = (prevProps, name) => {
    return _find(prevProps.params, ['name', name]);
  };

  getPrevParamValue = (prevProps, name, defaultValue = null) => {
    const param = this.getPrevParam(prevProps, name);
    if (param && param.value !== null && param.value !== '') {
      return param.value;
    }

    return defaultValue;
  };

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showSettings = e => {
    const { index, showElementSettings } = this.props;
    if (showElementSettings) {
      showElementSettings(e, index);
    }
  };

  getResponsiveClasses = () => {
    const { editMode } = this.props;

    let classes = ' ';

    if (this.getParamValue('hiddenPhone', 'no') === 'yes') {
      classes += 'hidden-xs ';
    }
    if (this.getParamValue('hiddenTablet', 'no') === 'yes') {
      classes += 'hidden-sm hidden-md ';
    }
    if (this.getParamValue('hiddenDesktop', 'no') === 'yes') {
      classes += 'hidden-lg ';
    }

    return editMode ? '' : classes;
  };

  getPaddingClasses = (defaultValue = 0) => {
    let classes = ' ';

    classes += 'p-t-' + this.getParamValue('paddingTop', defaultValue) + ' ';
    classes += 'p-b-' + this.getParamValue('paddingBottom', defaultValue) + ' ';

    classes += 'sm-p-t-' + this.getParamValue('smPaddingTop', this.getParamValue('paddingTop', defaultValue)) + ' ';
    classes +=
      'sm-p-b-' + this.getParamValue('smPaddingBottom', this.getParamValue('paddingBottom', defaultValue)) + ' ';

    return classes;
  };

  getBackgroundStyle = () => {
    let style = {};

    let backgroundUrl = this.getParamValue('backgroundUrl');
    const domElem = document.getElementById(this.getMainId());

    if (domElem && backgroundUrl) {
      const elemWidth = domElem.offsetWidth;

      if (elemWidth > 1200) {
        backgroundUrl = this.getParamValue('backgroundXlUrl', backgroundUrl);
      } else if (elemWidth > 1920) {
        backgroundUrl = this.getParamValue('backgroundRetinaUrl', backgroundUrl);
      }

      style = {
        ...style,
        backgroundImage: 'url(' + backgroundUrl + ')',
        backgroundSize: this.getParamValue('backgroundSize', 'cover'),
        backgroundPosition: this.getParamValue('backgroundPosition', 'left top'),
        backgroundRepeat: this.getParamValue('backgroundRepeat', 'repeat'),
        backgroundAttachment: this.getParamValue('backgroundAttachment', 'scroll'),
      };
    }

    const backgroundColor = this.getParamValue('backgroundColor', 'transparent');
    if (backgroundColor && backgroundColor !== 'transparent') {
      style.backgroundColor = backgroundColor;
    }

    return style;
  };

  getElementStyle = () => {
    const { elementStyle } = this.state || {};
    if (elementStyle) {
      return <style type="text/css" dangerouslySetInnerHTML={{ __html: elementStyle }} />;
    }
  };

  getParallaxData = () => {
    const { layout, effectsEnabled } = this.props;

    const parallaxType = this.getParamValue('parallaxType', 'none');

    if (effectsEnabled && parallaxType !== 'none') {
      const properties = [];
      const parallaxInitialOpacity = parseInt(this.getParamValue('parallaxInitialOpacity', '100'), 10) / 100;
      const parallaxDirection = this.getParamValue('parallaxDirection', 'center');
      const parallaxIntensity = parseInt(this.getParamValue('parallaxIntensity', '50'), 10);

      if (parallaxInitialOpacity < 100) {
        properties.push({
          startValue: parallaxInitialOpacity,
          endValue: 1,
          property: 'opacity',
        });
      }

      switch (parallaxType) {
        case 'slide': {
          // default -> translate right
          const property = {
            startValue: ((-1 * parallaxIntensity) / 100) * layout.width,
            endValue: 0,
            property: 'translateX',
            unit: 'px',
          };

          switch (parallaxDirection) {
            case 'center': {
              property.unit = '';
              property.property = 'scaleX';
              const startValue = 1 - parallaxIntensity / 100;
              property.startValue = startValue;
              property.endValue = 1;

              properties.push({ ...property, property: 'scaleY' });
              properties.push({ ...property, property: 'scaleZ' });
              break;
            }

            case 'left': {
              property.startValue = (parallaxIntensity / 100) * layout.width;
              break;
            }

            case 'top': {
              property.startValue = ((-1 * parallaxIntensity) / 100) * layout.height;
              property.property = 'translateY';
              break;
            }

            case 'bottom': {
              property.startValue = (parallaxIntensity / 100) * layout.height;
              property.property = 'translateY';
              break;
            }
          }

          properties.push(property);
          break;
        }

        case 'zoom': {
          // default -> zoom right
          const scaleProperty = {
            startValue: 1 - parallaxIntensity / 100,
            endValue: 1,
            property: 'scaleX',
            unit: '',
          };
          properties.push(scaleProperty);
          properties.push({ ...scaleProperty, property: 'scaleY' });
          properties.push({ ...scaleProperty, property: 'scaleZ' });

          switch (parallaxDirection) {
            case 'right':
              properties.push({
                startValue: ((-1 * parallaxIntensity) / 100) * layout.width,
                endValue: 0,
                property: 'translateX',
                unit: 'px',
              });
              break;

            case 'left':
              properties.push({
                startValue: (parallaxIntensity / 100) * layout.width,
                endValue: 0,
                property: 'translateX',
                unit: 'px',
              });
              break;

            case 'top':
              properties.push({
                startValue: ((-1 * parallaxIntensity) / 100) * layout.height,
                endValue: 0,
                property: 'translateY',
                unit: 'px',
              });
              break;

            case 'bottom':
              properties.push({
                startValue: (parallaxIntensity / 100) * layout.height,
                endValue: 0,
                property: 'translateY',
                unit: 'px',
              });
              break;
          }
          break;
        }
      }

      return [
        {
          start: 'self',
          duration: ((parallaxIntensity * 0.8 + 20) / 100) * layout.height,
          properties,
        },
      ];
    }

    return null;
  };

  getAnimationStyle = () => {
    const { index, effectsEnabled } = this.props;

    let style = '';
    const animationType = this.getParamValue('animationType', 'none');

    if (effectsEnabled && animationType !== 'none') {
      const stylePrefix = '#' + this.getMainId();
      const animationName = 'anim_' + index.replace(/\./g, '_');
      const animationInitialOpacity = parseInt(this.getParamValue('animationInitialOpacity', '0'), 10) / 100;

      switch (animationType) {
        case 'fade': {
          style += '@-webkit-keyframes ' + animationName + ' {\n';
          style += '0% { opacity: ' + animationInitialOpacity + '; } \n';
          style += '100% { opacity: 1; } \n';
          style += '}\n';

          style += '@keyframes ' + animationName + ' {\n';
          style += '0% { opacity: ' + animationInitialOpacity + '; } \n';
          style += '100% { opacity: 1; } \n';
          style += '}\n';
          break;
        }

        case 'slide': {
          const animationDirection = this.getParamValue('animationDirection', 'center');
          const animationIntensity = parseInt(this.getParamValue('animationIntensity', '50'), 10);

          let transformType = 'translate3d';
          let transformParams = '0,0,0';
          let transformEndParams = '0,0,0';

          switch (animationDirection) {
            case 'center':
              transformType = 'scale3d';
              transformParams = 1 - animationIntensity / 100;
              transformParams += ',' + transformParams + ',' + transformParams;
              transformEndParams = '1,1,1';
              break;

            case 'right':
              transformParams = '-' + animationIntensity * 2 + '%, 0, 0';
              break;

            case 'left':
              transformParams = animationIntensity * 2 + '%, 0, 0';
              break;

            case 'top':
              transformParams = '0, ' + animationIntensity * 2 + '%, 0';
              break;

            case 'bottom':
              transformParams = '0, -' + animationIntensity * 2 + '%, 0';
              break;
          }

          style += '@-webkit-keyframes ' + animationName + ' {\n';
          style +=
            '0% { opacity: ' +
            animationInitialOpacity +
            '; transform: ' +
            transformType +
            '(' +
            transformParams +
            '); } ' +
            '\n';
          style += '100% { opacity: 1; transform: ' + transformType + '(' + transformEndParams + '); } \n';
          style += '}\n';

          style += '@keyframes ' + animationName + ' {\n';
          style +=
            '0% { opacity: ' +
            animationInitialOpacity +
            '; transform: ' +
            transformType +
            '(' +
            transformParams +
            '); } ' +
            '\n';
          style += '100% { opacity: 1; transform: ' + transformType + '(' + transformEndParams + '); } \n';
          style += '}\n';
          break;
        }

        case 'bounce': {
          const animationDirection = this.getParamValue('animationDirection', 'center');
          const animationIntensity = parseInt(this.getParamValue('animationIntensity', '50'), 10);

          let transformType = 'translate';
          let transformMultiplier = 1;
          const calculateTransformValue = (type, multiplier, progress) => {
            if (type === 'scale') {
              return progress * (1 - (1 - progress) * (animationIntensity / 100));
            }

            return (1 - progress) * multiplier * 2 * animationIntensity + '%';
          };

          switch (animationDirection) {
            case 'center':
              transformType = 'scale';
              break;

            case 'right':
              transformType += 'X';
              transformMultiplier = -1;
              break;

            case 'left':
              transformType += 'X';
              break;

            case 'top':
              transformType += 'Y';
              break;

            case 'bottom':
              transformType += 'Y';
              transformMultiplier = -1;
              break;
          }

          let frames = '';
          frames +=
            '0% { opacity: ' +
            animationInitialOpacity +
            '; transform: ' +
            transformType +
            '(' +
            calculateTransformValue(transformType, transformMultiplier, 0.3) +
            '); }' +
            '\n';
          frames +=
            '50% { opacity: .9;  transform: ' +
            transformType +
            '(' +
            calculateTransformValue(transformType, transformMultiplier, 1.1) +
            '); }' +
            '\n';
          frames +=
            '80% { opacity: 1; transform: ' +
            transformType +
            '(' +
            calculateTransformValue(transformType, transformMultiplier, 0.89) +
            '); }' +
            '\n';
          frames +=
            '100% { opacity: 1; transform: ' +
            transformType +
            '(' +
            calculateTransformValue(transformType, transformMultiplier, 1) +
            '); }' +
            '\n';

          style += '@-webkit-keyframes ' + animationName + ' {\n';
          style += frames;
          style += '}\n';

          style += '@keyframes ' + animationName + ' {\n';
          style += frames;
          style += '}\n';
          break;
        }

        case 'zoom': {
          const animationDirection = this.getParamValue('animationDirection', 'center');
          const animationIntensity = parseInt(this.getParamValue('animationIntensity', '50'), 10);

          let scaleTransformParams = 1 - animationIntensity / 100;
          scaleTransformParams += ',' + scaleTransformParams + ',' + scaleTransformParams;

          let initialTranslate = '';
          let endTranslate = '';
          switch (animationDirection) {
            case 'right':
              initialTranslate = 'translate3d(-' + animationIntensity / 2 + '%, 0, 0)';
              endTranslate = 'translate3d(0,0,0)';
              break;

            case 'left':
              initialTranslate = 'translate3d(' + animationIntensity / 2 + '%, 0, 0)';
              endTranslate = 'translate3d(0,0,0)';
              break;

            case 'top':
              initialTranslate = 'translate3d(0, ' + animationIntensity / 2 + '%, 0)';
              endTranslate = 'translate3d(0,0,0)';
              break;

            case 'bottom':
              initialTranslate = 'translate3d(0, -' + animationIntensity / 2 + '%, 0)';
              endTranslate = 'translate3d(0,0,0)';
              break;
          }

          style += '@-webkit-keyframes ' + animationName + ' {\n';
          style +=
            '0% { opacity: ' +
            animationInitialOpacity +
            '; transform: scale3d(' +
            scaleTransformParams +
            ') ' +
            initialTranslate +
            '; } ' +
            '\n';
          style += '100% { opacity: 1; transform: scale3d(1, 1, 1) ' + endTranslate + '; } \n';
          style += '}\n';

          style += '@keyframes ' + animationName + ' {\n';
          style +=
            '0% { opacity: ' +
            animationInitialOpacity +
            '; transform: scale3d(' +
            scaleTransformParams +
            ') ' +
            initialTranslate +
            '; } ' +
            '\n';
          style += '100% { opacity: 1; transform: scale3d(1, 1, 1) ' + endTranslate + '; } \n';
          style += '}\n';
          break;
        }

        case 'flip': {
          const animationDirection = this.getParamValue('animationDirection', 'center');
          const animationIntensity = parseInt(this.getParamValue('animationIntensity', '50'), 10);

          let rotationAxis = 'X';
          let rotationAngle = 90 * (animationIntensity / 100);
          switch (animationDirection) {
            case 'right':
              rotationAxis = 'Y';
              rotationAngle *= -1;
              break;

            case 'left':
              rotationAxis = 'Y';
              break;

            case 'center':
            case 'top':
              rotationAxis = 'X';
              rotationAngle *= -1;
              break;

            case 'bottom':
              rotationAxis = 'X';
              break;
          }

          style += '@-webkit-keyframes ' + animationName + ' {\n';
          style +=
            '0% { opacity: ' +
            animationInitialOpacity +
            '; transform: perspective(2000px) rotate' +
            rotationAxis +
            '(' +
            rotationAngle +
            'deg); } ' +
            '\n';
          style += '100% { opacity: 1; transform: rotate' + rotationAxis + '(0); } \n';
          style += '}\n';

          style += '@keyframes ' + animationName + ' {\n';
          style +=
            '0% { opacity: ' +
            animationInitialOpacity +
            '; transform: perspective(2000px) rotate' +
            rotationAxis +
            '(' +
            rotationAngle +
            'deg); } ' +
            '\n';
          style += '100% { opacity: 1; transform: rotate' + rotationAxis + '(0); } \n';
          style += '}\n';
          break;
        }

        case 'fold': {
          const animationDirection = this.getParamValue('animationDirection', 'center');
          const animationIntensity = parseInt(this.getParamValue('animationIntensity', '50'), 10);

          let rotationAxis = 'X';
          let rotationAngle = 90 * (animationIntensity / 100);
          let transformOrigin = 'bottom';
          switch (animationDirection) {
            case 'right':
              rotationAxis = 'Y';
              rotationAngle *= -1;
              transformOrigin = 'left';
              break;

            case 'left':
              rotationAxis = 'Y';
              transformOrigin = 'right';
              break;

            case 'center':
            case 'top':
              rotationAxis = 'X';
              rotationAngle *= -1;
              transformOrigin = 'bottom';
              break;

            case 'bottom':
              rotationAxis = 'X';
              transformOrigin = 'top';
              break;
          }

          style += '\n' + stylePrefix + ' {';
          style +=
            '-webkit-transform-origin:' +
            transformOrigin +
            '; -ms-transform-origin:' +
            transformOrigin +
            '; transform-origin:' +
            transformOrigin +
            ';';
          style += '}\n';

          style += '@-webkit-keyframes ' + animationName + ' {\n';
          style +=
            '0% { opacity: ' +
            animationInitialOpacity +
            '; transform: perspective(2000px) rotate' +
            rotationAxis +
            '(' +
            rotationAngle +
            'deg); } ' +
            '\n';
          style += '100% { opacity: 1; transform: rotate' + rotationAxis + '(0); } \n';
          style += '}\n';

          style += '@keyframes ' + animationName + ' {\n';
          style +=
            '0% { opacity: ' +
            animationInitialOpacity +
            '; transform: perspective(2000px) rotate' +
            rotationAxis +
            '(' +
            rotationAngle +
            'deg); } ' +
            '\n';
          style += '100% { opacity: 1; transform: rotate' + rotationAxis + '(0); } \n';
          style += '}\n';
          break;
        }

        case 'roll': {
          const animationDirection = this.getParamValue('animationDirection', 'center');
          const animationIntensity = parseInt(this.getParamValue('animationIntensity', '50'), 10);

          let rotationAngle = 360 * (animationIntensity / 100);
          let transformOrigin = 'bottom';
          switch (animationDirection) {
            case 'right':
              rotationAngle *= -1;
              transformOrigin = 'left';
              break;

            case 'left':
              transformOrigin = 'right';
              break;

            case 'center':
            case 'top':
              rotationAngle *= -1;
              transformOrigin = 'bottom';
              break;

            case 'bottom':
              transformOrigin = 'top';
              break;
          }

          style += '\n' + stylePrefix + ' {';
          style +=
            '-webkit-transform-origin:' +
            transformOrigin +
            '; -ms-transform-origin:' +
            transformOrigin +
            '; transform-origin:' +
            transformOrigin +
            ';';
          style += '}\n';

          style += '@-webkit-keyframes ' + animationName + ' {\n';
          style += '0% { opacity: ' + animationInitialOpacity + '; transform: rotateZ(' + rotationAngle + 'deg); } \n';
          style += '100% { opacity: 1; transform: rotateZ(0); } \n';
          style += '}\n';

          style += '@keyframes ' + animationName + ' {\n';
          style += '0% { opacity: ' + animationInitialOpacity + '; transform: rotateZ(' + rotationAngle + 'deg); } \n';
          style += '100% { opacity: 1; transform: rotateZ(0); } \n';
          style += '}\n';
          break;
        }
      }

      style += '\n' + stylePrefix + '.elem-shown { ';
      style += 'animation-name: ' + animationName + '; ';
      style += 'animation-fill-mode: both; ';
      style += 'animation-duration: ' + this.getParamValue('animationDuration', '1000') + 'ms; ';
      style += '-webkitanimation-name: ' + animationName + '; ';
      style += '-webkitanimation-duration: ' + this.getParamValue('animationDuration', '1000') + 'ms; ';
      style += 'animation-delay: ' + this.getParamValue('animationDelay', '0') + 'ms; ';
      style += 'animation-timing-function: ' + this.getParamValue('animationSpeedCurve', 'ease-in-out') + '; ';
      style += ' }';
    }

    return style;
  };

  processStyle = () => {
    const elementStyle = this.getParamValue('style');
    const animationStyle = this.getAnimationStyle();

    if (elementStyle) {
      const parser = new CSSParser({ ver: '3' });

      let style = '';
      const mediaSplit = elementStyle.split('@media');
      for (let mediaStyleDef = 0; mediaStyleDef < mediaSplit.length; mediaStyleDef++) {
        let ast;
        // ast = parser.parse((mediaStyleDef === 0 ? '' : '@media') + mediaSplit[mediaStyleDef])
        try {
          ast = parser.parse((mediaStyleDef === 0 ? '' : '@media') + mediaSplit[mediaStyleDef]);
        } catch (err) {
          return null;
        }

        // process normal rulesets
        if (ast.rulesets) {
          style += this.processStyleRulesets(ast.rulesets);
        }

        // process media queries (if there are any)
        if (ast.medias) {
          style += '@media ';
          style += ast.medias.mediaqueries[0].prefix + ' ';
          style += ast.medias.mediaqueries[0].media_type + ' ';

          for (let i = 0; i < ast.medias.mediaqueries[0].expression.length; i++) {
            style +=
              'and (' +
              ast.medias.mediaqueries[0].expression[i].media_feature +
              ': ' +
              ast.medias.mediaqueries[0].expression[i].value +
              ')';
          }

          style += ' { ' + this.processStyleRulesets(ast.medias.rulesets) + ' } ';
        }
      }

      style += '\n' + animationStyle;

      this.setState({ elementStyle: style });
    } else if (animationStyle) {
      this.setState({ elementStyle: animationStyle });
    }
  };

  processStyleRulesets = rulesets => {
    let style = '';
    for (let i = 0; i < rulesets.length; i++) {
      let { selector } = rulesets[i];
      if (selector.indexOf('self') === 0) {
        selector = selector.replace('self', '');
      }
      if (selector !== ':after' && selector !== ':before') {
        selector = ' ' + selector;
      }

      style += '#' + this.getMainId() + selector + ' { ';

      for (let j = 0; j < rulesets[i].declaration.length; j++) {
        style += rulesets[i].declaration[j].key + ': ' + rulesets[i].declaration[j].value + '; ';
      }

      style += ' } ';
    }

    return style;
  };

  getAppendHtml = () => {
    const content = this.getParamValue('appendHtml', '');

    if (content) {
      return <RichEditorContent content={content} />;
    }
  };

  getCssClasses = () => {
    return ' ' + this.getParamValue('cssClasses', '') + ' ';
  };

  __componentDidMount = () => {
    this.processStyle();
  };

  __componentDidUpdate = prevProps => {
    const currentStyle = this.getParamValue('style');
    const prevStyle = this.getPrevParamValue(prevProps, 'style');

    const animationType = this.getParamValue('animationType', 'none');
    const prevAnimationType = this.getPrevParamValue(prevProps, 'animationType', 'none');

    if (animationType !== prevAnimationType) {
      this.setState({ shown: false }, () => {
        this.processStyle();

        this.animationResetTimeout = setTimeout(() => this.setState({ shown: true }), 200);
      });
    } else if ((currentStyle || null) !== (prevStyle || null)) {
      if (currentStyle) {
        this.processStyle();
      } else {
        this.setState({ elementStyle: null });
      }
    }
  };

  __shouldComponentUpdate = (nextProps, nextState) => {
    return true;
  };

  __componentWillUnmount = () => {
    clearTimeout(this.animationResetTimeout);
  };

  componentDidMount = () => {
    this.__componentDidMount();
  };

  componentDidUpdate = (prevProps, prevState) => {
    this.__componentDidUpdate(prevProps, prevState);
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return this.__shouldComponentUpdate(nextProps, nextState);
  };

  componentWillUnmount = () => {
    this.__componentWillUnmount();
  };
}
