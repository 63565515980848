import React from 'react';
import PropTypes from 'prop-types';

import CmsElementContact from 'components/cms/elements/CmsElementContact';

import IconLink from 'components/IconLink';
import Panel from 'components/panel/Panel';
import { useSelector } from 'react-redux';
import { intlModule } from '@chedri/base';

function ChatSimpleLead({ onClose }) {
  const appIntl = useSelector(intlModule.selectors.getIntl);
  const messages = useSelector(intlModule.selectors.getMessages);
  return (
    <Panel className="chatter-teaser">
      <Panel.Header
        rightAction={
          <IconLink
            name="fa-times-circle"
            solid
            onClick={onClose}
            linkClassName="link"
            iconClassName="fs-18 v-align-middle"
          />
        }
      >
        {messages.get_in_contact_with_us}
      </Panel.Header>
      <Panel.Body>
        <CmsElementContact
          intl={{ messages }}
          appIntl={appIntl}
          params={[
            {
              name: 'content',
              value: messages.no_agent_available,
            },
            { name: 'textAlign', value: 'left' },
          ]}
          email={localStorage.getItem('anonymousEmail') || ''}
          name={localStorage.getItem('anonymousName') || ''}
        />
      </Panel.Body>
    </Panel>
  );
}
ChatSimpleLead.defaultProps = {
  onClose: () => undefined,
};
ChatSimpleLead.propTypes = {
  onClose: PropTypes.func,
};

export default ChatSimpleLead;
