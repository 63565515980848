import { forEach as _forEach } from 'lodash';
import validator from 'formValidation/Validator';

import { Entity as BaseEntity } from '@chedri/base';

const getterName = (name, suffix = '') => 'get' + name.charAt(0).toUpperCase() + name.substring(1) + suffix;

export default class Entity extends BaseEntity {
  validators = validator;

  /**
   * Updated version of the @chedri/base@1.4.3
   * /src/forms/Entity.js
   * fixing the infinite loop while parsing Metadata.
   * Now we're checking if the current fieldName is already present in the fieldTree.
   *
   * @param {*} data
   * @param {string} metaIdentifier
   * @param {[array]} fieldTree
   * @param {[boolean]} useThisWhenTreeEmpty
   * @returns {Object}
   */
  loopMeta = (data, metaIdentifier, fieldTree = [], useThisWhenTreeEmpty = true) => {
    const result = {};
    if (!this.meta[metaIdentifier]) {
      return data;
    }
    const meta = this.meta[metaIdentifier].data;
    const metaKeys = Object.keys(meta);
    for (let i = 0; i < metaKeys.length; i++) {
      const fieldName = metaKeys[i];
      if (Object.prototype.hasOwnProperty.call(meta, fieldName)) {
        const fieldMeta = meta[fieldName];
        const fieldData = data && (data[fieldName] || data[fieldMeta.name]);

        if (fieldMeta.complexType && fieldMeta.array) {
          // this field is a complex type and is an array
          const values = [];
          if (fieldData) {
            _forEach(data[fieldName] || data[fieldMeta.name], (value, key) => {
              const newFieldTree = fieldTree.slice();
              newFieldTree.push(fieldName);
              values[key] = this.loopMeta(value ?? {}, fieldMeta.type, newFieldTree, useThisWhenTreeEmpty);
            });
          }
          result[fieldName] = values;
        } else if (fieldMeta.complexType) {
          /*
            Here an infinite loop could occur, when a current type has self type down in the field tree.
            In example: Client->parent (Client type again).
            Loop can be longer:
              target(Client)->parent->effectiveSuccessManager->parent->successManager
          */

          // count current fieldName occurence in the existing fieldTree
          const repeatCount = fieldTree.reduce((acc, c) => (c === fieldName ? acc + 1 : acc), 0);
          const maxRepeatitionsWithinTree = 2;

          if (
            fieldName === fieldTree[0] ||
            fieldName === fieldTree[fieldTree.length - 1] ||
            (repeatCount > 0 && fieldMeta.type === metaIdentifier) ||
            repeatCount > maxRepeatitionsWithinTree
          ) {
            // console.log(
            //   `[Entity] repeats x${repeatCount} "${metaIdentifier}.${fieldName}"\n\tTree: [${fieldTree.join(', ')}]`
            // );

            result[fieldName] = null;
          } else {
            // no repeatitions, all fine
            // this field is complex type, but not an array
            const newFieldTree = fieldTree.slice();
            newFieldTree.push(fieldName);
            result[fieldName] = this.loopMeta(fieldData || {}, fieldMeta.type, newFieldTree, useThisWhenTreeEmpty);
          }
        } else if (fieldMeta.array) {
          // this is an array of simple type data
          const values = [];
          if (fieldData) {
            _forEach(fieldData, (value, key) => {
              values[key] = value;
            });
          }
          result[fieldName] = values;
        } else if (fieldData) {
          result[fieldName] = fieldData;
        } else if (fieldData === false) {
          // save the false value not as Null, but as a Boolean false
          result[fieldName] = false;
        } else if (fieldData === 0) {
          result[fieldName] = 0;
        } else {
          result[fieldName] = null;
        }

        // assign get methods
        const source = fieldTree.length === 0 && useThisWhenTreeEmpty ? this : result;
        source[getterName(fieldName)] = () => result[fieldName];
        source[getterName(fieldName, 'MetaInformation')] = () => fieldMeta;
        source[getterName(fieldName, 'ValidationResult')] = () => ({ valid: true });
      }
    }

    return result;
  };
}
