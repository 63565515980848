import {
  SET_SUMMARY_FILTERS,
  SET_SUMMARY_FILTER,
  REMOVE_SUMMARY_FILTER,
  RESET_SUMMARY_FILTER,
} from 'actions/SummaryActions';

const initialState = {};

export default function summary(state = initialState, action) {
  switch (action.type) {
    case SET_SUMMARY_FILTERS: {
      let newState = { ...action.payload.filters };
      return newState;
    }

    case SET_SUMMARY_FILTER: {
      let newState = { ...state };
      const { name, values } = action.payload;
      if (!values.length) {
        delete newState[name];
      } else {
        newState[name] = values;
      }

      return newState;
    }

    case REMOVE_SUMMARY_FILTER: {
      let newState = { ...state };
      const { name, value } = action.payload;
      let values = newState[name].slice();
      values.splice(values.indexOf(value), 1);

      if (!values.length) {
        delete newState[name];
      } else {
        newState[name] = values;
      }

      return newState;
    }

    case RESET_SUMMARY_FILTER: {
      return {};
    }
  }

  return state;
}
