import {
  SET_RESERVATION_FILTERS,
  SET_RESERVATION_FILTER,
  REMOVE_RESERVATION_FILTER,
  RESET_RESERVATION_FILTER,
} from 'actions/ReservationActions';

const initialState = {};

export default function reservation(state = initialState, action) {
  switch (action.type) {
    case SET_RESERVATION_FILTERS: {
      let newState = { ...action.payload.filters };
      return newState;
    }

    case SET_RESERVATION_FILTER: {
      let newState = { ...state };
      const { name, values } = action.payload;
      if (!values.length) {
        delete newState[name];
      } else {
        newState[name] = values;
      }

      return newState;
    }

    case REMOVE_RESERVATION_FILTER: {
      let newState = { ...state };
      const { name, value } = action.payload;
      let values = newState[name].slice();
      values.splice(values.indexOf(value), 1);

      if (!values.length) {
        delete newState[name];
      } else {
        newState[name] = values;
      }

      return newState;
    }

    case RESET_RESERVATION_FILTER: {
      return {};
    }
  }

  return state;
}
