import React from 'react';

import MainMenuLink from 'components/layout/mainMenus/common/MainMenuLink';

export default class LinksList extends React.Component {
  render() {
    const {
      flipId,
      layout,
      elements,
      currentIndex,
      onMouseEnter,
      onMouseLeave,
      animatingOut,
      append,
      prepend,
    } = this.props;
    return (
      <ul onMouseLeave={onMouseLeave} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {prepend}
        {elements.map((element, i) => (
          <MainMenuLink
            key={element.id}
            icon={element.icon}
            {...element}
            layout={layout}
            index={i}
            flipId={flipId}
            currentIndex={currentIndex}
            onMouseEnter={onMouseEnter}
            onDropdownClose={onMouseLeave}
            animatingOut={animatingOut}
          />
        ))}
        {append}
      </ul>
    );
  }
}
LinksList.defaultProps = {
  append: [],
};
