import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Clearfix } from 'react-bootstrap';

import SimpleSubmitButton from 'components/layout/SimpleSubmitButton';

import BlockerInfos from 'components/forms/activeForms/BlockerInfos';
import ViewersList from 'components/forms/activeForms/ViewersList';

class ActiveFormsToolbar extends React.Component {
  render() {
    const {
      intl: { messages },
      user,
      fabActions,
      formStatus,
      entityChanged,
      activeForms,
      chatUsers,
      formType,
      formId,
      emergency,
    } = this.props;

    const submitDisabled = formStatus.pending || !activeForms.allowEdit;

    return (
      <Clearfix>
        {!!fabActions && <div className="pull-right m-l-10">{fabActions}</div>}

        {formType !== "editor" ?
        <div className="pull-right">
          <SimpleSubmitButton
            disabled={submitDisabled}
            pending={formStatus.pending}
            label={messages.save}
            entityChanged={entityChanged}
          />
        </div> : <></>}

        {!!activeForms.blockedBy && (
          <div className="pull-right m-r-10">
            <BlockerInfos
              activeForms={activeForms}
              user={user}
              chatUsers={chatUsers}
              formType={formType}
              formId={formId}
              emergency={emergency}
            />
          </div>
        )}
        {!!activeForms.blockedBy && activeForms.viewers.length && (
          <div className="pull-right m-r-10">
            <ViewersList
              activeForms={activeForms}
              user={user}
              chatUsers={chatUsers}
              formType={formType}
              formId={formId}
            />
          </div>
        )}
      </Clearfix>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.user,
    activeForms: state.activeForms,
    chatUsers: state.communication.contact.list,
  };
};

export default injectIntl(connect(mapStateToProps)(ActiveFormsToolbar));
