import { find as _find } from 'lodash';

const initialState = {
  isLoading: false,
  errors: 0,
  events: {
    events: [],
  },
};

export default function companyDataTimeline(state = initialState, action) {
  switch (action.type) {
    case 'RESET_COMPANY_DATA_TIMELINE': {
      return {
        ...state,
        isLoading: false,
        errors: 0,
        events: { events: [] },
      };
    }

    case 'FETCH_COMPANY_DATA_TIMELINE_PENDING': {
      return {
        ...state,
        isLoading: true,
        errors: 0,
      };
    }

    case 'FETCH_COMPANY_DATA_TIMELINE_FULFILLED': {
      const events = { ...state.events };
      events.events = state.events.events.slice();

      for (let i = 0; i < action.payload.data.events.length; i++) {
        if (_find(events.events, ['id', action.payload.data.events[i].id]) === undefined) {
          // load only when the id is not on the list
          events.events.push(action.payload.data.events[i]);
        }
      }
      events.count = action.payload.data.count;
      events.limit = action.payload.data.limit;
      events.offset = action.payload.data.offset;

      return {
        ...state,
        isLoading: false,
        errors: 0,
        events,
      };
    }

    case 'FETCH_COMPANY_DATA_TIMELINE_REJECTED': {
      return {
        ...state,
        isLoading: false,
        errors: action.payload.response ? action.payload.response.data : 400,
        events: { events: [] },
      };
    }
  }

  return state;
}
