import React from 'react';
import { components } from 'react-select';

class OrderOption extends React.Component {
  render() {
    const {
      data: { target },
    } = this.props;
    const profile = target.profile || {};

    return (
      <components.Option {...this.props}>
        <strong>{profile.company}</strong> {!!profile.company && <br />}
        {profile.first_name} {profile.last_name} {!!target.username && '(' + target.username + ')'}
      </components.Option>
    );
  }
}

class OrderValue extends React.Component {
  render() {
    const {
      data: { target },
    } = this.props;
    const profile = target.profile || {};

    return (
      <div className="Select-value">
        <strong>{profile.company}</strong> {profile.first_name} {profile.last_name}{' '}
        {!!target.username && '(' + target.username + ')'}
      </div>
    );
  }
}

const orderOptionFormatter = options => {
  return options.list.map(option => {
    return orderSingleOptionFormatter(option);
  });
};

const orderSingleOptionFormatter = (option = null) => {
  if (option.id) {
    let label = '';
    const profile = option.target || {};
    if (profile.company) {
      label += profile.company + ' ';
    }
    if (profile.first_name) {
      label += profile.first_name + ' ';
    }
    if (profile.last_name) {
      label += profile.last_name + ' ';
    }
    if (profile.street) {
      label += profile.street + ' ';
    }
    if (profile.city) {
      label += profile.city + ' ';
    }

    return { ...option, value: option.id, label };
  }

  return null;
};
const orderOptionFromEntity = order => {
  return !order
    ? null
    : orderSingleOptionFormatter({
        id: order.getId(),
        target: {
          username: order.getTarget().getUsername(),
          profile: {
            company: order
              .getTarget()
              .getProfile()
              .getCompany(),
            first_name: order
              .getTarget()
              .getProfile()
              .getFirstName(),
            last_name: order
              .getTarget()
              .getProfile()
              .getLastName(),
            street: order
              .getTarget()
              .getProfile()
              .getStreet(),
            city: order
              .getTarget()
              .getProfile()
              .getCity(),
          },
        },
      });
};

export { OrderValue, OrderOption, orderOptionFormatter, orderSingleOptionFormatter, orderOptionFromEntity };
