import React from 'react';
import { connect } from 'react-redux';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import scriptLoader from 'react-async-script-loader';
import { DragSource, DropTarget } from 'react-dnd';

import { getLocationsPath } from 'helpers/intl';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

import Loader from 'components/layout/Loader';
import LocationsMap from 'components/layout/maps/LocationsMap';

import List from 'components/List';

import { GOOGLE_MAPS_URL } from 'js/constants';
import { elementSource, elementTarget } from './elementDragUtils';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
class CmsElementLocations extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  render() {
    const { connectDropTarget, connectDragSource, connectDragPreview, isScriptLoaded } = this.props;
    const { shown } = this.state;

    if (!isScriptLoaded) {
      return <Loader />;
    }

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-locations ' + shownClass + this.getCssClasses()}
        onClick={this.showSettings}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <InnerLocationsCmsComponentWithProps height={this.getParamValue('mapHeight', 300)} />

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}

class InnerLocationsCmsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultsInit: false,
      zoom: 13,
      center: {
        lat: 49.2401572,
        lng: 6.9969327,
      },
    };
  }

  componentDidUpdate = prevProps => {
    const { locations } = this.props;

    if (!locations.pending && !locations.hasError && prevProps.locations.pending) {
      this.calculateMapCenter(locations.data.list);
    }
  };

  calculateZoomLevel = (west, east) => {
    // https://stackoverflow.com/questions/6048975/google-maps-v3-how-to-calculate-the-zoom-level-for-a-given-bounds
    const GLOBE_WIDTH = 256; // a constant in Google's map projection
    let angle = east - west;
    if (angle < 0) {
      angle += 360;
    }
    let zoom = Math.round(Math.log((window.innerWidth * 360) / angle / GLOBE_WIDTH) / Math.LN2) - 1;
    zoom = Math.min(zoom, 17);

    return zoom;
  };

  calculateMapCenter = list => {
    if (!list || !list.length) {
      return;
    }

    let maxLat = list[0].coordinates.lat;
    let minLat = list[0].coordinates.lat;
    let maxLng = list[0].coordinates.lng;
    let minLng = list[0].coordinates.lng;

    // Calculate bounds
    list.forEach(element => {
      if (element.coordinates.lat > maxLat) maxLat = element.coordinates.lat;
      if (element.coordinates.lat < minLat) minLat = element.coordinates.lat;

      if (element.coordinates.lng > maxLng) maxLng = element.coordinates.lng;
      if (element.coordinates.lng < minLng) minLng = element.coordinates.lng;
    });

    // Calculate center between bounds
    const centerLat = (maxLat + minLat) / 2.0;
    const centerLng = (maxLng + minLng) / 2.0;

    const zoom = this.calculateZoomLevel(minLng, maxLng);

    this.setState({ center: { lat: centerLat, lng: centerLng }, zoom, defaultsInit: true });
  };

  getLink = location => {
    return getLocationsPath() + '/' + location.id + '-' + location.name;
  };

  render() {
    const { height, locations } = this.props;
    const { defaultsInit, zoom, center } = this.state;

    const urlData = { distance: 100 };

    return (
      <>
        <List
          noRender
          listName="companyLocations"
          url="/api/whitelabellocations.json"
          urlData={urlData}
          list={locations}
        />

        <div style={{ height: height + 'px' }} class="gmap m-t-10 p-b-30 sm-p-b-10">
          {!locations.pending && defaultsInit && (
            <LocationsMap locations={locations} zoom={zoom} center={center} getLink={this.getLink} />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    locations: state.list.companyLocations,
  };
};

const InnerLocationsCmsComponentWithProps = connect(mapStateToProps)(InnerLocationsCmsComponent);

export default scriptLoader(GOOGLE_MAPS_URL)(CmsElementLocations);
