import axios from 'axios';

export const CHATTER_OPEN = 'CHATTER_OPEN';
export const CHATTER_CLOSE = 'CHATTER_CLOSE';
export const OPEN_CONVERSTATION = 'OPEN_CONVERSTATION';
export const CLOSE_CONVERSTATION = 'CLOSE_CONVERSTATION';
export const DISCARD_CONVERSTATION = 'DISCARD_CONVERSTATION';
export const CHATTER_CHANGE_MESSAGE_STATUS = 'CHATTER_CHANGE_MESSAGE_STATUS';
export const CONVERSATION_ENDED = 'CONVERSATION_ENDED';
export const CLOSE_CHATTER_REVIEW = 'CLOSE_CHATTER_REVIEW';

export const openChatter = () => {
  return {
    type: CHATTER_OPEN,
    payload: null,
  };
};

export const closeChatter = () => {
  return {
    type: CHATTER_CLOSE,
    payload: null,
  };
};

export const openConverstation = user => {
  return {
    type: OPEN_CONVERSTATION,
    payload: user,
  };
};

export const closeConversation = () => {
  return {
    type: CLOSE_CONVERSTATION,
    payload: null,
  };
};

export const discardConversation = username => {
  return {
    type: DISCARD_CONVERSTATION,
    payload: username,
  };
};

export const changeChatterMessageStatus = (messageId, newStatus) => {
  return {
    type: CHATTER_CHANGE_MESSAGE_STATUS,
    payload: axios.put(`/api/chattermessages/${messageId}.json`, { status: newStatus }),
  };
};

export const conversationEnded = args => {
  return {
    type: CONVERSATION_ENDED,
    payload: args,
  };
};

export const closeReview = () => {
  return {
    type: CLOSE_CHATTER_REVIEW,
    payload: null,
  };
};
