import React, { useState, useEffect } from 'react';
import _, { findIndex as _findIndex, forEach as _forEach, cloneDeep as _cloneDeep } from 'lodash';
import { Button, Col, Row } from 'react-bootstrap';
import may from 'js/security';
import {
  createFolder,
  getMediaLibrary,
  editFolder,
  editFile,
  deleteFolder,
  eraseFolder,
  undeleteFolder,
  deleteFile,
  undeleteFile,
  eraseFile,
  getMediaLibraryFolder,
  getMediaLibraryFolderSorted,
  setVariables,
} from 'actions/MediaLibraryActions';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { S3_LINKS, S3_LINKS_DIV } from 'js/constants';
import Modals from './Modals';
import FolderView from './FolderView';
import UploadProcess from './UploadProcess';
import { changeUploadContext, resetUploadContext } from 'actions/UploadActions';

const Functions = ({
  createFolder,
  getMediaLibrary,
  getMediaLibraryFolder,
  getMediaLibraryFolderSorted,
  mediaFiles,
  mediaFolders,
  user,
  editFolder,
  saving,
  pending,
  editFile,
  deleteFolder,
  undeleteFolder,
  eraseFolder,
  deleteFile,
  undeleteFile,
  eraseFile,
  client,
  updater,
  alreadyExist,
  setVariables,
  sorting,
  openedFolderSet,
  messages,
  changeUploadContext
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progressInfo, setProgressInfo] = useState([]);
  const [progressInfoRun, setProgressInfoRun] = useState([]);

  const [showEdit, setShowEdit] = useState(false);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [showFolderEdit, setShowFolderEdit] = useState(false);

  const [newFolderName, setNewFolderName] = useState({ name: '', idFolder: '', index: '' });
  const [openedFolder, setOpenedFolder] = useState('main');
  const [activdir, setActivdir] = useState({});
  const [userData, setUserData] = useState({ username: '', role: '', access: 1, dropColor: '#000000' });
  const [filesDrag, setFilesDrag] = useState([]);
  const [foldersDrag, setFoldersDrag] = useState([]);
  const [selectedUrls, setSelectedUrls] = useState([]);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [currentFolders, setCurrentFolders] = useState([]);
  const [copiedFiles, setCopiedFiles] = useState([]);
  const [copiedFolders, setCopiedFolders] = useState([]);
  const [storeF, setStoreF] = useState(['main']);
  const [storeParent, setStoreParent] = useState();
  const [imgRightsQuery, setImgRightsQuery] = useState(false);

  const s3urlThumb = S3_LINKS.MEDIA_IMGS_THUMB;
  const s3urlOriginal = S3_LINKS.MEDIA_IMGS_ORIGINAL;
  const pic = S3_LINKS_DIV.MEDIA_IMAGE_THUMB_DEFAULT;

  // set
  const [mediaData, setMediaData] = useState({ index: 0, title: '', description: '', copyright: '', parent: 'main' });

  // get
  const [getMedia, setGetMedia] = useState({
    title: 'Rucksack',
    description: 'Survival',
    copyright: 'c by cc',
    tag: 'rucksack',
    sour: pic,
    back: 0,
    selected: '',
  });

  const includePdfFiles = true;

  useEffect(() => {
    userControls();
    getMediaLibrary(client, sorting, user.granted_holding_access_whitelist, user.username, includePdfFiles);
  }, [client]);

  useEffect(() => {
    userControls();
    if (sorting) {
      getMediaLibraryFolderSorted(client, openedFolder, sorting, user.granted_holding_access_whitelist, user.username, includePdfFiles);
    }
  }, [client, sorting]);

  function userControls() {
    if (may('ROLE_ADMIN', user.roles)) {
      userData.role = 'admin';
    }
    userData.username = user.username;
    setUserData({ ...userData });
  }

  useEffect(() => {
    if (updater !== true) {
      choiceFolder(openedFolder);
    }
    changeUploadContext({
      type: 'mediaFile',
      data: { parent: openedFolder, contextId: 'medialibrary' },
      uploadLink: '/api/medialibraries/files.json'
    });
  }, [mediaFiles, mediaFolders, updater]);

  useEffect(() => {
    if (alreadyExist === true) {
      errorMessages();
    }
  }, [alreadyExist]);

  const selectFile = event => {
    setImgRightsQuery(true);
    setSelectedFiles(event.target.files);
    setProgressInfo([]);
    setProgressInfoRun([]);
    const _selectedUrls = [];
    _forEach(event.target.files, files => {
      _selectedUrls.push(URL.createObjectURL(files));
    });
    if (_selectedUrls.length === event.target.files.length) {
      setSelectedUrls(_selectedUrls);
    }
  };

  const customErrorUi = ({ onClose }) => {
    const { info, info_text: infoText } = messages.medialibrary;
    const CustomComponent = () => (
      <div className="react-confirm-alert-body" displayName="errors">
        <h1>{info}</h1>
        <p>{infoText}</p>
        <div className="react-confirm-alert-button-group" style={{ width: '100%', margin: '0 auto' }}>
          <Button style={{ margin: '0 auto', marginTop: '20px', width: '100px' }} bsStyle="primary" onClick={onClose}>
            OK
          </Button>
        </div>
      </div>
    );
    CustomComponent.displayName = 'errorMessages';

    return <CustomComponent />;
  };

  const childrenElement = () => <div />;

  const errorMessages = () => {
    const options = {
      customUI: customErrorUi,
      childrenElement,
      closeOnEscape: true,
      closeOnClickOutside: true,
    };

    confirmAlert(options);
    setVariables('already_exist_false');
  };

  function handleSubmit(e) {
    e.preventDefault();
    editFile({ id: mediaFiles[mediaData.index].id, data: mediaData });
  }

  function handleChange(name, value) {
    const dataChanged = mediaData;
    dataChanged[name] = value;
    setMediaData(dataChanged);
  }

  function handleFolderRename(data, value) {
    newFolderName.name = value;

    // TODO: must only mutate state via actions
    mediaFolders[newFolderName.index].name = newFolderName.name;
  }

  function choice(fileId) {
    const index = _findIndex(mediaFiles, ['id', fileId]);

    setMediaData({
      index,
      title: mediaFiles[index].title,
      description: mediaFiles[index].description,
      copyright: mediaFiles[index].copyright,
    });
    setShowEdit(true);
  }

  function del(fileId) {
    // replaces 1 element at index 4
    const index = _findIndex(mediaFiles, ['id', fileId]);
    deleteFile(mediaFiles[index].id);
  }

  function addDirectory() {
    setShowCreateFolder(true);
  }

  function handleFolderChange(data, value) {
    newFolderName.name = value;
    setNewFolderName(newFolderName);
  }

  useEffect(() => {
    if (saving === false) {
      setShowFolderEdit(saving);
      setShowEdit(saving);
      choiceFolder(openedFolder);
    }
  }, [saving]);

  function handleFolderSubmit(e) {
    e.preventDefault();
    setShowCreateFolder(false);
    createFolder({ name: newFolderName.name, parent: openedFolder });
  }

  function choiceFolder(parent, parentparent) {
    const storeIndex = _.findIndex(storeF, store => store === parent);
    if (storeIndex === -1) {
      const addFoldersToStore = _cloneDeep(storeF);
      addFoldersToStore.push(parent);
      setStoreF([...addFoldersToStore]);
      setOpenedFolder(parent);
      openedFolderSet(parent);
      getMediaLibraryFolder(client, parent, sorting, user.granted_holding_access_whitelist, user.username, includePdfFiles);
      setStoreParent(parentparent);
    } else {
      if (storeParent) {
        parentparent = storeParent;
      }
      // parent is folderid
      setCurrentFiles(
        mediaFiles ? mediaFiles.filter(d => d.parent === parent || (parent === 'main' && d.parent === null)) : []
      );

      setCurrentFolders(mediaFolders ? mediaFolders.filter(d => d.parent === parent) : []);
      const parentDir = parent !== 'main' && mediaFolders ? mediaFolders.filter(d => d.id === parent)[0] : {};
      const index = _findIndex(mediaFolders, ['id', parentparent]);
      const name = index !== -1 ? mediaFolders[index].name : messages.medialibrary.back_to_root;

      setOpenedFolder(parent);
      openedFolderSet(parent);
      setActivdir({ folderInfo: parentDir, files: currentFiles, folders: currentFolders, parentName: name });

      if (parent === 'main') {
        setGetMedia({ back: 0 });
      } else {
        setGetMedia({ back: 1 });
      }
      const back = parent === 'main' ? 0 : 1;
      setGetMedia({ back });

      if (parentDir) {
        userData.access = 0;
        if (userData.role === 'admin') {
          userData.access = 1;
        } else if (userData.role !== 'admin') {
          if (parentDir.owner && userData.username === parentDir.owner.username) {
            userData.access = 1;
          } else if (parentDir.creator && userData.username === parentDir.creator.username) {
            userData.access = 1;
          }
        }
        if (parent === 'main') {
          userData.access = 1;
        }
        setUserData({ ...userData });
      }
      setStoreParent('');
    }
  } // End folderChoice

  function editFolderModal(folderId) {
    setShowFolderEdit(true);
    const index = _findIndex(mediaFolders, ['id', folderId]);

    setNewFolderName({
      name: mediaFolders[index].name,
      idFolder: mediaFolders[index].id,
      index,
    });
  }

  function handleFolderEditSubmit(e) {
    e.preventDefault();
    editFolder({ id: newFolderName.idFolder, data: newFolderName });
  }

  function deleteFolderHandle(folderId) {
    const mediaFolder = mediaFolders.find(({ id }) => id === folderId);
    if (mediaFolder) {
      deleteFolder(mediaFolder.id);
    }
  }

  function addDragFile(fileId) {
    const index = _findIndex(mediaFiles, ['id', fileId]);
    const addDrags = filesDrag;
    addDrags.push(mediaFiles[index]);

    setFilesDrag([...addDrags]);
  }

  function deleteDragFile(fileId) {
    let sliced2 = [];

    const indexFilesDrag = _findIndex(filesDrag, ['id', fileId]);
    const deleteDrags = filesDrag;
    const sliced = deleteDrags.slice(0, indexFilesDrag);
    if (indexFilesDrag < deleteDrags.length - 1) {
      sliced2 = deleteDrags.slice(indexFilesDrag + 1);
    }
    setFilesDrag([...sliced, ...sliced2]);
  }

  function addDragFolder(folderId) {
    const index = _findIndex(mediaFolders, ['id', folderId]);
    const addDrags = foldersDrag;
    addDrags.push(mediaFolders[index]);

    setFoldersDrag([...addDrags]);
  }

  function deleteDragFolder(folderId) {
    let sliced4 = [];

    const indexFoldersDrag = _findIndex(foldersDrag, ['id', folderId]);
    const deleteDrags = foldersDrag;
    const sliced3 = deleteDrags.slice(0, indexFoldersDrag);
    if (indexFoldersDrag < deleteDrags.length - 1) {
      sliced4 = deleteDrags.slice(indexFoldersDrag + 1);
    }
    setFoldersDrag([...sliced3, ...sliced4]);
  }

  function markFile(fileId) {
    const checkDouble = _findIndex(filesDrag, ['id', fileId]);
    if (checkDouble === -1) {
      addDragFile(fileId);
    } else {
      deleteDragFile(fileId);
    }
  }

  function markFolder(folderId) {
    const checkDouble = _findIndex(foldersDrag, ['id', folderId]);

    if (checkDouble === -1) {
      addDragFolder(folderId);
    } else {
      deleteDragFolder(folderId);
    }
  }

  /* cut and paste Folder */
  function copyFolder(folderId) {
    const checkDouble = _findIndex(copiedFolders, ['id', folderId]);
    if (checkDouble === -1) {
      const indexIt = _findIndex(mediaFolders, ['id', folderId]);
      const addFolders = _cloneDeep(copiedFolders);
      addFolders.push(mediaFolders[indexIt]);
      setCopiedFolders([...addFolders]);
    } else {
      const indexFoldersCopied = _findIndex(copiedFolders, ['id', folderId]);
      const deleteCopied = _cloneDeep(copiedFolders);
      const sliced = deleteCopied.slice(0, indexFoldersCopied);
      let sliced2 = [];
      if (indexFoldersCopied < deleteCopied.length - 1) {
        sliced2 = deleteCopied.slice(indexFoldersCopied + 1);
      }
      setCopiedFolders([...sliced, ...sliced2]);
    }
  }

  function pasteFolder() {
    for (let i = 0; i < copiedFolders.length + 1; i++) {
      if (i < copiedFolders.length) {
        const indexIt = _findIndex(mediaFolders, ['id', copiedFolders[i].id]);
        mediaFolders[indexIt].parent = openedFolder;
        editFolder({ id: mediaFolders[indexIt].id, data: mediaFolders[indexIt] });
      } else {
        setCopiedFolders([]);
      }
    }
  }

  /* cut and paste File */
  function copyFile(fileId) {
    const checkDouble = _findIndex(copiedFiles, ['id', fileId]);
    if (checkDouble === -1) {
      const indexIt = _findIndex(mediaFiles, ['id', fileId]);
      const addFiles = _cloneDeep(copiedFiles);
      addFiles.push(mediaFiles[indexIt]);
      setCopiedFiles([...addFiles]);
    } else {
      let sliced2 = [];
      const indexFilesCopied = _findIndex(copiedFiles, ['id', fileId]);
      const deleteCopied = _cloneDeep(copiedFiles);
      const sliced = deleteCopied.slice(0, indexFilesCopied);
      if (indexFilesCopied < deleteCopied.length - 1) {
        sliced2 = deleteCopied.slice(indexFilesCopied + 1);
      }
      setCopiedFiles([...sliced, ...sliced2]);
    }
  }

  function pasteFile() {
    for (let i = 0; i < copiedFiles.length + 1; i++) {
      if (i < copiedFiles.length) {
        const indexIt = _findIndex(mediaFiles, ['id', copiedFiles[i].id]);
        mediaFiles[indexIt].parent = openedFolder;
        editFile({ id: mediaFiles[indexIt].id, data: mediaFiles[indexIt] });
      } else {
        setCopiedFiles([]);
      }
    }
  }

  function processDrop(fileId, parent, mediaFiles, mediaFolders, ownFileType, owner, creator) {
    // -- Drop Folder
    if (ownFileType) {
      const indexFolder = _findIndex(mediaFolders, ['id', fileId]);
      let dropFolderAllowed = 0;
      // let indexFolderParent = 0;
      let index = 0;

      if (foldersDrag.length > 0) {
        let i;
        for (i = 0; i < foldersDrag.length; i++) {
          // indexFolderParent = _findIndex(foldersDrag, ['id', foldersDrag[i].parent]);
          index = _findIndex(mediaFolders, ['id', foldersDrag[i].id]);

          if (parent !== 'main') {
            const indexFolderParent = _findIndex(mediaFolders, ['id', parent]);

            if (userData.role === 'admin') {
              dropFolderAllowed = 1;
            } else if (userData.username === owner) {
              dropFolderAllowed = 1;
            } else if (
              userData.username === foldersDrag[i].creator.username &&
              userData.username === mediaFolders[indexFolderParent].creator.username
            ) {
              dropFolderAllowed = 1;
            }
          } else {
            // End if !== "main"
            dropFolderAllowed = 1;
          }

          if (foldersDrag[i].id !== parent && dropFolderAllowed) {
            mediaFolders[index].parent = parent;
            foldersDrag[i].parent = parent;

            // cut file
          }
        }
        const folderSimulateId = 'multiDrag';
        editFolder({ id: folderSimulateId, data: foldersDrag });
      } else {
        if (parent !== 'main') {
          const indexFolderParent = _findIndex(mediaFolders, ['id', parent]);

          if (userData.role === 'admin') {
            dropFolderAllowed = 1;
          } else if (userData.username === owner) {
            dropFolderAllowed = 1;
          } else if (
            userData.username === creator &&
            userData.username === mediaFolders[indexFolderParent].creator.username
          ) {
            dropFolderAllowed = 1;
          }
        } else {
          // End if !== "main"
          dropFolderAllowed = 1;
        }

        if (fileId !== parent && dropFolderAllowed) {
          mediaFolders[indexFolder].parent = parent;
          editFolder({ id: mediaFolders[indexFolder].id, data: mediaFolders[indexFolder] });
        }
      }
    }

    // -- Drop File
    if (!ownFileType) {
      // let indexFolderParent = 0;
      let index = 0;
      let dropFileAllowed = 0;

      if (filesDrag.length > 0) {
        let i;
        for (i = 0; i < filesDrag.length; i++) {
          // indexFolderParent = _findIndex(filesDrag, ['id', filesDrag[i].parent]);
          index = _findIndex(mediaFiles, ['id', filesDrag[i].id]);

          if (parent !== 'main') {
            const indexFolderParent = _findIndex(mediaFolders, ['id', parent]);
            if (userData.role === 'admin') {
              dropFileAllowed = 1;
            } else if (userData.username === owner) {
              dropFileAllowed = 1;
            } else if (
              userData.username === filesDrag[i].creator.username &&
              userData.username === mediaFolders[indexFolderParent].creator.username
            ) {
              dropFileAllowed = 1;
            }
          } else {
            // End if !== "main"
            dropFileAllowed = 1;
          }

          if (dropFileAllowed) {
            mediaFiles[index].parent = parent;
            mediaFiles[i].parent = parent;
            // cut file
          }
        }

        const fileSimulateId = 'multiDrag';
        editFile({ id: fileSimulateId, data: filesDrag });
      } else {
        index = _findIndex(mediaFiles, ['id', fileId]);
        if (parent !== 'main') {
          const indexFolderParent = _findIndex(mediaFolders, ['id', parent]);
          if (userData.role === 'admin') {
            dropFileAllowed = 1;
          } else if (userData.username === owner) {
            dropFileAllowed = 1;
          } else if (
            userData.username === creator &&
            userData.username === mediaFolders[indexFolderParent].creator.username
          ) {
            dropFileAllowed = 1;
          }
        } else {
          // End if !== "main"
          dropFileAllowed = 1;
        }

        if (fileId !== parent && dropFileAllowed === 1) {
          mediaFiles[index].parent = parent;
          editFile({ id: mediaFiles[index].id, data: mediaFiles[index] });
        }
      }
    } // End dropFile
  } // End processDrop

  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col>
              <div />
            </Col>
          </Row>

          <Row className="mediainfo">
            <Col />
          </Row>
          <Row>
            <Col />
            <p>{}</p>
          </Row>
          <Row>
            <Col>
              <h3>
                <i className="fas fa-folders" /> {messages.medialibrary.directories}
                {getMedia.back ? (
                  <div>
                    <span style={{ fontSize: '40', color: '', margin: '10px' }}>
                      <i
                        className="far fa-arrow-alt-circle-left"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          choiceFolder(activdir.folderInfo.parent);
                        }}
                      />
                    </span>
                    <span style={{ fontSize: '12px' }}>
                      - <i className="fad fa-folder-open" /> {activdir.folderInfo.name}&nbsp; -{' '}
                      {messages.medialibrary.directory_by} {activdir.folderInfo?.owner?.profile?.company ?? 'CreaCheck'}
                      {activdir.folderInfo?.creator?.profile && (
                        <>
                          &nbsp;- {messages.medialibrary.created_by} {activdir.folderInfo.creator.profile.first_name}
                          &nbsp;
                          {activdir.folderInfo.creator.profile.last_name}
                        </>
                      )}
                    </span>
                  </div>
                ) : null}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <FolderView
                choiceFolder={choiceFolder}
                editFolder={editFolderModal}
                folders={currentFolders}
                files={currentFiles}
                mediaFiles={mediaFiles}
                s3urlThumb={s3urlThumb}
                s3urlOriginal={s3urlOriginal}
                del={del}
                undeleteFile={undeleteFile}
                eraseFile={eraseFile}
                choice={choice}
                deleteFolder={deleteFolderHandle}
                undeleteFolder={undeleteFolder}
                eraseFolder={eraseFolder}
                selectFile={selectFile}
                addDirectory={addDirectory}
                getMedia={getMedia}
                activdir={activdir}
                mediaFolders={mediaFolders}
                userData={userData}
                markFile={markFile}
                markFolder={markFolder}
                filesDrag={filesDrag}
                foldersDrag={foldersDrag}
                processDrop={processDrop}
                pending={pending}
                saving={saving}
                copyFolder={copyFolder}
                copyFile={copyFile}
                pasteFile={pasteFile}
                pasteFolder={pasteFolder}
                copiedFiles={copiedFiles}
                copiedFolders={copiedFolders}
                messages={messages}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Modals
        mediaFiles={mediaFiles}
        mediaData={mediaData}
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        s3urlOriginal={s3urlOriginal}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        saving={saving}
        showCreateFolder={showCreateFolder}
        setShowCreateFolder={setShowCreateFolder}
        handleFolderChange={handleFolderChange}
        handleFolderSubmit={handleFolderSubmit}
        newFolderName={newFolderName}
        showFolderEdit={showFolderEdit}
        setShowFolderEdit={setShowFolderEdit}
        handleFolderEditSubmit={handleFolderEditSubmit}
        handleFolderRename={handleFolderRename}
        messages={messages}
      />

      <UploadProcess
        mediaFiles={mediaFiles}
        mediaData={mediaData}
        setMediaData={setMediaData}
        openedFolder={openedFolder}
        selectedFiles={[...selectedFiles]}
        setSelectedFiles={setSelectedFiles}
        progressInfo={progressInfo}
        setProgressInfo={setProgressInfo}
        progressInfoRun={progressInfoRun}
        setProgressInfoRun={setProgressInfoRun}
        selectedUrls={selectedUrls}
        messages={messages}
        imgRightsQuery={imgRightsQuery}
        setImgRightsQuery={setImgRightsQuery}
      />
    </>
  );
};

const mapStateToProps = ({ mediaLibrary, login }) => ({
  mediaFolders: mediaLibrary.mediaFolders,
  mediaFiles: mediaLibrary.mediaFiles,
  saving: mediaLibrary.saving,
  pending: mediaLibrary.pending,
  client: login.user?.parent?.username,
  updater: mediaLibrary.updater,
  alreadyExist: mediaLibrary.already_exist,
});

const mapDispatchToProps = {
  createFolder,
  deleteFolder,
  editFolder,
  eraseFolder,
  undeleteFolder,
  getMediaLibrary,
  getMediaLibraryFolder,
  getMediaLibraryFolderSorted,
  editFile,
  deleteFile,
  undeleteFile,
  eraseFile,
  setVariables,
  changeUploadContext
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Functions);
