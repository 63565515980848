import axios from 'axios';

export const COOKIE = 'COOKIE';
export const COOKIE_PENDING = 'COOKIE_PENDING';
export const COOKIE_FULFILLED = 'COOKIE_FULFILLED';
export const COOKIE_REJECTED = 'COOKIE_REJECTED';

export function saveCookie(cookies) {
  return {
    type: COOKIE,
    payload: axios.post('/api/cookies.json?_locale=' + globalLocale, cookies),
  };
}
