import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { formStatusDispatchHelper, formReset } from 'actions/FormStatusActions';

import PriceNegotiationForm from './PriceNegotiationForm';

class PriceNegotiationNewForm extends React.Component {
  componentDidUpdate = prevProps => {
    const { formStatus, history, appIntl } = this.props;

    if (prevProps.formStatus.pending && !formStatus.pending && !formStatus.hasError) {
      history.push(`${appIntl.urlPrefix}account/priceNegotiations/${formStatus.response.id}`);
    }
  };

  render() {
    const { onSubmit, onSubmitParent, formStatus, item } = this.props;

    return (
      <PriceNegotiationForm
        type="new"
        onSubmit={onSubmit}
        onSubmitParent={onSubmitParent}
        formStatus={formStatus}
        data={{ item }}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    formStatus: state.formStatus.priceNegotiationNew,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: args => {
      formStatusDispatchHelper(
        'priceNegotiationNew',
        `/api/items/${ownProps.item.id}/pricenegotiations.json`,
        args,
        'post',
        dispatch
      );
    },
    onFormSuccessfull: () => {
      dispatch(formReset('priceNegotiationNew'));
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PriceNegotiationNewForm)
);
