import React from 'react';
import { Overlay, Popover, Row, Col } from 'react-bootstrap';

import IntlFormIndicator from 'components/intlForm/IntlFormIndicator';
import IntlFormSelector from 'components/intlForm/IntlFormSelector';

class FormCheckbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValues: props.values !== null && props.values !== undefined ? props.values : [],
      values: props.values !== null && props.values !== undefined ? props.values : [],
      focused: false,
    };

    this.inputRef = React.createRef();
  }

  checkFocused = () => {
    return this.state.focused && this.props.markFocused ? 'focused ' : '';
  };

  checkRequired = () => {
    return this.props.meta.validate.rules.indexOf('required') !== -1 ? 'required ' : '';
  };

  checkError = () => {
    return this.props.validation.valid ? '' : 'has-error ';
  };

  // check if user may read this field
  checkAccess = () => {
    return this.props.meta.acl.read;
  };

  // check if user may write to this field
  checkReadOnly = () => {
    return !this.props.meta.acl.write;
  };

  catchFocus = () => {
    if (!this.props.markFocused) {
      return;
    }
    this.inputRef.current.focus();
  };

  onChange = e => {
    const { values } = this.state;
    const { name, onChange } = this.props;
    const { target } = e;
    const { value } = target;
    const checked = Boolean(target.checked);

    if (checked) {
      if (values.indexOf(value) === -1) {
        // add the value to values
        this.setState({ values: [...values, value] });
      }
    } else {
      // remove value from values
      this.setState({ values: values.filter(entry => entry !== value) });
    }

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      onChange(name, this.state.values);
    }, 150);
  };

  checkDisabled = () => {
    const { disabled, meta } = this.props;
    if (disabled) {
      return true;
    }
    if (!meta.acl.write) {
      return true;
    }
    return false;
  };

  setValue = values => {
    this.setState({ values });
    // this.onChange(values)
  };

  isChecked = value => {
    if (value !== null) {
      const { values } = this.state;
      const stringValue = value.toString();
      return values.indexOf(stringValue) > -1;
    }
    return false;
  };

  renderOption = (name, option, i) => {
    const { highlightedValues, readonly } = this.props;

    return (
      <div
        class={`field-wrapper ${highlightedValues.includes(option.value) ? 'field-wrapper--highlighted' : ''}`}
        key={name + option.value}
      >
        <div class="checkbox check-success">
          {readonly ? (
            <input
              id={name + option.value}
              ref={i === 0 ? this.inputRef : null}
              value={option.value}
              type="checkbox"
              name={name}
              disabled
              checked={this.isChecked(option.value)}
            />
          ) : (
            <input
              id={name + option.value}
              ref={i === 0 ? this.inputRef : null}
              value={option.value}
              type="checkbox"
              name={name}
              onChange={this.onChange}
              onFocus={() => this.setState({ focused: true })}
              onBlur={() => this.setState({ focused: false })}
              disabled={this.checkDisabled()}
              checked={this.isChecked(option.value)}
            />
          )}
          <label
            for={name + option.value}
            className="font-heading fs-13"
            style={{ textTransform: 'none', fontWeight: 'normal' }}
          >
            {option.label}
          </label>
        </div>
      </div>
    );
  };

  render() {
    const { label, name, validation, options, isMultilanguage, columns, formGroupClass } = this.props;

    return (
      <div
        class={
          'form-group form-group-default ' +
          formGroupClass +
          ' ' +
          this.checkFocused() +
          this.checkRequired() +
          this.checkError() +
          (this.checkDisabled() ? 'disabled ' : '')
        }
        onClick={this.catchFocus}
      >
        <label>
          {label}
          <IntlFormIndicator show={isMultilanguage} />
        </label>
        {isMultilanguage && (
          <div class="multi-lang-list p-t-5 p-b-5">
            <IntlFormSelector />
          </div>
        )}
        {columns === 1 ? (
          options.map((option, i) => this.renderOption(name, option, i))
        ) : (
          <Row>
            {options.map((option, i) => {
              return (
                <Col key={name + option.value} xs={12} sm={12 / columns}>
                  {this.renderOption(name, option, i)}
                </Col>
              );
            })}
          </Row>
        )}

        <Overlay show={this.state.focused && !validation.valid} target={this.inputRef.current} placement="top">
          <Popover id={`popover-helptext-${name}`} class="popover-modal">
            {validation.helpText}
          </Popover>
        </Overlay>
      </div>
    );
  }
}
FormCheckbox.defaultProps = {
  label: '',
  meta: { validate: { rules: '' }, acl: { read: true, write: true } },
  validation: { valid: true },
  values: [],
  onChange: () => {},
  disabled: false,
  options: [],
  columns: 1,
  isMultilanguage: false,
  markFocused: true,
  highlightedValues: [],
  formGroupClass: '',
};

export default FormCheckbox;
