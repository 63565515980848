import { forEach as _forEach, get as _get } from 'lodash';

import { DATA_ID_PENDING, DATA_ID_FULFILLED, DATA_ID_REJECTED } from 'actions/DataIdActions';
import { FORM_FINISHED } from 'actions/FormStatusActions';
import { UPLOAD_FILE_FINISHED } from 'actions/UploadActions';

const initialState = {
  lead: {},
  leadDoc: {},
  clientDoc: {},
  dynData: {},
  chatterHistory: {},
  commerceProducts: {},
  item: {},
  location: {},
  doc: {},
  handover: {},
  client: {},
  questionnaire: {},
  filledQuestionnaire: {},
  invoice: {},
  quote: {},
  whiteLabelProject: {},
  customerChat: {},
  orderAuthorization: {},
};
const defaultDataIdState = {
  pending: true,
  hasError: false,
  errors: [],
  data: {},
};

export default function dataId(state = initialState, action) {
  switch (action.type) {
    case DATA_ID_PENDING: {
      const { dataIdName, dataIdId } = action.payload;

      return {
        ...state,
        [dataIdName]: {
          ...(state[dataIdName] || {}),
          [dataIdId]: {
            ..._get(state, [dataIdName, dataIdId], defaultDataIdState),
            pending: true,
            hasError: false,
            errors: [],
            data: {},
          },
        },
      };
    }

    case DATA_ID_FULFILLED: {
      if (!action.payload.response || !action.payload.response.data) return state;

      const { dataIdName, dataIdId } = action.payload;

      return {
        ...state,
        [dataIdName]: {
          ...(state[dataIdName] || {}),
          [dataIdId]: {
            ..._get(state, [dataIdName, dataIdId], defaultDataIdState),
            pending: false,
            hasError: false,
            errors: [],
            data: action.payload.response.data,
          },
        },
      };
    }

    case DATA_ID_REJECTED: {
      const { dataIdName, dataIdId } = action.payload;

      return {
        ...state,
        [dataIdName]: {
          ...(state[dataIdName] || {}),
          [dataIdId]: {
            ..._get(state, [dataIdName, dataIdId], defaultDataIdState),
            pending: false,
            hasError: true,
            errors: action.payload.response ? action.payload.response.data : {},
          },
        },
      };
    }

    case FORM_FINISHED: {
      const { formName, response } = action.payload;

      if (formName === 'documentNew' || formName === 'orderDocumentNew' || formName === 'leadDocumentNew') {
        const clientDoc = { ...state.clientDoc };

        _forEach(clientDoc, (docsList, targetUsername) => {
          const newClientDocList = docsList.data.list.slice();
          newClientDocList.push(response.data);

          clientDoc[targetUsername] = {
            ...clientDoc[targetUsername],
            data: { ...clientDoc[targetUsername].data, list: newClientDocList, count: newClientDocList.length },
          };
        });

        return { ...state, clientDoc };
      }

      if (
        formName === 'leadWon' ||
        formName === 'leadLost' ||
        formName === 'leadChangeStage' ||
        formName === 'leadChangeTeam' ||
        formName === 'leadChangePipeline'
      ) {
        const newState = { ...state };

        newState.lead[response.data.id] = {
          ...newState.lead[response.data.id],
          data: response.data,
        };

        return newState;
      }

      if (
        formName === 'orderActionApproveCarSale' ||
        formName === 'orderActionEnterVinNumber' ||
        formName === 'orderActionEnterEinNumber' ||
        formName === 'orderActionMarkCarAsPaid' ||
        formName === 'orderActionSendPaymentInstructions' ||
        formName === 'orderActionCancelOrder' ||
        formName === 'orderActionPickupInvite' ||
        formName === 'orderActionPickedUp' ||
        formName === 'orderActionCancelBooking' ||
        formName === 'orderActionMarkAssigneeKroschkeDocumentsSent' ||
        formName === 'orderActionMarkClientKroschkeDocumentsSent' ||
        formName === 'orderActionDefineCustomLicensePlateNumber'
      ) {
        const newState = { ...state };

        newState.lead[response.data.id] = {
          ...newState.lead[response.data.id],
          data: response.data,
        };

        return newState;
      }

      if (formName === 'orderAuthorization') {
        const newState = { ...state };

        newState.orderAuthorization[response.data.order_id] = {
          ...newState.orderAuthorization[response.data.order_id],
          data: response.data,
        };

        return newState;
      }

      break;
    }

    case UPLOAD_FILE_FINISHED: {
      if (action.payload.response.config.url.indexOf('/api/leads/') !== -1) {
        // a lead file was uploaded
        // get the lead id
        const match = action.payload.response.config.url.match(/\/api\/leads\/([a-z0-9]{32})\.json/);
        const leadId = match[1];

        const newState = { ...state };

        if (newState.leadDoc[leadId]) {
          // only update the list when the dataId was used
          // state.leadDoc[ leadId ].data.list
          // state.leadDoc[ leadId ].data.count
          const data = { ...newState.leadDoc[leadId].data };
          data.count += 1;
          data.list = newState.leadDoc[leadId].data.list.slice();
          data.list.push(action.payload.response.data);

          newState.leadDoc[leadId] = { data };
        }

        return newState;
      }
    }
  }

  return state;
}
