import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { intlModule, notificationModule, MessageType, getLogger, decodeActionType } from '@chedri/base';

import { notifyUserByDesktop } from '../helpers/notifications';
import globals from '../globals';
import Icon from './Icon';

const notifyLogger = getLogger('notification-desktop');

const { whiteLabelSettings } = globals;

export const ErrorContentShape = PropTypes.shape({
  code: PropTypes.number,
  message: PropTypes.string,
});

export const MessageShape = PropTypes.shape({
  key: PropTypes.number,
  type: PropTypes.number,
  content: PropTypes.oneOfType([PropTypes.string, ErrorContentShape]),
});
NotificationMessage.propTypes = {
  message: MessageShape.isRequired,
};

function NotificationMessage({ message }) {
  const dispatch = useDispatch();

  const messages = useSelector(intlModule.selectors.getMessages);

  const { key, type, content, data = {}, desktop } = message;
  const { thumbnail, dismissAfter = 10, actions, requireInteraction } = data;

  const [icon, setIcon] = useState('fa-info-circle');
  const [className, setClassname] = useState('');

  const dismiss = useCallback(() => {
    dispatch(notificationModule.actions.dismiss(key));
  }, [key]);

  useEffect(() => {
    switch (MessageType.parse(type)) {
      case MessageType.success:
        setIcon('fa-check');
        setClassname('success');
        break;
      case MessageType.warning:
        setIcon('fa-exclamation-circle');
        setClassname('warning');
        break;
      case MessageType.danger:
        setIcon('fa-exclamation-triangle');
        setClassname('danger');
        break;
      case MessageType.information:
      default:
        setIcon('fa-info-circle');
        setClassname('info');
    }
  }, [type]);

  useEffect(() => {
    if (dismissAfter > 0) {
      const dismissHandle = setTimeout(dismiss, dismissAfter * 1000);
      return () => {
        clearTimeout(dismissHandle);
      };
    }
  }, [dismissAfter, dismiss]);

  useEffect(() => {
    if (desktop) {
      notifyUserByDesktop(
        whiteLabelSettings.name,
        {
          icon: whiteLabelSettings.favicon.web_link,
          body: content.message || content,
          actions,
          requireInteraction,
          silent: true, // Webpage will play sounds, not the OS
        },
        key
      )
        .then(() => {
          notifyLogger.debug('User notification were delivered.');
        })
        .catch(err => {
          notifyLogger.error('User notification were not delivered.', err);
        });
    }
  }, [content, desktop, actions, key]);

  return (
    <div class={`pgn push-on-sidebar-open ${thumbnail ? 'pgn-circle' : 'pgn-simple'}`}>
      {thumbnail ? (
        <div class={`alert alert-${className}`}>
          <div>
            <div className="pgn-thumbnail">
              <img height="40" width="40" class="inline" src={thumbnail} alt="" />
            </div>
            <div className="pgn-message">
              <div>
                <p>{content.message || content}</p>
              </div>
            </div>
          </div>
          <button type="button" class="close" data-dismiss="alert" onClick={() => dismiss(key)}>
            <span class="sr-only">{messages.close}</span>
          </button>
        </div>
      ) : (
        <div class={`alert alert-${className} bordered`} style={{ borderRadius: '4px' }}>
          <button type="button" class="close" data-dismiss="alert" onClick={() => dismiss(key)}>
            <span class="sr-only">{messages.close}</span>
          </button>
          <Icon name={icon} solid iconClassName={`text-${className}`} /> - {content.message || content}
          {Array.isArray(actions) ? (
            <div className="m-t-5">
              {actions.map(entry => (
                <a
                  key={entry.action}
                  href="#action"
                  className="btn btn-link pull-right"
                  onClick={e => {
                    if (e.stopPropagation) {
                      e.stopPropagation();
                    }
                    if (e.preventDefault) {
                      e.preventDefault();
                    }
                    const action = decodeActionType({ action: entry.action });
                    dispatch(action);
                    dismiss(key);
                  }}
                >
                  {entry.title}
                </a>
              ))}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default NotificationMessage;
