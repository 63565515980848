import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

Confirmation.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Confirmation.defaultProps = {
  value: 'on',
  checked: false,
  onChange: () => undefined,
};

function Confirmation({ name, value, checked, children, onChange }) {
  const [id, setId] = useState(`${name}_${value}_confirm`);

  const change = useCallback(
    ({ target }) => {
      const { checked, value, name } = target;
      onChange(checked ? value : undefined, name);
    },
    [onChange]
  );

  useEffect(() => {
    setId(`${name}_${value}_confirm`);
  }, [name, value]);

  return (
    <div className="confirmation">
      <div className="affirm">
        <input id={id} type="checkbox" name={name} value={value} checked={checked} onChange={change} />
      </div>
      <div className="description">{children}</div>
    </div>
  );
}

export default Confirmation;
