import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import AbstractCmsElement from './AbstractCmsElement';

export default class CmsElementAccordion extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      ...this.state,
      shownAccordionIndex: null,
      shown: false,
    };
  }

  show = shownAccordionIndex => {
    this.setState({ shownAccordionIndex });
  };

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  render() {
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const mainColorClass = 'text-' + this.getParamValue('mainColor', 'master') + ' ';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-accordion ' + shownClass + mainColorClass + this.getResponsiveClasses() + this.getCssClasses()}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <h2 class="p-l-20">{this.getParamValue('title')}</h2>

        {this.props.children
          ? this.props.children.map((child, i) => {
              return React.cloneElement(child, {
                accordionIndex: i,
                shownAccordionIndex: this.state.shownAccordionIndex,
                show: this.show,
              });
            })
          : null}

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementAccordion.defaultProps = {
  index: '0',
};
