import { getUserAvatarInfos } from 'helpers/clients';

import { OAUTH_ERROR, SET_LOGIN_FORWARD_URL, SET_ANONYMOUS_USER_DATA } from 'actions/LoginActions';

const initialState = {
  user: {
    logged: false,
    adhocLogout: false,
    loginPending: false,
    logoutPending: false,
    loginError: false,
    loginErrors: [],
    anonymousEmail: localStorage.getItem('anonymousEmail'),
    anonymousName: localStorage.getItem('anonymousName'),
    voipDeviceToken: '',
  },
  forwardUrl: null,
};

// export function auth(state = {}, action) {
//   if (['FETCH_CREDENTIALS_FULFILLED', 'HANDLE_LOGIN_FULFILLED'].includes(action.type)) {
//     const { data } = action.payload;

//     if (data === '') {
//       return {
//         ...state,
//         logged: false,
//         loginPending: false,
//         logoutPending: false,
//         loginError: false,
//         loginErrors: [],
//       };
//     }

//     data.profile = {
//       ...data.profile,
//       ...getUserAvatarInfos(data),
//     };

//     return {
//       ...state,
//       ...data,
//       logged: true,
//       loginPending: false,
//       logoutPending: false,
//       loginError: false,
//       loginErrors: [],
//     };
//   }
//   return state;
// }

export default function login(state = initialState, action) {
  switch (action.type) {
    case 'HANDLE_LOGIN_PENDING':
    case 'FETCH_CREDENTIALS_PENDING': {
      return {
        ...state,
        user: {
          ...state.user,
          logged: false,
          adhocLogout: false,
          loginPending: true,
          logoutPending: false,
          loginError: false,
          loginErrors: [],
        },
      };
    }

    case 'HANDLE_LOGIN_REJECTED':
    case 'FETCH_CREDENTIALS_REJECTED': {
      // this action should never happen (FETCH_CREDENTIALS_REJECTED)
      return {
        ...state,
        user: {
          ...state.user,
          logged: false,
          adhocLogout: false,
          loginPending: false,
          logoutPending: false,
          loginError: true,
          loginErrors: action.payload.response.data,
        },
      };
    }

    /* we fetch the credentials after the website was reloaded */
    /* we also get user data after the user logged in */
    case 'HANDLE_LOGIN_FULFILLED':
    case 'FETCH_CREDENTIALS_FULFILLED': {
      const { data } = action.payload;

      if (data === '') {
        return {
          ...state,
          user: {
            ...state.user,
            logged: false,
            adhocLogout: false,
            loginPending: false,
            logoutPending: false,
            loginError: false,
            loginErrors: [],
          },
        };
      }

      data.profile = {
        ...data.profile,
        ...getUserAvatarInfos(data),
      };

      return {
        ...state,
        user: {
          ...data,
          logged: true,
          adhocLogout: false,
          loginPending: false,
          logoutPending: false,
          loginError: false,
          loginErrors: [],
        },
      };
    }

    case 'HANDLE_LOGOUT_PENDING': {
      return {
        ...state,
        user: {
          ...state.user,
          logged: false,
          adhocLogout: false,
          loginPending: false,
          logoutPending: true,
          loginError: false,
          loginErrors: [],
        },
      };
    }

    case 'HANDLE_LOGOUT_REJECTED': {
      break;
    }

    case 'HANDLE_LOGOUT_FULFILLED': {
      return {
        ...state,
        user: {
          ...state.user,
          logged: false,
          adhocLogout: false,
          loginPending: false,
          logoutPending: false,
          loginError: false,
          loginErrors: [],
        },
      };
    }

    case OAUTH_ERROR: {
      return {
        ...state,
        user: { ...state.user, loginError: true, loginErrors: [action.payload.error] },
      };
    }

    case 'FORM_FINISHED': {
      if (action.payload.formName === 'profileEdit') {
        const user = { ...state.user, ...action.payload.response.data };
        return { ...state, user };
      }

      if (action.payload.formName === 'forgotPasswordReset') {
        const user = { ...state.user, ...action.payload.response.data, logged: true };
        return { ...state, user };
      }

      if (action.payload.formName === 'checkoutSignupForm') {
        const user = { ...state.user, ...action.payload.response.data, logged: true };
        return { ...state, user };
      }

      if (action.payload.formName === 'clientAcceptTerms') {
        const user = { ...state.user, has_accepted_terms_and_conditions: true };
        return { ...state, user };
      }

      if (action.payload.formName === 'clientAcceptTermsOfUse') {
        const user = { ...state.user, has_accepted_terms_of_use: true };
        return { ...state, user };
      }

      if (action.payload.formName === 'clientAcceptOrderProcessingContract') {
        const user = { ...state.user, has_accepted_order_processing_contract: true };
        return { ...state, user };
      }

      if (action.payload.formName === 'initSoleRepresentative') {
        const user = { ...state.user, ...action.payload.response.data };
        return { ...state, user };
      }

      break;
    }

    case 'UPLOAD_FILE_FINISHED': {
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data !== undefined &&
        action.payload.response.data.email !== undefined &&
        state.user.profile.id === action.payload.response.data.id
      ) {
        const user = { ...state.user };
        user.profile = action.payload.response.data;

        return { ...state, user };
      }

      break;
    }

    case SET_LOGIN_FORWARD_URL: {
      return { ...state, forwardUrl: action.payload };
    }

    case SET_ANONYMOUS_USER_DATA: {
      const { email: anonymousEmail, name: anonymousName } = action.payload;
      return { ...state, user: { ...state.user, anonymousEmail, anonymousName } };
    }
  }

  if (action.type.indexOf('REJECTED') !== -1) {
    if (action.payload && action.payload.response && action.payload.response.status === 403) {
      const user = {
        ...state.user,
        logged: false,
        adhocLogout: true,
        loginPending: false,
        logoutPending: false,
        loginError: false,
        loginErrors: [],
      };
      return { ...state, user };
    }
  }

  return state;
}
