import React from 'react';
import PropTypes from 'prop-types';

// import { intlModule } from '@chedri/base';

// import { useSelector } from 'react-redux';
import { equalTo } from 'helpers/proptypes';
import CartWizardStepCoupon from './CartWizardStepCoupon';
import Properties from '../Properties';
import IntlDateTime from '../IntlDateTime';
import { useValidation } from '../../validation/ValidationContext';
import ValidationAlertIcon from '../../validation/ValidationAlertIcon';

CartWizardStepHeader.defaultProps = {
  number: undefined,
  name: 'Not named',
  label: 'Not named',
  campaign: 'Not named',
  lastModified: new Date(),
  lastStored: new Date(),
};

CartWizardStepHeader.propTypes = {
  number: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  campaign: PropTypes.string,
  lastModified: PropTypes.instanceOf(Date),
  lastStored: PropTypes.instanceOf(Date),
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.oneOfType([equalTo(CartWizardStepCoupon)]),
};

function CartWizardStepHeader({
  number,
  name,
  label,
  campaign,
  lastModified,
  lastStored,
  children,
  validationResult: validationResultParam = null,
  messages,
}) {
  // const messages = useSelector(intlModule.selectors.getMessages);
  const { validationResult } = useValidation();
  const properties = [
    { label: messages.cart_edition_title, description: label },
    { label: messages.cart_recent_edition, description: <IntlDateTime date={lastModified} /> },
    { label: messages.cart_campaign_title, description: campaign },
    { label: messages.cart_last_saved, description: <IntlDateTime date={lastStored} /> },
  ];

  return (
    <div className="wizard-step-header">
      <div className={`wizard-step-coupon${children ? ' enabled' : ''}`}>
        {number && (
          <div id={number} className="number">
            {number}
          </div>
        )}
        <div className="wizard-step-header-body">
          <div class="wizard-step-header-body__heading">
            <div className="headline">
              {name}
              <ValidationAlertIcon validationResult={validationResultParam || validationResult} />
            </div>
            {children}
          </div>
          <Properties data={properties} className="m-t-10" />
        </div>
      </div>
    </div>
  );
}

export default CartWizardStepHeader;
