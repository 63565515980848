import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Clearfix } from 'react-bootstrap';
import AnimateHeight from 'react-animate-height';
import { Range } from 'rc-slider';
import { find as _find, isNumber as _isNumber } from 'lodash';

import { parseQs, stringifyQs } from 'helpers/http';

class SummaryFilterSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mouseOver: false,
      show: false,
      changed: false,
      min: props.filters[props.urlNameMin]
        ? parseFloat(props.filters[props.urlNameMin])
        : props.useNullMin
        ? 0
        : this.getMin(),
      max: props.filters[props.urlNameMax] ? parseFloat(props.filters[props.urlNameMax]) : this.getMax(),
    };
  }

  handleClick = () => {
    this.setState(prevProps => ({ show: !prevProps.show }));
  };

  getName = () => {
    return this.props.name;
  };

  valueClick = values => {
    let changed = false;
    let state = {};
    if (values[0] !== this.state.min) {
      state.min = values[0];
      changed = true;
    }
    if (values[1] !== this.state.max) {
      state.max = values[1];
      changed = true;
    }

    if (changed) {
      this.setState({ ...state, changed });
    }
  };

  setFilter = () => {
    const { history, location, urlNameMin, urlNameMax } = this.props;
    const { min, max } = this.state;
    let query = parseQs(location.search);

    if (!min || min === this.getMin()) {
      delete query[urlNameMin];
    } else {
      query[urlNameMin] = min;
    }
    if (!max || max === this.getMax()) {
      delete query[urlNameMax];
    } else {
      query[urlNameMax] = max;
    }

    history.replace({ pathname: location.pathname, search: stringifyQs(query) });
    this.setState({ changed: false });
  };

  getMin = () => {
    const { facets, facetNameMin } = this.props;

    const facet = _find(facets.data.list, ['id', facetNameMin]);
    return facet && facet.values[0] && _isNumber(facet.values[0].count) ? facet.values[0].count : 0;
  };

  getMax = () => {
    const { facets, facetNameMax } = this.props;

    const facet = _find(facets.data.list, ['id', facetNameMax]);
    return facet && facet.values[0] && _isNumber(facet.values[0].count) ? facet.values[0].count : 0;
  };

  hasFilters = () => {
    const { filters, urlNameMin, urlNameMax } = this.props;
    return filters[urlNameMin] !== undefined || filters[urlNameMax] !== undefined;
  };

  // make sure that when the prices change outside of the range (for example user resets all or just one filter),
  //   the slider adjusts accordingly
  componentDidUpdate = prevProps => {
    const { urlNameMin, urlNameMax, filters, useNullMin } = this.props;

    const min = useNullMin ? 0 : this.getMin();

    const prevMin = parseFloat(prevProps.filters[urlNameMin] ? prevProps.filters[urlNameMin] : min);
    const currentMin = parseFloat(filters[urlNameMin] ? filters[urlNameMin] : min);

    const prevMax = parseFloat(prevProps.filters[urlNameMax] ? prevProps.filters[urlNameMax] : this.getMax());
    const currentMax = parseFloat(filters[urlNameMax] ? filters[urlNameMax] : this.getMax());

    const stateUpdate = {};
    let doUpdateState = false;

    if (currentMin !== prevMin) {
      stateUpdate.min = currentMin;
      doUpdateState = true;
    }

    if (currentMax !== prevMax) {
      stateUpdate.max = currentMax;
      doUpdateState = true;
    }

    if (doUpdateState) {
      this.setState(stateUpdate);
    }
  };

  render() {
    const { title, useNullMin } = this.props;
    const { mouseOver, show } = this.state;

    const min = useNullMin ? 0 : this.getMin();
    const max = this.getMax();

    return (
      <div
        class={'summary-filter animate-all ' + (mouseOver ? 'hover ' : '') + (show ? 'active ' : '')}
        onMouseEnter={() => this.setState({ mouseOver: true })}
        onMouseLeave={() => this.setState({ mouseOver: false })}
      >
        <div class="summary-filter-title" onClick={this.handleClick}>
          <Clearfix>
            <div class="pull-left">{title}</div>
            <div class="pull-right">
              {this.hasFilters() && !show ? (
                <i class="fal fa-check text-success" />
              ) : (
                <i class={'fal fa-chevron-' + (show ? 'up' : 'right')} />
              )}
            </div>
          </Clearfix>
        </div>

        <AnimateHeight duration={200} height={show ? 'auto' : 0}>
          <div class="summary-filter-body">
            <div class="padding-15">
              {show ? (
                <Range
                  allowCross={false}
                  defaultValue={[min, max]}
                  value={[this.state.min, this.state.max]}
                  min={min}
                  max={this.state.max > max ? this.state.max : max}
                  onChange={this.valueClick}
                  onAfterChange={this.setFilter}
                />
              ) : null}
            </div>

            <Clearfix class="p-l-15 p-r-15">
              <div class="pull-left">{this.props.formatter(this.state.min)}</div>
              <div class="pull-right">{this.props.formatter(this.state.max)}</div>
            </Clearfix>
          </div>
        </AnimateHeight>
      </div>
    );
  }
}
SummaryFilterSlider.defaultProps = {
  formatter: value => value,
  name: 'PRICE',
  title: 'Price',
  urlNameMin: 'MIN_PRICE',
  urlNameMax: 'MAX_PRICE',
  facetNameMin: 'minPrice',
  facetNameMax: 'maxPrice',
  disabled: false,
  useNullMin: true,
};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    projectConfig: state.data.projectConfig,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(SummaryFilterSlider)));
