import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import Loader from '../Loader';
import { uploadFile, setVariables } from '../../../actions/MediaLibraryActions';
import Modal from '../../Modal';

const UploadProcess = props => {
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [dataCounter, setDataCounter] = useState(0);
  const [errorCounter, setErrorCounter] = useState(0);
  const [proofUpload, setProofUpload] = useState([]);
  useEffect(() => {
    if (props.selectedFiles.length > 0) {
        setShowUploadProgress(true);
      // uploadSplit();
    }
  }, [props.selectedFiles]);

  useEffect(() => {
    if (props.clientImageRightsQuery && props.imgRightsQuery === true) {
      // if file permission query is set in client
      filePermissionQuery();
    }
  }, [props.imgRightsQuery]);

  useEffect(() => {
    if (props.saving === false && props.filesToUpload === 0) closeUploadProcess();
    if (props.filesToUpload > 0 && props.saving === false) upload();
  }, [props.saving, props.filesToUpload]);

  useEffect(() => {
    if (props.progressInfo.length > 0) {
      setUploading(true);
      upload();
    }
  }, [props.progressInfo]);

  const FileCounter = ({ dataCounter }) => {
    return (
      <div style={{ textAlign: 'center' }}>
        <h3>
          {props.messages.medialibrary.upload_processing}: {dataCounter}/
          {props.selectedFiles ? props.selectedFiles.length : 0}
        </h3>
      </div>
    );
  };

  const ErrorCounter = ({ errorCounter }) => {
    return (
      <div style={{ textAlign: 'center' }}>
        <h4 style={{ color: 'red' }}>
          <i style={{ fontSize: '20px', color: '#cd1315' }} className="far fa-exclamation-circle" /> {errorCounter}{' '}
          {props.messages.medialibrary.not_uploaded}
        </h4>
        <Button
          className="trashButton"
          onClick={() => {
            closeUploadProcess();
          }}
          style={{ backgroundColor: '#E03A3A', color: '#FFFFFF' }}
        >
          <i className="far fa-window-close" style={{ color: '#FFFFFF' }} />
          <span className="hidden-xs">{props.messages.medialibrary.close}</span>
        </Button>
      </div>
    );
  };

  const closeUploadProcess = () => {
    props.setSelectedFiles([]);
    setShowUploadProgress(false);
    setDataCounter(0);
    setErrorCounter(0);
    setProofUpload([]);
    props.setVariables('already_exist_errors_reset');
    props.setImgRightsQuery(false);
  };

  const ListProgress = ({ index }) => {
    const progressOutput = [];
    progressOutput.push(
      <React.Fragment key={index}>
        {props.progressInfoRun && props.progressInfoRun.length > 0 ? (
          <div className="progress">
            <div
              className="progress-bar progress-bar-info progress-bar-striped"
              role="progressbar"
              aria-valuenow={props.progressInfoRun[index].percentage}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: props.progressInfoRun[index].percentage + '%' }}
            >
              {props.progressInfoRun[index].percentage}%
            </div>
          </div>
        ) : (
          <></>
        )}
      </React.Fragment>
    );
    return progressOutput;
  };

  const uploadSplit = () => {
    const _progressInfos = [];
    for (let i = 0; i < props.selectedFiles.length; i++) {
      _progressInfos.push({ percentage: 0 });
    }
    props.setProgressInfo(props.progressInfo.concat(_progressInfos));
  };

  const upload = () => {
    const _progressInfosNeu = [...props.progressInfo];
    let _selectedFiles = props.selectedFiles.length;

    let _mediaDataClone = { index: 0, title: '', description: '', copyright: '', parent: props.openedFolder };
    let i = 0;
    let counter = 1;
    if (props.filesToUpload !== 0) {
      i = _selectedFiles - props.filesToUpload;
      counter = _selectedFiles - props.filesToUpload + 1;
    }

    const idx = i;
    const file = props.selectedFiles[i];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('json', JSON.stringify(_mediaDataClone));

    const uploadProgress = {
      onUploadProgress: ProgressEvent => {
        _progressInfosNeu[idx].percentage = Math.round((100 * ProgressEvent.loaded) / ProgressEvent.total);
        props.setProgressInfoRun(props.progressInfoRun.concat(_progressInfosNeu));
      },
    };

    props.uploadFile(formData, uploadProgress, _selectedFiles - counter);
    _selectedFiles--;
    // }
  };

  function removeSelection(index) {
    const realArray = Array.from(props.selectedFiles);
    const slicedOne = realArray.slice(0, index);
    let slicedTwo = [];
    if (index < realArray.length - 1) {
      slicedTwo = realArray.slice(index + 1);
    }
    props.setSelectedFiles([...slicedOne, ...slicedTwo]);
  }

  const filePermissionQuery = () => {
    const options = {
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body" style={{ width: '100%' }}>
            <h1>{props.messages.image_permission_title}</h1>
            <p>{props.messages.image_permission_warning}</p>
            <div className="react-confirm-alert-button-group" style={{ width: '100%', margin: '0 auto' }}>
              <Button
                style={{ margin: '5px', width: '100px' }}
                bsStyle="primary"
                onClick={() => {
                  onClose();
                  closeUploadProcess();
                }}
              >
                {props.messages.no}
              </Button>
              <Button
                style={{ margin: '5px', width: '100px' }}
                bsStyle="warning"
                onClick={() => {
                  onClose();
                  setShowUploadProgress(true);
                }}
              >
                {props.messages.yes}
              </Button>
            </div>
          </div>
        );
      },
      childrenElement: () => <div />,
      closeOnEscape: false,
      closeOnClickOutside: false,
    };

    confirmAlert(options);
  };

  return (
    <>
      <Modal
        show={showUploadProgress}
        onHide={() => {
          setShowUploadProgress(false);
          closeUploadProcess();
        }}
      >
        <Modal.Header closeButton>
          <h3>{props.messages.medialibrary.upload_media}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="FileUpload">
            <>
              <Row className="hidden-xs" style={{ backgroundColor: '#F1F1F1' }}>
                <Col sm={3} xs={3}>
                  <h5>{props.messages.medialibrary.preview}</h5>
                </Col>
                <Col sm={3} xs={3}>
                  <h5>{props.messages.medialibrary.name}</h5>
                </Col>
                <Col sm={3} xs={3}>
                  <h5>{props.messages.medialibrary.options}</h5>
                </Col>
                <Col sm={1} xs={1}>
                  <h5>{props.messages.medialibrary.upload_info}</h5>
                </Col>
                <Col sm={1} xs={1}>
                  <h5>{props.messages.medialibrary.status}</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  {props.selectedFiles ? (
                    props.selectedFiles.map((item, i) => (
                      <div key={i} className="uploadScreen">
                        <Row className="m-t-20 m-b-20">
                          <Col sm={3} xs={3}>
                            {item.type !== 'video/mp4' ? (
                              <div>
                                <img
                                  src={item.type === 'application/pdf' ? '/img/pdf.svg' : props.selectedUrls[i]}
                                  style={{ objectFit: 'cover', height: '75px', width: '75px' }}
                                  alt={'selectedImage_' + i}
                                />
                              </div>
                            ) : (
                              <video controls style={{ width: '100%', height: '100x' }}>
                                <source src={props.selectedUrls[i]} type="video/mp4" />
                              </video>
                            )}
                          </Col>
                          <Col sm={3} xs={5}>
                            <p style={{ wordWrap: 'break-word' }}>{item.name}</p>
                          </Col>
                          <Col sm={3} xs={3}>
                            <Button
                              className="trashButton"
                              onClick={() => {
                                removeSelection(i);
                              }}
                              style={{ backgroundColor: '#E03A3A', color: '#FFFFFF' }}
                            >
                              <i className="fal fa-trash-alt" style={{ color: '#FFFFFF' }} />
                              <span className="hidden-xs">{props.messages.remove_selection}</span>
                            </Button>
                          </Col>
                          <Col sm={1} xs={1}>
                            {proofUpload.length > 0 && !proofUpload[i] ? (
                              <i style={{ fontSize: '30px', color: '#cd1315' }} className="far fa-exclamation-circle" />
                            ) : proofUpload.length > 0 && proofUpload[i] ? (
                              <i style={{ fontSize: '30px', color: '#b2ce4d' }} className="fal fa-check-circle" />
                            ) : (
                              <></>
                            )}
                            {props.already_exist_errors.length > 0 && props.already_exist_errors[i] ? (
                              <i style={{ fontSize: '30px', color: '#cd1315' }} className="far fa-times-circle" />
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col sm={2} xs={12}>
                            {props.progressInfoRun.length > 0 ? <ListProgress key={i} index={i} /> : <></>}
                          </Col>
                        </Row>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              {uploading && (
                <>
                  <Row>
                    <Col md={12}>
                      <Row>{props.saving ? <Loader /> : <></>}</Row>
                      {dataCounter ? <FileCounter dataCounter={dataCounter} /> : <></>}
                      {errorCounter ? <ErrorCounter errorCounter={errorCounter} /> : <></>}
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <div className="showUploads" style={{ textAlign: 'right' }}>
                  <Button onClick={uploadSplit} bsStyle="success" className="card-button">
                    <i className="far fa-upload" />
                    {props.messages.medialibrary.upload}
                  </Button>
                </div>
              </Row>
            </>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  mediaFolders: state.mediaLibrary.mediaFolders,
  mediaFiles: state.mediaLibrary.mediaFiles,
  saving: state.mediaLibrary.saving,
  pending: state.mediaLibrary.pending,
  updater: state.mediaLibrary.updater,
  filesToUpload: state.mediaLibrary.filesToUpload,
  already_exist_errors: state.mediaLibrary.already_exist_errors,
  clientImageRightsQuery: state.login.user?.parent?.image_rights_query,
});

const mapDispatchToProps = dispatch => {
  return {
    uploadFile: (data, uploadProgress, filesToUpload) => {
      dispatch(uploadFile(data, uploadProgress, filesToUpload));
    },
    setVariables: data => {
      dispatch(setVariables(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadProcess);
