import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedNumber } from 'react-intl';
import { Row, Col, Clearfix } from 'react-bootstrap';
import Modal from 'components/Modal';

import { submitLoginWithoutRedirect } from 'actions/LoginActions';
import { addNotification } from 'actions/NotificationsActions';

import ItemInfos from 'components/layout/item/ItemInfos';
import CmsElementLoginForm from 'components/cms/elements/CmsElementLoginForm';

import PriceNegotiationNewForm from 'components/forms/priceNegotiation/PriceNegotiationNewForm';

class ModalNewPriceNegotiation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shown: false,
    };
  }

  componentDidUpdate = prevProps => {
    const {
      intl: { messages },
      formStatus,
      doNotify,
      close,
    } = this.props;
    const { shown } = this.state;

    if (shown && prevProps.formStatus.pending && !formStatus.pending && !formStatus.hasError) {
      // after we saved the data, notify the user and close the modal
      doNotify(1, messages.price_negotiation_started);
      close();
    }
  };

  onShow = () => {
    this.setState({ shown: true });
  };

  onExit = () => {
    this.setState({ shown: false });
  };

  getSocialMediaState = () => {
    const { appIntl, projectConfig, location } = this.props;

    const res = {
      whiteLabelProjectId: projectConfig.data.id,
      route: appIntl.urlPrefix + location.pathname + (location.search ? '?' : '') + location.search,
    };

    return res;
  };

  submitLogin = loginData => {
    const { appIntl, submitLogin } = this.props;

    return submitLogin(loginData, appIntl.urlPrefix);
  };

  render() {
    const { intl, appIntl, show, close, user, item, webApp } = this.props;
    const { messages } = intl;

    return (
      <Modal show={show} onHide={close} bsSize="lg" onExit={this.onExit} onShow={this.onShow}>
        <Modal.Header closeButton>
          <Modal.Title>{messages.send_price_proposal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="bg-master-light padding-30 b-rad-lg m-b-20">
            <h2 class="no-margin">{messages.how_it_works}</h2>
            <p>{messages.our_prices_are_already_optimized_but_if_you_think_price_is_too_high_negotiate_it}</p>
            <div class="p-t-20">
              <ol>
                <li>{messages.select_the_car_you_love}</li>
                <li>{messages.sign_up_or_log_in_if_you_already_have_an_account}</li>
                <li>{messages.enter_desired_price}</li>
                <li>{messages.send_the_form_our_employee_will_contact_you}</li>
                <li>{messages.if_we_settle_on_a_price_you_can_buy_the_car_online}</li>
              </ol>
            </div>
          </div>

          <ItemInfos item={item} showActions={false} />

          {/* allow initiation of price negotiation process only for logged in users */}
          {user.logged ? (
            <div class="m-t-20">
              <Clearfix>
                <Col xs={6} sm={3}>
                  <h5 class="semi-bold no-margin">
                    <span class="text-black">{messages.our_price}</span>
                  </h5>
                  <h3 class="m-t-0">
                    <span class="text-primary">
                      <FormattedNumber value={item.pricing.price} style="currency" currency={webApp.currency} />
                    </span>
                  </h3>
                </Col>
                <Col xs={6} sm={9}>
                  <PriceNegotiationNewForm item={item} />
                </Col>
              </Clearfix>
            </div>
          ) : (
            <div class="m-t-30">
              <Row>
                <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
                  <h4>{messages.you_have_to_log_in_to_proceed}</h4>
                  <CmsElementLoginForm
                    hideTitle
                    socialMediaLoginState={window.btoa(JSON.stringify(this.getSocialMediaState()))}
                    intl={intl}
                    appIntl={appIntl}
                    onSubmit={this.submitLogin}
                  />
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    webApp: state.webApp,
    user: state.login.user,
    projectConfig: state.data.projectConfig,
    formStatus: state.formStatus.priceNegotiationNew,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    submitLogin: (args, urlPrefix) => dispatch(submitLoginWithoutRedirect(args, urlPrefix)),
    doNotify: (code, text) => {
      dispatch(addNotification(code, text));
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ModalNewPriceNegotiation)
  )
);
