import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { formStatusDispatchHelper, formReset } from 'actions/FormStatusActions';

import ChatterReviewForm from 'components/forms/chatter/ChatterReviewForm';

class ChatterReviewNewForm extends React.Component {
  componentDidUpdate = prevProps => {
    const { shown, onSubmitParent, formStatus } = this.props;

    if (shown && onSubmitParent && prevProps.formStatus.pending && !formStatus.pending && !formStatus.hasError) {
      onSubmitParent(formStatus.response);
    }
  };

  onSubmit = args => {
    const { reviewData, onSubmit } = this.props;
    const type = reviewData.customerChatId ? 'chat' : 'call';
    const id = reviewData.customerChatId || reviewData.customerVideoCallId;

    onSubmit(id, type, args);
  };

  render() {
    const { shown, formStatus, reviewData } = this.props;

    return (
      <ChatterReviewForm
        reviewData={reviewData}
        type="new"
        onSubmit={this.onSubmit}
        formStatus={formStatus}
        shown={shown}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reviewData } = ownProps;

  let formStatus = state.formStatus.customerChatEdit;
  if (reviewData && !reviewData.customerChatId) {
    formStatus = state.formStatus.customerVideoCallEdit;
  }

  return {
    formStatus,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  const { reviewData } = ownProps;
  const formName = reviewData && reviewData.customerChatId ? 'customerChatEdit' : 'customerVideoCallEdit';

  return {
    onSubmit: (id, type, args) => {
      const url = type === 'chat' ? `/api/customerchats/${id}.json` : `/api/video/customercalls/${id}.json`;
      formStatusDispatchHelper(formName, url, args, 'put', dispatch);
    },
    onFormSuccessfull: () => {
      dispatch(formReset(formName));
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ChatterReviewNewForm)
  )
);
