import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Row, Col } from 'react-bootstrap';
import { injectIntl } from 'react-intl';

import { formStatusDispatchHelper } from 'actions/FormStatusActions';
import { getSalutationOptions, getGenderForSalutation } from 'helpers/clients';

import CmsElementSignupFormLegal from 'components/cms/elements/CmsElementSignupFormLegal';

import Loader from 'components/layout/Loader';
import SimpleSubmitButton from 'components/layout/SimpleSubmitButton';
import Data from 'components/Data';

import FormInput from 'components/forms/FormInput';
import FormInputPassword from 'components/forms/FormInputPassword';
import FormAutocomplete from 'components/forms/FormAutocomplete';
import FormErrors from 'components/forms/FormErrors';

import EntityStateUpdater from 'component/EntityStateUpdaterV2';

import { passwordRepeat, injectValidator } from 'formValidation/entityValidators';

class CompanySignup extends EntityStateUpdater {
  constructor(props) {
    super(props, {
      metaIdentifier: 'chedriCompanySignupForm',
      dataProps: 'empty',
      defaultDataProps: { country: 'DE', gender: 'male', salutation: 'mr' },
    }); // pass the configuration to entity state updater

    this.entity.__addValidator(injectValidator(passwordRepeat, props.intl));
  }

  componentDidMount = () => {
    const { appIntl, history, user } = this.props;

    if (user.logged) {
      history.push(appIntl.urlPrefix);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { formStatus } = this.props;

    if (!formStatus.pending && prevProps.formStatus.pending && !formStatus.hasError) {
      // registration was successfull, redirect the user to a success page
      const { appIntl, history } = this.props;
      history.push(appIntl.urlPrefix + 'companySignupComplete');
    }

    this.__componentDidUpdate(prevProps, prevState);
  };

  updateSalutation = (name, value) => {
    const gender = getGenderForSalutation(value);
    this.updateEntityMultiple(['salutation', 'gender'], [value, gender]);
  };

  onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    this.validateEntity(() => {
      this.props.onSubmit(this.props.appIntl.urlPrefix, this.state.entity.__getStoredData());
    });
  };

  render() {
    const {
      intl: { messages },
      formStatus,
    } = this.props;
    const { entity, entityLoaded } = this.state;

    return (
      <div>
        <Data dataName="chedriCompanySignupForm" /> {/* fetch meta data */}
        <Grid class="p-t-50 p-b-50">
          <Row>
            <Col xs={12}>
              <FormErrors formStatus={formStatus} />

              {entityLoaded ? (
                <form method="post" onSubmit={this.onSubmit}>
                  <h3 class="m-b-0 p-b-0">Unternehmendaten</h3>
                  <p class="">Bitte geben Sie uns Informationen über das Unternehmen, bei dem Sie arbeiten.</p>
                  <div class="form-group-attached">
                    <FormInput
                      label={messages.company}
                      name="company"
                      value={entity.getCompany}
                      meta={entity.getCompanyMetaInformation}
                      validation={entity.getCompanyValidationResult}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />

                    <FormInput
                      type="tel"
                      label={messages.phone}
                      name="phone"
                      value={entity.getPhone}
                      meta={entity.getPhoneMetaInformation}
                      validation={entity.getPhoneValidationResult}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                    <FormInput
                      type="email"
                      label={messages.email}
                      name="companyEmail"
                      value={entity.getCompanyEmail}
                      meta={entity.getCompanyEmailMetaInformation}
                      validation={entity.getCompanyEmailValidationResult}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />

                    <FormInput
                      label={messages.street}
                      name="street"
                      value={entity.getStreet}
                      meta={entity.getStreetMetaInformation}
                      validation={entity.getStreetValidationResult}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />

                    <Row class="clearfix no-margin">
                      <Col sm={6}>
                        <FormInput
                          label={messages.house_number}
                          name="houseNumber"
                          value={entity.getHouseNumber}
                          meta={entity.getHouseNumberMetaInformation}
                          validation={entity.getHouseNumberValidationResult}
                          onChange={this.updateEntity}
                          disabled={formStatus.pending}
                        />
                      </Col>
                      <Col sm={6}>
                        <FormInput
                          label={messages.apartment_number}
                          name="apartmentNumber"
                          value={entity.getApartmentNumber}
                          meta={entity.getApartmentNumberMetaInformation}
                          validation={entity.getApartmentNumberValidationResult}
                          onChange={this.updateEntity}
                          disabled={formStatus.pending}
                        />
                      </Col>
                      <Col sm={12}>
                        <FormInput
                          label={messages.address_line2}
                          name="addressLine2"
                          value={entity.getAddressLine2()}
                          meta={entity.getAddressLine2MetaInformation()}
                          validation={entity.getAddressLine2ValidationResult()}
                          onChange={this.updateEntity}
                          disabled={formStatus.pending}
                        />
                      </Col>
                    </Row>

                    <Row class="clearfix no-margin">
                      <Col sm={6}>
                        <FormInput
                          label={messages.zip_code}
                          name="zipCode"
                          value={entity.getZipCode}
                          meta={entity.getZipCodeMetaInformation}
                          validation={entity.getZipCodeValidationResult}
                          onChange={this.updateEntity}
                          disabled={formStatus.pending}
                        />
                      </Col>
                      <Col sm={6}>
                        <FormInput
                          label={messages.city}
                          name="city"
                          value={entity.getCity}
                          meta={entity.getCityMetaInformation}
                          validation={entity.getCityValidationResult}
                          onChange={this.updateEntity}
                          disabled={formStatus.pending}
                        />
                      </Col>
                    </Row>

                    <FormAutocomplete
                      label={messages.country}
                      name="country"
                      value={entity.getCountry}
                      meta={entity.getCountryMetaInformation}
                      validation={entity.getCountryValidationResult}
                      onChange={this.updateEntity}
                      autocompleteType="country"
                      complete
                      disabled={formStatus.pending}
                    />
                  </div>

                  <h3 class="m-b-0 p-b-0 m-t-30">Ihre Daten</h3>
                  <p class="">
                    Bitte machen Sie Angaben zu Ihrer Person, damit wir ein Mitarbeiterprofil für Sie erstellen können.
                    <br />
                    Sie erhalten zunächst Administratorrechte für Ihr Projekt..
                  </p>
                  <div class="form-group-attached">
                    <FormAutocomplete
                      label={messages.salutation}
                      name="salutation"
                      value={entity.getSalutation}
                      meta={entity.getSalutationMetaInformation}
                      validation={entity.getSalutationValidationResult}
                      onChange={this.updateSalutation}
                      options={getSalutationOptions(messages)}
                      disabled={formStatus.pending}
                    />
                    <FormInput
                      label={messages.first_name}
                      name="firstName"
                      value={entity.getFirstName}
                      meta={entity.getFirstNameMetaInformation}
                      validation={entity.getFirstNameValidationResult}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                    <FormInput
                      label={messages.last_name}
                      name="lastName"
                      value={entity.getLastName}
                      meta={entity.getLastNameMetaInformation}
                      validation={entity.getLastNameValidationResult}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                    <FormInput
                      type="tel"
                      label={messages.mobile_phone}
                      name="mobile"
                      value={entity.getMobile}
                      meta={entity.getMobileMetaInformation}
                      validation={entity.getMobileValidationResult}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                    <FormInput
                      type="email"
                      label={messages.email}
                      name="email"
                      value={entity.getEmail}
                      meta={entity.getEmailMetaInformation}
                      validation={entity.getEmailValidationResult}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                  </div>

                  <div class="form-group-attached m-t-10">
                    <FormInputPassword
                      name="password"
                      label={messages.password}
                      value={entity.getPassword}
                      meta={entity.getPasswordMetaInformation}
                      validation={entity.getPasswordValidationResult}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                    <FormInputPassword
                      name="repeatPassword"
                      label={messages.repeat_password}
                      value={entity.getRepeatPassword}
                      meta={entity.getRepeatPasswordMetaInformation}
                      validation={entity.getRepeatPasswordValidationResult}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                    />
                  </div>

                  <h3 class="m-b-0 p-b-0 m-t-30">Ihr Testdomainname</h3>
                  <p class="">
                    Bitte erstellen Sie einen Domainnamen, in dem Ihr Projekt gehostet wird. Die Domain ist direkt nach
                    der Registrierung verfügbar.
                    <br />
                    Denken Sie daran, dass wir Projekte auch unter einem von Ihnen angegebenen Domainnamen hosten
                    können. Kontaktieren Sie uns einfach später.
                  </p>
                  <div class="form-group-attached">
                    <FormInput
                      name="testDomain"
                      label="Test Domain"
                      value={entity.getTestDomain}
                      meta={entity.getTestDomainMetaInformation}
                      validation={entity.getTestDomainValidationResult}
                      onChange={this.updateEntity}
                      disabled={formStatus.pending}
                      suffixAddon=".creacheck.de"
                    />
                  </div>

                  <div class="p-t-20 p-b-15 fs-11">
                    <CmsElementSignupFormLegal {...this.props} />
                  </div>

                  <SimpleSubmitButton
                    label={messages.signup}
                    iconClass="fad fa-chevron-double-right"
                    entityChanged={this.getEntityChanged()}
                    errorButton={this.hasValidationErrorButton()}
                    pending={formStatus.pending}
                    block
                    defaultStyle="primary"
                  />
                </form>
              ) : (
                <Loader />
              )}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    meta: state.entityMeta,
    user: state.login.user,
    formStatus: state.formStatus.companySignup,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (urlPrefix, args) => {
      formStatusDispatchHelper('companySignup', urlPrefix + 'login/chedriRegister', args, 'post', dispatch);
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CompanySignup)
  )
);
