import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { intlModule } from '@chedri/base';

import Modal from 'components/Modal';
import AddressList from 'components/layout/contacts/AddressList';

AddressListModal.propTypes = {
  show: PropTypes.bool,
  showAddContact: PropTypes.bool,
  onClose: PropTypes.func,
  onSelected: PropTypes.func,
  filterByTypes: PropTypes.arrayOf(PropTypes.string),
  filter: PropTypes.string,
};

AddressListModal.defaultProps = {
  show: false,
  showAddContact: true,
  onClose: () => undefined,
  onSelected: () => {},
  filterByTypes: [],
  filter: 'all',
};

function AddressListModal({ show, showAddContact, onSelected, onClose, filterByTypes, filter }) {
  const messages = useSelector(intlModule.selectors.getMessages);

  return (
    <Modal show={show} onHide={onClose} bsSize="lg">
      <Modal.Header closeButton>
        <Modal.Title>{messages.find_address}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddressList
          showAddContact={showAddContact}
          onSelected={(...args) => onSelected(...args) || onClose()}
          filterByTypes={filterByTypes}
          filter={filter}
        />
      </Modal.Body>
    </Modal>
  );
}

export default AddressListModal;
