import React from 'react';
import { components } from 'react-select';

class ModelOption extends React.Component {
  render() {
    const { data } = this.props;
    return <components.Option {...this.props}>{data.label}</components.Option>;
  }
}

class ModelValue extends React.Component {
  render() {
    const { data } = this.props;

    return <div className="Select-value">{data.label}</div>;
  }
}

const modelOptionFormatter = options => {
  return options.list.map(option => {
    return modelSingleOptionFormatter(option);
  });
};

const modelSingleOptionFormatter = (option = null) => {
  if (!option) {
    return option;
  }
  return { ...option, value: option.id, label: option.name };
};

const modelOptionFromEntity = model => {
  return !model
    ? null
    : modelSingleOptionFormatter({
        id: model.getId(),
        name: model.getName(),
      });
};

export { ModelValue, ModelOption, modelOptionFormatter, modelSingleOptionFormatter, modelOptionFromEntity };
