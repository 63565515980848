import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Row, Col } from 'react-bootstrap';
import { Wizard, Steps, Step } from 'react-albus';
import { omit as _omit } from 'lodash';
import { Element, scroller } from 'react-scroll';

import { addNotification } from 'actions/NotificationsActions';
import WizardNav from 'components/layout/WizardNav';
import CompanySignupStepSubscriptionPlan from 'components/forms/auth/CompanySignupStepSubscriptionPlan';
import CompanySignupStepAdditionalPlugins from 'components/forms/auth/CompanySignupStepAdditionalPlugins';
import CompanySignupStepCompanyData from 'components/forms/auth/CompanySignupStepCompanyData';
import CompanySignupStepPersonalData from 'components/forms/auth/CompanySignupStepPersonalData';

import { formStatusDispatchHelper } from 'actions/FormStatusActions';

import Loader from 'components/layout/Loader';

import Data from 'components/Data';
import Entity from 'components/Entity';

import { entityMetaMapping } from 'js/constants';

import FormErrors from 'components/forms/FormErrors';
import WizardButtons from 'components/forms/WizardButtons';

import EntityStateUpdater from 'component/EntityStateUpdater';

import { entityPasswordRepeat, injectValidator } from 'formValidation/entityValidators';

class CompanySignupWizard extends EntityStateUpdater {
  constructor(props) {
    super(props);
    this.state = {
      entityLoaded: false,
      entityChanged: false,
      canValidate: {},
    };

    this.entity = new Entity();
    this.entity.setData({ country: 'DE', gender: 'male', salutation: 'mr' });
    this.entity.addValidator(injectValidator(entityPasswordRepeat, props.intl));

    if (
      props.meta[entityMetaMapping.chedriCompanySignupForm] !== undefined &&
      !props.meta[entityMetaMapping.chedriCompanySignupForm].pending
    ) {
      this.entity.setMeta(props.meta, entityMetaMapping.chedriCompanySignupForm);
    }

    if (this.entity.getDataLoaded() && this.entity.getMetaLoaded()) {
      this.state = {
        ...this.state,
        entityLoaded: true,
        entity: this.entity,
      };
    }

    this.dataRef = React.createRef();
  }

  changeStep = (wizard, targetStep = null) => {
    const { step: currentStep, steps } = wizard;

    if (!steps.length) {
      return;
    }

    if (currentStep && targetStep) {
      if (steps.indexOf(targetStep) < steps.indexOf(currentStep)) {
        wizard.push(targetStep.id);
        // window.scrollTo(0, 0);
        scroller.scrollTo('gradient');
        return;
      }
    }

    const stepValidations = [
      { name: 'subscription_plan', fields: ['plugins'] },
      {
        name: 'company_data',
        fields: [
          'company',
          'phone',
          'companyEmail',
          'street',
          'houseNumber',
          'apartmentNumber',
          'addressLine2',
          'zipCode',
          'city',
          'country',
        ],
      },
      {
        name: 'personal_data',
        fields: ['salutation', 'firstName', 'lastName', 'mobile', 'email', 'password', 'repeatPassword', 'testDomain'],
      },
    ];
    let isValid = true;

    this.setState(
      prevState => ({ entity: prevState.entity.validate() }),
      () => {
        const {
          doNotify,
          intl: { messages },
        } = this.props;
        const { entity, canValidate } = this.state;

        const step = stepValidations.filter(step => step.name === wizard.step.id);

        if (step.length === 1) {
          for (let i = 0; i < entity.errors.length; i++) {
            if (step[0].fields.includes(entity.errors[i].fieldName)) {
              isValid = false;
              break;
            }
          }
        }

        if (isValid) {
          wizard.push(targetStep.id);
          // window.scrollTo(0, 0);
          scroller.scrollTo('gradient');
        } else {
          doNotify(0, messages.form_contains_errors);
        }
        this.setState({
          entity: entity.validate(),
          canValidate: { ...canValidate, [currentStep.id]: true },
        });
      }
    );
  };

  formSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    const {
      doNotify,
      intl: { messages },
      onSubmit,
      appIntl,
      whiteLabelSubscriptionPlanList,
    } = this.props;

    this.setState(
      prevState => ({ entity: prevState.entity.validate() }),
      () => {
        const { entity } = this.state;

        if (entity.isValid) {
          const data = _omit(entity.processedData, ['projectLang']);

          // are there plugins included in the subscription plan to add?
          const subscriptionIncludes = whiteLabelSubscriptionPlanList.list.filter(
            subscriptionData => subscriptionData.id === data.plugins[0]
          )[0].includes;

          for (let i = 0; i < subscriptionIncludes.length; i++) {
            data.plugins.push(subscriptionIncludes[i].plugin.id);
          }

          onSubmit(appIntl.urlPrefix, data);
        } else {
          doNotify(0, messages.form_contains_errors);
        }
      }
    );
  };

  componentDidMount = () => {
    const { appIntl, history, user } = this.props;

    if (user.logged) {
      history.push(appIntl.urlPrefix);
    }
  };

  componentDidUpdate = prevProps => {
    const { meta, formStatus } = this.props;

    if (
      meta[entityMetaMapping.chedriCompanySignupForm] !== undefined &&
      !meta[entityMetaMapping.chedriCompanySignupForm].pending &&
      !this.entity.getMetaLoaded()
    ) {
      this.entity.setMeta(meta, entityMetaMapping.chedriCompanySignupForm);
      if (this.entity.getDataLoaded()) {
        this.setState({ entityLoaded: true, entity: this.entity });
      }
    }

    if (!formStatus.pending && prevProps.formStatus.pending && !formStatus.hasError) {
      // registration was successfull, redirect the user to a success page
      const { appIntl, history } = this.props;
      history.push(appIntl.urlPrefix + 'companySignupComplete');
    }
  };

  render() {
    const {
      intl: { messages },
      formStatus,
    } = this.props;
    const { entityLoaded, entityChanged, entity, canValidate } = this.state;

    return (
      <div class="m-b-10">
        <Data dataName="chedriCompanySignupForm" /> {/* fetch meta data */}
        <div class="m-b-30">
          <FormErrors formStatus={formStatus} />
        </div>
        {entityLoaded ? (
          <Wizard onNext={this.changeStep}>
            <Element name="gradient">
              <div class="text-white m-b-25 text-center bg-gradient-primary">
                <WizardNav onChange={this.changeStep} />
              </div>
            </Element>
            <Grid>
              <Row>
                <Col xs={12}>
                  <div class="clearfix full-height">
                    <Steps>
                      <Step id="subscription_plan" name={messages.company_singup_wizard_step_subscription_plan}>
                        <CompanySignupStepSubscriptionPlan
                          entityLoaded={entityLoaded}
                          entity={entity}
                          entityChanged={entityChanged}
                          disabled={formStatus.pending}
                          changeEntity={this.changeEntity}
                          doValidate={canValidate['subscription_plan']}
                          changeStep={this.changeStep}
                        />
                      </Step>
                      <Step id="plugins" name={messages.company_singup_wizard_step_additional_plugins}>
                        <CompanySignupStepAdditionalPlugins
                          entityLoaded={entityLoaded}
                          entity={entity}
                          entityChanged={entityChanged}
                          disabled={formStatus.pending}
                          changeEntity={this.changeEntity}
                          doValidate={canValidate['plugins']}
                        />
                      </Step>
                      <Step id="company_data" name={messages.company_singup_wizard_step_company_data}>
                        <Col lg={8} lgOffset={2}>
                          <CompanySignupStepCompanyData
                            entityLoaded={entityLoaded}
                            entity={entity}
                            entityChanged={entityChanged}
                            disabled={formStatus.pending}
                            changeEntity={this.changeEntity}
                            doValidate={canValidate['company_data']}
                          />
                        </Col>
                      </Step>
                      <Step id="personal_data" name={messages.company_singup_wizard_step_personal_data}>
                        <Col lg={8} lgOffset={2}>
                          <CompanySignupStepPersonalData
                            entityLoaded={entityLoaded}
                            entity={entity}
                            entityChanged={entityChanged}
                            disabled={formStatus.pending}
                            changeEntity={this.changeEntity}
                            doValidate={canValidate['personal_data']}
                          />
                        </Col>
                      </Step>
                    </Steps>
                  </div>
                  <WizardButtons
                    onChange={this.changeStep}
                    onSubmit={this.formSubmit}
                    submitText={messages.signup}
                    lastSeptIcon="fas fa-sign-in-alt"
                    notShownInSteps={['subscription_plan']}
                  />
                </Col>
              </Row>
            </Grid>
          </Wizard>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    meta: state.entityMeta,
    user: state.login.user,
    formStatus: state.formStatus.companySignup,
    whiteLabelSubscriptionPlanList: state.list.whiteLabelSubscriptionPlan.data,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (urlPrefix, args) => {
      formStatusDispatchHelper('companySignup', urlPrefix + 'login/chedriRegister', args, 'post', dispatch);
    },
    doNotify: (code, text) => {
      dispatch(addNotification(code, text));
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CompanySignupWizard)
  )
);
