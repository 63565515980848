import React from 'react';
import { connect } from 'react-redux';
import { Badge } from 'react-bootstrap';
import { components } from 'react-select';
import _, { forEach as _forEach } from 'lodash';

import { getNameInCurrentLanguage } from 'helpers/intl';

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    categoriesSettings: state.templateSettings.categoriesSettings,
  };
};

class CategoryOptionWithProps extends React.Component {
  render() {
    const { data, appIntl, categoriesSettings } = this.props;
    let categoryName = "";
    if(typeof data.path !== undefined && typeof data.path === 'string'){
      let path = data.path.split(",");
      let cleanedPath = [];
      _forEach(path, (singlePath, i) => {
        if (singlePath !== "" && singlePath !== "0") {
          cleanedPath.push(singlePath);
        }
      });
      _forEach(cleanedPath, (singleCleanedPath, i) => {
        if(cleanedPath.length-1 > i){
          const index = _.findIndex(categoriesSettings?.list, category => category.id === singleCleanedPath);
          if (index !== -1) {
            categoryName = categoryName + getNameInCurrentLanguage(categoriesSettings?.list[index].name, appIntl) + " > "
          }
        }
      });
    }

    return (
      <components.Option {...this.props}>
        {!!data.id && <Badge className="m-r-10 text-uppercase p-l-10 p-r-10">{appIntl.displayIntlForm}</Badge>}
        <span className="categoryPathName">{categoryName}</span><p>{getNameInCurrentLanguage(data.name, appIntl)}</p>
      </components.Option>
    );
  }
}
const CategoryOption = connect(mapStateToProps)(CategoryOptionWithProps);

class CategoryValueWithProps extends React.Component {
  render() {
    const { data, appIntl } = this.props;

    return (
      <div className="Select-value">
        {!!data.id && <Badge className="m-r-10 text-uppercase p-l-10 p-r-10">{appIntl.displayIntlForm}</Badge>}
        {getNameInCurrentLanguage(data.name, appIntl)}
      </div>
    );
  }
}
const CategoryValue = connect(mapStateToProps)(CategoryValueWithProps);

const categoryOptionFormatter = options => {
  return options.list.map(option => {
    return categorySingleOptionFormatter(option);
  });
};

const categorySingleOptionFormatter = (option = null) => {
  if (!option) {
    return option;
  }
  let label = '';
  _forEach(option.name, langName => {
    if (langName.name !== undefined) {
      label += langName.name + ' ';
    }
  });
  return { ...option, value: option.id, label };
};

const categoryOptionFromEntity = category => {
  return !category
    ? null
    : categorySingleOptionFormatter({
        id: category.getId(),
        name: category.getName().map(langName => {
          return { lang: langName.getLang(), name: langName.getName() };
        }),
      });
};

export {
  CategoryValue,
  CategoryOption,
  categoryOptionFormatter,
  categorySingleOptionFormatter,
  categoryOptionFromEntity,
};
