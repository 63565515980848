import React from 'react';
import PropTypes from 'prop-types';

import RoundImage from 'components/layout/RoundImage';
import Icon from './Icon';

function letter(input, index = 0, defaultValue = 'N') {
  if (typeof input === 'string' && input.length > index) {
    return input[index].toLocaleUpperCase();
  }
  return defaultValue;
}

function Avatar({ firstName, lastName, textColor, color, size, thumbLink, isAnonymous, className }) {
  if (thumbLink) {
    return (
      <RoundImage
        size={size != null ? size : 100}
        usePixels={size != null}
        url={thumbLink}
        className={`${className}`}
      />
    );
  }
  return (
    <RoundImage size={size != null ? size : 100} usePixels={size != null} hexColor={color} className={`${className}`}>
      {isAnonymous ? (
        <Icon name="fa-user-tie" duotone iconStyles={{ fontSize: size != null ? '1.5vw' : '2.4vw' }} />
      ) : (
        <h6 style={{ color: textColor, fontSize: size != null ? '1vw' : '1.5vw' }} className="bold">
          {`${letter(firstName)}${letter(lastName, 0, letter(firstName, 1).toLocaleLowerCase())}`}
        </h6>
      )}
    </RoundImage>
  );
}
Avatar.defaultProps = {
  size: undefined,
  thumbLink: null,
  isAnonymous: false,
};
Avatar.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.number,
  thumbLink: PropTypes.string,
  isAnonymous: PropTypes.bool,
};

export default Avatar;
