export const defaultColors = [
  { r: 75, g: 101, b: 192 }, // 4b65c0
  { r: 96, g: 163, b: 244 }, // 60a3f4
  { r: 73, g: 155, b: 147 }, // 499b93
  { r: 153, g: 196, b: 85 }, // 99c455
  { r: 237, g: 167, b: 59 }, // eda73b
  { r: 222, g: 72, b: 86 }, // de4856
];

export const rgbToHex = index => {
  let hex = Number(Math.round(index)).toString(16);
  if (hex.length < 2) {
    hex = '0' + hex;
  }
  return hex;
};

export const fullColorHex = (r, g, b) => {
  return rgbToHex(r) + rgbToHex(g) + rgbToHex(b);
};

export const getColor = (value, maxValue, useColors = null) => {
  const colors = useColors || defaultColors;

  if (!maxValue || value > maxValue) {
    return colors[0];
  }

  const indexBase = (value / maxValue) * (colors.length - 1);

  const prevIndex = Math.floor(indexBase);
  const nextIndex = Math.ceil(indexBase);

  const increaseFactor = indexBase - prevIndex;

  const color = {
    r: colors[prevIndex].r + (colors[nextIndex].r - colors[prevIndex].r) * increaseFactor,
    g: colors[prevIndex].g + (colors[nextIndex].g - colors[prevIndex].g) * increaseFactor,
    b: colors[prevIndex].b + (colors[nextIndex].b - colors[prevIndex].b) * increaseFactor,
  };

  return fullColorHex(color.r, color.g, color.b);
};

export const getColorForString = string => {
  let output = 0;
  for (let i = 0, strlen = string.length; i < strlen; i++) {
    output += string[i].charCodeAt(0);
  }

  return getColor(output % 20, 20);
};
