import React from 'react';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

export default class CmsElementGalleryChild extends AbstractCmsElement {
  render() {
    const imageUrl = this.getParamValue('imageUrl', null);

    let colClass = ' col-xs-9 col-md-6 col-lg-4'; // medium
    switch (this.getParamValue('size', null)) {
      case 'xs':
        colClass = ' col-xs-3 col-lg-2';
        break;

      case 'sm':
        colClass = ' col-xs-6 col-md-4 col-lg-3';
        break;

      case 'lg':
        colClass = ' col-xs-12 col-md-9 col-lg-6';
        break;
    }

    return imageUrl ? (
      <div id={this.getMainId()} class={'ms-gallery-child col ' + colClass}>
        {this.getElementStyle()}

        <img src={imageUrl} class="img-responsive" />
      </div>
    ) : (
      <div class="col padding-20 text-center">
        <i class="fa fa-image fa-4x" />
      </div>
    );
  }
}
