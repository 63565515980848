import React from 'react';
import { Col, Clearfix } from 'react-bootstrap';

import SummaryItemGridView from './SummaryItemGridView';
import SummaryItemListView from './SummaryItemListView';

export default class SummaryItems extends React.Component {
  render() {
    const { dummiesCount, view, lg, md, xs, hideBrand, hidePerks, hideEquipment, hideFooter } = this.props;

    const res = [];
    for (let i = 0; i < dummiesCount; i++) {
      if (view === 'list') {
        res.push(
          <SummaryItemListView
            key={i}
            hideBrand={hideBrand}
            hidePerks={hidePerks}
            hideEquipment={hideEquipment}
            hideFooter={hideFooter}
          />
        );
      } else if (view === 'grid') {
        res.push(
          <Col lg={lg} md={md} xs={xs} key={i}>
            <SummaryItemGridView
              hideBrand={hideBrand}
              hidePerks={hidePerks}
              hideEquipment={hideEquipment}
              hideFooter={hideFooter}
            />
          </Col>
        );

        if (i && (i + 1) % 3 === 0) {
          res.push(
            <Clearfix
              visibleLgBlock={lg === 4}
              visibleMdBlock={md === 4}
              visibleSmBlock
              visibleXsBlock
              key={'c3' + i}
            />
          );
        }

        if (i && (i + 1) % 2 === 0) {
          res.push(
            <Clearfix
              visibleLgBlock={lg === 6}
              visibleMdBlock={md === 6}
              visibleSmBlock
              visibleXsBlock
              key={'c2' + i}
            />
          );
        }
      }
    }

    return res;
  }
}
SummaryItems.defaultProps = {
  dummiesCount: 12,
  view: 'grid', // grid or list
  lg: 4,
  md: 6,
  xs: 12,
  hideBrand: false,
  hidePerks: false,
  hideEquipment: false,
  hideFooter: true,
};
