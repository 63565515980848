import React from 'react';
// import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import { includes as _includes } from 'lodash';

class Tabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: 0,
    };
  }

  componentDidUpdate = () => {
    const { selectedTab } = this.props;
    const { selected } = this.state;

    if (selectedTab !== null && selected !== selectedTab) {
      this.setState({ selected: selectedTab });
    }
  };

  changeTab = (e, tabIndex) => {
    e.preventDefault();

    const { disabled, onTabChange } = this.props;

    if (disabled.indexOf(tabIndex) === -1) {
      this.setState({ selected: tabIndex });

      if (onTabChange) {
        onTabChange(tabIndex);
      }
    }
  };

  changeTabSelect = option => {
    this.setState({ selected: option.value });
  };

  shouldMount = index => {
    const { keepMounted } = this.props;
    const { selected } = this.state;

    if (index === selected) {
      return true;
    }

    if (keepMounted) {
      return _includes(keepMounted, index);
    }
  };

  getErrorsCount = index => {
    return null; // temporary
    /* if (this.refs['tabContent' + index] !== undefined) {
      const errorsCount = ReactDOM.findDOMNode(this.refs['tabContent' + index]).getElementsByClassName('has-error')
        .length;
      if (errorsCount) {
        return <span className="badge badge-important">{errorsCount}</span>;
      }
    } */
  };

  getTabClass = () => {
    const { tabStyle } = this.props;
    switch (tabStyle) {
      case 'arrow':
        return 'nav-tabs-linetriangle nav-tabs-separator bg-master-lightest ';

      case 'simple':
        return 'nav-tabs-simple ';

      default:
        return 'nav-tabs-fillup ';
    }
  };

  getTabResponsiveClass = () => {
    const { tabResponsiveStyle } = this.props;
    switch (tabResponsiveStyle) {
      case 'stack':
        return 'nav-stack-sm ';

      default:
        return 'hidden-sm hidden-xs ';
    }
  };

  render() {
    const { tabs, disabled, tabContent, className } = this.props;
    const { selected } = this.state;

    return (
      <div className={className}>
        <ul
          className={
            'nav nav-tabs ' +
            this.getTabClass() +
            this.getTabResponsiveClass() +
            (!this.props.tabHeaderFluid ? ' ' : ' ')
          }
        >
          {tabs.map((tab, i) => {
            return (
              <li className={(i === selected ? 'active ' : '') + (disabled.indexOf(i) > -1 ? 'disabled ' : '')} key={i}>
                <a href={'#' + tab} onClick={e => this.changeTab(e, i)}>
                  <span>
                    {tab} {this.getErrorsCount(i)}
                  </span>
                </a>
              </li>
            );
          })}
        </ul>

        {this.props.tabResponsiveStyle === 'selectBox' && (
          <div className="nav-tab-dropdown cs-wrapper full-width p-t-10 visible-xs visible-sm">
            <Select
              name="tab"
              value={selected}
              options={tabs.map((tab, i) => {
                return { value: i, label: tab };
              })}
              onChange={this.changeTabSelect}
              searchable={false}
              clearable={false}
            />
          </div>
        )}

        <div className="tab-content no-padding" style={{ overflow: 'visible' }}>
          {tabContent.map((content, i) => {
            return this.shouldMount(i) ? (
              <div ref={'tabContent' + i} className={'tab-pane ' + (i === selected ? 'active' : '')} key={i}>
                <div className="row column-seperation no-margin">
                  {i === selected ? React.cloneElement(content, { visible: 'visible' }) : content}
                </div>
              </div>
            ) : null;
          })}
        </div>
      </div>
    );
  }
}
Tabs.defaultProps = {
  tabs: [],
  tabContent: [],
  tabHeaderFluid: true,
  tabStyle: 'fillUp',
  tabResponsiveStyle: 'selectBox',
  selectedTab: null,
  disabled: [],
  className: 'panel panel-transparent',
};

const mapStateToProps = state => {
  return {
    layout: state.layout,
  };
};

export default connect(mapStateToProps)(Tabs);
