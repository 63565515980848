import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Grid, Row, Col, Button } from 'react-bootstrap';

import { getPassengerCarSearchPath, getPassengerCarSearchResultPath } from 'helpers/intl';

class SummaryNoResults extends React.Component {
  render() {
    const {
      intl: { messages },
      type,
    } = this.props;

    let title = messages.we_did_not_find_any_results_for_your_search;
    let possibleActions = null;

    switch (type) {
      case 'rental': {
        title = messages.we_did_not_find_any_cars_at_this_location_for_the_selected_period;
        break;
      }

      case 'cars': {
        const allCarsLink = getPassengerCarSearchResultPath();
        const carSearchLink = getPassengerCarSearchPath();

        title = messages.we_did_not_find_any_cars_for_your_seaech;
        possibleActions = (
          <Grid fluid>
            <Row>
              <Col xs={6} sm={4} smOffset={2} md={3} mdOffset={3}>
                <Link to={allCarsLink}>
                  <Button bsStyle="primary" block>
                    {messages.all_cars}
                  </Button>
                </Link>
              </Col>
              <Col xs={6} sm={4} md={3}>
                <Link to={carSearchLink}>
                  <Button bsStyle="primary" block>
                    {messages.search_for_cars}
                  </Button>
                </Link>
              </Col>
            </Row>
          </Grid>
        );
        break;
      }

      default: {
        break;
      }
    }

    return (
      <div class="padding-30 sm-padding-15">
        <h1 class="text-center m-b-30">{title}</h1>

        {possibleActions}
      </div>
    );
  }
}

export default injectIntl(SummaryNoResults);
