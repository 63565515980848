import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DropTarget } from 'react-dnd';
import { CancelToken } from 'axios';

import {
  uploadFileStart,
  uploadFile,
  uploadProgress,
  uploadFinished,
  uploadError,
  uploadRetryCount,
  uploadNetworkError,
  wholeUploadFinished,
  filesUploadHelper,
} from 'actions/UploadActions';

const fileTarget = {
  drop(props, monitor) {
    props.onDrop(monitor.getItem().files, props.upload.context.uploadLink, props.upload.context.data);
  },
};

class Upload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      retryTimeoutPending: null,
    };

    this.isUploading = false;
    this.uploadingFile = null;
  }

  componentWillUpdate = (nextProps, nextState) => {
    if (this.isUploading && nextProps.upload && nextProps.upload.files) {
      for (let i = 0; i < nextProps.upload.files.length; i++) {
        const file = nextProps.upload.files[i];
        if (file.file === this.uploadingFile && (file.isUploaded || file.networkError || file.error != 0)) {
          this.isUploading = false;
          this.uploadingFile = null;

          break;
        }
      }
    }

    if (
      !this.uploadingFile &&
      !nextState.retryTimeoutPending &&
      nextProps.webApp.isOnline &&
      nextProps.upload &&
      nextProps.upload.files
    ) {
      for (let i = 0; i < nextProps.upload.files.length; i++) {
        const file = nextProps.upload.files[i];
        if (!file.isUploading && !file.isUploaded && file.error === 0) {
          if (file.networkError) {
            // if there was a network error, wait a few seconds and restart the upload
            this.setState({
              retryTimeoutPending: setTimeout(() => {
                this.setState({ retryTimeoutPending: null }, () => {
                  this.props.startUpload(file.file, file.uploadLink, file.args, file.retryCount);
                });
              }, file.retryCount * file.retryCount * 1000),
            });
          } else {
            this.isUploading = true;
            this.uploadingFile = file.file;
            this.props.startUpload(file.file, file.uploadLink, file.args);
          }

          break;
        }
      }
    }

    if (!this.isUploading) {
      // this.props.onUploadFinished()
    }
  };

  render() {
    const { connectDropTarget, isOver, canDrop, user } = this.props;

    const style =
      canDrop && user.logged
        ? {
            position: 'absolute',
            top: (document.body.scrollTop || document.documentElement.scrollTop) + 'px',
            left: 0,
            height: '100%',
            width: '100%',
            background: 'rgba(13,24,35,0.95)',
            zIndex: 9999,
            color: '#fff',
            textAlign: 'center',
          }
        : {
            display: 'none',
          };

    const contentWhenLoading = canDrop ? (
      <div>
        <i class="fa fa-cloud-upload" style={{ fontSize: '30em', marginTop: '30px', lineHeight: 1 }} />
        <h1>Drop your files here</h1>
      </div>
    ) : (
      ''
    );

    return connectDropTarget(<div style={style}>{contentWhenLoading}</div>);
  }
}

const mapStateToProps = state => {
  return {
    webApp: state.webApp,
    user: state.login.user,
    upload: state.upload,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onDrop: filesUploadHelper(dispatch),
    startUpload: (file, uploadLink, args, retryAttempts = 0) => {
      const retryAttemptsMax = 10;

      const source = new CancelToken.source();

      // progress event will dispatch an event
      const progressCallback = progressEvent => {
        return dispatch(uploadProgress(progressEvent, file));
      };
      // finished callback will trigger an event
      const finishedCallback = response => {
        return dispatch(uploadFinished(file, response));
      };
      // error callback will trigger an event
      const errorCallback = error => {
        if ((!error.response || !error.response.status) && retryAttempts < retryAttemptsMax) {
          dispatch(uploadRetryCount(file, retryAttempts + 1)); // mark the file with the information about network error
        } else {
          return dispatch(uploadError(file, error));
        }
      };
      // mark that we start an upload
      dispatch(uploadFileStart(file, retryAttempts));
      // dispatch the file upload
      dispatch(uploadFile(uploadLink, file, args, progressCallback, finishedCallback, errorCallback, source.token));

      return source;
    },
    onUploadFinished: () => {
      dispatch(wholeUploadFinished());
    },
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    DropTarget(NativeTypes.FILE, fileTarget, (connect, monitor) => ({
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }))(Upload)
  )
);
