export const ACCOUNT_SEARCH_SHOW = 'ACCOUNT_SEARCH_SHOW';
export const ACCOUNT_SEARCH_HIDE = 'ACCOUNT_SEARCH_HIDE';
export const ACCOUNT_SEARCH_VALUE = 'ACCOUNT_SEARCH_VALUE';

export function showAccountSearch() {
  return {
    type: ACCOUNT_SEARCH_SHOW,
    payload: null,
  };
}

export function hideAccountSearch() {
  return {
    type: ACCOUNT_SEARCH_HIDE,
    payload: null,
  };
}

export function changeAccountSearchValue(value) {
  return {
    type: ACCOUNT_SEARCH_VALUE,
    payload: value,
  };
}
