import React from 'react';
import { findDOMNode } from 'react-dom';
import { Button, Clearfix } from 'react-bootstrap';
import { DragSource, DropTarget } from 'react-dnd';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { find as _find } from 'lodash';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';
import SimplePrompt from 'components/layout/SimplePrompt';

const rowSource = {
  beginDrag(props) {
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index.toString();
    const hoverIndex = props.index.toString();

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // compare if it is the same container
    let dragContainer = dragIndex.substring(0, dragIndex.lastIndexOf('.'));
    dragContainer = dragContainer !== '' ? dragContainer + '.' : '';
    let hoverContainer = hoverIndex.substring(0, hoverIndex.lastIndexOf('.'));
    hoverContainer = hoverContainer !== '' ? hoverContainer + '.' : '';

    let direction,
      newIndex,
      sameContainer = dragContainer === hoverContainer;
    const dragContainerIndex = dragIndex.substring(dragIndex.lastIndexOf('.')).replace('.', '');
    const hoverContainerIndex = hoverIndex.substring(hoverIndex.lastIndexOf('.')).replace('.', '');

    // drop after or before?
    if (hoverClientY < hoverMiddleY) {
      direction = 'before';
    } else {
      direction = 'after';
    }

    // calculate new index
    if ((sameContainer && dragContainerIndex > hoverContainerIndex) || !sameContainer) {
      if (direction === 'after') {
        newIndex = hoverContainer + (parseInt(hoverContainerIndex) + 1);
      } else {
        newIndex = hoverContainer + hoverContainerIndex;
      }
    } else {
      if (direction === 'after') {
        newIndex = hoverContainer + hoverContainerIndex;
      } else {
        newIndex = hoverContainer + (parseInt(hoverContainerIndex) - 1 < 0 ? 0 : parseInt(hoverContainerIndex) - 1);
      }
    }

    if (newIndex !== dragIndex) {
      props.reorderElements(dragIndex, hoverIndex, direction, sameContainer);
      monitor.getItem().index = newIndex;
    }
  },
};

@DropTarget('row', rowTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('row', rowSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
// editable
export default class CmsElementRow extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      mouseOver: false,
      shown: false,
      rowMenuMarginLeft: 0,
    };

    this.mouseMoveBlock = false;
    this.containerRef = React.createRef();
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { mouseOver } = this.state;

    const img = new Image();
    img.onload = () => this.props.connectDragPreview(img);
    img.src =
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAAAUCAYAAADIpHLKAAAAoklEQVRoge3boQ0CQRRF0buTDX63I/oAicWBo4GlAhIcZdDTIrGICYrw7Iy4p4JnbvLNH7bP2wa4AjtgRtILeACnEViAY9s9UlcmahPvAuwbj5F6dSh4Vkn/zKX1AqlnBiIFBiIFBiIFBiIFBiIFBiIFBiIFBiIFBiIFBiIFBiIFBiIFBVhbj5A6tRbq55SkX/cROAMD9XFqartH6sL35fbyAWO/DirzPXTvAAAAAElFTkSuQmCC';

    if (mouseOver && !prevState.mouseOver) {
      this.calculateRowMenuMarginLeft();
    }
    if (!mouseOver && prevState.mouseOver) {
      this.setState({ rowMenuMarginLeft: 0 });
    }

    this.__componentDidUpdate(prevProps, prevState);
  };

  getColParam = (col, name) => {
    return _find(col.props.params, ['name', name]);
  };

  getColParamValue = (col, name, defaultValue = null) => {
    const param = this.getColParam(col, name);
    if (param && param.value) {
      return param.value;
    } else {
      return defaultValue;
    }
  };

  getColDef = col => {
    const { editMode } = this.props;

    const xs = parseInt(this.getColParamValue(col, 'xs', 12), 10);
    const sm = parseInt(this.getColParamValue(col, 'sm', xs), 10);
    const md = parseInt(this.getColParamValue(col, 'md', sm), 10);
    const lg = parseInt(this.getColParamValue(col, 'lg', md), 10);

    const hiddenPhone = !editMode && this.getColParamValue(col, 'hiddenPhone', 'no') === 'yes';
    const hiddenTablet = !editMode && this.getColParamValue(col, 'hiddenTablet', 'no') === 'yes';
    const hiddenDesktop = !editMode && this.getColParamValue(col, 'hiddenDesktop', 'no') === 'yes';

    return {
      xs: hiddenPhone ? 0 : xs,
      sm: hiddenTablet ? 0 : sm,
      md: hiddenTablet ? 0 : md,
      lg: hiddenDesktop ? 0 : lg,
    };
  };

  mouseEnter = () => {
    const { editMode } = this.props;
    if (editMode) {
      this.setState({ mouseOver: true });
    }
  };

  mouseLeave = () => {
    const { editMode } = this.props;
    if (editMode) {
      this.setState({ mouseOver: false });
    }
  };

  mouseMove = () => {
    if (!this.state.mouseOver && !this.mouseMoveBlock) {
      this.setState({ mouseOver: true }, () => (this.mouseMoveBlock = false));
    }
  };

  showSettings = e => {
    const { index, showElementSettings } = this.props;
    showElementSettings(e, index);
  };

  removeElement = () => {
    const { index, removeElement } = this.props;
    removeElement(index);
  };

  calculateRowMenuMarginLeft = () => {
    const { editMode, scale } = this.props;
    const { mouseOver } = this.state;

    // get the information about the grid mouse over
    let rowMenuMarginLeft = 0;
    if (editMode && mouseOver && this.containerRef && this.containerRef.current) {
      const rowMenu = this.containerRef.current.querySelector('.ms-row-menu');

      if (rowMenu) {
        const rowMenuRect = rowMenu.getBoundingClientRect();

        let gridContainer = this.containerRef.current;
        do {
          gridContainer = gridContainer.parentElement;
          if (gridContainer.classList.contains('ms-grid')) {
            break;
          }
        } while (gridContainer.parentElement !== null);

        if (gridContainer) {
          const gridMenu = gridContainer.querySelector('.ms-grid-menu');

          if (gridMenu) {
            const gridMenuRect = gridMenu.getBoundingClientRect();

            if (
              gridMenuRect.left + gridMenuRect.width > rowMenuRect.left &&
              gridMenuRect.top + gridMenuRect.height > rowMenuRect.top
            ) {
              rowMenuMarginLeft = gridMenuRect.width - (rowMenuRect.left - gridMenuRect.left) + 10;
            }
          }
        }
      }
    }

    rowMenuMarginLeft *= 1 / scale;
    this.setState({ rowMenuMarginLeft });
  };

  doNothing = e => {
    e.preventDefault();
  };

  render() {
    const { isDragging, editMode, layout, connectDragSource, connectDropTarget, scale } = this.props;
    const { mouseOver, shown, rowMenuMarginLeft } = this.state;

    let equalHeightColClass = ' ';
    if (layout.breakpointIndex == 0 && this.getParamValue('colSameHeightPhone', 'no') === 'yes') {
      equalHeightColClass = 'row-eq-height ';
    } else if (layout.breakpointIndex <= 2 && this.getParamValue('colSameHeightTablet', 'no') === 'yes') {
      equalHeightColClass = 'row-eq-height ';
    } else if (layout.breakpointIndex == 3 && this.getParamValue('colSameHeightDesktop', 'no') === 'yes') {
      equalHeightColClass = 'row-eq-height ';
    }

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';

    const parallaxData = this.getParallaxData();

    let colCounter = 0;
    const innerContent = (
      <div
        ref={this.containerRef}
        id={this.getMainId()}
        className={
          shownClass +
          equalHeightColClass +
          this.getResponsiveClasses() +
          (this.getParamValue('isCondensed', 'no') === 'yes' ? ' condensed ' : '') +
          this.getCssClasses() +
          ' ms-row row ' +
          this.getPaddingClasses(15) +
          (isDragging ? 'ms-row-dragging ' : '')
        }
        style={{
          ...this.getBackgroundStyle(),
        }}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
        onDragLeave={this.mouseLeave}
        onMouseMove={this.mouseMove}
      >
        {this.getElementStyle()}

        {/* show outline of the row */}
        {mouseOver && editMode && (
          <>
            <span className="ms-row-outline ms-row-outline-top" />
            <span className="ms-row-outline ms-row-outline-right" />
            <span className="ms-row-outline ms-row-outline-bottom" />
            <span className="ms-row-outline ms-row-outline-left" />
            <div
              className="ms-row-menu"
              style={{
                transform: `scale(${1 / scale})`,
                transformOrigin: 'top left',
                marginLeft: rowMenuMarginLeft + 'px',
              }}
            >
              {connectDragSource(
                <a className="btn btn-default cursor-move" href="#moveToReorder" onClick={this.doNothing}>
                  <i className="fal fa-arrows" />
                </a>
              )}
              <Button onClick={this.showSettings}>
                <i className="fal fa-cog" />
              </Button>
              <SimplePrompt
                onSuccess={this.removeElement}
                placement="bottom"
                bsStyle="default"
                showLabel={false}
                className=" "
              />
            </div>
          </>
        )}

        {this.props.children &&
          this.props.children.map((col, i) => {
            const colDef = this.getColDef(col);

            let res = [];
            res.push(React.cloneElement(col, { key: i }));

            colCounter += colDef[layout.breakpoint];
            if (colCounter % 12 === 0) {
              res.push(<Clearfix key={'c' + i} />);
            }

            return res;
          })}

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      <div>
        <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
          {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
        </ScrollTrigger>
      </div>
    );
  }
}
