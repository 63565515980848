import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

IconRadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  title: PropTypes.string,
  icon: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

IconRadioButton.defaultProps = {
  icon: undefined,
  variant: undefined,
  value: 'on',
  checked: false,
  onChange: () => undefined,
  title: null,
};

let i = 0;

function IconRadioButton({ name, icon, variant, label, title, value, checked, onChange, className }) {
  i++;
  const id = `${name}${i}_${value}_radio`;

  return (
    <div className={`icon-radio${variant ? ' ' + variant : ''}`}>
      {icon ? <Icon name={icon} /> : null}
      <label className={`icon-radio-label ${className || ''}`} for={id}>
        <div title={title || label}>
          <div>{label}</div>
        </div>
        <div>
          <input id={id} type="radio" name={name + i} value={value} checked={checked} onChange={onChange} />
        </div>
      </label>
    </div>
  );
}

export default IconRadioButton;
