import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Clearfix } from 'react-bootstrap';
import AnimateHeight from 'react-animate-height';
import { concat as _concat, pullAll as _pullAll, forEach as _forEach } from 'lodash';

import { parseQs, stringifyQs } from 'helpers/http';

import SummaryFilterValue from 'components/layout/summary/SummaryFilterValue';

class SummaryFiltersBoolean extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mouseOver: false,
      show: false,
    };
  }

  handleClick = () => {
    this.setState(prevProps => ({ show: !prevProps.show }));
  };

  valueClick = (filterName, value, checked) => {
    // new values for this filter:
    let values = this.props.filters[filterName] !== undefined ? this.props.filters[filterName].slice() : [];

    if (checked) {
      values = _concat(values, [value]);
    } else {
      _pullAll(values, [value]);
    }

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: stringifyQs({ ...parseQs(this.props.location.search), [filterName]: values }),
    });
  };

  isValueChecked = (filterName, value) => {
    const stateIndex =
      this.props.filters[filterName] !== undefined ? this.props.filters[filterName].indexOf(value) : -1;
    return stateIndex >= 0;
  };

  hasFilters = () => {
    const { facets, filters } = this.props;

    if (facets) {
      for (let i = 0; i < facets.length; i++) {
        if (filters[facets[i].name] !== undefined) {
          return true;
        }
      }
    }

    return false;
  };

  getFilters = () => {
    const { facets } = this.props;

    let res = [];

    if (facets) {
      _forEach(facets, facet => {
        res.push({
          filterName: facet.name,
          name: facet.name,
          selectValue: 'true',
        });
      });
    }

    return res;
  };

  render() {
    const { intl } = this.props;
    const { mouseOver, show } = this.state;
    const messages = intl.messages;

    return (
      <div
        class={'summary-filter animate-all ' + (mouseOver ? 'hover ' : '') + (show ? 'active ' : '')}
        onMouseEnter={() => this.setState({ mouseOver: true })}
        onMouseLeave={() => this.setState({ mouseOver: false })}
      >
        <div class="summary-filter-title" onClick={this.handleClick}>
          <Clearfix>
            <div class="pull-left">{messages.properties}</div>
            <div class="pull-right">
              {this.hasFilters() && !show ? (
                <i class="fal fa-check text-success" />
              ) : (
                <i class={'fal fa-chevron-' + (show ? 'up' : 'right')} />
              )}
            </div>
          </Clearfix>
        </div>

        <AnimateHeight duration={200} height={show ? 'auto' : 0}>
          <div class="summary-filter-body">
            {this.getFilters().map((filter, i) => {
              return (
                <SummaryFilterValue
                  type="select"
                  value={filter}
                  checked={this.isValueChecked(filter.filterName, filter.selectValue)}
                  onClick={(value, checked) => this.valueClick(filter.filterName, value, checked)}
                  key={i}
                />
              );
            })}
          </div>
        </AnimateHeight>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(SummaryFiltersBoolean)));
