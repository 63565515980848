import React from 'react';
import { injectIntl } from 'react-intl';
import {Overlay, Popover, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';

class FormImageWithPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      mouseOver: false,
    };

    this.inputRef = React.createRef();
  }

  checkFocused = () => {
    return this.state.focused ? 'focused ' : '';
  };

  checkRequired = () => {
    return this.props.meta.validate.rules.indexOf('required') !== -1 ? 'required ' : '';
  };

  checkError = () => {
    return this.props.validation.valid ? '' : 'has-error ';
  };

  // check if user may read this field
  checkAccess = () => {
    return this.props.meta.acl.read;
  };

  // check if user may write to this field
  checkReadOnly = () => {
    return !this.props.meta.acl.write;
  };

  catchFocus = () => {
    // this.setState({ focused: true })
  };

  onChange = files => {
    this.props.onChange(this.props.name, files);
  };

  checkDisabled = () => {
    const { disabled, meta } = this.props;
    if (disabled) {
      return true;
    }
    if (!meta.acl.write) {
      return true;
    }
    return false;
  };

  setValue = value => {};

  mouseEnter = () => {
    this.setState({ mouseOver: true });
  };

  mouseLeave = () => {
    this.setState({ mouseOver: false });
  };

  render() {
    const {
      intl: { messages },
      label,
      name,
      validation,
      url,
      pending,
      disabled,
      infoText,
    } = this.props;
    const { mouseOver } = this.state;

    return (
      <div
        className={
          'form-group form-group-default ' +
          this.checkFocused() +
          this.checkRequired() +
          this.checkError() +
          (this.checkDisabled() ? 'disabled ' : '')
        }
        onClick={this.catchFocus}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
      >
        <label>
          {label} {pending && <i className="fa fa-circle-notch fa-spin" />}
          {!!infoText && (
            <span className="m-l-5">
              <OverlayTrigger placement="top" overlay={<Tooltip>{infoText}</Tooltip>}>
                <i className="far fa-question-circle cursor-help" />
              </OverlayTrigger>
            </span>
          )}
        </label>

        {!!url && (
          <div className="relative" style={{ width: '100%' }}>
            {mouseOver && (
              <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                <Button
                  className="text-danger pull-right"
                  bsSize="xsmall"
                  onClick={() => this.props.onReset(name)}
                  disabled={disabled}
                >
                  <i className="fa fa-times" />
                </Button>
              </div>
            )}

            <div style={{ position: 'absolute', width: '100%', top: '50%' }} className="text-center">
              <div style={{ transform: 'translateY(-50%)' }}>
                <Button
                  onClick={() => this.inputRef.current.click()}
                  className="btn-rounded"
                  bsSize="sm"
                  disabled={disabled}
                >
                  <i className="fa fa-cog m-r-10" />
                  {messages.change_image}
                </Button>
              </div>
            </div>
            <div style={{ background: 'url(/img/checkered.png)' }}>
              <div
                style={{
                  width: '100%',
                  paddingBottom: '56.25%',
                  backgroundImage: 'url(' + url + ')',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            </div>
          </div>
        )}

        <input
          ref={this.inputRef}
          type="file"
          class="form-control"
          name={name}
          onChange={e => this.onChange(e.target.files)}
          onFocus={() => this.setState({ focused: true })}
          onBlur={() => this.setState({ focused: false })}
          disabled={this.checkDisabled() || pending}
          style={{ visibility: url ? 'hidden' : 'visible' }}
        />

        <Overlay show={this.state.focused && !validation.valid} target={this.inputRef.current} placement="top">
          <Popover class="popover-modal">{validation.helpText}</Popover>
        </Overlay>
      </div>
    );
  }
}
FormImageWithPreview.defaultProps = {
  label: '',
  infoText: '',
  meta: { validate: { rules: '' }, acl: { read: true, write: true } },
  validation: { valid: true },
  onChange: () => {},
  // onChange: (name, files) => { console.log(name); console.log(files) },
  disabled: false,
  onReset: () => {},
};

export default injectIntl(FormImageWithPreview);
