import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import { formStatusDispatchHelper } from 'actions/FormStatusActions';
import { addNotification } from 'actions/NotificationsActions';

import FormInput from 'components/forms/FormInput';
import FormErrors from 'components/forms/FormErrors';

import DocMeta from 'components/DocMeta';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
    };

    this.emailRef = React.createRef();
  }

  componentDidUpdate = prevProps => {
    const {
      intl: { messages },
      formStatus,
      doNotify,
    } = this.props;

    if (!formStatus.pending && prevProps.formStatus.pending && !formStatus.hasError) {
      this.emailRef.current.setValue(''); // reset email field

      doNotify(1, messages.email_with_password_reset_instructions_sent);
    }
  };

  onSubmit = e => {
    e.preventDefault();

    const { appIntl, onSubmit } = this.props;
    const { email } = this.state;

    onSubmit({ email }, appIntl.urlPrefix);
  };

  render() {
    const {
      intl: { messages },
      projectConfig,
      appIntl,
      formStatus,
    } = this.props;
    const { email } = this.state;

    const tags = [
      { name: 'title', content: messages.meta.forgot_password.title },
      { name: 'description', content: messages.meta.forgot_password.description },
      { itemProp: 'name', content: messages.meta.forgot_password.name },
      { itemProp: 'description', content: messages.meta.forgot_password.description },
      // {itemProp: 'image', content: "http://www.example.com/image.jpg"}
    ];

    return (
      <DocMeta tags={tags}>
        <div class="content">
          <Grid fluid>
            <Row class="p-t-50 p-b-50">
              <Col xs={12} md={6} lg={4} mdOffset={3} lgOffset={4}>
                <h1>{messages.password_assistance}</h1>

                <Row>
                  <Col xs={12}>
                    <section class="widgit">
                      <header>
                        <h4>
                          <i class="fa fa-lock" /> {messages.get_new_password}
                        </h4>
                      </header>
                      <Row>
                        {projectConfig.data.id === '6234463689d0c7126367c2ce' ? (
                          <Col xs={12}>
                            <p>{messages.forgot_password_infotext_robethood}</p>
                          </Col>
                        ) : (
                          <Col xs={12}>
                            <p>{messages.forgot_password_infotext}</p>
                          </Col>
                        )}
                      </Row>
                    </section>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <section class="widgit">
                      <form onSubmit={this.onSubmit}>
                        <FormErrors formStatus={formStatus} />

                        <div class="panel panel-transparent no-margin">
                          <div class="panel-body p-l-0 p-r-0">
                            <div class="form-group-attached">
                              <FormInput
                                ref={this.emailRef}
                                label={messages.email}
                                name="email"
                                value={email}
                                onChange={(name, value) => this.setState({ [name]: value })}
                                disabled={formStatus.pending}
                              />
                            </div>

                            <div class="text-right m-t-15">
                              <Button bsSize="lg" block disabled={formStatus.pending} type="submit" bsStyle="primary">
                                <i class={'fa-fw ' + (formStatus.pending ? ' fa fa-circle-notch fa-spin' : '')} />{' '}
                                {messages.continue}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </section>
                  </Col>
                </Row>

                <Row>
                  {projectConfig.data.id === '6234463689d0c7126367c2ce' ? (
                    <Col xs={12}>
                      <p>{messages.forgot_password_info_email_not_used_robethood} <Link to={appIntl.urlPrefix + 'contact'}>{messages.contact}</Link></p>
                    </Col>
                  ) : (
                    <Col xs={12}>
                      <p>{messages.forgot_password_info_email_not_used} <Link to={appIntl.urlPrefix + 'contact'}>{messages.contact}</Link></p>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Grid>
        </div>
      </DocMeta>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    formStatus: state.formStatus.forgotPassword,
    projectConfig: state.data.projectConfig,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (args, urlPrefix) => {
      formStatusDispatchHelper('forgotPassword', urlPrefix + 'api/requestpasswordreset.json', args, 'post', dispatch);
    },
    doNotify: (code, text) => {
      dispatch(addNotification(code, text));
    },
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ForgotPassword)
);
