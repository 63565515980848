import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import Headline from 'components/layout/Headline';
import FormInput from 'components/forms/FormInput';
import { getVariantCopyAmountValue } from 'helpers/cart';
// import { intlModule } from '@chedri/base';
import { injectIntl } from 'react-intl';

const quantityDisplay = ({ id, sizeWithQuantity, onChange }) => {
  const { quantity, translated, net_surcharge: netSurcharge } = sizeWithQuantity;

  return (
    <>
      <FormInput
        prefixAddon={translated}
        name={id + '_selected'}
        value={Math.max(0, quantity || 0)}
        validation={{ valid: quantity >= 0, helpText: 'Must be greater than 0' }}
        onChange={(_, value) => onChange(id, +value)}
        type="number"
        min={0}
      />
      {netSurcharge > 0 && <> Surcharge: {netSurcharge}</>}
    </>
  );
};

const TextileSizesSelector = ({ cartItem, intl, changedCartItemValue }) => {
  // const messages = useSelector(intlModule.selectors.getMessages);
  const {
    textile_sizes_option: textileSizesOption,
    textile_sizes_selected: textileSizesSelected = [],
    variant,
  } = cartItem;

  const availableSizes = textileSizesOption?.textile_sizes ?? [];
  if (!availableSizes.length) {
    return null;
  }

  const selectedDict = textileSizesSelected.reduce((acc, { textile_size_id: id, quantity }) => {
    acc[id] = quantity;

    return acc;
  }, {});

  const totalNumberFromVariant = getVariantCopyAmountValue(variant);

  const initialSelection = availableSizes.reduce((acc, size) => {
    const { textile_size_id: textileSizeId } = size;
    acc[textileSizeId] = { ...size, quantity: selectedDict[textileSizeId] || 0 };

    return acc;
  }, {});

  const [selectedSizeQuantities, setSelectedSizeQuantities] = useState(initialSelection);

  const sumQuantities = obj => Object.values(obj).reduce((acc, { quantity }) => acc + quantity, 0);
  const pickedNumber = sumQuantities(selectedSizeQuantities);

  const onChange = (id, quantity) => {
    const sizeWithQuantity = selectedSizeQuantities[id];
    const updatedSelectedSizeQuantities = {
      ...selectedSizeQuantities,
      [id]: { ...sizeWithQuantity, quantity: Math.max(quantity, 0) },
    };
    setSelectedSizeQuantities(updatedSelectedSizeQuantities);

    const updatedPickedNumber = sumQuantities(updatedSelectedSizeQuantities);
    if (updatedPickedNumber === totalNumberFromVariant) {
      const cartItemTextileSizesSelected = Object.values(updatedSelectedSizeQuantities)
        .filter(({ quantity }) => quantity > 0)
        .map(({ textile_size_id: id, quantity }) => ({
          textile_size_id: id,
          quantity,
        }));

      changedCartItemValue(cartItemTextileSizesSelected, 'textile_sizes_selected');
    }
  };

  const list = Object.entries(selectedSizeQuantities).map(([id, sizeWithQuantity]) => (
    <Col xs={4} key={id}>
      {quantityDisplay({
        id,
        sizeWithQuantity,
        onChange,
      })}
    </Col>
  ));

  const title = `${textileSizesOption?.name} ${pickedNumber}/${totalNumberFromVariant}`;

  const messageId =
    pickedNumber === totalNumberFromVariant ? 'textile_exact_number_selected' : 'textile_select_exactly_n_items';
  const subtitle = intl.formatMessage({ id: messageId }, { total: totalNumberFromVariant });

  return (
    <Row>
      <Col>
        <Headline description={subtitle}>{title}</Headline>
        <Row>{list}</Row>
      </Col>
    </Row>
  );

  // // helper array-to-object { option_id: option }
  // const arrayToDictionary = (arr, propName) => Object.fromEntries(arr.map(option => [option[propName], option]));

  // const collectionDict = arrayToDictionary(collection, 'option_id');
  // const selectedDict = arrayToDictionary(selected ?? [], 'option_id');
  // const preselectedDict = arrayToDictionary(preselected ?? [], 'option_id');

  // const formRadios = collection.map(({ option_id: optionId, name, options }) => {
  //   const preselectedValue = preselectedDict[optionId];
  //   const preselectedValueId = preselectedValue?.option_value_id;
  //   const isPreselected = !!preselectedValue;
  //   const filterOptions = ({ option_value_id: valueId }) => !isPreselected || valueId === preselectedValueId;

  //   const listOptions = options.filter(filterOptions).map(({ option_value_id: valueId, name, price, currency }) => {
  //     const grossPrice = getGrossFromNet(price, cartItem.vat);
  //     const priceLabel = `${messages.net}: ${intl.formatNumber(price, { style: 'currency', currency })}
  //       / ${messages.gross}: ${intl.formatNumber(grossPrice, { style: 'currency', currency })}`;

  //     return {
  //       value: String(valueId),
  //       label: `${name} ${price ? priceLabel : ''}`,
  //     };
  //   });

  //   const optionValueDict = arrayToDictionary(options, 'option_value_id');
  //   const noneOptionId = options.find(({ price }) => !price)?.option_value_id ?? '';
  //   const value = String(selectedDict[optionId]?.option_value_id ?? noneOptionId);
  //   const onChange = (_, valueId) => {
  //     const value = optionValueDict[valueId];

  //     delete selectedDict[optionId];
  //     const selected = [...Object.values(selectedDict), value];

  //     changedCartItemValue(selected, 'perforation_options_selected');
  //   };

  //   return (
  //     <FormRadio
  //       name={`perforation_option_${optionId}}`}
  //       label={`${name} ${isPreselected ? `[${messages.perforation.preset}]` : ''}`}
  //       options={listOptions}
  //       onChange={isPreselected ? () => {} : onChange}
  //       value={value}
  //       key={optionId}
  //       disabled={isPreselected}
  //     />
  //   );
  // });

  // return (
  //   <>
  //     <Row>
  //       <Col>{formRadios}</Col>
  //     </Row>
  //   </>
  // );
};

export default injectIntl(TextileSizesSelector);
