import React from 'react';
import { components } from 'react-select';

class WhiteLabelSettingsOption extends React.Component {
  render() {
    const { data } = this.props;
    return <components.Option {...this.props}>{data.label}</components.Option>;
  }
}

class WhiteLabelSettingsValue extends React.Component {
  render() {
    const { data } = this.props;

    return <div className="Select-value">{data.label}</div>;
  }
}

const whiteLabelSettingsOptionFormatter = options => {
  return options.list.map(option => {
    return whiteLabelSettingsSingleOptionFormatter(option);
  });
};

const whiteLabelSettingsSingleOptionFormatter = option => {
  return {
    ...option,
    value: option.id,
    label: option.name,
  };
};

const whiteLabelSettingsOptionFromEntity = whiteLabelSettings => {
  return !whiteLabelSettings
    ? null
    : whiteLabelSettingsSingleOptionFormatter({
        id: whiteLabelSettings.getId(),
        name: whiteLabelSettings.getName(),
      });
};

export {
  WhiteLabelSettingsOption,
  WhiteLabelSettingsValue,
  whiteLabelSettingsOptionFormatter,
  whiteLabelSettingsSingleOptionFormatter,
  whiteLabelSettingsOptionFromEntity,
};
