import React from 'react';
import { injectIntl } from 'react-intl';
import { Button } from 'react-bootstrap';
import { animateScroll as scroll } from 'react-scroll';

class SimpleSubmitButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showErrorText: false,
    };
  }

  componentDidUpdate = prevProps => {
    const { errorButton, onError } = this.props;

    // there was no error, but there is now
    if (!prevProps.errorButton && errorButton) {
      this.setState({ showErrorText: true }, () => scroll.scrollToTop());

      if (onError) {
        onError();
      }
    }
  };

  render() {
    const {
      intl: { messages },
      label,
      iconClass,
      block,
      defaultStyle,
      entityChanged,
      disabled,
      pending,
      errorButton,
    } = this.props;
    const { showErrorText } = this.state;

    let buttonStyle = defaultStyle;
    if (errorButton) {
      buttonStyle = 'danger';
    } else if (entityChanged) {
      buttonStyle = 'primary';
    }

    return (
      <React.Fragment>
        <Button bsSize="lg" disabled={disabled || pending} type="submit" bsStyle={buttonStyle} block={block}>
          <i className={'fa-fw ' + (pending ? 'fa fa-circle-notch fa-spin' : iconClass)} /> {label}
        </Button>

        {showErrorText && <div className="text-danger m-t-5">{messages.form_contains_errors}</div>}
      </React.Fragment>
    );
  }
}
SimpleSubmitButton.defaultProps = {
  block: false,
  disabled: false,
  defaultStyle: 'default',
  iconClass: 'fal fa-save',
};

export default injectIntl(SimpleSubmitButton);
