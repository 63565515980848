import axios from 'axios';

export const FETCH_CLIENT_NAV_BADGES = 'FETCH_CLIENT_NAV_BADGES';

export function fetchClientNavBadges() {
  return {
    type: FETCH_CLIENT_NAV_BADGES,
    payload: axios.get('/api/client/nav/badges.json?_locale=' + globalLocale),
  };
}
