import React, { useState, useEffect, useCallback } from "react";
import SimplePrompt from "../SimplePrompt";
import  {Col, Button, ModalBody, ModalHeader, ModalTitle, Row} from "react-bootstrap";
import Loader from "../Loader";
import { useDrag } from 'react-dnd';
import { useDrop } from 'react-dnd';
import { cloneDeep as _cloneDeep, findIndex as _findIndex, find as _find, forEach as _forEach, findKey as _findKey} from "lodash";
import UploadService from "./FileUploadService";
import FormInput from "../../forms/FormInput";
import {
 connectToPixabay
} from './../../../actions/MediaLibraryActions';
import {connect} from "react-redux";


const PixabayApi = ({handleMedia, neededHeight, neededWidth, pending, connectToPixabay, pixabaySearching, pixabayFilesCache, pixabaySearchingTextCache}) => {
  const [searchingText, setSearchingText] = useState("");
  const [message, setMessage] = useState("");
  const [fileInfos, setFileInfos] = useState([]);
  const [folderInfos, setFolderInfos] = useState([]);
  const [openedFolder, setOpenedFolder] = useState('main');
  const [searchingResult, setSearchingResult] = useState([]);
  const [search, setSearch] = useState([]);
  //const [pending, setPending] = useState(false);


  useEffect(() => {
   setSearchingResult(pixabaySearching);
   setSearch(pixabaySearchingTextCache);
  }, [pixabaySearching]);

  function PixabaySearch(searching) {
    const key = "20060832-d6e544143563e670a83bf560e";
    connectToPixabay(key, searching, neededHeight, neededWidth)
    let copyOfSearch = _cloneDeep(search);
    copyOfSearch.push(searching);
    setSearch(copyOfSearch);
  }

  const handleSearch = (name, value) => {
    setSearchingText(value.replace(' ', '+').replace('%2B', '+'));
  }

  const ListFiles = (props) => {

    let pointerEvents;
    let opacity;
    //setPending(true);

    if(neededHeight > props.imageHeight || neededWidth > props.imageWidth)
    {
      pointerEvents="none";
      opacity = 0.3;
    }
    else {
      pointerEvents="all";
      opacity = 1;
    }



    return (
      <div md={2} className="cc-media-image" style={{pointerEvents}} key={props.index} onClick={()=>{props.handleMedia("pixabay", props.fileUrl, props.filename, props.previewURL, "by Pixabay")}} >
        <div id="cardImages" className="card" style={{ opacity, pointerEvents}}>
          <img className="card-img-top rounded cc-card-img" src={props.thumb}
               width="100%"/>
        </div>
      </div>
    )
  }

    return (

      <div class="mediaLibraryViews pixabayImage">
        <div class="searchingArea">
        <FormInput
          type="text"
          label="Suchanfrage"
          name="searchingField"
          onChange={handleSearch}
          value={searchingText.replace('+', ' ')}
          maxLength="100"
        />
        <button className="btn btn-default card-button searchingButton" onClick={() => {
          PixabaySearch(searchingText);
        }} > Suchen</button>
        </div>

        <div>
          {searchingResult.hits && searchingResult.hits.length > 0 ?
            <div>
            <h4 style={{margin:"20px"}}>Gefundene Bilder auf <a href="https://pixabay.com" rel="nofollow" target="_blank">pixabay.com</a></h4>
            {searchingResult.hits.map((file, index) => (
              <ListFiles index={index} fileUrl={file.fileURL} thumb={file.previewURL} title={file.user} filename={file.id + "_pixabay"} copyright="by Pixabay" imageHeight={file.imageHeight} imageWidth={file.imageWidth} handleMedia={handleMedia} neededHeight={neededHeight} neededWidth={neededWidth} />
            ))}
            </div>
            : pending ? <Loader/> : <div>Sie können über die "Suchanfrage" nach weiteren Bildern suchen</div>
          }
        </div>
        <div>
          {pixabayFilesCache && pixabayFilesCache.length > 0 ?

            <div>
              <h4 style={{margin:"20px"}}>Suchverlauf auf <a href="https://pixabay.com" rel="nofollow" target="_blank">pixabay.com</a></h4>
              {pixabayFilesCache.map((files, index) => (
              <div>
                <h4>{search && search.length > 0 && search[index]}</h4>
                {files.hits.map((file, index) => (
                  <ListFiles index={index} fileUrl={file.fileURL} thumb={file.previewURL} title={file.user} filename={file.id + "_pixabay"} copyright="by Pixabay" imageHeight={file.imageHeight} imageWidth={file.imageWidth} handleMedia={handleMedia} neededHeight={neededHeight} neededWidth={neededWidth} />
                ))}
              </div>
                ))}
            </div>

            : pending ? <Loader/> : <div>Sie können über die "Suchanfrage" nach weiteren Bildern suchen</div>
          }
        </div>
      </div>



)




}
const mapStateToProps = state => ({
  pixabaySearching: state.mediaLibrary.pixabaySearching,
  pending: state.mediaLibrary.pendingP,
  pixabayFilesCache: state.mediaLibrary.pixabayFilesCache,
  pixabaySearchingTextCache: state.mediaLibrary.pixabaySearchingTextCache,

});

const mapDispatchToProps = dispatch => {
  return {
    connectToPixabay: (key, searching, neededHeight, neededWidth) => {
      dispatch(connectToPixabay(key, searching, neededHeight, neededWidth));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PixabayApi);
