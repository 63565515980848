import React from 'react';
import { useSelector } from 'react-redux';
import { chatModule, communicationModule } from '@chedri/base';

import Spinner from 'components/Spinner';
import { AutoSizer, List } from 'react-virtualized';
import ContactItem from './ContactItem';

function findConversation(conversations, username) {
  return conversations.find(entry => entry.username === username);
}

function CommunicationContacts({ onItemSelected }) {
  const items = useSelector(communicationModule.contact.selectors.getList);
  const isLoading = useSelector(communicationModule.contact.selectors.isListLoading);
  const activeConversations = useSelector(chatModule.selectors.getActiveConversations);
  const unopenedMessagesFrom = useSelector(chatModule.selectors.getUnopenedMessagesFrom);

  if (isLoading || !items) {
    return <Spinner className="text-success" size={24} />;
  }

  return (
    <ul className="full-height">
      {Array.isArray(items) && (
        <AutoSizer>
          {({ height, width }) => (
            <List
              width={width}
              height={height}
              rowCount={items.length}
              rowHeight={80}
              rowRenderer={({ index, style }) => {
                const item = items[index];
                let lastMessage = item.last_message;
                const conversation = findConversation(activeConversations, item.id);
                let unopenedMessagesCount = item.unseen_messages || 0;
                if (unopenedMessagesFrom[item.id]) {
                  unopenedMessagesCount = unopenedMessagesFrom[item.id].messages;
                }
                if (conversation && conversation.messages.length > 0) {
                  lastMessage = conversation.messages[0].message;
                }
                return (
                  <ContactItem
                    key={item.id}
                    item={item}
                    lastMessage={lastMessage}
                    unopenedMessagesCount={unopenedMessagesCount}
                    onClick={onItemSelected}
                    style={style}
                  />
                );
              }}
            />
          )}
        </AutoSizer>
      )}
    </ul>
  );
}
CommunicationContacts.defaultProps = {};
CommunicationContacts.propTypes = {};

export default CommunicationContacts;
