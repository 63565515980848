import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Clearfix } from 'react-bootstrap';
import AnimateHeight from 'react-animate-height';
import { concat as _concat, pullAll as _pullAll, forEach as _forEach } from 'lodash';

import { parseQs, stringifyQs } from 'helpers/http';
import { equipmentLevels } from 'helpers/items';

import SummaryFilterValue from 'components/layout/summary/SummaryFilterValue';

class SummaryFilterCarEquipment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mouseOver: false,
      show: false,
    };
  }

  handleClick = () => {
    this.setState(prevProps => ({ show: !prevProps.show }));
  };

  getName = () => {
    return 'EQUIPMENT';
  };

  valueClick = (value, checked) => {
    // new values for this filter:
    let values = this.props.filters[this.getName()] !== undefined ? this.props.filters[this.getName()].slice() : [];

    if (checked) {
      values = _concat(values, [value]);
    } else {
      _pullAll(values, [value]);
    }

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: stringifyQs({ ...parseQs(this.props.location.search), [this.getName()]: values }),
    });
  };

  isValueChecked = value => {
    const { filters } = this.props;
    const stateIndex = filters[this.getName()] !== undefined ? filters[this.getName()].indexOf(value) : -1;
    return stateIndex >= 0;
  };

  hasFilters = () => {
    const { filters } = this.props;
    return filters[this.getName()] !== undefined;
  };

  render() {
    const {
      intl: { messages },
    } = this.props;
    const { mouseOver, show } = this.state;

    const equipments = equipmentLevels(messages);

    return (
      <div
        class={'summary-filter animate-all ' + (mouseOver ? 'hover ' : '') + (show ? 'active ' : '')}
        onMouseEnter={() => this.setState({ mouseOver: true })}
        onMouseLeave={() => this.setState({ mouseOver: false })}
      >
        <div class="summary-filter-title" onClick={this.handleClick}>
          <Clearfix>
            <div class="pull-left">{messages.equipmentLevel}</div>
            <div class="pull-right">
              {this.hasFilters() && !show ? (
                <i class="fal fa-check text-success" />
              ) : (
                <i class={'fal fa-chevron-' + (show ? 'up' : 'right')} />
              )}
            </div>
          </Clearfix>
        </div>

        <AnimateHeight duration={200} height={show ? 'auto' : 0}>
          <div class="summary-filter-body">
            {equipments.map((equipment, i) => {
              const icons = [];

              let j = 0;
              _forEach(equipment.icons, (name, icon) => {
                const iconClass = i > 0 && j === 0 ? ' m-r-15 ' : ' m-r-5 ';
                icons.push(<i class={icon + iconClass + ''} title={name} key={icon} />);
                j++;
              });

              const description = <div class="hint-text p-l-5">{icons}</div>;

              return (
                <SummaryFilterValue
                  type="select"
                  value={{ selectValue: equipment.value, name: equipment.name, description }}
                  checked={this.isValueChecked(equipment.value)}
                  onClick={this.valueClick}
                  key={i}
                />
              );
            })}
          </div>
        </AnimateHeight>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(SummaryFilterCarEquipment)));
