import React from 'react';
import { connect } from 'react-redux';
import { Badge } from 'react-bootstrap';
import { components } from 'react-select';
import _ from 'lodash';

import { getNameInCurrentLanguage } from 'helpers/intl';

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

class ProductTemplateOptionWithProps extends React.Component {
  render() {
    const { data, appIntl } = this.props;
    return (
      <components.Option {...this.props}>
        {data.id ? <Badge class="m-r-10 text-uppercase p-l-10 p-r-10">{appIntl.displayIntlForm}</Badge> : null}
        {getNameInCurrentLanguage(data.name, appIntl)} {data.type}
      </components.Option>
    );
  }
}
const ProductTemplateOption = connect(mapStateToProps)(ProductTemplateOptionWithProps);

class ProductTemplateValueWithProps extends React.Component {
  render() {
    const { data, appIntl } = this.props;

    return (
      <div class="Select-value">
        {data.id ? <Badge class="m-r-10 text-uppercase p-l-10 p-r-10">{appIntl.displayIntlForm}</Badge> : null}
        {getNameInCurrentLanguage(data.name, appIntl)}
      </div>
    );
  }
}
const ProductTemplateValue = connect(mapStateToProps)(ProductTemplateValueWithProps);

const productTemplateOptionFormatter = options => {
  return options.list.map(option => {
    return productTemplateSingleOptionFormatter(option);
  });
};

const productTemplateSingleOptionFormatter = (option = null) => {
  if (option.id) {
    let label = '';
    _.forEach(option.name, langName => {
      if (langName.name !== undefined) {
        label += langName.name + ' ';
      }
    });
    return { ...option, value: option.id, label };
  }

  return null;
};
const productTemplateOptionFromEntity = productTemplate => {
  return !productTemplate
    ? null
    : productTemplateSingleOptionFormatter({
      id: productTemplate.getId(),
      name: productTemplate.getName().map(langName => {
        return { lang: langName.getLang(), name: langName.getName() };
      }),
    });
};

export { ProductTemplateValue, ProductTemplateOption, productTemplateOptionFormatter, productTemplateSingleOptionFormatter, productTemplateOptionFromEntity };
