import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Row, Col } from 'react-bootstrap';
import { injectIntl } from 'react-intl';

import EntityStateUpdater from 'component/EntityStateUpdater';

import Loader from 'components/layout/Loader';
import Data from 'components/Data';
import Entity from 'components/Entity';

import FormRichEditor from 'components/forms/FormRichEditor';
import FormErrors from 'components/forms/FormErrors';

import { entityMetaMapping } from 'js/constants';

class ChatterReviewForm extends EntityStateUpdater {
  constructor(props) {
    super(props);

    this.state = {
      entityLoaded: false,
      entityChanged: false,
    };

    const { entityMappingName } = props;

    this.entity = new Entity();
    this.entity.setData({}); // set the data as already set

    if (props.meta[entityMappingName] !== undefined && !props.meta[entityMappingName].pending) {
      this.entity.setMeta(props.meta, entityMappingName);

      this.state = {
        ...this.state,
        entityLoaded: true,
        entity: this.entity,
      };
    }
  }

  componentDidUpdate = () => {
    const { meta, entityMappingName } = this.props;

    if (meta[entityMappingName] !== undefined && !meta[entityMappingName].pending && !this.entity.getMetaLoaded()) {
      this.entity.setMeta(meta, entityMappingName);
      if (this.entity.getDataLoaded()) {
        this.setState({ entityLoaded: true, entity: this.entity });
      }
    }
  };

  componentDidMount = () => {};

  formSubmit = e => {
    e.preventDefault();

    this.setState(
      prevState => ({ entity: prevState.entity.validate() }),
      () => {
        const { onSubmit } = this.props;
        const { entity } = this.state;

        if (entity.isValid) {
          onSubmit(entity.processedData);
        }
      }
    );
  };

  getStars = (count, callback) => {
    const res = [];

    for (let i = 0; i < 6; i++) {
      const className = count && i + 1 <= count ? 'fad fa-star text-primary ' : 'far fa-star text-master-lighter ';
      res.push(<i key={i} className={className + 'cursor fa-4x '} onClick={() => callback(i + 1)} />);
    }

    return <div className="">{res}</div>;
  };

  render() {
    const {
      intl: { messages },
      formStatus,
      reviewType,
      entityMappingKey,
    } = this.props;
    const { entity, entityLoaded } = this.state;

    return (
      <Row>
        <Data dataName={entityMappingKey} /> {/* fetch meta data */}
        <Col xs={12}>
          <FormErrors formStatus={formStatus} />

          {entityLoaded ? (
            <form method="post" onSubmit={this.formSubmit}>
              <div class="panel panel-transparent">
                <div class="form-group-attached">
                  <div className="form-group form-group-default">
                    <label>Wie bewerten Sie Ihren Kontakt mit uns?</label>
                    <div className="text-center">
                      {this.getStars(entity.getOverallStars(), countStars =>
                        this.updateEntity('overallStars', countStars)
                      )}
                    </div>
                  </div>
                  {reviewType === 'call' && (
                    <div className="form-group form-group-default">
                      <label>Wie bewerten Sie die Verbindungsqualität?</label>
                      <div className="text-center">
                        {this.getStars(entity.getConnectionQualityStars(), countStars =>
                          this.updateEntity('connectionQualityStars', countStars)
                        )}
                      </div>
                    </div>
                  )}
                  <FormRichEditor
                    name="remarks"
                    label={messages.message}
                    value={entity.getRemarks()}
                    meta={entity.getRemarksMetaInformation()}
                    validation={entity.getRemarksValidationResult()}
                    onChange={this.updateEntity}
                    disabled={formStatus.pending}
                  />
                </div>

                <div className="text-right m-t-15">
                  <Button
                    bsSize="lg"
                    disabled={formStatus.pending}
                    type="submit"
                    bsStyle={this.state.entityChanged ? 'primary' : 'default'}
                  >
                    <i className={'fa-fw ' + (formStatus.pending ? ' fa fa-circle-notch fa-spin' : 'fal fa-save')} />{' '}
                    {messages.save}
                  </Button>
                </div>
              </div>
            </form>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reviewData } = ownProps;
  const reviewType = reviewData && reviewData.customerVideoCallId ? 'call' : 'chat';
  const entityMappingKey = reviewType + 'Review';
  const entityMappingName = entityMetaMapping[entityMappingKey];

  return {
    meta: state.entityMeta,
    reviewType,
    entityMappingKey,
    entityMappingName,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(ChatterReviewForm)));
