export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';
export const SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED';
export const SOCKET_EMIT = 'SOCKET_EMIT';
export const SOCKET_EVENT = 'SOCKET_EVENT';
export const SOCKET_REGISTER_CALLBACK = 'SOCKET_REGISTER_CALLBACK';
export const SOCKET_RESET_CALLBACK = 'SOCKET_RESET_CALLBACK';

export const socketConnect = (options = {}) => {
  return {
    type: SOCKET_CONNECT,
    payload: options,
  };
};

export const socketConnected = () => {
  return {
    type: SOCKET_CONNECTED,
    payload: null,
  };
};

export const socketDisconnect = () => {
  return {
    type: SOCKET_DISCONNECT,
    payload: null,
  };
};

export const socketDisconnected = () => {
  return {
    type: SOCKET_DISCONNECTED,
    payload: null,
  };
};

export const socketEmit = (eventName, args, callback = null) => {
  return {
    type: SOCKET_EMIT,
    payload: { eventName, args, callback },
  };
};

export const socketEvent = (eventName, args) => {
  return {
    type: SOCKET_EVENT,
    payload: { eventName, args },
  };
};

export const socketRegisterCallback = (eventName, callback) => {
  return {
    type: SOCKET_REGISTER_CALLBACK,
    payload: { eventName, callback },
  };
};

export const socketResetCallback = (eventName, callback) => {
  return {
    type: SOCKET_RESET_CALLBACK,
    payload: { eventName, callback },
  };
};
