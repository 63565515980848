import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Severity from './Severity';
import { reduceValidationResult } from './Validation';

AlertIcon.propTypes = {
  className: PropTypes.string,
  property: PropTypes.string,
  name: PropTypes.string,
  validationResult: PropTypes.shape({
    severity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

AlertIcon.defaultProps = {
  className: '',
  name: undefined,
  property: undefined,
  validationResult: undefined,
};

function AlertIcon({ className, name, property, validationResult }) {
  if (!validationResult) {
    return null;
  }
  const validationResultEntry = reduceValidationResult(validationResult, property);

  const { severity } = validationResultEntry || {};
  let iconName = name;
  if (!iconName) {
    switch (severity) {
      case Severity.Warning:
        iconName = 'fa-exclamation-triangle';
        break;
      case Severity.Error:
        iconName = 'fa-exclamation-triangle';
        break;
      default:
        iconName = 'fa-info-circle';
    }
  }
  return <Icon name={iconName} solid iconClassName={`alert-icon ${className} ${Severity.toString(severity)}`} />;
}

export default AlertIcon;
