import React from 'react';
import { injectIntl, FormattedMessage, FormattedNumber } from 'react-intl';

import MoreInfoStepB from 'components/layout/checkout/moreInfo/MoreInfoStepB';

import { ONLINE_CAR_DISCOUNT } from 'js/constants';

class StepBCarCheckoutInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMoreInfo: false,
    };
  }

  showMoreInfo = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showMoreInfo: true });
  };

  closeMoreInfo = () => {
    this.setState({ showMoreInfo: false });
  };

  render() {
    const {
      intl: { messages },
      projectConfig,
    } = this.props;
    const { showMoreInfo } = this.state;

    return (
      <div>
        <i class="fa fa-car fa-2x hint-text" />
        <h2>{messages.your_car_is_ready_to_check_out}</h2>
        <p>
          {messages.choosing_one_of_our_cars_is_a_good_decision}
          <br />
          <i class="fa fa-check-circle" /> {messages.your_car_will_be_delivered_to_you}
          <br />
          {ONLINE_CAR_DISCOUNT > 0 ? (
            <span>
              <i class="fa fa-check-circle" />{' '}
              <FormattedMessage
                id="your_will_get_a_amount_discount_for_ordering_online"
                values={{
                  amount: (
                    <FormattedNumber
                      value={ONLINE_CAR_DISCOUNT}
                      style="currency"
                      currency={projectConfig.data.currency}
                    />
                  ),
                }}
              />{' '}
              <br />
            </span>
          ) : null}
          <i class="fa fa-check-circle" /> {messages.you_will_get_exclusive_offers_when_using_our_app}
        </p>
        <p class="small hint-text">
          {messages.you_dont_risk_when_ordering_online}{' '}
          <a href="#moreInfo" onClick={this.showMoreInfo}>
            {messages.find_out_more}
          </a>
        </p>

        <MoreInfoStepB show={showMoreInfo} onHide={this.closeMoreInfo} isCarCheckout />
      </div>
    );
  }
}

export default injectIntl(StepBCarCheckoutInfo);
