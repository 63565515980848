import React from 'react';
import { connect } from 'react-redux';
import { injectStripe } from 'react-stripe-elements';
import { get as _get } from 'lodash';

import { handleCardSetup } from 'helpers/stripe';
import { getSubmitData } from 'helpers/checkout';

class PaymentButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stripeLoading: false,
      paymentMethodId: null,
      paymentIntentId: null,
    };
  }

  componentDidUpdate = prevProps => {
    const { checkout, formStatus, doUpdatePayment } = this.props;

    if (prevProps.formStatus.pending && !formStatus.pending && formStatus.hasError) {
      if (_get(formStatus, 'errors.data.requires_action')) {
        this.triggerPaymentAuth(formStatus.errors.data.payment_intent_client_secret);
      } else {
        // reset the payment settings when the form has an error
        this.setState({ paymentIntentId: null, paymentMethodId: null }, () => {
          doUpdatePayment(checkout.activePaymentPlugin, checkout.payment, {
            ...checkout.paymentData,
          });
        });
      }
    }
  };

  onClick = e => {
    if (e) {
      e.stopPropagation();
    }

    const { checkout, validate, onSubmit, formStatus, raiseError, doHandleCardSetup, doUpdateCheckout } = this.props;
    const { stripeLoading, paymentMethodId: statePaymentMethodId, paymentIntentId: statePaymentIntentId } = this.state;

    const creditCardHolder =
      checkout.paymentData.creditCardHolder ||
      (checkout.userData && checkout.userData.firstName + ' ' + checkout.userData.lastName);
    const paymentMethodId = statePaymentMethodId || checkout.paymentData.paymentMethodId;

    if (!stripeLoading && !formStatus.pending) {
      validate().then(data => {
        // check if we have a payment method id
        if (!paymentMethodId) {
          this.setState({ stripeLoading: true }, () => doUpdateCheckout({ paymentPending: true }));

          return doHandleCardSetup(creditCardHolder)
            .then(stripeRes => {
              this.setState({ stripeLoading: false }, () => doUpdateCheckout({ paymentPending: false }));

              if (stripeRes.error) {
                raiseError(stripeRes.error.message);
              } else if (stripeRes.setupIntent) {
                this.setState({ paymentMethodId: stripeRes.setupIntent.payment_method }, this.onClick);
              }
            })
            .catch(e => {
              this.setState({ stripeLoading: false }, () => doUpdateCheckout({ paymentPending: false }));
              throw e;
            });
        }

        const submitData = getSubmitData(data, checkout);
        if (statePaymentMethodId) {
          submitData.payment.paymentMethodId = statePaymentMethodId;
        }
        if (statePaymentIntentId) {
          submitData.payment.paymentIntentId = statePaymentIntentId;
        }

        onSubmit(submitData);
      });
    }
  };

  triggerPaymentAuth = clientSecret => {
    const {
      intl: { messages },
      stripe,
      raiseError,
      doUpdateCheckout,
    } = this.props;

    // Use Stripe.js to handle required card action
    this.setState({ stripeLoading: true }, () => {
      doUpdateCheckout({ paymentPending: true });

      stripe
        .handleCardAction(clientSecret)
        .then(result => {
          if (result.error) {
            this.setState(
              {
                stripeLoading: false,
              },
              () => {
                doUpdateCheckout({ paymentPending: false });
                raiseError(messages.payment_method_invalid);
              }
            );
          } else {
            // The card action has been handled
            // The PaymentIntent can be confirmed again on the server
            this.setState(
              {
                stripeLoading: false,
                paymentIntentId: result.paymentIntent.id,
              },
              () => {
                doUpdateCheckout({ paymentPending: false });
                this.onClick();
              }
            );
          }
        })
        .catch(e => {
          this.setState({ stripeLoading: false }, () => doUpdateCheckout({ paymentPending: false }));
          throw e;
        });
    });
  };

  render() {
    const { label, disabled, buttonClassName, icon, type } = this.props;
    const { stripeLoading } = this.state;

    const pending = disabled || stripeLoading;

    return (
      // eslint-disable-next-line react/button-has-type
      <button className={buttonClassName} disabled={pending} onClick={this.onClick} type={type}>
        <span>
          {pending ? <i className="fa-fw fa fa-circle-notch fa-spin" /> : icon} {label}
        </span>
      </button>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    doHandleCardSetup: creditCardHolder => handleCardSetup(ownProps.stripe, creditCardHolder),
  };
};

export default injectStripe(
  connect(
    null,
    mapDispatchToProps
  )(PaymentButton)
);
