import React from 'react';

//
export default class PaymentContent extends React.Component {
  render() {
    const {
      intl: { messages },
    } = this.props;

    return <p>{messages.order_will_directly_be_marked_paid}</p>;
  }
}
PaymentContent.defaultProps = {
  employee: false,
};
