import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import _intersection from 'lodash/intersection';
import { FormGroup, FormControl, InputGroup } from 'react-bootstrap';
import { intlModule } from '@chedri/base';
import Icon from 'components/Icon';
import IconLink from 'components/IconLink';
import RichEditorContent from 'components/RichEditorContent';
import Pagination from './../../../components/layout/Pagination';
import { connect } from 'react-redux';
import { getContactList } from './../../../actions/ContactListActions';
import Loader from './../Loader';

function AddressList({ onSelected, filterByTypes, filter, getContactList, pending, contacts, limit, count, page }) {
  const messages = useSelector(intlModule.selectors.getMessages);

  const [searchText, setSearchText] = useState('');

  const [searchInput, setSearchInput] = useState('');

  const searchInputChange = useCallback(
    ({ target }) => {
      if (target && target.value != null) {
        setSearchInput(target.value);
        search(target.value);
      }
    },
    [search, setSearchInput]
  );

  const searchInputReset = useCallback(() => {
    setSearchInput('');
    search('');
  }, [search, setSearchInput]);

  const search = useCallback(
    debounce(async search => {
      if (search !== null && search.length > 0) {
        getContactList(20, 'relevance', 'desc', 1, 0, search);
        setSearchText(search);
      } else {
        getContactList(20, 'relevance', 'desc', 1, 0, null);
        setSearchText('');
      }
    }, 400),
    [contacts, setSearchText]
  );

  const isAddressFiltered = addr => {
    if (!filterByTypes || !filterByTypes.length) {
      return true;
    }
    return _intersection(addr.types, filterByTypes).length;
  };

  const handlePagination = nextPage =>
    getContactList(limit, 'relevance', 'desc', nextPage, limit * (nextPage - 1), searchText);

  useEffect(() => {
    getContactList(20, 'relevance', 'desc', 1, 0, null);
  }, []);

  return (
    <div className="contacts">
      <div className="contacts-header">
        <div className="search-label">
          <h4>
            <Icon name="fa-address-book" label={messages.contacts} />
          </h4>
        </div>
        <div className="search">
          <FormGroup controlId="searchInputId">
            <InputGroup>
              <InputGroup.Addon
                style={{ background: 'white', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }}
              >
                <Icon name={pending ? 'fa-spinner-third fa-spin' : 'fa-search'} />
              </InputGroup.Addon>
              <FormControl type="text" value={searchInput} onChange={searchInputChange} autoComplete="off" />
              <InputGroup.Addon
                style={{ background: 'white', borderTopRightRadius: '20px', borderBottomRightRadius: '20px' }}
              >
                <IconLink name="fa-times" onClick={searchInputReset} />
              </InputGroup.Addon>
            </InputGroup>
          </FormGroup>
        </div>
        <div className="inline m-l-10">
          <Pagination count={count} resPerPage={20} activePage={page} handleSelect={handlePagination} />
        </div>
      </div>
      {Array.isArray(contacts) && pending ? (
        <Loader />
      ) : (
        contacts
          .filter(contact => contact.addresses.find(isAddressFiltered))
          .map(contact => {
            return (
              <div class="list-group" key={contact.id}>
                <div class="list-group-entry">
                  <RichEditorContent
                    content={
                      `${contact?.first_name ?? ''} ${contact?.last_name ?? ''}`.trim() +
                      ((contact?.first_name || contact?.last_name) && contact?.company ? ` - ${contact.company}` : (contact?.company ?? ''))
                    }
                  />
                </div>
                {contact.addresses.filter(isAddressFiltered).map(addr => (
                  <div className="list-entry" onClick={() => onSelected(contact, addr)} key={addr.id}>
                    <div className="list-entry-item">
                      <div>
                        {`${addr?.street ?? ''} ${addr?.house_number ?? ''}` +
                          (addr?.apartment_number ? ` / ${addr.apartment_number}` : '')}
                      </div>
                    </div>
                    <div className="list-entry-item">
                      <div>{`${addr?.zipcode ?? ''} ${addr?.city ?? ''}`}</div>
                    </div>
                    <div className="list-entry-item">
                      <div>{addr?.country_name ?? ''}</div>
                    </div>
                  </div>
                ))}
              </div>
            );
          })
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  pending: state.contacts.pending,
  contacts: state.contacts.contacts,
  count: state.contacts.count,
  limit: state.contacts.limit,
  offset: state.contacts.offset,
  page: state.contacts.page,
});

const mapDispatchToProps = dispatch => {
  return {
    getContactList: (limit, sort, dir, page, offset, search) => {
      dispatch(getContactList(limit, sort, dir, page, offset, search));
    },
  };
};

AddressList.propTypes = {
  showAddContact: PropTypes.bool,
  filterByTypes: PropTypes.arrayOf(PropTypes.string),
  filter: PropTypes.string,
};

AddressList.defaultProps = {
  showAddContact: true,
  filterByTypes: [],
  filter: 'all',
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressList);
