import {
  CART_RESET,
  CART_FETCH_FULFILLED,
  CART_FETCH_PENDING,
  CART_ADD_ITEM_PENDING,
  CART_ADD_ITEM_FULFILLED,
  CART_ADD_ITEM_REJECTED,
  CART_ADD_VARIANT_PENDING,
  CART_ADD_VARIANT_FULFILLED,
  CART_ADD_VARIANT_REJECTED,
  CART_REMOVE_ITEM_PENDING,
  CART_REMOVE_ITEM_FULFILLED,
  CART_REMOVE_ITEM_REJECTED,
  CART_REMOVE_VARIANT_PENDING,
  CART_REMOVE_VARIANT_FULFILLED,
  CART_REMOVE_VARIANT_REJECTED,
  CART_UPDATE_ITEM_PENDING,
  CART_UPDATE_ITEM_FULFILLED,
  CART_UPDATE_ITEM_REJECTED,
  CART_UPDATE_FULFILLED,
  CART_UPDATE_PENDING,
  CART_UPDATE_REJECTED,
} from 'actions/CartActions';

const initialState = {
  pending: false,
  addToCartPending:false,
  id: null,
  currency: 'EUR',
  items: [],
  sum_net: 0,
  sum_gross: 0,
  type: 'cart',
  vat_sum_rates: [],
};

export default function cart(state = initialState, action) {
  switch (action.type) {
    case CART_RESET: {
      return { ...initialState };
    }
    case CART_FETCH_FULFILLED: {
      return { ...state, ...action.payload.data, pending: false };
    }

    case CART_FETCH_PENDING: {
      return { ...state, pending: true };
    }

    case CART_ADD_ITEM_PENDING:
    case CART_ADD_VARIANT_PENDING: {
      return { ...state, pending: true, addToCartPending: true };
    }

    case CART_ADD_ITEM_FULFILLED: {
      return { ...state, ...action.payload.data, pending: false, addToCartPending: false };
    }

    case CART_ADD_VARIANT_FULFILLED: {
      return { ...state, ...action.payload.data, pending: false };
    }

    case CART_ADD_ITEM_REJECTED:
    case CART_ADD_VARIANT_REJECTED: {
      return { ...state, pending: false };
    }

    case CART_REMOVE_ITEM_PENDING:
    case CART_REMOVE_VARIANT_PENDING: {
      return { ...state, pending: true };
    }

    case CART_REMOVE_ITEM_FULFILLED: {
      return { ...state, ...action.payload.data, pending: false };
    }

    case CART_REMOVE_VARIANT_FULFILLED: {
      return { ...state, ...action.payload.data, pending: false };
    }

    case CART_REMOVE_ITEM_REJECTED:
    case CART_REMOVE_VARIANT_REJECTED: {
      return { ...state, pending: false };
    }

    case CART_UPDATE_ITEM_FULFILLED: {
      return { ...state, ...action.payload.data, pending: false };
    }

    case CART_UPDATE_ITEM_PENDING: {
      return { ...state, pending: true };
    }

    case CART_UPDATE_ITEM_REJECTED: {
      return { ...state, pending: false };
    }

    case CART_UPDATE_FULFILLED: {
      return { ...state, ...action.payload.data, pending: false };
    }

    case CART_UPDATE_PENDING: {
      return { ...state, pending: true };
    }

    case CART_UPDATE_REJECTED: {
      return { ...state, pending: false };
    }
  }

  return state;
}
