import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import { injectIntl } from 'react-intl';

import { getSalutationOptions, getGenderForSalutation } from 'helpers/clients';

import CmsElementSignupFormLegal from 'components/cms/elements/CmsElementSignupFormLegal';

import Loader from 'components/layout/Loader';
import SimpleSubmitButton from 'components/layout/SimpleSubmitButton';
import Data from 'components/Data';

import FormInput from 'components/forms/FormInput';
import FormInputPassword from 'components/forms/FormInputPassword';
import FormAutocomplete from 'components/forms/FormAutocomplete';
import FormErrors from 'components/forms/FormErrors';

import EntityStateUpdater from 'component/EntityStateUpdaterV2';

import { emailPattern, passwordRepeat, injectValidator } from 'formValidation/entityValidators';
import { communicationVideoModule } from '@chedri/base';
import { formStatusDispatchHelper } from '../../../actions/FormStatusActions';
import FriendlyCaptcha from './FriendlyCaptcha';

const { whiteLabelSettings } = global;

class SignupForm extends EntityStateUpdater {
  constructor(props) {
    super(props, {
      metaIdentifier: 'cmsSignupForm',
      dataProps: 'empty',
      defaultDataProps: { country: 'DE', gender: 'male', salutation: 'mr' },
    }); // pass the configuration to entity state updater

    this.entity.__addValidator(injectValidator(passwordRepeat, props.intl));
    this.entity.__addValidator(injectValidator(emailPattern, props.intl));
  }

  updateSalutation = (name, value) => {
    const gender = getGenderForSalutation(value);
    this.updateEntityMultiple(['salutation', 'gender'], [value, gender]);
  };

  formSubmit = e => {
    e.preventDefault();
    e.stopPropagation(); // if we are editing a page, we have to make sure the parent form will not be submitted
    this.validateEntity(() => {
      const { appIntl } = this.props;

      this.props.onSubmit(appIntl.urlPrefix, this.state.entity.__getStoredData());
    });
  };

  render() {
    const {
      intl: { messages },
      formStatus,
      buttonTitle,
      isInCall,
    } = this.props;
    const { entity, entityLoaded } = this.state;

    const maybeShow = component => {
      const { name } = component.props;
      const { registration_visible_fields: registrationVisibleFields } = whiteLabelSettings;
      if (registrationVisibleFields?.includes(name)) {
        return component;
      }
    };

    return (
      <Row>
        <Data dataName="cmsSignupForm" /> {/* fetch meta data */}
        <Col xs={12}>
          {entityLoaded ? (
            <Form onSubmit={this.formSubmit}>
              <FormErrors formStatus={formStatus} />

              <div class="form-group-attached">
                {maybeShow(
                  <FormAutocomplete
                    label={messages.salutation}
                    name="salutation"
                    value={entity.getSalutation}
                    meta={entity.getSalutationMetaInformation}
                    validation={entity.getSalutationValidationResult}
                    onChange={this.updateSalutation}
                    options={getSalutationOptions(messages)}
                    disabled={formStatus.pending}
                  />
                )}
                {maybeShow(
                  <FormInput
                    label={messages.first_name}
                    name="firstName"
                    value={entity.getFirstName}
                    meta={entity.getFirstNameMetaInformation}
                    validation={entity.getFirstNameValidationResult}
                    onChange={this.updateEntity}
                    disabled={formStatus.pending}
                  />
                )}
                {maybeShow(
                  <FormInput
                    label={messages.last_name}
                    name="lastName"
                    value={entity.getLastName}
                    meta={entity.getLastNameMetaInformation}
                    validation={entity.getLastNameValidationResult}
                    onChange={this.updateEntity}
                    disabled={formStatus.pending}
                  />
                )}
                {maybeShow(
                  <FormInput
                    label={messages.company}
                    name="company"
                    value={entity.getCompany}
                    meta={entity.getCompanyMetaInformation}
                    validation={entity.getCompanyValidationResult}
                    onChange={this.updateEntity}
                    disabled={formStatus.pending}
                  />
                )}

                {maybeShow(
                  <FormInput
                    type="tel"
                    label={messages.phone}
                    name="phone"
                    value={entity.getPhone}
                    meta={entity.getPhoneMetaInformation}
                    validation={entity.getPhoneValidationResult}
                    onChange={this.updateEntity}
                    disabled={formStatus.pending}
                  />
                )}

                {maybeShow(
                  <FormInput
                    type="tel"
                    label={messages.mobile_phone}
                    name="mobile"
                    value={entity.getMobile}
                    meta={entity.getMobileMetaInformation}
                    validation={entity.getMobileValidationResult}
                    onChange={this.updateEntity}
                    disabled={formStatus.pending}
                  />
                )}
                <FormInput
                  type="email"
                  label={messages.email}
                  name="email"
                  value={entity.getEmail}
                  meta={entity.getEmailMetaInformation}
                  validation={entity.getEmailValidationResult}
                  onChange={this.updateEntity}
                  disabled={formStatus.pending}
                  autoComplete="username"
                />
              </div>

              <div class="form-group-attached m-t-10">
                {maybeShow(
                  <FormInput
                    label={messages.street}
                    name="street"
                    value={entity.getStreet}
                    meta={entity.getStreetMetaInformation}
                    validation={entity.getStreetValidationResult}
                    onChange={this.updateEntity}
                    disabled={formStatus.pending}
                  />
                )}

                <Row class="clearfix no-margin">
                  <Col sm={6}>
                    {maybeShow(
                      <FormInput
                        label={messages.house_number}
                        name="houseNumber"
                        value={entity.getHouseNumber}
                        meta={entity.getHouseNumberMetaInformation}
                        validation={entity.getHouseNumberValidationResult}
                        onChange={this.updateEntity}
                        disabled={formStatus.pending}
                      />
                    )}
                  </Col>
                  <Col sm={6}>
                    {maybeShow(
                      <FormInput
                        label={messages.apartment_number}
                        name="apartmentNumber"
                        value={entity.getApartmentNumber}
                        meta={entity.getApartmentNumberMetaInformation}
                        validation={entity.getApartmentNumberValidationResult}
                        onChange={this.updateEntity}
                        disabled={formStatus.pending}
                      />
                    )}
                  </Col>
                  <Col sm={12}>
                    {maybeShow(
                      <FormInput
                        label={messages.address_line2}
                        name="addressLine2"
                        value={entity.getAddressLine2}
                        meta={entity.getAddressLine2MetaInformation}
                        validation={entity.getAddressLine2ValidationResult}
                        onChange={this.updateEntity}
                        disabled={formStatus.pending}
                      />
                    )}
                  </Col>
                </Row>

                <Row class="clearfix no-margin">
                  <Col sm={6}>
                    {maybeShow(
                      <FormInput
                        label={messages.zip_code}
                        name="zipCode"
                        value={entity.getZipCode}
                        meta={entity.getZipCodeMetaInformation}
                        validation={entity.getZipCodeValidationResult}
                        onChange={this.updateEntity}
                        disabled={formStatus.pending}
                      />
                    )}
                  </Col>
                  <Col sm={6}>
                    {maybeShow(
                      <FormInput
                        label={messages.city}
                        name="city"
                        value={entity.getCity}
                        meta={entity.getCityMetaInformation}
                        validation={entity.getCityValidationResult}
                        onChange={this.updateEntity}
                        disabled={formStatus.pending}
                      />
                    )}
                  </Col>
                </Row>

                {maybeShow(
                  <FormAutocomplete
                    label={messages.country}
                    name="country"
                    value={entity.getCountry}
                    meta={entity.getCountryMetaInformation}
                    validation={entity.getCountryValidationResult}
                    onChange={this.updateEntity}
                    autocompleteType="country"
                    complete
                    disabled={formStatus.pending}
                  />
                )}
              </div>

              <div class="form-group-attached m-t-10">
                <FormInputPassword
                  name="password"
                  label={messages.password}
                  value={entity.getPassword}
                  meta={entity.getPasswordMetaInformation}
                  validation={entity.getPasswordValidationResult}
                  onChange={this.updateEntity}
                  disabled={formStatus.pending}
                  autoComplete="new-password"
                />
                <FormInputPassword
                  name="repeatPassword"
                  label={messages.repeat_password}
                  value={entity.getRepeatPassword}
                  meta={entity.getRepeatPasswordMetaInformation}
                  validation={entity.getRepeatPasswordValidationResult}
                  onChange={this.updateEntity}
                  disabled={formStatus.pending}
                  autoComplete="new-password"
                />
              </div>

              <div class="p-t-20 p-b-15 fs-11">
                <FriendlyCaptcha />
                <CmsElementSignupFormLegal {...this.props} />
              </div>

              <SimpleSubmitButton
                label={buttonTitle}
                iconClass="fad fa-chevron-double-right"
                entityChanged={this.getEntityChanged()}
                errorButton={this.hasValidationErrorButton()}
                pending={formStatus.pending}
                block
                defaultStyle="primary"
                disabled={isInCall}
              />
            </Form>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (urlPrefix, args) => {
      const captchaElement = document.querySelector('[name="frc-captcha-solution"]');
      args['frc-captcha-solution'] = captchaElement?.value;
      formStatusDispatchHelper('cmsSignupForm', urlPrefix + 'login/register', args, 'post', dispatch);
    },
  };
};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    meta: state.entityMeta,
    formStatus: state.formStatus.cmsSignupForm,
    isInCall: communicationVideoModule.selectors.isInCall(state),
  };
};

// export default withRouter(injectIntl(connect(mapStateToProps)(SignupForm)));
export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(SignupForm)
  )
);
