import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Button } from 'react-bootstrap';
import Modal from 'components/Modal';

import { formReset } from 'actions/FormStatusActions';

import CmsElementLeadForm from 'components/cms/elements/CmsElementLeadForm';

class ModalCarBuyInquiry extends React.Component {
  componentDidUpdate = prevProps => {
    // if (this.props.formStatus.pending && !nextProps.formStatus.pending && !nextProps.formStatus.hasError) {
    // }

    const { show, resetForm } = this.props;

    if (prevProps.show && !show) {
      resetForm();
    }
  };

  render() {
    const {
      intl: { messages },
      intl,
      appIntl,
      show,
      close,
      item,
      formStatus,
    } = this.props;

    const params = [
      { name: 'buttonTitle', value: 'Anfrage senden' },
      { name: 'itemId', value: item.id },
      { name: 'leadSource', value: 'carDetailPage' },
      { name: 'requireAddress', value: 'yes' },
      { name: 'requireEmailContact', value: 'yes' },
      { name: 'requirePhoneContact', value: 'yes' },
      { name: 'requireMessage', value: 'yes' },
    ];

    return (
      <Modal show={show} onHide={close} bsSize="lg">
        <Modal.Header closeButton>
          <Modal.Title>Fahrzeug anfragen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CmsElementLeadForm intl={intl} appIntl={appIntl} params={params} />

          {!!formStatus.response && (
            <div class="text-right">
              <Button onClick={close}>{messages.close}</Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    formStatus: state.formStatus.cmsLeadForm,
  };
};
const mapDispatchToProps = () => {
  return {
    resetForm: () => {
      formReset('cmsLeadForm');
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ModalCarBuyInquiry)
  )
);
