import React from 'react';
import { connect } from 'react-redux';
import { Tab, Row, Col, Nav, NavItem } from 'react-bootstrap';
import Scrollbar from 'react-scrollbars-custom';
import Panel from 'react-bootstrap/lib/Panel';
import _, { find as _find, findIndex as _findIndex, forEach as _forEach } from 'lodash';
import { getNameInCurrentLanguage } from 'helpers/intl';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { findSpace, getRasterSlots, isSpaceFreeAt, lookForTemplateBlock } from './actions/Raster';
import { setLoginForwardUrl } from '../../actions/LoginActions';
import { setVariables } from '../../actions/EditorActions';
import ReactTooltip from "react-tooltip";
import {S3_LINKS} from "js/constants";

class TemplateBlockCategory extends React.PureComponent {
  constructor(props) {
    super(props);

    this.categoryRef = React.createRef();
    this.state = {
      open: false,
    };
  }

  componentDidUpdate = () => {
    ReactTooltip.rebuild();
  }

  componentDidMount = () => {};

  updateStage = () => {
    const { updatePageSize } = this.props;

    updatePageSize(document.getElementById('stage').offsetWidth, document.getElementById('stage').offsetHeight);
  };

  insertBlock = e => {
    const { insertContentBlock, setVariables } = this.props;
    const blockId = e.target.getAttribute('data-block');
    const blockIndex = e.target.getAttribute('data-index');
    setVariables('unsavedtrue');
    insertContentBlock(blockId, blockIndex);
  };

  getSortedBlocks = (cRow, cCol, blocks) => {

    let col = 1;
    let cRowInit = cRow;
    let sortedBlock = [];
    for(let i = 0; i < blocks.length; i++){
      if(blocks[i].page_space_needed <= cRow){
        for(let a = 0; a < sortedBlock.length; a++){
          if(sortedBlock.length > 0 && sortedBlock[a].col === col){
            sortedBlock[a].R = 0;
          }
        }
        cRow = cRow - blocks[i].page_space_needed;
      }
      else{
        col = col + 1;
        cRow = cRowInit;
        cRow = cRow - blocks[i].page_space_needed;
      }
      sortedBlock.push({id:blocks[i].id, col: col, R:cRow});
    }
    return sortedBlock;
  }

  getMaxFreeRowInSpace = (_sortedBlocks) => {
    let freeSpace = 0;
    for(let b = 0; b < _sortedBlocks.length; b++){
      if(_sortedBlocks[b].R > freeSpace)
      {
        freeSpace = _sortedBlocks[b].R
      }
    }
    return freeSpace;
  }

  render() {
    const {
      templateBlocks,
      pageSpace,
      currentPage,
      usedBlocks,
      raster,
      templateSettings,
      pageAmount,
      appIntl,
      blockContainer,
      init,
      endless,
      ccToolbar,
    } = this.props;
    const { open } = this.state;
    const {
      intl: { messages },
    } = this.props;
    const blockCategory = [];
    const blockCategoryTab = [];
    let firstElement = '';
    let eventKey = '';
    let freeSlots = [];
    let containerClass = '';
    if (blockContainer) {
      const containerBlock = _find(usedBlocks, { id: blockContainer });
      if (containerBlock) {
        for (let k = 0; k < containerBlock.container_cols; k++) {
          if (containerBlock.container_space * containerBlock.container_cols > containerBlock.space_consumed) {
            if (containerBlock.space_consumed <= containerBlock.container_space && k === 0) {
              freeSlots.push(containerBlock.container_space - containerBlock.space_consumed);
            } else if (k > 0 && containerBlock.space_consumed > containerBlock.container_space * k) {
              let freeSpace = 0;
              for (
                let spaceConsumed = containerBlock.space_consumed;
                spaceConsumed > 0;
                spaceConsumed -= containerBlock.container_space
              ) {
                freeSpace = containerBlock.container_space - spaceConsumed;
              }
              freeSlots.push(freeSpace > 0 ? freeSpace : 0);
            } else if (k > 0) {
              // nothing
            }
          }
        }
        /* calculate freeSpace if all Cols are full */
        let freeSpace = 0;
        let _sortedBlocks = this.getSortedBlocks(containerBlock.container_space, containerBlock.container_cols, containerBlock.blocks);
        if (_sortedBlocks.length > 0) {
          let startSort = false;
          _forEach(_sortedBlocks, (sBlock, i) => {
            if(sBlock.col === containerBlock.container_cols){
              startSort = true
            }
          });
          if(startSort) {
            freeSpace = this.getMaxFreeRowInSpace(_sortedBlocks)
            freeSlots.push(freeSpace);
            freeSlots = [freeSpace];
          }else{
            freeSlots.push(containerBlock.container_space);
          }
        }else{
          freeSlots.push(containerBlock.container_space);
        }
        if (containerBlock.container_class) {
          containerClass = containerBlock.container_class;
        }
        /* calculate end */

      } else {
        console.log('Error: container block not found');
      }
    } else {
      freeSlots = getRasterSlots(raster, currentPage, pageSpace, 0, 0, endless);
    }
    _forEach(templateBlocks, (category, i) => {
      const blockCategoryVariants = [];
      eventKey = 'block-category-' + i;
      if (i === 0) {
        firstElement = eventKey;
      }
      if (
        (!init && getNameInCurrentLanguage(category.category, appIntl) === 'Initial') ||
        getNameInCurrentLanguage(category.category, appIntl) !== 'Initial'
      ) {
        blockCategory.push(
          <NavItem
            key={eventKey}
            eventKey={eventKey}
            data-tip={getNameInCurrentLanguage(category.category, appIntl)}
            data-for="left"
            onClick={() => {
              this.setState({ open: true });
            }}
          >
            <i className={category.thumb} />
          </NavItem>
        );
        _forEach(category.variants, (variant, j) => {
          const blockCategoryVariantsBlocks = [];

          _forEach(variant.blocks, (block, k) => {
            const blockIndex = i + '.' + j + '.' + k;
            let excludePages = [];
            if (block.exclude_pages !== '' && block.exclude_pages !== false) {
              excludePages = block.exclude_pages.split('.');
              if (excludePages.length === 0) {
                excludePages = block.exclude_pages.split(',');
              }
            }

            const styles = {
              background: `url(${S3_LINKS.TEMPLATES}${templateSettings.id}/images/${block.thumb}) no-repeat center center`,
            };
            let blockUsed = false;
            let variantBlockUsed = false;
            let documentBlockUsed = false;
            let categoryBlockUsed = false;
            let oversizeUnavailable = false;

            if (_findIndex(usedBlocks, ['template_id', block.id]) !== -1) {
              blockUsed = true;
            }else if(blockUsed === false){
              _.forEach(usedBlocks, _block => {
                if (_.findIndex(_block.blocks, ['template_id', block.id]) >= 0) {
                  blockUsed = true;
                }
              });
            }
            let spaceBlocked = false;

            if (block.once_per_document && lookForTemplateBlock(raster, block.id)) {
              documentBlockUsed = true;
            }else if(block.once_per_document && _.findIndex(this.props.allUsedBlocks, ['id', block.id]) >= 0){
                documentBlockUsed = true;
            }
            if (block.top >= 0 && raster[currentPage][block.top] !== '') {
              spaceBlocked = true;
            }
            let noRasterSpace = true;
            _forEach(freeSlots, slot => {
              if (slot >= block.page_space_needed) {
                noRasterSpace = false;
              }
            });
            if (block.page_space_needed === 0) {
              noRasterSpace = false;
            }
            if (
              typeof block.once_per_variant !== 'undefined' &&
              block.once_per_variant !== '' &&
              _findIndex(usedBlocks, ['once_per_variant', block.once_per_variant]) !== -1
            ) {
              variantBlockUsed = true;
            }
           else if( typeof block.once_per_variant !== 'undefined' &&
              block.once_per_variant !== '' &&
              _findIndex(usedBlocks, ['once_per_variant', block.once_per_variant]) === -1){
             _.forEach(usedBlocks, _block => {
               if (_.findIndex(_block.blocks, ['once_per_variant', block.once_per_variant]) >= 0) {
                 variantBlockUsed = true;
               }
             });
           }
            if (
              typeof block.once_per_category !== 'undefined' &&
              block.once_per_category !== '' &&
              _findIndex(usedBlocks, ['once_per_category', block.once_per_category]) !== -1
            ) {
              categoryBlockUsed = true;
            }
            else if( typeof block.once_per_category !== 'undefined' &&
              block.once_per_category !== '' &&
              _findIndex(usedBlocks, ['once_per_category', block.once_per_category]) === -1){
              _.forEach(usedBlocks, _block => {
                if (_.findIndex(_block.blocks, ['once_per_category', block.once_per_category]) >= 0) {
                  categoryBlockUsed = true;
                }
              });
            }
            let rasterOverflow = false;
            if(block.top >= 0){
              _forEach(usedBlocks, (usedBlock) => {
                if(usedBlock.top > block.top && block.top + block.page_space_needed > usedBlock.top){
                  rasterOverflow = true;
                }
              });
            }

            if (
              typeof block.oversize !== 'undefined' &&
              block.oversize !== 0 &&
              block.oversize !== null &&
              !noRasterSpace
            ) {
              if (
                (block.oversize_direction === 'Right' &&
                  currentPage + 1 === pageAmount &&
                  templateSettings.format.order_pages !== 'down') ||
                (block.oversize_direction === 'Left' &&
                  currentPage + 1 === pageAmount / 2 &&
                  templateSettings.format.divide_pages === 'half' &&
                  templateSettings.format.order_pages === 'down-up') ||
                (block.oversize_direction === 'Right' &&
                  currentPage + 1 === pageAmount / 2 &&
                  templateSettings.format.divide_pages === 'half' &&
                  templateSettings.format.order_pages !== 'down-up') ||
                (block.oversize_direction === 'Left' &&
                  currentPage === pageAmount / 2 &&
                  templateSettings.format.divide_pages === 'half') ||
                (block.oversize_direction === 'Right' &&
                  currentPage === 0 &&
                  templateSettings.format.order_pages === 'down-up') ||
                (block.oversize_direction === 'Left' &&
                  currentPage === 0 &&
                  templateSettings.format.order_pages !== 'down-up' &&
                  templateSettings.format.order_pages !== 'down')
              ) {
                oversizeUnavailable = true;
              }
              if (!oversizeUnavailable) {
                const offsetTop = findSpace(raster, currentPage, block.page_space_needed, 0, pageSpace);
                let checkLinkedTo = false;
                if (
                  (block.oversize_direction === 'Right' && templateSettings.format.order_pages !== 'down-up') ||
                  (block.oversize_direction === 'Right' &&
                    templateSettings.format.order_pages === 'down-up' &&
                    currentPage >= pageAmount / 2) ||
                  (block.oversize_direction === 'Left' &&
                    templateSettings.format.order_pages === 'down-up' &&
                    currentPage < pageAmount / 2)
                ) {
                  checkLinkedTo = isSpaceFreeAt(raster, currentPage + 1, offsetTop, block.page_space_needed);
                } else if (
                  (block.oversize_direction === 'Left' && templateSettings.format.order_pages !== 'down-up') ||
                  (block.oversize_direction === 'Right' &&
                    templateSettings.format.order_pages === 'down-up' &&
                    currentPage < pageAmount / 2) ||
                  (block.oversize_direction === 'Left' &&
                    templateSettings.format.order_pages === 'down-up' &&
                    currentPage >= pageAmount / 2)
                ) {
                  checkLinkedTo = isSpaceFreeAt(raster, currentPage - 1, offsetTop, block.page_space_needed);
                }
                oversizeUnavailable = !checkLinkedTo;
              }
            }
            let parentNotAvailable = false;
            if(block.block_combination_child !== ""){
              parentNotAvailable = true;
              let blockCombinationChild = block.block_combination_child.split(",");
              _forEach(blockCombinationChild, (blockCC, i) => {
                if(_findIndex(usedBlocks, ['block_combination_parent', blockCC]) !== -1) {
                  parentNotAvailable = false;
                }
              });
              if(parentNotAvailable === true) {
                _.forEach(usedBlocks, _block => {
                  _forEach(blockCombinationChild, (blockCC, i) => {
                    if (_findIndex(_block.blocks, ['block_combination_parent', blockCC]) !== -1) {
                      parentNotAvailable = false;
                    }
                  });
                });
              }
            }

            let disableGroup = false;
            if(block.disable_group !== "" && block.disable_group !== undefined){
              let usedBlockVariant = usedBlocks; // default perPage
              let splitBlockOptions = block.disable_group.split("#");
              let splitBlockVariants = splitBlockOptions[0].split(",");
              let splitDisableGroups = splitBlockOptions[1].split(",");

                _forEach(splitDisableGroups, (blockDG, i) => {
                  if(splitBlockVariants[i] === "D"){
                    usedBlockVariant = this.props.allUsedBlocksContent;
                  }
                  let even = false;
                  if (i % 2 == 0) {
                    even = true;
                  }
                  if (even) {
                    _forEach(usedBlockVariant, (_usedBlock, ix) => {
                      let splitUsedBlockDisableGroups = [];
                      if (_usedBlock.disable_group !== "" && _usedBlock.disable_group !== undefined) {
                        splitUsedBlockDisableGroups = _usedBlock.disable_group.split("#");
                        let splitUsedDisableGroups = splitUsedBlockDisableGroups[1].split(",");
                        if (splitUsedDisableGroups?.length > 0 && splitUsedDisableGroups[i + 1] === splitDisableGroups[i] && splitUsedDisableGroups[i + 1] !== "x" && splitDisableGroups[i] !== "x") {
                          disableGroup = true;
                        }
                      }
                    });

                    if(disableGroup === false) {
                      _forEach(usedBlockVariant, (_usedBlock, ix) => {
                        if(_usedBlock?.blocks?.length > 0) {
                          _.forEach(_usedBlock?.blocks, (blockInsideContainer, iic) => {
                            let splitUsedBlockDisableGroupsIC = [];
                            if (blockInsideContainer.disable_group !== "" && blockInsideContainer.disable_group !== undefined) {
                              splitUsedBlockDisableGroupsIC = blockInsideContainer.disable_group.split("#");
                              let splitUsedDisableGroupsIC = splitUsedBlockDisableGroupsIC[1].split(",");
                              if (splitUsedDisableGroupsIC?.length > 0 && splitUsedDisableGroupsIC[i + 1] === splitDisableGroups[i] && splitUsedDisableGroupsIC[i + 1] !== "x" && splitDisableGroups[i] !== "x") {
                                disableGroup = true;
                              }
                            }
                          });
                        }
                      });
                    }
                  }
                });

            }


            if (
              (block.once_per_page && blockUsed) || // nur einmal pro Seite verfügbar
              rasterOverflow ||
              categoryBlockUsed ||
              variantBlockUsed ||
              documentBlockUsed ||
              noRasterSpace ||
              spaceBlocked ||
              (blockContainer && block.inner === false) ||
              (blockContainer && block.container_class !== containerClass) ||
              (!blockContainer && block.only_inside_block === true) ||
              (!blockContainer && block.inline_block === true) ||
              oversizeUnavailable ||
              parentNotAvailable ||
              disableGroup ||
              (typeof block.restricted_to_page !== 'undefined' &&
                block.restricted_to_page >= 0 &&
                parseInt(currentPage) + 1 !== parseInt(block.restricted_to_page)) || // nur für bestimmte Seite verfügbar
              (excludePages.length > 0 && excludePages.indexOf((currentPage + 1).toString()) >= 0) ||
              (templateSettings.last_page_number === 'last' &&
                parseInt(currentPage) + 1 === pageAmount &&
                excludePages.includes('last')) ||
              (block.only_last && parseInt(currentPage) + 1 !== pageAmount)
            ) {
              // nicht auf dieser Seite verfügbar
              blockCategoryVariantsBlocks.push(
                <div
                  key={"blockCategoryVariantsBlocksDisabled" + block.id + "_" + i}
                  className="thumb disabled"
                  data-tip={getNameInCurrentLanguage(block.title, appIntl)}
                  data-for="top"
                >
                  <a
                    href="#"
                    data-block={block.id}
                    data-index={blockIndex}
                    data-space={block.page_space_needed}
                    style={styles}
                  />
                </div>
              );
            } else {
              blockCategoryVariantsBlocks.push(
                <div  key={"blockCategoryVariantsBlocks" + block.id + "_" + i} className="thumb" data-tip={getNameInCurrentLanguage(block.title, appIntl)} data-for="top">
                  <a
                    href="#"
                    data-block={block.id}
                    data-index={blockIndex}
                    data-space={block.page_space_needed}
                    style={styles}
                    onClick={this.insertBlock}
                  />
                </div>
              );
            }
          });
          const panelId = eventKey + '-' + j;
          blockCategoryVariants.push(
            <Panel key={panelId} id={panelId} defaultExpanded>
              <Panel.Heading>
                <Panel.Title toggle>{getNameInCurrentLanguage(variant.title, appIntl)}</Panel.Title>
              </Panel.Heading>
              <Panel.Collapse>
                <Panel.Body>{blockCategoryVariantsBlocks}</Panel.Body>
              </Panel.Collapse>
            </Panel>
          );
        });
        blockCategoryTab.push(
          <Tab.Pane key={eventKey} eventKey={eventKey}>
            <div className="title">
              <p>{getNameInCurrentLanguage(category.category, appIntl)}</p>
            </div>
            <div className="block-elements">
              <Scrollbar style={{ width: '100%', height: '100%' }}>{blockCategoryVariants}</Scrollbar>
            </div>
          </Tab.Pane>
        );
      }
    });
    let toolbarOpen = '';
    let toolbarIcon = <i className="far fa-angle-double-left" />;
    if (open) {
      toolbarOpen = 'opened';
      toolbarIcon = <i className="far fa-angle-double-right" />;
    }
    return (
      <Tab.Container
        id="toolbar-blocks"
        className={toolbarOpen}
        defaultActiveKey={firstElement}
        style={{ display: ccToolbar }}
      >
        <Row className="clearfix">
          <Col className="block-icons">
            <a
              data-tip={messages.editor.add_content}
              data-for="left"
              className="expand-toggle"
              onClick={() => {
                this.setState({ open: !open });
              }}
            >
              {toolbarIcon}
            </a>
            <Nav bsStyle="pills" stacked>
              {blockCategory}
            </Nav>
          </Col>
          <Col className="block-content">
            <Tab.Content animation>{blockCategoryTab}</Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    ccToolbar: state.layout.ccToolbar,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setVariables: data => {
      dispatch(setVariables(data));
    },
  };
};

// export default connect(mapStateToProps)(TemplateBlockCategory);

export default injectIntl(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(TemplateBlockCategory)
  )
);
