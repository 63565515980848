import React from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import Select, { Creatable } from 'react-select';
import { isObject as _isObject } from 'lodash';

class FormTags extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
    };

    this.inputRef = React.createRef();
  }

  checkFocused = () => {
    return this.state.focused ? 'focused ' : '';
  };

  checkRequired = () => {
    return this.props.meta.validate.rules.indexOf('required') !== -1 ? 'required ' : '';
  };

  checkError = () => {
    return this.props.validation.valid ? '' : 'has-error ';
  };

  // check if user may read this field
  checkAccess = () => {
    return this.props.meta.acl.read;
  };

  // check if user may write to this field
  checkReadOnly = () => {
    return !this.props.meta.acl.write;
  };

  catchFocus = () => {
    this.inputRef.current.focus();
  };

  onChange = value => {
    const { name, onChange } = this.props;

    const vals = value.map(v => {
      return v.value;
    });

    onChange(name, vals);
  };

  getValues = () => {
    const { value } = this.props;

    if (value && value.length !== undefined) {
      return value.map(v => {
        return _isObject(v) ? v : { label: v, value: v };
      });
    }

    return null;
  };

  checkDisabled = () => {
    const { disabled, meta } = this.props;
    if (disabled) {
      return true;
    }
    if (!meta.acl.write) {
      return true;
    }
    return false;
  };

  render() {
    const { label, name, validation, creatable, messages, options } = this.props;
    const { focused } = this.state;

    const SelectComponent = creatable ? Creatable : Select;

    return (
      <div
        className={
          'form-group form-group-default ms-select--multi ' +
          this.checkFocused() +
          this.checkRequired() +
          this.checkError() +
          (this.checkDisabled() ? 'disabled ' : '')
        }
        onClick={this.catchFocus}
        style={{ overflow: 'visible' }}
      >
        <label>{label}</label>

        <SelectComponent
          isMulti
          ref={this.inputRef}
          name={name}
          onChange={this.onChange}
          value={this.getValues()}
          placeholder=""
          noResultsText={messages.autocomplete.noResultsText}
          loadingMessage={e => messages.autocomplete.loadingPlaceholder}
          onFocus={() => this.setState({ focused: true })}
          onBlur={() => this.setState({ focused: false })}
          isClearable={false}
          isDisabled={this.checkDisabled()}
          classNamePrefix="ms-select"
          options={options}
        />

        {!validation.valid && (
          <Overlay show={focused} target={this.inputRef.current} placement="top">
            <Popover className="popover-modal" id={`error_${name}_${label}`}>
              {validation.helpText}
            </Popover>
          </Overlay>
        )}
      </div>
    );
  }
}
FormTags.defaultProps = {
  label: '',
  meta: { validate: { rules: '' }, acl: { read: true, write: true } },
  validation: { valid: true },
  value: '',
  onChange: () => {},
  disabled: false,
  type: 'text',
  prefixAddon: '',
  suffixAddon: '',
  infoText: '',
  onBlur: () => {},
  options: null,
  creatable: true,
};

export default FormTags;
