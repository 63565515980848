import React, { Component } from 'react';
import { ButtonGroup, Button, DropdownButton, MenuItem } from 'react-bootstrap';

export default class Pagination extends Component {
  conditionalScrollTop = () => {
    if (window.scrollY > 120) {
      window.scroll(0, 0);
    }
  };

  render() {
    const { count, resPerPage, activePage, handleSelect, isDropDown } = this.props;

    const firstDisabled = count > resPerPage && activePage > 1 ? false : true;
    const prevDisabled = activePage > 1 ? false : true;
    const nextDisabled = activePage * resPerPage < count ? false : true;
    const lastDisabled = activePage < Math.ceil(count / resPerPage) ? false : true;

    const pages = Math.ceil(count / resPerPage);

    const pagesList = [];

    for (let i = 1; i <= pages; i++) {
      pagesList.push(
        <MenuItem key={i} eventKey={i}>{`${count > 0 ? (i - 1) * resPerPage + 1 : 0} - ${
          i * resPerPage > count ? count : i * resPerPage
        } / ${count}`}</MenuItem>
      );
    }

    return (
      <ButtonGroup>
        <Button
          disabled={firstDisabled}
          onClick={e => {
            e.preventDefault();
            if (firstDisabled) {
              return;
            }
            handleSelect(1);
            this.conditionalScrollTop();
          }}
        >
          <i class="fa fa-arrow-left" />
        </Button>

        <Button
          disabled={prevDisabled}
          onClick={e => {
            e.preventDefault();
            if (prevDisabled) {
              return;
            }
            handleSelect(activePage - 1);
            this.conditionalScrollTop();
          }}
        >
          <i class="fa fa-chevron-left" />
        </Button>

        {!isDropDown ? (
          <Button style={{ cursor: 'auto' }}>
            {count > 0 ? (activePage - 1) * resPerPage + 1 : 0} -{' '}
            {activePage * resPerPage > count ? count : activePage * resPerPage}
            {' / '}
            {count}
          </Button>
        ) : (
          <DropdownButton
            title={`${count > 0 ? (activePage - 1) * resPerPage + 1 : 0} - ${
              activePage * resPerPage > count ? count : activePage * resPerPage
            } / ${count}`}
            id="page-select"
            bsSize="small"
            drop="up"
            onSelect={handleSelect}
          >
            {pagesList}
          </DropdownButton>
        )}

        <Button
          disabled={nextDisabled}
          onClick={e => {
            e.preventDefault();
            if (nextDisabled) {
              return;
            }
            handleSelect(activePage + 1);
            this.conditionalScrollTop();
          }}
        >
          <i class="fa fa-chevron-right" />
        </Button>

        <Button
          disabled={lastDisabled}
          onClick={e => {
            e.preventDefault();
            if (lastDisabled) {
              return;
            }
            handleSelect(Math.ceil(count / resPerPage));
            this.conditionalScrollTop();
          }}
        >
          <i class="fa fa-arrow-right" />
        </Button>
      </ButtonGroup>
    );
  }
}
