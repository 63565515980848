import React from 'react';
import { connect } from 'react-redux';
import { find as _find, forEach as _forEach, cloneDeep as _cloneDeep } from 'lodash';
import ReactDOM from 'react-dom';
import { Alert, Button } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import ImageEditorRc from 'js/cc-editor/build/react-image-editor';
import Slider from 'rc-slider/lib/Slider';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { createObjectURL } from 'helpers/upload';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import FormSwitchery from 'components/forms/FormSwitchery';
import { confirmAlert } from 'react-confirm-alert';
import { S3_LINKS } from 'js/constants';
import MediaTabs from '../MediaTabs';
import EditorApplyStyles from '../EditorApplyStyles';
import Loader from '../../../components/layout/Loader';
import FormInput from '../../../components/forms/FormInput';
import { getTimezoneFromState } from '../../../helpers/intl';

const ContextModal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('context-modal'));
};

class ImageGalleryElement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      imagesEdit: [],
      showImagesEdit: false,
      imageFile: undefined,
      showImageGalleryOptions: false,
      showImageTooSmallError: false,
      showImageEditor: false,
      showMedia: false,
      editorImageSrc: null,
      imageSrc: null,
      imageKey: '',
      xZoom: 0,
      imgWidth: 0,
      imgHeight: 0,
      imgFilename: '',
      imgThumb: '',
      imgCopyright: '',
      path: '',
      IMAGE_URL: [],
      numberOfImagesByPage: 3,
      imageWidth: 380,
      showEditTitle: false,
      showEditDescription: false,
      showEditCopyright: false,
      textNumber: 0,
      schedules: [],
      schedule: [],
      schedulesEdit: [],
      showSchedulesEdit: false,
      imageSort: '',
      autoPlay: false,
    };
    this.cropperRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.params !== this.props.params) this.getSliderData();
  }

  setAutoPlay = (name, value) => {
    this.setState({ autoPlay: value }, () => {
      this.setSliderData(name);
    });
  };

  setSliderData = (imageUrl, reverse) => {
    const { IMAGE_URL } = this.state;
    const { id, attributes, updateParam } = this.props;
    const IMAGE_URL_CLONE2 = _cloneDeep(IMAGE_URL);
    const {
      intl: { messages },
    } = this.props;
    if (imageUrl !== 'edit' && imageUrl !== 'autoplay') {
      const IMG_ARR = [
        imageUrl,
        "",
        "",
        messages.editor.edit_copyright,
      ];
      IMAGE_URL_CLONE2.push(IMG_ARR);
    }

    let param = '';
    _forEach(IMAGE_URL_CLONE2, (schedule, i) => {
      param += schedule[0] + '#aaa#' + schedule[1] + '#aaa#' + schedule[2] + '#aaa#' + schedule[3];
      if (i < IMAGE_URL_CLONE2.length - 1) {
        param += '#cc#';
      }
    });
    param = param + '#opt#' + this.state.autoPlay;

    const overflow = false;
    const defaultImage = false;
    const errors = [{ overflow, defaultImage }];
    let showOptions = false;
    updateParam(id, attributes['data-name'], param, errors);
    if (reverse === true || imageUrl === 'autoplay') showOptions = true;
    this.setState({ IMAGE_URL: IMAGE_URL_CLONE2, showImageGalleryOptions: showOptions }, () => {
      this.getSliderData();
    });
  };

  getSliderData = () => {
    const { attributes, params } = this.props;
    const param = _find(params, ['name', attributes['data-name']]);
    const terms = [];
    let outputAndSettings;
    let _autoPlay = false;

    if (param) {
      outputAndSettings = param.value.split('#opt#');
      const output = outputAndSettings[0]?.split('#cc#');
      _forEach(output, entry => {
        const schedule = entry.split('#aaa#');
        terms.push(schedule);
      });
      _autoPlay = String(true) === (outputAndSettings.length > 0 ? outputAndSettings[1] : false);
    }
    this.setState({ IMAGE_URL: terms, schedulesEdit: terms, autoPlay: _autoPlay }, () => {});
  };

  handleOnChange = e => {
    const { attributes, imageUpload, id, clientImageRightsQuery } = this.props;
    const { IMAGE_URL } = this.state;
    const file = e.target.files[0];

    if (file) {
      const img = new Image();
      img.src = createObjectURL(file);
      img.onload = () => {
        const minwidth = parseInt(attributes['data-width'], 10);
        const minheight = parseInt(attributes['data-height'], 10);
        const imgwidth = img.width;
        const imgheight = img.height;
        if (imgwidth < minwidth || imgheight < minheight) {
          this.setState({ showImageTooSmallError: true, showMedia: false, showImagesEdit: false });
        } else if (imgwidth === minwidth && imgheight === minheight && !clientImageRightsQuery) {
          this.setState({ showImageTooSmallError: false, showMedia: false, showImageEditor: false }, () => {
            this.update(img.src);

            const ownKey = this.state.imageSort;
            const IMAGE_URL_CLONE3 = _cloneDeep(IMAGE_URL);
            imageUpload(
              id,
              file,
              null,
              attributes['data-name'],
              null,
              imgwidth,
              imgheight,
              null,
              null,
              null,
              null,
              IMAGE_URL_CLONE3,
              ownKey,
              '',
              this.state.autoPlay
            );
            this.setSliderData(img.src);
          });
        } else {
          this.setState({
            showImageTooSmallError: false,
            showMedia: false,
            imageKey: null,
            showImageEditor: true,
            editorImageSrc: img,
            imageSrc: file,
            imgWidth: imgwidth,
            imgHeight: imgheight,
          });
          if (clientImageRightsQuery) {
            this.imagePermissionQuery();
          }
        }
      };
    }
  };

  DataURIToBlob = dataURI => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  imageUpload = image => {
    const data = this.cropperRef.current.getData();
    const { imageUpload, id, attributes } = this.props;
    const { imageSrc, imageKey, imgWidth, imgHeight, imgFilename, imgThumb, imgCopyright, IMAGE_URL } = this.state;
    const imageBlob = this.DataURIToBlob(image);
    const imageUrl = URL.createObjectURL(imageBlob);
    let updateF;
    const ownKey = this.state.imageSort;
    const IMAGE_URL_CLONE3 = _cloneDeep(IMAGE_URL);

    imageUpload(
      id,
      imageSrc,
      imageKey,
      attributes['data-name'],
      data,
      imgWidth,
      imgHeight,
      imgFilename,
      imgThumb,
      imgCopyright,
      updateF,
      IMAGE_URL_CLONE3,
      ownKey,
      '',
      this.state.autoPlay
    );

    this.setSliderData(imageUrl);
    this.setState({
      showImageTooSmallError: false,
      showMedia: false,
      showImageEditor: false,
      xZoom: 0,
      editorImageSrc: '',
      showImagesEdit: false,
    });
  };

  loadOriginal = () => {
    const { attributes } = this.props;
    const original = attributes['data-original'];
    const originalSrc = S3_LINKS.MEDIA_IMGS_ORIGINAL + original;
    const imgC = new Image();
    imgC.src = originalSrc;
    imgC.crossOrigin = 'anonymous';
    imgC.onload = () => {
      const imgwidth = imgC.width;
      const imgheight = imgC.height;
      this.setState({
        showImageTooSmallError: false,
        showMedia: false,
        showImageEditor: true,
        editorImageSrc: imgC,
        imageSrc: null,
        imageKey: original,
        imgWidth: imgwidth,
        imgHeight: imgheight,
        imgThumb: '',
        imgCopyright: '',
      });
    };
  };

  loadMedia = (original, path, filename, thumb, copyright) => {
    let originalSrc = path + original;
    if (original === 'pixabay') {
      originalSrc = path;
      original = path;
    }

    const imgC = new Image();
    imgC.src = originalSrc;
    imgC.crossOrigin = 'anonymous';
    imgC.onload = () => {
      const imgwidth = imgC.width;
      const imgheight = imgC.height;
      this.setState({
        showImageTooSmallError: false,
        showMedia: false,
        showImageEditor: true,
        editorImageSrc: imgC,
        imageSrc: null,
        imageKey: original,
        imgWidth: imgwidth,
        imgHeight: imgheight,
        imgFilename: filename,
        imgThumb: thumb,
        imgCopyright: copyright,
        path,
        showDescriptionInputField: false,
      });
    };
  };

  handleClick = () => {
    this.setState({ showMedia: true, showImageEditor: false });
  };

  handleMedia = (original, path, filename = '', thumb = '', copyright = '') => {
    this.loadMedia(original, path, filename, thumb, copyright);
  };

  handleFileUpload = (e, option) => {
    this.inputElement.click();
    this.setState({ showDescriptionInputField: option });
  };

  componentDidMount = () => {
    this.getSliderData();
  };

  showImagesEdit = () => {
    this.setState({ showImagesEdit: true });
  };

  showImageGalleryOptionsEdit = () => {
    const { filesUploading } = this.props;
    if (filesUploading && filesUploading.length === 0) {
      this.setState({ showImageGalleryOptions: true });
      this.getSliderData();
    }
  };

  closeImagesEdit = () => {
    this.setState({ showImagesEdit: false });
  };

  handleGalleryOptions = (name, value) => {
    if (name === 'nSeite' && value) {
      this.setState({ numberOfImagesByPage: value });
    }
    if (name === 'hBild' && value) {
      if (value > 600) {
        this.setState({ imageWidth: 600 });
      } else {
        this.setState({ imageWidth: value });
      }
    }
  };

  handleAddImage = option => {
    this.setState({ imageSort: option }, () => {
      this.setState({ showImagesEdit: true });
    });
  };

  handleIEclose = () => {
    this.setState({ showImagesEdit: false, showImageEditor: false, showImageGalleryOptions: false });
  };

  showTextInput = index => {
    this.setState({
      showEditTitle: true,
      textNumber: index,
      showEditDescription: true,
      showEditCopyright: true,
    });
  };

  editText = (name, value) => {
    const { IMAGE_URL, textNumber } = this.state;
    if (name === 'imageTitle') {
      const IMAGE_URL_CLONE = _cloneDeep(IMAGE_URL);
      IMAGE_URL_CLONE[textNumber][1] = value;
      this.setState({ IMAGE_URL: IMAGE_URL_CLONE });
    }
    if (name === 'imageDescription') {
      const IMAGE_URL_CLONE = _cloneDeep(IMAGE_URL);
      IMAGE_URL_CLONE[textNumber][2] = value;
      this.setState({ IMAGE_URL: IMAGE_URL_CLONE });
    }
    if (name === 'imageCopyright') {
      const IMAGE_URL_CLONE = _cloneDeep(IMAGE_URL);
      IMAGE_URL_CLONE[textNumber][3] = value;
      this.setState({ IMAGE_URL: IMAGE_URL_CLONE });
    }
  };

  handleDeleteImage = index => {
    const { IMAGE_URL } = this.state;
    const IMAGE_URL_CLONE5 = _cloneDeep(IMAGE_URL);

    const sliced = IMAGE_URL_CLONE5.slice(0, index);
    let sliced2 = [];
    if (index < IMAGE_URL_CLONE5.length - 1) {
      sliced2 = IMAGE_URL_CLONE5.slice(index + 1);
    }

    const NEW_IMAGE_URL = [...sliced, ...sliced2];
    this.setState({ IMAGE_URL: NEW_IMAGE_URL });
  };

  reverseImages = () => {
    const { IMAGE_URL } = this.state;
    const IMAGE_URL_REVERSE = _cloneDeep(IMAGE_URL);
    IMAGE_URL_REVERSE.reverse();
    this.setState({ IMAGE_URL: IMAGE_URL_REVERSE }, () => {
      this.setSliderData('edit', true);
    });
  };

  imagePermissionQuery = () => {
    const {
      intl: { messages },
    } = this.props;
    const options = {
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body" style={{ width: '100%' }}>
            <h1>{messages.image_permission_title}</h1>
            <p>{messages.image_permission_warning}</p>
            <div className="react-confirm-alert-button-group" style={{ width: '100%', margin: '0 auto' }}>
              <Button
                style={{ margin: '5px', width: '100px' }}
                bsStyle="primary"
                onClick={() => {
                  onClose();
                  this.handleIEclose();
                }}
              >
                {messages.no}
              </Button>
              <Button
                style={{ margin: '5px', width: '100px' }}
                bsStyle="warning"
                onClick={() => {
                  onClose();
                }}
              >
                {messages.yes}
              </Button>
            </div>
          </div>
        );
      },
      childrenElement: () => <div />,
      closeOnEscape: false,
      closeOnClickOutside: false,
    };

    confirmAlert(options);
  };

  switchToPosition = (index, direction) => {
    const { IMAGE_URL } = this.state;
    const IMAGE_URL_CLONE = _cloneDeep(IMAGE_URL);

    // check if index is valid
    if (index >= 0 && index < IMAGE_URL_CLONE.length) {
      // calculate the index of the image to swap with
      let newIndex;
      if (direction === 'left') {
        newIndex = (index + IMAGE_URL.length - 1) % IMAGE_URL_CLONE.length;
      } else if (direction === 'right') {
        newIndex = (index + 1) % IMAGE_URL.length;
      }

      // Swap the positions of the images in the array
      [IMAGE_URL_CLONE[index], IMAGE_URL_CLONE[newIndex]] = [IMAGE_URL_CLONE[newIndex], IMAGE_URL_CLONE[index]];
    }
    this.setState({ IMAGE_URL: IMAGE_URL_CLONE });
  };

  render() {
    const { name: Tag, attributes, usedMedia, filesUploading, templateId, rootUser } = this.props;
    const {
      showImagesEdit,
      textNumber,
      showImageEditor,
      editorImageSrc,
      xZoom,
      showImageGalleryOptions,
      IMAGE_URL,
      numberOfImagesByPage,
      imageWidth,
      showEditTitle,
      showEditDescription,
      showEditCopyright,
      showImageTooSmallError,
    } = this.state;
    const enable = { display: 'block' };
    const ratio = attributes['data-height'] / attributes['data-width'];
    const canvasWidth = parseFloat(document.getElementsByTagName('body')[0].offsetWidth.toFixed(0)) - 200;
    const possibleZooms = parseFloat((attributes['data-width'] / canvasWidth).toFixed(0));
    const output = [];
    const IMGHeight = '100%';
    const IMGWidth = '100%';
    let originalSrc = '';
    const {
      intl: { messages },
    } = this.props;

    if (typeof attributes['data-original'] !== 'undefined' && typeof attributes['data-thumb'] !== 'undefined') {
      originalSrc = S3_LINKS.MEDIA_IMGS_THUMB + attributes['data-thumb'];
    }
    if (filesUploading && filesUploading.length > 0) {
      output.push(
        <div style={{ width: '1160px', textAlign: 'center' }}>
          <h1 style={{ color: '#D32A31' }}>Uploading...</h1>
          <Loader />
        </div>
      );
    } else {
      output.push(
        IMAGE_URL.map((slide, index) => (
          <SplideSlide key={index}>
            <div className="imgDiv">
              <i
                className="far fa-arrow-alt-left splide-slide-switch-left"
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  top: '50%',
                  left: '10px',
                  fontSize: '20px',
                  color: '#FFFFFF',
                  textShadow: '-3px 0px 2px #5F5F65',
                  cursor: 'pointer',
                }}
                onClick={() => this.switchToPosition(index, 'left')}
              />
              <img
                style={{
                  border: '1px solid #FFFFFF',
                  height: IMGHeight,
                  width: IMGWidth,
                  borderRadius: '10px',
                  objectFit: 'cover',
                  position: 'absolute',
                }}
                src={slide[0]}
                alt={slide[0]}
              />
              <i
                className="far fa-arrow-alt-right splide-slide-switch-right"
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  top: '50%',
                  right: '10px',
                  fontSize: '20px',
                  color: '#FFFFFF',
                  textShadow: '3px 0px 2px #5F5F65',
                  cursor: 'pointer',
                }}
                onClick={() => this.switchToPosition(index, 'right')}
              />
              <p
                className="sliderImageCopyright"
                onClick={() => {
                  this.showTextInput(index);
                }}
              >
                {slide[3] || messages.no_image_copyright}
              </p>
              <Button
                style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  padding: '0',
                  height: '30px',
                  width: '30px',
                  margin: '3px',
                }}
                bsStyle="danger"
                bsSize="large"
                onClick={() => {
                  this.handleDeleteImage(index);
                }}
              >
                X
              </Button>
            </div>

            <div style={{ width: '100%', textAlign: 'center' }}>
              <h4
                onClick={() => {
                  this.showTextInput(index);
                }}
              >
                {slide[1] || messages.no_image_title}
              </h4>
              <p
                onClick={() => {
                  this.showTextInput(index);
                }}
              >
                {slide[2] || messages.no_image_description}
              </p>
            </div>
          </SplideSlide>
        ))
      );
    }
    const previewImageCss = attributes['data-pre']
      ? '.cropper-face {opacity: 0.5; background: url(' +
        S3_LINKS.TEMPLATES +
        templateId +
        '/images/' +
        attributes['data-pre'] +
        ') center no-repeat;background-size: 100% 100%;}'
      : '';

    return (
      <>
        <div className="fileinputfield">
          <input ref={input => (this.inputElement = input)} type="file" onChange={this.handleOnChange} />
        </div>
        <Tag {...attributes} onClick={this.showImageGalleryOptionsEdit}>
          <div>
            {IMAGE_URL.length > 0 && numberOfImagesByPage > 0 && imageWidth > 0 ? (
              <div>
                <Splide
                  className="sliderCSS3erEditor"
                  options={{
                    fixedWidth: 380,
                    height: 400,
                    rewind: true,
                    perPage: 3,
                    gap: 0,
                    pagination: true,
                    breakpoints: {
                      '1160': {
                        fixedWidth: 380,
                        height: 400,
                      },
                    },
                  }}
                >
                  {output}
                </Splide>
              </div>
            ) : (
              <div className="wrap">
                <p>{messages.editor.no_images_selected}</p>
              </div>
            )}
          </div>
        </Tag>

        {showImageGalleryOptions && numberOfImagesByPage && imageWidth && (
          <ContextModal>
            <div id="contextModalWrap">
              <div id="contextModalStage" className="row">
                <h3>{messages.editor.gallery_settings}</h3>
                <div>
                  <Splide
                    className="sliderCSS3erOptions"
                    options={{
                      fixedWidth: 253,
                      height: 300,
                      rewind: true,
                      perPage: 3,
                      gap: 0,
                      pagination: true,
                      breakpoints: {
                        '780': {
                          fixedWidth: 253,
                          height: 300,
                        },
                      },
                    }}
                  >
                    {output}
                  </Splide>
                  <div className="image-gallery-autoplay" style={{ marginTop: '10px' }}>
                    <FormSwitchery
                      name="autoplay"
                      checked={this.state.autoPlay}
                      value
                      label="Autoplay"
                      onChange={(name, value) => this.setAutoPlay(name, value)}
                    />
                  </div>
                  <h4>{messages.editor.image_text_settings}</h4>
                  <p>
                    {messages.editor.minimum_size}:{' '}
                    {parseInt(attributes['data-width'], 10) + 'px x ' + parseInt(attributes['data-height'], 10) + 'px'}
                  </p>
                  {showImageTooSmallError && (
                    <div id="infobox">
                      <Alert>{messages.editor.too_low_resolution}</Alert>
                    </div>
                  )}
                  {!showEditTitle && !showEditDescription && !showEditCopyright ? (
                    <p>{messages.editor.change_slider}</p>
                  ) : (
                    <></>
                  )}

                  {showEditTitle && showEditDescription && showEditCopyright && IMAGE_URL.length - 1 >= textNumber ? (
                    <div style={{ width: '900px', margin: '0 auto', display: 'flex' }}>
                      <div
                        style={{
                          width: '150px',
                          height: '100px',
                          margin: '0 auto',
                          marginBottom: '10px',
                          border: '1px solid #FFFFFF',
                          borderRadius: '10px',
                          overflow: 'hidden',
                        }}
                      >
                        <img
                          style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                          src={IMAGE_URL[textNumber][0]}
                          alt="imageOfGallery"
                        />
                      </div>
                      <div style={{ width: '50%', marginRight: '25%' }}>
                        <FormInput
                          type="text"
                          label={messages.editor.change_image_title}
                          name="imageTitle"
                          onChange={this.editText}
                          value={IMAGE_URL[textNumber][1]}
                        />

                        <FormInput
                          type="text"
                          label={messages.editor.change_image_description}
                          name="imageDescription"
                          onChange={this.editText}
                          value={IMAGE_URL[textNumber][2]}
                        />

                        <FormInput
                          type="text"
                          label={messages.editor.image_copyright}
                          name="imageCopyright"
                          onChange={this.editText}
                          value={IMAGE_URL[textNumber][3]}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {this.state.IMAGE_URL && this.state.IMAGE_URL.length > 0 ? (
                    <div className="sliderButtons">
                      <Button
                        style={{ width: '100%', maxWidth: '250px' }}
                        bsStyle="success"
                        bsSize="large"
                        onClick={() => this.handleAddImage('sliderImageReverse')}
                      >
                        {messages.editor.add_image_front}
                      </Button>
                      <Button
                        style={{ width: '100%', maxWidth: '250px' }}
                        bsStyle="success"
                        bsSize="large"
                        onClick={() => this.handleAddImage('sliderImageDefault')}
                      >
                        {messages.editor.add_image_back}
                      </Button>
                      {this.state.IMAGE_URL.length > 1 ? (
                        <Button
                          style={{ width: '100%', maxWidth: '250px' }}
                          bsStyle="warning"
                          bsSize="large"
                          onClick={() => this.reverseImages()}
                        >
                          {messages.editor.slider_images_invert}
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <div className="sliderButtons">
                      <Button
                        style={{ width: '100%', maxWidth: '250px' }}
                        bsStyle="success"
                        bsSize="large"
                        onClick={() => this.handleAddImage('sliderImageDefault')}
                      >
                        {messages.editor.add_image}
                      </Button>
                    </div>
                  )}
                </div>
                <div className="galleryButtons">
                  <Button bsStyle="success" bsSize="large" onClick={() => this.setSliderData('edit')}>
                    {messages.editor.take_on}
                  </Button>
                  <Button bsStyle="danger" bsSize="large" onClick={this.handleIEclose} className="cancelIE">
                    {messages.editor.abort}
                  </Button>
                </div>
              </div>
            </div>
          </ContextModal>
        )}

        {showImagesEdit && (
          <ContextModal>
            <div id="contextModalWrap">
              <div id="contextModalStage" className="row">
                <MediaTabs
                  usedMedia={usedMedia}
                  loadMedia={this.loadMedia}
                  neededWidth={attributes['data-width']}
                  neededHeight={attributes['data-height']}
                  rootUser={rootUser}
                  originalSrc={originalSrc}
                  loadOriginal={this.loadOriginal}
                  handleFileUpload={this.handleFileUpload}
                  handleMedia={this.handleMedia}
                  attributess={attributes['data-thumb']}
                  saveDescription={this.props.saveDescription}
                  handleIEclose={this.handleIEclose}
                />

                <Button bsStyle="danger" bsSize="large" onClick={this.handleIEclose} className="cancelIE">
                  {messages.editor.abort}
                </Button>
              </div>
            </div>
          </ContextModal>
        )}

        {showImageEditor && (
          <ContextModal style={enable}>
            <EditorApplyStyles styles={previewImageCss} />
            <div id="contextModalWrap">
              <div id="contextModalStage" className="image-editor">
                <ImageEditorRc
                  ref={this.cropperRef}
                  crossOrigin="true" // boolean, set it to true if your image is cors protected or it is hosted on cloud like aws s3 image server
                  src={editorImageSrc.src}
                  style={{ height: '80%', width: '100%' }}
                  aspectRatio={1 / ratio}
                  className="image-edit"
                  scalable
                  movable
                  zoomable
                  rotatable
                  toggleDragModeOnDblclick={false}
                  imageName="cropy.jpg"
                  saveImage={this.imageUpload.bind(this)} // it has to catch the returned data and do it whatever you want
                  responseType="image/jpeg" // 'blob/base64'
                  viewMode={2}
                  dragMode="move"
                  zoomOnTouch={false}
                  zoomOnWheel={false}
                  minCropBoxWidth={parseInt(attributes['data-width'], 10)}
                  minCropBoxHeight={parseInt(attributes['data-height'], 10)}
                  maxCropBoxWidth={parseInt(attributes['data-width'], 10)}
                  maxCropBoxHeight={parseInt(attributes['data-height'], 10)}
                  imageGallery="imageGallery"
                />
                <Loader />
                <Button bsStyle="warning" bsSize="large" onClick={this.handleIEclose} className="cancelIE">
                  {messages.editor.abort}
                </Button>
                <Slider
                  step={0.01}
                  min={0}
                  max={parseFloat(possibleZooms)}
                  defaultValue={xZoom}
                  value={xZoom}
                  onChange={x => {
                    const newXstate = parseFloat(x.toFixed(2));
                    const newX = newXstate - this.state.xZoom;

                    this.setState({ xZoom: newXstate });
                    this.cropperRef.current.zoom(newX);
                  }}
                />
              </div>
            </div>
            <ReactTooltip id="bottom" place="bottom" effect="solid" />
          </ContextModal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    defaultTimezone: getTimezoneFromState(state),
    appIntl: state.intl,
    clientImageRightsQuery: state.login.user?.parent?.image_rights_query,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(ImageGalleryElement)));
