import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

function Spinner({ size, className, style }) {
  return (
    <Icon
      name="fa-spinner-third"
      iconStyles={{ ...style, fontSize: size + 'px' }}
      iconClassName={`${className ? className + ' ' : ''}fa-spin`}
      duotone
    />
  );
}
Spinner.defaultProps = {
  size: 24,
};
Spinner.propTypes = {
  size: PropTypes.number,
};

export default Spinner;
