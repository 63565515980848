import React from 'react';

class ListFilterSearchTerm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      focused: false,
    };

    this.inputRef = React.createRef();
  }

  onSubmit = e => {
    e.stopPropagation();
    e.preventDefault();

    const { onSearch } = this.props;
    const { text } = this.state;
    onSearch(text);
  };

  onChange = e => {
    this.setState({ text: e.target.value });
  };

  onFocus = () => {
    this.setState({ focused: true });
  };

  onBlur = () => {
    this.setState({ focused: false });
  };

  reset = e => {
    e.preventDefault();

    this.setState({ text: '' }, () => {
      const { onSearch } = this.props;
      onSearch('');
    });
  };

  render() {
    const { placeholder } = this.props;
    const { text, focused } = this.state;

    return (
      <div className={`text-search-container clearfix ${focused ? 'focused ' : ''}`}>
        <form onSubmit={this.onSubmit}>
          <input
            ref={this.inputRef}
            value={text}
            type="text"
            name="searchText"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
          />
        </form>

        {text ? (
          <a href="#clearSearchTerm" onClick={this.reset}>
            <i className="fal fa-times" />
          </a>
        ) : (
          <i className="far fa-search" />
        )}
      </div>
    );
  }
}
ListFilterSearchTerm.defaultProps = {};

export default ListFilterSearchTerm;
