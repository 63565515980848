import React from 'react';
import { forEach as _forEach, isPlainObject as _isPlainObject } from 'lodash';

import { entityMetaMapping } from 'js/constants';

import Entity from 'components/EntityV2';

export default class EntityStateUpdaterV2 extends React.Component {
  entityName = 'entity';

  metaIdentifier = ''; // what is the meta identifier for this entity?

  dataIdentifier = ''; // is data being fetched with data reducer? if so, define the identifier here

  dataProps = ''; // is data injected as props in the constructor

  defaultDataProps = {}; // if data is not present, use the defaults

  constructor(props, options = null) {
    super(props);

    if (options) {
      _forEach(options, (value, optionName) => {
        this[optionName] = value;
      });
    }

    this.state = {
      [this.getEntityName() + 'Loaded']: false,
      [this.getEntityName() + 'Changed']: false,
      [this.getEntityName() + 'ValidationErrorButton']: false,
      [this.getEntityName() + 'Validating']: false,
    };

    this.entity = new Entity();

    // if data comes with props, set the entity data
    if (this.dataProps) {
      this.entity.__setData(props[this.dataProps] || this.defaultDataProps);
    }

    // check if entity meta is already in the state
    if (
      props.meta[entityMetaMapping[this.metaIdentifier]] !== undefined &&
      !props.meta[entityMetaMapping[this.metaIdentifier]].pending
    ) {
      this.entity.__setMeta(props.meta, entityMetaMapping[this.metaIdentifier]);

      if (this.entity.__getDataLoaded()) {
        this.state = {
          ...this.state,
          [this.getEntityName()]: this.entity,
          [this.getEntityName() + 'Loaded']: true,
        };
      }
    }
  }

  /**
   * Helper method for getting the property value from the provided state
   * with a fallback to the local state.
   *
   * @param {Object|null} state some state object
   * @param {string} propertyNameSuffix appended to the EntityName
   * @returns {mixed} value
   */
  __getStateProperty = (state = null, propertyNameSuffix = '') => {
    const { state: thisState } = this;
    const propertyName = this.getEntityName() + propertyNameSuffix;
    return state === null ? thisState[propertyName] : state[propertyName];
  };

  // get information if the submit button should get an error class
  hasValidationErrorButton = (state = null) => {
    return this.__getStateProperty(state, 'ValidationErrorButton');
  };

  // get information if the validation is in progress
  isValidating = (state = null) => {
    return this.__getStateProperty(state, 'Validating');
  };

  // check if the entity is loaded
  getEntityLoaded = (state = null) => {
    return this.__getStateProperty(state, 'Loaded');
  };

  getEntityChanged = (state = null) => {
    return this.__getStateProperty(state, 'Changed');
  };

  // return the entity name key
  getEntityName = () => {
    return this.entityName ?? 'entity';
  };

  // return the entity for given state (one may use nextState or prevState too)
  // returns null if the entity was just loaded
  getEntity = (state = null) => {
    if (this.getEntityLoaded(state)) {
      return this.__getStateProperty(state);
    }

    return null;
  };

  // helper function that sets value of one field and returns the entity
  __updateOneField = (entity, fieldName, value, fieldTree = []) => {
    if (_isPlainObject(value)) {
      value = entity.__createValue(fieldName, value, fieldTree);
    }

    const setter = 'set' + fieldName.charAt(0).toUpperCase() + fieldName.substr(1);

    let toWrite = entity;
    if (fieldTree) {
      for (let i = 0; i < fieldTree.length; i++) {
        const getter = 'get' + fieldTree[i].charAt(0).toUpperCase() + fieldTree[i].substr(1);
        toWrite = toWrite[getter];
      }
    }

    toWrite[setter] = value;

    return entity;
  };

  // update one value
  updateEntity = (fieldName, value, fieldTree = [], callback = null, isLoud = true) => {
    let newEntity = this.getEntity();
    newEntity = this.__updateOneField(newEntity, fieldName, value, fieldTree);

    this.changeEntity(newEntity, callback, isLoud);
  };

  // update multiple values at once
  updateEntityMultiple = (fieldNames, values, fieldTrees = [], callback = null, isLoud = true) => {
    let newEntity = this.getEntity();

    _forEach(fieldNames, (fieldName, i) => {
      const value = values[i];
      const fieldTree = fieldTrees[i] !== undefined && fieldTrees[i] !== null ? fieldTrees[i] : [];

      newEntity = this.__updateOneField(newEntity, fieldName, value, fieldTree);
    });

    this.changeEntity(newEntity, callback, isLoud);
  };

  // replace the entity state with a new one
  // one may define the callback that will be executed after the state changes
  // one may also define if the entity should be marked as changed
  changeEntity = (entity, callback = null, isLoud = true) => {
    const stateChangedCallback = () => {
      if (callback) {
        callback();
      }
    };

    const alreadyChanged = this.state[this.getEntityName() + 'Changed'];

    this.setState(
      prevState => ({
        [this.getEntityName()]: entity,
        [this.getEntityName() + 'Changed']: alreadyChanged || isLoud,
        key: prevState.key + 1,
      }),
      stateChangedCallback
    );
  };

  // catch the event
  // only call a protected method so the event may easily be overridden in child class
  componentDidUpdate = (prevProps, prevState) => {
    this.__componentDidUpdate(prevProps, prevState);
  };

  // helper method to use in normal componentDidUpdate
  // needed due to lack of overload in javascript
  __componentDidUpdate = (prevProps, prevState) => {
    const { meta } = this.props;

    // check if meta data got loaded
    if (this.metaIdentifier) {
      if (
        meta[entityMetaMapping[this.metaIdentifier]] !== undefined &&
        !meta[entityMetaMapping[this.metaIdentifier]].pending &&
        !this.entity.__getMetaLoaded()
      ) {
        this.entity.__setMeta(meta, entityMetaMapping[this.metaIdentifier]);
        if (this.entity.__getDataLoaded()) {
          this.__loadEntityToState();
        }
      }
    }

    // check if data props got loaded
    if (this.dataIdentifier) {
      if (
        prevProps[this.dataIdentifier].pending &&
        !this.props[this.dataIdentifier].pending &&
        !this.props[this.dataIdentifier].hasError &&
        !this.entity.__getDataLoaded()
      ) {
        this.entity.__setData(this.props.data[this.dataIdentifier]);
        if (this.entity.__getMetaLoaded()) {
          this.__loadEntityToState();
        }
      }
    }

    // check if the entity has en error now
    if (this.getEntityLoaded() && this.isValidating(prevState) && !this.isValidating() && !this.getEntity().__isValid) {
      this.setState({ [this.getEntityName() + 'ValidationErrorButton']: true });
      this.buttonErrorTimeout = setTimeout(() => {
        this.setState({ [this.getEntityName() + 'ValidationErrorButton']: false });
      }, 5000);
    }
  };

  // after data and meta is loaded, take the entity property and set it to state
  // mark the entity as loaded too
  __loadEntityToState = () => {
    this.setState({
      [this.getEntityName()]: this.entity,
      [this.getEntityName() + 'Loaded']: true,
    });
  };

  // catch the event in current class
  // only call a protected method so the event may easily be overridden in child class
  componentWillUnmount = () => {
    this.__componentWillUnmount();
  };

  // clear timeout when a validation error occured
  __componentWillUnmount = () => {
    clearTimeout(this.buttonErrorTimeout);
    clearTimeout(this.validateTimeout);
  };

  // validate the entity and execute callbacks
  validateEntity = (successCallback = null, errorCallback = null) => {
    this.validateTimeout = setTimeout(() => {
      this.setState(
        prevState => ({
          [this.getEntityName()]: prevState[this.getEntityName()].__validate(),
          [this.getEntityName() + 'ValidationErrorButton']: false,
          [this.getEntityName() + 'Validating']: true, // set the information in state that the entity is being validated
        }),
        () => {
          this.setState(
            {
              [this.getEntityName() + 'Validating']: false, // set the information that the validation finished
            },
            () => {
              if (this.state[this.getEntityName()].__isValid) {
                if (successCallback) {
                  successCallback();
                }
              } else if (errorCallback) {
                errorCallback();
              }
            }
          );
        }
      );
    }, 150);
  };

  // easier pass attributes needed for entity props updater
  getPropsForPropsUpdater = () => {
    return {
      [this.getEntityName()]: this.getEntity(),
      [this.getEntityName() + 'Loaded']: this.getEntityLoaded(),
      [this.getEntityName() + 'Changed']: this.getEntityChanged(),
      updateEntity: this.updateEntity,
      updateEntityMultiple: this.updateEntityMultiple,
      changeEntity: this.changeEntity,
    };
  };
}
