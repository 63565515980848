import React from 'react';
import { connect } from 'react-redux';
import { find as _find, forEach as _forEach } from 'lodash';
import ReactDOM from 'react-dom';
import { Button, ButtonGroup, Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import Loader from 'components/layout/Loader';
import { confirmAlert } from 'react-confirm-alert';
import Axios from 'axios';
import { getTimezoneFromState } from '../../../helpers/intl';
import FormAutocomplete from '../../../components/forms/FormAutocomplete';
import FormInput from '../../../components/forms/FormInput';

const ContextModal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('context-modal'));
};

class ActionButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      social: [],
      socialEdit: [],
      showSocialEdit: false,
      buttonColor: '#000000',
      buttonFontColor: '#FFFFFF',
      uploading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.params[0] && prevProps.params[0]?.value !== this.props.params[0]?.value) {
      this.getButton();
    }
  }

  componentDidMount = () => {
    this.getButton();
  };

  setButton = () => {
    const { socialEdit } = this.state;
    const { id, attributes, updateParam } = this.props;

    const param =
      socialEdit[0] +
      '#aaa#' +
      socialEdit[1] +
      '#aaa#' +
      socialEdit[2] +
      '#aaa#' +
      socialEdit[3] +
      '#aaa#' +
      socialEdit[4];
    const overflow = false;
    const default_image = false;
    const errors = [{ overflow, default_image }];
    updateParam(id, attributes['data-name'], param, errors);
    this.setState({ social: socialEdit, showSocialEdit: false }, () => {});
  };

  getButton = () => {
    const { attributes, params } = this.props;
    let social = [];
    const param = _find(params, ['name', attributes['data-name']]);
    if (param) {
      social = param.value.split('#aaa#');
    }
    let _buttonFontColor = '';
    social && social.length > 4 ? (_buttonFontColor = social[4]) : '';
    this.setState({ social, socialEdit: social, buttonColor: social[3], buttonFontColor: _buttonFontColor }, () => {});
  };

  showSocialEdit = () => {
    this.setState({ showSocialEdit: true });
  };

  closeButtonEdit = () => {
    this.setState({ showSocialEdit: false });
  };

  getButtonContainer = () => {
    return (
      <div
        className="action_button"
        style={{ backgroundColor: this.state.buttonColor, color: this.state.buttonFontColor }}
      >
        <span>{this.state.socialEdit[2]}</span>
      </div>
    );
  };

  updateButtonColor = color => {
    const { socialEdit } = this.state;
    socialEdit[3] = color;
    this.setState({ buttonColor: color, socialEdit });
  };

  updateButtonFontColor = color => {
    const { socialEdit } = this.state;
    socialEdit[4] = color;
    this.setState({ buttonFontColor: color, socialEdit });
  };

  uploadFiles = e => {
    const {
      intl: { messages },
    } = this.props;
    this.setState({ uploading: true, progress: 0 });
    const formData = new FormData();
    _forEach(e.target.files, (file, i) => {
      formData.append('file' + i, file);
    });
    this.uploadPDF(formData)
      .then(response => {
        const { socialEdit } = this.state;
        socialEdit[1] = response?.data;
        this.setState({ uploading: false, socialEdit }, () => {
          const options = {
            customUI: ({ onClose }) => {
              return (
                <div className="react-confirm-alert-body">
                  <h1>{messages.upload_pdf_title}</h1>
                  <p>{messages.upload_pdf_status_done}</p>
                  <div className="react-confirm-alert-button-group" style={{ width: '100%', margin: '0 auto' }}>
                    <Button
                      style={{ margin: '0 auto', marginTop: '20px', width: '100px' }}
                      bsStyle="info"
                      onClick={onClose}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              );
            },
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
          };
          confirmAlert(options);
        });
      })
      .catch(() => {
        this.setState({ uploading: false }, () => {
          const options = {
            customUI: ({ onClose }) => {
              return (
                <div className="react-confirm-alert-body">
                  <h1>{messages.upload_pdf_title}</h1>
                  <p>{messages.upload_pdf_status_error}</p>
                  <div className="react-confirm-alert-button-group" style={{ width: '100%', margin: '0 auto' }}>
                    <Button
                      style={{ margin: '0 auto', marginTop: '20px', width: '100px' }}
                      bsStyle="info"
                      onClick={onClose}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              );
            },
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
          };
          confirmAlert(options);
        });
      });
  }; // End uploadFiles

  // contact to API
  uploadPDF = formData => {
    const { editionRV } = this.props;
    return Axios.post('/api/editions/' + editionRV?.id + '/uploads/pdfs.json', formData);
  };

  getPageUrl = pageId => {
    return '/de/website/' + this.props.editionId + '/' + pageId;
  };

  render() {
    const { name: Tag, attributes, disabled } = this.props;
    const { social, socialEdit, showSocialEdit } = this.state;
    const {
      intl: { messages },
    } = this.props;
    let isDisabled0 = false;
    let isDisabled1 = false;
    let { navEntries } = this.props;
    if (socialEdit.length > 0 && socialEdit[0] !== '' && socialEdit[0] !== 'undefined' && socialEdit[0] !== undefined) {
      isDisabled1 = true;
    }
    if (socialEdit.length > 0 && socialEdit[1] !== '' && socialEdit[1] !== 'undefined' && socialEdit[1] !== undefined) {
      isDisabled0 = true;
    }

    const pages =  this.props.pages ?? [];
    const availablePages = pages.map(page => {
      return [this.getPageUrl(page.id), messages.editor.nav_type_page_prefix + ' ' + page.page_number];
    });

    if(availablePages.length > 1 && this.props.isWebsite) {
      navEntries = navEntries ?? [];
      navEntries = navEntries.concat(availablePages);
    }

    return (
      <>
        <Tag {...attributes} onClick={this.showSocialEdit} data-tip={messages.editor.button_settings} data-for="top">
          <div className="wrap">
            {social.length > 0 ? this.getButtonContainer(social) : <p>{messages.editor.action_button_content_text}</p>}
          </div>
        </Tag>
        {showSocialEdit && (
          <ContextModal>
            <div id="contextModalWrap">
              <div id="contextModalStage" className="row">
                <Row className="h-45">
                  <Col md={4}>
                    <FormAutocomplete
                      onChange={(name, value) => {
                        const { socialEdit } = this.state;
                        socialEdit[0] = value;
                        this.setState({ socialEdit });
                      }}
                      value={socialEdit[0] ?? ''}
                      label={messages.editor.action_button_link_element}
                      name="internalLink"
                      options={navEntries?.map(option => {
                        return { label: option[1], value: option[0] };
                      })}
                      disabled={isDisabled0 || disabled}
                    />
                    <p>{messages.editor.action_button_or}</p>
                    <FormInput
                      name="externalLink"
                      label={messages.editor.action_button_external_link}
                      value={socialEdit[1] ?? ''}
                      onChange={(name, value) => {
                        const { socialEdit } = this.state;
                        socialEdit[1] = value;
                        this.setState({ socialEdit });
                      }}
                      disabled={isDisabled1 || disabled}
                    />
                    <FormInput
                      name="buttonName"
                      label={messages.editor.choose_button_label}
                      value={socialEdit[2] ?? ''}
                      onChange={(name, value) => {
                        const { socialEdit } = this.state;
                        socialEdit[2] = value;
                        this.setState({ socialEdit });
                      }}
                      disabled={disabled}
                    />
                    <div className="uploadPDF">
                      <label htmlFor="file-input" style={{ cursor: 'pointer' }}>
                        <p>
                          <span style={{ fontSize: '15px' }}>
                            + <i className="far fa-file-pdf" /> {messages.upload_pdf}
                          </span>
                        </p>
                      </label>
                      <input
                        style={{ display: 'none' }}
                        id="file-input"
                        type="file"
                        accept="application/pdf"
                        onChange={e => {
                          this.uploadFiles(e);
                        }}
                        disabled={disabled}
                      />
                      {this.state.uploading ? <Loader /> : <></>}
                    </div>
                    {this.props.attributes['data-bbgcolor'] !== undefined && (
                      <div className="changeColorOptions">
                        <h3>{messages.editor.button_color}</h3>
                        <ButtonGroup className="edit-color QRBackground" bsSize="small" vertical>
                          {this.props.settings.back_colors &&
                            this.props.settings.back_colors.map(bgColor => {
                              if (
                                this.props.attributes['data-bbgcolor'] === 'true' ||
                                this.props.attributes['data-bbgcolor'].includes(bgColor.label[0].name)
                              ) {
                                return (
                                  <Button
                                    data-tip={messages.editor.change_backgroundcolor}
                                    onClick={() => this.updateButtonColor(bgColor.color)}
                                    style={{ padding: 4 }}
                                    disabled={disabled}
                                  >
                                    <p
                                      style={{
                                        width: 30,
                                        height: 30,
                                        padding: 0,
                                        margin: 0,
                                        background: bgColor.color,
                                      }}
                                    />
                                  </Button>
                                );
                              }
                              return null;
                            })}
                          {this.props.settings.target &&
                            this.props.settings.target.back_colors &&
                            this.props.settings.target.back_colors.map(bgColor => {
                              if (
                                this.props.attributes['data-bbgcolor'] === 'true' ||
                                this.props.attributes['data-bbgcolor'].includes(bgColor.label[0].name)
                              ) {
                                return (
                                  <Button
                                    data-tip={messages.editor.change_backgroundcolor}
                                    onClick={() => this.updateButtonColor(bgColor.color)}
                                    style={{ padding: 4 }}
                                    disabled={disabled}
                                  >
                                    <p
                                      style={{
                                        width: 30,
                                        height: 30,
                                        padding: 0,
                                        margin: 0,
                                        background: bgColor.color,
                                      }}
                                    />
                                  </Button>
                                );
                              }
                              return null;
                            })}
                        </ButtonGroup>
                      </div>
                    )}
                    {this.props.attributes['data-bfcolor'] !== undefined && (
                      <div className="changeColorOptions">
                        <h3>{messages.editor.button_font_color}</h3>
                        <ButtonGroup className="edit-color QRBackground" bsSize="small" vertical>
                          {this.props.settings.font_colors &&
                            this.props.settings.font_colors.map(fontColor => {
                              if (
                                this.props.attributes['data-bfcolor'] === 'true' ||
                                this.props.attributes['data-bfcolor'].includes(fontColor.label[0].name)
                              ) {
                                return (
                                  <Button
                                    data-tip={messages.editor.change_fontcolor}
                                    onClick={() => this.updateButtonFontColor(fontColor.color)}
                                    style={{ padding: 4 }}
                                    disabled={disabled}
                                  >
                                    <p
                                      style={{
                                        width: 30,
                                        height: 30,
                                        padding: 0,
                                        margin: 0,
                                        background: fontColor.color,
                                      }}
                                    />
                                  </Button>
                                );
                              }
                              return null;
                            })}
                          {this.props.settings.target &&
                            this.props.settings.target.font_colors &&
                            this.props.settings.target.font_colors.map(fontColor => {
                              if (
                                this.props.attributes['data-bfcolor'] === 'true' ||
                                this.props.attributes['data-bfcolor'].includes(fontColor.label[0].name)
                              ) {
                                return (
                                  <Button
                                    data-tip={messages.editor.change_fontcolor}
                                    onClick={() => this.updateButtonFontColor(fontColor.color)}
                                    style={{ padding: 4 }}
                                    disabled={disabled}
                                  >
                                    <p
                                      style={{
                                        width: 30,
                                        height: 30,
                                        padding: 0,
                                        margin: 0,
                                        background: fontColor.color,
                                      }}
                                    />
                                  </Button>
                                );
                              }
                              return null;
                            })}
                        </ButtonGroup>
                      </div>
                    )}
                  </Col>
                  <Col md={8} className="h-100">
                    <div style={{ minHeight: '50%', border: '2px dashed #ccc', padding: '10px' }}>
                      <p>{messages.editor.preview_button}</p>
                      {socialEdit[2] && this.getButtonContainer(socialEdit)}
                    </div>
                  </Col>
                </Row>

                <Row className="text-right">
                  <Button bsStyle="success" bsSize="large" onClick={this.setButton} disabled={disabled}>
                    {messages.editor.take_on}
                  </Button>{' '}
                  <Button bsStyle="danger" bsSize="large" onClick={this.closeButtonEdit}>
                    {messages.editor.abort}
                  </Button>
                </Row>
              </div>
            </div>
          </ContextModal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    defaultTimezone: getTimezoneFromState(state),
    appIntl: state.intl,
    navEntries: state.editor.navEntries,
    editionRV: state.editor.editionRV,
    pages: state.editor.editionRV.pages,
    isWebsite: state.editor.editionRV?.template?.export_type?.value === '000000000000000000000003',
    editionId: state.editor.editionRV?.id,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(ActionButton)));
