import _ from 'lodash';

const initialState = { company: '' };

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_COMPANY': {
      return { ...state, company: payload.company };
    }
  }
  return state;
};
