import React from 'react';
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { isPlainObject as _isPlainObject, clone as _clone } from 'lodash';

import { formStatusDispatchHelper, formReset } from 'actions/FormStatusActions';

import FormInput from 'components/forms/FormInput';
import FormErrors from 'components/forms/FormErrors';

import RichEditorContent from 'components/RichEditorContent';

import Data from 'components/Data';
import Entity from 'components/Entity';

import { entityMetaMapping } from 'js/constants';

import AbstractCmsElement from './AbstractCmsElement';

class CmsElementNewsletter extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      ...this.state,
      entityLoaded: false,
      entityChanged: false,
      shown: false,
    };

    this.entity = new Entity();
    this.entity.setData({});

    if (
      props.meta[entityMetaMapping.cmsNewsletterForm] !== undefined &&
      !props.meta[entityMetaMapping.cmsNewsletterForm].pending
    ) {
      this.entity.setMeta(props.meta, entityMetaMapping.cmsNewsletterForm);

      this.state = {
        ...this.state,
        entityLoaded: true,
        entity: this.entity,
      };
    }
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { meta } = this.props;

    if (
      meta[entityMetaMapping.cmsNewsletterForm] !== undefined &&
      !meta[entityMetaMapping.cmsNewsletterForm].pending &&
      !this.entity.getMetaLoaded()
    ) {
      this.entity.setMeta(meta, entityMetaMapping.cmsNewsletterForm);
      this.setState({ entityLoaded: true, entity: this.entity });
    }

    this.__componentDidUpdate(prevProps, prevState);
  };

  componentWillUnmount = () => {
    this.props.resetForm();
    this.__componentWillUnmount();
  };

  changeEntity = (entity, callback = null) => {
    this.setState(
      prevState => ({
        [this.getEntityName()]: entity,
        [this.getEntityName() + 'Changed']: true,
        key: prevState.key + 1,
      }),
      callback
    );
  };

  updateEntity = (propertyName, value, fieldTree = [], callback = null) => {
    const entity = this.state[this.getEntityName()];

    if (_isPlainObject(value)) {
      value = entity.createValue(propertyName, _clone(fieldTree), value);
    }

    const newEntity = { ...entity.updateValue(propertyName, value, fieldTree) };

    this.changeEntity(newEntity, callback);
  };

  getEntityName = () => {
    return this.entityName !== undefined ? this.entityName : 'entity';
  };

  onSubmit = e => {
    e.preventDefault();
    e.stopPropagation(); // if we are editing a page, we have to make sure the parent form will not be submitted

    clearTimeout(this.submitTimeout);
    this.submitTimeout = setTimeout(() => {
      this.setState(
        prevState => ({ entity: prevState.entity.validate() }),
        () => {
          const { onSubmit } = this.props;
          const { entity } = this.state;

          if (entity.isValid) {
            // ommit unnecessary data
            const data = {
              ...entity.processedData,
              pipelineId: this.getParamValue('pipelineId'),
              source: this.getParamValue('leadSource'),
            };
            onSubmit(data);
          }
        }
      );
    });

    return false;
  };

  render() {
    const {
      intl: { messages },
      formStatus,
      user,
    } = this.props;
    const { entity, entityLoaded, shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const containerClasses =
      'ms-newsletter-container text-' +
      this.getParamValue('textAlign', 'center') +
      ' text-' +
      this.getParamValue('mainColor', 'master');

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-newsletter ' + shownClass + this.getResponsiveClasses() + this.getCssClasses()}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        {user.logged ? null : (
          <div>
            <Data dataName="cmsNewsletterForm" />
            <FormErrors formStatus={formStatus} />

            {entityLoaded && formStatus.response ? (
              <div class={containerClasses}>
                <p>{messages.weve_received_your_request_and_will_contact_you}</p>
              </div>
            ) : null}

            {entityLoaded && !formStatus.response ? (
              <div class={containerClasses}>
                <h2 class="no-margin p-b-25">{this.getParamValue('title', '')}</h2>
                <RichEditorContent content={'<p>' + this.getParamValue('content', '') + '</p>'} />

                <Form class="m-t-15" onSubmit={this.onSubmit}>
                  <FormInput
                    name="email"
                    value={entity.getEmail()}
                    onChange={this.updateEntity}
                    label={messages.email}
                    type="email"
                  />

                  <div class="text-right">
                    <Button
                      type="submit"
                      class="call-to-action btn-animated from-left fa fa-arrow-right"
                      bsStyle="primary"
                      onClick={this.onClick}
                    >
                      <span>{messages.subscribe}</span>
                    </Button>
                  </div>
                </Form>
              </div>
            ) : null}

            {this.getAppendHtml()}
          </div>
        )}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementNewsletter.defaultProps = {
  index: '0',
};

const mapStateToProps = state => {
  return {
    formStatus: state.formStatus.cmsNewsletterForm,
    meta: state.entityMeta,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSubmit: args => {
      formStatusDispatchHelper('cmsNewsletterForm', '/api/cms/newsletterForm.json', args, 'post', dispatch);
    },
    resetForm: () => {
      formReset('cmsNewsletterForm');
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CmsElementNewsletter);
