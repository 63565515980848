import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import TableResponsive from 'components/layout/TableResponsive';

class NecessaryCookiesList extends React.Component {
  render() {
    const {
      intl: { messages },
    } = this.props;

    const cookieListTableHeaders = {
      name: messages.name,
      description: messages.description,
      domain: messages.domain,
      ttl: 'TTL',
    };

    return (
      <TableResponsive
        headers={cookieListTableHeaders}
        rows={[
          {
            name: 'PHPSESSID',
            description: messages.cookie_description.PHPSESSID,
            domain: window.location.hostname,
            ttl: messages.cookie_ttl['60_days'],
          },
          {
            name: 'tempTarget',
            description: messages.cookie_description.tempTarget,
            domain: window.location.hostname,
            ttl: messages.cookie_ttl['6_months'],
          },
          {
            name: 'chedriCookiesBoxClosed',
            description: messages.cookie_description.chedriCookiesBoxClosed,
            domain: window.location.hostname,
            ttl: messages.cookie_ttl['6_months'],
          },
          {
            name: 'chedriCookiesPerformance',
            description: messages.cookie_description.chedriCookiesPerformance,
            domain: window.location.hostname,
            ttl: messages.cookie_ttl['6_months'],
          },
          {
            name: 'chedriCookiesTargeting',
            description: messages.cookie_description.chedriCookiesTargeting,
            domain: window.location.hostname,
            ttl: messages.cookie_ttl['6_months'],
          },
        ]}
        hover
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    projectConfig: state.data.projectConfig,
  };
};

export default injectIntl(connect(mapStateToProps)(NecessaryCookiesList));
