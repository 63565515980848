import React from 'react';
import Masonry from 'react-masonry-component';
import Lightbox from 'react-image-lightbox';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { find as _find } from 'lodash';

import { dispatchResizeEvent } from 'helpers/window';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

export default class CmsElementGallery extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      showLightbox: false,
      lightboxIndex: 0,
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showLightbox = (lightboxIndex = 0) => {
    this.setState({ showLightbox: true, lightboxIndex });
  };

  closeLightbox = () => {
    this.setState({ showLightbox: false });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { children } = this.props;

    if (children === null || prevProps.children === null || children.length !== prevProps.children.length) {
      dispatchResizeEvent();
    }

    this.__componentDidUpdate(prevProps, prevState);
  };

  getImageUrlFromParams = params => {
    const param = _find(params, ['name', 'imageUrl']);
    if (param) {
      return param.value;
    }

    return '';
  };

  render() {
    const { showLightbox, lightboxIndex, shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const images = this.props.children.map(child => {
      return this.getImageUrlFromParams(child.props.params);
    });

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={
          'ms-gallery-container ' +
          shownClass +
          this.getResponsiveClasses() +
          (this.getParamValue('isCondensed', 'no') === 'yes' ? ' condensed' : '') +
          this.getCssClasses()
        }
        style={{
          ...this.getBackgroundStyle(),
        }}
      >
        {this.getElementStyle()}

        <Masonry
          className="ms-gallery"
          elementType="div"
          options={{ transitionDuration: 0 }}
          disableImagesLoaded={false}
          updateOnEachImageLoad
        >
          {this.props.children.map((child, i) => {
            return React.cloneElement(child, { lightboxIndex: i, showLightbox: this.showLightbox });
          })}
        </Masonry>

        {showLightbox && (
          <Lightbox
            mainSrc={images[lightboxIndex]}
            nextSrc={images[(lightboxIndex + 1) % images.length]}
            prevSrc={images[(lightboxIndex + images.length - 1) % images.length]}
            onCloseRequest={this.closeLightbox}
            onMovePrevRequest={() =>
              this.setState({
                lightboxIndex: (lightboxIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                lightboxIndex: (lightboxIndex + 1) % images.length,
              })
            }
          />
        )}

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementGallery.defaultProps = {
  index: '0',
};
