import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import CompanySignupWizardPluginSelector from 'components/forms/auth/CompanySignupWizardPluginSelector';

import EntityPropsUpdater from 'component/EntityPropsUpdater';

class CompanySignupStepAdditionalPlugins extends EntityPropsUpdater {
  getUrlDataSubscriptionPlan = () => {
    const res = {};

    res.filters = { type: 'subscription_plan' };

    return res;
  };

  getUrlDataMainPlugin = () => {
    const res = {};

    res.filters = { type: 'main_plugin' };
    res.sort = 'isFeatured';
    res.dir = 'desc';

    return res;
  };

  render() {
    const {
      intl: { messages },
      listMainPlugin,
      formStatus,
      entity,
    } = this.props;

    return (
      <div>
        <h1>{messages.company_singup_wizard_step_subscription_plan_main_plugin_headline}</h1>
        {entity.getPlugins() ? (
          <div class="m-t-25">
            <CompanySignupWizardPluginSelector
              listName="whiteLabelMainPlugin"
              url="/api/whitelabelplugins.json"
              urlData={this.getUrlDataMainPlugin()}
              list={listMainPlugin}
              name="plugins"
              selection={entity.getPlugins()}
              meta={entity.getPluginsMetaInformation()}
              validation={entity.getPluginsValidationResult()}
              onChange={this.updateEntity}
              disabled={formStatus.pending}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    listSubscriptionPlan: state.list.whiteLabelSubscriptionPlan,
    listMainPlugin: state.list.whiteLabelMainPlugin,
    formStatus: state.formStatus.companySignup,
  };
};

export default injectIntl(connect(mapStateToProps)(CompanySignupStepAdditionalPlugins));
