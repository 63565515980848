import React from 'react';
import PropTypes from 'prop-types';
import Time from './Time';

function ChatMessage({ item, name, isMe, isFirst, isLast, locale, timezone, isWarning }) {
  const { message } = item;
  const contactClassNames = isMe ? 'right pull-right' : 'left pull-left';

  return (
    <div className="clearfix">
      <div
        className={`chat-message-entry ${contactClassNames} ${isWarning || isFirst ? 'first' : ''} ${
          isWarning || isLast ? 'last' : ''
        } ${isWarning && 'warning'}`}
      >
        <div>{message}</div>
        {!isMe && <div className="name pull-left">{name}</div>}
        <div className="time pull-right">
          <Time currentMessage={item} locale={locale} timezone={timezone} />
        </div>
      </div>
    </div>
  );
}
ChatMessage.defaultProps = {
  name: '',
  isMe: false,
  isFirst: true,
  isLast: true,
  isWarning: false,
  locale: 'de',
  timezone: 'Europe/Berlin',
};
ChatMessage.propTypes = {
  item: PropTypes.object.isRequired,
  name: PropTypes.string,
  isMe: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  isWarning: PropTypes.bool,
  locale: PropTypes.string,
  timezone: PropTypes.string,
};

export default ChatMessage;
