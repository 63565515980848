import {
  UPDATE_RETINA,
  UPDATE_BREAKPOINT,
  UPDATE_WIDTH_AND_HEIGHT,
  UPDATE_HEADER_HEIGHT,
  UPDATE_ACTIVE_CMS,
  UPDATE_FOCUS,
  UPDATE_ZOOM,
  UPDATE_VAR
} from 'actions/LayoutActions';
import _ from "lodash";

const isTouchDevice = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = query => {
    return window.matchMedia(query).matches;
  };

  if ('ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch)) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
};

const initialState = {
  isRetina: false,
  isFocused: true,
  breakpoint: 'lg',
  breakpointIndex: 3,
  width: null,
  height: null,
  headerHeight: null,
  isTouchDevice: isTouchDevice(),
  activeCms: null,
  isWidget: typeof widget !== 'undefined' && widget,
  zHeight:0,
  zWidth:0,
  scrollView:undefined,
  activateZoom:false,
  viewActive: false,
  zoomFactor:undefined,
  ccToolbar:"",
};

export default function layout(state = initialState, action) {
  switch (action.type) {
    case UPDATE_RETINA: {
      return { ...state, isRetina: action.payload };
    }

    case UPDATE_BREAKPOINT: {
      let breakpointIndex = 3;
      switch (action.payload) {
        case 'xs':
          breakpointIndex = 0;
          break;

        case 'sm':
          breakpointIndex = 1;
          break;

        case 'md':
          breakpointIndex = 2;
          break;
      }
      return { ...state, breakpoint: action.payload, breakpointIndex };
    }

    case UPDATE_WIDTH_AND_HEIGHT: {
      return { ...state, ...action.payload };
    }

    case UPDATE_HEADER_HEIGHT: {
      return { ...state, headerHeight: action.payload };
    }

    case UPDATE_ACTIVE_CMS: {
      return { ...state, activeCms: action.payload };
    }

    case UPDATE_FOCUS: {
      return { ...state, isFocused: action.payload };
    }

    case UPDATE_ZOOM: {
       let width = action.payload[1];
       let height = action.payload[0];
       let _ScrollView = action.payload[2];
       let _viewActive = true;
       let _activateZoom = true;
       let _zoomFactor = action.payload[3];

      return { ...state, zHeight: height, zWidth: width, scrollView: _ScrollView, viewActive: _viewActive, activateZoom: _activateZoom, zoomFactor: _zoomFactor };
    }
    case UPDATE_VAR: {
      let { viewActive, activateZoom, ccToolbar} = state;

      let _viewActive = viewActive;
      let _activateZoom = activateZoom;
      let _ccToolbar = ccToolbar;

      if(action.payload === "viewActiveFalse"){
        _viewActive = false;
      }
      if(action.payload === "activateZoomFalse"){
        _activateZoom = false;
      }
      if(action.payload === "ccToolbarNone"){
        _ccToolbar = "none";
      }
      if(action.payload === "ccToolbarBlock"){
        _ccToolbar = "";
      }

      return { ...state, viewActive: _viewActive, activateZoom: _activateZoom, ccToolbar: _ccToolbar };
    }
  }

  return state;
}
