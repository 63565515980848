import React from 'react';

function DoubleEuroIcon({ className, style }) {
  return (
    <div className={`double-euro-icon ${className || ''}`} style={style}>
      <span className="fa-stack fa-1x double-euro-icon__group-front">
        <i
          class="fas fa-circle fa-stack-2x double-euro-icon__icon-back"
          // style={{ '-webkit-text-stroke': '2px ' + color, color: bgColor }}
        />
        <i class="fas fa-euro-sign fa-stack-2x double-euro-icon__icon-front" />
      </span>
      <span className="fa-stack fa-1x  double-euro-icon__group-back">
        <i
          class="fas fa-circle fa-stack-2x  double-euro-icon__icon-back"
          // style={{ '-webkit-text-stroke': '2px ' + color, color: bgColor }}
        />
        <i class="fas fa-euro-sign fa-stack-2x double-euro-icon__icon-front" />
      </span>
    </div>
  );
}

DoubleEuroIcon.defaultProps = {};

export default DoubleEuroIcon;
