import axios from 'axios';

import { toQueryString } from 'helpers/http';

export const DATA_ID_PENDING = 'DATA_ID_PENDING';
export const DATA_ID_SUBMIT = 'DATA_ID_SUBMIT';
export const DATA_ID_FULFILLED = 'DATA_ID_FULFILLED';
export const DATA_ID_REJECTED = 'DATA_ID_REJECTED';

export function dataIdPending(dataIdName, dataIdId, data) {
  return {
    type: DATA_ID_PENDING,
    payload: { dataIdName, dataIdId, ...data },
  };
}

export function dataIdSend(url, data, finishedCallback, errorCallback, method = 'get') {
  let fullUrl = url;
  if (fullUrl.indexOf('?') === -1) {
    fullUrl += '?';
  }

  if (method === 'get' && data !== undefined && data) {
    fullUrl += toQueryString(data) + '&';
  }
  fullUrl += '_locale=' + globalLocale;

  return {
    type: DATA_ID_SUBMIT,
    payload: axios({
      method: method,
      url: fullUrl,
      data: { ...data },
    })
      .then(finishedCallback)
      .catch(errorCallback),
  };
}

export function dataIdFulfilled(dataIdName, dataIdId, response) {
  return {
    type: DATA_ID_FULFILLED,
    payload: { dataIdName, dataIdId, response },
  };
}

export function dataIdRejected(dataIdName, dataIdId, response) {
  return {
    type: DATA_ID_REJECTED,
    payload: { dataIdName, dataIdId, response: response.response },
  };
}
