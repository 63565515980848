import React from 'react';
import Modal from '../../Modal';
import { Col, Row } from 'react-bootstrap';
import FormInput from '../../forms/FormInput';
import { findIndex as _findIndex, find as _find, forEach as _forEach, findKey as _findKey } from 'lodash';

const Modals = ({
  mediaFiles,
  mediaData,
  showEdit,
  setShowEdit,
  s3urlOriginal,
  handleSubmit,
  handleChange,
  saving,
  showCreateFolder,
  setShowCreateFolder,
  handleFolderSubmit,
  handleFolderChange,
  showFolderEdit,
  setShowFolderEdit,
  handleFolderRename,
  newFolderName,
  handleFolderEditSubmit,
  messages
}) => {
  return (
    <>
      {/* Modal File Metadaten edit */}

      {mediaFiles[mediaData.index] && (
        <Modal show={showEdit} onHide={() => setShowEdit(false)}>
          <Modal.Header closeButton>
            <h3>{messages.medialibrary.adjust_meta}</h3>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12} className="m-b-20">
                {mediaFiles[mediaData.index].thumb !== 'video/mp4' ? (
                  <img
                    src={s3urlOriginal + mediaFiles[mediaData.index].key}
                    style={{ maxHeight: '400px', maxWidth: '100%' }}
                  />
                ) : (
                  <video controls style={{ maxHeight: '400px', maxWidth: '100%' }}>
                    <source src={s3urlOriginal + mediaFiles[mediaData.index].key} type="video/mp4" />
                  </video>
                )}
              </Col>
            </Row>
            <Row className={'mediainfo'}>
              <form onSubmit={handleSubmit}>
                <Row>
                  <div className="form-group-attached">
                    <Col md={6}>
                      <FormInput
                        type="text"
                        label={messages.medialibrary.title}
                        name="title"
                        onChange={handleChange}
                        value={mediaData.title}
                      />
                    </Col>
                    <Col md={6}>
                      <FormInput
                        type="text"
                        label={messages.medialibrary.description}
                        name="description"
                        onChange={handleChange}
                        value={mediaData.description}
                      />
                    </Col>
                  </div>
                </Row>
                <Row>
                  <div className="form-group-attached">
                    <Col md={6}>
                      <FormInput
                        type="text"
                        label={messages.medialibrary.copyright}
                        name="copyright"
                        onChange={handleChange}
                        value={mediaData.copyright}
                      />
                    </Col>
                  </div>
                </Row>
                <Row>
                  <div className="m-t-20 m-l-15">
                    <button class="btn btn-danger" type="submit">
                      {saving ? <i className="fa fa-spinner-third fa-spin" /> : <i className="fa fa-save" />}
                      {messages.medialibrary.save}
                    </button>
                  </div>
                </Row>
              </form>
            </Row>
          </Modal.Body>
        </Modal>
      )}

      {/* Modal create Folder - create Name */}

      <Modal show={showCreateFolder} onHide={() => setShowCreateFolder(false)}>
        <Modal.Header closeButton>
          <h3>{messages.medialibrary.create_new_directory}</h3>
        </Modal.Header>
        <Modal.Body>
          <Row className={'mediainfo'}>
            <form onSubmit={handleFolderSubmit}>
              <Row>
                <div className="form-group-attached">
                  <Col md={12}>
                    <FormInput type="text" label={messages.medialibrary.directory_name} name="name" onChange={handleFolderChange} value="" />
                  </Col>
                </div>
              </Row>

              <Row>
                <div className="m-t-20 m-l-15">
                  <button class="btn btn-danger" type="submit">
                    {saving ? <i className="fa fa-spinner-third fa-spin" /> : <i className="fa fa-save" />}
                    {messages.medialibrary.save}
                  </button>
                </div>
              </Row>
            </form>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Modal edit Folder - edit Name */}

      <Modal show={showFolderEdit} onHide={() => setShowFolderEdit(false)}>
        <Modal.Header closeButton>
          <h3>{messages.medialibrary.rename_folder}</h3>
        </Modal.Header>
        <Modal.Body>
          <Row className={'mediainfo'}>
            <form onSubmit={handleFolderEditSubmit}>
              <Row>
                <div className="form-group-attached">
                  <Col md={12}>
                    <FormInput
                      type="text"
                      label={messages.medialibrary.directory_name}
                      name="name"
                      onChange={handleFolderRename}
                      value={newFolderName.name}
                    />
                  </Col>
                </div>
              </Row>

              <Row>
                <div className="m-t-20 m-l-15">
                  <button class="btn btn-danger" type="submit">
                    {saving ? <i className="fa fa-spinner-third fa-spin" /> : <i className="fa fa-save" />}
                    {messages.medialibrary.save}
                  </button>
                </div>
              </Row>
            </form>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Modal edit Folder - edit Name */}
    </>
  );
};

export default Modals;
