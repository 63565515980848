import React from 'react';
import { components } from 'react-select';

class BrandOption extends React.Component {
  render() {
    const { data } = this.props;
    return <components.Option {...this.props}>{data.label}</components.Option>;
  }
}

class BrandValue extends React.Component {
  render() {
    const { data } = this.props;

    return <div className="Select-value">{data.label}</div>;
  }
}

const brandOptionFormatter = options => {
  return options.list.map(option => {
    return brandSingleOptionFormatter(option);
  });
};

const brandSingleOptionFormatter = (option = null) => {
  if (!option) {
    return option;
  }
  return { ...option, value: option.id, label: option.name };
};

const brandOptionFromEntity = brand => {
  return !brand
    ? null
    : brandSingleOptionFormatter({
        id: brand.getId(),
        name: brand.getName(),
      });
};

export { BrandValue, BrandOption, brandOptionFormatter, brandSingleOptionFormatter, brandOptionFromEntity };
