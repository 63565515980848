import React from 'react';
import PropTypes from 'prop-types';

function NotificationMessage({ type, position, thumbnail, message, className, style }) {
  return (
    <div className={`pgn-wrapper ${className}`} data-position={position} style={style}>
      <div className="pgn push-on-sidebar-open pgn-circle">
        <div className={`alert alert-${type} p-r-10 box-shadow-small`}>
          <div className="full-width">
            {thumbnail && <div className="pgn-thumbnail">{thumbnail}</div>}
            {message && <div className="pgn-message full-width">{message}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}
NotificationMessage.defaultProps = {
  type: 'alert',
  position: 'top-right',
  thumbnail: null,
  message: null,
};
NotificationMessage.propTypes = {
  type: PropTypes.string,
  position: PropTypes.string,
  thumbnail: PropTypes.element,
  message: PropTypes.element,
};

export default NotificationMessage;
