import React from 'react';
import { injectIntl, FormattedMessage, FormattedNumber } from 'react-intl';
import { Label } from 'react-bootstrap';

import { ONLINE_CAR_DISCOUNT } from 'js/constants';

class SummaryListCarPerks extends React.Component {
  render() {
    return null;

    /* const { intl, projectConfig } = this.props;
    const messages = intl.messages;

    return (
      <div class="m-b-15">
        <Label class="m-r-10">{messages.can_be_ordered_online}</Label>
        <Label class="m-r-10">
          <FormattedMessage id="count_years_warranty" values={{ count: 2 }} />
        </Label>
        {ONLINE_CAR_DISCOUNT > 0 ? (
          <Label class="m-r-10">
            <FormattedMessage
              id="amount_online_advantage"
              values={{
                amount: (
                  <FormattedNumber
                    value={ONLINE_CAR_DISCOUNT}
                    style="currency"
                    currency={projectConfig.data.currency}
                  />
                ),
              }}
            />
          </Label>
        ) : null}
      </div>
    ); */
  }
}

export default injectIntl(SummaryListCarPerks);
