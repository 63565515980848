import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { addNotification } from 'actions/NotificationsActions';

class ItemActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
    };
  }

  getActions = () => {
    const {
      intl: { messages },
      listItemComponent,
      addActions,
    } = this.props;

    const actions = addActions.slice();
    const buttonProps = {
      block: true,
    };

    return actions;
  };

  render() {
    const { item, wrapperComponent } = this.props;

    const actions = this.getActions();
    const children = [];
    for (let i = 0; i < actions.length; i++) {
      children.push(actions[i]);
    }

    if (wrapperComponent === null) {
      return children;
    }

    return React.cloneElement(wrapperComponent, {}, children);
  }
}
ItemActions.defaultProps = {
  wrapperComponent: <div />,
  listItemComponent: null,
  actionExecuted: () => {},
  addActions: [],
};

const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {
    doNotify: (code, text) => {
      dispatch(addNotification(code, text));
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps,
      null,
      { forwardRef: true }
    )(ItemActions),
    { forwardRef: true }
  )
);
