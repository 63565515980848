import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Form, Button, Alert } from 'react-bootstrap';

import EntityStateUpdater from 'component/EntityStateUpdater';

// import Loader from 'components/layout/Loader';
import Data from 'components/Data';
import Entity from 'components/Entity';
import Icon from 'components/Icon';

import FormInput from 'components/forms/FormInput';

import { entityMetaMapping } from 'js/constants';

const entityMappingName = entityMetaMapping.anonymousUserInfo;

class AnonymousUserInfoForm extends EntityStateUpdater {
  constructor(props) {
    super(props);

    this.state = {
      entityLoaded: false,
      entityChanged: false,
    };

    this.entity = new Entity();
    // if we get service data from the outside, we can pass it on to our data entity
    this.entity.setData(
      props.anonymousUserInfo || {
        email: localStorage.getItem('anonymousEmail') || '',
        name: localStorage.getItem('anonymousName') || '',
      }
    );

    if (props.meta[entityMappingName] !== undefined && !props.meta[entityMappingName].pending) {
      this.entity.setMeta(props.meta, entityMappingName);

      this.state = {
        ...this.state,
        entityLoaded: true,
        entity: this.entity,
      };
    }
  }

  componentDidUpdate = () => {
    const { meta } = this.props;

    if (meta[entityMappingName] !== undefined && !meta[entityMappingName].pending && !this.entity.getMetaLoaded()) {
      this.entity.setMeta(meta, entityMappingName);
      if (this.entity.getDataLoaded()) {
        this.setState({ entityLoaded: true, entity: this.entity });
      }
    }
  };

  formSubmit = e => {
    e.preventDefault();

    this.setState(
      prevState => ({ entity: prevState.entity.validate() }),
      () => {
        const { onSubmit } = this.props;
        const { entity } = this.state;

        if (entity.isValid) {
          onSubmit(entity.processedData);
        }
      }
    );
  };

  render() {
    const {
      intl: { messages },
      appIntl,
      disabled,
      isConnecting,
    } = this.props;
    const { entity, entityLoaded } = this.state;

    return (
      <Form onSubmit={this.formSubmit}>
        <Data dataName="anonymousUserInfo" /> {/* fetch meta data */}
        {entityLoaded && (
          <React.Fragment>
            {!entity.isValid && <Alert bsStyle="warning">{messages.form_incorrect_data}</Alert>}

            <div className="form-group-attached">
              <FormInput
                name="email"
                label={messages.email}
                value={entity.getEmail()}
                meta={entity.getEmailMetaInformation()}
                validation={entity.getEmailValidationResult()}
                onChange={this.updateEntity}
                disabled={disabled}
              />
              <FormInput
                name="name"
                label={messages.client_name}
                value={entity.getName()}
                meta={entity.getNameMetaInformation()}
                validation={entity.getNameValidationResult()}
                onChange={this.updateEntity}
                disabled={disabled}
              />
            </div>
            <div className="small text-master-light m-t-20 m-b-10">
              <Link to={appIntl.urlPrefix + 'privacy'} className="text-master">
                {messages.accept_terms_and_condition_start_chat}
              </Link>
            </div>
            <Button block bsStyle="primary" bsSize="lg" type="submit" disabled={isConnecting}>
              {isConnecting && <Icon name="fa-spinner-third" iconClassName=" fa-spin" />} {messages.start_chat}
            </Button>
          </React.Fragment>
        )}
      </Form>
    );
  }
}
AnonymousUserInfoForm.defaultProps = {
  disabled: false,
};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    meta: state.entityMeta,
  };
};

export default connect(mapStateToProps)(injectIntl(AnonymousUserInfoForm));
