import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

Collapsible.propTypes = {
  icon: PropTypes.string.isRequired,
  variant: PropTypes.string,
  label: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  open: PropTypes.bool,
};

Collapsible.defaultProps = {
  variant: undefined,
  description: undefined,
  open: false,
};

function Collapsible({ icon, variant, label, description, open, children }) {
  const [show, setShow] = useState(open);

  const toggleShow = useCallback(
    event => {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      if (event && event.stopPropagation) {
        event.stopPropagation();
      }
      setShow(!show);
      return false;
    },
    [show, setShow]
  );

  return (
    <>
      <a onClick={toggleShow}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <div className={`collapsible${variant ? ' ' + variant : ''}${!show ? ' collapsed' : ''}`}>
          <Icon name={icon} iconClassName="main" />
          <div className="collapsible-label">
            <div className="label">{label}</div>
            <div className="description">{description}</div>
          </div>
          <div className="opener">{show ? <Icon name="fa-chevron-up" /> : <Icon name="fa-chevron-down" />}</div>
        </div>
      </a>
      {show && <div className="collapsible-content">{children}</div>}
    </>
  );
}

export default Collapsible;
