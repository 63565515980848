import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pick as _pick, pickBy as _pickBy, size as _size } from 'lodash';

import { resetCheckout, initCheckout, initCarCheckout, initCarRentCheckout } from 'actions/CheckoutActions';
import { userDataForCheckout } from 'helpers/clients';
import { parseQs, stringifyQs } from 'helpers/http';

import Loader from 'components/layout/Loader';

import Data from 'components/Data';
import DataId from 'components/DataId';

// check for URL params to forward the user to checkout
class CheckoutInit extends React.Component {
  constructor(props) {
    super(props);

    this.initStarted = false;

    this.state = {
      data: parseQs(props.location.search),
    };

    window.scrollTo(0, 0);
  }

  componentDidMount = () => {
    const { appIntl, history } = this.props;
    const { data } = this.state;

    if (!data || !data.type) {
      history.replace(appIntl.urlPrefix);
    }
  };

  componentWillUpdate = nextProps => {
    const { appIntl, history, user, checkout, itemsCount, doInitCarRentCheckout, doInitCarCheckout } = this.props;
    const { data } = this.state;

    let allItemsLoaded = false;
    let locationDataLoaded = false;
    if (data.items && itemsCount === nextProps.itemsLoadedCount) {
      allItemsLoaded = true;
    }

    if (!data.locationId || nextProps.locationDataLoaded) {
      locationDataLoaded = true;
    }

    if (!this.initStarted && allItemsLoaded && locationDataLoaded) {
      // we have all items data and we have the location data
      // we may now init the checkout

      this.initStarted = true;

      switch (data.type) {
        case 'item_sales': {
          // @todo
          break;
        }

        case 'car_sales': {
          const carData = nextProps.dataItems[data.items[0].id].data;

          doInitCarCheckout({ ...carData, price: carData.pricing.price, quantity: 1 }, userDataForCheckout(user));
          break;
        }

        case 'car_rent': {
          const carData = nextProps.dataItems[data.items[0].id].data;

          doInitCarRentCheckout(
            { ...carData, price: 0, quantity: 0 },
            nextProps.locationData.data,
            userDataForCheckout(user),
            data.userTimezone || 'UTC',
            data.dateFrom,
            data.dateTo
          );
        }
      }
    }

    if (!checkout.init && nextProps.checkout.init) {
      history.push({
        pathname: appIntl.urlPrefix + 'checkout',
        search: stringifyQs(_pick(data, ['error'])), // error could be in the url from oauth login
      });
    }
  };

  render() {
    const { locationData } = this.props;
    const { data } = this.state;

    // data.type -> item_sales, car_sales, car_rent
    // data.dateFrom
    // data.dateTo
    // data.items -> array with IDs
    // data.locationId
    // data.userTimezone

    const items = [];
    if (data.items && data.items.length) {
      for (let i = 0; i < data.items.length; i++) {
        items.push(
          <DataId
            dataIdName="item"
            dataIdId={data.items[i].id}
            url={'/api/items/' + data.items[i].id + '.json'}
            key={i}
          />
        );
      }
    }

    // check if we need to load the location data
    let location = null;
    if (data.locationId) {
      location = (
        <Data
          dataName="clientLocation"
          url={'/api/whitelabellocations/' + data.locationId + '.json'}
          data={locationData}
        />
      );
    }

    return (
      <div class="content">
        <div class="p-t-50 p-b-50">
          <h1 class="text-center">Initiating checkout</h1>

          <Loader />
        </div>

        {items}
        {location}
      </div>
    );
  }
}
CheckoutInit.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
  const res = {
    appIntl: state.intl,
    user: state.login.user,
    checkout: state.checkout,
  };

  const data = parseQs(ownProps.location.search);

  // check if we have a locationId
  // check the items we need to track
  if (data.locationId) {
    res.locationData = state.data.clientLocation;
    res.locationDataLoaded = !!(!state.data.clientLocation.pending && state.data.clientLocation.data.id);
  }

  // check for items
  if (data.items) {
    const items = _pickBy(state.dataId.item, (item, itemId) => {
      for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].id === itemId) {
          return true;
        }
      }

      return false;
    });

    res.itemsCount = data.items.length;
    res.dataItems = items;
    res.itemsLoadedCount = _size(
      _pickBy(items, item => {
        return !item.pending && item.data.id;
      })
    );
  }

  return res;
};
const mapDispatchToProps = dispatch => {
  return {
    doInitCarRentCheckout: (car, locationData, userData, userTimezone, dateFrom, dateTo) => {
      dispatch(resetCheckout(initCarRentCheckout(car, locationData, userData, userTimezone, dateFrom, dateTo)));
    },
    doInitCheckout: (items, userData) => {
      dispatch(resetCheckout(initCheckout(items, userData)));
    },
    doInitCarCheckout: (car, userData) => {
      dispatch(resetCheckout(initCarCheckout(car, userData)));
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CheckoutInit)
);
