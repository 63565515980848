/** @see https://github.com/ljharb/qs */
import { stringify, parse } from 'qs';

export const toQueryString = (obj, urlEncode = false) => {
  return stringifyQs(obj);
};

/**
 * Parse optionally nested query string into an Object. Example:
 * qs.parse('?a=b&c=d', { ignoreQueryPrefix: true });
 * { a: 'b', c: 'd' }
 */
export const parseQs = search => {
  return parse(search, { ignoreQueryPrefix: true });
};

export const stringifyQs = search => {
  return stringify(search, { arrayFormat: 'brackets' });
};

export const checkDomain = url => {
  if (url.indexOf('//') === 0) {
    url = location.protocol + url;
  }
  return url
    .toLowerCase()
    .replace(/([a-z])?:\/\//, '$1')
    .split('/')[0];
};

export const isExternal = url => {
  if (!url) return true;
  return (url.indexOf(':') > -1 || url.indexOf('//') > -1) && checkDomain(location.href) !== checkDomain(url);
};

export const dataURItoBlob = dataURI => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  // separate out the mime component
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  // write the bytes of the string to a typed array
  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};
