import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { find as _find } from 'lodash';

import CmsElement from 'components/cms/CmsElement';

import ModalMasterTermsOfService from 'components/modals/ModalMasterTermsOfService';
import ModalPrivacyPolicy from 'components/modals/ModalPrivacyPolicy';

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTerms: false,
      showPrivacyPolicy: false,
    };
  }

  showTerms = e => {
    e.preventDefault();

    this.setState({ showTerms: true });
  };

  closeTerms = () => {
    this.setState({ showTerms: false });
  };

  showPrivacyPolicy = e => {
    e.preventDefault();

    this.setState({ showPrivacyPolicy: true });
  };

  closePrivacyPolicy = () => {
    this.setState({ showPrivacyPolicy: false });
  };

  getCmsFooter = () => {
    const { appIntl, projectConfig } = this.props;
    const cmsFooter = _find(projectConfig.data.footer, ['language', appIntl.displayIntlForm]);

    return cmsFooter ? cmsFooter.content : [];
  };

  render() {
    const {
      intl: { messages },
      intl,
      location,
      appIntl,
      layout,
    } = this.props;
    const { showTerms, showPrivacyPolicy } = this.state;

    let checkout = false;
    if (location.pathname.indexOf(appIntl.urlPrefix + 'checkout') > -1) {
      checkout = true;
    }

    return checkout ? (
      <div class="text-right p-t-30 p-b-30 p-l-10 p-r-10">
        <a href="#terms" onClick={this.showTerms}>
          {messages.signup_agreement_text_terms}
        </a>
        {' | '}
        <a href="#privacy" onClick={this.showPrivacyPolicy}>
          {messages.signup_agreement_text_data_use}
        </a>

        <ModalMasterTermsOfService show={showTerms} close={this.closeTerms} />
        <ModalPrivacyPolicy show={showPrivacyPolicy} close={this.closePrivacyPolicy} />
      </div>
    ) : (
      <div>
        {!!this.getCmsFooter() &&
          this.getCmsFooter().map((element, i) => {
            return (
              <CmsElement index={i.toString()} {...element} intl={intl} appIntl={appIntl} layout={layout} key={i} />
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    layout: state.layout,
    projectConfig: state.data.projectConfig,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(Footer)));
