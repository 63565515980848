import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Clearfix } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import MainMenuLink from 'components/layout/mainMenus/common/MainMenuLink';

class LanguageMenu extends React.Component {
  constructor(props) {
    super(props);

    this.index = 1100;
  }

  onClick = e => {
    e.preventDefault();
  };

  setLanguage = (e, locale) => {
    e.preventDefault();

    const { doChangeLocale } = this.props;
    doChangeLocale(locale);
  };

  getText = () => {
    const { label } = this.props;

    return (!!label && label) || <i className="fal fa-globe" />;
  };

  getContent = () => {
    const { languages } = this.props;

    return (
      <Clearfix className="menu-dropdown">
        <ul
          className="dropdown-menu bg-transparent no-border no-padding no-shadow"
          style={{ position: 'static', display: 'block', float: 'none' }}
        >
          {languages.map(language => {
            return (
              <li key={language.value}>
                <a href={`#${language.value}`} onClick={e => this.setLanguage(e, language.value)}>
                  {language.label}
                </a>
              </li>
            );
          })}
        </ul>
      </Clearfix>
    );
  };

  render() {
    const { appIntl, layout, onMouseEnter, onMouseLeave, currentIndex, animatingOut, languages } = this.props;

    if (!languages || languages.length < 2) {
      return null;
    }

    return (
      <MainMenuLink
        flipId=""
        index={this.index}
        currentIndex={currentIndex}
        layout={layout}
        onMouseEnter={onMouseEnter}
        onDropdownClose={onMouseLeave}
        onClick={this.onClick}
        hasDropdown
        animatingOut={animatingOut}
        id="nav-language"
        className=""
        link="#selectLanguage"
        text={this.getText()}
        dropdownWidth={10}
        listClassName="nav-language"
        dropdownMenuElements={[
          {
            content: [{ type: 'raw', params: [{ name: 'content', value: this.getContent() }] }],
            language: appIntl.locale,
          },
        ]}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    user: state.login.user,
    projectConfig: state.data.projectConfig,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    doChangeLocale: locale => {
      dispatch({
        type: 'CHANGE_LOCALE',
        payload: {
          locale,
        },
      });

      let newLocationPathname = ownProps.location.pathname.replace(/^\//, '');

      if (newLocationPathname.search(new RegExp('de/|pl/|en/|tr/')) === 0) {
        newLocationPathname = newLocationPathname.substr(3);
      }

      ownProps.history.replace('/' + locale + '/' + newLocationPathname);
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(LanguageMenu)
  )
);
