import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import ScrollTrigger from 'react-scroll-trigger';

import DocMeta from 'components/DocMeta';

class GoPro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paypalButtonShown: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // if the user is not logged in, then redirect him to the login page
    if (!this.props.user.logged) {
      this.props.history.push(this.props.appIntl.urlPrefix + 'login');
    }
  }

  componentWillMount() {
    if (!this.props.user.logged) {
      this.props.history.push(this.props.appIntl.urlPrefix + 'login');
    }
  }

  render() {
    const { intl, appIntl } = this.props;
    const messages = intl.messages;

    const tags = [
      { name: 'title', content: messages.meta.go_pro.title },
      { name: 'description', content: messages.meta.go_pro.description },
      { itemProp: 'name', content: messages.meta.go_pro.name },
      { itemProp: 'description', content: messages.meta.go_pro.description },
      // { itemProp: 'image', content: 'http://www.example.com/image.jpg' },
    ];

    const paypalButtonClass = this.state.paypalButtonShown ? 'button-shown' : 'button-not-shown-yet';

    return (
      <DocMeta tags={tags}>
        <div>
          <section id="about" class="bg-light">
            <div class="container">
              <Row>
                <Col lg={8} lgOffset={2} class="col text-center">
                  <h2 class="section-heading">{messages.go_pro.title}</h2>
                  <hr />
                  <p class="text-faded">
                    Thanks for testing us out. We really appreciate it that you trust us on your business.
                  </p>
                  <p class="text-faded"></p>

                  <ScrollTrigger onEnter={() => this.setState({ paypalButtonShown: true })}>
                    <div>
                      <a href={appIntl.urlPrefix + 'subscribe'} class={paypalButtonClass + ' btn btn-lg btn-primary'}>
                        Go to Paypal
                      </a>
                    </div>
                  </ScrollTrigger>
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </DocMeta>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.user,
    appIntl: state.intl,
  };
};

export default injectIntl(withRouter(connect(mapStateToProps)(GoPro)));
