import React from 'react';
import { Clearfix } from 'react-bootstrap';

import { getTextColor } from 'components/forms/autocomplete/Color';

export default class SummaryFilterValue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mouseOver: false,
    };
  }

  getValue = () => {
    const { value } = this.props;
    return value.selectValue ? value.selectValue : value.name;
  };

  render() {
    const { type, value, checked, onClick } = this.props;
    const { mouseOver } = this.state;

    return (
      <div
        class={'p-t-10 p-b-10 p-l-10 cursor ' + (mouseOver ? '' : '')}
        onMouseEnter={() => this.setState({ mouseOver: true })}
        onMouseLeave={() => this.setState({ mouseOver: false })}
        onClick={() => onClick(this.getValue(), !checked)}
      >
        <div class="v-align-middle summary-filter-value">
          <Clearfix>
            {value.name}

            <div class="pull-right">
              {type === 'select' || type === 'boolean' ? (
                <i class={checked ? 'fas fa-check-square text-primary' : 'fal fa-square'} />
              ) : null}

              {type === 'colors' && checked ? <i class="fas fa-check-square" style={{ color: value.color }} /> : null}

              {type === 'colors' && !checked ? <i class="fas fa-square" style={{ color: value.color }} /> : null}
            </div>

            {/* mouseOver ? (<div class="m-l-15 hint-text pull-right">{value.count}</div>) : null */}
          </Clearfix>

          {value.description ? value.description : null}
        </div>
      </div>
    );
  }
}
