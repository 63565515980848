import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import AbstractCmsElement from './AbstractCmsElement';

export default class CmsElementRaw extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  render() {
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-raw ' + shownClass + this.getResponsiveClasses() + this.getCssClasses()}
        style={{
          ...this.getBackgroundStyle(),
        }}
      >
        {this.getElementStyle()}

        {this.getParamValue('content')}

        {this.getAppendHtml()}
      </div>
    );

    return (
      <>
        <ScrollTrigger onEnter={this.shown} />
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </>
    );
  }
}
CmsElementRaw.defaultProps = {
  index: '0',
};
