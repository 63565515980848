import React from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import { getTimezoneFromState } from '../../../helpers/intl';

class BackgroundElement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    const { show } = this.state;
    const { id, currentPage, params, attributes, setSelectedColor, blockContainerId } = this.props;
    let contentValue;
    let valueOfColor = '';
    _.forEach(params, param => {
      if (param.name === attributes['data-name']) {
        valueOfColor = param?.value;
      }
    });

    if (attributes['data-backgrounds-tag']) {
      const nameToFind = 'bgColorNew';
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = valueOfColor;
      const metaTag = tempDiv.querySelector(`meta[name="${nameToFind}"]`);

      if (metaTag) {
        contentValue = metaTag.getAttribute('content');
      }
      if (show === false) {
        let page = false;
        let section = false;
        let stage = false;
        if (typeof attributes['data-backgrounds-tag'] !== 'undefined') {
          if (attributes['data-backgrounds-tag'].includes('section')) {
            section = true;
          }
          if (attributes['data-backgrounds-tag'].includes('page')) {
            page = true;
          }
          if (attributes['data-backgrounds-tag'].includes('stage')) {
            stage = true;
          }
          if (setSelectedColor) {
            setSelectedColor(
              id,
              contentValue,
              currentPage,
              '',
              section,
              page,
              blockContainerId,
              stage,
              attributes['data-name']
            );
          }
        }
      }
    }
    if (attributes['data-backgrounds-init'] && contentValue === undefined) {
      let initialUpdate = true;
      this.changeBackgroundE(attributes['data-backgrounds-init'], id, attributes['data-name'], initialUpdate);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { show } = this.state;
    const { id, currentPage, params, attributes, setSelectedColor, blockContainerId, colorSettings } = this.props;
    let contentValue;
    let valueOfColor = '';
    _.forEach(params, param => {
      if (param.name === attributes['data-name']) {
        valueOfColor = param?.value;
      }
    });

    const nameToFind = 'bgColorNew';
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = valueOfColor;
    const metaTag = tempDiv.querySelector(`meta[name="${nameToFind}"]`);

    if (metaTag) {
      contentValue = metaTag.getAttribute('content');
    }


    if (attributes['data-backgrounds-init'] && contentValue === undefined) {
      let initialUpdate = true;
      this.changeBackgroundE(attributes['data-backgrounds-init'], id, attributes['data-name'], initialUpdate);
    }


    if (attributes['data-backgrounds-tag']) {
      if (show === false) {
        let page = false;
        let section = false;
        let stage = false;
        if (typeof attributes['data-backgrounds-tag'] !== 'undefined') {
          if (attributes['data-backgrounds-tag'].includes('section')) {
            section = true;
          }
          if (attributes['data-backgrounds-tag'].includes('page')) {
            page = true;
          }
          if (attributes['data-backgrounds-tag'].includes('stage')) {
            stage = true;
          }
          if (setSelectedColor && JSON.stringify(prevProps.colorSettings) !== JSON.stringify(colorSettings)) {
            setSelectedColor(id, contentValue, currentPage, '', section, page, blockContainerId, stage, attributes['data-name']);
          }
        }
      }
    }
  }

  changeBackgroundE = (bgColorE, blockId, dataNameE, initialUpdate) => {
    let metaTag = '';
    const { updateParam, id, currentPage, attributes, setSelectedColor, blockContainerId } = this.props;
    metaTag = `<meta content="${bgColorE}" name="bgColorNew"/>`;
    const dataToStore = `${metaTag}`;
    updateParam(blockId, dataNameE, dataToStore, null, '', '', currentPage, initialUpdate);

    // update colorSettings
    let page = false;
    let section = false;
    let stage = false;
    if (typeof attributes['data-backgrounds-tag'] !== 'undefined') {
      if (attributes['data-backgrounds-tag'].includes('section')) {
        section = true;
      }
      if (attributes['data-backgrounds-tag'].includes('page')) {
        page = true;
      }
      if (attributes['data-backgrounds-tag'].includes('stage')) {
        stage = true;
      }
      if (setSelectedColor) {
        setSelectedColor(id, bgColorE, currentPage, '', section, page, blockContainerId, stage, attributes['data-name']);
      }
    }
  };

  showAndHide = () => {
    if (this.state.show === true) {
      this.setState({ show: false });
    } else {
      this.setState({ show: true });
    }
  };

  updateAttributes = () => {
    const { attributes, params } = this.props;
    const _attributes = _.cloneDeep(attributes);
    let valueOfColor = '';
    let contentValue = '';
    _.forEach(params, param => {
      if (param.name === attributes['data-name']) {
        valueOfColor = param?.value;
      }
    });
    const nameToFind = 'bgColorNew';
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = valueOfColor;
    const metaTag = tempDiv.querySelector(`meta[name="${nameToFind}"]`);

    if (metaTag) {
      contentValue = metaTag.getAttribute('content');
    }
    _attributes.className = [`${_attributes.className} ${contentValue}`].join();

    return _attributes;
  };

  render() {
    const {
      name: Tag,
      attributes,
      settings,
      intl: { messages },
      block,
    } = this.props;

    const output = [];
    const _attributes = this.updateAttributes();

    output.push(
      <>
        {block?.template &&
          block?.template.includes('data-backgrounds') &&
          (settings?.back_colors || (settings?.target && settings?.target?.back_colors)) && (
            <Button
              data-block={block?.id}
              data-parent={block?.id}
              bsSize="large"
              className="toggle_bg colorButton"
              onClick={() => this.showAndHide()}
            >
              <i
                className="fas fa-fill-drip"
                data-block={block?.id}
                data-parent={block?.id}
                onClick={() => this.showAndHide()}
              />
              {this.state.show === true ? (
                <ButtonGroup className="edit-color" bsSize="small" vertical>
                  {settings?.target &&
                    settings?.target?.back_colors &&
                    settings?.target?.back_colors.map(bgColor => {
                      const getBackgroundAttr = _attributes['data-backgrounds'];
                      if (
                        getBackgroundAttr &&
                        (getBackgroundAttr.includes(bgColor?.label[0]?.name) || getBackgroundAttr.includes('true'))
                      ) {
                        return (
                          <Button
                            data-tip={messages.editor.change_backgroundcolor}
                            data-for="blockinfo"
                            data-block={bgColor?.id}
                            data-parent={block?.id}
                            onClick={() =>
                              this.changeBackgroundE(bgColor?.label[0]?.name, block.id, attributes['data-name'])
                            }
                            style={{ padding: 4 }}
                          >
                            <p
                              style={{
                                width: 40,
                                height: 40,
                                padding: 0,
                                margin: 0,
                                background: bgColor.color,
                              }}
                            />
                          </Button>
                        );
                      }
                      return null;
                    })}
                  {settings?.back_colors &&
                    settings?.back_colors.map(bgColor => {
                      const getBackgroundAttr = _attributes['data-backgrounds'];
                      if (
                        getBackgroundAttr &&
                        (getBackgroundAttr.includes(bgColor.label[0].name) || getBackgroundAttr.includes('true'))
                      ) {
                        return (
                          <Button
                            data-tip={messages.editor.change_backgroundcolor}
                            data-for="blockinfo"
                            data-block={bgColor?.id}
                            data-parent={block?.id}
                            onClick={() =>
                              this.changeBackgroundE(bgColor?.label[0]?.name, block?.id, attributes['data-name'])
                            }
                            style={{ padding: 4 }}
                          >
                            <p
                              style={{
                                width: 40,
                                height: 40,
                                padding: 0,
                                margin: 0,
                                background: bgColor?.color,
                              }}
                            />
                          </Button>
                        );
                      }
                      return null;
                    })}
                </ButtonGroup>
              ) : (
                ''
              )}
            </Button>
          )}
      </>
    );

    return (
      <Tag {..._attributes} contentEditable={false}>
        {output}
      </Tag>
    );
  }
}
const mapStateToProps = state => {
  return {
    defaultTimezone: getTimezoneFromState(state),
    appIntl: state.intl,
  };
};
export default withRouter(injectIntl(connect(mapStateToProps)(BackgroundElement)));
