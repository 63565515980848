import React from 'react';
import { Link } from 'react-router-dom';

import MapMarker from 'components/layout/MapMarker';

class SingleLocationMarker extends React.Component {
  render() {
    const { location, isActive, getLink, closeTooltip } = this.props;

    return (
      <MapMarker
        locationId={location.locationId}
        active={isActive}
        title={<div class="lh-16">{location.company}</div>}
        tooltip={
          <div class="list-view" style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <ul class="no-border">
              <li class="p-l-10 p-r-10 no-overflow block" style={{ display: 'block' }}>
                <Link to={getLink(location)} class="text-master">
                  <span class="block small hint-text">
                    {location.street} {location.house_number} {location.apartment_number}
                    <br />
                    {location.zip_code} {location.city}
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        }
        closeTooltip={closeTooltip}
      />
    );
  }
}

export default SingleLocationMarker;
