import React from 'react';
import { connect } from 'react-redux';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import { submitLogin } from 'actions/LoginActions';

import LoginForm from 'components/forms/auth/LoginForm';
import LoginSocialMediaButtons from 'components/layout/LoginSocialMediaButtons';

import RichEditorContent from 'components/RichEditorContent';
import AbstractCmsElement from './AbstractCmsElement';

class CmsElementLoginForm extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  onSubmit = args => {
    this.props.onSubmit(args, this.props.appIntl.urlPrefix);
  };

  componentWillUnmount = () => {
    clearTimeout(this.submitTimeout);
    this.__componentWillUnmount();
  };

  render() {
    const {
      intl: { messages },
      projectConfig,
      hideTitle,
      socialMediaLoginState,
    } = this.props;
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const textClass = 'text-' + this.getParamValue('mainColor', 'master') + ' ';

    const showSocialMediaLogin =
      projectConfig.data.facebook_login_client_id ||
      projectConfig.data.google_login_client_id ||
      projectConfig.data.microsoft_login_client_id;

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-login-form-content ' + shownClass + this.getCssClasses()}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <div class="ms-login-form-container">
          {!hideTitle && (
            <h4 class={'no-margin p-b-5 ' + textClass}>
              <i class="fa fa-lock m-r-5" /> {this.getParamValue('title', '')}
            </h4>
          )}

          <RichEditorContent content={'<p class="' + textClass + '">' + this.getParamValue('content', '') + '</p>'} />

          <LoginForm onSubmit={this.onSubmit} buttonTitle={this.getParamValue('buttonTitle', messages.button_signin)} />

          {showSocialMediaLogin && (
            <div class="m-t-30">
              <h4>
                <center>{messages.alternative_login_long}</center>
              </h4>

              <center>
                <LoginSocialMediaButtons socialMediaLoginState={socialMediaLoginState} />
              </center>
            </div>
          )}
        </div>

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementLoginForm.defaultProps = {
  index: '0',
  socialMediaLoginState: null,
  hideTitle: false,
};

const mapStateToProps = state => {
  return {
    projectConfig: state.data.projectConfig,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  const res = {};

  if (!ownProps.onSubmit) {
    res.onSubmit = (args, urlPrefix) => {
      dispatch(submitLogin(args, urlPrefix));
    };
  }

  return res;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CmsElementLoginForm);
