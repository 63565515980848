import React from 'react';
import { DayPickerSingleDateController } from 'react-dates';
import { Row, Col, Overlay, Popover, Clearfix } from 'react-bootstrap';
import moment from 'moment';
import 'moment-timezone';

import generateId from 'helpers/id';

import IntlDateTime from 'components/layout/IntlDateTime';
import TimePicker from 'components/forms/TimePicker';

export default class DateTimePicker extends React.Component {
  constructor(props) {
    super(props);

    moment.locale(globalLocale);

    this.state = {
      value: moment.tz(props.value, props.returnTz).toISOString(),
      focused: false,
      pickerId: generateId('date-picker-'),
      mouseOver: false,
    };

    this.inputRef = React.createRef();
  }

  checkFocused = () => {
    return this.state.focused ? 'focused ' : '';
  };

  checkRequired = () => {
    return this.props.meta.validate.rules.indexOf('required') !== -1 ? 'required ' : '';
  };

  checkError = () => {
    return this.props.validation.valid ? '' : 'has-error ';
  };

  // check if user may read this field
  checkAccess = () => {
    return this.props.meta.acl.read;
  };

  // check if user may write to this field
  checkReadOnly = () => {
    return !this.props.meta.acl.write;
  };

  resetDate = e => {
    e.preventDefault();
    e.stopPropagation();

    const { name, onChange } = this.props;
    onChange(name, null);

    this.setState({
      value: null,
    });
  };

  handleDateSelect = date => {
    const { name, value, locale, displayTz, returnTz, onChange } = this.props;

    if (date) {
      const oldDate = moment.tz(value, returnTz);
      const newDate = moment.tz(date, displayTz).tz(returnTz);
      newDate.hours(oldDate.hours());
      newDate.minutes(oldDate.minutes());
      onChange(name, newDate);

      date.locale(locale);
      this.setState({
        value: moment
          .tz(date, displayTz)
          .tz(returnTz)
          .toISOString(),
      });
    }
  };

  handleTimeSelect = datetime => {
    const { name, locale, displayTz, returnTz, onChange } = this.props;

    if (datetime) {
      const newDate = moment.tz(datetime, displayTz).tz(returnTz);
      onChange(name, newDate);

      datetime.locale(locale);
      this.setState({
        value: moment
          .tz(datetime, displayTz)
          .tz(returnTz)
          .toISOString(),
      });
    }
  };

  checkDisabled = () => {
    const { disabled, meta } = this.props;
    if (disabled) {
      return true;
    }
    if (!meta.acl.write) {
      return true;
    }
    return false;
  };

  isOutsideRange = date => {
    if (this.props.minDate) {
      let minDate = moment.tz(this.props.minDate, 'UTC');
      if (date.isBefore(minDate)) {
        return true;
      }
    }
    if (this.props.maxDate) {
      let maxDate = moment.tz(this.props.maxDate, 'UTC');
      if (date.isAfter(maxDate)) {
        return true;
      }
    }

    return false;
  };

  onFocus = () => {
    this.setState({ focused: true });
  };

  onBlur = () => {
    this.setState({ focused: false });
  };

  onMouseEnter = () => {
    this.setState({ mouseOver: true });
  };

  onMouseLeave = () => {
    this.setState({ mouseOver: false });
  };

  render() {
    const {
      label,
      className,
      style,
      isDayBlocked,
      appendToBody,
      value,
      showClearDate,
      placeholder,
      locale,
      returnTz,
      displayTz,
    } = this.props;
    const { pickerId, focused, mouseOver } = this.state;

    const displayTzValue = moment.tz(value, returnTz).tz(displayTz);

    return (
      <div
        class={
          'cursor form-group form-group-default ' +
          this.checkFocused() +
          this.checkRequired() +
          this.checkError() +
          (this.checkDisabled() ? 'disabled ' : '') +
          ' ' +
          className
        }
        style={{ overflow: 'visible', paddingBottom: appendToBody ? '4px' : '3px', ...style }}
        onClick={this.onFocus}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <label>{label}</label>
        <div class="lh-25">
          <Clearfix>
            <span ref={this.inputRef}>{value ? <IntlDateTime date={value} /> : placeholder || '-'}</span>
            {showClearDate && (
              <span className="pull-right">
                <a
                  href="#resetDate"
                  className={`text-danger p-l-5 p-r-5 ${mouseOver ? 'opacity-show ' : 'opacity-hide '}`}
                  onClick={this.resetDate}
                >
                  ×
                </a>
              </span>
            )}
          </Clearfix>

          <Overlay show={focused} target={this.inputRef.current} placement="bottom" rootClose onHide={this.onBlur}>
            <Popover id={pickerId} style={{ maxWidth: 'none', zIndex: 1200 }}>
              <Row>
                <Col xs={9} className="b-r b-grey">
                  <DayPickerSingleDateController
                    date={value && displayTzValue}
                    onDateChange={this.handleDateSelect}
                    // focused={focused}
                    // onFocusChange={({ focused }) => this.setState({ focused })}
                    // id={label + name}
                    numberOfMonths={1}
                    noBorder
                    hideKeyboardShortcutsPanel
                    isOutsideRange={this.isOutsideRange}
                    isDayBlocked={isDayBlocked}
                    /** @see https://github.com/react-dates/react-dates/blob/master/src/components/DayPickerSingleDateController.jsx
                     * for available properties.
                     */
                    // block
                    // small
                    // verticalSpacing={0}
                    // showClearDate={showClearDate}
                    // placeholder={placeholder}
                  />
                </Col>
                <Col xs={3} className="p-t-60">
                  <TimePicker
                    displayTz={displayTz}
                    locale={locale}
                    value={moment(value).toISOString()}
                    onChange={value => this.handleTimeSelect(moment(value))}
                  />
                </Col>
              </Row>
            </Popover>
          </Overlay>
        </div>
      </div>
    );
  }
}
DateTimePicker.defaultProps = {
  label: '',
  name: '',
  meta: { validate: { rules: '' }, acl: { read: true, write: true } },
  validation: { valid: true },
  value: null,
  onChange: () => {},
  disabled: false,
  displayTz: 'CET',
  returnTz: 'CET',
  className: '',
  style: {},
  locale: 'en',
  showClearDate: false,
};
