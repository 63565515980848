import whiteLabelRequiredOverride from './helpers/whiteLabelRequiredOverride';
const projectConfig = require('../../project.config.js');
export const ROLE_SUPERADMIN = 'ROLE_SUPERADMIN';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_USER = 'ROLE_USER';
export const ROLE_EMPLOYEE = 'ROLE_EMPLOYEE';
export const ROLE_ROOT_EMPLOYEE = 'ROLE_ROOT_EMPLOYEE';
export const ROLE_CUSTOMER = 'ROLE_CUSTOMER';
export const ROLE_COMPANY = 'ROLE_COMPANY';
export const ROLE_VENDOR = 'ROLE_VENDOR';
export const ROLE_CRM_MASTER = 'ROLE_CRM_MASTER';
export const ROLE_CRM_SUPPORT = 'ROLE_CRM_SUPPORT';
export const ROLE_CRM_FINANCIAL = 'ROLE_CRM_FINANCIAL';
export const ROLE_CRM_LOCATION = 'ROLE_CRM_LOCATION';
export const ROLE_CRM_PROFILE = 'ROLE_CRM_PROFILE';
export const ROLE_CRM_SUBSCRIPTION = 'ROLE_CRM_SUBSCRIPTION';
export const ROLE_CRM_PASSWORD = 'ROLE_CRM_PASSWORD';
export const ROLE_CRM_ROLES = 'ROLE_CRM_ROLES';
export const ROLE_CMS_PAGES = 'ROLE_CMS_PAGES';
export const ROLE_ERP_MASTER = 'ROLE_ERP_MASTER';
export const ROLE_ERP_ITEM = 'ROLE_ERP_ITEM';
export const ROLE_AUTHORIZE = 'ROLE_AUTHORIZE';
export const ROLE_STANDARD = 'ROLE_STANDARD';
export const ROLE_DIRECT_CUSTOMER = 'ROLE_DIRECT_CUSTOMER';
export const ROLE_WL_ADMIN = 'ROLE_WL_ADMIN';
export const ROLE_PROVISION_VALID = 'ROLE_PROVISION_VALID';

export const DEFAULT_CLIENT_ROLES = [ROLE_USER, ROLE_COMPANY];
export const DEFAULT_USER_ROLES = [ROLE_USER, ROLE_EMPLOYEE, ROLE_STANDARD];

export const HEADER_HEIGHT = 60;
export const PROJECT_NAME = projectConfig.CONFIG_PROJECT_NAME;
export const DOMAIN =
  window.location.origin.indexOf('.local') !== -1
    ? projectConfig.CONFIG_PROJECT_DOMAIN_DEV
    : projectConfig.CONFIG_PROJECT_DOMAIN_PROD;
export const BASENAME = window.location.origin.indexOf('.local') !== -1 ? '' : '';
export const GOOGLE_MAPS_KEY = '';
export const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&libraries=places&language=${globalLocale}`;
export const ONLINE_CAR_DISCOUNT = 0;

export const PICKUP_AVAILABLE = false;

export const AUTOMOTIVE_RENT_PLUGIN = 'AUTOMOTIVE_RENT';
export const AUTOMOTIVE_SALES_PLUGIN = 'AUTOMOTIVE_ECOMMERCE'; // added temporary to remove warnings
export const CHEDRI_COMMS_CENTER_PLUGIN = 'CHEDRI_COMMS_CENTER';
export const EMAIL_INTEGRATION_MS_PLUGIN = 'EMAIL_INTEGRATION_MS';
export const CONNECTME_PLUGIN = 'CONNECTME';
export const PAYMENT_PLUGIN_PAYPAL_PLUS = 'PAYPAL_PLUS';
export const PAYMENT_PLUGIN_STRIPE_PLAIN = 'STRIPE_PLAIN';
export const PAYMENT_PLUGIN_STRIPE_CONNECT = 'STRIPE_CONNECT';
export const PAYMENT_CASH = 'CASH';
export const PAYMENT_NONE = 'NONE';
export const PAYMENT_BILL = 'BILL';
export const PAYMENT_OPTION_BILL = 'bill';
export const PAYMENT_OPTION_STRIPE_PLAIN = 'stripePlain';

export const STATUS_NEW = 'new';
export const STATUS_IN_PROGRESS = 'inProgress';
export const STATUS_CLARIFY = 'clarify';
export const STATUS_COMPLETED = 'completed';

export const SHIPPING_PICKUP = 'pickup';
export const SHIPPING_TRANSPORT = 'transport';

export const SHIPPING_PARCEL_COST = 5.95;

export const emailEntityData = {
  type: 'string',
  acl: { read: true, write: true },
  validate: {
    rules: 'required,isEmail',
    messages: { required: 'email_required', isEmail: 'email_invalid' },
  },
};

// Managed fields from Client and Contact documents present in Signup form
export const managedFieldNames = [
  'salutation',
  'firstName',
  'lastName',
  'company',
  'mobile',
  'phone',
  'street',
  'houseNumber',
  'apartmentNumber',
  'addressLine2',
  'zipCode',
  'city',
  'country',
];

const entityMetaStatic = {
  'Pass:Pass': {
    name: 'Pass:Pass',
    data: {
      password: {
        name: 'password',
        type: 'string',
        acl: { read: true, write: true },
        validate: {
          rules: 'required,isLength:8:30',
          messages: {
            required: 'password_required',
            isLength: 'password_length',
          },
        },
      },
      repeatPassword: {
        name: 'repeatPassword',
        type: 'string',
        acl: { read: true, write: true },
        validate: {
          rules: 'required,isLength:8:30',
          messages: {
            required: 'repeat_password_required',
            isLength: 'repeat_password_length',
          },
        },
      },
    },
  },
  'Checkout:UserData': {
    name: 'Checkout:UserData',
    data: {
      salutation: {
        name: 'salutation',
        type: 'string',
        acl: { read: true, write: true },
        validate: {
          rules: 'required',
          messages: { required: 'salutation_required' },
        },
      },
      gender: {
        name: 'gender',
        type: 'string',
        acl: { read: true, write: true },
        validate: {
          rules: 'required',
          messages: { required: 'gender_required' },
        },
      },
      firstName: {
        name: 'firstName',
        type: 'string',
        acl: { read: true, write: true },
        validate: {
          rules: 'required,isLength:2:50',
          messages: {
            required: 'first_name_required',
            isLength: 'first_name_required',
          },
        },
      },
      lastName: {
        name: 'lastName',
        type: 'string',
        acl: { read: true, write: true },
        validate: {
          rules: 'required,isLength:2:50',
          messages: {
            required: 'last_name_required',
            isLength: 'last_name_required',
          },
        },
      },
      street: {
        name: 'street',
        type: 'string',
        acl: { read: true, write: true },
        validate: {
          rules: 'required,isLength:2:50',
          messages: { required: 'street_required', isLength: 'street_required' },
        },
      },
      houseNumber: {
        name: 'houseNumber',
        type: 'string',
        acl: { read: true, write: true },
        validate: {
          rules: 'required,isLength:1:20',
          messages: {
            required: 'house_number_required',
            isLength: 'house_number_required',
          },
        },
      },
      apartmentNumber: {
        name: 'apartmentNumber',
        type: 'string',
        acl: { read: true, write: true },
        validate: { rules: '', messages: {} },
      },
      addressLine2: {
        name: 'addressLine2',
        type: 'string',
        acl: { read: true, write: true },
        validate: { rules: '', messages: {} },
      },
      zipCode: {
        name: 'zipCode',
        type: 'string',
        acl: { read: true, write: true },
        validate: {
          rules: 'required,isLength:4:8',
          messages: {
            required: 'zip_code_required',
            isLength: 'zip_code_invalid',
          },
        },
      },
      city: {
        name: 'city',
        type: 'string',
        acl: { read: true, write: true },
        validate: {
          rules: 'required,isLength:3:50',
          messages: { required: 'city_required', isLength: 'city_required' },
        },
      },
      country: {
        name: 'country',
        type: 'string',
        acl: { read: true, write: true },
        validate: {
          rules: 'required,isLength:2:2',
          messages: {
            required: 'country_required',
            isLength: 'country_required',
          },
        },
      },
      mobile: {
        name: 'mobile',
        type: 'string',
        acl: { read: true, write: true },
        validate: { rules: '', messages: {} },
      },
      phone: {
        name: 'phone',
        type: 'string',
        acl: { read: true, write: true },
        validate: { rules: '', messages: {} },
      },
    },
  },
};

entityMetaStatic['Checkout:UserDataWithRegistration'] = {
  name: 'Checkout:UserDataWithRegistration',
  data: {
    email: { name: 'email', ...emailEntityData },
    ...entityMetaStatic['Pass:Pass'].data,
    ...entityMetaStatic['Checkout:UserData'].data,
  },
};

entityMetaStatic['Checkout:CompanyData'] = {
  name: 'Checkout:CompanyData',
  data: {
    ...entityMetaStatic['Checkout:UserData'].data,
    company: {
      name: 'company',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required',
        messages: { required: 'company_required' },
      },
    },
  },
};

entityMetaStatic['Checkout:CompanyDataWithRegistration'] = {
  name: 'Checkout:CompanyDataWithRegistration',
  data: {
    email: { name: 'email', ...emailEntityData },
    ...entityMetaStatic['Pass:Pass'].data,
    ...entityMetaStatic['Checkout:UserData'].data,
    company: {
      name: 'company',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required',
        messages: { required: 'company_required' },
      },
    },
  },
};

// newsletter form (cms)
entityMetaStatic['Cms:NewsletterForm'] = {
  name: 'Cms:NewsletterForm',
  data: {
    email: { name: 'email', ...emailEntityData },
  },
};

// login form (cms)
entityMetaStatic['Cms:LoginForm'] = {
  name: 'Cms:LoginForm',
  data: {
    username: {
      name: 'username',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required,isLength:2:50',
        messages: {
          required: 'username_required',
          isLength: 'username_required',
        },
      },
    },
    password: {
      name: 'password',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required,isLength:8:30',
        messages: {
          required: 'password_required',
          isLength: 'password_length',
        },
      },
    },
  },
};

// signup form (cms)
const CmsSignupFormMetaDataSource = {
  company: {
    name: 'company',
    type: 'string',
    acl: { read: true, write: true },
    validate: { rules: '', messages: {} },
  },
  email: { name: 'email', ...emailEntityData },
  mobile: {
    name: 'mobile',
    type: 'string',
    acl: { read: true, write: true },
    validate: {
      rules: 'required,isLength:8:30',
      messages: {
        required: 'This value should not be blank.',
        isLength: 'This value should not be blank.',
      },
    },
  },
  ...entityMetaStatic['Checkout:UserData'].data,
  ...entityMetaStatic['Pass:Pass'].data,
};

entityMetaStatic['Cms:SignupForm'] = {
  name: 'Cms:SignupForm',
  data: whiteLabelRequiredOverride(CmsSignupFormMetaDataSource),
};

// company signup form (chedri)
entityMetaStatic['Chedri:CompanySignupForm'] = {
  name: 'Chedri:CompanySignupForm',
  data: {
    ...entityMetaStatic['Cms:SignupForm'].data,
    company: {
      name: 'company',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required',
        messages: { required: 'company_required' },
      },
    },
    companyEmail: { name: 'companyEmail', ...emailEntityData },
    testDomain: {
      name: 'testDomain',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required',
        messages: { required: 'test_domain_required' },
      },
    },
    liveDomain: {
      name: 'liveDomain',
      type: 'string',
      acl: { read: true, write: true },
      validate: { rules: '', messages: {} },
    },
    plugins: {
      name: 'plugins',
      type: 'array',
      acl: { read: true, write: true },
      validate: { rules: 'required', messages: 'plugins_required' },
    },
  },
};

// contact form (cms)
entityMetaStatic['Cms:ContactForm'] = {
  name: 'Cms:ContactForm',
  data: {
    email: { name: 'email', ...emailEntityData },
    name: {
      name: 'name',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required,isLength:2:50',
        messages: { required: 'name_required', isLength: 'name_required' },
      },
    },
    content: {
      name: 'content',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required,isLength:2:5000',
        messages: {
          required: 'message_required',
          isLength: 'message_required',
        },
      },
    },
  },
};

// more information about cash payment (for cars)
entityMetaStatic['Checkout:PaymentCashAdditionalInfos'] = {
  name: 'Checkout:PaymentCashAdditionalInfos',
  data: {
    placeOfBirth: {
      name: 'placeOfBirth',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required,isLength:2:50',
        messages: {
          required: 'place_of_birth_required',
          isLength: 'place_of_birth_required',
        },
      },
    },
    dateOfBirth: {
      name: 'dateOfBirth',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required,isLength:2:5000',
        messages: {
          required: 'date_of_birth_required',
          isLength: 'date_of_birth_required',
        },
      },
    },
    nationality: {
      name: 'nationality',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required,isLength:2:5000',
        messages: {
          required: 'nationality_required',
          isLength: 'nationality_required',
        },
      },
    },
  },
};

entityMetaStatic['Financial:BankData'] = {
  name: 'Financial:BankData',
  data: {
    accountHolder: {
      name: 'accountHolder',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required,isLength:2:5000',
        messages: {
          required: 'account_holder_required',
          isLength: 'account_holder_required',
        },
      },
    },
    iban: {
      name: 'iban',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required,isIban',
        messages: {
          required: 'iban_required',
          isIban: 'iban_has_wrong_format',
        },
      },
    },
    swiftBic: {
      name: 'swiftBic',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required,isLength:8:11',
        messages: {
          required: 'swift_bic_required',
          isLength: 'swift_bic_required',
        },
      },
    },
    bankName: {
      name: 'bankName',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required,isLength:2:5000',
        messages: {
          required: 'bank_name_required',
          isLength: 'bank_name_required',
        },
      },
    },
    bankLocation: {
      name: 'bankLocation',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required,isLength:2:5000',
        messages: {
          required: 'bank_location_required',
          isLength: 'bank_location_required',
        },
      },
    },
  },
};

// payment instructions when a car with wire transfer payment is ordered
// also used to enter user's bank data (for example when bank data is needed for car registration)
entityMetaStatic['OrderAction:PaymentInstructions'] = {
  name: 'OrderAction:PaymentInstructions',
  data: {
    ...entityMetaStatic['Financial:BankData'].data,
    reference: {
      name: 'reference',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required,isLength:2:5000',
        messages: {
          required: 'reference_required',
          isLength: 'reference_required',
        },
      },
    },
    paymentInstructions: {
      name: 'paymentInstructions',
      type: 'string',
      acl: { read: true, write: true },
      validate: { rules: '', messages: {} },
    },
  },
};

entityMetaStatic['PluginSettings:CommerceAutomotiveCarRental'] = {
  name: 'PluginSettings:CommerceAutomotiveCarRental',
  data: {
    maxRentDays: {
      name: 'maxRentDays',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required',
        messages: { required: 'This value should not be blank' },
      },
    },
    maxBookingFuture: {
      name: 'maxBookingFuture',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required',
        messages: { required: 'This value should not be blank' },
      },
    },
  },
};
entityMetaStatic['PluginSettings:Connectme'] = {
  name: 'PluginSettings:Connectme',
  data: {
    onlineAppointmentBookingPath: {
      name: 'onlineAppointmentBookingPath',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required',
        messages: { required: 'This value should not be blank' },
      },
    },
    vinsIgnoreList: {
      name: 'vinsIgnoreList',
      type: 'string',
      acl: { read: true, write: true },
      validate: { rules: '', messages: {} },
    },
  },
};
entityMetaStatic['PluginSettings:PartnerConnect'] = {
  name: 'PluginSettings:PartnerConnect',
  data: {
    customersIgnoreList: {
      name: 'customersIgnoreList',
      type: 'string',
      acl: { read: true, write: true },
      validate: { rules: '', messages: {} },
    },
  },
};
entityMetaStatic['PluginSettings:ChedriCommsCenter'] = {
  name: 'PluginSettings:ChedriCommsCenter',
  data: {
    allowAnonymousWebrtc: {
      name: 'allowAnonymousWebrtc',
      type: 'string',
      acl: { read: true, write: true },
      validate: { rules: '', messages: {} },
    },
  },
};
entityMetaStatic['PluginSettings:AutomotiveEcommerce'] = {
  name: 'PluginSettings:AutomotiveEcommerce',
  data: {
    carReservationFeeEnabled: {
      name: 'carReservationFeeEnabled',
      type: 'string',
      acl: { read: true, write: true },
      validate: { rules: '', messages: {} },
    },
    carReservationFeeAmount: {
      name: 'carReservationFeeAmount',
      type: 'string',
      acl: { read: true, write: true },
      validate: { rules: '', messages: {} },
    },
  },
};

// contact form (cms)
entityMetaStatic['CommsCenter:AnonymousUserInfo'] = {
  name: 'CommsCenter:AnonymousUserInfo',
  data: {
    email: { name: 'email', ...emailEntityData },
    name: {
      name: 'name',
      type: 'string',
      acl: { read: true, write: true },
      validate: {
        rules: 'required,isLength:2:50',
        messages: { required: 'name_required', isLength: 'name_required' },
      },
    },
  },
};

// review of comms center
entityMetaStatic['CommsCenter:ChatReview'] = {
  name: 'CommsCenter:ChatReview',
  data: {
    overallStars: {
      name: 'overallStars',
      type: 'integer',
      acl: { read: true, write: true },
      validate: {
        rules: 'required',
        messages: { required: 'stars_rating_required' },
      },
    },
    remarks: {
      name: 'remarks',
      type: 'string',
      acl: { read: true, write: true },
      validate: { rules: '', messages: {} },
    },
  },
};

entityMetaStatic['CommsCenter:CallReview'] = {
  name: 'CommsCenter:CallReview',
  data: {
    ...entityMetaStatic['CommsCenter:ChatReview'].data,
    connectionQualityStars: {
      name: 'connectionQualityStars',
      type: 'integer',
      acl: { read: true, write: true },
      validate: {
        rules: 'required',
        messages: { required: 'stars_rating_required' },
      },
    },
  },
};

export { entityMetaStatic };

export const entityMetaMapping = {
  client: 'Client:Client',
  clientParent: 'Client:Client',
  user: 'Client:Client',
  note: 'Client:Note',
  message: 'Messaging:Message',
  textMessage: 'Messaging:TextMessage',
  template: 'Mail:Template',
  password: 'Pass:Pass',
  questionnaire: 'Questionnaire:Questionnaire',
  filledQuestionnaire: 'Questionnaire:FilledQuestionnaire',
  doc: 'Docs:Doc',
  docsList: 'DocsList',
  docType: 'Docs:DocType',
  team: 'Client:Team',
  brand: 'Items:Brand',
  category: 'Items:Category',
  attribute: 'Items:Attribute',
  attributeGroup: 'Items:AttributeGroup',
  item: 'Items:Item',
  itemVariant: 'Items:Variant',
  cmsDescription: 'Translate:EmbeddedCmsDescription',
  clientLocation: 'Client:EmbeddedClientLocation',
  clientLocations: 'LocationList',
  pipeline: 'Leads:Pipeline',
  lead: 'Leads:Lead',
  leadCatcher: 'Leads:LeadCatcher',
  leadTagger: 'Leads:LeadTagger',
  page: 'Cms:Page',
  cmsTemplate: 'Cms:PageTemplate',
  model: 'Items:Model',
  order: 'Order:Order',
  vendorOrder: 'VendorOrder:VendorOrder',
  dataPush: 'DataPush:PushConfig',
  whiteLabel: 'WhiteLabel:WhiteLabelSettings',
  whiteLabelPlugin: 'WhiteLabel:WhiteLabelPlugin',
  checkoutUserData: 'Checkout:UserData',
  checkoutUserDataWithRegistration: 'Checkout:UserDataWithRegistration',
  checkoutCompanyData: 'Checkout:CompanyData',
  checkoutCompanyDataWithRegistration: 'Checkout:CompanyDataWithRegistration',
  cmsLeadForm: 'Cms:LeadForm',
  cmsNewsletterForm: 'Cms:NewsletterForm',
  cmsLoginForm: 'Cms:LoginForm',
  cmsSignupForm: 'Cms:SignupForm',
  cmsContactForm: 'Cms:ContactForm',
  cmsElement: 'Cms:DynamicElement',
  cmsFile: 'Main:S3File',
  checkoutPaymentCashAdditionalInfos: 'Checkout:PaymentCashAdditionalInfos',
  dynamicDataDefinition: 'DynamicData:Definition',
  orderActionPaymentInstructions: 'OrderAction:PaymentInstructions',
  orderActionPickupInvite: 'Order:EmbeddedPickupData',
  socialMediaLoginSecret: 'WhiteLabel:SocialMediaLoginSecret',
  externalAccount: 'Client:ExternalAccount',
  modelGroup: 'Items:ModelGroup',
  modelAsset: 'Items:ModelAsset',
  leadPerMailRule: 'WhiteLabel:LeadPerMailRule',
  pluginSettingsCommerceAutomotiveCarRental: 'PluginSettings:CommerceAutomotiveCarRental',
  pluginSettingsConnectme: 'PluginSettings:Connectme',
  chedriCompanySignupForm: 'Chedri:CompanySignupForm',
  financialBankData: 'Financial:BankData',
  priceNegotiation: 'PriceNegotiation:PriceNegotiation',
  clientCar: 'Items:ClientCar',
  carService: 'CarService:Service',
  carServiceStation: 'CarService:ServiceStation',
  docTemplate: 'Docs:DocTemplate',
  quote: 'Finance:Quote',
  invoice: 'Finance:Invoice',
  autoresponse: 'Leads:Autoresponse',
  anonymousUserInfo: 'CommsCenter:AnonymousUserInfo',
  chatReview: 'CommsCenter:ChatReview',
  callReview: 'CommsCenter:CallReview',
  mainMenuVersion: 'WhiteLabel:MainMenuVersion',
  leadAssignmentStrategy: 'Leads:LeadAssignmentStrategy',
  leadEscalationRule: 'Leads:EscalationRule',
  productTemplate: 'Template:Template',
  edition: 'Edition:Edition',
  campaign: 'Edition:Campaign',
  budget: 'Order:BudgetDefinition',
  coupon: 'Order:OrderCouponDefinition',
  allowedDomain: 'Main:AllowedDomain',
};

export const mapOptions = {
  panControl: false,
  mapTypeControl: false,
  scrollwheel: false,
  /* styles: [
    {
      "featureType": "all",
      "elementType": "labels.text.fill",
      "stylers": [
        { "color": "#ffffff" },
        { "weight": .20 },
        { "lightness": 28 },
        { "saturation": 23 },
        { "visibility": "on" }
      ]
    },
    {
      "featureType": "all",
      "elementType": "labels.text.stroke",
      "stylers": [
        { "color": "#494949" },
        { "lightness": 13 },
        { "visibility": "off" }
      ]
    },
    {
      "featureType": "all",
      "elementType": "labels.icon",
      "stylers": [
        { "visibility": "off" }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.fill",
      "stylers": [
        { "color": "#000000" }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [
        { "color": "#144b53" },
        { "lightness": 14 },
        { "weight": 1.4 }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [
        { "color": "#08304b" }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        { "color": "#0c4152" },
        { "lightness": 5 }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
        { "color": "#000000" }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        { "color": "#0b434f" },
        { "lightness": 25 }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry.fill",
      "stylers": [
        { "color": "#000000" }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry.stroke",
      "stylers": [
        { "color": "#0b3d51" },
        { "lightness": 16 }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [
        { "color": "#000000" }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [
        { "color": "#146474" }
      ]
    },
    {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
        { "color": "#021019" }
      ]
    }
  ] */
};

// few outdated constants still used somewhere in the code
export const CHEDRI_CMS = {
  chedriCancellationPolicy: { de: '', en: '', pl: '', tr: '' },
  chedriPrivacyPolicy: { de: '', en: '', pl: '', tr: '' },
  carServicesTerms: { de: '', en: '', pl: '', tr: '' },
};

export const CUSTOMER_RETENTION_PC_PLUGIN = 'CUSTOMER_RETENTION_PC';
export const SALES_ACTIVITY_DASHBOARD_PLUGIN = 'SALES_ACTIVITY_DASHBOARD_PLUGIN';

export const S3_DOMAIN = 'https://s3.eu-central-1.amazonaws.com';
export const S3_BUCKETS = {
  MEDIA_IMGS: PROJECT_NAME + '.mediaimgs',
  CLIENTS: PROJECT_NAME + '.clients',
  TEMPLATES: PROJECT_NAME + '.templates',
  ITEM_IMGS: PROJECT_NAME + '.itemimgs',
  PDF_IMAGE: PROJECT_NAME + '.pdf.image',
  CMS_IMAGE: PROJECT_NAME + '.cmsimgs',
};

export const S3_LINKS = {
  MEDIA_IMGS_THUMB: S3_DOMAIN + '/' + S3_BUCKETS.MEDIA_IMGS + '.thumb/',
  MEDIA_IMGS_ORIGINAL: S3_DOMAIN + '/' + S3_BUCKETS.MEDIA_IMGS + '.original/',
  CLIENTS: S3_DOMAIN + '/' + S3_BUCKETS.CLIENTS + '/',
  TEMPLATES: S3_DOMAIN + '/' + S3_BUCKETS.TEMPLATES + '/',
  ITEM_IMGS_THUMB: S3_DOMAIN + '/' + S3_BUCKETS.ITEM_IMGS + '.thumb/',
  PDF_IMAGE: S3_DOMAIN + '/' + S3_BUCKETS.PDF_IMAGE + '/',
  CMS_IMAGE_THUMB: S3_DOMAIN + '/' + S3_BUCKETS.CMS_IMAGE + '/',
};

export const S3_LINKS_DIV = {
  ITEM_IMGS_THUMB_DEFAULT: S3_LINKS.ITEM_IMGS_THUMB + projectConfig.DEFAULT_ITEM_IMAGE,
  PDF_IMAGE_DEFAULT: S3_LINKS.PDF_IMAGE + projectConfig.DEFAULT_PDF_IMAGE,
  MEDIA_IMAGE_THUMB_DEFAULT: S3_LINKS.MEDIA_IMGS_THUMB + projectConfig.DEFAULT_MEDIA_IMAGE,
  TEMPLATE_INITIAL_PREVIEW: S3_LINKS.TEMPLATES + projectConfig.INITIAL_PREVIEW,
  CMS_IMAGE_THUMB_DEFAULT: S3_LINKS.CMS_IMAGE_THUMB + projectConfig.DEFAULT_CMS_IMAGE,
};

export const FILTERS = {
  SAVE_TO_FOR_ROOT_EMPLOYEES: '?filters%5Bowner%5D=CreacheckAdmin',
};
