import React from 'react';
import GoogleMap from 'google-map-react';
import scriptLoader from 'react-async-script-loader';
import Loader from 'components/layout/Loader';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

import * as constants from 'js/constants';

class CmsElementMap extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  render() {
    const { appIntl, isScriptLoaded } = this.props;
    const { shown } = this.state;

    if (!isScriptLoaded) {
      return <Loader />;
    }

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';

    const position = {
      lat: parseFloat(this.getParamValue('mapLat', 49.4456943)),
      lng: parseFloat(this.getParamValue('mapLng', 7.7704389)),
    };

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-map ' + shownClass + this.getResponsiveClasses() + this.getCssClasses()}
        style={{ ...this.getBackgroundStyle(), height: this.getParamValue('mapHeight', 300) + 'px' }}
      >
        {this.getElementStyle()}

        <GoogleMap
          bootstrapURLKeys={{
            key: constants.GOOGLE_MAPS_KEY,
            language: appIntl.locale,
          }}
          defaultCenter={position}
          defaultZoom={this.getParamValue('mapZoom', 11)}
          options={constants.mapOptions}
        />

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementMap.defaultProps = {
  index: '0',
};

export default scriptLoader(constants.GOOGLE_MAPS_URL)(CmsElementMap);
