import { findIndex as _findIndex, indexOf as _indexOf } from 'lodash';

import { getThumbFile } from 'helpers/items';
import { getColorForString } from 'helpers/charts';
import { getTextColor } from 'components/forms/autocomplete/Color';

export const userDataForCheckout = user => {
  return !user || !user.profile
    ? {
        salutation: 'mr',
        gender: 'male',
        company: '',
        firstName: '',
        lastName: '',
        street: '',
        houseNumber: '',
        apartmentNumber: '',
        addressLine2: '',
        zipCode: '',
        city: '',
        mobile: '',
        country: 'DE',
      }
    : {
        salutation: user.profile.salutation,
        gender: user.profile.gender,
        company: user.profile.company,
        firstName: user.profile.first_name,
        lastName: user.profile.last_name,
        street: user.profile.street,
        houseNumber: user.profile.house_number,
        apartmentNumber: user.profile.apartment_number,
        addressLine2: user.profile.address_line2,
        zipCode: user.profile.zip_code,
        city: user.profile.city,
        mobile: user.profile.mobile,
        country: user.profile.country,
      };
};

export const getGenderOptions = messages => {
  return [
    { value: 'male', label: messages.genderOptions.male },
    { value: 'female', label: messages.genderOptions.female },
  ];
};

export const getSalutationOptions = messages => {
  return [
    { value: 'mr', label: messages.salutationOptions.mr },
    { value: 'mrs', label: messages.salutationOptions.mrs },
  ];
};

export const getSalutationForGender = gender => {
  switch (gender) {
    case 'male':
      return 'mr';

    case 'female':
      return 'mrs';
  }
};

export const getGenderForSalutation = salutation => {
  switch (salutation) {
    case 'mr':
      return 'male';

    case 'mrs':
      return 'female';
  }
};

export const getUserAvatarInfos = user => {
  let avatarThumbLink = null;
  if (user.profile.avatar) {
    const imageThumb = getThumbFile(user.profile.avatar);
    avatarThumbLink = imageThumb ? imageThumb.web_link : null;
  }

  const avatarColor = '#' + getColorForString(user.profile.first_name + user.profile.last_name);
  const avatarTextColor = getTextColor(avatarColor);

  return {
    avatarThumbLink,
    avatarColor,
    avatarTextColor,
  };
};

export const getChatterUserAvatarInfos = chatterUser => {
  const avatarColor = '#' + getColorForString(chatterUser ? chatterUser.name : 'anon.');
  const avatarTextColor = getTextColor(avatarColor);

  return {
    avatarThumbLink: chatterUser ? chatterUser.avatar : null,
    avatarColor,
    avatarTextColor,
  };
};

export const getMainRoleGroups = messages => {
  return [
    { value: 'ROLE_EMPLOYEE', roles: ['ROLE_EMPLOYEE', 'ROLE_USER'], label: messages.employee },
    { value: 'ROLE_COMPANY', roles: ['ROLE_COMPANY', 'ROLE_USER'], label: messages.company },
    { value: 'ROLE_CUSTOMER', roles: ['ROLE_CUSTOMER', 'ROLE_USER'], label: messages.client },
    { value: 'ROLE_AGENCY', roles: ['ROLE_COMPANY', 'ROLE_AGENCY', 'ROLE_USER'], label: messages.marketing_agency },
  ];
};

export const getCalculatedHoldingCompanyIndex = (holdingInfos, companyId) => {
  const { is_company: isCompany, ancestors, parent, siblings, descendants } = holdingInfos;

  const isSibling = _findIndex(siblings, { id: companyId }) >= 0;
  const isDescendant = _findIndex(descendants, { id: companyId }) >= 0;

  // check the position of the company and the parent in the ancestors list
  let companyIndex = _findIndex(ancestors, { id: companyId });
  let parentIndex = _findIndex(ancestors, { id: parent.id });
  if (isCompany) {
    parentIndex++;
  }
  if (isSibling) {
    companyIndex = parentIndex;
  }
  if (isDescendant) {
    companyIndex = parentIndex + 1;
  }

  return { companyIndex, parentIndex };
};

export const getCalculatedHoldingCompanyInfos = (holdingInfos, company) => {
  const {
    username,
    is_company: isCompany,
    parent,
    siblings,
    descendants,
    current_granted_holding_access: grantedHoldingAccess,
    current_granted_holding_access_depth: grantedHoldingAccessDepth,
    max_granted_holding_access: maxGrantedHoldingAccess,
    max_granted_holding_access_depth: maxGrantedHoldingAccessDepth,
    whitelist,
    blacklist,
  } = holdingInfos;

  const isSibling = _findIndex(siblings, { id: company.id }) >= 0;
  const isDescendant = _findIndex(descendants, { id: company.id }) >= 0;
  const isWhitelisted = _indexOf(whitelist, company.id) >= 0;
  const isBlacklisted = _indexOf(blacklist, company.id) >= 0;

  // check the position of the company and the parent in the ancestors list
  const { companyIndex, parentIndex } = getCalculatedHoldingCompanyIndex(holdingInfos, company.id);

  const isDirectParent = company.id === parent.id;
  let mayReadData = isDirectParent;
  if (companyIndex <= parentIndex && grantedHoldingAccess < 0 && parentIndex + grantedHoldingAccess <= companyIndex) {
    mayReadData = true;
  }
  if (companyIndex > parentIndex && parentIndex + grantedHoldingAccessDepth >= companyIndex) {
    mayReadData = true;
  }
  if (isWhitelisted) {
    mayReadData = true;
  }
  if (isBlacklisted) {
    mayReadData = false;
  }

  let mayGrantAccess = false;
  if (companyIndex <= parentIndex && parentIndex >= companyIndex + maxGrantedHoldingAccess) {
    mayGrantAccess = true;
  }
  if (companyIndex >= parentIndex && companyIndex <= parentIndex + maxGrantedHoldingAccessDepth) {
    mayGrantAccess = true;
  }

  const isCurrentUser = company.id === username;

  return {
    username,
    isCompany,
    companyIndex,
    parentIndex,
    isSibling,
    isDescendant,
    isWhitelisted,
    isBlacklisted,
    isDirectParent,
    isCurrentUser,
    mayReadData,
    mayGrantAccess,
  };
};

export const getAddressTypeOptions = (messages) => [
  { value: 'mail', label: messages.address_mail },
  { value: 'delivery', label: messages.address_delivery },
  { value: 'invoice', label: messages.address_invoice },
];

export const getPhoneTypeOptions = (messages) => [
  { value: 'mobile', label: messages.mobile },
  { value: 'headquarter', label: messages.headquarter },
  { value: 'phone', label: messages.phone },
  { value: 'extension-number', label: messages['extension-number'] },
];

export const PHONE_TYPE_DEFAULT = 'mobile';

export const COUNTRY_DEFAULT = 'DE';

// Possible values for the invoicePaymentOption property
export const INVOICE_PAYMENT_OPTION_ENABLED = 'ENABLED';
export const INVOICE_PAYMENT_OPTION_DISABLED = 'DISABLED';
export const INVOICE_PAYMENT_OPTION_INHERITED = 'INHERITED';

const getInvoicePaymentOptionTranslations = messages =>
  messages?.invoice_payment_option || {
    enabled: INVOICE_PAYMENT_OPTION_ENABLED,
    disabled: INVOICE_PAYMENT_OPTION_DISABLED,
    inherited: INVOICE_PAYMENT_OPTION_INHERITED,
  };

export const getInvoicePaymentOptions = messages => {
  const { enabled, disabled, inherited } = getInvoicePaymentOptionTranslations(messages);

  return [
    { label: enabled, value: INVOICE_PAYMENT_OPTION_ENABLED },
    { label: disabled, value: INVOICE_PAYMENT_OPTION_DISABLED },
    { label: inherited, value: INVOICE_PAYMENT_OPTION_INHERITED },
  ];
};

export const getInvoicePaymentOptionName = (messages, setting) => {
  const { enabled, disabled, inherited } = getInvoicePaymentOptionTranslations(messages);
  const dictionary = {
    [INVOICE_PAYMENT_OPTION_ENABLED]: enabled,
    [INVOICE_PAYMENT_OPTION_DISABLED]: disabled,
    [INVOICE_PAYMENT_OPTION_INHERITED]: inherited,
  };
  return dictionary[setting] || '';
};

// Possible values for the generalized inheritable option
export const OPTION_ENABLED = 'ENABLED';
export const OPTION_DISABLED = 'DISABLED';
export const OPTION_INHERITED = 'INHERITED';
