import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';

import { getTimezoneFromState } from 'helpers/intl';

import IntlTime from 'components/layout/IntlTime';

class WorkingHours extends React.Component {
  constructor(props) {
    super(props);

    moment.locale(props.intl.locale);
  }

  getTimezone = () => {
    const { defaultTimezone, timezone } = this.props;
    return timezone || defaultTimezone;
  };

  // values come from database in a float format. we need to format the time in HH:mm
  formatValue = value => {
    const val = value.toString().split('.');
    let hours = parseInt(val[0], 10);
    let minutes = Math.round((value - val[0]) * 60);
    if (hours < 10) {
      hours = '0' + hours.toString();
    }
    if (minutes < 10) {
      minutes = '0' + minutes.toString();
    }
    return hours + ':' + minutes;
  };

  printTimes = timesList => {
    if (timesList.length === 0) {
      return <span key="none">-</span>;
    }

    const { data } = this.props;
    const datePrefix = moment(data.reference_date || undefined).format('YYYY-MM-DD');

    const res = [];
    for (let i = 0; i < timesList.length; i++) {
      res.push(
        <span class="m-r-10" key={i}>
          <IntlTime
            date={datePrefix + ' ' + this.formatValue(timesList[i].start) + ':00'}
            timezone={this.getTimezone()}
          />
          {' - '}
          <IntlTime
            date={datePrefix + ' ' + this.formatValue(timesList[i].end) + ':00'}
            timezone={this.getTimezone()}
          />
        </span>
      );
    }

    return res;
  };

  render() {
    const {
      intl: { messages },
      data,
    } = this.props;

    const localeData = moment.localeData();

    // try to group opening hours
    const groups = [];
    let currentGroup = null;

    // based on dow, see where we start with the iso day
    // iso day starts with 1 (monday) and applies if moment data (dow) equals 1
    //   for dow equal 0 the week starts with sunday, that is iso day 7
    let init = 0;
    if (localeData._week.dow === 0) {
      init = 6;
    } else if (localeData._week.dow === 6) {
      init = 5;
    }

    for (let i = 0; i < 7; i++) {
      const isoDay = (init + i) % 7;

      let times = '';
      for (let j = 0; j < data['iso_day' + (isoDay + 1)].length; j++) {
        times += data['iso_day' + (isoDay + 1)][j].start + '-' + data['iso_day' + (isoDay + 1)][j].end + ', ';
      }

      if (i === 0 || currentGroup.times !== times) {
        if (currentGroup) {
          groups.push(currentGroup);
        }

        // init the currentGroup
        currentGroup = {
          startLabel: localeData._weekdaysMin[(isoDay + 1) % 7],
          endLabel: localeData._weekdaysMin[(isoDay + 1) % 7],
          times,
          timesList: data['iso_day' + (isoDay + 1)],
        };
      } else if (currentGroup.times === times) {
        currentGroup.endLabel = localeData._weekdaysMin[isoDay + 1];
      }
    }

    if (!groups.length || currentGroup.times !== groups[groups.length - 1].times) {
      groups.push(currentGroup);
    }

    return (
      <div>
        {groups.map((group, i) => {
          return group.timesList && group.timesList.length ? (
            <span class="block" key={i}>
              <strong>
                {group.startLabel === group.endLabel ? group.startLabel : group.startLabel + ' - ' + group.endLabel}
              </strong>
              : {this.printTimes(group.timesList)}
            </span>
          ) : null;
        })}

        <div class="fs-8 hint-text m-t-5">
          * {messages.timezone}: {this.getTimezone()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    defaultTimezone: getTimezoneFromState(state),
  };
};

export default injectIntl(connect(mapStateToProps)(WorkingHours));
