import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import CompanySignupWizardSubscriptionPlan from 'components/forms/auth/CompanySignupWizardSubscriptionPlan';

import EntityPropsUpdater from 'component/EntityPropsUpdater';

class CompanySignupStepSubscriptionPlan extends EntityPropsUpdater {
  getUrlDataSubscriptionPlan = () => {
    const res = {};

    res.filters = { type: 'subscription_plan' };
    res.sort = 'sortingPrice';
    res.dir = 'asc';

    return res;
  };

  getUrlDataMainPlugin = () => {
    const res = {};

    res.filters = { type: 'main_plugin' };
    res.sort = 'isFeatured';
    res.dir = 'desc';

    return res;
  };

  render() {
    const {
      intl: { messages },
      listSubscriptionPlan,
      formStatus,
      entity,
      changeStep,
    } = this.props;

    return (
      <div>
        <h1>{messages.company_singup_wizard_free_trial_hint}</h1>
        <h2>{messages.company_singup_wizard_step_subscription_plan_headline}</h2>
        <CompanySignupWizardSubscriptionPlan
          listName="whiteLabelSubscriptionPlan"
          url="/api/whitelabelplugins.json"
          urlData={this.getUrlDataSubscriptionPlan()}
          list={listSubscriptionPlan}
          name="plugins"
          selection={entity.getPlugins()}
          meta={entity.getPluginsMetaInformation()}
          validation={entity.getPluginsValidationResult()}
          onChange={this.updateEntity}
          disabled={formStatus.pending}
          changeStep={changeStep}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    listSubscriptionPlan: state.list.whiteLabelSubscriptionPlan,
    listMainPlugin: state.list.whiteLabelMainPlugin,
    formStatus: state.formStatus.companySignup,
  };
};

export default injectIntl(connect(mapStateToProps)(CompanySignupStepSubscriptionPlan));
