// Check php://OrderBundle/Model/CouponCalculator.php for updates

import { round } from './financial'

export function getCouponDiscountFromPrice(coupon, price, useConstraint) {
  let value = 0;
  const minValue = round(coupon.min_order_value, 2);
  if (!useConstraint || minValue <= price) {
    if (coupon.type === 'monetary') {
      const discount = round(parseFloat(coupon.value_discount), 2);
      value = Math.min(discount, price);
    } else if (coupon.type === 'percentage') {
      const percentage = round(parseFloat(coupon.percent_discount), 2);
      value = round(
        parseFloat(price) * round((percentage / 100), 2)
        , 2
      );
    }
    const maxValue = Math.round(coupon.max_order_discount);
    if (maxValue > 0 && maxValue < value ) {
      value = round(parseFloat(coupon.max_order_discount), 2);
    }
  }
  return value;
}

export function getValueFromOrder(coupon, order, useConstraint) {
  const price = order.items.reduce(
    (subtotal, item) => subtotal + (item.item && item.code == null ? item.net_price : 0),
    0
  );
  return getCouponDiscountFromPrice(coupon, price, useConstraint);
}

export function getValueFromCartItem(coupon, cartItem, useConstraint) {
  if ('unset' !== coupon.print_type) {
    if (cartItem.print_type !== coupon.print_type) {
      return 0;
    }
  }

  let price;
  if (cartItem.print_type === 'pdf_only') {
    price = Math.round(cartItem.print_pdf_price);
  } else if (cartItem.item) {
    price = Math.round(
      ((cartItem.variant != null) ? cartItem.variant : cartItem.item).pricing[
        (cartItem.shipping_type !== 'standard')
          ? `${cartItem.shipping_type}_price`
          : 'price'
        ]
      );
  }
  return getCouponDiscountFromPrice(coupon, price, useConstraint);
}
