import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Row, Col, Alert } from 'react-bootstrap';

import { parseQs } from 'helpers/http';

// there was a checkout error / user may not complete the checkout because of an error
class CheckoutError extends React.Component {
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    this.state = {
      query: parseQs(props.location.search),
    };
  }

  componentDidMount = () => {
    if (!this.state.query.checkout_error) {
      this.props.history.push(this.props.appIntl.urlPrefix);
    }
  };

  render() {
    const { checkout } = this.props;
    const { query } = this.state;

    return (
      <div class="content">
        <Grid>
          <Row>
            <Col xs={12}>
              <h1 class="text-danger">Checkout error</h1>
              <h5 class="text-danger">We are not able to process your order</h5>

              {query.checkout_error === 'no_payment_module_found' ? (
                <div>
                  <Alert bsStyle="danger">One of users did not provide payment information to our shop</Alert>
                </div>
              ) : null}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
CheckoutError.defaultProps = {};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    checkout: state.checkout,
  };
};

export default withRouter(connect(mapStateToProps)(CheckoutError));
