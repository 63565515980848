import _ from 'lodash';

const initialState = {
  tasksData: [],
  leadsData: [],
  pending: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'TASKS_C_PENDING': {
      return { ...state, pending: true};
    }
    case 'TASKS_C_FULFILLED': {
      return { ...state, tasksData: payload, pending: false,  };
    }
    case 'LEADS_C_PENDING': {
      return { ...state, pending: true};
    }
    case 'LEADS_C_FULFILLED': {
      return { ...state, leadsData: payload, pending: false,  };
    }

  }
  return state;
};
