import React from 'react';
import { useDrop } from 'react-dnd';

const DropFolderBack = ({ accept, onDrop, choiceFolder, id, name }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = isOver && canDrop;
  let backgroundColor = '#626262';
  if (isActive) {
    backgroundColor = '#B2CE4C';
  } else if (canDrop) {
    backgroundColor = '#F626262';
  }

  return (
    <div
      ref={drop}
      md={2}
      style={{ cursor: 'pointer' }}
      className="cc-media-folder cc-media-folder-back"
      onClick={() => choiceFolder(id)}
    >
      <div className="card backTo" style={{ backgroundColor }}>
        <span style={{ color: '' }}>
          <i className="far fa-arrow-alt-circle-left sizeFolderIcon" />
        </span>
        <span> {name}</span>
      </div>
    </div>
  );
};

export default DropFolderBack;
