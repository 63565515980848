import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Grid, Row, Col, Clearfix } from 'react-bootstrap';

import { saveCookie } from 'actions/CookiesActions';
import { arePerformanceCookiesUsed, areTargetingCookiesUsed } from 'helpers/whiteLabel';

import FormSwitchery from 'components/forms/FormSwitchery';

import NecessaryCookiesList from 'components/layout/cookie/NecessaryCookiesList';
import PerformanceCookiesList from 'components/layout/cookie/PerformanceCookiesList';
import TargetingCookiesList from 'components/layout/cookie/TargetingCookiesList';

class CookieOptOut extends React.Component {
  updatePreferences = (cookieName, isChecked) => {
    this.props.saveCookie({ [cookieName]: isChecked ? '1' : '0' });
  };

  render() {
    const {
      intl: { messages },
      cookies,
      projectConfig,
    } = this.props;

    const isPerformanceVisible = arePerformanceCookiesUsed(projectConfig.data);
    const isTargetingVisible = areTargetingCookiesUsed(projectConfig.data);

    return (
      <div className="p-t-70 p-b-70">
        <Grid>
          <Row>
            <Col xs={12}>
              <h2 className="bold">{messages.privacy_preference_center}</h2>
              <div>
                <h2>{messages.necessary_cookies}</h2>
                <div className="text-right text-success bold">{messages.always_active}</div>
                <p>{messages.necessary_cookies_information}</p>
                <div>
                  <b>{messages.cookies_used}:</b>
                </div>

                <NecessaryCookiesList />
              </div>

              {isPerformanceVisible && (
                <div>
                  <h2>{messages.performance_cookies}</h2>
                  <Clearfix>
                    <div className="pull-right">
                      {cookies.pending ? (
                        <i className="fa fa-circle-notch fa-spin" />
                      ) : (
                        <FormSwitchery
                          name="chedriCookiesPerformance"
                          checked={cookies.performance}
                          value={true}
                          onChange={this.updatePreferences}
                        />
                      )}
                    </div>
                  </Clearfix>

                  <PerformanceCookiesList />
                </div>
              )}

              {isTargetingVisible && (
                <div>
                  <h2>{messages.targeting_cookies}</h2>
                  <Clearfix>
                    <div className="pull-right">
                      {cookies.pending ? (
                        <i className="fa fa-circle-notch fa-spin" />
                      ) : (
                        <FormSwitchery
                          name="chedriCookiesTargeting"
                          checked={cookies.performance}
                          value={true}
                          onChange={this.updatePreferences}
                        />
                      )}
                    </div>
                  </Clearfix>

                  <TargetingCookiesList />
                </div>
              )}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    cookies: state.cookies,
    projectConfig: state.data.projectConfig,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    saveCookie: cookies => {
      dispatch(saveCookie(cookies));
    },
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CookieOptOut)
);
