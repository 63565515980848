import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { forEach as _forEach, find as _find, findIndex as _findIndex } from 'lodash';

import { getNameInCurrentLanguage } from 'helpers/intl';

import IntlDate from 'components/layout/IntlDate';

class ItemVariantSelection extends React.Component {
  selectVariantAttribute = (e, attributeId, valueId) => {
    e.preventDefault();

    const { selectVariantAttribute } = this.props;
    selectVariantAttribute(attributeId, valueId);
  };

  listValues = attribute => {
    const { appIntl, variants, selectedVariantAttributes } = this.props;

    let values = [];

    _forEach(variants, variant => {
      const variantAttribute = _find(variant.attributes, ['id', attribute.id]);

      // check if this variant is possible for selection
      let attributeValuesFound = 0;
      _forEach(selectedVariantAttributes, selectedVariantAttribute => {
        const searchedVariantAttribute = _find(variant.attributes, ['id', selectedVariantAttribute.attributeId]);

        if (
          searchedVariantAttribute &&
          _find(searchedVariantAttribute.values, ['id', selectedVariantAttribute.valueId])
        ) {
          attributeValuesFound++;
        }
      });

      const variantAvailableForSelection = attributeValuesFound === selectedVariantAttributes.length;

      if (variantAttribute) {
        _forEach(variantAttribute.values, variantAttributeValue => {
          const alreadyPresent = _findIndex(values, ['id', variantAttributeValue.id]);

          if (alreadyPresent === -1) {
            values.push({ ...variantAttributeValue, active: variantAvailableForSelection });
          } else if (!values[alreadyPresent].active && variantAvailableForSelection) {
            values[alreadyPresent].active = true;
          }
        });
      }
    });

    return values.map((value, i) => {
      const {
        intl: { messages },
      } = this.props;
      const isSelected = _findIndex(selectedVariantAttributes, { attributeId: attribute.id, valueId: value.id });

      let text = getNameInCurrentLanguage(value.value, appIntl);

      switch (value.value[0].type) {
        case 'bool':
          if (text === 'yes') {
            text = messages.yes;
          } else {
            text = messages.no;
          }
          break;
        case 'date':
          text = <IntlDate date={getNameInCurrentLanguage(value.value, appIntl)} />;
          break;
      }

      return (
        <li class="pull-left m-r-10 m-b-10 fs-10 no-padding" key={i}>
          {value.active ? (
            <a
              href="#select"
              onClick={e => this.selectVariantAttribute(e, attribute.id, value.id)}
              class={
                'p-l-10 p-r-10 p-t-5 p-b-5 bg-master-lighter b-a ' + (isSelected > -1 ? 'b-transparent ' : 'b-hide ')
              }
            >
              {text}
            </a>
          ) : (
            <span
              class="p-l-10 p-r-10 p-t-5 p-b-5 bg-master-lightest b-a b-transparent hint-text"
              onClick={e => this.selectVariantAttribute(e, attribute.id, value.id)}
            >
              {text}
            </span>
          )}
        </li>
      );
    });
  };

  render() {
    const { appIntl, item } = this.props;

    return (
      <div>
        {item.variants.attributes.map((attribute, i) => {
          return (
            <div class="m-b-10" key={i}>
              <div>
                <b>{getNameInCurrentLanguage(attribute.name, appIntl)}</b>
              </div>
              <div class="clearfix">
                <ul class="list-unstyled item-variant-list">{this.listValues(attribute)}</ul>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default injectIntl(connect(mapStateToProps)(ItemVariantSelection));
