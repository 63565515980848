import React from 'react';
import { Popover, Button } from 'react-bootstrap';
import { DragSource, DropTarget } from 'react-dnd';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';
import CmsNewElement from 'components/cms/CmsNewElement';
import SimplePrompt from 'components/layout/SimplePrompt';

import OverlayTrigger from 'components/OverlayTrigger';

const colTarget = {
  hover(props, monitor, component) {
    // accept elements only if column is empty
    if (props.children === null) {
      const dragIndex = monitor.getItem().index.toString();
      const hoverIndex = props.index.toString();

      props.reorderElements(dragIndex, hoverIndex + '.0', 'before', false);
      monitor.getItem().index = hoverIndex + '.0';
    }
  },
};

@DropTarget('element', colTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
export default class CmsElementCol extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      showNew: false,
      mouseOver: false,
      shown: false,
      colMenuMarginLeft: 0,
    };

    this.mouseMoveBlock = false;
    this.containerRef = React.createRef();
    this.overlayRef = React.createRef();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { mouseOver } = this.state;

    if (mouseOver && !prevState.mouseOver) {
      this.calculateColMenuMarginLeft();
    }
    if (!mouseOver && prevState.mouseOver) {
      this.setState({ colMenuMarginLeft: 0 });
    }

    this.__componentDidUpdate(prevProps, prevState);
  };

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  mouseEnter = () => {
    this.setState({ mouseOver: true });
  };

  mouseLeave = () => {
    this.setState({ mouseOver: false });
  };

  mouseMove = () => {
    if (!this.state.mouseOver && !this.mouseMoveBlock) {
      this.setState({ mouseOver: true }, () => (this.mouseMoveBlock = false));
    }
  };

  showNew = () => {
    this.setState({ showNew: true });
  };

  closeNew = () => {
    this.setState({ showNew: false });
  };

  getResponsiveDef = () => {
    const xs = parseInt(this.getParamValue('xs', 12), 10);
    const xsOffset = parseInt(this.getParamValue('xsOffset', 0), 10);
    const sm = parseInt(this.getParamValue('sm', xs), 10);
    const smOffset = parseInt(this.getParamValue('smOffset', 0), 10);
    const md = parseInt(this.getParamValue('md', sm), 10);
    const mdOffset = parseInt(this.getParamValue('mdOffset', 0), 10);
    const lg = parseInt(this.getParamValue('lg', md), 10);
    const lgOffset = parseInt(this.getParamValue('lgOffset', 0), 10);

    return (
      ' col-xs-' +
      xs +
      ' col-sm-' +
      sm +
      ' col-md-' +
      md +
      ' col-lg-' +
      lg +
      ' col-xs-offset-' +
      xsOffset +
      ' col-sm-offset-' +
      smOffset +
      ' col-md-offset-' +
      mdOffset +
      ' col-lg-offset-' +
      lgOffset
    );
  };

  addElement = element => {
    this.props.addElement(this.props.index, element);
  };

  showSettings = e => {
    const { index, showElementSettings } = this.props;
    showElementSettings(e, index);
  };

  removeElement = () => {
    const { index, removeElement } = this.props;
    removeElement(index);
  };

  noChildrenClick = () => {
    this.overlayRef.current.triggerVisible();
  };

  calculateColMenuMarginLeft = () => {
    const { editMode, scale } = this.props;
    const { mouseOver } = this.state;

    // get the information about the grid mouse over
    let colMenuMarginLeft = 0;
    if (editMode && mouseOver && this.containerRef && this.containerRef.current) {
      const colMenu = this.containerRef.current.querySelector('.ms-col-menu');

      if (colMenu) {
        const colMenuRect = colMenu.getBoundingClientRect();

        let rowContainer = this.containerRef.current;
        do {
          rowContainer = rowContainer.parentElement;
          if (rowContainer.classList.contains('ms-row')) {
            break;
          }
        } while (rowContainer.parentElement !== null);

        if (rowContainer) {
          const rowMenu = rowContainer.querySelector('.ms-row-menu');

          if (rowMenu) {
            const rowMenuRect = rowMenu.getBoundingClientRect();

            if (
              rowMenuRect.left + rowMenuRect.width > colMenuRect.left &&
              rowMenuRect.top + rowMenuRect.height > colMenuRect.top
            ) {
              colMenuMarginLeft = rowMenuRect.width - (colMenuRect.left - rowMenuRect.left) + 10;
            }
          }
        }
      }
    }

    colMenuMarginLeft *= 1 / scale;
    this.setState({ colMenuMarginLeft });
  };

  render() {
    const {
      intl: { messages },
      editMode,
      canvasContainerRef,
      connectDropTarget,
      scale,
    } = this.props;
    const { mouseOver, showNew, shown, colMenuMarginLeft } = this.state;

    const mainId = this.getMainId();
    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';

    const parallaxData = this.getParallaxData();

    const innerContent = connectDropTarget(
      <div
        ref={this.containerRef}
        className={
          'col ms-col ' + shownClass + this.getResponsiveDef() + this.getResponsiveClasses() + this.getCssClasses()
        }
        style={{
          ...this.getBackgroundStyle(),
        }}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
        onDragLeave={this.mouseLeave}
        onMouseMove={this.mouseMove}
      >
        {this.getElementStyle()}

        {/* show outline of the column */}
        {(mouseOver || showNew) && editMode && (
          <>
            <span className="ms-col-outline ms-col-outline-top" />
            <span className="ms-col-outline ms-col-outline-right" />
            <span className="ms-col-outline ms-col-outline-bottom" />
            <span className="ms-col-outline ms-col-outline-left" />
            <div
              className="ms-col-menu"
              style={{
                transform: `scale(${1 / scale})`,
                transformOrigin: 'top left',
                marginLeft: colMenuMarginLeft + 'px',
              }}
            >
              <Button onClick={this.showSettings}>
                <i className="fal fa-cog" />
              </Button>
              <SimplePrompt
                onSuccess={this.removeElement}
                placement="bottom"
                bsStyle="default"
                showLabel={false}
                className=" "
              />
            </div>
          </>
        )}

        {this.props.children}

        {!this.props.children && (
          <div className="p-t-15 p-b-15 text-center" onClick={this.noChildrenClick} style={{ opacity: 0.4 }}>
            <i className="fa fa-cube fa-4x" />
          </div>
        )}

        {editMode && (
          <div className="text-center ms-add-new ms-add-new-element">
            <OverlayTrigger
              ref={this.overlayRef}
              container={() => canvasContainerRef.current}
              delayHide={150}
              placement="bottom"
              trigger="click"
              rootClose
              wrappingComponent={Popover}
              id={`add_element_${mainId}`}
              overlay={<CmsNewElement addElement={this.addElement} />}
              onEnter={this.showNew}
              onExit={this.closeNew}
            >
              <a
                className={
                  'ms-cms-button btn btn-default btn-rounded ' + (mouseOver || showNew ? ' btn-show' : ' btn-hide')
                }
                style={{
                  transform: `scale(${1 / scale}) translateY(${15 * scale}px)`,
                }}
              >
                {messages.cms_add_element}
              </a>
            </OverlayTrigger>
          </div>
        )}

        {this.getAppendHtml()}
      </div>
    );

    return (
      <>
        <ScrollTrigger onEnter={this.shown} {...this.getCustomId()} />
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </>
    );
  }
}
