import { getLogger, decodeActionType, dismiss } from '@chedri/base';

const logger = getLogger('notification-desktop');

let handleNotificationClick = () => undefined;
let serviceWorkerRegistration;

function notifyAlternativeWithActions(title, options, key) {
  // Select the first action and make it the main action
  const action = options.actions.length > 0 ? options.actions[0] : {};
  const localOptions = Object.assign({}, options);
  // Remove the actions, otherwise this will produce an error
  delete localOptions.actions;
  // Show notification
  const desktopNotification = new Notification(title, localOptions);
  // Regsitering the click handler
  desktopNotification.onclick = () => {
    // Use notfication click handler
    handleNotificationClick({
      data: JSON.stringify({ event: 'chedri.notificationclick', action: action.action, key }),
    });
  };
  desktopNotification.onerror = ev => {
    logger.error('Error handling desktop notification', ev);
  };
}

function notifyWithActions(title, options, key) {
  if ('serviceWorker' in navigator && serviceWorkerRegistration) {
    if (serviceWorkerRegistration.showNotification) {
      // We can use showNotification, though actions are handled
      // Show notification
      serviceWorkerRegistration.showNotification(title, {
        ...options,
        data: JSON.stringify({ key, actions: options.actions }),
      });
    } else {
      logger.warn('Service worker showNotification not supported. Using alternative possibility with less function.');
      notifyAlternativeWithActions(title, options, key);
    }
  } else {
    logger.warn('Service worker functionality not supported. Using alternative possibility with less function.');
    notifyAlternativeWithActions(title, options, key);
  }
}

function createNotificationClickHandler(dispatch) {
  return event => {
    try {
      logger.debug('Notification clicked, preparing redux action.');
      const json = JSON.parse(event.data);
      if (json.event === 'chedri.notificationclick') {
        const action = decodeActionType(json);
        logger.debug('Dispatch decoded action with type', action.type, 'with payload', action.payload);
        dispatch(action);
        if (json.key) {
          dispatch(dismiss(json.key));
        }
      }
    } catch (err) {
      logger.error('Error while handling notification', event, err);
    }
  };
}

function setupServiceWorker() {
  if ('serviceWorker' in navigator) {
    logger.info('Register notifications service worker.');
    navigator.serviceWorker.register('/service-worker/notifications.js').then(registration => {
      if (registration.active) {
        logger.debug('Notifications service worker is registered successfully');
        serviceWorkerRegistration = registration;
      }
    });
    logger.debug('Setup event listener to receive messages from the service worker.');
    navigator.serviceWorker.addEventListener('message', handleNotificationClick);
  } else {
    logger.warn('Service worker are not available. Alternative handling is provided.');
  }
}

export function requestPermission() {
  if (typeof Notification === 'undefined') {
    logger.warn('Notfications are not supported at all.');
    // Tell the caller that we were not able to setup a desktop notification
    return Promise.reject(new Error('Desktop notifications are unsupported.'));
  }
  return Promise.resolve(Notification.requestPermission()) // Make a promise requestPermission for all browsers
    .then(permission => {
      if (permission == null) {
        // Standard requestPermission is not working
        return new Promise(res => {
          // Use the documented deprecated alternative version
          Notification.requestPermission(permission => {
            // and resolve the promise
            res(permission);
          });
        });
      }
      return permission;
    });
}

export function notifyUserByDesktop(title, options, key) {
  return requestPermission().then(permission => {
    if (permission === 'granted') {
      if (Array.isArray(options.actions)) {
        // We need to have actions, so ...
        notifyWithActions(title, options, key);
      } else {
        // eslint-disable-next-line no-unused-vars
        const desktopNotification = new Notification(title, options);
      }
      // Tell the caller that we were able to setup a desktop notification
      return true;
    }
    logger.warn('Desktop notifications are denied by the user.');
    // Tell the caller that we were not able to setup a desktop notification
    return false;
  });
}

export function provideStoreToNotification(store) {
  const { dispatch } = store;
  handleNotificationClick = createNotificationClickHandler(dispatch);
  setupServiceWorker();
}
