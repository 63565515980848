import React, { useCallback } from 'react';
import _findIndex from 'lodash/findIndex';
import { withWizard } from 'react-albus';
import { useIntl } from 'react-intl';
import { Button, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

function WizardButtons({
  wizard,
  onSubmit = () => {},
  pending,
  submitText,
  useHistory = false,
  onChange,
  backIcon = 'fa fa-chevron-left',
  continueIcon = 'fa fa-chevron-right',
  lastSeptIcon = 'fal fa-save',
  notShownInSteps = [],
}) {
  const { messages } = useIntl();
  const breakpointIdx = useSelector(state => state.layout.breakpointIndex);
  const { step, steps, next, previous, push } = wizard;
  const currentStepIdx = _findIndex(steps, item => item.id === step.id);
  const isFirst = !currentStepIdx;
  const isLast = steps.length - 1 === currentStepIdx;
  let showButtons = true;

  if (currentStepIdx >= 0) {
    showButtons = !notShownInSteps.includes(steps[currentStepIdx].id);
  }

  const goToStep = useCallback(
    step => {
      return onChange ? onChange(wizard, step) : push(step.id);
    },
    [wizard]
  );

  return (
    <Row className="wizard-buttons">
      {showButtons && (
        <Col xs={12}>
          {!isFirst ? (
            <Button
              bsStyle={null}
              bsSize={breakpointIdx > 1 ? 'lg' : 'sm'}
              onClick={useHistory ? previous : () => goToStep(steps[currentStepIdx - 1])}
              className="pull-left"
            >
              <i class={backIcon + ' m-r-10'} />
              {messages.previous}
            </Button>
          ) : null}
          {!isLast ? (
            <Button
              bsStyle="primary"
              bsSize={breakpointIdx > 1 ? 'lg' : 'sm'}
              onClick={useHistory ? next : () => goToStep(steps[currentStepIdx + 1])}
              className="pull-right"
              disabled={pending}
            >
              {messages.next}
              <i class={continueIcon + ' m-l-10'} />
            </Button>
          ) : (
            <Button
              bsStyle="primary"
              bsSize={breakpointIdx > 1 ? 'lg' : 'sm'}
              onClick={onSubmit}
              className="pull-right"
              disabled={pending}
            >
              <i class={`fa-fw m-r-10 ${pending ? ' fa fa-circle-notch fa-spin' : lastSeptIcon}`} />
              {submitText || messages.save}
            </Button>
          )}
        </Col>
      )}
    </Row>
  );
}

export default withWizard(WizardButtons);
