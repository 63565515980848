import React from 'react';

import { isCarRegistrationServiceActive, getShippingType } from 'helpers/checkout';
import { SHIPPING_TRANSPORT } from 'js/constants';

import LegalTextHelper from 'components/layout/LegalTextHelper';

import ModalDealerItemSalesTermsOfService from 'components/modals/ModalDealerItemSalesTermsOfService';
import ModalDealerCarSalesTermsOfService from 'components/modals/ModalDealerCarSalesTermsOfService';
import ModalDealerCarRentTermsOfService from 'components/modals/ModalDealerCarRentTermsOfService';
import ModalCancellationPolicy from 'components/modals/ModalCancellationPolicy';
import ModalPrivacyPolicy from 'components/modals/ModalPrivacyPolicy';
import LegalChedriCarServicesTerms from 'components/layout/checkout/legal/LegalChedriCarServicesTerms';
import LegalChedriPrivacyPolicy from 'components/layout/checkout/legal/LegalChedriPrivacyPolicy';
import LegalChedriCancellationPolicy from 'components/layout/checkout/legal/LegalChedriCancellationPolicy';

class StepFLegal extends React.Component {
  constructor(props) {
    super(props);

    const { checkout } = props;

    const bookedServices = [];
    if (getShippingType(checkout) === SHIPPING_TRANSPORT) {
      bookedServices.push('Lieferung');
    }

    if (isCarRegistrationServiceActive(checkout)) {
      bookedServices.push('Zulassung');
    }

    this.state = {
      showDealerItemSalesTerms: false, // show dealer's item sales terms modal?
      showDealerCarSalesTerms: false, // show dealer's car sales terms modal?
      showDealerCarRentTerms: false, // show dealer's car rental terms modal?
      showDealerCancellationPolicy: false, // should we show the dealer's cancellation policy?
      showDealerPrivacyPolicy: false, // should we show the dealer's privacy policy?
      showChedriCarServicesTerms: false,
      showChedriPrivacyPolicy: false,
      showChedriCancellationPolicy: false,
      carServiceInitiationPeriodAccepted: false, // did the user accept that the HC Digital Services may start with services
      bookedServices,
      hasBookedServices: bookedServices.length > 0,
    };
  }

  // when component mounts, check if any checkboxes are needed
  // if no, mark the legal accepted as "all good"
  componentDidMount = () => {
    const { onLegalAccepted } = this.props;
    const { hasBookedServices } = this.state;

    if (!hasBookedServices) {
      onLegalAccepted(true);
    }
  };

  checkboxClick = e => {
    const { checkout, onLegalAccepted } = this.props;
    const { hasBookedServices } = this.state;

    this.setState({ [e.target.name]: e.target.checked }, () => {
      const { carServiceInitiationPeriodAccepted } = this.state;

      let countNeeded = 0;
      if (checkout.isCarCheckout && hasBookedServices) {
        countNeeded = 1;
      }

      let countChecked = 0;
      if (hasBookedServices && carServiceInitiationPeriodAccepted) {
        countChecked++;
      }

      if (countChecked === countNeeded) {
        onLegalAccepted(true);
      } else {
        onLegalAccepted(false);
      }
    });
  };

  showDealerItemSalesTerms = e => {
    e.preventDefault();
    this.setState({ showDealerItemSalesTerms: true });
  };

  closeDealerItemSalesTerms = () => {
    this.setState({ showDealerItemSalesTerms: false });
  };

  showDealerCarSalesTerms = e => {
    e.preventDefault();
    this.setState({ showDealerCarSalesTerms: true });
  };

  closeDealerCarSalesTerms = () => {
    this.setState({ showDealerCarSalesTerms: false });
  };

  showDealerCarRentTerms = e => {
    e.preventDefault();
    this.setState({ showDealerCarRentTerms: true });
  };

  closeDealerCarRentTerms = () => {
    this.setState({ showDealerCarRentTerms: false });
  };

  showDealerCancellationPolicy = e => {
    e.preventDefault();
    this.setState({ showDealerCancellationPolicy: true });
  };

  closeDealerCancellationPolicy = () => {
    this.setState({ showDealerCancellationPolicy: false });
  };

  showDealerPrivacyPolicy = e => {
    e.preventDefault();
    this.setState({ showDealerPrivacyPolicy: true });
  };

  closeDealerPrivacyPolicy = () => {
    this.setState({ showDealerPrivacyPolicy: false });
  };

  showChedriCarServicesTerms = e => {
    e.preventDefault();
    this.setState({ showChedriCarServicesTerms: true });
  };

  closeChedriCarServicesTerms = () => {
    this.setState({ showChedriCarServicesTerms: false });
  };

  showChedriPrivacyPolicy = e => {
    e.preventDefault();
    this.setState({ showChedriPrivacyPolicy: true });
  };

  closeChedriPrivacyPolicy = () => {
    this.setState({ showChedriPrivacyPolicy: false });
  };

  showChedriCancellationPolicy = e => {
    e.preventDefault();
    this.setState({ showChedriCancellationPolicy: true });
  };

  closeChedriCancellationPolicy = () => {
    this.setState({ showChedriCancellationPolicy: false });
  };

  getDealerItemSalesTermsLabel = () => {
    /* const {
      intl: { messages },
    } = this.props;

    return <span></span>; */
  };

  getDealerCarSalesTermsLabel = () => {
    const {
      intl: { messages },
    } = this.props;

    return (
      <div className="p-l-25 m-b-10">
        <LegalTextHelper
          text={messages.checkout_buy_order_legal_text}
          substitutes={{
            '{company}': this.getDealerCompanyName(),
            '{general_terms_and_conditions}': (
              <a href="#showTerms" className="text-master b-b" onClick={this.showDealerCarSalesTerms}>
                {messages.signup_agreement_text_terms}
              </a>
            ),
            '{cancellation_policy_and_cancellation_form}': (
              <a href="#showCancellationPolicy" className="text-master b-b" onClick={this.showDealerCancellationPolicy}>
                {messages.signup_agreement_text_cancellation_policy_and_form}
              </a>
            ),
            '{privacy_policy}': (
              <a href="#showPrivacyPolicy" className="text-master b-b" onClick={this.showDealerPrivacyPolicy}>
                {messages.signup_agreement_text_data_use}
              </a>
            ),
          }}
        />
      </div>
    );
  };

  getDealerCarRentTermsLabel = () => {
    const {
      intl: { messages },
    } = this.props;

    return (
      <div className="p-l-25 m-b-10">
        <LegalTextHelper
          text={'Für die Miete gelten die {general_terms_and_conditions} von {company} ({privacy_policy})'}
          substitutes={{
            '{company}': this.getDealerCompanyName(),
            '{general_terms_and_conditions}': (
              <a href="#showTerms" className="text-master b-b" onClick={this.showDealerCarRentTerms}>
                {messages.signup_agreement_text_terms}
              </a>
            ),
            '{privacy_policy}': (
              <a href="#showPrivacyPolicy" className="text-master b-b" onClick={this.showDealerPrivacyPolicy}>
                {messages.signup_agreement_text_data_use}
              </a>
            ),
          }}
        />
      </div>
    );
  };

  getAdditionalServicesTermsLabel = () => {
    const {
      intl: { messages },
    } = this.props;
    const { hasBookedServices, bookedServices } = this.state;

    return (
      hasBookedServices && (
        <div className="p-l-25 m-b-10">
          <LegalTextHelper
            text={messages.checkout_services_terms_cancelation_legal_text}
            substitutes={{
              '{company}': 'HC Digital Services GmbH',
              '{booked_services}': bookedServices.join('/'),
              '{general_terms_and_conditions}': (
                <a href="#showTerms" className="text-master b-b" onClick={this.showChedriCarServicesTerms}>
                  {messages.signup_agreement_text_terms}
                </a>
              ),
              '{cancellation_policy_and_cancellation_form}': (
                <a
                  href="#showCancellationPolicy"
                  className="text-master b-b"
                  onClick={this.showChedriCancellationPolicy}
                >
                  {messages.signup_agreement_text_cancellation_policy_and_form}
                </a>
              ),
              '{privacy_policy}': (
                <a href="#showPrivacyPolicy" className="text-master b-b" onClick={this.showChedriPrivacyPolicy}>
                  {messages.signup_agreement_text_data_use}
                </a>
              ),
            }}
          />
        </div>
      )
    );
  };

  getCarServiceInitiationPeriodLabel = () => {
    const {
      intl: { messages },
    } = this.props;

    return (
      <span>
        <LegalTextHelper
          text={messages.checkout_payment_agreement}
          substitutes={{'{company}': 'HC Digital Services GmbH'}}
        />
      </span>
    );
  };

  getDealerCompanyName = () => {
    const { checkout } = this.props;
    return checkout.items[0].owner.company;
  };

  getOwner = () => {
    const { checkout } = this.props;
    return checkout.items[0].owner;
  };

  render() {
    const { checkout } = this.props;
    const {
      showDealerItemSalesTerms,
      showDealerCarSalesTerms,
      showDealerCarRentTerms,
      showDealerCancellationPolicy,
      showDealerPrivacyPolicy,
      showChedriCarServicesTerms,
      showChedriPrivacyPolicy,
      showChedriCancellationPolicy,
      carServiceInitiationPeriodAccepted,
      hasBookedServices,
    } = this.state;

    //
    const owner = this.getOwner();

    const modals = (
      <React.Fragment>
        <ModalDealerItemSalesTermsOfService
          show={showDealerItemSalesTerms}
          close={this.closeDealerItemSalesTerms}
          owner={owner}
        />
        <ModalDealerCarSalesTermsOfService
          show={showDealerCarSalesTerms}
          close={this.closeDealerCarSalesTerms}
          owner={owner}
        />
        <ModalDealerCarRentTermsOfService
          show={showDealerCarRentTerms}
          close={this.closeDealerCarRentTerms}
          owner={owner}
        />
        <ModalCancellationPolicy
          show={showDealerCancellationPolicy}
          close={this.closeDealerCancellationPolicy}
          owner={owner}
        />
        <ModalPrivacyPolicy show={showDealerPrivacyPolicy} close={this.closeDealerPrivacyPolicy} owner={owner} />

        <LegalChedriCarServicesTerms show={showChedriCarServicesTerms} close={this.closeChedriCarServicesTerms} />
        <LegalChedriPrivacyPolicy show={showChedriPrivacyPolicy} close={this.closeChedriPrivacyPolicy} />
        <LegalChedriCancellationPolicy show={showChedriCancellationPolicy} close={this.closeChedriCancellationPolicy} />
      </React.Fragment>
    );

    if (checkout.isCarRentCheckout) {
      return (
        <div>
          {this.getDealerCarRentTermsLabel()}

          {modals}
        </div>
      );
    }

    if (checkout.isCarCheckout) {
      return (
        <div>
          {this.getDealerCarSalesTermsLabel()}
          {this.getAdditionalServicesTermsLabel()}

          {hasBookedServices && (
            <div class="checkbox check-success no-margin">
              <input
                type="checkbox"
                value="true"
                name="carServiceInitiationPeriodAccepted"
                id="carServiceInitiationPeriodAccepted"
                onChange={this.checkboxClick}
                checked={carServiceInitiationPeriodAccepted}
              />
              <label for="carServiceInitiationPeriodAccepted" style={{ whiteSpace: 'normal' }}>
                {this.getCarServiceInitiationPeriodLabel()}
              </label>
            </div>
          )}

          {modals}
        </div>
      );
    }

    return (
      <div>
        {this.getDealerItemsTermsLabel()}

        {modals}
      </div>
    );
  }
}

export default StepFLegal;
