import React from 'react';
import { injectIntl } from 'react-intl';
import { Clearfix, Col } from 'react-bootstrap';

import FormInput from 'components/forms/FormInput';

class StepFCarWireTransfer extends React.Component {
  render() {
    const { intl } = this.props;
    const messages = intl.messages;

    return (
      <Clearfix>
        <Col xs={12} md={7} class="no-padding">
          <p>{messages.payment_instructions_will_be_provided_after_seller_accepts_order}</p>
        </Col>
      </Clearfix>
    );
  }
}

export default injectIntl(StepFCarWireTransfer);
