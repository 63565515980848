// eslint-disable-next-line no-unused-vars
import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { Row, Col, Form } from 'react-bootstrap';

import { chatModule, intlModule, getLogger, communicationModule } from '@chedri/base';

import Spinner from 'components/Spinner';

import ChatMessage from './ChatMessage';
import IconButton from '../IconButton';

const logger = getLogger('chat');

function toAuthor(creator, user, contact = null) {
  let author = user.profile;
  let isMe = true;
  if (creator === user.username) {
    author = user.profile;
  }
  if (contact && creator === contact.id) {
    author = contact;
    isMe = false;
  }
  return { id: author.id, name: author.name || `${author.first_name} ${author.last_name}`, isMe };
}

function scrollToBottom(element) {
  if (element) {
    setTimeout(() => {
      // eslint-disable-next-line no-param-reassign
      element.scrollTop = element.scrollHeight - element.clientHeight;
    }, 25);
  }
}

function Chat({ me, selectedContact }) {
  const dispatch = useDispatch();

  const locale = useSelector(intlModule.selectors.getLocale);
  const messages = useSelector(intlModule.selectors.getMessages);
  const contact = useSelector(communicationModule.contact.selectors.findOneFromList(selectedContact.id));
  const items = useSelector(chatModule.selectors.getMessages);
  const hasActiveConversation = useSelector(chatModule.selectors.hasActiveConversation);
  const hasInactivityWarning = useSelector(chatModule.selectors.hasInactivityWarning);
  const isPending = useSelector(chatModule.selectors.isPending) || !contact;
  const isConversationClosed = useSelector(chatModule.selectors.isConversationClosed(selectedContact.id));
  const isCustomerChat = selectedContact.customer_chat_id || (contact && contact.role === 'customer');
  const isSocketConnected = useSelector(communicationModule.selectors.socket.isConnected);
  const isOnline = useSelector(state => state.webApp.isOnline);

  const [chatRef, setChatRef] = useState();
  const [isContactOnline, setIsContactOnline] = useState(false);
  const [chatItems, setChatItems] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  const sendMessage = useCallback(
    e => {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      if (newMessage) {
        dispatch(
          chatModule.actions.sendMessage(contact.id, newMessage, {
            customerChat: contact.customer_chat_id,
            customerVideoCall: contact.customer_video_call_id,
            isAnonymousUser: contact.is_anonymous_user,
            role: contact.role,
          })
        );
        scrollToBottom(chatRef);
        setNewMessage('');
      }
    },
    [newMessage, contact, setNewMessage]
  );

  const changeMessage = useCallback(
    ({ target }) => {
      setNewMessage(target.value);
    },
    [setNewMessage]
  );

  useEffect(() => {
    if (selectedContact) {
      logger.debug('Show chat for contact', selectedContact);
      dispatch(
        chatModule.actions.openChat(
          selectedContact.id,
          !!selectedContact.is_anonymous_user,
          selectedContact.customer_chat_id,
          selectedContact.customer_video_call_id
        )
      );
      return () => {
        logger.debug('Disable chat window');
        dispatch(chatModule.actions.closeChat());
      };
    }
  }, [selectedContact]);

  useEffect(() => {
    if (items) {
      setChatItems([...items].reverse());
    }
    scrollToBottom(chatRef);
  }, [items, chatRef, setChatItems]);

  useEffect(() => {
    if (hasActiveConversation && chatRef) {
      scrollToBottom(chatRef);
    }
  }, [hasActiveConversation, chatRef]);

  useEffect(() => {
    if (selectedContact) {
      setIsContactOnline(selectedContact.is_online || selectedContact.is_mobile_online);
    }
  }, [selectedContact, setIsContactOnline]);

  useEffect(() => {
    if (hasInactivityWarning) {
      scrollToBottom(chatRef);
    }
  }, [chatRef, hasInactivityWarning]);

  return isPending ? (
    <Row>
      <Col xs={12}>
        <Spinner className="text-success" size={24} />
      </Col>
    </Row>
  ) : isCustomerChat && (isConversationClosed || !contact || !contact.is_online) ? (
    <Row>
      <Col xs={12}>{messages.customer_chat_ended}</Col>
    </Row>
  ) : (
    <>
      <div className="chat-inner" ref={ref => setChatRef(ref)}>
        {me && (
          <>
            {chatItems.map((item, index, contacts) => {
              const { name, isMe } = toAuthor(item.creator, me, contact);
              const createdAt = moment(item.cdate);
              let showDate = false;

              let isFirst = false;
              let isLast = false;
              if (index === 0 || contacts[index - 1].creator !== item.creator) {
                isFirst = true;
                showDate = index === 0 || (index > 0 && !createdAt.isSame(moment(contacts[index - 1].cdate), 'day'));
              }
              if (index + 1 === contacts.length || contacts[index + 1].creator !== item.creator) {
                isLast = true;
              }

              return (
                <div key={item.id}>
                  {showDate && <div className="chat-date">{createdAt.locale(locale).format('ll')}</div>}
                  <ChatMessage
                    item={item}
                    name={name}
                    isMe={isMe}
                    isFirst={isFirst}
                    isLast={isLast}
                    locale={locale}
                    timezone={me.profile.timezone || 'Europe/Berlin'}
                  />
                </div>
              );
            })}
            {hasInactivityWarning && (
              <ChatMessage
                item={{ message: messages.chat_inactivity_warning }}
                name={contact.name}
                isMe={false}
                isWarning
                locale={locale}
                timezone={me.profile.timezone}
              />
            )}
          </>
        )}
      </div>

      <div class={'message-form-container ' + (isContactOnline && isSocketConnected && isOnline ? '' : 'disabled ')}>
        <Form onSubmit={sendMessage}>
          <span class="input-group">
            <input
              type="text"
              class="form-control chat-input"
              placeholder={messages.message_required}
              disabled={!isContactOnline || !isSocketConnected || !isOnline}
              value={newMessage}
              onChange={changeMessage}
            />
            <span class="input-group-btn">
              <IconButton
                type="submit"
                name="fa-paper-plane"
                buttonClassName="btn btn-default btn-link bg-transparent"
                disabled={!isContactOnline || !isSocketConnected || !isOnline || !newMessage}
              />
            </span>
          </span>
        </Form>
      </div>
    </>
  );
}
Chat.defaultProps = {};
Chat.propTypes = {
  me: PropTypes.object.isRequired,
  selectedContact: PropTypes.object.isRequired,
};

export default Chat;
