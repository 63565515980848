import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { updateCheckout, updatePayment } from 'actions/CheckoutActions';

import * as PaymentButtons from 'components/ePayment/paymentButtons';

class CheckoutProceedButton extends React.Component {
  getSizeClass = () => {
    const { bsSize } = this.props;

    switch (bsSize) {
      case 'large':
      case 'lg': {
        return 'btn-lg';
      }

      case 'small':
      case 'sm': {
        return 'btn-sm';
      }

      case 'xsmall':
      case 'xs': {
        return 'btn-xs';
      }
    }

    return '';
  };

  getIcon = () => {
    const { iconClassName } = this.props;

    if (iconClassName !== 'none') {
      return <i className={'fa-fw ' + iconClassName} />;
    }

    return null;
  };

  render() {
    const { bsStyle, className, checkout } = this.props;
    const componentName = ('PaymentButton_' + checkout.activePaymentPlugin.toLowerCase()).replace(/_([a-z])/g, g => {
      return g[1].toUpperCase();
    });
    const Component = PaymentButtons[componentName];

    if (!Component) {
      return null;
    }

    const buttonClassName = `btn btn-${bsStyle} ${this.getSizeClass()} ${className}`;

    return <Component {...this.props} buttonClassName={buttonClassName} icon={this.getIcon()} />;
  }
}
CheckoutProceedButton.defaultProps = {
  bsStyle: 'default',
  className: '',
  iconClassName: 'fal fa-save',
  type: 'button',
};

const mapStateToProps = state => {
  return {
    user: state.login.user,
    checkout: state.checkout,
    projectConfig: state.data.projectConfig,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    doUpdateCheckout: params => {
      dispatch(updateCheckout(params));
    },
    doUpdatePayment: (plugin, type, data = {}) => {
      dispatch(updatePayment(plugin, type, data));
    },
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CheckoutProceedButton)
);
