import axios from 'axios';
import { createLogger, LogLevel, getLogger } from '@chedri/base';

// eslint-disable-next-line no-undef
const debug = DEBUG;
// eslint-disable-next-line no-undef
const useReduxLogger = USE_REDUX_LOGGER;

const consoleErrorFunc = console.error.bind(console);
console.error = (...args) => {
  getLogger('console').warn('ERROR -', ...args); // May an unthrown exception and only error logged to console.
  if (debug) {
    consoleErrorFunc(...args);
  }
};

const logger = createLogger('root', {
  level: debug ? LogLevel.Debug : LogLevel.Off,
  noErrorLog: true,
  timeToLive: 60 * 1000, // Keep the latest logging messages to report
  delay: 0, // > 0 report log messages in delay milliseconds, = 0 report messages only if errors occur , < 0 no reporting
  app: 'web',
  useReduxLogger,
});
logger.http = axios;
logger.http.defaults.baseURL = '';

logger.debug('Environment Development debug =', debug);

window.onerror = function uncaughtErrorHandler(message, url, lineNo, columnNo, error) {
  const errorDescriptor = { message, url, lineNo, columnNo };
  logger.fatal('Unexpected error', errorDescriptor, error);
};
window.addEventListener('unhandledrejection', errorEvent => {
  const { error, reason } = errorEvent;
  logger.fatal('Unexpected error', reason || error);
});

// eslint-disable-next-line import/prefer-default-export
export const crashReporterMiddleware = () => next => action => {
  let log;
  const source = /(\w*?)_(\w*)/gim.exec(action.type);
  if (source) {
    log = getLogger('redux_' + source[1].toLowerCase());
  } else {
    log = logger;
  }
  try {
    if (action.error) {
      log.warn('Error transported through redux stream.', action);
    } else {
      log.debug(action.type, action.payload); // -> Keep action at log cache to send on error
    }
    return next(action);
  } catch (err) {
    log.warn('Caught an exception on redux stream.', err);
    throw err; // -> Rethrow and give standard error handling a chance, at least we catch it at uncaught error handler
  }
};
