/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage, FormattedNumber } from 'react-intl';
import { Clearfix, Button, Label, Badge } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import _, { find as _find, pick as _pick } from 'lodash';

import { fetchCart } from 'actions/CartActions';
import { resetCheckout, initCheckout } from 'actions/CheckoutActions';
import { getNameInCurrentLanguage, getCartPath } from 'helpers/intl';
import { getThumbFile } from 'helpers/items';
import { userDataForCheckout } from 'helpers/clients';
import { parseQs, stringifyQs } from 'helpers/http';
import { cartRemoveEdition } from '../../../../actions/CartActions';

import MainMenuLink from 'components/layout/mainMenus/common/MainMenuLink';

class CartMenu extends React.Component {
  constructor(props) {
    super(props);

    this.index = 1200;
  }
  componentDidMount = () => {
    this.props.doInitCart();
  }

  componentDidUpdate = prevProps => {
    const { appIntl, location, history, cart, checkout, onMouseEnter, onMouseLeave } = this.props;

    // open the nav cart if a new item was added
    if (prevProps.cart.addPending && !cart.addPending) {
      onMouseEnter(this.index);
    }

    if (!prevProps.checkout.init && checkout.init && checkout.navToCheckout) {
      onMouseLeave();
      history.push({
        pathname: appIntl.urlPrefix + 'checkout',
        search: stringifyQs(_pick(parseQs(location.search), ['error'])), // error could be in the url from oauth login
      });
    }
  };

  getImage = (item, variant, edition) => {
    if (variant && variant.assets && variant.assets.imgs && variant.assets.imgs.length) {
      // the item is variant, the variant has own image, we should use it
      const imageThumb = getThumbFile(variant.assets.imgs[0]);
      return (imageThumb && imageThumb.web_link) || null;
    }

    if (item && item.assets && item.assets.imgs && item.assets.imgs.length) {
      let imgIndex = 0;
      imgIndex = _.findIndex(item.assets.imgs, img => img.owner.id === edition?.template?.target?.username);
      const imageThumb = getThumbFile(item.assets.imgs[imgIndex]);
      return (imageThumb && imageThumb.web_link) || null;
    }

    return null;
  };

  renderItem = ({ id, item, edition, variant }) => {
    const {
      intl: { messages },
      removeFromCart,
      appIntl,
    } = this.props;

    const imageUrl = this.getImage(item, variant, edition);
    let itemName;
    (typeof item?.name === 'object') ? itemName = getNameInCurrentLanguage(item.name, appIntl) :  itemName = item?.name;

    return (
      <Clearfix key={id}>
        <div className="pull-left m-r-10 p-b-10">
          {imageUrl ? (
            <img style={{ width: '100px' }} src={imageUrl} title={itemName} alt={itemName} />
          ) : (
            <div style={{ width: '100px' }} title={itemName}>
              <div className="p-t-20 p-b-20 bg-master-lightest text-center">
                <i className="fa fa-cube" />
              </div>
            </div>
          )}
        </div>

        <div className="pull-left m-l-10 ü-b-10" style={{ width: 'calc(100% - 120px)' }}>
          <span>{edition.title}</span>
          <br />
          <span className="font-secondary fs-11 all-caps">{itemName}</span>

          {variant && <div className="m-t-5">{this.printAttributes(item, variant)}</div>}
          <div className="m-t-5">
            <a href="#" onClick={() => removeFromCart(edition)} className="text-danger pull-left">
              {messages.delete}
            </a>
          </div>
        </div>
      </Clearfix>
    );
  };

  renderItems = () => {
    const { cart } = this.props;

    const visibleItems = cart.items.map(this.renderItem).slice(0, 5);
    return cart.items.length > visibleItems.length
      ? [
          ...visibleItems,
          <div className="p-t-10 text-center" key="more">
            {cart.items.length - visibleItems.length} more
          </div>,
        ]
      : visibleItems;
  };

  printAttributes = (item, variant) => {
    const { appIntl } = this.props;

    const res = [];
    for (let i = 0; i < item.variants.attributes.length; i++) {
      const variantAttribute = item.variants.attributes[i];

      const attribute = _find(variant.attributes, ['id', variantAttribute.id]);
      if (attribute) {
        res.push(
          <Label className="m-r-10 m-b-5" key={i}>
            {getNameInCurrentLanguage(attribute.name, appIntl)}:{' '}
            {getNameInCurrentLanguage(attribute.values[0].value, appIntl)}
          </Label>
        );
      }
    }

    return res;
  };

  doInitCheckout = () => {
    const { cart, user, doInitCheckout } = this.props;
    doInitCheckout(cart.items, userDataForCheckout(user));
  };

  getText = () => {
    const { label, cart } = this.props;

    return (
      <>
        {(!!label && label) || <i className="fal fa-shopping-bag" />}

        {cart.items && cart.items.length > 0 && (
          <Badge
            className="overlayer top-left inline fs-10 badge-important"
            style={{ marginTop: '-8px', marginLeft: '14px' }}
          >
            {cart.items.length}
          </Badge>
        )}
      </>
    );
  };

  getContent = () => {
    const {
      intl: { messages },
      cart,
      projectConfig,
    } = this.props;

    return (
      <Clearfix className="menu-dropdown">
        <ul
          className="dropdown-menu bg-transparent no-border no-padding no-shadow"
          style={{ position: 'static', display: 'block', float: 'none' }}
        >
          <li className="padding-15">
            <div className="clearfix">
              {!!cart.items.length && (
                <div className="pull-left">
                  <FormattedMessage id="count_products" values={{ count: cart.items.length }} />
                </div>
              )}

              <div className="pull-right">
                <Link to={getCartPath()} onClick={this.doClose}>
                  {messages.show_cart}
                </Link>
              </div>
            </div>

            {!cart.items.length && <h5 className="text-center">{messages.cart_is_empty}</h5>}

            {this.renderItems()}
          </li>

          {!!cart.items.length && (
            <li className="bg-master-lighter padding-15 clearfix">
              <div className="pull-right bold">
                {messages.total}{' '}
                <FormattedNumber value={cart.total_gross} style="currency" currency={projectConfig.data.currency} />
              </div>
            </li>
          )}
        </ul>
      </Clearfix>
    );
  };

  render() {
    const { appIntl, layout, onMouseEnter, onMouseLeave, currentIndex, animatingOut } = this.props;

    return (
      <MainMenuLink
        flipId=""
        index={this.index}
        currentIndex={currentIndex}
        layout={layout}
        onMouseEnter={onMouseEnter}
        onDropdownClose={onMouseLeave}
        hasDropdown
        animatingOut={animatingOut}
        id="nav-cart"
        className="relative"
        link={getCartPath()}
        text={
          <span>
            <i className="fad fa-shopping-cart" />
            {this.getText()}
          </span>
        }
        dropdownWidth={20}
        listClassName="nav-cart"
        dropdownMenuElements={[
          {
            content: [{ type: 'raw', params: [{ name: 'content', value: this.getContent() }] }],
            language: appIntl.locale,
          },
        ]}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    cart: state.cart,
    checkout: state.checkout,
    projectConfig: state.data.projectConfig,
    user: state.login.user,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    removeFromCart: edition => {
      dispatch(cartRemoveEdition(edition));
    },
    doInitCheckout: (items, userData) => {
      dispatch(resetCheckout(initCheckout(items, userData)));
    },
    doInitCart: () => {
      dispatch(fetchCart());
    }
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(CartMenu)
  )
);
