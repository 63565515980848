import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col, Clearfix } from 'react-bootstrap';
import { throttle as _throttle } from 'lodash';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

import RichEditorContent from 'components/RichEditorContent';

import { dispatchResizeEvent } from 'helpers/window';

class CmsElementCarouselChild extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.state = {
      translateY: 0,
      opacity: 1,
    };

    this.containerRef = React.createRef();
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.parallax);

    const img = new Image();
    img.onload = () => {
      dispatchResizeEvent();
    };
    img.src = this.getParamValue('imageUrl');
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.parallax);
  };

  componentDidUpdate = prevProps => {
    if (this.getPrevParamValue(prevProps, 'imageUrl') !== this.getParamValue('imageUrl')) {
      const img = new Image();
      img.onload = () => {
        dispatchResizeEvent();
      };
      img.src = this.getParamValue('imageUrl');
    }
  };

  parallax = () => {
    _throttle(() => {
      const container = this.containerRef.current;
      const containerRectangle = container.getBoundingClientRect();

      const containerHeight = containerRectangle.bottom - containerRectangle.top;

      const opacity =
        (containerHeight - (containerRectangle.top < 0 ? Math.abs(containerRectangle.top) : 0)) / containerHeight;

      this.setState({
        translateY: (1 - opacity) * containerHeight * 0.3,
        opacity: opacity * 1.5,
      });
    }, 50)();
  };

  render() {
    const { layout, index } = this.props;
    // const { translateY, opacity } = this.state;

    const imageUrl = this.getParamValue('imageUrl', null);
    const isFullHeight = this.getParamValue('fullHeight', 'no') === 'yes';
    const headerStyle = {
      // opacity,
      height: isFullHeight ? layout.height + 'px' : 'auto',
      // transform: 'translateY(' + translateY + 'px)'
    };

    const vAlign = this.getParamValue('vAlign', 'top');
    let vAlignClass = '';
    if (vAlign === 'middle') {
      vAlignClass = 'col-middle ';
    } else if (vAlign === 'bottom') {
      vAlignClass = 'col-bottom ';
    }

    const isFirstGrid = !!(index.toString().charAt(0) === '0' && isFullHeight);

    return (
      <div
        id={this.getMainId()}
        class="ms-carousel-child jumbotron bg-transparent full-height no-padding no-margin"
        ref={this.containerRef}
      >
        {this.getElementStyle()}

        <div
          class="container-fluid no-padding"
          style={{
            ...headerStyle,
            ...this.getBackgroundStyle(),
          }}
        >
          <div
            class="inner full-height"
            style={{
              backgroundImage: isFullHeight && imageUrl ? 'url(' + imageUrl + ')' : 'none',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div class={'full-height ' + (isFirstGrid ? 'content ' : '')} style={{ position: 'relative' }}>
              {!isFullHeight && imageUrl ? (
                <div class="w-100">
                  <img src={imageUrl} style={{ width: '100%' }} />
                </div>
              ) : null}

              <div
                class={vAlignClass}
                style={
                  !isFullHeight && imageUrl
                    ? { position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }
                    : {}
                }
              >
                <Grid>
                  <Row>
                    <Col xs={12} class={'text-' + this.getParamValue('mainColor', 'master')}>
                      <RichEditorContent content={'<div>' + this.getParamValue('content', '') + '</div>'} />
                    </Col>
                  </Row>
                </Grid>
              </div>
              <Clearfix />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout,
  };
};

export default connect(mapStateToProps)(CmsElementCarouselChild);
