import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import TableResponsive from 'components/layout/TableResponsive';

class TargetingCookiesList extends React.Component {
  render() {
    const {
      intl: { messages },
    } = this.props;

    const cookieListTableHeaders = {
      name: messages.name,
      description: messages.description,
      domain: messages.domain,
      ttl: 'TTL',
    };

    return (
      <TableResponsive
        headers={cookieListTableHeaders}
        rows={[
          {
            name: 'act',
            description: messages.cookie_description.act,
            domain: '.facebook.com',
            ttl: messages.cookie_ttl['end_of_session'],
          },
          {
            name: 'c_user',
            description: messages.cookie_description.c_user,
            domain: '.facebook.com',
            ttl: messages.cookie_ttl['end_of_session'],
          },
          {
            name: 'datr',
            description: messages.cookie_description.datr,
            domain: '.facebook.com',
            ttl: messages.cookie_ttl['2_years'],
          },
          {
            name: 'fr',
            description: messages.cookie_description.fr,
            domain: '.facebook.com',
            ttl: messages.cookie_ttl['2_years'],
          },
          {
            name: 'sb',
            description: messages.cookie_description.sb,
            domain: '.facebook.com',
            ttl: messages.cookie_ttl['end_of_session'],
          },
          {
            name: 'xs',
            description: messages.cookie_description.xs,
            domain: '.facebook.com',
            ttl: messages.cookie_ttl['end_of_session'],
          },
        ]}
        hover
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    projectConfig: state.data.projectConfig,
  };
};

export default injectIntl(connect(mapStateToProps)(TargetingCookiesList));
