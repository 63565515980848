import React from 'react';
import { Link } from 'react-router-dom';
import { Flipper } from 'react-flip-toolkit';
import { concat as _concat } from 'lodash';

import LinksList from 'components/layout/mainMenus/common/LinksList';
import DropdownProfile from 'components/layout/mainMenus/common/DropdownProfile';
import LanguageMenu from 'components/layout/mainMenus/common/LanguageMenu';
import CartMenu from 'components/layout/mainMenus/common/CartMenu';
import FavoritesMenu from 'components/layout/mainMenus/common/FavoritesMenu';
import AccountSearchMenu from 'components/layout/mainMenus/common/AccountSearchMenu';

import { ROLE_EMPLOYEE } from 'js/constants';
import may from 'js/security';

class SingleMinimalistic extends React.Component {
  render() {
    const {
      forwardedRef,
      appIntl: { urlPrefix },
      layout,
      user,
      scrolled,
      menuVersion,
      menuBackground,
      sidebarVisible,
      logoUrl,
      toggleSidebar,
      topNavLinks,
      languages,
      onMouseEnter,
      onMouseLeave,
      currentIndex,
      prevIndex,
      animatingOut,
      preview,
    } = this.props;

    let append = [];
    /*if (user.logged && may(ROLE_EMPLOYEE, user.roles)) {
      append.push(
        <AccountSearchMenu
          key="search"
          layout={layout}
          currentIndex={currentIndex}
          prevIndex={prevIndex}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          animatingOut={animatingOut}
          label={<i className="fal fa-search" />}
        />
      );
    }*/

    append = _concat(append, [
      <FavoritesMenu
        key="favorites"
        layout={layout}
        currentIndex={currentIndex}
        prevIndex={prevIndex}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        animatingOut={animatingOut}
        languages={languages}
      />,
      <LanguageMenu
        key="language"
        layout={layout}
        currentIndex={currentIndex}
        prevIndex={prevIndex}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        animatingOut={animatingOut}
        languages={languages}
      />,
      <CartMenu
        key="cart"
        layout={layout}
        currentIndex={currentIndex}
        prevIndex={prevIndex}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        animatingOut={animatingOut}
        languages={languages}
      />,
      <DropdownProfile
        key="user"
        layout={layout}
        currentIndex={currentIndex}
        prevIndex={prevIndex}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        animatingOut={animatingOut}
        label={<i className="fal fa-user" />}
      />,
    ]);

    return (
      <nav className="horizontal-menu mv-single-minimalistic" role="navigation" ref={forwardedRef}>
        <div
          className={`header no-border header-${menuVersion} header-${menuBackground} ${
            preview ? 'header-preview ' : ''
          }`}
          id="horizontal-menu"
        >
          {/* responsive menu */}
          <div className="container-fluid relative">
            <div className="pull-left full-height visible-sm visible-xs">
              <div className="header-inner">
                <a
                  href="#toggleSidebar"
                  className="btn-link toggle-sidebar visible-sm-inline-block visible-xs-inline-block padding-5"
                  onClick={toggleSidebar}
                >
                  <div id="nav-burger-icon" className={(sidebarVisible ? 'open ' : '') + (scrolled ? 'scrolled ' : '')}>
                    <span />
                    <span />
                    <span />
                    <span />
                  </div>
                </a>
              </div>
            </div>
            <div className="pull-center hidden-md hidden-lg">
              <div className="header-inner">
                <div className="brand inline">
                  <Link to={urlPrefix}>
                    <img src={logoUrl} alt="logo" className="nav-logo" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* desktop menu */}
          <div className="container-fluid no-padding relative hidden-xs hidden-sm">
            <div className="pull-left">
              <div className="header-inner">
                <div className="brand inline">
                  <Link to={urlPrefix}>
                    {/* when it comes to the left padding, it's important to have it when the left menu is there */}
                    <img src={logoUrl} alt="logo" className="nav-logo" />
                  </Link>
                </div>
              </div>
            </div>

            <div className="bar-inner header-inner main-menu-elements">
              <Flipper flipKey={currentIndex} spring="veryGentle">
                <LinksList
                  flipId="main"
                  layout={layout}
                  elements={topNavLinks}
                  currentIndex={currentIndex}
                  prevIndex={prevIndex}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  animatingOut={animatingOut}
                />
              </Flipper>
            </div>

            <div className="pull-right">
              <div className="bar-inner header-inner">
                <div>
                  <LinksList
                    flipId="sys"
                    layout={layout}
                    elements={[]}
                    currentIndex={currentIndex}
                    prevIndex={prevIndex}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    animatingOut={animatingOut}
                    append={append}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

const SingleMinimalisticForExport = React.forwardRef((props, ref) => (
  <SingleMinimalistic forwardedRef={ref} {...props} />
));
SingleMinimalisticForExport.displayName = 'MenuSingleMinimalistic';

export default SingleMinimalisticForExport;
