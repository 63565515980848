import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Grid, Row, Col } from 'react-bootstrap';

import {
  mayNavigateToB,
  mayNavigateToC,
  mayNavigateToD,
  mayNavigateToE,
  mayNavigateToF,
  mayNavigateToG,
} from 'helpers/checkout';

import Tabs from 'components/layout/Tabs';

class CheckoutNav extends React.Component {
  render() {
    const { checkout, steps, stepIndex, onNav } = this.props;

    const currentNavIndex = stepIndex - 1;

    /* console.log(
      mayNavigateToB(checkout),
      mayNavigateToC(checkout),
      mayNavigateToD(checkout),
      mayNavigateToE(checkout),
      mayNavigateToF(checkout),
      mayNavigateToG(checkout)
    ) */

    const tabs = [];
    const disabled = [];

    for (let i = 0; i < steps.length; i++) {
      if (disabled.length || !steps[i].stepChecker(checkout)) {
        disabled.push(i);
      }

      tabs.push(
        <>
          <i class={steps[i].icon + ' tab-icon'} /> {steps[i].title}
        </>
      );
    }

    //  console.log(disabled)

    return (
      <Grid fluid className="no-padding">
        <Row>
          <Col xs={12} className="no-padding">
            <Tabs
              tabStyle="arrow"
              tabResponsiveStyle="stack"
              tabs={tabs}
              onTabChange={onNav}
              selectedTab={currentNavIndex}
              disabled={disabled}
              className=""
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(CheckoutNav)));
