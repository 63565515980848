import React from 'react';

export function tourSteps(messages){
  const tourStepsAllLanguages = [
    {
      selector: '#page',
      content: <p><strong>{messages.editor.work_surface}</strong><br/>{messages.editor.work_surface_description}</p>,
    },
    {
      selector: '#pagesCanvas',
      content: <p><strong>{messages.editor.page_navigation}</strong><br/>{messages.editor.page_navigation_description}</p>,
    },
    {
      selector: '#toolbar-blocks',
      content: <p><strong>{messages.editor.content_blocks}</strong><br/>{messages.editor.content_blocks_description}</p>,
    },
    {
      selector: '#zoomer',
      content: <p><strong>{messages.editor.screen_view}</strong><br/>{messages.editor.screen_view_description}</p>,
    },
    {
      selector: '#errorBox',
      content: <p><strong>{messages.editor.error_display}</strong><br/>{messages.editor.error_display_description}</p>,
    },
    {
      selector: '.right-tools',
      content: <p><strong>{messages.editor.options}</strong><br/>{messages.editor.options_description}</p>,
    },
    {
      selector: 'body',
      content: <p><strong>{messages.editor.tutorial_end}</strong><br/>{messages.editor.tutorial_end_description}</p>,
    },
  ];

  return tourStepsAllLanguages
}
