import axios from 'axios';

export function handleCardSetup(stripe, cardOwner) {
  return axios.post('/api/stripe/setupIntent/create').then(res => {
    return stripe.handleCardSetup(res.data.client_secret, {
      payment_method_data: {
        billing_details: {
          name: cardOwner,
        },
      },
    });
  });
}
