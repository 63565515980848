import { handleCardSetup } from 'helpers/stripe';

export const CHECKOUT_RESET = 'CHECKOUT_RESET';
export const CHECKOUT_INIT = 'CHECKOUT_INIT';
export const CHECKOUT_UPDATE = 'CHECKOUT_UPDATE';
export const PAYMENT_UPDATE = 'PAYMENT_UPDATE';
export const CHECKOUT_STRIPE_CARD_SETUP = 'CHECKOUT_STRIPE_CARD_SETUP';
export const CHECKOUT_STRIPE_CARD_SETUP_REJECTED = 'CHECKOUT_STRIPE_CARD_SETUP_REJECTED';
export const CHECKOUT_STRIPE_CARD_SETUP_FULFILLED = 'CHECKOUT_STRIPE_CARD_SETUP_FULFILLED';

export function resetCheckout(nextAction) {
  return {
    type: CHECKOUT_RESET,
    payload: nextAction,
  };
}

export function initCheckout(items, userData, initData = {}) {
  return {
    type: CHECKOUT_INIT,
    payload: { items, userData, initData },
  };
}

export function initCarCheckout(car, userData, initData = {}) {
  return {
    type: CHECKOUT_INIT,
    payload: { items: [car], userData, initData },
  };
}

export function initCarRentCheckout(car, locationData, userData, userTimezone, dateFrom, dateTo, initData = {}) {
  return {
    type: CHECKOUT_INIT,
    payload: { items: [car], locationData, userData, userTimezone, dateFrom, dateTo, initData },
  };
}

export function updateCheckout(params) {
  return {
    type: CHECKOUT_UPDATE,
    payload: params,
  };
}

export function updatePayment(plugin, type, data = {}) {
  return {
    type: PAYMENT_UPDATE,
    payload: { plugin, type, data },
  };
}

export function handleStripeCardSetup(stripe, cardOwner) {
  return {
    type: CHECKOUT_STRIPE_CARD_SETUP,
    payload: handleCardSetup(stripe, cardOwner),
  };
}
