import React from 'react';
import { Clearfix, Col, Button } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';
import moment from 'moment';

import { elementSource, elementTarget } from './elementDragUtils';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
export default class CmsElementCommerceOnlineAppointmentBooking extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      coordinates: '',
      address: localStorage.getItem('locationAddress') || '',
      addressInputFocused: false,
      addressInputValue: localStorage.getItem('locationAddress') || '',

      shown: false,
    };

    moment.locale(props.intl.locale);
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  render() {
    const {
      intl: { messages },
      connectDropTarget,
      connectDragSource,
      connectDragPreview,
    } = this.props;
    const { addressInputFocused, shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const textClass = 'text-' + this.getParamValue('mainColor', 'master') + ' ';

    const parallaxData = this.getParallaxData();

    const locationInput = (
      <div class={'form-group form-group-default ' + (addressInputFocused ? 'focused ' : '')}>
        <input
          value={''}
          class="form-control"
          type="text"
          onFocus={() => {
            this.setState({ addressInputFocused: true });
          }}
          onBlur={() => this.setState({ addressInputFocused: false })}
        />
      </div>
    );

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-ecommerce-online-appointment-booking ' + shownClass + this.getCssClasses()}
        onClick={this.showSettings}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <Clearfix>
          <Col xs={12} lg={11}>
            <h4 class={'no-margin font-secondary fs-13 text-uppercase lh-16 bold ' + textClass}>
              {messages.desired_pickup_location}
            </h4>
            {locationInput}
          </Col>
          <Col xs={12} mdOffset={6} md={6} lgOffset={0} lg={1}>
            <h4 class="no-margin font-secondary fs-13 lh-16 hidden-xs hidden-sm hidden-md">{'\u00A0'}</h4>{' '}
            {/* dummy element so the button aligns with the input elements */}
            <Button bsStyle="primary" class="hidden-xs hidden-sm hidden-md" block>
              <i class="fa fa-chevron-right" />
            </Button>
            <Button bsStyle="primary" bsSize="lg" class="hidden-lg text-uppercase" block>
              {messages.search}
            </Button>
          </Col>
        </Clearfix>

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}
