import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getSalutationOptions } from 'helpers/clients';
import axios from 'axios';
import { addNotification } from 'actions/NotificationsActions';
import FormAutocomplete from 'components/forms/FormAutocomplete';

class CartWizardContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      entity: {
        ...props.contact,
        target: props.contact.target ?? props.target,
        addresses: [{
          ...props.address,
          target: props.address.target ?? props.target,
        }],
      },
      disabled: props.disabled,
      savingData: false,
      isFormDirty: false,
      isFormValid: true,
      initialEntity: JSON.stringify({
        ...props.contact,
        target: props.contact.target ?? props.target,
        addresses: [{
          ...props.address,
          target: props.address.target ?? props.target,
        }],
      }),

    };
  }

  componentDidUpdate(prevProps) {
    if(this.props.disabled !== prevProps.disabled) {
      this.setState({ disabled: this.props.disabled });
    }
    // Typical usage, don't forget to compare the props
    if (this.props.contact?.id !== prevProps.contact?.id || this.props.address?.id !== prevProps.address?.id) {
      this.setState({
        entity: {
          ...this.props.contact,
          target: this.props.contact.target ?? this.props.target,
          addresses: [{
            ...this.props.address,
            target: this.props.address.target ?? this.props.target,
          }],
        },
        initialEntity: JSON.stringify({
          ...this.props.contact,
          target: this.props.contact.target ?? this.props.target,
          addresses: [{
            ...this.props.address,
            target: this.props.address.target ?? this.props.target,
          }],
        }),
      });
    }
  }

  validateForm = (entity) => {
    const { messages } = this.props;
    entity = entity ?? this.state.entity;
    let errors = [];
    const address = entity.addresses[0];
    if (!address.city) {
      errors.push(messages.city_required);
    }
    if (!address.house_number) {
      errors.push(messages.house_number_required);
    }
    if (!address.street) {
      errors.push(messages.street_required);
    }

    if (!address.country) {
      errors.push(messages.country_required);
    }

    return errors;
  };

  handleInputChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    let entity = this.state.entity;

    if (name.includes('address.country')) {
      entity.addresses = [{
          ...entity.addresses[0],
          country: value,
          country_name: event.target.label,
        }];
    } else if (name.includes('address')) {
      entity.addresses = [{
            ... entity.addresses[0],
            [name.replace('address.', '')]: value,
          }];
    } else if (name.includes('mobile') || name.includes('phone')) {
      let numbers = entity.numbers ?? [];
      const index = numbers.findIndex(item => item.type === name);
      if (index > -1) {
        if(value === '' || value === null || value === undefined) {
          numbers.splice(index, 1);
        } else {
          numbers[index] = {
            ...numbers[index],
            number: value,
          };
        }
      } else if(value !== '' && value !== null) {
        numbers.push({
          type: name,
          number: value,
        });
      }
      entity.numbers = numbers;
    } else {
      entity[name] = value;
    }
    const isFormDirty = JSON.stringify(entity) !== this.state.initialEntity,
          isFormValid = this.validateForm(entity).length === 0;

    this.setState({
      entity,
      isFormDirty,
      isFormValid,
    });

    this.props.contactFormNeedsAttention(isFormDirty);
  };

  saveChanges = () => {
    const { messages, doNotify, type } = this.props;
    const { isFormDirty, entity } = this.state;
    let contact = entity;
    if(!isFormDirty) {
      doNotify(0, messages.no_changes_made);
      return;
    }

    let errors = this.validateForm();
    if(errors.length > 0){
      doNotify(0, errors.join('<br>'));
      return;
    }

    let address = entity.addresses[0];
    if (typeof address.types === 'undefined' || address.types == null || address.types.length === 0) {
      address.types = ['delivery'];
      if(type === 'invoice') address.types.push('invoice');
    } else {
      if (address.types.indexOf('delivery') === -1) address.types.push('delivery');
      if (address.types.indexOf('invoice') === -1 && type === 'invoice') address.types.push('invoice');
    }

    this.setState({ savingData: true });
    axios[typeof address.id !== 'undefined' ? 'put' : 'post'](`/api/addresses${address.id ? '/' + address.id : ''}.json`, address)
      .then((response) => {
        address.id = response.data.id;
        contact.addresses[0] = address;
        axios[typeof contact.id !== 'undefined' ? 'put' : 'post'](`/api/contacts${contact.id ? '/' + contact.id : ''}.json`, contact)
          .then((response) => {
            contact.id = response.data.id;
            doNotify(1, messages.contact_data_saved);
            this.props.contactIsUpdated(contact, address);
            this.props.contactFormNeedsAttention(false);
            this.setState({
              entity: contact,
              initialEntity: JSON.stringify(contact),
              savingData: false,
              isFormDirty: false
            });
          })
          .catch((error) => {
            console.log(error);
            doNotify(0, error);
          });
      })
      .catch((error) => {
        console.log(error);
        doNotify(0, error);
      })
      .finally(() => this.setState({ savingData: false }));
  };

  render() {
    const { entity, target, savingData, isFormDirty, isFormValid, disabled } = this.state;
    const { messages } = this.props;
    let contact = entity;
    let address = entity.addresses[0];
    let isFormDisabled = typeof disabled === 'boolean' ? disabled : true;

    let landline = null;
    let mobile = null;

    contact?.numbers?.forEach(item => {
      const { type, number } = item;
      if (type === 'phone') {
        landline ??= number;
      } else if (type === 'mobile') {
        mobile ??= number;
      }
    });

    if (contact?.target && target?.profile && contact.target.trim() === target.username.trim()) {
      if (target.profile.phone) {
        landline ??= target.profile.phone;
      }
      if (target.profile.mobile) {
        mobile ??= target.profile.mobile;
      }
    } else if (contact?.target && target?.profile && contact.target.trim() !== target.username.trim()) {
      isFormDisabled = true;
    }

    return (
      <div>
        <Row>
          <Col xs={12} md={12} className="wizard-contact-form__left">
            <FormGroup controlId="companyInput">
              <ControlLabel style={{ whiteSpace: 'nowrap' }}>{messages?.company || <span>&nbsp;</span>}</ControlLabel>
              <FormControl type="text" name="company" value={contact?.company || ''} readOnly={isFormDisabled}
                           onChange={this.handleInputChange} />
            </FormGroup>
            <Row>
              <Col xs={6}>
                <FormGroup controlId="formControlsSelect">
                  <ControlLabel>{messages.salutation || <span>&nbsp;</span>}</ControlLabel>
                  <FormControl style={{ cursor: isFormDisabled ? 'not-allowed' : 'default' }} value={contact.salutation}
                               name={'salutation'}
                               componentClass="select" readOnly={isFormDisabled} onChange={this.handleInputChange}>
                    <option value={''}>{''}</option>
                    {getSalutationOptions(messages).map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </FormControl>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup controlId="titleInput">
                  <ControlLabel style={{ whiteSpace: 'nowrap' }}>{messages.title || <span>&nbsp;</span>}</ControlLabel>
                  <FormControl type="text" value={contact.title || ''} name={'title'} readOnly={isFormDisabled}
                               onChange={this.handleInputChange} />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup controlId="nameInput">
              <ControlLabel style={{ whiteSpace: 'nowrap' }}>{messages.last_name || <span>&nbsp;</span>}</ControlLabel>
              <FormControl type="text" value={contact.last_name || ''} name={'last_name'} readOnly={isFormDisabled}
                           onChange={this.handleInputChange} />
            </FormGroup>
            <FormGroup controlId="firstnameInput">
              <ControlLabel style={{ whiteSpace: 'nowrap' }}>{messages.first_name || <span>&nbsp;</span>}</ControlLabel>
              <FormControl type="text" value={contact.first_name || ''} name={'first_name'} readOnly={isFormDisabled}
                           onChange={this.handleInputChange} />
            </FormGroup>
            <Row>
              <Col xs={6}>
                <FormGroup controlId="streetInput">
                  <ControlLabel
                    style={{ whiteSpace: 'nowrap' }}>{[messages.street, messages.house_number, messages.apartment_number].join(' / ') ||
                    <span>&nbsp;</span>}</ControlLabel>
                  <FormControl type="text" value={address.street || ''} name={'address.street'}
                               readOnly={isFormDisabled} onChange={this.handleInputChange} />
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormGroup controlId="houseNumberInput">
                  <ControlLabel style={{ whiteSpace: 'nowrap' }}>{<span>&nbsp;</span>}</ControlLabel>
                  <FormControl type="text" value={address.house_number || ''} name={'address.house_number'}
                               readOnly={isFormDisabled} onChange={this.handleInputChange} />
                </FormGroup>
              </Col>
              <Col xs={2}>
                <FormGroup controlId="apartmentNumberInput">
                  <ControlLabel style={{ whiteSpace: 'nowrap' }}>{<span>&nbsp;</span>}</ControlLabel>
                  <FormControl type="text" value={address.apartment_number || ''} readOnly={isFormDisabled}
                               name={'address.apartment_number'} onChange={this.handleInputChange} />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup controlId="addressLine2Input">
                  <ControlLabel style={{ whiteSpace: 'nowrap' }}>{messages.address_line2}</ControlLabel>
                  <FormControl type="text" value={address.address_line2 || ''} readOnly={isFormDisabled}
                               name={'address.address_line2'} onChange={this.handleInputChange} />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={12} className="wizard-contact-form__right">
            <Row>
              <Col xs={6} md={6}>
                <FormGroup controlId="zipcodeInput">
                  <ControlLabel style={{ whiteSpace: 'nowrap' }}>{messages.zip_code ||
                    <span>&nbsp;</span>}</ControlLabel>
                  <FormControl type="text" value={address.zipcode || ''} name={'address.zipcode'}
                               readOnly={isFormDisabled} onChange={this.handleInputChange} />
                </FormGroup>
              </Col>
              <Col xs={6} md={6}>
                <FormGroup controlId="cityInput">
                  <ControlLabel style={{ whiteSpace: 'nowrap' }}>{messages.city || <span>&nbsp;</span>}</ControlLabel>
                  <FormControl type="text" value={address.city || ''} name={'address.city'} readOnly={isFormDisabled}
                               onChange={this.handleInputChange} />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup controlId="mobileInput">
              <ControlLabel style={{ whiteSpace: 'nowrap' }}>{messages.mobile || <span>&nbsp;</span>}</ControlLabel>
              <FormControl type="text" value={mobile || ''} name={'mobile'} readOnly={isFormDisabled}
                           onChange={this.handleInputChange} />
            </FormGroup>
            <FormGroup controlId="phoneInput">
              <ControlLabel style={{ whiteSpace: 'nowrap' }}>{messages.phone || <span>&nbsp;</span>}</ControlLabel>
              <FormControl type="text" value={landline || ''} name={'phone'} readOnly={isFormDisabled}
                           onChange={this.handleInputChange} />
            </FormGroup>
            <FormGroup controlId="emailInput">
              <ControlLabel style={{ whiteSpace: 'nowrap' }}>{messages.email || <span>&nbsp;</span>}</ControlLabel>
              <FormControl type="text" value={contact.email || ''} name={'email'} readOnly={isFormDisabled}
                           onChange={this.handleInputChange} />
            </FormGroup>
            <FormAutocomplete
              style={{ border: 'none', backgroundColor: 'transparent', padding: '0' }}
              ref="country"
              name={`address.country`}
              label={messages.country}
              value={address.country}
              onChange={(name, value) => {
                this.handleInputChange({ target: { name: `address.country`, value: value.value, label: value.label } });
              }}
              disabled={isFormDisabled}
              autocompleteType="country"
              returnValueObject
              complete
            />
          </Col>
        </Row>
        {!isFormDisabled && (
          <Row>
            <button disabled={!isFormDirty} type="button" className="pull-right btn btn-primary" onClick={this.saveChanges}>
              <i className={'fas ' + (savingData ? 'fa-circle-notch fa-spin' : 'fa-save')} /> {messages.save}</button>
          </Row>)}
      </div>
    );
  }
}

CartWizardContactForm.propTypes = {
  contact: PropTypes.object,
  address: PropTypes.object,
  target: PropTypes.object,
  contactIsUpdated: PropTypes.func,
  saveAsDefaultContact: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  messages: PropTypes.object,
  doNotify: PropTypes.func,
  notifyParentForFormStatus: PropTypes.func
};

const mapStateToProps = ({
                           intl: {
                             locale,
                             messages: { messages },
                           },
                         ... state
                         }) => ({ locale, messages, user: state.login.user, });
const mapDispatchToProps = dispatch => {
  return {
    doNotify: (code, text) => {
      dispatch(addNotification(code, text));
    },
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(CartWizardContactForm);
