import React from 'react';
import { FormattedNumber } from 'react-intl';

export default class CarSalesServices extends React.Component {
  render() {
    const { layout, checkout, projectConfig } = this.props;

    let anyServiceActive = false;
    for (let i = 0; i < checkout.services.length; i++) {
      if (checkout.services[i].isActive) {
        anyServiceActive = true;
        break;
      }
    }

    return anyServiceActive ? (
      <React.Fragment>
        {checkout.services.map((service, i) => {
          if (service.isActive) {
            const itemImage = (
              <div title={service.title}>
                <div class="p-t-20 p-b-20 bg-master-lightest text-center">
                  <i class="fa fa-cube" />
                </div>
              </div>
            );
            const itemDetails = (
              <div>
                <h5 className="bold m-t-0">{service.title}</h5>

                <h6>Ihr Vertragspartner</h6>
                <div className="small">
                  <address>
                    HC Digital Services GmbH
                    <br />
                    Trippstadterstr. 110
                    <br />
                    67663 Kaiserslautern
                  </address>
                </div>
              </div>
            );
            const itemPrice = (
              <h5 className="bold">
                <FormattedNumber value={service.cost} style="currency" currency={projectConfig.data.currency} />
              </h5>
            );

            return layout.breakpointIndex > 1 ? (
              <tr key={service.name}>
                <td className="col-xs-3 v-align-middle">{itemImage}</td>
                <td className="col-xs-6 v-align-middle">{itemDetails}</td>
                <td className="col-xs-3 v-align-middle text-right">{itemPrice}</td>
              </tr>
            ) : (
              <React.Fragment key={service.name}>
                {itemDetails} {itemPrice}
              </React.Fragment>
            );
          }

          return null;
        })}
      </React.Fragment>
    ) : null;
  }
}
