import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Badge } from 'react-bootstrap';

import { getFavoritesPath } from 'helpers/intl';

import MainMenuLink from 'components/layout/mainMenus/common/MainMenuLink';

class FavoritesMenu extends React.Component {
  constructor(props) {
    super(props);

    this.index = 1300;
  }

  getText = () => {
    const { label, favoriteItems } = this.props;

    return (
      <>
        {(!!label && label) || <i className="fal fa-heart" />}

        {favoriteItems.count > 0 && (
          <Badge
            className="overlayer top-right inline hint-text fs-10 badge-important"
            style={{ marginTop: '-10px', marginRight: '-10px' }}
          >
            {favoriteItems.count}
          </Badge>
        )}
      </>
    );
  };

  render() {
    const { layout, onMouseEnter, onMouseLeave, currentIndex, animatingOut } = this.props;

    return (
      <MainMenuLink
        flipId=""
        index={this.index}
        currentIndex={currentIndex}
        layout={layout}
        onMouseEnter={onMouseEnter}
        onDropdownClose={onMouseLeave}
        animatingOut={animatingOut}
        id="nav-favorites"
        className="relative"
        link={getFavoritesPath()}
        text={this.getText()}
        listClassName="nav-favorites"
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    favoriteItems: state.favoriteItems,
  };
};

export default injectIntl(connect(mapStateToProps)(FavoritesMenu));
