export const copyAmountAttributeId = '5e9d61bc3a0211433f3be1c3';

export function getVariantCopyAmountValue(variant) {
  return variant
    ? parseInt(variant.attributes.find(({ id }) => id === copyAmountAttributeId).values[0].value[0].name, 10)
    : 0;
}

export function getVariantNetPrice(variant, cartItem) {
  if (cartItem.item.type === 'itemWebsite') {
    return cartItem.item.pricing.price;
  }
  if (cartItem.print_type === 'pdf_only') {
    return cartItem.print_pdf_price;
  }

  let net = 0;
  if (variant) {
    net = variant.pricing.price;
    if (cartItem.shipping_type === 'express') {
      net = variant.pricing.express_price;
    } else if (cartItem.shipping_type === 'overnight') {
      net = variant.pricing.overnight_price;
    } else if (cartItem.shipping_type === 'sameday') {
      net = variant.pricing.sameday_price;
    }
  }

  return net;
}

export function getVariantLeftCampaignBudget(variant, cartItem) {
  if (!cartItem.campaign_budget) {
    return 0;
  }

  return (
    cartItem.campaign_budget +
    cartItem.coupon_value -
    getVariantNetPrice(variant, cartItem) -
    cartItem.campaign_running_budget -
    cartItem.split_shipping_price -
    (cartItem.is_manual_data_check_requested ? cartItem.manual_data_check_price : 0) -
    (cartItem.is_web_pdf_requested ? cartItem.web_pdf_price : 0) -
    (cartItem.is_wants_domain_hosting ? cartItem.domain_hosting_net_amount : 0)
  );
}

export function getVariantLeftUserBudget(variant, cartItem) {
  if (!cartItem.user_budget) {
    return 0;
  }

  return (
    cartItem.user_budget +
    cartItem.coupon_value -
    getVariantNetPrice(variant, cartItem) -
    cartItem.user_running_budget -
    cartItem.split_shipping_price -
    (cartItem.is_manual_data_check_requested ? cartItem.manual_data_check_price : 0) -
    (cartItem.is_web_pdf_requested ? cartItem.web_pdf_price : 0) -
    (cartItem.is_wants_domain_hosting ? cartItem.domain_hosting_net_amount : 0)
  );
}

export const isCartItemTextileQuantitiesValid = cartItem => {
  const { textile_sizes_option: option, textile_sizes_selected: selected, variant } = cartItem;
  if (!option) {
    return true;
  }
  const textileSelectedTotal = selected?.reduce((acc, { quantity }) => acc + quantity, 0) ?? 0;

  return getVariantCopyAmountValue(variant) === textileSelectedTotal;
};

export const isCartTextileQuantitiesValid = ({ items }) => items.every(isCartItemTextileQuantitiesValid);

/**
 * Get string of selected textile sizes and their quantities,
 * like "Größe S: 2, Größe M: 3"
 * @param {object} cartItem
 * @returns {string}
 */
const textileSummary = cartItem => {
  const { textile_sizes_option: option, textile_sizes_selected: selected } = cartItem;
  if (!option || !Array.isArray(selected)) {
    return '';
  }

  const translatedDict = Object.fromEntries(
    option.textile_sizes.map(({ textile_size_id: id, translated }) => [id, translated])
  );

  const summary = selected
    .filter(({ quantity }) => quantity > 0)
    .map(({ textile_size_id: id, quantity }) => `${translatedDict[id]}: ${quantity}`);

  return summary.join(', ');
};

export function getSummaryPositions(cart, messages) {
  const itemPositions = cart.items.map(cartItem => {
    const {
      applied_product_group_discount: discount = 0,
      perforation_options_selected: perforationOptionsSelected = [],
    } = cartItem;

    const perforationNetPrice = perforationOptionsSelected?.reduce((acc, { price }) => acc + price, 0) ?? 0;
    const perforationTitles = perforationOptionsSelected?.map(({ description }) => description).join(', ') ?? '';
    const textileSummaryText = textileSummary(cartItem);

    const label = cartItem.item.name;
    const isPdf = cartItem.print_type === 'pdf_only';
    const quantity = isPdf ? null : cartItem.variant ? getVariantCopyAmountValue(cartItem.variant) : null;
    const info = isPdf
      ? 'PDF Download'
      : cartItem.item.type === 'itemWebsite'
      ? 'Website'
      : perforationTitles || textileSummaryText;

    const domainHosting =
      cartItem?.item?.type === 'itemWebsite'
        ? messages.domain_hosting + ' (' + messages.annual.toLowerCase() + ')'
        : null;
    const net = getVariantNetPrice(cartItem.variant, cartItem) - discount + perforationNetPrice;

    const unit = messages.uom.piece;

    return {
      label,
      quantity,
      info,
      domain_hosting: domainHosting,
      net,
      unit,
      discount,
    };
  });

  const manualDataCheck = cart.items.reduce(
    ({ count, totalNet }, cartItem) => {
      if (!cartItem.is_manual_data_check_requested) {
        return { count, totalNet };
      }
      return {
        count: count + 1,
        totalNet: totalNet + cartItem.manual_data_check_price,
      };
    },
    { count: 0, totalNet: 0 }
  );

  const webPdf = cart.items.reduce(
    ({ count, totalNet }, cartItem) => {
      if (!cartItem.is_web_pdf_requested) {
        return { count, totalNet };
      }
      return {
        count: count + 1,
        totalNet: totalNet + cartItem.web_pdf_price,
      };
    },
    { count: 0, totalNet: 0 }
  );

  const domainHosting = cart.items.reduce(
    ({ count, totalNet }, cartItem) => {
      if (!cartItem.is_wants_domain_hosting) {
        return { count, totalNet };
      }
      return {
        count: count + 1,
        totalNet: totalNet + cartItem.domain_hosting_net_amount,
      };
    },
    { count: 0, totalNet: 0 }
  );

  return [
    ...itemPositions,
    ...(manualDataCheck.count
      ? [
          {
            label: `${manualDataCheck.count}x Man. Datenprüfung`,
            net: manualDataCheck.totalNet,
          },
        ]
      : []),
    ...(webPdf.count
      ? [
          {
            label: `${webPdf.count}x Web-PDF`,
            net: webPdf.totalNet,
          },
        ]
      : []),
    ...(domainHosting.count
      ? [
          {
            label: `${messages.domain_hosting} ( ${messages.annual.toLowerCase()} )`,
            net: domainHosting.totalNet,
          },
        ]
      : []),
  ];
}
