import { FETCH_CLIENT_ANCESTORS_FULFILLED } from 'actions/ClientsActions';

const initialState = {
  ancestors: [],
  cache: {}, // TODO - key: clientId, value: array of ancestors
};

export default function clientBreadcrumbs(state = initialState, action) {
  let ancestors;
  switch (action.type) {
    case FETCH_CLIENT_ANCESTORS_FULFILLED:
      ancestors = action.payload || [];
      return { ...state, ancestors };
  }

  return state;
}
