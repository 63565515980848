import React from 'react';
import { connect } from 'react-redux';
import { find as _find } from 'lodash';

import * as CmsEditableElements from 'components/cms/editableElements';
import * as CmsElements from 'components/cms/elements';

class CmsElement extends React.Component {
  getComponentName = componentType => {
    const componentName = ('CmsElement_' + componentType.toLowerCase()).replace(/_([a-z])/g, g => {
      return g[1].toUpperCase();
    });
    return componentName;
  };

  getComponentByName = name => {
    const { editMode } = this.props;

    if (!editMode && CmsElements[name] !== undefined) {
      return CmsElements[name];
    }

    if (CmsEditableElements[name] !== undefined) {
      return CmsEditableElements[name];
    }
  };

  render() {
    const {
      appIntl,
      type,
      children,
      index,
      editMode,
      effectsEnabled,
      textSubstitute,
      dynamicElementContent,
      dynamic_element_content: underscoreDynamicElementContent,
    } = this.props;

    const RenderComponent = this.getComponentByName(this.getComponentName(type));

    // check if we have any children for this element
    // if it's a dynamic element, children are stored in an extra prop -> dynamicElementContent
    // dynamicElementContent contains information ofr multiple languages, but we only need one
    let parseChildren = children;
    if (type === 'dynamic_element') {
      const dynamicElementLangContent = _find(dynamicElementContent || underscoreDynamicElementContent, [
        'language',
        appIntl.displayIntlForm,
      ]);
      parseChildren = (dynamicElementLangContent && dynamicElementLangContent.content) || null;
    }

    return (
      <RenderComponent {...this.props}>
        {parseChildren && parseChildren.length
          ? parseChildren.map((child, i) => {
              const elementIndex = index + '.' + i;
              const editModeProp = type === 'dynamic_element' ? false : editMode; // if it's dynamic element, then it's not editable

              return React.createElement(
                CmsElement,
                {
                  ...this.props,
                  key: i,
                  index: elementIndex,
                  type: child.type,
                  editMode: editModeProp,
                  effectsEnabled,
                  textSubstitute,
                  params: child.params.slice(),
                  // children: child.children,
                },
                child.children
              );
            })
          : null}
      </RenderComponent>
    );
  }
}
CmsElement.defaultProps = {
  editMode: false,
  effectsEnabled: true,
  addElement: () => {},
  reorderElements: () => {},
  textSubstitute: null,
};

const mapStateToProps = state => {
  return {
    user: state.login.user,
  };
};

export default connect(mapStateToProps)(CmsElement);
