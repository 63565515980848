/**
 * This reducer is not a root entry standalone reducer,
 * but is used as extension inside the /list/* state branch.
 */
import {
  GET_SUBSCRIPTION_PRICES_PENDING,
  GET_SUBSCRIPTION_PRICES_FULFILLED,
  ADD_SUBSCRIPTION_PRICE_PENDING,
  ADD_SUBSCRIPTION_PRICE_FULFILLED,
  EDIT_SUBSCRIPTION_PRICE_PENDING,
  EDIT_SUBSCRIPTION_PRICE_FULFILLED,
  DELETE_SUBSCRIPTION_PRICE_PENDING,
  DELETE_SUBSCRIPTION_PRICE_FULFILLED,
  DEACTIVATE_SUBSCRIPTION_PRICE_PENDING,
  DEACTIVATE_SUBSCRIPTION_PRICE_FULFILLED,
} from 'actions/SubscriptionPricesActions';

export default (state, { type, payload }) => {
  const { subscriptionPrices } = state;

  switch (type) {
    case GET_SUBSCRIPTION_PRICES_FULFILLED: {
      const { list } = payload;

      return { ...state, subscriptionPrices: { ...subscriptionPrices, data: { list, pending: false } } };
    }

    case GET_SUBSCRIPTION_PRICES_PENDING:
    case ADD_SUBSCRIPTION_PRICE_PENDING:
    case EDIT_SUBSCRIPTION_PRICE_PENDING:
    case DELETE_SUBSCRIPTION_PRICE_PENDING:
    case DEACTIVATE_SUBSCRIPTION_PRICE_PENDING: {
      const { data } = subscriptionPrices;

      return { ...state, subscriptionPrices: { ...subscriptionPrices, data: { ...data, pending: true } } };
    }

    case ADD_SUBSCRIPTION_PRICE_FULFILLED:
    case EDIT_SUBSCRIPTION_PRICE_FULFILLED: {
      const { data } = subscriptionPrices;
      const { list } = data;
      const { list: newList } = payload;

      const newIds = newList.map(({ id }) => id);
      const noDupes = list.filter(({ id }) => !newIds.includes(id));
      const updatedList = [...newList, ...noDupes];

      return { ...state, subscriptionPrices: { ...subscriptionPrices, data: { list: updatedList, pending: false } } };
    }

    case DELETE_SUBSCRIPTION_PRICE_FULFILLED: {
      const { data } = subscriptionPrices;
      const { list } = data;
      const { id: deletedId } = payload;

      const updatedList = list.filter(({ id }) => id !== deletedId);

      return { ...state, subscriptionPrices: { ...subscriptionPrices, data: { list: updatedList, pending: false } } };
    }

    case DEACTIVATE_SUBSCRIPTION_PRICE_FULFILLED: {
      const { data } = subscriptionPrices;
      const { list } = data;
      const { id: updatedId } = payload;

      const index = list.findIndex(({ id }) => id === updatedId);
      const updatedList = [...list];
      updatedList.splice(index, 1, payload);

      return { ...state, subscriptionPrices: { ...subscriptionPrices, data: { list: updatedList, pending: false } } };
    }
  }

  return state;
};
