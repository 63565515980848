import { forEach as _forEach, find as _find } from 'lodash';

import { emailEntityData, entityMetaStatic } from 'js/constants';

export const getQuestionTypes = messages => {
  return [
    { value: 'client_data', label: messages.client_data },
    { value: 'location', label: messages.location },
    { value: 'dropdown', label: messages.dropdown },
    { value: 'tags', label: messages.tags },
    { value: 'checkboxes', label: messages.checkboxes },
    { value: 'radio', label: messages.multiple_choice },
    { value: 'text_input', label: messages.text_input },
    { value: 'number_input', label: messages.number_input },
    { value: 'multiline_input', label: messages.multiline_input },
    { value: 'date', label: messages.date },
    { value: 'paragraph', label: messages.paragraph },
  ];
};

export const getQuestionType = (type, messages) => {
  return _find(getQuestionTypes(messages), { value: type });
};

export const getQuestionTypeIcon = questionType => {
  switch (questionType) {
    case 'client_data':
      return 'fal fa-user';

    case 'location':
      return 'fal fa-map-marked-alt';

    case 'dropdown':
      return 'fal fa-chevron-square-down';

    case 'tags':
      return 'fal fa-tags';

    case 'checkboxes':
      return 'fal fa-check-square';

    case 'radio':
      return 'fal fa-dot-circle';

    case 'text_input':
      return 'fal fa-font';

    case 'number_input':
      return 'fal fa-plus';

    case 'multiline_input':
      return 'fal fa-text-height';

    case 'date':
      return 'fal fa-calendar-alt';

    case 'paragraph':
      return 'fal fa-paragraph';
  }
};

export const mayQuestionHaveOptions = type => {
  const haveOptions = ['dropdown', 'tags', 'checkboxes', 'radio'];
  return haveOptions.indexOf(type) > -1;
};

export const mayQuestionHaveMultipleAnswers = type => {
  const haveMultipleAnswers = ['tags', 'checkboxes'];
  return haveMultipleAnswers.indexOf(type) > -1;
};

export const isSystemType = type => {
  const areSystemType = ['client_data', 'location'];
  return areSystemType.indexOf(type) > -1;
};

export const constructMeta = (questionnnaireData, messages) => {
  let data = {};

  _forEach(questionnnaireData.questions, (question, i) => {
    if (!isSystemType(question.type)) {
      const validate = {
        rules: '',
        messages: {},
      };
      const isMultiple = mayQuestionHaveMultipleAnswers(question.type);

      if (question.is_required) {
        validate.rules = 'required';
        validate.messages = {
          required: messages['This value should not be blank'],
        };
      }

      data[i.toString()] = {
        name: i.toString(),
        type: 'QuestionnaireAnswer',
        acl: { read: true, write: true },
        validate,
        ref: false,
        array: isMultiple,
        complexType: true,
      };
    } else {
      switch (question.type) {
        case 'client_data': {
          data = {
            ...data,
            email: { name: 'email', ...emailEntityData },
            ...entityMetaStatic['Checkout:UserData'].data,
            mobile: {
              name: 'mobile',
              type: 'string',
              acl: { read: true, write: true },
              validate: { rules: 'required', messages: { required: messages['This value should not be blank'] } },
            },
          };
          break;
        }

        case 'location': {
          const validate = {
            rules: '',
            messages: {},
          };

          if (question.is_required) {
            validate.rules = 'required';
            validate.messages = {
              required: messages['This value should not be blank'],
            };
          }

          data.location = {
            name: 'location',
            type: 'string',
            acl: { read: true, write: true },
            validate,
            ref: true,
            array: false,
            complexType: false,
          };
          break;
        }
      }
    }
  });

  return {
    Questionnaire: {
      name: 'Questionnaire',
      data,
    },
    QuestionnaireAnswer: {
      name: 'QuestionnaireAnswer',
      data: {
        question: {
          name: 'index',
          type: 'integer',
          acl: { read: true, write: true },
          validate: {
            rules: '',
          },
        },
        index: {
          name: 'index',
          type: 'integer',
          acl: { read: true, write: true },
          validate: {
            rules: '',
          },
        },
        answer: {
          name: 'answer',
          type: 'string',
          acl: { read: true, write: true },
          validate: {
            rules: '',
          },
        },
      },
    },
  };
};
