import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Grid, Row, Col, Alert } from 'react-bootstrap';

import { formStatusDispatchHelper, formReset } from 'actions/FormStatusActions';

import Data from 'components/Data';
import DocMeta from 'components/DocMeta';

import Loader from 'components/layout/Loader';
import FilledQuestionnaireForm from 'components/layout/questionnaire/FilledQuestionnaireForm';

class FilledQuestionnaire extends React.Component {
  onSubmit = formData => {
    const { match, onSubmit } = this.props;

    onSubmit(match.params.filledQuestionnaireToken, formData);
  };

  componentDidUpdate = prevProps => {
    const { data } = this.props;

    if (prevProps.data.pending && !data.pending && !data.hasError && !data.data.did_target_open) {
      this.timeout = setTimeout(this.markOpened, 5000);
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeout);
  };

  markOpened = () => {
    const { match, markOpened } = this.props;
    markOpened(match.params.filledQuestionnaireToken);
  };

  render() {
    const {
      intl: { messages },
      match,
      formStatus,
      data,
    } = this.props;

    // SEO tags for the header
    const title = messages.questionnaire + ' - ' + (!data.pending ? data.data.name : '');
    const description = '';
    const tags = [{ name: 'title', content: title }, { name: 'description', content: description }];

    return (
      <DocMeta tags={tags}>
        <div className={typeof widget !== 'undefined' && widget ? '' : 'content'}>
          <Data
            dataName="filledQuestionnaire"
            url={'/api/filledquestionnaires/' + match.params.filledQuestionnaireToken + '/token.json'}
            data={data}
          />

          {data.pending ? (
            <Loader />
          ) : (
            <Grid>
              <Row>
                <Col xs={12} className="p-t-30 p-b-50">
                  <h1>{data.data.name}</h1>

                  {data.data.filled_out ? (
                    <Alert bsStyle="success">
                      <strong>Achtung!</strong> Wir haben bereits Antworten auf diesen Fragebogen erhalten.
                    </Alert>
                  ) : (
                    <FilledQuestionnaireForm
                      questionnaireData={data.data.questionnaire}
                      formStatus={formStatus}
                      onSubmit={this.onSubmit}
                    />
                  )}
                </Col>
              </Row>
            </Grid>
          )}
        </div>
      </DocMeta>
    );
  }
}
FilledQuestionnaire.defaultProps = {};

const mapStateToProps = state => {
  return {
    data: state.data.filledQuestionnaire,
    formStatus: state.formStatus.filledQuestionnaireEdit,
    formStatusOpened: state.formStatus.filledQuestionnaireOpened,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (token, args) => {
      formStatusDispatchHelper(
        'filledQuestionnaireEdit',
        `/api/filledquestionnaires/${token}/answers.json`,
        args,
        'put',
        dispatch
      );
    },
    markOpened: token => {
      formStatusDispatchHelper(
        'filledQuestionnaireOpened',
        `/api/filledquestionnaires/${token}/opened.json`,
        {},
        'put',
        dispatch
      );
    },
    resetForm: () => {
      formReset('filledQuestionnaireEdit');
    },
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FilledQuestionnaire)
);
