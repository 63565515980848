import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedNumber } from 'react-intl';
import { Grid, Row, Col } from 'react-bootstrap';
import { animateScroll as scroll } from 'react-scroll';

import { formStatusDispatchHelper } from 'actions/FormStatusActions';
import { getPluginSettingValue } from 'helpers/whiteLabel';
import { AUTOMOTIVE_SALES_PLUGIN } from 'js/constants';

import FormErrors from 'components/forms/FormErrors';
import FormTextarea from 'components/forms/FormTextarea';

import StepFCheckoutItemList from 'components/layout/checkout/StepFCheckoutItemList';
import CheckoutSummaryPersonal from 'components/layout/checkout/CheckoutSummaryPersonal';
import CheckoutSummaryTransport from 'components/layout/checkout/CheckoutSummaryTransport';
import CheckoutSummaryCarRentalPickupLocation from 'components/layout/checkout/CheckoutSummaryCarRentalPickupLocation';
import StepFCarCashPayment from 'components/layout/checkout/StepFCarCashPayment';
import StepFCarCreditPayment from 'components/layout/checkout/StepFCarCreditPayment';
import StepFCarEscrowPayment from 'components/layout/checkout/StepFCarEscrowPayment';
import StepFCarWireTransfer from 'components/layout/checkout/StepFCarWireTransfer';
import CheckoutTotal from 'components/layout/checkout/CheckoutTotal';
import StepFLegal from 'components/layout/checkout/StepFLegal';

import PaymentOptions from 'components/ePayment/PaymentOptions';

import ModalOrderInProgress from 'components/modals/ModalOrderInProgress';
import Loader from 'components/layout/Loader';

const StepFGoNextButton = React.lazy(() => import('components/layout/checkout/StepFGoNextButton'));

// payment
class StepF extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayButtonError: false, // should we display an error message near to the "buy now" button?
      buttonErrorText: '', // what text should be displayed above the button?
      carCashPaymentValid: false,
      legalAccepted: false,
    };

    if (!props.checkout.creditCardHolder && props.checkout.userData) {
      props.setCheckoutState({
        creditCardHolder: props.checkout.userData.firstName + ' ' + props.checkout.userData.lastName,
      });
    }

    // do we expect the customer to pay a reservation fee when ordering a car?
    this.carReservationFeeEnabled =
      getPluginSettingValue(AUTOMOTIVE_SALES_PLUGIN, props.projectConfig.data, 'carReservationFeeEnabled', 'true') ===
      'true';
    this.carReservationFeeAmount = parseInt(
      getPluginSettingValue(AUTOMOTIVE_SALES_PLUGIN, props.projectConfig.data, 'carReservationFeeAmount', '1000'),
      10
    );

    this.carCashPaymentRef = React.createRef();
  }

  // if an error occurs, scroll to the top to show the error
  // also, the order now button will be red for 15 seconds
  raiseError = (buttonErrorText = '') => {
    clearTimeout(this.buttonErrorTimeout);

    this.setState({ displayButtonError: true, buttonErrorText }, () => scroll.scrollToTop());
    this.buttonErrorTimeout = setTimeout(() => {
      this.setState({ displayButtonError: false, buttonErrorText: '' });
    }, 15000);
  };

  componentDidUpdate = prevProps => {
    const { appIntl, history, formStatus } = this.props;

    if (prevProps.formStatus.pending && !formStatus.pending && !formStatus.hasError) {
      // the order is done, we may forward the user to a success page! yes yes yes
      history.push(appIntl.urlPrefix + 'orderComplete');
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.buttonErrorTimeout);
  };

  // legal textboxes are not set, we need to raise an error and tell the user to agree to legal terms
  validateLegal = () => {
    const { legalAccepted } = this.state;

    if (!legalAccepted) {
      const {
        intl: { messages },
      } = this.props;

      this.raiseError(messages.accept_terms_and_cancellation_before_continuing);
    }
  };

  onCarCashPaymentValidation = (carCashPaymentValid, raiseError = false) => {
    const {
      intl: { messages },
    } = this.props;
    const callback =
      carCashPaymentValid || !raiseError
        ? null
        : () => {
            this.raiseError(messages.car_cash_payment_data_missing_or_invalid);
          };
    this.setState({ carCashPaymentValid }, callback);
  };

  // calculate how much money the user has to pay with e-payment
  getEPaymentTotal = () => {
    const { checkout } = this.props;

    if (checkout.isCarCheckout) {
      // calculate the advance
      let total = 0;

      // count all the services and transport costs
      if (checkout.shipping !== null && checkout.shipping.cost > 0) {
        total += checkout.shipping.cost;
      }

      for (let i = 0; i < checkout.services.length; i++) {
        if (checkout.services[i].isActive && checkout.services[i].cost > 0) {
          total += checkout.services[i].cost;
        }
      }

      // make sure we charge also the pre-payment for the car (RESERVATION_FEE euro)
      if (this.carReservationFeeEnabled) {
        total += this.carReservationFeeAmount;
      }

      return total;
    }

    return checkout.total;
  };

  // set the payment method
  setPayment = (e, payment) => {
    e.preventDefault();

    const { setCheckoutState } = this.props;
    setCheckoutState({ payment });
  };

  // set the car payment method
  setCarPayment = (e, carPayment) => {
    e.preventDefault();

    const { setCheckoutState } = this.props;
    setCheckoutState({ carPayment });
  };

  // set the client remarks to the order
  setRemarks = (name, value) => {
    const { setCheckoutState } = this.props;
    setCheckoutState({ [name]: value });
  };

  // navigation action when user wants to go bback
  clickPrevious = () => {
    const { appIntl, history, checkout } = this.props;

    if (checkout.isCarRentCheckout) {
      history.push(appIntl.urlPrefix + 'checkout/personal');
    } else {
      history.push(appIntl.urlPrefix + 'checkout/shipping');
    }
  };

  getSubmitData = () => {
    const { checkout } = this.props;

    return {
      ...checkout,
    };
  };

  formValidate = () => {
    return new Promise(resolve => {
      resolve(this.getSubmitData());
    });
  };

  onLegalAccepted = legalAccepted => {
    this.setState({ legalAccepted });
  };

  render() {
    const {
      intl: { messages },
      intl,
      user,
      checkout,
      checkoutInfos,
      projectConfig,
      formStatus,
      onSubmit,
      setCheckoutState,
    } = this.props;
    const { buttonErrorText } = this.state;

    const ePaymentTotal = this.getEPaymentTotal();

    return (
      <Grid fluid>
        <div className="panel panel-transparent">
          <FormErrors formStatus={formStatus} additionalErrors={buttonErrorText ? [buttonErrorText] : undefined} />

          <Row>
            <Col xs={12} className="text-center p-t-50 p-b-50">
              <h2>{messages.only_one_step_left}</h2>
              <h4>{messages.check_if_everything_is_correct_and_provide_payment_data}</h4>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={4} className="p-t-15 p-b-15">
              <CheckoutSummaryPersonal {...this.props} className="bg-white b-rad-lg padding-15 box-shadow-small" />
            </Col>

            {!checkout.isCarRentCheckout && (
              <Col xs={12} md={4} className="p-t-15 p-b-15">
                <CheckoutSummaryTransport {...this.props} className="bg-white b-rad-lg padding-15 box-shadow-small" />
              </Col>
            )}

            {checkout.isCarRentCheckout && (
              <Col xs={12} md={4} className="p-t-15 p-b-15">
                <CheckoutSummaryCarRentalPickupLocation
                  {...this.props}
                  className="bg-white b-rad-lg padding-15 box-shadow-small"
                />
              </Col>
            )}
          </Row>

          <Row className="m-t-40">
            <Col xs={12}>
              <div className="bg-white b-rad-lg box-shadow-small">
                <div className="hidden-xs hidden-sm b-a b-thick b-white b-rad-lg no-b-rad-bottom bg-master-lightest padding-15">
                  <Row>
                    <Col md={9}>{messages.item_name}</Col>
                    <Col md={3} className="text-right">
                      {messages.price}
                    </Col>
                  </Row>
                </div>

                <div className="padding-15">
                  <StepFCheckoutItemList {...this.props} />
                </div>
              </div>
            </Col>

            <Col xs={12} md={7} mdOffset={5} className="padding-30 sm-padding-5 m-t-30">
              {/* if it's a car checkout, show the payment schedule - payment 1 information */}
              {checkout.isCarCheckout && ePaymentTotal > 0 && (
                <div>
                  <h4 className="m-b-5">{messages.your_payment_schedule}</h4>
                  {this.carReservationFeeEnabled && (
                    <React.Fragment>
                      <h5 className="no-margin">{messages.reservation_fee}</h5>
                      <p>
                        <FormattedNumber
                          value={this.carReservationFeeAmount}
                          style="currency"
                          currency={projectConfig.data.currency}
                        />
                      </p>
                    </React.Fragment>
                  )}

                  {checkout.shipping !== null && checkout.shipping.cost > 0 && (
                    <React.Fragment>
                      <h5 className="m-b-0">{messages.shipping}</h5>
                      <p>
                        <FormattedNumber
                          value={checkout.shipping.cost}
                          style="currency"
                          currency={projectConfig.data.currency}
                        />
                      </p>
                    </React.Fragment>
                  )}
                  {!!checkout.services &&
                    !!checkout.services.length &&
                    checkout.services.map(service => {
                      return service.isActive ? (
                        <React.Fragment key={service.name}>
                          <h5 className="m-b-0">{service.title}</h5>
                          <p>
                            <FormattedNumber
                              value={service.cost}
                              style="currency"
                              currency={projectConfig.data.currency}
                            />
                          </p>
                        </React.Fragment>
                      ) : null;
                    })}
                  {/* <p>
                      <FormattedNumber value={ePaymentTotal} style="currency" currency={projectConfig.data.currency} />
                    </p> */}
                </div>
              )}

              <PaymentOptions
                clientId={user.username}
                ePaymentTotal={ePaymentTotal}
                currency={projectConfig.data.currency}
              />

              {checkout.isCarCheckout && (
                <div className="m-t-25">
                  <h5>{messages.payment_2_choose_payment_method_for_the_car}</h5>
                  <p>
                    <FormattedNumber
                      value={checkout.total - ePaymentTotal}
                      style="currency"
                      currency={projectConfig.data.currency}
                    />
                  </p>

                  <ul className="list-unstyled list-inline m-l-30">
                    {/*
                        <li><a href="#carPaymentEscrow" className={'p-r-30 text-black ' + (checkout.carPayment === 'escrow' ? '' : 'hint-text ')} onClick={e => this.setCarPayment(e, 'escrow')}>
                          <i className="fas fa-exchange-alt fa-fw" /> {' '}
                          {messages.payment_escrow}
                        </a></li>
                      */}

                    {/*
                        <li><a href="#carPaymentCredit" className={'p-r-30 text-black ' + (checkout.carPayment === 'carCredit' ? '' : 'hint-text ')} onClick={e => this.setCarPayment(e, 'carCredit')}>
                          <i className="fas fa-money-bill-alt fa-fw" /> {' '}
                          {messages.payment_car_credit}
                        </a></li>
                      */}

                    {checkout.shipping && checkout.shipping.method === 'transport' ? null : (
                      <li>
                        <a
                          href="#carPaymentCash"
                          className={'p-r-30 text-black ' + (checkout.carPayment === 'cash' ? '' : 'hint-text ')}
                          onClick={e => this.setCarPayment(e, 'cash')}
                        >
                          <i className="far fa-money-bill-alt fa-fw" /> {messages.payment_cash}
                        </a>
                      </li>
                    )}

                    <li>
                      <a
                        href="#carPaymentWireTransfer"
                        className={'p-r-30 text-black ' + (checkout.carPayment === 'wireTransfer' ? '' : 'hint-text ')}
                        onClick={e => this.setCarPayment(e, 'wireTransfer')}
                      >
                        <i className="far fa-money-bill-alt fa-fw" /> {messages.payment_wire_transfer}
                      </a>
                    </li>
                  </ul>
                  <div className="bg-master-light padding-30 b-rad-lg">
                    {checkout.carPayment === 'escrow' && (
                      <StepFCarEscrowPayment checkout={checkout} setCheckoutState={setCheckoutState} />
                    )}

                    {checkout.carPayment === 'carCredit' && (
                      <StepFCarCreditPayment checkout={checkout} setCheckoutState={setCheckoutState} />
                    )}

                    {checkout.carPayment === 'cash' && (
                      <StepFCarCashPayment
                        ref={this.carCashPaymentRef}
                        intl={intl}
                        meta={this.props.meta}
                        checkout={checkout}
                        setCheckoutState={setCheckoutState}
                        updateValidation={this.onCarCashPaymentValidation}
                      />
                    )}

                    {checkout.carPayment === 'wireTransfer' && (
                      <StepFCarWireTransfer checkout={checkout} setCheckoutState={setCheckoutState} />
                    )}
                  </div>
                </div>
              )}

              <br />

              <div className="m-b-30">
                <FormTextarea
                  label={messages.order_comments_and_special_wishes}
                  name="remarks"
                  value={checkout.remarks}
                  onChange={this.setRemarks}
                />
              </div>

              <div className="m-b-30">Zusammenfassung</div>

              <div className="m-b-30">
                <CheckoutTotal isCarCheckout={checkout.isCarCheckout} checkout={checkout} />
              </div>

              <div className="m-b-20 b-t b-grey p-t-30">
                <StepFLegal
                  intl={intl}
                  checkout={checkout}
                  checkoutInfos={checkoutInfos}
                  projectConfig={projectConfig}
                  onLegalAccepted={this.onLegalAccepted}
                />
              </div>
            </Col>
          </Row>

          <div className="padding-20 sm-padding-5 sm-m-b-20 sm-m-t-20 bg-white clearfix">
            <Col md={7} mdOffset={5} className="no-padding">
              <ul className="pager wizard no-style">
                <Suspense
                  fallback={
                    <li>
                      <Loader />
                    </li>
                  }
                >
                  <StepFGoNextButton
                    intl={intl}
                    checkout={checkout}
                    projectConfig={projectConfig}
                    legalAccepted={this.state.legalAccepted}
                    validateLegal={this.validateLegal}
                    raiseError={this.raiseError}
                    displayButtonError={this.state.displayButtonError}
                    onSubmit={onSubmit}
                    formStatus={formStatus}
                    formValidate={this.formValidate}
                    pending={formStatus.pending}
                    carCashPaymentValid={this.state.carCashPaymentValid}
                    carCashPayment={this.carCashPaymentRef.current}
                  />
                </Suspense>

                <li className="previous">
                  <button className="btn btn-default btn-cons pull-right" type="button" onClick={this.clickPrevious}>
                    <span>{messages.checkout_previous}</span>
                  </button>
                </li>
              </ul>
            </Col>
          </div>
        </div>

        <ModalOrderInProgress show={formStatus.pending} />
      </Grid>
    );
  }
}
StepF.defaultProps = {};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    projectConfig: state.data.projectConfig,
    formStatus: state.formStatus.orderNew,
    meta: state.entityMeta,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSubmit: args => {
      formStatusDispatchHelper('orderNew', '/api/orders.json', args, 'post', dispatch, 1);
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(StepF)
  )
);
