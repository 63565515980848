import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import RichEditorContent from 'components/RichEditorContent';

import AbstractCmsElement from './AbstractCmsElement';

export default class CmsElementBlurb extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  render() {
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const imageClass = shown ? 'image-shown ' : 'image-not-shown-yet ';

    const imagePositionClass = this.getParamValue('imagePosition', 'top') === 'left' ? 'image-left ' : '';
    const mainColorClass = 'text-' + this.getParamValue('mainColor', 'master') + ' ';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={
          'ms-blurb-content ' +
          shownClass +
          imagePositionClass +
          mainColorClass +
          this.getResponsiveClasses() +
          this.getCssClasses()
        }
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <div class="ms-blurb-image m-b-30">
          {this.getParamValue('useImage', false) ? (
            <img
              src={this.getParamValue('imageUrl')}
              class={'img-responsive ' + imageClass}
              alt={this.getParamValue('title', '')}
            />
          ) : (
            <i
              class={imageClass + ' fa-fw ' + this.getParamValue('icon', 'far fa-check-circle')}
              style={{ color: this.getParamValue('iconColor', 'inherit') }}
            />
          )}
        </div>

        <div class="ms-blurb-container">
          <h4 class={'no-margin p-b-5 ' + mainColorClass}>{this.getParamValue('title', '')}</h4>
          <RichEditorContent content={'<p>' + this.getParamValue('content', '') + '</p>'} />
        </div>

        {this.getAppendHtml()}
      </div>
    );

    return (
      <div>
        <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
          {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
        </ScrollTrigger>
      </div>
    );
  }
}
CmsElementBlurb.defaultProps = {
  index: '0',
};
