import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedNumber } from 'react-intl';
import { Grid, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { forEach as _forEach, findIndex as _findIndex, concat as _concat, find as _find } from 'lodash';

import { cartAddItem, cartAddVariant } from 'actions/CartActions';
import { getNameInCurrentLanguage, getDescriptionInCurrentLanguage, getCartPath } from 'helpers/intl';
import { getWebFile, getThumbFile } from 'helpers/items';

import RichEditorContent from 'components/RichEditorContent';
import List from 'components/List';

import ItemVariantSelection from 'components/layout/itemDetails/ItemVariantSelection';
import ItemAttributeGroupsRender from 'components/layout/itemDetails/ItemAttributeGroupsRender';

class ItemDetailsRender extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasVariants: !!(
        props.item.data.variants &&
        props.item.data.variants.attributes &&
        props.item.data.variants.attributes.length
      ),
      selectedVariantAttributes: [],
      isVariantSelected: false,
      quantity: 1,
    };
  }

  /*
    Function returns selected Variant, when all Possible Variants (e.g. both size and color) are selected
  */
  getSelectedVariant = () => {
    let selectedVariant = null;

    const { variants } = this.props;
    const { selectedVariantAttributes } = this.state;

    for (let i = 0; i < variants.data.list.length; i++) {
      const variant = variants.data.list[i];
      let found = 0;

      _forEach(variant.attributes, variantAttribute => {
        _forEach(variantAttribute.values, variantAttributeValue => {
          if (
            _findIndex(selectedVariantAttributes, {
              attributeId: variantAttribute.id,
              valueId: variantAttributeValue.id,
            }) > -1
          ) {
            found++;
          }
        });
      });

      if (found === selectedVariantAttributes.length) {
        selectedVariant = variant;
        break;
      }
    }

    return selectedVariant;
  };

  addToCart = () => {
    if (this.props.cart.pending) {
      return;
    }

    if (this.state.hasVariants) {
      const selectedVariant = this.getSelectedVariant();

      this.props.cartAddVariant(this.state.quantity, this.props.item.data, selectedVariant);
    } else {
      this.props.cartAddItem(this.state.quantity, this.props.item.data);
    }
  };

  // user selected a variant option
  selectVariantAttribute = (attributeId, valueId) => {
    const { item } = this.props;
    const { selectedVariantAttributes } = this.state;
    let newSelectedVariantAttributes = selectedVariantAttributes.slice();

    const index = _findIndex(selectedVariantAttributes, { attributeId });
    if (index > -1) {
      newSelectedVariantAttributes.splice(index, 1, { attributeId, valueId });
    } else {
      newSelectedVariantAttributes.push({ attributeId, valueId });
    }

    this.setState({
      selectedVariantAttributes: newSelectedVariantAttributes,
      isVariantSelected: newSelectedVariantAttributes.length === item.data.variants.attributes.length ? true : false,
    });
  };

  // get all item images, including variant images
  getAllImages = () => {
    const { item, variants } = this.props;

    let images = !item.pending && item.data.assets && item.data.assets.imgs ? item.data.assets.imgs : [];

    if (!item.pending && item.data.id) {
      if (this.state.hasVariants) {
        _forEach(variants.data.list, variant => {
          if (variant.assets && variant.assets.imgs) {
            images = _concat(images, variant.assets.imgs);
          }
        });
      }
    }

    return images;
  };

  // check if user may add this item to cart
  mayAddToCart = () => {
    if (this.state.hasVariants && this.state.isVariantSelected) {
      return true;
    }

    if (!this.state.hasVariants) {
      return true;
    }

    return false;
  };

  // check if item is already in the cart
  alreadyInCart = () => {
    const { cart, item } = this.props;

    if (this.state.hasVariants && this.state.isVariantSelected) {
      const selectedVariant = this.getSelectedVariant();

      for (let i = 0; i < cart.items.length; i++) {
        if (
          cart.items[i].id === item.data.id &&
          cart.items[i].variant &&
          cart.items[i].variant.id === selectedVariant.id
        ) {
          return true;
        }
      }

      return false;
    }

    return !!_find(cart.items, ['id', item.data.id]);
  };

  // get information on missing attributes
  printMissingAttributes = () => {
    const { intl, appIntl, item } = this.props;
    const { selectedVariantAttributes } = this.state;
    const messages = intl.messages;
    let missing = [];

    _forEach(item.data.variants.attributes, attribute => {
      if (_findIndex(selectedVariantAttributes, { attributeId: attribute.id }) === -1) {
        missing.push(attribute);
      }
    });

    let message = [];
    message.push(messages.please_select + ' ');
    for (let i = 0; i < missing.length; i++) {
      if (i) {
        if (i + 1 === missing.length) {
          message.push(messages.enum_last_glue);
        } else {
          message.push(messages.enum_many_glue);
        }
      }

      message.push(getNameInCurrentLanguage(missing[i].name, appIntl));
    }

    message.push(messages.enum_end);

    return message;
  };

  printOrderButton = () => {
    const {
      intl: { messages },
      cart,
    } = this.props;

    if (this.mayAddToCart()) {
      if (this.alreadyInCart()) {
        return (
          <OverlayTrigger placement="top" overlay={<Tooltip>{messages.item_already_in_cart}</Tooltip>}>
            <Button class="bold" bsStyle="primary" bsSize="lg" onClick={() => this.props.history.push(getCartPath())}>
              <i class="fa fa-shopping-cart m-r-10" /> {messages.show_cart}
            </Button>
          </OverlayTrigger>
        );
      }

      return (
        <Button class="bold" onClick={this.addToCart} bsStyle="primary" bsSize="lg" disabled={cart.pending}>
          <i class={'m-r-10 fa-fw ' + (cart.pending ? 'fa fa-circle-notch fa-spin' : 'fa fa-shopping-cart')} />{' '}
          {messages.add_to_cart}
        </Button>
      );
    }

    return (
      <OverlayTrigger placement="top" overlay={<Tooltip>{this.printMissingAttributes()}</Tooltip>}>
        <Button class="bold" bsStyle="primary" bsSize="lg" disabled>
          <i class="fa fa-shopping-cart m-r-10" /> {messages.add_to_cart}
        </Button>
      </OverlayTrigger>
    );
  };

  printPriceRange = () => {
    const { projectConfig, variants } = this.props;
    const { selectedVariantAttributes } = this.state;

    let minPrice;
    let maxPrice;
    if (selectedVariantAttributes.length > 0) {
      // MinMax of selected Variants (e.g. only with color "black")
      _forEach(variants.data.list, variant => {
        let found = 0;
        _forEach(selectedVariantAttributes, selectedVariantAttribute => {
          _forEach(variant.attributes, variantAttribute => {
            if (selectedVariantAttribute.attributeId && variantAttribute.id) {
              const check = _findIndex(variantAttribute.values, ['id', selectedVariantAttribute.valueId]);
              if (check > -1) {
                if (!maxPrice || variant.pricing.price > maxPrice) maxPrice = variant.pricing.price;
                if (!minPrice || variant.pricing.price < minPrice) minPrice = variant.pricing.price;
              }
            }
          });
        });
      });
    } else {
      // MinMax of all Variants
      _forEach(variants.data.list, variant => {
        if (!maxPrice || variant.pricing.price > maxPrice) maxPrice = variant.pricing.price;
        if (!minPrice || variant.pricing.price < minPrice) minPrice = variant.pricing.price;
      });
    }

    // When there is no difference between min and max, display only one amount
    if (minPrice === maxPrice) {
      return (
        <h3 class="text-danger-dark no-margin">
          <FormattedNumber value={minPrice} style="currency" currency={projectConfig.data.currency} />
        </h3>
      );
    }

    return (
      <h3 class="text-danger-dark no-margin">
        <FormattedNumber value={minPrice} style="currency" currency={projectConfig.data.currency} />
        &nbsp;-&nbsp;
        <FormattedNumber value={maxPrice} style="currency" currency={projectConfig.data.currency} />
      </h3>
    );
  };

  printPrice = () => {
    const { item, webApp } = this.props;
    const { hasVariants, isVariantSelected } = this.state;

    if (hasVariants) {
      if (isVariantSelected) {
        // When all possible Variants are selected
        let selectedVariant = this.getSelectedVariant();

        return (
          <h3 class="text-danger-dark no-margin">
            <FormattedNumber value={selectedVariant.pricing.price} style="currency" currency={webApp.currency} />
          </h3>
        );
      }

      return this.printPriceRange();
    }

    // no variants -> print item price
    return (
      <h3 class="text-danger-dark no-margin">
        <FormattedNumber value={item.data.pricing.price} style="currency" currency={webApp.currency} />
      </h3>
    );
  };

  printVatShippingInfo = () => {
    const { intl, item } = this.props;
    const { hasVariants, isVariantSelected } = this.state;
    const messages = intl.messages;

    let vat = item.data.pricing.vat_rate;

    // get vat_rat of selected variant
    if (hasVariants && isVariantSelected) {
      vat = this.getSelectedVariant().pricing.vat_rate;
    }

    return (
      <p class="text-info">
        {messages.incl_short} {vat}% {messages.shopItem.vat}, {messages.shopItem.plus_shipping}
      </p>
    );
  };

  render() {
    const { intl, appIntl, item, variants } = this.props;
    const messages = intl.messages;
    const images = this.getAllImages();

    return (
      <div>
        <List noRender listName="itemVariants" url={'/api/items/' + item.data.id + '/variants.json'} list={variants} />

        {variants.pending ? null : (
          <div>
            <Grid fluid>
              <Row>
                <Col xs={12} md={4}>
                  {images.length ? (
                    <ImageGallery
                      showThumbnails
                      showPlayButton={false}
                      onScreenChange={fullscreenElement => this.setState({ isFullScreen: fullscreenElement !== null })}
                      renderItem={item => {
                        return (
                          <div
                            className="image-gallery-image"
                            style={{
                              height: this.state.isFullScreen ? '100%' : '400px',
                              width: '100%',
                              backgroundImage: 'url(' + item.original + ')',
                              backgroundSize: 'contain',
                              backgroundPosition: 'center center',
                              backgroundRepeat: 'no-repeat',
                            }}
                          >
                            <img src={item.original} style={{ visibility: 'hidden' }} alt="" />
                          </div>
                        );
                      }}
                      items={images.map(img => {
                        const imageOriginal = getWebFile(img);
                        const imageThumb = getThumbFile(img);

                        // if (imageThumb) {
                        return { original: imageOriginal.web_link, thumbnail: imageThumb.web_link };
                        // }
                      })}
                    />
                  ) : null}
                </Col>

                <Col xs={12} md={6}>
                  <h3>{item.data.name}</h3>
                  <h4 class="text-info">
                    {messages.from} {item.data.brand ? item.data.brand.name : null}
                  </h4>
                  <hr />

                  <RichEditorContent
                    content={'<div>' + getDescriptionInCurrentLanguage(item.data.description, appIntl) + '</div>'}
                  />

                  <Row>
                    <Col xs={12} md={7}>
                      <ItemVariantSelection
                        item={item.data}
                        variants={variants.data.list}
                        selectedVariantAttributes={this.state.selectedVariantAttributes}
                        selectVariantAttribute={this.selectVariantAttribute}
                      />
                    </Col>
                    <Col xs={12} md={5} class="text-right">
                      {this.printPrice()}
                      {this.printVatShippingInfo()}
                      {this.printOrderButton()}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <hr />

              <ItemAttributeGroupsRender
                attributes={item.data.attributes}
                attributeGroups={item.data.category.attribute_groups}
              />
            </Grid>
          </div>
        )}
      </div>
    );
  }
}
ItemDetailsRender.defaultProps = {};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    webApp: state.webApp,
    layout: state.layout,
    variants: state.list.itemVariants,
    projectConfig: state.data.projectConfig,
    cart: state.cart,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    cartAddItem: (quantity, item) => {
      dispatch(cartAddItem(quantity, item));
    },
    cartAddVariant: (quantity, item, variant) => {
      dispatch(cartAddVariant(quantity, item, variant));
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ItemDetailsRender)
  )
);
