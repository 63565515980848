import React from 'react';
import { FormattedNumber } from 'react-intl';

import {
  getThumbFile,
  getCarRentalDaysCount,
  calculateCarRentalPrice,
  getAllowedCarRentalMileageByDaysCountAndStartDate,
} from 'helpers/items';
import { getNetFromGross } from 'helpers/financial';

import IntlDateTime from 'components/layout/IntlDateTime';

export default class CarRentItem extends React.Component {
  getImage = item => {
    if (item.variant && item.variant.assets && item.variant.assets.imgs && item.variant.assets.imgs.length) {
      // the item is variant, the variant has own image, we should use it
      const imageThumb = getThumbFile(item.variant.assets.imgs[0]);
      return imageThumb ? imageThumb.web_link : null;
    }

    if (item && item.assets && item.assets.imgs && item.assets.imgs.length) {
      const imageThumb = getThumbFile(item.assets.imgs[0]);
      return imageThumb ? imageThumb.web_link : null;
    }

    return null;
  };

  getDetailsDescription = daysCount => {
    const {
      intl: { messages },
      checkout,
    } = this.props;

    const invoiceValues = calculateCarRentalPrice(checkout.items[0], daysCount, checkout.dateFrom, checkout.dateTo);

    const res = [];
    if (invoiceValues.weekdayCount) {
      res.push({
        ...checkout.items[0],
        code: 'RENTAL_WEEKDAY',
        name: messages.car_rental_weekday,
        description: checkout.items[0].name,
        quantity: invoiceValues.weekdayCount,
        grossPrice: invoiceValues.weekdayPrice,
        price: invoiceValues.weekdayPrice,
        netPrice: getNetFromGross(invoiceValues.weekdayPrice, checkout.items[0].pricing.vat_rate),
        vatRate: checkout.items[0].pricing.vat_rate,
      });
    }

    if (invoiceValues.weekendCount) {
      res.push({
        ...checkout.items[0],
        code: 'RENTAL_WEEKEND',
        name: messages.car_rental_weekend,
        description: checkout.items[0].name,
        quantity: invoiceValues.weekendCount,
        grossPrice: invoiceValues.weekendPrice,
        price: invoiceValues.weekendPrice,
        netPrice: getNetFromGross(invoiceValues.weekendPrice, checkout.items[0].pricing.vat_rate),
        vatRate: checkout.items[0].pricing.vat_rate,
      });
    }

    if (invoiceValues.weekCount) {
      res.push({
        ...checkout.items[0],
        code: 'RENTAL_WEEK',
        name: messages.car_rental_week,
        description: checkout.items[0].name,
        quantity: invoiceValues.weekCount,
        grossPrice: invoiceValues.weekPrice,
        price: invoiceValues.weekPrice,
        netPrice: getNetFromGross(invoiceValues.weekPrice, checkout.items[0].pricing.vat_rate),
        vatRate: checkout.items[0].pricing.vat_rate,
      });
    }

    if (invoiceValues.monthCount) {
      res.push({
        ...checkout.items[0],
        code: 'RENTAL_MONTH',
        name: messages.car_rental_month,
        description: checkout.items[0].name,
        quantity: invoiceValues.monthCount,
        grossPrice: invoiceValues.monthPrice,
        price: invoiceValues.monthPrice,
        netPrice: getNetFromGross(invoiceValues.monthPrice, checkout.items[0].pricing.vat_rate),
        vatRate: checkout.items[0].pricing.vat_rate,
      });
    }

    return res;
  };

  render() {
    const {
      intl: { messages },
      layout,
      checkout,
      projectConfig,
      checkoutInfos,
    } = this.props;

    const [car] = checkout.items;
    const daysCount = getCarRentalDaysCount(checkout.dateFrom, checkout.dateTo);
    const invoiceValues = calculateCarRentalPrice(car, daysCount, checkout.dateFrom, checkout.dateTo);
    const includedAllowedMileage = getAllowedCarRentalMileageByDaysCountAndStartDate(
      daysCount,
      checkout.dateFrom,
      checkout.dateTo
    );

    const imageUrl = this.getImage(car);

    const itemImage = (
      <div>
        {imageUrl ? (
          <img src={imageUrl} alt={car.name} className="img-responsive" />
        ) : (
          <div title={car.name}>
            <div class="p-t-20 p-b-20 bg-master-lightest text-center">
              <i class="fa fa-cube" />
            </div>
          </div>
        )}
      </div>
    );
    const itemDetails = (
      <div>
        <h5 className="bold m-t-0">
          {car.brand ? car.brand.name : null} {car.name}
        </h5>
        <h5>
          {messages.orderTypes.car_rent}{' '}
          <span class="hint-text">
            <IntlDateTime date={checkout.dateFrom} /> - <IntlDateTime date={checkout.dateTo} />
          </span>
        </h5>

        <h6>{messages.offered_by}</h6>
        <div className="small">
          <address>
            {checkoutInfos.data.owner_infos[0].owner.profile.company}
            <br />
            {checkoutInfos.data.owner_infos[0].owner.profile.street}{' '}
            {checkoutInfos.data.owner_infos[0].owner.profile.house_number}{' '}
            {checkoutInfos.data.owner_infos[0].owner.profile.apartment_number}
            <br />
            {checkoutInfos.data.owner_infos[0].owner.profile.zip_code},{' '}
            {checkoutInfos.data.owner_infos[0].owner.profile.city}
          </address>
        </div>

        <div>
          {!!invoiceValues.weekdayCount && <div></div>}
          {!!invoiceValues.weekendCount && <div></div>}
          {!!invoiceValues.weekCount && <div></div>}
          {!!invoiceValues.monthCount && <div></div>}
          {messages.included}: {includedAllowedMileage}km
        </div>
      </div>
    );
    const itemPrice = (
      <h5 className="bold">
        <FormattedNumber value={invoiceValues.total} style="currency" currency={projectConfig.data.currency} />
      </h5>
    );

    return layout.breakpointIndex > 1 ? (
      <tr>
        <td className="col-xs-3 v-align-middle">{itemImage}</td>
        <td className="col-xs-6 v-align-middle">{itemDetails}</td>
        <td className="col-xs-3 v-align-middle text-right">{itemPrice}</td>
      </tr>
    ) : (
      <React.Fragment>
        {itemDetails} {itemPrice}
      </React.Fragment>
    );
  }
}
