import React from 'react';
import { components } from 'react-select';
import { forEach as _forEach } from 'lodash';

class TeamTypeOption extends React.Component {
  render() {
    const { data } = this.props;
    return <components.Option {...this.props}>{data.label}</components.Option>;
  }
}

class TeamTypeValue extends React.Component {
  render() {
    const { data } = this.props;

    return <div className="Select-value">{data.label}</div>;
  }
}

const teamTypeOptionFormatter = options => {
  const result = [];
  _forEach(options.list || options, (label, value) => {
    result.push({ value, label });
  });
  return result;
};

export { TeamTypeValue, TeamTypeOption, teamTypeOptionFormatter };
