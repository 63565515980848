import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

import CmsElementCommerceProducts from 'components/cms/elements/CmsElementCommerceProducts';

class MoreItems extends React.Component {
  render() {
    const { messages, intl, appIntl, data } = this.props;

    const params = [
      { name: 'category', value: data.category_path },
      { name: 'minImageCount', value: 2 },
      { name: 'categoryId', value: data.category.id },
    ];

    const urlParams = { filter: {} };
    if (data.brand) {
      urlParams.filter.BRAND = [data.brand.name];
    }
    if (data.model) {
      urlParams.filter.MODEL = [data.model.name];
    }

    params.push({ name: 'urlParams', value: JSON.stringify(urlParams) });

    return (
      <Grid>
        <Row className="p-t-50 p-b-70 item-details-more-items">
          <Col xs={12}>
            <h2>{messages.more_vehicles_based_on_your_search}</h2>
            <CmsElementCommerceProducts intl={intl} appIntl={appIntl} index="0" params={params} />
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default MoreItems;
