import React from 'react';
import { withRouter } from 'react-router-dom';

import SummaryFilter from 'components/layout/summary/SummaryFilter';

class SummaryFilterList extends React.Component {
  constructor(props) {
    super(props);

    const dummiesCount = 6,
      minLength = 55,
      maxLength = 90;

    let dummies = [];
    for (let i = 0; i < dummiesCount; i++) {
      dummies.push(Math.random() * (maxLength - minLength) + minLength);
    }

    this.state = { dummies };
  }

  render() {
    const { location } = this.props;

    return (
      <div>
        {this.state.dummies.map((dummyWidth, i) => {
          const facet = {
            name: (
              <span class="ms-wireframe" style={{ width: '130px' }}>
                <span class="block animated-background" style={{ width: dummyWidth + '%' }}>
                  {'\u00A0'}
                </span>
              </span>
            ),
            values: [],
          };

          return <SummaryFilter facet={facet} filters={{}} location={location} key={i} />;
        })}
      </div>
    );
  }
}

export default withRouter(SummaryFilterList);
