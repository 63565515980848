import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Grid, Row, Col, Button, DropdownButton, MenuItem, Clearfix } from 'react-bootstrap';
import { isEmpty as _isEmpty, isEqual as _isEqual, keys as _keys } from 'lodash';

import { changeActivePage } from 'actions/ListActions';
import * as summaryActions from 'actions/SummaryActions';
import { setView } from 'actions/SummarySettingsActions';
import { getItemDetailsPath } from 'helpers/intl';
import {
  getWebFile,
  getBrand,
  displaySummaryItemGrossPrice,
  displaySummaryItemNetPrice,
  isInFavorites,
} from 'helpers/items';
import { parseQs, stringifyQs } from 'helpers/http';

import SummaryCategoryBreadcrumb from 'components/layout/summary/SummaryCategoryBreadcrumb';
import SummaryFilterBar from 'components/layout/summary/SummaryFilterBar';
import SummaryTags from 'components/layout/summary/SummaryTags';
import SummaryNoResults from 'components/layout/summary/SummaryNoResults';
import ItemListView from 'components/layout/summary/ItemListView';
import ItemGridView from 'components/layout/summary/ItemGridView';
import Pagination from 'components/layout/Pagination';
import SummaryItemsWireframe from 'components/layout/wireframes/SummaryItems';

import List from 'components/List';

import DocMeta from 'components/DocMeta';

class Summary extends React.Component {
  constructor(props) {
    super(props);

    const query = parseQs(props.location.search);

    let category = '';
    if (query && query.category) {
      ({ category } = query);
    } else if (props.match && props.match.params.category) {
      ({ category } = props.match.params);
    } else if (props.initialCategory) {
      category = props.initialCategory;
    }

    this.state = {
      category,
      sort: query && query.SORT ? query.SORT : 'priceAsc',
      initialised: true,
    };

    if (query && !_isEmpty(query) && !_isEqual(query, props.filters)) {
      props.setFilters(query);
      this.state.initialised = false; // we need to wait until the filters state get updated
    } else if (props.filters && !_isEmpty(props.filters) && _isEmpty(query)) {
      // if there filters set in state, use them
      props.history.replace({
        pathname: props.location.pathname,
        search: stringifyQs(props.filters),
      });
    }
  }

  getTitle = count => {
    const { list, type } = this.props;

    if (list.pending) {
      return <span>&nbsp;</span>;
    }

    if (type === 'cars') {
      return <FormattedMessage id="we_found_count_cars" values={{ count }} />;
    }

    return <FormattedMessage id="we_found_count_products" values={{ count }} />;
  };

  componentDidUpdate = prevProps => {
    const { location, match, filters, setFilters } = this.props;
    const { initialised, category } = this.state;

    if (!_isEqual(prevProps.location.search, location.search)) {
      setFilters(parseQs(location.search));
    }

    if (!initialised && !_isEqual(prevProps.filters, filters)) {
      this.setState({ initialised: true });
    }

    if (prevProps.match && prevProps.match.params && match && match.params) {
      if (prevProps.match.params.category !== match.params.cateogry && match.params.category !== category) {
        this.setState({ category: match.params.category });
      }
    }
  };

  changeSort = sort => {
    this.setState({ sort }, () => {
      const { location, history } = this.props;

      const query = parseQs(location.search);
      query.SORT = sort;
      history.push({ pathname: location.pathname, search: stringifyQs(query) });
    });
  };

  handlePagination = page => {
    // when the page changes, we need to set the current state, then dispatch new event to fetch list
    const { list, changeActivePage } = this.props;
    changeActivePage('summary', page, list.resPerPage);
  };

  generateMetaTags = () => {
    const {
      intl: { messages },
      type,
      filters,
    } = this.props;

    let title = messages.search_for + ' ';
    if (filters.BRAND && filters.BRAND.length > 0) {
      title += filters.BRAND.join(', ');
    }
    title += ' ' + (type === 'cars' ? messages.cars : messages.products);
    if (filters.DISTANCE) {
      title += ' ' + messages.within + ' ' + filters.DISTANCE + 'km';
    }
    if (filters.ADDRESS) {
      title += ' ' + messages.of + ' ' + filters.ADDRESS;
    }

    if (type === 'cars') {
      if (_keys(filters).length === 0) {
        ({ title } = messages.meta.carShop);
      }

      return [
        { name: 'title', content: title },
        { name: 'description', content: messages.meta.carShop.description },
        { itemProp: 'name', content: messages.meta.carShop.name },
        { itemProp: 'description', content: messages.meta.carShop.description },
        // { itemProp: 'image', content: "http://www.example.com/image.jpg" }
      ];
    }

    if (_keys(filters).length === 0) {
      ({ title } = messages.meta.productShop);
    }
    return [
      { name: 'title', content: title },
      { name: 'description', content: messages.meta.productShop.description },
      { itemProp: 'name', content: messages.meta.productShop.name },
      { itemProp: 'description', content: messages.meta.productShop.description },
      // { itemProp: 'image', content: "http://www.example.com/image.jpg" }
    ];
  };

  render() {
    const {
      intl: { messages },
      intl,
      appIntl,
      layout,
      type,
      list,
      facets,
      attributes,
      filters,
      summarySettings,
      projectConfig,
      setView,
    } = this.props;
    const { initialised, sort } = this.state;

    const { category } = this.state;
    const listUrlData = { category, filter: { ...filters } };
    const facetUrlData = { category /* , filter: { ..._omit(filters, ['SORT']) } */ };

    return initialised ? (
      <DocMeta tags={this.generateMetaTags()}>
        <div class={(typeof widget !== 'undefined' && widget ? '' : 'content p-b-30') + ' summary'}>
          <div class="sm-padding-10">
            <List listName="summary" url="/api/summary/list.json" urlData={listUrlData} list={list} noRender />
            <List
              listName="summaryFacets"
              url="/api/summary/facets.json"
              urlData={facetUrlData}
              list={facets}
              noRender
            />
            <List
              listName="summaryAttributes"
              url="/api/summary/attributes.json"
              urlData={facetUrlData}
              list={attributes}
              noRender
            />

            <Grid fluid>
              <SummaryCategoryBreadcrumb category={category} type={type} />

              <Row class="condensed">
                <Col xs={12}>
                  <h5 class="text-primary no-margin">{this.getTitle(list.data.count)}</h5>
                </Col>
              </Row>

              <Row class="p-t-15 p-b-10 condensed">
                <Col xs={9} md={8}>
                  <SummaryTags />
                </Col>
                <Col xs={3} md={4} class="text-right">
                  <DropdownButton
                    class="hidden-xs hidden-sm"
                    noCaret
                    pullRight
                    title={
                      <React.Fragment>
                        {messages.do_sort}: {messages.sort[sort]}
                        <i class="fa fa-chevron-down text-primary m-l-15" />
                      </React.Fragment>
                    }
                  >
                    <MenuItem onClick={() => this.changeSort('fav')}>{messages.sort.fav}</MenuItem>
                    <MenuItem onClick={() => this.changeSort('cdate')}>{messages.sort.cdate}</MenuItem>
                    <MenuItem onClick={() => this.changeSort('priceAsc')}>{messages.sort.priceAsc}</MenuItem>
                    <MenuItem onClick={() => this.changeSort('priceDesc')}>{messages.sort.priceDesc}</MenuItem>
                  </DropdownButton>

                  <DropdownButton
                    class="hidden-md hidden-lg"
                    noCaret
                    pullRight
                    title={<i class="fal fa-sort text-primary" />}
                  >
                    <MenuItem onClick={() => this.changeSort('fav')}>{messages.sort.fav}</MenuItem>
                    <MenuItem onClick={() => this.changeSort('cdate')}>{messages.sort.cdate}</MenuItem>
                    <MenuItem onClick={() => this.changeSort('priceAsc')}>{messages.sort.priceAsc}</MenuItem>
                    <MenuItem onClick={() => this.changeSort('priceDesc')}>{messages.sort.priceDesc}</MenuItem>
                  </DropdownButton>

                  <div class="btn-group input-group-md m-l-15 hidden-xs hidden-sm">
                    <Button
                      class={summarySettings.view === 'list' ? 'active' : ''}
                      bsStyle="default"
                      onClick={() => setView('list')}
                    >
                      <i class={'fa fa-bars ' + (summarySettings.view !== 'list' ? 'text-primary ' : '')} />
                    </Button>
                    <Button
                      class={summarySettings.view === 'grid' ? 'active' : ''}
                      bsStyle="default"
                      onClick={() => setView('grid')}
                    >
                      <i class={'fal fa-th-large ' + (summarySettings.view !== 'grid' ? 'text-primary ' : '')} />
                    </Button>
                  </div>
                </Col>
              </Row>

              <Clearfix>
                <SummaryFilterBar
                  type={type}
                  attributes={attributes}
                  facets={facets}
                  filters={filters}
                  location={this.props.location}
                  {...summaryActions}
                />

                <div class="summary-item-list-container">
                  {list.pending ? <SummaryItemsWireframe view={summarySettings.view} /> : null}

                  {!list.pending && list.data.count === 0 ? <SummaryNoResults type={type} /> : null}

                  <Row>
                    {list.data.list.map((item, i) => {
                      const defaultImageThumb = getWebFile(item.img);
                      const brandName = getBrand(item, appIntl);
                      const detailsUrl = getItemDetailsPath(type, item.slug) + this.props.location.search;

                      const itemProps = {
                        intl,
                        appIntl,
                        projectConfig,
                        type,
                        item,
                        imageUrl: defaultImageThumb !== undefined ? defaultImageThumb.web_link : '',
                        brandName,
                        detailsUrl,
                        displayGrossPrice: displaySummaryItemGrossPrice(intl, item.currency, item),
                        displayNetPrice: displaySummaryItemNetPrice(intl, item.currency, item),
                        history: this.props.history,
                        isFavorite: isInFavorites(this.props.favoriteItems.list, item),
                      };

                      if (summarySettings.view === 'list' && layout.breakpointIndex > 0) {
                        return <ItemListView {...itemProps} key={item.id} />;
                      }

                      const res = [
                        <Col lg={4} md={6} xs={12} key={item.id}>
                          <ItemGridView {...itemProps} />
                        </Col>,
                      ];

                      if (i && (i + 1) % 3 === 0) {
                        res.push(<Clearfix visibleLgBlock key={'c3' + item.id} />);
                      }

                      if (i && (i + 1) % 2 === 0) {
                        res.push(<Clearfix visibleMdBlock key={'c2' + item.id} />);
                      }

                      return res;
                    })}
                  </Row>
                </div>
              </Clearfix>

              <Row>
                <Col xs={12}>
                  <div class="text-center">
                    {!list.pending && (
                      <Pagination
                        count={list.data.count}
                        resPerPage={list.resPerPage}
                        activePage={list.activePage}
                        handleSelect={this.handlePagination}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      </DocMeta>
    ) : null;
  }
}
Summary.defaultProps = {
  type: 'summary',
  initialCategory: '',
};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    layout: state.layout,
    list: state.list.summary,
    facets: state.list.summaryFacets,
    attributes: state.list.summaryAttributes,
    filters: state.summary,
    summarySettings: state.summarySettings,
    projectConfig: state.data.projectConfig,
    favoriteItems: state.favoriteItems,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setFilters: filters => {
      dispatch(summaryActions.setFilters(filters));
    },
    changeActivePage: (listName, activePage, resPerPage) => {
      dispatch(changeActivePage(listName, activePage, resPerPage));
    },
    setView: view => {
      dispatch(setView(view));
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Summary)
  )
);
