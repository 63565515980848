import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedNumber } from 'react-intl';
import { Col, Clearfix, ButtonGroup, Button } from 'react-bootstrap';
import GoogleMap from 'google-map-react';

import { SHIPPING_PARCEL_COST, GOOGLE_MAPS_KEY, mapOptions } from 'js/constants';

import MapMarker from 'components/layout/MapMarker';
import WorkingHours from 'components/layout/client/WorkingHours';

import FormAutocomplete from 'components/forms/FormAutocomplete';

class StepEItemCheckoutSettings extends React.Component {
  changeShippingMethod = method => {
    const { checkout, setCheckoutState } = this.props;

    switch (method) {
      case 'pickup':
        setCheckoutState({ shipping: { ...checkout.shipping, method, cost: 0 } });
        break;

      case 'parcel':
        setCheckoutState({ shipping: { ...checkout.shipping, method, cost: SHIPPING_PARCEL_COST } });
        break;
    }
  };

  render() {
    const {
      intl: { messages },
      appIntl,
      checkout,
      projectConfig,
      setCheckoutState,
    } = this.props;

    const isParcelActive =
      checkout.shipping === null || !checkout.shipping.method || checkout.shipping.method === 'parcel';

    return (
      <div>
        <ButtonGroup vertical block>
          <Button type="button" bsSize="lg" active={isParcelActive} onClick={() => this.changeShippingMethod('parcel')}>
            <i class="fa fa-truck m-r-10" /> {messages.i_want_my_order_delivered}
            <span class="m-l-10">
              {'+ '}{' '}
              <FormattedNumber value={SHIPPING_PARCEL_COST} style="currency" currency={projectConfig.data.currency} />
            </span>
          </Button>
          <Button
            type="button"
            bsSize="lg"
            active={checkout.shipping && checkout.shipping.method === 'pickup'}
            onClick={() => this.changeShippingMethod('pickup')}
          >
            <i class="fa fa-truck m-r-10" /> {messages.i_will_pick_up_my_order}
          </Button>
        </ButtonGroup>

        {checkout.shipping && checkout.shipping.method === 'pickup' ? (
          <div class="bg-master-light padding-30 m-t-15 b-rad-lg">
            <Clearfix>
              <Col xs={12} md={7} class="no-padding">
                <p>{messages.select_location}</p>

                <div class="form-group-attached">
                  <FormAutocomplete
                    label={messages.location}
                    name="location"
                    value={checkout.locationData ? checkout.locationData.id : null}
                    returnValueObject
                    autocompleteType="whiteLabelLocation"
                    complete
                    onChange={(name, locationData) => {
                      setCheckoutState({ locationData });
                    }}
                  />
                </div>

                <p class="m-t-30 hidden-xs">{messages.we_will_inform_you_when_we_complete_order_for_pickup}</p>
              </Col>
              {checkout.locationData && checkout.locationData.id ? (
                <Col xs={12} md={5} class="p-l-20 p-r-0 sm-p-l-0">
                  <p>{messages.location}</p>

                  <h3>{checkout.locationData.name}</h3>
                  <p>
                    {checkout.locationData.street} {checkout.locationData.house_number}{' '}
                    {checkout.locationData.apartment_number ? checkout.locationData.apartment_number : null}
                    <br />
                    {checkout.locationData.zip_code} {checkout.locationData.city}
                  </p>
                  <WorkingHours data={checkout.locationData.working_week} timezone={checkout.locationData.timezone} />

                  <div style={{ height: '200px' }}>
                    <GoogleMap
                      bootstrapURLKeys={{
                        key: GOOGLE_MAPS_KEY,
                        language: appIntl.locale,
                      }}
                      defaultCenter={{
                        lat: checkout.locationData.coordinates.lat,
                        lng: checkout.locationData.coordinates.lng,
                      }}
                      center={{
                        lat: checkout.locationData.coordinates.lat,
                        lng: checkout.locationData.coordinates.lng,
                      }}
                      defaultZoom={15}
                      options={mapOptions}
                    >
                      <MapMarker
                        lat={checkout.locationData.coordinates.lat}
                        lng={checkout.locationData.coordinates.lng}
                      />
                    </GoogleMap>
                  </div>
                </Col>
              ) : null}

              <p class="m-t-30 hidden-sm hidden-md hidden-lg">
                {messages.we_will_inform_you_when_we_complete_order_for_pickup}
              </p>
            </Clearfix>
          </div>
        ) : (
          <div class="p-t-30">
            <p>{messages.we_will_ship_your_order_to_provided_address}</p>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    projectConfig: state.data.projectConfig,
  };
};

export default injectIntl(connect(mapStateToProps)(StepEItemCheckoutSettings));
