import React, { createElement, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { transform } from 'js/css-transform';
import HTML from 'html-parse-stringify';
import { Alert } from 'react-bootstrap';
import _ from 'lodash';
import he from 'he';
import { isExternal } from 'helpers/http';
import ActionButton from 'cc-editor/components/Block/ActionButton';
import GoogleMapsElement from 'cc-editor/components/Block/GoogleMapsElement';
import BackgroundElement from 'cc-editor/components/Block/BackgroundElement';
import DropdownElement from 'cc-editor/components/Block/DropdownElement';
import PreviewImageElement from './Block/PreviewImageElement';
import PreviewEditable from './Block/PreviewEditable';
import BlockContainer from './Block/BlockContainer';
import NavigationElement from './Block/NavigationElement';
import CalendarElement from './Block/CalendarElement';
import CalendarElementOptimized from './Block/CalendarElementOptimized';
import VideoElement from './Block/VideoElement';
import SocialMediaElement from './Block/SocialMediaElement';
import ImageGalleryElement from './Block/ImageGalleryElement';
import ImageGalleryElementTwo from './Block/ImageGalleryElementTwo';
import ContactFormElement from './Block/ContactFormElement';
import RSSFeedsElement from './Block/RSSFeedsElement';
import TextSlider from './Block/TextSlider';
import TextSliderTwo from './Block/TextSliderTwo';
import QRCode from './Block/QRCode';
import DraggableContainer from './Block/DraggableContainer';
import ContactFormElementNew from './Block/ContactFormElementNew';
import QRCodeWithLogo from 'cc-editor/components/Block/QRCodeWithLogo';
import Creatomate from "cc-editor/components/Block/Creatomate";

export default class PreviewBlock extends PureComponent {
  constructor(props) {
    super(props);

    this.forbiddenTags = ['iframe', 'script'];

    this.allowedAttributes = ['class', 'style', 'colspan', 'href', 'target', 'src', 'alt'];
  }

  getLinkData = ({ name, attributes }) => {
    const isLinkExternal = isExternal(attributes.href);
    // Prevents double escaping - the urls are HTML escaped on save in Wysiwyg edior and while rendering by React.
    // This generates double escaping and broken links in the end.
    const decodedHref = he.decode(attributes.href);

    return {
      name: isLinkExternal ? name : Link,
      attributes: {
        ...attributes,
        ...(isLinkExternal
          ? {
              href: decodedHref,
            }
          : {
              to: decodedHref,
            }),
      },
    };
  };

  loop = elements => {
    const { index, data, type, appIntl, rasterV, updateParam } = this.props;

    let result = [];
    let counter = 0;
    _.forEach(elements, (value, key) => {
      counter++;
      if (value.type === 'text') {
        result.push(he.decode(value.content));
      } else if (value.type === 'tag' && !_.includes(this.forbiddenTags, value.name)) {
        let { name } = value;
        let attributes = { key };

        _.forEach(value.attrs, (attr, attrKey) => {
          switch (attrKey) {
            case 'class': {
              attributes.className = attr;
              break;
            }

            case 'style': {
              if (attr) {
                try {
                  attributes[attrKey] = transform(attr);
                } catch (err) {
                  console.log('error', err);
                }
              }
              break;
            }

            case 'data-background': {
              const { data } = this.props;
              _.forEach(data.params, param => {
                if (param.name === value.attrs['data-name']) {
                  const parsetParam = HTML.parse(param.value);
                  _.forEach(parsetParam, paramContent => {
                    if (paramContent.name === 'meta') {
                      attributes.className = [`${attributes.className} ${paramContent.attrs.content}`].join();
                    }
                  });
                }
              });
              break;
            }

            default: {
              attributes[attrKey] = attr;
              break;
            }
          }
        });

        if (name === 'a') {
          ({ name, attributes } = this.getLinkData({ name, attributes }));
        } else if (name === 'html' || name === 'head' || name === 'body') {
          name = 'div';
        }

        if (name === 'img' && _.includes(attributes.className, 'editable')) {
          result.push(
            <PreviewImageElement
              key={'preview_' + data.id + '_' + counter}
              index={index}
              name="img"
              attributes={attributes}
              params={data.params}
              srcThumb={this.props.srcThumb}
            />
          );
        } else if (name === 'img' || name === 'br' || name === 'hr') {
          result.push(createElement(name, { ...attributes }));
        } else if (_.includes(attributes.className, 'block-container')) {
          result.push(
            <BlockContainer
              key={'preview_' + data.id + '_' + counter}
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              children={value.children}
              blocks={data.blocks}
              type={type}
              rasterV={rasterV}
              currentPage={this.props.currentPage}
              setSelectedColor={this.props.setSelectedColor}
              setFontSizeSettings={this.props.setFontSizeSettings}
              fontSizeSettings={this.props.fontSizeSettings}
              getColorSettings={this.props.getColorSettings}
              updateInnerBlock={this.props.updateInnerBlock}
              usedMedia={this.props.usedMedia}
            />
          );
        } else if (_.includes(attributes.className, 'ContainerDraggable')) {
          result.push(
            <DraggableContainer
              key={'preview_' + data.id + '_' + counter}
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              children={value.children}
              blocks={data.blocks}
              type={type}
              rasterV={rasterV}
              setFontSizeSettings={this.props.setFontSizeSettings}
              fontSizeSettings={this.props.fontSizeSettings}
              currentPage={this.props.currentPage}
              setSelectedColor={this.props.setSelectedColor}
              getColorSettings={this.props.getColorSettings}
              updateInnerBlock={this.props.updateInnerBlock}
              usedMedia={this.props.usedMedia}
            />
          );
        } else if (_.includes(attributes.className, 'nav')) {
          result.push(
            <NavigationElement
              key={'preview_' + data.id + '_' + counter}
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              params={data.params}
            />
          );
        } else if (_.includes(attributes.className, 'calendar')) {
          result.push(
            <CalendarElement
              key={'preview_' + data.id + '_' + counter}
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              params={data.params}
            />
          );
        } else if (_.includes(attributes.className, 'cal-optimized')) {
          result.push(
            <CalendarElementOptimized
              key={'preview_' + data.id + '_' + counter}
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              params={data.params}
            />
          );
        } else if (_.includes(attributes.className, 'video')) {
          result.push(
            <VideoElement
              key={'preview_' + data.id + '_' + counter}
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              params={data.params}
            />
          );
        } else if (_.includes(attributes.className, 'socialmedia')) {
          result.push(
            <SocialMediaElement
              key={'preview_' + data.id + '_' + counter}
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              params={data.params}
            />
          );
        } else if (_.includes(attributes.className, 'imagegallery')) {
          result.push(
            <ImageGalleryElement
              key={'preview_' + data.id + '_' + counter}
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              params={data.params}
            />
          );
        } else if (_.includes(attributes.className, 'biggallery')) {
          result.push(
            <ImageGalleryElementTwo
              key={'preview_' + data.id + '_' + counter}
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              params={data.params}
            />
          );
        } else if (_.includes(attributes.className, 'textslider')) {
          result.push(
            <TextSlider
              key={'preview_' + data.id + '_' + counter}
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              params={data.params}
            />
          );
        } else if (_.includes(attributes.className, 'slidertxtbig')) {
          result.push(
            <TextSliderTwo
              key={'preview_' + data.id + '_' + counter}
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              params={data.params}
            />
          );
        } else if (_.includes(attributes.className, 'contactform')) {
          result.push(
            <ContactFormElement
              key={'preview_' + data.id + '_' + counter}
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              params={data.params}
            />
          );
        } else if (_.includes(attributes.className, 'newform')) {
          result.push(
            <ContactFormElementNew
              key={'preview_' + data.id + '_' + counter}
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              params={data.params}
            />
          );
        } else if (_.includes(attributes.className, 'actionButton')) {
          result.push(
            <ActionButton
              key={'preview_' + data.id + '_' + counter}
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              params={data.params}
            />
          );
        } else if (_.includes(attributes.className, 'dropdownmenu')) {
          result.push(
            <DropdownElement
              key={'preview_' + data.id + '_' + counter}
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              params={data.params}
            />
          );
        } else if (_.includes(attributes.className, 'newmap')) {
          result.push(
            <GoogleMapsElement
              key={'preview_' + data.id + '_' + counter}
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              params={data.params}
            />
          );
        } else if (_.includes(attributes.className, 'rssfeeds')) {
          result.push(
            <RSSFeedsElement
              key={'preview_' + data.id + '_' + counter}
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              params={data.params}
            />
          );
        } else if (_.includes(attributes.className, 'editable')) {
          // it's a tag that the user can edit
          result.push(
            <PreviewEditable
              key={'preview_' + data.id + '_' + counter}
              index={index}
              name={name}
              attributes={attributes}
              children={value.children}
              params={data.params}
              id={data.id}
              setFontSizeSettings={this.props.setFontSizeSettings}
            />
          );
        } else if (_.includes(attributes.className, 'qr-code')) {
          // it's a tag that the user can edit
          result.push(
            <QRCode
              key={'preview_' + data.id + '_' + counter}
              index={index}
              name={name}
              attributes={attributes}
              children={value.children}
              params={data.params}
            />
          );
        } else if (_.includes(attributes.className, 'qr-logo-code')) {
          // it's a tag that the user can edit
          result.push(
            <QRCodeWithLogo
              key={'preview_' + data.id + '_' + counter}
              index={index}
              name={name}
              attributes={attributes}
              children={value.children}
              params={data.params}
            />
          );
        } else if (_.includes(attributes.className, 'backgroundOptions')) {
          result.push(
            <BackgroundElement
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              params={data.params}
              key={data.id + '_' + counter}
              block={data}
              value={value}
              currentPage={this.props.currentPage}
              setSelectedColor={this.props.setSelectedColor}
              updateParam={updateParam}
              blockContainerId={this.props.blockContainerId ?? undefined}
            />
          );
        } else if (_.includes(attributes.className, 'creatomate')) {
          result.push(
            <Creatomate
              key={'preview_' + data.id + '_' + counter}
              appIntl={appIntl}
              id={data.id}
              name={name}
              attributes={attributes}
              params={data.params}
            />
          );
        } else if (attributes.isRoot) {
          result = this.loop(value.children);
        } else {
          result.push(createElement(name, { ...attributes }, this.loop(value.children)));
        }
      }
    });

    return result;
  };

  doNothing = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  render() {
    const { data } = this.props;
    if (data && data.template) {
      try {
        return this.loop(HTML.parse('<div isRoot="true">' + data.template + '</div>'));
      } catch (e) {
        // if we cannot parse the HTML, we can show an error
        return (
          <div className="rich-editor-content">
            <Alert bsStyle="danger">{e} Incorrect HTML detected. Please check your code in the editor.</Alert>
          </div>
        );
      }
    }

    return null;
  }
}
PreviewBlock.defaultProps = {
  textSubstitute: null,
  content: null,
};
