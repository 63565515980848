import React from 'react';
import { FormattedNumber } from 'react-intl';
import { includes as _includes, forEach as _forEach } from 'lodash';

import { mapping, getHorsePower } from 'helpers/items';

import WireframeSummaryFilterList from 'components/layout/wireframes/SummaryFilterList';

import SummaryFilter from './SummaryFilter';
import SummaryFilterSlider from './SummaryFilterSlider';
import SummaryFilterLocation from './SummaryFilterLocation';
import SummaryFilterCarBrand from './SummaryFilterCarBrand';
import SummaryFilterCarModel from './SummaryFilterCarModel';
import SummaryFilterCarType from './SummaryFilterCarType';
import SummaryFilterCarEquipment from './SummaryFilterCarEquipment';
import SummaryFiltersBoolean from './SummaryFiltersBoolean';

export default class SummaryFilterList extends React.Component {
  getFilterActions = () => {
    const { setFilters, setFilter, resetFilter, removeFilter } = this.props;
    return { setFilters, setFilter, resetFilter, removeFilter };
  };

  getBooleanFilter = () => {
    const { filters, facets, location } = this.props;

    const res = [];

    _forEach(facets.data.list, facet => {
      if (facet.type && facet.type === 'boolean') {
        res.push(facet);
      }
    });

    return res.length ? (
      <SummaryFiltersBoolean
        facets={res}
        filters={filters}
        location={location}
        {...this.getFilterActions()}
        key="bool"
      />
    ) : null;
  };

  getSystemFilters = () => {
    const {
      intl: { messages },
      webApp,
      type,
      filters,
      facets,
      location,
    } = this.props;
    const res = [];

    if (type !== 'rent') {
      res.push(
        <SummaryFilterSlider
          facets={facets}
          filters={filters}
          location={location}
          {...this.getFilterActions()}
          key="price"
          title={messages.price}
          formatter={value => {
            return <FormattedNumber value={value} style="currency" currency={webApp.currency} />;
          }}
        />
      );

      if (type !== 'shop') {
        res.push(
          <SummaryFilterLocation filters={filters} location={location} {...this.getFilterActions()} key="location" />
        );
      }
    }

    return res;
  };

  render() {
    const {
      intl: { messages },
      type,
      filters,
      facets,
      location,
    } = this.props;

    const filterActions = this.getFilterActions();

    return (
      <div class="summary-filter-menu">
        {facets.data.list.map((facet, i) => {
          if (
            !_includes(
              [
                'category',
                'minPrice',
                'maxPrice',
                'minFirstRegistration',
                'maxFirstRegistration',
                'minPower',
                'maxPower',
                'minMileage',
                'maxMileage',
              ],
              facet.id
            ) &&
            facet.type !== 'boolean'
          ) {
            if (type === 'cars') {
              if (facet.id === mapping.brand) {
                return [
                  <SummaryFilterCarBrand
                    facet={facet}
                    filters={filters}
                    location={location}
                    {...filterActions}
                    key={i + 'brand'}
                  />,
                  <SummaryFilterCarModel filters={filters} {...filterActions} key={i + 'model'} />,
                ];
              }

              if (facet.id === mapping.carType) {
                return (
                  <SummaryFilterCarType
                    facet={facet}
                    filters={filters}
                    location={location}
                    {...filterActions}
                    key={i}
                  />
                );
              }

              return <SummaryFilter facet={facet} filters={filters} location={location} {...filterActions} key={i} />;
            }

            if (type === 'rent') {
              if (facet.id === mapping.brand) {
                return (
                  <SummaryFilterCarBrand
                    facet={facet}
                    filters={filters}
                    location={location}
                    {...filterActions}
                    key={i}
                  />
                );
              }

              if (facet.id === mapping.carType) {
                return (
                  <SummaryFilterCarType
                    facet={facet}
                    filters={filters}
                    location={location}
                    {...filterActions}
                    key={i}
                  />
                );
              }

              return <SummaryFilter facet={facet} filters={filters} location={location} {...filterActions} key={i} />;
            }

            if (type === 'shop') {
              if (facet.id === mapping.brand) {
                return [
                  <SummaryFilterCarBrand
                    facet={facet}
                    filters={filters}
                    location={location}
                    {...filterActions}
                    key={i + 'brand'}
                  />,
                ];
              }
            }

            return <SummaryFilter facet={facet} filters={filters} location={location} {...filterActions} key={i} />;
          }
        })}

        {type === 'cars' && !facets.pending && (
          <React.Fragment>
            <SummaryFilterCarEquipment filters={filters} location={location} {...filterActions} />
            <SummaryFilterSlider
              facets={facets}
              filters={filters}
              location={location}
              title={messages.engine}
              name="POWER"
              urlNameMin="MIN_POWER"
              urlNameMax="MAX_POWER"
              facetNameMin="minPower"
              facetNameMax="maxPower"
              formatter={value => {
                return getHorsePower(value) + ' ' + messages.car_power_unit;
              }}
              {...filterActions}
            />
            <SummaryFilterSlider
              facets={facets}
              filters={filters}
              location={location}
              useNullMin={false}
              title={messages.first_registration}
              name="FIRST_REGISTRATION"
              urlNameMin="MIN_FIRST_REG"
              urlNameMax="MAX_FIRST_REG"
              facetNameMin="minFirstRegistration"
              facetNameMax="maxFirstRegistration"
              {...filterActions}
            />
            <SummaryFilterSlider
              facets={facets}
              filters={filters}
              location={location}
              title={messages.odometer_reading}
              name="MILEAGE"
              urlNameMin="MIN_MILEAGE"
              urlNameMax="MAX_MILEAGE"
              facetNameMin="minMileage"
              facetNameMax="maxMileage"
              formatter={value => {
                return value + ' ' + messages.mileage_unit;
              }}
              {...filterActions}
            />
          </React.Fragment>
        )}

        {!facets.pending ? this.getSystemFilters() : null}

        {!facets.pending ? this.getBooleanFilter() : <WireframeSummaryFilterList />}
      </div>
    );
  }
}
