import { Validator } from '@chedri/base';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

Validator.extend('isPhoneNumber', val => {
  let number = null;
  try {
    number = phoneUtil.parseAndKeepRawInput(val, 'DE');
  } catch (err) {
    return false;
  }

  return phoneUtil.isValidNumber(number);
});

Validator.extend('isRegexPhoneNumberCountryCode', function(val) {
  // Country code check DE, AT, CH, ES
  let regexPattern = /^\+(49|41|43|34)\d+$/;
  return regexPattern.test(val);
});

export default Validator;
