import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Overlay, Popover, ButtonGroup } from 'react-bootstrap';

class SimplePrompt extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };

    this.buttonRef = React.createRef();
  }

  handleClick = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState(prevState => ({ show: !prevState.show }));
  };

  doClose = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ show: false });
  };

  doDelete = e => {
    e.preventDefault();
    e.stopPropagation();

    const { onSuccess } = this.props;
    this.setState({ show: false });
    onSuccess(e);
  };

  render() {
    const {
      intl: { messages },
      container,
      placement,
      buttonLabel,
      iconClass,
      disabled,
      bsStyle,
      bsSize,
      className,
      showLabel,
      showMessage,
    } = this.props;
    const { show } = this.state;

    return (
      <>
        <Button
          ref={this.buttonRef}
          bsSize={bsSize}
          bsStyle={bsStyle}
          className={className}
          onClick={this.handleClick}
          disabled={disabled}
        >
          <i className={iconClass || 'fas fa-trash-alt'} />
          {showLabel && <span className="hidden-xs"> {buttonLabel || messages.delete}</span>}
        </Button>

        <Overlay
          show={show}
          target={this.buttonRef.current}
          placement={placement}
          rootClose
          onHide={() => {
            this.setState({ show: false });
          }}
          container={container !== undefined ? container : undefined}
        >
          <Popover
            style={{ zIndex: 1200 }}
            id={Math.random()
              .toString(16)
              .substring(0, 2)}
          >
            <div class="text-center">{showMessage || messages.are_you_sure}</div>

            <div style={{ minWidth: '140px' }}>
              <ButtonGroup class="m-t-10" justified>
                <Button style={{ width: '49%' }} bsSize="sm" class="p-l-5 p-r-5" onClick={this.doClose}>
                  {messages.no}
                </Button>
                <Button
                  style={{ width: '49%' }}
                  bsSize="sm"
                  class="p-l-5 p-r-5"
                  bsStyle="danger"
                  onClick={this.doDelete}
                >
                  {buttonLabel || messages.delete}
                </Button>
              </ButtonGroup>
            </div>
          </Popover>
        </Overlay>
      </>
    );
  }
}
SimplePrompt.defaultProps = {
  placement: 'top',
  disabled: false,
  bsStyle: 'danger',
  bsSize: 'sm',
  className: 'btn-rounded',
  showLabel: true,
};

export default injectIntl(SimplePrompt);
