import { DOMAIN, BASENAME } from 'js/constants';

export const getFacebookOAuthUrl = (clientId, urlPrefix, state) => {
  return (
    'https://www.facebook.com/dialog/oauth?client_id=' +
    clientId +
    '&redirect_uri=' +
    DOMAIN +
    BASENAME +
    urlPrefix +
    'login/facebook&scope=email,public_profile&state=' +
    state
  );
};

export const getGoogleOAuthUrl = (clientId, urlPrefix, state) => {
  return (
    'https://accounts.google.com/o/oauth2/v2/auth?scope=email%20profile&redirect_uri=' +
    DOMAIN +
    BASENAME +
    urlPrefix +
    'login/google&response_type=code&client_id=' +
    clientId +
    '&state=' +
    state
  );
};

export const getMicrosoftOAuthUrl = (clientId, directoryId, urlPrefix, state) => {
  const url = new URL(`https://login.microsoftonline.com/${directoryId}/oauth2/v2.0/authorize`);

  const searchData = {
    scope: 'openid profile offline_access user.read',
    redirect_uri: window.location.origin + urlPrefix + 'login/microsoft',
    response_type: 'code',
    response_mode: 'query',
    client_id: clientId,
    state,
  };

  const { searchParams } = url;
  Object.entries(searchData).forEach(([key, value]) => searchParams.set(key, value));

  return url.toString();
};
