import axios from 'axios';


export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const GET_SUBSCRIPTIONS_PENDING = 'GET_SUBSCRIPTIONS_PENDING';
export const GET_SUBSCRIPTIONS_FULFILLED = 'GET_SUBSCRIPTIONS_FULFILLED';

export const GET_SUBSCRIPTIONS_FOR_USER = 'GET_SUBSCRIPTIONS_FOR_USER';
export const GET_SUBSCRIPTIONS_FOR_USER_PENDING = 'GET_SUBSCRIPTIONS_FOR_USER_PENDING';
export const GET_SUBSCRIPTIONS_FOR_USER_FULFILLED = 'GET_SUBSCRIPTIONS_FOR_USER_FULFILLED';

export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION';
export const ADD_SUBSCRIPTION_PENDING = 'ADD_SUBSCRIPTION_PENDING';
export const ADD_SUBSCRIPTION_FULFILLED = 'ADD_SUBSCRIPTION_FULFILLED';

export const EDIT_SUBSCRIPTION = 'EDIT_SUBSCRIPTION';
export const EDIT_SUBSCRIPTION_PENDING = 'EDIT_SUBSCRIPTION_PENDING';
export const EDIT_SUBSCRIPTION_FULFILLED = 'EDIT_SUBSCRIPTION_FULFILLED';

export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';
export const DELETE_SUBSCRIPTION_PENDING = 'DELETE_SUBSCRIPTION_PENDING';
export const DELETE_SUBSCRIPTION_FULFILLED = 'DELETE_SUBSCRIPTION_FULFILLED';

export const DEACTIVATE_SUBSCRIPTION = 'DEACTIVATE_SUBSCRIPTION';
export const DEACTIVATE_SUBSCRIPTION_PENDING = 'DEACTIVATE_SUBSCRIPTION_PENDING';
export const DEACTIVATE_SUBSCRIPTION_FULFILLED = 'DEACTIVATE_SUBSCRIPTION_FULFILLED';

export const SET_VARIABLES_SUBSCRIPTION = 'SET_VARIABLES_SUBSCRIPTION';
export const GET_SINGLE_SUBSCRIPTION = 'GET_SINGLE_SUBSCRIPTION';

const PREFIX = '/api/subscriptions';
const api = (verb, url, data = {}) => axios[verb](PREFIX + url, data).then(({ data }) => data);

export function getSubscriptionsR() {
  return {
    type: GET_SUBSCRIPTIONS,
    payload: api('get', '/list/subscriptions.json'),
  };
}

export function getSubscriptionsForUserR() {
  return {
    type: GET_SUBSCRIPTIONS_FOR_USER,
    payload: api('get', '/list/subscriptions/forloggedinuser.json'),
  };
}

export function getSingleSubscriptionR(id) {
  return {
    type: GET_SINGLE_SUBSCRIPTION,
    payload: api('get', `/views/${id}/subscription.json`),
  };
}

export function editSubscriptionR(id, data) {
  return {
    type: EDIT_SUBSCRIPTION,
    payload: api('put', `/subscriptions/${id}.json`, data),
  };
}

export function addSubscriptionR(data) {
  return {
    type: ADD_SUBSCRIPTION,
    payload: api('post', '/subscriptions.json', data),
  };
}

export function deleteSubscriptionR(id) {
  return {
    type: DELETE_SUBSCRIPTION,
    payload: api('delete', `/subscriptions/${id}.json`),
  };
}

export function deactivateSubscriptionR(id, sub, messages) {
  const payloadData = [];
  payloadData.messages = messages;
  return {
    type: DEACTIVATE_SUBSCRIPTION,
    payload: axios.put(`/api/subscriptions/deactivates/${id}/subscription.json`, sub).then(({ data }) => {
      payloadData.data = data;
      return payloadData;
    }),
  };
}
export function setVariablesSubscription(key, id) {
  return {
    type: SET_VARIABLES_SUBSCRIPTION,
    payload: [key, id],
  };
}
