import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Grid, Row, Col, Clearfix } from 'react-bootstrap';

import { getItemDetailsPath } from 'helpers/intl';
import {
  isCarItem,
  getWebFile,
  getBrand,
  displaySummaryItemGrossPrice,
  displaySummaryItemNetPrice,
} from 'helpers/items';
import { generateMetaTags } from 'helpers/metaTags';

import Breadcrumb from 'components/layout/Breadcrumb';
import ItemGridView from 'components/layout/summary/ItemGridView';

import DataId from 'components/DataId';
import DocMeta from 'components/DocMeta';

class Favorites extends React.Component {
  render() {
    const {
      intl: { messages },
      intl,
      appIntl,
      favoriteItems,
      projectConfig,
      webApp,
    } = this.props;

    return (
      <DocMeta tags={generateMetaTags(messages.meta.favorites)}>
        <div class={typeof widget !== 'undefined' && widget ? '' : 'content'}>
          <Grid fluid>
            <Row class="condensed">
              <Col xs={12}>
                <Breadcrumb
                  crumbs={[{ name: messages.home, link: appIntl.urlPrefix }, { name: messages.favorites, link: null }]}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <h1>{messages.favorites_list}</h1>
              </Col>
            </Row>

            {favoriteItems.count ? (
              <Row>
                {favoriteItems.list.map((fav, i) => {
                  const type = isCarItem(fav.item) ? 'cars' : 'items';

                  const defaultImageThumb = getWebFile(fav.item.img);
                  const detailsUrl = getItemDetailsPath(type, fav.item.slug);

                  const brandName = getBrand(fav.item, appIntl);

                  const res = [
                    <DataId
                      dataIdName="item"
                      dataIdId={fav.item.id}
                      url={'/api/items/' + fav.item.id + '.json'}
                      key={'d' + i}
                    />,
                    <Col lg={3} md={4} sm={6} xs={12} key={i}>
                      <ItemGridView
                        intl={intl}
                        appIntl={appIntl}
                        projectConfig={projectConfig}
                        type={type}
                        item={fav.item}
                        imageUrl={defaultImageThumb !== undefined ? defaultImageThumb.web_link : ''}
                        brandName={brandName}
                        detailsUrl={detailsUrl}
                        displayGrossPrice={displaySummaryItemGrossPrice(intl, webApp.currency, fav.item)}
                        displayNetPrice={displaySummaryItemNetPrice(intl, webApp.currency, fav.item)}
                        history={this.props.history}
                        isFavorite
                      />
                    </Col>,
                  ];

                  if (i && (i + 1) % 4 === 0) {
                    res.push(<Clearfix visibleLgBlock key={'c4' + i} />);
                  }

                  if (i && (i + 1) % 3 === 0) {
                    res.push(<Clearfix visibleMdBlock key={'c3' + i} />);
                  }

                  if (i && (i + 1) % 2 === 0) {
                    res.push(<Clearfix visibleSmBlock key={'c2' + i} />);
                  }

                  return res;
                })}
              </Row>
            ) : (
              <Row></Row>
            )}
          </Grid>
        </div>
      </DocMeta>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    webApp: state.webApp,
    favoriteItems: state.favoriteItems,
    projectConfig: state.data.projectConfig,
  };
};
const mapDispatchToProps = dispatch => {
  return {};
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Favorites)
);
