import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';
import { injectIntl } from 'react-intl';

import DocMeta from 'components/DocMeta';

import { updateActiveCms } from 'actions/LayoutActions';

import Data from 'components/Data';
import CmsPage from 'components/CmsPage';

import Loader from 'components/layout/Loader';

class Signedup extends React.Component {
  componentDidMount = () => {
    this.props.updateActiveCms('cmsSystemPageRegistrationSuccess');
  };

  componentWillUnmount = () => {
    this.props.updateActiveCms(null);
  };

  render() {
    const {
      intl: { messages },
      projectConfig,
      cmsPage,
      height,
    } = this.props;

    const tags = [
      { name: 'title', content: messages.meta.signedup.title },
      { name: 'description', content: messages.meta.signedup.description },
      { itemProp: 'name', content: messages.meta.signedup.name },
      { itemProp: 'description', content: messages.meta.signedup.description },
      { itemProp: 'image', content: 'http://www.example.com/image.jpg' },
    ];

    return (
      <React.Fragment>
        {cmsPage.data && !cmsPage.data.id && (
          <Data dataName="cmsSystemPageRegistrationSuccess" url="/api/pages/registrationsuccess.json" data={cmsPage} />
        )}

        {cmsPage.pending ? (
          <div className="content relative" style={{ height: `${height}px` }}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%)' }}>
              <Loader />
            </div>
          </div>
        ) : cmsPage.data ? (
          <CmsPage data={cmsPage.data} doUpdateActiveCms={false} />
        ) : (
          <DocMeta tags={tags}>
            <div class="no-wrap content">
              <Grid>
                <Row class="p-t-30 p-b-40">
                  <Col xs={12} md={6} lg={4} mdOffset={3} lgOffset={4}>
                    <h1>{messages.thanks_for_signing_up}</h1>

                    <Row>
                      <Col xs={12}>
                        <section class="widgit">
                          <header>
                            <h4>
                              <i class="fa fa-thumbs-up" /> {messages.confirm_your_email}
                            </h4>
                          </header>
                          <Row>
                            { /*If it is robethood*/ }
                            {projectConfig.data.id === '6234463689d0c7126367c2ce' ? (
                              <Col xs={12}>
                                <p>{messages.signed_up_gratulations_robethood.replace('%company%', projectConfig.data.name)}</p>
                                <p>{messages.check_email_for_message_from_us_robethood}</p>
                              </Col>
                              ) : (
                              <Col xs={12}>
                                <p>{messages.signed_up_gratulations.replace('%company%', projectConfig.data.name)}</p>
                                <p>{messages.check_email_for_message_from_us}</p>
                              </Col>
                            )}
                          </Row>
                        </section>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Grid>
            </div>
          </DocMeta>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    height: state.layout.height,
    cmsPage: state.data.cmsSystemPageRegistrationSuccess,
    projectConfig: state.data.projectConfig,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateActiveCms: activeCms => {
      dispatch(updateActiveCms(activeCms));
    },
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Signedup)
);
