import React from 'react';
import { Button } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';

import { elementSource, elementTarget } from './elementDragUtils';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

import FormInput from 'components/forms/FormInput';
import FormTextarea from 'components/forms/FormTextarea';

import RichEditorContent from 'components/RichEditorContent';

import LegalTextHelper from 'components/layout/LegalTextHelper';

import ModalPrivacyPolicy from 'components/modals/ModalPrivacyPolicy';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
export default class CmsElementContact extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
      showPrivacyPolicy: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  showPrivacyPolicy = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showPrivacyPolicy: true });
  };

  closePrivacyPolicy = () => {
    this.setState({ showPrivacyPolicy: false });
  };

  getPrivacyPolicyText = () => {
    const {
      intl: { messages },
    } = this.props;

    const text = messages.contact_agreement_text;
    const substitutes = {
      '{data_use}': (
        <a href="#dataUse" onClick={this.showPrivacyPolicy}>
          {messages.signup_agreement_text_data_use}
        </a>
      ),
    };

    return <LegalTextHelper text={text} substitutes={substitutes} />;
  };

  render() {
    const {
      intl: { messages },
      connectDropTarget,
      connectDragSource,
      connectDragPreview,
    } = this.props;
    const { shown, showPrivacyPolicy } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const textClass = 'text-' + this.getParamValue('mainColor', 'master');

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        className={'ms-contact ' + shownClass + this.getCssClasses()}
        style={{ ...this.getBackgroundStyle() }}
        onClick={this.showSettings}
      >
        {this.getElementStyle()}

        <div className={'ms-contact-container text-' + this.getParamValue('textAlign', 'center') + ' ' + textClass}>
          <h2 className="no-margin p-b-25">{this.getParamValue('title')}</h2>
          <RichEditorContent content={'<p>' + this.getParamValue('content', '') + '</p>'} />
        </div>

        <div className="m-t-15 m-b-15 form-group-attached">
          <FormInput value={''} onChange={this.change} label={messages.email} type="email" />
          <FormInput value={''} onChange={this.change} label={messages.client_name} />
          <FormTextarea value={''} onChange={this.change} label={messages.message} />
        </div>

        <p className={textClass}>{this.getPrivacyPolicyText()}</p>
        <div className="text-right">
          <Button
            className="call-to-action btn-animated from-left fa fa-arrow-right"
            bsStyle="primary"
            onClick={this.onClick}
          >
            <span>{messages.send}</span>
          </Button>
        </div>

        {this.getAppendHtml()}

        <ModalPrivacyPolicy show={showPrivacyPolicy} close={this.closePrivacyPolicy} />
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}
