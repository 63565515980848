import React from 'react';
import { forEach as _forEach, findIndex as _findIndex } from 'lodash';

class TableHeaderResponsive extends React.Component {
  render() {
    const { headers, visibleElements, breakpointIndex } = this.props;

    if (breakpointIndex === 0) {
      return null;
    }

    const res = [];

    _forEach(headers, (value, key) => {
      if (visibleElements === 'all' || _findIndex(visibleElements, key)) {
        res.push(
          <th key={key} className={key === 'actions' || key === 'totalDue' ? 'text-right' : ''}>
            {value}
          </th>
        );
      }
    });

    return (
      <thead>
        <tr>{res}</tr>
      </thead>
    );
  }
}
TableHeaderResponsive.defaultProps = {
  visibleElements: 'all',
};

export default TableHeaderResponsive;
