import { isPluginActive } from 'helpers/whiteLabel';
import {
  PAYMENT_PLUGIN_STRIPE_PLAIN,
  PAYMENT_PLUGIN_STRIPE_CONNECT,
  PAYMENT_PLUGIN_PAYPAL_PLUS,
  PAYMENT_NONE,
  PAYMENT_CASH,
  PAYMENT_BILL,
  PAYMENT_OPTION_BILL,
  PAYMENT_OPTION_STRIPE_PLAIN,
} from 'js/constants';

export const getStripeConfig = whiteLabelSettings => {
  let isActive = false;
  let key = null;

  if (whiteLabelSettings) {
    // check if one of the stripe plugins (connect or plain is active)
    if (
      isPluginActive(PAYMENT_PLUGIN_STRIPE_PLAIN, whiteLabelSettings) ||
      isPluginActive(PAYMENT_PLUGIN_STRIPE_CONNECT, whiteLabelSettings)
    ) {
      const { stripe_publishable_key: stripePublishableKey } = whiteLabelSettings;
      if (stripePublishableKey) {
        isActive = true;
        key = stripePublishableKey;
      }
    }
  }

  return { isActive, key };
};

export const getPaymentType = plugin => {
  switch (plugin) {
    case PAYMENT_NONE: {
      return 'none';
    }

    case PAYMENT_CASH: {
      return 'cash';
    }

    case PAYMENT_PLUGIN_STRIPE_PLAIN:
    case PAYMENT_PLUGIN_STRIPE_CONNECT: {
      return 'creditCard';
    }

    case PAYMENT_PLUGIN_PAYPAL_PLUS: {
      return 'paypal';
    }

    case PAYMENT_BILL: {
      return PAYMENT_OPTION_BILL;
    }
  }
};

/**
 * Interim patch for mis-aligned payment names.
 * Given a payment plugin name return cooresponding string value
 * as in the OrderBundle\Document\Cart::availablePaymentOptions
 *
 * @param {string} plugin uppercase plugin name
 * @returns {string} payment name as in the available payment options
 */
export const getPaymentOptionByPlugin = plugin => {
  switch (plugin) {
    case PAYMENT_BILL: {
      return PAYMENT_OPTION_BILL;
    }

    case PAYMENT_PLUGIN_STRIPE_PLAIN:
    case PAYMENT_PLUGIN_STRIPE_CONNECT: {
      return PAYMENT_OPTION_STRIPE_PLAIN;
    }

    case PAYMENT_PLUGIN_PAYPAL_PLUS: {
      return PAYMENT_PLUGIN_PAYPAL_PLUS;
    }
  }
};

export const getPaymentNameByType = type => {
  const types = {
    [PAYMENT_OPTION_BILL]: PAYMENT_BILL,
    paypal: PAYMENT_PLUGIN_PAYPAL_PLUS,
    [PAYMENT_PLUGIN_PAYPAL_PLUS]: PAYMENT_PLUGIN_PAYPAL_PLUS,
    creditCard: PAYMENT_PLUGIN_STRIPE_PLAIN, // or _STRIPE_CONNECT ?
    [PAYMENT_OPTION_STRIPE_PLAIN]: PAYMENT_PLUGIN_STRIPE_PLAIN,
    none: PAYMENT_NONE,
    cash: PAYMENT_CASH,
  };

  return types[type] ?? null;
};
