import React from 'react';
import { injectIntl } from 'react-intl';
import { Button } from 'react-bootstrap';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';

import { elementSource, elementTarget } from './elementDragUtils';

import FormInput from 'components/forms/FormInput';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

import RichEditorContent from 'components/RichEditorContent';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
class CmsElementNewsletter extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  render() {
    const {
      intl: { messages },
      connectDropTarget,
      connectDragSource,
      connectDragPreview,
    } = this.props;
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-newsletter ' + shownClass + this.getCssClasses()}
        onClick={this.showSettings}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <div
          class={
            'ms-newsletter-container text-' +
            this.getParamValue('textAlign', 'center') +
            ' text-' +
            this.getParamValue('mainColor', 'master')
          }
        >
          <h2 class="no-margin p-b-25">{this.getParamValue('title', '')}</h2>
          <RichEditorContent content={'<p>' + this.getParamValue('content', '') + '</p>'} />

          <FormInput name="email" label={messages.email} type="email" style={{ display: 'initial', width: '250px' }} />

          <div class="text-right">
            <Button
              class="call-to-action btn-animated from-left fa fa-arrow-right"
              bsStyle="primary"
              onClick={this.onClick}
            >
              <span>{messages.subscribe}</span>
            </Button>
          </div>
        </div>

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}

export default injectIntl(CmsElementNewsletter);
