import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import CmsElementQuestionnaireForm from './CmsElementQuestionnaireForm';
import AbstractCmsElement from './AbstractCmsElement';

export default class CmsElementQuestionnaire extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  render() {
    const {
      intl: { messages },
    } = this.props;
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const mainColorClass = 'text-' + this.getParamValue('mainColor', 'master') + ' ';
    const questionnaireId = this.getParamValue('questionnaireId');
    const pipelineId = this.getParamValue('pipelineId');
    const successMessage = this.getParamValue('thankYouText');
    const buttonTitle = this.getParamValue('buttonTitle');
    const leadSource = this.getParamValue('leadSource');

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={
          'ms-questionnaire-content ' + shownClass + mainColorClass + this.getResponsiveClasses() + this.getCssClasses()
        }
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        {!!questionnaireId && (
          <React.Fragment>
            <h4 class={'no-margin p-b-5 ' + mainColorClass}>{this.getParamValue('title', messages.questionnaire)}</h4>

            <CmsElementQuestionnaireForm
              questionnaireId={questionnaireId}
              pipelineId={pipelineId}
              mainColorClass={mainColorClass}
              successMessage={successMessage}
              buttonTitle={buttonTitle}
              leadSource={leadSource}
            />
          </React.Fragment>
        )}

        {this.getAppendHtml()}
      </div>
    );

    return (
      <div>
        <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
          {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
        </ScrollTrigger>
      </div>
    );
  }
}
CmsElementQuestionnaire.defaultProps = {
  index: '0',
};
