import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Clearfix, Row, Col, Label } from 'react-bootstrap';
import { find as _find } from 'lodash';

import { getNameInCurrentLanguage } from 'helpers/intl';
import {
  getThumbFile,
  getCarRentalDaysCount,
  calculateCarRentalPrice,
  getAllowedCarRentalMileageByDaysCountAndStartDate,
} from 'helpers/items';
import { getNetFromGross } from 'helpers/financial';

import IntlDateTime from 'components/layout/IntlDateTime';

class CheckoutItemList extends React.Component {
  getCarPhotos = () => {
    const { checkout } = this.props;
    const item = checkout.items[0];

    if (!item || !item.assets || !item.assets.imgs) return null;

    const photos = [];
    for (let i = 0; i < 4; i++) {
      if (item.assets.imgs[i] !== undefined) {
        const imageThumb = getThumbFile(item.assets.imgs[i]);
        const xsHidden = i > 0;

        if (imageThumb) {
          photos.push(
            <Col xs={12} md={3} xsHidden={xsHidden} smHidden={xsHidden} key={i}>
              <img src={imageThumb.web_link} class="img-responsive" alt={item.name + ' photo ' + (i + 1)} />
            </Col>
          );
        }
      }
    }

    return <Row>{photos}</Row>;
  };

  getImage = item => {
    if (item.variant && item.variant.assets && item.variant.assets.imgs && item.variant.assets.imgs.length) {
      // the item is variant, the variant has own image, we should use it
      const imageThumb = getThumbFile(item.variant.assets.imgs[0]);
      return imageThumb ? imageThumb.web_link : null;
    }

    if (item && item.assets && item.assets.imgs && item.assets.imgs.length) {
      const imageThumb = getThumbFile(item.assets.imgs[0]);
      return imageThumb ? imageThumb.web_link : null;
    }

    return null;
  };

  printAttributes = item => {
    const { appIntl } = this.props;

    const res = [];
    for (let i = 0; i < item.variants.attributes.length; i++) {
      const variantAttribute = item.variants.attributes[i];

      const attribute = _find(item.variant.attributes, ['id', variantAttribute.id]);
      if (attribute) {
        res.push(
          <Label key={i} class="m-r-10 m-b-5">
            {getNameInCurrentLanguage(attribute.name, appIntl)}:{' '}
            {getNameInCurrentLanguage(attribute.values[0].value, appIntl)}
          </Label>
        );
      }
    }

    return res;
  };

  getCarRentItems = daysCount => {
    const {
      intl: { messages },
      checkout,
    } = this.props;

    const invoiceValues = calculateCarRentalPrice(checkout.items[0], daysCount, checkout.dateFrom, checkout.dateTo);

    const res = [];
    if (invoiceValues.weekdayCount) {
      res.push({
        ...checkout.items[0],
        code: 'RENTAL_WEEKDAY',
        name: messages.car_rental_weekday,
        description: checkout.items[0].name,
        quantity: invoiceValues.weekdayCount,
        grossPrice: invoiceValues.weekdayPrice,
        price: invoiceValues.weekdayPrice,
        netPrice: getNetFromGross(invoiceValues.weekdayPrice, checkout.items[0].pricing.vat_rate),
        vatRate: checkout.items[0].pricing.vat_rate,
      });
    }

    if (invoiceValues.weekendCount) {
      res.push({
        ...checkout.items[0],
        code: 'RENTAL_WEEKEND',
        name: messages.car_rental_weekend,
        description: checkout.items[0].name,
        quantity: invoiceValues.weekendCount,
        grossPrice: invoiceValues.weekendPrice,
        price: invoiceValues.weekendPrice,
        netPrice: getNetFromGross(invoiceValues.weekendPrice, checkout.items[0].pricing.vat_rate),
        vatRate: checkout.items[0].pricing.vat_rate,
      });
    }

    if (invoiceValues.weekCount) {
      res.push({
        ...checkout.items[0],
        code: 'RENTAL_WEEK',
        name: messages.car_rental_week,
        description: checkout.items[0].name,
        quantity: invoiceValues.weekCount,
        grossPrice: invoiceValues.weekPrice,
        price: invoiceValues.weekPrice,
        netPrice: getNetFromGross(invoiceValues.weekPrice, checkout.items[0].pricing.vat_rate),
        vatRate: checkout.items[0].pricing.vat_rate,
      });
    }

    if (invoiceValues.monthCount) {
      res.push({
        ...checkout.items[0],
        code: 'RENTAL_MONTH',
        name: messages.car_rental_month,
        description: checkout.items[0].name,
        quantity: invoiceValues.monthCount,
        grossPrice: invoiceValues.monthPrice,
        price: invoiceValues.monthPrice,
        netPrice: getNetFromGross(invoiceValues.monthPrice, checkout.items[0].pricing.vat_rate),
        vatRate: checkout.items[0].pricing.vat_rate,
      });
    }

    return res;
  };

  render() {
    const {
      intl: { messages },
      checkout,
      projectConfig,
      checkoutInfos,
      showOwnerInfos,
    } = this.props;

    let car = null;
    let { items } = checkout;
    let daysCount = null;
    if (checkout.isCarRentCheckout) {
      daysCount = getCarRentalDaysCount(checkout.dateFrom, checkout.dateTo);
      items = this.getCarRentItems(daysCount);
      [car] = checkout.items;
    }

    return (
      <div>
        {!checkout.isItemCheckout && this.getCarPhotos()}

        {checkout.isCarRentCheckout ? (
          <div>
            <h5>
              {!!car.brand && car.brand.name} {car.name}
            </h5>
            <h4>
              {messages.orderTypes.car_rent}{' '}
              <span class="hint-text">
                <IntlDateTime date={checkout.dateFrom} /> - <IntlDateTime date={checkout.dateTo} />
              </span>
            </h4>
          </div>
        ) : (
          <h4>{messages.your_order}</h4>
        )}

        {checkoutInfos.data.owner_infos.map((checkoutInfo, i) => {
          const res = [];
          if (!checkout.isCarRentCheckout && showOwnerInfos) {
            res.push(
              <div key="owner-data">
                <Clearfix>
                  <h4 class="m-t-25">{messages.offered_by}</h4>

                  <div class="col-lg-12 sm-no-padding">
                    <h5 class="semi-bold m-t-0">{checkoutInfo.owner.profile?.company}</h5>
                    <address>
                      {checkoutInfo.owner.profile.street} {checkoutInfo.owner.profile.house_number}{' '}
                      {checkoutInfo.owner.profile.apartment_number}
                      <br />
                      {checkoutInfo.owner.profile.zip_code}, {checkoutInfo.owner.profile.city}
                    </address>
                  </div>
                </Clearfix>
              </div>
            );
          }

          res.push(
            <table class="table table-condensed" key="items-list">
              <tbody>
                {items.map(item => {
                  const imageUrl = this.getImage(item);

                  return item.owner.id === checkoutInfo.id ? (
                    <tr key={item.id}>
                      <td class="v-align-middle">
                        <Clearfix>
                          {checkout.isItemCheckout ? (
                            <div class="pull-left m-r-10">
                              {imageUrl ? (
                                <img style={{ width: '100px' }} src={imageUrl} alt={item.name} />
                              ) : (
                                <div style={{ width: '100px' }} title={item.name}>
                                  <div class="p-t-20 p-b-20 bg-master-lightest text-center">
                                    <i class="fa fa-cube" />
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : null}

                          <div class={'pull-left ' + (checkout.isItemCheckout ? 'm-l-10 ' : '')}>
                            <span class="font-secondary fs-11 all-caps">{item.brand.name}</span>
                            <span class="m-l-10">{item.name}</span>

                            {item.variant !== undefined ? <div class="m-t-5">{this.printAttributes(item)}</div> : null}
                          </div>
                        </Clearfix>
                      </td>
                      <td class="text-right v-align-middle">
                        <span>
                          {messages.qty} {item.quantity}
                        </span>
                      </td>
                      <td class="text-right v-align-middle">
                        <h4 class="text-primary no-margin font-secondary">
                          <FormattedNumber value={item.price} style="currency" currency={projectConfig.data.currency} />
                        </h4>
                      </td>
                    </tr>
                  ) : null;
                })}
              </tbody>
            </table>
          );

          if (checkout.isCarRentCheckout) {
            const includedAllowedMileage = getAllowedCarRentalMileageByDaysCountAndStartDate(
              daysCount,
              checkout.dateFrom,
              checkout.dateTo
            );

            res.push(
              <table class="table table-condensed" key="rent-mileage">
                <tbody>
                  <tr>
                    <td class="v-align-middle p-t-0">
                      <strong>
                        {messages.included}: {includedAllowedMileage}km
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            );
          }

          return res;
        })}

        {checkout.isCarRentCheckout && showOwnerInfos ? (
          <Clearfix>
            <h4 class="m-t-25">{messages.offered_by}</h4>

            <div class="col-lg-12 sm-no-padding">
              <h5 class="semi-bold m-t-0">{checkoutInfos.data.owner_infos[0].owner.profile?.company}</h5>
              <address>
                {checkoutInfos.data.owner_infos[0].owner.profile.street}{' '}
                {checkoutInfos.data.owner_infos[0].owner.profile.house_number}{' '}
                {checkoutInfos.data.owner_infos[0].owner.profile.apartment_number}
                <br />
                {checkoutInfos.data.owner_infos[0].owner.profile.zip_code},{' '}
                {checkoutInfos.data.owner_infos[0].owner.profile.city}
              </address>
            </div>
          </Clearfix>
        ) : null}
      </div>
    );
  }
}
CheckoutItemList.defaultProps = {
  showOwnerInfos: false,
};

export default CheckoutItemList;
