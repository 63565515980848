import React from 'react';
import { Clearfix, Col } from 'react-bootstrap';

import EntityStateUpdater from 'component/EntityStateUpdater';
import Data from 'components/Data';
import Entity from 'components/Entity';

import FormInput from 'components/forms/FormInput';
import FormAutocomplete from 'components/forms/FormAutocomplete';

import { entityMetaMapping } from 'js/constants';

class StepFCarCashPayment extends EntityStateUpdater {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      entityLoaded: false,
      entityChanged: false,
      showMoreInfo: false,
    };

    this.entity = new Entity();
    this.entity.setData(props.checkout.carCashPayment);

    const entityMetaName = 'checkoutPaymentCashAdditionalInfos';
    if (
      props.meta[entityMetaMapping[entityMetaName]] !== undefined &&
      !props.meta[entityMetaMapping[entityMetaName]].pending
    ) {
      this.entity.setMeta(props.meta, entityMetaMapping[entityMetaName]);

      this.state = {
        ...this.state,
        entityLoaded: true,
        entity: this.entity,
      };
    }
  }

  componentDidUpdate = () => {
    const { meta } = this.props;

    const entityMetaName = 'checkoutPaymentCashAdditionalInfos';

    if (
      meta[entityMetaMapping[entityMetaName]] !== undefined &&
      !meta[entityMetaMapping[entityMetaName]].pending &&
      !this.entity.getMetaLoaded()
    ) {
      this.entity.setMeta(meta, entityMetaMapping[entityMetaName]);
      if (this.entity.getDataLoaded()) {
        this.setState({ entityLoaded: true, entity: this.entity });
      }
    }
  };

  componentWillUnmount = () => {
    this.props.setCheckoutState({ carCashPayment: { ...this.state.entity.processedData } });
  };

  validate = (successCallback = null, raiseError = false) => {
    const entity = this.state.entity.validate();
    this.setState({ entity }, () => {
      if (entity.isValid) {
        if (successCallback) {
          successCallback();
        }
      }

      this.props.updateValidation(entity.isValid, raiseError);
    });

    this.props.setCheckoutState({ carCashPayment: { ...entity.processedData } });

    return entity.isValid;
  };

  onBlur = () => {
    const entity = this.state.entity.validate();
    if (!this.state.entity.isValid && entity.isValid) {
      this.validate();
    }

    this.props.updateValidation(entity.isValid);
  };

  render() {
    const {
      intl: { messages },
    } = this.props;
    const { entity, entityLoaded } = this.state;

    return (
      <Clearfix>
        <Data dataName="checkoutPaymentCashAdditionalInfos" /> {/* fetch meta data */}
        {entityLoaded ? (
          <Col xs={12} md={7} class="no-padding">
            <p>{messages.in_order_to_confirm_identity_please_complete_your_details}</p>

            <div class="form-group-attached">
              <FormInput
                label={messages.place_of_birth}
                name="placeOfBirth"
                value={entity.getPlaceOfBirth()}
                meta={entity.getPlaceOfBirthMetaInformation()}
                validation={entity.getPlaceOfBirthValidationResult()}
                onChange={this.updateEntity}
                onBlur={this.onBlur}
              />
              <FormInput
                label={messages.date_of_birth}
                name="dateOfBirth"
                value={entity.getDateOfBirth()}
                meta={entity.getDateOfBirthMetaInformation()}
                validation={entity.getDateOfBirthValidationResult()}
                onChange={this.updateEntity}
                onBlur={this.onBlur}
              />
              <FormAutocomplete
                label={messages.nationality}
                name="nationality"
                autocompleteType="country"
                value={entity.getNationality()}
                meta={entity.getNationalityMetaInformation()}
                validation={entity.getNationalityValidationResult()}
                onChange={this.updateEntity}
                onBlur={this.onBlur}
              />
            </div>
          </Col>
        ) : null}
      </Clearfix>
    );
  }
}

export default StepFCarCashPayment;
