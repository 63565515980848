import React from 'react';
import { findIndex as _findIndex, forEach as _forEach, cloneDeep as _cloneDeep } from "lodash";

export function checkIfMediaBlockExists (usedMedia, blockId, param) {

  return _findIndex(usedMedia, function (o) {
    return o.blockid === blockId && o.param === param;
  });
}

export function checkIfMediaStaysSame (usedMedia, blockId, param, key) {

  return _findIndex(usedMedia, function (o) {
    return o.key === key && o.blockid === blockId && o.param === param;
  });
}

export function checkIfMediaIsUnused (usedMedia, key) {

  return _findIndex(usedMedia, function (o) {
    return o.key === key && o.blockid === 'unused';
  });
}

export function lookUpBlockForMedia(usedMedia, block) {
  const usedMedias = [];
  _forEach(block.params, (param, i) => {
    let index = checkIfMediaBlockExists(usedMedia, block.id, param.name);
    if(index >= 0){
      usedMedias.push(index);
    }
  });
  return usedMedias;
}

export function makeMediasUnused(usedMedia, block) {
  const mediasToUnuse = lookUpBlockForMedia(usedMedia, block);
  _forEach(mediasToUnuse, (index, i) => {
    if(checkIfMediaIsUnused(usedMedia, usedMedia[index].key) < 0) {
      usedMedia[index].blockid = 'unused';
    } else {
      usedMedia.splice(index, 1);
    }
  });
  return usedMedia;
}

export function copyMediasUsed(usedMedia, block, newId) {
  const mediasToCopy = lookUpBlockForMedia(usedMedia, block);
  let copiedMedia;
  _forEach(mediasToCopy, (index, i) => {
    copiedMedia = _cloneDeep(usedMedia[index]);
    copiedMedia.blockid = newId;
    usedMedia.push(copiedMedia);
  });
  return usedMedia;
}
