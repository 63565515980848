import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';

import { elementSource, elementTarget } from './elementDragUtils';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

import RichEditorContent from 'components/RichEditorContent';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
export default class CmsElementText extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  render() {
    const { connectDropTarget, connectDragSource, connectDragPreview } = this.props;
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const content = this.getParamValue('content') || '';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-text relative ' + shownClass + this.getCssClasses()}
        onClick={this.showSettings}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <RichEditorContent
          content={'<div class="text-' + this.getParamValue('mainColor', 'master') + '">' + content + '</div>'}
        />
        {!content && (
          <div class="p-t-15 p-b-15 text-center">
            <i class="far fa-file-alt fa-4x" />
          </div>
        )}

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}
