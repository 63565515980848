/* eslint-disable import/prefer-default-export */

import chatterSaga from './chatterSaga';
import callingSaga from './callingSaga';
import notificationSaga from './notificationSaga';

const { take, put, call, spawn } = require('redux-saga/effects');
const DetectRTC = require('detectrtc');

const logged = !!(typeof global.userData !== 'undefined' && global.userData.username);

export function* initWebApp() {
  yield take('APP_INIT');

  yield spawn(chatterSaga);
  yield spawn(callingSaga);
  yield spawn(notificationSaga);

  yield put({
    type: 'WHITE_LABEL_SETTINGS_FULFILLED',
    payload: {
      data: global.whiteLabelSettings,
    },
  });
  yield call(DetectRTC.isLoaded);
  if (logged) {
    yield put({
      type: 'FETCH_CREDENTIALS_FULFILLED',
      payload: { data: global.userData },
    });
  }
}
