import React from 'react';
import {
  forEach as _forEach,
  clone as _clone,
  isPlainObject as _isPlainObject,
  isArray as _isArray,
  isFunction as _isFunction,
} from 'lodash';

import EntityGlobal from 'components/EntityGlobal';

export default class EntityStateUpdater extends React.Component {
  changeEntity = (entity, callback = null, isLoud = true, stateParams = {}) => {
    const entityName = this.getEntityName();

    const stateChangedCallback = () => {
      if (_isFunction(callback)) {
        callback();
      }
      if (_isFunction(this['change' + entityName + 'Callback'])) {
        this['change' + entityName + 'Callback']();
      }

      if (this.globalEntityName) {
        EntityGlobal.setEntity(this.globalEntityName, this.state[entityName]);
      }
    };

    const alreadyChanged = this.state[entityName + 'Changed'];

    this.setState(
      prevState => ({
        ...stateParams,
        [entityName]: entity,
        [entityName + 'Changed']: alreadyChanged || isLoud,
        key: prevState.key + 1,
      }),
      stateChangedCallback
    );
  };

  updateEntity = (propertyName, value, fieldTree = [], callback = null, isLoud = true) => {
    const entity = this.state[this.getEntityName()];

    if (_isPlainObject(value)) {
      value = entity.createValue(propertyName, _clone(fieldTree), value);
    } else if (_isArray(value)) {
      value = value.map(valueEntry => entity.createValue(propertyName, _clone(fieldTree), valueEntry));
    }

    const newEntity = { ...entity.updateValue(propertyName, value, fieldTree) };

    this.changeEntity(newEntity, callback, isLoud);
  };

  updateEntityMultiple = (propertyNames, values, fieldTrees = [], callback = null, isLoud = true) => {
    const entity = this.state[this.getEntityName()];

    let newEntity;
    _forEach(propertyNames, (propertyName, i) => {
      let value = values[i];
      const fieldTree = fieldTrees[i] !== undefined && fieldTrees[i] !== null ? fieldTrees[i] : [];

      if (_isPlainObject(value)) {
        value = entity.createValue(propertyName, _clone(fieldTree), value);
      } else if (_isArray(value)) {
        value = value.map(valueEntry => entity.createValue(propertyName, _clone(fieldTree), valueEntry));
      }

      newEntity = { ...entity.updateValue(propertyName, value, fieldTree) };
    });

    this.changeEntity(newEntity, callback, isLoud);
  };

  getEntityName = () => {
    return this.entityName !== undefined ? this.entityName : 'entity';
  };
}
