import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Clearfix } from 'react-bootstrap';
import AnimateHeight from 'react-animate-height';

import { typeIcons } from 'helpers/items';

import CarSelectableAttribute from 'components/layout/search/CarSelectableAttribute';

class SummaryFilterCarType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mouseOver: false,
      show: false,
    };
  }

  handleClick = () => {
    this.setState(prevProps => ({ show: !prevProps.show }));
  };

  getName = () => {
    return this.props.facet.name;
  };

  hasFilters = () => {
    return this.props.filters[this.getName()] !== undefined;
  };

  render() {
    const { facet } = this.props;
    const { mouseOver, show } = this.state;

    return (
      <div
        class={'summary-filter animate-all ' + (mouseOver ? 'hover ' : '') + (show ? 'active ' : '')}
        onMouseEnter={() => this.setState({ mouseOver: true })}
        onMouseLeave={() => this.setState({ mouseOver: false })}
      >
        <div class="summary-filter-title" onClick={this.handleClick}>
          <Clearfix>
            <div class="pull-left">{facet.name}</div> {/* filter name comes already with the name in proper language */}
            <div class="pull-right">
              {this.hasFilters() && !show ? (
                <i class="fal fa-check text-success" />
              ) : (
                <i class={'fal fa-chevron-' + (show ? 'up' : 'right')} />
              )}
            </div>
          </Clearfix>
        </div>

        <AnimateHeight duration={200} height={show ? 'auto' : 0}>
          <div class="car-search summary-filter-body">
            {facet.values.map((type, i) => {
              const obj = {
                name: type.name,
                icon: typeIcons[type.id] ? <i class={typeIcons[type.id]} /> : null,
              };

              return (
                <CarSelectableAttribute
                  obj={obj}
                  name={facet.name}
                  location={this.props.location}
                  history={this.props.history}
                  size="xxl"
                  key={i}
                />
              );
            })}
          </div>
        </AnimateHeight>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(SummaryFilterCarType)));
