import React, { useCallback } from 'react';
import { withWizard } from 'react-albus';
import _findIndex from 'lodash/findIndex';

function WizardNav({ wizard, onChange, errors }) {
  const { step, steps, push } = wizard;
  const currentStepIdx = _findIndex(steps, item => item.id === step.id);
  const progress = steps.length ? (currentStepIdx / (steps.length - 1)) * 100 : 0;

  return (
    <div className="bs-wizard-2">
      <div className="bs-wizard-2__steps">
        {steps.map((stepItem, idx) => (
          <WizardNavStep
            key={stepItem.id}
            step={stepItem}
            isCurrent={stepItem.id === step.id}
            isComplete={idx < currentStepIdx}
            onStep={step => (onChange ? onChange(wizard, step) : push(step.id))}
            hasError={errors[stepItem.id]}
            number={idx + 1}
          />
        ))}
      </div>
      <div className="bs-wizard-2__progress-bar">
        <div className="progress-bar" style={{ width: progress + '%' }} />
      </div>
    </div>
  );
}
WizardNav.defaultProps = {
  errors: {},
};

export default withWizard(WizardNav);

function WizardNavStep({ step, isCurrent, isComplete, onStep, hasError, number }) {
  return (
    <div
      className={`bs-wizard-2__step ${
        isCurrent ? 'bs-wizard-2__step--active' : isComplete ? 'bs-wizard-2__step--complete' : ''
      } ${hasError ? 'bs-wizard-2__step--error' : ''}`}
      onClick={() => !isCurrent && onStep(step)}
    >
      <div className="bs-wizard-2__step-point">
        <i class="bs-wizard-2__step-check far fa-check" />
        <div className="bs-wizard-2__step-nr">{number}</div>
      </div>
      <div className="bs-wizard-2__step-label fs-10 all-caps">{step.name}</div>
    </div>
  );
}
