import React from 'react';
import FormAutocomplete from "components/forms/FormAutocomplete";

class ListFilterSearchTermNumberAndSpecific extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      focused: false,
      field: '',
    };

    this.inputRef = React.createRef();
  }

  onSubmit = e => {
    e.stopPropagation();
    e.preventDefault();

    const { onSearch } = this.props;
    const { text, field } = this.state;
    onSearch(text, field);
  };

  onChange = e => {
    this.setState({ text: e.target.value });
  };

  onFocus = () => {
    this.setState({ focused: true });
  };

  onBlur = () => {
    this.setState({ focused: false });
  };

  reset = e => {
    e.preventDefault();

    this.setState({ text: '' }, () => {
      const { onSearch } = this.props;
      onSearch('');
    });
  };

  setField = (name, value) => {
    this.setState({ field: value });
  };

  render() {
    const { messages } = this.props;
    const { text, focused } = this.state;
    let options = [
      [messages.invoice_search_debtor, 'debtor'],
      [messages.invoice_search_vat, 'vat'],
      [messages.invoice_search_amount, 'sumGross'],
    ];
    return (
      <div className="search-term-number-and-specific" style={{ display: 'flex', float: 'left' }}>
        <div style={{ marginRight: '10px' }}>
          <FormAutocomplete
            onChange={(name, value) => {
              this.setField(name, value);
            }}
            value={this.state.field}
            label={messages.search_field_name}
            name="searchField"
            infoText={messages.search_field_info_text}
            options={options.map(option => {
              return { label: option[0], value: option[1] };
            })}
          />
        </div>
        <div style={{ height: '30px' }} className={`text-search-container clearfix ${focused ? 'focused ' : ''}`}>
          <form onSubmit={this.onSubmit}>
            <input
              ref={this.inputRef}
              value={text}
              type="text"
              name="searchText"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.onChange}
            />
          </form>

          {text ? (
            <a href="#clearSearchTerm" onClick={this.reset}>
              <i className="fal fa-times" />
            </a>
          ) : (
            <i className="far fa-search" />
          )}
        </div>
      </div>
    );
  }
}
ListFilterSearchTermNumberAndSpecific.defaultProps = {};

export default ListFilterSearchTermNumberAndSpecific;
