import React from 'react';
import { Clearfix } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Detector } from 'react-detect-offline';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import { updateOnlineStatus } from 'actions/AppActions';
import {
  updateBreakpoint,
  updateRetina,
  updateFocus,
  updateWidthAndHeight,
  updateHeaderHeight,
} from 'actions/LayoutActions';
import { getTasksData, getLeadsData } from "../actions/LeadAndTaskActions";
import { fetchClientNavBadges } from 'actions/NavActions';
import { getIEVersion } from 'helpers/window';
import { getNavInfos, getSidebar } from 'helpers/nav';
import { ROLE_EMPLOYEE } from 'js/constants';

import AccountSearch from 'components/layout/AccountSearch';
import Footer from 'components/layout/Footer';
import Upload from 'components/Upload';
import Data from 'components/Data';
import CookieConsent from 'components/CookieConsent';
// import ExitIntent from 'components/ExitIntent';
import Notifications from 'components/Notifications';
import ErrorBoundary from 'components/ErrorBoundary';

import CommunicationChatControlCenter from 'components/communication/ChatControlCenter';
import CommunicationCallControlCenter from 'components/communication/CallControlCenter';

import MainMenu from 'components/layout/mainMenus/MainMenu';

import may from 'js/security';
import { communicationModule } from '@chedri/base';
import Modal from "../components/Modal";
import LoginForm from "../components/forms/auth/LoginForm";
import {submitLogin, submitLoginWithoutRedirect} from "../actions/LoginActions";

class Layout extends React.Component {
  constructor(props) {
    super(props);

    const { appIntl, layout, projectConfig, user } = props;

    this.state = {
      sidebarVisible: false,
      browserSupported: true,
      navInfos: getNavInfos(appIntl, layout, projectConfig, user, this.props.tasksData, this.props.leadsData),
      updateLeadsAndTasksCounter: true
    };
  }

  detectorRender = () => {
    return null;
  };

  updateDimensions = () => {
    clearTimeout(this.heightTimeout);

    const { layout, doUpdateWidthAndHeight, updateBreakpoint, updateRetina, updateHeaderHeight } = this.props;

    const w = window;
    const d = document;
    const { documentElement } = d;
    const body = d.getElementsByTagName('body')[0];
    const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
    const height = w.innerHeight || documentElement.clientHeight || body.clientHeight;

    let isRetina = false;
    if (typeof window !== 'undefined' && window !== null) {
      const mediaQuery =
        '(-webkit-min-device-pixel-ratio: 1.25), ' +
        '(min--moz-device-pixel-ratio: 1.25), ' +
        '(-o-min-device-pixel-ratio: 5/4), ' +
        '(min-resolution: 1.25dppx)';
      if (window.devicePixelRatio > 1.25) {
        isRetina = true;
      }
      if (window.matchMedia && window.matchMedia(mediaQuery).matches) {
        isRetina = true;
      }
    }

    if (layout.height !== height || layout.width !== width) {
      this.heightTimeout = setTimeout(() => doUpdateWidthAndHeight(width, height), 200);
    }

    if (width >= 1200) {
      if (layout.breakpoint !== 'lg') {
        updateBreakpoint('lg');
      }
    } else if (width >= 1025) { //992
      if (layout.breakpoint !== 'md') {
        updateBreakpoint('md');
      }
    } else if (width >= 768) {
      if (layout.breakpoint !== 'sm') {
        updateBreakpoint('sm');
      }
    } else if (layout.breakpoint !== 'xs') {
      updateBreakpoint('xs');
    }

    if (isRetina !== layout.isRetina) {
      updateRetina(isRetina);
    }

    const header = document.getElementById('horizontal-menu');
    if (header && header.offsetHeight !== layout.headerHeight) {
      updateHeaderHeight(header.offsetHeight);
    }
  };

  updateWindowFocus = () => {
    const { updateFocus } = this.props;
    updateFocus(true);
  };

  updateWindowBlur = () => {
    const { updateFocus } = this.props;
    updateFocus(false);
  };


  componentDidMount = () => {
    const { user } = this.props;
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('focus', this.updateWindowFocus);
    window.addEventListener('blur', this.updateWindowBlur);
    this.updateDimensions();

    if (getIEVersion() <= 10) {
      this.setState({ browserSupported: false });
    }

    /* update Tasks and Leads */
    const waitForLeadsAndTasks = window.setInterval(() => {
      if(user.logged) {
        this.props.getTasksData();
        this.props.getLeadsData();
      }
    }, 3600000);

  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('focus', this.updateWindowFocus);
    window.removeEventListener('blur', this.updateWindowBlur);
    clearTimeout(this.refreshClientNavBadgesTimeout);
  };



  showSidebar = () => {
    this.setState({ sidebarVisible: true });
  };

  hideSidebar = () => {
    this.setState({ sidebarVisible: false });
  };

  getCmsPageData = () => {
    const { layout, cms } = this.props;

    if (layout.activeCms) {
      return cms[layout.activeCms];
    }

    return null;
  };

  componentDidUpdate = prevProps => {
    const { appIntl, location, history, layout, user, cookies, projectConfig, accountSearch, modals } = this.props;
    const { sidebarVisible } = this.state;

    // deal with scroll when user navigates the page
    // send event to google analytics
    if (location.pathname !== prevProps.location.pathname || location.search !== prevProps.location.search) {
      if (cookies.performance) {
        if (projectConfig.data.google_analytics_tracking_id) {
          ReactGA.pageview(location.pathname + location.search);
        }
      }

      if (cookies.targeting) {
        if (projectConfig.data.facebook_pixel_tracking_id) {
          ReactPixel.pageView();
        }
      }

      if (!modals.isOpened) {
        document.body.className = document.body.className.replace(/ ?modal-open/, '');
      }

      if (history.action === 'PUSH') {
        window.scrollTo(0, 0);
      }
    }

    if (document.body.className.indexOf('fixed-header') < 0) {
      document.body.className += ' fixed-header ';
    }

    if (layout.isRetina && document.body.className.indexOf('main-container-retina') < 0) {
      document.body.className += ' main-container-retina ';
    }

    if (sidebarVisible && document.body.className.indexOf('sidebar-open') < 0) {
      document.body.className += ' sidebar-open ';
    } else if (!sidebarVisible) {
      document.body.className = document.body.className.replace(/ ?sidebar-open/, '');
    }

    if (accountSearch.show && document.body.className.indexOf('hide-overflow-y') < 0) {
      document.body.className += ' hide-overflow-y ';
    } else if (!accountSearch.show) {
      document.body.className = document.body.className.replace(/ ?hide-overflow-y/, '');
    }

    let themed = true;
    if (themed) {
      // check if the user is an employee
      // if yes, and it is a backend page, disable the themed class
      if (may(ROLE_EMPLOYEE, user.roles) && location.pathname.indexOf(appIntl.urlPrefix + 'account') > -1) {
        themed = false;
      }
    }

    // disable theme for the checkout and cart
    if (themed && location.pathname.indexOf(appIntl.urlPrefix + 'checkout') > -1) {
      themed = false;
    }
    if (themed && location.pathname.indexOf(appIntl.urlPrefix + 'cart') > -1) {
      themed = false;
    }

    if (themed && document.body.className.indexOf('themed') < 0) {
      document.body.className += ' themed ';
    } else if (!themed) {
      document.body.className = document.body.className.replace(/ ?themed/, '');
    }

    // deal with changes to the navigation
    if (
      appIntl.locale !== prevProps.appIntl.locale || // if the locale changed
      user.logged !== prevProps.user.logged || // if the user changed the auth state
      layout.breakpointIndex !== prevProps.layout.breakpointIndex || // if the responsive breakpoint index changed
      this.props.tasksData !== prevProps.tasksData ||
      this.props.leadsData !== prevProps.leadsData
    ) {
      this.setState({ navInfos: getNavInfos(appIntl, layout, projectConfig, user, this.props.tasksData, this.props.leadsData) });
    }

  };

  onSubmit = args => {
    this.props.onSubmit(args, this.props.appIntl.urlPrefix);
  };

  closeLoginModal = () => {

  }

  render() {
    const { user, appIntl, webApp, layout, projectConfig, isChatterAgent, cookies } = this.props;
    const { sidebarVisible, browserSupported, navInfos } = this.state;
    const { messages } = appIntl.messages;

    const isEmployee = may(ROLE_EMPLOYEE, user.roles);

    // const exitIntentDisabled = user.logged ? true : false
    const cmsPageData = this.getCmsPageData();

    // what should the sidebar look like?
    const sidebar = getSidebar(layout, navInfos, sidebarVisible, this.showSidebar, this.hideSidebar, {
      isChatterAgent,
    });

    /* initialize Tasks and Leads */
    const { updateLeadsAndTasksCounter } = this.state;
    if(user.logged && updateLeadsAndTasksCounter){
      this.setState({updateLeadsAndTasksCounter: false}, () => {
        this.props.getTasksData();
        this.props.getLeadsData();
      })
    }

    /*
    <ExitIntent disabled={exitIntentDisabled}>
      Hey ho, don't go!
    </ExitIntent>
    */
    let updateNotificationClass = "";
    if(this.props.updateNotification !== undefined){
      updateNotificationClass = "main-container-update-n-c";
      if (document.body.className.indexOf('update-notification-is-on') < 0) {
        document.body.className += ' update-notification-is-on ';
      }
    }

    return projectConfig.data && projectConfig.data.id ? (
      <>
        {sidebar}

        <div
          id="main-container"
          class={'page-container ' + (sidebar ? 'with-sidebar ' : 'no-sidebar ') + (isEmployee ? 'employee ' : '') + updateNotificationClass}
        >
          <Upload />
          <Notifications />

          <MainMenu
            cmsPageData={cmsPageData}
            {...navInfos}
            sidebarVisible={sidebarVisible}
            showSidebar={this.showSidebar}
            hideSidebar={this.hideSidebar}
          />

          <div class="page-content-wrapper">
            {browserSupported ? (
              <ErrorBoundary>
                <div>{this.props.children}</div>
              </ErrorBoundary>
            ) : (
              <div className="content">
                <div className="outdated-browser">
                  <h2>{messages.outdated_browser}</h2>
                  <p>{messages.update_browser_text} </p>
                  <p>
                    <a
                      className="btn"
                      id="btnUpdateBrowser"
                      rel="noopener noreferrer"
                      href="https://bestvpn.org/outdatedbrowser/"
                    >
                      {messages.update_browser_btn_text}
                    </a>
                  </p>
                </div>
              </div>
            )}

           {(!cmsPageData || !cmsPageData.data.hide_footer) && <Footer />}
          </div>

          <Clearfix />

          {!window.location.href.includes('privacy') && !window.location.href.includes('imprint') && <CookieConsent />}
        </div>

        <AccountSearch />
        <CommunicationChatControlCenter />
        <CommunicationCallControlCenter />
        <Detector onChange={this.props.updateOnlineStatus} render={this.detectorRender} />
        {user.adhocLogout && (
          <Modal show={user.adhocLogout} className="creacheck-modal" onHide={() => this.closeLoginModal()} bsSize="sm">
            <Modal.Header>Login
            </Modal.Header>
            <Modal.Body>
              <LoginForm onSubmit={this.onSubmit} buttonTitle={messages.button_signin} />
            </Modal.Body>
          </Modal>
        )}
      </>
    ) : (
      <Data dataName="projectConfig" url="/api/whitelabelsettings.json" data={projectConfig} />
    );
  }
}

const mapStateToProps = state => {
  return {
    webApp: state.webApp,
    user: state.login.user,
    layout: state.layout,
    appIntl: state.intl,
    clientNavBadges: state.clientNavBadges,
    accountSearch: state.accountSearch,
    projectConfig: state.data.projectConfig,
    cms: {
      cmsPage: state.data.cmsPage,
      cmsSystemPageHome: state.data.cmsSystemPageHome,
      cmsSystemPageImprint: state.data.cmsSystemPageImprint,
      cmsSystemPageTermsOfService: state.data.cmsSystemPageTermsOfService,
      cmsSystemPagePrivacyPolicy: state.data.cmsSystemPagePrivacyPolicy,
      cmsSystemPageCookieUse: state.data.cmsSystemPageCookieUse,
      cmsSystemPageLogin: state.data.cmsSystemPageLogin,
      cmsSystemPageSignup: state.data.cmsSystemPageSignup,
      cmsSystemPageReservation: state.data.cmsSystemPageReservation,
      cmsSystemPageContact: state.data.cmsSystemPageContact,
      cmsSystemPageCancellationPolicy: state.data.cmsSystemPageCancellationPolicy,
      cmsSystemPageRegistartionSuccess: state.data.cmsSystemPageRegistrationSuccess,
      cmsSystemPageMarketplaceTerms: state.data.cmsSystemPageMarketplaceTerms,
      cmsSystemPageOrderProcessingContract: state.data.cmsSystemPageOrderProcessingContract,
    },
    modals: state.modals,
    cookies: state.cookies,
    isChatterAgent: communicationModule.selectors.isAgent(state),
    tasksData: state.leadAndTaskCounter.tasksData,
    leadsData: state.leadAndTaskCounter.leadsData,
    updateNotification: state.list.updates.updateNotification,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateBreakpoint: breakpoint => {
      dispatch(updateBreakpoint(breakpoint));
    },
    updateRetina: isRetina => {
      dispatch(updateRetina(isRetina));
    },
    doUpdateWidthAndHeight: (width, height) => {
      dispatch(updateWidthAndHeight(width, height));
    },
    updateHeaderHeight: headerHeight => {
      dispatch(updateHeaderHeight(headerHeight));
    },
    fetchClientNavBadges: () => {
      dispatch(fetchClientNavBadges());
    },
    updateOnlineStatus: isOnline => {
      dispatch(updateOnlineStatus(isOnline));
    },
    updateFocus: isFocused => {
      dispatch(updateFocus(isFocused));
    },
    onSubmit: (args, urlPrefix) => {
      dispatch(submitLoginWithoutRedirect(args, urlPrefix));
    },
    getTasksData: () => {
      dispatch(getTasksData());
    },
    getLeadsData: () => {
      dispatch(getLeadsData());
    },
  };
};


export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Layout)
);
