import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedNumber } from 'react-intl';

export const DEFAULT_TAX_RATE = 19; // percent todo get this from whitelabelsettings

export const getNetFromGross = (grossPrice, vatRate) => {
  if (typeof grossPrice === 'undefined') {
    grossPrice = 0
  }
  if (typeof vatRate === 'undefined') {
    vatRate = DEFAULT_TAX_RATE
  }
  return round(grossPrice / (1 + (vatRate / 100)), 2);
};

export const getGrossFromNet = (netPrice, vatRate) => {
  if (typeof netPrice === 'undefined') {
    netPrice = 0
  }
  if (typeof vatRate === 'undefined') {
    vatRate = DEFAULT_TAX_RATE
  }
  return round(netPrice * (1 + (vatRate / 100)), 2);
};

export const round = (value, exp) => {
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math.round(value);
  }

  value = +value;
  exp = +exp;

  if (Number.isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }

  // Shift
  value = value.toString().split('e');
  value = Math.round(+(value[0] + 'e' + (value[1] ? +value[1] + exp : exp)));

  // Shift back
  value = value.toString().split('e');

  return +(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp));
};

export const getCurrencySymbol = currency => {
  switch (currency) {
    case 'PLN': {
      return 'zł';
    }

    default: {
      const number = Number().toLocaleString(undefined, { style: 'currency', currency });
      return number.slice(number.lastIndexOf('0') + 1).trim();
    }
  }
};

export const getDeposit = doc => {
  let valueLabel;
  let typeLabel;
  const value = doc.deposit || (doc.getDeposit && doc.getDeposit()) || 0;
  const type = doc.deposit_type || (doc.getDepositType && doc.getDepositType()) || 'percent';

  return { valueLabel, value, typeLabel, type };
};

export const getDepositAmount = doc => {
  const deposit = getDeposit(doc);
  const totalDue = getTotalDue(doc);

  if (deposit.value) {
    switch (deposit.type) {
      case 'fixed': {
        return deposit.value;
      }

      case 'percent':
      default: {
        return round(totalDue * (deposit.value / 100), 2);
      }
    }
  }
};

// get net sum
export const getSubtotal = doc => {
  let subtotal = 0;

  (typeof doc.getPositions === 'function' ? doc.getPositions() : doc.positions).forEach(position => {
    const net_price = (position.net_price || (position.getNetPrice && position.getNetPrice()) || 0);
    const quantity = (position.quantity || (position.getQuantity && position.getQuantity()) || 1);
    subtotal += round(net_price * quantity, 2);
  });

  return round(subtotal, 2);
};

export const listVatSum = doc => {
  const vatRates = [];

  (doc.positions || doc.getPositions()).map(position => {
    const positionVatRate = (position.vat_rate || (position.getVatRate && position.getVatRate()) || null);
    if (positionVatRate >= 0) {
      if (vatRates[positionVatRate] === undefined) {
        vatRates[positionVatRate] = 0.0;
      }

      const gross_price = (position.gross_price || (position.getGrossPrice && position.getGrossPrice()) || 0);
      const net_price = (position.net_price || (position.getNetPrice && position.getNetPrice()) || 0);
      const vat = round((net_price) * (getNumericVatRate(position) / 100), 2);
      const quantity = (position.quantity || (position.getQuantity && position.getQuantity()) || 1);
      vatRates[positionVatRate] += round((gross_price - net_price) * quantity, 2);
    }
  });

  const result = [];
  const vatName = 'MwSt'; // @todo
  Object.keys(vatRates).forEach(key => {
    result.push(
      <Row key={key}>
        <Col xs={4}>
          {key !== 'null' && key + '%'} {vatName}
        </Col>
        <Col xs={8}>
          <div class="text-right">
            {key === 'null' ? (
              'n/a'
            ) : (
              <FormattedNumber
                value={round(vatRates[key], 2)}
                style="currency"
                currency={doc.currency || doc.getCurrency()}
              />
            )}
          </div>
        </Col>
      </Row>
    );
  });

  return result;
};

export const getTotalDue = doc => {
  let totalDue = 0;
  (doc.positions || doc.getPositions()).map(position => {
    const gross_price = (position.gross_price || (position.getGrossPrice && position.getGrossPrice()) || 0);
    const quantity = (position.quantity || (position.getQuantity && position.getQuantity()) || 1);
    totalDue += round(gross_price * quantity, 2);
  });

  return round(totalDue, 2);
};

export const getNumericVatRate = position => {
  const vatRate = (position.vat_rate || (position.getVatRate && position.getVatRate()) || 0);
  return vatRate;
};
