import React from 'react';
import Spinner from '../../Spinner';

function CartWizardStepBody({ children, pending }) {
  return (
    <div className="wizard-step-body">
      {children}
      {pending ? (
        <div className="wizard-step-body__pending">
          <Spinner />
        </div>
      ) : null}
    </div>
  );
}

export default CartWizardStepBody;
