import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import FormRadio from 'components/forms/FormRadio';
import { getGrossFromNet } from 'helpers/financial';
import { intlModule } from '@chedri/base';
import { injectIntl } from 'react-intl';

const PerforationOptionSelector = ({ cartItem, intl, changedCartItemValue }) => {
  const messages = useSelector(intlModule.selectors.getMessages);
  const {
    perforation_options_available: available,
    perforation_options_selected: selected = [],
    perforation_options_preselected: preselected = [],
  } = cartItem;

  if (!available?.collection?.length) {
    return null;
  }
  const { collection } = available;

  // helper array-to-object { option_id: option }
  const arrayToDictionary = (arr, propName) => Object.fromEntries(arr.map(option => [option[propName], option]));

  const selectedDict = arrayToDictionary(selected ?? [], 'option_id');
  const preselectedDict = arrayToDictionary(preselected ?? [], 'option_id');

  const formRadios = collection.map(({ option_id: optionId, name, options }) => {
    const preselectedValue = preselectedDict[optionId];
    const preselectedValueId = preselectedValue?.option_value_id;
    const isPreselected = !!preselectedValue;
    const filterOptions = ({ option_value_id: valueId }) => !isPreselected || valueId === preselectedValueId;

    const listOptions = options.filter(filterOptions).map(({ option_value_id: valueId, name, price, currency }) => {
      const grossPrice = getGrossFromNet(price, cartItem.vat);
      const priceLabel = `${messages.net}: ${intl.formatNumber(price, { style: 'currency', currency })}
        / ${messages.gross}: ${intl.formatNumber(grossPrice, { style: 'currency', currency })}`;

      return {
        value: String(valueId),
        label: `${name} ${price ? priceLabel : ''}`,
      };
    });

    const optionValueDict = arrayToDictionary(options, 'option_value_id');
    const noneOptionId = options.find(({ price }) => !price)?.option_value_id ?? '';
    const value = String(selectedDict[optionId]?.option_value_id ?? noneOptionId);
    const onChange = (_, valueId) => {
      const value = optionValueDict[valueId];

      delete selectedDict[optionId];
      const selected = [...Object.values(selectedDict), value];

      changedCartItemValue(selected, 'perforation_options_selected');
    };

    return (
      <FormRadio
        name={`perforation_option_${optionId}}`}
        label={`${name} ${isPreselected ? `[${messages.perforation.preset}]` : ''}`}
        options={listOptions}
        onChange={isPreselected ? () => {} : onChange}
        value={value}
        key={optionId}
        disabled={isPreselected}
      />
    );
  });

  return (
    <>
      <Row>
        <Col>{formRadios}</Col>
      </Row>
    </>
  );
};

export default injectIntl(PerforationOptionSelector);
