import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { showAccountSearch, hideAccountSearch } from 'actions/AccountSearchActions';

class AccountSearchLink extends React.Component {
  render() {
    const {
      intl: { messages },
      accountSearch,
      doShowAccountSearch,
      label,
    } = this.props;

    return (
      <a href="#showSearch" className="search-link" onClick={doShowAccountSearch}>
        <i className="fad fa-search" />
        {(!!label && label) || (
          <>
            {accountSearch.value ? (
              <span>
                {messages.search_for} <span className="bold">{accountSearch.value}</span>
              </span>
            ) : (
              <span>{messages.search}</span>
            )}
          </>
        )}
      </a>
    );
  }
}

const mapStateToProps = state => {
  return {
    accountSearch: state.accountSearch,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    doShowAccountSearch: (e = null) => {
      if (e) {
        e.preventDefault();
      }

      dispatch(showAccountSearch());
    },
    doHideAccountSearch: (e = null) => {
      if (e) {
        e.preventDefault();
      }

      dispatch(hideAccountSearch());
    },
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AccountSearchLink)
);
