const Info = 'info';
const Warning = 'warning';
const Error = 'error';

const Severity = {
  0: Info,
  1: Warning,
  2: Error,
  Info: 0,
  Warning: 1,
  Error: 2,
  [Info]: 0,
  [Warning]: 1,
  [Error]: 2,
  toString: severity => {
    let result = Severity[severity];
    if (typeof result === 'number') {
      result = Severity[result];
    }
    return result;
  },
};

export default Severity;
