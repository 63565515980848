import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

export default class CmsElementUploadedVideo extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  render() {
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const wrapperClass =
      'ms-uploaded-video-wrapper ms-uploaded-video-wrapper-' + this.getParamValue('videoAspectRatio', '16-9') + ' ';
    const isVideoSelected = !!this.getParamValue('videoFile');
    const isUploaded = this.getParamValue('videoUploaded') === '1';
    const videoWebMp4Url = this.getParamValue('videoWebMp4Url');
    const videoWebWebmUrl = this.getParamValue('videoWebWebmUrl');
    const playing = this.getParamValue('videoAutoplay', 'no') === 'yes';
    const imageUrl = this.getParamValue('imageUrl', '');

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={wrapperClass + shownClass + this.getResponsiveClasses() + this.getCssClasses()}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        {shown && isVideoSelected && isUploaded && (
          <div className="ms-uploaded-video-player-content">
            <video autoPlay={playing} controls poster={imageUrl || undefined}>
              <source src={videoWebMp4Url} type="video/mp4" />
              <source src={videoWebWebmUrl} type="video/webm" />
            </video>
          </div>
        )}

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementUploadedVideo.defaultProps = {
  index: '0',
};
