import React from 'react';
import { useIntl } from 'react-intl';
import { getVariantCopyAmountValue } from '../../../helpers/cart';
import Properties from '../Properties';
import ValueAddedTax from '../ValueAddedTax';

function CartWizardStepCompactInfo({ cartItem }) {
  const { messages } = useIntl();

  const websiteProps = [{ key: 'type_of_order', label: messages.type_of_order + ':', description: messages.website }];

  if (cartItem.is_wants_domain_hosting) {
    websiteProps.push({
      key: 'domain_hosting',
      label: messages.domain_hosting + ':',
      description: cartItem.is_wants_domain_hosting ? messages.yes : messages.no,
    });

    websiteProps.push({
      key: 'desired_domain',
      label: messages.desired_domain + ':',
      description: cartItem.desired_domain,
    });
  }

  const printProps = [
    {
      key: 'type_of_order',
      label: messages.type_of_order + ':',
      description: {
        print_production: messages.print_production,
        pdf_only: messages.pdf_download,
      }[cartItem.print_type],
    },
    {
      key: 'order_quantity',
      label: messages.order_quantity + ':',
      description: `${getVariantCopyAmountValue(cartItem.variant)} ${messages.uom.piece}`,
    },
    {
      key: 'shipping_type',
      label: messages.production_time + ':',
      description: cartItem.shipping_type.charAt(0).toUpperCase() + cartItem.shipping_type.slice(1),
    },
    {
      key: 'manual_data_check',
      label: messages.data_check + ':',
      description: cartItem.is_manual_data_check_requested ? messages.yes : messages.no,
    },
    {
      key: 'web_pdf',
      label: 'Web-PDF:',
      description: cartItem.is_web_pdf_requested ? messages.yes : messages.no,
    },
    {
      key: 'web_pdf',
      label: messages.deliver_adress + ':',
      description: `X ${messages.deliver_adress}`,
    },
  ];

  const data = cartItem.item.type === 'itemWebsite' ? websiteProps : printProps;
  const isItemAllowsReducedVatRate = !!cartItem?.is_reduced_vat_rate_check_available;

  return (
    <div className="cart-step-compact">
      <div style={{ width: '77%', maxWidth: '77%' }}>
        <Properties className="cart-step-compact-view" data={data} />
      </div>
      <div style={{ width: '23%' }}>
        <ValueAddedTax
          key="summary"
          net={cartItem.price}
          gross={cartItem.gross}
          vat={cartItem.vat}
          currency={cartItem.currency}
          webPdfPrice={cartItem.is_web_pdf_requested ? cartItem.web_pdf_price : 0}
          manualDataCheckPrice={cartItem.is_manual_data_check_requested ? cartItem.manual_data_check_price : 0}
          appliedProductGroupDiscount={cartItem.applied_product_group_discount}
          isItemAllowsReducedVatRate={isItemAllowsReducedVatRate}
        />
      </div>
    </div>
  );
}

CartWizardStepCompactInfo.defaultProps = {};

export default CartWizardStepCompactInfo;
