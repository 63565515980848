import React from 'react';
import { connect } from 'react-redux';

import { updateActiveCms } from 'actions/LayoutActions';

import Data from 'components/Data';
import CmsPage from 'components/CmsPage';

import Loader from 'components/layout/Loader';

class Imprint extends React.Component {
  componentDidMount = () => {
    this.props.updateActiveCms('cmsSystemPageImprint');
  };

  componentWillUnmount = () => {
    this.props.updateActiveCms(null);
  };

  render() {
    const { cmsPage, height } = this.props;

    return (
      <React.Fragment>
        {!cmsPage.data.id && <Data dataName="cmsSystemPageImprint" url="/api/pages/imprint.json" data={cmsPage} />}

        {cmsPage.pending ? (
          <div className="content relative" style={{ height: `${height}px` }}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%)' }}>
              <Loader />
            </div>
          </div>
        ) : (
          <CmsPage data={cmsPage.data} doUpdateActiveCms={false} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    height: state.layout.height,
    cmsPage: state.data.cmsSystemPageImprint,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateActiveCms: activeCms => {
      dispatch(updateActiveCms(activeCms));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Imprint);
