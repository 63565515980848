import React from 'react';
import { Link } from 'react-router-dom';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import { isExternal } from 'helpers/http';

import AbstractCmsElement from './AbstractCmsElement';

export default class CmsElementImage extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  getImgComponent = () => {
    let styleClass = '';
    styleClass += 'drop-shadow-' + this.getParamValue('shadow') + ' ';
    styleClass += 'rounded-corners-' + this.getParamValue('roundCorners') + ' ';

    let imageUrl = this.getParamValue('imageUrl');
    const domElem = document.getElementById(this.getMainId());

    if (domElem) {
      const elemWidth = domElem.offsetWidth;

      if (elemWidth > 1200) {
        imageUrl = this.getParamValue('backgroundXlUrl', imageUrl);
      } else if (elemWidth > 1920) {
        imageUrl = this.getParamValue('backgroundRetinaUrl', imageUrl);
      }

      return (
        <img src={imageUrl} class={'ms-img img-responsive ' + styleClass} alt={this.getParamValue('imageFileTitle')} />
      );
    }

    return null;
  };

  getContent = () => {
    const link = this.getParamValue('link');

    if (link) {
      if (isExternal(link)) {
        return <a href={link}>{this.getImgComponent()}</a>;
      }

      return <Link to={link}>{this.getImgComponent()}</Link>;
    }

    return this.getImgComponent();
  };

  render() {
    const { shown } = this.state;
    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div id={this.getMainId()} class={'ms-img ' + shownClass + this.getResponsiveClasses() + this.getCssClasses()}>
        {this.getElementStyle()}

        <div style={{ ...this.getBackgroundStyle() }}>{this.getContent()}</div>

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementImage.defaultProps = {
  index: '0',
};
