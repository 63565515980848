import React from 'react';

class LegalTextHelper extends React.Component {
  render() {
    const { text, substitutes } = this.props;

    // search for matches in the source text
    // each match is a lowercase text in curly brackets - for example "Hello {first_name}!"
    const matches = [];
    text.replace(/({{1}[a-z_]+}{1}){1}/g, (match, g1, g2) => {
      matches.push({ match, pos: g2 });
    });

    const res = [];
    let currentIndex = 0;
    let i = 0;
    matches.map(match => {
      if (substitutes[match.match]) {
        res.push(<React.Fragment key={i++}>{text.substring(currentIndex, match.pos)}</React.Fragment>);
        res.push(<React.Fragment key={i++}>{substitutes[match.match]}</React.Fragment>);

        currentIndex = match.pos + match.match.length;
      }
    });

    res.push(<React.Fragment key={i++}>{text.substring(currentIndex)}</React.Fragment>);

    return res;
  }
}
LegalTextHelper.defaultProps = {
  text: '',
  substitutes: {},
};

export default LegalTextHelper;
