import React from 'react';
import withSideEffect from 'react-side-effect';

let _serverMeta = [];

const getMetaFromPropsList = propsList => {
  var innermostProps = propsList[propsList.length - 1];

  if (innermostProps) {
    return innermostProps.tags;
  }
};

const removeMetaNodes = () => {
  if (typeof document !== 'undefined') {
    var nodes = document.querySelectorAll('meta[data-doc-meta="true"], title[data-doc-meta="true"]');
    Array.prototype.slice.call(nodes).forEach(node => {
      node.parentNode.removeChild(node);
    });
  }
};

const insertNode = tag => {
  if (tag.name == 'title') {
    insertTitleNode(tag);
  } else {
    insertMetaNode(tag);
  }
};

const insertMetaNode = tag => {
  var newNode = document.createElement('meta');
  for (var property in tag) {
    if (tag.hasOwnProperty(property)) {
      newNode.setAttribute(property, tag[property]);
    }
  }
  newNode.setAttribute('data-doc-meta', 'true');
  document.getElementsByTagName('head')[0].appendChild(newNode);
};

const insertTitleNode = tag => {
  var newNode = document.createElement('title');
  newNode.innerHTML = tag.content;
  newNode.setAttribute('data-doc-meta', 'true');
  document.getElementsByTagName('head')[0].appendChild(newNode);
};

const insertMetaNodes = tags => {
  if (typeof document !== 'undefined') {
    Array.prototype.slice.call(tags).forEach(tag => {
      insertNode(tag);
    });
  }
};

removeMetaNodes(); // required unless html5mode

class DocumentMeta extends React.Component {
  render() {
    if (this.props.children) {
      return React.Children.only(this.props.children);
    } else {
      return null;
    }
  }
}

export default withSideEffect(
  propsList => {
    _serverMeta = getMetaFromPropsList(propsList) || [];

    removeMetaNodes();
    insertMetaNodes(_serverMeta);

    return _serverMeta;
  },
  () => {}
)(DocumentMeta);
