import React from 'react';
import { connect } from 'react-redux';
import { Badge } from 'react-bootstrap';
import { components } from 'react-select';
import { forEach as _forEach } from 'lodash';

import { getNameInCurrentLanguage } from 'helpers/intl';

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

const hexToR = h => {
  return parseInt(cutHex(h).substring(0, 2), 16);
};
const hexToG = h => {
  return parseInt(cutHex(h).substring(2, 4), 16);
};
const hexToB = h => {
  return parseInt(cutHex(h).substring(4, 6), 16);
};
const cutHex = h => {
  return h.charAt(0) === '#' ? h.substring(1, 7) : h;
};
const getTextColor = (hexColor = '#ffffff') => {
  if (hexColor === 'transparent') {
    return 'black';
  }

  const o = Math.round(
    (parseInt(hexToR(hexColor), 10) * 299 +
      parseInt(hexToG(hexColor), 10) * 587 +
      parseInt(hexToB(hexColor), 19) * 114) /
      1000
  );
  return o > 125 ? 'black' : 'white';
};

class ColorOptionWithProps extends React.Component {
  render() {
    const { data, appIntl } = this.props;
    return (
      <components.Option {...this.props}>
        <Badge style={{ background: data.color, color: getTextColor(data.color) }} className="p-l-10 p-r-10 m-r-10">
          {data.color}
        </Badge>
        {!!data.id && <Badge className="m-r-10 text-uppercase p-l-10 p-r-10">{appIntl.displayIntlForm}</Badge>}
        {getNameInCurrentLanguage(data.langValue, appIntl)}
      </components.Option>
    );
  }
}
const ColorOption = connect(mapStateToProps)(ColorOptionWithProps);

class ColorValueWithProps extends React.Component {
  render() {
    const { data, appIntl } = this.props;

    return (
      <div className="Select-value">
        <Badge style={{ background: data.color, color: getTextColor(data.color) }} className="p-l-10 p-r-10 m-r-10">
          {data.color}
        </Badge>
        {!!data.id && <Badge className="m-r-10 text-uppercase p-l-10 p-r-10">{appIntl.displayIntlForm}</Badge>}
        {getNameInCurrentLanguage(data.langValue, appIntl)}
      </div>
    );
  }
}
const ColorValue = connect(mapStateToProps)(ColorValueWithProps);

function colorOptionFormatter(options) {
  return (options.list || options).map(option => {
    let label = '';
    _forEach(option.value, langName => {
      if (langName.name !== undefined) {
        label += langName.name + ' ';
      }
    });
    return { ...option, langValue: option.value, value: option.id, label };
  });
}

export { ColorValue, ColorOption, colorOptionFormatter, getTextColor };
