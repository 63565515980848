import React from 'react';
import AnimateHeight from 'react-animate-height';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

import RichEditorContent from 'components/RichEditorContent';

export default class CmsElementAccordionChild extends AbstractCmsElement {
  render() {
    const { accordionIndex, shownAccordionIndex, show } = this.props;

    const title = this.getParamValue('title', '');
    const content = this.getParamValue('content', '');

    const open = accordionIndex === shownAccordionIndex;

    return (
      <div id={this.getMainId()} className={'ms-accordion-child m-b-5 ' + (open ? 'open ' : '')}>
        {this.getElementStyle()}

        <div
          className="ms-accordion-child-head p-t-20 p-b-20 p-l-20 p-r-20 cursor"
          onClick={() => show(open ? null : accordionIndex)}
        >
          <span className="fs-14 text-uppercase">{title}</span>

          <i className="pull-right fa fa-chevron-down m-t-5" />
        </div>

        <AnimateHeight duration={500} height={open ? 'auto' : 0}>
          <RichEditorContent
            content={'<div class="p-b-10 p-l-20 p-r-20 relative ms-accordion-child-content">' + content + '</div>'}
          />
        </AnimateHeight>
      </div>
    );
  }
}
CmsElementAccordionChild.defaultProps = {
  index: '0',
};
