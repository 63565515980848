import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col, Badge, OverlayTrigger, Popover, Button } from 'react-bootstrap';
import { injectIntl } from 'react-intl';

import Loader from 'components/layout/Loader';
import { getWebFile } from 'helpers/items';
import { getDescriptionInCurrentLanguage } from 'helpers/intl';

import List from 'components/List';

class CompanySignupWizardPluginSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = { pluginIds: [], showDetails: false, detailData: null };
  }

  handleSelectionClick = id => {
    const { selection } = this.props;

    let pluginIds = [];
    if (selection) {
      pluginIds = selection;
    }

    if (pluginIds.includes(id)) {
      const index = pluginIds.indexOf(id);
      if (index > -1) {
        pluginIds.splice(index, 1);
      }
    } else {
      pluginIds.push(id);
    }
    this.setState({ pluginIds });
    this.props.onChange(this.props.name, pluginIds);
  };

  getSubscriptionPlanDataCart = () => {
    const {
      intl: { messages },
      appIntl,
      whiteLabelSubscriptionPlanList,
      selection,
    } = this.props;

    let subscriptionPlanData = [];

    [subscriptionPlanData] = whiteLabelSubscriptionPlanList.list.filter(
      subscriptionData => subscriptionData.id === selection[0]
    );

    const { name, description, main_logo, price_short_info } = subscriptionPlanData;

    const desc = getDescriptionInCurrentLanguage(description, appIntl);

    const priceInfo = (
      <div>
        {messages.company_signup_wizard_free_trial_plugin_hint}
        <br />
        {getDescriptionInCurrentLanguage(price_short_info, appIntl)}
      </div>
    );

    let mainLogo = undefined;
    if (main_logo !== undefined) {
      mainLogo = getWebFile(main_logo);
    }

    return (
      <div class="selected-subscription-plan-item b-a b-rad-lg">
        {
          <div class="m-t-15 m-b-15 selected-subscription-plan-item-header text-center">
            {mainLogo !== undefined ? (
              <img class="selected-subscription-plan-image" src={mainLogo.web_link} />
            ) : (
              <div class="selected-subscription-plan-no-image fa-stack fa-4x text-center">
                <i class="fas fa-camera fa-stack-1x" />
                <i class="fas fa-ban fa-stack-2x" />
              </div>
            )}
          </div>
        }
        <div class="text-center">
          <h1>{name}</h1>
        </div>
        <div class="selected-subscription-plan-item-body">
          <div dangerouslySetInnerHTML={{ __html: desc }} />
          <div class="m-t-20 plugin-selector-slide-item-price-info">{priceInfo}</div>
        </div>
      </div>
    );
  };

  overlayStopPropagation = e => {
    e.stopPropagation();
  };

  getSubscriptionIncludes = () => {
    const { selection, whiteLabelSubscriptionPlanList } = this.props;
    let subscriptionIncludes = [];

    subscriptionIncludes = whiteLabelSubscriptionPlanList.list.filter(
      subscriptionData => subscriptionData.id === selection[0]
    )[0].includes;

    return subscriptionIncludes;
  };

  getPluginCarts = () => {
    const {
      intl: { messages },
      appIntl,
      list,
      selection,
    } = this.props;

    const subscriptionIncludes = this.getSubscriptionIncludes();

    const data = [];
    for (let i = 0; i < list.data.list.length; i++) {
      const whiteLabel = list.data.list[i];
      const { id, name, description, main_logo, price_short_info, is_featured } = whiteLabel;

      let mainLogo = '';
      if (main_logo !== undefined) {
        mainLogo = getWebFile(main_logo);
      }

      let checked = false;
      let disabled = false;
      if (selection && selection.includes(id)) {
        checked = true;
      }

      const desc = getDescriptionInCurrentLanguage(description, appIntl);
      let priceInfo = getDescriptionInCurrentLanguage(price_short_info, appIntl);
      let cursor = 'pointer';

      if (subscriptionIncludes.length > 0) {
        for (let i = 0; i < subscriptionIncludes.length; i++) {
          if (subscriptionIncludes[i].plugin.id === id) {
            priceInfo = (
              <div>
                {messages.included_in_subscription_plan}
                <br />
                {priceInfo}
              </div>
            );
            checked = true;
            disabled = true;
            cursor = 'normal';
            break;
          } else {
            priceInfo = (
              <div>
                {messages.company_signup_wizard_free_trial_plugin_hint}
                <br />
                {priceInfo}
              </div>
            );
          }
        }
      } else {
        priceInfo = (
          <div>
            {messages.company_signup_wizard_free_trial_plugin_hint}
            <br />
            {priceInfo}
          </div>
        );
      }

      let borderColor = 'b-grey';
      let backgroundColor = 'bg-white';
      let selected = false;
      if ((selection && selection.includes(id)) || checked) {
        borderColor = 'b-primary';
        backgroundColor = 'plugin-selection-card-item-selected';
        selected = true;
      }

      if (is_featured) {
        borderColor = 'b-primary';
      }
      const colors = borderColor + ' ' + backgroundColor;

      const clickFunction = () => this.handleSelectionClick(id);

      data.push(
        <Col md={6} sm={12} class="d-flex m-t-10 plugin-selection-card-item">
          <div
            class={'flex-item p-r-10 p-l-10 m-r-5 bg-master-lightest b-thick b-a b-rad-lg relative ' + colors}
            onClick={!disabled && clickFunction}
            style={{ cursor }}
          >
            {selected && (
              <Badge
                className="overlayer top-right inline hint-text fs-10 badge-primary"
                style={{ marginTop: '-10px', marginRight: '-10px' }}
              >
                <i className="fa fa-check fa-6" />
              </Badge>
            )}
            {is_featured && (
              <div
                class="plugin-selection-card-item-selected b-rad-lg b-a b-primary"
                style={{ textAlign: 'center', marginTop: '-10px' }}
              >
                {messages.mostly_asked}
              </div>
            )}

            <Col className="flex-column" sm={5}>
              {mainLogo ? (
                <img class="plugin-selection-card-item-image" src={mainLogo.web_link} />
              ) : (
                <div class="plugin-selection-card-item-no-image fa-stack fa-4x m-t-10">
                  <i class="fas fa-camera fa-stack-1x" />
                  <i class="fas fa-ban fa-stack-2x" />
                </div>
              )}
            </Col>
            <Col sm={7}>
              <div class="text-center m-t-10">
                <div class="text-center">
                  <strong>{name}</strong>
                </div>
                <div class="pull-right">
                  <OverlayTrigger
                    placement="top"
                    trigger="click"
                    onClick={this.overlayStopPropagation}
                    rootClose
                    wrappingComponent={Popover}
                    overlay={
                      <Popover style={{ widht: '100%' }}>
                        <div class="plugin-selection-card">
                          <div class="plugin-selection-card-item">
                            <strong class="text-center">{name}</strong>
                            <div class="m-t-10" dangerouslySetInnerHTML={{ __html: desc }} />
                            <div class="plugin-selection-card-item-price-info">{priceInfo}</div>
                            {!disabled && (
                              <Button
                                className="btn-rounded m-b-20 m-t-20 pull-right plugin-selector-slider-item-select_button"
                                onClick={() => this.handleSelectionClick(id)}
                              >
                                {selected ? messages.remove_selection : messages.make_selection}
                              </Button>
                            )}
                          </div>
                        </div>
                      </Popover>
                    }
                    style={{ widht: '100%' }}
                  >
                    <i className="far fa-question-circle cursor-help" />
                  </OverlayTrigger>
                </div>
              </div>
              <div class="plugin-selection-card-item-price-info">{priceInfo}</div>
            </Col>
          </div>
        </Col>
      );
    }

    return data;
  };

  render() {
    const { list, url, listName, urlData } = this.props;

    return (
      <>
        <List listName={listName} url={url} urlData={urlData} list={list} noRender />
        {list.pending ? (
          <Loader />
        ) : (
          <>
            <Grid class="container-fluid">
              <Row>
                <Col className="selected-subscription-plan-card" sm={4}>
                  {this.getSubscriptionPlanDataCart()}
                </Col>
                <Col sm={8}>
                  <Row className="row-eq-height plugin-selection-card flex-stretch-container wrap">
                    {this.getPluginCarts()}
                  </Row>
                </Col>
              </Row>
            </Grid>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    whiteLabelSubscriptionPlanList: state.list.whiteLabelSubscriptionPlan.data,
  };
};
export default injectIntl(connect(mapStateToProps)(CompanySignupWizardPluginSelector));
