import React, { useEffect, useState } from 'react';

import { getCurrencySymbol } from 'helpers/financial';
import { CouponShape } from '../../../helpers/proptypes';

CartWizardStepCoupon.propTypes = {
  coupon: CouponShape,
};

CartWizardStepCoupon.defaultProps = {
  coupon: undefined,
};

function CartWizardStepCoupon({ coupon }) {
  if (!coupon) {
    return null;
  }
  const [label, setLabel] = useState('');
  useEffect(() => {
    // Calculate the label
    if (coupon.type === 'percentage') {
      setLabel(`${coupon.percent_discount}% Rabatt`);
    } else if (coupon.type === 'monetary') {
      setLabel(`${coupon.value_discount}${getCurrencySymbol(coupon.currency)} Rabatt`);
    }
  }, [coupon]);

  return (
    <div className="coupon">
      <div className="triangle" />
      <div className="coupon-body">
        <div className="title">{label}</div>
        <div className="teaser">{coupon.description}</div>
      </div>
    </div>
  );
}

export default CartWizardStepCoupon;
