import _, {forEach as _forEach} from 'lodash';
import { findIndex as _findIndex,} from 'lodash';

const initialState = {
  deletedCampaigns: [],
  pending: false,
  saving:false,
  restore:false,
  deletedEditions: [],
  pendingRestoreC: false,
  pendingRestoreE: false,
  deletedFolders:[],
  deletedImages:[],
  pendingMediaLibrary: false,
  parentIsMain:true,
  pendingFolderAction:false,
  pendingMediaAction:false,
  parentIsMain2:true,
  errorMessage:"",
  pendingDeleteE:false,


};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'CE_TRASH_PENDING': {
      return { ...state, pending: true };
    }
    case 'CE_TRASH_FULFILLED': {
      let { deletedCampaigns, deletedEditions } = state;

      deletedCampaigns = payload.deletedCampaigns;
      deletedEditions = payload.deletedEditions;
      return { ...state, deletedCampaigns, deletedEditions, parentIsMain2: true, pending: false };
  }
    case 'E_TRASH_PENDING': {
      return { ...state, pending: true };
    }
    case 'E_TRASH_FULFILLED': {
      let { deletedEditions } = state;
      let dEditionsConcat = deletedEditions;
      if(payload.deletedEditions && payload.deletedEditions.length !== 0){
        dEditionsConcat = deletedEditions.concat(payload.deletedEditions);
      }

      return { ...state, deletedEditions: dEditionsConcat, pending: false, parentIsMain2: false };

    }
    case 'RESTORE_CAMPAIGN_PENDING': {
      return { ...state, pendingRestoreC: true };
    }
    case 'RESTORE_CAMPAIGN_FULFILLED': {
      let { deletedCampaigns, deletedEditions, restore } = state;
      let campaignId = payload;
      deletedCampaigns = _.filter(deletedCampaigns, x => x.id !== campaignId);
      deletedEditions = _.filter(deletedEditions, x => x.campaign.id !== campaignId);
      return { ...state, deletedCampaigns, deletedEditions, restore: true, pendingRestoreC: false };

    }
    case 'RESTORE_EDITION_PENDING': {
      return { ...state, pendingRestoreE: true };
    }
    case 'RESTORE_EDITION_FULFILLED': {
      let { deletedEditions, errorMessage, restore } = state;

      //if payload !== campaignNotAvailable
      if(payload !== "campaignNotAvailable"){
        let editionId = payload;
        deletedEditions = _.filter(deletedEditions, x => x.id !== editionId);
      }else{
        errorMessage = "Die Edition kann nicht wiederhergstellt werden. Stellen Sie zuerst die Kampagne wieder her."
      }

      return { ...state, deletedEditions, restore: true, pendingRestoreE: false, errorMessage: errorMessage};
    }
    case 'DELETE_EDITION_IN_TRASH_PENDING': {
      return { ...state, pendingDeleteE: true };
    }
    case 'DELETE_EDITION_IN_TRASH_FULFILLED': {
      let { deletedEditions, errorMessage, restore } = state;
      let EditionId = payload;
      deletedEditions = _.filter(deletedEditions, x => x.id !== EditionId);
      return { ...state, deletedEditions, pendingDeleteE: false };
    }
    case 'SET_VARIABLES_TRASH': {
      if (payload[0] === 'restorefalse') {
        return { ...state, restore: false };
      }
      if (payload[0] === 'c_delete') {
        let { deletedCampaigns} = state;
        let sliced;
        let sliced2 = [];
        const indexDeletedCampaign = _findIndex(deletedCampaigns, ['id', payload[1]]);
        let _deletedCampaigns = deletedCampaigns;
        sliced = _deletedCampaigns.slice(0, indexDeletedCampaign);
        if (indexDeletedCampaign < _deletedCampaigns.length - 1) {
          sliced2 = _deletedCampaigns.slice(indexDeletedCampaign + 1);
        }
       _deletedCampaigns = [...sliced, ...sliced2];

        return { ...state, deletedCampaigns: _deletedCampaigns, restore: true, pendingRestoreC: false};
      }
      if (payload[0] === 'e_delete') {

        let { deletedEditions} = state;
        let sliced;
        let sliced2 = [];
        const indexDeletedEdition = _findIndex(deletedEditions, ['id', payload[1]]);
        let _deletedEditions = deletedEditions;
        sliced = _deletedEditions.slice(0, indexDeletedEdition);
        if (indexDeletedEdition < _deletedEditions.length - 1) {
          sliced2 = _deletedEditions.slice(indexDeletedEdition + 1);
        }
        _deletedEditions = [...sliced, ...sliced2];

        return { ...state, deletedEditions: _deletedEditions, restore: true, pendingRestoreE: false};
      }
    }
    case 'MEDIA_TRASH_PENDING': {
      return { ...state, pendingMediaLibrary: true };
    }
    case 'MEDIA_TRASH_FULFILLED': {
      let { deletedFolders, deletedImages } = state;
      deletedFolders = payload.mediaFolders
      deletedImages = payload.mediaImages;
      return { ...state, deletedFolders, deletedImages, pendingMediaLibrary: false, parentIsMain: true };
    }
    case 'MEDIA_TRASH_PARENT_PENDING': {
      return { ...state, pendingMediaLibrary: true };
    }
    case 'MEDIA_TRASH_PARENT_FULFILLED': {
      let { deletedFolders, deletedImages } = state;
      let dFoldersConcat = deletedFolders;
      let dImagesConcat = deletedImages;
      if(payload.mediaFolders && payload.mediaFolders.length !== 0){
        _forEach (deletedFolders, (deletedFolder, indexDF) => {
          _forEach (payload.mediaFolders, (mediaFolder, indexMF) => {
            if(JSON.stringify(deletedFolder) === JSON.stringify(mediaFolder)){
              payload.mediaFolders = _.filter(payload.mediaFolders, x => x.id !== mediaFolder.id);
            }
          });
        });
      dFoldersConcat = deletedFolders.concat(payload.mediaFolders);

      }
      if(payload.mediaImages && payload.mediaImages.length !== 0) {
        _forEach (deletedImages, (deletedImage, indexDI) => {
          _forEach (payload.mediaImages, (mediaImage, indexMI) => {
            if(JSON.stringify(deletedImage) === JSON.stringify(mediaImage)){
              payload.mediaImages = _.filter(payload.mediaImages, x => x.id !== mediaImage.id);
            }
          });
        });
       dImagesConcat = deletedImages.concat(payload.mediaImages);
      }
      return { ...state, deletedFolders: dFoldersConcat, deletedImages: dImagesConcat, pendingMediaLibrary: false, parentIsMain: false };
    }
    case 'DELETE_FOLDER_IN_TRASH_FULFILLED': {
      let { deletedFolders } = state;
      let folderId = payload;
      deletedFolders = _.filter(deletedFolders, x => x.id !== folderId);
      return { ...state, deletedFolders };
    }
    case 'DELETE_FILE_IN_TRASH_FULFILLED': {
      let { deletedImages } = state;
      let fileId = payload;
      deletedImages = _.filter(deletedImages, x => x.id !== fileId);
      return { ...state, deletedImages };
    }
    case 'RESTORE_FOLDER_PENDING': {
      return { ...state, pendingFolderAction: true };
    }
    case 'RESTORE_FOLDER_FULFILLED': {
      let { deletedFolders, deletedImages } = state;
      let folderId = payload;
      deletedFolders = _.filter(deletedFolders, x => x.id !== folderId);
      deletedImages = _.filter(deletedImages, x => x.parent !== folderId);
      return { ...state, deletedFolders, pendingFolderAction:false };
    }
    case 'RESTORE_FILE_PENDING': {
      return { ...state, pendingMediaAction: true };
    }
    case 'RESTORE_FILE_FULFILLED': {
      let { deletedImages } = state;
      let fileId = payload;
      deletedImages = _.filter(deletedImages, x => x.id !== fileId);
      return { ...state, deletedImages, pendingMediaAction: false };
    }
    case 'DELETE_ALL_PENDING': {
      return { ...state, pending: true, pendingMediaLibrary: true };
    }
    case 'DELETE_ALL_FULFILLED': {
      let {deletedCampaigns, deletedEditions, deletedFolders, deletedImages} = state;
      deletedCampaigns = [];
      deletedEditions = [];
      deletedFolders = [];
      deletedImages = [];

      return { ...state, deletedCampaigns: deletedCampaigns, deletedEditions: deletedEditions, deletedFolders: deletedFolders, deletedImages: deletedImages, pending: false, pendingMediaLibrary: false };
    }
}
  return state;
};
