import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { updateCheckout, updatePayment } from 'actions/CheckoutActions';

class PaymentButton extends React.Component {
  render() {
    const {
      intl: { messages },
      ePaymentTotal,
      user,
      projectConfig,
      checkout,
    } = this.props;

    return <>Bill Button</>;
  }
}

export default PaymentButton;
