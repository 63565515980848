import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getNameInCurrentLanguage, getDescriptionInCurrentLanguage } from 'helpers/intl';
import { getWebFile } from 'helpers/items';

import Data from 'components/Data';
import DocMeta from 'components/DocMeta';

import CarDetailsRender from 'components/layout/itemDetails/CarDetailsRender';
import ItemDetailsRender from 'components/layout/itemDetails/ItemDetailsRender';
import ItemDetailsWireframe from 'components/layout/wireframes/ItemDetails';

class ItemDetails extends React.Component {
  render() {
    const { appIntl, match, item } = this.props;

    // SEO tags for the header
    const tags = [];
    if (!item.pending && !item.hasError && item.data) {
      let title = '';
      if (item.data.seo && item.data.seo.title && item.data.seo.title.length) {
        title = getNameInCurrentLanguage(item.data.seo.title, appIntl);
      }
      if (!title) {
        title = (item.data.brand ? item.data.brand.name + ' ' : '') + item.data.name;
      }

      tags.push({ name: 'title', content: title });
      tags.push({ itemProp: 'name', content: title });

      if (item.data.seo && item.data.seo.description && item.data.seo.description.length) {
        tags.push({
          name: 'description',
          content: getDescriptionInCurrentLanguage(item.data.seo.description, appIntl),
        });
        tags.push({
          itemProp: 'description',
          content: getDescriptionInCurrentLanguage(item.data.seo.description, appIntl),
        });
      }

      if (item.data.default_img) {
        const seoImageThumb = getWebFile(item.data.default_img);

        if (seoImageThumb) {
          tags.push({ itemProp: 'image', content: seoImageThumb.web_link });
        }
      }
    }

    let res = null;
    if (item.pending) {
      res = <ItemDetailsWireframe itemSlug={match.params.slug} />;
    } else if (item.hasError) {
    } else if (item.data.type === 'car') {
      res = <CarDetailsRender item={item} />;
    } else {
      res = <ItemDetailsRender item={item} />;
    }

    return (
      <DocMeta tags={tags}>
        <div class={typeof widget !== 'undefined' && widget ? '' : 'content'}>
          <Data dataName="item" url={'/api/itemslug/' + match.params.slug + '.json'} data={item} />

          {res}
        </div>
      </DocMeta>
    );
  }
}
ItemDetails.defaultProps = {};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    item: state.data.item,
  };
};

export default injectIntl(connect(mapStateToProps)(ItemDetails));
