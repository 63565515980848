import axios from 'axios';
import { camelCase as _camelCase } from 'lodash';

export const CART_RESET = 'CART_RESET';

export const CART_FETCH = 'CART_FETCH';
export const CART_FETCH_PENDING = 'CART_FETCH_PENDING';
export const CART_FETCH_FULFILLED = 'CART_FETCH_FULFILLED';

export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_ADD_ITEM_PENDING = 'CART_ADD_ITEM_PENDING';
export const CART_ADD_ITEM_FULFILLED = 'CART_ADD_ITEM_FULFILLED';
export const CART_ADD_ITEM_REJECTED = 'CART_ADD_ITEM_REJECTED';

export const CART_ADD_VARIANT = 'CART_ADD_VARIANT';
export const CART_ADD_VARIANT_PENDING = 'CART_ADD_VARIANT_PENDING';
export const CART_ADD_VARIANT_FULFILLED = 'CART_ADD_VARIANT_FULFILLED';
export const CART_ADD_VARIANT_REJECTED = 'CART_ADD_VARIANT_REJECTED';

export const CART_DOMAIN = 'CART_DOMAIN';

export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_REMOVE_ITEM_PENDING = 'CART_REMOVE_ITEM_PENDING';
export const CART_REMOVE_ITEM_FULFILLED = 'CART_REMOVE_ITEM_FULFILLED';
export const CART_REMOVE_ITEM_REJECTED = 'CART_REMOVE_ITEM_REJECTED';

export const CART_REMOVE_VARIANT = 'CART_REMOVE_VARIANT';
export const CART_REMOVE_VARIANT_PENDING = 'CART_REMOVE_VARIANT_PENDING';
export const CART_REMOVE_VARIANT_FULFILLED = 'CART_REMOVE_VARIANT_FULFILLED';
export const CART_REMOVE_VARIANT_REJECTED = 'CART_REMOVE_VARIANT_REJECTED';

export const CART_UPDATE_ITEM = 'CART_UPDATE_ITEM';
export const CART_UPDATE_ITEM_PENDING = 'CART_UPDATE_ITEM_PENDING';
export const CART_UPDATE_ITEM_FULFILLED = 'CART_UPDATE_ITEM_FULFILLED';
export const CART_UPDATE_ITEM_REJECTED = 'CART_UPDATE_ITEM_REJECTED';

export const CART_UPDATE = 'CART_UPDATE';
export const CART_UPDATE_PENDING = 'CART_UPDATE_PENDING';
export const CART_UPDATE_FULFILLED = 'CART_UPDATE_FULFILLED';
export const CART_UPDATE_REJECTED = 'CART_UPDATE_REJECTED';

const everyObjectPropInArray = (obj, propsArray) => Object.keys(obj).every(prop => propsArray.includes(prop));

export function resetCart() {
  return {
    type: CART_RESET,
  };
}

export function fetchCart(type = 'cart') {
  return {
    type: CART_FETCH,
    payload: axios.get('/api/cart/types/' + type + '.json?_locale=' + globalLocale),
  };
}

export function cartAddItem(quantity, item, cartType = 'cart') {
  return {
    type: CART_ADD_ITEM,
    payload: axios.post('/api/cart/types/' + cartType + '/items/' + item.id + '.json?_locale=' + globalLocale),
  };
}

export function cartAddEdition(edition, cartType = 'cart') {
  return {
    type: CART_ADD_ITEM,
    payload: axios.post('/api/cart/types/' + cartType + '/editions/' + edition.id + '.json?_locale=' + globalLocale),
  };
}

export function cartAddVariant(quantity, item, variant, cartType = 'cart') {
  return {
    type: CART_ADD_VARIANT,
    payload: axios.post(
      '/api/cart/types/' + cartType + '/items/' + item.id + '/variants/' + variant.id + '.json?_locale=' + globalLocale
    ),
  };
}

export function cartRemoveItem(item, cartType = 'cart') {
  return {
    type: CART_REMOVE_ITEM,
    payload: axios.delete('/api/cart/types/' + cartType + '/items/' + item.id + '.json?_locale=' + globalLocale),
  };
}

export function cartRemoveEdition(edition, cartType = 'cart') {
  return {
    type: CART_REMOVE_ITEM,
    payload: axios.delete('/api/cart/types/' + cartType + '/editions/' + edition.id + '.json?_locale=' + globalLocale),
  };
}

export function cartRemoveVariant(item, variant, cartType = 'cart') {
  return {
    type: CART_REMOVE_VARIANT,
    payload: axios.delete(
      '/api/cart/types/' + cartType + '/items/' + item.id + '/variants/' + variant.id + '.json?_locale=' + globalLocale
    ),
  };
}

export function cartUpdateItem(item, updateObj, cartType = 'cart') {
  const validItemProps = [
    'print_type',
    'shipping_type',
    'is_manual_data_check_requested',
    'is_reduced_vat_rate_check_requested',
    'is_web_pdf_requested',
    'is_wants_domain_hosting',
    'is_configuration_finished',
    'variant',
    'manual_data_check_request_comment',
    'desired_domain',
    'url_forwarding',
    'split_shipping',
    'perforation_options_available',
    'perforation_options_selected',
    'perforation_options_preselected',
    'textile_sizes_selected',
  ];

  if (!everyObjectPropInArray(updateObj, validItemProps)) {
    throw new Error('Cart updateItem action got invalid update object:', updateObj);
  }

  return {
    type: CART_UPDATE_ITEM,
    payload: axios.put(
      '/api/cart/types/' + cartType + '/editions/' + item.edition.id + '.json?_locale=' + globalLocale,
      convertKeysToCamelCase(updateObj)
    ),
  };
}

export function cartUpdate(updateObj, cartType = 'cart') {
  const validItemProps = ['shipping_address', 'invoice_address', 'use_invoice_address_for_shipping'];
  if (!everyObjectPropInArray(updateObj, validItemProps)) {
    throw new Error('Cart update action got invalid update object:', updateObj);
  }

  return {
    type: CART_UPDATE,
    payload: axios.put(
      '/api/cart/types/' + cartType + '.json?_locale=' + globalLocale,
      convertKeysToCamelCase(updateObj)
    ),
  };
}

export function cartCheckDomainAvailability(item) {
  return {
    type: CART_DOMAIN,
    payload: axios.get(`/api/route53/checkdomainavailability.json?domain=${item.desired_domain}`),
  };
}

function convertKeysToCamelCase(obj) {
  return Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      [_camelCase(key)]: obj[key],
    }),
    {}
  );
}
