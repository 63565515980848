import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { forEach as _forEach, find as _find } from 'lodash';

import { getNameInCurrentLanguage } from 'helpers/intl';

class ItemAttributeGroupsRender extends React.Component {
  render() {
    const { attributes, attributeGroups, appIntl } = this.props;

    const attributeGroupCols = [];

    _forEach(attributeGroups, attributeGroup => {
      const attributeRows = [];

      _forEach(attributeGroup.attributes, attribute => {
        const listAttribute = _find(attributes, ['id', attribute.attribute.id]);
        if (listAttribute) {
          attributeRows.push(
            <tr>
              <td>{getNameInCurrentLanguage(listAttribute.name, appIntl)}</td>
              <td>{getNameInCurrentLanguage(listAttribute.values[0].value, appIntl)}</td>
            </tr>
          );
        }
      });

      // show only, when there are attributes in this group
      if (attributeRows.length) {
        attributeGroupCols.push(
          <Col xs={12} md={4}>
            <h3>{getNameInCurrentLanguage(attributeGroup.attribute_group.name, appIntl)}</h3>
            <table class="table">
              <tbody>{attributeRows}</tbody>
            </table>
          </Col>
        );
      }
    });

    return <Row>{attributeGroupCols}</Row>;
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default injectIntl(connect(mapStateToProps)(ItemAttributeGroupsRender));
