import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { Badge, Clearfix, ButtonGroup, Button } from 'react-bootstrap';
import ScrollArea from 'react-scrollbar';

import { submitLogout } from 'actions/LoginActions';
import { isPluginActive } from 'helpers/whiteLabel';
import { getThumbFile } from 'helpers/items';
import { isExternal } from 'helpers/http';
import { ROLE_EMPLOYEE, ROLE_COMPANY, ROLE_DIRECT_CUSTOMER } from 'js/constants';
import { communicationVideoModule } from '@chedri/base';

import may from 'js/security';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.navRef = React.createRef();
  }

  menuClick = (e, index, depth) => {
    e.preventDefault();
    e.stopPropagation();

    const newState = { ...this.state };
    newState[index + '-' + depth] = !this.checkOpen(index, depth);
    this.setState(newState);
  };

  checkOpen = (index, depth) => {
    return !!(this.state && this.state.hasOwnProperty(index + '-' + depth) && this.state[index + '-' + depth]);
  };

  navClick = (e, link) => {
    e.preventDefault();
    e.stopPropagation();

    const { history, layout, handleMouseLeave } = this.props;

    if (isExternal(link)) {
      window.location = link;
    } else {
      history.push(link);
      if (layout.breakpointIndex <= 1 || layout.isTouchDevice) {
        handleMouseLeave(); /*  */
      }
    }
  };

  parseChildren = (children, depth) => {
    const { visible, handleMouseEnter } = this.props;

    return children.map((element, i) => {
      const hasChildren = element.link === null && element.children.length;
      const opened = this.checkOpen(i, depth);

      const icon = element.icon || <i className="far fa-circle" />;

      return (
        <li key={i} className={element.listClassName}>
          {element.badge === undefined || element.badge <= 0 ? null : (
            <span style={{ position: 'absolute', marginTop: '-5px', right: 0 }}>
              <Badge className="red-bg">{element.badge}</Badge>
            </span>
          )}
          {hasChildren ? (
            <a
              href="#expand"
              onClick={e => {
                this.menuClick(e, i, depth);
              }}
              className={element.className}
            >
              <span className="title">{element.text}</span>
              <span className={'arrow ' + (opened ? 'open ' : '')} />
            </a>
          ) : (
            <Link to={element.link || '/'} onClick={e => this.navClick(e, element.link)}>
              <span className="title">{element.text}</span>
            </Link>
          )}
          <span
            className="icon-thumbnail"
            onClick={e => {
              if (!visible) {
                e.preventDefault();
                handleMouseEnter();
              } else if (!hasChildren) {
                this.navClick(e, element.link);
              }
            }}
          >
            {icon}
          </span>
          {hasChildren && (
            <ul className="sub-menu" style={{ display: opened ? 'block' : 'none' }}>
              {this.parseChildren(element.children, depth + 1)}
            </ul>
          )}
        </li>
      );
    });
  };

  componentWillUnmount = () => {
    document.removeEventListener('click', this.outsideClick, false);
  };

  componentDidUpdate = prevProps => {
    const { visible } = this.props;

    if (!prevProps.visible && visible) {
      document.addEventListener('click', this.outsideClick, false);
    } else if (prevProps.visible && !visible) {
      document.removeEventListener('click', this.outsideClick, false);
    }
  };

  outsideClick = e => {
    if (this.navRef.current.contains(e.target)) {
      return;
    }

    const { handleMouseLeave } = this.props;
    handleMouseLeave();
  };

  render() {
    const {
      intl: { messages },
      appIntl,
      visible,
      elements,
      layout,
      topNavLinks,
      projectConfig,
      user,
      handleMouseEnter,
      handleMouseLeave,
      submitLogout,
      isInCall,
    } = this.props;


    const style =
      visible && layout.breakpointIndex > 1
        ? {
            transform: 'translate(210px, 0px)',
          }
        : {};

    let logoUrl = '/img/chedri_logo_white.png';
    if (projectConfig.data.id !== 'ChedriAdmin') {
      if (projectConfig.data.use_scroll_logo) {
        const thumbnail = getThumbFile(projectConfig.data.scroll_logo);
        if (thumbnail) {
          logoUrl = thumbnail.web_link;
        }
      } else {
        const thumbnail = getThumbFile(projectConfig.data.main_logo);
        if (thumbnail) {
          logoUrl = thumbnail.web_link;
        }
      }
    }

    let updateNotificationClass = "";
    if(this.props.updateNotification !== undefined){
      updateNotificationClass = "nav-update-n-c"
    }
    return (
      <nav
        ref={this.navRef}
        className={'page-sidebar ' + (visible ? 'visible ' : '') + updateNotificationClass}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          if (layout.breakpointIndex > 1) {
            handleMouseLeave(); /* only hide the menu when there is a mouse */
          }
        }}
        style={style}
      >
        <div className="sidebar-header text-center">
          <Link to={appIntl.urlPrefix}>
            <img src={logoUrl} alt="logo" className="nav-logo" style={{ visibility: visible ? 'visible' : 'hidden' }} />
          </Link>
        </div>
        <div className="sidebar-menu">
          <ScrollArea
            speed={0.8}
            className="scroll-wrapper menu-items"
            contentClassName=""
            horizontal={false}
            style={{ overflow: 'hidden' }}
          >
            {layout.breakpointIndex <= 1 && user.logged && (
              <div className="sidebar-profile p-t-15">
                <Clearfix>
                  <div className="profile-icon text-center">
                    {user.profile && user.profile.avatar && user.profile.avatar.thumb_link ? (
                      <span className="thumbnail-wrapper circular inline">
                        <span
                          className="block"
                          style={{ backgroundImage: 'url(' + user.profile.avatar.thumb_link + ')' }}
                        />
                      </span>
                    ) : (
                      <i className="far fa-fw fa-user-circle text-white" />
                    )}
                  </div>

                  <div className="text-center m-t-5">
                    <Link
                      to={appIntl.urlPrefix + 'account/settings/profile'}
                      onClick={e => {
                        if (may(ROLE_EMPLOYEE, user.roles) || may(ROLE_COMPANY, user.roles) || may(ROLE_DIRECT_CUSTOMER, user.roles)) {
                          this.navClick(e, appIntl.urlPrefix + 'account/settings/profile');
                        } else {
                          this.navClick(e, appIntl.urlPrefix + 'account/profile');
                        }
                      }}
                    >
                      {user.username}
                    </Link>
                  </div>
                </Clearfix>
              </div>
            )}

            <div className="p-t-30">
              <ul className="menu-items">
                {layout.breakpointIndex <= 1 && user.logged && may('ROLE_CUSTOMER', user.roles) && (
                  <>
                  <li>
                    <Link
                      to={appIntl.urlPrefix + 'account'}
                      onClick={e => this.navClick(e, appIntl.urlPrefix + 'account')}
                    >
                      <span className="title">Dashboard</span>
                    </Link>

                    <span
                      className="icon-thumbnail"
                      onClick={e => {
                        if (!visible) {
                          e.preventDefault();
                          handleMouseEnter();
                        } else {
                          this.navClick(e, appIntl.urlPrefix + 'account');
                        }
                      }}
                    >
                      <i className="fa fa-fw fa-tachometer-alt" />
                    </span>
                  </li>
                  </>
                )}
                {user.logged && layout.breakpointIndex <= 1 ?
                  <li>
                    <Link
                      to={appIntl.urlPrefix + messages.cart}
                      onClick={e => this.navClick(e, appIntl.urlPrefix + messages.cart)}
                    ><span className="cartTitle">{messages.cart}</span>
                    </Link>

                    {this.props.cart.items && this.props.cart.items.length > 0 ? (
                        <span className="sidebarCart"
                              onClick={e => {
                                if (!visible) {
                                  e.preventDefault();
                                  handleMouseEnter();
                                } else {
                                  this.navClick(e, appIntl.urlPrefix + messages.cart);
                                }
                              }}
                        > {this.props.cart.items.length}</span>
                      ) :
                      <span
                        className="icon-thumbnail"
                        onClick={e => {
                          if (!visible) {
                            e.preventDefault();
                            handleMouseEnter();
                          } else {
                            this.navClick(e, appIntl.urlPrefix + messages.cart);
                          }
                        }}
                      >
                  <i className="far fa-shopping-cart"></i>
                  </span>}
                  </li>: <></>}

                {!user.logged ?
                  <>
                    <li>
                      <Link
                        to={appIntl.urlPrefix + 'login'}
                        onClick={e => this.navClick(e, appIntl.urlPrefix + 'login')}
                      >
                        <span className="title">{messages.accountAndLogin}</span>
                      </Link>

                      <span
                        className="icon-thumbnail"
                        onClick={e => {
                          if (!visible) {
                            e.preventDefault();
                            handleMouseEnter();
                          } else {
                            this.navClick(e, appIntl.urlPrefix + 'login');
                          }
                        }}
                      >
                     <i className="far fa-user-circle"></i>
                    </span>
                    </li>
                  </>
                  : <></>}

                {layout.breakpointIndex <= 1 && user.logged && may('ROLE_CUSTOMER', user.roles) && (
                  <>
                  <li>
                    <Link
                      to={appIntl.urlPrefix + 'account/orders'}
                      onClick={e => this.navClick(e, appIntl.urlPrefix + 'account/orders')}
                    >
                      <span className="title">{messages.orders}</span>
                    </Link>

                    <span
                      className="icon-thumbnail"
                      onClick={e => {
                        if (!visible) {
                          e.preventDefault();
                          handleMouseEnter();
                        } else {
                          this.navClick(e, appIntl.urlPrefix + 'account/orders');
                        }
                      }}
                    >
                      <i className="fas fa-fw fa-shopping-bag" />
                    </span>
                  </li>
                  </>
                )}

                {this.parseChildren(topNavLinks.concat(elements), 0)}

                {layout.breakpointIndex <= 1 &&
                  user.logged &&
                  may(ROLE_EMPLOYEE, user.roles) &&
                  isPluginActive('SALES_ACTIVITY_DASHBOARD', projectConfig.data) && (
                    <>
                    <li>
                      <Link
                        to={appIntl.urlPrefix + 'account/salesActivityDashboard'}
                        onClick={e => this.navClick(e, appIntl.urlPrefix + 'account/salesActivityDashboard')}
                      >
                        <span className="title">Sales Activity Dashboard</span>
                      </Link>

                      <span
                        className="icon-thumbnail"
                        onClick={e => {
                          if (!visible) {
                            e.preventDefault();
                            handleMouseEnter();
                          } else {
                            this.navClick(e, appIntl.urlPrefix + 'account/salesActivityDashboard');
                          }
                        }}
                      >
                        <i className="fa fa-fw fa-plug" />
                      </span>
                    </li>
                 </>
                  )}

                {layout.breakpointIndex <= 1 &&
                  user.logged &&
                  may(ROLE_EMPLOYEE, user.roles) &&
                  isPluginActive('RENT_ACTIVITY_DASHBOARD', projectConfig.data) && (
                    <li>
                      <Link
                        to={appIntl.urlPrefix + 'account/rentActivityDashboard'}
                        onClick={e => this.navClick(e, appIntl.urlPrefix + 'account/rentActivityDashboard')}
                      >
                        <span className="title">Rent Activity Dashboard</span>
                      </Link>

                      <span
                        className="icon-thumbnail"
                        onClick={e => {
                          if (!visible) {
                            e.preventDefault();
                            handleMouseEnter();
                          } else {
                            this.navClick(e, appIntl.urlPrefix + 'account/rentActivityDashboard');
                          }
                        }}
                      >
                        <i className="fa fa-fw fa-plug" />
                      </span>
                    </li>
                  )}

                {layout.breakpointIndex <= 1 &&
                  user.logged &&
                  may(ROLE_EMPLOYEE, user.roles) &&
                  isPluginActive('CONNECTME', projectConfig.data) && (
                    <li>
                      <Link
                        to={appIntl.urlPrefix + 'account/connectme'}
                        onClick={e => this.navClick(e, appIntl.urlPrefix + 'account/connectme')}
                      >
                        <span className="title">ConnectMe</span>
                      </Link>

                      <span
                        className="icon-thumbnail"
                        onClick={e => {
                          if (!visible) {
                            e.preventDefault();
                            handleMouseEnter();
                          } else {
                            this.navClick(e, appIntl.urlPrefix + 'account/connectme');
                          }
                        }}
                      >
                        <i className="fa fa-fw fa-plug" />
                      </span>
                    </li>
                  )}
              </ul>
            </div>
            <div>
            </div>

            {layout.breakpointIndex <= 1 && user.logged && (
              <div className="p-t-10 p-l-15 p-r-15 p-b-20">
                <ButtonGroup className="full-width">
                  {(may(ROLE_EMPLOYEE, user.roles) || may('ROLE_COMPANY', user.roles)) && (
                    <Button
                      type="button"
                      onClick={e => this.navClick(e, appIntl.urlPrefix + 'account/settings')}
                      className="btn-link col-xs-6"
                    >
                      <i className="fa fa-fw fa-cogs" />
                    </Button>
                  )}

                  <Button type="button" onClick={() => !isInCall && submitLogout()} className="btn-link col-xs-6">
                    <i className="fa fa-power-off" />
                  </Button>
                </ButtonGroup>
              </div>
            )}
          </ScrollArea>
        </div>
        <Clearfix />
      </nav>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    projectConfig: state.data.projectConfig,
    user: state.login.user,
    isInCall: communicationVideoModule.selectors.isInCall(state),
    cart: state.cart,
    updateNotification: state.list.updates.updateNotification,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    submitLogout: () => {
      dispatch(submitLogout());
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Sidebar)
  )
);
