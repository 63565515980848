const initialState = {
  isLoading: false,
  errors: 0,
  data: {},
};

export default function calendar(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_CALENDAR_PENDING': {
      return {
        ...state,
        isLoading: true,
        errors: 0,
      };
    }
    case 'FETCH_CALENDAR_FULFILLED': {
      return {
        ...state,
        isLoading: false,
        errors: 0,
        data: action.payload.data,
      };
    }
    case 'FETCH_CALENDAR_REJECTED': {
      return {
        ...state,
        isLoading: false,
        errors: action.payload.response.data,
        data: {},
      };
    }
  }

  return state;
}
