import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Modal from 'components/Modal';
import inEU from '@segment/in-eu';

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import { saveCookie } from 'actions/CookiesActions';
import { arePerformanceCookiesUsed, areTargetingCookiesUsed } from 'helpers/whiteLabel';

import CookieConsentInfos from 'components/modals/CookieConsentInfos';

class CookieConsent extends React.Component {
  constructor(props) {
    super(props);

    const { cookies } = this.props;

    this.state = {
      justClosed: false,
      hide: !!cookies.boxClosed,
      showInfos: false, // modal window with information on cookies and settings for performance / targeting cookies
      cookiesPerformanceChecked: cookies.performance,
      cookiesTargetingChecked: cookies.targeting,
      buttonUsed: null,
    };
  }

  showInfos = e => {
    e.preventDefault();
    this.setState({ showInfos: true });
  };

  closeInfos = () => {
    this.setState({ showInfos: false });
  };

  clickAll = () => {
    const { saveCookie } = this.props;

    this.setState({ buttonUsed: 'saveAll' }, () => {
      saveCookie({
        chedriCookiesBoxClosed: '1',
        chedriCookiesPerformance: '1',
        chedriCookiesTargeting: '1',
      });
    });
  };

  clickSaveCookieSettings = () => {
    const { saveCookie } = this.props;
    const { cookiesPerformanceChecked, cookiesTargetingChecked } = this.state;

    this.setState({ buttonUsed: 'saveSettings' }, () => {
      saveCookie({
        chedriCookiesBoxClosed: '1',
        chedriCookiesPerformance: cookiesPerformanceChecked,
        chedriCookiesTargeting: cookiesTargetingChecked,
      });
    });
  };

  checkboxChange = e => {
    const target = e.target;
    const role = target.value;
    const checked = target.checked;

    this.setState({ [role + 'Checked']: checked });
  };

  componentDidMount = () => {
    const {
      webApp: { debug },
      cookies,
    } = this.props;

    if (cookies.performance) {
      this.initPerformance(debug);
    }

    if (cookies.targeting) {
      this.initTargeting(debug);
    }
  };

  initPerformance = debug => {
    const { projectConfig } = this.props;
    this.performanceInit = true;

    if (projectConfig.data.google_analytics_tracking_id) {
      ReactGA.initialize(projectConfig.data.google_analytics_tracking_id, { debug });
      ReactGA.set({ anonymizeIp: true });
    }
  };

  initTargeting = debug => {
    const { projectConfig } = this.props;
    this.targetingInit = true;

    if (projectConfig.data.facebook_pixel_tracking_id) {
      ReactPixel.init(projectConfig.data.facebook_pixel_tracking_id, {}, { autoConfig: true, debug });
    }
  };

  componentDidUpdate = prevProps => {
    const { cookies } = this.props;

    // check if cookies were saved:
    if (!cookies.pending && prevProps.cookies.pending) {
      // check if we can close the cookie message that is below the page
      if (!prevProps.cookies.boxClosed && cookies.boxClosed) {
        this.setState({ justClosed: true, hide: true });
        this.justClosedCookieInfoTimeout = setTimeout(() => {
          this.setState({ justClosed: false });
        }, 300);
      }

      // check if the performance cookie settings changed
      if (cookies.performance !== prevProps.cookies.performance) {
        if (cookies.performance) {
          if (!this.performanceInit) {
            this.initPerformance();
          }
        }
      }

      // check if the targeting cookie settings changed
      if (cookies.targeting !== prevProps.cookies.targeting) {
        if (cookies.targeting) {
          if (!this.targetingInit) {
            this.initTargeting();
          }
        }
      }
    }
  };

  render() {
    const {
      intl: { messages },
      appIntl,
      projectConfig,
      cookies,
    } = this.props;
    const { cookiesPerformanceChecked, cookiesTargetingChecked, buttonUsed } = this.state;

    if (this.state.hide) {
      return null;
    }

    const isInEu = inEU();
    const isPerformanceVisible = arePerformanceCookiesUsed(projectConfig.data);
    const isTargetingVisible = areTargetingCookiesUsed(projectConfig.data);

    const pendingIcon = <i className="fa fa-circle-notch fa-spin m-r-5" />;

    const res = [<CookieConsentInfos show={this.state.showInfos} close={this.closeInfos} key="infos" />];

    const cookieSettingsSaveText =
      cookiesPerformanceChecked || cookiesTargetingChecked
        ? messages.save_and_continue
        : messages.accept_only_necessary_cookies;

    if (!this.state.showInfos) {
      res.push(
        <Modal show className="full-width stick-bottom" key="bottomInfos">
          <Modal.Header>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid className="no-padding">
              <Row>
                <Col xs={12} sm={8}>
                  <Row>
                    <Col xs={12}>
                      <span className="cc-message">{messages.this_website_uses_cookies}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <div className="checkbox check-success">
                        <input
                          disabled={true}
                          id="cookiesNecessary"
                          name="cookiesNecessary"
                          type="checkbox"
                          value="cookiesNecessary"
                          checked={true}
                          onChange={this.checkboxChange}
                        />
                        <label for="cookiesNecessary">{messages.necessary_cookies}</label>
                      </div>
                    </Col>

                    {isPerformanceVisible && (
                      <Col xs={4}>
                        <div className="checkbox check-success">
                          <input
                            disabled={false}
                            id="cookiesPerformance"
                            name="cookiesPerformance"
                            type="checkbox"
                            value="cookiesPerformance"
                            checked={cookiesPerformanceChecked}
                            onChange={this.checkboxChange}
                          />
                          <label for="cookiesPerformance">{messages.performance_cookies}</label>
                        </div>
                      </Col>
                    )}

                    {isTargetingVisible && (
                      <Col xs={4}>
                        <div className="checkbox check-success">
                          <input
                            disabled={false}
                            id="cookiesTrageting"
                            name="cookiesTrageting"
                            type="checkbox"
                            value="cookiesTrageting"
                            checked={cookiesTargetingChecked}
                            onChange={this.checkboxChange}
                          />
                          <label for="cookiesTrageting">{messages.targeting_cookies}</label>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col xs={12} sm={4}>
                  {isInEu ? (
                    <div className="cc-compliance text-center sm-p-t-20">
                      <Button block onClick={this.clickAll} bsStyle="primary" disabled={cookies.pending} data-cy="acceptCookies">
                        {cookies.pending && buttonUsed === 'saveAll' && pendingIcon}
                        {messages.accept_all_cookies}
                      </Button>
                      <Button block onClick={this.clickSaveCookieSettings} disabled={cookies.pending}>
                        {cookies.pending && buttonUsed === 'saveSettings' && pendingIcon}
                        {cookieSettingsSaveText}
                      </Button>
                      <div className="m-t-10 small p-t-15">
                        <a href="#showInfos" className="text-black m-r-5" onClick={this.showInfos}>
                          {messages.cookie_settings}
                        </a>
                        <Link to={appIntl.urlPrefix + 'privacy'} className="text-black m-r-5">
                          | {messages.signup_agreement_text_data_use}
                        </Link>
                        <Link to={appIntl.urlPrefix + 'imprint'} className="text-black">
                          | {messages.imprint}
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className="cc-compliance text-right">
                      <Button onClick={this.clickAll} disabled={cookies.pending}>
                        {cookies.pending && buttonUsed === 'saveAll' && pendingIcon}
                        {messages.got_it}
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
            </Grid>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      );
    }

    return res;
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    webApp: state.webApp,
    user: state.login.user,
    cookies: state.cookies,
    projectConfig: state.data.projectConfig,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    saveCookie: cookies => {
      dispatch(saveCookie(cookies));
    },
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CookieConsent)
);
