import React from 'react';
import { connect } from 'react-redux';

import { updateActiveCms } from 'actions/LayoutActions';

import Data from 'components/Data';
import CmsPage from 'components/CmsPage';

import Loader from 'components/layout/Loader';

class Locations extends React.Component {
  componentDidMount = () => {
    this.props.updateActiveCms('cmsSystemPageLocations');
  };

  componentWillUnmount = () => {
    this.props.updateActiveCms(null);
  };

  render() {
    const { cmsPage } = this.props;

    return (
      <div>
        {!cmsPage.data.id ? (
          <Data dataName="cmsSystemPageLocations" url="/api/pages/locations.json" data={cmsPage} />
        ) : null}

        {cmsPage.pending ? (
          <div class="content">
            <Loader />
          </div>
        ) : (
          <CmsPage data={cmsPage.data} doUpdateActiveCms={false} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cmsPage: state.data.cmsSystemPageLocations,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateActiveCms: activeCms => {
      dispatch(updateActiveCms(activeCms));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Locations);
