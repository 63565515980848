import axios from 'axios';

export const DELETER_PENDING = 'DELETER_PENDING';
export const DELETER_SUBMIT = 'DELETER_SUBMIT';
export const DELETER_FULFILLED = 'DELETER_FULFILLED';
export const DELETER_REJECTED = 'DELETER_REJECTED';

export function deleterPending(deleterName) {
  return {
    type: DELETER_PENDING,
    payload: { deleterName },
  };
}

export function deleterSend(url, finishedCallback, errorCallback, method = 'delete') {
  let fullUrl = url;
  if (fullUrl.indexOf('?') === -1) {
    fullUrl += '?';
  }

  fullUrl += '_locale=' + globalLocale;

  return {
    type: DELETER_SUBMIT,
    payload: axios({
      method: method,
      url: fullUrl,
    })
      .then(finishedCallback)
      .catch(errorCallback),
  };
}

export function deleterFulfilled(deleterName, response) {
  return {
    type: DELETER_FULFILLED,
    payload: {
      deleterName,
      response,
    },
  };
}

export function deleterRejected(deleterName, response) {
  return {
    type: DELETER_REJECTED,
    payload: {
      deleterName,
      response: response.response,
    },
  };
}

export function deleterDispatchHelper(deleterName, url, dispatch) {
  dispatch(deleterPending(deleterName));

  // finished callback will trigger an event
  const finishedCallback = response => {
    return dispatch(deleterFulfilled(deleterName, response));
  };
  // error callback will trigger an event
  const errorCallback = error => {
    return dispatch(deleterRejected(deleterName, error));
  };
  // dispatch fetching the deleted id
  dispatch(deleterSend(url, finishedCallback, errorCallback));
}
