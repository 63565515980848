import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';

function TextareaEditor({ value, onChange, className, name, ...props }) {
  const { messages } = useIntl();
  const [isEditable, setIsEditable] = useState(false);
  const [editValue, setEditValue] = useState(value);

  return (
    <div className={`textarea-editor ${className}`} {...props} onClick={() => !isEditable && setIsEditable(true)}>
      {isEditable ? (
        <>
          <TextareaAutosize
            className="textarea-editor__textarea"
            value={editValue}
            placeholder={props.placeholder || ''}
            name={name}
            onChange={e => setEditValue(e.target.value)}
            // minRows={8}
            autoFocus
          />
          <div className="textarea-editor__action-bar">
            <Button bsStyle="secondary" bsSize="small" onClick={() => setIsEditable(false)}>
              {messages.cancel}
            </Button>
            <Button
              bsStyle="primary"
              bsSize="small"
              className="m-l-5"
              onClick={() => onChange(editValue) || setIsEditable(false)}
            >
              {messages.save}
            </Button>
          </div>
        </>
      ) : (
        <span className="textarea-editor__text">
          {!value && <>{props.placeholder || ''}</>}
          {value.split(/(?:\r\n|\r|\n)/).map(line => (
            <React.Fragment key={line}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </span>
      )}
    </div>
  );
}

TextareaEditor.defaultProps = {
  value: '',
  className: '',
  onChange: () => {},
  name: 'textareaEditor',
};

export default TextareaEditor;
