import React from 'react';
// import PropTypes from 'prop-types';

// CartWizardStepFrame.propTypes = {};

function CartWizardStepFrame({ children }) {
  return <div className="wizard-step-frame">{children}</div>;
}

export default CartWizardStepFrame;
