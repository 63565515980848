import React from 'react';
import { components } from 'react-select';

export class EmailTemplateOption extends React.Component {
  render() {
    const { data } = this.props;
    return <components.Option {...this.props}>{data.label}</components.Option>;
  }
}

export class EmailTemplateValue extends React.Component {
  render() {
    const { data } = this.props;

    return <div className="Select-value">{data.label}</div>;
  }
}

export function emailTemplateOptionFormatter(options) {
  return options.list.map(option => {
    return { ...option, value: option.id, label: option.subject };
  });
}
