import React from 'react';
import { connect } from 'react-redux';
import { Badge } from 'react-bootstrap';
import { components } from 'react-select';
import { forEach as _forEach } from 'lodash';

import { getNameInCurrentLanguage } from 'helpers/intl';

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

class DocTypeOptionWithProps extends React.Component {
  render() {
    const { data, appIntl } = this.props;
    return (
      <components.Option {...this.props}>
        {!!data.id && <Badge className="m-r-10 text-uppercase p-l-10 p-r-10">{appIntl.displayIntlForm}</Badge>}
        {getNameInCurrentLanguage(data.name, appIntl)}
      </components.Option>
    );
  }
}
const DocTypeOption = connect(mapStateToProps)(DocTypeOptionWithProps);

class DocTypeValueWithProps extends React.Component {
  render() {
    const { data, appIntl } = this.props;

    return (
      <div className="Select-value">
        {!!data.id && <Badge className="m-r-10 text-uppercase p-l-10 p-r-10">{appIntl.displayIntlForm}</Badge>}
        {getNameInCurrentLanguage(data.name, appIntl)}
      </div>
    );
  }
}
const DocTypeValue = connect(mapStateToProps)(DocTypeValueWithProps);

const docTypeOptionFormatter = options => {
  return options.list.map(option => {
    return docTypeSingleOptionFormatter(option);
  });
};

const docTypeSingleOptionFormatter = (option = null) => {
  if (option.id) {
    let label = '';
    _forEach(option.name, langName => {
      if (langName.name !== undefined) {
        label += langName.name + ' ';
      }
    });
    return { ...option, value: option.id, label };
  }

  return null;
};
const docTypeOptionFromEntity = docType => {
  return !docType
    ? null
    : docTypeSingleOptionFormatter({
        id: docType.getId(),
        name: docType.getName().map(langName => {
          return { lang: langName.getLang(), name: langName.getName() };
        }),
      });
};

export { DocTypeValue, DocTypeOption, docTypeOptionFormatter, docTypeSingleOptionFormatter, docTypeOptionFromEntity };
