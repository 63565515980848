import React from 'react';

import RichEditorContent from 'components/RichEditorContent';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

export default class CmsElementTimelineBlock extends AbstractCmsElement {
  render() {
    return (
      <div class="timeline-block">
        <div class="timeline-point complete text-center">
          <i class={this.getParamValue('icon', 'far fa-circle')} />
        </div>
        <div class="timeline-content">
          <div class="card share full-width">
            <div class="card-block">
              <RichEditorContent content={'<p class="no-margin">' + this.getParamValue('content', '') + '</p>'} />
            </div>
            <div class="event-date">
              <small class="fs-12 hint-text">
                <span>{this.getParamValue('title', '')}</span>
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
