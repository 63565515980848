import axios, { CancelToken } from 'axios';
import { toQueryString } from 'helpers/http';

export const LIST_PENDING = 'LIST_PENDING';
export const LIST_SUBMIT = 'LIST_SUBMIT';
export const LIST_FULFILLED = 'LIST_FULFILLED';
export const LIST_REJECTED = 'LIST_REJECTED';
export const LIST_RETRY = 'LIST_RETRY';
export const CHANGE_LIST_SORT = 'CHANGE_LIST_SORT';
export const CHANGE_ACTIVE_PAGE = 'CHANGE_ACTIVE_PAGE';
export const SAVE_SETTINGS_EDIT = 'SAVE_SETTINGS_EDIT';
export const SAVE_SETTINGS_NEW = 'SAVE_SETTINGS_NEW';
export const SET_VARIABLES_R = 'SET_VARIABLES_R';
export const ARCHIVE_CAMPAIGN = 'ARCHIVE_CAMPAIGN';
export const MARK_RECENT_EDITION = 'MARK_RECENT_EDITION';
export const UNMARK_MARKED_EDITION = 'UNMARK_MARKED_EDITION';
export const IMPORT_USERS = 'IMPORT_USERS';
export const REMOVE_USERS = 'REMOVE_USERS';
export const REVERSE_USERS = 'REVERSE_USERS';
export const REVERSE_REMOVED_USERS = 'REVERSE_REMOVED_USERS';
export const NEW_UPDATE_NOTIFICATION = 'NEW_UPDATE_NOTIFICATION';
export const EDIT_UPDATE_NOTIFICATION = 'EDIT_UPDATE_NOTIFICATION';
export const DELETE_UPDATE_NOTIFICATION = 'DELETE_UPDATE_NOTIFICATION';
export const GET_UPDATE_NOTIFICATION = 'GET_UPDATE_NOTIFICATION';
export const SET_PRODUCT_GROUP_STATUS = 'SET_PRODUCT_GROUP_STATUS';
export const SET_NEW_PRODUCT_GROUP = 'SET_NEW_PRODUCT_GROUP';
export const EDIT_PRODUCT_GROUP = 'EDIT_PRODUCT_GROUP';
export const DELETE_PRODUCT_GROUP = 'DELETE_PRODUCT_GROUP';
export const REMOVE_COMPANY_LOCATION_IMAGE = 'REMOVE_COMPANY_LOCATION_IMAGE';

export const GET_COUPON_BALANCE = 'GET_COUPON_BALANCE';

export const RESTORE_PRODUCT_TEMPLATE = 'RESTORE_PRODUCT_TEMPLATE';

// repeated function that only extracts data property
const getData = ({ data }) => data;

export const listPending = (listName, data) => {
  return {
    type: LIST_PENDING,
    payload: { listName, ...data },
  };
};

export const listSend = (url, data, finishedCallback, errorCallback, cancelToken, method = 'get') => {
  let fullUrl = url;
  if (fullUrl.indexOf('?') === -1) {
    fullUrl += '?';
  }

  if (method === 'get' && data !== undefined && data) {
    fullUrl += toQueryString(data) + '&';
  }
  fullUrl += '_locale=' + globalLocale;

  return {
    type: LIST_SUBMIT,
    payload: axios({
      method,
      url: fullUrl,
      data: method !== 'get' ? { ...data } : null,
      cancelToken,
    })
      .then(finishedCallback)
      .catch(errorCallback),
  };
};

export const listFulfilled = (listName, response) => {
  return {
    type: LIST_FULFILLED,
    payload: {
      listName,
      response,
    },
  };
};

export const listRejected = (listName, response) => {
  return {
    type: LIST_REJECTED,
    payload: {
      listName,
      response: response.response,
    },
  };
};

export const changeListSort = (listName, sort, dir) => {
  return {
    type: CHANGE_LIST_SORT,
    payload: {
      listName,
      sort,
      dir,
    },
  };
};

export const changeActivePage = (listName, activePage, resPerPage) => {
  return {
    type: CHANGE_ACTIVE_PAGE,
    payload: {
      listName,
      activePage,
      resPerPage,
    },
  };
};

export const removeCompanyLocationImage = (locationIndex, imageIndex) => {
  return {
    type: REMOVE_COMPANY_LOCATION_IMAGE,
    payload: {
      locationIndex,
      imageIndex,
    },
  };
};

export const listRetryCount = (listName, retryCount) => {
  return {
    type: LIST_RETRY,
    payload: {
      listName,
      retryCount,
    },
  };
};

export const listDispatchHelper = (listName, url, data, dispatch) => {
  let retryAttempts = 0;
  const retryAttemptsMax = 10;

  // DEPRECATED @see https://github.com/axios/axios#canceltoken-deprecated
  const source = CancelToken.source();

  // add the request to queue
  dispatch(
    listPending(listName, {
      sort: data.sort,
      dir: data.dir,
      activePage: data.page,
      resPerPage: data.limit,
      searchTerm: data.search || '',
    })
  );

  // finished callback will trigger an event
  const finishedCallback = response => {
    return dispatch(listFulfilled(listName, response));
  };
  // error callback will trigger an event
  const errorCallback = error => {
    if (!axios.isCancel(error)) {
      if ((!error.response || !error.response.status) && retryAttempts < retryAttemptsMax) {
        retryAttempts++;

        dispatch(listRetryCount(listName, retryAttempts));
        setTimeout(() => {
          dispatch(listSend(url, data, finishedCallback, errorCallback, source.token));
        }, retryAttempts * retryAttempts * 1000);
      } else {
        return dispatch(listRejected(listName, error));
      }
    }
  };
  // dispatch the request
  dispatch(listSend(url, data, finishedCallback, errorCallback, source.token));

  return source;
};

// ---------------- CampaignSettings.js --------------------
export function campaignSettingsEditR({ id }, json) {
  return {
    type: SAVE_SETTINGS_EDIT,
    payload: axios.put(`/api/campaigns/${id}.json`, { json }).then(getData),
  };
}

// ---------------- CampaignSettings.js --------------------
export function campaignSettingsNewR(params) {
  return {
    type: SAVE_SETTINGS_NEW,
    payload: axios.post('/api/campaigns.json', { json: JSON.stringify(params) }).then(getData),
  };
}

// --------- campaigns --------------------------------
export function setVariablesAboutListR(key) {
  return {
    type: SET_VARIABLES_R,
    payload: key,
  };
}
// ----------- archive campaigns ------------------------
export function storeCampaignR(campaignId, option) {
  return {
    type: ARCHIVE_CAMPAIGN,
    payload: axios.put(`/api/campaigns/${campaignId}/archives/${option}.json`).then(getData),
  };
}

export function markRecentEditionR(editionId, mark) {
  return {
    type: MARK_RECENT_EDITION,
    payload: axios.put(`/api/editions/${editionId}.json`, { json: JSON.stringify({ marked: mark }) }).then(getData),
  };
}

export function unmarkMarkedEditionR(editionId, mark) {
  return {
    type: UNMARK_MARKED_EDITION,
    payload: axios.put(`/api/editions/${editionId}.json`, { json: JSON.stringify({ marked: mark }) }).then(getData),
  };
}

export function importUsers(formData, uploadProgress, files) {
  return {
    type: IMPORT_USERS,
    payload: axios.post('/api/imports/users.json', formData, uploadProgress).then(({data}) => ({...data, files})),
  };
}

export function removeUsers(formData, uploadProgress, files) {
  return {
    type: REMOVE_USERS,
    payload: axios.post('/api/removes/users.json', formData, uploadProgress).then(({ data }) => ({ ...data, files })),
  };
}

export function reverseUsers(formData, uploadProgress, files) {
  return {
    type: REVERSE_USERS,
    payload: axios
      .post('/api/revertlastimports/users.json', formData, uploadProgress)
      .then(({ data }) => ({ ...data, files })),
  };
}

export function reverseRemovedUsers(formData, uploadProgress, files) {
  return {
    type: REVERSE_REMOVED_USERS,
    payload: axios
      .post('/api/revertlastdeletes/users.json', formData, uploadProgress)
      .then(({ data }) => ({ ...data, files })),
  };
}

export function getUpdateNotificationR() {
  return {
    type: GET_UPDATE_NOTIFICATION,
    payload: axios.get('/api/updates.json').then(getData),
  };
}

export function editUpdateNotificationR(data) {
  const { id } = data;
  return {
    type: EDIT_UPDATE_NOTIFICATION,
    payload: axios.put(`/api/updates/${id}.json`, data).then(getData),
  };
}

export function addUpdateNotificationR(data) {
  return {
    type: NEW_UPDATE_NOTIFICATION,
    payload: axios.post('/api/updates.json', data).then(getData),
  };
}

export function deleteUpdateNotificationR(id) {
  return {
    type: DELETE_UPDATE_NOTIFICATION,
    payload: axios.delete(`/api/updates/${id}.json`).then(getData),
  };
}

export function setProductGroupStatusR(data, id) {
  return {
    type: SET_PRODUCT_GROUP_STATUS,
    payload: axios.put(`/api/flyeralarm/productgroups/deactivates/${id}/fa/product/group.json`, data).then(getData),
  };
}

export function setNewProductGroupR(data) {
  return {
    type: SET_NEW_PRODUCT_GROUP,
    payload: axios.post('/api/flyeralarm/productgroups/fas/products/groups.json', data).then(getData),
  };
}

export function editProductGroupR(data, id) {
  return {
    type: EDIT_PRODUCT_GROUP,
    payload: axios.put(`/api/flyeralarm/productgroups/fas/${id}/product/group.json`, data).then(getData),
  };
}

export function deleteProductGroupR(id) {
  return {
    type: DELETE_PRODUCT_GROUP,
    payload: axios.delete(`/api/flyeralarm/productgroups/fas/${id}/product/group.json`).then(getData),
  };
}

export function getCouponBalance(couponId) {
  return {
    type: GET_COUPON_BALANCE,
    payload: axios.get(`/api/coupondefinitions/${couponId}/balance.json`).then(({ data }) => data),
  };
}

export function restoreProductTemplateR(id) {
  return {
    type: RESTORE_PRODUCT_TEMPLATE,
    payload: axios.put(`/api/restoreproducttemplates/${id}.json`).then(({ data }) => data),
  };
}
