import _ from 'lodash';

const initialState = {
  contacts: [],
  count: 0,
  offset: 0,
  limit: 20,
  pending: false,
  page: 1,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'CONTACT_LIST_PENDING': {
      return { ...state, pending: true };
    }
    case 'CONTACT_LIST_FULFILLED': {
      const { list, count, offset, limit, page } = payload;
      return { ...state, contacts: list, count, offset, limit, pending: false, page };
    }
    case 'CONTACTS_FETCH_LIST': {
      return { ...state, pending: true };
    }
    case 'CONTACTS_FETCH_LIST_COMPLETE': {
      const { list, count, offset, limit } = payload.data;
      return { ...state, contacts: list, count, offset, limit, pending: false, page: 1 };
    }
    case 'CONTACTS_CREATE_COMPLETE': {
      let { contacts } = state;
      const addedContact = payload.entity;
      contacts.push(addedContact);
      return { ...state, contacts };
    }
    case 'CONTACTS_UPDATE_COMPLETE': {
      let { contacts } = state;
      const contactPayload = payload.entity;
      const index = _.findIndex(contacts, contact => contact.id === contactPayload.id);
      let _contacts = contacts;
      _contacts[index] = contactPayload;
      return { ...state, contacts: _contacts };
    }
    case 'CONTACTS_DELETE_COMPLETE': {
      let { contacts } = state;
      let id = payload?.entity?.id;
      contacts = _.filter(contacts, x => x.id !== id);
      return { ...state, contacts };
    }
  }
  return state;
};
