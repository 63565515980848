import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class FormSwitchery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValue: props.value ? props.value : '',
      value: props.value ? props.value : '',
      checked: props.checked ? props.checked : false,
    };

    this.inputRef = React.createRef();
  }

  componentDidUpdate = prevProps => {
    const { checked } = this.props;

    if (!prevProps.checked && checked) {
      this.setState({ checked: true });
    }
  };

  // check if user may write to this field
  checkReadOnly = () => {
    const { meta } = this.props;
    return !meta.acl.write;
  };

  onChange = e => {
    const { target } = e;
    let { value } = target;
    if (value === 'true') {
      value = true;
    }
    const { checked } = target;
    const { name, onChange } = this.props;

    this.setState({ checked });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      onChange(name, checked ? value : false);
    }, 150);
  };

  checkDisabled = () => {
    const { disabled, meta } = this.props;

    if (disabled) {
      return true;
    }
    if (!meta.acl.write) {
      return true;
    }

    return false;
  };

  setValue = checked => {
    this.setState({ checked });
    // this.onChange({ target: { value: checked ? value : false }})
  };

  render() {
    const { label, name, color, infoText, preventChangeOnClick, onClick } = this.props;
    const { value, checked } = this.state;

    return (
      <span className={'clearfix switchery-container ' + (this.checkDisabled() ? 'disabled ' : '')} onClick={onClick}>
        <input
          ref={this.inputRef}
          value={value || ''}
          type="checkbox"
          checked={checked}
          name={name}
          onChange={this.onChange}
          disabled={this.checkDisabled()}
          style={{ display: 'none' }}
          id={'switchery' + name}
        />
        <span
          onClick={() => !preventChangeOnClick && this.inputRef.current.click()}
          className={
            'pull-left switchery switchery-small switchery-' +
            color +
            ' ' +
            (checked ? 'switchery-checked ' : 'switchery-unchecked ')
          }
        >
          <small />
        </span>

        <label
          for={'switchery' + name}
          className="p-l-10 pull-left"
          onClick={e => preventChangeOnClick && e.preventDefault()}
        >
          {label}
          {!!infoText && (
            <span className="m-l-5">
              <OverlayTrigger placement="top" overlay={<Tooltip>{infoText}</Tooltip>}>
                <i className="far fa-question-circle cursor-help" />
              </OverlayTrigger>
            </span>
          )}
        </label>
      </span>
    );
  }
}
FormSwitchery.defaultProps = {
  label: '',
  meta: { validate: { rules: '' }, acl: { read: true, write: true } },
  value: '',
  onChange: () => {},
  onClick: () => {},
  preventChangeOnClick: false,
  disabled: false,
  color: 'primary',
  infoText: '',
};

export default FormSwitchery;
