import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';

import { elementSource, elementTarget } from './elementDragUtils';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';
import Loader from 'components/layout/Loader';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
export default class CmsElementUploadedVideo extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  render() {
    const { connectDropTarget, connectDragSource, connectDragPreview } = this.props;
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const wrapperClass =
      'ms-uploaded-video-wrapper ms-uploaded-video-wrapper-' + this.getParamValue('videoAspectRatio', '16-9') + ' ';
    const isVideoSelected = !!this.getParamValue('videoFile');
    const isUploaded = this.getParamValue('videoUploaded') === '1';
    const thumbUrl = this.getParamValue('videoThumbUrl');
    const imageUrl = this.getParamValue('imageUrl', '');

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={wrapperClass + shownClass + this.getCssClasses()}
        onClick={this.showSettings}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <div class="ms-uploaded-video-player-content">
          {isVideoSelected && isUploaded ? (
            <video autoPlay loop controls poster={imageUrl || undefined}>
              <source src={thumbUrl} type="video/mp4" />
            </video>
          ) : (
            <div className="p-t-15 p-b-15 text-center bg-master-lightest">
              {isVideoSelected && !isUploaded ? <Loader /> : <i className="fas fa-play fa-4x" />}
            </div>
          )}
        </div>

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}
