import React from 'react';
import { connect } from 'react-redux';
import { isEqual as _isEqual } from 'lodash';

import { entityMetaMapping } from 'js/constants';

import EntityMeta from 'components/EntityMeta';

import { dataDispatchHelper } from 'actions/DataActions';

class Data extends React.Component {
  constructor(props) {
    super(props);

    this.currentRequestSource = null;
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate = prevProps => {
    const { appIntl, dataName, url, requestData } = this.props;

    if (
      (dataName !== prevProps.dataName && url) ||
      (url !== prevProps.url && dataName) ||
      appIntl.locale !== prevProps.appIntl.locale ||
      !_isEqual(requestData, prevProps.requestData)
    ) {
      this.fetch();
    }
  };

  setCurrentRequestSource = currentRequestSource => {
    this.currentRequestSource = currentRequestSource;
  };

  fetch = () => {
    const { dataName, url, requestData, method, fetch } = this.props;

    // fetch data
    if (dataName && url) {
      fetch(this.currentRequestSource, this.setCurrentRequestSource, dataName, url, requestData, method);
    }
  };

  render() {
    const { dataName, meta } = this.props;

    return entityMetaMapping[dataName] !== undefined ? (
      <EntityMeta entityMetaIdentifier={entityMetaMapping[dataName]} metaData={meta[entityMetaMapping[dataName]]} />
    ) : null;
  }
}
Data.defaultProps = {
  dataName: '',
  url: '',
  data: {},
  requestData: {},
  method: 'get',
};

const mapStateToProps = (state, ownProps) => {
  return {
    meta: state.entityMeta,
    appIntl: state.intl,
    data: state.data[ownProps.dataName],
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetch: (currentRequestSource, setCurrentRequestSource, dataName, url, data, method) => {
      if (currentRequestSource) {
        currentRequestSource.cancel();
      }
      setCurrentRequestSource(dataDispatchHelper(dataName, url, data, method, dispatch));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(Data);
