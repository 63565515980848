import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Grid } from 'react-bootstrap';

import { mayNavigateToE } from 'helpers/checkout';

import StepDCarRegistration from 'components/layout/checkout/StepDCarRegistration';
// import StepDCarInsurance from 'components/layout/checkout/StepDCarInsurance'

// additional services when buying a car
class StepD extends React.Component {
  mayProceed = () => {
    const { checkout } = this.props;
    return mayNavigateToE(checkout);
  };

  clickNext = () => {
    if (this.mayProceed()) {
      const { appIntl, history } = this.props;
      history.push(appIntl.urlPrefix + 'checkout/shipping');
    }
  };

  clickPrevious = () => {
    const { appIntl, history } = this.props;
    history.push(appIntl.urlPrefix + 'checkout/personal');
  };

  render() {
    const {
      intl: { messages },
      projectConfig,
    } = this.props;

    // when we add car insurance to our services, add this code after car registration service
    // <hr /> <StepDCarInsurance {...this.props} />
    // const carRegistrationServiceData = getCarRegistrationServiceData(checkout);

    return (
      <Grid fluid>
        <div class="panel panel-transparent">
          <StepDCarRegistration {...this.props} projectConfig={projectConfig} includeTotal />
        </div>

        <div class="padding-20 sm-padding-5 sm-m-b-20 sm-m-t-20 bg-white clearfix">
          <ul class="pager wizard no-style">
            <li class={'next ' + (this.mayProceed() ? '' : 'disabled ')} style={{ display: 'list-item' }}>
              <button
                class="btn btn-primary btn-cons pull-right btn-animated from-left fa fa-truck"
                type="button"
                onClick={this.clickNext}
              >
                <span>{messages.checkout_next}</span>
              </button>
            </li>

            <li class="previous">
              <button class="btn btn-default btn-cons pull-right" type="button" onClick={this.clickPrevious}>
                <span>{messages.checkout_previous}</span>
              </button>
            </li>
          </ul>
        </div>
      </Grid>
    );
  }
}
StepD.defaultProps = {};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    projectConfig: state.data.projectConfig,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(StepD)));
