import React from 'react';

export default class CheckoutSummaryCarRentalPickupLocation extends React.Component {
  render() {
    const {
      intl: { messages },
      checkout,
      className,
    } = this.props;

    if (!checkout.locationData) {
      return null;
    }

    return (
      <div className={className}>
        <h4 className="m-t-0">{messages.pick_up_and_drop_off}</h4>

        <div>
          <h5 className="semi-bold m-t-0">{checkout.locationData.name}</h5>
          <address>
            {checkout.locationData.street} {checkout.locationData.house_number} {checkout.locationData.apartment_number}
            <br />
            {checkout.locationData.zip_code}, {checkout.locationData.city}
          </address>
        </div>
      </div>
    );
  }
}
CheckoutSummaryCarRentalPickupLocation.defaultProps = {
  className: '',
};
