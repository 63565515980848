import React from 'react';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import { Label } from 'react-bootstrap';
import moment from 'moment';
import 'moment-timezone';
import { find as _find, forEach as _forEach } from 'lodash';

import { getNameInCurrentLanguage } from 'helpers/intl';
import { getNetFromGross } from 'helpers/financial';

import IntlDate from 'components/layout/IntlDate';

export const mapping = {
  brand: '000000000000000000000001',
  model: '000000000000000000000002',
  power: '59b119db29b1d60028469f9d',
  firstRegistration: '59c3855229b1d6037f5f0e71',
  mileage: '59b1167929b1d6002703e4c5',
  gearbox: '59b0194029b1d600142386c7',
  fuelType: '59b015f929b1d6001423869b',
  capacity: '59b11c0529b1d60028469fa5',
  paintColor: '5950e06ae3f1310d0347c0a1',
  paintColorManufacturer: '59b1164129b1d60028469f99',
  carType: '59afe9fa29b1d6000874e1d1',
  numPreviousOwner: '59b11c9e29b1d60028469fa9',
  emissionClass: '59b014ee29b1d600151f5855',
  numDoors: '59b013db29b1d60014238691',
  carCondition: '59b1158129b1d6002703e4c1',
  numSeats: '59b11a9329b1d600265622f9',
  constructionYear: '59b11ab829b1d60028469fa1',
  parkingAssistance: '59c4d6f329b1d600813fecd4',
  interiorMaterial: '59b01dec29b1d600171161cd',
  airbags: '59b25bc329b1d6017a38e8b1',
  airConditioning: '59b01ab829b1d600142386cb',
  environmentBadge: '5a60a27847ce0d01fc059a91',
  co2Emissions: '59c5381329b1d6013031dee9',
  fuelConsumptionCombined: '59c514f829b1d601314beb11',
  fuelConsumptionUrban: '59c5154e29b1d6012f6c6491',
  fuelConsumptionExtraUrban: '59c515f529b1d6013031dee1',
  energyEfficiencyClass: '59c518e729b1d6013031dee5',
};

export const carCategories = [
  '0000000006',
  '0000000007',
  '0000000008',
  '0000000009',
  '000000000A',
  '000000000B',
  '000000000C',
  '000000000D',
  '000000000E',
  '000000000F',
  '000000000G',
  '000000000H',
];

export const categories = {
  passengerVehicles: '0000000006',
  passengerVehiclesPath: ',0,0000000006,',
};

export const carIcons = {
  'Mercedes-Benz': 'mercedes',
  Audi: 'audi',
  BMW: 'bmw',
  Ford: 'ford',
  FORD: 'ford',
  Hyundai: 'hyundai',
  Jaguar: 'jaguar',
  JEEP: 'jeep',
  Jeep: 'jeep',
  'Land-Rover': 'land-rover',
  'Land Rover': 'land-rover',
  MAN: 'man',
  Mini: 'mini',
  Peugeot: 'peugeot',
  Renault: 'renault',
  Scania: 'scania',
  Seat: 'seat',
  SEAT: 'seat',
  Skoda: 'skoda',
  smart: 'smart',
  Volkswagen: 'volkswagen',
  VW: 'volkswagen',
  Volvo: 'volvo',
  Mitsubishi: 'mitsubishi',
  PORSCHE: 'porsche',
  Porsche: 'porsche',
  Suzuki: 'suzuki',
  SUZUKI: 'suzuki',
  DS: 'ds',
  Citroen: 'citroen',
  CITROEN: 'citroen',
  Microcar: 'microcar',
  MICROCAR: 'microcar',
};

export const typeIcons = {
  '59afeb1329b1d60009144831': 'chedri-cabrio-car chedri-3x',
  '59afeb1329b1d60009144835': 'chedri-sedan-car-model chedri-3x',
  '59afeb1329b1d60009144839': 'chedri-car-suv chedri-3x',
  '59afeb1329b1d6000914483d': 'chedri-jeep chedri-3x',
  '59afeb1329b1d60009144841': 'chedri-car-city-model chedri-3x',
  '59afec4529b1d60009144845': 'chedri-sportive-car chedri-3x',
  '59afec4529b1d60009144849': 'chedri-minivan-car chedri-3x',
  '59afec4529b1d6000914484d': 'far fa-ellipsis-h fa-3x',
};

export const energyLabels = [
  { label: 'A+', color: '#00a650' },
  { label: 'A', color: '#00a650' },
  { label: 'B', color: '#4db748' },
  { label: 'C', color: '#c0d434' },
  { label: 'D', color: '#fdf300' },
  { label: 'E', color: '#ffb80e' },
  { label: 'F', color: '#f37022' },
  { label: 'G', color: '#ed1b24' },
];

export const equipmentLevels = messages => {
  return [
    {
      name: messages.equipment_level_budget,
      value: 'budget',
      description: (
        <div class="p-b-15 small text-center">
          Radio
          <br />
          {messages.car_air_conditioning}
        </div>
      ),
      icons: { 'chedri-car-radio': 'Radio', 'fal fa-snowflake': messages.car_air_conditioning },
    },
    {
      name: messages.equipment_level_family,
      value: 'family',
      description: (
        <div class="p-b-15 small text-center">
          <div class="m-b-5">{messages.like_budget}</div> + Bluetooth
          <br />+ {messages.parkingAssistanceRear}
        </div>
      ),
      icons: {
        'fal fa-plus': messages.like_budget,
        'fab fa-bluetooth': 'Bluetooth',
        'chedri-car-sensor': messages.parkingAssistanceRear,
      },
    },
    {
      name: messages.equipment_level_comfort,
      value: 'comfort',
      description: (
        <div class="p-b-15 small text-center">
          <div class="m-b-5">{messages.like_family}</div> + {messages.airConditioningAutomatic}
          <br />+ {messages.navigation}
          <br />+ {messages.alloyRims}
          <br />+ {messages.parkingAssistanceFrontRear}
        </div>
      ),
      icons: {
        'fal fa-plus m-r-10': messages.like_family,
        'chedri-auto-ac': messages.airConditioningAutomatic,
        'chedri-gps': messages.navigation,
        'chedri-wheel': messages.alloyRims,
        'chedri-car-sensors': messages.parkingAssistanceFrontRear,
      },
    },
    {
      name: messages.equipment_level_business,
      value: 'business',
      description: (
        <div class="p-b-15 small text-center">
          <div class="m-b-5">{messages.like_comfort}</div> + {messages.metallicPaint}
          <br />+ {messages.cruiseControl}
          <br />+ {messages.seatHeating}
          <br />+ {messages.leatherSeats}
        </div>
      ),
      icons: {
        'fal fa-plus m-r-10': messages.like_comfort,
        'chedri-car-painting': messages.metallicPaint,
        'chedri-tachometer': messages.cruiseControl,
        'chedri-heated-sets': messages.seatHeating,
        'chedri-leather': messages.leatherSeats,
      },
    },
  ];
};

export const getDefaultImage = item => {
  let defaultImage;

  if (item.default_img) {
    defaultImage = item.default_img;
  }

  return defaultImage;
};

export const getFileVariation = (image, variationType) => {
  let variationFile;

  if (image) {
    _forEach(image.variations, variation => {
      if (variation.variation_type === variationType) {
        variationFile = variation.file;
      }
    });
  }

  return variationFile;
};

export const getThumbFile = image => {
  return getFileVariation(image, 'thumb');
};

export const getWebFile = image => {
  return getFileVariation(image, 'web');
};

export const getXlFile = image => {
  return getFileVariation(image, 'xl');
};

export const getRetinaFile = image => {
  return getFileVariation(image, 'retina');
};

export const getDefaultImageFromEntity = item => {
  return item.getDefaultImg();
};

export const getFileVariationFromEntity = (image, variationType) => {
  let variationFile;

  _forEach(image.getVariations(), variation => {
    if (variation.getVariationType() === variationType) {
      variationFile = variation.getFile();
    }
  });

  return variationFile;
};

export const getThumbFileFromEntity = image => {
  return getFileVariationFromEntity(image, 'thumb');
};

export const getWebFileFromEntity = image => {
  return getFileVariationFromEntity(image, 'web');
};

export const getXlFileFromEntity = image => {
  return getFileVariationFromEntity(image, 'xl');
};

export const getRetinaFileFromEntity = image => {
  return getFileVariationFromEntity(image, 'retina');
};

export const getAttributeSingleValue = (item, appIntl, attributeId) => {
  const attribute = _find(item.attributes, ['id', attributeId]);

  if (attribute && attribute.values && attribute.values[0]) {
    return getNameInCurrentLanguage(attribute.values[0].value, appIntl);
  }

  return null;
};

export const getAttributeValues = (item, appIntl, attributeId) => {
  const attribute = _find(item.attributes, ['id', attributeId]);

  const values = [];
  if (attribute) {
    attribute.values.map(attr => {
      if (attr) values.push(getNameInCurrentLanguage(attr.value, appIntl));
    });
  }
  return values;
};

export const getAttributeName = (item, appIntl, attributeId) => {
  const attribute = _find(item.attributes, ['id', attributeId]);
  if (attribute) {
    return getNameInCurrentLanguage(attribute.name, appIntl);
  }
};

export const getBrand = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.brand);
};

export const getModel = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.model);
};

export const getPower = (item, appIntl) => {
  // power in kW
  return getAttributeSingleValue(item, appIntl, mapping.power);
};

export const getHorsePower = kWPower => {
  return Math.round(kWPower * 1.35962);
};

export const getCapacity = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.capacity);
};

export const getPaintColor = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.paintColor);
};

export const getPaintColorHex = item => {
  const attribute = _find(item.attributes, ['id', mapping.paintColor]);

  if (attribute) {
    return attribute.values[0].color;
  }
};

export const getPaintColorManufacturer = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.paintColorManufacturer);
};

export const getFirstRegistration = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.firstRegistration);
};

export const getMileage = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.mileage);
};

export const getGearbox = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.gearbox);
};

export const getFuelType = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.fuelType);
};

export const getCarType = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.carType);
};

export const getNumberPreviousCarOwner = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.numPreviousOwner);
};

export const getEmissionClass = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.emissionClass);
};

export const getNumberDoors = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.numDoors);
};

export const getCarCondition = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.carCondition);
};

export const getNumberSeats = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.numSeats);
};

export const getConstructionYear = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.constructionYear);
};

export const getParkingAssistance = (item, appIntl) => {
  return getAttributeValues(item, appIntl, mapping.parkingAssistance).join(', ');
};

export const getInteriorMaterial = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.interiorMaterial);
};

export const getAirbags = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.parkingAssistance);
};

export const getAirConditioning = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.airConditioning);
};

export const getEnvironmentBadge = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.environmentBadge);
};

export const getCo2Emissions = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.co2Emissions);
};

export const getFuelConsumptionCombined = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.fuelConsumptionCombined);
};

export const getFuelConsumptionUrban = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.fuelConsumptionUrban);
};

export const getFuelConsumptionExtraUrban = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.fuelConsumptionExtraUrban);
};

export const getEnergyEfficiencyClass = (item, appIntl) => {
  return getAttributeSingleValue(item, appIntl, mapping.energyEfficiencyClass);
};

export const getCategoryFromPath = categoryPath => {
  return categoryPath.substring(3, categoryPath.indexOf(',', 4));
};

export const getSearchTypeFromCategoryPath = categoryPath => {
  const category = getCategoryFromPath(categoryPath);
  return getSearchTypeFromCategory(category);
};

export const getSearchTypeFromCategory = category => {
  if (carCategories.indexOf(category) > -1) {
    return 'cars';
  }

  return 'summary';
};

// check if the item is inside any of car categories (passenger, commercial, vans, ...)
export const isCarItem = item => {
  for (let i = 0; i < carCategories.length; i++) {
    if (item.category_path.indexOf(',0,' + carCategories[i]) === 0) {
      return true;
    }
  }

  return false;
};

// display summary item gross price
export const displaySummaryItemGrossPrice = (intl, currency, item) => {
  if (item.min_price || item.max_price) {
    if (item.max_price && item.max_price > item.min_price) {
      if (isCarItem(item)) {
        return (
          <span>
            {intl.messages.from} <FormattedNumber value={item.min_price} style="currency" currency={currency} />
          </span>
        );
      }

      return (
        <span>
          <FormattedNumber value={item.min_price} style="currency" currency={currency} />
          &nbsp;-&nbsp;
          <FormattedNumber value={item.max_price} style="currency" currency={currency} />
        </span>
      );
    }

    return <FormattedNumber value={item.min_price} style="currency" currency={currency} />;
  }

  return intl.messages.price;
};

export const displaySummaryItemNetPrice = (intl, currency, item) => {
  if ((item.min_price || item.max_price) && item.vat_rate) {
    if (item.max_price && item.max_price > item.min_price) {
      if (isCarItem(item)) {
        return (
          <React.Fragment>
            {intl.messages.incl_short} {item.vat_rate}% {intl.messages.vat_short} ({intl.messages.from}{' '}
            <FormattedMessage
              id="price_net"
              values={{
                price: (
                  <FormattedNumber
                    value={getNetFromGross(item.min_price, item.vat_rate)}
                    style="currency"
                    currency={currency}
                  />
                ),
              }}
            />
            )
          </React.Fragment>
        );
      }

      return (
        <React.Fragment>
          {intl.messages.incl_short} {item.vat_rate}% {intl.messages.vat_short} (
          <FormattedMessage
            id="price_net"
            values={{
              price: (
                <React.Fragment>
                  <FormattedNumber
                    value={getNetFromGross(item.min_price, item.vat_rate)}
                    style="currency"
                    currency={currency}
                  />
                  &nbsp;-&nbsp;
                  <FormattedNumber
                    value={getNetFromGross(item.max_price, item.vat_rate)}
                    style="currency"
                    currency={currency}
                  />
                </React.Fragment>
              ),
            }}
          />
          )
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {intl.messages.incl_short} {item.vat_rate}% {intl.messages.vat_short} (
        <FormattedMessage
          id="price_net"
          values={{
            price: (
              <FormattedNumber
                value={getNetFromGross(item.min_price, item.vat_rate)}
                style="currency"
                currency={currency}
              />
            ),
          }}
        />
        )
      </React.Fragment>
    );
  }

  return null;
};

// check if selected item is one of the user's favorite
export const isInFavorites = (favorites, item) => {
  for (let i = 0; i < favorites.length; i++) {
    if (favorites[i].item.id === item.id) {
      return true;
    }
  }

  return false;
};

// check how many days will be calculated when renting a car
export const getCarRentalDaysCount = (dateFrom, dateTo) => {
  const start = moment(dateFrom);
  const end = moment(dateTo);

  const duration = moment.duration(end.diff(start));

  return Math.ceil(duration.asDays());
};

// get car rental prices
// if the prices are missing, calculate them on the fly
export const getCarRentalPrices = item => {
  return {
    weekdayPrice: item.pricing.weekday_price,
    weekendPrice: item.pricing.weekend_price || Math.ceil(item.pricing.weekday_price * 1.8) - 1,
    weekPrice: item.pricing.week_price || Math.ceil(item.pricing.weekday_price * 4.2) - 1,
    monthPrice: item.pricing.month_price || Math.ceil(item.pricing.weekday_price * 11) - 1,
  };
};

export const calculateCarRentalPeriodsCount = (daysCount = 0, dateFrom = null, dateTo = null) => {
  let weekdayCount = 0;
  let weekendCount = 0;
  let weekCount = 0;
  let monthCount = 0;
  let date = moment(dateFrom);

  const startTz = moment.tz(dateFrom, 'UTC').tz('Europe/Berlin');
  const startTime = startTz.hours() + startTz.minutes() / 60;
  const endTz = moment.tz(dateTo, 'UTC').tz('Europe/Berlin');
  const endTime = endTz.hours() + endTz.minutes() / 60;

  if (daysCount < 7) {
    for (let i = 0; i < daysCount; i++) {
      if (date.isoWeekday() === 5 && daysCount - (i + 2) >= 0) {
        // friday; check if the rental ends now or continues till after the weekend
        weekendCount++;

        // check if the booking starts before the weekend rate (12:00)
        // if yes, calculate additional day
        if (startTime < 12) {
          weekdayCount++;
          i++;
          date = date.add(1, 'days');
        }

        date = date.add(2, 'days');
        i += 2;

        // check if the booking ends before or after 12:00
        if (endTime > 12) {
          weekdayCount++;
          i++;
          date = date.add(1, 'days');
        }
      } else {
        // weekday
        weekdayCount++;

        date = date.add(1, 'days');
      }
    }
  } else if (daysCount < 30) {
    // calculate for how many weeks the car is rented
    weekCount = Math.round((daysCount / 7) * 100) / 100;
  } else {
    // calculate for how many months the car is rented
    monthCount = Math.round((daysCount / 30) * 100) / 100;
  }

  return {
    weekdayCount,
    weekendCount,
    weekCount,
    monthCount,
    quantity: weekdayCount + weekendCount + weekCount + monthCount,
  };
};

// calculate car rental price
export const calculateCarRentalPrice = (item, daysCount = 0, dateFrom = null, dateTo = null) => {
  const { weekdayPrice, weekendPrice, weekPrice, monthPrice } = getCarRentalPrices(item);
  const { weekdayCount, weekendCount, weekCount, monthCount, quantity } = calculateCarRentalPeriodsCount(
    daysCount,
    dateFrom,
    dateTo
  );

  const totalWeekday = weekdayCount * weekdayPrice;
  const totalWeekend = weekendCount * weekendPrice;
  const totalWeek = weekCount * weekPrice;
  const totalMonth = monthCount * monthPrice;

  return {
    weekdayPrice,
    weekendPrice,
    weekPrice,
    monthPrice,
    totalWeekday,
    totalWeekend,
    totalWeek,
    totalMonth,
    weekdayCount,
    weekendCount,
    weekCount,
    monthCount,
    total: totalWeekday + totalWeekend + totalWeek + totalMonth,
    quantity,
  };
};

export const getAllowedCarRentalMileageByDaysCountAndStartDate = (daysCount = 0, dateFrom = null, dateTo = null) => {
  const { weekdayCount, weekendCount, weekCount, monthCount } = calculateCarRentalPeriodsCount(
    daysCount,
    dateFrom,
    dateTo
  );

  return weekdayCount * 250 + weekendCount * 750 + weekCount * 1500 + monthCount * 3500;
};

// get maximal milaege the user could drive when renting a car
export const getAllowedCarRentalMileage = orders => {
  let allowedMileage = 0;

  for (let i = 0; i < orders.length; i++) {
    for (let j = 0; j < orders[i].items.length; j++) {
      switch (orders[i].items[j].code) {
        case 'RENTAL_WEEKDAY':
          allowedMileage += 250 * orders[i].items[j].quantity;
          break;

        case 'RENTAL_WEEKEND':
          allowedMileage += 750 * orders[i].items[j].quantity;
          break;

        case 'RENTAL_WEEK':
          allowedMileage += 1500 * orders[i].items[j].quantity;
          break;

        case 'RENTAL_MONTH':
          allowedMileage += 3500 * orders[i].items[j].quantity;
          break;
      }
    }
  }

  return allowedMileage;
};

// get amount in euro for how much is one extra kilometer charged (car rental)
export const getCarRentalMileageSurcharge = carRentalWeekdayPrice => {
  if (carRentalWeekdayPrice < 50) {
    return 0.25;
  }
  if (carRentalWeekdayPrice < 80) {
    return 0.3;
  }
  if (carRentalWeekdayPrice < 100) {
    return 0.35;
  }
  if (carRentalWeekdayPrice < 120) {
    return 0.4;
  }
  if (carRentalWeekdayPrice < 170) {
    return 0.45;
  }
  return 0.5;
};

// check if selected date is within opening hours
export const checkOpeningHours = (workingHours, selectedDate) => {
  // workingHours.iso_day1, iso_day2, iso_day3, iso_day4, iso_day5, iso_day6, iso_day7
  const date = moment(selectedDate);

  const isoWeekday = date.isoWeekday();
  const hours = date.hours();
  const minutes = date.minutes();

  const daySettings =
    workingHours && workingHours['iso_day' + isoWeekday] ? workingHours['iso_day' + isoWeekday] : null;

  if (daySettings) {
    const floatTime = parseFloat(hours + minutes / 60);

    for (let i = 0; i < daySettings.length; i++) {
      if (daySettings[i].start <= floatTime && daySettings[i].end >= floatTime) {
        // return true if any of the time periods contains the searched date
        return true;
      }
    }
  }

  return false;
};

// get item variant attributtes as html component
export const getVariantDescription = (itemVariant, appIntl, messages) => {
  let res = null;

  if (itemVariant) {
    const attributeSpans = itemVariant.attributes.map(attribute => {
      return (
        <span class="variant-attribute-inline" key={attribute.id}>
          <span class="variant-attribute-name">{getNameInCurrentLanguage(attribute.name, appIntl)}:</span>
          <span class="variant-attribute-value">{getVariantValueFormated(attribute.values[0], appIntl, messages)}</span>
        </span>
      );
    });

    if (attributeSpans) {
      res = <span class="variant-attributes-inline-container">{attributeSpans}</span>;
    }
  }

  return res;
};

// get item attributtes as html component for checkout
export const printItemAttributes = (item, appIntl, messages) => {
  const res = [];
  for (let i = 0; i < item.variants.attributes.length; i++) {
    const variantAttribute = item.variants.attributes[i];

    const attribute = _find(item.variant.attributes, ['id', variantAttribute.id]);
    if (attribute) {
      const valueText = getVariantValueFormated(attribute.values[0], appIntl, messages);

      res.push(
        <Label key={i} class="m-r-10 m-b-5">
          {getNameInCurrentLanguage(attribute.name, appIntl)}: {valueText}
        </Label>
      );
    }
  }

  return res;
};

export const getVariantValueFormated = (value, appIntl, messages) => {
  let ret = '';

  ret = getNameInCurrentLanguage(value.value, appIntl);

  switch (value.value[0].type) {
    case 'bool':
      if (ret === 'yes') {
        ret = messages.yes;
      } else {
        ret = messages.no;
      }
      break;
    case 'date':
      ret = <IntlDate date={getNameInCurrentLanguage(value.value, appIntl)} />;
      break;
  }

  return ret;
};
