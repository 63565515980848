import React from 'react';
import { OverlayTrigger, Tooltip, Overlay, Popover } from 'react-bootstrap';
import Trumbowyg from 'react-trumbowyg';

import { BASENAME } from 'js/constants';

class FormRichEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValue: props.value ? props.value : '',
      value: props.value ? props.value : '',
      focused: false,
    };

    this.mainRef = React.createRef();
  }

  componentDidUpdate = prevProps => {
    const { id, name, disabled } = this.props;
    const { value } = this.state;

    if (prevProps.disabled && !disabled) {
      $('#' + id + '-trumbowyg-' + name).trumbowyg('html', value);
    }
  };

  checkFocused = () => {
    const { focused } = this.state;
    return focused ? 'focused ' : '';
  };

  checkRequired = () => {
    return this.props.meta.validate.rules.indexOf('required') !== -1 ? 'required ' : '';
  };

  checkError = () => {
    return this.props.validation.valid ? '' : 'has-error ';
  };

  // check if user may read this field
  checkAccess = () => {
    return this.props.meta.acl.read;
  };

  // check if user may write to this field
  checkReadOnly = () => {
    return !this.props.meta.acl.write;
  };

  catchFocus = () => {
    const { id, name } = this.props;
    const { focused } = this.state;
    if (!focused) {
      $('#' + id + '-trumbowyg-' + name).focus();
    }
  };

  onChange = () => {
    const { id, name, onChange } = this.props;

    this.setState({ value: $('#' + id + '-trumbowyg-' + name).trumbowyg('html') });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      onChange(name, $('#' + id + '-trumbowyg-' + name).trumbowyg('html'));
    }, 150);
  };

  checkDisabled = () => {
    const { disabled, meta } = this.props;
    if (disabled) {
      return true;
    }
    if (!meta.acl.write) {
      return true;
    }
    return false;
  };

  setValue = value => {
    const { id, name } = this.props;
    $('#' + id + '-trumbowyg-' + name).trumbowyg('html', value);
    this.onChange(value);
  };

  initAtWho = () => {
    const { id, name, initAtWho } = this.props;

    if (initAtWho) {
      $('#' + id + '-trumbowyg-' + name).atwho({
        at: '@',
        data: BASENAME + '/api/usersForMentions.json',
        displayTpl: '<li class="mentionLi"> ${name} </li>',
        insertTpl: '<span class="mention" value="${id}">${atwho-at}${name}</span>',
      });
    }
  };

  render() {
    const { label, name, id, validation, infoText, autogrow } = this.props;
    const { focused, initialValue } = this.state;

    return (
      <div
        ref={this.mainRef}
        className={
          'form-group form-group-default ' +
          this.checkFocused() +
          this.checkRequired() +
          this.checkError() +
          (this.checkDisabled() ? 'disabled ' : '')
        }
        style={{ overflow: 'visible' }}
      >
        <label onClick={this.catchFocus}>
          {label}
          {!!infoText && (
            <span className="m-l-5">
              <OverlayTrigger placement="top" overlay={<Tooltip>{infoText}</Tooltip>}>
                <i className="far fa-question-circle cursor-help" />
              </OverlayTrigger>
            </span>
          )}
        </label>
        <Trumbowyg
          id={id + '-trumbowyg-' + name}
          data={initialValue}
          onChange={this.onChange}
          onFocus={() => {
            this.setState({ focused: true });
          }}
          onBlur={() => {
            this.setState({ focused: false });
          }}
          onInit={this.initAtWho}
          disabled={this.checkDisabled()}
          removeformatPasted
          autogrow={autogrow}
          semantic={false}
          buttons={[
            ['viewHTML'],
            ['formatting'],
            'btnGrp-semantic',
            ['link'],
            'btnGrp-justify',
            'btnGrp-lists',
            ['horizontalRule'],
            ['removeformat'],
          ]}
        />

        {!validation.valid && (
          <Overlay show={focused} target={this.mainRef.current} placement="top">
            <Popover className="popover-modal" id={`error_${name}_${label}`}>
              {validation.helpText}
            </Popover>
          </Overlay>
        )}
      </div>
    );
  }
}
FormRichEditor.defaultProps = {
  label: '',
  meta: { validate: { rules: '' }, acl: { read: true, write: true } },
  validation: { valid: true },
  value: '',
  onChange: () => {},
  disabled: false,
  id: '',
  initAtWho: false,
  infoText: '',
  autogrow: true,
};

export default FormRichEditor;
