import React from 'react';

export default class FabActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      component: null,
    };

    this.containerRef = React.createRef();
  }

  componentDidMount = () => {
    document.addEventListener('click', this.outsideClick, false);
  };

  componentWillUnmount = () => {
    document.removeEventListener('click', this.outsideClick, false);
  };

  outsideClick = e => {
    if (this.containerRef.current.contains(e.target)) {
      return;
    }

    this.setState({ show: false });
  };

  onClick = e => {
    e.preventDefault();

    this.setState(prevState => ({ show: !prevState.show }));
  };

  render() {
    const { supportFilters, size, children } = this.props;
    const { show, component } = this.state;

    const res = [];
    if (supportFilters) {
      res.push(
        <div style={{ position: 'absolute', left: '-6000px' }} key="fab-filters">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
              <filter id="shadowed-goo">
                <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                  result="goo"
                />
                <feGaussianBlur in="goo" stdDeviation="3" result="shadow" />
                <feColorMatrix
                  in="shadow"
                  mode="matrix"
                  values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 -0.2"
                  result="shadow"
                />
                <feOffset in="shadow" dx="1" dy="1" result="shadow" />
                <feComposite in2="shadow" in="goo" result="goo" />
                <feComposite in2="goo" in="SourceGraphic" result="mix" />
              </filter>
              <filter id="goo">
                <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                  result="goo"
                />
                <feComposite in2="goo" in="SourceGraphic" result="mix" />
              </filter>
            </defs>
          </svg>
        </div>
      );
    }

    let fabCount = 0;
    if (component) {
      fabCount = component.getActions().length;
    }

    res.push(
      <span
        style={{
          position: 'relative',
          display: fabCount === 0 ? 'none' : 'inline-block',
          width: size + 'px',
          textAlign: 'left',
          marginLeft: size + 'px',
          zIndex: '700',
        }}
        key="fab-action-button"
        ref={this.containerRef}
      >
        <nav className={'ms-fab-' + (fabCount > 1 ? fabCount : 2) + ' ' + (show ? 'ms-fab-open ' : '')}>
          <a className={'ms-fab-open-button ms-fab-size-' + size} onClick={this.onClick} href="#actions">
            {show ? <i className="fal fa-times" /> : <i className="far fa-bolt" />}
          </a>

          {React.cloneElement(children, {
            ref: newComponent => {
              // in case of injectIntl or connect, we will get the component with this function
              if (!component && newComponent !== null) {
                this.setState({ component: newComponent });
              }
            },
            wrappedComponentRef: newComponent => {
              // in case of withRouter, we will get the component with this function
              if (!component && newComponent !== null) {
                this.setState({ component: newComponent });
              }
            },
            actionExecuted: () => this.setState({ show: false }),
          })}
        </nav>
      </span>
    );

    return res;
  }
}
FabActions.defaultProps = {
  supportFilters: false,
  size: 40,
};
