import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { authModule, chatModule, communicationVideoModule, communicationModule } from '@chedri/base';

import IconButton from 'components/IconButton';
import ChatContactsPanel from './ChatContactsPanel';
import ChatMessagePanel from './ChatMessagePanel';

function ChatEmployeeDetached() {
  const me = useSelector(authModule.selectors.getAuth);
  const activeContact = useSelector(chatModule.selectors.getActiveContact);
  const dispatch = useDispatch();

  const isChatOpen = useSelector(chatModule.selectors.isOpen);
  const chatWith = useSelector(chatModule.selectors.getActiveConversationWith);
  const hasPendingCalls = useSelector(communicationVideoModule.selectors.hasPendingCalls);
  const isSocketConnected = useSelector(communicationModule.selectors.socket.isConnected);
  const isNetworkOnline = useSelector(state => state.webApp.isOnline);

  const toggleChatControl = useCallback(
    e => {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      if (isChatOpen) {
        dispatch(chatModule.actions.closeChat());
      } else {
        dispatch(chatModule.actions.openChat(chatWith));
      }
    },
    [isChatOpen, chatWith]
  );

  const goBack = useCallback(() => {
    dispatch(chatModule.actions.closeChat());
    setTimeout(() => dispatch(chatModule.actions.openChat(null)));
  });

  return (
    <div className="chatter-position">
      {isChatOpen ? (
        chatWith ? (
          <ChatMessagePanel
            me={me}
            contact={activeContact}
            onBack={goBack}
            onClose={toggleChatControl}
            isDisabled={
              hasPendingCalls ||
              !isSocketConnected ||
              !isNetworkOnline ||
              (!activeContact.is_online && !activeContact.is_mobile_online)
            }
          />
        ) : (
          <ChatContactsPanel
            onContactSelected={contact => dispatch(chatModule.actions.openChat(contact.id))}
            onClose={toggleChatControl}
          />
        )
      ) : (
        <IconButton
          name="fa-comment-dots"
          solid
          iconClassName="fa-2x"
          buttonClassName="btn-chatter"
          onClick={toggleChatControl}
        />
      )}
    </div>
  );
}

export default ChatEmployeeDetached;
