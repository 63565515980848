export const FAVORITE_ITEMS_ADD = 'FAVORITE_ITEMS_ADD';
export const FAVORITE_ITEMS_REMOVE = 'FAVORITE_ITEMS_REMOVE';

export function favoriteItemsAdd(item, variant = null) {
  return {
    type: FAVORITE_ITEMS_ADD,
    payload: { item, variant },
  };
}

export function favoriteItemsRemove(item, variant = null) {
  return {
    type: FAVORITE_ITEMS_REMOVE,
    payload: { item, variant },
  };
}
