import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';

import { getTimezoneFromState } from 'helpers/intl';

class IntlTimeRaw extends React.Component {
  getTimezone = () => {
    const { defaultTimezone, timezone } = this.props;
    return timezone || defaultTimezone;
  };

  render() {
    const { date, forwardedRef } = this.props;

    let tzDate = moment.tz(date, 'UTC');
    tzDate = tzDate.tz(this.getTimezone());

    return date ? <span ref={forwardedRef}>{tzDate.format('LT')}</span> : <span>&nbsp;</span>;
  }
}

const mapStateToProps = state => {
  return {
    defaultTimezone: getTimezoneFromState(state),
  };
};
const ConnectedIntlTime = connect(mapStateToProps)(IntlTimeRaw);
// eslint-disable-next-line react/display-name
const IntlTime = React.forwardRef((props, ref) => <ConnectedIntlTime forwardedRef={ref} {...props} />);
export default IntlTime;
