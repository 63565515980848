import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Grid, Row, Col } from 'react-bootstrap';
import { Elements } from 'react-stripe-elements';
import { findIndex as _findIndex } from 'lodash';

import { updateCheckout } from 'actions/CheckoutActions';
import { mayNavigateToB, mayNavigateToC, mayNavigateToD, mayNavigateToE, mayNavigateToF } from 'helpers/checkout';

import Breadcrumb from 'components/layout/Breadcrumb';

import CheckoutNav from 'components/layout/checkout/CheckoutNav';
import StepA from 'components/layout/checkout/StepA'; // login or continue with registration
import StepB from 'components/layout/checkout/StepB'; // summary of items in the cart
import StepC from 'components/layout/checkout/StepC'; // user personal data registration (user address information)
import StepD from 'components/layout/checkout/StepD'; // additional services the user may book
import StepE from 'components/layout/checkout/StepE'; // shipping information
import StepF from 'components/layout/checkout/StepF'; // payment

import DocMeta from 'components/DocMeta';
import Data from 'components/Data';

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      steps: this.getSteps(props),
    };

    window.scrollTo(0, 0);
  }

  getSteps = props => {
    const { checkout } = props;

    const res = [];

    // summary of the cart
    res.push({
      name: 'overview',
      icon: checkout.isItemCheckout ? 'fa fa-shopping-cart' : 'fa fa-car',
      title: checkout.isItemCheckout ? props.intl.messages.your_cart : props.intl.messages.your_car,
      stepChecker: mayNavigateToB,
    });

    // user personal information (state loaded from user object)
    res.push({
      name: 'personal',
      icon: 'fa fa-user',
      title: props.intl.messages.your_data,
      stepChecker: mayNavigateToC,
    });

    // additional services - only cars
    if (checkout.isCarCheckout) {
      res.push({
        name: 'services',
        icon: 'fa fa-certificate',
        title: props.intl.messages.services,
        stepChecker: mayNavigateToD,
      });
    }

    // shipping information (cars and items, not car rental)
    if (!checkout.isCarRentCheckout) {
      res.push({
        name: 'shipping',
        icon: 'fa fa-truck',
        title: props.intl.messages.shipping_information,
        stepChecker: mayNavigateToE,
      });
    }

    // payment
    res.push({
      name: 'payment',
      icon: 'fa fa-credit-card',
      title: props.intl.messages.payment_details,
      stepChecker: mayNavigateToF,
    });

    return res;
  };

  getStepIndex = () => {
    const { location } = this.props;
    const { steps } = this.state;

    if (location.pathname.indexOf('overview') !== -1) {
      return _findIndex(steps, ['name', 'overview']) + 1;
    }

    if (location.pathname.indexOf('personal') !== -1) {
      return _findIndex(steps, ['name', 'personal']) + 1;
    }

    if (location.pathname.indexOf('services') !== -1) {
      return _findIndex(steps, ['name', 'services']) + 1;
    }

    if (location.pathname.indexOf('shipping') !== -1) {
      return _findIndex(steps, ['name', 'shipping']) + 1;
    }

    if (location.pathname.indexOf('payment') !== -1) {
      return _findIndex(steps, ['name', 'payment']) + 1;
    }

    if (location.pathname.indexOf('summary') !== -1) {
      return _findIndex(steps, ['name', 'summary']) + 1;
    }

    return 0;
  };

  updateCheckout = params => {
    const { doUpdateCheckout } = this.props;
    doUpdateCheckout(params);
  };

  getStepComponent = stepIndex => {
    const { appIntl, layout, user, checkout, checkoutInfos } = this.props;
    const { steps } = this.state;

    if (!stepIndex) {
      return <StepA setCheckoutState={this.updateCheckout} checkout={checkout} />;
    }

    switch (steps[stepIndex - 1].name) {
      case 'overview': // summary of the cart
        return (
          <StepB
            setCheckoutState={this.updateCheckout}
            checkout={checkout}
            checkoutInfos={checkoutInfos}
            steps={steps}
            stepIndex={stepIndex}
            user={user}
          />
        );

      case 'personal': // user personal information (state loaded from user object)
        return (
          <StepC
            setCheckoutState={this.updateCheckout}
            checkout={checkout}
            checkoutInfos={checkoutInfos}
            steps={steps}
            stepIndex={stepIndex}
            user={user}
          />
        );

      case 'services': // additional services - only cars
        return (
          <StepD
            setCheckoutState={this.updateCheckout}
            checkout={checkout}
            checkoutInfos={checkoutInfos}
            steps={steps}
            stepIndex={stepIndex}
            user={user}
          />
        );

      case 'shipping': // shipping information
        return (
          <StepE
            setCheckoutState={this.updateCheckout}
            checkout={checkout}
            checkoutInfos={checkoutInfos}
            steps={steps}
            stepIndex={stepIndex}
            user={user}
          />
        );

      case 'payment': // payment
        return (
          <Elements locale={appIntl.locale} key={appIntl.locale}>
            <StepF
              setCheckoutState={this.updateCheckout}
              checkout={checkout}
              checkoutInfos={checkoutInfos}
              steps={steps}
              stepIndex={stepIndex}
              user={user}
              layout={layout}
            />
          </Elements>
        );
    }
  };

  // this index is the index of the tabs (counting from 0)
  navByIndex = index => {
    const { appIntl, history } = this.props;
    const { steps } = this.state;

    history.push(appIntl.urlPrefix + 'checkout/' + steps[index].name);
  };

  componentDidUpdate = () => {
    const { appIntl, history, checkout } = this.props;

    if (!checkout.items.length) {
      history.push(appIntl.urlPrefix + 'cart');
    }
  };

  generateMetaTags = stepIndex => {
    const {
      intl: { messages },
    } = this.props;
    const { steps } = this.state;

    let title = messages.meta.checkout.title + ' - ';
    if (stepIndex > 0) {
      title += steps[stepIndex - 1].title;
    }

    return [
      { name: 'title', content: title },
      { name: 'description', content: messages.meta.checkout.description },
      { itemProp: 'name', content: messages.meta.checkout.name },
      { itemProp: 'description', content: messages.meta.checkout.description },
      // {itemProp: 'image', content: "http://www.example.com/image.jpg"}
    ];
  };

  getCheckoutData = stepIndex => {
    if (stepIndex) {
      const { checkout, checkoutInfos } = this.props;
      const items = [];

      for (let i = 0; i < checkout.items.length; i++) {
        items.push(checkout.items[i].id);
      }

      return (
        <Data dataName="checkoutInfos" url="/api/checkoutinfos.json" requestData={{ items }} data={checkoutInfos} />
      );
    }
  };

  render() {
    const {
      intl: { messages },
      appIntl,
      checkout,
    } = this.props;
    const { steps } = this.state;

    const stepIndex = this.getStepIndex();

    return (
      <DocMeta tags={this.generateMetaTags(stepIndex)}>
        <div class={typeof widget !== 'undefined' && widget ? '' : 'content'}>
          {stepIndex > 0 ? (
            <CheckoutNav steps={steps} checkout={checkout} stepIndex={stepIndex} onNav={this.navByIndex} />
          ) : (
            <Grid fluid>
              <Row>
                <Col xs={12}>
                  <Breadcrumb
                    crumbs={[{ link: appIntl.urlPrefix, name: messages.home }, { link: null, name: messages.checkout }]}
                  />
                </Col>
              </Row>
            </Grid>
          )}

          <div>
            {this.getCheckoutData(stepIndex)}
            {this.getStepComponent(stepIndex)}
          </div>
        </div>
      </DocMeta>
    );
  }
}
Checkout.defaultProps = {};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    layout: state.layout,
    user: state.login.user,
    checkout: state.checkout,
    checkoutInfos: state.data.checkoutInfos,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    doUpdateCheckout: params => {
      dispatch(updateCheckout(params));
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Checkout)
  )
);
