import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Clearfix, Col } from 'react-bootstrap';
import { find as _find } from 'lodash';

import { getNameInCurrentLanguage } from 'helpers/intl';
import {
  mapping,
  carCategories,
  getDefaultImage,
  getDefaultImageFromEntity,
  getWebFile,
  getWebFileFromEntity,
  getAttributeSingleValue,
  getVariantValueFormated,
} from 'helpers/items';

import FabActions from 'components/layout/FabActions';
import FabActionElement from 'components/layout/FabActionElement';

import ItemActions from 'components/layout/item/ItemActions';

class ItemInfos extends React.Component {
  // map category id to attribute key (intl translation key) and attribute id for the value to be displayed
  static CATEGORY_ATTRIBUTES_CONFIG = {
    '0000000006': [
      { key: 'car_condition', attributeId: mapping.carCondition },
      { key: 'mileage', attributeId: mapping.mileage },
    ],
  };

  constructor(props) {
    super(props);

    const { inModal } = props;

    this.state = {
      compact: inModal,
    };
  }

  showDetails = e => {
    e.preventDefault();

    this.setState({ compact: false });
  };

  getId = () => {
    const { item, entity } = this.props;
    return item.id || entity.getId();
  };

  getItemEditUrl = (category, id) => {
    const {
      appIntl: { urlPrefix },
    } = this.props;
    if (carCategories.indexOf(category) >= 0) {
      // car item
      return urlPrefix + 'account/cars/' + id;
    }

    // normal item
    return urlPrefix + 'account/items/' + id;
  };

  getCategory = () => {
    const { item, entity } = this.props;
    const category = item.category || entity.getCategory();
    return category ? category.id || category.getId() : null;
  };

  getName = () => {
    const { item, entity, appIntl } = this.props;
    let _itemName = item.name || entity.getName();
    let itemName;
    (typeof _itemName === 'object') ? itemName = getNameInCurrentLanguage(_itemName, appIntl) : itemName = _itemName;

    return itemName;
  };

  getIdentInternal = () => {
    const { item, entity } = this.props;
    const ident = item.ident || entity.getIdent();
    return ident ? ident.internal || (ident.getInternal && ident.getInternal()) : null;
  };

  getImage = () => {
    const { item, entity } = this.props;
    if (entity && entity.getId) {
      const defaultImage = getDefaultImageFromEntity(entity);
      return defaultImage ? getWebFileFromEntity(defaultImage) : null;
    }

    const defaultImage = getDefaultImage(item);
    return defaultImage ? getWebFile(defaultImage) : null;
  };

  getBrandName = () => {
    const { item, entity } = this.props;
    const brand = item.brand || entity.getBrand();
    return brand ? brand.name || brand.getName() : null;
  };

  getModelName = () => {
    const { item, entity } = this.props;
    const model = item.model || entity.getModel();
    return model ? model.name || model.getName() : null;
  };

  getVariantDescription = () => {
    const {
      entityVariant,
      appIntl,
      intl: { messages },
    } = this.props;

    if (entityVariant) {
      const res = [];
      for (let i = 0; i < entityVariant.attributes.length; i++) {
        const variantAttribute = entityVariant.attributes[i];

        const attribute = _find(entityVariant.attributes, ['id', variantAttribute.id]);
        if (attribute) {
          res.push(
            <React.Fragment key={i}>
              <dt class="m-t-5">{getNameInCurrentLanguage(attribute.name, appIntl)}</dt>
              <dd>{getVariantValueFormated(attribute.values[0], appIntl, messages)}</dd>
            </React.Fragment>
          );
        }
      }

      return res;
    }
  };

  render() {
    const {
      intl: { messages },
      appIntl,
      item,
      entity,
      showActions,
      addEditAction,
      history,
      inModal,
      shippingOutput,
    } = this.props;
    const { compact } = this.state;

    const id = this.getId();
    const name = this.getName();
    const identInternal = this.getIdentInternal();
    const image = this.getImage();
    const imageLink = image ? image.web_link || image.webLink : null;
    const category = this.getCategory();
    const variantDescription = this.getVariantDescription();

    const itemEditUrl = this.getItemEditUrl(category, id);

    const addActions = [];
    if (addEditAction) {
      addActions.push(
        <FabActionElement
          size="60"
          icon="fas fa-pencil-alt"
          caption={messages.edit}
          action={() => history.push(itemEditUrl)}
        />
      );
    }

    const actions = showActions && (
      <FabActions>
        <ItemActions
          item={item.id ? item : entity.processedData}
          wrapperComponent={null}
          listItemComponent={<FabActionElement size="60" />}
          addActions={addActions}
        />
      </FabActions>
    );

    return (
      <Clearfix>
        {inModal && compact && (
          <>
            <a href="#showDetails" className="block clearfix text-master" onClick={this.showDetails}>
              <span className="pull-left block">
                <i className="fal fa-chevron-down" />
              </span>
              <span className="block m-l-25">
                {!!identInternal && <>(#{identInternal})</>} {name}
              </span>
            </a>

            <div className="top-right m-t-20" style={{ marginLeft: '-70px' }}>
              {actions}
            </div>
          </>
        )}

        {(!inModal || !compact) && (
          <>
            <Col xs={3} className="p-l-0">
              {imageLink ? (
                <img src={imageLink} alt={name} className="img-responsive" />
              ) : (
                <div className="bg-master-lightest text-center padding-15">
                  <i className="fad fa-image fa-3x hint-text" />
                </div>
              )}

              {/* showActions && <div className="text-center m-t-10">{actions}</div> */}
            </Col>
            <Col xs={9} className="p-r-0">
              <dl className="no-margin full-width" key="items">
                <dt>{identInternal ? messages.car_number + ' ' + identInternal : messages.car}</dt>
                <dd>{name}</dd>
                {variantDescription}
                <dt>Versandart</dt>
                <dd>{shippingOutput}</dd>

                {category && ItemInfos.CATEGORY_ATTRIBUTES_CONFIG[category] && (
                  <>
                    {ItemInfos.CATEGORY_ATTRIBUTES_CONFIG[category].map(attrConfig => {
                      const value = getAttributeSingleValue(item.id ? item : entity, appIntl, attrConfig.attributeId);
                      return (
                        <React.Fragment key={attrConfig.key}>
                          <dt class="m-t-5">{messages[attrConfig.key]}</dt>
                          <dd>{value || '-'}</dd>
                        </React.Fragment>
                      );
                    })}
                  </>
                )}
              </dl>
            </Col>
          </>
        )}
      </Clearfix>
    );
  }
}
ItemInfos.defaultProps = {
  item: {},
  entity: {},
  showActions: true,
  addEditAction: false,
  inModal: false,
};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(ItemInfos)));

