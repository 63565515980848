import React from 'react';
import { connect } from 'react-redux';
import { Col, Clearfix } from 'react-bootstrap';
import { get as _get } from 'lodash';

import EntityStateUpdater from 'component/EntityStateUpdater';

import FormAutocomplete from 'components/forms/FormAutocomplete';
import FormInput from 'components/forms/FormInput';

import Loader from 'components/layout/Loader';

import Entity from 'components/Entity';

import { entityMetaMapping } from 'js/constants';

//
class PaymentContent extends EntityStateUpdater {
  constructor(props) {
    super(props);

    this.defaultPaymentTarget = '+14days';

    const { meta, checkout } = props;
    const bankData = checkout.paymentData || {};

    this.state = {
      ...this.state,
      entity: null,
      entityLoaded: false,
      didBankDataInit:
        !!bankData.bankName ||
        !!bankData.bankLocation ||
        !!bankData.accountHolder ||
        !!bankData.iban ||
        !!bankData.swiftBic,
    };

    this.entity = new Entity();
    this.entity.setData(bankData);

    if (meta[entityMetaMapping.financialBankData] !== undefined && !meta[entityMetaMapping.financialBankData].pending) {
      this.entity.setMeta(meta, entityMetaMapping.financialBankData);

      this.state = {
        ...this.state,
        entityLoaded: true,
        entity: this.entity,
      };
    }
  }

  componentDidUpdate = prevProps => {
    const { meta, myParent } = this.props;
    const { entityLoaded } = this.state;

    if (
      meta[entityMetaMapping.financialBankData] !== undefined &&
      !meta[entityMetaMapping.financialBankData].pending &&
      !this.entity.getMetaLoaded()
    ) {
      this.entity.setMeta(meta, entityMetaMapping.financialBankData);
      if (this.entity.getDataLoaded()) {
        this.setState({ entityLoaded: true, entity: this.entity }, () => {
          if (!myParent.pending && !myParent.hasError) {
            this.initParentBankData();
          }
        });
      }
    }

    if (entityLoaded && prevProps.myParent.pending && !myParent.pending && !myParent.hasError) {
      // parent data loaded, update the entity
      this.initParentBankData();
    }
  };

  componentDidMount = () => {
    const { myParent, checkout, doUpdatePayment } = this.props;
    const { entityLoaded, didBankDataInit } = this.state;

    if (entityLoaded && !myParent.pending && !didBankDataInit) {
      this.initParentBankData();
    }

    if (!checkout.paymentData.paymentTarget) {
      doUpdatePayment(checkout.activePaymentPlugin, checkout.payment, {
        ...checkout.paymentData,
        paymentTarget: this.defaultPaymentTarget,
      });
    }
  };

  initParentBankData = () => {
    const { myParent } = this.props;
    const bankData = _get(myParent, 'data.financial.bank_data');

    if (bankData) {
      this.updateEntityMultiple(
        ['accountHolder', 'iban', 'swiftBic', 'bankName', 'bankLocation'],
        [bankData.account_holder, bankData.iban, bankData.swift_bic, bankData.bank_name, bankData.bank_location],
        true,
        () => this.setState({ didBankDataInit: true })
      );
    } else {
      this.setState({ didBankDataInit: true });
    }
  };

  changePaymentTarget = (name, paymentTarget) => {
    const { checkout, doUpdatePayment } = this.props;
    doUpdatePayment(checkout.activePaymentPlugin, checkout.payment, { ...checkout.paymentData, paymentTarget });
  };

  changeentityCallback = () => {
    const { checkout, doUpdatePayment } = this.props;
    const { entity } = this.state;

    const addData = {};
    if (!checkout.paymentData.paymentTarget) {
      addData.paymentTarget = this.defaultPaymentTarget;
    }

    // update the checkout when bank data change
    doUpdatePayment(checkout.activePaymentPlugin, checkout.payment, {
      ...checkout.paymentData,
      ...entity.processedData,
      ...addData,
    });
  };

  render() {
    const {
      intl: { messages },
      intl,
      checkout,
      disabled,
    } = this.props;
    const { entityLoaded, entity, didBankDataInit } = this.state;

    const value = checkout.paymentData.paymentTarget || this.defaultPaymentTarget;
    const options = [
      { value: 'upon_collection', label: messages.upon_collection },
      { value: '+7days', label: intl.formatMessage({ id: 'number_days' }, { days: 7 }) },
      { value: '+14days', label: intl.formatMessage({ id: 'number_days' }, { days: 14 }) },
      { value: '+1months', label: intl.formatMessage({ id: 'number_months' }, { months: 1 }) },
    ];

    return (
      <Clearfix>
        <Col xs={12} md={10} lg={7} className="no-padding">
          {entityLoaded && didBankDataInit ? (
            <>
              <div className="form-group-attached">
                <FormAutocomplete
                  label={messages.due_date}
                  options={options}
                  value={value}
                  onChange={this.changePaymentTarget}
                  disabled={disabled}
                />
              </div>

              {value !== 'upon_collection' && (
                <>
                  <p className="m-t-10 form-group-title">{messages.payment_instructions}</p>
                  <div className="form-group-attached">
                    <FormInput
                      label={messages.bank_name}
                      name="bankName"
                      value={entity.getBankName()}
                      meta={entity.getBankNameMetaInformation()}
                      validation={entity.getBankNameValidationResult()}
                      onChange={this.updateEntity}
                      disabled={disabled}
                    />
                    <FormInput
                      label={messages.account_holder}
                      name="accountHolder"
                      value={entity.getAccountHolder()}
                      meta={entity.getAccountHolderMetaInformation()}
                      validation={entity.getAccountHolderValidationResult()}
                      onChange={this.updateEntity}
                      disabled={disabled}
                    />
                    <FormInput
                      label={messages.bank_location}
                      name="bankLocation"
                      value={entity.getBankLocation()}
                      meta={entity.getBankLocationMetaInformation()}
                      validation={entity.getBankLocationValidationResult()}
                      onChange={this.updateEntity}
                      disabled={disabled}
                    />
                    <FormInput
                      label={messages.iban}
                      name="iban"
                      value={entity.getIban()}
                      meta={entity.getIbanMetaInformation()}
                      validation={entity.getIbanValidationResult()}
                      onChange={this.updateEntity}
                      disabled={disabled}
                    />
                    <FormInput
                      label={messages.swift_bic}
                      name="swiftBic"
                      value={entity.getSwiftBic()}
                      meta={entity.getSwiftBicMetaInformation()}
                      validation={entity.getSwiftBicValidationResult()}
                      onChange={this.updateEntity}
                      disabled={disabled}
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <Loader />
          )}
        </Col>
      </Clearfix>
    );
  }
}
PaymentContent.defaultProps = {
  employee: false,
};

const mapStateToProps = state => {
  return {
    meta: state.entityMeta,
  };
};

export default connect(mapStateToProps)(PaymentContent);
