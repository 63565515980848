import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col } from 'react-bootstrap';
import * as uuidLib from 'uuid';

import { getLogger, intlModule, communicationVideoModule as videoComms } from '@chedri/base';

import IconButton from 'components/IconButton';

const logger = getLogger('communication');

function CallIncoming({ call }) {
  const dispatch = useDispatch();

  const messages = useSelector(intlModule.selectors.getMessages);

  const acceptCall = useCallback(() => {
    dispatch(videoComms.actions.acceptCall(call.uuid));
    logger.debug('Call accepted');
  }, [call]);

  const closeCall = useCallback(() => {
    dispatch(videoComms.actions.rejectCall(call.uuid));
    logger.debug('Call rejected');
  }, [call]);

  useEffect(() => {
    dispatch(videoComms.actions.updateCallId(uuidLib.v4(), call.user.id));
  }, []);

  return (
    <div>
      <Col xs={7} className="overflow-ellipsis lh-47 no-padding">
        {call.user.name} {messages.do_calling}
      </Col>
      <Col xs={5} className="lh-47 p-r-0 text-right controls">
        <IconButton
          name="fa-phone"
          buttonClassName="btn btn-sm btn-rounded btn-success"
          onClick={acceptCall}
          tooltip={messages.accept}
          tooltipPlacement="bottom"
        />
        <IconButton
          name="fa-phone-slash"
          buttonClassName="btn btn-sm btn-rounded btn-danger"
          onClick={closeCall}
          tooltip={messages.reject}
          tooltipPlacement="bottom"
        />
      </Col>
    </div>
  );
}
export default CallIncoming;
