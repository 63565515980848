import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Alert, Button } from 'react-bootstrap';
import _, { find as _find } from 'lodash';
import { createObjectURL } from 'helpers/upload';
import ImageEditorRc from 'js/cc-editor/build/react-image-editor';
import Slider from 'rc-slider/lib/Slider';
import ReactTooltip from 'react-tooltip';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import EXIF from 'exif-js';
import { confirmAlert } from 'react-confirm-alert';
import { S3_LINKS } from 'js/constants';
import Loader from '../../../components/layout/Loader';
import EditorApplyStyles from '../EditorApplyStyles';
import MediaTabs from '../MediaTabs';
import FormInput from '../../../components/forms/FormInput';

const ContextModal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('context-modal'));
};

class ImageElement extends React.Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.state = {
      showImageTooSmallError: false,
      showImageEditor: false,
      showMedia: false,
      editorImageSrc: null,
      imageSrc: null,
      imageKey: '',
      xZoom: 0,
      imgWidth: 0,
      imgHeight: 0,
      imgFilename: '',
      imgThumb: '',
      imgCopyright: '',
      path: '',
      imgBlockWidth: 0,
      imgBLockHeight: 0,
      imageDescription: '',
      showDescriptionInputField: false,
      scaleFree: false,
    };
    this.cropperRef = React.createRef();
  }

  handleOnChange = (e, DataURIToBlob) => {
    const { attributes, imageUpload, id, clientImageRightsQuery } = this.props;
    const { imgBlockWidth, imgBlockHeight } = this.state;
    let file;
    file = e.target.files[0];

    if (file) {
      const self = this; // Save the current this to use it inside callbacks
      EXIF.getData(file, function() {
        const Orientation = EXIF.getTag(this, 'Orientation');
        const img = new Image();
        img.src = createObjectURL(file);

        img.onload = () => {
          const minwidth = imgBlockWidth;
          const minheight = imgBlockHeight;
          let imgwidth = img.width;
          let imgheight = img.height;

          if (Orientation && Orientation !== 1) {
            const image = new Image();
            const imgCanvas = {};
            const reader = new FileReader();

            reader.onload = ev => {
              image.src = ev.target.result;

              image.onload = () => {
                const imgHeight = imgheight;
                const imgWidth = imgwidth;

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = imgWidth;
                canvas.height = imgHeight;

                switch (Orientation) {
                  case 3: // rotate 180 degrees
                    ctx.rotate(Math.PI);
                    ctx.drawImage(image, -imgWidth, -imgHeight, imgWidth, imgHeight);
                    break;
                  case 6: // rotate - 90 degrees
                    canvas.width = imgHeight;
                    canvas.height = imgWidth;
                    ctx.rotate((3 * Math.PI) / 2);
                    ctx.drawImage(image, -imgWidth, 0, imgWidth, imgHeight);
                    // When the image is rotated 90 degrees, the width becomes the height and the height becomes the width (please pay attention to the difference between variables imgHeight and imgheight
                    imgwidth = imgHeight;
                    imgheight = imgWidth;
                    break;
                  case 8: // rotate 90 degrees
                    canvas.width = imgHeight;
                    canvas.height = imgWidth;
                    ctx.rotate(Math.PI / 2);
                    ctx.drawImage(image, 0, -imgHeight, imgWidth, imgHeight);
                    // When the image is rotated 90 degrees, the width becomes the height and the height becomes the width (please pay attention to the difference between variables imgHeight and imgheight
                    imgwidth = imgHeight;
                    imgheight = imgWidth;
                    break;
                }

                const dataUrl = canvas.toDataURL('image/jpeg', 0.1);
                imgCanvas.src = DataURIToBlob(dataUrl);
                imgCanvas.src = createObjectURL(imgCanvas.src);

                if (imgwidth < minwidth || imgheight < minheight) {
                  self.setState({ showImageTooSmallError: true, showMedia: false });
                } else if (imgwidth === minwidth && imgheight === minheight && !clientImageRightsQuery) {
                  self.setState({ showImageTooSmallError: false, showMedia: false, showImageEditor: false }, () => {
                    self.update(imgCanvas.src);
                    imageUpload(id, file, null, attributes['data-name'], null, imgwidth, imgheight);
                  });
                } else {
                  self.setState({
                    showImageTooSmallError: false,
                    showMedia: false,
                    imageKey: null,
                    editorImageSrc: imgCanvas,
                    imageSrc: file,
                    imgWidth: imgwidth,
                    imgHeight: imgheight,
                    showImageEditor: true,
                  });
                  if (clientImageRightsQuery) {
                    self.imagePermissionQuery();
                  }
                }
              };
            };

            reader.readAsDataURL(file);
          } else if (imgwidth < minwidth || imgheight < minheight) {
            self.setState({ showImageTooSmallError: true, showMedia: false });
          } else if (imgwidth === minwidth && imgheight === minheight && !clientImageRightsQuery) {
            self.setState({ showImageTooSmallError: false, showMedia: false, showImageEditor: false }, () => {
              self.update(img.src);
              imageUpload(id, file, null, attributes['data-name'], null, imgwidth, imgheight);
            });
          } else {
            self.setState({
              showImageTooSmallError: false,
              showMedia: false,
              imageKey: null,
              editorImageSrc: img,
              imageSrc: file,
              imgWidth: imgwidth,
              imgHeight: imgheight,
              showImageEditor: true,
            });
            if (clientImageRightsQuery) {
              self.imagePermissionQuery();
            }
          }
        };
      });
    }
  };

  DataURIToBlob = dataURI => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  imageUpload = image => {
    const data = this.cropperRef.current.getData();
    const { imageUpload, id, attributes } = this.props;
    const { imgBlockWidth, imgBlockHeight } = this.state;

    data.minWidth = imgBlockWidth;
    data.minHeight = imgBlockHeight;

    const {
      imageSrc,
      imageKey,
      imgWidth,
      imgHeight,
      imgFilename,
      imgThumb,
      imgCopyright,
      imageDescription,
    } = this.state;
    const imageBlob = this.DataURIToBlob(image);
    const imageUrl = URL.createObjectURL(imageBlob);
    this.update(imageUrl, imgCopyright);

    /** @see creacheck/web/js/cc-editor/Editor.js uploadImage */
    imageUpload(
      id,
      imageSrc,
      imageKey,
      attributes['data-name'],
      data,
      imgWidth,
      imgHeight,
      imgFilename,
      imgThumb,
      imgCopyright,
      null,
      null,
      null,
      imageDescription
    );
    this.setState({
      showImageTooSmallError: false,
      showMedia: false,
      showImageEditor: false,
      xZoom: 0,
      editorImageSrc: '',
    });
  };

  update = (value, copyright) => {
    const { id, attributes, updateParam } = this.props;
    const overflow = false;
    const default_image = false;
    const errors = [{ overflow, default_image }];
    if (!copyright) {
      copyright = '';
    }
    updateParam(id, attributes['data-name'], value, errors, 'copyright_' + attributes['data-name'], copyright);
  };

  getSrc = () => {
    const { attributes, params, settings } = this.props;
    const param = _find(params, ['name', attributes['data-name']]);
    if (param) {
      return param.value;
    }
    if (attributes.src) {
      if (attributes.src === '{logo}' && settings) {
        return settings?.target?.client_main_logo?.variations[0]?.file?.web_link;
      }
      return attributes.src;
    }
    return 'nopic.jpg';
  };

  loadOriginal = (e, option) => {
    const { attributes } = this.props;
    const original = attributes['data-original'];
    const originalSrc = S3_LINKS.MEDIA_IMGS_ORIGINAL + original;
    const imgC = new Image();
    this.setState({ showDescriptionInputField: option });
    imgC.src = originalSrc;
    imgC.crossOrigin = 'anonymous';
    imgC.onload = () => {
      const imgwidth = imgC.width;
      const imgheight = imgC.height;
      this.setState({
        showImageTooSmallError: false,
        showMedia: false,
        showImageEditor: true,
        editorImageSrc: imgC,
        imageSrc: null,
        imageKey: original,
        imgWidth: imgwidth,
        imgHeight: imgheight,
        imgThumb: '',
        imgCopyright: '',
      });
    };
  };

  loadMedia = (original, path, filename, thumb, copyright, files) => {
    this.setState({ showDescriptionInputField: false });
    // const original = e.target.getAttribute('data-original');
    let originalSrc = '';
    if (original === 'tp') {
      originalSrc = path;
    } else {
      originalSrc = path + original;
    }

    if (original === 'pixabay') {
      originalSrc = path;
      original = path;
    }

    const imgC = new Image();
    imgC.src = originalSrc;
    imgC.crossOrigin = 'anonymous';
    imgC.onload = () => {
      const imgwidth = imgC.width;
      const imgheight = imgC.height;
      this.setState({
        showImageTooSmallError: false,
        showMedia: false,
        showImageEditor: true,
        editorImageSrc: imgC,
        imageSrc: null,
        imageKey: original,
        imgWidth: imgwidth,
        imgHeight: imgheight,
        imgFilename: filename,
        imgThumb: thumb,
        imgCopyright: copyright,
        path,
      });

      if (original === 'tp') {
        this.setState({
          imageSrc: files,
          imageKey: null,
        });
      }
    };
  };
  // imageKey = orignal
  // path: null,

  handleMedia = (original, path, filename = '', thumb = '', copyright = '', files) => {
    this.loadMedia(original, path, filename, thumb, copyright, files);
  };

  handleClick = () => {
    this.setState({ showMedia: true, showImageEditor: false });
  };

  handleFileUpload = (e, option) => {
    this.inputElement.click();
    this.setState({ showDescriptionInputField: option });
  };

  handleIEclose = () => {
    // this.cropperRef.current.destroy();
    this.inputElement.value = '';
    this.setState({
      showImageTooSmallError: false,
      showMedia: false,
      showImageEditor: false,
      editorImageSrc: '',
      xZoom: 0,
    });
  };

  updateImageSize = (width, height) => {
    this.setState({
      imgBlockWidth: width,
      imgBlockHeight: height,
    });
  };

  componentDidUpdate = () => {
    const { imgBlockWidth, imgBlockHeight } = this.state;
    if (this.imageRef.current.offsetWidth !== imgBlockWidth || this.imageRef.current.offsetHeight !== imgBlockHeight) {
      this.updateImageSize(this.imageRef.current.offsetWidth, this.imageRef.current.offsetHeight);
    }
  };

  componentDidMount = () => {
    const waitForIt = window.setInterval(() => {
      if (this.imageRef.current && this.imageRef.current.offsetWidth > 0 && this.imageRef.current.offsetHeight > 0) {
        window.clearInterval(waitForIt);
        this.updateImageSize(this.imageRef.current.offsetWidth, this.imageRef.current.offsetHeight);
      }
    }, 100);
  };

  setScaleFree = () => {
    this.setState({ scaleFree: true });
  };

  unsetScaleFree = () => {
    this.setState({ scaleFree: false });
  };

  setImageDescription = (name, value) => {
    this.setState({ imageDescription: value });
  };

  imagePermissionQuery = () => {
    const {
      intl: { messages },
    } = this.props;
    const options = {
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body" style={{ width: '100%' }}>
            <h1>{messages.image_permission_title}</h1>
            <p>{messages.image_permission_warning}</p>
            <div className="react-confirm-alert-button-group" style={{ width: '100%', margin: '0 auto' }}>
              <Button
                style={{ margin: '5px', width: '100px' }}
                bsStyle="primary"
                onClick={() => {
                  onClose();
                  this.handleIEclose();
                }}
              >
                {messages.no}
              </Button>
              <Button
                style={{ margin: '5px', width: '100px' }}
                bsStyle="warning"
                onClick={() => {
                  onClose();
                }}
              >
                {messages.yes}
              </Button>
            </div>
          </div>
        );
      },
      childrenElement: () => <div />,
      closeOnEscape: false,
      closeOnClickOutside: false,
    };

    confirmAlert(options);
  };

  render() {
    const { name: Tag, attributes, usedMedia, templateId, rootUser, params, disabled } = this.props;
    const {
      showImageTooSmallError,
      showImageEditor,
      showMedia,
      editorImageSrc,
      xZoom,
      imgWidth,
      imgBlockWidth,
      imgBlockHeight,
      imageDescription,
      showDescriptionInputField,
    } = this.state;
    const ratio = imgBlockHeight / imgBlockWidth;
    const canvasWidth = parseFloat(document.getElementsByTagName('body')[0].offsetWidth.toFixed(0)) - 200;
    const possibleZooms = parseFloat((imgWidth / canvasWidth).toFixed(0));
    const enable = { display: 'block' };
    const {
      intl: { messages },
    } = this.props;
    let originalSrc = '';
    const freeScale = attributes['data-freescale'];

    if (typeof attributes['data-original'] !== 'undefined' && typeof attributes['data-thumb'] !== 'undefined') {
      originalSrc = S3_LINKS.MEDIA_IMGS_THUMB + attributes['data-thumb'];
    }
    const previewImageCss = attributes['data-pre']
      ? '.cropper-face {opacity: 0.5; background: url(' +
        S3_LINKS.TEMPLATES +
        templateId +
        '/images/' +
        attributes['data-pre'] +
        ') center no-repeat;background-size: 100% 100%;}'
      : '';
    let blob = false;
    if (this.getSrc().includes('blob')) {
      blob = true;
    }

    const index = _.findIndex(params, param => param.name === attributes['data-name']);
    const _filter = params[index] && params[index].filter !== undefined ? params[index].filter : '';
    let allowedImageTypes = 'image/jpeg, image/png';
    const exportType = this.props.settings?.export_type?.value;
    if (exportType === '000000000000000000000003' || exportType === '000000000000000000000001') {
      allowedImageTypes = 'image/jpeg, image/png, image/webp';
    }
    return (
      <div className="editor-image-content dropbox" id="test">
        <Tag ref={this.imageRef} {...attributes} src={this.getSrc()} style={{ filter: _filter }} />
        {blob && this.props.uploadError === true ? (
          <p className="uploadError">
            <i className="far fa-exclamation-triangle" />
          </p>
        ) : (
          <></>
        )}
        <div className="fileclicker" onClick={this.handleClick}>
          <p>
            {messages.editor.minimum_size} {imgBlockWidth} x {imgBlockHeight} Pixel
          </p>
        </div>

        <input
          ref={input => (this.inputElement = input)}
          type="file"
          accept={allowedImageTypes}
          onChange={e => {
            this.handleOnChange(e, this.DataURIToBlob);
          }}
        />
        {showImageTooSmallError && (
          <div id="infobox">
            <Alert>{messages.editor.too_low_resolution}</Alert>
          </div>
        )}
        {showMedia && (
          <ContextModal>
            <div id="contextModalWrap">
              <div id="contextModalStage" className="row">
                <MediaTabs
                  usedMedia={usedMedia}
                  loadMedia={this.loadMedia}
                  neededWidth={imgBlockWidth}
                  neededHeight={imgBlockHeight}
                  rootUser={rootUser}
                  originalSrc={originalSrc}
                  loadOriginal={this.loadOriginal}
                  handleFileUpload={this.handleFileUpload}
                  handleMedia={this.handleMedia}
                  attributess={attributes['data-thumb']}
                  saveDescription={this.props.saveDescription}
                  handleIEclose={this.handleIEclose}
                  handleOnChange={this.handleOnChange}
                />

                <Button bsStyle="danger" bsSize="large" onClick={this.handleIEclose} className="cancelIE">
                  {messages.editor.abort}
                </Button>
              </div>
            </div>
          </ContextModal>
        )}
        {showImageEditor && (
          <ContextModal style={enable}>
            <EditorApplyStyles styles={previewImageCss} />
            <div id="contextModalWrap">
              <div id="contextModalStage" className="image-editor">
                <ImageEditorRc
                  ref={this.cropperRef}
                  crossOrigin="true" // boolean, set it to true if your image is cors protected or it is hosted on cloud like aws s3 image server
                  src={editorImageSrc.src}
                  style={{ height: '80%', width: '100%' }}
                  aspectRatio={!this.state.scaleFree ? 1 / ratio : NaN}
                  className="image-edit"
                  scalable
                  movable
                  zoomable
                  rotatable
                  toggleDragModeOnDblclick={false}
                  imageName="cropy.jpg"
                  saveImage={this.imageUpload.bind(this)} // it has to catch the returned data and do it whatever you want
                  responseType="image/jpeg" // 'blob/base64'
                  viewMode={2}
                  dragMode="move"
                  zoomOnTouch={false}
                  zoomOnWheel={false}
                  enable={!disabled}
                />
                <Loader />
                <Button
                  bsStyle="danger"
                  bsSize="large"
                  style={{ zIndex: 101 }}
                  onClick={this.handleIEclose}
                  className="cancelIE"
                >
                  {messages.editor.abort}
                </Button>
                <Slider
                  step={0.01}
                  min={0.01}
                  max={parseFloat(possibleZooms) - 0.01}
                  defaultValue={xZoom}
                  value={xZoom}
                  onChange={x => {
                    const newXstate = parseFloat(x.toFixed(2));
                    const newX = newXstate - this.state.xZoom;

                    this.setState({ xZoom: newXstate });
                    this.cropperRef.current.zoom(newX);
                  }}
                />
                {!showDescriptionInputField ? (
                  <FormInput
                    type="text"
                    label={messages.editor.image_description}
                    name="description"
                    onChange={this.setImageDescription}
                    value={imageDescription}
                  />
                ) : (
                  <></>
                )}
                {freeScale ? (
                  <>
                    {!this.state.scaleFree ? (
                      <Button
                        bsSize="large"
                        onClick={() => {
                          this.setScaleFree();
                        }}
                        className="scaleFree"
                      >
                        {messages.editor.set_scale_free}
                      </Button>
                    ) : (
                      <Button
                        bsSize="large"
                        onClick={() => {
                          this.unsetScaleFree();
                        }}
                        className="scaleFree"
                      >
                        {messages.editor.unset_scale_free}
                      </Button>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <ReactTooltip id="bottom" place="bottom" effect="solid" />
          </ContextModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
    clientImageRightsQuery: state.login.user?.parent?.image_rights_query,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(ImageElement)));
