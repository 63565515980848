import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';

import { elementSource, elementTarget } from './elementDragUtils';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

import RichEditorContent from 'components/RichEditorContent';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
export default class CmsElementBlurb extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  render() {
    const { connectDropTarget, connectDragSource, connectDragPreview } = this.props;
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const imageClass = shown ? 'image-shown ' : 'image-not-shown-yet ';

    const imagePositionClass = this.getParamValue('imagePosition', 'top') === 'left' ? 'image-left ' : '';
    const mainColorClass = 'text-' + this.getParamValue('mainColor', 'master') + ' ';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-blurb-content ' + shownClass + imagePositionClass + mainColorClass + this.getCssClasses()}
        onClick={this.showSettings}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <div class="ms-blurb-image m-b-30">
          {this.getParamValue('useImage', false) ? (
            <img src={this.getParamValue('imageUrl')} class={'img-responsive ' + imageClass} />
          ) : (
            <i
              class={imageClass + ' fa-fw ' + this.getParamValue('icon', 'far fa-check-circle')}
              style={{ color: this.getParamValue('iconColor', 'inherit') }}
            />
          )}
        </div>

        <div class="ms-blurb-container">
          <h4 class={'no-margin p-b-5 ' + mainColorClass}>{this.getParamValue('title', '')}</h4>
          <RichEditorContent content={'<p>' + this.getParamValue('content', '') + '</p>'} />
        </div>

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}
