import React from 'react';
import { OverlayTrigger, Tooltip, Overlay, Popover } from 'react-bootstrap';
import { isUndefined as _isUndefined, isNull as _isNull, toString as _toString } from 'lodash';

export default class FormInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: !_isUndefined(props.value) && !_isNull(props.value) ? _toString(props.value) : '',
      focused: false,
    };

    this.inputRef = React.createRef();
  }

  checkFocused = () => {
    return this.state.focused ? 'focused ' : '';
  };

  checkRequired = () => {
    return this.props.meta.validate.rules.indexOf('required') !== -1 ? 'required ' : '';
  };

  checkError = () => {
    return this.props.validation.valid ? '' : 'has-error ';
  };

  // check if user may read this field
  checkAccess = () => {
    return this.props.meta.acl.read;
  };

  // check if user may write to this field
  checkReadOnly = () => {
    return !this.props.meta.acl.write;
  };

  catchFocus = () => {
    this.inputRef.current.focus();
  };

  onChange = value => {
    this.setState({ value });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.onChange(this.props.name, value);
    }, 150);
  };

  onBlur = (value) => {
    this.setState({ focused: false, value });
    this.props.onBlur(this.props.name, value);
  };

  checkDisabled = () => {
    const { disabled, meta } = this.props;

    if (disabled) {
      return true;
    }
    if (!meta.acl.write) {
      return true;
    }
    return false;
  };

  setValue = value => {
    this.setState({ value });
    this.onChange(value);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.value !== this.props.value && this.state.value !== this.props.value){
      this.setState({ value: this.props.value });
    }
  }

  render() {
    const {
      label,
      name,
      type,
      step,
      validation,
      infoText,
      prefixAddon,
      suffixAddon,
      autocomplete,
      min,
      max,
      readonly,
      style,
      useFormGroupDefault,
      maxLength,
      placeholder,
    } = this.props;
    const { value } = this.state;

    const input = (
      <input
        ref={this.inputRef}
        value={value || ''}
        type={type || 'text'}
        step={type === 'number' ? step : undefined}
        min={type === 'number' && min !== undefined ? min : undefined}
        max={type === 'number' && max !== undefined ? max : undefined}
        maxLength={maxLength ? maxLength : undefined}
        className="form-control"
        name={name}
        onChange={e => this.onChange(e.target.value)}
        onFocus={() => this.setState({ focused: true })}
        onBlur={e => this.onBlur(e.target.value)}
        disabled={this.checkDisabled()}
        autoComplete={autocomplete}
        readOnly={readonly}
        placeholder={placeholder}
      />
    );

    return (
      <div
        className={
          `form-group ${useFormGroupDefault ? 'form-group-default' : ''} ` +
          this.checkFocused() +
          this.checkRequired() +
          this.checkError() +
          (this.checkDisabled() ? 'disabled ' : '')
        }
        style={style}
        onClick={this.catchFocus}
      >
        <label>
          {label}
          {!!infoText && (
            <span className="m-l-5">
              <OverlayTrigger placement="top" overlay={<Tooltip>{infoText}</Tooltip>}>
                <i className="far fa-question-circle cursor-help" />
              </OverlayTrigger>
            </span>
          )}
        </label>
        {prefixAddon || suffixAddon ? (
          <span className="input-group">
            {!!prefixAddon && <span className="input-group-addon">{prefixAddon}</span>}
            {input}
            {!!suffixAddon && <span className="input-group-addon">{suffixAddon}</span>}
          </span>
        ) : (
          input
        )}

        {!validation.valid && (
          <Overlay show={this.state.focused} target={this.inputRef.current} placement="top">
            <Popover className="popover-modal" id={`error_${name}_${label}`}>
              {validation.helpText}
            </Popover>
          </Overlay>
        )}
      </div>
    );
  }
}
FormInput.defaultProps = {
  label: '',
  meta: { validate: { rules: '' }, acl: { read: true, write: true } },
  validation: { valid: true },
  value: '',
  onChange: () => {},
  disabled: false,
  type: 'text',
  step: 1,
  prefixAddon: '',
  suffixAddon: '',
  infoText: '',
  onBlur: () => {},
  autocomplete: null,
  useFormGroupDefault: true,
};
