import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Row, Col } from 'react-bootstrap';

import Breadcrumb from 'components/layout/Breadcrumb';

class BreadcrumbCategoryNav extends React.Component {
  render() {
    const { intl, appIntl } = this.props;
    const messages = intl.messages;

    const crumbs = [
      { link: appIntl.urlPrefix, name: messages.home },
      {
        link: window.location.href,
        name: (
          <span class="ms-wireframe" style={{ width: '55px' }}>
            <span class="block animated-background lh-16" style={{ width: '100%' }}>
              {'\u00A0'}
            </span>
          </span>
        ),
      },
      {
        link: window.location.href,
        name: (
          <span class="ms-wireframe" style={{ width: '66px' }}>
            <span class="block animated-background lh-16" style={{ width: '100%' }}>
              {'\u00A0'}
            </span>
          </span>
        ),
      },
    ];

    return (
      <Row class="condensed">
        <Col xs={12}>
          <Breadcrumb crumbs={crumbs} />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default injectIntl(connect(mapStateToProps)(BreadcrumbCategoryNav));
