import React from 'react';
import { connect } from 'react-redux';

import _, { find as _find } from 'lodash';

class PreviewImageElement extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showImageTooSmallError: false,
      showImageEditor: false,
      editorImageSrc: null,
      xZoom: 0,
      imgWidth: 0,
    }

    this.cropperRef = React.createRef();
  }

  getSrc = () => {
    const { attributes, params, srcThumb } = this.props;
    const param = _find(params, ['name', attributes['data-name']]);
    if (srcThumb !== "" && srcThumb !== undefined){
      return srcThumb;
    }else{
      if (param) {
        if(param.thumb){
          return param.thumb
        }else{
          return param.value;
        }
      } else if (attributes.src) {
        return attributes.src;
      } else {
        return 'nopic.jpg';
      }
    }

  };

  render() {
    const { name: Tag, attributes, params } = this.props;
    const index = _.findIndex(params, param => param.name === attributes['data-name']);
    let _filter = this.props.params[index] && this.props.params[index].filter !== undefined ? this.props.params[index].filter : "";

    return (
        <div className={`editor-image-content dropbox`} id="test">
          <Tag {...attributes} src={this.getSrc()} style={{filter:_filter}} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
  };
};

export default connect(mapStateToProps)(PreviewImageElement);
