import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';

import { elementSource, elementTarget } from './elementDragUtils';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
export default class CmsElementImage extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  showSettings = e => {
    this.props.showElementSettings(e, this.props.index);
  };

  getImgComponent = () => {
    let styleClass = '';
    styleClass += 'drop-shadow-' + this.getParamValue('shadow') + ' ';
    styleClass += 'rounded-corners-' + this.getParamValue('roundCorners') + ' ';

    let imageUrl = this.getParamValue('imageUrl');
    const domElem = document.getElementById(this.getMainId());

    if (domElem && imageUrl) {
      const elemWidth = domElem.offsetWidth;

      if (elemWidth > 1200) {
        imageUrl = this.getParamValue('imageXlUrl', imageUrl);
      } else if (elemWidth > 1920) {
        imageUrl = this.getParamValue('imageRetinaUrl', imageUrl);
      }

      return (
        <img src={imageUrl} class={'ms-img img-responsive ' + styleClass} alt={this.getParamValue('imageFileTitle')} />
      );
    }

    return (
      <div class="p-t-15 p-b-15 text-center">
        <i class="fa fa-image fa-4x" />
      </div>
    );
  };

  render() {
    const { connectDropTarget, connectDragSource, connectDragPreview } = this.props;
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-img relative ' + shownClass + this.getCssClasses()}
        onClick={this.showSettings}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        {this.getImgComponent()}

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}
