import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Row, Col, Alert } from 'react-bootstrap';

import { listDispatchHelper } from 'actions/ListActions';
import { hideAccountSearch, changeAccountSearchValue } from 'actions/AccountSearchActions';
import { getDefaultImage, getThumbFile } from 'helpers/items';
import { getNameInCurrentLanguage } from 'helpers/intl';
import { getColorForString } from 'helpers/charts';
import { getTextColor } from 'components/forms/autocomplete/Color';

import RoundImage from 'components/layout/RoundImage';

class AccountSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchInitialized: false,
      inputValue: '',
    };
  }

  componentDidUpdate = prevProps => {
    // the search is open:
    if (this.props.accountSearch.show && !prevProps.accountSearch.show) {
      document.addEventListener('keydown', this.handleKeyDownClose);
      this.searchInput.focus();
      this.searchInput.setSelectionRange(0, this.searchInput.value.length);
    }

    // the search is closed:
    if (!this.props.accountSearch.show && prevProps.accountSearch.show) {
      document.removeEventListener('keydown', this.handleKeyDownClose);
      this.props.changeValue(this.state.inputValue);
    }
  };

  handleCloseClick = e => {
    // 64 is the padding number on the left where the user can click to close the search
    if (e.target.getBoundingClientRect().left + e.nativeEvent.offsetX < 64) {
      this.props.hideSearch();
    }
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleSearch = e => {
    e.preventDefault();

    const { fetchData } = this.props;
    const { inputValue } = this.state;

    this.setState({ searchInitialized: true });
    fetchData(
      'accountSearchClients',
      '/api/clients.json',
      { filters: { type: 'customer' } },
      1,
      10,
      inputValue,
      '',
      ''
    );
    fetchData('accountSearchItems', '/api/items.json', {}, 1, 10, inputValue, '', '');
  };

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.handleKeyDownClose);
  };

  handleKeyDownClose = e => {
    const { hideSearch } = this.props;

    if (e.keyCode == 27) {
      hideSearch();
    }
  };

  clientClick = username => {
    const { appIntl, history, hideSearch } = this.props;

    history.push(appIntl.urlPrefix + 'account/clients/' + username);
    hideSearch();
  };

  itemClick = (itemType, itemId) => {
    const { appIntl, history, hideSearch } = this.props;

    if (itemType === 'car') {
      history.push(appIntl.urlPrefix + 'account/cars/' + itemId);
    } else {
      history.push(appIntl.urlPrefix + 'account/items/' + itemId);
    }

    hideSearch();
  };

  render() {
    const {
      intl: { messages },
      appIntl,
      user,
      accountSearch,
      clientList,
      itemList,
      projectConfig,
    } = this.props;

    const pending = (clientList.pending || itemList.pending) && this.state.searchInitialized;

    let logoUrl = '/img/creacheck_logo.png';
    const thumbnail = getThumbFile(projectConfig.data.main_logo);
    if (thumbnail) {
      logoUrl = thumbnail.web_link;
    }

    if (user.logged && accountSearch.show) {
      return (
        <div
          id="account-search"
          class="overlay"
          style={{ display: 'block' }}
          onClick={e => {
            this.handleCloseClick(e);
          }}
        >
          <div class="overlay-content has-results m-t-20 newOverlayStyle">
            <div className="container-fluid">
            <button
              type="button"
              className="close pull-right"
              aria-label="Close"
              onClick={() => {
                this.props.hideSearch();
              }}
            >
              <span>×</span>
            </button>
            </div>
            <div class="container-fluid account-searching">
              <img src={logoUrl} alt="logo" class="nav-logo" style={{ minWidth:"158px", minHeight:"58px", marginTop: '-11px', marginLeft: '-9px' }} />
            </div>
            <div class="container-fluid">
              <form onSubmit={this.handleSearch}>
                <input
                  ref={input => {
                    this.searchInput = input;
                  }}
                  onChange={this.handleInputChange}
                  value={this.state.inputValue}
                  id="overlay-search"
                  class="overlay-search bg-transparent"
                  placeholder={messages.search + '...'}
                  autoComplete="off"
                  spellCheck="false"
                />
              </form>
              <div class="inline-block">
                {pending ? (
                  <p class="fs-13">
                    <i class="fa fa-circle-notch fa-spin" />
                  </p>
                ) : (
                  <p class="fs-13">{messages.press_enter_to_search}</p>
                )}
              </div>
            </div>
            {clientList.data.list.length || itemList.data.list.length ? (
              <div class="container-fluid">
                <div class="search-results m-t-40">
                  <p class="bold">{messages.search_results}</p>
                  <Row>
                    <Col md={6}>
                      {clientList.data.list.map(client => {
                        let avatarThumbLink = null;
                        if (client.profile.avatar) {
                          const imageThumb = getThumbFile(client.profile.avatar);
                          avatarThumbLink = imageThumb ? imageThumb.web_link : null;
                        }

                        const avatarColor =
                          '#' + getColorForString(client.profile.first_name + client.profile.last_name);
                        const avatarTextColor = getTextColor(avatarColor);

                        return (
                          <div
                            class="clearfix cursor"
                            onClick={() => this.clientClick(client.username)}
                            key={client.username}
                          >
                            <div class="thumbnail-wrapper d48 circular inline m-t-10">
                              {avatarThumbLink ? (
                                <RoundImage size={50} usePixels url={avatarThumbLink} />
                              ) : (
                                <div style={{ opacity: '.6' }}>
                                  <RoundImage size={50} usePixels hexColor={avatarColor}>
                                    <h6 style={{ color: avatarTextColor }} class="bold">
                                      {client.profile.first_name.substring(0, 1)}&nbsp;
                                      {client.profile.last_name.substring(0, 1)}
                                    </h6>
                                  </RoundImage>
                                </div>
                              )}
                            </div>
                            <div class="p-l-10 inline p-t-5">
                              <h5 class="m-b-5">
                                <span class="semi-bold result-name">{client.profile.company}</span>{' '}
                                {client.profile.first_name} {client.profile.last_name}
                              </h5>
                              <p class="hint-text">
                                {client.profile.street} {client.profile.house_number} {client.profile.apartment_number}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </Col>
                    <Col md={6}>
                      {itemList.data.list.map(item => {
                        const { id, name, brand, category, type } = item;

                        const defaultImage = getDefaultImage(item);
                        let defaultImageThumb;
                        if (defaultImage !== undefined) {
                          defaultImageThumb = getThumbFile(defaultImage);
                        }

                        return (
                          <div class="clearfix cursor" onClick={() => this.itemClick(type, id)} key={id}>
                            <div class="thumbnail-wrapper d48 circular bg-success text-white inline m-t-10">
                              {defaultImageThumb !== undefined && <img src={defaultImageThumb.web_link} alt={name} />}
                            </div>
                            <div class="p-l-10 inline p-t-5">
                              <h5 class="m-b-5">
                                <span class="semi-bold result-name">{brand && brand.name}</span> {name}
                              </h5>
                              <p class="hint-text">{getNameInCurrentLanguage(category && category.name, appIntl)}</p>
                            </div>
                          </div>
                        );
                      })}
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <div>
                {this.state.searchInitialized && !pending && (
                  <div class="container-fluid">
                    <div class="m-t-40">
                      <Alert bsStyle="info" class="text-uppercase text-center">
                        {messages.no_results_found}
                      </Alert>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.user,
    appIntl: state.intl,
    accountSearch: state.accountSearch,
    clientList: state.list.accountSearchClients,
    itemList: state.list.accountSearchItems,
    projectConfig: state.data.projectConfig,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    hideSearch: () => {
      dispatch(hideAccountSearch());
    },
    changeValue: value => {
      dispatch(changeAccountSearchValue(value));
    },
    fetchData: (listName, url, data, page = 1, resPerPage = 20, search = '', sort = '', dir = '') => {
      return listDispatchHelper(
        listName,
        url,
        { ...data, offset: (page - 1) * resPerPage, limit: resPerPage, search, sort, dir },
        dispatch
      );
    },
  };
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(AccountSearch)
  )
);
