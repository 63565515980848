const initialState = {
  pending: false,
  hasError: false,
  errors: [],
  data: {},
};

export default function settings(state = initialState, action) {
  switch (action.type) {
    case 'WHITE_LABEL_SETTINGS_FULFILLED':
      return { ...state, pending: false, hasError: false, errors: [], data: action.payload.data };
  }
  return state;
}
