import React from 'react';
import { Link } from 'react-router-dom';

import MapCountMarker from 'components/layout/MapCountMarker';

class ClusteredLocationsMarker extends React.Component {
  render() {
    const { cluster, isActive, getLink, closeTooltip } = this.props;
    const clusterLocations = cluster.points;

    return (
      <MapCountMarker
        locationId="count"
        active={isActive}
        title={<div class="lh-16">{cluster.numPoints + ' Standorte'}</div>}
        count={cluster.numPoints}
        tooltip={
          <div class="list-view" style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <ul class="no-border">
              {clusterLocations.map(location => {
                return (
                  <li key={location.locationId} class="p-l-10 p-r-10 no-overflow block" style={{ display: 'block' }}>
                    <Link to={getLink(location)} class="text-master">
                      <span class="block p-t-5 overflow-ellipsis">{location.company}</span>

                      <span class="block small hint-text p-b-5">
                        {location.street} {location.house_number} {location.apartment_number}
                        <br />
                        {location.zip_code} {location.city}
                      </span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        }
        closeTooltip={closeTooltip}
      />
    );
  }
}

export default ClusteredLocationsMarker;
