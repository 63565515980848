import React from 'react';
import { injectIntl } from 'react-intl';

import LegalTextHelper from 'components/layout/LegalTextHelper';
import MoreInfoStepECarCheckout from 'components/layout/checkout/moreInfo/MoreInfoStepECarCheckout';

import LegalChedriCarServicesTerms from 'components/layout/checkout/legal/LegalChedriCarServicesTerms';
import LegalChedriPrivacyPolicy from 'components/layout/checkout/legal/LegalChedriPrivacyPolicy';

// information on shipping methods for car orders
class StepECarCheckoutInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMoreInfo: false,
      showTerms: false,
      showPrivacyPolicy: false,
    };
  }

  showMoreInfo = e => {
    e.preventDefault();

    this.setState({ showMoreInfo: true });
  };

  closeMoreInfo = () => {
    this.setState({ showMoreInfo: false });
  };

  showTerms = e => {
    e.preventDefault();

    this.setState({ showTerms: true });
  };

  closeTerms = () => {
    this.setState({ showTerms: false });
  };

  showPrivacyPolicy = e => {
    e.preventDefault();

    this.setState({ showPrivacyPolicy: true });
  };

  closePrivacyPolicy = () => {
    this.setState({ showPrivacyPolicy: false });
  };

  render() {
    const {
      intl: { messages },
    } = this.props;
    const { showMoreInfo, showTerms, showPrivacyPolicy } = this.state;

    return (
      <div>
        <i class="fa fa-truck fa-2x hint-text" />
        <h2>{messages.get_your_car_delivered}</h2>
        <p>{messages.cant_pick_your_car_yourself_we_got_you_covered}</p>
        <p>{messages.we_are_more_than_happy_to_show_you_around_your_new_car}</p>

        <p class="small hint-text">
          <LegalTextHelper
            text={messages.car_delivery_legal_text}
            substitutes={{
              '{company}': 'HC Digital Services GmbH',
              '{terms_and_conditions}': (
                <a href="#showTerms" className="text-master b-b" onClick={this.showTerms}>
                  {messages.terms_and_conditions}
                </a>
              ),
              '{privacy_policy}': (
                <a href="#showPrivacyPolicy" className="text-master b-b" onClick={this.showPrivacyPolicy}>
                  {messages.signup_agreement_text_data_use}
                </a>
              ),
            }}
          />
        </p>

        <MoreInfoStepECarCheckout show={showMoreInfo} onHide={this.closeMoreInfo} />
        <LegalChedriCarServicesTerms show={showTerms} close={this.closeTerms} />
        <LegalChedriPrivacyPolicy show={showPrivacyPolicy} close={this.closePrivacyPolicy} />
      </div>
    );
  }
}

export default injectIntl(StepECarCheckoutInfo);
