import React from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';
import { DragSource, DropTarget } from 'react-dnd';

import { elementSource, elementTarget } from './elementDragUtils';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

@DropTarget('element', elementTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
@DragSource('element', elementSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
export default class CmsElementAnimatedHeader extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.defaultOptions = 'Header|Values|Text';

    this.state = {
      ...this.state,
      animationActive: false,
      option: 0,
      optionProgress: 0,
      blinkActive: false,
    };
  }

  componentWillUnmount = () => {
    clearTimeout(this.animTimeout);
    this.__componentWillUnmount();
  };

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true, animationActive: true }, this.doAnim);
    }
  };

  disableAnimation = () => {
    this.setState({ option: 0, optionProgress: 0, animationActive: false, blinkActive: false });
  };

  doAnim = () => {
    const { animationActive, option, optionProgress, blinkActive } = this.state;
    const options = this.getParamValue('options', this.defaultOptions).split('|');
    if (animationActive) {
      if (!options[option]) {
        this.setState({ option: 0, optionProgress: 0 }, this.continueAnimation);
      } else if (blinkActive) {
        // get next option index
        this.setState(
          prevState => ({
            blinkActive: false,
            option: (prevState.option + 1) % options.length,
            optionProgress: 0,
          }),
          this.continueAnimation
        );
      } else {
        // check the current option progress
        const lastOptionProgress = options[option].length <= optionProgress;
        if (lastOptionProgress) {
          this.setState({ blinkActive: true }, () => this.continueAnimation(600));
        } else {
          this.setState(prevState => ({ optionProgress: prevState.optionProgress + 1 }), this.continueAnimation);
        }
      }
    }
  };

  continueAnimation = (delay = 200) => {
    this.animTimeout = setTimeout(this.doAnim, delay);
  };

  getOptionContent = () => {
    const { option, optionProgress } = this.state;
    const options = this.getParamValue('options', this.defaultOptions).split('|');

    if (options[option]) {
      return options[option].substr(0, optionProgress);
    }

    return '';
  };

  render() {
    const { connectDropTarget, connectDragSource, connectDragPreview } = this.props;
    const { shown, blinkActive } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const content = this.getParamValue('title', 'Animated');
    const mainColorClass = 'text-' + this.getParamValue('mainColor', 'master') + ' ';
    const Header = 'h' + parseInt(this.getParamValue('headerSize', '1'), 10);
    const optionContent = this.getOptionContent();

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={'ms-animated-header relative ' + shownClass + mainColorClass + this.getCssClasses()}
        onClick={this.showSettings}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        <Header>
          {content} <span className="typed">{optionContent}</span>
          <span className={'typed-cursor ' + (blinkActive ? 'typed-cursor--blink' : '')}>|</span>
        </Header>

        {this.getAppendHtml()}
      </div>
    );

    return connectDropTarget(
      connectDragSource(
        connectDragPreview(
          <div>
            <ScrollTrigger onEnter={this.shown} onExit={this.disableAnimation} {...this.getCustomId()}>
              {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
            </ScrollTrigger>
          </div>
        )
      )
    );
  }
}
