import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { parseQs, stringifyQs } from 'helpers/http';

class CarSelectableAttribute extends React.Component {
  constructor(props) {
    super(props);

    const query = parseQs(props.location.search);

    this.state = {
      hovered: false,
      selected: !!(
        query[props.name] && query[props.name].indexOf(props.obj.value ? props.obj.value : props.obj.name) > -1
      ),
    };
  }

  componentDidUpdate = prevProps => {
    const { location, name, obj } = this.props;
    const { selected } = this.state;

    if (prevProps.location.search !== location.search) {
      const query = parseQs(location.search);
      const querySelected = !!(query[name] && query[name].indexOf(obj.value ? obj.value : obj.name) > -1);

      if (selected !== querySelected) {
        this.setState({ selected: querySelected });
      }
    }
  };

  // remove a value from array
  removeFilter = (name, value) => {
    const { location, history } = this.props;

    const query = parseQs(location.search);
    const values = query[name].slice();
    values.splice(values.indexOf(value), 1);

    if (!values.length) {
      delete query[name];
    } else {
      query[name] = values;
    }

    history.replace({ pathname: location.pathname, search: stringifyQs(query) });
  };

  //
  addFilter = (name, value) => {
    const { location, history } = this.props;

    const query = parseQs(location.search);
    const values = query[name] ? query[name].slice() : [];
    values.push(value);

    history.replace({
      pathname: location.pathname,
      search: stringifyQs({ ...query, [name]: values }),
    });
  };

  change = e => {
    const { name, obj, onChange } = this.props;
    const selected = e.target.checked;

    if (onChange) {
      onChange(selected);
    } else {
      this.setState({ selected }, () => {
        if (selected) {
          this.addFilter(name, obj.value ? obj.value : obj.name);
        } else {
          this.removeFilter(name, obj.value ? obj.value : obj.name);
        }
      });
    }
  };

  render() {
    const { obj, name, size } = this.props;
    const { selected, hovered } = this.state;

    let img = null;
    if (obj.src) {
      img = <img src={obj.src} />;
    }
    if ((selected || hovered) && obj.src_selected) {
      img = <img src={obj.src_selected} />;
    }

    let icon = null;
    if (obj.icon) {
      icon = obj.icon;
    }
    if ((selected || hovered) && obj.icon_selected) {
      icon = obj.icon_selected;
    }

    return (
      <div
        class={
          'filter filter-attribute ' +
          (hovered ? 'hovered ' : '') +
          (selected ? 'selected ' : '') +
          (size ? 'filter-attribute-' : '') +
          size +
          ' '
        }
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
      >
        <label title={obj.name} for={obj.name}>
          <div class="body">
            <div class="icon">
              {img}
              {icon}
            </div>

            <div class="text overflow-ellipsis">{obj.name}</div>

            <input
              id={obj.name}
              name={name + '[]'}
              type="checkbox"
              checked={selected}
              value={true}
              onChange={this.change}
              style={{ visibility: 'hidden', position: 'absolute' }}
            />
          </div>
        </label>
      </div>
    );
  }
}
CarSelectableAttribute.defaultProps = {
  size: '',
  onChange: null,
};

export default injectIntl(connect()(CarSelectableAttribute));
