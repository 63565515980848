import React from 'react';
import { connect } from 'react-redux';

import {find as _find, forEach as _forEach, cloneDeep as _cloneDeep} from 'lodash';
import ReactDOM from "react-dom";
import {Button} from "react-bootstrap";
import {getTimezoneFromState} from "../../../helpers/intl";
import FormInput from "../../../components/forms/FormInput";
import Loader from "../../../components/layout/Loader";
import ReactTooltip from "react-tooltip";
import Slider from "rc-slider/lib/Slider";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import EditorApplyStyles from "../EditorApplyStyles";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";
import { S3_LINKS } from "js/constants";

const ContextModal = ({children}) => {
  return ReactDOM.createPortal(
    children,
    document.getElementById('context-modal')
  );
};


class TextSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTextEdit: false,
      showSliderOptions: false,
      showTextEditor: false,
      xZoom: 0,
      TEXT_DATA: [],
      showEditTitle: false,
      showEditDescription: false,
      textNumber: 0,
      schedule: [],
      schedulesEdit: [],
      textSort: "",

      text1: "",
      text2: "",
      sliderTitle: "Neuer Titel",
      sliderLink: "Neuer Link",
      sliderLinkTitle: "https://...",

    }
    this.cropperRef = React.createRef();
  }



  componentDidUpdate(prevProps)  {
    if (prevProps.params !== this.props.params) this.getSliderData();
  }

  setSliderData = (data, reverse) => {
    const {TEXT_DATA} = this.state;
    const { id, attributes, updateParam } = this.props;
    const TEXT_DATA_CLONE2 = _cloneDeep(TEXT_DATA);
    const {intl: { messages }} = this.props;

    if(data !== "edit") {
      let ELEMENT_ARR = [data[0], data[1]];
      if(this.state.textSort === "sliderElementReverse"){
        TEXT_DATA_CLONE2.unshift(ELEMENT_ARR);
      }else{
        TEXT_DATA_CLONE2.push(ELEMENT_ARR);
      }

    }

    let param = '';
    _forEach(TEXT_DATA_CLONE2, (schedule, i) =>{
      param += schedule[0] + '#aaa#' + schedule[1];
      if(i < TEXT_DATA_CLONE2.length-1){
        param += '#cc#';
      }
    });
    param = param + '#abc#' + this.state.sliderTitle + '#abc#' + this.state.sliderLink + '#abc#' + this.state.sliderLinkTitle;
    const overflow = false;
    const default_image = false;
    const errors = [{overflow, default_image}];
    let showOptions = true;
    if(data === "edit"){
      showOptions = false;
    }
    updateParam(id, attributes['data-name'], param, errors)
    if(reverse === true) showOptions = true;
    this.setState({TEXT_DATA: TEXT_DATA_CLONE2, showSliderOptions: showOptions }, () => { this.getSliderData(); });
  }

  getSliderData = () => {
    const { name: Tag, attributes, params } = this.props;
    const param = _find(params, ['name', attributes['data-name']]);
    let terms = [];
    let outputTitle = "Neuer Titel";
    let outputLink = "https://...";
    let outputLinkTitle = "Neuer Linktitel";
    let data;

    if(param) {
      data = param.value.split("#abc#")
      const output = data[0].split("#cc#");
      _forEach(output, (entry, i) => {
        const schedule = entry.split('#aaa#');
        terms.push( schedule );
      });
      outputTitle = (data[1] !== undefined) ? data[1] : outputTitle;
      outputLink =  (data[2] !== undefined) ? data[2] : outputLink;
      outputLinkTitle = (data[3] !== undefined) ? data[3] : outputLinkTitle;

    }
    this.setState({TEXT_DATA: terms, schedulesEdit: terms, sliderTitle: outputTitle, sliderLink: outputLink, sliderLinkTitle: outputLinkTitle }, () => {});
  }


  handleOnChange = e => {
          this.setState({ showTextEdit: false });
            let data = [this.state.text1, this.state.text2];
            this.setSliderData(data);
  }


  componentDidMount = () => {
    this.getSliderData();
  }

  showTextEdit = () => {
    this.setState({showTextEdit: true});
  }

  showSliderOptionsEdit = () => {
    const { filesUploading } = this.props;
      this.setState({showSliderOptions: true})
      this.getSliderData();
  }

  handleAddElement = (option) => {
    this.setState({ textSort: option }, () => {
      this.setState({ showTextEdit: true });
    });

  }


  handleIEclose = () => {
    this.setState({ showTextEdit: false, showTextEditor: false, showSliderOptions: false });
  };

  showTextInput = (index) => {
    const { TEXT_DATA } = this.state;
    this.setState({
      showEditTitle: true,
      textNumber: index,
      showEditDescription: true,
    });
  }

  editText = (name, value) => {
    const { TEXT_DATA, textNumber } = this.state;
    if(name === "text1"){
      const TEXT_DATA_CLONE = _cloneDeep(TEXT_DATA);
      TEXT_DATA_CLONE[textNumber][0] = value;
      this.setState({ TEXT_DATA: TEXT_DATA_CLONE });
    }
    if(name === "text2"){
      const TEXT_DATA_CLONE = _cloneDeep(TEXT_DATA);
      TEXT_DATA_CLONE[textNumber][1] = value;
      this.setState({ TEXT_DATA: TEXT_DATA_CLONE });
    }
  }

  handleDeleteElement = (index) => {
    const { TEXT_DATA} = this.state;
    const TEXT_DATA_CLONE5 = _cloneDeep(TEXT_DATA);

    let sliced = TEXT_DATA_CLONE5.slice(0,index);
    let sliced2 = [];
    if(index < TEXT_DATA_CLONE5.length - 1){
      sliced2 = TEXT_DATA_CLONE5.slice(index+1);
    }

    const NEW_TEXT_DATA = [...sliced, ...sliced2];
    this.setState({ TEXT_DATA: NEW_TEXT_DATA });
  }

  reverseElements = () => {
    const { TEXT_DATA} = this.state;
    const TEXT_DATA_REVERSE = _cloneDeep(TEXT_DATA);
    TEXT_DATA_REVERSE.reverse();
    this.setState({TEXT_DATA: TEXT_DATA_REVERSE}, () => {  this.setSliderData("edit", true) })

  }



  render() {
    const { name: Tag, attributes, templateId, disabled } = this.props;
    const {showTextEdit, textNumber, showTextEditor, xZoom, showSliderOptions, TEXT_DATA, showEditTitle, showEditDescription } = this.state;
    const enable={display:'block'};
    const canvasWidth = parseFloat(document.getElementsByTagName('body')[0].offsetWidth.toFixed(0)) - 200;
    const possibleZooms = parseFloat((attributes['data-width'] / canvasWidth).toFixed(0));
    const output = [];
    const {intl: { messages }} = this.props;


      output.push(

        TEXT_DATA.map((slide, index) => (
          <SplideSlide key={index}>
            <div className="textDiv">
              <p class="text1" onClick={() => {
                this.showTextInput(index)
              }}><span>{slide[0] || messages.no_text}</span></p>
              <p className="text2" onClick={() => {
                this.showTextInput(index)
              }}><span>{slide[1] || messages.no_text}</span></p>
              <Button disabled={disabled} style={{position:"absolute", top:"0", right:"0", padding:"0", height:"30px", width:"30px", margin:"3px"}} bsStyle='danger' bsSize='large' onClick={() => {this.handleDeleteElement(index)}} >X</Button>
            </div>
          </SplideSlide>
        ))
      )

    const previewImageCss = attributes['data-pre'] ? '.cropper-face {opacity: 0.5; background: url('+ S3_LINKS.TEMPLATES + templateId+'/images/'+attributes['data-pre']+') center no-repeat;background-size: 100% 100%;}' : '';

    return (
      <>

        <Tag {...attributes} onClick={this.showSliderOptionsEdit}>

          <div>
            {TEXT_DATA.length>0   ?
              <div>
                <div className="sliderTitle"><p><span>{this.state.sliderTitle}</span></p></div>
                <Splide className="sliderCSSTextEditor"
                  options={ {
                    fixedWidth:380,
                    height: 400,
                    rewind : true,
                    perPage: 3,
                    gap    : 0,
                    pagination: true,
                    breakpoints:{
                      '1160':{
                        fixedWidth: 380,
                        height: 400,
                      }
                    }
                  } }

                >
                  {output}
                </Splide>
                <div className="sliderLink"><p className="slink"><span>{this.state.sliderLinkTitle}</span></p></div>
              </div>
              :
              <div className="wrap"><p>{messages.editor.edit_text_slider}</p></div>
            }
          </div>
        </Tag>

        {showSliderOptions && !disabled && (
          <ContextModal>
            <div id="contextModalWrap">
              <div id="contextModalStage" className="row">
                <h3>{messages.editor.slider_settings}</h3>
                <div className="textSliderOptions">
                  <div className="sliderTitle"><p>{this.state.sliderTitle}</p></div>
                  <Splide className="sliderCSSTextOptions"
                          options={ {
                            fixedWidth:253,
                            height: 300,
                            rewind : true,
                            perPage: 3,
                            gap    : 0,
                            pagination: true,
                            breakpoints:{
                              '780':{
                                fixedWidth: 253,
                                height: 300,
                              }
                            }
                          } }

                  >
                    {output}
                  </Splide>
                  <div className="sliderLink"><p><span>{this.state.sliderLinkTitle}</span></p></div>

                  {this.state.TEXT_DATA && this.state.TEXT_DATA.length > 0 ?
                    <div className="sliderButtons">
                      <Button style={{width:"100%", maxWidth:"250px"}} bsStyle='success' bsSize='large' onClick={()=>this.handleAddElement("sliderElementReverse")} >{messages.editor.add_element_before}</Button>
                      <Button style={{width:"100%", maxWidth:"250px"}} bsStyle='success' bsSize='large' onClick={()=>this.handleAddElement("sliderElementDefault")} >{messages.editor.add_element_after}</Button>
                      {this.state.TEXT_DATA.length > 1 ?
                      <Button style={{width:"100%", maxWidth:"250px"}} bsStyle='warning' bsSize='large' onClick={() => this.reverseElements()} >{messages.editor.invert_elements}</Button>
                        : <></>}
                      </div>
                    :
                    <div className="sliderButtons">
                      <Button style={{width:"100%", maxWidth:"250px"}} bsStyle='success' bsSize='large' onClick={()=>this.handleAddElement("sliderElementDefault")} >{messages.editor.add_element}</Button>
                    </div>}
                    <h4>{messages.editor.edit_text_title}</h4>
                      <FormInput
                        type="text"
                        label={messages.editor.title}
                        name="titel"
                        onChange={(name, value)=>this.setState({sliderTitle:value})}
                        value={this.state.sliderTitle}
                      />
                  <h4>{messages.editor.edit_text_link}</h4>
                  <FormInput
                    type="text"
                    label={messages.editor.link}
                    name="link"
                    onChange={(name, value)=>this.setState({sliderLink:value})}
                    value={this.state.sliderLink}
                  />
                  <h4>{messages.editor.edit_link_title}</h4>
                  <FormInput
                    type="text"
                    label={messages.editor.link_title}
                    name="linktitel"
                    onChange={(name, value)=>this.setState({sliderLinkTitle:value})}
                    value={this.state.sliderLinkTitle}
                  />
                  <h4>{messages.editor.edit_text_text}</h4>
                  {!showEditTitle && !showEditDescription ?
                    <p>{messages.editor.edit_text_of_element}</p> : <></>}

                  {showEditTitle && showEditDescription && TEXT_DATA.length-1 >= textNumber ?
                    <div style={{width:"900px", margin:"0 auto", display:"inline"}}>
                        <div>
                          <FormInput
                            type="text"
                            label={messages.editor.main_text}
                            name="text1"
                            onChange={this.editText}
                            value={TEXT_DATA[textNumber][0]}
                          />
                          <FormInput
                            type="text"
                            label={messages.editor.more_text}
                            name="text2"
                            onChange={this.editText}
                            value={TEXT_DATA[textNumber][1]}
                          />
                        </div>
                    </div> : <></>
                  }
                </div>
                <div className="galleryButtons" style={{position:"fixed", right:"100px", bottom:"100px"}}>
                  <Button bsStyle='danger' bsSize='large' onClick={() => this.setSliderData("edit")} >{messages.editor.take_on}</Button>
                  <Button bsStyle='warning' bsSize='large' onClick={this.handleIEclose} className={ 'cancelIE'}>{messages.editor.abort}</Button>
                </div>
              </div>
            </div>
          </ContextModal>
        )}

        {showTextEdit && !disabled && (
          <ContextModal>
            <div id="contextModalWrap">
              <div id="contextModalStage" className="row">

                <FormInput
                  type="text"
                  label={messages.editor.main_text}
                  name="text1"
                  onChange={(name, value)=>this.setState({text1:value})}
                  value=""
                />
                <FormInput
                  type="text"
                  label={messages.editor.more_text}
                  name="text2"
                  onChange={(name, value)=>this.setState({text2:value})}
                  value=""
                />

                <Button bsStyle='success' bsSize='large' onClick={this.handleOnChange} className="success">{messages.editor.create_text}</Button>

                <Button bsStyle='danger' bsSize='large' onClick={this.handleIEclose} className={ 'cancelIE'}>{messages.editor.abort}</Button>

              </div>
            </div>
          </ContextModal>
        )}

        {showTextEditor && !disabled && (
          <ContextModal style={enable}>
            <EditorApplyStyles styles={previewImageCss} />
            <div id="contextModalWrap">
              <div id="contextModalStage" className="image-editor">
                <Loader />
                <Button bsStyle='warning' bsSize='large' onClick={this.handleIEclose} className={ 'cancelIE'}>{messages.editor.abort}</Button>
                <Slider
                  step={0.01}
                  min={0}
                  max={ parseFloat(possibleZooms) }
                  defaultValue={xZoom}
                  value={xZoom}
                  onChange={(x) => {
                    const newXstate = parseFloat(x.toFixed(2));
                    const newX = newXstate - this.state.xZoom;

                    this.setState({ xZoom: newXstate });
                    this.cropperRef.current.zoom( newX );
                  }}
                />
              </div>
            </div>
            <ReactTooltip id="bottom" place="bottom" effect="solid" />
          </ContextModal>
        )}

      </>
    )
  }
}


const mapStateToProps = state => {
  return {
    defaultTimezone: getTimezoneFromState(state),
    appIntl: state.intl,
  };
};


export default withRouter(
  injectIntl(
    connect(
      mapStateToProps
    )(TextSlider)
  )
);


