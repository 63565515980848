import React from 'react';
import { components } from 'react-select';

class MyWhiteLabelProjectOption extends React.Component {
  render() {
    const { data } = this.props;
    return <components.Option {...this.props}>{data.label}</components.Option>;
  }
}

class MyWhiteLabelProjectValue extends React.Component {
  render() {
    const { data } = this.props;

    return <div className="Select-value">{data.label}</div>;
  }
}

const myWhiteLabelProjectOptionFormatter = options => {
  return options.list.map(option => {
    return myWhiteLabelProjectSingleOptionFormatter(option);
  });
};

const myWhiteLabelProjectSingleOptionFormatter = (option = null) => {
  return { ...option, value: option.id, label: option.name };
};

const myWhiteLabelProjectOptionFromEntity = myWhiteLabelProject => {
  return !myWhiteLabelProject
    ? null
    : myWhiteLabelProjectSingleOptionFormatter({
        id: myWhiteLabelProject.getId(),
        name: myWhiteLabelProject.getName(),
      });
};

export {
  MyWhiteLabelProjectValue,
  MyWhiteLabelProjectOption,
  myWhiteLabelProjectOptionFormatter,
  myWhiteLabelProjectSingleOptionFormatter,
  myWhiteLabelProjectOptionFromEntity,
};
