import axios from 'axios';

export const UPDATE_MESSENGER_OPTIN_USER_REF = 'UPDATE_MESSENGER_OPTIN_USER_REF';

export function updateMessengerOptinUserRef(userRef, ref) {
  return {
    type: UPDATE_MESSENGER_OPTIN_USER_REF,
    payload: { userRef, ref },
  };
}
