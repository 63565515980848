import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Badge } from 'react-bootstrap';

import Avatar from 'components/Avatar';

import { getChatterUserAvatarInfos } from 'helpers/clients';

function ContactItem({ item, lastMessage, unopenedMessagesCount, onClick, ...props }) {
  const { first_name: firstName, last_name: lastName, is_online: isOnline, is_mobile_online: isMobileOnline } = item;
  const { avatarThumbLink, avatarTextColor, avatarColor } = getChatterUserAvatarInfos(item);

  const itemClick = useCallback(e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (typeof onClick === 'function') {
      onClick(item);
    }
  });

  return (
    <li className="chat-user-list clearfix" {...props}>
      <a href="#openConversation" onClick={itemClick} className="padding-10 ws-normal">
        <span className="chat-user-list__avatar">
          <Avatar
            firstName={firstName}
            lastName={lastName}
            thumbLink={avatarThumbLink}
            textColor={avatarTextColor}
            color={avatarColor}
            className={isOnline || isMobileOnline ? 'avatar-b-online' : ''}
          />
        </span>
        <span className="block chat-user-list__info clearfix">
          <span className="text-info bold fs-12 col-xs-10 overflow-ellipsis no-padding">{item.name}</span>
          <span className="text-master-light fs-12 col-xs-2 text-right no-padding">-</span>
          <span className="clearfix" />
          <span className="text-master-light fs-10 lh-10 col-xs-10 no-padding">{lastMessage}</span>
          <span className="text-master-light col-xs-2 text-right no-padding">
            {!!unopenedMessagesCount && (
              <Badge bsStyle="info" className="fs-10 lh-10">
                {unopenedMessagesCount}
              </Badge>
            )}
          </span>
          <span className="clearfix" />
        </span>
      </a>
    </li>
  );
}
ContactItem.defaultProps = {
  lastMessage: '',
  unopenedMessagesCount: undefined,
  onClick: () => undefined,
};
ContactItem.propTypes = {
  item: PropTypes.object.isRequired,
  lastMessage: PropTypes.string,
  unopenedMessagesCount: PropTypes.number,
  onClick: PropTypes.func,
};

export default ContactItem;
