import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Grid, Row } from 'react-bootstrap';

import { gMapDdistance } from 'helpers/maps';
import { mayNavigateToF } from 'helpers/checkout';

import StepECarCheckoutInfo from 'components/layout/checkout/infoArea/StepECarCheckoutInfo';
import StepEItemCheckoutInfo from 'components/layout/checkout/infoArea/StepEItemCheckoutInfo';
import StepECarCheckoutSettings from 'components/layout/checkout/StepECarCheckoutSettings';
import StepEItemCheckoutSettings from 'components/layout/checkout/StepEItemCheckoutSettings';
import CheckoutTotal from 'components/layout/checkout/CheckoutTotal';

// shipping information
class StepE extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      detectingDistance: false,
    };
  }

  mayProceed = () => {
    const { checkout } = this.props;
    return mayNavigateToF(checkout);
  };

  clickNext = () => {
    const {
      appIntl: { urlPrefix },
      history,
    } = this.props;

    if (this.mayProceed()) {
      history.push(urlPrefix + 'checkout/payment');
    }
  };

  clickPrevious = () => {
    const {
      appIntl: { urlPrefix },
      history,
      checkout,
    } = this.props;

    if (checkout.isCarCheckout) {
      history.push(urlPrefix + 'checkout/services');
    } else {
      history.push(urlPrefix + 'checkout/personal');
    }
  };

  // if we have a car checkout, there should only be one car; return the first car we find or null if no car was found
  getCarItem = () => {
    const { checkout } = this.props;

    for (let i = 0; i < checkout.items.length; i++) {
      if (checkout.items[i].type === 'car') {
        return checkout.items[i];
      }
    }

    return null;
  };

  componentDidMount = () => {
    // check if it a car order; if so, check if we already have a calculated route
    // if no route found, make the google maps call and save it in the store
    // the distance between the car location and client location is the base to calculate the shipping cost
    const { checkout, setCheckoutState } = this.props;

    if (checkout.isCarCheckout) {
      if (checkout.shipping === null || !checkout.shipping.distance) {
        const car = this.getCarItem();

        if (car) {
          this.setState({ detectingDistance: true });
          const locationTo =
            checkout.userData.street +
            ' ' +
            checkout.userData.houseNumber +
            ', ' +
            checkout.userData.zipCode +
            ' ' +
            checkout.userData.city;

          gMapDdistance(
            car.id,
            locationTo,
            /* success: */ (status, distance) => {
              if (status === 'OK') {
                setCheckoutState({ shipping: { ...checkout.shipping, distance } });
              }

              this.setState({ detectingDistance: false });
            },
            /* error: */ () => {
              this.setState({ detectingDistance: false });
            }
          );
        }
      }
    }
  };

  render() {
    const {
      intl: { messages },
      checkout,
      setCheckoutState,
    } = this.props;
    const { detectingDistance } = this.state;

    return (
      <Grid fluid>
        <div class="panel panel-transparent">
          <Row class="row-same-height">
            <div class="col-md-5 b-r b-dashed b-grey sm-b-b">
              <div class="padding-30 sm-padding-5 sm-m-t-15 m-t-50">
                {checkout.isCarCheckout && <StepECarCheckoutInfo />}
                {checkout.isItemCheckout && <StepEItemCheckoutInfo />}
              </div>
            </div>
            <div class="col-md-7">
              <div class="padding-30 sm-padding-5">
                {checkout.isCarCheckout ? (
                  <StepECarCheckoutSettings
                    setCheckoutState={setCheckoutState}
                    detectingDistance={detectingDistance}
                    checkout={checkout}
                  />
                ) : (
                  <StepEItemCheckoutSettings setCheckoutState={setCheckoutState} checkout={checkout} />
                )}

                <br />

                <CheckoutTotal isCarCheckout={checkout.isCarCheckout} checkout={checkout} />
              </div>
            </div>
          </Row>

          <div class="padding-20 sm-padding-5 sm-m-b-20 sm-m-t-20 bg-white clearfix">
            <ul class="pager wizard no-style">
              <li class={'next ' + (this.mayProceed() ? '' : 'disabled ')} style={{ display: 'list-item' }}>
                <button
                  class="btn btn-primary btn-cons pull-right btn-animated from-left fa fa-credit-card"
                  type="button"
                  onClick={this.clickNext}
                >
                  <span>{messages.checkout_next}</span>
                </button>
              </li>

              <li class="previous">
                <button class="btn btn-default btn-cons pull-right" type="button" onClick={this.clickPrevious}>
                  <span>{messages.checkout_previous}</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </Grid>
    );
  }
}
StepE.defaultProps = {};

const mapStateToProps = state => {
  return {
    appIntl: state.intl,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(StepE)));
