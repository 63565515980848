const initialState = {
  dismissed: [],
};

export default function hints(state = initialState, action) {
  switch (action.type) {
    case 'HINT_DISMISSED': {
      let dismissed = state.dismissed.slice();
      dismissed.push(action.payload);
      return { dismissed };
    }
  }
  return state;
}
