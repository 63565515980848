import React from 'react';
import ReactPlayer from 'react-player';
import ScrollTrigger from 'react-scroll-trigger';
import Plx from 'react-plx';

import AbstractCmsElement from 'components/cms/elements/AbstractCmsElement';

export default class CmsElementVideo extends AbstractCmsElement {
  constructor(props) {
    super(props);

    this.allowStateChange = true;

    this.state = {
      shown: false,
    };
  }

  shown = () => {
    if (this.allowStateChange) {
      this.setState({ shown: true });
    }
  };

  render() {
    const { shown } = this.state;

    const shownClass = shown ? 'elem-shown ' : 'elem-hidden ';
    const wrapperClass = 'ms-video-wrapper ms-video-wrapper-' + this.getParamValue('videoAspectRatio', '16-9') + ' ';
    const videoUrl = this.getParamValue('videoUrl');
    const playing = this.getParamValue('videoAutoplay', 'no') === 'yes';

    const parallaxData = this.getParallaxData();

    const innerContent = (
      <div
        id={this.getMainId()}
        class={wrapperClass + shownClass + this.getResponsiveClasses() + this.getCssClasses()}
        style={{ ...this.getBackgroundStyle() }}
      >
        {this.getElementStyle()}

        {shown ? (
          <ReactPlayer
            url={videoUrl}
            controls
            width="100%"
            height="100%"
            playing={playing}
            className="ms-video-player-content"
          />
        ) : (
          <div />
        )}

        {this.getAppendHtml()}
      </div>
    );

    return (
      <ScrollTrigger onEnter={this.shown} {...this.getCustomId()}>
        {parallaxData ? <Plx parallaxData={parallaxData}>{innerContent}</Plx> : innerContent}
      </ScrollTrigger>
    );
  }
}
CmsElementVideo.defaultProps = {
  index: '0',
};
